import { Dialog } from 'primereact/dialog'
import SocialMediaShare from './SocialMediaShare'
import { useTranslation } from '../../hooks/useTranslation'

type props = {
    displayPosition: boolean
    hideShare: any
    urlSlug: string
    title: string
    postType: string
}

const SharePopup = ({ displayPosition, hideShare, title, urlSlug, postType }: props) => {
    return (
        <Dialog
            header={useTranslation("Share this post", 'capitalize')}
            visible={displayPosition}
            modal
            style={{ width: '33rem' }}
            onHide={hideShare}
            draggable={false}
            resizable={false}
            className=''
        >
            <SocialMediaShare urlSlug={urlSlug} title={title} postType={postType} />
        </Dialog>
    )
}
export default SharePopup
