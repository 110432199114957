import React, { useContext } from 'react'
import { ADD_NEW_HOLIDAY, EDIT_HOLIDAY } from '../../constants/strings'
import { CommonCtx } from '../../context/CommonCtxProvider'
import { PageMode } from '../../constants/constants'
import { ToastMessageProps } from '../../constants/staticTypes'
import HolidayForm from './HolidayForm'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { JTranslation } from '../../helpers/jTranslate'

type Props = {
    pageMode: PageMode.ADD | PageMode.EDIT
    setShowSideBar: React.Dispatch<React.SetStateAction<boolean>>
    setToastMessage: React.Dispatch<React.SetStateAction<ToastMessageProps>>
    year: string
}

const HolidaySideBar = ({ pageMode, setShowSideBar, setToastMessage, year }: Props) => {
    // CONTEXT VARIABLE
    const { showSideBar } = useContext(CommonCtx)
    const onClose = () => setShowSideBar(false)

    return (
        <Offcanvas
            show={showSideBar}
            onHide={() => onClose()}
            backdrop="static"
            // responsive="xl"
            placement="end"
            className="custom-offcanvas"
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                    {pageMode === PageMode.ADD 
                        ? <JTranslation typeCase="pascal" text={ADD_NEW_HOLIDAY} /> 
                        : <JTranslation typeCase="pascal" text={EDIT_HOLIDAY} />
                    }
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="row">
                    <div className=" col-md-12 col-lg-12 mb-3 ">
                        <HolidayForm onClose={onClose} pageMode={pageMode} setToastMessage={setToastMessage} year={year} />
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default HolidaySideBar
