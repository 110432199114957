import { QueryClient } from "react-query";
import { publicUserTrackingApi } from "../constants/apiEndPoints";
import { HttpMethods } from "../constants/constants";
import HttpServiceHelper from "./httpServiceHelper";
import { v4 as uuidv4 } from "uuid";

// user tracking
export const trackGuestUser = (queryClient: QueryClient, deviceId: string, xDeviceIdHeader: string) => {
    return HttpServiceHelper({
        url: publicUserTrackingApi,
        headers: {
            "X-device-data": xDeviceIdHeader
        },
        method: HttpMethods.POST,
        data: {
            deviceId
        },
        queryClient
    });
}

// get device id
export const getDeviceId = () => {
    return localStorage.getItem("device-id");
}

// set device id
export const setDeviceId = () => {
    localStorage.setItem("device-id", uuidv4() + "-" + Date.now());
}