import { useContext } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { PageMode } from '../../constants/constants'
import { ToastMessageProps } from '../../constants/staticTypes'
import { CommonCtx } from '../../context/CommonCtxProvider'
import MandatoryDaysForm from './MandatoryDaysForm'

type Props = {
	pageMode: PageMode.ADD | PageMode.EDIT
	setMode: React.Dispatch<React.SetStateAction<PageMode>>
	setShowSideBar: React.Dispatch<React.SetStateAction<boolean>>
	setToastMessage: React.Dispatch<React.SetStateAction<ToastMessageProps>>
}

function MandatoryDaysSideBar({ pageMode, setShowSideBar, setToastMessage, setMode }: Props) {
	// CONTEXT VARIABLE
	const { showSideBar } = useContext(CommonCtx)
	const onClose = () => setShowSideBar(false)

	return (
		<Offcanvas
			show={showSideBar}
			onHide={() => onClose()}
			backdrop="static"
			// responsive="xl"
			placement="end"
			className="custom-offcanvas"
		>
			<Offcanvas.Header closeButton>
				<Offcanvas.Title>Mandatory Days</Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body>
				<div className="row">
					<div className=" col-md-12 col-lg-12 mb-3 ">
						<MandatoryDaysForm onClose={onClose} pageMode={pageMode} setMode={setMode} setToastMessage={setToastMessage} />
					</div>
				</div>
			</Offcanvas.Body>
		</Offcanvas>
	)
}

export default MandatoryDaysSideBar
