import { useState } from 'react'
import {
	addShiftConfig,
	convertApiDataToFormData,
	deleteShiftConfig,
	getShiftConfigList,
	updateShiftConfig,
} from '../../helpers/shiftConfigHelper'
import { AxiosResponse } from 'axios'
import { CREATE_SHIFT, SHIFT_CONFIGURATION_TITLE, TITLE_SHIFT_SETTING, UPDATE_WARNING } from '../../constants/strings'
import { Shift, ShiftForm, ShiftFormData, ToastMessageProps } from '../../constants/staticTypes'
import { SHIFT_CONFIG_LIST } from '../../constants/queryKeys'
// import { TabMenu } from 'primereact/tabmenu'
import { toastMessageInitialData } from '../../constants/constants'
import Loader from '../../components/loader/Loader'
import ShiftConfigCard from '../../components/management_schedule/ShiftConfigCard'
import ToastAlert from '../../components/alert/ToastAlert'
import useQueryHook from '../../hooks/useQueryHook'
import withSidebar from '../../hoc/withSidebar'
import { JTranslation } from '../../helpers/jTranslate'
import MlxPopover from '../../components/common/MlxPopover'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'
import { useQueryClient } from 'react-query'
import useMutationHook from '../../hooks/useMutationHook'
import WarningModal from '../../components/warning_modal/WarningModal'

const ShiftConfig = () => {
	const queryClient = useQueryClient()
	const shiftMutation = useMutationHook(queryClient, true)

	const [showWarning, setShowWarning] = useState(false)
	const [currentEditItem, setCurrentEditItem] = useState<string | null>(null)
	const [shiftData, setShiftData] = useState<ShiftFormData[]>([] as ShiftFormData[])
	const [localShiftData, setLocalShiftData] = useState<ShiftFormData[]>([] as ShiftFormData[])
	const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)

	const onShiftConfigSuccess = (res: AxiosResponse<any, any>) => {
		const shifts = res?.data.data.lists as Shift[]
		const parsedData = convertApiDataToFormData(shifts)
		setShiftData(parsedData)
	}

	// fetch shift config list
	const shiftDataQuery = useQueryHook(SHIFT_CONFIG_LIST, () => getShiftConfigList(), onShiftConfigSuccess)

	// on create custom menu success
	const onSuccess = (message: string, variant: string) => {
		setToastMessage({ message, variant, show: true })
		setCurrentEditItem(null)
		shiftDataQuery.refetch()
	}

	// on api error
	const onError = (message: string, variant: string) => {
		setToastMessage({ message, variant, show: true })
		setCurrentEditItem(null)
		shiftDataQuery.refetch()
	}

	// delete local shift
	const deleteLocalShift = (id: string) => {
		setLocalShiftData(localShiftData.filter((data) => data.id !== id))
	}

	const handleAddSubmit = (data: ShiftFormData) => {
		const formData: ShiftForm = {
			shiftName: data.shiftName,
			startTime: dayjs(data.startTime).format('HH:mm'),
			endTime: dayjs(data.endTime).format('HH:mm'),
		}
		addShiftConfig(shiftMutation, formData, onSuccess, onError)
		deleteLocalShift(data.id)
	}

	const handleUpdateSubmit = (data: ShiftFormData) => {
		const formData: Shift = {
			id: data.id,
			shiftName: data.shiftName,
			startTime: dayjs(data.startTime).format('HH:mm'),
			endTime: dayjs(data.endTime).format('HH:mm'),
		}
		updateShiftConfig(shiftMutation, formData, onSuccess, onError)
	}

	// @ts-ignore
	const handleDeleteShift = (data: ShiftFormData) => {
		const checkIfNew = data?.id?.includes('new-item')
		if (checkIfNew) {
			deleteLocalShift(data.id)
            setCurrentEditItem(null)
		} else {
			deleteShiftConfig(shiftMutation, { id: data.id }, onSuccess, onError)
		}
	}

	// page loading animation
	if (shiftDataQuery.isLoading) return <Loader />

	return (
		<>
			{/* TOAST MESSAGE COMPONENT */}
			<ToastAlert
				show={toastMessage.show}
				onClose={() => setToastMessage(toastMessageInitialData)}
				message={toastMessage.message}
				variant={toastMessage.variant}
				data-testid="toast-message"
			/>

			{/* WARNING MODAL */}
			<WarningModal
				show={showWarning}
				title={UPDATE_WARNING}
				onHide={() => {
					setShowWarning(false)
				}}
				callback={() => {
					setCurrentEditItem(null)
					setShowWarning(false)
				}}
			/>

			<div className="col-md-12 col-lg-12 mb-3 h-100 ">
				{/*   <div className="page-title d-flex mb-3  align-items-sm-center flex-column flex-sm-row">
                    <div className="flex-grow-1 mb-1 mb-sm-">
                        <h5 className="mb-0"><JTranslation typeCase="pascal" text={SHIFT_CONFIGURATION_TITLE} /></h5>
                    </div>
                </div> */}
				<div className="page-title">
					<h5 className="d-flex align-items-baseline">
						<JTranslation typeCase="pascal" text={SHIFT_CONFIGURATION_TITLE} />
						<MlxPopover data={{ title: SHIFT_CONFIGURATION_TITLE, body: TITLE_SHIFT_SETTING }} />
					</h5>
				</div>
				<div className="col-md-12 col-lg-12 mb-3 h-100 ">
					<div className="card h-100 ">
						<div className="card-body flex-grow-1 overflow-hidden">
							<div className="survey-section h-100   d-flex flex-column">
								{/* <TabMenu model={menuItem} /> */}

								<div className="card shift-add-p-bg mb-3 shift-sticky flex-grow-0">
									<div className="card-body d-flex flex-column flex-md-row d-md-flex align-items-md-center gap-3">
										{/* <div className='flex-grow-0 shift-add-container'> */}
										{/* <MlxPopover data={{title:SHIFT_SETTING, body:HELP_SHIFT_SETTINGS}}/> */}
										{/*  </div> */}
										<div className="flex-grow-1 d-flex">
											<span className="flex-grow-0 shift-add-container me-2">
												<i className="ri-information-line"></i>
											</span>
											{/*  <JTranslation typeCase="capitalize" text={TITLE_SHIFT_SETTING} /> */}
											<p className="mb-0">
												<JTranslation
													typeCase="none"
													text={'You can create your own shifts here.'}
												/>
											</p>
										</div>
										<div className="flex-grow-0 d-flex justify-content-end">
											<button
												disabled={!!currentEditItem}
												id="create-shift-button"
												className="btn btn-custom-primary-outline d-flex text-nowrap"
												onClick={() => {
													if (currentEditItem) {
														return
													}
													const newShift: ShiftFormData = {
														id: uuidv4() + '-new-item',
														shiftName: '',
														startTime: null,
														endTime: null,
													}
													setLocalShiftData([...(localShiftData || []), newShift])
													setCurrentEditItem(newShift.id)
												}}
											>
												<i className="ri-add-circle-line me-1"></i>
												<span className="">
													{' '}
													<JTranslation typeCase="pascal" text={CREATE_SHIFT} />
												</span>
											</button>
										</div>
									</div>
								</div>
								<div className="overflow-auto">
									<div className="flex-grow-1">
										{localShiftData
											?.map((shift, index) => ({ ...shift, index }))
											?.sort((a, b) => b.index - a.index)
											?.map((shift, index) => (
												<ShiftConfigCard
													key={shift.id}
													shift={shift}
													handleAddSubmit={handleAddSubmit}
													handleUpdateSubmit={handleUpdateSubmit}
													handleDeleteShift={handleDeleteShift}
													currentEditItem={currentEditItem}
													setCurrentEditItem={setCurrentEditItem}
												/>
											))}
										{shiftData
											?.map((shift, index) => ({ ...shift, index }))
											?.sort((a, b) => b.index - a.index)
											?.map((shift, index) => (
												<ShiftConfigCard
													key={shift.id}
													shift={shift}
													handleAddSubmit={handleAddSubmit}
													handleUpdateSubmit={handleUpdateSubmit}
													handleDeleteShift={handleDeleteShift}
													currentEditItem={currentEditItem}
													setCurrentEditItem={setCurrentEditItem}
												/>
											))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default withSidebar(ShiftConfig)
