import { AxiosResponse } from "axios";
import { QueryClient, UseMutationResult } from "react-query";
import { couponDetailsApi, couponRedeemApi, couponsListApi } from "../constants/apiEndPoints";
import { AlertVariant, HttpMethods } from "../constants/constants";
import { Payload } from "../constants/staticTypes";
import { Coupon } from "../constants/staticTypes";
import HttpServiceHelper from "./httpServiceHelper";
import { getErrorMessage } from "./utils";

// fetch all active coupons
export const getActiveCouponsList = (queryClient: QueryClient,
    search: string, lastKey: string | null, limit: number) => {
    let url = `${couponsListApi}?search=${search}&limit=${limit}`;
    if (lastKey !== null) {
        url = `${url}&lastKey=${encodeURIComponent(lastKey as string)}`;
    }
    return HttpServiceHelper({
        url,
        method: HttpMethods.GET,
        queryClient
    });
}

// get coupon details
export const getCouponDetails = (queryClient: QueryClient,
    couponCode: string,
    couponDetailsMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onSuccess: (coupon: Coupon, message: string) => void,
    displayToast: (message: string, variant: string) => void
) => {
    couponDetailsMutation.mutate({
        queryClient,
        url: couponDetailsApi,
        method: HttpMethods.POST,
        data: {
            couponCode
        }
    }, {
        onSuccess: (res) => onSuccess(res.data.data, res.data.message),
        onError: (error) => displayToast(getErrorMessage(error), AlertVariant.ERROR)
    });
}

// redeem coupon
export const redeemCoupon = (
    queryClient: QueryClient,
    coupon: Coupon,
    redeemCouponMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onSuccess: (oldItem: Coupon, newItem: Coupon, message: string) => void,
    displayToast: (message: string, variant: string) => void
) => {
    redeemCouponMutation.mutate({
        queryClient,
        url: couponRedeemApi,
        method: HttpMethods.POST,
        data: {
            couponCodeId: coupon.id
        }
    }, {
        onSuccess: (res) => onSuccess(coupon, res.data.data, res.data.message),
        onError: (error) => displayToast(getErrorMessage(error), AlertVariant.ERROR)
    });
}
