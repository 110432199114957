import { ScheduleMenuId, UserGlobalSettings, CustomMenu, FnBGlobalSetting, GuestFnbGlobalSetting } from "../constants/staticTypes"

export const initialCustomMenuResponse: CustomMenu = {
	photoKey: null,
	menuName: '',
	menuCode: '',
	globalSettings: {
		marketPriceLabel: '',
		showMenuCardPrice: false,
		showMenuSuggestedItemCardPrice: false,
		qrCodeSettings: {
			qrCodeTopLabel: '',
			includeLogo: true,
			qrCodeBottomLabel: '',
		},
		menuResetTimeInMinutes: 10
	},
	menuDescription: '',
	createdAt: 0,
	id: '',
	isActive: false,
	rowStatus: 'ACTIVE',
	searchTerm: '',
	updatedAt: 0,
	signedUrl: '',
	menuCategoryAndItems: [],
}

export const initialPosValue = {
	preferredName: '',
	id: '',
	picture: '',
	posNumber: '',
	signedUrl: '',
	status: '',
	userName: '',
	userType: '',
}

export const initialFnBGlobalSettings: FnBGlobalSetting = {
	marketPriceLabel: '',
	showMenuCardPrice: false,
	showMenuSuggestedItemCardPrice: false,
	qrCodeSettings: {
		qrCodeTopLabel: '',
		includeLogo: true,
		qrCodeBottomLabel: '',
	},
	menuResetTimeInMinutes: 10
}

export const initialGuestFnbGlobalSettings: GuestFnbGlobalSetting = {
	marketPriceLabel: '',
	showMenuCardPrice: false,
	showMenuSuggestedItemCardPrice: false,
	menuResetTimeInMinutes: 10
}

export const initialScheduleMenu: ScheduleMenuId = {
	id: "",
	menuName: ""
}

export const initialUserGlobalSettings: UserGlobalSettings = { qrCodeLabel: "" }

export const initialStringArray: string[] = []

export const initialColorTheme: string = '#fbaa07'