import { useState, useCallback, useRef, useContext, useEffect } from 'react'
import { DebounceInput } from 'react-debounce-input'
import { FaSearch, FaTimesCircle } from 'react-icons/fa'
import { FileManagementCtx } from '../../context/FileManagementCtxProvider'
import { Pagination, FileTypeVerificationTabs, InvoiceFilter } from '../../constants/staticTypes'
import { Paginator } from 'primereact/paginator'
import { TabView, TabPanel } from 'primereact/tabview'
import { v4 as uuidv4 } from 'uuid'
import { VERIFY_TYPE, VERIFY_DATA, INVOICES, UPLOADED_DOCUMENTS, FILTER_TABLE_COLUMNS } from '../../constants/strings'
import Dropdown from 'react-bootstrap/Dropdown'
import FileTypeVerificationDataTable from './FileTypeVerificationDataTable'
import InvoiceListDataTable from './InvoiceListDataTable'
import withSidebar from '../../hoc/withSidebar'
import { JTranslation, TranslationContext, jTranslationText } from '../../helpers/jTranslate'
import { useTranslation } from '../../hooks/useTranslation'
import { useWindowSize } from 'react-use'
import { MOBILE_VIEW_BREAKPOINT } from '../../constants/constants'

const FileTypeVerification = () => {
    const inputField = useRef(null)
    // STATE VARIABLES
    const [searchValue, setSearchValue] = useState('')
    const [currentTab, setCurrentTab] = useState<FileTypeVerificationTabs>('verify_type')
    // PAGINATION
    const [basicFirst, setBasicFirst] = useState(0)
    const [basicRows, setBasicRows] = useState(20)
    const [pageNumber, setPageNumber] = useState(1)
    const [total, setTotal] = useState(0)
    // CONTEXT VARIABLES
    const { setRefetchAPI, filterValue, setFilterValue } = useContext(FileManagementCtx)
    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext

    const [translationText, setTranslatedText] = useState<{
        filterTable: string,
        search: string,
    }>({ 
        filterTable: FILTER_TABLE_COLUMNS,
        search: 'Search',
    });

    const [showTitle, setShowTitle] = useState(true)
    const { width, height } = useWindowSize()
	const breakPoint = MOBILE_VIEW_BREAKPOINT

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: FILTER_TABLE_COLUMNS, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: 'Search', typeCase: 'pascal', translationContext }),
                ])
                
                setTranslatedText({
                    filterTable: translations[0] ?? FILTER_TABLE_COLUMNS,
                    search: translations[1] ?? 'Search',
                })
            } catch {
                setTranslatedText({
                    filterTable: FILTER_TABLE_COLUMNS,
                    search: 'Search',
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    const changeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        let change = filterValue.map((item) =>
            item.title === e.target.value ? { title: e.target.value, display: e.target.checked } : item
        )
        setFilterValue(change)
    }

    const getDropDownItem = () =>
        filterValue.map((item: InvoiceFilter) => {
            return (
                <div key={uuidv4()} className="form-group input-group custom-input-group mb-2">
                    <input
                        type="checkbox"
                        autoComplete="off"
                        value={item.title}
                        checked={item.display ? true : false}
                        onChange={(e) => changeFilter(e)}
                    />
                    <label className="form-label ms-2 mb-0"><JTranslation typeCase="pascal" text={item.title} /></label>
                </div>
            )
        })

    const resetPagination = () => {
        setBasicFirst(0)
        setPageNumber(1)
    }

    const storeCurrentTabName = (tabName: FileTypeVerificationTabs) => {
        setCurrentTab(tabName)
    }

    const changePagination = (event: Pagination) => {
        setBasicFirst(event.first)
        setBasicRows(event.rows)
        setPageNumber(event.page + 1)
        setRefetchAPI(true)
    }

    const onClear = () => {
        setSearchValue('')
        setRefetchAPI(true)
    }

    const searchFile = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value.trimStart()) {
                setSearchValue(e.target.value)
                setRefetchAPI(true)
            } else {
                // @ts-ignore-start
                inputField?.current?.click()
                // @ts-ignore-end
            }
        },
        [setSearchValue, setRefetchAPI]
    )

    // effect to reset pagination when the tab changes
    useEffect(() => {
        resetPagination()
    }, [currentTab])

    return (
        <div className="col-md-12 col-lg-12 mb-3 h-100 user-scroll-fix ">
            <div className="card h-100 ">
                <div className="card-header flex-grow-0">
                    <div className="d-flex  align-items-center">
                        <div className="flex-grow-1">
                            {showTitle &&
                                <h4 className="mb-0 title d-flex align-items-center"><JTranslation typeCase="pascal" text={UPLOADED_DOCUMENTS} /></h4>
                            }
                        </div>
                        <div className="flex-grow-0 d-flex justify-content-end ">
                            {currentTab === 'invoices' && (
                                <Dropdown className="filter-btn me-2">
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        <i className="ri-filter-line" title={translationText.filterTable}></i>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <div className="col-12 p-3">{getDropDownItem()}</div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}
                        </div>
                        <div className="flex-grow-0 d-flex justify-content-end ">
                            <div className={`form-group has-search ${!showTitle ? '' : 'search-small-box'}`}>
                                <span className="fa fa-search form-control-feedback">
                                    <FaSearch />{' '}
                                </span>
                                <DebounceInput
                                    onFocusCapture={() => {breakPoint >= width && setShowTitle(false)}}
                                    onBlurCapture={(event: any) => {                                                
                                        if(breakPoint >= width && event.target.value.length === 0) {
                                            setShowTitle(true)
                                        }
                                    }}
                                    className="form-control"
                                    placeholder={translationText.search}
                                    minLength={0}
                                    debounceTimeout={500}
                                    onChange={searchFile}
                                    data-test-id={'search'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body flex-grow-1 overflow-hidden pb-0">
                    <div className="row h-100">
                        <div className="col-lg-12" style={{ height: '100%', overflow: 'hidden' }}>
                            <TabView className="custom-prime-tab-main h-100 d-flex flex-column">
                                <TabPanel header={useTranslation(VERIFY_TYPE, 'pascal')} headerClassName="custom-prime-tab h-100">
                                    {/* VERIFY FILE TYPE COMPONENT */}
                                    <div className="h-100 d-flex flex-column ">
                                        <div className="flex-grow-1 h-100 overflow-hidden">
                                            <FileTypeVerificationDataTable
                                                searchValue={searchValue}
                                                basicRows={basicRows}
                                                pageNumber={pageNumber}
                                                setTotal={setTotal}
                                                storeCurrentTabName={storeCurrentTabName}
                                            />
                                        </div>

                                        <div className="flex-grow-0">
                                            <Paginator
                                                first={basicFirst}
                                                rows={basicRows}
                                                totalRecords={total}
                                                rowsPerPageOptions={[10, 20, 30, 50]}
                                                onPageChange={changePagination}
                                            />
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel header={useTranslation(INVOICES, 'pascal')} headerClassName="custom-prime-tab h-100">
                                    {/* INVOICE LISTING COMPONENT  */}
                                    <div className="h-100 d-flex flex-column ">
                                        <div className="flex-grow-1 h-100 overflow-hidden">
                                            <InvoiceListDataTable
                                                searchValue={searchValue}
                                                basicRows={basicRows}
                                                pageNumber={pageNumber}
                                                setTotal={setTotal}
                                                storeCurrentTabName={storeCurrentTabName}
                                            />
                                        </div>

                                        <div className="flex-grow-0">
                                            <Paginator
                                                first={basicFirst}
                                                rows={basicRows}
                                                totalRecords={total}
                                                rowsPerPageOptions={[10, 20, 30, 50]}
                                                onPageChange={changePagination}
                                            />
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel header={useTranslation(VERIFY_DATA, 'pascal')} headerClassName="custom-prime-tab h-100">
                                    <JTranslation typeCase="pascal" text={"PLACEHOLDER"} />
                                </TabPanel>
                            </TabView>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withSidebar(FileTypeVerification)
