import { PropsWithChildren } from "react";
import Dropzone, { useDropzone } from "react-dropzone";

type InputAction = { fileInputAction: Function };
interface ExtendedProps extends PropsWithChildren, InputAction { }

const DropZone = ({ children, fileInputAction }: ExtendedProps) => {
    return (
        <Dropzone
            noClick
            multiple
            onDrop={(acceptedFiles) => {
                fileInputAction(acceptedFiles)
            }}
        >
            {({ getRootProps, getInputProps, isDragActive }) => (
                <section className="custom-dropzone">
                    <div {...getRootProps()} className={isDragActive ? "dropzone-inner drop-zone-highlight" : "dropzone-inner"}>
                        <input {...getInputProps()} autoComplete="off"/>
                        {children}
                    </div>
                </section>
            )}
        </Dropzone>
    );
};

export default DropZone;