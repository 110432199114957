import { JTranslation } from '../../helpers/jTranslate'
import withSidebar from '../../hoc/withSidebar'
import NotificationsListGrid from '../../components/manage_notifications/NotificationListGrid'

function MyNotifications() {
	return (
		<div className="col-md-12 col-lg-12 mb-3 h-100 ">
			<div className="page-title py-2 ps-2">
				<h5 className="d-flex">
					<JTranslation typeCase="pascal" text={'Notification Center'} />
				</h5>
			</div>

			<NotificationsListGrid />
		</div>
	)
}

export default withSidebar(MyNotifications)
