import React, { useEffect, useState } from 'react'
import { HolidayData } from '../../constants/staticTypes'
import { useQueryClient } from 'react-query'
import { HOLIDAYS_LIST } from '../../constants/queryKeys'
import { AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import useQueryHook from '../../hooks/useQueryHook'
import { getAllHolidaysList } from '../../helpers/holidayHelper'
import Loader from '../loader/Loader'
import { Card } from 'primereact/card'
import { JTranslation } from '../../helpers/jTranslate'
import { Badge } from 'primereact/badge'
import { dateFormatter, toPascalCase } from '../../helpers/utils'
import HolidayImage from '../../assets/images/holiday_bg.svg'
import { useNavigate } from 'react-router-dom'
import { Skeleton } from 'primereact/skeleton'

function UpcomingHolidaysCard() {
  // STATE VARIABLE
	const [holidayList, setHolidayList] = useState<HolidayData[]>([])
    const navigate = useNavigate()

	const queryClient = useQueryClient()
	useEffect(() => {
		// Reset holiday query and to show loader and data
		queryClient.removeQueries(HOLIDAYS_LIST)
	}, [queryClient])

	// holiday list fetch success
	const onHolidayListFetchSuccess = (res: AxiosResponse<any, any>) => {
		const holidayList = res.data.data.lists as HolidayData[]
        const today = dayjs().startOf('day'); // Set today as the reference date
		const sortedHolidayList = holidayList
            .filter(holiday => {
                const holidayDate = dayjs(holiday.startDate).startOf('day');
                return holidayDate.isAfter(today) || holidayDate.isSame(today); // Check if holiday is today or after
            })
            .sort((a, b) => dayjs(a.startDate).isAfter(dayjs(b.startDate)) ? 1 : -1); // Sort in ascending order
		setHolidayList(sortedHolidayList)
	}

	// fetch users list
	const holidayListData = useQueryHook(HOLIDAYS_LIST, () => getAllHolidaysList(), onHolidayListFetchSuccess)

	// if (holidayListData.isLoading) {
	// 	return <Loader />
	// }

	const parsedHolidayType = (holidayType: string) => {
        switch (holidayType) {
            case '':
                return '';
            case 'public':
                return 'Federal Holiday';
            case 'religious':
                return 'Cultural/Religious Holiday';
            case 'state':
                return 'State Holiday';
            case 'optional_observances':
                return 'Restaurant Holiday';
            default:
                return '';
        }
    }

	return (
		<>
        <Card 
            className="dashboard-timeoff-card card h-100 custom-shadow border-radius-05 border-0 overflow-auto" 
            title={
                <div className='d-flex justify-content-between'>
                    <div className='d-flex align-items-center'>
                        <JTranslation typeCase="pascal" text={"Upcoming Holidays"} />
                    </div>
                    <button className="btn btn-custom-primary dashboard-btn" onClick={()=>navigate('/holidays')}><JTranslation typeCase="pascal" text={'View All'} /></button>
                </div>
            }
        >
			<div className="col-md-12 col-lg-12 mb-3 h-100">
                <div className='dashboard-widget-height'>
                    <div className="h-100 ">
                        {holidayListData.isFetching ?
                            <>
                                <Skeleton className='mb-1' height='4rem'></Skeleton>
                                <Skeleton className='mb-1' height='4rem'></Skeleton>
                                <Skeleton className='mb-1' height='4rem'></Skeleton>
                            </>
                            :
                            holidayList.length > 0 ? (
                                holidayList.map((holiday: HolidayData) => (
                                    <div
                                        key={holiday.id}
                                        className="row px-2 py-2 mb-2 mx-2 d-flex flex-row align-items-center holiday-list-card"
                                    >
                                        <div className='d-flex justify-content-between align-items-center p-0'>
                                            <div className="flex-grow-0 mx-2">
                                            <div className='me-2 dashboard-status'>
                                                    <i className='ri-circle-fill success'></i>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1 d-flex-column">
                                            <div className="d-flex align-items-center">
                                                    <span className="fw-semibold overflow-ellipsis">{toPascalCase(holiday?.title)}</span>
                                                    {holiday.isPaid && (
                                                        <Badge
                                                            className="custom-badge mx-3"
                                                            value="Paid"
                                                            severity="warning"
                                                        ></Badge>
                                                    )}
                                                </div>
                                                <div className="holiday-name white-space-nowrap me-2">
                                                    {dateFormatter({ date: holiday.startDate, format: 'MMM D, YYYY - ddd' })}
                                                </div>
                                                
                                            </div>                                    
                                        </div>
                                    </div>
                                ))
                            ) : (
                            <p><JTranslation typeCase="pascal" text={"No Upcoming Holidays"} /></p>
                            )
                        }
                    </div>
			    </div>
			</div>
        </Card>
		</>
	)
}

export default UpcomingHolidaysCard