import { useQueryClient } from 'react-query'
import withSidebar from '../../hoc/withSidebar'
import { useContext, useEffect, useState } from 'react'
import WarningModal from '../../components/warning_modal/WarningModal'
import useMutationHook from '../../hooks/useMutationHook'
import { CommonCtx } from '../../context/CommonCtxProvider'
import { AreaType, JobRole, SqlPagination, ToastMessageProps } from '../../constants/staticTypes'
import { MOBILE_VIEW_BREAKPOINT, toastMessageInitialData } from '../../constants/constants'
import ToastAlert from '../../components/alert/ToastAlert'
import { AREA_LIST, ROLES_LIST } from '../../constants/queryKeys'
import { deleteJobRole, getRolesList } from '../../helpers/roleHelper'
import { JTranslation, TranslationContext, jTranslationText } from '../../helpers/jTranslate'
import { toPascalCase } from '../../helpers/utils'
import useQueryHook from '../../hooks/useQueryHook'
import { AxiosResponse } from 'axios'
import { DELETE_WARNING } from '../../constants/strings'
import RoleFormSideBar from '../../components/roles/RoleFormSideBar'
import { useWindowSize } from 'react-use'
import { FaEdit, FaSearch } from 'react-icons/fa'
import { DebounceInput } from 'react-debounce-input'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import MlxTooltip from '../../components/common/MlxTooltip'
import { Card } from 'primereact/card'
import { DataView } from 'primereact/dataview'
import { getAreaList } from '../../helpers/manageAreaHelper'

function Roles() {
	const queryClient = useQueryClient()
	const roleListMutation = useMutationHook(queryClient, true)
	const { width } = useWindowSize()
	const breakPoint = MOBILE_VIEW_BREAKPOINT

	// CONTEXT VARIABLE
	const { setShowSideBar: setRoleSideBar } = useContext(CommonCtx)
	const translationContext = useContext(TranslationContext)
	const { targetLanguage } = translationContext

	// STATE VARIABLES
	const [refetchApi, setRefetchApi] = useState(0)
	const [roleList, setRoleList] = useState<JobRole[]>([])
	const [pagination, setPagination] = useState<SqlPagination>({
		currentPage: 0,
		limit: 10,
		total: 0,
	})
	const [editRoleData, setEditRoleData] = useState<JobRole>()
	const [deleteRoleData, setDeleteRoleData] = useState<JobRole | null>(null)
	const [showTitle, setShowTitle] = useState(true)
	const [searchValue, setSearchValue] = useState('')
	const [areaList, setAreaList] = useState<AreaType[]>([] as AreaType[])
	// ALERT VARIABLES
	const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
	// WARNING MODAL
	const [showWarning, setWarning] = useState(false)
	const [warningMessage, setWarningMessage] = useState('')

	const [translationText, setTranslatedText] = useState<{
		search: string
		paginationTemplate: string
		emptyMessage: string
		edit: string
		delete: string
	}>({
		search: 'Search',
		paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
		emptyMessage: 'No guest found.',
		edit: 'Edit',
		delete: 'Delete',
	})

	// on add or update addOn success
	const onSuccess = (message: string, variant: string) => {
		setToastMessage({ message, variant, show: true })
		queryClient.refetchQueries([ROLES_LIST]) // nosonar
	}

	// on add or update addOn error
	const onError = (message: string, variant: string) => {
		setToastMessage({ message, variant, show: true })
	}

	// api call to delete add-on
	const deleteRoleApiCall = () => {
		deleteJobRole(roleListMutation, { id: deleteRoleData?.id as string }, onSuccess, onError)
	}

	// side bar close
	const handleClose = () => setRoleSideBar(false)

	// on trigger search
	const onSearchTrigger = (searchKey: string) => {
		if (roleListData.isFetching) {
			queryClient.cancelQueries([ROLES_LIST]) // nosonar
		}
		setSearchValue(searchKey)
		setRefetchApi((prev) => prev + 1)
	}

	// on edit button click
	const onEditClick = (role: JobRole) => {
		setEditRoleData(role)
	}

	// on delete button click
	const onDeleteClick = (role: JobRole) => {
		setDeleteRoleData(role)
		setWarningMessage(DELETE_WARNING)
		setWarning(true)
	}

	// fetch addOns list
	const roleListData = useQueryHook(
		ROLES_LIST,
		() =>
			getRolesList({
				limit: pagination?.limit ?? 10,
				page: pagination?.currentPage ?? 1,
				searchValue: searchValue,
				sort: 'DESC',
			}),
		(response: AxiosResponse<any, any>) => {
			let responseData = response?.data.data
			let roleList = responseData.lists as JobRole[]
			let pagination = responseData.pagination as SqlPagination
			setRoleList(roleList)
			setPagination(pagination)
		},
		(error) => {
			console.log(`Failed to fetch roles list: ${error}`)
		},
		true,
		false
	)

	// fetch addOns list
	const areaListData = useQueryHook(
		AREA_LIST,
		() =>
			getAreaList('', 500, ''),
		(response: AxiosResponse<any, any>) => {
			let responseData = response?.data.data
			let areaData = responseData.lists as AreaType[]
			setAreaList(areaData)
		},
		(error) => {
			console.log(`Failed to fetch area list: ${error}`)
		},
		true,
		false
	)

	const deleteButtonTemplateForRoleGroup = (role: JobRole) => {
		return (
			<>
				{
					<MlxTooltip target=".delete">
						<i
							role="button"
							data-pr-tooltip={translationText.delete}
							data-pr-position="bottom"
							className={
								'ri-delete-bin-line delete ' +
								// (!checkPermission(
								// 	queryClient,
								// 	PermissionKeys.MANAGE_ADDON_GROUPS,
								// 	SubPermissionKeys.DELETE
								// ) && ' disabled') +
								(width <= breakPoint ? ' rounded-background shadow-sm ms-2' : '')
							}
							data-testid="delete-addongroup"
							onClick={(e) => {
								e.stopPropagation()
								// if (
								// 	checkPermission(
								// 		queryClient,
								// 		PermissionKeys.MANAGE_ADDON_GROUPS,
								// 		SubPermissionKeys.DELETE
								// 	)
								// ) {
								onDeleteClick(role)
								// }
							}}
						></i>
					</MlxTooltip>
				}
			</>
		)
	}

	const editButtonTemplateForRoleGroup = (role: JobRole) => {
		return (
			<span className={`${width <= breakPoint ? 'rounded-background shadow-sm ms-2' : ''}`}>
				<MlxTooltip target=".edit">
					<FaEdit
						className={`edit`}
						data-pr-tooltip={translationText.edit}
						data-pr-position="bottom"
						data-testid="edit-addongroup"
						onClick={(e) => {
							e.preventDefault()
							// if (
							// 	checkPermission(queryClient, PermissionKeys.MANAGE_ADDON_GROUPS, SubPermissionKeys.EDIT)
							// ) {
							onEditClick(role)
							setRoleSideBar(true)
							// }
						}}
					/>
				</MlxTooltip>
			</span>
		)
	}

	const itemTemplate = (role: JobRole) => {
		return (
			<Card className="data-view-card mt-2 d-flex flex-column justify-content-even">
				<div className="d-flex flex-row justify-content-between align-items-center">
					<p className="m-0">{toPascalCase(role.jobRoleName)}</p>
					<p className="m-0">{toPascalCase(role?.jobRoleDepartment?.departmentName ?? '')}</p>
				</div>
				<div className="view-footer mt-2 pt-3 d-flex flex-row justify-content-between align-items-center">
					<div className="d-flex justify-content-even align-items-center">
						{editButtonTemplateForRoleGroup(role)}
						{deleteButtonTemplateForRoleGroup(role)}
					</div>
				</div>
			</Card>
		)
	}

	// Translate on load and language switch
	useEffect(() => {
		const fetchTranslation = async () => {
			try {
				const translations = await Promise.all([
					jTranslationText({ text: 'Search', typeCase: 'pascal', translationContext }),
					jTranslationText({ text: 'Showing 1 to 2 of 3 items', typeCase: 'pascal', translationContext }),
					jTranslationText({ text: 'No roles found.', typeCase: 'pascal', translationContext }),
					jTranslationText({ text: 'Edit', typeCase: 'pascal', translationContext }),
					jTranslationText({ text: 'Delete', typeCase: 'pascal', translationContext }),
				])

				setTranslatedText({
					search: translations[0] ?? 'Search',
					paginationTemplate:
						translations[1]
							?.replace('1', '{first}')
							?.replace('2', '{last}')
							?.replace('3', '{totalRecords}') ?? 'Showing {first} to {last} of {totalRecords} items',
					emptyMessage: translations[2] ?? 'No roles found.',
					edit: translations[3] ?? 'Edit',
					delete: translations[4] ?? 'Delete',
				})
			} catch {
				setTranslatedText({
					search: 'Search',
					paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
					emptyMessage: 'No roles found.',
					edit: 'Edit',
					delete: 'Delete',
				})
			}
		}
		fetchTranslation()
	}, [targetLanguage])

	useEffect(() => {
		roleListData.refetch()
		areaListData.refetch()
	}, [refetchApi])

	return (
		<>
			<WarningModal
				show={showWarning}
				title={warningMessage}
				onHide={() => {
					setWarning(false)
					setDeleteRoleData(null)
				}}
				callback={() => {
					deleteRoleApiCall()
					setWarning(false)
				}}
			/>

			<ToastAlert
				data-testid="toast"
				message={toastMessage.message}
				onClose={() => setToastMessage(toastMessageInitialData)}
				show={toastMessage.show}
				variant={toastMessage.variant}
			/>

			<RoleFormSideBar editRole={editRoleData} areaList={areaList} handleClose={handleClose} setEditRoleData={setEditRoleData} />

			<div className="col-md-12 col-lg-12 mb-3 h-100 ">
				<div className="card h-100 ">
					<div className="card-header flex-grow-0">
						<div className="d-flex  align-items-center">
							<div className="flex-grow-1">
								{showTitle && (
									<h4 className="mb-0 title">
										<JTranslation typeCase="pascal" text={'Job Roles'} />
									</h4>
								)}
							</div>
							<div className="flex-grow-1 d-flex justify-content-end ">
								<div className="flex-grow-0 me-2 ms-1">
									<button
										className="btn btn-custom-primary d-flex icon-only-btn"
										type="button"
										data-testid="add-ingredients-btn"
										onClick={() => setRoleSideBar(true)}
									>
										<i className="ri-add-circle-line me-1"></i>
										<span className="btn-text">
											<JTranslation typeCase="pascal" text={'Add Role'} />
										</span>
									</button>
								</div>
								<div className={`form-group has-search ${!showTitle ? '' : 'search-small-box'}`}>
									<span className="fa fa-search form-control-feedback">
										<FaSearch />{' '}
									</span>
									<DebounceInput
										onFocusCapture={() => {
											breakPoint >= width && setShowTitle(false)
										}}
										onBlurCapture={(event: any) => {
											if (breakPoint >= width && event.target.value.length === 0) {
												setShowTitle(true)
											}
										}}
										autoComplete="off"
										className="form-control "
										placeholder={translationText.search}
										minLength={0}
										debounceTimeout={500}
										data-testid="search"
										onChange={(e) => {
											onSearchTrigger(e.target.value)
										}}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="card-body flex-grow-1 overflow-hidden">
						<div className="survey-section h-100">
							<div className="row mb-5 h-100">
								<div className="col-lg-12" style={{ height: '100%', overflow: 'auto' }}>
									{width <= breakPoint ? (
										<DataView
											value={roleList}
											layout={'list'}
											itemTemplate={itemTemplate}
											paginator
											paginatorClassName="p-dataview-customers"
											dataKey="id"
											emptyMessage={translationText.emptyMessage}
											currentPageReportTemplate={translationText.paginationTemplate}
											rows={pagination.limit}
											totalRecords={pagination.total}
											lazy
											first={(pagination.currentPage - 1) * pagination.limit}
											onPage={(event) => {
												const selectedPage = event?.page ?? 0
												const selectedLimit = event?.rows ?? 10
												setPagination({
													...pagination,
													currentPage: selectedPage + 1,
													limit: selectedLimit,
												} as SqlPagination)
												setRefetchApi((prev) => prev + 1)
											}}
										/>
									) : (
										<DataTable
											value={roleList}
											paginator
											className="p-datatable-customers"
											scrollable
											scrollHeight="flex"
											paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
											rowsPerPageOptions={[10, 25, 50]}
											dataKey="id"
											rowHover
											loading={areaListData?.isFetching || roleListData?.isFetching}
											responsiveLayout="scroll"
											emptyMessage={translationText.emptyMessage}
											currentPageReportTemplate={translationText.paginationTemplate}
											rows={pagination.limit}
											totalRecords={pagination.total}
											lazy
											first={(pagination.currentPage - 1) * pagination.limit}
											onPage={(event) => {
												const selectedPage = event?.page ?? 0
												const selectedLimit = event?.rows ?? 10
												setPagination({
													...pagination,
													currentPage: selectedPage + 1,
													limit: selectedLimit,
												} as SqlPagination)
												setRefetchApi((prev) => prev + 1)
											}}
										>
											<Column
												field="jobRoleName"
												header={<JTranslation typeCase="pascal" text={'Name'} />}
												className="word-break-grid"
												style={{ minWidth: '15rem', maxWidth: '20rem' }}
											/>
											<Column
												header={<JTranslation typeCase="pascal" text={'Area of operation'} />}
												className="word-break-grid"
												style={{ minWidth: '15rem', maxWidth: '20rem' }}
												body={(data) => {
													return <>{data?.jobRoleDepartment?.departmentName ?? ''}</>
												}}
											/>
											<Column
												field="edit"
												style={{ maxWidth: '1rem', minWidth: '1rem' }}
												bodyClassName="bg-sticky-color"
												body={editButtonTemplateForRoleGroup}
												alignFrozen="right"
												frozen={true}
											/>
											<Column
												field="delete"
												style={{ maxWidth: '1rem', minWidth: '1rem' }}
												bodyClassName="bg-sticky-color"
												body={deleteButtonTemplateForRoleGroup}
												alignFrozen="right"
												frozen={true}
											/>
										</DataTable>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default withSidebar(Roles)
