import { AlertVariant, HttpMethods } from '../constants/constants'
import { AxiosResponse } from 'axios'
import { getErrorMessage } from './utils'
import { getExtractedFiles, getFileTypes, approveExtractedFileResult, changeExtractedEntity } from '../constants/apiEndPoints'
import { Payload, ExtractedFileApiResponse, FileTypeOption, ThumpsUpOrDown, ChangeFileType } from '../constants/staticTypes'
import { UseMutationResult } from 'react-query'

// feedback loop for the accuracy of the system's file type recognition
export const approveExtractedResult = (
	verifyDocumentMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	data: ThumpsUpOrDown,
	onSuccess: (message: string) => void,
	onError: (res: string, variant: string) => void
) => {
	verifyDocumentMutation.mutate(
		{
			url: approveExtractedFileResult,
			method: HttpMethods.POST,
			data: {
				extractResultId: data.extractResultId,
				isExtractedTypeCorrect: data.isExtractedTypeCorrect,
			},
		},
		{
			onSuccess: (res) => onSuccess(res.data.message),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

// api call to change the extracted file type
export const changeExtractedFileType = (
	verifyDocumentMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	data: ChangeFileType,
	onSuccess: (message: string) => void,
	onError: (res: string, variant: string) => void
) => {
	verifyDocumentMutation.mutate(
		{
			url: changeExtractedEntity,
			method: HttpMethods.POST,
			data: {
				extractResultId: data.extractResultId,
				textractIdentifiedEntity: data.textractIdentifiedEntity,
			},
		},
		{
			onSuccess: (res) => onSuccess(res.data.message),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

// get all extracted files for user confirmation
export const getAllExtractedFiles = (
	verifyDocumentMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	urlParams: string,
	onSuccess: (data: ExtractedFileApiResponse) => void,
	onError: (res: string, variant: string) => void
) => {
	verifyDocumentMutation.mutate(
		{
			url: getExtractedFiles + urlParams,
			method: HttpMethods.GET,
		},
		{
			onSuccess: (res) => onSuccess(res.data.data),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

// get all extracted files for user confirmation
export const getAllFilesTypes = (
	verifyDocumentMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	onSuccess: (data: FileTypeOption[]) => void,
	onError: (res: string, variant: string) => void
) => {
	verifyDocumentMutation.mutate(
		{
			url: getFileTypes,
			method: HttpMethods.GET,
		},
		{
			onSuccess: (res) => onSuccess(res.data.data),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}
