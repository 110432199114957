import HeaderComponent from '../components/header/HeaderComponent';

const withPublicHeader = (WrappedComponent: Function) => {
    return (props: any) => {
        document.body.classList.remove('user-style');
        return (
            <>
                <div className="p-0">
                    <div id="GuestMain" className="h-100 d-flex flex-column vh-100 overflow-auto">
                        <HeaderComponent isAdmin={false} />
                        <WrappedComponent {...props} />
                    </div>
                </div>
            </>
        );
    };
};

export default withPublicHeader;