import { useState, useRef, useEffect, useContext } from 'react'
import { Button } from 'primereact/button'
import { extractCategoryInfo, filterSubCategoryByItemTag, getItemTags, getValueAfterHash } from '../../helpers/menuViewHelper'
import { FnBCtx } from '../../context/MoveItemCtxProvider'
import { JTranslation } from '../../helpers/jTranslate'
import { MenuFilter, Menu } from '../../constants/staticTypes'
import { NO_CATEGORY_FOUND, NO_TAG_FOUND, CATEGORY_FILTER, TAG_FILTER } from '../../constants/strings'
import { OverlayPanel } from 'primereact/overlaypanel'
import { routes } from '../../constants/routes'
import { SELECT_MENU_ITEM } from '../../constants/queryKeys'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { v4 as uuidv4 } from 'uuid'
import { useTrackEvent } from '../../hooks/useTrackEvent'

type Props = {
  type: MenuFilter
  selectedMenu: Menu | undefined
  setSelectedMenu: React.Dispatch<React.SetStateAction<any>>
}

export default function CardPopover({ type, selectedMenu, setSelectedMenu }: Props) {
  // context variables
  const { changeInSelectedMenu, selectedTag, setSelectedTag, selectedMenuCopy, setSelectedMenuCopy } = useContext(FnBCtx)
  const { trackEvent } = useTrackEvent()
  // local variables
  const opRef = useRef(null)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const categoryNames = selectedMenu ? extractCategoryInfo(selectedMenu) : null
  // state variables
  const [buttonState, setButtonState] = useState('filter')
  const [hasScrollTopClass, setHasScrollTopClass] = useState(false)
  const [showDiv, setShowDiv] = useState(false)

  function getValidTagNames(): string[] {
    if (selectedMenu) {
      return getItemTags(selectedMenu)
    } else {
      return []
    }
  }

  // Usage
  const tagNames = getValidTagNames()

  useEffect(() => {
    // on unmount clean-up selected tags and restore SelectedMenu from local storage
    return () => {
      if (selectedTag.length) {
        const tempMenu = queryClient.getQueryData(SELECT_MENU_ITEM) as Menu
        setSelectedMenu(tempMenu)
        // setSelectedTag([])
      }
    }
  }, [changeInSelectedMenu]) // eslint-disable-line

  const resetSelectedMenu = () => {
    setSelectedTag([]) // remove selected tag
    setSelectedMenu(selectedMenuCopy)
  }
  const hidePopOverlay = async () => {
    // @ts-ignore
    opRef?.current?.hide() // hide tags
    await handleOverlayHide()
  }

  const closePopUp = (
    e:
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setTimeout(() => {
      handleButtonClick(e)
      navigate(routes.menu_view)
    }, 0)
  }

  const toggleDiv = (value: any = null) => {
    removeAllDvs()
    const toggleValue = value ?? !showDiv
    setShowDiv(toggleValue)

    if (toggleValue) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  }

  const handleButtonClick = (e: any) => {
    removeAllDvs()
    const newButtonState = buttonState === 'filter' ? 'cancel' : 'filter'
    setButtonState(newButtonState)
    // @ts-ignore
    opRef?.current?.toggle(e)
    toggleDiv(newButtonState === 'cancel')
  }

  const handleOverlayHide = () => {
    removeAllDvs()
    setButtonState('filter')
    toggleDiv(false)
  }

  useEffect(() => {
    removeAllDvs()
    renderCustomDiv()
  }, [showDiv]) // eslint-disable-line

  const removeAllDvs = () => {
    const customDivs = document.querySelectorAll('.custom-overlay')
    customDivs?.forEach((div) => {
      div.remove()
    })
  }

  const renderCustomDiv = () => {
    const customDiv = document.querySelector('.custom-overlay')
    if (showDiv) {
      if (!customDiv) {
        const newCustomDiv = document.createElement('div')
        newCustomDiv.className = 'custom-overlay'
        document.body.appendChild(newCustomDiv)
      }
    } else {
      if (customDiv) {
        customDiv.remove()
      }
    }
  }

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const hasClass = !!document.querySelector('.p-scrolltop-enter-done')
      setHasScrollTopClass(hasClass)
    })

    observer.observe(document.documentElement, {
      childList: true,
      subtree: true,
      attributes: true,
      attributeFilter: ['class'],
    })

    return () => {
      observer.disconnect()
    }
  }, [])

  const handleJump = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()

    const targetId = event.currentTarget.getAttribute('href')?.substring(1)
    const targetElement = document.getElementById(targetId!)
    const divElement = document.querySelector('.scroll-menu-container')

    // image-menu-sticky
    if (targetElement) {
      const extraOffset = 80
      const divRect = divElement?.getBoundingClientRect()
      const divHeight = (divRect?.height || 100) - extraOffset
      const topOffset = targetElement.offsetTop + divHeight
      window.scrollTo({
        top: topOffset,
        behavior: 'smooth',
      })
    }
  }

  const scrollToCategory = (currentCategoryIndex: string) => {
		const element = document.querySelector(`[data-scrollid="${currentCategoryIndex}"]`)
		if (element !== null) {
			setTimeout(() => {
				element.scrollIntoView({ behavior: 'smooth', block: 'center' })
			}, 100)
		}
  }

  return (
    <div className={`${hasScrollTopClass ? 'bottom-65' : ''}`}>
      {type === 'TAG' ? (
        <>
          {/* <Badge severity='danger' className='beat-effect'></Badge> */}
          <Button
            type='button'
            title={TAG_FILTER}
            className={`rounded-circle tags-filter-btn ${selectedTag?.length > 0 ? 'glow-effect' : ''
              }`}
            icon={buttonState === 'filter' ? 'pi pi-bookmark' : 'pi pi-times'}
            aria-label={buttonState === 'filter' ? 'Filter' : 'Cancel'}
            onClick={handleButtonClick}
            data-testid='tag-filter-btn'
          />
        </>
      ) : (
        ''
      )}
      {type === 'CATEGORY' ? (
        <Button
          type='button'
          title={CATEGORY_FILTER}
          className='rounded-circle category-filter-btn'
          icon={buttonState === 'filter' ? 'pi  pi-filter' : 'pi pi-times'}
          aria-label={buttonState === 'filter' ? 'Filter' : 'Cancel'}
          onClick={handleButtonClick}
          data-testid='category-filter-btn'
        />
      ) : (
        ''
      )}

      <OverlayPanel
        ref={opRef}
        onHide={handleOverlayHide}
        className='popover_wrapper'
        pt={{
          root: {
            style: {
              marginTop: '-27px',
              marginLeft: '3px'
            }
          }
        }}
      >
        <div className='d-flex justify-content-between align-items-center mb-2'>
          <h6 className='fw-bold mb-0'>
            <JTranslation text={type} />
          </h6>

          {type === 'TAG' && selectedTag?.length > 0 && (
            <Button
              className='btn p-button-sm  btn-custom-primary-outline justify-content-center'
              onClick={async (e) => {
                await hidePopOverlay()
                resetSelectedMenu()
              }}
              data-testid="clear-tag"
            >
              <JTranslation text='Clear' />
            </Button>
          )}
        </div>
        <ul>
          {/* CATEGORY */}
          {type === 'CATEGORY' &&
            categoryNames?.map((item) => (
              <li key={uuidv4()} className='hover-bg'>
                <span>
                  <a
                    href={`#${getValueAfterHash(item.id)}`}
                    data-testid={"category#" + item.id}
                    onClick={(e) => {
                      scrollToCategory(item?.urlSlug)
                      closePopUp(e)
                      handleJump(e)
                      // Google Analytics
                      trackEvent({
                        eventName: "filter_menu",
                        value: {
                          filter_type: "category",
                          category_name: item.categoryName,
                        },
                      });
                    }}
                  >
                    <JTranslation text={item.categoryName} />
                    {/* {item.categoryName} */}
                  </a>
                </span>
              </li>
            ))}
          {/* BLANK STATE TEXT */}
          {type === 'CATEGORY' && categoryNames?.length === 0 ? (
            <p>
              <JTranslation text={NO_CATEGORY_FOUND} />
            </p>
          ) : null}

          {/* TAG */}
          {type === 'TAG' &&
            tagNames?.map((item) => (
              <li key={uuidv4()} className='hover-bg'>
                {/* eslint-disable-next-line */}
                <a
                  href='#'
                  data-testid={"tag#" + item}
                  onClick={(e) => {
                    const filteredStructure = filterSubCategoryByItemTag({
                      dataStructure: selectedMenu,
                      selectedTags: [item],
                    })
                    setSelectedMenu(filteredStructure)
                    if (!selectedTag.length) {
                      setSelectedMenuCopy(selectedMenu)
                    }
                    setSelectedTag([item])
                    closePopUp(e)
                    // Google Analytics
                    trackEvent({
                      eventName: "filter_menu",
                      value: {
                        filter_type: "tags",
                        tag_name: [item],
                      },
                    });
                  }}
                >
                  <span>
                    {selectedTag.includes(item) ? (
                      <strong className='color-primary-custom'>
                        <JTranslation text={item} />
                        {/* {item} */}
                      </strong>
                    ) : (
                      //  item
                      <JTranslation text={item} />
                    )}
                  </span>
                </a>
              </li>
            ))}
          {/* BLANK STATE TEXT */}
          {type === 'TAG' && tagNames?.length === 0 ? (
            <p>
              {' '}
              <JTranslation text={NO_TAG_FOUND} />
            </p>
          ) : null}
        </ul>
      </OverlayPanel>
    </div >
  )
}
