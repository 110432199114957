import React, { useState, useContext, useEffect } from 'react';
import 'react-phone-number-input/style.css';
import { AlertVariant, FormClass, PhoneNumberFormClass, TENANT_ID_HEADER } from "../../constants/constants";
import { Avatar } from 'primereact/avatar';
import { CommonCtx } from '../../context/CommonCtxProvider';
import { FaSms } from 'react-icons/fa'
import { getErrorMessage, isStrongPassword, popover } from "../../helpers/utils";
import { getUserRoles, transformRoleToGroupOptions } from "../../helpers/userRolesHelper";
import { GrMail } from "react-icons/gr";
import { copyUserToCurrentTenant, countries, getTenantUser, initialFormData, initialFormValidationData, isFormDisabled, uploadFile, upsertUsers } from "../../helpers/manageUsersHelper";
import { MultiSelect } from 'primereact/multiselect';
import { getTenantInfo, resendPasswordEmail } from '../../helpers/authHelper';
import { useQueryClient } from "react-query";
import { Users, UserFormData, JobRole, RolePermissions, CopyUserFormData } from "../../constants/staticTypes";
import { PERMISSION_ROLE_LIST, USERS_LIST, USER_ROLES } from "../../constants/queryKeys";
import * as EmailValidator from 'email-validator';
import CommonModal from '../common_modal/CommonModal';
import DatePickerComponent from "../date_picker/DatePickerComponent";
import Offcanvas from 'react-bootstrap/Offcanvas';
import PasswordChecklist from "react-password-checklist";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import placeholder from "../../assets/images/placeholder-avatar.jpg";
import ToastAlert from "../../components/alert/ToastAlert";
import useMutationHook from "../../hooks/useMutationHook";
import useQueryHook from "../../hooks/useQueryHook";
import {
    ADD_NEW_USER,
    CANCEL,
    DO_YOU_WANT_TO_PROCEED,
    EDIT_USER,
    EMAIL_ERROR,
    EMAIL_TEXT,
    FIRST_NAME,
    FULL_NAME_ERROR,
    HELP_USER_PHOTO,
    HIRE_DATE,
    LAST_NAME,
    MOBILE_ERROR,
    MOBILE_TEXT,
    PASSWORD_LESS_LOGIN_WARNING_1,
    PASSWORD_NOTIFICATION,
    PASSWORD_TEXT,
    POS_ERROR,
    POS_NUMBER,
    DISPLAY_NAME, ROLE_NAME,
    SAVE,
    SEND_PASSWORD_CREATION_LINK_HELP,
    SEND_PASSWORD_CREATION_LINK,
    SEND_PASSWORD_LINK,
    SEND_PASSWORD_VIA_EMAIL, UPDATE,
    UPLOAD_PHOTO_TYPE_ERROR,
    USER_ADD_ERROR,
    USER_ADD_SUCCESS,
    USERNAME_ERROR,
    USER_PHOTO,
    USER_UPDATE_ERROR,
    USER_UPDATE_SUCCESS,
    USER_UPLOAD_PHOTO_ERROR,
    USER_UPLOAD_SUCCESS,
    USERNAME,
    HELP_USERNAME,
    PREFERRED_LANGUAGE,
    PERMISSION_ERROR
} from '../../constants/strings';
import { JTranslation, TranslationContext, jTranslationText } from '../../helpers/jTranslate';
import { useTranslation } from '../../hooks/useTranslation';
import { Dropdown } from 'primereact/dropdown';
import MlxPopover from '../common/MlxPopover';
import { getPermissionRoles } from '../../helpers/permissionHelper';
import './manage_users.scss';
import { useDebounce } from 'react-use';
import { AxiosResponse } from 'axios';
import { Divider } from 'primereact/divider';
import { BlockUI } from 'primereact/blockui';
import { Badge } from 'primereact/badge';

type Props = {
    showToast: (message: string, variant: string) => void,
    editUser: Users | undefined,
    setEditUser: React.Dispatch<React.SetStateAction<Users | undefined>>,
    handleClose: () => void,
}

const AddUserSideBar = ({ showToast, editUser, setEditUser, handleClose }: Props) => {
    // CONTEXT VARIABLE
    const { showSideBar } = useContext(CommonCtx)
    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext    
    const currentTenantId = localStorage.getItem(TENANT_ID_HEADER)
    const associatedTenants = editUser?.activeTenants 
        ? editUser?.activeTenants?.map(tenant => tenant.tenantName) as string[] 
        : [] as string[]

    // LOCAL VARIABLES
    const queryClient = useQueryClient();
    const uploadFileMutation = useMutationHook(queryClient, true); // upload file mutation
    const upsertUserMutation = useMutationHook(queryClient, true); // add or update user mutation
    const copyUserMutation = useMutationHook(queryClient, true); // copy user mutation
    const resendPasswordMutation = useMutationHook(queryClient, true); // resend password mutation
    const resendSmsMutation = useMutationHook(queryClient, true) // resend password mutation
    const [formData, setFormData] = useState<UserFormData>(initialFormData);
    const [rolesList, setRolesList] = useState<JobRole[] | []>(); // roles list
    const [groupedRoleList, setGroupedRoleList] = useState<any[]>([])
    const [staffPermissionRolesList, setstaffPermissionRolesList] = useState<RolePermissions[] | []>(); // roles list
    const [image, setImage] = useState(""); // local image
    const [passwordLessWarning, setPasswordLessWarning] = useState(false);
    const [passwordLessWarningApproved, setPasswordLessWarningApproved] = useState(false);
    const [passwordMessages, setPasswordMessages] = useState({});
    const [checkingOtherUsers, setCheckingOtherUsers] = useState(false);
    const [otherTenantUser, setOtherTenantUser] = useState<Users>();
    const [otherTenantUserConfirmPopup, setOtherTenantUserConfirmPopup] = useState<Users>();

    // FORM VALIDATIONS
    const [formValidations, setFormValidations] = useState(initialFormValidationData);
    const [showPassword, setViewPassword] = useState(false);
    const [passwordEmailButton, setPasswordEmailButton] = useState(false);
    const [passwordSmsButton, setPasswordSmsButton] = useState(false)
    const [iteration, setIteration] = useState(0)

    // ALERT VARIABLES
    const [alertVariant, setVariant] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [showAlert, setShowAlert] = useState(false)

    const onResendEmailSuccess = () => {
        setPasswordEmailButton(true)
        setVariant(AlertVariant.SUCCESS)
        setErrorMessage("Email sent successfully");
        setShowAlert(true);
    }

    const onResendEmailError = () => {
        setPasswordEmailButton(false)
        setVariant(AlertVariant.ERROR)
        setErrorMessage("Unable to send email, Please try again")
        setShowAlert(true);
    }
    const onResendSmsSuccess = () => {
        setPasswordSmsButton(true)
        setVariant(AlertVariant.SUCCESS)
        setErrorMessage("SMS sent successfully");
        setShowAlert(true);
    }

    const onResendSmsError = () => {
        setPasswordSmsButton(false)
        setVariant(AlertVariant.ERROR)
        setErrorMessage("Unable to send sms, Please try again")
        setShowAlert(true);
    }

    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({
                        text: 'Password has at least 6 characters.',
                        typeCase: 'capitalize',
                        translationContext,
                    }),
                    jTranslationText({
                        text: 'Password has special characters.',
                        typeCase: 'capitalize',
                        translationContext,
                    }),
                    jTranslationText({ text: 'Password has a number.', typeCase: 'capitalize', translationContext }),
                    jTranslationText({
                        text: 'Password has a capital letter.',
                        typeCase: 'capitalize',
                        translationContext,
                    }),
                ])
                const passwordMessages = {
                    minLength: translations[0],
                    specialChar: translations[1],
                    number: translations[2],
                    capital: translations[3],
                }
                setPasswordMessages(passwordMessages)
            } catch {
                const passwordMessages = {
                    minLength: 'Password has at least 6 characters.',
                    specialChar: 'Password has special characters.',
                    number: 'Password has a number.',
                    capital: 'Password has a capital letter.',
                }
                setPasswordMessages(passwordMessages)
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    useEffect(() => {
        if (editUser) {
            setValidationsTrue();
            const jobRolesData = editUser.jobRoles.map(role => role?.jobRoleId) as string[]
            const staffPermissionRolesData = editUser.staffPermissionRoles.map(permission => permission?.permissionRoleId) as string[]
            setFormData({
                email: editUser?.email !== null ? editUser?.email : "",
                photoKey: editUser.photoKey !== null ? editUser.photoKey : "",
                preferredName: editUser.preferredName,
                firstName: editUser.firstName,
                lastName: editUser.lastName,
                hireDate: editUser.hireDate !== null && editUser.hireDate?.trim().length > 0 ? new Date(editUser.hireDate) : null,
                phoneNumber: editUser.phoneNumber !== undefined ? editUser.phoneNumber : undefined,
                posNumber: editUser.posNumber,
                loginUserName: editUser.loginUserName,
                password: PASSWORD_TEXT,
                jobRoles: jobRolesData,
                passwordLessLoginOnly: editUser.passwordLessLoginOnly,
                sendPasswordCreation: editUser.sendPasswordCreation,
                sendPasswordCreationTo: "",
                staffId: editUser.id,
                preferredLangKey: editUser.preferredLangKey,
                staffPermissionRoles: staffPermissionRolesData
            });
            setImage(editUser.signedUrl !== undefined ? editUser.signedUrl : "");
            setViewPassword(false);
        }

        return () => {
            setPasswordEmailButton(false)
            setPasswordSmsButton(false)
        }
    }, [editUser])

    // react query to fetch roles
    useQueryHook(USER_ROLES, getUserRoles, (res) => setRolesList(res.data?.data.lists));

    // react query to fetch roles
    useQueryHook(
        PERMISSION_ROLE_LIST,
        getPermissionRoles, (res) => setstaffPermissionRolesList(res.data?.data.lists as RolePermissions[])
    );

    // on file upload success
    const onUploadSuccess = (key: string, imageUrl: string) => {
        showToast(USER_UPLOAD_SUCCESS, AlertVariant.SUCCESS);
        setFormData({ ...formData, photoKey: key });
        setImage(imageUrl);
    }

    // on file upload error
    const onUploadError = () => showToast(USER_UPLOAD_PHOTO_ERROR, "danger");

    // on add or update user success
    const onAddUpdateUserSuccess = () => {
        setOtherTenantUser(undefined)
        setOtherTenantUserConfirmPopup(undefined)
        showToast(editUser ? USER_UPDATE_SUCCESS : USER_ADD_SUCCESS, "success");
        handleClose();
        onClear();
        setPasswordLessWarning(false)
        queryClient.refetchQueries([USERS_LIST]); // nosonar
    };

    // on add or update user error
    const onAddUpdateUserError = (res: unknown) => {
        try {
            showToast(getErrorMessage(res), AlertVariant.ERROR);
        } catch (e) {
            showToast(editUser ? USER_UPDATE_ERROR : USER_ADD_ERROR, AlertVariant.ERROR);
        }
        setPasswordLessWarning(false)
    }

    // set validations true
    const setValidationsTrue = () => setFormValidations(initialFormValidationData)

    // clear data
    const onClear = () => {
        setFormData(initialFormData);
        setImage("");
        setEditUser(undefined);
        setValidationsTrue()
    }

    const handlePasswordValidation = () => {
        if (!formData.password || formData.sendPasswordCreation) {
            return FormClass.VALID
        }

        if (isStrongPassword(formData.password) || editUser !== undefined) {
            return FormClass.VALID
        } else {
            return FormClass.INVALID;
        }
    }

    const handleEmailValidation = () => {
        if (formData.email?.trim().length > 0 && EmailValidator.validate(formData.email)) {
            return FormClass.VALID
        }
        if (formData.sendPasswordCreationTo === "email") {
            return FormClass.INVALID
        } else {
            return FormClass.VALID
        }
    }

    const validateForm = () => {
        let emailValid = handleEmailValidation();
        let preferredName = formData.preferredName?.trim().length > 0 ? FormClass.VALID : FormClass.INVALID;
        let firstName = formData.firstName?.trim().length > 0 ? FormClass.VALID : FormClass.INVALID;
        let lastName = formData.lastName?.trim().length > 0 ? FormClass.VALID : FormClass.INVALID;
        let loginUserName = formData.loginUserName?.trim().length > 2 && (EmailValidator.validate(formData.loginUserName) || /^[A-Za-z0-9_-]*$/.test(formData.loginUserName)) ? FormClass.VALID : FormClass.INVALID;
        let passwordValid = handlePasswordValidation();
        let posValid = !EmailValidator.validate(formData.posNumber) ? FormClass.VALID : FormClass.INVALID;
        let phoneValid = isValidPhoneNumber(String(formData.phoneNumber)) ? PhoneNumberFormClass.VALID : PhoneNumberFormClass.INVALID;
        let preferredLangKey = formData.preferredLangKey?.trim().length > 0 ? "" : " is-invalid-custom";
        let staffPermissionRolesValid = formData.staffPermissionRoles.length > 0 ? "" : " is-invalid-custom";

        setFormValidations({
            email: emailValid,
            preferredName: preferredName,
            firstName: firstName,
            lastName: lastName,
            loginUserName: loginUserName,
            password: passwordValid,
            posNumber: posValid,
            phoneNumber: phoneValid,
            preferredLangKey,
            staffPermissionRoles: staffPermissionRolesValid,
        });
    }

    // effect to re-run field validation on text change
    useEffect(() => {
        if (iteration) {
            validateForm()
        }
        setIteration(iteration + 1)
    }, [
        formData.sendPasswordCreation,
        formData.sendPasswordCreationTo,
        formData.jobRoles,
        formData.phoneNumber,
        formData.preferredLangKey,
        formData.staffPermissionRoles
    ])

    // effect to submit form once the password approval warning is accepted
    useEffect(() => {
        if (passwordLessWarningApproved) onFormSubmit()

        return () => {
            setPasswordLessWarningApproved(false)
        }
    }, [passwordLessWarningApproved])

    // click add or update user
    const onFormSubmit = () => {
        // form validations
        let emailValid = handleEmailValidation();
        let preferredName = formData.preferredName?.trim().length > 0 ? FormClass.VALID : FormClass.INVALID;
        let firstName = formData.firstName?.trim().length > 0 ? FormClass.VALID : FormClass.INVALID;
        let lastName = formData.lastName?.trim().length > 0 ? FormClass.VALID : FormClass.INVALID;
        let loginUserName = formData.loginUserName?.trim().length > 2 && (EmailValidator.validate(formData.loginUserName) || /^[A-Za-z0-9_-]*$/.test(formData.loginUserName)) ? FormClass.VALID : FormClass.INVALID;
        let passwordValid = handlePasswordValidation();
        let posValid = !EmailValidator.validate(formData.posNumber) ? FormClass.VALID : FormClass.INVALID;
        let phoneValid = isValidPhoneNumber(String(formData.phoneNumber)) ? PhoneNumberFormClass.VALID : PhoneNumberFormClass.INVALID;
        let date = formData.hireDate !== null ? formData.hireDate?.toDateString() : "";
        let preferredLangKeyValid = formData.preferredLangKey?.trim().length > 0 ? "" : " is-invalid-custom";
        let staffPermissionRolesValid = formData.staffPermissionRoles.length > 0 ? "" : " is-invalid-custom";

        // inform user that they are creating a user with password
        if (emailValid === FormClass.VALID &&
            passwordValid === FormClass.VALID &&
            posValid === FormClass.VALID &&
            staffPermissionRolesValid === "" &&
            firstName === FormClass.VALID &&
            lastName === FormClass.VALID &&
            loginUserName === FormClass.VALID &&
            preferredName === FormClass.VALID &&
            phoneValid === PhoneNumberFormClass.VALID &&
            preferredLangKeyValid === "" &&
            formData.passwordLessLoginOnly &&
            !editUser
        ) {
            setPasswordLessWarning(true)
        }

        if (emailValid === FormClass.VALID &&
            passwordValid === FormClass.VALID &&
            posValid === FormClass.VALID &&
            staffPermissionRolesValid === "" &&
            firstName === FormClass.VALID &&
            lastName === FormClass.VALID &&
            loginUserName === FormClass.VALID &&
            phoneValid === PhoneNumberFormClass.VALID &&
            (!editUser && formData.passwordLessLoginOnly ? passwordLessWarningApproved : true) &&
            preferredLangKeyValid === ""
        ) {
            let data = {
                ...formData,
                hireDate: date,
            }

            upsertUsers(data, upsertUserMutation, onAddUpdateUserSuccess, onAddUpdateUserError, editUser?.id);
        } else {
            setFormValidations({
                email: emailValid,
                preferredName: preferredName,
                firstName: firstName,
                lastName: lastName,
                loginUserName: loginUserName,
                password: passwordValid,
                posNumber: posValid,
                phoneNumber: phoneValid,
                preferredLangKey: preferredLangKeyValid,
                staffPermissionRoles: staffPermissionRolesValid
            });
        }
    }

    // Copy user
    const onCopyUserSubmit = () => {
        // form validations
        const posValid = !EmailValidator.validate(formData.posNumber) ? FormClass.VALID : FormClass.INVALID;
        const date = formData.hireDate !== null ? formData.hireDate?.toDateString() : "";
        const preferredLangKeyValid = formData.preferredLangKey?.trim().length > 0 ? "" : " is-invalid-custom";
        const staffPermissionRolesValid = formData.staffPermissionRoles.length > 0 ? "" : " is-invalid-custom";

        // inform user that they are creating a user with password
        if (posValid === FormClass.VALID &&
            staffPermissionRolesValid === "" &&
            preferredLangKeyValid === "" &&
            formData.passwordLessLoginOnly &&
            !editUser
        ) {
            setPasswordLessWarning(true)
        }

        if (posValid === FormClass.VALID &&
            staffPermissionRolesValid === "" &&
            (!editUser && formData.passwordLessLoginOnly ? passwordLessWarningApproved : true) &&
            preferredLangKeyValid === ""
        ) {
            const data = {
                ...formData,
                hireDate: date,
            }

            const copyUserFormData: CopyUserFormData = {
                staffId: data.staffId,
                hireDate: data.hireDate,
                jobRoles: data.jobRoles,
                newTenantId: currentTenantId as string,
                posNumber: data.posNumber,
                staffPermissionRoles: data.staffPermissionRoles
            }
            copyUserToCurrentTenant(copyUserFormData, copyUserMutation, onAddUpdateUserSuccess, onAddUpdateUserError);
        } else {
            setFormValidations({
                ...formValidations,
                posNumber: posValid,
                preferredLangKey: preferredLangKeyValid,
                staffPermissionRoles: staffPermissionRolesValid
            });
        }
    }

    // item template for dropdown
    const itemTemplate = (option: JobRole) => {
        return <option key={option.id}>{option.jobRoleName}</option>;
    }

    const PasswordNotification = (<>
        <p> {PASSWORD_LESS_LOGIN_WARNING_1} </p>
        <p> {DO_YOU_WANT_TO_PROCEED} </p>
    </>)

    useEffect(() => {
        if(rolesList && rolesList?.length > 0) {
            const groupedList = transformRoleToGroupOptions(rolesList)
            setGroupedRoleList(groupedList)
        }
    }, [rolesList])
    
    const confirmCopyOtherTenantUser = (editUser: Users) => {
        setFormData({
            email: editUser?.email !== null ? editUser?.email : "",
            photoKey: editUser.photoKey !== null ? editUser.photoKey : "",
            preferredName: editUser.preferredName,
            firstName: editUser.firstName,
            lastName: editUser.lastName,
            hireDate: editUser.hireDate !== null && editUser.hireDate?.trim().length > 0 ? new Date(editUser.hireDate) : null,
            phoneNumber: editUser.phoneNumber !== undefined ? editUser.phoneNumber : undefined,
            posNumber: editUser.posNumber,
            loginUserName: editUser.loginUserName,
            password: '',
            jobRoles: [],
            passwordLessLoginOnly: editUser.passwordLessLoginOnly,
            sendPasswordCreation: editUser.sendPasswordCreation,
            sendPasswordCreationTo: "",
            staffId: editUser.id,
            preferredLangKey: editUser.preferredLangKey,
            staffPermissionRoles: []
        })
        setImage(editUser.signedUrl !== undefined ? editUser.signedUrl : "")
        setViewPassword(false)
        setOtherTenantUser(editUser)
        setOtherTenantUserConfirmPopup(undefined)
    }

    useDebounce(
        () => {
            if(editUser ?? otherTenantUser) return

            let checkUserPromises: Promise<AxiosResponse<any, any>>[] = [];
            if (formData?.email && EmailValidator.validate(formData?.email)) {
                checkUserPromises.push(getTenantUser('email', formData?.email))
            }

            if (formData?.phoneNumber) {
                checkUserPromises.push(getTenantUser('phone', String(formData?.phoneNumber)))
            }

            setCheckingOtherUsers(true)
            Promise.all(checkUserPromises)
                .then(([response1, response2]) => {
                    const emailCheckUsers = response1?.data?.data as [] ?? []
                    const phoneCheckUsers = response2?.data?.data as [] ?? []
                    const usersList = [...emailCheckUsers, ...phoneCheckUsers] as Users[]

                    const checkIfExistInCurrentTenant = usersList.find(user => user?.tenantId?.toLowerCase() === currentTenantId?.toLocaleLowerCase())

                    if(checkIfExistInCurrentTenant) {
                        setVariant(AlertVariant.ERROR)
                        setErrorMessage("User with same email or phone number already exist in current tenant");
                        setShowAlert(true)
                        setFormData({ ...formData, email: '', phoneNumber: '' })
                        return
                    }

                    if (usersList.length > 0) {
                        const editUser = usersList[0] as Users
                        setOtherTenantUserConfirmPopup(editUser)
                    }
                }).catch((error) => {
                    console.log('Failed to check other tenant users', error)
                }).finally(() => {                    
                    setCheckingOtherUsers(false)
                })
        },
        1200,
        [formData?.email, formData?.phoneNumber]
    )

    return (
        <Offcanvas
            show={showSideBar}
            onHide={() => {
                setFormData({ ...formData, email: '', phoneNumber: '' })
                setOtherTenantUserConfirmPopup(undefined)
                setOtherTenantUser(undefined)

                onClear()
                handleClose()
                setIteration(0)
            }}
            backdrop='static'
            // responsive='xl'
            placement='end'
            className='custom-offcanvas'
        >
            {/* TOAST MESSAGE COMPONENT */}
            <ToastAlert
                show={showAlert}
                onClose={setShowAlert}
                message={errorMessage}
                variant={alertVariant}
            />

            {/* PASSWORD LESS WARNING MODAL  */}
            <CommonModal
                show={passwordLessWarning}
                onHide={setPasswordLessWarning}
                title={PASSWORD_NOTIFICATION}
                modalContent={PasswordNotification}
                callback={() => {
                    setPasswordLessWarningApproved(true)
                    setPasswordLessWarning(false)
                }}
            />

            <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                    {editUser
                        ? <JTranslation typeCase="pascal" text={EDIT_USER} />
                        : otherTenantUser 
                            ? <JTranslation typeCase="pascal" text={'Copy User'} /> 
                            : <JTranslation typeCase="pascal" text={ADD_NEW_USER} />
                    }
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className='row mb-5'>

                    <div className='col-md-12 col-lg-12 mb-3'>
                        <div className='profile-bg col-md-12 d-flex justify-content-center'>
                            <div className='profile-container user-delete-profile'>
                                <Avatar
                                    className='mr-2 shadow-sm d-flex flex-grow-0 align-items-center justify-content-center user-delete'
                                    size='xlarge'
                                    shape='circle'
                                    image={
                                        image !== null && image?.trim().length > 0
                                            ? image
                                            : placeholder
                                    }
                                    imageAlt='user'
                                />

                                {image !== null && image?.trim().length > 0 && (
                                    <div
                                        className='delete-user-icon'
                                        data-testid="delete-profile-img"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setImage('')
                                            setFormData({ ...formData, photoKey: '' })
                                        }}
                                    >
                                        <i className='ri-delete-bin-6-line'></i>
                                    </div>
                                )}

                                <label htmlFor='file-upload' className='cam-icon'>
                                    <div className=' shadow-sm'>
                                        <i className='ri-pencil-line'></i>
                                        <input
                                            className='d-none'
                                            data-testid='upload'
                                            id='file-upload'
                                            type='file'
                                            accept='image/*'
                                            autoComplete='off'
                                            disabled={isFormDisabled(queryClient, editUser)}
                                            onChange={(e) => {
                                                // Trigger File Upload
                                                if (e.target.files && e.target.files?.length > 0) {
                                                    if (
                                                        e.target.files[0].type.indexOf('image') !== -1
                                                    ) {
                                                        uploadFile(
                                                            e.target.files[0],
                                                            uploadFileMutation,
                                                            onUploadSuccess,
                                                            onUploadError
                                                        )
                                                        e.target.value = ''
                                                    } else {
                                                        showToast(UPLOAD_PHOTO_TYPE_ERROR, 'danger')
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                </label>
                            </div>
                            <div className='profile-help'>
                                <MlxPopover data={{ title: USER_PHOTO, body: HELP_USER_PHOTO }} />
                            </div>
                        </div>
                        {/*  <Avatar image="images/avatar/amyelsner.png" className="mr-2" size="xlarge" shape="circle" /> */}
                    </div>

                    <div className='col-md-6 col-lg-6 mb-3'>
                        <label htmlFor='mobileNumber' className='form-label'>
                            <JTranslation typeCase="pascal" text={MOBILE_TEXT} />
                            <span className='mandatory'>*</span>
                        </label>
                        <div className='custom-input-spinner'>
                            <BlockUI className='user-block-ui' blocked={!!otherTenantUser}>
                                <PhoneInput
                                    placeholder={useTranslation("Enter phone number", "capitalize")}
                                    defaultCountry='US'
                                    value={formData.phoneNumber}
                                    className={formValidations.phoneNumber}
                                    data-testid="mobile"
                                    onChange={(value) => {
                                        setFormData({
                                            ...formData,
                                            phoneNumber: value,
                                        })

                                        if (formValidations.phoneNumber === PhoneNumberFormClass.INVALID) {
                                            const numString = String(formData?.phoneNumber);

                                            setFormValidations({
                                                ...formValidations,
                                                phoneNumber:
                                                    isValidPhoneNumber(numString)
                                                        ? PhoneNumberFormClass.VALID
                                                        : PhoneNumberFormClass.INVALID,
                                            })
                                        }
                                    }}
                                />
                            </BlockUI>
                            {checkingOtherUsers && (
                                <i className="pi pi-spin pi-spinner" style={{ fontSize: '1rem', color: '#fbaa07' }}></i>                                
                            )}
                        </div>
                        {formValidations.phoneNumber === PhoneNumberFormClass.INVALID && (
                            <div className='invalid-feedback' data-testid='error-mobile'>
                                <JTranslation typeCase="capitalize" text={MOBILE_ERROR} />
                            </div>
                        )}
                    </div>

                    <div className='col-md-6 col-lg-6 mb-3'>
                        <label htmlFor='emailId' className='form-label'>
                            <JTranslation typeCase="pascal" text={EMAIL_TEXT} />{' '}
                            {formData.sendPasswordCreationTo === 'email' ? (
                                <span className='mandatory'>*</span>
                            ) : (
                                ''
                            )}
                        </label>
                        <div className='custom-input-spinner'>
                            <BlockUI className='user-block-ui' blocked={!!otherTenantUser}>
                                <input
                                    type='text'
                                    data-testid='email'
                                    className={formValidations.email}
                                    id='emailId'
                                    value={formData.email}
                                    autoComplete='off'
                                    onChange={(e) => {
                                        setFormData({
                                            ...formData,
                                            email: e.target.value,
                                        })
                                        if (formValidations.email === FormClass.INVALID) {
                                            setFormValidations({
                                                ...formValidations,
                                                email:
                                                    EmailValidator.validate(e.target.value) ||
                                                        e.target.value?.trim().length === 0
                                                        ? FormClass.VALID
                                                        : FormClass.INVALID,
                                            })
                                        }
                                    }}
                                />
                            </BlockUI>
                            {checkingOtherUsers && (
                                <i className="pi pi-spin pi-spinner" style={{ fontSize: '1rem', color: '#fbaa07' }}></i>                                
                            )}
                        </div>

                        {formValidations.email === FormClass.INVALID && (
                            <div className='invalid-feedback' data-testid='error-email'>
                                <JTranslation typeCase="capitalize" text={EMAIL_ERROR} />
                            </div>
                        )}
                    </div>

                    {associatedTenants.length > 0 && (
                        <div className='col-md-6 col-lg-6 mb-3'>
                            <label htmlFor='mobileNumber' className='form-label'>
                                <JTranslation typeCase="pascal" text={'Associated With'} />
                            </label>
                            <div className="d-flex justify-content-start align-items-center">
                                {associatedTenants.map((tenant, index) => (
                                    <Badge key={index} className="me-2" value={tenant} severity="warning"></Badge>
                                ))}
                            </div>
                        </div>
                    )}

                    {otherTenantUserConfirmPopup && (
                        <div className="confirm-popup-other-tenant-user p-2 rounded bg-light">
                            <p>
                                <JTranslation typeCase="capitalize" text={`This user is associated with the tenant `} /><b>{otherTenantUserConfirmPopup?.tenantDetails?.tenantName}</b><JTranslation typeCase="capitalize" text={`. Would you like to copy this user?`} />
                            </p>
                            <div className="d-flex justify-content-end">
                                <button
                                    className='btn btn-custom-primary-outline'
                                    type='button'
                                    data-testid='clear-button'
                                    onClick={() => {
                                        setFormData({ ...formData, email: '', phoneNumber: '' })
                                        setOtherTenantUserConfirmPopup(undefined)
                                        setOtherTenantUser(undefined)
                                    }}
                                >
                                    <JTranslation typeCase="pascal" text={CANCEL} />
                                </button>

                                <button
                                    className='btn btn-custom-primary ms-2'
                                    type='button'
                                    data-testid='save-button'
                                    disabled={isFormDisabled(queryClient, editUser)}
                                    onClick={() => {
                                        confirmCopyOtherTenantUser(otherTenantUserConfirmPopup)
                                    }}
                                >
                                    <JTranslation typeCase="pascal" text={'Proceed'} />
                                </button>
                            </div>
                        </div>
                    )}
                    
                    <Divider type='dashed' />

                    <div className='col-md-6 col-lg-6 mb-3'>
                        <label htmlFor='validationCustom01' className='form-label'>
                            <JTranslation typeCase="pascal" text={DISPLAY_NAME} />
                            <span className='mandatory '>*</span>{' '}
                        </label>
                        <input
                            type='text'
                            className={formValidations.preferredName}
                            id='validationCustom01'
                            autoComplete='off'
                            data-testid='fullname'
                            maxLength={130}
                            value={formData.preferredName}
                            onChange={(e) => {
                                setFormData({ ...formData, preferredName: e.target.value })
                                if (formValidations.preferredName === FormClass.INVALID) {
                                    setFormValidations({
                                        ...formValidations,
                                        preferredName:
                                            e.target.value?.trim().length > 0
                                                ? FormClass.VALID
                                                : FormClass.INVALID,
                                    })
                                }
                            }}
                            disabled={!!otherTenantUser}
                        />

                        {formValidations.preferredName === FormClass.INVALID && (
                            <div className='invalid-feedback' data-testid='error-name'>
                                <JTranslation typeCase="capitalize" text={FULL_NAME_ERROR} />
                            </div>
                        )}
                    </div>

                    <div className='col-md-6 col-lg-6 mb-3'>
                        <label htmlFor='posNumber' className='form-label'>
                            <JTranslation typeCase="pascal" text={POS_NUMBER} />
                        </label>
                        <input
                            data-testid='pos'
                            type='text'
                            className={formValidations.posNumber}
                            id='posNumber'
                            value={formData.posNumber}
                            maxLength={30}
                            autoComplete='off'
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    posNumber: e.target.value,
                                })
                                if (formValidations.posNumber === FormClass.INVALID) {
                                    setFormValidations({
                                        ...formValidations,
                                        posNumber:
                                            !EmailValidator.validate(formData.posNumber)
                                                ? FormClass.VALID
                                                : FormClass.INVALID,
                                    })
                                }
                            }}
                        />
                        {formValidations.posNumber === FormClass.INVALID && (
                            <div className='invalid-feedback' data-testid='error-pos'>
                                <JTranslation typeCase="capitalize" text={POS_ERROR} />
                            </div>
                        )}
                    </div>

                    <div className='col-md-6 col-lg-6 mb-3'>
                        <label htmlFor='validationCustom02' className='form-label'>
                            <JTranslation typeCase="pascal" text={FIRST_NAME} />
                            <span className='mandatory '>*</span>{' '}
                        </label>
                        <input
                            type='text'
                            className={formValidations.firstName}
                            id='validationCustom02'
                            data-testid="firstname"
                            autoComplete='off'
                            maxLength={130}
                            value={formData.firstName}
                            onChange={(e) => {
                                setFormData({ ...formData, firstName: e.target.value })
                                if (formValidations.firstName === FormClass.INVALID) {
                                    setFormValidations({
                                        ...formValidations,
                                        firstName:
                                            e.target.value?.trim().length > 0
                                                ? FormClass.VALID
                                                : FormClass.INVALID,
                                    })
                                }
                            }}
                            disabled={!!otherTenantUser}
                        />

                        {formValidations.firstName === FormClass.INVALID && (
                            <div className='invalid-feedback' data-testid='error-name'>
                                <JTranslation typeCase="capitalize" text={FULL_NAME_ERROR} />
                            </div>
                        )}
                    </div>

                    <div className='col-md-6 col-lg-6 mb-3'>
                        <label htmlFor='validationCustom03' className='form-label'>
                            <JTranslation typeCase="pascal" text={LAST_NAME} />
                            <span className='mandatory '>*</span>{' '}
                        </label>
                        <input
                            type='text'
                            className={formValidations.lastName}
                            autoComplete='off'
                            id='validationCustom03'
                            data-testid="lastname"
                            maxLength={130}
                            value={formData.lastName}
                            onChange={(e) => {
                                setFormData({ ...formData, lastName: e.target.value })
                                if (formValidations.lastName === FormClass.INVALID) {
                                    setFormValidations({
                                        ...formValidations,
                                        lastName:
                                            e.target.value?.trim().length > 0
                                                ? FormClass.VALID
                                                : FormClass.INVALID,
                                    })
                                }
                            }}
                            disabled={!!otherTenantUser}
                        />

                        {formValidations.lastName === FormClass.INVALID && (
                            <div className='invalid-feedback' data-testid='error-name'>
                                <JTranslation typeCase="capitalize" text={FULL_NAME_ERROR} />
                            </div>
                        )}
                    </div>

                    <div className='col-md-6 col-lg-6 mb-3'>
                        <label htmlFor='rolesSelect' className='form-label'>
                            <JTranslation typeCase="pascal" text={ROLE_NAME} />
                            {/* <span className='mandatory'>*</span> */}
                        </label>
                        <MultiSelect
                            data-testid='roles-select'
                            disabled={isFormDisabled(queryClient, editUser)}
                            // appendTo={'self'}
                            value={formData.jobRoles}
                            options={groupedRoleList}
                            itemTemplate={itemTemplate}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    jobRoles: e.value,
                                })
                            }}
                            className={'custom-multiselect'}
                            optionLabel='jobRoleName'
                            placeholder={useTranslation('Select', 'pascal')}
                            showSelectAll={false}
                            maxSelectedLabels={3}
                            optionValue='id'
                            filter
                            optionGroupLabel="departmentName" 
                            optionGroupChildren="items" 
                            optionGroupTemplate={(option) => <>{option.departmentName}</>}
                        />

                        {/* {formValidations.roles === ' is-invalid-custom' && (
                            <div className='invalid-feedback' data-testid='error-roles'>
                                <JTranslation typeCase="capitalize" text={ROLE_ERROR} />
                            </div>
                        )} */}
                    </div>

                    <div className='col-md-6 col-lg-6 mb-3'>
                        <label htmlFor='rolesSelect' className='form-label'>
                            <JTranslation typeCase="pascal" text={'Permission Name'} />
                            <span className='mandatory'>*</span>
                        </label>
                        <Dropdown
                            className={formValidations.staffPermissionRoles + ' w-100 preferred-language-dropdown'}
                            data-testid='preferred-language'
                            value={formData.staffPermissionRoles[0]}
                            options={staffPermissionRolesList}
                            optionLabel="permissionRoleName"
                            optionValue='id'
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    staffPermissionRoles: [e.value],
                                })
                            }}
                            placeholder={useTranslation('Select', 'pascal')}
                        />

                        {formValidations.staffPermissionRoles === ' is-invalid-custom' && (
                            <div className='invalid-feedback' data-testid='error-roles'>
                                <JTranslation typeCase="capitalize" text={PERMISSION_ERROR} />
                            </div>
                        )}
                    </div>

                    <div className='col-md-6 col-lg-6 mb-3'>
                        <label htmlFor='preferredLangKey' className='form-label'>
                            <JTranslation typeCase="pascal" text={PREFERRED_LANGUAGE} />
                        </label>
                        <BlockUI className='user-block-ui rounded' blocked={!!otherTenantUser}>
                            <Dropdown
                                className={formValidations.preferredLangKey + ' w-100 preferred-language-dropdown'}
                                data-testid='preferred-language'
                                value={formData.preferredLangKey?.toLowerCase()}
                                options={countries}
                                optionLabel="langName"
                                optionValue='langKey'
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        preferredLangKey: e.value,
                                    })
                                }}
                                placeholder={useTranslation('Select', 'pascal')}
                            />
                        </BlockUI>

                        {formValidations.preferredLangKey === ' is-invalid-custom' && (
                            <div className='invalid-feedback' data-testid='error-roles'>
                                <JTranslation typeCase="capitalize" text={"Please choose a language"} />
                            </div>
                        )}
                    </div>

                    <div className='col-md-6 col-lg-6 mb-3 hire-date-input'>
                        <label htmlFor='hireDate' className='form-label'>
                            <JTranslation typeCase="pascal" text={HIRE_DATE} />
                        </label>
                        <DatePickerComponent
                            data-testid='date-picker'
                            selectedDate={formData.hireDate}
                            customInput={
                                <input type='text' maxLength={10} autoComplete='off' />
                            }
                            setDate={(value) => {
                                setFormData({
                                    ...formData,
                                    hireDate: value as Date,
                                })
                            }}
                        />
                    </div>

                    <div className='col-md-6 col-lg-6'>
                        <div className='row'>
                            <div className=' col-md-12  col-lg-12 mb-3'>
                                <div className="help-small align-items-center">
                                    <label htmlFor='username' className='form-label'>
                                        <JTranslation typeCase="pascal" text={USERNAME} />
                                        <span className='mandatory'>*</span>
                                    </label>
                                    <MlxPopover data={{ title: USERNAME, body: HELP_USERNAME }} />
                                </div>
                                <input
                                    data-testid='username'
                                    type='text'
                                    className={formValidations.loginUserName}
                                    id='username'
                                    value={formData.loginUserName}
                                    maxLength={30}
                                    autoComplete='off'
                                    onChange={(e) => {
                                        setFormData({
                                            ...formData,
                                            loginUserName: e.target.value.replace(/\s+/g, '').toLowerCase(),
                                        })
                                        if (formValidations.loginUserName === FormClass.INVALID) {
                                            setFormValidations({
                                                ...formValidations,
                                                loginUserName:
                                                    e.target.value?.trim().length > 0
                                                        ? FormClass.VALID
                                                        : FormClass.INVALID,
                                            })
                                        }
                                    }}
                                    disabled={!!otherTenantUser}
                                />
                                {formValidations.loginUserName === FormClass.INVALID && (
                                    <div className='invalid-feedback' data-testid='error-username'>
                                        <JTranslation typeCase="capitalize" text={USERNAME_ERROR} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {(editUser?.email || editUser?.phoneNumber) ? (
                        <div className='col-md-6 col-lg-6'>
                            <div className='row'>
                                <label>
                                    <JTranslation typeCase="capitalize" text={SEND_PASSWORD_LINK} />
                                </label>
                                {/* <div className='col-md-6 col-lg-6'>
                                    <button
                                        className='btn btn-custom-primary mt-2 w-100'
                                        type='button'
                                        disabled={
                                            passwordSmsButton ||
                                            editUser?.phoneNumber?.trim().length === 0
                                        }
                                        onClick={() => {
                                            const data = {
                                                // staffPhone: formData.phoneNumber,
                                                staffId: formData.staffId,
                                            }
                                            resendPasswordSms(
                                                resendSmsMutation,
                                                data,
                                                onResendSmsSuccess,
                                                onResendSmsError
                                            )
                                        }}
                                    >
                                        <FaSms /> SMS
                                    </button>
                                </div> */}
                                <div className='col-md-6 col-lg-6'>
                                    <button
                                        className='btn btn-custom-primary mt-2 w-100'
                                        type='button'
                                        disabled={
                                            passwordEmailButton ||
                                            !editUser?.email ||
                                            !EmailValidator.validate(editUser.email)
                                        }
                                        onClick={() => {
                                            const data = {
                                                // staffEmail: formData.email,
                                                staffId: formData.staffId,
                                            }
                                            resendPasswordEmail(
                                                resendPasswordMutation,
                                                data,
                                                onResendEmailSuccess,
                                                onResendEmailError
                                            )
                                        }}
                                    >
                                        <GrMail /> <JTranslation typeCase="pascal" text={"Email"} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : null}

                    <div
                        className={
                            editUser ? 'visibility-hidden' : ' col-md-6  col-lg-6 mb-3'
                        }
                    >
                        <div className='form-group input-group custom-input-group'>
                            <label
                                htmlFor='passwordInput'
                                className='form-label w-100 d-block'
                            >
                                <JTranslation typeCase="pascal" text={PASSWORD_TEXT} />
                            </label>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                className={formValidations.password}
                                id='passwordInput'
                                data-testid='password'
                                value={formData.password}
                                autoComplete='new-password'
                                disabled={
                                    editUser !== undefined ||
                                    isFormDisabled(queryClient, editUser) ||
                                    formData.sendPasswordCreation || 
                                    !!otherTenantUser
                                }
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        password: e.target.value,
                                        passwordLessLoginOnly: e.target.value ? false : true,
                                    })
                                    if (formValidations.password === FormClass.INVALID) {
                                        setFormValidations({
                                            ...formValidations,
                                            password:
                                                e.target.value?.trim().length > 0 &&
                                                    isStrongPassword(e.target.value)
                                                    ? FormClass.VALID
                                                    : FormClass.INVALID,
                                        })
                                    }
                                }}
                            />

                            <span
                                className='input-group-text'
                                data-testid='password-show'
                                onClick={() => {
                                    if (editUser === undefined) {
                                        setViewPassword(!showPassword)
                                    }
                                }}
                            >
                                <i
                                    className={showPassword ? 'ri-eye-line' : 'ri-eye-off-line'}
                                ></i>
                            </span>


                        </div>
                        {!formData.sendPasswordCreation && !otherTenantUser && (
                            <div className='p-2'>
                                <PasswordChecklist
                                    className='password-checklist'
                                    rules={['minLength', 'specialChar', 'number', 'capital']}
                                    minLength={6}
                                    value={formData.password}
                                    iconSize={14}
                                    messages={passwordMessages}
                                />
                            </div>
                        )}

                        {!otherTenantUser && (
                            <div className=' align-items-center mt-1'>
                                <div className='form-check d-flex align-items-center'>
                                    <input
                                        className='form-check-input me-2'
                                        id='sendPasswordCreation'
                                        data-testid="password-creation-link"
                                        type='checkbox'
                                        name='sendPasswordCreation'
                                        checked={formData.sendPasswordCreation}
                                        onChange={() => {
                                            setFormData({
                                                ...formData,
                                                sendPasswordCreation: !formData.sendPasswordCreation,
                                                passwordLessLoginOnly: formData.sendPasswordCreation,
                                                password: '',
                                            })
                                        }}
                                    />
                                    <label className='form-check-label d-flex align-items-center'>
                                        <JTranslation typeCase="capitalize" text={SEND_PASSWORD_CREATION_LINK} />
                                    </label>
                                    <div className='help-small'>
                                        <MlxPopover data={{ title: SEND_PASSWORD_CREATION_LINK, body: SEND_PASSWORD_CREATION_LINK_HELP }} />
                                    </div>
                                </div>
                            </div>
                        )}
                       

                        {formData.sendPasswordCreation && !otherTenantUser && (
                            <div className=' align-items-center mt-1'>
                                {/* <div className='col-12'>
                                    <div className='form-check d-flex align-items-center'>
                                        <input
                                            className='form-check-input me-2'
                                            id='sendPasswordCreation'
                                            type='radio'
                                            checked={formData.sendPasswordCreationTo === 'sms'}
                                            name='passwordCreationMode'
                                            onChange={() => {
                                                setFormData({
                                                    ...formData,
                                                    sendPasswordCreationTo: 'sms',
                                                })
                                            }}
                                        />
                                        <label className='form-check-label d-flex align-items-center'>
                                            {SEND_PASSWORD_VIA_SMS}
                                        </label>
                                    </div>
                                </div> */}

                                <div className='col-12'>
                                    <div className='form-check d-flex align-items-center'>
                                        <input
                                            className='form-check-input me-2'
                                            id='sendPasswordCreation'
                                            data-testid="password-via-email"
                                            type='radio'
                                            checked={formData.sendPasswordCreationTo === 'email'}
                                            name='passwordCreationMode'
                                            onChange={() => {
                                                setFormData({
                                                    ...formData,
                                                    sendPasswordCreationTo: 'email',
                                                })
                                            }}
                                        />
                                        <label className='form-check-label d-flex align-items-center'>
                                            <JTranslation typeCase="capitalize" text={SEND_PASSWORD_VIA_EMAIL} />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                </div>

                <div className='save-btn-section shadow save-btn-absolute'>
                    <button
                        className='btn btn-custom-primary-outline'
                        type='button'
                        data-testid='clear-button'
                        disabled={isFormDisabled(queryClient, editUser)}
                        onClick={() => {
                            setFormData({ ...formData, email: '', phoneNumber: '' })
                            setOtherTenantUserConfirmPopup(undefined)
                            setOtherTenantUser(undefined)

                            handleClose()
                            onClear()
                            setIteration(0)
                        }}
                    >
                        <JTranslation typeCase="pascal" text={CANCEL} />
                    </button>

                    <button
                        className='btn btn-custom-primary'
                        type='button'
                        data-testid='save-button'
                        disabled={isFormDisabled(queryClient, editUser)}
                        onClick={() => {
                            if(otherTenantUser) {
                                onCopyUserSubmit()
                            } else {
                                onFormSubmit()
                            }
                        }}
                    >
                        {editUser
                            ? <JTranslation typeCase="pascal" text={UPDATE} />
                            : otherTenantUser 
                                ? <JTranslation typeCase="pascal" text={'Copy'} /> 
                                : <JTranslation typeCase="pascal" text={SAVE} />
                        }
                    </button>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default AddUserSideBar