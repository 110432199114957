import withSidebar from "../../hoc/withSidebar";
import { FaSearch, FaFileExport } from "react-icons/fa";
import Dropdown from 'react-bootstrap/Dropdown';
import useQueryHook from "../../hooks/useQueryHook";
import { useQueryClient } from "react-query";
import { useContext, useEffect, useState } from "react";
import { exportReportsCSV, getFormattedReportPayloadData, getSurveyReports } from "../../helpers/surveyReportHelper";
import { SurveyReport } from "../../constants/staticTypes";
import Loader from "../../components/loader/Loader";
import { SURVEY_REPORTS } from "../../constants/queryKeys";
import { AxiosResponse } from "axios";
import SurveyReportGrid from "../../components/reports/SurveyReportGrid";
import "./styles/report.scss";
import DatePickerComponent from "../../components/date_picker/DatePickerComponent";
import { formatDate, getDateValue, popover } from "../../helpers/utils";
import useMutationHook from "../../hooks/useMutationHook";
import ToastAlert from "../../components/alert/ToastAlert";
import { CLEAR, HELP_REPORTS, REPORTS } from "../../constants/strings";
import { OverlayTrigger } from "react-bootstrap";
import { JTranslation, TranslationContext, jTranslationText } from "../../helpers/jTranslate";
import { DebounceInput } from "react-debounce-input";
import { useWindowSize } from "react-use";
import { MOBILE_VIEW_BREAKPOINT } from "../../constants/constants";
import MlxPopover from "../../components/common/MlxPopover";

function SurveyReports() {
    const queryClient = useQueryClient();
    const [surveyReports, setReport] = useState<SurveyReport[]>([]);
    const [searchValue, setSearch] = useState("");
    const [lastKey, setLastKey] = useState<string | null>(null);
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [pagination, setPagination] = useState(false);
    const [page, setPage] = useState(0);
    const exportCSVMutation = useMutationHook(queryClient, true);
    const [showToast, setToast] = useState(false); // Toast alert state
    const [toastMessage, setToastMessage] = useState(""); // Toast message
    const [toastVariant, setToastVariant] = useState(""); // Toast Variant
    const limit = 200; // limit of fetch data
    const csvDataLimit = 100; // limit of csv export

    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext

    const [translationText, setTranslatedText] = useState<{
        search: string,
    }>({ 
        search: 'Search',
    });

    const [showTitle, setShowTitle] = useState(true)
    const { width, height } = useWindowSize()
	const breakPoint = MOBILE_VIEW_BREAKPOINT

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: 'Search', typeCase: 'pascal', translationContext }),
                ])
                
                setTranslatedText({
                    search: translations[0] ?? 'Search',
                })
            } catch {
                setTranslatedText({
                    search: 'Search',
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    // fetch success
    const onFetchSuccess = (res: AxiosResponse<any, any>) => {
        const reportsList = res.data.data.list as SurveyReport[];
        if (pagination) {
            setReport([...surveyReports, ...reportsList]);
            setPage(page + 10);
        } else {
            setReport(reportsList);
        }
        setPagination(false);
        setLastKey(res.data.data.paginationData.lastKey);
    }

    // fetch reports
    const reportsData = useQueryHook(SURVEY_REPORTS,
        () => getSurveyReports(queryClient, "", null, limit, startDate, endDate), onFetchSuccess);

    // on trigger search
    const onSearchTrigger = (searchKey: string, lastKeyValue: string | null) => {
        if (reportsData.isFetching) {
            queryClient.cancelQueries([SURVEY_REPORTS]);
        }
        queryClient.fetchQuery(SURVEY_REPORTS, () =>
            getSurveyReports(queryClient, searchKey, lastKeyValue, limit, startDate, endDate));
    }

    // show toast
    const displayToast = (message: string, variant: string) => {
        setToastVariant(variant);
        setToastMessage(message);
        setToast(true);
    }

    return <>
        <ToastAlert data-testid="toast" show={showToast} onClose={setToast} message={toastMessage} variant={toastVariant} />
        {reportsData.isLoading ? <Loader /> :
            <div className="col-md-12 col-lg-12 mb-3 h-100 " data-testid="reports">
                <div className="page-title">
                    <h5 className="d-flex"><JTranslation typeCase="pascal" text={"Reports"} />
                        <MlxPopover data={{title:REPORTS, body:HELP_REPORTS}}/>
                    </h5>

                </div>

                <div className="col-md-12 col-lg-12 mb-3 h-100 ">
                    <div className="card h-100 ">
                        <div className="card-header flex-grow-0">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="flex-grow-1 title-section">
                                    {showTitle &&
                                        <h4 className="mb-0 title"> <JTranslation typeCase="pascal" text={"Guest Survey Reports"} /></h4>
                                    }
                                </div>
                                <div className="flex-grow-0 filter-section">
                                    <Dropdown className="filter-btn me-2">
                                        <Dropdown.Toggle variant="success" id="dropdown-basic" data-testid="survey-filter">
                                            <i className="ri-filter-line"></i>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <div className="col-12 p-3">
                                                <div className="form-group input-group custom-input-group mb-2">
                                                    <label className="form-label w-100 d-block mb-0"><JTranslation typeCase="pascal" text={"Start Date"} /></label>
                                                    <DatePickerComponent
                                                        selectedDate={getDateValue(startDate)}
                                                        customInput={<input type="text" data-testid="start-date"
                                                            maxLength={10} autoComplete="off" />}
                                                        setDate={(value) => {
                                                            if (value !== null) {
                                                                setStartDate(formatDate(value.toString()));
                                                            } else {
                                                                setStartDate("");
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className="form-group input-group custom-input-group mb-2">
                                                    <label className="form-label w-100 d-block mb-0"><JTranslation typeCase="pascal" text={"End Date"} /></label>
                                                    <DatePickerComponent data-testid="end-date"
                                                        selectedDate={getDateValue(endDate)}
                                                        customInput={<input type="text" data-testid="end-date" maxLength={10} autoComplete="off" />}
                                                        setDate={(value) => {
                                                            if (value !== null) {
                                                                setEndDate(formatDate(value.toString()));
                                                            } else {
                                                                setEndDate("");
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className=" text-end mt-3">
                                                    <button className="btn btn-custom-primary-outline me-1" type="button"
                                                        data-testid="filter-clear"
                                                        onClick={() => {
                                                            setStartDate("");
                                                            setEndDate("");
                                                        }}><JTranslation typeCase="pascal" text={CLEAR} /></button>
                                                    <button className="btn btn-custom-primary" type="button"
                                                        data-testid="filter"
                                                        onClick={() => {
                                                            onSearchTrigger(searchValue, null);
                                                        }}><JTranslation typeCase="pascal" text={"Filter"} /></button>
                                                </div>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <div className="flex-grow-0 me-2 position-relative">
                                        <button className={`btn btn-custom-primary-outline icon-only-btn 
                                        ${surveyReports.length === 0 && " disabled-custom"}`}
                                            data-testid="export-btn"
                                            type="button" disabled={surveyReports.length === 0}
                                            onClick={() => {
                                                const data = getFormattedReportPayloadData(searchValue, null, csvDataLimit, startDate, endDate);
                                                exportReportsCSV(queryClient, data, displayToast, exportCSVMutation);
                                            }} ><FaFileExport className="me-2 font-ic"/> <span className="btn-text"><JTranslation typeCase="pascal" text={"Export to CSV"} /></span></button>
                                    </div>
                                    <div className="flex-grow-0  d-flex justify-content-end ">
                                        <div className="col-md-12">
                                            <div className={`form-group has-search ${!showTitle ? '' : 'search-small-box'}`}>
                                                <span className="fa fa-search form-control-feedback"><FaSearch />  </span>
                                                <DebounceInput
                                                    onFocusCapture={() => {breakPoint >= width && setShowTitle(false)}}
                                                    onBlurCapture={(event: any) => {                                                
                                                        if(breakPoint >= width && event.target.value.length === 0) {
                                                            setShowTitle(true)
                                                        }
                                                    }}
                                                    className="form-control"
                                                    placeholder={translationText.search}
                                                    minLength={0}
                                                    debounceTimeout={500}
                                                    onChange={(e) => {
                                                        setSearch(e.target.value);
                                                        onSearchTrigger(e.target.value, null);
                                                    }}
                                                    data-test-id={'survey-report-search'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="card-body flex-grow-1 overflow-hidden">
                            <div className="survey-section h-100">
                                <div className="row mb-5 h-100">
                                    <div className="col-lg-12" style={{ height: '100%', overflow: 'auto' }}>
                                        <SurveyReportGrid surveyReports={surveyReports} searchValue={searchValue}
                                            onSearchTrigger={onSearchTrigger} lastKey={lastKey} selectedPage={page}
                                            setPagination={setPagination} isLoading={reportsData.isFetching}
                                            setSelectedPage={setPage} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
    </>
}
export default withSidebar(SurveyReports);