import { Dispatch, PropsWithChildren, SetStateAction, createContext, useEffect, useMemo, useRef, useState } from 'react'
import { FirebaseApp, initializeApp } from 'firebase/app'
import { Messaging, getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging'
import { firebaseConfig, publicVapidKey } from '../config/firebase.config'
import { awsAmplifyConfig, awsAnalyticsConfig } from '../config/awsConfig'
import { getStaffId } from '../helpers/utils'
import { Toast } from 'primereact/toast'
import { useLocation, useNavigate } from 'react-router-dom'
import { JTranslation } from '../helpers/jTranslate'
import { Analytics, Auth } from 'aws-amplify'
import {
	AVAILABILITY_LIST,
	AVAILABILITY_REQUESTS_LIST,
	HOLIDAYS_LIST,
	LOGIN_STATUS,
	NEWS_AND_EVENTS_LIST,
	NOTIFICATIONS_LIST,
	SCHEDULE_STATUS,
	STAFFS_WITH_SCHEDULES_AND_SHIFT,
	SWAP_REQUEST_LIST,
	UNREAD_NOTIFICATIONS_LIST,
	USER_PUBLISHED_LIST_FOR_WEEK,
	USERS_TIME_OFF_LIST,
} from '../constants/queryKeys'
import { useQueryClient } from 'react-query'
import { getIconName } from '../helpers/notificationHelper'
import { FIREBASE_DETAILS } from '../constants/constants'
import { routes } from '../constants/routes'

type FirebaseMessageType =
	| 'time-off-admin'
	| 'time-off-user'
	| 'availability-admin'
	| 'availability-user'
	| 'shift-user'
	| 'shift-admin'
	| 'news'
	| 'event'
	| 'holiday'
	| 'shift-user'
	| 'shift-swap-list'
	| 'shift-swap-schedule'

type FirebaseDetails = {
	token: string | null
	staffId: string | null
}

// SETTER TYPE DECLARATIONS
type SetFirebaseApp = Dispatch<SetStateAction<FirebaseApp | null>>
type SetMessaging = Dispatch<SetStateAction<Messaging | null>>
type SetFirebaseToken = Dispatch<SetStateAction<string | null>>
type SetFirebasePermission = Dispatch<SetStateAction<boolean>>
type SetFirebaseCheckSupport = Dispatch<SetStateAction<boolean>>
type SerFirebaseMessage = Dispatch<SetStateAction<any>>

// UPDATE FUNCTION DECLARATIONS
const UpdateFirebaseApp: SetFirebaseApp = () => null
const UpdateMessaging: SetMessaging = () => null
const UpdateFirebaseToken: SetFirebaseToken = () => null
const UpdateFirebasePermission: SetFirebasePermission = () => false
const UpdateFirebaseCheckSupport: SetFirebaseCheckSupport = () => false
const UpdateFirebaseMessage: SerFirebaseMessage = () => null

// CONTEXT VARIABLES
export const FirebaseCtx = createContext({
	firebaseApp: null as FirebaseApp | null,
	setFirebaseApp: UpdateFirebaseApp,
	messaging: null as Messaging | null,
	setMessaging: UpdateMessaging,
	firebaseToken: null as string | null,
	setFirebaseToken: UpdateFirebaseToken,
	firebasePermission: false,
	setFirebasePermission: UpdateFirebasePermission,
	firebaseCheckSupport: false,
	setFirebaseCheckSupport: UpdateFirebaseCheckSupport,
	firebaseMessage: null as any,
	setFirebaseMessage: UpdateFirebaseMessage,
	initializeFirebase: () => {},
})

export const FirebaseCtxProvider = ({ children }: Readonly<PropsWithChildren>) => {
	const toastRef = useRef<any>()
	const navigate = useNavigate()
	const location = useLocation()
	const staffId = getStaffId()
	const queryClient = useQueryClient()
	const userLoginStatus = queryClient.getQueryData(LOGIN_STATUS) as {
		isLoggedIn: boolean
	}
	const storedFirebaseDetails: FirebaseDetails = JSON.parse(
		localStorage.getItem(FIREBASE_DETAILS) as string
	) as FirebaseDetails
	const toastKey = 'notSupportToastShown'

	const [firebaseApp, setFirebaseApp] = useState<FirebaseApp | null>(null)
	const [messaging, setMessaging] = useState<Messaging | null>(null)
	const [firebaseToken, setFirebaseToken] = useState<string | null>(null)
	const [firebasePermission, setFirebasePermission] = useState<boolean>(false)
	const [firebaseCheckSupport, setFirebaseCheckSupport] = useState<boolean>(false)
	const [firebaseMessage, setFirebaseMessage] = useState<any>(null)

	const notSupportToast = () => {
		// Check if the notification has already been shown
		if (!localStorage.getItem(toastKey)) {
			toastRef?.current.show({
				life: 4000,
				severity: 'error',
				summary: (
					<JTranslation
						typeCase="capitalize"
						text={
							'Browser may not fully support notification services, please use Chrome for better experience'
						}
					/>
				),
			})

			localStorage.setItem(toastKey, 'true')
		}
	}

	const noPermissionToast = () => {
		// Check if the notification has already been shown
		if (!localStorage.getItem(toastKey)) {
			toastRef?.current.show({
				life: 4000,
				sticky: true,
				closable: true,
				icon: 'pi pi-bell',
				severity: 'error',
				summary: (
					<JTranslation
						typeCase="capitalize"
						text={
							'It looks like web push notifications are blocked or disabled in your browser. Please enable notifications manually in your browser settings and try again.'
						}
					/>
				),
			})
			localStorage.setItem(toastKey, 'true')
		}
	}

	const pushNotificationToastComponent = (firebaseMessage: any) => {
		return (
			<div
				className="d-flex"
				role="button"
				onClick={() => {
					if (firebaseMessage?.data?.link) {
						const currentUrlHost = window.location.hostname
						const publicUrl = new URL(firebaseMessage?.data?.link, window.location.href)
						if (currentUrlHost !== publicUrl.hostname) {
							window.location.href = firebaseMessage?.data?.link
						} else {
							navigate(publicUrl?.pathname)
						}
					}
					toastRef?.current?.clear()
				}}
			>
				<span
					className={`p-toast-message-icon ${getIconName(firebaseMessage?.data?.type ?? '')}`}
					data-pc-section="icon"
				></span>
				<div className="p-toast-message-text" data-pc-section="text">
					<span className="p-toast-summary" data-pc-section="summary">
						<JTranslation typeCase="pascal" text={firebaseMessage?.data?.title} />
					</span>
					<div className="p-toast-detail" data-pc-section="detail">
						<JTranslation typeCase="pascal" text={firebaseMessage?.data?.body} />
					</div>
				</div>
			</div>
		)
	}

	// Get firebase token
	const getFirebaseToken = (messaging: Messaging) => {
		const MAX_RETRIES = 3
		const BASE_DELAY = 2000 // 2 seconds
		const generateToken = async (retryCount: number = 0) => {
			try {
				const token = await getToken(messaging, { vapidKey: publicVapidKey })
				if (token) {
					// console.log('Token generated : ', token)
					setFirebaseToken(token)
				} else {
					console.log('No Instance ID token available. Request permission to generate one.')
				}
			} catch (error) {
				if (retryCount < MAX_RETRIES) {
					const backoffDelay = Math.pow(2, retryCount) * BASE_DELAY // Exponential backoff calculation
					console.log(`Retrying... Attempt ${retryCount + 1} after ${backoffDelay} ms`)
					await new Promise((resolve) => setTimeout(resolve, backoffDelay)) // Wait for backoff period
					await generateToken(retryCount + 1)
				} else {
					console.log('An error occurred while retrieving token. ', error)
					console.log('Maximum retry attempts reached.')
					setFirebasePermission(false)
				}
			}
		}

		Notification.requestPermission()
			.then(async (permission) => {
				if (permission === 'granted') {
					setFirebasePermission(true)
					await generateToken()
				} else {
					setFirebasePermission(false)
					// noPermissionToast()
				}
			})
			.catch((error) => {
				console.log('Permission error on web push notifications. ', error)
				setFirebasePermission(false)
				// noPermissionToast()
			})
	}

	const initializeConfig = () => {
		//Initialize Amplify
		// Amplify.Logger.LOG_LEVEL = 'DEBUG';
		Auth.configure(awsAmplifyConfig)
		Analytics.configure(awsAnalyticsConfig)
		Analytics.autoTrack('session', {
			enable: false,
		})
	}

	const handleFirebaseMessage = (firebaseMessage: any) => {
		const messageType: FirebaseMessageType = firebaseMessage?.data?.type ?? ('' as FirebaseMessageType)

		// fetch for notification pop-up panel and page
		if (location.pathname === routes.my_notifications) {
			queryClient.refetchQueries([NOTIFICATIONS_LIST])
		}
		queryClient.refetchQueries([UNREAD_NOTIFICATIONS_LIST])
		
		switch (messageType) {
			case 'time-off-admin':
			case 'time-off-user':
				queryClient.refetchQueries([USERS_TIME_OFF_LIST])
				break
			case 'availability-admin':
				queryClient.refetchQueries([AVAILABILITY_REQUESTS_LIST])
				break
			case 'availability-user':
				queryClient.refetchQueries([AVAILABILITY_LIST])
				break
			case 'holiday':
				queryClient.refetchQueries([HOLIDAYS_LIST])
				break
			case 'news':
			case 'event':
				queryClient.refetchQueries([NEWS_AND_EVENTS_LIST])
				break
			case 'shift-user':
				queryClient.refetchQueries([USER_PUBLISHED_LIST_FOR_WEEK])
				break
			case 'shift-swap-list':
				queryClient.refetchQueries([SWAP_REQUEST_LIST])
				break
			case 'shift-swap-schedule':
				queryClient.refetchQueries([SCHEDULE_STATUS])
				queryClient.refetchQueries([STAFFS_WITH_SCHEDULES_AND_SHIFT])
				break
		}
	}

	const initializeFirebase = useMemo(() => {
		return () => {
			// Check if the browser supports web push,
			isSupported()
				.then((hasSupport) => {
					setFirebaseCheckSupport(hasSupport)
					if (!hasSupport) {
						console.log('Browser does not support notification services, please use Chrome or Firefox')
						// notSupportToast() // Hidden for now
					}
				})
				.catch((error) => {
					console.log('Browser does not support notification services, please use Chrome or Firefox', error)
					// notSupportToast() // Hidden for now
				})
				.finally(async () => {
					initializeConfig()

					const app: FirebaseApp = initializeApp(firebaseConfig)
					setFirebaseApp(app)

					const firebaseMessaging: Messaging = getMessaging(app)
					setMessaging(firebaseMessaging)

					getFirebaseToken(firebaseMessaging)
				})
		}
	}, [])

	useEffect(() => {
		initializeFirebase()
	}, [])

	useEffect(() => {
		if (messaging && firebaseToken && userLoginStatus?.isLoggedIn) {
			// Check if token is already stored
			if (storedFirebaseDetails?.token === firebaseToken && storedFirebaseDetails?.staffId === staffId) {
				onMessage(messaging, (payload) => {
					console.log('onMessage', payload)
					setFirebaseMessage(payload)
				})
				return
			}

			initializeConfig()

			if (!staffId) {
				return
			}

			// Add/Update token to AWS pinpoint
			Analytics.updateEndpoint({
				address: firebaseToken,
				channelType: 'GCM',
				userId: staffId,
				optOut: 'NONE',
				// userAttributes: {
				// 	username: ['someUserName1'],
				// },
				// attributes: {
				// 	plan: ['basic'],
				// },
			})
				.then((response) => {
					console.log('------- Analytics.updateEndpoint return data=', response)
					localStorage.setItem(FIREBASE_DETAILS, JSON.stringify({ token: firebaseToken, staffId }))
				})
				.catch((error) => {
					console.log('-------  ERROR at Analytics.updateEndpoint=', error)
				})

			onMessage(messaging, (payload) => {
				console.log('onMessage', payload)
				setFirebaseMessage(payload)
			})
		}
	}, [firebaseToken, userLoginStatus])

	useEffect(() => {
		if (firebaseMessage) {
			toastRef?.current.show({
				id: firebaseMessage?.messageId,
				closable: true,
				icon: 'pi pi-bell',
				severity: 'success',
				life: 3000,
				// sticky: true,
				content: pushNotificationToastComponent(firebaseMessage),
			})
			handleFirebaseMessage(firebaseMessage)
			setFirebaseMessage(null)
		}
	}, [firebaseMessage])

	return (
		<FirebaseCtx.Provider
			value={useMemo(
				() => ({
					firebaseApp,
					setFirebaseApp,
					messaging,
					setMessaging,
					firebaseToken,
					setFirebaseToken,
					firebasePermission,
					setFirebasePermission,
					firebaseCheckSupport,
					setFirebaseCheckSupport,
					firebaseMessage,
					setFirebaseMessage,
					initializeFirebase,
				}),
				[
					firebaseApp,
					messaging,
					firebaseToken,
					firebasePermission,
					firebaseCheckSupport,
					firebaseMessage,
					initializeFirebase,
				]
			)}
		>
			<div className="push-notification-toast">
				<Toast ref={toastRef} />
			</div>
			{children}
		</FirebaseCtx.Provider>
	)
}
