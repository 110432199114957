import { AxiosResponse } from "axios";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { UseMutationResult } from "react-query";
import { Payload, UserRolesList } from "../../constants/staticTypes";
import { ADD, ADD_CUSTOM_ROLE, CLEAR, ROLE_DESC, ROLE_NAME } from "../../constants/strings";
import { addRole } from "../../helpers/userRolesHelper";
import { JTranslation } from "../../helpers/jTranslate";

type Props = {
    show: boolean,
    onHide: React.Dispatch<React.SetStateAction<boolean>>,
    item: UserRolesList | undefined,
    addRoleMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onAddRoleSuccess: (item: UserRolesList) => void,
    onError: (errorMessage: string) => void
}

function AddCustomRole({ show, onHide, item, addRoleMutation, onAddRoleSuccess, onError }: Props) {
    const [roleName, setRoleName] = useState("");
    const [roleDescription, setRoleDesc] = useState("");

    return (
        <Modal
            data-testid="custom-role-modal"
            centered
            show={show}>
            <Modal.Header>
                <h5 className="modal-title" id="exampleModalLabel"><JTranslation typeCase="pascal" text={ADD_CUSTOM_ROLE} /></h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => {
                    setRoleName("");
                    setRoleDesc("");
                    onHide(false);
                }}
                    aria-label="Close" data-testid="close-button"></button>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-3">
                    <label htmlFor="roleName" className="form-label"><JTranslation typeCase="pascal" text={ROLE_NAME} /><span className="mandatory">*</span></label>
                    <input type="email" className="form-control" id="roleName"
                        data-testid="role-name"
                        autoFocus
                        maxLength={50}
                        autoComplete="off"
                        value={roleName}
                        onChange={(e) => setRoleName(e.target.value)} />
                </div>
                <div className="mb-3">
                    <label htmlFor="exampleFormControlInput2" className="form-label"><JTranslation typeCase="pascal" text={ROLE_DESC} /></label>
                    <textarea className="form-control" id="exampleFormControlInput2"
                        rows={3} data-testid="role-desc" autoComplete="new-password"
                        value={roleDescription} onChange={(e) => setRoleDesc(e.target.value)}
                        maxLength={2000}></textarea>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn  btn-custom-primary-outline" data-bs-dismiss="modal"
                    onClick={() => {
                        setRoleName("");
                        setRoleDesc("");
                    }}><JTranslation typeCase="pascal" text={CLEAR} /></button>
                <button type="button" className="btn btn-custom-primary"
                    data-testid="add-new-role-save"
                    disabled={roleName?.trim().length === 0}
                    onClick={(e) => {
                        e.preventDefault();
                        if (item && roleName?.trim().length > 0) {
                            let newRole = {
                                ...item,
                                roleName: roleName,
                                roleDescription: roleDescription
                            };
                            setRoleName("");
                            setRoleDesc("");
                            onHide(false);
                            addRole(addRoleMutation, newRole, onAddRoleSuccess, onError);
                        }
                    }}><JTranslation typeCase="pascal" text={ADD} /></button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddCustomRole;