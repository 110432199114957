import { useState, useContext, useEffect, useRef } from 'react'
import ToastAlert from '../../components/alert/ToastAlert'
import WarningModal from '../../components/warning_modal/WarningModal'
import { DATE_FORMAT, PageMode, toastMessageInitialData } from '../../constants/constants'
import { ToastMessageProps, JobRole, HolidayData } from '../../constants/staticTypes'
import { CURRENT_WEEK, VIEW_AVAILABILITY, VIEW_AVAILABILITY_HELP } from '../../constants/strings'
import { CommonCtx } from '../../context/CommonCtxProvider'
import withSidebar from '../../hoc/withSidebar'
import { getAllDays, getAllWeekDays } from '../../helpers/utils'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import AvailabilityViewDataGrid from '../../components/availability/AvailabilityViewDataGrid'
import { AxiosResponse } from 'axios'
import Loader from '../../components/loader/Loader'
import { HOLIDAYS_LIST, MANDATORY_DAYS_LIST, STAFF_AVAILABILITY, USER_ROLES } from '../../constants/queryKeys'
import useQueryHook from '../../hooks/useQueryHook'
import { Button } from 'primereact/button'
import MandatoryDaysSideBar from '../../components/availability/MandatoryDaysSideBar'
import { getUserRoles } from '../../helpers/userRolesHelper'
import MlxPopover from '../../components/common/MlxPopover'
import { useFullscreen, useWindowSize } from 'react-use'
import { getAllMandatoryDays, getAvailabilityGlance } from '../../helpers/availabilityHelper'
import { MultiSelect } from 'primereact/multiselect'
import { getAllHolidaysList } from '../../helpers/holidayHelper'

function ViewAvailability() {
	const previewRef = useRef(null)
	const { width } = useWindowSize()

	// STATE VARIABLE
	const [mode, setMode] = useState<PageMode>(PageMode.ADD)
	const [availabilityList, setAvailabilityList] = useState<any[]>([])
	const [rolesList, setRolesList] = useState<JobRole[]>([])
	const [selectedRoles, setSelectedRoles] = useState<string[]>([])
	const [mandatoryDays, setMandatoryDays] = useState<dayjs.Dayjs[]>([])
	const [holidayList, setHolidayList] = useState<HolidayData[]>([])
	const [selectedWeek, setSelectedWeek] = useState<dayjs.Dayjs>(dayjs())
	const [weekDays, setWeekDays] = useState<dayjs.Dayjs[]>([])
	const [showPreview, setShowPreview] = useState(false)

	// Full screen handler
	useFullscreen(previewRef, showPreview, { onClose: () => setShowPreview(false) })

	// CONTEXT VARIABLE
	const { showSideBar, setShowSideBar } = useContext(CommonCtx)

	// ALERT VARIABLES
	const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)

	// WARNING MODAL
	const [showWarning, setWarning] = useState(false) // Warning popup
	const [warningMessage, setWarningMessage] = useState('') // Warning message

	// roles list fetch success
	const onRoleSuccess = (res: AxiosResponse<any, any>) => {
		const data: JobRole[] | [] = res.data?.data?.lists as JobRole[]
		setRolesList(data)
	}

	// fetch roles list
	const userRoles = useQueryHook(USER_ROLES, getUserRoles, onRoleSuccess)

	// fetch all mandatory days
	const mandatoryDaysQuery = useQueryHook(
		MANDATORY_DAYS_LIST, 
		() => getAllMandatoryDays(), 
		(response: AxiosResponse<any, any>) => {
			const data = response?.data?.data?.lists
			if(data) {
				const convertStringToDayJS = [];
				for (const day of data) {
					// can have single time, day or multiple days
					const startDate = dayjs(day.startDate)
					const endDate = dayjs(day.endDate)
					const totalDays = getAllDays(startDate, endDate)
					convertStringToDayJS.push(...totalDays)
				}
				setMandatoryDays(convertStringToDayJS)
			}
		},
		() => { },
		false,
		false
	)

	// holiday list
	const holidayListQuery = useQueryHook(
		HOLIDAYS_LIST,
		() => getAllHolidaysList(dayjs().year()),
		(response: AxiosResponse<any, any>) => {
			const holidayList = (response?.data?.data?.lists ?? []) as HolidayData[]
			setHolidayList(holidayList)
		},
		() => { },
		false,
		false
		
	)

	// fetch roles list
	const staffAvailabilityQueryHook = useQueryHook(STAFF_AVAILABILITY, () => getAvailabilityGlance({
		startDate: weekDays[0].format('YYYY-MM-DD'),
		endDate: weekDays[weekDays.length - 1].format('YYYY-MM-DD'),
	}), (response: AxiosResponse<any, any>) => {
		setAvailabilityList(response?.data?.data)
	}, () => { },
		false,
		false
	)

	// set all week days
	useEffect(() => {
		const weekDays = getAllWeekDays(selectedWeek, 'DD/MM/YYYY')
		setWeekDays(weekDays)
	}, [selectedWeek])

	// fetch onc weekdays are selected
	useEffect(() => {
		if (weekDays.length) {
			holidayListQuery.refetch()
			mandatoryDaysQuery.refetch()
			staffAvailabilityQueryHook.refetch()
		}
	}, [weekDays])

	if (staffAvailabilityQueryHook.isLoading || mandatoryDaysQuery.isLoading || holidayListQuery.isLoading || userRoles.isLoading) {
		return <Loader />
	}

	return (
		<>
			<WarningModal
				show={showWarning}
				title={warningMessage}
				onHide={() => {
					setWarning(false)
				}}
				callback={() => {
					setWarning(false)
				}}
			/>

			<ToastAlert
				data-testid="toast"
				message={toastMessage.message}
				onClose={() => setToastMessage(toastMessageInitialData)}
				show={toastMessage.show}
				variant={toastMessage.variant}
			/>

			{/* Mandatory days sidebar */}
			<MandatoryDaysSideBar
				pageMode={mode}
				setMode={setMode}
				setShowSideBar={setShowSideBar}
				setToastMessage={setToastMessage}
			/>

			<div className="col-md-12 col-lg-12 mb-3 h-100 ">
				<div className="page-title d-flex mb-3 align-items-center">
					<div className="flex-grow-1">
						<h5 className="mb-0 d-flex align-items-center">
							<span>{VIEW_AVAILABILITY}</span>
							<MlxPopover data={{ title: VIEW_AVAILABILITY, body: VIEW_AVAILABILITY_HELP }} />
						</h5>
					</div>
					<div className="flex-grow-0 align-items-center">
						{/* Fullscreen preview */}
						<Button
							icon="pi pi-window-maximize"
							className=" p-button-rounded p-button-primary p-button-text fs-4 color-primary-custom"
							title="Preview"
							onClick={() => {
								setShowPreview(true)
							}}
						/>

						{/* F&B configuration*/}
						<Button
							icon="ri-settings-4-line "
							className=" p-button-rounded p-button-primary p-button-text fs-4 color-primary-custom"
							title="Mandatory Days"
							onClick={() => {
								setShowSideBar(true)
							}}
						/>
					</div>
				</div>

				<div className="card h-100">
					<div className="card-header flex-grow-0">
						<div className="d-flex align-items-center">
							<div className="flex-grow-1 py-2"></div>
							<div className="flex-grow-0 me-2 ms-1">
								<div className="d-flex align-items-center">
									{width > 789 && (
										<div className='d-flex align-items-center'>
											<button
												className="btn btn-custom-primary me-1"
												style={{ minWidth: '40px' }}
												type="button"
												onClick={() => {
													setSelectedWeek((prevValues) => prevValues.subtract(1, 'week'))
												}}
											>
												<span className="btn-text">
													<i className="ri-arrow-left-double-line"></i>
												</span>
											</button>
											<button
												className="btn btn-custom-primary d-flex me-1"
												type="button"
												onClick={() => {
													setSelectedWeek(dayjs())
												}}
											>
												<i className="ri-calendar-todo-line me-1"></i>
												<span className="btn-text">{CURRENT_WEEK}</span>
											</button>
											<button
												className="btn btn-custom-primary me-1"
												style={{ minWidth: '40px' }}
												type="button"
												onClick={() => {
													setSelectedWeek((prevValues) => prevValues.add(1, 'week'))
												}}
											>
												<span className="btn-text">
													<i className="ri-arrow-right-double-line"></i>
												</span>
											</button>
										</div>
									)}
									<div className="ms-2" style={{minWidth: '190px'}}>
										<MultiSelect
											data-testid='roles-select'
											value={selectedRoles}
											options={rolesList}
											itemTemplate={(option: JobRole) => <option key={option.id}>{option.jobRoleName}</option>}
											onChange={(e) => {
												setSelectedRoles(e.value)
											}}
											className={'custom-multiselect'}
											optionLabel='jobRoleName'
											showSelectAll={false}
											maxSelectedLabels={1}
											optionValue='id'
											filter
											showClear={true}
											placeholder={'Select roles'}
										/>
									</div>
									<DatePicker
										className="ms-2 availability-date-picker"
										onChange={(date, dateString) => {
											const selectedDate = date ? dayjs(date) : dayjs()
											setSelectedWeek(selectedDate)
										}}
										picker="week"
										value={selectedWeek}
										format={(value) =>
											`${dayjs(value).startOf('week').format(DATE_FORMAT)} - ${dayjs(value)
												.endOf('week')
												.format(DATE_FORMAT)}`
										}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="card-body flex-grow-1 overflow-hidden">
						<div className="h-100 d-flex flex-column justify-content-center">
							<AvailabilityViewDataGrid
								tableData={availabilityList}
								mandatoryDays={mandatoryDays}
								weekDays={weekDays}
								previewRef={previewRef}
								showPreview={showPreview}
								setShowPreview={setShowPreview}
								loading={staffAvailabilityQueryHook.isFetching || mandatoryDaysQuery.isFetching || holidayListQuery.isFetching}
								selectedRoles={selectedRoles}
								holidayList={holidayList}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default withSidebar(ViewAvailability)
