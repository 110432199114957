import React, { createContext, ReactNode, useMemo, useState } from 'react'

interface TranslationContextProps {
  sourceLanguage: string
  targetLanguage: string
  apiKey: string
  provider: 'google' // 'google'   | 'yahoo'
  changeTargetLanguage: (newLanguage: string) => void
}

export const TranslationContext = createContext<TranslationContextProps>({
  sourceLanguage: 'en', // Default source language is English
  targetLanguage: 'en', // Default target language is English
  apiKey: '', //  API key of the service
  provider: 'google', // Default translation provider is Google
  changeTargetLanguage: () => { }, // Default changeTargetLanguage function
})

interface TranslationProviderProps {
  sourceLanguage?: string
  targetLanguage?: string
  apiKey: string
  provider?: 'google' // 'google'  | 'yahoo'
  children: ReactNode
  onLanguageChange?: (newLanguage: string) => void // Callback function to handle logging in app.tsx
}

/**
 * Translation provider component that wraps the children with the TranslationContext.Provider.
 * @param sourceLanguage - The source language for translation. Default is 'en' (English) if not provided.
 * @param targetLanguage - The target language for translation. Default is 'en' (English) if not provided.
 * @param provider - The translation provider. Default is 'google' if not provided (accepts 'google').
 * @param apiKey - The API key required for translation.
 */
export const JTranslationProvider: React.FC<TranslationProviderProps> = ({
  sourceLanguage = 'en', // Default source language is English if not provided
  targetLanguage = 'en', // Default target language is English if not provided
  provider = 'google', // Default translation provider is Google if not provided
  apiKey,
  children,
  onLanguageChange, // Callback function to handle
}) => {
  const [currentTargetLanguage, setTargetLanguage] = useState(targetLanguage) // Add state for targetLanguage

  const changeTargetLanguage = (newLanguage: string) => {
    setTargetLanguage(newLanguage)
    localStorage.setItem('targetLanguage', newLanguage)
  }

  return (
    <TranslationContext.Provider
      value={useMemo(() => {
        return {
          sourceLanguage,
          targetLanguage: currentTargetLanguage,
          apiKey,
          provider,
          changeTargetLanguage,
          // onLanguageChange,
        }
      }, [sourceLanguage, currentTargetLanguage, apiKey, provider])}
    >
      {children}
    </TranslationContext.Provider>
  )
}
