import { useContext, useState, useEffect, useRef } from 'react'
import { JTranslation, TranslationContext, jTranslationText } from '../../helpers/jTranslate'
import { Badge } from 'primereact/badge'
import { NotificationObject } from '../../constants/staticTypes'
import {
	getIconName,
	getNavigateUrl,
	getUnreadNotificationListView,
	markNotificationAsRead,
} from '../../helpers/notificationHelper'
import { convertUTCtoLocalByOffset, getStaffId } from '../../helpers/utils'
import { useQueryClient } from 'react-query'
import { AxiosResponse } from 'axios'
import { useNavigate } from 'react-router-dom'
import { HEADER_RENDERED, LOGIN_STATUS, NOTIFICATIONS_LIST, UNREAD_NOTIFICATIONS_LIST } from '../../constants/queryKeys'
import noNotificationImage from '../../assets/images/no-notifications.png'
import useQueryHook from '../../hooks/useQueryHook'
import { Card } from 'primereact/card'

let fetchingPaginatedData = true

function NotificationCard() {
    const id = getStaffId()
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const translationContext = useContext(TranslationContext)
	const { targetLanguage } = translationContext

	const [unreadBadge, setUnreadBadge] = useState(0)
	const [notificationList, setNotificationList] = useState<NotificationObject[]>([])

	// login status value
	const loginStatus = queryClient.getQueryData(LOGIN_STATUS) as {
		isLoggedIn: boolean
	}

	const unreadNotifications = useQueryHook(
		UNREAD_NOTIFICATIONS_LIST,
		() => getUnreadNotificationListView(queryClient, id),
		(res: AxiosResponse<any, any>) => {
			const data: NotificationObject[] = res.data.data.list
			setUnreadBadge(data?.length)
			setNotificationList(data?.slice(0, 5))
		},
		() => {},
		false,
		false
	)
	
	// mark notification as read and navigate
	const markAsRead = (notification: NotificationObject) => {
		const params = {
			staffId: id,
			notificationIds: [notification.id],
			readStatus: true,
		}
		markNotificationAsRead(queryClient, params)
			.then(() => {
				unreadNotifications.refetch()
			})
			.catch((response) => {
				console.error(`failed to mark as read`, response)
			})
		navigate(getNavigateUrl(notification))
	}

	useEffect(() => {
		const initialLoad = queryClient.getQueryData(HEADER_RENDERED) as boolean
		if (loginStatus?.isLoggedIn && !initialLoad) {
			queryClient.setQueryData(HEADER_RENDERED, true)
			unreadNotifications.refetch()
		}
	}, [])

	return (
        <Card 
            className="dashboard-notification-card card h-100 custom-shadow border-radius-05 border-0" 
            title={
				<>
					<div className='d-flex align-items-center'>
					<JTranslation typeCase="pascal" text={"Notifications"} />
						{unreadBadge > 0 && (
							<Badge className="ms-2 dashboard-badge" value={unreadBadge} severity="danger"></Badge>
						)}
					</div>
					<button className="btn btn-custom-primary dashboard-btn" onClick={()=>navigate('/my-notifications')}><JTranslation typeCase="pascal" text={'View All'} /></button>
				</>
            }
        >
			
							<div className='dashboard-widget-height notification-panel h-100'>
							{notificationList.length > 0 && (
								<div className="d-flex flex-column h-100">
									<div className="flex-grow-1 overflow-auto notification-wrapper">
										<div className="col-lg-12 px-1">
											{notificationList.map((notification, index) => (
												<div
													key={index}
													className={`mb-2 notification-list p-3 ${
														notification?.isRead ? 'read' : 'unread'
													}`}
													role="button"
													onClick={() => {
														navigate('/my-notifications')
													}}
												>
													<div className="notification-type">
														<i className={getIconName(notification?.topic)}></i>
													</div>
													<div className="ps-3 notification-message">
														<p className="m-0 mb-1">
															<span className="f-600">
																{notification?.notification?.title}
															</span>
															<small className="ps-3 text-muted">
																{convertUTCtoLocalByOffset(notification?.timestamp, {
																	humanizeFormat: true,
																})}
															</small>
														</p>
														<p className="m-0 pt-0">
															{notification?.notification?.body?.slice(0, 60) + '...'}
														</p>
													</div>
												</div>
											))}
										</div>
									</div>
								</div>
							)} 

								{notificationList.length === 0 && (
									<div className="h-100 d-flex flex-column align-items-center justify-content-center">
										<div className="flex-grow-1 d-flex align-items-center justify-content-center p-3">
											<img
												className="img-fluid"
												src={noNotificationImage}
												alt="no-notifications"
											/>
										</div>
									</div>
								)}
							</div>
					
        </Card>
	)
}

export default NotificationCard