import { useState, useContext } from 'react'
import { approveExtractedResult } from '../../helpers/verifyDocumentTypeHelper'
import { Button } from 'primereact/button'
import { CONFIRM, VIEW_INVOICE_TEMPLATE, NOT_AN_INVOICE } from '../../constants/strings'
import { ExtractedFileData, ThumpsUpOrDown } from '../../constants/staticTypes'
import { FileManagementCtx } from '../../context/FileManagementCtxProvider'
import { useQueryClient } from 'react-query'
import ToastAlert from '../../components/alert/ToastAlert'
import useMutationHook from '../../hooks/useMutationHook'
import WarningModal from '../../components/warning_modal/WarningModal'
import { useTranslation } from '../../hooks/useTranslation'

const FileTypeVerificationActions = (props: ExtractedFileData) => {
  const queryClient = useQueryClient()
  const verifyDocumentMutation = useMutationHook(queryClient, true)
  // CONTEXT VARIABLES
  const { setRefetchAPI } = useContext(FileManagementCtx)
  // ALERT VARIABLES
  const [alertVariant, setVariant] = useState('')
  const [toastMessage, setToastMessage] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [thumpsDownModal, setThumpsDownModal] = useState(false)

  const onSuccess = (message: string) => {
    setToastMessage(message)
    setVariant('success')
    setShowAlert(true)
    setRefetchAPI(true)
  }

  const onError = (errorMessage: string, variant: string) => {
    setToastMessage(errorMessage)
    setVariant(variant)
    setShowAlert(true)
    setRefetchAPI(true)
  }

  // api call to record thumps down on document's auto file type recognition
  const thumpsDown = () => {
    let payload: ThumpsUpOrDown = { extractResultId: props.id, isExtractedTypeCorrect: false }
    approveExtractedResult(verifyDocumentMutation, payload, onSuccess, onError)
  }

  return (
    <>
      <ToastAlert show={showAlert} onClose={setShowAlert} message={toastMessage} variant={alertVariant} />
      {/* THUMPS DOWN MODAL */}
      <WarningModal
        show={thumpsDownModal}
        onHide={() => setThumpsDownModal(false)}
        title={NOT_AN_INVOICE}
        primaryButtonName={CONFIRM}
        callback={() => {
          thumpsDown()
          setThumpsDownModal(false)
        }}
      />
      <Button
        icon="pi pi-eye"
        className="p-button-sm p-button-success p-button-rounded me-2 custom-thumb-icon p-button-outlined"
        aria-label="Thumps up"
        title={useTranslation(VIEW_INVOICE_TEMPLATE, 'capitalize')}
        data-testid={"view-invoice-btn#" + props.id}
        onClick={() => alert('work-in-progress')}
      />{' '}
      <Button
        icon="pi pi-thumbs-down"
        className="p-button-sm p-button-danger p-button-rounded custom-thumb-icon p-button-outlined"
        aria-label="Thumps down"
        title={useTranslation(NOT_AN_INVOICE, 'capitalize')}
        data-testid={"thumpsdown-btn#" + props.id}
        onClick={() => setThumpsDownModal(true)}
      />
    </>
  )
}

export default FileTypeVerificationActions
