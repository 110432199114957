import { NavLink, useLocation } from 'react-router-dom';
import {
  FESTIVAL_TEMPLATES,
  GUEST_LOCATIONS,
  SELECTED_MENU,
  SPECIAL_MENU_CODE,
} from '../../constants/constants';
import { routes } from '../../constants/routes';
import GetLogo from '../../helpers/JGetLogo';
import mobileLogo from '../../assets/images/mobile-logo.svg';
import HeartAnimation from '../celebrations/valentinesDay/HeartAnimation';
import CloverLeafAnimation from '../celebrations/stPatricksDay/CloverLeafAnimation';
import EasterLogoAnimation from '../celebrations/easter/EasterLogoAnimation';
import ChristmasLogoAnimation from '../celebrations/christmas/ChristmasLogoAnimation';
import CincoDeMayoLogoAnimation from '../celebrations/cincoDeMayo/cincoDeMayoLogoAnimation';
import MothersDayLogoAnimation from '../celebrations/mothersDay/MothersDayLogoAnimation';
import { useQuery } from 'react-query';
import type { BrandingData } from '../../constants/staticTypes';
import { BRANDING_INFO } from '../../constants/queryKeys';
import { checkFestivalEffects } from '../celebrations/helpers';
import { getTenantInfo } from '../../helpers/authHelper';
import { useWindowSize } from 'react-use';

function LogoComponent() {
  const { data: brandingInfo } = useQuery<BrandingData>({
    queryKey: BRANDING_INFO,
    enabled: false, refetchOnWindowFocus: false,
    queryFn: () =>
      getTenantInfo().then(
        (tenantInfo) => tenantInfo.data?.data?.branding as BrandingData
      ),
  });
  const location = useLocation();
  const logo = GetLogo();
  const menuCode = localStorage.getItem(SPECIAL_MENU_CODE);
  const { width } = useWindowSize();
  const breakPoint = 768;
  const skipRoutes = [
    routes.login,
    routes.forgot,
    routes.reset,
    routes.set_password,
  ];

  const hasElementStartingWith = (
    guestLocations: string[],
    pathname: string
  ): boolean => {
    return guestLocations.some((location) => pathname.startsWith(location));
  };

  // logo click navigation route
  const getLogoNavRoute = (): string => {
    if (
      !menuCode &&
      hasElementStartingWith(GUEST_LOCATIONS, location.pathname)
    ) {
      localStorage.removeItem(SELECTED_MENU);
      return routes.menu_view;
    } else if (menuCode) {
      return `${routes.special_menu}/${menuCode}`;
    } else {
      localStorage.removeItem(SELECTED_MENU);
      return routes.dashboard;
    }
  };

  const isDesktopView = (): boolean => {
    if (width <= breakPoint) {
      // Skip mobile logo if in login
      if (skipRoutes.includes(location.pathname)) {
        return true;
      }
      return false;
    }
    return true;
  };

  return (
    <div className='position-relative'>
      <NavLink
        className={
          isDesktopView() ? 'logo-sec-left text-center' : 'd-flex mobile-logo'
        }
        data-testid={isDesktopView() ? 'logo' : 'mobile-logo'}
        to={getLogoNavRoute()}
      >
        {isDesktopView() ? (
          <img src={logo} className='img-fluid' alt='logo' />
        ) : (
          <img src={mobileLogo} className='img-fluid' alt='mobile-logo' />
        )}

        {checkFestivalEffects(
          brandingInfo as BrandingData,
          FESTIVAL_TEMPLATES.VALENTINES_DAY,
          location.pathname
        ) && (
          <HeartAnimation
            style={
              skipRoutes.includes(location.pathname) ? { left: '35%' } : {}
            }
          />
        )}
        {checkFestivalEffects(
          brandingInfo as BrandingData,
          FESTIVAL_TEMPLATES.ST_PATRICKS_DAY,
          location.pathname
        ) && (
          <CloverLeafAnimation
            style={
              skipRoutes.includes(location.pathname) ? { left: '35%' } : {}
            }
          />
        )}
        {checkFestivalEffects(
          brandingInfo as BrandingData,
          FESTIVAL_TEMPLATES.CHRISTMAS_NEWYEAR,
          location.pathname
        ) && (
          <ChristmasLogoAnimation
            style={
              skipRoutes.includes(location.pathname) ? { left: '35%' } : {}
            }
          />
        )}
        {checkFestivalEffects(
          brandingInfo as BrandingData,
          FESTIVAL_TEMPLATES.EASTER,
          location.pathname
        ) && (
          <EasterLogoAnimation
            style={
              skipRoutes.includes(location.pathname) ? { left: '35%' } : {}
            }
          />
        )}
        {checkFestivalEffects(
          brandingInfo as BrandingData,
          FESTIVAL_TEMPLATES.CINCO_DEMAYO,
          location.pathname
        ) && (
          <CincoDeMayoLogoAnimation
            style={skipRoutes.includes(location.pathname) ? {} : {}}
          />
        )}
        {checkFestivalEffects(
          brandingInfo as BrandingData,
          FESTIVAL_TEMPLATES.MOTHERS_DAY,
          location.pathname
        ) && (
          <MothersDayLogoAnimation
            style={skipRoutes.includes(location.pathname) ? {} : {}}
          />
        )}
      </NavLink>
    </div>
  );
}

export default LogoComponent;
