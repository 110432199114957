import { useState, useEffect } from 'react'
import { AxiosResponse } from 'axios'
import { getNewsListGuestView } from '../../helpers/newsAndEventsHelper'
import { JTranslation } from '../../helpers/jTranslate'
import { NewsPostGuestView, GetNewsAndEventsApiParams } from '../../constants/staticTypes'
import { routes } from '../../constants/routes'
import { ScrollTop } from 'primereact/scrolltop';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { v4 as uuidv4 } from "uuid"
import Loader from '../../components/loader/Loader'
import NewsCard from './NewsCard'
import withPublicHeader from '../../hoc/withPublicHeader'

let fetchingPaginatedData = true

const NewsOnlyGuestView = () => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const { setIsFetching } = useInfiniteScroll(getMoreData)
    // STATE VARIABLES
    const [news, setNews] = useState<NewsPostGuestView[]>([])
    const [lastKey, setLastKey] = useState('')
    const [loading, setLoading] = useState(true)

    function getMoreData() {
        const param: GetNewsAndEventsApiParams = { limit: 9, lastKey: lastKey }
        // fetch paginated data
        if (fetchingPaginatedData && lastKey) {
            getNewsListGuestView(queryClient, param)
            .then(onFetchNewsSuccess)
            .catch(onError);
        }
        fetchingPaginatedData = false
    }

    const onFetchNewsSuccess = (res: AxiosResponse<any, any>) => {
        const data: NewsPostGuestView[] = res.data.data.list
        const key = res.data.data.pagination.lastKey
        setNews((prevNews) => [...prevNews, ...data])
        setLastKey(key)
        setIsFetching(false)
        setLoading(false)
        fetchingPaginatedData = true
    }

    const onError = () => {
        setIsFetching(false)
        setLoading(false)
    }

    useEffect(() => {
        const param: GetNewsAndEventsApiParams = { limit: 9 }
        // fetch news api call
        getNewsListGuestView(queryClient, param)
            .then(onFetchNewsSuccess)
            .catch(onError);
        window.scrollTo(0,0)
    }, []) // eslint-disable-line

    if (loading) {
        return <Loader />
    }

    return (
        <div className="container h-100">
            <ScrollTop target='parent' className='scroll-top' behavior='smooth'/>
            <div className="row mb-3 mt-3">
                <div className="col-lg-12 p-0">
                    <div className="card">
                        <div className='card-header p-0 card-breadcrumb'>
                            <div className=' '>
                                <div className='col-md-12 d-flex align-items-center p-2'>
                                    <div
                                        className='button btn  back-btn back-btn-new'
                                        onClick={() => navigate(routes.news_and_events)}
                                    >
                                        <i className='ri-arrow-left-line'></i>
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <h3 className="mb-0 text-start">{<JTranslation text="Latest News" />}</h3>
                                    </div>

                                </div>
                            </div>
                        </div>

                        {/* NEWS COMPONENT */}
                        <div className='card-body newsEventsWrapper '>
                            <div className="row">
                                {news.map((newsItem) => (
                                    <NewsCard key={uuidv4()} news={newsItem} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withPublicHeader(NewsOnlyGuestView)
