import React from 'react'
import { ALLOWED_FILE_FORMATS, ROOT_FOLDER, PermissionKeys, SubPermissionKeys } from '../../../constants/constants'
import { ApiFolderAndFileDS } from '../../../constants/staticTypes'
import { checkPermission } from '../../../helpers/utils'
import { FileData } from 'chonky'
import { QueryClient } from 'react-query'

import {
    clearSelectionAction,
    createFolderAction,
    deleteAction,
    downloadAction,
    openSelectionAction,
    renameFolderAction,
    selectAllAction,
    toggleHiddenFileAction,
    uploadFileAction,
} from './customActions'

// displays actions based on user's role permission
export const getPermittedFileActions = (queryClient: QueryClient) => {
    let actions = [openSelectionAction, clearSelectionAction, selectAllAction, toggleHiddenFileAction]

    if (checkPermission(queryClient, PermissionKeys.MANAGE_FILES, SubPermissionKeys.DOCUMENT_DOWNLOAD)) {
        // @ts-ignore-start
        actions.unshift(downloadAction)
        // @ts-ignore-end
    }
    if (checkPermission(queryClient, PermissionKeys.MANAGE_FILES, SubPermissionKeys.DOCUMENT_DELETE)) {
        // @ts-ignore-start
        actions.unshift(deleteAction)
        // @ts-ignore-end
    }
    if (checkPermission(queryClient, PermissionKeys.MANAGE_FILES, SubPermissionKeys.DOCUMENT_UPLOAD)) {
        // @ts-ignore-start
        actions.unshift(uploadFileAction)
        // @ts-ignore-end
    }
    if (checkPermission(queryClient, PermissionKeys.MANAGE_FILES, SubPermissionKeys.DOCUMENT_RENAME)) {
        // @ts-ignore-start
        actions.unshift(renameFolderAction)
        // @ts-ignore-end
    }
    if (checkPermission(queryClient, PermissionKeys.MANAGE_FILES, SubPermissionKeys.FOLDER_CREATION)) {
        // @ts-ignore-start
        actions.unshift(createFolderAction)
        // @ts-ignore-end
    }

    return actions
}

// cleanup folderId
export const getParentFolderId = (pId: string | null) => {
    return pId === ROOT_FOLDER || !pId ? null : pId
}

export const openFileExplorer = (inputFile: React.MutableRefObject<null>) => {
    // @ts-ignore-start
    inputFile.current.click()
    // @ts-ignore-end
}

export const openFileInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener noreferrer')
}

// check the file extension of uploaded file
export const checkFileExtension = (file: File) => {
    let fileExtension = file.name.split('.')?.pop() || ''
    return ALLOWED_FILE_FORMATS.includes(fileExtension.toLocaleLowerCase())
}

// get file extensions for upload
export const getFileExtensions = () => {
    let fileExtensions = ALLOWED_FILE_FORMATS.map((extension) => `.${extension}`)
    return fileExtensions.toString()
}

export const getSelectedFileName = (selectedFile: ApiFolderAndFileDS) => {
    const { name } = selectedFile
    return name
}

export const getSelectedFileUrl = (selectedFile: ApiFolderAndFileDS) => {
    const { signedUrl } = selectedFile
    return signedUrl
}

export const getIntegerFileId = (draggedFile: FileData) => {
    if (typeof draggedFile.id === 'number') {
        return draggedFile.id
    } else if (typeof draggedFile.dbId === 'number') {
        return draggedFile.dbId
    } else {
        return ''
    }
}

export const getZipItems = (selectedFiles: ApiFolderAndFileDS[]) => {
    return selectedFiles.map((file) => {
        if (file.isDir && typeof file.id === 'number') {
            return {
                isDir: file.isDir,
                itemId: file.id,
                isUuid: false,
            }
        } else if (file.isDir && typeof file.id === 'string') {
            return {
                isDir: file.isDir,
                itemId: file.id,
                isUuid: true,
            }
        } else if (!file.isDir && typeof file.dbId === 'number') {
            return {
                isDir: file.isDir,
                itemId: file.dbId,
                isUuid: false,
            }
        } else {
            return {
                isDir: file.isDir,
                itemId: file.dbId,
                isUuid: true,
            }
        }
    })
}
