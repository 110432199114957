import { AxiosResponse } from "axios"
import { getErrorMessage } from "./utils"
import { HttpMethods, AlertVariant } from "../constants/constants"
import { listAllAddOnGroupApi, addOnGroupApi, addOnGroupSingleApi, cloneAddOnGroupApi } from "../constants/apiEndPoints"
import {
	Payload,
	DeleteAddonGroup,
	NewAddonGroup,
	SingleAddonGroup,
	SingleAddOnGroupResponse,
} from "../constants/staticTypes"
import { UseMutationResult } from "react-query"
import HttpServiceHelper from "./httpServiceHelper"

// initial form state
export const initialAddonGroupFormData = {
	addonGroupName: "",
	addonGroupPrice: "",
	selectedAddons: [],
}

// initial state for newAddon
export const initialAddOnGroupDeleteData: DeleteAddonGroup = {
	id: "",
}

// fetch all users
export const getAddOnsGroupList = (search: string, limit: number, lastKey: string) => {
	return HttpServiceHelper({
		url: listAllAddOnGroupApi + "?search=" + search + "&limit=" + limit + "&lastKey=" + lastKey,
		method: HttpMethods.GET,
	})
}

// create new add-on
export const createNewAddOnGroup = (
	addOnMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	addOnGroupData: NewAddonGroup,
	onSuccess: (message: string, variant: string) => void,
	onError: (res: string, variant: string) => void
) => {
	addOnMutation.mutate(
		{
			url: addOnGroupApi,
			method: HttpMethods.POST,
			data: addOnGroupData,
		},
		{
			onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

// update add-on
export const updateAddOnGroup = (
	addOnMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	addOnGroupData: NewAddonGroup,
	onSuccess: (message: string, variant: string) => void,
	onError: (res: string, variant: string) => void
) => {
	addOnMutation.mutate(
		{
			url: addOnGroupApi,
			method: HttpMethods.PUT,
			data: addOnGroupData,
		},
		{
			onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

// delete add-on
export const deleteAddOnGroup = (
	addOnMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	addOnGroupData: DeleteAddonGroup,
	onSuccess: (message: string, variant: string) => void,
	onError: (res: string, variant: string) => void
) => {
	addOnMutation.mutate(
		{
			url: addOnGroupApi,
			method: HttpMethods.DELETE,
			data: addOnGroupData,
		},
		{
			onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

// single add-on details
export const fetchSingleAddOnGroup = (
	addOnMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	param: SingleAddonGroup,
	onSuccess: (data: SingleAddOnGroupResponse) => void,
	onError: (res: string, variant: string) => void
) => {
	addOnMutation.mutate(
		{
			url: addOnGroupSingleApi,
			method: HttpMethods.POST,
			data: param,
		},
		{
			onSuccess: (res) => onSuccess(res.data.data),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

// add-on group clone
export const cloneAddOnGroup = (
	cloneAddOnMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	param: SingleAddonGroup,
	onSuccess: (res: string, variant: string) => void,
	onError: (res: string, variant: string) => void
) => {
	cloneAddOnMutation.mutate(
		{
			url: cloneAddOnGroupApi,
			method: HttpMethods.POST,
			data: param,
		},
		{
			onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}
