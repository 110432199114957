import React, { useEffect } from 'react'
import Lottie from 'react-lottie'
import animationData from './cupid.json'
import './CupidAnimation.css'

const CupidAnimation: React.FC = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  useEffect(() => {
    const moveAnimation = () => {
      // Ensure the animation stays within the viewport
      const animationContainer = document.querySelector('.animation-container')
      if (animationContainer) {
        const animationWidth = 100
        const animationHeight = 100

        // Consider scroll offsets
        const maxX = window.innerWidth - animationWidth
        const maxY = window.innerHeight - animationHeight

        const randomX = Math.random() * maxX
        const randomY = Math.random() * maxY
        //@ts-ignore
        animationContainer.style.transform = `translate(${randomX}px, ${randomY}px)`
      }
    }

    // Adjust the animation on scroll and resize
    const handleViewportChange = () => moveAnimation()
    window.addEventListener('scroll', handleViewportChange)
    window.addEventListener('resize', handleViewportChange)

    // Initial positioning
    moveAnimation()

    // Move the animation to a new random position every 5 seconds
    const intervalId = setInterval(moveAnimation, 5000)

    // Cleanup
    return () => {
      clearInterval(intervalId)
      window.removeEventListener('scroll', handleViewportChange)
      window.removeEventListener('resize', handleViewportChange)
    }
  }, [])

  return (
    <div className='animation-container'>
      <Lottie
        options={defaultOptions}
        height={100}
        width={100}
        isPaused={false}
        isStopped={false}
      />
    </div>
  )
}

export default CupidAnimation
