import React from 'react'
import Lottie from 'react-lottie'
import animationData from './cloverLeafAnimation.json'
import './cloverLeafAnimation.css'
import { getStPatricksDayEffects } from '../celebrationsConfigs'

const CloverLeafAnimation: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
	const stPatricksDayEffects = getStPatricksDayEffects()
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	}

	if (stPatricksDayEffects?.showSpecialLogo) {
		return (
			<div className="animation-container-clover" style={style}>
				<Lottie options={defaultOptions} height={70} width={70} isPaused={false} isStopped={false} />
			</div>
		)
	}
	return null
}

export default CloverLeafAnimation
