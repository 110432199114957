import { useEffect } from 'react'
import { getGoogleAnalyticsKey } from '../helpers/authHelper'
import { STAFF_ID, TENANT_NAME } from '../constants/constants'

// Declare the global gtag function
declare global {
	interface Window {
		gtag: (...args: any[]) => void
		dataLayer: any[]
	}
}

type EventProps = {
	eventName: string
	label?: any
	value?: any
	nonInteraction?: boolean
	transport?: 'beacon' | 'xhr' | 'image'
}

// Initialize Google Analytics only once
export const useInitializeGA = () => {
	useEffect(() => {
		const tenantName = localStorage.getItem(TENANT_NAME)
		const user_id = localStorage.getItem(STAFF_ID)
		const trackingId = getGoogleAnalyticsKey() // Your function to get the GA4 ID dynamically

		// Dynamically load the gtag script
		const script = document.createElement('script')
		script.async = true
		script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`
		document.head.appendChild(script)

		script.onload = () => {
			window.dataLayer = window.dataLayer || []
			window.gtag = function () {
				window.dataLayer.push(arguments)
			}
			window.gtag('js', new Date())
			window.gtag('config', trackingId, { send_page_view: false });
			window.gtag('set', { user_id: user_id })
			window.gtag('set', {
				user_properties: {
					tenant_name: tenantName,
					screen_orientation: window.matchMedia('(orientation: landscape)').matches ? 'landscape' : 'portrait',
				},
			})
		}
	}, [])
}

// Custom hook for tracking events
export const useTrackEvent = () => {
	const trackEvent = (props: EventProps) => {
		if (window.gtag) {
			window.gtag('event', props.eventName, {
				screen_orientation: window.matchMedia("(orientation: landscape)").matches ? 'landscape' : 'portrait',
				...(props.label && { label: props.label }),
				...props.value,
			})
		}
	}

	return { trackEvent }
}
