import { APPROVE, REJECT, CANCEL, DELETE, EDIT, MORE_INFO } from '../constants/strings'
import { AxiosResponse } from 'axios'
import { Badge } from 'primereact/badge'
import { FaEdit } from 'react-icons/fa'
import { getErrorMessage, checkPermission, popoverTemplate } from './utils'
import { HttpMethods, AlertVariant, PermissionKeys, SubPermissionKeys, PageMode } from '../constants/constants'
import { QueryClient, UseMutationResult } from 'react-query'
import { timeOffApi } from '../constants/apiEndPoints'
import * as Yup from 'yup'
import dayjs from 'dayjs'
import HttpServiceHelper from './httpServiceHelper'
import {
	AddTimeOff,
	AddTimeOffResponse,
	ConfirmPopupType,
	Payload,
	TimeOffEntry,
	Id,
	TimeOffRequestFormType,
	TimeOffRequestStatus,
	UpdateTimeOff,
} from '../constants/staticTypes'
import { OverlayTrigger } from 'react-bootstrap'
import { useTranslation } from '../hooks/useTranslation'
import { JTranslation } from './jTranslate'
import MlxTooltip from '../components/common/MlxTooltip'
import MlxPopover from '../components/common/MlxPopover'

export const fullDayValidationSchema = Yup.object({
	type: Yup.string(),
	user: Yup.string(),
	fromDate: Yup.date()
		.required('From date is required')
		.test('date-range', 'From date must be less than or equal to To date', function (fromDate, { parent }) {
			return dayjs(fromDate).isBefore(parent.toDate, 'day') || dayjs(fromDate).isSame(parent.toDate, 'day')
		}),
	toDate: Yup.date()
		.required('To date is required')
		.test('date-range', 'To date must be greater than or equal to From date', function (toDate, { parent }) {
			return dayjs(toDate).isAfter(parent.fromDate, 'day') || dayjs(toDate).isSame(parent.fromDate, 'day')
		}),
	reason: Yup.string().required('Reason is required'),
	note: Yup.string(),
	staffId: Yup.string().required('User is required'),
})

export const timeOffValidationSchema = Yup.object({
	type: Yup.string(),
	user: Yup.string(),
	fromDate: Yup.date().required('From date is required'),
	startTime: Yup.object().required('Start time is required'),
	endTime: Yup.object()
		.required('End time is required')
		.test('time-range', 'End time must be at least 1 hour later than Start time', function (_endTime, { parent }) {
			const startDateTime = dayjs(parent.startTime)
			const endDateTime = dayjs(parent.endTime)
			return endDateTime.isAfter(startDateTime.add(59, 'minute'))
		}),
	reason: Yup.string().required('Reason is required'),
	note: Yup.string(),
	staffId: Yup.string().required('User is required'),
})

export const initialValuesForTimeOff: TimeOffRequestFormType = {
	type: 'full-day',
	fromDate: dayjs().startOf('day').toDate(),
	toDate: dayjs().endOf('day').toDate(),
	startTime: dayjs().set('hour', 8).set('minute', 0).set('second', 0),
	endTime: dayjs().set('hour', 10).set('minute', 0).set('second', 0),
	staffId: '',
	note: '',
	duration: '1 day',
	reason: '',
}

export const initialValuesForConfirmPopup: ConfirmPopupType = {
	visible: false,
	buttonName: '',
	requestId: '',
	action: 'PENDING',
}

export type ChangeStatusTemplateType = {
	timeOff: TimeOffEntry
	queryClient: QueryClient
	setMode: React.Dispatch<React.SetStateAction<PageMode>>
	setRequestInfo: React.Dispatch<React.SetStateAction<ConfirmPopupType>>
	setShowWarning: React.Dispatch<React.SetStateAction<boolean>>
	note: JSX.Element | null
	isMobileView?: boolean
	translateEdit: string,
	translateDelete: string,
}

export const timeOffActionTemplate = ({
	timeOff,
	queryClient,
	setRequestInfo,
	setShowWarning,
	setMode,
	note,
	isMobileView,
	translateEdit,
	translateDelete,
}: ChangeStatusTemplateType) => {
	// nosonar
	const { id, actionStatus } = timeOff

	switch (actionStatus) {
		case 'PENDING':
			return (
				<div
					className={`w-100 d-flex ${
						isMobileView ? 'justify-content-between flex-row-reverse' : 'justify-content-even'
					} align-items-center`}
				>
					<div className="d-flex align-items-center">
						<button
							className={`btn btn-sm btn-success me-2 ${isMobileView && 'rounded-circle'} ${
								!checkPermission(
									queryClient,
									PermissionKeys.MANAGE_TIME_OFF,
									SubPermissionKeys.MANAGE_REQUEST
								) && ' disabled'
							}`}
							type="button"
							id={id}
							onClick={(e) => {
								e.stopPropagation()
								if (
									checkPermission(
										queryClient,
										PermissionKeys.MANAGE_TIME_OFF,
										SubPermissionKeys.MANAGE_REQUEST
									)
								) {
									// open confirmation popup
									setRequestInfo({ buttonName: id, visible: true, requestId: id, action: 'APPROVED' })
								}
							}}
						>
							<i className={`ri-check-fill ${!isMobileView && 'me-1'}`}></i>
							{!isMobileView && <JTranslation typeCase="pascal" text={APPROVE} />}
						</button>

						<button
							className={`btn btn-danger btn-sm me-2 ${isMobileView && 'rounded-circle'} ${
								!checkPermission(
									queryClient,
									PermissionKeys.MANAGE_TIME_OFF,
									SubPermissionKeys.MANAGE_REQUEST
								) && ' disabled'
							}`}
							type="button"
							onClick={(e) => {
								e.stopPropagation()
								if (
									checkPermission(
										queryClient,
										PermissionKeys.MANAGE_TIME_OFF,
										SubPermissionKeys.MANAGE_REQUEST
									)
								) {
									// open reject popup
									setRequestInfo({
										buttonName: id,
										visible: false,
										requestId: id,
										action: 'REJECTED',
									})
									setShowWarning(true)
								}
							}}
						>
							<i className={`ri-close-fill ${!isMobileView && 'me-1'}`}></i>
							{!isMobileView && <JTranslation typeCase="pascal" text={REJECT} />}
						</button>
					</div>

					<div className="d-flex align-items-center">
						{note && (
							<MlxPopover data={{title:MORE_INFO, body:note}}/>

						)}
						<div className={isMobileView ? 'rounded-background shadow-sm' : 'mb-1 ms-2'}>
							<MlxTooltip target='.edit'>
								<FaEdit
									data-pr-tooltip={translateEdit}
									data-pr-position="bottom"
									className={
										'edit  ' +
										(!checkPermission(
											queryClient,
											PermissionKeys.MANAGE_TIME_OFF,
											SubPermissionKeys.EDIT
										) && ' disabled')
									}
									onClick={() => {
										if (
											checkPermission(
												queryClient,
												PermissionKeys.MANAGE_TIME_OFF,
												SubPermissionKeys.EDIT
											)
										) {
											setRequestInfo({
												buttonName: id,
												visible: false,
												requestId: id,
												action: 'EDITED',
											})
											setMode(PageMode.EDIT)
										}
									}}
								/>
							</MlxTooltip>
						</div>

						<div className={isMobileView ? 'rounded-background shadow-sm' : 'ms-2'}>
							<MlxTooltip target='.delete'>
								<i
									className={
										'ri-delete-bin-line delete ' +
										(!checkPermission(
											queryClient,
											PermissionKeys.MANAGE_TIME_OFF,
											SubPermissionKeys.DELETE
										) && ' disabled')
									}
									data-pr-tooltip={translateDelete}
									data-pr-position='bottom'
									onClick={(e) => {
										e.stopPropagation()
										// open reject popup
										if (
											checkPermission(
												queryClient,
												PermissionKeys.MANAGE_TIME_OFF,
												SubPermissionKeys.DELETE
											)
										) {
											setRequestInfo({
												buttonName: id,
												visible: false,
												requestId: id,
												action: 'DELETE',
											})
											setShowWarning(true)
										}
									}}
								></i>
							</MlxTooltip>
						</div>
					</div>
				</div>
			)

		case 'APPROVED':
			return (
				<>
					<Badge className="me-2 custom-badge" value={<JTranslation typeCase="pascal" text={"Approved"} />} severity="success"></Badge>

					<button
						className={`btn btn-secondary btn-sm me-2 ${isMobileView && 'rounded-circle'} ${
							!checkPermission(
								queryClient,
								PermissionKeys.MANAGE_TIME_OFF,
								SubPermissionKeys.MANAGE_REQUEST
							) && ' disabled'
						}`}
						type="button"
						onClick={(e) => {
							e.stopPropagation()
							if (
								checkPermission(
									queryClient,
									PermissionKeys.MANAGE_TIME_OFF,
									SubPermissionKeys.MANAGE_REQUEST
								)
							) {
								// open reject popup
								setRequestInfo({ buttonName: id, visible: false, requestId: id, action: 'CANCELLED' })
								setShowWarning(true)
							}
						}}
					>
						<i className={`ri-close-fill ${!isMobileView && 'me-1'}`}></i>
						{!isMobileView && <JTranslation typeCase="pascal" text={CANCEL} />}
					</button>
				</>
			)

		case 'CANCELLED':
			return (
				<div
					className={`w-100 d-flex ${
						isMobileView ? 'justify-content-between flex-row-reverse' : 'justify-content-even'
					} align-items-center`}
				>
					<Badge className="me-2 custom-badge" value={<JTranslation typeCase="pascal" text={"Cancelled"} />} severity="warning"></Badge>
					{note && (
						<MlxPopover data={{title:MORE_INFO, body:note}}/>

					)}
				</div>
			)

		case 'REJECTED':
			return (
				<div
					className={`w-100 d-flex ${
						isMobileView ? 'justify-content-between flex-row-reverse' : 'justify-content-even'
					} align-items-center`}
				>
					<Badge className="me-2 custom-badge" value={<JTranslation typeCase="pascal" text={"Rejected"} />} severity="danger"></Badge>
					{note && (
						<MlxPopover data={{title:MORE_INFO, body:note}}/>
					)}
				</div>
			)

		default:
			return null
	}
}

export const timeOffActionTemplateHistory = ({
	timeOff,
	queryClient,
	setRequestInfo,
	setShowWarning,
	setMode,
	note,
	isMobileView,
	translateEdit,
	translateDelete,
}: ChangeStatusTemplateType) => {
	// nosonar
	const { id, actionStatus } = timeOff

	switch (actionStatus) {
		case 'PENDING':
			return (
				<div className={`w-100 d-flex justify-content-start align-items-center`}>
					{!isMobileView && (
						<Badge className="me-2 custom-badge" value={<JTranslation typeCase="pascal" text={"Pending"} />} severity="warning"></Badge>
					)}
					<div className={isMobileView ? 'rounded-background shadow-sm' : 'mb-1 ms-2'}>
						<MlxTooltip target='.edit'>
							<FaEdit
								data-pr-tooltip={translateEdit}
								data-pr-position='bottom'
								className={`edit  ${
									!checkPermission(
										queryClient,
										PermissionKeys.MANAGE_MY_TIME_OFF,
										SubPermissionKeys.MY_TIME_OFF
									) && ' disabled'
								}`}
								onClick={(e) => {
									e.stopPropagation()
									if (
										checkPermission(
											queryClient,
											PermissionKeys.MANAGE_MY_TIME_OFF,
											SubPermissionKeys.MY_TIME_OFF
										)
									) {
										setRequestInfo({ buttonName: id, visible: false, requestId: id, action: 'EDITED' })
										setMode(PageMode.EDIT)
									}
								}}
							/>
						</MlxTooltip>
					</div>
					<div className={isMobileView ? 'rounded-background shadow-sm' : 'ms-2'}>
						<MlxTooltip target='.delete'>
							<i
								className={`ri-delete-bin-line delete ${
									!checkPermission(
										queryClient,
										PermissionKeys.MANAGE_MY_TIME_OFF,
										SubPermissionKeys.MY_TIME_OFF
									) && ' disabled'
								}`}
								data-pr-tooltip={translateDelete}
								data-pr-position='bottom'
								onClick={(e) => {
									e.stopPropagation()
									// open reject popup
									if (
										checkPermission(
											queryClient,
											PermissionKeys.MANAGE_MY_TIME_OFF,
											SubPermissionKeys.MY_TIME_OFF
										)
									) {
										setRequestInfo({ buttonName: id, visible: false, requestId: id, action: 'DELETE' })
										setShowWarning(true)
									}
								}}
							></i>
						</MlxTooltip>
					</div>					
					{isMobileView && (
						<Badge className="me-2 custom-badge ms-auto" value={<JTranslation typeCase="pascal" text={"Pending"} />} severity="warning"></Badge>
					)}
				</div>
			)

		case 'APPROVED':
			return (
				<>
					<Badge className="me-2 custom-badge" value={<JTranslation typeCase="pascal" text={"Approved"} />} severity="success"></Badge>

					<button
						className={`btn btn-danger btn-sm me-2 ${isMobileView && 'rounded-circle'}`}
						type="button"
						onClick={(e) => {
							e.stopPropagation()
							// open reject popup
							setRequestInfo({ buttonName: id, visible: false, requestId: id, action: 'CANCELLED' })
							setShowWarning(true)
						}}
					>
						<i className={`ri-close-fill ${!isMobileView && 'me-1'}`}></i>
						{!isMobileView && <JTranslation typeCase="pascal" text={CANCEL} />}
					</button>
				</>
			)

		case 'CANCELLED':
			return (
				<div
					className={`w-100 d-flex ${
						isMobileView ? 'justify-content-between flex-row-reverse' : 'justify-content-even'
					} align-items-center`}
				>
					<Badge className="me-2 custom-badge" value={<JTranslation typeCase="pascal" text={"Cancelled"} />} severity="warning"></Badge>
					{note && (
						<MlxPopover data={{title:MORE_INFO, body:note}}/>
					)}
				</div>
			)

		case 'REJECTED':
			return (
				<div
					className={`w-100 d-flex ${
						isMobileView ? 'justify-content-between flex-row-reverse' : 'justify-content-even'
					} align-items-center`}
				>
					<Badge className="me-2 custom-badge" value={<JTranslation typeCase="pascal" text={"Rejected"} />} severity="danger"></Badge>
					{note && (
						<MlxPopover data={{title:MORE_INFO, body:note}}/>
					)}
				</div>
			)

		default:
			return null
	}
}

// fetch user's time-off requests
export const getAllUserTimeOffRequests = ({ limit, search = '' }: { limit: number; search?: string }) => {
	return HttpServiceHelper({
		url: `${timeOffApi}/list?limit=${limit}&search=${search}`,
		method: HttpMethods.GET,
	})
}

export const addTimeOffRequest = (
	timeOffMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	params: AddTimeOff,
	onSuccess: (data: AddTimeOffResponse) => void,
	onError: (res: string, variant: string) => void
) => {
	timeOffMutation.mutate(
		{
			url: `${timeOffApi}/requests`,
			method: HttpMethods.POST,
			data: params,
		},
		{
			onSuccess: (res) => onSuccess(res.data),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

export const updateTimeOffRequest = (
	timeOffMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	params: UpdateTimeOff,
	onSuccess: (data: AddTimeOffResponse) => void,
	onError: (res: string, variant: string) => void
) => {
	timeOffMutation.mutate(
		{
			url: `${timeOffApi}/requests`,
			method: HttpMethods.PUT,
			data: params,
		},
		{
			onSuccess: (res) => onSuccess(res.data),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

export const timeOffChangeRequest = (
	timeOffMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	params: TimeOffRequestStatus,
	onSuccess: (data: AddTimeOffResponse) => void,
	onError: (res: string, variant: string) => void
) => {
	timeOffMutation.mutate(
		{
			url: `${timeOffApi}/status`,
			method: HttpMethods.POST,
			data: params,
		},
		{
			onSuccess: (res) => onSuccess(res.data),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

export const timeOffDelete = (
	timeOffMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	params: Id,
	onSuccess: (data: AddTimeOffResponse) => void,
	onError: (res: string, variant: string) => void
) => {
	timeOffMutation.mutate(
		{
			url: `${timeOffApi}/id`,
			method: HttpMethods.DELETE,
			data: params,
		},
		{
			onSuccess: (res) => onSuccess(res.data),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

export const getFullDataById = (
	timeOffMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	params: Id,
	onSuccess: (data: TimeOffEntry) => void,
	onError: (res: string, variant: string) => void
) => {
	timeOffMutation.mutate(
		{
			url: `${timeOffApi}/id`,
			method: HttpMethods.POST,
			data: params,
		},
		{
			onSuccess: (res) => onSuccess(res.data.data),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

// fetch user's time-off requests
export const getAllByUser = ({ id, limit, search = '' }: { id: string; limit: number; search?: string }) => {
	return HttpServiceHelper({
		url: `${timeOffApi}/user?limit=${limit}&search=${search}`,
		method: HttpMethods.POST,
		data: {
			id: id,
		},
	})
}
