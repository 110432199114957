import { useState, useEffect, useContext } from 'react'
import { TranslationContext, jTranslationText } from '../helpers/jTranslate'

// Custom hook for translating text
export function useTranslation(initialText: string, typeCase?: 'upper' | 'lower' | 'capitalize' | 'pascal' | 'none') {
	const translationContext = useContext(TranslationContext)
	const [translatedText, setTranslatedText] = useState('')

	useEffect(() => {
		async function fetchTranslation() {
			try {
				const translatedText = await jTranslationText({
					text: initialText,
                    typeCase,
					translationContext,
				})
				setTranslatedText(translatedText ?? initialText)
			} catch (error) {
				console.error('Error translating text:', error)
				setTranslatedText(initialText)
			}
		}

		fetchTranslation()
	}, [initialText, translationContext, typeCase]) // Runs whenever initialText or translationContext changes

	return translatedText
}
