import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ADD_ITEM_TITLE, CANCEL, EDIT_ITEM_TITLE, SAVE, UPDATE_WARNING, USER_UPLOAD_PHOTO_ERROR, USER_UPLOAD_VIDEO_ERROR } from "../../../constants/strings";
import { ADD_ONS_GROUP_LIST, ADD_ONS_LIST, INGREDIENTS_LIST } from "../../../constants/queryKeys";
import { AlertVariant, FileType, ItemType } from "../../../constants/constants";
import { AxiosResponse } from "axios";
import { createAndUpdateCustomItem } from "../../../helpers/customMenuHelper";
import { getAddOnsList } from "../../../helpers/manageAddOnsHelper";
import { getIngredientsList } from "../../../helpers/manageIngredientsHelper";
import { getInitialAddItemFormData, upsertItem } from "../../../helpers/menuManagementHelper";
import { isItemUnchanged } from "../../../helpers/utils";
import { MenuItem, Menu, SubCategory, Addon, Ingredient, AddonGroup } from "../../../constants/staticTypes";
import { useQueryClient } from "react-query";
import AddBeverageForm from "./add_item_forms/AddBeverageForm";
import AddFoodForm from "./add_item_forms/AddFoodForm";
import useMutationHook from "../../../hooks/useMutationHook";
import useQueryHook from "../../../hooks/useQueryHook";
import WarningModal from "../../warning_modal/WarningModal";
import { getAddOnsGroupList } from "../../../helpers/manageAddOnsGroupHelper";
import { JTranslation } from "../../../helpers/jTranslate";

type Props = {
    setAddItem: React.Dispatch<React.SetStateAction<boolean>>,
    displayToast: (message: string, variant: string) => void,
    itemCategoryId: string | undefined,
    onSuccess: (message: string) => void,
    editItem: MenuItem | Menu | SubCategory | undefined,
    parentComponent?: "F&B_MENU" | "CUSTOM_F&B_MENU",
    customMenuMode?: "CREATE" | "UPDATE",
    isFoodForm: boolean,
    setFormType: Dispatch<SetStateAction<boolean>>
}

function AddItem({ isFoodForm, setFormType, setAddItem, displayToast, itemCategoryId, onSuccess, editItem, parentComponent = "F&B_MENU", customMenuMode = "CREATE" }: Props) {
    const queryClient = useQueryClient();
    const [formData, setFormData] = useState<MenuItem>(getInitialAddItemFormData(itemCategoryId, isFoodForm));
    const [initialFormData, setInitialFormData] = useState<MenuItem>(getInitialAddItemFormData(itemCategoryId, isFoodForm));
    const uploadFileMutation = useMutationHook(queryClient, true);
    const upsertMenuItem = useMutationHook(queryClient, true);
    const [images, setImages] = useState<string[]>([]);
    const [videos, setVideos] = useState<string[]>([]);
    const [warning, showWarning] = useState(false);
    const [disabledType, setDisabledType] = useState("");
    const [emptyPriceFound, setEmptyPriceFound] = useState(false);
    const [addOnList, setAddOnList] = useState<Addon[]>([])
    const [ingredientsList, setIngredientsList] = useState<Ingredient[]>([])
    const [addOnGroupList, setAddOnGroupList] = useState<AddonGroup[]>([])

    useEffect(() => {
        if (editItem) {
            let item = editItem as MenuItem;
            setFormType(item.itemType === ItemType.FOOD ? true : false);
            setDisabledType(item.itemType === ItemType.FOOD ? ItemType.BEVERAGE : ItemType.FOOD);
            setImages(item.signedPhotoKeys ? item.signedPhotoKeys : []);
            setVideos(item.signedVideoKeys ? item.signedVideoKeys : []);
            delete item.signedPhotoKeys;
            delete item.signedVideoKeys;

            setFormData({ ...item, itemCategoryId })
            setInitialFormData({
                ...item,
                itemCategoryId,
                itemPrices: [...item.itemPrices],
                additionalInfo: [...item.additionalInfo],
            })
        }
    }, [editItem, itemCategoryId])

    const apiCallForSave = () => {
        if (parentComponent === "F&B_MENU") {
            // api call
            upsertItem(upsertMenuItem, formData, onSuccess, (message, variant) =>
                displayToast(message, variant)
            )
        } else if (parentComponent === "CUSTOM_F&B_MENU" && customMenuMode === "CREATE") {
            // api call
            createAndUpdateCustomItem(upsertMenuItem, formData, onSuccess, (message, variant) =>
                displayToast(message, variant)
            )
        } else if (parentComponent === "CUSTOM_F&B_MENU" && customMenuMode === "UPDATE") {
            const params = {
                ...formData,
                // @ts-ignore
                itemMenuId: editItem?.itemMenuId!,
                itemCategoryId: itemCategoryId,
            }
            // api call
            createAndUpdateCustomItem(upsertMenuItem, params, onSuccess, (message, variant) =>
                displayToast(message, variant)
            )
        }
    }

    // ingredients list fetch success
    const onSuccessOfIngredientsList = (res: AxiosResponse<any, any>) => {
        const data = res.data?.data.list
        const newData = data.filter((item: any) => item.ingredientName !== null) //removing null items
        setIngredientsList(newData.sort((a: any, b: any) => a.ingredientName.localeCompare(b.ingredientName)))
    }

    // fetch ingredients list
    useQueryHook(INGREDIENTS_LIST, () => getIngredientsList('', 500, ''), onSuccessOfIngredientsList)

    // addOns list fetch success
    const onAddOnListFetchSuccess = (res: AxiosResponse<any, any>) => {
        const data = res.data?.data.list
        const newData = data.filter((item: any) => item.addonName !== null) //removing null items
        setAddOnList(newData.sort((a: any, b: any) => a.addonName.localeCompare(b.addonName)));
    }

    // fetch addOns list
    useQueryHook(ADD_ONS_LIST, () => getAddOnsList('', 500, ''), onAddOnListFetchSuccess)

    // addOns Group list fetch success
    const onAddOnGroupListFetchSuccess = (res: AxiosResponse<any, any>) => {
        const data = res.data?.data.list
        const newData = data.filter((item: any) => item.addonGroupName !== null) //removing null items
        setAddOnGroupList(newData.sort((a: any, b: any) => a.addonGroupName.localeCompare(b.addonGroupName)));
    }

    // fetch addOns Group list
    useQueryHook(ADD_ONS_GROUP_LIST, () => getAddOnsGroupList('', 500, ''), onAddOnGroupListFetchSuccess)

    // on file upload success
    const onUploadSuccess = (key: string, fileUrl: string, fileType: FileType) => {
        if (fileType === FileType.IMAGE) {
            setFormData({ ...formData, photoKeys: [...formData.photoKeys, key] });
            setImages([...images, fileUrl]);
        } else {
            setFormData({ ...formData, videoKeys: [...formData.videoKeys, key] });
            setVideos([...videos, fileUrl]);
        }
    }

    // on file upload error
    const onUploadError = (fileType: FileType) =>
        displayToast(fileType === FileType.IMAGE ? USER_UPLOAD_PHOTO_ERROR : USER_UPLOAD_VIDEO_ERROR, AlertVariant.ERROR);

    // on form type radio button change
    const onFormTypeCheckedChange = (type: string) => {
        setFormData({
            ...formData,
            itemType: type
        });
        setFormType(!isFoodForm);
    }

    // on delete image or video
    const onDeleteFile = (fileType: FileType, index: number) => {
        if (fileType === FileType.IMAGE) {
            formData.photoKeys.splice(index, 1);
            images.splice(index, 1);
            setFormData({ ...formData, photoKeys: formData.photoKeys });
            setImages(images);
        } else {
            formData.videoKeys.splice(index, 1);
            videos.splice(index, 1);
            setFormData({ ...formData, videoKeys: formData.videoKeys });
            setVideos(videos);
        }
    }

    useEffect(() => {
        let emptyValue = false

        formData.itemPrices.forEach(item => {
            if (!item.fieldValue) emptyValue = true
        })

        !emptyValue && formData.addonPrices.forEach(item => {
            if (!item.fieldName) emptyValue = true
        })

        emptyValue ? setEmptyPriceFound(true) : setEmptyPriceFound(false)
    }, [formData, emptyPriceFound])

    return (
        <>
            <WarningModal show={warning} title={UPDATE_WARNING} onHide={() => showWarning(!warning)} callback={() => {
                showWarning(false);
                setAddItem(false);
            }} />
            <div className="col-md-12 col-lg-12 mb-3 h-100 ">
                <div className="card   h-100 ">
                    <div className="card-header flex-grow-0">
                        <div className="d-flex  align-items-center py-1">
                            <div className='button btn flex-grow-0 me-2 back-btn back-btn-new' data-testid="back-btn" onClick={() => showWarning(true)}>
                                <i className='ri-arrow-left-line'></i>
                            </div>
                            <div className="flex-grow-1">
                                <h4 className="mb-0 title">{editItem
                                    ? <JTranslation typeCase="pascal" text={EDIT_ITEM_TITLE} />
                                    : <JTranslation typeCase="pascal" text={ADD_ITEM_TITLE} />
                                }</h4>
                            </div>
                        </div>
                    </div>

                    <div className="card-body flex-grow-1 overflow-auto">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="add-item-section">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-check form-check-inline mb-3">
                                                <input className="form-check-input" type="radio" data-testid="inlineRadio1" name="inlineRadioOptions" id="inlineRadio1" value="option1"
                                                    autoComplete="off"
                                                    disabled={disabledType === ItemType.FOOD}
                                                    checked={isFoodForm} onChange={() => {
                                                        if (disabledType !== ItemType.FOOD) {
                                                            onFormTypeCheckedChange(ItemType.FOOD)
                                                        }
                                                    }} />
                                                <label className="form-check-label fw-bold" htmlFor="inlineRadio1"><JTranslation typeCase="pascal" text={"Food"} /></label>
                                            </div>
                                            <div className="form-check form-check-inline mb-3">
                                                <input className="form-check-input" type="radio" data-testid="inlineRadio2" name="inlineRadioOptions" id="inlineRadio2" value="option2"
                                                    autoComplete="off"
                                                    disabled={disabledType === ItemType.BEVERAGE}
                                                    checked={!isFoodForm} onChange={() => {
                                                        if (disabledType !== ItemType.BEVERAGE) {
                                                            onFormTypeCheckedChange(ItemType.BEVERAGE)
                                                        }
                                                    }} />
                                                <label className="form-check-label fw-bold" htmlFor="inlineRadio2"><JTranslation typeCase="pascal" text={"Beverage"} /></label>
                                            </div>
                                        </div>
                                        <hr className="mb-0"></hr>
                                        {isFoodForm ? (
                                            <AddFoodForm
                                                displayToast={displayToast}
                                                formData={formData}
                                                images={images}
                                                onDeleteFile={onDeleteFile}
                                                onUploadError={onUploadError}
                                                onUploadSuccess={onUploadSuccess}
                                                setFormData={setFormData}
                                                setImages={setImages}
                                                uploadFileMutation={uploadFileMutation}
                                                videos={videos}
                                                addOnList={addOnList}
                                                addOnGroupList={addOnGroupList}
                                                ingredientsList={ingredientsList}
                                                parentComponent={parentComponent}
                                            />
                                        ) : (
                                            <AddBeverageForm
                                                displayToast={displayToast}
                                                formData={formData}
                                                images={images}
                                                onDeleteFile={onDeleteFile}
                                                onUploadError={onUploadError}
                                                onUploadSuccess={onUploadSuccess}
                                                setFormData={setFormData}
                                                setImages={setImages}
                                                uploadFileMutation={uploadFileMutation}
                                                videos={videos}
                                                addOnList={addOnList}
                                                addOnGroupList={addOnGroupList}
                                                ingredientsList={ingredientsList}
                                                parentComponent={parentComponent}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="d-flex flex-grow-0">
                        <div className="save-btn-section shadow">
                            <button className="btn btn-custom-primary-outline" data-testid="cancel-button" type="button" onClick={() => {
                                if (isItemUnchanged(initialFormData, formData)) {
                                    setAddItem(false);
                                } else {
                                    showWarning(true);
                                }
                            }}><JTranslation typeCase="pascal" text={CANCEL} /></button>
                            <button
                                className="btn btn-custom-primary"
                                type="button"
                                data-testid="save-button"
                                disabled={formData.itemName?.trim().length === 0 || emptyPriceFound}
                                onClick={(e) => {
                                    e.preventDefault()
                                    apiCallForSave()
                                }}
                            >
                                <JTranslation typeCase="pascal" text={SAVE} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddItem;