import { useState, useEffect } from 'react'
import { AxiosResponse } from 'axios'
import { CUSTOM_MENU_LIST } from '../../constants/queryKeys'
import { CreateCustomMenu, DeleteCustomMenuType, ToastMessageProps } from '../../constants/staticTypes'
import { DELETE_WARNING, MANAGE_CUSTOM_MENU, CUSTOM_MENU_HELP } from '../../constants/strings'
import { getCustomMenuList, deleteCustomMenu, initialCustomMenuDeleteData } from '../../helpers/customMenuHelper'
import { getValueAfterHash } from '../../helpers/menuViewHelper'
import { OverlayTrigger } from 'react-bootstrap'
import { popover } from '../../helpers/utils'
import { routes } from '../../constants/routes'
import { toastMessageInitialData } from '../../constants/constants'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import CustomMenuListDataGrid from '../../components/manage_custom_menu/CustomMenuListDataGrid'
import Loader from '../../components/loader/Loader'
import ToastAlert from '../../components/alert/ToastAlert'
import useMutationHook from '../../hooks/useMutationHook'
import useQueryHook from '../../hooks/useQueryHook'
import WarningModal from '../../components/warning_modal/WarningModal'
import withSidebar from '../../hoc/withSidebar'
import { JTranslation } from '../../helpers/jTranslate'
import MlxPopover from '../../components/common/MlxPopover'

function ManageCustomMenu() {
    const queryClient = useQueryClient()
    const customMenuMutation = useMutationHook(queryClient, true)
    const navigate = useNavigate();
    // ALERT VARIABLES
    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
    // WARNING MODAL
    const [showWarning, setWarning] = useState(false) // Warning popup
    const [warningMessage, setWarningMessage] = useState('') // Warning message
    // STATE VARIABLES
    const [customMenuList, setCustomMenuList] = useState<CreateCustomMenu[]>([])
    const [editCustomMenu, setCustomMenu] = useState<CreateCustomMenu>()
    const [deleteCustomMenuData, setDeleteCustomMenuData] = useState<DeleteCustomMenuType>(initialCustomMenuDeleteData)

    useEffect(() => {
        const res = queryClient.getQueryData(CUSTOM_MENU_LIST) as AxiosResponse<any, any>;
        let data = res?.data?.data as CreateCustomMenu[]
        if (data) setCustomMenuList(data)
    }, [queryClient])

    // customMenus list fetch success
    const onSuccessOfCustomMenuList = (res: AxiosResponse<any, any>) => {
        setCustomMenuList(res.data?.data)
    }

    // fetch customMenus list
    const customMenuData = useQueryHook(CUSTOM_MENU_LIST, () => getCustomMenuList('', 500, ''), onSuccessOfCustomMenuList)

    if (customMenuData.isLoading) {
        return <Loader />
    }

    // on trigger search
    const onSearchTrigger = (searchKey: string) => {
        if (customMenuData.isFetching) {
            queryClient.cancelQueries([CUSTOM_MENU_LIST]) // nosonar
        }
        queryClient.fetchQuery(CUSTOM_MENU_LIST, () => getCustomMenuList(searchKey, 500, '')) // nosonar
    }

    // on add or update customMenus success
    const onSuccess = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
        queryClient.refetchQueries([CUSTOM_MENU_LIST]) // nosonar
    }

    // on add or update customMenus error
    const onError = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
    }

    // on edit button click
    const onEditClick = (customMenu: CreateCustomMenu) => {
        setCustomMenu(customMenu)
        navigate(`${routes.custom_menu_list}/${getValueAfterHash(customMenu.id)}`)
    }

    // on delete button click
    const onDeleteClick = (menu: CreateCustomMenu) => {
        setDeleteCustomMenuData({ menuId: menu.id })
        setWarningMessage(DELETE_WARNING)
        setWarning(true)
    }

    // api call to delete add-on
    const deleteCustomMenuApiCall = () => {
        deleteCustomMenu(customMenuMutation, deleteCustomMenuData, onSuccess, onError)
    }

    return (
        <>
            <WarningModal
                show={showWarning}
                title={warningMessage}
                onHide={() => {
                    setWarning(false)
                    setDeleteCustomMenuData(initialCustomMenuDeleteData)
                }}
                callback={() => {
                    deleteCustomMenuApiCall()
                    setWarning(false)
                }}
            />

            <ToastAlert
                data-testid="toast"
                message={toastMessage.message}
                onClose={() => setToastMessage(toastMessageInitialData)}
                show={toastMessage.show}
                variant={toastMessage.variant}
            />

            <div className="col-md-12 col-lg-12 mb-3 h-100 ">
                <div className="page-title">
                    <h5 className="d-flex"><JTranslation typeCase="pascal" text={MANAGE_CUSTOM_MENU} />
                        <MlxPopover data={{title:MANAGE_CUSTOM_MENU, body:CUSTOM_MENU_HELP}}/>
                    </h5>
                </div>

                <CustomMenuListDataGrid
                    customMenuList={customMenuList}
                    editCustomMenu={editCustomMenu}
                    loading={customMenuData.isLoading}
                    onDeleteClick={onDeleteClick}
                    onEditClick={onEditClick}
                    onSearchTrigger={onSearchTrigger}
                />
            </div>
        </>
    )
}
export default withSidebar(ManageCustomMenu)
