import React, { useContext, useEffect, useState } from "react";
import 'react-image-crop/src/ReactCrop.scss';
import { ADD, ADD_ADD_ONS_GROUP, CANCEL } from "../../../../constants/strings";
import { Modal } from "react-bootstrap";
import { AddonGroup, DisplayModalType, MenuItem } from "../../../../constants/staticTypes";
import Select from 'react-select';
import { JTranslation, TranslationContext, jTranslationText } from "../../../../helpers/jTranslate";

type Props = {
    showAddonGroupModal: boolean,
    setAddonGroupModal: React.Dispatch<React.SetStateAction<boolean>>,
    modalHeight?: string,
    hideModal?: (param: DisplayModalType) => void,
    formData: MenuItem,
    setFormData: React.Dispatch<React.SetStateAction<MenuItem>>,
    addOnGroupList: AddonGroup[],
}

function AddAddonGroupModal({ 
    showAddonGroupModal, 
    setAddonGroupModal, 
    modalHeight, 
    hideModal, 
    formData, 
    setFormData, 
    addOnGroupList,
}: Props) {

    const [selectedAddOnGroup, setSelectedAddOnGroup] = useState<AddonGroup | undefined>();
    const [translationText, setTranslatedText] = useState<{
        loading: string,
        select: string,
      }>({ 
        loading: 'Loading...',
        select: 'Select',
    });

    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext

    // hide modal
    const onHide = () => {
        setSelectedAddOnGroup(undefined)
        setAddonGroupModal(false)
    }

    const setFormDataAndCloseModal = () => {
        if(selectedAddOnGroup) {
            if(!formData.selectedAddonGroups) {
                formData.selectedAddonGroups = [];
            } 
            const checkIfAlreadyAdded = formData?.selectedAddonGroups?.find(addOnGroup => addOnGroup.addonGroupId === selectedAddOnGroup.id);
            if(!checkIfAlreadyAdded && selectedAddOnGroup?.id) {
                formData.selectedAddonGroups.push({
                    addonGroupDiscount: '0',
                    addonGroupId: selectedAddOnGroup?.id,
                    addonGroupPrice: selectedAddOnGroup?.addonGroupPrice
                })
                setFormData(formData);
            }
        }
        setSelectedAddOnGroup(undefined)
        setAddonGroupModal(false)
    }

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: 'Loading...', typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: 'Select', typeCase: 'pascal', translationContext }),
                ])
                setTranslatedText({
                    loading: translations[0] ?? 'Loading...',
                    select: translations[1] ?? 'Select',
                })
            } catch {
                setTranslatedText({
                    loading: 'Loading...',
                    select: 'Select',
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    return (
        <Modal centered show={showAddonGroupModal}>
            <Modal.Header><h5 className="mb-0"><JTranslation typeCase="pascal" text={ADD_ADD_ONS_GROUP} /></h5></Modal.Header>
            <Modal.Body className="col-sm-12">
            <div className="flex-grow-0 d-flex flex-column justify-content-between ">
                <div className="row">
                    <div className="col-md-12">
                        <Select
                            options={addOnGroupList
                                .filter(item => {
                                    const checkIfSelected = formData?.selectedAddonGroups?.find(addOnGroup => addOnGroup?.addonGroupId === item.id);
                                    if(!checkIfSelected) {
                                        return item
                                    }
                                })
                                .map((item) => {
                                return {
                                    value: item.id,
                                    label: item.addonGroupName,
                                    id: item.id,
                                }
                            })}
                            placeholder={addOnGroupList.length === 0 ? translationText.loading : translationText.select}
                            isSearchable
                            data-testid="addonGroupSelect"
                            value={addOnGroupList
                                .filter(addOnGroup => addOnGroup?.id === selectedAddOnGroup?.id)
                                .map((item) => ({ value: item.id, label: item.addonGroupName, id: item.id }))
                            }
                            onChange={(selectedItem) => {
                                const selectedAddOnGroup = addOnGroupList.find(addOnGroup => addOnGroup?.id === selectedItem?.id)
                                setSelectedAddOnGroup(selectedAddOnGroup)
                            }}
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-end mt-5">
                    <button className="btn btn-custom-primary-outline" data-testid="cancel" onClick={onHide}><JTranslation typeCase="pascal" text={CANCEL} /></button>
                    <button className="ms-2 btn btn-custom-primary" data-testid="addBtn" onClick={setFormDataAndCloseModal}><JTranslation typeCase="pascal" text={ADD} /></button>
                </div>
            </div>
            </Modal.Body>
        </Modal>
    )
}

export default React.memo(AddAddonGroupModal);