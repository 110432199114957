import { useEffect, useState, useContext, useRef } from 'react'
import '../../assets/styles/scss/main.scss'
import './styles/login-page.scss'
import { Amplify } from 'aws-amplify'
import { awsConfig } from '../../config/awsConfig'
import { Checkbox } from 'primereact/checkbox'
import { CommonCtx } from '../../context/CommonCtxProvider'
import { FNB_GLOBAL_SETTINGS, PERMISSION_LIST, USER_INFO } from '../../constants/queryKeys'
import {
	FORGOT_PASSWORD,
	LOGIN_ERROR,
	PASSWORD_TEXT,
	LOGIN_TEXT,
	USERNAME_TEXT,
	COPYRIGHT,
	QR_CODE_SCANNER,
	SEND,
	MAGIC_LINK_LABEL,
	BACK_TO_LOGIN,
	BACK_TO_WELCOME,
	LOGIN_WITH_LINK_ERROR,
	CHANNEL,
	LOGGED_IN,
} from '../../constants/strings'
import { Link, useNavigate } from 'react-router-dom'
import { Messages } from 'primereact/messages'
import { PermissionList, PosLoginResponse, Users } from '../../constants/staticTypes'
import { routes } from '../../constants/routes'
import {
	saveLoginStatus,
	savePermissions,
	userLogin,
	loginWithUsernameOnly,
	getServerPosFromQrCode,
	getUserInfo,
	getUserPermissions,
	loginWithLink,
} from '../../helpers/authHelper'
import { showLoader } from '../../helpers/utils'
import {
	TENANT_ADMIN,
	TENANT_ADMIN_TYPE,
	TENANT_ID_HEADER,
	STAFF_ID,
	USER_NAME,
	LOGIN_METHOD,
	AlertVariant,
	ORG_ID_HEADER,
} from '../../constants/constants'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useTrackEvent } from '../../hooks/useTrackEvent'
import CommonModal from '../../components/common_modal/CommonModal'
import QRScanner from '../../components/manage_coupon/QRScanner'
import ToastAlert from '../../components/alert/ToastAlert'
import useMutationHook from '../../hooks/useMutationHook'
import { useTranslation } from '../../hooks/useTranslation'
import { JTranslation, TranslationContext, jTranslationText } from '../../helpers/jTranslate'
import LogoComponent from '../../components/header/LogoComponent'
import { AxiosResponse } from 'axios'
import { getPermissionList } from '../../helpers/permissionHelper'

function Login() {
	Amplify.configure(awsConfig)
	const queryClient = useQueryClient()
	const loginMutation = useMutationHook(queryClient, true)
	const loginButton = useRef(null)
	const successMessage = useRef(null)
	const navigate = useNavigate()
	const { trackEvent } = useTrackEvent()
	const loginErrorRef = useRef(null)

	// CONTEXT VARIABLES
	const { setPosUserInfo, setIsUserPos } = useContext(CommonCtx)
	const translationContext = useContext(TranslationContext)
	const { targetLanguage, changeTargetLanguage } = translationContext
	// STATE VARIABLES
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const [isLoginFailed, setIsLoginFailed] = useState(false)
	const [loginFailedErrorMessage, setLoginFailedErrorMessage] = useState(LOGIN_ERROR)
	const [cognitoUsername, setCognitoUsername] = useState('')
	const [passwordShow, setPasswordShow] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [loginLink, setLoginLink] = useState(false)
	const [passwordText, setPasswordText] = useState('')
	// TOAST VARIABLES
	const [showToast, setToast] = useState(false) // Toast alert state
	const [toastMessage, setToastMessage] = useState('') // Toast message
	const [toastVariant, setToastVariant] = useState('') // Toast Variant

	const commonModalHideAction = () => setShowModal(false)

	const isLocal = process.env.REACT_APP_IS_LOCAL_ENV

	const broadcastLoginEvent = () => {
		// create a new broadcast channel with the same name
		const channel = new BroadcastChannel(CHANNEL)
		channel.postMessage(LOGGED_IN)
		channel.close()
	}

	const setScanResult = (result: string) => {
		const posNumber = getServerPosFromQrCode(result)
		setUsername(posNumber)
		setPassword('')
		setShowModal(false)
		// @ts-ignore
		if (posNumber) loginButton.current.click()
	}

	// show toast
	const showToastMessage = (message: string, variant: string) => {
		setToastMessage(message)
		setToastVariant(variant)
		setToast(true)
		setUsername('')
	}

	const proceedAction = () => {
		// empty function
	}

	const onPosSuccess = (res: PosLoginResponse) => {
		broadcastLoginEvent()
		// Google Analytics
		trackEvent({
			eventName: 'login_success',
			value: {
				method: LOGIN_METHOD.POS,
			},
		})

		localStorage.setItem('user', res.preferredName)
		setCognitoUsername(res.preferredName)
		queryClient.fetchQuery(FNB_GLOBAL_SETTINGS) // Update time out settings
		saveLoginStatus(queryClient, true)
		setPosUserInfo(res)
		setIsUserPos(true)
		navigate(routes.welcome_page)
	}

	const onLoginWithLinkSuccess = (message: string) => {
		broadcastLoginEvent()

		// Google Analytics
		trackEvent({
			eventName: 'login_success',
			value: {
				method: LOGIN_METHOD.link,
			},
		})

		//@ts-ignore
		successMessage.current.show([{ severity: 'success', summary: message, sticky: true }])
	}

	// on api error
	const onPosError = () => {
		// Google Analytics
		trackEvent({
			eventName: 'login_failure',
			value: {
				method: LOGIN_METHOD.POS,
			},
		})
		showLoader(queryClient, false)
		setIsLoginFailed(true) // login error
	}

	// on api error
	const onLoginWithLinkError = () => {
		// Google Analytics
		trackEvent({
			eventName: 'login_failure',
			value: {
				method: LOGIN_METHOD.link,
			},
		})
		showLoader(queryClient, false)
		setIsLoginFailed(true) // login error
		showToastMessage(LOGIN_WITH_LINK_ERROR, AlertVariant.ERROR)
	}

	// invoke user info api only after fetching cognito user
	useEffect(() => {
		if (cognitoUsername?.trim().length > 0) {
			queryClient.fetchQuery(USER_INFO)
		}
	}, [cognitoUsername, queryClient])

	// handle user login click using react query
	const userLoginMutation = useMutation(userLogin, {
		onMutate: () => {
			setIsLoginFailed(false)
			showLoader(queryClient, true)
		},
		onSuccess: async (res) => {
			// get user info
			const userInfoResponse = await getUserInfo(queryClient, res.username)
			const userInfo = userInfoResponse.data.data as Users

			// check if user is active
			if (!userInfo?.isActive) {
				setCognitoUsername('')
				return Promise.reject(
					new Error(
						'EXCEPTION:Your account has been marked as inactive. Reach out to our admin team to reactivate your account.'
					)
				)
			}

			// modify user info data to match the Users type
			if (userInfo?.isAdmin) {
				localStorage.setItem('user', res.attributes.name)
				savePermissions(queryClient, { isAdmin: true })
			} else {
				const userPermissions = getUserPermissions(userInfo)
				if (Object.keys(userPermissions).length === 0) {
					setCognitoUsername('')
					return Promise.reject(
						new Error(
							'EXCEPTION:You no longer have access to certain features or areas within the application. Reach out to our admin team to access your account.'
						)
					)
				}
				savePermissions(queryClient, userPermissions)
			}
			
			// set USER INFO on login, since it is not in the query cache
			queryClient.setQueryData(USER_INFO, userInfoResponse)

			localStorage.setItem(USER_NAME, userInfo.preferredName)
			localStorage.setItem(STAFF_ID, userInfo.id)
			queryClient.refetchQueries([FNB_GLOBAL_SETTINGS]) // Update time out settings
			setCognitoUsername(res.username)
			changeTargetLanguage(userInfo?.preferredLangKey?.toLowerCase() ?? 'en')
			saveLoginStatus(queryClient, true)
			showLoader(queryClient, false)
			setIsUserPos(false)
			broadcastLoginEvent()
			// Google Analytics
			trackEvent({
				eventName: 'login_success',
				value: {
					method: LOGIN_METHOD.email,
				},
			})
		},
		onError: (error: Error) => {
			showLoader(queryClient, false)

			const errorMessage = error?.message
			const isException = errorMessage?.includes('EXCEPTION:')
			const cleanedMessage = isException ? errorMessage.replace('EXCEPTION:', '') : LOGIN_ERROR
			setLoginFailedErrorMessage(cleanedMessage)
			setIsLoginFailed(true) // login error

			setIsUserPos(false)
			// Google Analytics
			trackEvent({
				eventName: 'login_failure',
				value: {
					method: LOGIN_METHOD.email,
				},
			})
		},
	})

	// if not email generate username for staff else return same username
	const generateUsername = () => {
		// removed email validation for accomadating username field
		// if (EmailValidator.validate(username)) {
		//     return username;
		// }
		return `${username}#${localStorage.getItem(ORG_ID_HEADER)}` // TODO: change to ORG id
	}

	// enter key login trigger
	const handleEnterKeyPressEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
		// switch methods for login with and without password
		if (username?.trim().length && password?.trim().length === 0) {
			loginWithPos()
		} else {
			if (event.key === 'Enter' && username?.trim().length > 0 && password?.trim().length > 0) {
				userLoginMutation.mutate({ username: generateUsername(), password })
			}
		}
	}

	// api call to login without password
	const loginWithPos = () => {
		setIsLoginFailed(false)
		showLoader(queryClient, true)
		loginWithUsernameOnly(loginMutation, username?.trim(), onPosSuccess, onPosError)
	}

	// api call to login using link
	const loginWithLinkApi = () => {
		setIsLoginFailed(false)
		showLoader(queryClient, true)
		loginWithLink(loginMutation, generateUsername(), onLoginWithLinkSuccess, onLoginWithLinkError)
	}

	useEffect(() => {
		const fetchTranslationText = async () => {
			const passwordTextTranslated = await jTranslationText({
				text: PASSWORD_TEXT,
				typeCase: 'pascal',
				translationContext,
			})
			setPasswordText(passwordTextTranslated ?? PASSWORD_TEXT)
		}
		fetchTranslationText()
	}, [targetLanguage])

	// qr code modal content
	const modalContent = (
		<QRScanner
			setScanResult={setScanResult}
			setScanner={setShowModal}
			showToastMessage={showToastMessage}
			hideBackButton={true}
			showScanner={showModal}
		/>
	)

	useEffect(() => {
		if (isLoginFailed && loginFailedErrorMessage) {
			//@ts-ignore
			loginErrorRef.current.replace([
				{
					id: 'login-error-message',
					sticky: true,
					severity: 'error',
					content: <>{loginFailedErrorMessage}</>,
					closable: false,
				},
			])
		}
	}, [isLoginFailed])

	return (
		<div className="p-0 ">
			<ToastAlert show={showToast} onClose={setToast} message={toastMessage} variant={toastVariant} />
			{/* qr code scanner modal */}
			<CommonModal
				callback={proceedAction}
				modalContent={modalContent}
				onHide={commonModalHideAction}
				show={showModal}
				title={useTranslation(QR_CODE_SCANNER, 'none')}
				hideFooter={true}
			/>
			<div data-testid="login" className="login d-flex justify-content-center align-items-center">
				<div className="container">
					<div className="row justify-content-center align-items-center">
						<div className="col-md-9 col-lg-5 col-xl-5 ">
							<div className="login-box">
								<Link data-testid="forgot" className="spacing" to={routes.welcome_page}>
									<div className="login-header text-center d-flex align-items-center justify-content-center">
										<LogoComponent />
									</div>
								</Link>
								<div className="login-body">
									<div className="">
										<div className="form-group  custom-login-password mb-2">
											{!loginLink && (
												<span
													className="login-password-icon"
													onClick={() => {
														setUsername('')
														setShowModal(!showModal)
														// Google Analytics
														trackEvent({
															eventName: 'qr_scan',
															value: {
																qr_scanner: 'login_qr_scanner',
															},
														})
													}}
												>
													<i className="ri-qr-code-line"></i>
												</span>
											)}
											<input
												autoComplete="off"
												id="email-input"
												data-testid="email"
												autoFocus
												className="form-control form-control-lg custom-password-textbox "
												type="text"
												placeholder={useTranslation(USERNAME_TEXT, 'pascal')}
												aria-label=".form-control-lg example"
												value={username}
												onChange={(e) => setUsername(e.target.value.trim())}
											/>
										</div>
										<div className="form-group login-link-msg">
											{/* login with link success message */}
											<Messages ref={successMessage} />
										</div>
										<div className="d-flex mb-3">
											<div className="flex-grow-0">
												<Checkbox
													className="form-prime-checkbox"
													type="checkbox"
													name="login-link"
													checked={loginLink}
													onChange={(e) => setLoginLink(!loginLink)}
												/>
											</div>
											<div className="flex-grow-1">
												<label className="ms-2 fw-semibold">
													<JTranslation typeCase="pascal" text={MAGIC_LINK_LABEL} />
												</label>
											</div>
										</div>
										{!loginLink && (
											<div className="form-group  custom-login-password mb-4">
												<span
													className="login-password-icon"
													onClick={() => setPasswordShow(!passwordShow)}
												>
													<i className={passwordShow ? 'ri-eye-line' : 'ri-eye-off-line'}></i>
												</span>
												<input
													data-testid="password"
													autoComplete="new-password"
													className="form-control form-control-lg custom-password-textbox"
													type={passwordShow ? 'text' : 'password'}
													placeholder={passwordText}
													aria-label=".form-control-lg example"
													onKeyDown={(e) => {
														if (e.key === 'Enter') {
															handleEnterKeyPressEvent(e)
														}
													}}
													onChange={(e) => setPassword(e.target.value)}
												/>
											</div>
										)}
										{isLoginFailed && <Messages ref={loginErrorRef} />}
										<div className="d-grid gap-2 mb-3">
											<button
												type="button"
												className="btn btn-primary btn-lg btn-block btn-custom-primary"
												data-testid="submit-button"
												disabled={username?.trim().length === 0}
												ref={loginButton}
												onClick={(e) => {
													e.preventDefault()
													//@ts-ignore
													successMessage.current.clear()
													// switch methods for login with and without password
													if (!loginLink) {
														if (password?.trim().length === 0) {
															loginWithPos()
														} else {
															userLoginMutation.mutate({
																username: generateUsername(),
																password,
															})
														}
													}
													if (loginLink) {
														loginWithLinkApi()
													}
												}}
											>
												{loginLink ? (
													<JTranslation typeCase="upper" text={SEND} />
												) : (
													<JTranslation typeCase="upper" text={LOGIN_TEXT} />
												)}
											</button>
										</div>
										<div className="row d-flex justify-content-between forget-pass-sec">
											{loginLink ? (
												<div className="col-auto d-flex align-items-center fw-semibold">
													<i className="ri-arrow-left-line"></i>
													<Link
														data-testid="forgot"
														to={routes.login}
														onClick={() => setLoginLink(false)}
													>
														<JTranslation typeCase="pascal" text={BACK_TO_LOGIN} />
													</Link>
												</div>
											) : (
												<>
													<div className="col-auto d-flex align-items-center fw-semibold">
														<i className="ri-arrow-left-line"></i>
														<Link
															data-testid="forgot"
															className="spacing"
															to={routes.welcome_page}
														>
															<JTranslation typeCase="pascal" text={BACK_TO_WELCOME} />
														</Link>
													</div>
													<div className="col-auto d-flex align-items-center fw-semibold">
														<Link data-testid="forgot" to={routes.forgot}>
															<JTranslation typeCase="pascal" text={FORGOT_PASSWORD} />?
														</Link>
													</div>
												</>
											)}
										</div>
									</div>
									<div className="col-md-12 text-center mt-4">
										<div className="copyright-login">
											<p className="mb-0">
												<JTranslation typeCase="pascal" text={COPYRIGHT} />
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Login
