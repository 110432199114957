import { useState, useEffect, useContext } from 'react'
import "./styles/custom-menu.scss"
import { ADD_EXISTING_MENU_ITEMS, SELECTED, EXISTING_MENU_ITEMS, ACTIVE, INACTIVE, CANCEL, SAVE, ADD_EXISTING_HELP } from '../../constants/strings'
import { addExistingItemToCategory } from '../../helpers/customMenuHelper';
import { AlertVariant, SpecialMenuIdPrefixes } from '../../constants/constants';
import { Badge } from 'primereact/badge';
import { FnBCtx } from '../../context/MoveItemCtxProvider';
import { getDefaultPrice } from '../../helpers/menuViewHelper'
import { getItems } from '../../helpers/menuManagementHelper'
import { MENU_ITEMS } from '../../constants/queryKeys'
import { MenuItem, AddExistingItemsParamType } from '../../constants/staticTypes'
import { OverlayTrigger } from "react-bootstrap";
import { PickList } from 'primereact/picklist'
import { popover } from '../../helpers/utils';
import { useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query';
import { v4 as uuidv4 } from "uuid";
import blankImage from '../../assets/images/menu/180x120.png'
import Loader from '../../components/loader/Loader';
import ToastAlert from '../../components/alert/ToastAlert';
import useMutationHook from '../../hooks/useMutationHook';
import useQueryHook from '../../hooks/useQueryHook';
import withSidebar from '../../hoc/withSidebar';
import { Skeleton } from 'primereact/skeleton';
import MlxPopover from '../../components/common/MlxPopover';

const AddExistingItems = () => {
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const bulkAddMutation = useMutationHook(queryClient, true)
    const idPrefix = SpecialMenuIdPrefixes.SPECIAL_MENU_CATEGORY
    const { itemCategoryId } = useParams()
    // context variables
    const { source, setSource, target, setTarget } = useContext(FnBCtx)
    // TOAST VARIABLES
    const [showToast, setToast] = useState(false) // Toast alert state
    const [toastMessage, setToastMessage] = useState('') // Toast message
    const [toastVariant, setToastVariant] = useState('') // Toast Variant

    useEffect(() => {
        // clean-up
        return () => {
            setSource([])
            setTarget([])
        }
    }, []) // eslint-disable-line

    // fetch menu items without category
    const fetchStatus = useQueryHook(MENU_ITEMS, getItems, (res) => {
        let data = res.data.data as MenuItem[]
        setSource(data)
    })

    const onChange = (event: any) => {
        setSource(event.source)
        setTarget(event.target)
    }

    const displayToast = (message: string, variant: string) => {
        setToastVariant(variant)
        setToastMessage(message)
        setToast(true)
    }

    // api success response
    const onSuccess = (message: string) => {
        displayToast(message, AlertVariant.SUCCESS)
        navigate(-1)
    }

    const getMenuIds = () => {
        let menuIds: string[] = []
        target.forEach((item: MenuItem) => {
            // @ts-ignore
            menuIds.push(item.id)
        })
        return menuIds
    }

    const saveApiCall = () => {
        const params: AddExistingItemsParamType = {
            itemCategoryId: `${idPrefix}${itemCategoryId}`,
            menuItemIdsInOrder: getMenuIds(),
        }
        addExistingItemToCategory(bulkAddMutation, params, onSuccess, displayToast)
    }

    const itemTemplate = (item: MenuItem) => {
        return (
            <div className="product-item">
                <div className="image-container">
                    <img src={item.signedPhotoKeys?.length ? item.signedPhotoKeys[0] : blankImage} alt={item.itemName} />
                </div>
                <div className="product-list-detail">
                    <h5 className="mb-2">{item.itemName}</h5>
                </div>
                <div className="product-list-action">
                    {getDefaultPrice(item.itemPrices).map((price) => (
                        <h6 key={uuidv4()} className="mb-2">{price === "Infinity" ? "..." : `$${price}`}</h6>
                    ))}
                    <Badge value={item.isActive ? ACTIVE : INACTIVE} severity={item.isActive ? "success" : "danger"}>
                    </Badge>
                </div>
            </div>
        )
    }

    if (fetchStatus.isLoading) {
        return <Loader />
    }

    const PickListSkeleton = (
        <div className='row'>
            <div className="col-12 col-sm-6 col-md-6 col-lg-6">
            <Skeleton className='mb-2' height='60vh' width='100%'></Skeleton>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-6">
            <Skeleton className='mb-2' height='60vh'  width='100%'></Skeleton>
            </div>


        </div>
    )

    return (
      <div className='col-md-12 col-lg-12 mb-3 h-100 '>
        <div className='card h-100'>
          <ToastAlert
            data-testid='toast'
            message={toastMessage}
            onClose={setToast}
            show={showToast}
            variant={toastVariant}
          />
          <div className='card-header flex-grow-0'>
            <div className='d-flex  align-items-center py-1'>
              <div
                className='button btn flex-grow-0 me-2 back-btn back-btn-new'
                data-testid='back-btn'
                onClick={() => navigate(-1)}
              >
                <i className='ri-arrow-left-line'></i>
              </div>
              <div className='flex-grow-1 d-flex align-items-center'>
                <h4 className='mb-0 title'>{ADD_EXISTING_MENU_ITEMS}</h4>
                <MlxPopover data={{title:ADD_EXISTING_MENU_ITEMS, body:ADD_EXISTING_HELP}}/>
              </div>
            </div>
          </div>
          <div className='card-body  flex-grow-1 overflow-auto'>
            <div className='row mb-5'>
              <div className='col-md-12'>
                {source.length || target.length ? (
                  <PickList
                    source={source}
                    className='picklist-demo'
                    target={target}
                    itemTemplate={itemTemplate}
                    sourceHeader={EXISTING_MENU_ITEMS}
                    targetHeader={SELECTED}
                    sourceStyle={{ height: '342px' }}
                    targetStyle={{ height: '342px' }}
                    onChange={onChange}
                    filterBy='itemName'
                    sourceFilterPlaceholder='Search by name'
                    targetFilterPlaceholder='Search by name'
                    showTargetControls={false}
                    showSourceControls={false}
                    dataKey='id'
                  />
                ) : (
                  PickListSkeleton
                )}
              </div>
            </div>

            <div className='save-btn-section shadow save-btn-fixed'>
              <button
                type='button'
                className='btn  btn-custom-primary-outline'
                data-testid='cancel'
                onClick={() => navigate(-1)}
              >
                {CANCEL}
              </button>{' '}
              <button
                type='button'
                className='btn  btn-custom-primary'
                data-testid='save'
                disabled={!target.length}
                onClick={() => {
                  saveApiCall()
                }}
              >
                {SAVE}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
}

export default withSidebar(AddExistingItems)
