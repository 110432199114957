import { useContext, useEffect, useState } from 'react'
import './work_schedule.scss'
import { AxiosResponse } from 'axios'
import { Badge } from 'primereact/badge'
import { Button } from 'primereact/button'
import { checkStaffSchedule, cloneDraftFromPublished, createDraftSchedule, deleteExistingDraft, DraftResponse, DropDown, fetchDraftedAndPublishedScheduleIds, fetchStaffsWithSchedulesAndShift, getGlobalStaffing, getScheduleId, getWeekRange, initialDataMinimumEmployeeCount, initialScheduleStatus, optionTemplate, optionTemplateWithTime, publishDraftedSchedule, Schedule, ScheduleData, ScheduleIdsResponse, ScheduleMetadata, selectionTemplate, Shifts, ShiftsByDate, shiftSelectionTemplate, Staff, staffCountByDay, StartAndEndDates } from '../../helpers/workTimeScheduleHelper'
import { CommonCtx } from '../../context/CommonCtxProvider'
import { ConfirmPopup } from 'primereact/confirmpopup'
import { CREATE_DRAFT_SCHEDULE, ROLES_LIST, SCHEDULE_STATUS, SHIFT_CONFIG_LIST, STAFFING_REQUIREMENTS_LIST, STAFFS_WITH_SCHEDULES_AND_SHIFT } from '../../constants/queryKeys'
import { DATE_FORMAT, MOBILE_VIEW_BREAKPOINT, toastMessageInitialData } from '../../constants/constants'
import { DatePicker } from 'antd'
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown'
import { getRolesList } from '../../helpers/roleHelper'
import { getShiftConfigList } from '../../helpers/shiftConfigHelper'
import { isEmptyObject } from '../../helpers/utils'
import { JTranslation } from '../../helpers/jTranslate'
import { PrimeReactDropDown, ToastMessageProps, JobRole, ShiftTemplate, PerDayCount, ShiftDropDown } from '../../constants/staticTypes'
import { PUBLISH, WORK_TIME_SCHEDULE, WORK_TIME_SCHEDULE_HELP, PUBLISHED, DRAFT, CONFIRM_PUBLISH_SCHEDULE, CONFIRM_UPDATE_SCHEDULE, CLEAR_DRAFT, CONFIRM_CLEAR_DRAFT, ROLE, SHIFT, STAFF_SCHEDULE_DISCREPANCY, STAFF_COUNT } from '../../constants/strings'
import { useQueryClient } from 'react-query'
import { useWindowSize } from 'react-use'
import CommonModal from '../../components/common_modal/CommonModal'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import Loader from '../../components/loader/Loader'
import MinimumEmployeeCountSideBar from '../../components/manage_work_schedule/MinimumEmployeeCountSideBar'
import MlxPopover from '../../components/common/MlxPopover'
import scheduleError from '../../assets/images/work_time_schedule_error.png'
import ShiftScheduler from '../../components/manage_work_schedule/ShiftScheduler'
import ShiftSchedulerMobileView from '../../components/manage_work_schedule/ShiftSchedulerMobileView'
import ShiftScheduleSideBar from '../../components/manage_work_schedule/ShiftScheduleSideBar'
import ToastAlert from '../../components/alert/ToastAlert'
import useMutationHook from '../../hooks/useMutationHook'
import useQueryHook from '../../hooks/useQueryHook'
import withSidebar from '../../hoc/withSidebar'
dayjs.extend(isSameOrBefore);

const ManageWorkSchedule = () => {
    const { width } = useWindowSize()
    const breakPoint = MOBILE_VIEW_BREAKPOINT
    const { showSideBar, setShowSideBar } = useContext(CommonCtx)

    const [selectedWeek, setSelectedWeek] = useState<dayjs.Dayjs>(dayjs())
    const [selectedDateRange, setSelectedDateRange] = useState<StartAndEndDates>(getWeekRange(selectedWeek))
    const [selectedDay, setSelectedDay] = useState('')
    const [selectedRole, setSelectedRole] = useState<DropDown | null>(null)
    const [selectedEmployeeName, setSelectedEmployeeName] = useState('')
    const [staffDataWithSchedule, setStaffDataWithSchedule] = useState<Staff | null>(null)
    const [selectedLocation, setSelectedLocation] = useState<Location | null>(null)
    const [selectedShift, setSelectedShift] = useState<Shifts | null>(null)
    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
    const [shiftSideBar, setShiftSideBar] = useState(false)
    const [pageInitialLoad, setPageInitialLoad] = useState(true)
    const [roles, setRoles] = useState<PrimeReactDropDown[]>([])
    const [shifts, setShifts] = useState<ShiftDropDown[]>([])
    const [draftedScheduleId, setDraftedScheduleId] = useState('')
    const [publishedScheduleId, setPublishedScheduleId] = useState('')
    const [scheduleStatus, setScheduleStatus] = useState<Schedule>(initialScheduleStatus)
    const [scheduleData, setScheduleData] = useState<ScheduleIdsResponse | null>(null)
    const [staffsWithSchedule, setStaffsWithSchedule] = useState<ScheduleData | null>(null)
    const [scheduleMetadata, setScheduleMetadata] = useState<ScheduleMetadata | null>(null)
    const [staffingRequirement, setStaffingRequirement] = useState(initialDataMinimumEmployeeCount)
    const [scheduleId, setScheduleId] = useState('')
    const [isDateOlder, setIsDateOlder] = useState<boolean>(false)
    const [visible, setVisible] = useState({ edit: false, publish: false, clear: false });
    const [isCountOk, setIsCountOk] = useState<boolean>(true)

    const queryClient = useQueryClient()
    const scheduleMutation = useMutationHook(queryClient, true)

    const accept = () => {
        setVisible({ publish: false, edit: false, clear: false });

        switch (scheduleStatus.actionStatus) {
            case 'draft':
                publishDraftedSchedule(scheduleMutation, { scheduleId, scheduleData }, onSuccess, onError);
                break;
            case 'published':
                cloneDraftFromPublished(scheduleMutation, { scheduleId }, onSuccessWithoutMessage, onError);
                break;
            default:
                // clear draft
                deleteExistingDraft(scheduleMutation, { scheduleId: scheduleId }, onSuccessWithoutMessage, onError);
                break;
        }
    };

    const isTodayWithinSelectedWeek = () => {
        const today = dayjs().startOf('day')
        const endOfWeek = selectedWeek.endOf('week')
        today.isSameOrBefore(endOfWeek) ? setIsDateOlder(false) : setIsDateOlder(true)
    }

    const reloadPageData = () => {
        setScheduleData(null)
        setScheduleId("")
        setDraftedScheduleId("")
        setPublishedScheduleId("")
        scheduleDataQuery.refetch()
    }

    const onSuccessOfStaffsWithSchedule = (res: AxiosResponse<any, any>) => {
        const staffsWithSchedule: ScheduleData = res.data.data
        const { schedule } = staffsWithSchedule
        setStaffsWithSchedule(staffsWithSchedule)
        setScheduleMetadata(schedule?.scheduleMetadata ?? {} as ScheduleMetadata)
        setPageInitialLoad(false)
    }

    const onSuccessOfScheduleStatus = (res: AxiosResponse<any, any>) => {
        const scheduleData: ScheduleIdsResponse = res.data.data
        setScheduleData(scheduleData)
        // Destructure draft and published from scheduleData
        const { draft, published } = scheduleData

        // Check if draft and published schedules are empty
        const isDraftEmpty = isEmptyObject(draft)
        const isPublishedEmpty = isEmptyObject(published)

        // If draft is not empty, save the draft's id using saveDraftedScheduleId
        if (!isDraftEmpty) saveDraftedScheduleId(draft.id)

        // If published is not empty, save the published's id using savePublishedScheduleId
        if (!isPublishedEmpty) savePublishedScheduleId(published.id)
    }

    const onSuccessOfDraftCreated = (res: AxiosResponse<any, any>) => {
        const draftData: DraftResponse = res.data.data
        saveDraftedScheduleId(draftData.id)
    }

    const onSuccessOfGlobalStaffing = (res: AxiosResponse<any, any>) => {
        const staffingData: PerDayCount[] = res.data.data.lists
        setStaffingRequirement({ availability: staffingData })
    }

    const onSuccess = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
        reloadPageData()
    }

    const onSuccessWithoutMessage = (message: string, variant: string) => {
        reloadPageData()
    }

    const onError = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
    }

    const onRoleListSuccess = (res: AxiosResponse<any, any>) => {
        let data: JobRole[] | [] = res.data?.data.lists
        let roleDetails: PrimeReactDropDown[] = []
        data.forEach((item) => {
            if (item.isActive === true) {
                roleDetails.push({
                    code: item.id,
                    name: item.jobRoleName,
                })
            }
        })
        setRoles(roleDetails)
    }

    const onShiftConfigSuccess = (res: AxiosResponse<any, any>) => {
        const data: ShiftTemplate[] = res.data?.data.lists
        let shiftDetails: ShiftDropDown[] = []
        data.forEach((item) => {
            const { shiftName, startTime, endTime } = item
            shiftDetails.push({
                code: shiftName,
                name: shiftName,
                startTime: startTime,
                endTime: endTime
            })
        })
        setShifts(shiftDetails)
    }

    const resetScheduleData = () => {
        const res = queryClient.getQueryData(STAFFS_WITH_SCHEDULES_AND_SHIFT) as AxiosResponse<any, any>
        onSuccessOfStaffsWithSchedule(res)
    }
    const filterStaff = ({ staffArray, roleId, shiftName }: { staffArray: Staff[]; roleId?: string; shiftName?: string }) => {
        return staffArray
            .map((staff) => {
                if (!staff || !staff.shifts) return null;

                const filteredShifts = Object.entries(staff.shifts).reduce((acc, [date, shifts]) => {
                    const matchingShifts = shifts.filter((shift) => {
                        const matchRole = roleId ? shift.assignedShifts?.roleId === roleId : true;
                        const matchShift = shiftName ? shift.assignedShifts?.shiftName === shiftName : true;
                        return matchRole && matchShift;
                    });

                    if (matchingShifts.length > 0) {
                        acc[date] = matchingShifts;
                    }
                    return acc;
                }, {} as ShiftsByDate);

                // If no matching shifts are found, exclude this staff member
                if (Object.keys(filteredShifts).length === 0) return null;

                return { ...staff, shifts: filteredShifts };
            })
            .filter((staff) => staff !== null) as Staff[];
    };

    const saveDraftedScheduleId = (id: string) => setDraftedScheduleId(id)
    const savePublishedScheduleId = (id: string) => setPublishedScheduleId(id)
    const saveEmployeeName = (name: string) => setSelectedEmployeeName(name)

    // fetch shift list (api call)
    useQueryHook(SHIFT_CONFIG_LIST, () => getShiftConfigList(), onShiftConfigSuccess)
    // fetch role list (api call)
    useQueryHook(ROLES_LIST, () => getRolesList({ limit: 500 }), onRoleListSuccess)
    // api call to fetch staffing requirements
    const staffingQuery = useQueryHook(STAFFING_REQUIREMENTS_LIST, () => getGlobalStaffing(), onSuccessOfGlobalStaffing)
    // schedule data main QUERY-1
    const scheduleDataQuery = useQueryHook(SCHEDULE_STATUS, () => fetchDraftedAndPublishedScheduleIds(selectedDateRange), onSuccessOfScheduleStatus)
    // If both the draft and published IDs are empty, call the Create Draft API (api call)
    const isDraftEmpty = scheduleData?.draft && isEmptyObject(scheduleData.draft); // Check if the draft schedule ID is empty
    const isPublishedEmpty = scheduleData?.published && isEmptyObject(scheduleData.published); // Check if the published schedule ID is empty
    const createDraft = scheduleData && isDraftEmpty && isPublishedEmpty; // Determine if Create Draft API should be called
    //  schedule data dependent QUERY-2
    const draftDataQuery = useQueryHook(CREATE_DRAFT_SCHEDULE, () => createDraftSchedule(selectedDateRange), onSuccessOfDraftCreated, undefined, !!createDraft)

    // Determine which schedule ID to use
    useEffect(() => {
        const scheduleId = getScheduleId(draftedScheduleId, publishedScheduleId);
        setScheduleId(scheduleId)
    }, [draftedScheduleId, publishedScheduleId])

    //  schedule data dependent QUERY-3
    const staffsWithScheduleQuery = useQueryHook(STAFFS_WITH_SCHEDULES_AND_SHIFT, () => fetchStaffsWithSchedulesAndShift({ scheduleId }), onSuccessOfStaffsWithSchedule, undefined, !!scheduleId)

    // assigning API data to state variables
    useEffect(() => {
        if (staffsWithSchedule) {
            const { schedule } = staffsWithSchedule;
            setScheduleStatus(schedule);
        }
    }, [staffsWithSchedule]); // Run effect when staffsWithSchedule data changes

    // filter schedules
    useEffect(() => {
        if (staffsWithSchedule && (selectedRole?.code || selectedShift?.code)) {
            const { staffs } = staffsWithSchedule;
            const filterStaffByRole = filterStaff({ staffArray: staffs, roleId: selectedRole?.code, shiftName: selectedShift?.code });
            setStaffsWithSchedule({ ...staffsWithSchedule, staffs: filterStaffByRole });
        }
    }, [selectedRole, selectedShift]); // eslint-disable-line


    // refetch the staff schedule api once the selected date has changed 
    useEffect(() => {
        reloadPageData()
    }, [selectedDateRange])

    // Calculate the date range for the selected week
    useEffect(() => {
        const dateRange = getWeekRange(selectedWeek)
        setSelectedDateRange(dateRange)
        isTodayWithinSelectedWeek()
    }, [selectedWeek])

    // check expected vs current staff count
    const checkStaffCount = () => {
        const staffCount = checkStaffSchedule(staffCountByDay)
        setIsCountOk(staffCount)
    }

    const FiltersForWeb = (
        <>
            <Dropdown
                value={selectedRole}
                onChange={(e: DropdownChangeEvent) => {
                    resetScheduleData()
                    setSelectedRole(e.value)
                }}
                options={roles}
                optionLabel="name"
                placeholder={ROLE}
                filter
                disabled={!roles.length}
                showClear
                valueTemplate={selectionTemplate}
                itemTemplate={optionTemplate}
                className="w-full md:w-14rem"
            />
            {/* <Dropdown
                value={selectedLocation}
                onChange={(e: DropdownChangeEvent) => setSelectedLocation(e.value)}
                options={locations}
                optionLabel="name"
                placeholder={FILTER_LOCATION}
                filter
                showClear
                disabled={!locations.length}
                valueTemplate={selectionTemplate}
                itemTemplate={optionTemplate}
                className="w-full md:w-14rem"
            /> */}
            <Dropdown
                value={selectedShift}
                onChange={(e: DropdownChangeEvent) => {
                    resetScheduleData()
                    setSelectedShift(e.value)
                }}
                options={shifts}
                optionLabel="name"
                placeholder={SHIFT}
                filter
                disabled={!shifts.length}
                showClear
                valueTemplate={shiftSelectionTemplate}
                itemTemplate={optionTemplateWithTime}
                className="w-full md:w-14rem"
            />
        </>
    )

    const FiltersForMobile = (
        <>
            <Dropdown
                value={selectedRole}
                onChange={(e: DropdownChangeEvent) => setSelectedRole(e.value)}
                options={roles}
                optionLabel="name"
                filter
                // disabled={!roles.length}
                disabled={true}
                showClear
                valueTemplate={selectionTemplate}
                itemTemplate={optionTemplate}
                className="filter-button-mobile btn-prime-primary-outline"
                dropdownIcon={() => <i className="ri-user-2-line"></i>}
            />
            {/* <Dropdown
                value={selectedLocation}
                onChange={(e: DropdownChangeEvent) => setSelectedLocation(e.value)}
                options={locations}
                optionLabel="name"
                filter
                showClear
                disabled={!locations.length}
                valueTemplate={selectionTemplate}
                itemTemplate={optionTemplate}
                className="filter-button-mobile btn-prime-primary-outline"
                dropdownIcon={() => <i className="ri-map-pin-line"></i>}
            /> */}
            <Dropdown
                value={selectedShift}
                onChange={(e: DropdownChangeEvent) => setSelectedShift(e.value)}
                options={shifts}
                optionLabel="name"
                filter
                // disabled={!shifts.length}
                disabled={true}
                showClear
                valueTemplate={shiftSelectionTemplate}
                itemTemplate={optionTemplateWithTime}
                className="filter-button-mobile btn-prime-primary-outline"
                dropdownIcon={() => <i className="ri-calendar-schedule-line"></i>}
            />
        </>
    )

    const PublishOrEditContent = (
        <div>
            {
                scheduleStatus.actionStatus === "draft"
                    ? !isCountOk ? `${STAFF_SCHEDULE_DISCREPANCY} ${CONFIRM_PUBLISH_SCHEDULE}` : CONFIRM_PUBLISH_SCHEDULE
                    : CONFIRM_UPDATE_SCHEDULE
            }
        </div>
    )

    const ClearContent = <div>{CONFIRM_CLEAR_DRAFT}</div>;

    // loading screen
    if (pageInitialLoad || staffsWithScheduleQuery.isFetching) {
        return <Loader />
    }

    // error screen
    if (scheduleDataQuery.status === "error" || draftDataQuery.status === "error" || staffsWithScheduleQuery.status === "error") {
        return (

            <div className="text-center d-flex flex-column align-items-center justify-content-center">
                <img src={scheduleError} alt="" className="img-fluid mb-3" />
                <h2>Oops!!</h2>
                <h5 className="mt-2 mb-3">
                    <div className='mb-1 error-text-schedule'>Something went wrong.We're sorry, but an error occurred while loading this page.</div>
                    <div className='error-text-schedule'>Please try refreshing the page or come back later.</div>
                </h5>
                <div className='mt-2 mb-2'>
                    <button
                        className="btn btn-custom-primary d-flex">
                        <i className="ri-restart-line me-1"></i>
                        <span className="btn-text"><JTranslation typeCase="pascal" text={'Refresh Page'} /></span>
                    </button>
                </div>
            </div>
        )
    }

    if (staffsWithScheduleQuery.status === "success" && staffingQuery.status === "success") {
        return (
            <>
                <CommonModal
                    show={(visible.publish || visible.edit || visible.clear)}
                    onHide={() => {
                        setVisible({
                            publish: false,
                            edit: false,
                            clear: false,
                        });
                    }}
                    modalContent={visible.clear ? ClearContent : PublishOrEditContent}
                    title="Review and Confirm Changes"
                    callback={accept}
                />

                <div className="page-title d-flex mb-3 align-items-center">
                    <div className="flex-grow-1 d-flex">
                        <span className="d-flex">
                            <h5 className="d-flex mb-0 align-items-center">
                                <JTranslation typeCase="pascal" text={WORK_TIME_SCHEDULE} />
                                <MlxPopover data={{ title: WORK_TIME_SCHEDULE, body: WORK_TIME_SCHEDULE_HELP }} />
                            </h5>
                        </span>
                        <span className="d-flex align-items-center">
                            {scheduleStatus.actionStatus === 'draft'
                                ? <Badge
                                    value={<JTranslation typeCase="pascal" text={DRAFT} />}
                                    severity="danger"
                                    size={'large'}
                                    className="schedule-title-badge ms-2 text-nowrap"
                                />
                                : <Badge
                                    value={<JTranslation typeCase="pascal" text={PUBLISHED} />}
                                    severity="success"
                                    size={'large'}
                                    className="schedule-title-badge ms-2 text-nowrap"
                                />}
                        </span>
                    </div>
                    <div className="flex-grow-0 align-items-center">
                        <Button
                            icon="ri-settings-4-line "
                            className=" p-button-rounded p-button-primary p-button-text fs-4 color-primary-custom"
                            title={STAFF_COUNT}
                            data-testid="global-config"
                            onClick={() => {
                                setShowSideBar(!showSideBar)
                            }}
                        />
                    </div>
                </div>
                {/* TOAST MESSAGE COMPONENT */}
                <ToastAlert
                    show={toastMessage.show}
                    onClose={() => setToastMessage(toastMessageInitialData)}
                    message={toastMessage.message}
                    variant={toastMessage.variant}
                />

                {/*SIDE BARS  */}
                <MinimumEmployeeCountSideBar setToastMessage={setToastMessage} />
                <ShiftScheduleSideBar
                    shiftSideBar={shiftSideBar}
                    setShiftSideBar={setShiftSideBar}
                    setToastMessage={setToastMessage}
                    selectedWeek={selectedWeek}
                    selectedEmployeeName={selectedEmployeeName}
                    roles={roles}
                    shifts={shifts}
                    scheduleId={scheduleId}
                    staffDataWithSchedule={staffDataWithSchedule}
                    selectedDay={selectedDay}
                    staffsWithSchedule={staffsWithSchedule}
                />
                <div className="col-md-12 col-lg-12 mb-3 h-100 ">
                    <div className="card h-100 ">
                        <div className="card-header flex-grow-0">
                            <div className="d-flex  align-items-center">
                                <div className="flex-grow-1"></div>
                                <div className="flex-grow-0 me-2 ms-1 gap-2 d-flex">
                                    {/* switch web and mobile components */}
                                    {width >= breakPoint ? FiltersForWeb : FiltersForMobile}

                                    <span>
                                        <DatePicker
                                            onChange={(date) => {
                                                setSelectedWeek(dayjs(date))
                                            }}
                                            picker="week"
                                            value={selectedWeek}
                                            className="custom-schedule-calendar"
                                            format={(value) =>
                                                `${dayjs(value).startOf('week').format(DATE_FORMAT)} - ${dayjs(value)
                                                    .endOf('week')
                                                    .format(DATE_FORMAT)}`
                                            }
                                            allowClear={false}
                                        />
                                    </span>
                                    {scheduleStatus.actionStatus === 'draft' ?
                                        <Button
                                            //className="p-button-success m-1"
                                            tooltip={CLEAR_DRAFT}
                                            severity="secondary"
                                            className="text-nowrap prime-icon-only-btn rounded btn-prime-primary-outline d-flex justify-content-center"
                                            tooltipOptions={{ position: 'bottom' }}
                                            disabled={staffsWithScheduleQuery.isFetching || scheduleDataQuery.isFetching || draftDataQuery.isFetching || isDateOlder}
                                            onClick={() => {
                                                setVisible({ ...visible, clear: true })
                                            }}
                                            icon={<i className="ri-close-line me-1"></i>}
                                        >
                                            <span className="btn-text overflow-ellipsis">
                                                <JTranslation typeCase="pascal" text={CLEAR_DRAFT} />
                                            </span>
                                        </Button>
                                        : ""
                                    }
                                    <Button
                                        //className="p-button-success m-1"
                                        tooltip="edit"
                                        className="text-nowrap prime-icon-only-btn rounded btn-prime-primary-outline d-flex justify-content-center"
                                        tooltipOptions={{ position: 'bottom' }}
                                        disabled={scheduleStatus.actionStatus === 'draft' || scheduleMutation.isLoading || isDateOlder}
                                        onClick={() => {
                                            setVisible({ ...visible, edit: true })
                                        }}
                                        icon={<i className="ri-pencil-line me-1"></i>}
                                        outlined
                                    >
                                        <span className="btn-text overflow-ellipsis">
                                            <JTranslation typeCase="pascal" text='Edit' />
                                        </span>
                                    </Button>
                                    <Button
                                        tooltip="publish"
                                        severity="success"
                                        className="text-nowrap prime-icon-only-btn rounded"
                                        tooltipOptions={{ position: 'bottom' }}
                                        disabled={scheduleStatus.actionStatus !== 'draft' || scheduleMutation.isLoading || isDateOlder}
                                        onClick={() => {
                                            checkStaffCount()
                                            setVisible({ ...visible, publish: true })
                                        }}
                                        icon={<i className="ri-send-plane-2-line me-1"></i>}
                                    >
                                        <span className="btn-text overflow-ellipsis">
                                            <JTranslation typeCase="pascal" text={scheduleStatus.actionStatus === 'draft' ? PUBLISH : PUBLISHED} />
                                        </span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body flex-grow-1 overflow-auto">
                            <ConfirmPopup />
                            <div className="survey-section h-100">
                                {/* switch web and mobile components */}
                                {width >= breakPoint ? (
                                    <ShiftScheduler
                                        shiftSideBar={shiftSideBar}
                                        setShiftSideBar={setShiftSideBar}
                                        saveEmployeeName={saveEmployeeName}
                                        selectedWeek={selectedWeek}
                                        staffsWithSchedule={staffsWithSchedule}
                                        staffingRequirement={staffingRequirement.availability}
                                        scheduleId={scheduleId}
                                        setToastMessage={setToastMessage}
                                        reloadPageData={reloadPageData}
                                        setData={setStaffDataWithSchedule}
                                        setSelectedDay={setSelectedDay}
                                        scheduleMetadata={scheduleMetadata}
                                    />
                                ) : (
                                    <ShiftSchedulerMobileView
                                        shiftSideBar={shiftSideBar}
                                        setShiftSideBar={setShiftSideBar}
                                        saveEmployeeName={saveEmployeeName}
                                        selectedWeek={selectedWeek}
                                        staffsWithSchedule={staffsWithSchedule}
                                        staffingRequirement={staffingRequirement.availability}
                                        scheduleId={scheduleId}
                                        setToastMessage={setToastMessage}
                                        reloadPageData={reloadPageData}
                                        setData={setStaffDataWithSchedule}
                                        setSelectedDay={setSelectedDay}
                                        scheduleMetadata={scheduleMetadata}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

export default withSidebar(ManageWorkSchedule)

