import '../../assets/styles/scss/main.scss';
import { BACK_TO_LOGIN, EMAIL_ERROR, EMAIL_TEXT, FORGOT_PASSWORD_ERROR, PASSWORD_RESET_MAIL_SUCCESS, SEND } from '../../constants/strings';
import { routes } from '../../constants/routes';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import useMutationHook from '../../hooks/useMutationHook';
import { useQueryClient } from 'react-query';
import * as EmailValidator from 'email-validator';
import { sendForgotPasswordEmail } from '../../helpers/authHelper';
import ToastAlert from '../../components/alert/ToastAlert';
import { AlertVariant } from '../../constants/constants';
import LogoComponent from '../../components/header/LogoComponent';

function ForgotPassword() {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [showError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const forgotPasswordMutation = useMutationHook(queryClient, true);
    const [showToast, setToast] = useState(false); // Toast alert state
    const [toastMessage, setToastMessage] = useState(""); // Toast message
    const [toastVariant, setToastVariant] = useState(""); // Toast Variant

    // send password reset email success
    const onSendPasswordEmailSuccess = () => {
        setToastMessage(PASSWORD_RESET_MAIL_SUCCESS);
        setToastVariant(AlertVariant.SUCCESS);
        setToast(true);
        setTimeout(() => {
            navigate(-1);
        }, 2000);
    }

    // error sending password reset email
    const sendEmailError = () => {
        setErrorMessage(FORGOT_PASSWORD_ERROR);
        setError(true);
    }

    return (
        <div className='p-0'>
            <ToastAlert data-testid="toast" show={showToast} onClose={setToast} message={toastMessage} variant={toastVariant} />
            <div data-testid="forgot-password" className="login d-flex justify-content-center align-items-center">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-sm-12 col-md-9 col-lg-5 col-xl-5 ">
                            <div className="login-box">
                                <div className="login-header text-center d-flex align-items-center justify-content-center">
                                    <LogoComponent />
                                </div>
                                <div className="login-body">
                                    <div className="form-section">
                                        <div className="mb-3 login-title text-center">
                                            <h5 className="mb-0">Forgot Password</h5>
                                            <small className="text-opacity-75">Enter your email and we'll send you a reset link.</small>
                                        </div>
                                        <input className="form-control form-control-lg mb-3" type="text" placeholder={EMAIL_TEXT}
                                            aria-label=".form-control-lg example"
                                            data-testid="email"
                                            autoFocus autoComplete="off"
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                                if (showError) {
                                                    setError(!EmailValidator.validate(e.target.value));
                                                }
                                            }} />
                                        {showError && <div data-testid="error" className='error-msg mb-3'>{errorMessage}</div>}
                                        <div className="d-grid gap-2 mb-3">
                                            <button type="button" className="btn btn-primary btn-lg btn-block btn-custom-primary"
                                                data-testid="send-button"
                                                disabled={email?.trim().length === 0}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (EmailValidator.validate(email)) {
                                                        setError(false);
                                                        sendForgotPasswordEmail(
                                                            forgotPasswordMutation,
                                                            email,
                                                            onSendPasswordEmailSuccess,
                                                            sendEmailError);
                                                    } else {
                                                        setErrorMessage(EMAIL_ERROR);
                                                        setError(true);
                                                    }
                                                }}>{SEND.toUpperCase()}</button>
                                        </div>
                                        <div className="row d-flex justify-content-start mb-2 forget-pass-sec">
                                            <div className="col-auto d-flex align-items-center fw-semibold "><i className="ri-arrow-left-line"></i><Link className="" to={routes.login}>{BACK_TO_LOGIN}</Link></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword;