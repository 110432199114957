import { RefObject, createRef, useEffect } from 'react';
import styled from 'styled-components';

//import { WheelCanvasStyle } from "react-custom-roulette/dist/components/WheelCanvas/styles";
import { clamp } from "../../../helpers/utils";

export const WheelCanvasStyle = styled.canvas`
  width: 98%;
  height: 98%;
`;

export interface StyleType {
    backgroundColor?: string;
    textColor?: string;
}

export interface WheelData {
    option: string;
    style?: StyleType;
}

interface WheelCanvasProps extends DrawWheelProps {
    width: string;
    height: string;
    data: WheelData[];
}

interface DrawWheelProps {
    outerBorderColor: string;
    outerBorderWidth: number;
    innerRadius: number;
    innerBorderColor: string;
    innerBorderWidth: number;
    radiusLineColor: string;
    radiusLineWidth: number;
    fontSize: number;
    perpendicularText: boolean;
    textDistance: number;
}

const drawWheel = (
    canvasRef: RefObject<HTMLCanvasElement>,
    data: WheelData[],
    drawWheelProps: DrawWheelProps
) => {
    const QUANTITY = data.length;
    /* eslint-disable prefer-const */
    let {
        outerBorderColor,
        outerBorderWidth,
        innerRadius,
        innerBorderColor,
        innerBorderWidth,
        radiusLineColor,
        radiusLineWidth,
        fontSize,
        perpendicularText,
        textDistance,
    } = drawWheelProps;
    /* eslint-enable prefer-const */

    outerBorderWidth *= 2;
    innerBorderWidth *= 2;
    radiusLineWidth *= 2;
    fontSize *= 2;

    const canvas = canvasRef.current;
    if (canvas?.getContext('2d')) {
        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
        ctx.clearRect(0, 0, 500, 500);
        ctx.strokeStyle = 'transparent';
        ctx.lineWidth = 0;
        // ctx.translate(0.5, 0.5)

        const arc = Math.PI / (QUANTITY / 2);
        const startAngle = 0;
        const outsideRadius = canvas.width / 2 - 10;

        const clampedTextDistance = clamp(0, 100, textDistance);
        const textRadius = (outsideRadius * clampedTextDistance) / 100;

        const clampedInsideRadius = clamp(0, 100, innerRadius);
        const insideRadius = (outsideRadius * clampedInsideRadius) / 100;

        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2;

        ctx.font = `bold ${fontSize}px Helvetica, Arial`;
        // background: linear-gradient(180deg, #EFA22A 0%, #FCCF42 100%);
        //  box-shadow: 0px 4px 24px rgba(66, 66, 66, 0.76), inset 1px 5px 18px rgba(0, 0, 0, 0.31);
        //ctx.fillStyle = "background: linear-gradient(180deg, #EFA22A 0%, #FCCF42 100%)";
        //ctx.fillStyle = 'blue'
        //ctx.fillRect(0, 0, canvas.width, canvas.height);

        /* ctx.shadowColor = 'red';
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;
        ctx.shadowBlur = 50; */




        for (let i = 0; i < data.length; i++) {
            const angle = startAngle + i * arc;
            const { style } = data[i];
            ctx.fillStyle = (style && style.backgroundColor) as string;

            ctx.beginPath();
            ctx.arc(centerX, centerY, outsideRadius, angle, angle + arc, false);
            ctx.arc(centerX, centerY, insideRadius, angle + arc, angle, true);
            ctx.stroke();
            ctx.fill();

            ctx.save();

            // WHEEL RADIUS LINES
            ctx.strokeStyle = radiusLineWidth <= 0 ? 'transparent' : radiusLineColor;
            ctx.lineWidth = radiusLineWidth;
            for (let j = 0; j < data.length; j++) {
                const radiusAngle = startAngle + j * arc;
                ctx.beginPath();
                ctx.moveTo(
                    centerX + (insideRadius + 1) * Math.cos(radiusAngle),
                    centerY + (insideRadius + 1) * Math.sin(radiusAngle)
                );
                ctx.lineTo(
                    centerX + (outsideRadius - 1) * Math.cos(radiusAngle),
                    centerY + (outsideRadius - 1) * Math.sin(radiusAngle)
                );
                ctx.closePath();
                ctx.stroke();
            }

            // WHEEL OUTER BORDER
            let grd = ctx.createLinearGradient(canvas.width / 2, 0, canvas.width / 2, canvas.height);
            grd.addColorStop(0, "#EFA22A");
            grd.addColorStop(1, "#FCCF42");

            ctx.strokeStyle =
                outerBorderWidth <= 0 ? 'transparent' : grd // outerBorderColor;
            ctx.lineWidth = 50 //outerBorderWidth;
            ctx.beginPath();
            ctx.arc(
                centerX,
                centerY,
                outsideRadius - ctx.lineWidth / 2,
                0,
                2 * Math.PI
            );
            ctx.closePath();
            ctx.stroke();

            // set shadowing
            ctx.lineWidth = 2
            ctx.beginPath();
            ctx.arc(
                centerX,
                centerY,
                outsideRadius + ctx.lineWidth / 2,
                0,
                2 * Math.PI
            );
            ctx.closePath();
            ctx.strokeStyle =
                outerBorderWidth <= 0 ? 'transparent' : grd
            ctx.shadowColor = 'rgba(0, 0, 0, 0.31)';
            ctx.shadowBlur = 18;
            ctx.shadowOffsetX = 1;
            ctx.shadowOffsetY = 5;
            ctx.stroke(); 

            // stop the shadowing
            ctx.shadowColor = 'rgba(0, 0, 0, 0)';

            // set compositing to erase everything outside the stroke
            ctx.globalCompositeOperation = 'destination-in';
            ctx.fill();

            // set compositing to erase everything outside the stroke
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = 'transparent';
            ctx.fill();

            // always clean up -- set compsiting back to default
            ctx.globalCompositeOperation = 'source-over';

            // set shadowing
            /* ctx.lineWidth = 50
            ctx.beginPath();
            ctx.arc(
                centerX,
                centerY,
                outsideRadius + ctx.lineWidth / 2,
                0,
                2 * Math.PI
            );
            ctx.closePath();
            ctx.strokeStyle = grd
            ctx.shadowColor = 'black';
            ctx.shadowBlur = 500;
            ctx.stroke(); */

            // stop the shadowing
            ctx.shadowColor = 'rgba(0,0,0,0)';

            // WHEEL INNER BORDER
            ctx.strokeStyle =
                innerBorderWidth <= 0 ? 'transparent' : innerBorderColor;
            ctx.lineWidth = innerBorderWidth;
            ctx.beginPath();
            ctx.arc(
                centerX,
                centerY,
                insideRadius + ctx.lineWidth / 2 - 1,
                0,
                2 * Math.PI
            );
            ctx.closePath();
            ctx.stroke();

            // TEXT FILL
            ctx.fillStyle = (style && style.textColor) as string;
            ctx.translate(
                centerX + Math.cos(angle + arc / 2) * textRadius,
                centerY + Math.sin(angle + arc / 2) * textRadius
            );
            const text = data[i].option;
            const textRotationAngle = perpendicularText
                ? angle + arc / 2 + Math.PI / 2
                : angle + arc / 2;
            ctx.rotate(textRotationAngle);
            ctx.fillText(text, -ctx.measureText(text).width / 2, fontSize / 2.7);
            ctx.restore();
        }
    }
};

const WheelCanvas = ({
    width,
    height,
    data,
    outerBorderColor,
    outerBorderWidth,
    innerRadius,
    innerBorderColor,
    innerBorderWidth,
    radiusLineColor,
    radiusLineWidth,
    fontSize,
    perpendicularText,
    textDistance,
}: WheelCanvasProps): JSX.Element => {
    const canvasRef = createRef<HTMLCanvasElement>();
    const drawWheelProps = {
        outerBorderColor,
        outerBorderWidth,
        innerRadius,
        innerBorderColor,
        innerBorderWidth,
        radiusLineColor,
        radiusLineWidth,
        fontSize,
        perpendicularText,
        textDistance,
    };

    useEffect(() => {
        drawWheel(canvasRef, data, drawWheelProps);
    }, [canvasRef, data, drawWheelProps]);

    return <WheelCanvasStyle ref={canvasRef} width={width} height={height} />;
};

export default WheelCanvas;