import { Tooltip, TooltipProps } from 'primereact/tooltip'

function MlxTooltip(props : Readonly<TooltipProps>) {
  return (
    <>
      <Tooltip target={props.target} autoZIndex={false} />
      {props.children}
    </>
  )
}

export default MlxTooltip