import { SetStateAction, useState, useContext, useEffect } from "react";
import { activeInactiveCategory, activeInactiveItem, categorySort, deleteCategory, deleteMenuItem, itemSort } from "../../../helpers/menuManagementHelper";
import { ADD_NEW, BEVERAGE, CATEGORY, CHOOSE_TYPE, FOOD, HELP_CATEGORY, HELP_ITEMS, HELP_MENU, ITEMS, MENU } from "../../../constants/strings";
import { arrayMove, horizontalListSortingStrategy, rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import { Card } from 'primereact/card';
import { checkPermission, isMobile, customDeepClone, filterMenuList } from "../../../helpers/utils";
import { closestCenter, DndContext, DragEndEvent, PointerSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { DebounceInput } from "react-debounce-input";
import { FaSearch } from "react-icons/fa";
import { FnBCtx } from "../../../context/MoveItemCtxProvider";
import { JTranslation, TranslationContext, jTranslationText } from "../../../helpers/jTranslate";
import { MenuItem, SubCategory, MenuItemsListProps, Menu } from "../../../constants/staticTypes";
import { NextButton, PrevButton, usePrevNextButtons } from "../guest/EmblaCarouselArrowButton";
import { PermissionKeys, SubPermissionKeys, WarningCallback, DragItemType, MOBILE_VIEW_BREAKPOINT, ADMIN_SELECTED_MENU, ADMIN_SELECTED_CATEGORY } from "../../../constants/constants";
import { RadioButton } from "primereact/radiobutton";
import { useQueryClient } from "react-query";
import { useWindowSize } from "react-use";
import { v4 as uuidv4 } from "uuid";
import CategoryComponent from "./draggable/CategoryComponent";
import CommonModal from "../../common_modal/CommonModal";
import FnBLoader from "./FnBLoader";
import ItemComponent from "./draggable/ItemComponent";
import MenuComponent from "./draggable/MenuComponent";
import MlxPopover from "../../common/MlxPopover";
import ToastAlert from "../../../components/alert/ToastAlert";
import useEmblaCarousel from "embla-carousel-react";
import useMutationHook from "../../../hooks/useMutationHook";
import WarningModal from "../../warning_modal/WarningModal";
import ChooseFoodType from "./ChooseFoodType";

function MenuItemsList({
  setFormType,
  setEditItem,
  setAddMenu,
  showAddMenu,
  menuList,
  selectedMenu,
  setSelectedMenu,
  setSelectedCategory,
  setDeleteItem,
  setAddCatgory,
  showAddCategory,
  selectedCategory,
  setAddItem,
  showAddItem,
  selectedItem,
  setSelectedItem,
  deleteItem,
  onSuccess,
  displayToast,
  setMenuList,
  upsertMenu,
  loading,
  currentMenuIndex,
  setCurrentMenuIndex,
  currentCategoryIndex,
  setCurrentCategoryIndex
}: MenuItemsListProps) {
  // react query variables
  const queryClient = useQueryClient();
  const deleteItemMutation = useMutationHook(queryClient, true);
  const sortItemMutation = useMutationHook(queryClient, true);
  const activeInactiveMutation = useMutationHook(queryClient, true); // activate inactivate items or category
  const { width, height } = useWindowSize();
  const breakPoint = MOBILE_VIEW_BREAKPOINT;
  // context variables
  const { dndLock } = useContext(FnBCtx);
  const translationContext = useContext(TranslationContext);
  const { targetLanguage } = translationContext;
  // state variables
  const [showWarning, setWarning] = useState(false); // Warning popup
  const [callbackType, setCallbackType] = useState<WarningCallback>();
  const [warningMessage, setWarningMessage] = useState("");
  const [alertVariant, setVariant] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showAlert, setShow] = useState(false);
  const [showCategorySelection, setShowCategorySelection] = useState(false);
  const [addType, setAddType] = useState<"food" | "beverage" | null>(null);
  const [search, setSearch] = useState("");
  const [showTitle, setShowTitle] = useState(true);
  const storedMenu = localStorage.getItem(ADMIN_SELECTED_MENU);
  const storedCategory = localStorage.getItem(ADMIN_SELECTED_CATEGORY);
  let storedCategoryData = storedCategory ? JSON.parse(storedCategory) : null; // Check if storedCategory is not null before parsing

  const [translationText, setTranslatedText] = useState<{ search: string }>({ search: "Search" });
  const [filteredMenuList, setFilteredMenuList] = useState<Menu[]>(customDeepClone(menuList) as Menu[]);
  const [emblaRef, emblaApi] = useEmblaCarousel({ dragFree: true });
  const [emblaRefCategory, emblaApiCategory] = useEmblaCarousel({ dragFree: true });

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  const {
    prevBtnDisabled: prevBtnDisabledCategory,
    nextBtnDisabled: nextBtnDisabledCategory,
    onPrevButtonClick: onPrevButtonClickCategory,
    onNextButtonClick: onNextButtonClickCategory,
  } = usePrevNextButtons(emblaApiCategory);

  useEffect(() => {
    const updatedMenuList = customDeepClone(menuList) as Menu[];
    setFilteredMenuList(updatedMenuList);
  }, [menuList]);

  // Translate on load and language switch
  useEffect(() => {
    const fetchTranslation = async () => {
      try {
        const translations = await Promise.all([
          jTranslationText({
            text: "Search",
            typeCase: "pascal",
            translationContext,
          }),
        ]);

        setTranslatedText({
          search: translations[0] ?? "Search",
        });
      } catch {
        setTranslatedText({
          search: "Search",
        });
      }
    };
    fetchTranslation();
  }, [targetLanguage]);

  // on active inactive toggle click
  const onActiveInactiveToggle = (
    id: string,
    message: string,
    callbackType: SetStateAction<WarningCallback | undefined>
  ) => {
    setDeleteItem(id);
    setCallbackType(callbackType);
    setWarningMessage(message);
    setWarning(true);
  };

  // drag n drop sensors
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 2,
      },
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  );

  // drag end handler
  const handleDragEnd = (event: DragEndEvent, type: string) => {
    const { active, over } = event;
    const oldIndex = active?.data.current && active.data.current.sortable.index;
    const newIndex = over?.data.current && over?.data.current.sortable.index;
    let sortedIds: string[] = [];

    switch (type) {
      case DragItemType.CATEGORY_DRAG:
        if (selectedMenu) {
          setSelectedCategory(
            selectedMenu.subCategory.filter((sub) => sub.id === active.id)[0]
          );
          let sortedCategoriesList = arrayMove(
            selectedMenu.subCategory,
            oldIndex,
            newIndex
          );
          sortedIds = sortedCategoriesList.map((listItem) => listItem.id);
          setSelectedMenu({
            ...selectedMenu,
            subCategory: sortedCategoriesList as [SubCategory],
          });
        }
        break;

      case DragItemType.ITEM_DRAG:
        if (selectedCategory) {
          setSelectedItem(
            selectedCategory.menuItems.filter(
              (item) => item.id === active.id
            )[0]
          );
          let sortedItemsList = arrayMove(
            selectedCategory?.menuItems,
            oldIndex,
            newIndex
          );
          sortedIds = sortedItemsList.map((listItem) => listItem.id as string);
          setSelectedCategory({
            ...selectedCategory,
            menuItems: sortedItemsList as [MenuItem],
          });
        }
        break;

      default:
        let newList = arrayMove(filteredMenuList, oldIndex, newIndex);
        sortedIds = newList.map((listItem) => listItem.id);
        setSelectedMenu(
          filteredMenuList.filter((menu) => menu.id === active.id)[0]
        );
        setMenuList(newList);
        break;
    }

    // invoke api
    if (type === DragItemType.ITEM_DRAG) {
      itemSort(sortedIds, sortItemMutation, onSuccess, displayToast);
    } else {
      categorySort(
        sortedIds,
        type === DragItemType.MENU_DRAG,
        sortItemMutation,
        onSuccess,
        displayToast
      );
    }
  };

  // effect to select first item in menu, category and items on page load
  useEffect(() => {
    if (!selectedMenu) setSelectedMenu(filteredMenuList[0]);
    if (!selectedCategory) setSelectedCategory(selectedMenu?.subCategory[0]);
    if (selectedItem) setSelectedItem(selectedCategory?.menuItems[0]);
  }, [selectedMenu]); // eslint-disable-line

  useEffect(() => {
    const clonedMenuList = customDeepClone(menuList) as Menu[];
    if (search.trim().length > 0) {
      const updatedMenuList = filterMenuList(clonedMenuList, search, true);
      setFilteredMenuList(updatedMenuList);
      setSelectedMenu(updatedMenuList[0]);
      setSelectedCategory(updatedMenuList[0]?.subCategory[0]);
    } else {
      setFilteredMenuList(clonedMenuList);
      setSelectedMenu(clonedMenuList[0]);
      setSelectedCategory(clonedMenuList[0]?.subCategory[0]);
    }
  }, [search]);

  useEffect(() => {
    if (storedMenu) {
      setSelectedMenu(JSON.parse(storedMenu));
      if (storedCategoryData) {
        setSelectedCategory(storedCategoryData)
        setSelectedItem(storedCategoryData?.menuItems[0]);
      } else {
        setSelectedCategory(selectedMenu?.subCategory[0]);
        setSelectedItem(selectedMenu?.subCategory[0]?.menuItems[0]);
      }
    }

    //find the index of the selected menu item in the slider
    let menuIndex = 0;
    menuIndex = menuList.findIndex((menu) => menu.id === selectedMenu?.id);
    setCurrentMenuIndex(menuIndex);

    //find the index of the selected category item in the slider
    let categoryIndex = 0;
    categoryIndex = selectedMenu ? selectedMenu?.subCategory.findIndex((category) => category.id === storedCategoryData?.id) : 0;
    setCurrentCategoryIndex(categoryIndex);
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (emblaApi)
        emblaApi.scrollTo(currentMenuIndex, false)
      if (emblaApiCategory)
        emblaApiCategory.scrollTo(currentCategoryIndex, false)
    }, 500);
  }, [emblaApi, currentMenuIndex, emblaApiCategory, currentCategoryIndex])

  return (
    <>
      <CommonModal
        modalSize="sm"
        show={showCategorySelection}
        modalContent={<ChooseFoodType addType={addType} setAddType={setAddType} setFormType={setFormType} />}
        onHide={setShowCategorySelection}
        title={CHOOSE_TYPE}
        callback={() => {
          setAddType(null)
          setAddItem(!showAddItem)
          setAddType(addType)
        }}
        disableProceed={!Boolean(addType)}
      />

      <WarningModal
        show={showWarning}
        title={warningMessage}
        onHide={setWarning}
        callback={() => {
          setWarning(false);
          switch (callbackType) {
            case WarningCallback.DEL_ITEM:
              deleteMenuItem(
                deleteItem,
                deleteItemMutation,
                onSuccess,
                displayToast
              );
              break;

            case WarningCallback.DEL_CATEGORY:
              deleteCategory(
                deleteItem,
                deleteItemMutation,
                onSuccess,
                displayToast
              );
              break;

            case WarningCallback.ACTIVATE_DEACTIVATE_ITEM:
              activeInactiveItem(
                queryClient,
                deleteItem,
                activeInactiveMutation,
                onSuccess,
                displayToast
              );
              break;

            default:
              activeInactiveCategory(
                deleteItem,
                activeInactiveMutation,
                onSuccess,
                displayToast
              );
              break;
          }
        }}
      />

      <ToastAlert
        data-testid="menu-item-toast"
        show={showAlert}
        onClose={setShow}
        message={errorMessage}
        variant={alertVariant}
      />

      <div className="card   h-100 overflow-auto">
        <div className="card-body flex-grow-1 overflow-auto">
          <div className="row mb-3">
            <div className="d-flex  align-items-center">
              <div className="flex-grow-1">
                {showTitle && (
                  <h4 className="mb-0 title">
                    <JTranslation typeCase="pascal" text={"F&B Menu"} />
                  </h4>
                )}
              </div>
              <div className="flex-grow-0 d-flex justify-content-end ">
                <div className="col-md-12">
                  <div
                    className={`form-group has-search ${!showTitle ? "" : "search-small-box"
                      }`}
                  >
                    <span className="fa fa-search form-control-feedback">
                      <FaSearch />{" "}
                    </span>
                    <DebounceInput
                      onFocusCapture={() => {
                        breakPoint >= width && setShowTitle(false);
                      }}
                      onBlurCapture={(event: any) => {
                        if (
                          breakPoint >= width &&
                          event.target.value.length === 0
                        ) {
                          setShowTitle(true);
                        }
                      }}
                      className="form-control"
                      placeholder={translationText.search}
                      minLength={0}
                      debounceTimeout={500}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-12 mb-3">
              <div className="menu-ttle-bg d-flex justify-content-between">
                <h5 className="d-flex align-items-center mb-0">
                  <JTranslation typeCase="pascal" text={MENU} />
                  <MlxPopover data={{ title: MENU, body: HELP_MENU }} />
                </h5>
                {isMobile() ? (
                  <button
                    type="button"
                    className=" btn btn-custom-primary-outline "
                    id="menu-add-new"
                    disabled={
                      !checkPermission(
                        queryClient,
                        PermissionKeys.MANAGE_MENU,
                        SubPermissionKeys.ADD
                      )
                    }
                    onClick={() => {
                      if (
                        checkPermission(
                          queryClient,
                          PermissionKeys.MANAGE_MENU,
                          SubPermissionKeys.ADD
                        )
                      ) {
                        setEditItem(undefined);
                        setAddMenu(!showAddMenu);
                      }
                    }}
                  >
                    <i className="ri-add-line me-1"></i>{" "}
                    <JTranslation typeCase="pascal" text={ADD_NEW} />
                  </button>
                ) : null}
              </div>
            </div>
            <div className="addMenuContainer position-relative">
              {!isMobile() ? (
                <div className="add-btn-outer">
                  <div className="d-flex">
                    <button
                      className="addMenubox-container"
                      id="menu-add-new"
                      data-testid="add-menu"
                      disabled={
                        !checkPermission(
                          queryClient,
                          PermissionKeys.MANAGE_MENU,
                          SubPermissionKeys.ADD
                        )
                      }
                      onClick={() => {
                        if (
                          checkPermission(
                            queryClient,
                            PermissionKeys.MANAGE_MENU,
                            SubPermissionKeys.ADD
                          )
                        ) {
                          setEditItem(undefined);
                          setAddMenu(!showAddMenu);
                        }
                      }}
                    >
                      <i className="ri-add-line"></i>
                      <h6>
                        <JTranslation typeCase="pascal" text={ADD_NEW} />
                      </h6>
                    </button>
                  </div>
                </div>
              ) : null}

              {/* SKELETON LOADER */}
              {loading && <FnBLoader />}
              {dndLock
                ? !loading && (
                  <div
                    id="menu-scroll"
                    className="view-btn-scroll-container d-inline-block overflow-x-hidden nav-scroller-content-new menu-config-menu"
                    key={"droppable-key"}
                    style={{ overflow: "auto" }}
                  >
                    {/* image-navigation using Embla Carousel */}
                    <div className="embla">
                      <div className="embla__viewport" ref={emblaRef}>
                        <div className="embla__container">
                          {filteredMenuList.map((menu) => {
                            return (
                              <MenuComponent
                                key={uuidv4()}
                                menu={menu}
                                onActiveInactiveToggle={
                                  onActiveInactiveToggle
                                }
                                queryClient={queryClient}
                                selectedMenu={selectedMenu}
                                setAddMenu={setAddMenu}
                                setCallbackType={setCallbackType}
                                setDeleteItem={setDeleteItem}
                                setEditItem={setEditItem}
                                setSelectedCategory={setSelectedCategory}
                                setSelectedItem={setSelectedItem}
                                setSelectedMenu={setSelectedMenu}
                                setWarning={setWarning}
                                setWarningMessage={setWarningMessage}
                                showAddMenu={showAddMenu}
                              />
                            );
                          })}
                        </div>
                        <div className="menu-btns">
                          <PrevButton
                            onClick={onPrevButtonClick}
                            disabled={prevBtnDisabled}
                          />
                          <NextButton
                            onClick={onNextButtonClick}
                            disabled={nextBtnDisabled}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )
                : !loading && (
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={(e) =>
                      handleDragEnd(e, DragItemType.MENU_DRAG)
                    }
                  >
                    <SortableContext
                      items={filteredMenuList.map((menu) => menu.id)}
                      strategy={horizontalListSortingStrategy}
                    >
                      <div
                        id="menu-scroll"
                        className="view-btn-scroll-container"
                        key={"droppable-key"}
                        style={{ overflow: "auto" }}
                      >
                        {filteredMenuList.map((menu) => {
                          return (
                            <MenuComponent
                              key={uuidv4()}
                              menu={menu}
                              onActiveInactiveToggle={onActiveInactiveToggle}
                              queryClient={queryClient}
                              selectedMenu={selectedMenu}
                              setAddMenu={setAddMenu}
                              setCallbackType={setCallbackType}
                              setDeleteItem={setDeleteItem}
                              setEditItem={setEditItem}
                              setSelectedCategory={setSelectedCategory}
                              setSelectedItem={setSelectedItem}
                              setSelectedMenu={setSelectedMenu}
                              setWarning={setWarning}
                              setWarningMessage={setWarningMessage}
                              showAddMenu={showAddMenu}
                            />
                          );
                        })}
                      </div>
                    </SortableContext>
                  </DndContext>
                )}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-12 mb-3">
              <div className="menu-ttle-bg d-flex justify-content-between">
                <h5 className="d-flex align-items-center mb-0">
                  <JTranslation typeCase="pascal" text={CATEGORY} />
                  <MlxPopover data={{ title: CATEGORY, body: HELP_CATEGORY }} />
                </h5>
                {isMobile() ? (
                  <button
                    type="button"
                    className=" btn btn-custom-primary-outline "
                    id="category-add-new"
                    onClick={() => {
                      if (
                        checkPermission(
                          queryClient,
                          PermissionKeys.MANAGE_MENU,
                          SubPermissionKeys.ADD
                        )
                      ) {
                        setEditItem(undefined);
                        setAddCatgory(!showAddCategory);
                      }
                    }}
                    disabled={
                      selectedMenu === undefined ||
                      !checkPermission(
                        queryClient,
                        PermissionKeys.MANAGE_MENU,
                        SubPermissionKeys.ADD
                      )
                    }
                  >
                    <i className="ri-add-line me-1"></i>{" "}
                    <JTranslation typeCase="pascal" text={ADD_NEW} />
                  </button>
                ) : null}
              </div>
            </div>
            <div className="addMenuContainer position-relative">
              {!isMobile() ? (
                <div className="add-btn-outer">
                  <div className="d-flex">
                    <button
                      className="addMenubox-container"
                      id="category-add-new"
                      data-testid="add-category"
                      onClick={() => {
                        if (
                          checkPermission(
                            queryClient,
                            PermissionKeys.MANAGE_MENU,
                            SubPermissionKeys.ADD
                          )
                        ) {
                          setEditItem(undefined);
                          setAddCatgory(!showAddCategory);
                        }
                      }}
                      disabled={
                        selectedMenu === undefined ||
                        !checkPermission(
                          queryClient,
                          PermissionKeys.MANAGE_MENU,
                          SubPermissionKeys.ADD
                        )
                      }
                    >
                      <i className="ri-add-line"></i>
                      <h6>
                        <JTranslation typeCase="pascal" text={ADD_NEW} />
                      </h6>
                    </button>
                  </div>
                </div>
              ) : null}

              {/* SKELETON LOADER */}
              {loading && <FnBLoader />}

              {dndLock
                ? !loading && (
                  <div
                    id="menu-scroll"
                    className="view-btn-scroll-container d-inline-block overflow-x-hidden nav-scroller-content-new menu-config-menu"
                    key={"droppable-key"}
                    style={{ overflow: "auto" }}
                  >
                    {/* image-navigation using Embla Carousel */}
                    <div className="embla">
                      <div className="embla__viewport" ref={emblaRefCategory}>
                        <div className="embla__container">
                          {selectedMenu?.subCategory.map((category) => {
                            return (
                              <CategoryComponent
                                key={uuidv4()}
                                setAddCatgory={setAddCatgory}
                                setCallbackType={setCallbackType}
                                setDeleteItem={setDeleteItem}
                                setEditItem={setEditItem}
                                setSelectedCategory={setSelectedCategory}
                                setSelectedItem={setSelectedItem}
                                setWarning={setWarning}
                                setWarningMessage={setWarningMessage}
                                selectedCategory={selectedCategory}
                                selectedMenu={selectedMenu}
                                showAddCategory={showAddCategory}
                                category={category}
                                onActiveInactiveToggle={
                                  onActiveInactiveToggle
                                }
                                queryClient={queryClient}
                              />
                            );
                          })}
                        </div>
                        <div className="category-btns">
                          <PrevButton
                            onClick={onPrevButtonClickCategory}
                            disabled={prevBtnDisabledCategory}
                          />
                          <NextButton
                            onClick={onNextButtonClickCategory}
                            disabled={nextBtnDisabledCategory}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )
                : !loading &&
                selectedMenu && (
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={(e) =>
                      handleDragEnd(e, DragItemType.CATEGORY_DRAG)
                    }
                  >
                    <SortableContext
                      items={selectedMenu.subCategory.map(
                        (category) => category.id
                      )}
                      strategy={rectSortingStrategy}
                    >
                      <div className="view-btn-scroll-container">
                        {selectedMenu.subCategory.map((category) => {
                          return (
                            <CategoryComponent
                              category={category}
                              key={uuidv4()}
                              onActiveInactiveToggle={onActiveInactiveToggle}
                              queryClient={queryClient}
                              selectedCategory={selectedCategory}
                              selectedMenu={selectedMenu}
                              setAddCatgory={setAddCatgory}
                              setCallbackType={setCallbackType}
                              setDeleteItem={setDeleteItem}
                              setEditItem={setEditItem}
                              setSelectedCategory={setSelectedCategory}
                              setSelectedItem={setSelectedItem}
                              setWarning={setWarning}
                              setWarningMessage={setWarningMessage}
                              showAddCategory={showAddCategory}
                            />
                          );
                        })}
                      </div>
                    </SortableContext>
                  </DndContext>
                )}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-12 mb-3">
              <div className="menu-ttle-bg d-flex justify-content-between">
                <h5 className="d-flex align-items-center  mb-0">
                  <JTranslation typeCase="pascal" text={ITEMS} />
                  <MlxPopover data={{ title: ITEMS, body: HELP_ITEMS }} />
                </h5>
                {isMobile() ? (
                  <button
                    type="button"
                    className=" btn btn-custom-primary-outline "
                    id="items-add-new"
                    onClick={() => {
                      if (
                        checkPermission(
                          queryClient,
                          PermissionKeys.MANAGE_MENU,
                          SubPermissionKeys.ADD
                        )
                      ) {
                        setEditItem(undefined);
                        setShowCategorySelection(true);
                      }
                    }}
                    disabled={
                      selectedMenu === undefined ||
                      selectedCategory === undefined ||
                      !checkPermission(
                        queryClient,
                        PermissionKeys.MANAGE_MENU,
                        SubPermissionKeys.ADD
                      )
                    }
                  >
                    <i className="ri-add-line me-1"></i>{" "}
                    <JTranslation typeCase="pascal" text={ADD_NEW} />
                  </button>
                ) : null}
              </div>
            </div>

            <div className="add-item-outer">
              {!isMobile() ? (
                <div className="add-new-section" id="items-add-new">
                  <div className="add-btn-outer">
                    <div className="d-flex">
                      <button
                        className="addMenubox-container"
                        data-testid="add-item"
                        onClick={() => {
                          if (
                            checkPermission(
                              queryClient,
                              PermissionKeys.MANAGE_MENU,
                              SubPermissionKeys.ADD
                            )
                          ) {
                            setEditItem(undefined);
                            setShowCategorySelection(true);
                          }
                        }}
                        disabled={
                          selectedMenu === undefined ||
                          selectedCategory === undefined ||
                          !checkPermission(
                            queryClient,
                            PermissionKeys.MANAGE_MENU,
                            SubPermissionKeys.ADD
                          )
                        }
                      >
                        <i className="ri-add-line"></i>
                        <h6>
                          <JTranslation typeCase="pascal" text={ADD_NEW} />
                        </h6>
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}

              {/* SKELETON LOADER */}
              {loading && <FnBLoader height="14rem" />}
              {dndLock
                ? !loading && (
                  <div
                    className="menu-item-section"
                    style={{ overflow: "auto" }}
                  >
                    {selectedCategory?.menuItems.map((item) => {
                      return (
                        <ItemComponent
                          item={item}
                          key={uuidv4()}
                          onActiveInactiveToggle={onActiveInactiveToggle}
                          onSuccess={onSuccess}
                          queryClient={queryClient}
                          selectedItem={selectedItem}
                          setAddItem={setAddItem}
                          setCallbackType={setCallbackType}
                          setDeleteItem={setDeleteItem}
                          setEditItem={setEditItem}
                          setSelectedItem={setSelectedItem}
                          setWarning={setWarning}
                          setWarningMessage={setWarningMessage}
                          showAddItem={showAddItem}
                          upsertMenu={upsertMenu}
                          setVariant={setVariant}
                          setShow={setShow}
                          setErrorMessage={setErrorMessage}
                        />
                      );
                    })}
                  </div>
                )
                : !loading &&
                selectedCategory && (
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={(e) =>
                      handleDragEnd(e, DragItemType.ITEM_DRAG)
                    }
                  >
                    <SortableContext
                      items={selectedCategory.menuItems.map(
                        (item) => item.id as string
                      )}
                      strategy={rectSortingStrategy}
                    >
                      <div
                        className="menu-item-section"
                        style={{ overflow: "auto" }}
                      >
                        {selectedCategory.menuItems.map((item) => {
                          return (
                            <ItemComponent
                              item={item}
                              key={uuidv4()}
                              onActiveInactiveToggle={onActiveInactiveToggle}
                              onSuccess={onSuccess}
                              queryClient={queryClient}
                              selectedItem={selectedItem}
                              setAddItem={setAddItem}
                              setCallbackType={setCallbackType}
                              setDeleteItem={setDeleteItem}
                              setEditItem={setEditItem}
                              setSelectedItem={setSelectedItem}
                              setWarning={setWarning}
                              setWarningMessage={setWarningMessage}
                              showAddItem={showAddItem}
                              upsertMenu={upsertMenu}
                              setVariant={setVariant}
                              setShow={setShow}
                              setErrorMessage={setErrorMessage}
                            />
                          );
                        })}
                      </div>
                    </SortableContext>
                  </DndContext>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MenuItemsList;
