import {JHapticFeedBack} from "./JHapticFeedBack"

// Import the default chime sound. Adjust the path as per your project structure.
import chimeSound from '../../assets/files/chime.wav';

// Import the functions to get effects for different celebrations
import {
  getChristmasEffects,
  getValentinesDayEffects,
} from './celebrationsConfigs';

export const playSound = (index: number = 0) => {
  // Get the effects configurations for both celebrations
  const christmasEffect = getChristmasEffects();
  const valentinesDayEffect = getValentinesDayEffects();

  // Destructure the configurations with fallback values to ensure functionality even if the configurations are not set
  const { playChime = false, chimeUrl = chimeSound } = christmasEffect || {};
  const { playChime: playValentineChime = false, chimeUrls = [] } = valentinesDayEffect || {};

  // Calculate the index for the Valentine's Day chime URLs, ensuring it loops correctly
  const soundIndex = index % (chimeUrls.length || 1); // Avoid division by 0 if chimeUrls is empty

  // Initialize the audio variable outside the if blocks to ensure it's accessible for playing
  let audio;

  // Decide which sound to play based on the celebration effect flags
  if (playChime) {
    audio = new Audio(chimeUrl);
    JHapticFeedBack(1)
  } else if (playValentineChime && chimeUrls.length > 0) { // Ensure there are URLs to play
    audio = new Audio(chimeUrls[soundIndex]);
    JHapticFeedBack(1)
    audio.volume = 0.4;
  }

  // Attempt to play the selected sound, handling any potential errors

  audio?.play().catch((error) => console.error('Error playing the sound:', error));
};
