import { AxiosResponse } from "axios"
import { QueryClient, UseMutationResult } from "react-query"
import { BetterLuckData } from "../components/game/admin/NoOfferText"
import { gameManagementApi, gameUpdateBetterLuckApi, guestConfirmPrizeApi, guestGameOptionsListApi, guestPrizeApi, gameStatusChange } from "../constants/apiEndPoints"
import { AlertVariant, HttpMethods } from "../constants/constants"
import { Payload, GameForm } from "../constants/staticTypes"
import { WheelSegments } from "../pages/game/GuestSpinWheel"
import HttpServiceHelper from "./httpServiceHelper"
import { getErrorMessage } from "./utils"

// initial form data
export const initialGameFormData = {
    prizeWinning: "",
    prizeDescription: "",
    prizeType: "discount",
    stockCount: "",
    couponCodeMaxDaysValidity: "",
    stockRepeatInterval: "DAILY",
    winningCriterias: {
        minVisitCount: "",
        weightage: "",
        locationRadiusInMiles: "",
        zipCodes: [] as string[]
    }
};

// get winning prize
export const getPrizeNumber = (prizes: WheelSegments[], winningPrize: string) => {
    return prizes.findIndex(item => item.id === winningPrize);
}

// add / edit offer
export const addEditOffer = (
    queryClient: QueryClient,
    editId: string,
    formData: GameForm,
    addEditMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onSuccess: (res: AxiosResponse<any, any>) => void,
    displayToast: (message: string, variant: string) => void
) => {
    type SubmitData = {
        gamePrizeOptionId?: string;
        prizeWinning: string;
        prizeDescription: string;
        prizeType: string;
        stockCount: number;
        couponCodeMaxDaysValidity: number,
        winningCriterias: {
            minVisitCount: number;
            weightage: number;
            locationRadiusInMiles?: number;
            zipCodes?: string[];
        };
    }

    let submitData = {
        ...formData,
        stockCount: parseInt(formData.stockCount),
        couponCodeMaxDaysValidity: parseInt(formData.couponCodeMaxDaysValidity),
        winningCriterias: {
            minVisitCount: parseInt(formData.winningCriterias.minVisitCount),
            weightage: parseInt(formData.winningCriterias.weightage)
        }
    } as SubmitData;

    if (formData.winningCriterias.zipCodes.length > 0) {
        submitData.winningCriterias.zipCodes = [...formData.winningCriterias.zipCodes];
    }

    if (formData.winningCriterias.locationRadiusInMiles.length > 0) {
        submitData.winningCriterias.locationRadiusInMiles = parseInt(formData.winningCriterias.locationRadiusInMiles);
    }

    let httpMethod = HttpMethods.POST;
    if (editId.length > 0) {
        submitData = {
            ...submitData,
            gamePrizeOptionId: editId
        }
        httpMethod = HttpMethods.PUT;
    }

    addEditMutation.mutate({
        queryClient,
        url: gameManagementApi,
        method: httpMethod,
        data: submitData
    }, {
        onSuccess: (res) => onSuccess(res),
        onError: (error) => displayToast(getErrorMessage(error), AlertVariant.ERROR)
    })
}

// fetch offers list
export const getOffersList = (queryClient: QueryClient, search: string, limit: number) => {
    return HttpServiceHelper({
        url: gameManagementApi + "?search=" + search + "&limit=" + limit,
        method: HttpMethods.GET,
        queryClient
    });
}

// update better luck text
export const updateBetterLuckData = (
    queryClient: QueryClient,
    formData: BetterLuckData,
    betterLuckTextUpdateMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    displayToast: (message: string, variant: string) => void
) => {
    betterLuckTextUpdateMutation.mutate({
        queryClient,
        url: gameUpdateBetterLuckApi,
        method: HttpMethods.PUT,
        data: formData
    }, {
        onSuccess: (res) => displayToast(res.data.message, AlertVariant.SUCCESS),
        onError: (error) => displayToast(getErrorMessage(error), AlertVariant.ERROR)
    })
}

// get guest prize options
export const getGuestPrizesList = (queryClient: QueryClient) => {
    return HttpServiceHelper({
        url: guestGameOptionsListApi,
        method: HttpMethods.GET,
        queryClient
    });
}

// game play
export const getPrize = (
    queryClient: QueryClient,
    surveyResponseId: string) => {
    return HttpServiceHelper({
        url: guestPrizeApi,
        method: HttpMethods.POST,
        data: {
            surveyResponseId
        },
        noAuth: true,
        queryClient
    });
}

// change offer status
export const changeOfferStatus = (
    queryClient: QueryClient,
    gamePrizeOptionId: string,
    isActive: boolean,
) => {
    return HttpServiceHelper({
        url: gameStatusChange,
        method: HttpMethods.PUT,
        data: {
            gamePrizeOptionId,
            isActive
        },
        queryClient
    });
}

// delete offer
export const deleteOffer = (
    queryClient: QueryClient,
    gamePrizeOptionId: string,
    deleteOfferMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onDeleteSuccess: (res: AxiosResponse<any, any>) => void,
    displayToast: (message: string, variant: string) => void) => {
    deleteOfferMutation.mutate({
        queryClient,
        url: gameManagementApi,
        method: HttpMethods.DELETE,
        data: {
            gamePrizeOptionId
        }
    }, {
        onSuccess: (res) => onDeleteSuccess(res),
        onError: (error) => displayToast(getErrorMessage(error), AlertVariant.ERROR)
    })
}

// confirm win 
export const confirmWin = (
    queryClient: QueryClient,
    gameWinMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    surveyResponseId: string, winId: string) => {
    gameWinMutation.mutate({
        queryClient,
        url: guestConfirmPrizeApi,
        method: HttpMethods.POST,
        noAuth: true,
        data: {
            surveyResponseId,
            winId
        }
    });
}