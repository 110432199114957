import { useEffect, useRef, useState } from "react";
import { AxiosResponse } from "axios";
import { CANCEL, ADD, UPDATE, HOLIDAY, MANDATORY, NO_TITLE, BUSINESS_CLOSE, MANDATORY_POPUP_CONTENT } from "../../constants/strings";
import { getFirstThreeLetter, timeFormatter } from "../../helpers/utils";
import { ColumnHeaderOptions } from "primereact/column";
import { confirmPopup } from "primereact/confirmpopup";
import { CustomEvent, EventFormData, Holidays, MandatoryDay, ScheduleMetadata, Staff, StaffRequirement, UpdateDraftMetaData, getDateOfWeekday, initialEventFormValues, isDateInRange, updateDraftMetadata } from "../../helpers/workTimeScheduleHelper";
import { DayOfWeek, Payload, PerDayCount } from "../../constants/staticTypes";
import { Formik, Form, Field, FieldProps, ErrorMessage } from "formik";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { JTranslation } from "../../helpers/jTranslate";
import { OverlayPanel } from "primereact/overlaypanel";
import { staffCountByDay } from "../../helpers/workTimeScheduleHelper";
import { TimePicker } from "antd";
import { UseMutationResult } from "react-query";
import { useTranslation } from "../../hooks/useTranslation";
import { v4 as uuidv4 } from 'uuid';
import dayjs from "dayjs";
import MlxPopover from "../common/MlxPopover";

type Props = {
    parent: 'web' | 'mobile',
    scheduleId: string,
    scheduleMetadata: ScheduleMetadata | null,
    customEvent: CustomEvent,
    scheduleMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onSuccess: (message: string, variant: string) => void,
    onError: (res: string, variant: string) => void
    day: DayOfWeek,
    selectedWeek: dayjs.Dayjs
    actionStatus: string | undefined
    staffingRequirement: PerDayCount[]
    headerData: ColumnHeaderOptions
    holidays: Holidays[];
    mandatoryDays: MandatoryDay[];
}

const CustomEventForm = ({
    parent,
    scheduleId,
    scheduleMetadata,
    customEvent,
    scheduleMutation,
    onSuccess,
    onError,
    day,
    selectedWeek,
    actionStatus,
    staffingRequirement,
    headerData,
    holidays,
    mandatoryDays,
}: Props) => {
    const addEventPanelRef = useRef<OverlayPanel>(null)
    const addStaffRequirementPanelRef = useRef<OverlayPanel>(null)
    const addedEvents = scheduleMetadata?.customEvents.find((event) => event.dayName.toLowerCase() === day.toLowerCase())
    const staffRequirementData = scheduleMetadata?.staffRequirement.find(data => data.dayName.toLowerCase() === day.toLowerCase())
    const minStaffRequirement = staffingRequirement?.find(data => data.dayName.toLowerCase() === day.toLowerCase())
    const deleteEventMessage = useTranslation('Do you want to delete this event?')

    const [eventId, setEventId] = useState<string>()
    const [initialEventFormData, setInitialEventFormData] = useState<EventFormData>(initialEventFormValues)
    const [staffRequirementFormData, setStaffRequirementFormData] = useState<StaffRequirement>()
    const [addEventPanelZIndex, setAddEventPanelZIndex] = useState(6000)

    const confirmDeleteEvent = (event: any, eventId: string) => {
        const accept = () => {
            const staffRequirement = scheduleMetadata?.staffRequirement ?? [] as StaffRequirement[]
            const customEvents = scheduleMetadata?.customEvents ?? [] as CustomEvent[]
            const updatedCustomEvents = customEvents.map((event): CustomEvent => {
                return {
                    date: event.date,
                    dayId: event.dayId,
                    dayName: event.dayName,
                    eventData: event.eventData.filter(data => data.id !== eventId),
                };
            })

            const params: UpdateDraftMetaData = {
                scheduleId,
                scheduleMetadata: {
                    staffRequirement: staffRequirement,
                    customEvents: updatedCustomEvents,
                },
            }
            // update API call
            updateDraftMetadata(scheduleMutation, params, onSuccess, onError)
        }
        const reject = () => { }

        confirmPopup({
            className: 'delete-event-confirm-popup',
            target: event.currentTarget,
            message: deleteEventMessage,
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept,
            reject
        });
    }

    // Retrieve the minimum staff count for a given day
    const getMinStaffCount = (): number => {
        const customCount = staffRequirementData?.customCount;
        const minCount = minStaffRequirement?.minCount;

        return customCount ? Number(customCount) : Number(minCount);
    }

    const getCurrentStaffCountForDay = () => {
        let staffsWithShift
        if (parent === 'web') {
            staffsWithShift = headerData.props.value as Staff[];
        } else {
            staffsWithShift = headerData as unknown as Staff[];
        }

        const date = getDateOfWeekday({ day, selectedWeek, format: 'YYYY-MM-DD' })
        const staffCount = staffsWithShift.reduce((count, staffData) => {
            const { shifts } = staffData;
            if (shifts[date]) {
                return count + 1;
            }
            return count;
        }, 0);

        return Number(staffCount);
    };
    const checkHoliday = () => {
        const date = getDateOfWeekday({ day, selectedWeek, format: 'YYYY-MM-DD' })
        const isHolidays = holidays.filter((holiday) => isDateInRange({ date: date, startDate: holiday.startDate, endDate: holiday.endDate })).length > 0
        return isHolidays
    };

    const getHolidayTitle = () => {
        const date = getDateOfWeekday({ day, selectedWeek, format: 'YYYY-MM-DD' })
        const holiday = holidays.find((holiday) => isDateInRange({ date: date, startDate: holiday.startDate, endDate: holiday.endDate }))
        return holiday?.title || NO_TITLE
    };

    const getIsOpenForBusiness = () => {
        const date = getDateOfWeekday({ day, selectedWeek, format: 'YYYY-MM-DD' })
        const holiday = holidays.find((holiday) => isDateInRange({ date: date, startDate: holiday.startDate, endDate: holiday.endDate }))
        return holiday ? holiday.isOpenForBusiness : false
    };

    const checkMandatoryDays = () => {
        const date = getDateOfWeekday({ day, selectedWeek, format: 'YYYY-MM-DD' })
        const isMandatory = mandatoryDays.filter((mandatoryDay) => isDateInRange({ date: date, startDate: mandatoryDay.startDate, endDate: mandatoryDay.endDate })).length > 0
        return isMandatory
    };

    const getColor = () => {
        const currentStaffCount = getCurrentStaffCountForDay() || 0;
        const minimumStaffCount = getMinStaffCount() || 0;

        if (currentStaffCount < minimumStaffCount) {
            return 'less';
        } else if (currentStaffCount === minimumStaffCount) {
            return 'equal';
        } else {
            return 'more';
        }
    };

    const saveStaffCount = () => {
        const currentStaffCount = getCurrentStaffCountForDay() || 0;
        const minimumStaffCount = getMinStaffCount() || 0;
        // update context with expected and current staff count
        staffCountByDay[day] = { current: currentStaffCount, expected: minimumStaffCount }
    }

    useEffect(() => {
        saveStaffCount()
    }, []) // eslint-disable-line

    return (
        <div className={`scheduler-header ${checkHoliday() && 'holiday-header'} ${checkMandatoryDays() && 'mandatory-day'}`}>
            <div className='mb-1 text-center text-nowrap fw-normal text-muted w-100'>
                <div className="d-flex align-center justify-content-center position-relative">
                    {getFirstThreeLetter(day)}
                    {checkMandatoryDays()
                        ? (<span className="help-main ms-3 position-absolute end-0">
                            <MlxPopover data={{ title: MANDATORY, body: MANDATORY_POPUP_CONTENT }} iconName="ri-calendar-check-line" />
                        </span>)
                        : checkHoliday()
                            ? (<span className="help-main ms-3 position-absolute end-0">
                                <MlxPopover data={{ title: getIsOpenForBusiness() ? HOLIDAY : BUSINESS_CLOSE, body: getHolidayTitle() }} iconName="ri-calendar-close-line" />
                            </span>)
                            : ""
                    }
                </div>

            </div>
            <div className="mb-2 text-center text-nowrap">{getDateOfWeekday({ selectedWeek, day: day })}</div>

            <div className="d-flex flex-row justify-content-center mb-2">
                <div className={`calender-headcount-display mb-1 ${getColor()}`} id={`pencil-${day}`}>
                    <div className={actionStatus !== 'draft' ? 'w-100 px-3 d-flex justify-content-between align-items-center' : ''}>
                        <i className="ri-group-line"></i>
                        <span className='ms-1'>{`${getCurrentStaffCountForDay()}/${getMinStaffCount()}`}</span>
                    </div>

                    {actionStatus === 'draft' && (
                        <>
                            <i
                                role="button"
                                className="ri-pencil-line edit-headcount"
                                onClick={(e) => {
                                    if (addStaffRequirementPanelRef.current) {
                                        if (staffRequirementData) {
                                            setStaffRequirementFormData(staffRequirementData)
                                            addStaffRequirementPanelRef.current.toggle(e)
                                        }
                                    }
                                }}
                            />

                            <OverlayPanel ref={addStaffRequirementPanelRef} >
                                <Formik
                                    initialValues={staffRequirementFormData ?? {} as StaffRequirement}
                                    validate={(data) => {
                                        let errors: any = {};
                                        if (data.customCount && data.customCount <= 0) {
                                            errors.customCount = 'requried';
                                        }
                                        return errors;
                                    }}
                                    onSubmit={(values) => {
                                        const customEvents = scheduleMetadata?.customEvents ?? [] as CustomEvent[]
                                        const staffRequirement = scheduleMetadata?.staffRequirement ?? [] as StaffRequirement[]
                                        const updatedStaffRequirements = staffRequirement.map((data): StaffRequirement => {
                                            if (data.dayName.toLowerCase() === values.dayName.toLowerCase()) {
                                                return { ...data, customCount: values.customCount }
                                            }
                                            return data
                                        })

                                        const params: UpdateDraftMetaData = {
                                            scheduleId,
                                            scheduleMetadata: {
                                                staffRequirement: updatedStaffRequirements,
                                                customEvents,
                                            },
                                        }
                                        // update API call
                                        updateDraftMetadata(scheduleMutation, params, onSuccess, onError)

                                        setStaffRequirementFormData({} as StaffRequirement)
                                        addStaffRequirementPanelRef.current?.hide()
                                    }}
                                >
                                    {(
                                        { errors, touched, submitForm, isValid, resetForm } // nosonar
                                    ) => (
                                        <Form>
                                            <div className='d-flex flex-column' style={{ minWidth: '320px' }}>
                                                <div className='mt-3 d-flex flex-column'>
                                                    <div className="mb-3">
                                                        <label htmlFor="customerCount" className="form-label">
                                                            <JTranslation typeCase="pascal" text={"Minimum Employee"} />
                                                        </label>
                                                        <Field name="customCount">
                                                            {({ field, form }: FieldProps) => (
                                                                <InputText
                                                                    type='number'
                                                                    className="form-control"
                                                                    id="customCount"
                                                                    data-testid="customer-count"
                                                                    autoComplete="off"
                                                                    value={field.value}
                                                                    onChange={(event) => form.setFieldValue(field.name, event.target.value, true)}
                                                                    style={
                                                                        errors.customCount && touched.customCount
                                                                            ? { border: '1px solid red' }
                                                                            : undefined
                                                                    }
                                                                />
                                                            )}
                                                        </Field>
                                                        <ErrorMessage className="formik-error" name="guests" component="div" render={(error) => <span className='formik-error'><JTranslation typeCase="pascal" text={error} /></span>} />
                                                    </div>
                                                </div>
                                                <div className='mt-1 d-flex flex-row justify-content-end'>
                                                    <button
                                                        type="button"
                                                        className="btn btn-custom-primary ms-3"
                                                        data-testid="add-new-event"
                                                        disabled={!isValid}
                                                        onClick={(event) => {
                                                            event.preventDefault()
                                                            submitForm()
                                                        }}
                                                    >
                                                        <JTranslation typeCase="pascal" text={UPDATE} />
                                                    </button>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </OverlayPanel>
                        </>
                    )}
                </div>
            </div>
            <div className="event-card-scroll">
                {addedEvents?.eventData.map((event) => {
                    const currentEvent = event as any
                    return (
                        <div key={event?.id} className="d-flex flex-row mb-0">
                            <div className="d-flex flex-row justify-content-between align-items-center px-2 reservation w-100">
                                <small className="text-nowrap w-100 text-center">{currentEvent?.guests} @ {timeFormatter({ time: currentEvent?.time, format: 'hh:mm A' })}</small>
                                {actionStatus === 'draft' ? (
                                    <>
                                        <i
                                            role="button"
                                            className="ri-pencil-line edit-headcount edit px-2"
                                            onClick={(e) => {
                                                if (addEventPanelRef.current) {
                                                    setEventId(event?.id)
                                                    setInitialEventFormData({ ...event, time: dayjs(event.time, 'HH:mm A') })
                                                    addEventPanelRef.current.toggle(e)
                                                }
                                            }}
                                        />
                                        {actionStatus === 'draft' && <i
                                            role="button"
                                            className="ri-delete-bin-6-line edit-headcount  ps-1 delete"
                                            onClick={(e) => {
                                                confirmDeleteEvent(e, event?.id)
                                            }}
                                        />}
                                    </>
                                ) : event?.desc && (
                                    <>
                                        <i
                                            role="button"
                                            className="ri-information-2-line edit-headcount px-2"
                                            onClick={(e) => {
                                                if (addEventPanelRef.current) {
                                                    addEventPanelRef.current.toggle(e)
                                                }
                                            }}
                                        />
                                        <OverlayPanel ref={addEventPanelRef}>
                                            <div style={{ width: '300px' }}>
                                                <p>{event?.desc}</p>
                                            </div>
                                        </OverlayPanel>
                                    </>
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>

            {actionStatus === 'draft' && (
                <div className='d-flex justify-content-between mt-1'>
                    {customEvent.eventData.length === 0 ? (
                        <>
                            <span className="reservation no-events white-space-nowrap flex-grow-1 ">
                                <JTranslation text='Add Events' typeCase='pascal' />

                                <i
                                    role="button"
                                    className="ri-add-circle-fill add-reservation-btn-only m-1 px-2"
                                    onClick={(event) => {
                                        if (addEventPanelRef.current) {
                                            setEventId(undefined)
                                            setInitialEventFormData(initialEventFormValues)
                                            addEventPanelRef.current.toggle(event)
                                        }
                                    }}
                                />
                            </span>

                        </>
                    ) : (
                        <span className="reservation no-events white-space-nowrap flex-grow-1 ">
                            <JTranslation text='Add Events' typeCase='pascal' />
                            <i
                                role="button"
                                className="ri-add-circle-fill add-reservation-btn-only m-1 px-2"
                                onClick={(event) => {
                                    if (addEventPanelRef.current) {
                                        setEventId(undefined)
                                        setInitialEventFormData(initialEventFormValues)
                                        addEventPanelRef.current.toggle(event)
                                    }
                                }}
                            />
                        </span>
                    )}

                    <OverlayPanel ref={addEventPanelRef} onShow={() => {
                        addEventPanelRef.current?.getElement()?.style.zIndex &&
                            setAddEventPanelZIndex(parseInt(addEventPanelRef.current.getElement().style.zIndex) + 5)
                    }}>
                        <Formik
                            initialValues={initialEventFormData}
                            validate={(data) => {
                                let errors: any = {};
                                if (data.guests <= 0) {
                                    errors.guests = 'requried';
                                }
                                if (data.guests > 999) {
                                    errors.guests = 'Guest count should not be greater than 999';
                                }
                                if (!data.time ?? !data?.time.isValid()) {
                                    errors.time = 'requried';
                                }
                                return errors;
                            }}
                            onSubmit={(values) => {
                                const updatedValues = { ...values, time: values.time.format('HH:mm A') }
                                const updatedEvents: any = customEvent.eventData.map((event: EventFormData) => {
                                    // edit
                                    if (event.id === eventId) {
                                        return {
                                            ...event,
                                            ...updatedValues
                                        }
                                    }
                                    return event
                                })
                                // if no entry, add 
                                if (updatedEvents.find((event: EventFormData) => event.id === eventId) === undefined) {
                                    updatedEvents.push({ ...updatedValues, id: uuidv4() })
                                }

                                const staffRequirement = scheduleMetadata?.staffRequirement ?? [] as StaffRequirement[]
                                const customEvents = scheduleMetadata?.customEvents ?? [] as CustomEvent[]
                                const updatedCustomEvents = customEvents.map((event): CustomEvent => {
                                    if (event.dayName.toLowerCase() === customEvent.dayName.toLowerCase()) {
                                        return { ...event, eventData: updatedEvents }
                                    }
                                    return event
                                })

                                const params: UpdateDraftMetaData = {
                                    scheduleId,
                                    scheduleMetadata: {
                                        staffRequirement: staffRequirement,
                                        customEvents: updatedCustomEvents,
                                    },
                                }
                                // update API call
                                updateDraftMetadata(scheduleMutation, params, onSuccess, onError)

                                setEventId(undefined)
                                setInitialEventFormData(initialEventFormValues)
                                addEventPanelRef.current?.hide()
                            }}
                        >
                            {(
                                { errors, touched, submitForm, isValid, resetForm } // nosonar
                            ) => (
                                <Form>
                                    <div className='d-flex flex-column' style={{ minWidth: '320px' }}>
                                        <h5 className="modal-title">
                                            <JTranslation text='Add Event' typeCase='pascal' /> {customEvent.dayName}
                                        </h5>
                                        <div className='mt-4 d-flex flex-column'>
                                            <div className="mb-3">
                                                <label htmlFor="customerCount" className="form-label">
                                                    <JTranslation typeCase="pascal" text={"No of Guests"} />
                                                </label>
                                                <Field name="guests">
                                                    {({ field, form }: FieldProps) => (
                                                        <InputText
                                                            type='number'
                                                            min={1}
                                                            max={999}
                                                            className="form-control"
                                                            id="customerCount"
                                                            data-testid="customer-count"
                                                            autoComplete="off"
                                                            value={field.value}
                                                            onChange={(event) => form.setFieldValue(field.name, event.target.value, true)}
                                                            style={
                                                                errors.guests && touched.guests
                                                                    ? { border: '1px solid red' }
                                                                    : undefined
                                                            }
                                                        />
                                                    )}
                                                </Field>
                                                <ErrorMessage className="formik-error" name="guests" component="div" render={(error) => <span className='formik-error'><JTranslation typeCase="pascal" text={error} /></span>} />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="time" className="form-label">
                                                    <JTranslation typeCase="pascal" text={"Time"} />
                                                </label>
                                                <Field name="time">
                                                    {({ field, form }: FieldProps) => (
                                                        <TimePicker
                                                            className="form-control"
                                                            popupClassName="custom-antd-time-picker"
                                                            size="middle"
                                                            use12Hours
                                                            clearIcon={false}
                                                            format="hh:mm A"
                                                            minuteStep={30}
                                                            value={field.value}
                                                            onChange={(value) => form.setFieldValue(field.name, value)}
                                                            style={
                                                                errors.time && touched.time
                                                                    ? { border: '1px solid red' }
                                                                    : undefined
                                                            }
                                                            inputReadOnly={true}
                                                            popupStyle={{ zIndex: addEventPanelZIndex }}
                                                        />
                                                    )}
                                                </Field>
                                                <ErrorMessage className="formik-error" name="time" component="div" render={(error) => <span className='formik-error'><JTranslation typeCase="pascal" text={error} /></span>} />
                                            </div>
                                            <div className="mb-3 d-flex flex-column">
                                                <label htmlFor="desc" className="form-label">
                                                    <JTranslation typeCase="pascal" text={"Description"} />
                                                </label>
                                                <Field name="desc">
                                                    {({ field, form }: FieldProps) => (
                                                        <InputTextarea
                                                            value={field.value}
                                                            onChange={(event) => form.setFieldValue(field.name, event.target.value)}
                                                            rows={5} cols={3}
                                                            autoResize
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                        </div>
                                        <div className='mt-3 d-flex flex-row justify-content-end'>
                                            <button
                                                type="button"
                                                className="btn btn-custom-primary-outline"
                                                onClick={() => {
                                                    resetForm()
                                                    addEventPanelRef.current?.hide()
                                                }}
                                            >
                                                <JTranslation typeCase="pascal" text={CANCEL} />
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-custom-primary ms-3"
                                                data-testid="add-new-event"
                                                disabled={!isValid}
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    submitForm()
                                                }}
                                            >
                                                <JTranslation typeCase="pascal" text={ADD} />
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </OverlayPanel>
                </div>
            )}
        </div>
    )
}

export default CustomEventForm