import React, { useEffect } from 'react'
import Lottie from 'react-lottie'
import animationData from './stPatricksDay.json'
import './AnimatedCharacter.css'

const AnimatedCharacter: React.FC = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  useEffect(() => {
    const jumpAnimation = () => {
      // Ensure the animation stays within the viewport
      const animationContainer = document.querySelector('.animation-container')
      if (animationContainer) {
        const animationHeight = 100
        const maxY = window.innerHeight - animationHeight

        // Simulate a jumping effect
        const jumpHeight = 30 // Adjust this value to control the height of the jump
        const isJumpingUp = Math.random() > 0.5
        const newY = maxY - (isJumpingUp ? jumpHeight : 0)

        // @ts-ignore
        animationContainer.style.bottom = `${newY}px`
        // @ts-ignore
        animationContainer.style.left = '0px' // Stick to the bottom left of the screen
      }
    }

    // // Move the animation to simulate jumping every 1 second
    // const intervalId = setInterval(jumpAnimation, 1000)

    // // Cleanup
    // return () => {
    //   clearInterval(intervalId)
    // }
  }, [])

  return (
    <div
      className='animation-container-character'
      style={{ position: 'fixed', bottom: '0', left: '0' }}
    >
      <Lottie
        options={defaultOptions}
        // height={80}
        // width={80}
        isPaused={false}
        isStopped={false}
      />
    </div>
  )
}

export default AnimatedCharacter
