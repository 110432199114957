import { useEffect, useState, useContext, useCallback } from 'react'
import {
	AlertVariant,
	FESTIVAL_TEMPLATES,
	MENU_RERENDER_INTERVAL,
	SELECTED_MENU,
	SPECIAL_MENU_CODE,
} from '../../constants/constants'
import { AxiosResponse } from 'axios'
import { CommonCtx } from '../../context/CommonCtxProvider'
import { FnBCtx } from '../../context/MoveItemCtxProvider'
import { countMenuItems, filterMenuList, getDomainFromURL } from '../../helpers/utils'
import {
	getMenuItemsPublic,
	getDefaultPrice,
	saveMenuSelection,
	setMenuData,
	shouldShowMenuCard,
	sliderSettings,
	getValueAfterHash,
	findMenuAndCategoryName,
} from '../../helpers/menuViewHelper'
import {
	ITEM_NOT_AVAILABLE,
	NO_SCHEDULE,
	REDIRECTION_FROM_ITEM_TITLE,
	REDIRECTION_FROM_ITEM_WARNING,
} from '../../constants/strings'
import { JTranslation } from '../../helpers/jTranslate'
import { Menu, Price, LocationState, FnBGlobalSetting, BrandingResponseData } from '../../constants/staticTypes'
import { PUBLIC_MENU, SELECT_MENU_ITEM, TENANT_INFO } from '../../constants/queryKeys'
import { routes } from '../../constants/routes'
import { ScrollTop } from 'primereact/scrolltop'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { useTrackEvent } from '../../hooks/useTrackEvent'
import { v4 as uuidv4 } from 'uuid'
import CardPopover from '../../components/floating_filter/CardPopover'
import CommonModal from '../../components/common_modal/CommonModal'
import Loader from '../../components/loader/Loader'
import placeholderImage from '../../assets/images/menu/540x360.png'
import Slider from 'react-slick'
import ToastAlert from '../../components/alert/ToastAlert'
import useQueryHook from '../../hooks/useQueryHook'
import withPublicHeader from '../../hoc/withPublicHeader'
import { playSound } from '../../components/celebrations/PlaySound'
import HeartIcon from '../../components/celebrations/valentinesDay/HeartIcon'
import { checkFestivalEffects } from '../../components/celebrations/helpers'
import { Button } from 'primereact/button'
import { DebounceInput } from 'react-debounce-input'
import { useWindowSize } from 'react-use'
import useEmblaCarousel from 'embla-carousel-react'
import { NextButton, PrevButton, usePrevNextButtons } from '../../components/f&b_menu/guest/EmblaCarouselArrowButton'
// let intervalId: NodeJS.Timer
let intervalId: ReturnType<typeof setInterval>
// let data: Menu[] = []

function GuestView() {
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const location = useLocation()
	const locationState = location.state as LocationState
	const storedSelectedMenu = localStorage.getItem(SELECTED_MENU)
	const { trackEvent } = useTrackEvent()
	const [emblaRef, emblaApi] = useEmblaCarousel({ dragFree: true })	

	const {
		prevBtnDisabled,
		nextBtnDisabled,
		onPrevButtonClick,
		onNextButtonClick
	} = usePrevNextButtons(emblaApi)
	
	// CONTEXT VARIABLES
	const { guestFnbGlobalSettings, setGuestFnbGlobalSettings, guestViewSearchFilter, setGuestViewSearchFilter } =
		useContext(CommonCtx)
	const { changeInSelectedMenu, setChangeInSelectedMenu, setSelectedTag, setUserSelectedMenu } = useContext(FnBCtx)
	// STATE VARIABLES
	const [menuItemsList, setMenuList] = useState<Menu[]>([])
	const [selectedMenu, setSelectedMenu] = useState<Menu>()
	const [showWarning, setShowWarning] = useState(false)
	const [sliderStatus, setSliderStatus] = useState(false)
	const [slider, setSlider] = useState<Slider | null>(null)
	const [currentMenuIndex, setCurrentMenuIndex] = useState(0)
	const [currentCategoryIndex, setCurrentCategoryIndex] = useState<string | null>(null)
	const [initialLoad, setInitialLoad] = useState(false)
	// TOAST VARIABLES
	const [showToast, setToast] = useState(false) // Toast alert state
	const [toastMessage, setToastMessage] = useState('') // Toast message
	const [toastVariant, setToastVariant] = useState('') // Toast Variant
	const [showSearch, setShowSearch] = useState(false)
	const [forceUpdateKey, setForceUpdateKey] = useState(0) // State to force re-render
	// URL PARAMS
	const { menuSlug, categorySlug } = useParams()

	const tenantInfoResponse = queryClient.getQueryData(TENANT_INFO) as AxiosResponse<any, any>
	let tenantInfo = tenantInfoResponse?.data?.data as BrandingResponseData
	const { branding } = tenantInfo
	const { width } = useWindowSize()
	const breakPointSearch = 526

	// set menu item from location state
	const setMenuItemFromLocationState = useCallback(
		(data: Menu[]) => {
			const selectedMenuItem = data.filter((menu) => menu.urlSlug === locationState?.menuId)[0]
			saveMenuSelection(queryClient, selectedMenuItem)
			setSelectedMenu(selectedMenuItem)
		},
		[locationState, queryClient]
	)

	// Filter out menus that are not scheduled to be displayed
	const filterScheduledMenuData = useCallback(() => {
		const res = queryClient.getQueryData(PUBLIC_MENU) as AxiosResponse<any, any>
		const data = res.data.data.list as Menu[]
		let dataIncludingSchedule: Menu[] = data?.filter((menu) => {
			if (
				shouldShowMenuCard(menu.periodicSchedules) === true ||
				shouldShowMenuCard(menu.periodicSchedules) === NO_SCHEDULE
			) {
				return menu
			}
		})

		setMenuData(dataIncludingSchedule, setMenuList)
		let selectedItem: Menu | undefined = queryClient.getQueryData(SELECT_MENU_ITEM) as Menu

		if (storedSelectedMenu) {
			selectedItem = JSON.parse(storedSelectedMenu) as Menu
		}

		if (selectedItem === undefined) {
			if (locationState?.menuId === undefined) {
				setSelectedMenu(dataIncludingSchedule[0])
			} else {
				setMenuItemFromLocationState(dataIncludingSchedule)
			}
		}
	}, [locationState, setMenuItemFromLocationState, storedSelectedMenu]) // eslint-disable-line

	// get item tags
	const getItemTags = (itemTag: string) => {
		const tags = itemTag.split(',')
		return tags.map((tag, index) => {
			return (
				<span key={uuidv4()} className="item-badge mb-1 mt-1">
					<JTranslation text={tag} />
				</span>
			)
		})
	}

	// get item price with field names
	const getItemPricesAndFieldNames = (itemPrices: Price[]) => {
		return getDefaultPrice(itemPrices).map((item, index) => {
			let fieldName = itemPrices.filter((price) =>
				Number(price.fieldValue) === Number(item) ? price.fieldName : null
			)
			return (
				<div key={uuidv4()} className="d-flex mb-0 align-items-baseline flex-column">
					<div className="price-ft w-100">
						<div className="d-flex text-nowrap overflow-hidden align-items-baseline">
							<div className="flex-grow-0 me-2">${item}</div>
							<span className="flex-grow-1 overflow-hidden text-ellipsis">
								<JTranslation text={fieldName[0]?.fieldName} />
							</span>
						</div>
					</div>
				</div>
			)
		})
	}

	const RedirectWarningModalContent = <p>{REDIRECTION_FROM_ITEM_WARNING}</p>

	const closeModalAndRedirect = () => {
		setShowWarning(false)
		// reload to menu view page
		window.location.reload()
	}

	const handleMenuList = (res: AxiosResponse<any, any>) => {
		let data = res.data.data.list as Menu[]
		let results_found: 'yes' | 'no' = 'no'
		let number_of_results = 0
		// Filter out menus
		const isKeywordSearched = !!guestViewSearchFilter.trim().length
		if (isKeywordSearched) {
			data = filterMenuList(data, guestViewSearchFilter)
			let count = countMenuItems(data, guestViewSearchFilter)
			results_found = count ? 'yes' : 'no'
			number_of_results = count
		}

		setGuestFnbGlobalSettings(res.data.data.globalSettings as FnBGlobalSetting)

		// Filter out menus that are not scheduled to be displayed
		const dataIncludingSchedule: Menu[] = data?.filter((menu) => {
			if (
				shouldShowMenuCard(menu.periodicSchedules) === true ||
				shouldShowMenuCard(menu.periodicSchedules) === NO_SCHEDULE
			) {
				return menu
			}
		})
		const updatedMenuData = setMenuData(dataIncludingSchedule, setMenuList)

		handleMenuSelection(updatedMenuData)
		handleUrlSlugs(updatedMenuData)

		return { results_found, number_of_results }
	}

	const handleMenuSelection = (menuList: Menu[]) => {
		const selectedItem = queryClient.getQueryData(SELECT_MENU_ITEM) as Menu

		let menuIndex = 0
		if (guestViewSearchFilter.trim().length > 0) {
			saveMenuSelection(queryClient, menuList[0])
			setSelectedMenu(menuList[0])
			menuIndex = menuList.findIndex((menu) => menu.id === menuList[0]?.id)
		} else if (!selectedItem?.id) {
			if (locationState?.menuId !== undefined) {
				menuIndex = locationState?.selectionIndex || 0
				setMenuItemFromLocationState(menuList)
			} else {
				saveMenuSelection(queryClient, menuList[0])
				setSelectedMenu(menuList[0])
			}
		} else {
			const selectedMenu = menuList.find((menu) => menu.id === selectedItem?.id)
			saveMenuSelection(queryClient, selectedMenu)
			setSelectedMenu(selectedMenu)
			menuIndex = menuList.findIndex((menu) => menu.id === selectedMenu?.id)
		}

		setCurrentMenuIndex(menuIndex)

		// React skips the effect if the new currentMenuIndex is the same as the previous one. So we need to force a rerender
		setForceUpdateKey((prevKey) => prevKey + 1)
	}

	const handleUrlSlugs = (menuList: Menu[]) => {
		if (menuSlug && !guestViewSearchFilter.trim().length) {
			const currentMenu = menuList.find((menu) => menu.urlSlug === menuSlug)
			const currentMenuIndex = menuList.findIndex((menu) => menu.urlSlug === menuSlug)
			if (currentMenu && currentMenuIndex) {
				saveMenuSelection(queryClient, currentMenu)
				setSelectedMenu(currentMenu)
				setCurrentMenuIndex(currentMenuIndex)
			} else {
				setSelectedMenu(menuList[0])
			}
			if (categorySlug) {
				setCurrentCategoryIndex(categorySlug)
			}
		}
	}

	// fetch menu items
	const fetchMenu = useQueryHook(
		PUBLIC_MENU,
		() => getMenuItemsPublic(queryClient),
		handleMenuList,
		undefined,
		true,
		false
	)

	const clearSearch = () => {
		saveMenuSelection(queryClient, undefined)
		setSelectedMenu(undefined)
		setGuestViewSearchFilter('')
		setShowSearch(false)

		if (guestViewSearchFilter.trim().length > 0) {
			fetchMenu.refetch()
		} else {
			const res = queryClient.getQueryData(PUBLIC_MENU) as AxiosResponse<any, any>
			if (res !== undefined) {
				handleMenuList(res)
			}
		}
	}

	// Scroll to category if the page is directed from link
	useEffect(() => {
		if (!window.onpopstate && currentCategoryIndex && !initialLoad) {
			const element = document.querySelector(`[data-scrollid="${currentCategoryIndex}"]`)
			if (element !== null) {
				setTimeout(() => {
					element.scrollIntoView({ behavior: 'smooth', block: 'center' })
					// set initial load
					setInitialLoad(true)
				}, 500)
			}
			setCurrentCategoryIndex(null)
		}
	}, [currentCategoryIndex])

	useEffect(() => {
		if (slider) {
			if (currentMenuIndex > 0) {
				setTimeout(() => {
					slider.slickGoTo(currentMenuIndex)
				}, 400)
			} else {
				slider.slickGoTo(currentMenuIndex)
			}
		}
	}, [forceUpdateKey, slider])

	// effect to save the selected menu item on local storage
	useEffect(() => {
		if (selectedMenu) {
			setUserSelectedMenu(selectedMenu)
			localStorage.setItem(SELECTED_MENU, JSON.stringify(selectedMenu))
			localStorage.removeItem(SPECIAL_MENU_CODE)
		}
	}, [selectedMenu])

	// effect to get the selected menu for local storage
	useEffect(() => {
		if (selectedMenu) {
			const { periodicSchedules } = selectedMenu

			const checkCardStatus = () => {
				let isParentMenuDisplayed = shouldShowMenuCard(periodicSchedules)
				setShowWarning(!isParentMenuDisplayed)
			}

			checkCardStatus()

			// checking card status on interval
			intervalId = setInterval(() => {
				filterScheduledMenuData()
				checkCardStatus()
			}, MENU_RERENDER_INTERVAL)

			// cleanup
			// stop the setInterval functions on page unmount
			return () => {
				clearInterval(intervalId)
			}
		}
	}, [selectedMenu, filterScheduledMenuData])

	useEffect(() => {
		// open search box
		if (guestViewSearchFilter) {
			setShowSearch(true)
		}

		// set data from cache if exist before api call
		const res = queryClient.getQueryData(PUBLIC_MENU) as AxiosResponse<any, any>
		if (res !== undefined) {
			handleMenuList(res)
		}

		// display success toast after guest submit survey
		if (locationState?.message !== undefined) {
			setToastVariant(AlertVariant.SUCCESS)
			setToastMessage(locationState.message)
			setToast(true)
			window.history.replaceState({}, document.title)
		}

		return () => {
			clearSearch()
		}
	}, []) // eslint-disable-line

	useEffect(() => {
		const res = queryClient.getQueryData(PUBLIC_MENU) as AxiosResponse<any, any>
		if (res !== undefined) {
			const { results_found, number_of_results } = handleMenuList(res)

			// Google Analytics
			if (guestViewSearchFilter && guestViewSearchFilter.length >= 3) {
				trackEvent({
					eventName: 'search_term',
					label: 'F&B Menu Search',
					value: {
						search_term: guestViewSearchFilter,
						results_found: results_found,
						number_of_results: number_of_results,
					},
				})
			}
		}
	}, [guestViewSearchFilter]) // eslint-disable-line

	useEffect(() => {
		setTimeout(() => {
			if(emblaApi)
			  emblaApi.scrollTo(currentMenuIndex, false)		
		}, 500);
	  }, [emblaApi, currentMenuIndex])

	return (
		<>
			{fetchMenu.isLoading ? (
				<Loader />
			) : (
				<>
					<div className="position-relative">
						<ToastAlert
							data-testid="toast"
							show={showToast}
							onClose={setToast}
							message={toastMessage}
							variant={toastVariant}
						/>
					</div>

					<ScrollTop
						target="parent"
						className="scroll-top"
						behavior="smooth"
						style={width < breakPointSearch && showSearch ? { bottom: '5rem' } : { bottom: '0.75rem'}}
					/>
					<div className="floating-container">
						<div className="search-bg">
							<div className="search-container flex-grow-1">
								<div className="d-flex align-items-center">
									{showSearch && (
										<div className="d-flex align-items-center flex-grow-1">
											<Button
												type="button"
												icon="pi pi-search"
												rounded
												text
												disabled
												className="search-icn-left"
											/>
											<DebounceInput
												autoComplete="off"
												autoFocus={true}
												className="form-control search-debounce-input"
												placeholder={'Search'}
												minLength={0}
												debounceTimeout={1000}
												data-testid="search-filter-text"
												onChange={(e) => setGuestViewSearchFilter(e.target.value ?? '')}
												value={guestViewSearchFilter}
											/>
											<Button
												type="button"
												icon="pi pi-times"
												rounded
												text
												className=" search-cancel"
												onClick={() => clearSearch()}
											/>
										</div>
									)}
									
								</div>
							</div>
							<div className='flex-grow-0 d-flex flex-row filter-container'>
							<Button
										type="button"
										icon="pi pi-search"
										className={' rounded-circle search-filter-btn'}
										onClick={() => setShowSearch(true)}
									/>
							<div className="categroy-container">
								<CardPopover
									type="CATEGORY"
									selectedMenu={selectedMenu}
									setSelectedMenu={setSelectedMenu}
								/>
							</div>
							<div className="tags-container">
								<CardPopover type="TAG" selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} />
							</div>
							</div>
							
						</div>
					</div>

					{/* reload modal popup */}
					<CommonModal
						show={showWarning}
						modalContent={RedirectWarningModalContent}
						title={REDIRECTION_FROM_ITEM_TITLE}
						hideCancel
						modalSize="md"
						onHide={closeModalAndRedirect}
						callback={closeModalAndRedirect}
						hideCloseButton
					/>

					<button className="survey-floating-btn">
						<i className="ri-survey-line"></i>
					</button>
					{/* image-navigation */}
					{/* <div className="col-12 p-0 image-menu-sticky">
						<div className="container-fluid">
							<div className="row ">
								<div className="col-lg-12 p-0">
									<div className="scroll-menu-container bg pt-3 pb-3">
										<div className="nav-scroller">
											<nav className="nav-scroller-nav">
												<div className={'nav-scroller-content-new main-menu'} id="menuscroll">
													<Slider
														{...sliderSettings}
														ref={(slider) => {
															setSlider(slider)
															if (
																slider &&
																locationState?.selectionIndex &&
																!sliderStatus
															) {
																setSliderStatus(true)
																slider.slickGoTo(locationState?.selectionIndex)
															}
														}}
													>
														{menuItemsList?.map((menu, index) => {
															return (
																<div
																	key={menu.id}
																	className={
																		'nav-scroller-item ' +
																		(selectedMenu?.id === menu.id && 'active') +
																		(guestViewSearchFilter.trim().length &&
																		!menu.subCategory.length
																			? ' block-inactive'
																			: '')
																	}
																	data-check={
																		guestViewSearchFilter.trim().length &&
																		!!menu.subCategory.length
																	}
																	data-testid={'menu#' + menu.id}
																	onClick={() => {
																		if (
																			guestViewSearchFilter.trim().length &&
																			!menu.subCategory.length
																		) {
																			return
																		}
																		saveMenuSelection(queryClient, menu)
																		setSelectedMenu(menu)
																		setChangeInSelectedMenu(
																			changeInSelectedMenu + 1
																		)
																		const element =
																			document.getElementById('scroll#0')
																		if (element !== null) {
																			element.scrollIntoView({ block: 'end' })
																		}
																		setSelectedTag([]) // remove tag selection on button
																		navigate(
																			`${routes.menu_view}/${menu.urlSlug}${location.search}`,
																			{
																				state: {
																					menuId: menu.urlSlug,
																					selectionIndex: index,
																				},
																			}
																		)
																		// Google Analytics
																		trackEvent({
																			eventName: 'select_menu',
																			label: 'Menu selection from the slider on guest view page',
																			value: {
																				page_path: '/menu',
																				page_location: `${getDomainFromURL()}/menu`,
																				menu_name: menu.categoryName,
																			},
																		})

																		if (
																			branding?.enableCelebrationEffect &&
																			[
																				FESTIVAL_TEMPLATES.CHRISTMAS_NEWYEAR,
																				FESTIVAL_TEMPLATES.VALENTINES_DAY,
																			].includes(
																				branding?.activeCelebrationTemplate as string
																			) &&
																			branding?.showCelebrationTemplateOn?.includes(
																				'allOtherPage'
																			)
																		) {
																			playSound(index)
																		}
																	}}
																>
																	<div className="menu-title">
																		<JTranslation text={menu.categoryName} />
																	</div>
																	<div className="overlay"></div>
																	<img
																		src={menu.signedUrl || placeholderImage}
																		alt="menu"
																	/>
																	{checkFestivalEffects(
																		branding,
																		FESTIVAL_TEMPLATES.VALENTINES_DAY,
																		location.pathname
																	) && <HeartIcon index={index} />}
																</div>
															)
														})}
													</Slider>
												</div>
											</nav>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div> */}
					
					{/* image-navigation using Embla Carousel */}
					<div className="col-12 p-0 image-menu-sticky">
						<div className="container-fluid">
							<div className="row ">
								<div className="col-lg-12 p-0">
									<div className="scroll-menu-container bg pt-3 pb-3">
										<div className="nav-scroller">
											<nav className="nav-scroller-nav">
												<div className={'nav-scroller-content-new main-menu'} id="menuscroll">
												<div className="embla">
													<div className="embla__viewport" ref={emblaRef}>
														<div className="embla__container">
															{menuItemsList?.map((menu, index) => {
																return (
																	<div
																		key={menu.id}
																		className={
																			'nav-scroller-item ' +
																			(selectedMenu?.id === menu.id && 'active') +
																			(guestViewSearchFilter.trim().length &&
																			!menu.subCategory.length
																				? ' block-inactive'
																				: '')
																		}
																		data-check={
																			guestViewSearchFilter.trim().length &&
																			!!menu.subCategory.length
																		}
																		data-testid={'menu#' + menu.id}
																		onClick={() => {
																			if (
																				guestViewSearchFilter.trim().length &&
																				!menu.subCategory.length
																			) {
																				return
																			}
																			saveMenuSelection(queryClient, menu)
																			setSelectedMenu(menu)
																			setChangeInSelectedMenu(
																				changeInSelectedMenu + 1
																			)
																			const element =
																				document.getElementById('scroll#0')
																			if (element !== null) {
																				element.scrollIntoView({ block: 'end' })
																			}
																			setSelectedTag([]) // remove tag selection on button
																			navigate(
																				`${routes.menu_view}/${menu.urlSlug}${location.search}`,
																				{
																					state: {
																						menuId: menu.urlSlug,
																						selectionIndex: index
																					},
																				}
																			)
																			// Google Analytics
																			trackEvent({
																				eventName: 'select_menu',
																				label: 'Menu selection from the slider on guest view page',
																				value: {
																					page_path: '/menu',
																					page_location: `${getDomainFromURL()}/menu`,
																					menu_name: menu.categoryName,
																				},
																			})

																			if (
																				branding?.enableCelebrationEffect &&
																				[
																					FESTIVAL_TEMPLATES.CHRISTMAS_NEWYEAR,
																					FESTIVAL_TEMPLATES.VALENTINES_DAY,
																				].includes(
																					branding?.activeCelebrationTemplate as string
																				) &&
																				branding?.showCelebrationTemplateOn?.includes(
																					'allOtherPage'
																				)
																			) {
																				playSound(index)
																			}
																		}}
																	>
																		<div className="menu-title">
																			<JTranslation text={menu.categoryName} />
																		</div>
																		<div className="overlay"></div>
																		<img
																			src={
																				menu?.photoThumbnails || placeholderImage
																			}
																			onError={(event)=>{event.currentTarget.src = menu.signedUrl }}
																			alt="menu"
																		/>
																		{checkFestivalEffects(
																			branding,
																			FESTIVAL_TEMPLATES.VALENTINES_DAY,
																			location.pathname
																		) && <HeartIcon index={index} />}
																	</div>
																)
															})}
														</div>
															<PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
															<NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
													</div>
												</div>
												</div>
											</nav>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="container guest-view-menu" data-testid="guest-view-menu">
						<div className="row mb-5">
							<div className="col-lg-12 p-0">
								<div className="card">
									<div className="card-body pb-0">
										{/* <!---------category and items-----> */}
										{selectedMenu?.subCategory
											?.filter((sub) => sub.isActive && sub.menuItems.length)
											.map((sub, index) => {
												return (
													<div
														className="row mt-4 mb-3"
														key={sub.id}
														data-testid={'sub#' + sub.id}
														id={`${getValueAfterHash(sub.id)}`}
													>
														<div
															className="col-md-12 text-center  mb-2"
															id={'scroll#' + index}
															data-scrollid={sub.urlSlug}
														>
															<h3 className="category-title-main">
																<JTranslation text={sub.categoryName} />
															</h3>
														</div>

														{sub.categoryDescription && (
															<div className="d-flex align-items-center justify-content-center text-center mb-4">
																<div className="col-md-10 col-lg-8">
																	<p className="category-additional-info mb-3">
																		<JTranslation
																			text={sub.categoryDescription}
																			type="html"
																		/>
																	</p>
																</div>
															</div>
														)}

														{sub.menuItems
															.filter((item) => item.isActive)
															.map((item) => {
																return (
																	<div
																		key={item.id}
																		className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 mb-3 p-md-2 p-sm-0 p-0"
																		data-testid={'item#' + item.id}
																		onClick={() => {
																			saveMenuSelection(queryClient, selectedMenu)
																			navigate(
																				// `${routes.menu_item_view}${location.search}`,
																				`${routes.menu_view}/${selectedMenu.urlSlug}/${sub.urlSlug}/${item.urlSlug}${location.search}`,
																				{
																					state: {
																						item: { ...item },
																						previousPath: `${routes.menu_view}/${selectedMenu.urlSlug}/${sub.urlSlug}/`
																					},
																				}
																			)

																			findMenuAndCategoryName({
																				menuItemId: item.id,
																				selectedMenu: selectedMenu,
																			}).then((result) => {
																				if (result) {
																					const { categoryName, menuName } =
																						result
																					const { itemName, itemPrices } =
																						item
																					const price = getDefaultPrice(
																						itemPrices
																					).map((item) =>
																						item === 'Infinity'
																							? null
																							: `$${item}`
																					)
																					// Google Analytics
																					trackEvent({
																						eventName: 'select_menu_item',
																						label: 'Menu item selection on guest view page',
																						value: {
																							page_path: '/menu',
																							page_location: `${getDomainFromURL()}/menu`,
																							menu_name: menuName,
																							menu_category: categoryName,
																							menu_item_name: itemName,
																							menu_item_price: price,
																						},
																					})
																				}
																			})
																		}}
																	>
																		<div className="item-list-main shadow-custom p-2">
																			<div className="item-list-image me-2 col-4  p-0">
																				{/* <img
																					src={
																						item?.photoThumbnails !== undefined ? item.photoThumbnails[0] : placeholderImage
																					}
																					onError={(event)=>{event.currentTarget.src = item.signedPhotoKeys ? item.signedPhotoKeys[0] : placeholderImage }}
																					className=""
																					alt=""
																				/> */}
																				<img 
																					src={Array.isArray(item?.photoThumbnails) && item.photoThumbnails.length > 0 
																						? item.photoThumbnails[0] 
																						: placeholderImage}
																					onError={(event) => {
																						const imgElement = event.currentTarget;
																						const fallbackAttempts = imgElement.getAttribute('data-fallback-attempts');
																					
																						// Handle first error (primary image failed)
																						if (!fallbackAttempts) {
																							// Try signedPhotoKeys as fallback if available
																							const fallbackImage = Array.isArray(item?.signedPhotoKeys) && item.signedPhotoKeys.length > 0 
																							? item.signedPhotoKeys[0] 
																							: placeholderImage;
																					
																							// Apply fallback image and set attribute to mark the attempt
																							imgElement.src = fallbackImage;
																							imgElement.setAttribute('data-fallback-attempts', '1'); // Mark the first attempt
																						}
																						// Handle second error (secondary image failed)
																						else if (fallbackAttempts === '1') {
																							// Final fallback to placeholderImage
																							imgElement.src = placeholderImage;
																							imgElement.setAttribute('data-fallback-attempts', '2'); // Mark the final attempt
																						}
																					}}
																					alt="menu item"
																					className="img-fluid"
																				/>
																			</div>

																			<div className="item-list-content col-8 h-100 px-2">
																				<div className="h-100 d-flex flex-column">
																					<div className="flex-grow-1">
																						<div className="title mb-0">
																							<JTranslation
																								text={item.itemName}
																							/>
																						</div>
																						{item.itemTag &&
																							getItemTags(item.itemTag)}
																						<div className="description mb-3 mt-0 mt-sm-0 mt-md-2">
																							<JTranslation
																								text={
																									item.itemDescription
																								}
																							/>
																						</div>
																					</div>

																					<div className="flex-grow-0">
																						<div className="market-price-text">
																							{item.isMarketPrice &&
																							guestFnbGlobalSettings.showMenuCardPrice ? (
																								<JTranslation
																									text={
																										guestFnbGlobalSettings.marketPriceLabel
																									}
																								/>
																							) : (
																								''
																							)}
																						</div>

																						{item.itemPrices.length > 0 &&
																						!item.isMarketPrice &&
																						guestFnbGlobalSettings.showMenuCardPrice &&
																						getDefaultPrice(item.itemPrices)
																							.length
																							? getItemPricesAndFieldNames(
																									item.itemPrices
																							  )
																							: ''}

																						<div className="position-relative d-flex align-items-center justify-content-start">
																							<div className=" col-9 not-available">
																								{item.isAvailable ? (
																									''
																								) : (
																									<JTranslation
																										text={
																											ITEM_NOT_AVAILABLE
																										}
																									/>
																								)}
																							</div>
																							<div className="col-3 item-arrow text-end">
																								<i className="ri-arrow-right-circle-fill"></i>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																)
															})}
													</div>
												)
											})}
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	)
}

export default withPublicHeader(GuestView)
