import { useState, useEffect, useContext } from 'react'
import { aiImageGeneratorParams } from '../../helpers/utils'
import { generateImageUsingAI } from '../../helpers/manageIngredientsHelper'
import { getImageDataURL } from "../../helpers/fileUploadHelper"
import { toastMessageInitialData, AlertVariant, IMAGE_TYPE, AI_IMAGE_SIZE_VALUES } from '../../constants/constants'
import { useQueryClient } from 'react-query'
import { v4 as uuidv4 } from 'uuid'
import ImageCropModal from '../f&b_menu/admin/add_item_forms/ImageCropModal'
import Loader from '../loader/Loader'
import ToastAlert from '../alert/ToastAlert'
import useMutationHook from '../../hooks/useMutationHook'

import {
    AiImage,
    AiImageGeneratorProps,
    AiImageResponse,
    AiImageSize,
    CropType,
    DisplayModalType,
    GenerateImageAiDescription,
    ToastMessageProps,
    UploadedAiImageInfo,
} from '../../constants/staticTypes'

import {
    GENERATE,
    IMAGE_PROMPT_PLACEHOLDER,
    LARGE,
    MEDIUM,
    PROMPT,
    REGENERATE_DESCRIPTION,
    IMAGE_SIZE,
    SMALL,
    USER_UPLOAD_PHOTO_ERROR,
} from '../../constants/strings'
import { JTranslation, TranslationContext, jTranslationText } from '../../helpers/jTranslate'

const AiImageGenerator = ({ callBack, type, cropType }: AiImageGeneratorProps) => {
    const currentCropType = cropType as CropType;
    const currentImageType = IMAGE_TYPE[currentCropType]
    const currentCropTypeWidth = currentImageType.width ?? 0
    const currentCropTypeHeight = currentImageType.height ?? 0
    const queryClient = useQueryClient()
    const aiImageMutation = useMutationHook(queryClient, true)
    const uuid = uuidv4()
    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext
    // state variables
    const [aiGeneratedCount, setAiGeneratedCount] = useState(0)
    const [aiGenerateImages, setAiGenerateImages] = useState<AiImage[]>([])
    const [imageCropSrc, setImageCrop] = useState<string | ArrayBuffer | null>(null)
    const [imageData, setImageData] = useState<UploadedAiImageInfo>({ imageKeys: [], signedImageUrls: [] })
    const [loader, setLoader] = useState(false)
    const [modalHeight, setModalHeight] = useState("")
    const [hideModalBody, setHideModalBody] = useState("")
    const [param, setParam] = useState<GenerateImageAiDescription>(aiImageGeneratorParams)
    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
    const [translationText, setTranslatedText] = useState<{
        imagePromptPlaceholder: string,
      }>({ 
        imagePromptPlaceholder: IMAGE_PROMPT_PLACEHOLDER,
    });

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: IMAGE_PROMPT_PLACEHOLDER, typeCase: 'pascal', translationContext }),
                ])
                setTranslatedText({
                    imagePromptPlaceholder: translations[0] ?? IMAGE_PROMPT_PLACEHOLDER,
                })
            } catch {
                setTranslatedText({
                    imagePromptPlaceholder: IMAGE_PROMPT_PLACEHOLDER,
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    useEffect(() => {
        getModalHeight()
    }, [param])

    useEffect(() => {
		if (currentCropTypeWidth <= AI_IMAGE_SIZE_VALUES.small && currentCropTypeHeight <= AI_IMAGE_SIZE_VALUES.small) {
			setParam({
				...param,
				imageSize: 'small',
			})
		} else if (currentCropTypeWidth <= AI_IMAGE_SIZE_VALUES.medium && currentCropTypeHeight <= AI_IMAGE_SIZE_VALUES.medium) {
			setParam({
				...param,
				imageSize: 'medium',
			})
		} else if (currentCropTypeWidth <= AI_IMAGE_SIZE_VALUES.large && currentCropTypeHeight <= AI_IMAGE_SIZE_VALUES.large) {
			setParam({
				...param,
				imageSize: 'large',
			})
		}

        // cleanup
        return () => {
            setAiGeneratedCount(0)
            setParam(aiImageGeneratorParams)
        }
    }, [])

    // effect to send the image data to the call back function
    useEffect(() => {
        const { imageKeys, signedImageUrls } = imageData

        if (imageKeys.length && signedImageUrls.length) {
            callBack(imageData)
        }
    }, [imageData, callBack])

    const displayModal = (value: DisplayModalType) => {
        setHideModalBody(value)
    }

    // on file upload success
    const onUploadSuccess = (key: string, imageUrl: string) => {
        setImageData((prevFormData) => ({
            ...prevFormData,
            signedImageUrls: [...prevFormData.signedImageUrls, imageUrl],
            imageKeys: [...prevFormData.imageKeys, key],
        }))
    }

    // on file upload error
    const onUploadError = () => {
        setToastMessage({
            message: USER_UPLOAD_PHOTO_ERROR,
            variant: AlertVariant.ERROR,
            show: true,
        })
    }

    // show toast
    const displayToast = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
    }

    const imagePreview = (url: string) => {
        return (
            <img
                className="gallery-thumb"
                alt="ai-generated-preview"
                src={url}
                key={uuidv4()}
                onClick={() => {
                    setLoader(true)
                    getImageDataURL(queryClient, url)
                        .then((res) => {
                            const { data } = res
                            setImageCrop(data)
                            setImageData({ imageKeys: [], signedImageUrls: [] })
                        })
                        .finally(() => {
                            setLoader(false)
                        })
                }}
            />
        )
    }

    const onSuccess = (response: AiImageResponse) => {
        const { aiResponse } = response
        setAiGenerateImages(aiResponse)
        setAiGeneratedCount((perCount) => perCount + 1)
    }

    // on add or update ingredient error
    const onError = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
    }

    const handleRadioButton = (size: AiImageSize) => {
        setParam((prevValue) => {
            return {
                ...prevValue,
                imageSize: size,
            }
        })
    }

    const onProceed = () => {
        generateImageUsingAI(aiImageMutation, param, onSuccess, onError)
    }

    const getModalHeight = () => {
        const div = document.querySelector('.modal-content');
        if (div) {
            const height = div.clientHeight
            setModalHeight(`${height}px`)
        }

    }

    return (
		<>
			{/* TOAST MESSAGE COMPONENT */}
			<ToastAlert
				show={toastMessage.show}
				onClose={() => setToastMessage(toastMessageInitialData)}
				message={toastMessage.message}
				variant={toastMessage.variant}
			/>

			{/* LOADER */}
			{loader && <Loader heightAuto />}

			<ImageCropModal
				displayToast={displayToast}
				image={imageCropSrc as string}
				mutation={aiImageMutation}
				onError={onUploadError}
				onSuccess={onUploadSuccess}
				setImageCrop={setImageCrop}
				show={imageCropSrc !== null}
				modalHeight={modalHeight}
				hideModal={displayModal}
				cropType={cropType}
			/>

			{!loader && (
				<div className={`ai-image-generator ${hideModalBody}`}>
					<div>
						<JTranslation typeCase="pascal" text={PROMPT} />
					</div>
					<textarea
						name={uuid}
						className="form-control image-prompt-textarea"
						value={param.positiveText}
						placeholder={translationText.imagePromptPlaceholder}
						onChange={(e) => {
							const prompt = e.target.value

							setParam((prevValue) => {
								return {
									...prevValue,
									positiveText: prompt,
									type: type,
								}
							})
						}}
					/>
					<div className=" col-md-12 col-lg-12  mt-3">
						<div className="mb-1">
							<JTranslation typeCase="pascal" text={IMAGE_SIZE} />
						</div>

						{(!currentCropType ||
							(
								currentCropType &&
								currentCropTypeWidth <= AI_IMAGE_SIZE_VALUES.small &&
								currentCropTypeHeight <= AI_IMAGE_SIZE_VALUES.small
							)) && (
							<div className="form-check form-check-inline mb-3">
								<input
									id="ai-image-small"
									className="form-check-input"
									type="radio"
									name={uuid}
									value={AiImageSize.SMALL}
									checked={param.imageSize === AiImageSize.SMALL}
									onChange={() => handleRadioButton(AiImageSize.SMALL)}
								/>
								<label htmlFor="ai-image-small">
									<JTranslation typeCase="pascal" text={SMALL} />
								</label>
							</div>
						)}
                        {(!currentCropType ||
							(
								currentCropType &&
								currentCropTypeWidth <= AI_IMAGE_SIZE_VALUES.medium &&
								currentCropTypeHeight <= AI_IMAGE_SIZE_VALUES.medium
							)) && (
                            <div className="form-check form-check-inline mb-3">
                                <input
                                    id="ai-image-medium"
                                    className="form-check-input"
                                    type="radio"
                                    name={uuid}
                                    value={AiImageSize.MEDIUM}
                                    checked={param.imageSize === AiImageSize.MEDIUM}
                                    onChange={() => handleRadioButton(AiImageSize.MEDIUM)}
                                />
                                <label htmlFor="ai-image-medium">
                                    <JTranslation typeCase="pascal" text={MEDIUM} />
                                </label>
                            </div>
                        )}
                        {(!currentCropType ||
							(
								currentCropType &&
								currentCropTypeWidth <= AI_IMAGE_SIZE_VALUES.large &&
								currentCropTypeHeight <= AI_IMAGE_SIZE_VALUES.large
							)) && (
                            <div className="form-check form-check-inline mb-3">
                                <input
                                    id="ai-image-large"
                                    className="form-check-input"
                                    type="radio"
                                    name={uuid}
                                    value={AiImageSize.LARGE}
                                    checked={param.imageSize === AiImageSize.LARGE}
                                    onChange={() => handleRadioButton(AiImageSize.LARGE)}
                                />
                                <label htmlFor="ai-image-large">
                                    <JTranslation typeCase="pascal" text={LARGE} />
                                </label>
                            </div>
                        )}
						<div className="col-12">
							<button
								type="button"
								className="btn  btn-custom-primary w-100"
								onClick={() => onProceed()}
								disabled={param.positiveText.length < 4}
							>
								{aiGeneratedCount ? (
									<JTranslation typeCase="pascal" text={REGENERATE_DESCRIPTION} />
								) : (
									<JTranslation typeCase="pascal" text={GENERATE} />
								)}
							</button>
						</div>

						{/* image thumbnails */}
						<div className="d-flex mt-3">{aiGenerateImages.map((image) => imagePreview(image.url))}</div>
					</div>
				</div>
			)}
		</>
	)
}

export default AiImageGenerator
