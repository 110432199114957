import { useState, useContext } from 'react'
import { approveExtractedResult } from '../../helpers/verifyDocumentTypeHelper'
import { Button } from 'primereact/button'
import { DOCUMENT_IDENTIFIED_CORRECTLY, DOCUMENT_IDENTIFIED_INCORRECTLY, CONFIRM } from '../../constants/strings'
import { ExtractedFileData, ThumpsUpOrDown, ActionButtonToBeEnabled } from '../../constants/staticTypes'
import { FileManagementCtx } from '../../context/FileManagementCtxProvider'
import { useQueryClient } from 'react-query'
import ToastAlert from '../../components/alert/ToastAlert'
import useMutationHook from '../../hooks/useMutationHook'
import WarningModal from '../../components/warning_modal/WarningModal'

const FileTypeVerificationActions = (props: ExtractedFileData) => {
  const {
    id,
    identifiedEntityUserApproval,
    isUserChangedInitialEntity,
    textractIdentifiedEntity,
    textractJobStatus,
  } = props

  const queryClient = useQueryClient()
  const verifyDocumentMutation = useMutationHook(queryClient, true)
  // CONTEXT VARIABLES
  const { setRefetchAPI, buttonToBeEnabled } = useContext(FileManagementCtx)
  // ALERT VARIABLES
  const [alertVariant, setVariant] = useState('')
  const [toastMessage, setToastMessage] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [thumpsUpModal, setThumpsUpModal] = useState(false)
  const [thumpsDownModal, setThumpsDownModal] = useState(false)

  const onSuccess = (message: string) => {
    setToastMessage(message)
    setVariant('success')
    setShowAlert(true)
    setRefetchAPI(true)
  }

  const onError = (errorMessage: string, variant: string) => {
    setToastMessage(errorMessage)
    setVariant(variant)
    setShowAlert(true)
    setRefetchAPI(true)
  }

  const thumbsUpButtonStatus = () => {
    let status = true
    buttonToBeEnabled.forEach((item: ActionButtonToBeEnabled) => {
      if (item.documentId === id) {
        status = false
      }
    })

    if ((textractJobStatus === 'SUCCEEDED' && textractIdentifiedEntity) || textractJobStatus === 'USER_REJECTED') {
      status = false
    }

    return status
  }

  const thumbsDownButtonStatus = () => {
    let status = true
    buttonToBeEnabled.forEach((item: ActionButtonToBeEnabled) => {
      if (item.documentId === id) {
        status = false
      }
    })
    if (textractJobStatus === 'SUCCEEDED' && textractIdentifiedEntity) {
      status = false
    }
    if (textractJobStatus === 'USER_REJECTED' || isUserChangedInitialEntity) {
      status = true
    }
    return status
  }

  // api call to record thumps up on document's auto file type recognition
  const thumpsUp = () => {
    let payload: ThumpsUpOrDown = { extractResultId: props.id, isExtractedTypeCorrect: true }
    approveExtractedResult(verifyDocumentMutation, payload, onSuccess, onError)
  }

  // api call to record thumps down on document's auto file type recognition
  const thumpsDown = () => {
    let payload: ThumpsUpOrDown = { extractResultId: props.id, isExtractedTypeCorrect: false }
    approveExtractedResult(verifyDocumentMutation, payload, onSuccess, onError)
  }

  return (
    <>
      <ToastAlert show={showAlert} onClose={setShowAlert} message={toastMessage} variant={alertVariant} />
      {/* THUMPS UP MODAL */}
      <WarningModal
        show={thumpsUpModal}
        onHide={() => setThumpsUpModal(false)}
        title={DOCUMENT_IDENTIFIED_CORRECTLY}
        primaryButtonName={CONFIRM}
        callback={() => {
          thumpsUp()
          setThumpsUpModal(false)
        }}
      />
      {/* THUMPS DOWN MODAL */}
      <WarningModal
        show={thumpsDownModal}
        onHide={() => setThumpsDownModal(false)}
        title={DOCUMENT_IDENTIFIED_INCORRECTLY}
        primaryButtonName={CONFIRM}
        callback={() => {
          thumpsDown()
          setThumpsDownModal(false)
        }}
      />
      <Button
        icon="pi pi-thumbs-up"
        className={
          identifiedEntityUserApproval === true
            ? 'p-button-sm p-button-success p-button-rounded me-2 custom-thumb-icon'
            : 'p-button-sm p-button-success p-button-rounded me-2 custom-thumb-icon p-button-outlined'
        }
        aria-label="Thumps up"
        title={DOCUMENT_IDENTIFIED_CORRECTLY}
        disabled={thumbsUpButtonStatus()}
        data-testid={"thumbsup#" + id}
        onClick={() => setThumpsUpModal(true)}
      />{' '}
      <Button
        icon="pi pi-thumbs-down"
        className={
          identifiedEntityUserApproval === false
            ? 'p-button-sm p-button-danger p-button-rounded custom-thumb-icon'
            : 'p-button-sm p-button-danger p-button-rounded custom-thumb-icon p-button-outlined'
        }
        aria-label="Thumps down"
        title={DOCUMENT_IDENTIFIED_INCORRECTLY}
        disabled={thumbsDownButtonStatus()}
        data-testid={"thumbsdown#" + id}
        onClick={() => setThumpsDownModal(true)}
      />
    </>
  )
}

export default FileTypeVerificationActions
