import { useRef } from 'react'
import { JTranslation } from '../../helpers/jTranslate'
import { OverlayPanel } from 'primereact/overlaypanel'
import { isMobile } from '../../helpers/utils'

type TypeCaseType = 'capitalize' | 'upper' | 'lower' | 'pascal' | 'none' | undefined

interface Props {
  data?: any
  titleTypeCase?: TypeCaseType
  bodyTypeCase?: TypeCaseType
  iconName?: string
}

function MlxPopover(props: Readonly<Props>) {
  const { iconName = "ri-question-line" } = props
  const panelRef = useRef<any>(null)
  const infoElement = useRef<any>(null)
  const bodyElement = document.body as HTMLElement

  const handleMouseEnter = (event: any) => {
    document.body.classList.add('no-scroll')
    panelRef?.current?.show(event)
    // Need a small delay to re-config the overlay position
    setTimeout(() => {
      panelRef?.current?.show(event)
      event.stopPropagation()
    }, 10)
  }

  const handleMouseLeave = (event: any) => {
    document.body.classList.remove('no-scroll')
    panelRef?.current?.hide(event)
  }

  const handleClick = (event: any) => {
    panelRef?.current?.toggle(event)
    // Need a small delay to re-config the overlay position
    setTimeout(() => {
      panelRef?.current?.show(event)
      event.stopPropagation()
    }, 10)
  }

  return (
    <>
      <span
        className="help-main ms-3"
        ref={infoElement}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        <i className={iconName}></i>
      </span>

      <OverlayPanel className="mlx-overlay-popover-custom" ref={panelRef} appendTo={bodyElement} >
        <div className="popover-heading d-flex justify-content-between">
          <JTranslation
            typeCase={props.titleTypeCase ? props.titleTypeCase : 'capitalize'}
            text={props.data.title}
          />
          {isMobile() ? <i className='ri-close-line' onClick={(event) => panelRef?.current?.hide(event)}></i> : ""}
        </div>
        <div className="overlay-popover-custom-body">
          <JTranslation typeCase="none" text={props.data.body} />
        </div>
      </OverlayPanel>
    </>
  )
}

export default MlxPopover
