import { useState, useContext, useEffect } from "react";
import "./styles/menu-management.scss";
import { ADMIN_SELECTED_CATEGORY, ADMIN_SELECTED_MENU, AlertVariant } from "../../constants/constants";
import { Button } from "primereact/button";
import { CommonCtx } from "../../context/CommonCtxProvider";
import { FnBCtx } from '../../context/MoveItemCtxProvider';
import { getMenuItems } from "../../helpers/menuManagementHelper";
import { MENU_LIST, TENANT_INFO } from "../../constants/queryKeys";
import { MENU_MANAGEMENT_TITLE, GLOBAL_CONFIGURATION, DOWNLOAD_QR_CODE } from "../../constants/strings";
import { MenuItem, Menu, SubCategory, BrandingResponseData } from "../../constants/staticTypes";
import { routes } from "../../constants/routes";
import { saveAs } from "file-saver";
import { useQueryClient } from "react-query";
import AddCategory from "../../components/f&b_menu/admin/AddCategory";
import AddItem from "../../components/f&b_menu/admin/AddItem";
import AddMenu from "../../components/f&b_menu/admin/AddMenu";
import axios, { AxiosResponse } from "axios";
import FnBMenuConfig from "../../components/f&b_menu/admin/FnBMenuConfig";
import FnBMenuSchedule from "../../components/f&b_menu/admin/FnBMenuSchedule";
import html2canvas from "html2canvas";
import Loader from "../../components/loader/Loader";
import MenuItemsList from "../../components/f&b_menu/admin/MenuItemsList";
import MoveItem from '../../components/f&b_menu/admin/MoveItem';
import qrcode from "qrcode-generator";
import ToastAlert from "../../components/alert/ToastAlert";
import useMutationHook from "../../hooks/useMutationHook";
import useQueryHook from "../../hooks/useQueryHook";
import withSidebar from "../../hoc/withSidebar";
import Spinner from 'react-bootstrap/Spinner';
import BsButton from 'react-bootstrap/Button';
import { JTranslation, TranslationContext, jTranslationText } from "../../helpers/jTranslate";
import MlxTooltip from "../../components/common/MlxTooltip";


enum PostFetchListAction { // action after api fetch
    UPDATE_MENU_SELECTION = "update-menu",
    RETAIN_MENU_SELECTION = "retain-menu",
    UPDATE_CATEGORY_SELECTION = "update-category",
    RETAIN_CATEGORY_SELECTION = "retain-category",
    UPDATE_ITEM_SELECTION = "update-item",
    RETAIN_ITEM_SELECTION = "retain-item"
}

function MenuManagement() {
    const queryClient = useQueryClient();
    const upsertMenuMutation = useMutationHook(queryClient, true);
    // STATE VARIABLES
    const [action, setAction] = useState(PostFetchListAction.RETAIN_MENU_SELECTION);
    const [currentMenuIndex, setCurrentMenuIndex] = useState(0);
    const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
    const [deleteItem, setDeleteItem] = useState<string | undefined>("");
    const [editItem, setEditItem] = useState<Menu | MenuItem | SubCategory | undefined>();
    const [generatingQR, setGeneratingQR] = useState(false);
    const [imageSrc, setImageSrc] = useState<string>('');
    const [isInitialLoad, setInitialLoad] = useState(true);
    const [loading, setLoading] = useState(false);
    const [menuList, setMenuList] = useState<Menu[] | []>([]);
    const [mobileLogUrl, setMobileLogoUrl] = useState({ url: '', imageKey: '' })
    const [selectedCategory, setSelectedCategory] = useState<SubCategory | undefined>();
    const [selectedItem, setSelectedItem] = useState<MenuItem | undefined>();
    const [selectedMenu, setSelectedMenu] = useState<Menu | undefined>();
    const [showAddCategory, setAddCategory] = useState(false);
    const [showAddItem, setAddItem] = useState(false);
    const [showAddMenu, setAddMenu] = useState(false);
    const [showToast, setToast] = useState(false); // Toast alert state
    const [toastMessage, setToastMessage] = useState(""); // Toast message
    const [toastVariant, setToastVariant] = useState(""); // Toast Variant
    const [isFoodForm, setFormType] = useState(true);
    const [translationText, setTranslatedText] = useState<{ globalConfiguration: string }>({ globalConfiguration: GLOBAL_CONFIGURATION });
    // CONTEXT VARIABLE
    const { scheduleMenu, setScheduleMenu, fnBGlobalSettings } = useContext(CommonCtx)
    const { moveItemSideBar, setMoveItemSideBar, fnBMenuConfigSideBar, setFnBMenuConfigSideBar } = useContext(FnBCtx)
    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext

    useEffect(() => {
        const fetchImage = async () => {
            if (!mobileLogUrl.url || imageSrc) return
            try {
                // Fetch the image as a Blob
                const response = await axios.get(mobileLogUrl.url, {
                    responseType: 'blob',
                });

                // Create a local URL for the blob
                const localUrl = URL.createObjectURL(response.data);

                // Update state with the local URL
                setImageSrc(localUrl);
            } catch (error) {
                console.error('Error fetching image:', error);
            }
        };

        fetchImage();
    }, [mobileLogUrl.url, imageSrc]);

    useEffect(() => {
        const res = queryClient.getQueryData(TENANT_INFO) as AxiosResponse<any, any>
        let data = res?.data?.data as BrandingResponseData
        const { branding } = data
        setMobileLogoUrl({ imageKey: '', url: branding?.logos?.small })
    }, [queryClient])

    useEffect(() => {
        // remove the local storage variables made for tracking the admin selections
        localStorage.removeItem(ADMIN_SELECTED_MENU)
        localStorage.removeItem(ADMIN_SELECTED_CATEGORY)

        //cleaning local storage on exit of the module
        return () => {
            localStorage.removeItem(ADMIN_SELECTED_MENU)
            localStorage.removeItem(ADMIN_SELECTED_CATEGORY)
        }
    }, [])

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: GLOBAL_CONFIGURATION, typeCase: 'pascal', translationContext }),
                ])
                setTranslatedText({
                    globalConfiguration: translations[0] ?? GLOBAL_CONFIGURATION,
                })
            } catch {
                setTranslatedText({
                    globalConfiguration: GLOBAL_CONFIGURATION,
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    // generate qr image
    const generateQR = async () => {
        let qrDiv;
        const canvas = await html2canvas((document.getElementById("qr-code") as HTMLElement), {
            onclone: (doc) => {
                qrDiv = doc.getElementById("qr-code") as HTMLElement;
                qrDiv.style.display = "inline-block";

                // add qr code
                const qrText = `${window.location.protocol}//${window.location.hostname}${routes.welcome_page}?qr_scan=true`;
                let qr = qrcode(0, 'L');
                qr.addData(qrText);
                qr.make();

                const qrTopLabel = fnBGlobalSettings?.qrCodeSettings?.qrCodeTopLabel;
                const qrTopBottom = fnBGlobalSettings?.qrCodeSettings?.qrCodeBottomLabel;

                if (qrTopLabel) {
                    const qrTopLabelElement = doc.getElementById("qr-top-label") as HTMLElement;
                    qrTopLabelElement.innerHTML = qrTopLabel;
                    // qrTopLabelElement.classList.add("qr-code-child qr-code-label-top");
                }
                if (qrTopBottom) {
                    const qrBottomLabelElement = doc.getElementById("qr-bottom-label") as HTMLElement;
                    qrBottomLabelElement.innerHTML = qrTopBottom;
                    // qrTopLabelElement.classList.add("qr-code-child qr-code-label-bottom");
                }
                (doc.getElementById("qr-img") as HTMLElement).innerHTML = qr.createSvgTag(10);
            }
        });
        let image = canvas.toDataURL("image/png", 1.0);
        return image;
    }

    // download qr
    const downloadQR = async () => {
        setGeneratingQR(true)
        let qrImage = await generateQR();
        setGeneratingQR(false)
        saveAs(qrImage, `F&B_Menu.png`);
    }

    // react query to fetch menu items
    const menuData = useQueryHook(MENU_LIST, getMenuItems, (res) => {
        let data = res.data.data as Menu[];
        setMenuList(data);
        if (selectedMenu !== undefined) {
            let filteredItem = data.filter(item => item.id === selectedMenu.id)[0];
            if (PostFetchListAction.UPDATE_MENU_SELECTION === action) {
                setSelectedMenu(data[data.length - 1]);
            } else {
                setSelectedMenu(filteredItem);
            }
            if (selectedCategory !== undefined) {
                setSelectedCategory({
                    ...selectedCategory,
                    menuItems: []
                });
                if (PostFetchListAction.UPDATE_CATEGORY_SELECTION === action) {
                    let subCategory = filteredItem?.subCategory[filteredItem?.subCategory.length - 1];
                    setSelectedCategory(subCategory);
                    setSelectedItem(subCategory?.menuItems[0]);
                } else if (PostFetchListAction.UPDATE_MENU_SELECTION !== action) {
                    let subCategory = filteredItem?.subCategory.filter(item => item.id === selectedCategory.id)[0];
                    setSelectedCategory(subCategory);
                    if (selectedItem !== undefined) {
                        setSelectedItem(subCategory?.menuItems.filter(item => item.id === selectedItem.id)[0]);
                        if (PostFetchListAction.UPDATE_ITEM_SELECTION === action) {
                            setSelectedItem(subCategory?.menuItems[subCategory.menuItems.length - 1]);
                        }
                    } else {
                        setSelectedItem(subCategory?.menuItems[0]);
                    }
                } else {
                    setSelectedCategory(undefined);
                    setSelectedItem(undefined);
                }
            } else if (filteredItem?.subCategory.length > 0) {
                let subCategory = filteredItem?.subCategory[filteredItem?.subCategory.length - 1];
                setSelectedCategory(subCategory);
                setSelectedItem(subCategory?.menuItems[0]);
            }
        } else {
            setSelectedMenu(data[0]);
            setSelectedCategory(data[0]?.subCategory[0]);
            setSelectedItem(data[0]?.subCategory[0]?.menuItems[0]);
        }
        setInitialLoad(false);
    });

    useEffect(() => {
        setLoading(menuData.isFetching)

        return () => {
            setLoading(false)
        }
    }, [menuData])

    if (menuData.isLoading || isInitialLoad) {
        return <Loader />
    }

    // show toast
    const displayToast = (message: string, variant: string) => {
        setToastVariant(variant);
        setToastMessage(message);
        setToast(true);
    }

    // api success response
    const onSuccess = (message: string) => {
        queryClient.fetchQuery(MENU_LIST, getMenuItems);
        displayToast(message, AlertVariant.SUCCESS);
        let actionValue = PostFetchListAction.RETAIN_MENU_SELECTION;
        if (showAddMenu && !editItem) {
            actionValue = PostFetchListAction.UPDATE_MENU_SELECTION;
        } else if (showAddCategory) {
            actionValue = PostFetchListAction.RETAIN_CATEGORY_SELECTION;
            if (!editItem) {
                actionValue = PostFetchListAction.UPDATE_CATEGORY_SELECTION;
            }
        } else if (showAddItem) {
            actionValue = PostFetchListAction.RETAIN_ITEM_SELECTION;
            if (!editItem) {
                actionValue = PostFetchListAction.UPDATE_ITEM_SELECTION;
            }
        }
        setAction(actionValue);
        setAddMenu(false);
        setAddCategory(false);
        setAddItem(false);
        setDeleteItem("");
        setEditItem(undefined);
        setMoveItemSideBar(false)
    }

    return (
        <>
            <ToastAlert data-testid="toast" show={showToast} onClose={setToast} message={toastMessage} variant={toastVariant} />
            {/* move item side drawer */}
            <MoveItem show={moveItemSideBar} handleClose={() => setMoveItemSideBar(false)} onSuccess={onSuccess} upsertMenu={upsertMenuMutation} />
            {/* F&B menu configuration side drawer */}
            <FnBMenuConfig show={fnBMenuConfigSideBar} handleClose={() => setFnBMenuConfigSideBar(false)} displayToast={displayToast} />
            {/* Menu schedule configuration side drawer */}
            <FnBMenuSchedule show={scheduleMenu} handleClose={() => setScheduleMenu(false)} displayToast={displayToast} />
            <AddMenu show={showAddMenu} handleClose={() => setAddMenu(!showAddMenu)} editItem={editItem}
                upsertMenu={upsertMenuMutation} displayToast={displayToast} onSuccess={onSuccess} />
            <AddCategory show={showAddCategory} handleClose={() => setAddCategory(!showAddCategory)} editItem={editItem}
                parentId={selectedMenu?.id} upsertMenu={upsertMenuMutation} onSuccess={onSuccess} />


            <div id="qr-code" className='qr-code-parent' style={{ display: "none" }}>
                <p id="qr-top-label" className='qr-code-child qr-code-label-top'></p>
                <div className="qr-image-wrapper">
                    {mobileLogUrl.url && (<img alt="small-logo" src={imageSrc} className="fnb-qr-code-logo" />)}
                    <div id="qr-img" ></div>
                </div>
                <p id="qr-bottom-label" className='qr-code-child qr-code-label-bottom'></p>
            </div>

            <div className="col-md-12 col-lg-12 mb-3 h-100 ">
                <div className="page-title d-flex mb-3  align-items-sm-center flex-column flex-sm-row">


                    <div className="flex-grow-1 mb-1 mb-sm-">
                        <h5 className="mb-0"><JTranslation typeCase="pascal" text={MENU_MANAGEMENT_TITLE} /></h5>
                    </div>

                    <div className="flex-grow-0 align-items-center d-flex align-self-end">
                        <BsButton
                            className="btn btn-custom-primary-outline text-nowrap"
                            type="button"
                            data-testid="download-qrcode"
                            disabled={!imageSrc || generatingQR}
                            onClick={(e) => {
                                e.stopPropagation();
                                downloadQR();
                            }}
                        >
                            {generatingQR ? (
                                <Spinner className="me-2" animation="border" size="sm" />
                            ) : (
                                <i className="ri-download-line me-2"></i>
                            )}
                            <JTranslation typeCase="none" text={DOWNLOAD_QR_CODE} />
                        </BsButton>


                        {/* F&B configuration*/}
                        <MlxTooltip target='.p-button-rounded'>
                            <Button
                                icon="ri-settings-4-line "
                                className=" p-button-rounded p-button-primary p-button-text fs-4 color-primary-custom"
                                data-pr-tooltip={translationText.globalConfiguration}
                                data-pr-position="left"
                                data-testid="global-config"
                                onClick={() => { setFnBMenuConfigSideBar(!fnBMenuConfigSideBar) }}
                            />
                        </MlxTooltip>
                    </div>
                </div>

                {showAddItem ? (
                    <AddItem
                        isFoodForm={isFoodForm}
                        setFormType={setFormType}
                        setAddItem={setAddItem}
                        displayToast={displayToast}
                        editItem={editItem}
                        itemCategoryId={selectedCategory?.id}
                        onSuccess={onSuccess}
                    />
                ) : (
                    <MenuItemsList
                        setFormType={setFormType}
                        onSuccess={onSuccess}
                        displayToast={displayToast}
                        showAddItem={showAddItem}
                        setMenuList={setMenuList}
                        deleteItem={deleteItem}
                        selectedItem={selectedItem}
                        setSelectedMenu={setSelectedMenu}
                        selectedCategory={selectedCategory}
                        setDeleteItem={setDeleteItem}
                        setEditItem={setEditItem}
                        setAddItem={setAddItem}
                        setAddMenu={setAddMenu}
                        setSelectedCategory={setSelectedCategory}
                        menuList={menuList}
                        showAddCategory={showAddCategory}
                        showAddMenu={showAddMenu}
                        setAddCatgory={setAddCategory}
                        selectedMenu={selectedMenu}
                        setSelectedItem={setSelectedItem}
                        upsertMenu={upsertMenuMutation}
                        loading={loading}
                        currentMenuIndex={currentMenuIndex}
                        setCurrentMenuIndex={setCurrentMenuIndex}
                        currentCategoryIndex={currentCategoryIndex}
                        setCurrentCategoryIndex={setCurrentCategoryIndex}
                    />
                )}
            </div>
        </>
    )
}
export default withSidebar(MenuManagement);