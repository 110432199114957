import { useState } from 'react'
import { AxiosResponse } from 'axios'
import { initialPaginationData } from '../../helpers/guestInfoListHelper'
import { getNewsAndEventsList } from '../../helpers/newsAndEventsHelper'
import { NEWS_AND_EVENTS_LIST } from '../../constants/queryKeys'
import { NewsAndEventsListType, PaginationData } from '../../constants/staticTypes'
import { NEWS_AND_EVENTS_HELP, NEWS_AND_EVENTS_MANAGEMENT } from '../../constants/strings'
import { OverlayTrigger } from 'react-bootstrap'
import { popover } from '../../helpers/utils'
import { useQueryClient } from 'react-query'
import Loader from '../../components/loader/Loader'
import NewsAndEventsListGrid from '../../components/news_&_events/NewsAndEventsListGrid'
import useQueryHook from '../../hooks/useQueryHook'
import withSidebar from '../../hoc/withSidebar'
import { JTranslation } from '../../helpers/jTranslate'
import MlxPopover from '../../components/common/MlxPopover'

function NewsAndEvents() {
    const queryClient = useQueryClient()
    const [isInitialLoad, setInitialLoad] = useState(true)
    const [paginationData, setPaginationData] = useState<PaginationData>(initialPaginationData)
    const [newsAndEventsList, setNewsAndEventsList] = useState<NewsAndEventsListType[]>([])

    // users list fetch success
    const onSuccess = (res: AxiosResponse<any, any>) => {
        setNewsAndEventsList(res.data?.data.list)
        setPaginationData(res.data?.data.paginationData)
        setInitialLoad(false)
    }

    // fetch news and events list
    const newsAndEventsData = useQueryHook(
        NEWS_AND_EVENTS_LIST,
        () => getNewsAndEventsList('', 500, paginationData.lastKey),
        onSuccess
    )

    if (newsAndEventsData.isLoading || isInitialLoad) {
        return <Loader />
    }

    // on trigger search
    const onSearchTrigger = (searchKey: string) => {
        if (newsAndEventsData.isFetching) {
            queryClient.cancelQueries([NEWS_AND_EVENTS_LIST])
        }
        queryClient.fetchQuery(NEWS_AND_EVENTS_LIST, () => getNewsAndEventsList(searchKey, 500, ''))
    }

    return (
        <>
            <div className="col-md-12 col-lg-12 mb-3 h-100 ">
                <div className="page-title">
                    <h5 className="d-flex">
                        <JTranslation typeCase="pascal" text={NEWS_AND_EVENTS_MANAGEMENT} />
                        <MlxPopover data={{title:NEWS_AND_EVENTS_MANAGEMENT, body:NEWS_AND_EVENTS_HELP}}/>
                    </h5>
                </div>

                <NewsAndEventsListGrid
                    posts={newsAndEventsList}
                    loading={newsAndEventsData.isLoading}
                    onSearchTrigger={onSearchTrigger}
                />
            </div>
        </>
    )
}
export default withSidebar(NewsAndEvents)
