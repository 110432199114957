import type React from 'react';
import './MothersDayLogoAnimation.css';
import { getMothersDayEffects } from '../celebrationsConfigs';

const MothersDayLogoAnimation: React.FC<{ style?: React.CSSProperties }> = ({
  style,
}) => {
  const effects = getMothersDayEffects();

  if (effects?.showSpecialLogo) {
    return (
      <div className='animation-container-mothers-day-logo'>
        <img
          src={effects?.specialLogoUrl || ''}
          alt='Mothers Day'
          style={style}
        />
      </div>
    );
  }
  return null;
};

export default MothersDayLogoAnimation;
