import { AxiosResponse } from 'axios'
import { getErrorMessage } from './utils'
import { HttpMethods, AlertVariant, FESTIVAL_TEMPLATES, FESTIVAL_THEME_PAGE_CONFIG } from '../constants/constants'
import { Payload, SetLogo, BrandingResponseData, FestivalThemeFormData, BrandingData } from '../constants/staticTypes'
import { setLogoApi, updateFestivalThemeApi } from '../constants/apiEndPoints'
import { UseMutationResult } from 'react-query'
import { cloneDeep } from 'lodash'
import {
	getChristmasEffects,
	getStPatricksDayEffects,
	getValentinesDayEffects,
	getEasterDayEffects,
	getCincoDeMayoDayEffects,
	getMothersDayEffects,
} from '../components/celebrations/celebrationsConfigs'

export type ThemeTemplate = {
	name: string
	key: string
}

export const initialFestivalThemeFormData: FestivalThemeFormData = {
	enableCelebrationEffect: false,
	activeCelebrationTemplate: '',
	showCelebrationTemplateOn: [],
}

// set brand logo
export const addBrandLogo = (
	logoMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	param: SetLogo,
	onSuccess: (data: BrandingResponseData) => void,
	onError: (res: string, variant: string) => void
) => {
	logoMutation.mutate(
		{
			url: setLogoApi,
			method: HttpMethods.POST,
			data: param,
		},
		{
			onSuccess: (res) => onSuccess(res.data.data),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

export const themeTemplates: ThemeTemplate[] = [
	{ name: 'Valentines day', key: FESTIVAL_TEMPLATES.VALENTINES_DAY },
	{ name: 'St. Patrick’s day', key: FESTIVAL_TEMPLATES.ST_PATRICKS_DAY },
	{ name: 'Easter', key: FESTIVAL_TEMPLATES.EASTER },
	{ name: 'Cinco de Mayo', key: FESTIVAL_TEMPLATES.CINCO_DEMAYO },
	{ name: 'Mother’s day', key: FESTIVAL_TEMPLATES.MOTHERS_DAY },
	{ name: 'Father’s day', key: FESTIVAL_TEMPLATES.FATHERS_DAY },
	{ name: 'Thanksgiving', key: FESTIVAL_TEMPLATES.THANKS_GIVING },
	{ name: 'Christmas & New year', key: FESTIVAL_TEMPLATES.CHRISTMAS_NEWYEAR },
]

export const enableThemeBranding = (brandingInfo: BrandingData, theme: string) => {
	if (!brandingInfo.celebrationEffects) {
		return brandingInfo
	}

	const brandInfo = cloneDeep(brandingInfo) as BrandingData
	brandInfo.enableCelebrationEffect = true
	brandInfo.showCelebrationTemplateOn = [
		FESTIVAL_THEME_PAGE_CONFIG.WELCOME_PAGE,
		FESTIVAL_THEME_PAGE_CONFIG.ALL_OTHER_PAGES,
	]
	brandInfo.activeCelebrationTemplate = theme

  // TODO: when adding new celebration templates, make sure to add it here
	let brandingInfoCelebrationEffects: { [key: string]: any } = {}
	switch (theme) {
		case FESTIVAL_TEMPLATES.CHRISTMAS_NEWYEAR:
			let christmasEffect = getChristmasEffects()
			if (christmasEffect) {
				christmasEffect.playChime = true
				christmasEffect.showEffects = true
				brandingInfoCelebrationEffects[FESTIVAL_TEMPLATES.CHRISTMAS_NEWYEAR] = christmasEffect
			}
			break
		case FESTIVAL_TEMPLATES.VALENTINES_DAY:
			let valentinesDayEffect = getValentinesDayEffects()
			if (valentinesDayEffect) {
				valentinesDayEffect.playChime = true
				valentinesDayEffect.showEffects = true
				valentinesDayEffect.showCardEffects = true
				valentinesDayEffect.showCupid = true
				valentinesDayEffect.showSpecialLogo = true
				brandingInfoCelebrationEffects[FESTIVAL_TEMPLATES.VALENTINES_DAY] = valentinesDayEffect
			}
			break
		case FESTIVAL_TEMPLATES.ST_PATRICKS_DAY:
			let stPatricksDayEffect = getStPatricksDayEffects()
			if (stPatricksDayEffect) {
				stPatricksDayEffect.playChime = true
				stPatricksDayEffect.showAnimatedCharacter = true
				stPatricksDayEffect.showCardEffects = true
				stPatricksDayEffect.showSpecialLogo = true
				stPatricksDayEffect.showEffects = true
				brandingInfoCelebrationEffects[FESTIVAL_TEMPLATES.ST_PATRICKS_DAY] = stPatricksDayEffect
			}
			break
		case FESTIVAL_TEMPLATES.EASTER:
			let easterEffects = getEasterDayEffects()
			if (easterEffects) {
				easterEffects.playChime = true
				easterEffects.showAnimatedCharacter = true
				easterEffects.showCardEffects = true
				easterEffects.showSpecialLogo = true
				easterEffects.showEffects = true
				brandingInfoCelebrationEffects[FESTIVAL_TEMPLATES.EASTER] = easterEffects
			}
			break
		case FESTIVAL_TEMPLATES.CINCO_DEMAYO:
			let cincoDeMayoDayEffects = getCincoDeMayoDayEffects()
			if (cincoDeMayoDayEffects) {
				cincoDeMayoDayEffects.playChime = true
				cincoDeMayoDayEffects.showAnimatedCharacter = true
				cincoDeMayoDayEffects.showCardEffects = true
				cincoDeMayoDayEffects.showSpecialLogo = true
				cincoDeMayoDayEffects.showEffects = true
				brandingInfoCelebrationEffects[FESTIVAL_TEMPLATES.CINCO_DEMAYO] = cincoDeMayoDayEffects
			}
			break
		case FESTIVAL_TEMPLATES.MOTHERS_DAY:
			let mothersDayEffect = getMothersDayEffects()
			if (mothersDayEffect) {
				mothersDayEffect.playChime = true
        mothersDayEffect.showAnimatedCharacter = true
				mothersDayEffect.showEffects = true
				mothersDayEffect.showCardEffects = true
				mothersDayEffect.showSpecialLogo = true
				brandingInfoCelebrationEffects[FESTIVAL_TEMPLATES.MOTHERS_DAY] = mothersDayEffect
			}
			break
	}

	brandInfo.celebrationEffects = { ...brandingInfoCelebrationEffects }
	return brandInfo
}

export const updateFestivalTheme = (
	festivalMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	param: FestivalThemeFormData,
	onSuccess: (data: BrandingResponseData) => void,
	onError: (res: string, variant: string) => void
) => {
	festivalMutation.mutate(
		{
			url: updateFestivalThemeApi,
			method: HttpMethods.PUT,
			data: param,
		},
		{
			onSuccess: (res) => onSuccess(res.data.data),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}
