import { Offcanvas } from "react-bootstrap"
import { handleTakeSwapShiftRequest, SwapRequestList, swapRequestTypes, TakeSwapRequestForm } from "../../helpers/swapScheduleHelper"
import { AlertVariant, toastMessageInitialData } from "../../constants/constants"
import { JTranslation } from "../../helpers/jTranslate"
import ToastAlert from "../alert/ToastAlert"
import { useQueryClient, UseQueryResult } from "react-query"
import { useContext, useEffect, useState } from "react"
import { ToastMessageProps } from "../../constants/staticTypes"
import { CommonCtx } from "../../context/CommonCtxProvider"
import useMutationHook from "../../hooks/useMutationHook"
import { PublishedSchedule, ScheduleData } from "../../helpers/workTimeScheduleHelper"
import { useFormik } from "formik"
import { AxiosResponse } from "axios"
import { getStaffId, imageOnErrorHandler } from "../../helpers/utils"
import dayjs from "dayjs"
import { Dropdown } from "primereact/dropdown"
import { CANCEL } from "../../constants/strings"

type Props = {
    takenSwapShiftRequest: SwapRequestList | null;
    staffsWithSchedule: ScheduleData | null;
    swapListQuery: UseQueryResult<AxiosResponse<any, any>, unknown>;
    handleClose: () => void;
}

type DropDown = { value: string, name: string }
type ShiftDropDown = { value: string, name: string, toStartTime: string, toEndTime: string, toRole: string }

const TakeSwiftRequestSideBar = ({
    takenSwapShiftRequest,
    staffsWithSchedule,
    swapListQuery,
    handleClose,
}: Readonly<Props>) => {
    const staffId = getStaffId()
    const queryClient = useQueryClient()
    const takeSwapPoolRequestMutation = useMutationHook(queryClient, true)
    const { showSideBar, setShowSideBar } = useContext(CommonCtx)

    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
    const [shiftList, setShiftList] = useState<ShiftDropDown[]>([])
    const [dateList, setDateList] = useState<DropDown[]>([])
    const [selectedDate, setSelectedDate] = useState<string | null>(null)

    const cleanUpForm = () => {
        setShiftList([])
        setDateList([])
        setSelectedDate('')
    }

    const onSuccess = () => {
        setToastMessage({ message: 'Shift request created successfully', show: true, variant: AlertVariant.SUCCESS })
        swapListQuery.refetch()
        cleanUpForm()
        setShowSideBar(false)
    }

    const onError = (error: string, variant: string) => {
        setToastMessage({ message: error, show: true, variant: variant })
    }

    const takeSwapShiftRequestFormikForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            shiftId: '',
            shiftSwapRequestId: takenSwapShiftRequest?.id,
            tenantStaffId: staffId,
        } as TakeSwapRequestForm,
        validate: (data) => {
            let errors: any = {}
            if (!data.shiftId) errors.shiftId = 'required'
            if (!data.shiftSwapRequestId) errors.shiftSwapRequestId = 'required'
            if (!data.tenantStaffId) errors.tenantStaffId = 'required'

            return errors
        },
        onSubmit: (data) => {
            handleTakeSwapShiftRequest(takeSwapPoolRequestMutation, data, onSuccess, onError)
        },
    })

    useEffect(() => {
        if (takenSwapShiftRequest && staffsWithSchedule) {
            const filterDateList: DropDown[] = []
            staffsWithSchedule.staffs.forEach((staff) => {
                if (staffId === staff.id) {
                    const shiftDates = Object.keys(staff.shifts)
                    shiftDates.forEach((date) => {
                        const parsedDate = dayjs(date).format('MMM D, YYYY')
                        const today = dayjs()
                        // take dates that are not in the past and current
                        if (dayjs(date).isAfter(today)) {
                            filterDateList.push({ value: date, name: parsedDate })
                        }
                    })
                }
            })
            setDateList(filterDateList as DropDown[])
        }
    }, [takenSwapShiftRequest])

    // filter shifts based on employee and date selection
    useEffect(() => {
        if (selectedDate && staffsWithSchedule) {
            const filterShiftList: ShiftDropDown[] = []
            staffsWithSchedule.staffs.forEach((staff) => {
                if (staffId === staff.id) {
                    const shiftDates = Object.keys(staff.shifts)
                    shiftDates.forEach((date) => {
                        if (date === selectedDate) {
                            staff.shifts[date].forEach((shift) => {
                                const updatedShift = (shift as any) as PublishedSchedule
                                filterShiftList.push({
                                    value: updatedShift.id,
                                    // name: `${updatedShift.assignedShifts.shiftName} (${updatedShift.assignedShifts.startTime} - ${updatedShift.assignedShifts.endTime})`,
                                    name: `${updatedShift.assignedShifts.startTime} - ${updatedShift.assignedShifts.endTime}`,
                                    toStartTime: updatedShift.assignedShifts.startTime,
                                    toEndTime: updatedShift.assignedShifts.endTime,
                                    toRole: updatedShift.assignedShifts.role,
                                })
                            })
                        }
                    })
                }
            })
            setShiftList(filterShiftList as ShiftDropDown[])
        }
    }, [selectedDate])

    return (
        <Offcanvas
            show={showSideBar}
            className='custom-offcanvas'
            onHide={() => {
                // cleanUpForm()
                handleClose()
            }}
            backdrop="static"
            // responsive="xl"
            placement="end"
        >
            {/* TOAST MESSAGE COMPONENT */}
            <ToastAlert
                show={toastMessage.show}
                onClose={() => setToastMessage(toastMessageInitialData)}
                message={toastMessage.message}
                variant={toastMessage.variant}
            />

            <Offcanvas.Header closeButton>
                <Offcanvas.Title><JTranslation typeCase="pascal" text={'Take Shift'} /></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <form className="swap-request-form" onSubmit={takeSwapShiftRequestFormikForm.handleSubmit}>
                    <div className="d-flex flex-row">
                        <img
                            className="rounded-circle"
                            src={takenSwapShiftRequest?.fromTenantStaff?.user?.photoKey}
                            alt={takenSwapShiftRequest?.fromTenantStaff?.user?.firstName}
                            style={{ width: '60px', height: '60px' }}
                            onError={imageOnErrorHandler}
                        />
                        <div className="d-flex flex-column ms-3">
                            <p className="m-0"><b>{takenSwapShiftRequest?.fromTenantStaff?.user?.preferredName ?? (takenSwapShiftRequest?.fromTenantStaff?.user?.firstName + ' ' + takenSwapShiftRequest?.fromTenantStaff?.user?.lastName)}</b></p>
                            <small className="m-0">{takenSwapShiftRequest?.fromShift?.assignedShifts ? takenSwapShiftRequest?.fromShift?.assignedShifts?.role : ''}</small>
                            <small className="m-0">
                            {takenSwapShiftRequest?.fromShift?.scheduleDate ? dayjs(takenSwapShiftRequest?.fromShift?.scheduleDate).format('MMM D, YYYY') : ''} ({takenSwapShiftRequest?.fromShift?.assignedShifts ? takenSwapShiftRequest?.fromShift?.assignedShifts?.shiftName : ''} : {takenSwapShiftRequest?.fromShift?.assignedShifts ? takenSwapShiftRequest?.fromShift?.assignedShifts?.startTime : ''} - {takenSwapShiftRequest?.fromShift?.assignedShifts ? takenSwapShiftRequest?.fromShift?.assignedShifts?.endTime : ''})
                            </small>
                        </div>
                    </div>

                    <div className="pt-4 row">
                        <div className={`col-md-12 col-lg-12 mb-3`}>
                            <label className="form-label">
                                <JTranslation typeCase="pascal" text={'Swap To'} />
                            </label>
                            <div className="p-inputgroup flex-1">
                                <Dropdown
                                    className={`flex-grow-1 festival-dropdown`}
                                    data-testid="request-type-dropdown"
                                    value={'pool'}
                                    options={swapRequestTypes}
                                    optionLabel="name"
                                    optionValue="value"
                                    dataKey="request-type-dropdown"
                                    disabled={true}
                                />
                            </div>
                        </div>

                        <div className={`col-md-12 col-lg-12 mb-3`}>
                            <label className="form-label">
                                <JTranslation typeCase="pascal" text={'Offered Date'} />
                            </label>
                            <div className="p-inputgroup flex-1">
                                <Dropdown
                                    className={`flex-grow-1 festival-dropdown`}
                                    data-testid="to-date-dropdown"
                                    value={selectedDate}
                                    onChange={(event) => {
                                        setSelectedDate(event.target.value)
                                    }}
                                    options={dateList}
                                    optionLabel="name"
                                    optionValue="value"
                                    dataKey="to-date-dropdown"
                                />
                            </div>
                        </div>

                        <div className={`col-md-12 col-lg-12 mb-3`}>
                            <label className="form-label">
                                <JTranslation typeCase="pascal" text={'Offered Shift'} />
                            </label>
                            <div className="p-inputgroup flex-1">
                                <Dropdown
                                    className={`flex-grow-1 festival-dropdown ${takeSwapShiftRequestFormikForm.errors.shiftId ? 'border-danger' : ''}`}
                                    data-testid="to-shift-dropdown"
                                    value={takeSwapShiftRequestFormikForm.values.shiftId}
                                    onChange={(event) => {
                                        const { value: shiftId } = event.target
                                        const shift = shiftList.find((shift) => shift.value === shiftId)
                                        takeSwapShiftRequestFormikForm.setFieldValue('shiftId', shiftId, true)
                                    }}
                                    options={shiftList}
                                    optionLabel="name"
                                    optionValue="value"
                                    dataKey="to-shift-dropdown"
                                />
                            </div>
                        </div>

                        {/* Footer */}
                        <div className="save-btn-section shadow save-btn-absolute">
                            <button
                                className="btn btn-custom-primary-outline ms-2"
                                type="button"
                                data-testid="clear-button"
                                onClick={() => {
                                    cleanUpForm()
                                    setShowSideBar(false)
                                }}
                            >
                                <JTranslation typeCase="pascal" text={CANCEL} />
                            </button>

                            <button
                                className="btn btn-custom-primary ms-2"
                                type="submit"
                                data-testid="save-button"
                            >
                                <JTranslation typeCase="pascal" text={'Take Shift'} />
                            </button>
                        </div>
                    </div>
                </form>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default TakeSwiftRequestSideBar