import { useState } from "react";
import '../../assets/styles/scss/main.scss';
import './styles/reset-password.scss';
import { BACK_TO_LOGIN, CONFIRM_PASSWORD_TEXT, FORGOT_PASSWORD_ERROR, PASSWORD_MISMATCH_ERROR, PASSWORD_TEXT, PASSWORD_WEAK_ERROR, SUBMIT } from "../../constants/strings";
import { isStrongPassword } from "../../helpers/utils";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { resetPassword } from "../../helpers/authHelper";
import { routes } from "../../constants/routes";
import { useQueryClient } from "react-query";
import PasswordChecklist from "react-password-checklist";
import useMutationHook from "../../hooks/useMutationHook";
import LogoComponent from "../../components/header/LogoComponent";

function ResetPassword() {
    const queryClient = useQueryClient();
    const [password, setPassword] = useState("");
    const [cnfPassword, setConfirmPassword] = useState("");
    const [isError, setError] = useState(false);
    const [isPasswordError, setPasswordError] = useState(false);
    const [isPasswordWeak, setPasswordWeakError] = useState(false);
    const navigate = useNavigate();

    // reset password mutation
    const resetPasswordMutation = useMutationHook(queryClient, true);

    // get url params
    const [searchParams] = useSearchParams();
    const userName = searchParams.get("user");
    const code = searchParams.get("code");

    // send password reset email success
    const onResetPasswordSuccess = () => {
        navigate(routes.login);
    }

    // error sending password reset email
    const onResetPasswordError = () => {
        setError(true);
    }

    // on change error check
    const onPasswordsChange = (currentInput: string, otherPassword: string) => {
        if (isPasswordError) {
            setPasswordError(currentInput !== otherPassword);
        }
        if (isPasswordWeak) {
            setPasswordWeakError(isStrongPassword(currentInput));
            setPasswordError(currentInput !== otherPassword);
        }
    }

    return (
        <div className='p-0 user-style'>
            <div data-testid="reset-password" className="login d-flex justify-content-center align-items-center">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-9 col-lg-5 col-xl-5 ">
                            <div className="login-box">
                                <div className="login-header text-center d-flex align-items-center justify-content-center">
                                    <LogoComponent />
                                </div>
                                <div className="login-body">
                                    <div className="form-section">

                                        <div className="mb-3 login-title text-center">
                                            <h5 className="mb-0">Reset Password</h5>

                                        </div>

                                        <input data-testid="password"
                                            autoFocus autoComplete="new-password"
                                            className="form-control form-control-lg mb-3" type="password" placeholder={PASSWORD_TEXT}
                                            aria-label=".form-control-lg example"
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                                onPasswordsChange(e.target.value, cnfPassword);
                                            }} />
                                        <input data-testid="cnf-password"
                                            autoComplete="off"
                                            className="form-control form-control-lg mb-3" type="password" placeholder={CONFIRM_PASSWORD_TEXT}
                                            aria-label=".form-control-lg example"
                                            onChange={(e) => {
                                                setConfirmPassword(e.target.value);
                                                onPasswordsChange(e.target.value, password);
                                            }} />

                                        {isError && <div data-testid="error" className='error-msg mb-3'>{FORGOT_PASSWORD_ERROR}</div>}
                                        {isPasswordError && <div data-testid="error-password" className='error-msg mb-3'>{PASSWORD_MISMATCH_ERROR}</div>}
                                        {isPasswordWeak && <div data-testid="error-password-weak" className='error-msg mb-3'>{PASSWORD_WEAK_ERROR}</div>}

                                        <div className="p-2">
                                            <PasswordChecklist className='password-checklist'
                                                rules={["minLength", "specialChar", "number", "capital", "match"]}
                                                minLength={6}
                                                value={password}
                                                valueAgain={cnfPassword}
                                                iconSize={14}
                                            />
                                        </div>
                                        {/*
                                        <div className="password-criteria">
                                            <ul>
                                                <li><i className="ri-arrow-right-s-line"></i> Password must be minimum 6 characters long</li>
                                                <li><i className="ri-arrow-right-s-line"></i> Contains at least one number</li>
                                                <li><i className="ri-arrow-right-s-line"></i> One uppercase letter</li>
                                                <li><i className="ri-arrow-right-s-line"></i> One lowercase letter</li>
                                                <li><i className="ri-arrow-right-s-line"></i> One symbol</li>


                                            </ul>
                                        </div> */}

                                        <div className="d-grid gap-2 mb-3">
                                            <button type="button" className="btn btn-primary btn-lg btn-block btn-custom-primary"
                                                data-testid="submit-button"
                                                disabled={password?.trim().length === 0 || cnfPassword?.trim().length === 0}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (password === cnfPassword) {
                                                        if (isStrongPassword(password)) {
                                                            setError(false);
                                                            setPasswordError(false);
                                                            setPasswordWeakError(false);
                                                            const data = {
                                                                userName: userName,
                                                                code: code,
                                                                password: password
                                                            }
                                                            resetPassword(resetPasswordMutation, data, onResetPasswordSuccess, onResetPasswordError);
                                                        } else {
                                                            setPasswordWeakError(true);
                                                            setPasswordError(false);
                                                        }
                                                    } else {
                                                        setPasswordWeakError(false);
                                                        setPasswordError(true);
                                                    }
                                                }}>{SUBMIT.toUpperCase()}</button>
                                        </div>
                                        <div className="row d-flex justify-content-end forget-pass-sec">
                                            <div className="col-auto d-flex align-items-center fw-semibold"><i className="ri-arrow-left-line"></i><Link data-testid="back" to={routes.login}>{BACK_TO_LOGIN}</Link></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;