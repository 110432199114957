import { Auth } from 'aws-amplify'
import { AxiosResponse } from 'axios'
import {
	HttpMethods,
	AlertVariant,
	SELECTED_MENU,
	SPECIAL_MENU_CODE,
	STAFF_ID,
	USER_NAME,
} from '../constants/constants'
import { LOGIN_STATUS, PERMISSIONS } from '../constants/queryKeys'
import { Payload, UserLogin, Users, PosLoginResponse } from '../constants/staticTypes'
import { QueryClient, UseMutationResult } from 'react-query'
import { routes } from '../constants/routes'
import { showDashboard, getErrorMessage } from './utils'
import HttpServiceHelper from './httpServiceHelper'
import {
	forgotPasswordApi,
	publicMagicLink,
	resendPasswordEmailApi,
	resendPasswordSmsApi,
	resetPasswordApi,
	setPasswordApi,
	tenantInfoApi,
	userInfoApi,
	usernameOnlyLogin,
} from '../constants/apiEndPoints'

// get server POS number
export const getServerPosFromQrCode = (QrCode: string) => {
	const params = new URLSearchParams(QrCode.slice(QrCode.indexOf('?') + 1))
	const POS = params.get('server')
	return POS as string
}

// user authentication
export const userLogin = (userDetails: UserLogin) => Auth.signIn(userDetails.username?.trim(), userDetails.password)

// user session
export const userSession = () => Auth.currentAuthenticatedUser({ bypassCache: true })

// user logout
export const userLogout = () => Auth.signOut()

export const sessionExpire = () =>
	Auth.currentSession()
		.then((session) => {
			// Tokens are valid, you can perform actions here if needed
		})
		.catch((error) => {
			// Tokens are not valid, handle the error here
			localStorage.removeItem(USER_NAME)
			localStorage.removeItem(STAFF_ID)
			localStorage.removeItem('activeMenu')
			localStorage.removeItem(SELECTED_MENU)
			localStorage.removeItem(SPECIAL_MENU_CODE)
			localStorage.removeItem('permissions')
			userLogout()
		})

// forgot password mail
export const sendForgotPasswordEmail = (
	forgotPasswordMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	email: string,
	onSuccess: () => void,
	onError: () => void
) => {
	forgotPasswordMutation.mutate(
		{
			url: forgotPasswordApi,
			method: HttpMethods.POST,
			noAuth: true,
			data: {
				email: email,
			},
		},
		{
			onSuccess,
			onError,
		}
	)
}

// validate SMS code
export const validateSMSCode = (
	forgotPasswordMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	email: string,
	onSuccess: (res: PosLoginResponse) => void,
	onError: (res: string, variant: string) => void
) => {
	forgotPasswordMutation.mutate(
		{
			url: forgotPasswordApi,
			method: HttpMethods.POST,
			noAuth: true,
			data: {
				email: email,
			},
		},
		{
			onSuccess: (res) => onSuccess(res.data.data),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

// login using POS number with out password
export const loginWithUsernameOnly = (
	usernameLoginMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	userName: string,
	onSuccess: (res: PosLoginResponse) => void,
	onError: (res: string, variant: string) => void
) => {
	usernameLoginMutation.mutate(
		{
			url: usernameOnlyLogin,
			method: HttpMethods.POST,
			noAuth: true,
			data: {
				userName: userName,
			},
		},
		{
			onSuccess: (res) => onSuccess(res.data.data),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

// login using link
export const loginWithLink = (
	usernameLoginMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	userName: string,
	onSuccess: (message: string) => void,
	onError: (res: string, variant: string) => void
) => {
	usernameLoginMutation.mutate(
		{
			url: publicMagicLink,
			method: HttpMethods.POST,
			noAuth: true,
			data: {
				userName: userName,
			},
		},
		{
			onSuccess: (res) => onSuccess(res.data.message),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

// reset password
export const resetPassword = (
	resetPasswordMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	data: {},
	onSuccess: () => void,
	onError: () => void
) => {
	resetPasswordMutation.mutate(
		{
			url: resetPasswordApi,
			method: HttpMethods.POST,
			noAuth: true,
			data: data,
		},
		{
			onSuccess,
			onError,
		}
	)
}

// get tenant info payload
export const getTenantInfo = () => {
	return HttpServiceHelper({
		url: tenantInfoApi,
		method: HttpMethods.GET,
		noAuth: true,
	})
}

// set login status
export const setLoginStatus = (isLoggedIn = false) => ({ isLoggedIn })

// save login status query data
export const saveLoginStatus = (queryClient: QueryClient, isLoggedIn: boolean) =>
	queryClient.setQueryData(LOGIN_STATUS, setLoginStatus(isLoggedIn))

// get user info and assigned permissions
export const getUserInfo = (queryClient: QueryClient, userName: string) => {
	return HttpServiceHelper({
		url: userInfoApi,
		method: HttpMethods.POST,
		queryClient,
		data: {
			userName,
		},
	})
}

// resend password email
export const resendPasswordEmail = (
	resendPasswordMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	data: {},
	onSuccess: () => void,
	onError: () => void
) => {
	resendPasswordMutation.mutate(
		{
			url: resendPasswordEmailApi,
			method: HttpMethods.POST,
			data: data,
		},
		{
			onSuccess,
			onError,
		}
	)
}
// resend password email
export const resendPasswordSms = (
	resendPasswordMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	data: {},
	onSuccess: () => void,
	onError: () => void
) => {
	resendPasswordMutation.mutate(
		{
			url: resendPasswordSmsApi,
			method: HttpMethods.POST,
			data: data,
		},
		{
			onSuccess,
			onError,
		}
	)
}

// resend password email
export const setUserPasswordApi = (
	resetPasswordMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	data: {},
	onSuccess: () => void,
	onError: () => void
) => {
	resetPasswordMutation.mutate(
		{
			url: setPasswordApi,
			method: HttpMethods.POST,
			noAuth: true,
			data: data,
		},
		{
			onSuccess,
			onError,
		}
	)
}

// get user permissions
export const getUserPermissions = (userInfo: Users) => {

	type PermissionsObject = {
		[key: string]: any
	}

	let permissions: PermissionsObject = {}
	// userInfo?.jobRoles.forEach((role) => {
	// 	role.rolePermissions.slice().forEach((permissionArray) => {
	// 		if (permissionArray.isEnabled && !(permissionArray.permissionKey in permissions)) {
	// 			permissions = {
	// 				...permissions,
	// 				[permissionArray.permissionKey]: [],
	// 			}
	// 		}
	// 		if (permissionArray.permissions.length > 0) {
	// 			permissionArray.permissions.slice().forEach((subPermission) => {
	// 				if (subPermission.value) {
	// 					let existingItems = permissions[permissionArray.permissionKey]
	// 					if (!existingItems) {
	// 						existingItems = []
	// 					}
	// 					permissions = {
	// 						...permissions,
	// 						[permissionArray.permissionKey]: [...existingItems, subPermission.key],
	// 					}
	// 				}
	// 			})
	// 		}
	// 	})
	// })

	// modified permission structure based on updated user permissions
	userInfo?.staffPermissionRoles[0]?.permissions?.forEach((permission) => {
		if (permission.permissionKey) {
			let key = permission.permissionKey
			let isActive = permission.isActive
			let values = permission.permissions.map((subPermission) => {
				if (subPermission.isActive) {
					return subPermission.permissionKey
				}
				return null
			}).filter((key) => key !== null)

			// if parent permission is active or at-least one sub-permission is active, set permission as active
			if(isActive || values.length > 0) {				
				permissions[key] = values
			}
		}
	})
	return permissions
}

// set permissions
export const setPermissions = (permissions: { [key: string]: any }) => ({ permissions })

// save permissions
export const savePermissions = (queryClient: QueryClient, permissions: { [key: string]: any }) => {
	queryClient.setQueryData(PERMISSIONS, setPermissions(permissions))
	const permissionString = JSON.stringify(permissions)
	localStorage.setItem(PERMISSIONS, permissionString)
}

// get root route for logged in user
export const getRootRoute = (isLoggedIn: boolean, queryClient: QueryClient) => {
	if (isLoggedIn && showDashboard(queryClient)) {
		return routes.dashboard
	} else {
		return routes.menu_view
	}
}

export const getGoogleAnalyticsKey = () => {
	switch (process.env.REACT_APP_ENVIRONMENT) {
		case 'development':
			return 'G-KDX5FZMLFT'
		case 'staging':
			return 'G-3KTZ5S1RJ1'
		case 'production':
			return 'G-98ZD5HPBQQ'
		default:
			return 'G-KDX5FZMLFT'
	}
}

export const getGoogleRecaptchaKey = () => {
	// return process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY ?? ""
	return '6Lcj1xcnAAAAANV10hyZ9XERLQ2S2fubnVVjzI-w'
}

export const samplePermissions = {
	manage_permission_groups: [ 'add', 'delete', 'edit' ],
	my_schedule: [],
	manage_work_time_schedule: [ 'edit', 'add', 'delete' ],
	manage_availability: [ 'manage_requests', 'view_availability', 'add', 'edit', 'delete' ],
	manage_shift: [ 'add', 'edit', 'delete' ],
	time_off_module: [ 'my_time_off' ],
	dashboard: [
	  'holiday_list',
	  'frequently_visited',
	  'survey_feedback_graph',
	  'time_off_list',
	  'survey_completion_graph'
	],
	holiday: [ 'edit', 'delete', 'add' ],
	manage_branding_about_us: [],
	manage_general_settings: [],
	branding: [ 'manage_logos' ],
	addon_groups: [ 'delete', 'add', 'edit' ],
	manage_time_off: [ 'add', 'edit', 'delete', 'manage_requests' ],
	manage_posts: [ 'add', 'delete', 'edit', 'change_status' ],
	manage_custom_f_b_menu: [ 'change_status', 'edit', 'manage_schedule', 'delete', 'add' ],
	manage_key_ingredients: [ 'add', 'delete', 'edit' ],
	manage_addons: [ 'delete', 'add', 'edit' ],
	manage_branding_welcome_screen: [],
	manage_documents: [
	  'document_download',
	  'document_delete',
	  'document_rename',
	  'folder_creation',
	  'document_upload'
	],
	manage_coupon: [ 'redeem', 'validity_check' ],
	manage_reports: [],
	manage_survey: [ 'edit', 'delete', 'change_status', 'add' ],
	manage_guest_info: [],
	manage_game: [ 'add', 'delete', 'edit' ],
	manage_f_b_menu: [ 'edit', 'change_status', 'delete', 'add', 'manage_schedule' ],
	manage_users: [ 'edit', 'add', 'change_status', 'delete' ],
	manage_roles: [ 'edit', 'add', 'delete' ]
  }
  