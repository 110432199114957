import {
	AvailabilityData,
	AvailabilityFormData,
	AvailabilityWeekFormData,
	ToastMessageProps,
} from '../../constants/staticTypes'
import { MOBILE_VIEW_BREAKPOINT, PageMode, PermissionKeys } from '../../constants/constants'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { checkPermission, convertUTCtoLocalByOffset, getAllWeekDays, getStaffId } from '../../helpers/utils'
import dayjs from 'dayjs'
import { Badge } from 'primereact/badge'
import { FaEdit } from 'react-icons/fa'
import { useContext, useEffect, useState } from 'react'
import { ScheduleManagementCtx } from '../../context/ScheduleManagementCtxProvider'
import { QueryClient } from 'react-query'
import { EDIT, DELETE } from '../../constants/strings'
import { JTranslation, TranslationContext, jTranslationText } from '../../helpers/jTranslate'
import { DataView } from 'primereact/dataview'
import { useWindowSize } from 'react-use'
import { Card } from 'primereact/card'
import MlxTooltip from '../common/MlxTooltip'

type Props = {
	tableData: AvailabilityData[]
	setToastMessage: React.Dispatch<React.SetStateAction<ToastMessageProps>>
	setMode: React.Dispatch<React.SetStateAction<PageMode>>
	showSideBar: boolean
	setShowSideBar: React.Dispatch<React.SetStateAction<boolean>>
	onAvailabilityDeleteClick: (data: AvailabilityData) => void
	queryClient: QueryClient
	setAvailabilityType: React.Dispatch<React.SetStateAction<'REGULAR' | 'TEMPORARY'>>
}

function AvailabilityDataGrid({
	tableData,
	setToastMessage,
	setMode,
	setShowSideBar,
	showSideBar,
	onAvailabilityDeleteClick,
	queryClient,
	setAvailabilityType,
}: Readonly<Props>) {
	// CONTEXT VARIABLES
	const { setAvailabilityData } = useContext(ScheduleManagementCtx)
	const translationContext = useContext(TranslationContext)
	const { targetLanguage } = translationContext

	const [translationText, setTranslatedText] = useState<{
		edit: string,
		delete: string,
		search: string,
		paginationTemplate: string,
		emptyMessage: string,
	}>({ 
		edit: EDIT,
		delete: DELETE,
		search: 'Search',
		paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
		emptyMessage: 'No Availability found',
	});

	const { width, height } = useWindowSize()
	const breakPoint = MOBILE_VIEW_BREAKPOINT

	// Translate on load and language switch
	useEffect(() => {
		const fetchTranslation = async () => {
			try {
				const translations = await Promise.all([
					jTranslationText({ text: EDIT, typeCase: 'pascal', translationContext }),
					jTranslationText({ text: DELETE, typeCase: 'pascal', translationContext }),
					jTranslationText({ text: 'Search', typeCase: 'pascal', translationContext }),
					jTranslationText({ text: 'Showing 1 to 2 of 3 items', typeCase: 'pascal', translationContext }),
					jTranslationText({ text: 'No Availability found', typeCase: 'pascal', translationContext }),					
				])
				
				setTranslatedText({
					edit: translations[0] ?? EDIT,
					delete: translations[1] ?? DELETE,
					search: translations[2] ?? 'Search',
					paginationTemplate:
						translations[3]
							?.replace('1', '{first}')
							?.replace('2', '{last}')
							?.replace('3', '{totalRecords}') ?? 'Showing {first} to {last} of {totalRecords} items',
					emptyMessage: translations[4] ?? 'No Availability found',
				})
			} catch {
				setTranslatedText({
					edit: EDIT,
					delete: DELETE,
					search: 'Search',
					paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
					emptyMessage: 'No Availability found',
				})
			}
		}
		fetchTranslation()
	}, [targetLanguage])

	const availabilityDateTemplate = (data: AvailabilityData) => {
		const startDate = convertUTCtoLocalByOffset(dayjs(data.startDate))
		const weekDays = getAllWeekDays(dayjs(data.startDate), 'MM/DD/YYYY')

		let endDate = ''
		if (!data.isRecurring) {
			endDate = weekDays.length > 0 ? convertUTCtoLocalByOffset(weekDays[weekDays.length - 1]) : ''
		}
		return <span className=" ">{`${startDate} - ${endDate}`}</span>
	}

	const availabilityStatusTemplate = (data: AvailabilityData) => {
		const { actionStatus } = data
		switch (actionStatus) {
			case 'APPROVED':
				return <Badge className="me-2 custom-badge" value={<JTranslation typeCase="pascal" text={"Approved"} />} severity="success"></Badge>
			case 'REJECTED':
				return <Badge className="me-2 custom-badge" value={<JTranslation typeCase="pascal" text={"Rejected"} />} severity="danger"></Badge>
			default:
				return <Badge className="me-2 custom-badge" value={<JTranslation typeCase="pascal" text={"Pending"} />} severity="warning"></Badge>
		}
	}

	const availabilityEditTemplate = (data: AvailabilityData) => {
		if (['REJECTED', 'APPROVED'].includes(data.actionStatus)) {
			return <></>
		}
		return (
			<span className={`${width <= breakPoint ? 'rounded-background shadow-sm ms-2' : ''}`}>
				<MlxTooltip target='.edit'>
					<FaEdit
						data-test-id={`availability-edit-${data.id}-btn`}
						className={`edit ${!checkPermission(queryClient, PermissionKeys.MANAGE_AVAILABILITY) && 'disabled'}`}
						data-pr-tooltip={translationText.edit}
						data-pr-position="bottom"
						onClick={(e) => {
							e.preventDefault()
							if (checkPermission(queryClient, PermissionKeys.MANAGE_AVAILABILITY)) {
								const weekDays = getAllWeekDays(dayjs(data.startDate))
								const editableData: AvailabilityFormData = {
									id: data.id,
									isRecurring: data.isRecurring ? 'recurring' : 'temporary',
									startDate: dayjs(data.startDate),
									endDate: data.endDate ? dayjs(data.endDate) : null,
									staffId: getStaffId(),
									weekdays: data.weekdays
										? data.weekdays.map((day, index) => {
												const weekDay: AvailabilityWeekFormData = {
													isAllDay: day.isAllDay,
													isAvailable: day.isAvailable,
													weekday: weekDays[index],
													notes: day.notes ?? '',
													availableTimes: day.availableTimes
														? day.availableTimes.map((time) => ({
																from: dayjs(
																	`${weekDays[index].format('MM/DD/YYYY')} ${time.from}`
																),
																to: dayjs(`${weekDays[index].format('MM/DD/YYYY')} ${time.to}`),
														}))
														: [],
												}
												return weekDay
										})
										: [],
								}
								setAvailabilityType(data.isRecurring ? 'REGULAR' : 'TEMPORARY')
								setAvailabilityData(editableData)
								setMode(PageMode.EDIT)
								setShowSideBar(true)
							}
						}}
					/>
				</MlxTooltip>
			</span>
		)
	}

	const availabilityDeleteTemplate = (data: AvailabilityData) => {
		if (['REJECTED', 'APPROVED'].includes(data.actionStatus)) {
			return <></>
		}
		return (
			<i
				title={translationText.delete}
				data-test-id={`availability-delete-${data.id}-btn`}
				className={`ri-delete-bin-line delete ${
					!checkPermission(queryClient, PermissionKeys.MANAGE_AVAILABILITY) && 'disabled'
				} ${width <= breakPoint ? ' rounded-background shadow-sm ms-2' : ''}`}
				onClick={(e) => {
					e.stopPropagation()
					if (checkPermission(queryClient, PermissionKeys.MANAGE_AVAILABILITY)) {
						onAvailabilityDeleteClick(data)
					}
				}}
			></i>
		)
	}

	const itemTemplate = (availabilityData: AvailabilityData) => {
        return (
			<Card className="data-view-card mt-2 d-flex flex-column justify-content-even">
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <p className="m-0">{availabilityDateTemplate(availabilityData)}</p>
                    <Badge severity={availabilityData.isRecurring ? 'success' : 'warning'} value={<>{availabilityData.isRecurring ? 'Regular' : 'Temporary'}</>} className="custom-badge ms-2" />
                </div>
				<div className="view-footer mt-2 pt-3 d-flex flex-row justify-content-between align-items-center">
					<div className='d-flex justify-content-even align-items-center'>
                        {availabilityEditTemplate(availabilityData)}{availabilityDeleteTemplate(availabilityData)}
                    </div>
					<div className='d-flex justify-content-even align-items-center'>{availabilityStatusTemplate(availabilityData)}</div>
				</div>
			</Card>
		)
    }

	return (
		<div className="row mt-2 h-100">
			<div className="col-lg-12" style={{ height: '100%', overflow: 'auto' }}>
				{width <= breakPoint ? (
					<DataView
						value={tableData}
						layout={'list'}
						itemTemplate={itemTemplate}
						paginator
						rows={10}
						paginatorClassName="p-dataview-customers"
						dataKey="id"
						emptyMessage={translationText.emptyMessage}
					/>
					) : (
						<DataTable
							value={tableData}
							paginator
							className="p-datatable-customers"
							rows={10}
							scrollable
							scrollHeight="flex"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
							rowsPerPageOptions={[10, 25, 50]}
							dataKey="id"
							rowHover
							responsiveLayout="scroll"
							emptyMessage={translationText.emptyMessage}
							currentPageReportTemplate={translationText.paginationTemplate}
						>
							<Column
								style={{ minWidth: '8rem', maxWidth: '8rem' }}
								field="date"
								header={<JTranslation typeCase="pascal" text={'Date'} />}
								className="word-break-grid"
								body={availabilityDateTemplate}
							/>
							<Column
								style={{ minWidth: '8rem', maxWidth: '8rem' }}
								field="type"
								header={<JTranslation typeCase="pascal" text={'Type'} />}
								className="word-break-grid"
								body={(data: AvailabilityData) => <>{data.isRecurring ? 'Recurring' : 'Temporary'}</>}
							/>
							<Column
								style={{ minWidth: '8rem', maxWidth: '8rem' }}
								field="status"
								header={<JTranslation typeCase="pascal" text={'Status'} />}
								className="word-break-grid"
								body={availabilityStatusTemplate}
							/>
							<Column
								field="edit"
								style={{ maxWidth: '3rem', width: '3rem' }}
								bodyClassName="bg-sticky-color"
								body={availabilityEditTemplate}
								alignFrozen="right"
								frozen={true}
							/>
							<Column
								field="delete"
								style={{ maxWidth: '3rem', width: '3rem' }}
								bodyClassName="bg-sticky-color"
								body={availabilityDeleteTemplate}
								alignFrozen="right"
								frozen={true}
							/>
						</DataTable>
					)
				}
			</div>
		</div>
	)
}

export default AvailabilityDataGrid
