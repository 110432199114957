import { AxiosResponse } from "axios";
import { ChangeEvent, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { UseMutationResult, useQueryClient } from "react-query";
import { AlertVariant, FileType, IMAGE_TYPE } from "../../../constants/constants";
import { MenuItem, Menu, Payload, SubCategory, UploadedAiImageInfo, CropType } from "../../../constants/staticTypes";
import { ADD_MENU_TITLE, CANCEL, DELETE_WARNING, EDIT_MENU_TITLE, MENU_NAME, SAVE, UPLOAD_PHOTO, USER_UPLOAD_PHOTO_ERROR, AI_IMAGE_GENERATOR, UPLOAD_PHOTO_ERROR, UPLOAD_PHOTO_INFO, UPLOAD_PHOTO_MESSAGE } from "../../../constants/strings";
import { checkIfHeicHeif, uploadFile } from "../../../helpers/fileUploadHelper";
import { upsertCategory } from "../../../helpers/menuManagementHelper";
import useMutationHook from "../../../hooks/useMutationHook";
import Alert from 'react-bootstrap/Alert';
import WarningModal from "../../warning_modal/WarningModal";
import menuImg1 from "../../../assets/images/menu/no_image_upload.png";
import ImageCropModal from "./add_item_forms/ImageCropModal";
import CommonModal from "../../common_modal/CommonModal";
import AiImageGenerator from "../../ai_image_generator/AiImageGenerator";
import { JTranslation } from "../../../helpers/jTranslate";
import { replaceMessageValues } from "../../../helpers/utils";

type Props = {
    show: boolean,
    handleClose: () => void,
    upsertMenu: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onSuccess: (message: string) => void,
    displayToast: (message: string, variant: string) => void,
    editItem: Menu | MenuItem | SubCategory | undefined
}

function AddMenu({ show, handleClose, upsertMenu, onSuccess, displayToast, editItem }: Props) {
    const [name, setMenuName] = useState("");
    const [photoKey, setPhotoKey] = useState("");
    const queryClient = useQueryClient();
    const uploadFileMutation = useMutationHook(queryClient, true); // upload file mutation
    const [image, setImage] = useState("");
    const [showAlert, setShow] = useState(false);
    const [alertVariant, setVariant] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [warning, setWarning] = useState(false);
    const [aiImageModal, setAiImageModal] = useState(false);
    const [imageCropSrc, setImageCrop] = useState<string | ArrayBuffer | null>(null);
    const cropType: CropType = 'MENU';
    const validateImageDimension = { width: IMAGE_TYPE[cropType].width, height: IMAGE_TYPE[cropType].height };

    useEffect(() => {
        let menuEditItem = editItem ? editItem as Menu : undefined;
        setMenuName(menuEditItem && menuEditItem.categoryName ? menuEditItem.categoryName : "");
        setPhotoKey(menuEditItem && menuEditItem.photoKey ? menuEditItem.photoKey : "");
        setImage(menuEditItem && menuEditItem.signedUrl ? menuEditItem.signedUrl : "");

    }, [editItem, show]);

    const saveImages = ({ imageKeys, signedImageUrls }: UploadedAiImageInfo) => {
        setPhotoKey(imageKeys[0]);
        setImage(signedImageUrls[0]);
    }

    // on file upload success
    const onUploadSuccess = (key: string, imageUrl: string) => {
        setPhotoKey(key);
        setImage(imageUrl);
    }

    // on file upload error
    const onUploadError = () => displayToast(USER_UPLOAD_PHOTO_ERROR, AlertVariant.ERROR);

    // on clear items
    const clearData = () => {
        setMenuName("");
        setPhotoKey("");
        setImage("");
    }

    // on api error
    const onError = (message: string, variant: string) => {
        setVariant(variant);
        setErrorMessage(message);
        setShow(true);
    }

    // on delete file
    const onDeleteFile = () => {
        setImage("");
        setPhotoKey("");
        setWarning(false);
    }

    // validate upload image dimensions
	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files && e.target.files[0]
		if (!file) {
			return // Handle no file selected case
		}

		const reader = new FileReader()
		reader.onload = (event) => {
			if (event.target?.result && typeof event.target?.result === 'string') {
				const img = new Image()
				img.onload = () => {
					const width = img.naturalWidth
					const height = img.naturalHeight
					if (
						validateImageDimension?.width &&
						validateImageDimension?.height &&
						(width < validateImageDimension.width || height < validateImageDimension.height)
					) {
						displayToast(
							replaceMessageValues(UPLOAD_PHOTO_ERROR, [
								validateImageDimension?.width,
								validateImageDimension?.height,
							]),
							AlertVariant.ERROR
						)
						e.target.value = ''
					} else {
						uploadFile(
							FileType.IMAGE,
							e,
							uploadFileMutation,
							onUploadSuccess,
							onUploadError,
							displayToast,
							setImageCrop
						)
					}
				}
				img.src = event.target.result
			}
		}
		reader.readAsDataURL(file)
	}

    return (
        <>
            <CommonModal
                show={aiImageModal}
                modalContent={<AiImageGenerator callBack={saveImages} type="otherImage" cropType={cropType} />}
                onHide={setAiImageModal}
                title={AI_IMAGE_GENERATOR}
                hideFooter
            />

            <ImageCropModal image={imageCropSrc as string} show={imageCropSrc !== null} setImageCrop={setImageCrop}
                mutation={uploadFileMutation} onSuccess={onUploadSuccess} onError={onUploadError}
                displayToast={displayToast} cropType={cropType} />
                
            <WarningModal show={warning} title={DELETE_WARNING} callback={onDeleteFile} onHide={() => setWarning(false)} />
            <Offcanvas show={show} onHide={() => {
                clearData();
                handleClose();
            }} placement="end" backdrop="static">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{editItem 
                        ? <JTranslation typeCase="pascal" text={EDIT_MENU_TITLE} /> 
                        : <JTranslation typeCase="pascal" text={ADD_MENU_TITLE} />
                    }</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="row">
                        <div className="col-md-12  mb-3">
                            <label htmlFor="menu-name" className="form-label"><JTranslation typeCase="pascal" text={MENU_NAME} /><span className="mandatory ">*</span></label>
                            <input type="text" className="form-control" id="menu-name" data-testid="menu-name" autoComplete="off"
                                value={name}
                                maxLength={100}
                                onChange={(e) => setMenuName(e.target.value)} />
                        </div>
                    </div>
                    {showAlert && <div className="row">
                        <div className="col-md-12">
                            <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
                                <p>
                                    <JTranslation typeCase="capitalize" text={errorMessage} />
                                </p>
                            </Alert>
                        </div>
                    </div>
                    }
                    <div className="row">

                        <div className="mb-3 col-md-12  col-lg-12  ">
                            <div className="row img-flex">
                                {/* <!---image----> */}
                                <div className="mb-3 col-md-12  col-lg-12  ">
                                    <div className="row img-flex">
                                        <div className=" col-6 col-md-4 col-lg-6 col-xl-6 mb-2">
                                            <div className={image !== null && image?.trim().length > 0 ? " img-thumb-main" :
                                                "no-image-avathar img-thumb-main"}>
                                                <div className="view-img-preview">
                                                    <img src={image !== null && image?.trim().length > 0 ? image : menuImg1}
                                                        className="img-fluid rounded-1" alt=" " />
                                                </div>
                                                <div className="preview-tool text-end">
                                                    <i className="ri-delete-bin-6-line delete" data-testid="delete-img" onClick={() => setWarning(true)}></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-3 col-md-12  col-lg-12 d-flex flex-column upload-c">
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <label htmlFor="file-upload" className="custom-file-upload btn  btn-custom-primary-outline w-100" >
                                        <i className="fas fa-plus"></i> <JTranslation typeCase="pascal" text={UPLOAD_PHOTO} />
                                    </label>
                                    <div className="mt-2 small text-secondary"><JTranslation typeCase="capitalize" text={
                                        validateImageDimension?.width && validateImageDimension?.height
                                            ? replaceMessageValues(UPLOAD_PHOTO_INFO, [
                                                    validateImageDimension?.width,
                                                    validateImageDimension?.height,
                                            ])
                                            : UPLOAD_PHOTO_MESSAGE
                                    } /></div>
                                </div>
                                <div className="col-12">
                                    <label
                                        htmlFor="image-generate"
                                        className="custom-file-upload btn  btn-custom-primary w-100"
                                        onClick={() => setAiImageModal(true)}
                                    >
                                        <i className="fas fa-plus"></i> <JTranslation typeCase="pascal" text={AI_IMAGE_GENERATOR} />
                                    </label>
                                </div>
                            </div>



                            <input data-testid="upload" id="file-upload" type="file" accept="image/*,.heic,.heif"
                                autoComplete="off"
                                onChange={async (event) => {
                                    const updatedEvent = await checkIfHeicHeif(event)                                    
                                    if (validateImageDimension) {
                                        handleFileChange(updatedEvent)
                                    } else {
                                        uploadFile(
                                            FileType.IMAGE,
                                            updatedEvent,
                                            uploadFileMutation,
                                            onUploadSuccess,
                                            onUploadError,
                                            onError,
                                            setImageCrop
                                        )
                                    }
                                }} />


                        </div>
                    </div>

                    <div className="save-btn-section shadow save-btn-absolute">
                        <button className="btn btn-custom-primary-outline" type="button" data-testid="cancel-button"
                            onClick={() => {
                                clearData();
                                handleClose();
                            }}><JTranslation typeCase="pascal" text={CANCEL} /></button>
                        <button className="btn btn-custom-primary" type="button" data-testid="save-button"
                            disabled={name?.trim().length === 0}
                            onClick={(e) => {
                                e.preventDefault();
                                upsertCategory(upsertMenu, {
                                    categoryId: editItem?.id,
                                    photoKey: photoKey,
                                    categoryName: name
                                }, onSuccess, onError);
                            }}><JTranslation typeCase="pascal" text={SAVE} /></button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default AddMenu;