import { useState, useEffect } from 'react'
import { AxiosResponse } from 'axios'
import { getErrorMessage } from '../../helpers/utils'
import { getEventsListGuestView, getNewsListGuestView } from '../../helpers/newsAndEventsHelper'
import { JTranslation } from '../../helpers/jTranslate'
import { NewsPostGuestView, EventPostGuestView, ToastMessageProps, GetNewsAndEventsApiParams } from '../../constants/staticTypes'
import { routes } from '../../constants/routes'
import { ScrollTop } from 'primereact/scrolltop';
import { toastMessageInitialData, AlertVariant } from '../../constants/constants'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { v4 as uuidv4 } from "uuid"
import EventsCard from './EventsCard'
import Loader from '../../components/loader/Loader'
import NewsCard from './NewsCard'
import noEvents from '../../assets/images/no_events.png'
import noNews from '../../assets/images/no_news.png'
import ToastAlert from '../../components/alert/ToastAlert'
import useMutationHook from '../../hooks/useMutationHook'
import withPublicHeader from '../../hoc/withPublicHeader'

const NewsAndEventsGuestView = () => {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const newsMutation = useMutationHook(queryClient, true)
    const eventsMutation = useMutationHook(queryClient, true)
    // STATE VARIABLES
    const [events, setEvents] = useState<EventPostGuestView[]>([])
    const [news, setNews] = useState<NewsPostGuestView[]>([])
    const [loading, setLoading] = useState(true)
    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)

    const onFetchEventSuccess = (res: AxiosResponse<any, any>) => {
        const data: EventPostGuestView[] = res.data.data.list
        setEvents(data)
        setLoading(false)
    }

    const onFetchNewsSuccess = (res: AxiosResponse<any, any>) => {
        const data: NewsPostGuestView[] = res.data.data.list
        setNews(data)
        setLoading(false)
    }

    const onError = (res: any) => {
        const error = getErrorMessage(res)
        setToastMessage({
            message: error,
            show: true,
            variant: AlertVariant.ERROR,
        })
        setLoading(false)
    }
    useEffect(() => {
        const param: GetNewsAndEventsApiParams = { limit: 6 }
        // fetch news api call
        getNewsListGuestView(queryClient, param)
            .then(onFetchNewsSuccess)
            .catch(onError);
        // fetch events api call
        getEventsListGuestView(queryClient, param)
            .then(onFetchEventSuccess)
            .catch(onError);
    }, []) // eslint-disable-line

    // page loader
    
    if (loading) {
    return <Loader /> 
    }

    return (
        <>
            {/* toaster component */}
            <ToastAlert
                show={toastMessage.show}
                onClose={() => setToastMessage(toastMessageInitialData)}
                message={toastMessage.message}
                variant={toastMessage.variant}
            />

            <ScrollTop target='parent' className='scroll-top' behavior='smooth'/>

            <div className="container h-100">
                <div className="row mb-3 mt-3">
                    <div className="col-lg-12 p-0">
                        <div className="card">
                            <div className="card-body newsEventsWrapper">
                                <div className="row mb-3">
                                    <div className="col-12 mb-3">
                                        <div className="d-flex align-items-center mb-4 border-bottom pb-3">
                                            <div className="flex-grow-1">
                                                <h3 className="mb-0 ">{<JTranslation text="Latest News" />}</h3>
                                            </div>
                                            <div className="flex-grow-0">
                                                {news.length ? <button
                                                    className="btn btn-custom-primary"
                                                    data-testid="more-news"
                                                    onClick={() => navigate(routes.news_only)}
                                                >
                                                    {<JTranslation text="View More" />}{' '}
                                                    <i className="ri-arrow-right-line"></i>
                                                </button>
                                                    : ""
                                                }
                                            </div>
                                        </div>
                                        {/* empty state */}
                                        {!news.length && !loading &&
                                            <>
                                                <div className='row my-5'>
                                                    <div className='col-12 d-flex justify-content-center'>
                                                        <img
                                                            className='mb-5 img-fluid'
                                                            alt='empty-pate'
                                                            src={noNews}
                                                        ></img>
                                                    </div>
                                                    <div className='col-12 text-center'>
                                                        {<JTranslation text="There is no news left to read" />}
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <div className="row">
                                            {news.map((newsItem) => (
                                                <NewsCard key={uuidv4()} news={newsItem} />
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-12 mt-5">
                                        <div className="d-flex align-items-center  mb-4 border-bottom pb-3">
                                            <div className="flex-grow-1">
                                                <h3 className="mb-0 ">{<JTranslation text="Latest Events" />}</h3>
                                            </div>
                                            <div className="flex-grow-0">
                                                {events.length ? <button
                                                    className="btn btn-custom-primary"
                                                    data-testid="more-events"
                                                    onClick={() => navigate(routes.events_only)}
                                                >
                                                    {<JTranslation text="View More" />}{' '}
                                                    <i className="ri-arrow-right-line"></i>
                                                </button>
                                                    : ""
                                                }
                                            </div>
                                        </div>

                                        {/* empty state */}
                                        {!events.length && !loading &&
                                            <>
                                                <div className='row my-5'>
                                                    <div className='col-12 d-flex justify-content-center'>
                                                        <img
                                                            className='mb-5 img-fluid'
                                                            alt='empty-pate'
                                                            src={noEvents}
                                                        ></img>
                                                    </div>
                                                    <div className='col-12 text-center'>
                                                        {<JTranslation text='There are no upcoming events' />}
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <div className="row">
                                            {events.map((event) => (
                                                <EventsCard key={uuidv4()} event={event} />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withPublicHeader(NewsAndEventsGuestView)
