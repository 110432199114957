// HeartFallingEffect.tsx
import { useContext, useEffect } from 'react'
import Particles, { initParticlesEngine } from '@tsparticles/react'
import type { ISourceOptions } from '@tsparticles/engine'

import { loadAll } from '@tsparticles/all'
// import heartShape from './heart-shape.json' // Import the heart shape JSON configuration

// import CupidAnimation from './CupidAnimation'
import './heartFalling.css'
import { CommonCtx } from '../../../context/CommonCtxProvider'

const HeartFallingEffect = () => {
	const { setParticleEngineLoaded } = useContext(CommonCtx)

	useEffect(() => {
		initParticlesEngine(async (engine) => {
			await loadAll(engine) // Load the slim configuration
		}).then(() => {
			setParticleEngineLoaded(true)
		})
	}, [])

	const particlesLoaded = (container: any) => {
		// console.log({ container })
	}

	const particlesOptions: ISourceOptions = {
		zIndex: 99999999,
		fullScreen: {
			enable: true,
			zIndex: 99999999,
			// zIndex: -1, // Ensure particles are behind other content
		},
		fpsLimit: 120,
		interactivity: {
			events: {
				onClick: {
					enable: true,
					mode: 'attract',
				},
				onHover: {
					enable: true,
					mode: 'attract',
				},
			},
			modes: {
				push: {
					quantity: 4,
				},
				repulse: {
					distance: 200,
					duration: 0.4,
				},
				attract: {
					distance: 700, // Distance of attraction
					duration: 0.3, // Duration of attraction effect (seconds)
					factor: 6, // The force of the attraction
					maxSpeed: 70, // The maximum speed of the particles
					speed: 1.5, // The speed of the effect
				},
			},
		},
		particles: {
			number: {
				value: 8, // Adjust number of particles
			},
			shape: {
				type: 'character',
				options: {
					character: {
						value: ['❤️'], // Using heart emoji as particles
						font: '10px serif', // Customize font style as needed
					},
				},
			},
			opacity: {
				value: 0.8,
			},
			size: {
				value: 5, // Adjust particle size
				// random: { enable: true, minimumValue: 12 },
				animation: {
					enable: true,
					speed: 10, // Speed of the size animation. Adjust as needed.
					// minimumValue: 8, // Minimum particle size. Adjust based on your preference.
					sync: true, // Use true to animate all particles simultaneously.
				},
			},
			move: {
				enable: true,
				speed: 3, // Adjust movement speed
				direction: 'none',
				random: true,
				straight: false,
				outModes: {
					default: 'bounce',
					// default: 'destroy', // Particles will be destroyed when they go out of canvas
				},
				attract: {
					enable: true,
				},
			},
		},
		detectRetina: true,
	}
  
	return (
		<>
			<Particles
				className="valentines-day-particles"
				id="ts-particles"
				// style={{ zIndex: 9999, position: 'absolute', top: 0 }} // The style of the canvas element to ensure it appears on top
				//@ts-ignore
				particlesLoaded={particlesLoaded}
				//@ts-ignore
				options={particlesOptions}
			/>
		</>
	)
}

export default HeartFallingEffect
