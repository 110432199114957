import { Modal } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { SHOW_LOADER } from '../constants/queryKeys';
import { setLoader } from '../helpers/utils';

const withLoader = (WrappedComponent: Function) => {
    return (props: any) => {
        const loader = useQuery(SHOW_LOADER, () => setLoader(false), {
            retry: 0,
            enabled: false
        });

        return (
            <>
                <WrappedComponent {...props} />
                {loader.data?.show &&
                    <Modal
                        id="custom-loader"
                        dialogClassName='custom-loader'
                        centered
                        autoFocus={false}
                        show
                    >
                        {/* <div className="spinner-border" role="status" /> */}
                        <div className="custom-loader-new"></div>
                    </Modal>
                }
            </>
        );
    };
};

export default withLoader;