import { useContext, useState, useEffect } from 'react';
import { Badge } from 'primereact/badge';
import { checkPermission, convertUTCtoLocalByOffset, imageOnErrorHandler, toPascalCase } from "../../helpers/utils";
import { Column } from 'primereact/column';
import { CommonCtx } from '../../context/CommonCtxProvider';
import { DataTable } from 'primereact/datatable';
import { defaultRoles, MOBILE_VIEW_BREAKPOINT, PermissionKeys, SubPermissionKeys } from "../../constants/constants";
import { DOWNLOAD_ALL_QR_CODE, EMAIL_TEXT, FIRST_NAME, LAST_NAME, HIRE_DATE, MOBILE_TEXT, POS_NUMBER, ROLE, STATUS, ADD_NEW_USER, USER_LIST, DELETE, EDIT, QR_DOWNLOAD } from "../../constants/strings";
import { FaEdit, FaSearch } from "react-icons/fa";
import { routes } from "../../constants/routes";
import { saveAs } from "file-saver";
import { TENANT_INFO } from '../../constants/queryKeys';
import { useQueryClient } from "react-query";
import { Users, BrandingResponseData, JobRole } from "../../constants/staticTypes";
import axios, { AxiosResponse } from 'axios';
import html2canvas from "html2canvas";
import JSZip from "jszip";
import qrcode from "qrcode-generator";
import Spinner from 'react-bootstrap/Spinner';
import BsButton from 'react-bootstrap/Button';
import { JTranslation, TranslationContext, jTranslationText } from '../../helpers/jTranslate';
import { useTranslation } from '../../hooks/useTranslation';
import { DebounceInput } from 'react-debounce-input';
import { useWindowSize } from 'react-use';
import { DataView } from 'primereact/dataview';
import { Card } from 'primereact/card';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import MlxTooltip from '../common/MlxTooltip';
import placeholder from "../../assets/images/placeholder-avatar.jpg";

type Props = {
    users: Users[],
    loading: boolean,
    onSearchTrigger: (searchKey: string) => void,
    showToast: (message: string, variant: string, data?: Users) => void,
    onEditClick: (user: Users) => void,
    onDeleteClick: (user: Users) => void,
    editUser: Users | undefined,
    onActiveInactiveClick: (user: Users) => void
}

function UserListDataGrid({ users, loading, onSearchTrigger, onEditClick, onDeleteClick, editUser, onActiveInactiveClick }: Readonly<Props>) {
    // CONTEXT VARIABLE
    const { setShowSideBar: setAddUserSideBar, userGlobalSettings, fnBGlobalSettings } = useContext(CommonCtx)
    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext

    const queryClient = useQueryClient();
    const [generatingQR, setGeneratingQR] = useState(false);
    const [imageSrc, setImageSrc] = useState<string>('');
    const [mobileLogUrl, setMobileLogoUrl] = useState({ url: '', imageKey: '' })
    const [progress, setProgress] = useState("")
    const [translationText, setTranslatedText] = useState<{
        download: string,
        edit: string,
        delete: string,
        paginationTemplate: string,
        emptyMessage: string,
    }>({
        download: QR_DOWNLOAD,
        edit: EDIT,
        delete: DELETE,
        paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
        emptyMessage: 'No Users Found',
    });
    const [showTitle, setShowTitle] = useState(true)
    const { width, height } = useWindowSize()
	const breakPoint = MOBILE_VIEW_BREAKPOINT

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: QR_DOWNLOAD, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: EDIT, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: DELETE, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: 'Showing 1 to 2 of 3 items', typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: 'No Users Found', typeCase: 'pascal', translationContext }),
                ])
                setTranslatedText({
                    download: translations[0] ?? QR_DOWNLOAD,
                    edit: translations[1] ?? EDIT,
                    delete: translations[2] ?? DELETE,
                    paginationTemplate:
                        translations[3]
                            ?.replace('1', '{first}')
                            ?.replace('2', '{last}')
                            ?.replace('3', '{totalRecords}') ?? 'Showing {first} to {last} of {totalRecords} items',
                    emptyMessage: translations[4] ?? 'No Users Found',
                })
            } catch {
                setTranslatedText({
                    download: QR_DOWNLOAD,
                    edit: EDIT,
                    delete: DELETE,
                    paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
                    emptyMessage: 'No Users Found',
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    useEffect(() => {
        const res = queryClient.getQueryData(TENANT_INFO) as AxiosResponse<any, any>
        let data = res?.data?.data as BrandingResponseData
        const { branding } = data
        setMobileLogoUrl({ imageKey: '', url: branding?.logos?.small })
    }, [queryClient])

    useEffect(() => {
        const fetchImage = async () => {
            if (!mobileLogUrl.url || imageSrc) return
            try {
                // Fetch the image as a Blob
                const response = await axios.get(mobileLogUrl.url, {
                    responseType: 'blob',
                });

                // Create a local URL for the blob
                const localUrl = URL.createObjectURL(response.data);

                // Update state with the local URL
                setImageSrc(localUrl);
            } catch (error) {
                console.error('Error fetching image:', error);
            }
        };

        fetchImage();
    }, [mobileLogUrl.url, imageSrc]);

    const userFirstNameTemplate = (user: Users) => {
        return (
            <>
                <img className="dataAvatar" alt='user avatar' src={user.signedUrl ?? placeholder} onError={imageOnErrorHandler} width={32} style={{ verticalAlign: 'middle' }} />
                <span className="image-text" data-testid={"fistName#" + user.id}>{user.firstName}</span>
            </>
        );
    }

    const userLastNameTemplate = (user: Users) => {
        return (
            <span className="image-text" data-testid={"lastName#" + user.id}>{user.lastName}</span>
        );
    }

    const roleBodyTemplate = (user: Users) => {
        if (user?.jobRoles.length> 0) {
            const moreText = user.jobRoles.length > 1 ? " +" + (user.jobRoles.length - 1) + " more" : "";
            const jobRoles = user?.jobRoles ?? [] as JobRole[]
            const roleNames = jobRoles.map(assignedRole => assignedRole?.jobRoleName)?.filter(role => role !== undefined);

            if(roleNames.length === 0) {
                return <></>
            }
            const parsedRoleNames = roleNames.join(', ')
            const moreTextRoleName = roleNames[0] + moreText
            return width <= breakPoint ? (
				<div className='mt-2'>
                    <OverlayTrigger
					trigger={['hover', 'hover']}
					placement="auto"
					overlay={<Tooltip className="addon-tooltip">{parsedRoleNames}</Tooltip>}
                    >
                        <span className={`customer-badge small-text`}>
                            {moreTextRoleName}
                        </span>
                    </OverlayTrigger>
                </div>
			) : (
                <MlxTooltip target='.role-name'>
                    <div
                        data-pr-position="mouse"
                        data-pr-tooltip={parsedRoleNames}
                        className="flex-grow-1 overflow-ellipsis role-name"
                    >
                        <span className={`customer-badge`}>{moreTextRoleName}</span>
                    </div>
                </MlxTooltip>
			)
        }
    }

    const emailBodyTemplate = (user: Users) => {
        return <MlxTooltip target='.email-text'>
            <a className="email-text" data-pr-position="bottom" data-pr-tooltip={user.email} href={"mailto:" + user.email}>{user.email}</a>
        </MlxTooltip>
    }

    const deleteButtonTemplate = (user: Users) => {
        return <>
            {user.isActive &&
                <MlxTooltip target='.delete'>
                    <i data-pr-tooltip={translationText.delete} data-pr-position='bottom' className={"ri-delete-bin-line delete " +
                        (!checkPermission(queryClient, PermissionKeys.MANAGE_USERS, SubPermissionKeys.DELETE) &&
                            " disabled") + (width <= breakPoint ? ' rounded-background shadow-sm ms-2' : '')} data-testid={"delete#" + user.id}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (checkPermission(queryClient, PermissionKeys.MANAGE_USERS, SubPermissionKeys.DELETE)) {
                                onDeleteClick(user);
                            }
                    }}>
                    </i>
                </MlxTooltip>
            }
        </>
    }

    const editButtonTemplate = (user: Users) => {
        return (
            <>
                {
                    user.isActive &&
                    <span className={`${width <= breakPoint ? 'rounded-background shadow-sm ms-2' : ''}`}>
                        <MlxTooltip target='.edit'>
                            <FaEdit
                                className={`edit ${!checkPermission(queryClient, PermissionKeys.MANAGE_USERS, SubPermissionKeys.EDIT) && ' disabled'}`}
                                data-pr-position="bottom"
                                data-pr-tooltip={translationText.edit}
                                data-testid="edit-user"
                                onClick={(e) => {
                                    e.preventDefault()
                                    if (checkPermission(queryClient, PermissionKeys.MANAGE_USERS, SubPermissionKeys.EDIT)) {
                                        onEditClick(user)
                                        setAddUserSideBar(true)
                                    }
                                }}
                            />
                        </MlxTooltip>
                    </span>
                }
            </>
        )

    }

    const activeInactiveTemplate = (user: Users) => {
        return (
            <div className="d-flex">
                {/* {user.isActive && <div className="flex-grow-0 "> */}
                {<div className="flex-grow-0 ">
                    <span className="form-check form-switch">
                        <input className="form-check-input form-check-input-custom"
                            type="checkbox"
                            autoComplete="off"
                            data-testid={"active-inactive#" + user.id}
                            checked={user.isActive}
                            disabled={!checkPermission(queryClient, PermissionKeys.MANAGE_USERS, SubPermissionKeys.STATUS)}
                            onChange={() => {
                                if (checkPermission(queryClient, PermissionKeys.MANAGE_USERS, SubPermissionKeys.STATUS)) {
                                    onActiveInactiveClick(user);
                                }
                            }}
                            id="flexSwitchCheckDefault" 
                            style={width <= breakPoint ? { width: '3em', height: '1.5em' } : { width: '2.25em', height: '1em' }}
                        />
                    </span>
                </div>}
                {width > breakPoint && (
					<div className="flex-grow-1">
                        {user.isActive ? (
                            <JTranslation text={'Active'} typeCase="pascal" />
                        ) : (
                            <JTranslation text={'Inactive'} typeCase="pascal" />
                        )}
                    </div>
				)}
                <div className=""></div>
            </div>
        )
    }

    // download qr template
    const downloadQRTemplate = (user: Users) => {
        if (user.jobRoles.some(item => item.jobRoleName.toUpperCase() === "SERVER") && user.isActive && user.posNumber) {
            return (
                <MlxTooltip target='.download'>
                    <i data-pr-tooltip={translationText.download} data-pr-position='bottom' 
                    className={`ri-download-2-fill download ${width <= breakPoint ? 'rounded-background shadow-sm ms-2' : ''}`} data-testid={"qr#" + user.id}
                        aria-disabled
                        onClick={(e) => {
                            e.stopPropagation();
                            downloadQR(user);
                    }}>
                    </i>
                </MlxTooltip>
            )
        }
    }

    // generate qr image
    const generateQR = async (user: Users) => {
        let qrDiv;
        const canvas = await html2canvas((document.getElementById("qr-code") as HTMLElement), {
            onclone: (doc) => {
                qrDiv = doc.getElementById("qr-code") as HTMLElement;
                qrDiv.style.display = "inline-block";

                // add qr code
                const qrText = `${window.location.protocol}//${window.location.hostname}${routes.welcome_page}?server=${user.posNumber}&qr_scan=true`;
                let qr = qrcode(0, 'L');
                qr.addData(qrText);
                qr.make();
                // (doc.getElementById("qr-top-label") as HTMLElement).innerHTML = fnBGlobalSettings.qrCodeSettings.qrCodeTopLabel;
                (doc.getElementById("qr-img") as HTMLElement).innerHTML = qr.createSvgTag(10);
                (doc.getElementById("qr-name") as HTMLElement).innerHTML = user.preferredName;
                (doc.getElementById("qr-label") as HTMLElement).innerHTML = userGlobalSettings ? userGlobalSettings.qrCodeLabel : " ";
            }
        });
        let image = canvas.toDataURL("image/png", 1.0);
        return image;
    }

    // download qr
    const downloadQR = async (user: Users) => {
        let qrImage = await generateQR(user);
        saveAs(qrImage, `${user.posNumber}.png`);
    }

    // download all qr code
    const downloadAllQR = async () => {
        setGeneratingQR(true)
        let zip = new JSZip();

        // filter out servers from list
        const serversList = users.filter(user => user.isActive &&
            user.jobRoles.some(role => role.jobRoleName.toUpperCase() === "SERVER"));

        // generate qr codes for all users in list
        for (let index = 0; index < serversList.length; index++) {
            const user = serversList[index];

            // add image to zip file
            let qrImage = await generateQR(user);
            qrImage = qrImage.replace("data:image/png;base64,", "");
            zip.file(user.posNumber + ".png", qrImage, { base64: true });

            // Calculate progress percentage as a whole number
            setProgress(`${Math.round(((index + 1) / serversList.length) * 100)}%`);

            if (index === serversList.length - 1) {
                const content = await zip.generateAsync({ type: "blob" });
                saveAs(content, "qrcodes.zip");
            }
        }
        setGeneratingQR(false)
        setProgress("")
    }

    // get download all button disabled status
    const isDownloadAllDisabled = () => {
        const serversList = users.filter(user => user.isActive &&
            user.jobRoles.some(role => role?.jobRoleName && role.jobRoleName.toUpperCase() === "SERVER"));
        return serversList.length === 0;
    }

    // Call the function to get the boolean value
    const downloadAllDisabled = isDownloadAllDisabled();

    const itemTemplate = (user: Users) => {
        return (
			<Card className="data-view-card mt-2 d-flex flex-column justify-content-even">
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <div className="view-header d-flex flex-row justify-content-between align-items-center">
                        <img className="dataAvatar" alt='user avatar' src={user.signedUrl !== null ? user.signedUrl : ""} onError={imageOnErrorHandler} width={32} style={{ verticalAlign: 'middle' }} />
                        <p className="m-0">{toPascalCase(`${user.firstName} ${user.lastName}`)}</p>
                    </div>
					<p className="m-0 text-nowrap"><span className='small-text'>#</span>{user.posNumber}</p>
                </div>
				<div className='mt-2'>
					<p className="m-0">{emailBodyTemplate(user)}{user.email && ', '}{user.phoneNumber}</p>
                    {user.hireDate && (
						<p className="m-0">
							<JTranslation typeCase="pascal" text={'Hire Date'} />:{' '}
							<span className="font-bold">{convertUTCtoLocalByOffset(user.hireDate)}</span>
						</p>
					)}
				</div>
                <div className='w-100 d-flex justify-content-end'>
                    <div className="m-0 text-nowrap">{roleBodyTemplate(user)}</div>
                </div>
				<div className="view-footer mt-2 pt-3 d-flex flex-row justify-content-between align-items-center">
					<div className='d-flex justify-content-even align-items-center'>
                        {editButtonTemplate(user)}
                        {/* {downloadQRTemplate(user)}{editButtonTemplate(user)}{deleteButtonTemplate(user)} */}
                    </div>
					<div className='d-flex justify-content-even align-items-center'>{activeInactiveTemplate(user)}</div>
				</div>
			</Card>
		)
    }

    return (
        <div className="col-md-12 col-lg-12 mb-3 h-100">
            <div id="qr-code" className='qr-code-parent' style={{ display: "none" }}>
                {/* <p id="qr-top-label" className='qr-code-child qr-code-label-top'></p> */}
                <div className="qr-image-wrapper">
                    {mobileLogUrl.url && (<img alt="small-logo" src={imageSrc} className="fnb-qr-code-logo" />)}
                    <div id="qr-img" ></div>
                </div>
                {/* <p id="qr-bottom-label" className='qr-code-child qr-code-label-bottom'></p> */}
                <div id="qr-label" className='qr-code-label'></div>
                <p id="qr-name" className='qr-code-preferred-name'></p>
            </div>
            <div className="card h-100">
                <div className="card-header flex-grow-0">
                    <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                            {showTitle &&
                                <h4 className="mb-0 title"><JTranslation typeCase="capitalize" text={USER_LIST} /></h4>
                            }
                        </div>
                        <div className="flex-grow-0 d-flex">
                            <div className="flex-grow-0 me-2 ms-1">
                                <button className="btn btn-custom-primary d-flex icon-only-btn" data-testid="add-new-user" type="button" onClick={() => setAddUserSideBar(true)}>
                                    <i className="ri-add-circle-line me-1"></i><span className='btn-text'><JTranslation typeCase="capitalize" text={ADD_NEW_USER} /></span>
                                </button>
                            </div>
                            {/* Hidden for now */}
                            {/* <div className="flex-grow-0 me-2 ms-1 position-relative">
                                <BsButton
                                    className="btn btn-custom-primary-outline icon-only-btn"
                                    type="button"
                                    data-testid="download-all-qr"
                                    disabled={!imageSrc || generatingQR || downloadAllDisabled}
                                    onClick={downloadAllQR}
                                >
                                    {generatingQR ? (
                                        <Spinner className='me-2' animation="border" size="sm" />
                                    ) : (
                                        <i className="ri-download-line me-2"></i>
                                    )}
                                    <span className='btn-text'><JTranslation typeCase="capitalize" text={DOWNLOAD_ALL_QR_CODE} /></span>
                                </BsButton>
                                {progress && <Badge className='custom-badge-qr' value={progress} severity="info" />}
                            </div> */}
                            <div className="flex-grow-0  d-flex justify-content-end ">
                                <div className="col-md-12 ">
                                    <div className={`form-group has-search ${!showTitle ? '' : 'search-small-box'}`}>
                                        <span className="fa fa-search form-control-feedback"><FaSearch />  </span>
                                        <DebounceInput
											onFocusCapture={() => {breakPoint >= width && setShowTitle(false)}}
											onBlurCapture={(event: any) => {                                                
                                                if(breakPoint >= width && event.target.value.length === 0) {
                                                    setShowTitle(true)
                                                }
                                            }}
                                            data-testid="search"
											className="form-control"
											placeholder={useTranslation("Search", "pascal")}
                                            autoComplete="off"
											minLength={0}
											debounceTimeout={500}
											onChange={(e) => {
												onSearchTrigger(e.target.value)
											}}
										/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="card-body flex-grow-1 overflow-hidden">
                    <div className="row mb-5 h-100">
                        <div className="col-lg-12" style={{ height: '100%', overflow: 'auto' }}>
                            {width <= breakPoint ? (
                                <DataView
                                    value={users}
                                    layout={'list'}
                                    itemTemplate={itemTemplate}
                                    paginator
                                    rows={10}
                                    paginatorClassName="p-dataview-customers"
                                    dataKey="id"
                                    emptyMessage={translationText.emptyMessage}
                                />
                            ) : (
                                <DataTable value={users} paginator className="p-datatable-customers" rows={10}
                                    scrollable scrollHeight="flex"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[10, 25, 50]}
                                    dataKey="id" rowHover
                                    selectionMode="single" selection={editUser}
                                    loading={loading} responsiveLayout="scroll"
                                    globalFilterFields={['name', 'country.name', 'representative.name', 'balance', 'status']} emptyMessage={translationText.emptyMessage}
                                    currentPageReportTemplate={translationText.paginationTemplate}>
                                    <Column field="posNumber" header={<JTranslation typeCase="pascal" text={POS_NUMBER} />} sortable className="word-break-grid" style={{ maxWidth: '10rem', minWidth: '7rem' }}
                                        sortFunction={(e) => {
                                            const rows = e.data as Users[];
                                            rows.sort((a, b) => {
                                                return a.posNumber?.localeCompare(b.posNumber, undefined, {
                                                    numeric: true,
                                                    sensitivity: 'base'
                                                });
                                            });
                                            if (e.order === 1) {
                                                return rows;
                                            } else {
                                                return rows.reverse();
                                            }
                                        }} />
                                    <Column field="firstName" header={<JTranslation typeCase="pascal" text={FIRST_NAME} />} sortable className="overflow-ellipsis word-break-grid" style={{ maxWidth: '12rem', minWidth: '12rem' }} body={userFirstNameTemplate} />
                                    <Column field="lastName" header={<JTranslation typeCase="pascal" text={LAST_NAME} />} sortable className="word-break-grid" style={{ maxWidth: '12rem', minWidth: '12rem' }} body={userLastNameTemplate} />
                                    <Column field="email" className='overflow-ellipsis' header={<JTranslation typeCase="pascal" text={EMAIL_TEXT} />} sortable style={{ minWidth: '12rem', maxWidth: '15rem' }} body={emailBodyTemplate} />
                                    <Column field="phoneNumber" header={<JTranslation typeCase="pascal" text={MOBILE_TEXT} />} sortable style={{ maxWidth: '10rem', minWidth: '9rem' }} />
                                    <Column field="jobRoles" header={<JTranslation typeCase="pascal" text={ROLE} />} style={{ minWidth: '15rem' }} body={roleBodyTemplate} />
                                    <Column field="hireDate" header={<JTranslation typeCase="pascal" text={HIRE_DATE} />} sortable style={{ maxWidth: '9rem', minWidth: '8rem' }}
                                        sortFunction={(e) => {
                                            const rows = e.data as Users[];
                                            rows.sort((a, b) => {
                                                if (a.hireDate === "") {
                                                    return -1;
                                                }
                                                return new Date(a.hireDate).getTime() - new Date(b.hireDate).getTime()
                                            });
                                            if (e.order === 1) {
                                                return rows;
                                            } else {
                                                return rows.reverse();
                                            }
                                        }} body={(userInfo: Users) => <>{convertUTCtoLocalByOffset(userInfo.hireDate)}</>} />
                                    <Column field="status" header={<JTranslation typeCase="pascal" text={STATUS} />} sortable style={{ maxWidth: '8rem', minWidth: '8rem' }} body={activeInactiveTemplate} />
                                    {/* <Column field="qr" style={{ maxWidth: '3rem', minWidth: '3rem' }} body={downloadQRTemplate} bodyClassName="bg-sticky-color" alignFrozen="right" frozen={true} /> */}
                                    <Column field="edit" style={{ maxWidth: '3rem', minWidth: '3rem' }} bodyClassName="bg-sticky-color" body={editButtonTemplate} alignFrozen="right" frozen={true} />
                                    {/* <Column field="delete" style={{ maxWidth: '3rem', minWidth: '3rem' }} bodyClassName="bg-sticky-color" body={deleteButtonTemplate} alignFrozen="right" frozen={true} /> */}
                                </DataTable>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default UserListDataGrid;