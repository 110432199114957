import React, { useState, useContext } from 'react'
import { ADD_NEWS_AND_EVENTS, NEWS, EVENTS } from '../../constants/strings'
import { CommonCtx } from '../../context/CommonCtxProvider'
import { PageMode, toastMessageInitialData } from '../../constants/constants'
import { ToastMessageProps, NewsAndEventsId, PostType } from '../../constants/staticTypes'
import EventForm from './EventForm'
import NewsForm from './NewsForm'
import Offcanvas from 'react-bootstrap/Offcanvas'
import ToastAlert from '../../components/alert/ToastAlert'
import { JTranslation } from '../../helpers/jTranslate'

type Props = {
    pageMode: PageMode.ADD | PageMode.EDIT
    postType: PostType.NEWS | PostType.EVENT
    selectedPostId: NewsAndEventsId
    setPostType: React.Dispatch<React.SetStateAction<PostType.NEWS | PostType.EVENT>>
    setShowSideBar: React.Dispatch<React.SetStateAction<boolean>>
}

const AddNewsAndEventsSideBar = ({ pageMode, setShowSideBar, selectedPostId, postType, setPostType }: Props) => {
    // CONTEXT VARIABLE
    const { showSideBar } = useContext(CommonCtx)
    // STATE VARIABLES
    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)

    const onClose = () => {
        setShowSideBar(false)
    }

    return (
        <Offcanvas
            show={showSideBar}
            onHide={() => onClose()}
            backdrop="static"
            // responsive="xl"
            placement="end"
            className="custom-offcanvas"
        >
            {/* TOAST MESSAGE COMPONENT */}
            <ToastAlert
                show={toastMessage.show}
                onClose={() => setToastMessage(toastMessageInitialData)}
                message={toastMessage.message}
                variant={toastMessage.variant}
            />

            <Offcanvas.Header closeButton>
                <Offcanvas.Title><JTranslation typeCase="pascal" text={ADD_NEWS_AND_EVENTS} /></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="row">
                    <div className=" col-md-12 col-lg-12 mb-3 ">
                        <div className='form-check form-check-inline mb-3'>
                            <input
                                type="radio"
                                className='form-check-input fw-bold'
                                name="picked"
                                value={PostType.NEWS}
                                checked={postType === PostType.NEWS}
                                disabled={pageMode === PageMode.EDIT}
                                data-testid="news-radio-btn"
                                onChange={() => {
                                    setPostType(PostType.NEWS)
                                }}
                            />
                            <JTranslation typeCase="pascal" text={NEWS} />
                        </div>
                        <div className='form-check form-check-inline mb-3'>
                            <input
                                type="radio"
                                className='form-check-input'
                                name="picked"
                                value={PostType.EVENT}
                                checked={postType === PostType.EVENT}
                                disabled={pageMode === PageMode.EDIT}
                                data-testid="events-radio-btn"
                                onChange={() => {
                                    setPostType(PostType.EVENT)
                                }}
                            />
                            <JTranslation typeCase="pascal" text={EVENTS} />
                        </div>
                    </div>
                    <div className=" col-md-12 col-lg-12 mb-3 ">
                        {/* FORM COMPONENTS */}
                        {postType === PostType.NEWS && <NewsForm onClose={onClose} pageMode={pageMode} selectedPostId={selectedPostId} postType={postType} />}
                        {postType === PostType.EVENT && <EventForm onClose={onClose} pageMode={pageMode} selectedPostId={selectedPostId} postType={postType} />}
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default AddNewsAndEventsSideBar
