import { BrandingData } from "../../constants/staticTypes"

// Generic function to check if the current date is within a given date range
export function isSpecialEffectDateWithinRange(
  rangeStartStr: string,
  rangeEndStr: string
): boolean {
  const currentDate = new Date()
  currentDate.setHours(0, 0, 0, 0) // Reset time to start of the day for accurate comparison

  const [startDay, startMonth] = rangeStartStr
    .split('-')
    .map((num) => parseInt(num, 10))
  const [endDay, endMonth] = rangeEndStr
    .split('-')
    .map((num) => parseInt(num, 10))

  const year = currentDate.getFullYear()

  const rangeStartDate = new Date(year, startMonth - 1, startDay)
  const rangeEndDate = new Date(year, endMonth - 1, endDay, 23, 59, 59, 999)

  return currentDate >= rangeStartDate && currentDate <= rangeEndDate
}

export const checkFestivalEffects = (brandingInfo: BrandingData, effectName: string, location: string): boolean => {
  if (brandingInfo?.enableCelebrationEffect && brandingInfo?.activeCelebrationTemplate === effectName) {
    // if not in welcome page
    if (location === '/welcome' && !brandingInfo?.showCelebrationTemplateOn?.includes('welcomePage')) {
      return false
    }
    // if not in all other pages
    if (location !== '/welcome' && !brandingInfo?.showCelebrationTemplateOn?.includes('allOtherPage')) {
      return false
    }
    return true
  }
  return false
}
