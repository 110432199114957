import { BACK, BOUQUET, COLOR, DENOMINATION, ORIGIN, TASTE } from "../../../constants/strings";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { HistoryInfo, MenuItem } from "../../../constants/staticTypes";
import countries from "../../../assets/files/countrylist.json";
import { hasFlag } from 'country-flag-icons';
import itemImage2 from "../../../assets/images/menu/540x360.png";
import withPublicHeader from '../../../hoc/withPublicHeader';
import { JTranslation, TranslationContext, jTranslationText } from '../../../helpers/jTranslate';
import { MENU_ITEM_DETAIL, PUBLIC_MENU } from '../../../constants/queryKeys';
import { getBreadCrumbPath, getMenuItemsDetailsBySlug, getMenuItemsPublic } from '../../../helpers/menuViewHelper';
import useQueryHook from '../../../hooks/useQueryHook';
import { useQueryClient } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import { BreadCrumb } from 'primereact/breadcrumb';
import { routes } from '../../../constants/routes';
import { AxiosResponse } from 'axios';
import { isJSONObjectEmpty, unescapeHtml } from '../../../helpers/utils';
import Loader from "../../loader/Loader";
import { Skeleton } from "primereact/skeleton";

type ItemState = {
    item: HistoryInfo
}

function WineHistory() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient()
  const [item, setItem] = useState<MenuItem>()
  const [historyInfo, setHistoryInfo] = useState<HistoryInfo>();
  const [breadCrumbItems, setBreadCrumbItems] = useState<{ label: string, url?: string }[]>([])
  const [flag, setFlag] = useState("");
  // URL PARAMS
  const { menuSlug, categorySlug, itemSlug } = useParams()
  const translationContext = useContext(TranslationContext)
  const TranslateText = async (text: string) => {
    let returnText = text
    try {
      const translatedText = await jTranslationText({
        text,
        translationContext,
      })
      returnText = translatedText || ""
    }
    catch (e) {
      console.log("Translation error on ItemView:", e)
    }
    return returnText
  }
  // fetch menu items
  const listDetails = useQueryHook(
    PUBLIC_MENU,
    () => getMenuItemsPublic(queryClient),
    () => { },
    undefined,
    true,
    false
  )
  const publicMenuList = queryClient.getQueryData(PUBLIC_MENU) as AxiosResponse<
    any,
    any
  >
  useEffect(() => {
    let isMounted = true

    const fetchData = async () => {
      if (
        publicMenuList &&
        !isJSONObjectEmpty(publicMenuList.data.data.list) &&
        !isJSONObjectEmpty(item)
      ) {
        const path = getBreadCrumbPath({
          data: publicMenuList.data.data.list,
          id: item?.id ?? '',
        })

        try {
          const menuName = await TranslateText(path?.menuName || "")
          const categoryName = await TranslateText(path?.categoryName || "")
          const itemName = await TranslateText(path?.itemName || "")

          if (isMounted) {
            if (path) {
              setBreadCrumbItems([
                { label: menuName || "", url: `${routes.menu_view}/${menuSlug}` },
                { label: categoryName || "", url: `${routes.menu_view}/${menuSlug}/${categorySlug}` },
                { label: itemName || "", url: `${routes.menu_view}/${menuSlug}/${categorySlug}/${itemSlug}` },
                { label: "History", url: `#` },
              ])
            }
          }
        } catch (error) {
          // Handle any errors here
        }
      }
    }

    fetchData()

    return () => {
      // Cleanup function to set isMounted to false when the component unmounts
      isMounted = false
    }
  }, [publicMenuList, item, translationContext])

    // fetch item details
    const itemDetails = useQueryHook(
      MENU_ITEM_DETAIL,
      () => getMenuItemsDetailsBySlug(queryClient, itemSlug as string),
      (res) => {
        let data = res.data.data.details as MenuItem
        setItem(data)
        setHistoryInfo(data.historyInfo)
        if (data.historyInfo.originCountry !== null) {
          let originCountry = countries.filter(country => country.name === data.historyInfo.originCountry)[0];
          let code = originCountry.code;
          if (hasFlag(code)) {
              setFlag(`http://purecatamphetamine.github.io/country-flag-icons/3x2/${code}.svg`);
          }
        }
      },
      undefined,
      true,
      true
    )

    useEffect(() => {
      if (itemSlug === undefined) { // go back if user directly access page
        navigate(-1);
      } else {
        itemDetails.refetch(); 
        window.scrollTo(0, 0);
      }
    }, [itemSlug]);

    return (
      <>
        {(
          itemDetails.isLoading || 
          itemDetails.isRefetching || 
          itemDetails.isFetching
        ) ? (
          <Loader />
        ) : (
          <div className='container'>
            <div className='row mb-3 mt-3'>
              <div className='col-lg-12'>
                <div className='card mb-3'>
                  <div className='card-header p-0'>
                    <div className=' '>
                      <div className='col-md-12 d-flex align-items-center p-2'>
                        <div
                          className='button btn  back-btn back-btn-new'
                          title={BACK}
                          onClick={() => navigate(-1)}
                        >
                          <i className='ri-arrow-left-line'></i>
                        </div>
                        {(
                          listDetails.isLoading || 
                          listDetails.isRefetching || 
                          listDetails.isFetching
                        ) ? (
                          <div className='row ms-2'>
                            <div className='col-3'>
                              <Skeleton width='6rem' height='2rem'></Skeleton>
                            </div>
                            <div className='col-3'>
                              <Skeleton width='6rem' height='2rem'></Skeleton>
                            </div>
                            <div className='col-3'>
                              <Skeleton width='6rem' height='2rem'></Skeleton>
                            </div>
                            <div className='col-3'>
                              <Skeleton width='8rem' height='2rem'></Skeleton>
                            </div>
                          </div>
                        ) : (
                          <BreadCrumb
                            className='custom-breadcrumb'
                            model={breadCrumbItems}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='card-body'>
                    <div className='row'>
                      <div className='col-md-12 d-flex align-items-center p-3 justify-content-between mb-3 border-bottom'>
                        <div className='wine-history-title col-10  '>
                          <h2 className='m-0'>
                            <JTranslation text={historyInfo?.title || ''} />
                          </h2>
                          <p className='m-0'>
                            <JTranslation text={historyInfo?.subTitle || ''} />
                          </p>
                        </div>

                        <div className='flag-box col-2 text-end'>
                          <img
                            src={flag}
                            className='rounded img-fluid'
                            width={80}
                            alt={historyInfo?.originCountry}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-md-12'>
                        <div className='row border-bottom pb-3 d-flex mb-3 wine-history-inner'>
                          <div className='col-md-4 d-flex align-items-start mb-3 justify-content-center'>
                            {historyInfo?.signedPhotoKeys.map((image) => {
                              return (
                                <img
                                  key={uuidv4()}
                                  src={image || itemImage2}
                                  className='img-fluid rounded-1'
                                  alt=''
                                />
                              )
                            })}
                          </div>
                          <div className='col-md-8'>
                            <h4 className='card-title fw-bold'>
                              <JTranslation text='Specification' />
                            </h4>
                            {/* {historyInfo?.description &&
                              parse(historyInfo?.description)} */}
                            <JTranslation
                              text={unescapeHtml(historyInfo?.description || '')}
                              type='html'
                            />
                            <div className='row g-3 origin-sec mb-4'>
                              <div className='col-sm-12  col-md-6 col-lg-6'>
                                <div className='d-flex align-items-center rounded  custom-shadow px-3 py-2 wine-spec'>
                                  <div className='mb-0'>
                                    <strong>
                                      {' '}
                                      <JTranslation text={ORIGIN} />:
                                    </strong>
                                    <div className='wine-spec-des'>
                                      {' '}
                                      <JTranslation
                                        text={historyInfo?.originCountry || ''}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className='col-sm-12  col-md-6 col-lg-6'>
                                <div className='d-flex align-items-center rounded  custom-shadow px-3 py-2 wine-spec'>
                                  <div className='mb-0'>
                                    <strong>
                                      <JTranslation text={DENOMINATION} /> :
                                    </strong>
                                    <div className='wine-spec-des'>
                                      {' '}
                                      <JTranslation
                                        text={historyInfo?.denomination || ''}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className='col-sm-12  col-md-6 col-lg-6'>
                                <div className='d-flex align-items-center rounded custom-shadow  px-3 py-2 wine-spec'>
                                  <div className='mb-0'>
                                    <strong>
                                      <JTranslation text={COLOR} />:
                                    </strong>
                                    <div className='wine-spec-des'>
                                      {' '}
                                      <JTranslation
                                        text={historyInfo?.color || ''}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className='col-sm-12  col-md-6 col-lg-6'>
                                <div className='d-flex align-items-center rounded custom-shadow  px-3 py-2 wine-spec'>
                                  <div className='mb-0'>
                                    <strong>
                                      {' '}
                                      <JTranslation text={BOUQUET} />:
                                    </strong>
                                    <div className='wine-spec-des'>
                                      <JTranslation
                                        text={historyInfo?.bouquet || ''}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className='col-sm-12 col-md-6 col-lg-6'>
                                <div className='d-flex align-items-center rounded  custom-shadow  px-3 py-2 wine-spec'>
                                  <div className='mb-0'>
                                    <strong>
                                      {' '}
                                      <JTranslation text={TASTE} />
                                      :
                                    </strong>
                                    <div className='wine-spec-des'>
                                      <JTranslation
                                        text={historyInfo?.taste || ''}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='vgr-cards'>
                          <div className='col-md-12 mb-3'>
                            <div className='row'>
                              {historyInfo?.additionalInfo.map((info) => {
                                return (
                                  <div key={uuidv4()}>
                                    <div className='col-md-12 mb-3 wine-history-info'>
                                      <JTranslation
                                        text={unescapeHtml(info?.textContent || '')}
                                        type='html'
                                      />
                                    </div>

                                    {/*  <!---image section---> */}
                                    <div className='col-md-12 mb-3'>
                                      <div className='row'>
                                        <div className='history-img-container'>
                                          {info.signedImageKeys.map((img) => {
                                            return (
                                              <div className=' mb-3 me-2' key={uuidv4()}>
                                                <div className=' history-img-view'>
                                                  <img
                                                    src={img}
                                                    className='img-fluid'
                                                    alt=''
                                                  />
                                                </div>
                                              </div>
                                            )
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    )
}
export default withPublicHeader(WineHistory);