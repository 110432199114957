import { ChangeEvent, useContext, useEffect, useState } from 'react'
import {
	RolePermissions,
	Permission,
	ToastMessageProps,
	PermissionList,
	PermissionListForm,
} from '../../constants/staticTypes'
import { useQueryClient } from 'react-query'
import { PERMISSION_LIST, PERMISSION_ROLE_LIST } from '../../constants/queryKeys'
import useQueryHook from '../../hooks/useQueryHook'
import { AxiosResponse } from 'axios'
import { JTranslation, TranslationContext, jTranslationText } from '../../helpers/jTranslate'
import ToastAlert from '../../components/alert/ToastAlert'
import { customDeepClone } from '../../helpers/utils'
import { ADD, CLEAR, DELETE_WARNING, SAVE } from '../../constants/strings'
import {
	convertListToPermissionForm,
	deleteCustomPermission,
	getPermissionRoles,
	simplifyPermissions,
	updateCustomPermission,
	updateFullPermissions,
	updatePermissionsItems,
} from '../../helpers/permissionHelper'
import withSidebar from '../../hoc/withSidebar'
import PermissionFeatureList from '../../components/permission_groups/PermissionFeatureList'
import { AlertVariant, toastMessageInitialData } from '../../constants/constants'
import useMutationHook from '../../hooks/useMutationHook'
import AddCustomPermission from '../../components/permission_groups/AddCustomPermission'
import WarningModal from '../../components/warning_modal/WarningModal'
import Loader from '../../components/loader/Loader'
import { capitalize } from 'lodash'

function PermissionGroups() {
	const queryClient = useQueryClient()
	const translationContext = useContext(TranslationContext)
	const { targetLanguage } = translationContext
	const permissionListResponse = queryClient.getQueryData(PERMISSION_LIST) as AxiosResponse<any, any>
	const addPermissionMutation = useMutationHook(queryClient, true)
	const deletePermissionMutation = useMutationHook(queryClient, true)
	const updatePermissionMutation = useMutationHook(queryClient, true)

	// STATE VARIABLES
	const [clearChanges, setClearChanges] = useState(0)
	const [showWarning, setShowWarning] = useState(false)
	const [deleteCustomRole, setDeleteCustomRole] = useState<RolePermissions | null>(null)
	const [addedCustomRole, setAddedCustomRole] = useState<RolePermissions | null>(null)
	const [showAddPermissionForm, setShowAddPermissionForm] = useState(false)
	const [permissionGroups, setPermissionGroups] = useState<RolePermissions[]>([])
	const [nonDefaultPermissionGroups, setNonDefaultPermissionGroups] = useState<RolePermissions[]>([])
	const [defaultPermissionGroups, setDefaultPermissionGroups] = useState<RolePermissions[]>([])
	const [selectedPermissionGroup, setSelectedPermissionGroup] = useState<RolePermissions | null>(null)
	const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
	const [translationText, setTranslatedText] = useState<{ permissionName: string; permissionDesc: string }>({
		permissionName: 'Permission Group Name',
		permissionDesc: 'Permission Group Description',
	})

	const getPermissionGroupsQuery = useQueryHook(
		PERMISSION_ROLE_LIST,
		getPermissionRoles,
		(res: AxiosResponse<any, any>) => {
			const fullPermissions = res.data?.data.lists as RolePermissions[]
			const clonedPermissions = customDeepClone(fullPermissions) as RolePermissions[]
			const parsedDefaultPermissionGroups = clonedPermissions?.filter(
				(permissionRole) => permissionRole.isDefault
			)
			const parsedPermissionGroups = clonedPermissions?.filter((permissionRole) => !permissionRole.isDefault)
			setPermissionGroups(clonedPermissions)
			setDefaultPermissionGroups(parsedDefaultPermissionGroups)
			setNonDefaultPermissionGroups(parsedPermissionGroups)

			if (!selectedPermissionGroup) {
				if (parsedDefaultPermissionGroups.length > 0) {
					setSelectedPermissionGroup(parsedDefaultPermissionGroups[0])
				} else if (parsedPermissionGroups.length > 0) {
					setSelectedPermissionGroup(parsedPermissionGroups[0])
				} else {
					setSelectedPermissionGroup(null)
				}
			} else if (addedCustomRole) {
				const addedPermissionGroup = parsedPermissionGroups.find(
					(permissionGroup) => permissionGroup.id === addedCustomRole.id
				)
				setSelectedPermissionGroup(addedPermissionGroup ?? parsedPermissionGroups[0])
				setAddedCustomRole(null)
			}
		},
		(error) => {
			console.log('Error on get permission list based on role', error)
		},
		false,
		false
	)

	const onPermissionChange = (permission: Permission, event: ChangeEvent<HTMLInputElement>) => {
		setSelectedPermissionGroup((permissionGroup) => {
			if (permissionGroup) {
				const clonedPermissionGroup = customDeepClone(permissionGroup) as RolePermissions
				const updatedPermissions = updatePermissionsItems(
					clonedPermissionGroup.permissions,
					permission.id,
					event.target.checked
				)
				return {
					...clonedPermissionGroup,
					permissions: updatedPermissions,
				}
			}
			return null
		})
	}

	// on add role success
	const onAddPermissionSuccess = (item: RolePermissions) => {
		setAddedCustomRole(item)
		getPermissionGroupsQuery.refetch()
	}

	// on error
	const onError = (message: string) => {
		setToastMessage({ message, variant: AlertVariant.ERROR, show: true })
	}

	// on delete custom role
	const onDeleteClick = () => {
		setShowWarning(false)
		if (deleteCustomRole) {
			deleteCustomPermission(
				deletePermissionMutation,
				deleteCustomRole?.id,
				(permissionId: string) => {
					setDeleteCustomRole(null)
					setSelectedPermissionGroup(null)
					getPermissionGroupsQuery.refetch()
				},
				onError
			)
		}
	}

	// on submission
	const updatePermissionChanges = () => {
		if (selectedPermissionGroup) {
			const clonedPermissionGroup = customDeepClone(selectedPermissionGroup) as RolePermissions
			const updatePermissionFormItems = convertListToPermissionForm(clonedPermissionGroup.permissions)
			const updatePermissionListForm: PermissionListForm = {
				id: clonedPermissionGroup.id,
				permissionRoleName: clonedPermissionGroup.permissionRoleName,
				permissionRoleDescription: clonedPermissionGroup.permissionRoleDescription,
				rolePermissions: updatePermissionFormItems,
			}

			updateCustomPermission(
				updatePermissionMutation,
				updatePermissionListForm,
				({ message }) => {
					setToastMessage({ message, variant: AlertVariant.SUCCESS, show: true })
					getPermissionGroupsQuery.refetch()
				},
				onError
			)
		}
	}

	useEffect(() => {
		const fetchTranslation = async () => {
			try {
				const translations = await Promise.all([
					jTranslationText({
						text: 'Permission Group Name',
						typeCase: 'pascal',
						translationContext,
					}),
					jTranslationText({
						text: 'Permission Group Description',
						typeCase: 'pascal',
						translationContext,
					}),
				])
				setTranslatedText({
					permissionName: translations[0] ?? 'Permission Group Name',
					permissionDesc: translations[1] ?? 'Permission Group Description',
				})
			} catch {
				setTranslatedText({
					permissionName: 'Permission Group Name',
					permissionDesc: 'Permission Group Description',
				})
			}
		}
		fetchTranslation()
	}, [targetLanguage])

	useEffect(() => {
		const selectedPermissions = selectedPermissionGroup?.permissions
		const permissionListData = permissionListResponse?.data.data as PermissionList[]
		if (permissionListData && selectedPermissions) {
			const permissionList = simplifyPermissions(permissionListData)
			const updatedPermissions = updateFullPermissions(permissionList, selectedPermissions)
			setSelectedPermissionGroup(
				(permissionGroup) => ({ ...permissionGroup, permissions: updatedPermissions } as RolePermissions)
			)
		}
	}, [permissionListResponse?.data, selectedPermissionGroup?.id, clearChanges])

	useEffect(() => {
		getPermissionGroupsQuery.refetch()
	}, [])

	if (getPermissionGroupsQuery.isLoading) {
		return <Loader />
	}

	return (
		<>
			<ToastAlert
				data-testid="toast"
				message={toastMessage.message}
				onClose={() => setToastMessage(toastMessageInitialData)}
				show={toastMessage.show}
				variant={toastMessage.variant}
			/>

			<WarningModal
				show={showWarning}
				title={DELETE_WARNING}
				onHide={() => {
					setDeleteCustomRole(null)
					setShowWarning(false)
				}}
				callback={onDeleteClick}
			/>

			<AddCustomPermission
				data-testid="add-custom-permission"
				show={showAddPermissionForm}
				onHide={setShowAddPermissionForm}
				addPermissionMutation={addPermissionMutation}
				onAddPermissionSuccess={onAddPermissionSuccess}
				onError={onError}
			/>

			<div className="page-title">
				<h5>
					<JTranslation typeCase="capitalize" text={'Manage Permissions'} />
				</h5>
			</div>

			<div className="col-md-5 col-lg-4  mb-3 h-100 " data-testid="user-roles">
				<div className="card  roles-link h-100">
					<div className="card-body h-100 over-fl-at mb-3 pt-0">
						<div className="roles-sticky-header py-3">
							{defaultPermissionGroups.length > 0 && (
								<>
									<div className="d-flex align-items-center mb-2">
										<div className="flex-grow-1 ">
											<h5 className="d-flex align-items-center card-title mb-0">
												<JTranslation typeCase="pascal" text={'Default Permissions'} />
											</h5>
										</div>
										<div className=" flex-grow-0 "></div>
									</div>
									<ul>
										{defaultPermissionGroups.map((item) => (
											<li
												role={selectedPermissionGroup?.id === item.id ? '' : "button"}
												data-testid={item.id}
												key={item.id}
												className={item.id === selectedPermissionGroup?.id ? 'active' : ''}
												onClick={() => {
													if(item.id !== selectedPermissionGroup?.id) {
														setSelectedPermissionGroup(item)														
													}
												}}
											>
												<JTranslation typeCase="pascal" text={item.permissionRoleName} />
											</li>
										))}
									</ul>
								</>
							)}

							<div className="d-flex align-items-center mt-3 ">
								<div className="flex-grow-1 ">
									<h5 className="d-flex align-items-center card-title mb-0">
										<JTranslation typeCase="pascal" text={'Custom Permissions'} />
									</h5>
								</div>
								<div className=" flex-grow-0 ">
									<button
										type="button"
										data-testid="add-permission-group"
										// disabled={
										// 	!checkPermission(
										// 		queryClient,
										// 		PermissionKeys.MANAGE_ROLES,
										// 		SubPermissionKeys.ADD
										// 	)
										// }
										className="btn btn-custom-primary-outline"
										onClick={() => {
											// if (
											// 	checkPermission(
											// 		queryClient,
											// 		PermissionKeys.MANAGE_ROLES,
											// 		SubPermissionKeys.ADD
											// 	)
											// ) {
											// 	if (itemUnchanged()) {
											setShowAddPermissionForm(true)
											// 	} else {
											// 		setUserNextOperation(UserOperations.ADD_CUSTOM_ROLE)
											// 		showProceedWarning()
											// 	}
											// }
										}}
									>
										<JTranslation typeCase="pascal" text={ADD} />
									</button>
								</div>
							</div>
						</div>

						<div className="">
							<ul>
								{nonDefaultPermissionGroups.map((item) => (
									<li
										key={item.id}
										className={item.id === selectedPermissionGroup?.id ? 'd-flex active' : 'd-flex'}
										role={item.id !== selectedPermissionGroup?.id ? "button" : ""}
									>
										<div
											role={item.id !== selectedPermissionGroup?.id ? "button" : ""}
											data-test-filed={item.id}
											key={item.id}
											className={`flex-grow-1 ${item.id === selectedPermissionGroup?.id ? 'active' : ''}`}
											onClick={() => {
												if(item.id !== selectedPermissionGroup?.id) {
													setSelectedPermissionGroup(item)
												}
											}}
										>
											{capitalize(item.permissionRoleName ?? '')}
										</div>
										<div className="flox-grow-0">
											<span
											// className={
											// 	(!checkPermission(
											// 		queryClient,
											// 		PermissionKeys.MANAGE_ROLES,
											// 		SubPermissionKeys.DELETE
											// 	) && ' disabled')
											// }
											>
												<i
													data-testid={item.id + '#delete'}
													onClick={(e) => {
														e.preventDefault()
														// if (
														// 	checkPermission(
														// 		queryClient,
														// 		PermissionKeys.MANAGE_ROLES,
														// 		SubPermissionKeys.DELETE
														// 	)
														// ) {
														setDeleteCustomRole(item)
														setShowWarning(true)
														// }
													}}
													className="ri-delete-bin-6-line"
												></i>
											</span>
										</div>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="col-md-7 col-lg-8  h-100">
				<div className="card  roles-forms  h-100 ">
					<div className="card-body h-100  ">
						<div className="h-100 ">
							<div className=" h-100 p-1 over-fl-at pb-6">
								{selectedPermissionGroup && (
									<>
										<div
											className="col-md-12"
											data-testid={selectedPermissionGroup.id + '#selected'}
										>
											<div className="mb-3">
												<input
													type="text"
													className="form-control"
													id="roleName"
													// disabled={
													// 	selectedPermissionGroup?.isDefault ||
													// 	!checkPermission(
													// 		queryClient,
													// 		PermissionKeys.MANAGE_ROLES,
													// 		SubPermissionKeys.EDIT
													// 	)
													// }
													data-testid="role-name-input"
													placeholder={translationText.permissionName}
													autoComplete="off"
													onChange={(e) => {
														if (selectedPermissionGroup && e.target.value) {
															const newItem = {
																...selectedPermissionGroup,
																permissionRoleName: e.target.value,
															}
															setSelectedPermissionGroup(newItem)
														}
													}}
													value={selectedPermissionGroup?.permissionRoleName}
												/>
											</div>
											<div className="mb-3">
												<textarea
													className="form-control"
													id="exampleFormControlTextarea1"
													placeholder={translationText.permissionDesc}
													// disabled={
													// 	!checkPermission(
													// 		queryClient,
													// 		PermissionKeys.MANAGE_ROLES,
													// 		SubPermissionKeys.EDIT
													// 	)
													// }
													data-testid="role-desc-input"
													onChange={(e) => {
														if (selectedPermissionGroup && e.target.value) {
															const newItem = {
																...selectedPermissionGroup,
																permissionRoleDescription: e.target.value,
															}
															setSelectedPermissionGroup(newItem)
														}
													}}
													value={selectedPermissionGroup?.permissionRoleDescription}
													rows={4}
												></textarea>
											</div>
										</div>
										<PermissionFeatureList
											permissions={selectedPermissionGroup?.permissions || []}
											onPermissionChange={onPermissionChange}
										/>
									</>
								)}
							</div>
							<div className="save-btn-section shadow roles-btn-group">
								<button
									className="btn btn-custom-primary-outline"
									type="button"
									data-testid="clear-button"
									// disabled={
									// 	selectedItem === undefined ||
									// 	!checkPermission(
									// 		queryClient,
									// 		PermissionKeys.MANAGE_ROLES,
									// 		SubPermissionKeys.EDIT
									// 	)
									// }
									onClick={() => {
										// if (
										// 	checkPermission(
										// 		queryClient,
										// 		PermissionKeys.MANAGE_ROLES,
										// 		SubPermissionKeys.EDIT
										// 	)
										// ) {
										const updatedPermissionGroup = permissionGroups?.find(
											(permissionGroup) => permissionGroup.id === selectedPermissionGroup?.id
										) as RolePermissions
										if (updatedPermissionGroup) {
											setSelectedPermissionGroup(updatedPermissionGroup)
											setClearChanges((changes) => changes + 1)
										}
										// }
									}}
								>
									<JTranslation typeCase="pascal" text={CLEAR} />
								</button>
								<button
									className="btn btn-custom-primary"
									type="button"
									data-testid="save-button"
									// disabled={
									// 	selectedItem === undefined ||
									// 	selectedItem?.roleName?.trim().length === 0 ||
									// 	itemUnchanged() ||
									// 	!checkPermission(
									// 		queryClient,
									// 		PermissionKeys.MANAGE_ROLES,
									// 		SubPermissionKeys.EDIT
									// 	)
									// }
									onClick={(e) => {
										e.preventDefault()
										updatePermissionChanges()
									}}
								>
									<JTranslation typeCase="pascal" text={SAVE} />
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default withSidebar(PermissionGroups)
