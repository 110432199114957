import { useEffect, useState, useContext } from 'react'
import { FnBCtx } from '../../../context/MoveItemCtxProvider'
import { getScheduleMenu, initialScheduleValue, addUpdateMenuSchedule, replaceSelectedHoursWithEmptyArray, disableMenuSchedule } from '../../../helpers/menuViewHelper'
import { initialScheduleMenu } from '../../../context/defaultContextValues'
import { MENU_SCHEDULE, MENU_SCHEDULE_HELP, CANCEL, SAVE } from '../../../constants/strings'
import { Offcanvas, OverlayTrigger } from 'react-bootstrap'
import { popover } from '../../../helpers/utils'
import { ScheduleMenuId, MenuSchedule, DisableMenuSchedule } from '../../../constants/staticTypes'
import { useQueryClient } from 'react-query'
import MenuScheduleComponent from './MenuScheduleComponent'
import useMutationHook from '../../../hooks/useMutationHook'
import { JTranslation } from '../../../helpers/jTranslate'
import MlxPopover from '../../common/MlxPopover'

type Props = {
    handleClose: () => void
    show: boolean
    displayToast: (message: string, variant: string) => void
}

function FnBMenuSchedule({ show, handleClose, displayToast }: Props) {
    const queryClient = useQueryClient()
    const scheduleMutation = useMutationHook(queryClient, true)
    // CONTEXT VARIABLES
    const { scheduleMenuItem, setScheduleMenuItem } = useContext(FnBCtx)
    // STATE VARIABLE
    const [isDaily, setIsDaily] = useState(false)
    const [currentSchedule, setCurrentSchedule] = useState<MenuSchedule>(initialScheduleValue)
    const [disableSave, setDisableSave] = useState(false)
    // LOCAL VARIABLE
    let scheduleCopy: MenuSchedule

    const onSave = () => {
        if (isDaily) {
            const { id } = currentSchedule
            const param: DisableMenuSchedule = { id, scheduleType: "always" }
            disableMenuSchedule(param, scheduleMutation, onAddUpdateSuccess, onError)
        } else {
            addUpdateMenuSchedule(replaceSelectedHoursWithEmptyArray(currentSchedule), scheduleMutation, onAddUpdateSuccess, onError)
        }
    }

    const onClose = () => {
        handleClose()
        setScheduleMenuItem(initialScheduleMenu)
        setCurrentSchedule(initialScheduleValue)
        scheduleCopy = initialScheduleValue
    }

    const onAddUpdateSuccess = (message: string, variant: string) => {
        displayToast(message, variant)
        setTimeout(() => { onClose() }, 500)
    }

    const onSuccess = (schedule: MenuSchedule) => {
        scheduleCopy = schedule
        // unnecessary values removed from schedule data
        delete scheduleCopy.isActive
        delete scheduleCopy.parentCategoryId
        delete scheduleCopy.rowStatus
        delete scheduleCopy.scheduleUpdatedAt
        delete scheduleCopy.specificDatesSchedules

        let modifiedPeriodicSchedules = initialScheduleValue.periodicSchedules
        // Check if the number of keys in the "periodicSchedules" object of the "schedule" variable is equal to zero.
        // If the condition is true, it indicates that no schedule is set.
        // In such cases, we need to initialize the schedule component with default data to ensure it has a valid starting state.
        if (Object.keys(schedule.periodicSchedules).length === 0) {
            scheduleCopy.periodicSchedules = {
                monday: {
                    isActive: false,
                    selectedHours: [{ startTime: null, endTime: null }],
                },
                tuesday: {
                    isActive: false,
                    selectedHours: [{ startTime: null, endTime: null }],
                },
                wednesday: {
                    isActive: false,
                    selectedHours: [{ startTime: null, endTime: null }],
                },
                thursday: {
                    isActive: false,
                    selectedHours: [{ startTime: null, endTime: null }],
                },
                friday: {
                    isActive: false,
                    selectedHours: [{ startTime: null, endTime: null }],
                },
                saturday: {
                    isActive: false,
                    selectedHours: [{ startTime: null, endTime: null }],
                },
                sunday: {
                    isActive: false,
                    selectedHours: [{ startTime: null, endTime: null }],
                },
            }
        } else {
            /// Loop through the API response and check if the returned array is empty.
            // If the array is empty, it means no data is available for the selected time period.
            // In such cases, we add the startTime and endTime to the "selectedHours" of "periodicSchedules" to indicate the absence of data.
            Object.keys(schedule.periodicSchedules).forEach((day) => {
                let selectedHoursLength = schedule.periodicSchedules[day].selectedHours.length
                if (selectedHoursLength) {
                    modifiedPeriodicSchedules[day] = schedule.periodicSchedules[day]
                } else {
                    modifiedPeriodicSchedules[day] = {
                        isActive: schedule.periodicSchedules[day].isActive,
                        selectedHours: [{ startTime: null, endTime: null }]
                    }
                }
            })

            scheduleCopy.periodicSchedules = modifiedPeriodicSchedules
        }
        setCurrentSchedule(scheduleCopy)
    }

    const onError = (error: string, variant: string) => {
        displayToast(error, variant)
    }

    // effect to make the API call to get current scheduled data
    useEffect(() => {
        const params: ScheduleMenuId = { id: scheduleMenuItem.id }
        if (scheduleMenuItem.id) {
            getScheduleMenu(params, scheduleMutation, onSuccess, onError)
        }
        // clean-up
        return () => {
            setCurrentSchedule({
                ...currentSchedule,
                periodicSchedules: {
                    monday: {
                        isActive: false,
                        selectedHours: [{ startTime: null, endTime: null }],
                    },
                    tuesday: {
                        isActive: false,
                        selectedHours: [{ startTime: null, endTime: null }],
                    },
                    wednesday: {
                        isActive: false,
                        selectedHours: [{ startTime: null, endTime: null }],
                    },
                    thursday: {
                        isActive: false,
                        selectedHours: [{ startTime: null, endTime: null }],
                    },
                    friday: {
                        isActive: false,
                        selectedHours: [{ startTime: null, endTime: null }],
                    },
                    saturday: {
                        isActive: false,
                        selectedHours: [{ startTime: null, endTime: null }],
                    },
                    sunday: {
                        isActive: false,
                        selectedHours: [{ startTime: null, endTime: null }],
                    },
                }
            })

        }
    }, [scheduleMenuItem])

    return (
        <Offcanvas
            show={show}
            placement="end"
            backdrop="static"
            // // responsive="xl"
            className="custom-offcanvas"
            onHide={onClose}
        >
            <Offcanvas.Header closeButton className="border-bottom">
                <Offcanvas.Title className="d-flex justify-content-center align-items-center">
                    <JTranslation typeCase="pascal" text={MENU_SCHEDULE} /> {`${scheduleMenuItem.menuName}`}
                    <MlxPopover data={{title:MENU_SCHEDULE, body:MENU_SCHEDULE_HELP}} />
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <MenuScheduleComponent
                    currentSchedule={currentSchedule}
                    setCurrentSchedule={setCurrentSchedule}
                    scheduleMenuInfo={scheduleMenuItem}
                    setDisableSave={setDisableSave}
                    isDaily={isDaily}
                    setIsDaily={setIsDaily}
                />
            </Offcanvas.Body>
            <div className="save-btn-section shadow save-btn-absolute">
                <button className="btn btn-custom-primary-outline" type="button" onClick={onClose}>
                    <JTranslation typeCase="pascal" text={CANCEL} />
                </button>
                <button
                    className="btn btn-custom-primary"
                    type="button"
                    disabled={disableSave}
                    onClick={() => {
                        // api call to add / update menu schedule
                        onSave()
                    }}
                >
                    <JTranslation typeCase="pascal" text={SAVE} />
                </button>
            </div>
        </Offcanvas>
    )
}

export default FnBMenuSchedule
