import { CreateCustomMenu } from '../../constants/staticTypes'
import { CustomMenuListDataGrid } from './CustomMenuListDataGrid.1'

export type Props = {
    customMenuList: CreateCustomMenu[]
    loading: boolean
    onSearchTrigger: (searchKey: string) => void
    onEditClick: (event: CreateCustomMenu) => void
    onDeleteClick: (event: CreateCustomMenu) => void
    editCustomMenu: CreateCustomMenu | undefined
}

export default CustomMenuListDataGrid
