import { useEffect, useState } from 'react'
import { Accordion } from 'react-bootstrap'
import { AddAssignmentCard, AddCardButton, Availability, AvailableTime, ScheduleData, Staff, UnavailableDay, addOrUpdateScheduleApi, days, getDateOfWeekday, initialAddOrUpdateSchedule, initialDataShiftAssignment, isDateInRange, isDateInSelectedWeek, optionTemplate, selectionTemplate, validationSchema } from '../../helpers/workTimeScheduleHelper'
import { Card } from 'primereact/card'
import { DATE_FORMAT } from '../../constants/constants'
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown'
import { ErrorMessage, Field, FieldArray, FieldProps, Form, Formik } from 'formik'
import { formatTimeToAmPm } from '../../helpers/utils'
import { JTranslation } from '../../helpers/jTranslate'
import { PrimeReactDropDown, ToastMessageProps, ShiftAssignment, ShiftDropDown, AvailabilityForFE, DayOfWeek, Roles } from '../../constants/staticTypes'
import { SAVE, CANCEL, ROLE, SELECT_ROLE, NO_ROLES_ASSIGNED, HOLIDAY, MANDATORY, TIME_OFF, BUSINESS_CLOSE } from '../../constants/strings'
import { STAFFS_WITH_SCHEDULES_AND_SHIFT } from '../../constants/queryKeys'
import { useQueryClient } from 'react-query'
import dayjs from 'dayjs'
import Offcanvas from 'react-bootstrap/Offcanvas'
import ShiftAssignmentCard from './ShiftAssignmentCard'
import useMutationHook from '../../hooks/useMutationHook'
import { Badge } from 'primereact/badge'

type Props = {
	roles: PrimeReactDropDown[]
	scheduleId: string
	selectedDay: string
	selectedEmployeeName: string,
	selectedWeek: dayjs.Dayjs
	setShiftSideBar: React.Dispatch<React.SetStateAction<boolean>>
	setToastMessage: React.Dispatch<React.SetStateAction<ToastMessageProps>>
	shifts: ShiftDropDown[]
	shiftSideBar: boolean,
	staffDataWithSchedule: Staff | null,
	staffsWithSchedule: ScheduleData | null
}

type GetStaffAvailability = {
	day: string
	availabilities: Availability | undefined
}

type InputRole = {
	isBd: boolean;
	role: string;
	roleId: string;
	endTime: string;
	isClose: boolean;
	shiftName: string;
	startTime: string;
};

function ShiftScheduleSideBar({
	roles,
	scheduleId,
	selectedDay,
	selectedEmployeeName,
	selectedWeek,
	setShiftSideBar,
	setToastMessage,
	shifts,
	shiftSideBar,
	staffDataWithSchedule,
	staffsWithSchedule
}: Props) {
	const [initialValues, setInitialValues] = useState<ShiftAssignment>(initialDataShiftAssignment);
	const onClose = () => setShiftSideBar(false)
	const queryClient = useQueryClient()
	const scheduleMutation = useMutationHook(queryClient, true)
	const jobRoles = staffDataWithSchedule?.jobRoles.map((role: any) => role?.jobRole?.jobRoleName);
	const tenantStaffId = staffDataWithSchedule?.id
	const availabilities = staffDataWithSchedule?.availabilities
	const unavailableDays = staffDataWithSchedule?.unavailableDays ?? []
	const { holidays, mandatoryDays } = staffsWithSchedule!

	function formatTimeRanges(input: AvailableTime[]): string {
		return input.map(range => `(${formatTime(range.from)} to ${formatTime(range.to)})`).join(', ');
	}

	function formatTime(time: string): string {
		// Ensure the time format is consistent with AM/PM capitalization and no extra spaces
		return time.toUpperCase().replace(/\s*(AM|PM)/, ' $1');
	}

	const getStaffAvailabilityForTheDay = ({ day, availabilities }: GetStaffAvailability) => {
		if (!availabilities || Object.keys(availabilities).length === 0) {
			return <strong>Not Set</strong>;
		}

		const staffAvailabilities = availabilities[day]?.filter((availability) => {
			const { isAvailable, weekday, startDate, endDate } = availability;
			const isDateCorrect = isDateInSelectedWeek({ startDate, endDate, selectedWeek });

			return isAvailable && day === weekday && isDateCorrect;
		});

		if (!staffAvailabilities || staffAvailabilities.length === 0) {
			return <strong>Not Available</strong>;
		}

		const availabilityData = staffAvailabilities.filter((availability, index) => {
			const { isAvailable, actionStatus, weekday } = availability;

			if (day === weekday && actionStatus === "APPROVED" && isAvailable) {
				return availability;
			}
		});

		const availabilityElements = availabilityData.map((availability, index) => {
			const { isRecurring, isAllDay, availableTimes } = availability;

			if (availabilityData.length > 1) {
				// temporary availability
				if (!isRecurring) {
					if (availableTimes?.length) {
						const time = formatTimeRanges(availableTimes);
						return <strong key={index}>{time}</strong>;
					}
					if (isAllDay) {
						return <strong key={index}>All Day</strong>;
					}
				}
			} else {
				// regular availability
				if (availableTimes?.length) {
					const time = formatTimeRanges(availableTimes);
					return <strong key={index}>{time}</strong>;
				}
				if (isAllDay) {
					return <strong key={index}>All Day</strong>;
				}
			}
		})

		if (availabilityElements.some((element) => element !== null)) {
			return availabilityElements;
		}

		return <strong>Not Available</strong>;
	};

	// assigning API data to form inputs
	useEffect(() => {
		if (staffDataWithSchedule) {
			// Function to convert date to DayOfWeek
			const getDayOfWeek = (dateString: string): string => {
				const dayIndex = dayjs(dateString).day()
				const days: DayOfWeek[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
				return days[dayIndex]
			}

			const processRoles = (input: InputRole[]): Roles[] => {
				const roleMap: { [key: string]: Roles } = {};
				let rolesArray: Roles[] = [];

				input.forEach(item => {
					const { role, roleId, isBd, isClose, shiftName, startTime, endTime } = item;

					if (!roleMap[roleId]) {
						roleMap[roleId] = {
							name: role,
							code: roleId,
							shifts: []
						};
					}

					roleMap[roleId].shifts.push({
						name: shiftName,
						code: shiftName,
						startTime: dayjs(startTime, 'hh:mm A').format('HH:mm:ss'),
						endTime: dayjs(endTime, 'hh:mm A').format('HH:mm:ss'),
						close: isClose,
						bd: isBd
					});
				});

				rolesArray = Object.values(roleMap);
				return rolesArray
			}

			// Populate ShiftAssignment
			const populateShiftAssignment = (scheduleData: Staff): ShiftAssignment => {
				const dateKeys = scheduleData && scheduleData.shifts ? Object.keys(scheduleData.shifts) : []

				const availability: AvailabilityForFE[] = days.map((day) => {
					const dateKey = dateKeys.find((key) => getDayOfWeek(key) === day)
					// @ts-ignore
					const roles: Roles[] = dateKey
						? scheduleData.shifts[dateKey].map((shift) => shift.assignedShifts) || []
						: []
					const rolesArray = processRoles(roles as unknown as InputRole[])

					return {
						day,
						roles: rolesArray,
						availabilities: {},
						unavailableDays: [],
						holidays: [],
						mandatoryDays: [],
					}
				})

				return { availability }
			}

			const formattedData = populateShiftAssignment(staffDataWithSchedule)
			setInitialValues(formattedData)
		}
	}, [staffDataWithSchedule])

	const onSuccess = (message: string, variant: string) => {
		setToastMessage({ message, variant, show: true })
		onClose()
		queryClient.refetchQueries([STAFFS_WITH_SCHEDULES_AND_SHIFT])
	}

	// on api error
	const onError = (message: string, variant: string) => {
		setToastMessage({ message, variant, show: true })
	}

	const getAvailabilityText = (
		availabilities: JSX.Element | (JSX.Element | null)[] | undefined
	): string => {
		if (!availabilities) {
			return ''
		}

		const availabilityElements = Array.isArray(availabilities)
			? availabilities.filter((element): element is JSX.Element => element !== null)
			: [availabilities]

		const availabilityTexts = availabilityElements
			.map((element) => element?.props?.children)
			.filter((text) => text !== undefined)

		return availabilityTexts.join(', ').toLowerCase()
	}

	const getUnavailabilityBadge = ({ unavailability }: { unavailability: UnavailableDay[] }) => {
		let badges: JSX.Element[] = []
		unavailability.forEach((timeOff) => {
			if (timeOff.type === 'time-off') {
				badges.push(<Badge value={`${TIME_OFF} ${formatTimeToAmPm(timeOff.startTime)} to ${formatTimeToAmPm(timeOff.endTime)}`} severity='danger' className='mb-2 max-height custom-badge-danger me-2 time-off' />)
			}
		})
		return badges
	}

	const getAvailabilityBadgeColor = (availabilityText: string): string => {
		switch (availabilityText) {
			case 'all day':
				return 'staff-availability-chip text-nowrap available-all-day'
			case 'not available':
				return 'staff-availability-chip text-nowrap not-available'
			default:
				return 'staff-availability-chip text-nowrap'
		}
	}

	const getAvailabilityInfoIconClass = (availabilityText: string): string => {
		switch (availabilityText) {
			case 'all day':
				return 'ri-information-line staff-availability-info available-all-day'
			case 'not available':
				return 'ri-information-line staff-availability-info not-available'
			default:
				return 'ri-information-line staff-availability-info'
		}
	}

	const getAccordionHeaderClass = ({ isHolidays, isMandatory }: { isMandatory: boolean, isHolidays: boolean }) => {
		if (isMandatory) {
			return "mandatory-bg"
		} else if (isHolidays) {
			return "holiday-bg"
		} else {
			return ""
		}
	}

	const getIsOpenForBusiness = (day: string) => {
		const date = getDateOfWeekday({ day, selectedWeek, format: 'YYYY-MM-DD' })
		const holiday = holidays.find((holiday) => isDateInRange({ date: date, startDate: holiday.startDate, endDate: holiday.endDate }))
		return holiday ? holiday.isOpenForBusiness : false
	};

	// Main function to process form data and send to API
	const postFormDataToApi = (formData: ShiftAssignment) => {
		// Initialize the schedule data structure for API submission
		const scheduleDataForApi: any = {
			...initialAddOrUpdateSchedule,
			scheduleId,
			tenantStaffId,
			assignShiftsByDate: {}
		};

		const { availability } = formData;

		// Iterate over each availability entry in the form data
		availability.forEach(({ day, roles }) => {
			// Get the formatted date for the given day
			const date = getDateOfWeekday({ day: day, selectedWeek, format: 'YYYY-MM-DD' });

			// If there are roles for this day, initialize an array for the date in the schedule data
			if (roles.length) {
				scheduleDataForApi.assignShiftsByDate[date] = [];
			}

			// Iterate over each role for the day
			roles.forEach(role => {
				// Map the shifts for the role to the required format
				const shifts = role.shifts.map(shift => ({
					shiftName: shift.name,
					startTime: formatTimeToAmPm(shift.startTime),
					endTime: formatTimeToAmPm(shift.endTime),
					role: role.name,
					roleId: role.code,
					isBd: shift.bd || false,
					isClose: shift.close || false,
				}));

				// Add the formatted shifts to the date in the schedule data
				scheduleDataForApi.assignShiftsByDate[date].push(...shifts);
			});
		});

		// Make the API call to add or update the schedule
		addOrUpdateScheduleApi(scheduleMutation, scheduleDataForApi, onSuccess, onError);
	};

	return (
		<Offcanvas
			show={shiftSideBar}
			onHide={() => onClose()}
			backdrop="static"
			// responsive="xl"
			placement="end"
			className="custom-offcanvas"
		>
			<Offcanvas.Header closeButton>
				<Offcanvas.Title>
					<h4><JTranslation text={selectedEmployeeName} /></h4>
					<h6><JTranslation text={jobRoles?.length ? jobRoles.join(" | ") : NO_ROLES_ASSIGNED} /></h6>
					<strong className='mb-0 h6'><JTranslation
						text={`Work schedule for ${dayjs(selectedWeek).startOf('week').format(DATE_FORMAT)} - ${dayjs(selectedWeek).endOf('week').format(DATE_FORMAT)}`}
					/></strong>
				</Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body>
				<Formik
					onSubmit={postFormDataToApi}
					onReset={() => setInitialValues(initialDataShiftAssignment)}
					initialValues={initialValues}
					validationSchema={validationSchema}
					validateOnChange={true}
				>
					{({ values, errors, touched }) => (
						<Form>
							<div className="row">
								<div className="col-md-12 col-lg-12 mb-3">
									<Accordion className="availability-form-accordion" defaultActiveKey={selectedDay}>
										<FieldArray
											name="availability"
											render={() => {
												return (
													<div>
														{values.availability.map(
															(weekDay, availabilityIndex) => {
																const rolesExist = errors?.availability?.[availabilityIndex];
																const touchedExist = touched?.availability?.[availabilityIndex];
																const availability = getStaffAvailabilityForTheDay({
																	day: weekDay.day,
																	availabilities: availabilities
																})
																// @ts-ignore
																const availabilityText = getAvailabilityText(availability)
																const date = getDateOfWeekday({ day: weekDay.day, selectedWeek, format: 'YYYY-MM-DD' })
																const isHolidays = holidays.filter((holiday) => isDateInRange({ date: date, startDate: holiday.startDate, endDate: holiday.endDate })).length > 0
																const isMandatory = mandatoryDays.filter((mandatoryDay) => isDateInRange({ date: date, startDate: mandatoryDay.startDate, endDate: mandatoryDay.endDate })).length > 0
																const isUnavailable = unavailableDays.filter((unavailableDay) => isDateInRange({ date: date, startDate: unavailableDay.startDate, endDate: unavailableDay.endDate })).length > 0
																const unavailabilityArray = unavailableDays.filter((unavailableDay) => isDateInRange({ date: date, startDate: unavailableDay.startDate, endDate: unavailableDay.endDate }))
																const unavailability = unavailabilityArray.filter((item) => (item.actionStatus === 'APPROVED' || item.actionStatus === 'PENDING'))

																// Check if rolesExist and touchedExist are not undefined
																const isError = rolesExist && touchedExist;

																// Apply style conditionally
																const accordionStyle = isError ? { border: '1px solid red' } : undefined;
																return (
																	<FieldArray name={`availability[${availabilityIndex}].roles`} key={availabilityIndex}
																		render={(availabilityHelper) => {
																			return (
																				<Accordion.Item
																					eventKey={weekDay.day}
																					style={accordionStyle}
																					className={isHolidays && !getIsOpenForBusiness(weekDay.day) ? "disabled" : ""}
																				>
																					<Accordion.Header className={`accordion-custom-schedule ${getAccordionHeaderClass({ isHolidays, isMandatory })}`}>
																						<>
																							{
																								<span className='workschedule-date'>
																									<JTranslation
																										typeCase="pascal"
																										text={`${weekDay.day
																											} (${getDateOfWeekday({
																												selectedWeek,
																												day: weekDay.day,
																											})})`}
																									/>
																								</span>
																							}

																							<div className={getAvailabilityBadgeColor(availabilityText)}>
																								<i className={getAvailabilityInfoIconClass(availabilityText)}></i>
																								Availability: {availability}
																							</div>

																							{isHolidays && !getIsOpenForBusiness(weekDay.day) ? <Badge value={BUSINESS_CLOSE} severity="danger" /> : ""}
																							{isUnavailable && <Badge value={TIME_OFF} severity='danger' className='m-1 custom-badge-danger' />}
																						</>
																					</Accordion.Header>
																					<Accordion.Body>
																						{isHolidays && <Badge value={HOLIDAY} severity='danger' className='m-1 custom-badge-danger mb-3' />}
																						{isMandatory && <Badge value={MANDATORY} severity='warning' className='m-1 custom-badge-primary mb-3' />}
																						{isUnavailable && getUnavailabilityBadge({ unavailability })}
																						{weekDay?.roles?.length === 0 && AddAssignmentCard({ arrayHelpers: availabilityHelper })}

																						{weekDay.roles.map((role, roleIndex) => {
																							// @ts-ignore
																							const rolesExist = errors?.availability?.[availabilityIndex]?.roles?.[roleIndex];
																							const touchedExist = touched?.availability?.[availabilityIndex]?.roles?.[roleIndex];

																							// Check if rolesExist and touchedExist are not undefined
																							const isError = rolesExist && touchedExist;

																							// Apply style conditionally
																							const roleStyle = isError ? { border: '1px solid red' } : undefined;
																							return (
																								<FieldArray name={`availability.${availabilityIndex}.roles.${roleIndex}`} key={roleIndex}
																									render={() => {
																										return (
																											<Card key={roleIndex} className='mb-2 work-schedule-card'>

																												<div className="d-flex flex-column mb-3">
																													<strong>{ROLE}</strong>
																													<Field
																														name={`availability.${availabilityIndex}.roles.${roleIndex}`}
																													>
																														{({ field, form }: FieldProps) => (
																															<Dropdown
																																value={{
																																	code: field.value.code,
																																	name: field.value.name
																																}}
																																onChange={(e: DropdownChangeEvent) => {
																																	const { value } = e
																																	form.setFieldValue(field.name, { ...field.value, ...value })
																																}}
																																style={roleStyle}
																																options={roles}
																																optionLabel="name"
																																placeholder={SELECT_ROLE}
																																valueTemplate={selectionTemplate}
																																itemTemplate={optionTemplate}
																																className="w-full md:w-14rem"
																															/>
																														)}
																													</Field>
																													<ErrorMessage className="formik-error" name={`availability.${availabilityIndex}.roles.${roleIndex}.role`} component="div" />
																												</div>

																												<div className="d-flex flex-column mt-2">
																													{role.shifts?.map((shiftInfo, shiftIndex) => (
																														<FieldArray name={`availability[${availabilityIndex}].roles[${roleIndex}].shifts`} key={shiftIndex}
																															render={(shiftsHelper) => {
																																return (
																																	<ShiftAssignmentCard
																																		key={shiftIndex}
																																		shifts={shifts}
																																		errors={errors}
																																		touched={touched}
																																		availabilityIndex={availabilityIndex}
																																		roleIndex={roleIndex}
																																		shiftIndex={shiftIndex}
																																		arrayHelpers={shiftsHelper}
																																	/>
																																)
																															}}
																														/>
																													))}
																												</div>
																												<div className='d-flex justify-content-end'>
																													{/* <i className="ri-delete-bin-6-line" onClick={() => availabilityHelper.remove(roleIndex)}></i> */}
																													<div className=" delete-round" onClick={() => availabilityHelper.remove(roleIndex)}><i className="ri-delete-bin-line delete "></i></div>
																												</div>
																											</Card >

																										)
																									}}
																								/>
																							)
																						})}

																						<div className='mt-3 mb-2'>
																							{values.availability[availabilityIndex].day === weekDay.day && values.availability[availabilityIndex].roles.length
																								? AddCardButton({ arrayHelpers: availabilityHelper })
																								: null}
																						</div>
																					</Accordion.Body>
																				</Accordion.Item>
																			)
																		}}

																	/>
																)
															}
														)}
													</div>
												)
											}
											}
										/>
									</Accordion>
								</div>
							</div>
							<div className='save-btn-section shadow save-btn-absolute'>
								<button
									className="btn btn btn-custom-primary-outline"
									type="button"
									data-testid="cancel-btn"
									onClick={() => onClose()}								>
									<JTranslation typeCase="pascal" text={CANCEL} />
								</button>
								<button
									className="btn btn-custom-primary"
									type="submit"
									data-testid="save-btn">
									<JTranslation typeCase="pascal" text={SAVE} />
								</button>
							</div>
						</Form>
					)}
				</Formik>
			</Offcanvas.Body>
		</Offcanvas >
	)
}

export default ShiftScheduleSideBar
