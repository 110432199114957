import { useState } from 'react'
import { AlertVariant, toastMessageInitialData } from '../../constants/constants'
import { PostType, SocialMediaPlatforms, ToastMessageProps } from '../../constants/staticTypes'
import { routes } from '../../constants/routes'
import ToastAlert from '../../components/alert/ToastAlert'
import useShare from '../../hooks/useShare'
import { getDomainFromURL } from '../../helpers/utils'

interface ShareProps {
    urlSlug: string
    title: string
    postType: string
}

const SocialMediaShare = ({ urlSlug, title, postType }: ShareProps) => {
    const { share, isSupported } = useShare()
    const domainName = getDomainFromURL();
    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)

    const getRouteForSlug = () => {
        switch (postType) {
            case PostType.NEWS:
                return routes.news_in_detail
            case PostType.EVENT:
                return routes.events_in_detail
            case PostType.SURVEY:
                return routes.survey_guest
            default:
                return
        }
    }

    const URL_WITHOUT_PLATFORM = `${domainName}${getRouteForSlug()}/${urlSlug}`

    // Function to create the sharing URL
    const createShareLink = (platform: SocialMediaPlatforms): string => {
        const encodedUrl = encodeURIComponent(`${domainName}${getRouteForSlug()}/${urlSlug}?source=${platform}`)
        const encodedText = encodeURIComponent(title)

        switch (platform) {
            case 'twitter':
                return `https://twitter.com/intent/tweet?text=${encodedUrl}`
            case 'facebook':
                return `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`
            case 'linkedin':
                return `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`
            case 'instagram':
                return `https://www.instagram.com/sharer.php?url=${encodedUrl}`
            case 'email':
                return `mailto:?subject=${encodedText}&body=${encodedUrl}`
            case 'whatsapp':
                return `https://wa.me/?text=${encodedUrl}`
            default:
                return ''
        }
    }

    const shareData = {
        url: `${getRouteForSlug()}/${urlSlug}`,
    }

    // Function to open the sharing link
    const openNewTab = (platform: SocialMediaPlatforms) => { 
        const shareLink = createShareLink(platform)
        window.open(shareLink, '_blank')
    }

    // Function to handle the onClick event
    const handleCopyClick = async () => {
        try {
            await navigator.clipboard.writeText(URL_WITHOUT_PLATFORM)
            setToastMessage({ message: 'Copied to clipboard', show: true, variant: AlertVariant.SUCCESS })
        } catch (err) {
            setToastMessage({ message: 'Failed to copy to clipboard', show: true, variant: AlertVariant.ERROR })
            console.error('Failed to copy text: ', err)
        }
    }

    return (
        <div className="row">
            {/* TOAST MESSAGE COMPONENT */}
            <ToastAlert
                show={toastMessage.show}
                onClose={() => setToastMessage(toastMessageInitialData)}
                message={toastMessage.message}
                variant={toastMessage.variant}
            />
            <div className="col-md-12 d-flex flex-wrap">
                <div className="social_container d-flex flex-column align-items-center">
                    <button
                        className="btn social_media_button facebook"
                        onClick={() => openNewTab('facebook')}
                        data-testid="fb-btn"
                    >
                        <i className="ri-facebook-fill"></i>
                    </button>
                    <div className="social_name">Facebook</div>
                </div>
                <div className="social_container">
                    <button
                        className="btn social_media_button twitter"
                        onClick={() => openNewTab('twitter')}
                        data-testid="twitter-btn"
                    >
                        <i className="ri-twitter-x-fill"></i>
                    </button>
                    <div className="social_name">Twitter</div>
                </div>
                {/* <div className="social_container">
                    <button className="btn social_media_button insta" onClick={() => openNewTab('instagram')}>
                        <i className="ri-instagram-fill"></i>
                    </button>
                    <div className="social_name">Instagram</div>
                </div> */}
                <div className="social_container">
                    <button
                        className="btn social_media_button whatsapp"
                        onClick={() => openNewTab('whatsapp')}
                        data-testid="whatsapp-btn"
                    >
                        <i className="ri-whatsapp-fill"></i>
                    </button>
                    <div className="social_name">WhatsApp</div>
                </div>
                <div className="social_container">
                    <button
                        className="btn social_media_button"
                        onClick={() => openNewTab('email')}
                        data-testid="email-btn"
                    >
                        <i className="ri-mail-open-fill"></i>
                    </button>
                    <div className="social_name">Email</div>
                </div>
                <div className="social_container">
                    <button
                        className="btn social_media_button"
                        onClick={() => handleCopyClick()}
                        data-testid="copy-link-btn"
                    >
                        <i className="ri-file-copy-fill"></i>
                    </button>
                    <div className="social_name">Copy Link</div>
                </div>
                <div className="social_container">
                    <button
                        onClick={() => share(shareData)}
                        disabled={!isSupported}
                        className="btn social_media_button"
                        data-testid="share-link-btn"
                    >
                        <i className="ri-share-fill"></i>
                    </button>
                    <div className="social_name">App Share</div>
                </div>
            </div>
        </div>
    )
}

export default SocialMediaShare
