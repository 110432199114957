import { AlertVariant, HttpMethods } from "../constants/constants";
import { AxiosResponse } from "axios";
import { getErrorMessage } from "./utils";
import { Payload, ApiFolderAndFileDS, SaveFolderToDb, RenameFolderOnDb, RenameFileOnDb, MoveFileOnDb, MoveFolderOnDb, DeleteFolderOnDb } from "../constants/staticTypes";
import { UseMutationResult, QueryClient } from "react-query";
import HttpServiceHelper from "./httpServiceHelper";
import {
    fileManagementCreateOrDeleteFolder, fileManagementDeleteFile, fileManagementMoveFile,
    fileManagementMoveFolder, fileManagementRenameFile, fileManagementRenameFolder,
    fileManagementSearch, listFileManagement,
} from '../constants/apiEndPoints'


// get file management data structure
export const getUploadedFolderStructure = (queryClient: QueryClient) => {
    return HttpServiceHelper({
        url: listFileManagement,
        method: HttpMethods.GET,
        queryClient
    });
}

// create a new folder / sub folder
export const createNewFolder = (
    fileManagerMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    data: SaveFolderToDb,
    onError: (res: string, variant: string) => void) => {
    fileManagerMutation.mutate({
        url: fileManagementCreateOrDeleteFolder,
        method: HttpMethods.POST,
        data: data
    }, {
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    });
}

// get file management data structure
export const recallUploadedFolderStructure = (
    fileManagerMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onSuccess: (message: string, data: ApiFolderAndFileDS[]) => void,
    onError: (res: string, variant: string) => void) => {
    fileManagerMutation.mutate({
        url: listFileManagement,
        method: HttpMethods.GET,
    }, {
        onSuccess: (res) => onSuccess(res.data.message, res.data.data),
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    });
}

// search files and folders
export const searchFileAndFolder = (
    fileManagerMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    data: {
        searchTerm: string;
    },
    onSuccess: (message: string, data: ApiFolderAndFileDS[]) => void,
    onError: (res: string, variant: string) => void) => {
    fileManagerMutation.mutate({
        url: fileManagementSearch,
        method: HttpMethods.POST,
        data: data
    }, {
        onSuccess: (res) => onSuccess(res.data.message, res.data.data),
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    });
}


// rename folder / sub folder
export const renameFolderApi = (
    fileManagerMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    data: RenameFolderOnDb,
    onError: (res: string, variant: string) => void) => {
    fileManagerMutation.mutate({
        url: fileManagementRenameFolder,
        method: HttpMethods.PUT,
        data: data
    }, {
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    });
}

// rename folder / sub folder
export const renameFileApi = (
    fileManagerMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    data: RenameFileOnDb,
    onError: (res: string, variant: string) => void) => {
    fileManagerMutation.mutate({
        url: fileManagementRenameFile,
        method: HttpMethods.PUT,
        data: data
    }, {
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    });
}

// delete a new folder / sub folder
export const deleteFolderApi = (
    fileManagerMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    data: DeleteFolderOnDb,
    onError: (res: string, variant: string) => void) => {
    fileManagerMutation.mutate({
        url: fileManagementCreateOrDeleteFolder,
        method: HttpMethods.DELETE,
        data: data
    }, {
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    });
}

// delete a file
export const deleteFileApi = (
    fileManagerMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    data: {
        fileId: string | number;
    },
    onError: (res: string, variant: string) => void) => {
    fileManagerMutation.mutate({
        url: fileManagementDeleteFile,
        method: HttpMethods.DELETE,
        data: data
    }, {
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    });
}

// move file 
export const moveFileApi = (
    fileManagerMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    data: MoveFileOnDb,
    onError: (res: string, variant: string) => void) => {
    fileManagerMutation.mutate({
        url: fileManagementMoveFile,
        method: HttpMethods.POST,
        data: data
    }, {
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    });
}

// move folder 
export const moveFolderApi = (
    fileManagerMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    data: MoveFolderOnDb,
    onError: (res: string, variant: string) => void) => {
    fileManagerMutation.mutate({
        url: fileManagementMoveFolder,
        method: HttpMethods.POST,
        data: data
    }, {
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    });
}
