import { useContext, useEffect, useState } from 'react'
import 'react-phone-number-input/style.css'
import {
	CANCEL,
	PRICE,
	SAVE,
	UPDATE,
	EDIT_ADD_ON_GROUP,
	ADD_NEW_ADD_ON_GROUP,
	ADD_ON_GROUP_NAME,
	ASSOCIATED_ITEMS,
	ASSOCIATED_ITEM,
	ADD_ADD_ON,
} from '../../constants/strings'
import {
	Addon,
	AddonGroup,
	NewAddonGroup,
	SelectedMenusItem,
	SingleAddOnGroupResponse,
	SingleAddonGroup,
	ToastMessageProps,
} from '../../constants/staticTypes'
import { CommonCtx } from '../../context/CommonCtxProvider'
import { useQueryClient } from 'react-query'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Select from 'react-select'
import useMutationHook from '../../hooks/useMutationHook'
import {
	createNewAddOnGroup,
	fetchSingleAddOnGroup,
	initialAddonGroupFormData,
	updateAddOnGroup,
} from '../../helpers/manageAddOnsGroupHelper'
import { ADD_ONS_GROUP_LIST } from '../../constants/queryKeys'
import { FormClass, toastMessageInitialData } from '../../constants/constants'
import ToastAlert from '../alert/ToastAlert'
import { isDecimalNumberWithDecimalPoints } from '../../helpers/utils'
import { v4 as uuidv4 } from 'uuid'
import { TranslationContext, jTranslationText, JTranslation } from '../../helpers/jTranslate'

type Props = {
	editAddonGroup: AddonGroup | undefined
	setEditAddOnGroup: React.Dispatch<React.SetStateAction<AddonGroup | undefined>>
	addOnList: Addon[]
	handleClose: () => void
}

const AddOnsGroupSideBar = ({ editAddonGroup, setEditAddOnGroup, addOnList, handleClose }: Props) => {
	// CONTEXT VARIABLE
	const { showAddOnGroupSideBar, setShowAddOnGroupSideBar } = useContext(CommonCtx)
	const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext
	// LOCAL VARIABLES
	const queryClient = useQueryClient()
	const addOnMutation = useMutationHook(queryClient, true) // add or update addOn mutation
	// STATE VARIABLES
	const [formData, setFormData] = useState<NewAddonGroup>(initialAddonGroupFormData)
	const [associatedItems, setAssociatedItems] = useState<SelectedMenusItem[] | null>(null)
	const [translationText, setTranslatedText] = useState<{
        loading: string,
        select: string,
      }>({ 
        loading: 'Loading...',
        select: 'Select',
    });
	// ALERT VARIABLES
	const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)

	// Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: 'Loading...', typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: 'Select', typeCase: 'pascal', translationContext }),
                ])
                setTranslatedText({
                    loading: translations[0] ?? 'Loading...',
                    select: translations[1] ?? 'Select',
                })
            } catch {
                setTranslatedText({
                    loading: 'Loading...',
                    select: 'Select',
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

	// effect for setting the form data
	useEffect(() => {
		if (editAddonGroup) {
			setFormData({
				addonGroupName: editAddonGroup?.addonGroupName || '',
				addonGroupPrice: editAddonGroup?.addonGroupPrice || '',
				selectedAddons: editAddonGroup?.selectedAddons || [],
			})
		}
	}, [editAddonGroup])

	//  This effect invokes the single addon API to retrieve the list of associated items
	useEffect(() => {
		if (editAddonGroup) {
			const param: SingleAddonGroup = { id: editAddonGroup.id as string }
			//   api call
			fetchSingleAddOnGroup(addOnMutation, param, onSingleAddOnGroupSuccess, onError)
		}

		return () => {
			// cleanup
			setAssociatedItems(null)
		}
	}, [editAddonGroup])

	// on add or update addOn Group success
	const onSingleAddOnGroupSuccess = (data: SingleAddOnGroupResponse) => {
		setAssociatedItems(data.selectedMenus)
	}

	// on add or update addOn success
	const onSuccess = (message: string, variant: string) => {
		setToastMessage({ message, variant, show: true })
		queryClient.refetchQueries([ADD_ONS_GROUP_LIST]) // nosonar
		setTimeout(() => {
			cleanUpData()
		}, 3000)
	}

	// on add or update addOn error
	const onError = (message: string, variant: string) => {
		setToastMessage({ message, variant, show: true })
	}

	// clear data
	const cleanUpData = () => {
		setFormData(initialAddonGroupFormData)
		setEditAddOnGroup(undefined)
		setToastMessage(toastMessageInitialData)
		setShowAddOnGroupSideBar(false)
	}

	const isFormValid = () => {
		let addonGroupName = formData.addonGroupName?.trim().length > 0 ? FormClass.VALID : FormClass.INVALID
		let addonGroupPrice =
			formData.addonGroupPrice?.toString().trim().length > 0 ? FormClass.VALID : FormClass.INVALID
		let selectedAddons = formData.selectedAddons?.length > 1 ? FormClass.VALID : FormClass.INVALID

		if (
			addonGroupName === FormClass.VALID &&
			addonGroupPrice === FormClass.VALID &&
			selectedAddons === FormClass.VALID
		) {
			return true
		}
		return false
	}

	const submitForm = () => {
		if (editAddonGroup) {
			const updateAddOnData: NewAddonGroup = {
				...initialAddonGroupFormData,
				id: editAddonGroup.id,
				addonGroupName: formData.addonGroupName,
				addonGroupPrice: formData.addonGroupPrice,
				selectedAddons: formData.selectedAddons,
			}
			// update addon api call
			updateAddOnGroup(addOnMutation, updateAddOnData, onSuccess, onError)
		} else {
			const newAddOnData: NewAddonGroup = {
				...initialAddonGroupFormData,
				addonGroupName: formData.addonGroupName,
				addonGroupPrice: formData.addonGroupPrice,
				selectedAddons: formData.selectedAddons,
			}
			// create addon api call
			createNewAddOnGroup(addOnMutation, newAddOnData, onSuccess, onError)
		}
	}

	return (
		<Offcanvas
			show={showAddOnGroupSideBar}
			className="custom-offcanvas"
			onHide={() => {
				handleClose()
				cleanUpData()
			}}
			backdrop="static"
			// responsive="xl"
			placement="end"
		>
			{/* TOAST MESSAGE COMPONENT */}
			<ToastAlert
				show={toastMessage.show}
				onClose={() => setToastMessage(toastMessageInitialData)}
				message={toastMessage.message}
				variant={toastMessage.variant}
			/>

			<Offcanvas.Header closeButton>
				<Offcanvas.Title>{editAddonGroup 
					? <JTranslation typeCase="pascal" text={EDIT_ADD_ON_GROUP} /> 
					: <JTranslation typeCase="pascal" text={ADD_NEW_ADD_ON_GROUP} />
				}</Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body>
				<div className="row">
					<div className=" col-md-12 col-lg-12 mb-3">
						<label htmlFor="validationCustom01" className="form-label">
							<JTranslation typeCase="pascal" text={ADD_ON_GROUP_NAME} />
							<span className="mandatory ">*</span>{' '}
						</label>
						<input
							type="text"
							className="form-control"
							id="validationCustom01"
							autoComplete="off"
							maxLength={100}
							data-testid="addongroup-name-input"
							value={formData.addonGroupName}
							onChange={(e) => {
								setFormData({ ...formData, addonGroupName: e.target.value })
							}}
						/>
					</div>
					<div className=" col-md-12 col-lg-12 mb-3">
						<label htmlFor="validationCustom01" className="form-label">
							<JTranslation typeCase="pascal" text={PRICE} />
							<span className="mandatory ">*</span>{' '}
						</label>
						<div className="input-group custom-prepend-input">
							<div className="input-group-prepend">
								<span className="input-group-text">$</span>
							</div>
							<input
								type="text"
								className="form-control"
								id="addonPrice"
								autoComplete="off"
								maxLength={10}
								data-testid="addongroup-price-input"
								value={formData.addonGroupPrice}
								onChange={(e) => {
									if (isDecimalNumberWithDecimalPoints(e,2)) {
										setFormData({ ...formData, addonGroupPrice: e.target.value })
									}
								}}
							/>
						</div>
					</div>
					<div className=" col-md-12 col-lg-12 mb-3">
						<label htmlFor="validationCustom01" className="form-label">
							<JTranslation typeCase="pascal" text={ADD_ADD_ON} />
							<span className="mandatory ">*</span>{' '}
						</label>
						<Select
							options={addOnList.map((item) => {
								return {
									value: item.id,
									label: item.addonName,
									id: item.id,
								}
							})}
							placeholder={addOnList.length === 0 ? translationText.loading : translationText.select}
							isSearchable
							isMulti
							data-testid="addons-select"
							value={addOnList
								.filter((item) => item.id && formData.selectedAddons.some((addOn) => addOn === item.id))
								.map((item) => ({ value: item.id, label: item.addonName, id: item.id }))}
							onChange={(selectedItems) => {
								const selectedValues = selectedItems.map((item) => item.id) as []

								setFormData({ ...formData, selectedAddons: selectedValues })
							}}
							onInputChange={(data) => {
								if (data) {
									const givenAddons = data?.split(',').map((name) => name?.trim()?.toLowerCase())
									const selectedValues = addOnList
										.filter((item) => givenAddons.includes(item.addonName.toLowerCase()))
										.map((item) => item.id) as []

									const updatedSelectedAddonsSet = new Set([
										...formData.selectedAddons,
										...selectedValues,
									])
									const updatedSelectedAddons = Array.from(updatedSelectedAddonsSet)

									setFormData({ ...formData, selectedAddons: updatedSelectedAddons })
								}
							}}
						/>
					</div>
				</div>

				{associatedItems && associatedItems.length > 0 && (
					<label htmlFor="validationCustom01" className="form-label">
						{associatedItems?.length > 1 
							? <JTranslation typeCase="pascal" text={ASSOCIATED_ITEMS} /> 
							: <JTranslation typeCase="pascal" text={ASSOCIATED_ITEM} />
						}
					</label>
				)}

				<div className=" col-md-12 col-lg-12 mb-3">
					{editAddonGroup &&
						associatedItems?.map((item) => {
							return (
								<div key={uuidv4()} className="capsule">
									{item.itemName}
								</div>
							)
						})}
				</div>

				<div className="save-btn-section shadow save-btn-absolute">
					<button
						className="btn btn-custom-primary-outline"
						type="button"
						data-testid="cancel-btn"
						onClick={() => {
							handleClose()
							cleanUpData()
						}}
					>
						<JTranslation typeCase="pascal" text={CANCEL} />
					</button>

					<button
						className="btn btn-custom-primary"
						type="button"
						disabled={!isFormValid()}
						data-testid="save-btn"
						onClick={() => {
							// validate and submit form
							if (isFormValid()) {
								submitForm()
							}
						}}
					>
						{editAddonGroup 
							? <JTranslation typeCase="pascal" text={UPDATE} /> 
							: <JTranslation typeCase="pascal" text={SAVE} />
						}
					</button>
				</div>
			</Offcanvas.Body>
		</Offcanvas>
	)
}

export default AddOnsGroupSideBar
