import SurveyHeaderComponent from '../components/header/SurveyHeaderComponent';

const withPublicHeader = (WrappedComponent: Function) => {
    return (props: any) => {
        document.body.classList.remove('user-style');
        return (
            <div className="p-0">
                <div id="GuestMain" className="h-100">
                    <SurveyHeaderComponent />
                    <WrappedComponent {...props} />
                </div>
            </div>
        );
    };
};

export default withPublicHeader;