import { Modal } from "react-bootstrap";
import { PROCEED, CANCEL } from "../../constants/strings";
import { JTranslation } from "../../helpers/jTranslate";

type Props = {
    show: boolean,
    title: string,
    primaryButtonName?: string,
    secondaryButtonName?: string,
    onHide: React.Dispatch<React.SetStateAction<boolean>>,
    callback: () => void
}

function WarningModal({ show, title, onHide, callback, primaryButtonName = PROCEED, secondaryButtonName = CANCEL }: Props) {
    return (
        <Modal
            data-testid="warning-modal"
            centered
            onHide={() => onHide(false)}
            show={show}>
            <Modal.Body className="">
                <p className="fw-semibold mb-0"><JTranslation typeCase="capitalize" text={title} /></p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn  btn-custom-primary-outline" data-bs-dismiss="modal"
                    data-testid="cancel"
                    onClick={() => onHide(false)} ><JTranslation typeCase="pascal" text={secondaryButtonName} /></button>
                <button type="button" className="btn btn-custom-primary"
                    data-testid="proceed"
                    onClick={(e) => {
                        e.preventDefault();
                        callback();
                    }}><JTranslation typeCase="pascal" text={primaryButtonName} /></button>
            </Modal.Footer>
        </Modal>
    )
}

export default WarningModal;