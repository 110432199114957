import { useEffect, useState, useContext } from 'react'
import { AlertVariant } from '../../../constants/constants'
import { Button } from 'primereact/button'
import { changeMarketPriceLabel, toggleCardPrice, toggleSuggestedCardPrice, addQrCodeLabels, updateMenuRestTime } from '../../../helpers/menuManagementHelper'
import { CommonCtx } from '../../../context/CommonCtxProvider'
import { FnBCtx } from '../../../context/MoveItemCtxProvider'
import { FnBGlobalSetting } from '../../../constants/staticTypes'
import { InputSwitch } from 'primereact/inputswitch'
import { Offcanvas, OverlayTrigger } from 'react-bootstrap'
import { Panel } from 'primereact/panel'
import { isDecimalNumber, popover } from '../../../helpers/utils'
import { useQueryClient } from 'react-query'
import useMutationHook from '../../../hooks/useMutationHook'
import {
    BOTTOM_LABEL,
    CUSTOM_LABEL_FOR_MARKET_PRICE,
    DISABLE_DND,
    DND_HELP,
    DND,
    ENABLE_DND,
    GLOBAL_CONFIGURATION,
    GLOBAL_PRICE_DISPLAY,
    HELP_GLOBAL_CONFIG,
    HIDE_DISPLAY_PRICE,
    HIDE_SUGGESTION_DISPLAY_PRICE,
    LOCK_DND,
    QR_CODE_LABELS,
    SHOW_DISPLAY_PRICE,
    SHOW_SUGGESTION_DISPLAY_PRICE,
    SUGGESTED_ITEMS_PRICE_DISPLAY,
    TOP_LABEL,
    UNLOCK_DND,
    FB_MENU_TIMEOUT_DURATION,
} from '../../../constants/strings'
import { JTranslation, TranslationContext, jTranslationText } from '../../../helpers/jTranslate'
import MlxPopover from '../../common/MlxPopover'

type Props = {
    handleClose: () => void
    show: boolean
    displayToast: (message: string, variant: string) => void
}

function FnBMenuConfig({ show, handleClose, displayToast }: Props) {
    const queryClient = useQueryClient()
    const fnBGlobalConfigMutation = useMutationHook(queryClient, true)
    // CONTEXT VARIABLES
    const { dndLock, setDndLock } = useContext(FnBCtx)
    const { fnBGlobalSettings, setFnBGlobalSettings } = useContext(CommonCtx)
    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext
    // STATE VARIABLE
    const [settings, setSettings] = useState<FnBGlobalSetting>(fnBGlobalSettings)
    const [customLabelEdited, setCustomLabelEdited] = useState(false)
    const [timeoutDurationEdited, setTimeoutDurationEdited] = useState(false)
    const [qrLabelEdited, setQrLabelEdited] = useState({ top: false, bottom: false })
    const [translationText, setTranslatedText] = useState<{
        hideDisplayPrice: string,
        showDisplayPrice: string,
        hideSuggestionDisplayPrice: string,
        showSuggestionDisplayPrice: string,
        lockDnd: string,
        unlockDnd: string,
      }>({ 
        hideDisplayPrice: HIDE_DISPLAY_PRICE,
        showDisplayPrice: SHOW_DISPLAY_PRICE,
        hideSuggestionDisplayPrice: HIDE_SUGGESTION_DISPLAY_PRICE,
        showSuggestionDisplayPrice: SHOW_SUGGESTION_DISPLAY_PRICE,
        lockDnd: LOCK_DND,
        unlockDnd: UNLOCK_DND,
    });

    const changeSuggestedCardSuccess = (newSettings: FnBGlobalSetting) => {
        setFnBGlobalSettings({
            ...fnBGlobalSettings,
            showMenuSuggestedItemCardPrice: newSettings.showMenuSuggestedItemCardPrice,
        })
    }

    const changeGlobalPriceSuccess = (newSettings: FnBGlobalSetting) => {
        setFnBGlobalSettings({ ...fnBGlobalSettings, showMenuCardPrice: newSettings.showMenuCardPrice })
    }

    const changeMarketPriceSuccess = (newLabel: string, message: string) => {
        setFnBGlobalSettings({ ...fnBGlobalSettings, marketPriceLabel: newLabel })
        displayToast(message, AlertVariant.SUCCESS)
    }

    const changeQrLabelSuccess = (newSetting: FnBGlobalSetting, message: string) => {
        setFnBGlobalSettings({ ...fnBGlobalSettings, ...newSetting })
        displayToast(message, AlertVariant.SUCCESS)
    }

    const changeMenuRestTimeSuccess = (newSetting: FnBGlobalSetting, message: string) => {
        setFnBGlobalSettings({ ...fnBGlobalSettings, ...newSetting })
        displayToast(message, AlertVariant.SUCCESS)
    }

    // api call to change global price display
    const changeSuggestedCardPrice = () => {
        toggleSuggestedCardPrice(
            !settings?.showMenuSuggestedItemCardPrice,
            fnBGlobalConfigMutation,
            changeSuggestedCardSuccess,
            displayToast
        )
    }

    // api call to change global price display
    const changeGlobalPriceDisplay = () => {
        toggleCardPrice(!settings?.showMenuCardPrice, fnBGlobalConfigMutation, changeGlobalPriceSuccess, displayToast)
    }

    // api call to change default market price text
    const changeMarketPriceText = () => {
        changeMarketPriceLabel(
            settings?.marketPriceLabel,
            fnBGlobalConfigMutation,
            changeMarketPriceSuccess,
            displayToast
        )
    }

    // api call to change QR code labels
    const changeQrLabels = () => {
        addQrCodeLabels(
            settings?.qrCodeSettings,
            fnBGlobalConfigMutation,
            changeQrLabelSuccess,
            displayToast
        )
    }

    // api call to change Menu Rest Time
    const changeMenuRestTime = () => {
        if(settings?.menuResetTimeInMinutes <= 0) {
            displayToast('The request timeout duration must be greater than 0', AlertVariant.ERROR)
            return;
        }
        updateMenuRestTime(
            { menuResetTimeInMinutes: settings?.menuResetTimeInMinutes },
            fnBGlobalConfigMutation,
            changeMenuRestTimeSuccess,
            displayToast
        )
    }

    // effect to show and hide the save and cancel buttons
    useEffect(() => {
        if (settings?.marketPriceLabel !== fnBGlobalSettings?.marketPriceLabel) {
            setCustomLabelEdited(true)
        } else {
            setCustomLabelEdited(false)
        }

        if (settings?.menuResetTimeInMinutes !== fnBGlobalSettings?.menuResetTimeInMinutes) {
            setTimeoutDurationEdited(true)
        } else {
            setTimeoutDurationEdited(false)
        }

        if (settings?.qrCodeSettings?.qrCodeTopLabel !== fnBGlobalSettings?.qrCodeSettings?.qrCodeTopLabel) {
            setQrLabelEdited((prev) => ({ ...prev, top: true }))
        } else {
            setQrLabelEdited((prev) => ({ ...prev, top: false }))
        }

        if (settings?.qrCodeSettings?.qrCodeBottomLabel !== fnBGlobalSettings?.qrCodeSettings?.qrCodeBottomLabel) {
            setQrLabelEdited((prev) => ({ ...prev, bottom: true }))
        } else {
            setQrLabelEdited((prev) => ({ ...prev, bottom: false }))
        }

        return () => {
            setCustomLabelEdited(false)
            setTimeoutDurationEdited(false)
            setQrLabelEdited({ top: false, bottom: false })
        }
    }, [settings, fnBGlobalSettings])

    // effect to replace settings value with sever state on component unmount
    useEffect(() => {
        setSettings(fnBGlobalSettings)
    }, [show, fnBGlobalSettings])

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: HIDE_DISPLAY_PRICE, typeCase: 'capitalize', translationContext, }),
                    jTranslationText({ text: SHOW_DISPLAY_PRICE, typeCase: 'capitalize', translationContext, }),
                    jTranslationText({ text: HIDE_SUGGESTION_DISPLAY_PRICE, typeCase: 'capitalize', translationContext, }),
                    jTranslationText({ text: SHOW_SUGGESTION_DISPLAY_PRICE, typeCase: 'capitalize', translationContext, }),
                    jTranslationText({ text: LOCK_DND, typeCase: 'capitalize', translationContext, }),
                    jTranslationText({ text: UNLOCK_DND, typeCase: 'capitalize', translationContext, }),
                ])
                setTranslatedText({
                    hideDisplayPrice: translations[0] ?? HIDE_DISPLAY_PRICE,
                    showDisplayPrice: translations[1] ?? SHOW_DISPLAY_PRICE,
                    hideSuggestionDisplayPrice: translations[2] ?? HIDE_SUGGESTION_DISPLAY_PRICE,
                    showSuggestionDisplayPrice: translations[3] ?? SHOW_SUGGESTION_DISPLAY_PRICE,
                    lockDnd: translations[4] ?? LOCK_DND,
                    unlockDnd: translations[5] ?? UNLOCK_DND,
                })
            } catch {
                setTranslatedText({
                    hideDisplayPrice: HIDE_DISPLAY_PRICE,
                    showDisplayPrice: SHOW_DISPLAY_PRICE,
                    hideSuggestionDisplayPrice: HIDE_SUGGESTION_DISPLAY_PRICE,
                    showSuggestionDisplayPrice: SHOW_SUGGESTION_DISPLAY_PRICE,
                    lockDnd: LOCK_DND,
                    unlockDnd: UNLOCK_DND,
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    return (
        <Offcanvas show={show} onHide={() => handleClose()} placement="end" backdrop="static">
            <Offcanvas.Header closeButton className="border-bottom">
                <Offcanvas.Title className="d-flex justify-content-center align-items-center">
                    <JTranslation typeCase="pascal" text={GLOBAL_CONFIGURATION} />
                    <MlxPopover data={{title:GLOBAL_CONFIGURATION, body:HELP_GLOBAL_CONFIG}} />
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Panel className="mt-2 p-panel-content-custom">
                    <div className="d-flex">
                        <div className="flex-grow-1 me-2"><JTranslation typeCase="pascal" text={GLOBAL_PRICE_DISPLAY} /></div>
                        <div className="flex-grow-0">
                            <InputSwitch
                                className="custom-input-switch"
                                checked={!settings?.showMenuCardPrice}
                                onChange={() => {
                                    setSettings({
                                        ...settings,
                                        showMenuCardPrice: !settings?.showMenuCardPrice,
                                    })
                                    // api call
                                    changeGlobalPriceDisplay()
                                }}
                                title={settings?.showMenuCardPrice 
                                    ? translationText.hideDisplayPrice 
                                    : translationText.showDisplayPrice
                                }
                            />
                        </div>
                    </div>
                    <hr></hr>
                    <div className="d-flex">
                        <div className="flex-grow-1 me-2"><JTranslation typeCase="pascal" text={SUGGESTED_ITEMS_PRICE_DISPLAY} /></div>
                        <div className="flex-grow-0">
                            <InputSwitch
                                className="custom-input-switch"
                                checked={!settings?.showMenuSuggestedItemCardPrice}
                                onChange={() => {
                                    setSettings({
                                        ...settings,
                                        showMenuSuggestedItemCardPrice: !settings?.showMenuSuggestedItemCardPrice,
                                    })
                                    // api call
                                    changeSuggestedCardPrice()
                                }}
                                title={
                                    settings?.showMenuSuggestedItemCardPrice
                                        ? translationText.hideSuggestionDisplayPrice
                                        : translationText.showSuggestionDisplayPrice
                                }
                            />
                        </div>
                    </div>
                </Panel>
                <div className="drag-bg mt-3">
                    <div className="d-flex align-items-center">
                        <div className="flex-grow-1 d-flex align-items-center me-2">
                            {dndLock 
                                ? <JTranslation typeCase="pascal" text={ENABLE_DND} /> 
                                : <JTranslation typeCase="pascal" text={DISABLE_DND} />
                            }
                            <MlxPopover data={{title:DND, body:DND_HELP}} />
                        </div>
                        <div className="flex-grow-0 d-flex">
                            <InputSwitch
                                className="custom-input-switch"
                                checked={!dndLock}
                                onChange={() => setDndLock(!dndLock)}
                                title={dndLock 
                                    ? translationText.lockDnd 
                                    : translationText.unlockDnd
                                }
                            />
                        </div>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-12">
                        <div className="form-group input-group custom-input-group">
                            <label htmlFor="custom_label" className=" form-label w-100 d-block">
                                <JTranslation typeCase="pascal" text={CUSTOM_LABEL_FOR_MARKET_PRICE} />
                            </label>
                            <input
                                id="custom_label"
                                className="form-control"
                                autoComplete="off"
                                maxLength={50}
                                value={settings?.marketPriceLabel}
                                onChange={(e) =>
                                    setSettings({
                                        ...settings,
                                        marketPriceLabel: e.target.value,
                                    })
                                }
                            />

                            <span className="input-group-text" onClick={() => setCustomLabelEdited(true)}>
                                <i className="ri-pencil-line"></i>
                            </span>
                        </div>

                        {customLabelEdited && (
                            <div className="d-flex justify-content-end">
                                <Button
                                    icon="pi pi-check"
                                    aria-label="Filter"
                                    className="mt-1 p-button-sm  p-button-rounded p-button-success p-button-text"
                                    onClick={() => {
                                        // api call
                                        changeMarketPriceText()
                                    }}
                                />
                                <Button
                                    icon="pi pi-times"
                                    className=" mt-1 p-button-rounded p-button-danger p-button-text"
                                    aria-label="Cancel"
                                    onClick={() =>
                                        setSettings({
                                            ...settings,
                                            marketPriceLabel: fnBGlobalSettings?.marketPriceLabel,
                                        })
                                    }
                                />
                            </div>
                        )}
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-12">
                        <div className="form-group input-group custom-input-group">
                            <label htmlFor="fb_menu_timeout_duration" className=" form-label w-100 d-block">
                                <JTranslation typeCase="pascal" text={FB_MENU_TIMEOUT_DURATION} />
                            </label>
                            <input
                                type="text"
                                id="fb_menu_timeout_duration"
                                className="form-control"
                                autoComplete="off"
                                value={settings?.menuResetTimeInMinutes}
                                onChange={(e) => {
									if (isDecimalNumber(e)) {
										setSettings({
                                            ...settings,
                                            menuResetTimeInMinutes: +e.target.value,
                                        })
									}
								}}
                            />

                            <span className="input-group-text" onClick={() => setTimeoutDurationEdited(true)}>
                                <i className="ri-pencil-line"></i>
                            </span>
                        </div>

                        {timeoutDurationEdited && (
                            <div className="d-flex justify-content-end">
                                <Button
                                    icon="pi pi-check"
                                    aria-label="Filter"
                                    className="mt-1 p-button-sm  p-button-rounded p-button-success p-button-text"
                                    onClick={() => {
                                        // api call
                                        changeMenuRestTime()
                                    }}
                                />
                                <Button
                                    icon="pi pi-times"
                                    className=" mt-1 p-button-rounded p-button-danger p-button-text"
                                    aria-label="Cancel"
                                    onClick={() => {
                                        setSettings({
                                            ...settings,
                                            menuResetTimeInMinutes: fnBGlobalSettings?.menuResetTimeInMinutes,
                                        })
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>

                <br />

                <Panel className="mt-2 p-panel-content-custom">
                    <strong className=" form-label w-100 d-block"><JTranslation text={QR_CODE_LABELS} /></strong>
                    <div className="col-12">
                        <div className="form-group input-group custom-input-group">
                            <label htmlFor="custom_label" className=" form-label w-100 d-block">
                                <JTranslation typeCase="pascal" text={TOP_LABEL} />
                            </label>
                            <textarea
                                id="custom_label"
                                className="form-control qr-code-text-area"
                                autoComplete="off"
                                maxLength={150}
                                value={settings?.qrCodeSettings?.qrCodeTopLabel}
                                onChange={(e) =>
                                    setSettings({
                                        ...settings,
                                        qrCodeSettings: {
                                            ...settings?.qrCodeSettings,
                                            includeLogo: true,
                                            qrCodeTopLabel: e.target.value,
                                        }
                                    })
                                }
                            />

                            <span className="input-group-text" onClick={() => setQrLabelEdited((prev) => ({ ...prev, top: true }))}>
                                <i className="ri-pencil-line"></i>
                            </span>
                        </div>

                        {qrLabelEdited.top && (
                            <div className="d-flex justify-content-end">
                                <Button
                                    icon="pi pi-check"
                                    aria-label="Filter"
                                    className="mt-1 p-button-sm  p-button-rounded p-button-success p-button-text"
                                    onClick={() => {
                                        // api call
                                        changeQrLabels()
                                    }}
                                />
                                <Button
                                    icon="pi pi-times"
                                    className=" mt-1 p-button-rounded p-button-danger p-button-text"
                                    aria-label="Cancel"
                                    onClick={() =>
                                        setSettings({
                                            ...settings,
                                            qrCodeSettings: {
                                                ...settings?.qrCodeSettings,
                                                qrCodeTopLabel: fnBGlobalSettings?.qrCodeSettings?.qrCodeTopLabel,
                                            }
                                        })
                                    }
                                />
                            </div>
                        )}
                    </div>

                    <br />

                    <div className="col-12">
                        <div className="form-group input-group custom-input-group">
                            <label htmlFor="custom_label" className=" form-label w-100 d-block">
                                <JTranslation typeCase="pascal" text={BOTTOM_LABEL} />
                            </label>
                            <textarea
                                id="custom_label"
                                className="form-control qr-code-text-area"
                                autoComplete="off"
                                maxLength={150}
                                value={settings?.qrCodeSettings?.qrCodeBottomLabel}
                                onChange={(e) =>
                                    setSettings({
                                        ...settings,
                                        qrCodeSettings: {
                                            ...settings?.qrCodeSettings,
                                            includeLogo: true,
                                            qrCodeBottomLabel: e.target.value,
                                        }
                                    })
                                }
                            />

                            <span className="input-group-text" onClick={() => setQrLabelEdited((prev) => ({ ...prev, bottom: true }))}>
                                <i className="ri-pencil-line"></i>
                            </span>
                        </div>

                        {qrLabelEdited.bottom && (
                            <div className="d-flex justify-content-end">
                                <Button
                                    icon="pi pi-check"
                                    aria-label="Filter"
                                    className="mt-1 p-button-sm  p-button-rounded p-button-success p-button-text"
                                    onClick={() => {
                                        // api call
                                        changeQrLabels()
                                    }}
                                />
                                <Button
                                    icon="pi pi-times"
                                    className=" mt-1 p-button-rounded p-button-danger p-button-text"
                                    aria-label="Cancel"
                                    onClick={() =>
                                        setSettings({
                                            ...settings,
                                            qrCodeSettings: {
                                                ...settings?.qrCodeSettings,
                                                qrCodeBottomLabel: fnBGlobalSettings?.qrCodeSettings?.qrCodeBottomLabel,
                                            }
                                        })
                                    }
                                />
                            </div>
                        )}
                    </div>
                </Panel>


            </Offcanvas.Body>
        </Offcanvas >
    )
}

export default FnBMenuConfig
