import { AxiosResponse } from "axios";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { QueryClient } from "react-query";
import { GUEST_SURVEY_RATING_GRAPH } from "../../constants/queryKeys";
import { getGuestSurveyRatingData } from "../../helpers/dashboardHelper";
import { customDeepClone, getYearRange } from "../../helpers/utils";
import useQueryHook from "../../hooks/useQueryHook";
import { GraphData, GraphOptions } from "../../pages/dashboard/Dashboard";
import { Line } from 'react-chartjs-2';
import { SURVEY_CATEGORY_TYPES } from "../../constants/constants";
import { JTranslation, TranslationContext, jTranslationText } from "../../helpers/jTranslate";
import { useWindowSize } from "react-use";

type Props = {
    queryClient: QueryClient,
    graphOptions: GraphOptions
}

function GuestSurveyRatingGraph({ queryClient, graphOptions }: Props) {   
    const [customGraphOptions, setCustomGraphOptions] = useState<GraphOptions>(graphOptions);
    const [guestSurveyData, setGuestSurveyData] = useState<GraphData>();
    const [year, setYear] = useState(new Date().getFullYear());
    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext
    const chartRef = useRef<any>(null);
    const { width } = useWindowSize();

    const legendColors = [
        'rgba(255, 99, 132, 0.5)',
        'rgba(53, 162, 235, 0.5)',
        'rgba(64, 231, 62, 0.5)',
        'rgba(244, 183, 127, 0.5)',
        'rgba(35, 35, 35, 0.5)',
        'rgba(157, 127, 244, 0.5)'
    ];

    // data fetch success
    const onFetchSuccess = async (res: AxiosResponse<any, any>) => {
        const data = res.data.data as {
            [key: string]: {
                [key: string]: {
                    guestGivenScore: number,
                    totalSurveyAnswerScore: number,
                    scorePercentage: number
                }
            }
        };

        // filter data based on month
        let surveyDataSet = [] as {
            [key: string]: number | string;
        }[];
        const labels = moment.months().map(month => {
            const key = month.toLowerCase();
            const monthData = data[key];
            const categoriesData = {} as { [key: string]: number | string };
            Object.keys(SURVEY_CATEGORY_TYPES).forEach(category => {
                if (monthData[category]) {
                    categoriesData[category] = monthData[category].scorePercentage;
                } else {
                    //categoriesData[category] = ;
                }
            });
            surveyDataSet.push(categoriesData);
            return month.substring(0, 3);
        });

        // filter graph dataset based on each category
        const datasets = [] as {
            label: string,
            data: (number | string)[],
            backgroundColor: string,
            borderColor: string,
            spanGaps: boolean
        }[];

        // Map each key to a promise
        const promises = Object.keys(SURVEY_CATEGORY_TYPES).map(async (key, index) => {
            const filteredData = surveyDataSet.map(item => item[key]);
            datasets.push({
                label: (await jTranslationText({ text: SURVEY_CATEGORY_TYPES[key], typeCase: 'pascal', translationContext })) as string,
                data: filteredData,
                backgroundColor: legendColors[index],
                borderColor: legendColors[index],
                spanGaps: true
            });
        });

        // Wait for all promises to resolve
        await Promise.all(promises);

        const guestData = {
            labels,
            datasets
        };
        setGuestSurveyData(guestData);
    }

    useQueryHook(GUEST_SURVEY_RATING_GRAPH,
        () => getGuestSurveyRatingData(queryClient, year), onFetchSuccess);

    useEffect(() => {
        queryClient.refetchQueries([GUEST_SURVEY_RATING_GRAPH])
    }, [targetLanguage])

    useEffect(() => {
        const chartParentWidth = chartRef?.current.getBoundingClientRect().width
        const { plugins: { legend } } =  customDeepClone(graphOptions) as GraphOptions;
        if(chartParentWidth && chartParentWidth <= 670) {
            legend.align = "start"; 
            legend.labels.boxWidth = 10;
        } else {
            legend.align = "center"; 
            legend.labels.boxWidth = 40;
        }        
        setCustomGraphOptions({...customDeepClone(graphOptions), plugins: { legend } })
    }, [width]);

    return <div className="card h-100 custom-shadow border-radius-05 border-0">
        <div className="card-header order-radius-card-header-05 border-0 py-2">
            <div className="d-flex  align-items-center">
                <div className="flex-grow-1">
                    <h4 className="mb-0 title"><JTranslation typeCase="pascal" text={"Guest Survey Feedback"} /></h4>
                </div>
                <div className="flex-grow-0  d-flex justify-content-end ">
                    <div className="col-md-12">
                        <select className="form-select" aria-label="Default select example" data-test-field="survey-feedback-select"
                            value={year}
                            onChange={(e) => {
                                setYear(parseInt(e.target.value));
                                queryClient.fetchQuery(GUEST_SURVEY_RATING_GRAPH,
                                    () => getGuestSurveyRatingData(queryClient, parseInt(e.target.value)));
                            }}>
                            {getYearRange().map(yearValue => {
                                return <option key={yearValue}
                                    value={yearValue}>{yearValue}</option>
                            })}
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div className="card-body flex-grow-1 overflow-hidden">
            <div className="survey-section ">
                <div className="row ">
                    <div ref={chartRef} className="col-lg-12 d-flex flex-column justify-content-center" style={{ minHeight: '250px', overflow: 'auto' }}>
                        {guestSurveyData && <Line redraw={true} options={customGraphOptions} data={guestSurveyData} />}
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default GuestSurveyRatingGraph;