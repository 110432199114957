import { BACK_TO_WELCOME, BACK_TO_FNB_MENU } from '../../constants/strings'
import { routes } from '../../constants/routes'
import linkExpiredImg from '../../assets/images/link-expired.png'
import { JTranslation } from '../../helpers/jTranslate'

type ErrorPageProps = {
  title: string
  bodyText: string
}

const ErrorPage = ({ title, bodyText }: ErrorPageProps) => {

  return (
    <>
      <div className='container guest-view-menu h-100'>
        <div className='row mt-3'>
          <div className='card  h-100'>
            <div className='d-flex align-items-center justify-content-center flex-column'>
              <h3 className='my-5 col-md-8 text-center apology-text'>
                <JTranslation text={title} />
              </h3>
              <img
                className='mb-5 img-fluid'
                alt='empty-pate'
                src={linkExpiredImg}
              ></img>
              <h3 className='text-danger'>
                <JTranslation text={bodyText} type='html' />
              </h3>

              <div className='d-flex align-items-center mt-4 mb-5 flex-wrap justify-content-center d-flex align-center gap-2 '>
                <button
                  className='btn btn-custom-primary-outline btn-sm-block'
                  onClick={() => window.location.pathname = routes.welcome_page}
                >
                  <JTranslation text={BACK_TO_WELCOME} />
                </button>
                <button
                  className='btn btn-custom-primary btn-sm-block'
                  onClick={() => window.location.pathname = routes.menu_view}
                >
                  <JTranslation text={BACK_TO_FNB_MENU} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ErrorPage
