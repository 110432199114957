import { AxiosResponse } from "axios";
import { QueryClient, UseMutationResult } from "react-query";
import { cloneUserApi, fileUploadApi, getTenantUserApi, staffsApi, usersApi, userStatusApi } from "../constants/apiEndPoints";
import { FormClass, HttpMethods, PermissionKeys, SubPermissionKeys, PhoneNumberFormClass } from "../constants/constants";
import { CopyUserFormData, Country, Payload, Users } from "../constants/staticTypes";
import HttpServiceHelper from "./httpServiceHelper";
import { checkPermission } from "./utils";

// initial form state
export const initialFormData = {
    photoKey: "",
    preferredName: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    loginUserName: "",
    password: "",
    jobRoles: [] as string[],
    posNumber: "",
    hireDate: null as Date | null,
    passwordLessLoginOnly: true,
    sendPasswordCreation: false,
    sendPasswordCreationTo: "sms",
    staffId: "",
    preferredLangKey: "EN",
    staffPermissionRoles: [] as string[]
}

// initial valid form
export const initialFormValidationData = {
    preferredName: FormClass.VALID,
    firstName: FormClass.VALID,
    lastName: FormClass.VALID,
    loginUserName: FormClass.VALID,
    posNumber: FormClass.VALID,
    email: FormClass.VALID,
    password: FormClass.VALID,
    // roles: "",
    phoneNumber: PhoneNumberFormClass.VALID,
    preferredLangKey: "",
    staffPermissionRoles: ""
}

// initial form state
export const initialProfileFormData = {
    photoKey: "",
    firstName: "",
    lastName: "",
    staffId: "",
    preferredLangKey: "EN"
}

// initial valid form
export const initialProfileFormValidationData = {
    firstName: FormClass.VALID,
    lastName: FormClass.VALID,
    preferredLangKey: ""
}

// add or update users (staff)
export const upsertUsers = (
    data: {},
    upsertMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onSuccess: () => void,
    onError: (res: unknown) => void,
    id?: string) => {
    data = id ? {
        ...data,
        id: id // add id if operation is update
    } : data;
    upsertMutation.mutate({
        url: staffsApi,
        method: id ? HttpMethods.PUT : HttpMethods.POST,
        data: data
    }, {
        onSuccess,
        onError
    })
}

// delete staff users
export const deleteUser = (
    data: {},
    deleteUserMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onSuccess: () => void,
    onError: () => void) => {
    deleteUserMutation.mutate({
        url: usersApi,
        method: HttpMethods.DELETE,
        data: data
    }, {
        onSuccess,
        onError
    })
}

// upload file
export const uploadFile = (
    file: File,
    uploadMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onUploadSuccess: (key: string, image: string) => void,
    onUploadError: () => void) => {
    let formData = new FormData();
    formData.append('selectedFile', file);
    uploadMutation.mutate({
        url: fileUploadApi,
        method: HttpMethods.POST,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data: formData
    },
        {
            onSuccess: (res) => onUploadSuccess(res.data.data.fileData.key, URL.createObjectURL(file)),
            onError: onUploadError
        })
}

// fetch all users
export const getUsersList = (search: string, limit: number, lastKey: string) => {
    return HttpServiceHelper({
        url: usersApi + "?sort[createdAt]=desc&search=" + search + "&limit=" + limit + "&lastKey=" + lastKey,
        method: HttpMethods.GET
    })
}

// activate inactivate users
export const activateInactivateUser = (
    user: Users,
    activateMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onSuccess: (user: Users) => void,
    onError: () => void) => {
    activateMutation.mutate({
        url: userStatusApi,
        method: HttpMethods.POST,
        data: {
            id: user.id,
            newStatus: user.isActive
        }
    }, {
        onSuccess: () => onSuccess(user),
        onError
    });
}

// get form disabled status
export const isFormDisabled = (queryClient: QueryClient, editUser: Users | undefined) => {
    return (!checkPermission(queryClient, PermissionKeys.MANAGE_USERS, SubPermissionKeys.ADD)) && editUser === undefined;
}

export const countries: Country[] = [
    { name: 'United States', code: 'US', langKey: 'en', langName: 'English' },
    { name: 'Italy', code: 'IT', langKey: 'it', langName: 'Italian' },
    { name: 'Spain', code: 'ES', langKey: 'es', langName: 'Spanish' },
    { name: 'France', code: 'FR', langKey: 'fr', langName: 'French' },
    { name: 'Germany', code: 'DE', langKey: 'de', langName: 'German' },
    { name: 'China', code: 'CN', langKey: 'zh', langName: 'Chinese' },
    { name: 'Japan', code: 'JP', langKey: 'ja', langName: 'Japanese' },
    { name: 'India', code: 'IN', langKey: 'hi', langName: 'Hindi' },
    { name: 'ArabicLeague', code: 'AE', langKey: 'ar', langName: 'Arabic' },
    {
      name: 'Portugal',
      code: 'PT',
      langKey: 'pt-PT',
      langName: 'Portuguese',
    },
    { name: 'India', code: 'IN', langKey: 'ml', langName: 'Malayalam' },
]

export const getFlagImage = ({ countryCode }: any) => {
    return `http://purecatamphetamine.github.io/country-flag-icons/1x1/${countryCode || 'US'}.svg`
}

// Check if user exists in other tenants
export const getTenantUser = (type: 'email' | 'phone', value: string) => {
    return HttpServiceHelper({
        url: getTenantUserApi + '/' + type,
        method: HttpMethods.POST,
        data: {
            checkValue: value
        }
    })
}

// copy users (staff)
export const copyUserToCurrentTenant = (
    data: CopyUserFormData,
    copyUserMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onSuccess: () => void,
    onError: (res: unknown) => void
) => {
    copyUserMutation.mutate({
        url: cloneUserApi,
        method: HttpMethods.POST,
        data
    }, {
        onSuccess,
        onError
    })
}