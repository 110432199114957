export const apiKey = process.env.REACT_APP_FIREBASE_API_KEY
export const authDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN
export const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID
export const storageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
export const messagingSenderId = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
export const appId = process.env.REACT_APP_FIREBASE_APP_ID
export const publicVapidKey = process.env.REACT_APP_FIREBASE_PUBLIC_VAPID_KEY

export const firebaseConfig = {
	apiKey,
	authDomain,
	projectId,
	storageBucket,
	messagingSenderId,
	appId,
}
