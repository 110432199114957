import { useEffect, useRef, useState } from 'react'
import { AlertVariant, DateFormats, TimeFormats, toastMessageInitialData } from '../../constants/constants'
import { AxiosResponse } from 'axios'
import { BrandingResponseData, TimezoneInfo, TimezoneInfoFormData, ToastMessageProps, BusinessHoursType, BusinessDefaultHours } from '../../constants/staticTypes'
import { CANCEL, UPDATE } from '../../constants/strings'
import { Divider } from 'primereact/divider'
import { Dropdown } from 'primereact/dropdown'
import { getTenantInfo } from '../../helpers/authHelper'
import { JTranslation } from '../../helpers/jTranslate'
import { MomentTimezones, businessHours, businessHoursInitialValues, changeBusinessHourDataStructure, defaultTimezoneInfo, hasInvalidEndTimeError, updateDateSettings } from '../../helpers/generalSettingsHelper'
import { TENANT_INFO } from '../../constants/queryKeys'
import { FormikErrors, useFormik } from 'formik'
import { useQuery, useQueryClient } from 'react-query'
import BusinessDays from './BusinessDays'
import ToastAlert from '../../components/alert/ToastAlert'
import useMutationHook from '../../hooks/useMutationHook'

function GeneralSettings() {
	const queryClient = useQueryClient()
	const dateSettingsMutation = useMutationHook(queryClient, true)
	const timeZones = MomentTimezones()
	const { data: tenantResponseData } = useQuery<AxiosResponse<any, any>>(TENANT_INFO, getTenantInfo)

	// STATE VARIABLES
	const [formSettingsData, setFormSettingsData] = useState<TimezoneInfo | null>(null)
	const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
	const [businessHoursFormData, setBusinessHoursFormData] = useState<BusinessHoursType>(businessHoursInitialValues)
	const [businessDefaultHours, setBusinessDefaultHours] = useState<BusinessDefaultHours[]>(businessHours)
	const [businessHoursErrors, setBusinessHoursErrors] = useState<FormikErrors<BusinessHoursType>>({})
	const submitFnRef = useRef<any>(null);

	const onSuccess = () => {
		setToastMessage({ message: 'Successfully updated', show: true, variant: AlertVariant.SUCCESS })
		queryClient.refetchQueries([TENANT_INFO])
	}

	const onError = (error: string, variant: string) =>
		setToastMessage({ message: error, show: true, variant: variant })

	// FORMIK INITIALIZATION
	const settingsFromik = useFormik({
		enableReinitialize: true,
		initialValues: formSettingsData ?? defaultTimezoneInfo,
		validate: (data) => {
			let errors: any = {}
			if (data.isDayLightSaving) {
				if (data.dayLightSavingOffSetInMinutes === undefined || data.dayLightSavingOffSetInMinutes === null) {
					errors.dayLightSavingOffSetInMinutes = 'requried'
				}
			}
			return errors
		},
		onSubmit: (data) => {
			const error = hasInvalidEndTimeError(businessHoursErrors)
			if (error) return
			const formData: TimezoneInfoFormData = {
				...data,
				dayLightSavingOffSetInMinutes: data.dayLightSavingOffSetInMinutes.toString(),
				businessDefaultHours: businessDefaultHours,
			}
			updateDateSettings(dateSettingsMutation, formData, onSuccess, onError)
		},
	})

	useEffect(() => {
		const tenantInfo = tenantResponseData?.data?.data as BrandingResponseData
		const businessHours = changeBusinessHourDataStructure(tenantInfo.businessDefaultHours)

		const timeZoneInfo: TimezoneInfo = {
			isDayLightSaving: tenantInfo?.branding?.isDayLightSaving ?? defaultTimezoneInfo.isDayLightSaving,
			dayLightSavingOffSetInMinutes: tenantInfo?.branding?.dayLightSavingOffSetInMinutes
				? +tenantInfo?.branding?.dayLightSavingOffSetInMinutes
				: defaultTimezoneInfo.dayLightSavingOffSetInMinutes,
			defaultTimezone: tenantInfo?.branding?.defaultTimezone ?? defaultTimezoneInfo.defaultTimezone,
			defaultDateFormat: tenantInfo?.branding?.defaultDateFormat ?? defaultTimezoneInfo.defaultDateFormat,
			defaultTimeFormat: tenantInfo?.branding?.defaultTimeFormat ?? defaultTimezoneInfo.defaultTimeFormat,
			weekStartsOn: tenantInfo?.branding?.weekStartsOn ?? defaultTimezoneInfo.weekStartsOn,
		}
		setFormSettingsData(timeZoneInfo)
		setBusinessHoursFormData(businessHours)
	}, [])

	return (
		<>
			<ToastAlert
				data-testid="toast"
				message={toastMessage.message}
				onClose={() => setToastMessage(toastMessageInitialData)}
				show={toastMessage.show}
				variant={toastMessage.variant}
			/>

			<div className="row h-100 pb-5 overflow-auto">
				<form onSubmit={settingsFromik.handleSubmit} className="col-md-12 mb-3 festival-form">
					<div className="col-md-6 col-lg-5">
						<div className="row">
							<div className="col-md-12 mb-3">
								<label className="form-label">
									<JTranslation typeCase="pascal" text={'Select Time Zone'} />
								</label>
								<div className="p-inputgroup flex-1">
									<Dropdown
										className={`festival-dropdown`}
										//appendTo={'self'}
										data-testid="timezone-dropdown"
										value={settingsFromik?.values?.defaultTimezone}
										onChange={(event) => {
											settingsFromik.setFieldValue('defaultTimezone', event.target.value, true)
										}}
										options={timeZones}
										optionLabel="label"
										optionValue="value"
										placeholder={''}
										filter
										resetFilterOnHide={true}
									/>
								</div>
								{settingsFromik?.values?.defaultTimezone && (
									<small className="w-100 text-secondary d-flex justify-content-end py-1 pe-2">
										{timeZones.find((tz) => tz.value === settingsFromik?.values?.defaultTimezone)?.example}
									</small>
								)}
							</div>

							<div className="col-md-6">
								<label className="form-label">
									<JTranslation typeCase="pascal" text={'Choose Date Format'} />
								</label>
								<div className="p-inputgroup flex-1">
									<Dropdown
										className={`festival-dropdown`}
										//appendTo={'self'}
										data-testid="date-format-dropdown"
										value={settingsFromik?.values?.defaultDateFormat}
										onChange={(event) => {
											settingsFromik.setFieldValue('defaultDateFormat', event.target.value, true)
										}}
										options={DateFormats}
										optionLabel="format"
										optionValue="format"
										placeholder={''}
									/>
								</div>
								{settingsFromik?.values?.defaultDateFormat && (
									<small className="w-100 text-secondary d-flex justify-content-end py-1 pe-2">
										{
											DateFormats.find((df) => df.format === settingsFromik?.values?.defaultDateFormat)
												?.example
										}
									</small>
								)}
							</div>

							<div className="col-md-6">
								<label className="form-label">
									<JTranslation typeCase="pascal" text={'Choose Time Format'} />
								</label>
								<div className="p-inputgroup flex-1">
									<Dropdown
										className={`festival-dropdown`}
										//appendTo={'self'}
										data-testid="time-format-dropdown"
										value={settingsFromik?.values?.defaultTimeFormat}
										onChange={(event) => {
											settingsFromik.setFieldValue('defaultTimeFormat', event.target.value, true)
										}}
										options={TimeFormats}
										optionLabel="format"
										optionValue="format"
										placeholder={''}
									/>
								</div>
								{settingsFromik?.values?.defaultTimeFormat && (
									<small className="w-100 text-secondary d-flex justify-content-end py-1 pe-2">
										{
											TimeFormats.find((tf) => tf.format === settingsFromik?.values?.defaultTimeFormat)
												?.example
										}
									</small>
								)}
							</div>
						</div>



						{/* <div className="col-md-6 col-lg-4 mt-4">
						<div className={`festival-page-selection`}>
							<div className="d-flex">
								<div className="flex-grow-0">
									<Button icon="pi pi-home" />
								</div>
								<div className="flex-grow-1 ps-2 d-flex align-items-center justify-content-between text-nowrap overflow-hidden ">
									<span className="menu-text">
										<JTranslation typeCase="pascal" text={'Daylight Saving'} />
									</span>
									<span className="form-switch">
										<input
											className="form-check-input form-check-input-custom"
											type="checkbox"
											autoComplete="off"
											checked={settingsFromik?.values?.isDayLightSaving}
											data-testid="daylight-saving-check"
											onChange={(event) => {
												settingsFromik.setFieldValue(
													'isDayLightSaving',
													event.target.checked,
													true
												)
											}}
											id="flexSwitchCheckDefault"
											style={{ width: '3em', height: '1.5em' }}
										/>
									</span>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-lg-4 mt-4">
						<label className="form-label">
							<JTranslation typeCase="pascal" text={'Offset (in minutes)'} />
						</label>
						<div className="p-inputgroup flex-1">
							<InputNumber
								className={
									settingsFromik.errors.dayLightSavingOffSetInMinutes ? 'border border-danger' : ''
								}
								value={settingsFromik?.values?.dayLightSavingOffSetInMinutes}
								onChange={(event) => {
									settingsFromik.setFieldValue('dayLightSavingOffSetInMinutes', event.value, true)
								}}
								pt={{
									input: {
										root: { autoComplete: 'off' },
									},
								}}
							/>
						</div>
					</div> */}


					</div>

					<div className="col-md-6 col-lg-5 mt-4">

					</div>

					<Divider className="py-1" />

					<div className="mb-4">
						<div className='mb-4'>
							<strong className="form-label">
								<JTranslation typeCase="pascal" text={'Business Hours'} />
							</strong>
						</div>

						<BusinessDays
							businessHoursFormData={businessHoursFormData}
							setBusinessHoursFormData={setBusinessHoursFormData}
							setBusinessDefaultHours={setBusinessDefaultHours}
							submitFnRef={submitFnRef}
							setBusinessHoursErrors={setBusinessHoursErrors}
						/>
					</div>

					<div className="save-btn-section shadow save-btn-absolute">
						<button
							className="btn btn-custom-primary-outline ms-2"
							type="button"
							data-testid="clear-button"
							disabled={!settingsFromik.dirty}
							onClick={() => {
								const tenantResponseData =
									queryClient.getQueryData<AxiosResponse<any, any>>(TENANT_INFO)
								const tenantInfo = tenantResponseData?.data?.data as BrandingResponseData
								settingsFromik.setValues(
									{
										isDayLightSaving:
											tenantInfo?.branding?.isDayLightSaving ??
											defaultTimezoneInfo.isDayLightSaving,
										dayLightSavingOffSetInMinutes: tenantInfo?.branding
											?.dayLightSavingOffSetInMinutes
											? +tenantInfo?.branding?.dayLightSavingOffSetInMinutes
											: defaultTimezoneInfo.dayLightSavingOffSetInMinutes,
										defaultTimezone:
											tenantInfo?.branding?.defaultTimezone ??
											defaultTimezoneInfo.defaultTimezone,
										defaultDateFormat:
											tenantInfo?.branding?.defaultDateFormat ??
											defaultTimezoneInfo.defaultDateFormat,
										defaultTimeFormat:
											tenantInfo?.branding?.defaultTimeFormat ??
											defaultTimezoneInfo.defaultTimeFormat,
										weekStartsOn:
											tenantInfo?.branding?.weekStartsOn ?? defaultTimezoneInfo.weekStartsOn,
									},
									true
								)
							}}
						>
							<JTranslation typeCase="pascal" text={CANCEL} />
						</button>

						<button
							className="btn btn-custom-primary ms-2"
							type="submit"
							data-testid="save-button"
							onClick={() => submitFnRef.current()}
						>
							<JTranslation typeCase="pascal" text={UPDATE} />
						</button>
					</div>
				</form>
			</div>
		</>
	)
}

export default GeneralSettings
