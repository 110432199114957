import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import { DocViewerDocs } from '../../constants/staticTypes'

const DocumentViewer = ({ docs }: DocViewerDocs) => {
  const config = {
    header: { disableFileName: true },
  }

  return (
    <DocViewer
      documents={docs}
      pluginRenderers={DocViewerRenderers}
      prefetchMethod="GET"
      config={config}
    />
  )
}

export default DocumentViewer