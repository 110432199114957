import { useRef, useEffect, useCallback } from "react";
import { Field, FieldProps } from "formik";
import { RecurrenceProps } from "../../../constants/staticTypes";
import { Dropdown } from 'primereact/dropdown';
import { JTranslation } from "../../../helpers/jTranslate";
import { dayOptions, monthDays, weekdays } from "../../../helpers/newsAndEventsHelper";
import { InputNumber } from "primereact/inputnumber";
import { RadioButton } from "primereact/radiobutton";
import { debounce } from 'lodash';

const Monthly = ({ errors, setNextOccurrence }: RecurrenceProps) => {
    const inputNumberRef1 = useRef<HTMLInputElement>(null)
    const inputNumberRef2 = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (inputNumberRef1.current && inputNumberRef2.current) {
            inputNumberRef1.current.autocomplete = "off"
            inputNumberRef2.current.autocomplete = "off"
        }
    }, [])

    // Debounced function to set field value
    const debouncedSetFieldValue = useCallback(debounce((form, fieldName, value) => {
        if (value) form.setFieldValue(fieldName, value);
    }, 1000), []);

    return (
        <>
            <div className="d-flex align-items-center gap-1 mb-3 every-month-main">
                <div className="every-check">
                    <Field name="every">
                        {({ field, form }: FieldProps) => (
                            <RadioButton
                                name={field.name}
                                className={errors.every ? 'p-invalid' : undefined}
                                value="every"
                                data-testid="daily-radio-option-every"
                                checked={field.value === "every"}
                                onChange={(e) => {
                                    form.setFieldValue(field.name, e.value)
                                    setNextOccurrence(null)
                                }}
                            />
                        )}
                    </Field>
                    <label className="ms-2"><JTranslation typeCase="none" text="Every" /></label>
                </div>
                <div className="width-110">
                    <Field name="selectedOrdinal">
                        {({ field, form }: FieldProps) => (
                            <div className="p-field">
                                <Dropdown
                                    id={field.name}
                                    {...field}
                                    options={dayOptions}
                                    onChange={(e) => form.setFieldValue(field.name, e.value)}
                                    onBlur={field.onBlur}
                                    disabled={form.values.every === 'every-weekday' || form.values.every === undefined}
                                    className={errors.selectedOrdinal ? 'p-invalid' : ''}
                                    data-testid="weekly-repeats-dropdown"

                                />
                            </div>
                        )}
                    </Field>
                </div>
                <div className="width-110">
                    <Field name="selectedDaysOfWeek">
                        {({ field, form }: FieldProps) => (
                            <div className="p-field">
                                <Dropdown
                                    id={field.name}
                                    {...field}
                                    options={weekdays}
                                    onChange={(e) => form.setFieldValue(field.name, e.value)}
                                    onBlur={field.onBlur}
                                    disabled={form.values.every === 'every-weekday' || form.values.every === undefined}
                                    className={errors.selectedDaysOfWeek ? 'p-invalid' : ''}
                                    data-testid="weekly-repeats-dropdown"
                                />
                            </div>
                        )}
                    </Field>
                </div>
                <div className="">
                    <JTranslation typeCase="none" text=" of every" />
                </div>
                <div className="">
                    <Field name="repeatsEveryNthMonth">
                        {({ field, form }: FieldProps) => (
                            <>
                                <InputNumber
                                    name={field.name}
                                    inputClassName={errors.repeatsEveryNthMonth ? 'p-invalid' : undefined}
                                    onChange={(e) => debouncedSetFieldValue(form, field.name, e.value)}
                                    value={field.value}
                                    disabled={form.values.every === 'every-weekday' || form.values.every === undefined}
                                    min={1}
                                    max={99}
                                    data-testid="daily-repeats-every-nth-day"
                                    className='width-50'
                                    inputRef={inputNumberRef1}
                                />
                                <span className='m-2'>
                                    <JTranslation typeCase="none" text={field.value > 1 ? "months" : 'month'} />
                                </span>
                            </>
                        )}
                    </Field>
                </div>
            </div>

            <div className="d-flex align-items-center gap-2 every-month-main-second">
                <div className="every-check">
                    <Field name="every">
                        {({ field, form }: FieldProps) => (
                            <RadioButton
                                name={field.name}
                                className={errors.every ? 'p-invalid' : undefined}
                                value="every-weekday"
                                data-testid="daily-radio-option-every-weekday"
                                checked={field.value === "every-weekday"}
                                onChange={(e) => {
                                    form.setFieldValue(field.name, e.value)
                                    setNextOccurrence(null)
                                }}
                            />
                        )}
                    </Field>
                    <label className="ms-2"><JTranslation typeCase="none" text="On the " /></label>
                </div>
                <div className="width-110">
                    <Field name="selectedMonthDay">
                        {({ field, form }: FieldProps) => (
                            <div className="p-field">
                                <Dropdown
                                    id={field.name}
                                    {...field}
                                    options={monthDays}
                                    onChange={(e) => form.setFieldValue(field.name, e.value)}
                                    onBlur={field.onBlur}
                                    disabled={form.values.every === 'every' || form.values.every === undefined}
                                    className={errors.selectedMonthDay ? 'p-invalid' : ''}
                                    data-testid="weekly-repeats-dropdown"
                                />
                            </div>
                        )}
                    </Field>
                </div>
                <div className="">
                    <JTranslation typeCase="none" text="of every " />
                </div>
                <div className="">
                    <Field name="repeatsEveryNthMonthRow2">
                        {({ field, form }: FieldProps) => (
                            <>
                                <InputNumber
                                    name={field.name}
                                    inputClassName={errors.repeatsEveryNthMonthRow2 ? 'p-invalid' : undefined}
                                    onChange={(e) => debouncedSetFieldValue(form, field.name, e.value)}
                                    value={field.value}
                                    disabled={form.values.every === 'every' || form.values.every === undefined}
                                    min={1}
                                    max={99}
                                    data-testid="daily-repeats-every-nth-day"
                                    className='width-50'
                                    inputRef={inputNumberRef2}
                                />
                                <span className='m-2'>
                                    <JTranslation typeCase="none" text={field.value > 1 ? "months" : 'month'} />
                                </span>
                            </>
                        )}
                    </Field>
                </div>
            </div>
        </>

    )
}

export default Monthly;