import { useContext, useState } from 'react'
import { addGlobalStaffingApi, days, getGlobalStaffing, initialDataMinimumEmployeeCount, weeklyEmployeeCountValidationSchema } from '../../helpers/workTimeScheduleHelper'
import { AddGlobalStaffingCount, MinimumEmployeeCountType, PerDayCount, ToastMessageProps } from '../../constants/staticTypes'
import { AxiosResponse } from 'axios'
import { CommonCtx } from '../../context/CommonCtxProvider'
import { FieldArray, Form, Formik } from 'formik'
import { JTranslation } from '../../helpers/jTranslate'
import { SAVE, CANCEL, STAFF_COUNT } from '../../constants/strings'
import { STAFFING_REQUIREMENTS_LIST } from '../../constants/queryKeys'
import { useQueryClient } from 'react-query'
import Offcanvas from 'react-bootstrap/Offcanvas'
import StaffCountCard from './StaffCountCard'
import useMutationHook from '../../hooks/useMutationHook'
import useQueryHook from '../../hooks/useQueryHook'

type Props = {
	setToastMessage: React.Dispatch<React.SetStateAction<ToastMessageProps>>
}

function MinimumEmployeeCountSideBar({ setToastMessage }: Props) {
	const queryClient = useQueryClient()
	const globalStaffingMutation = useMutationHook(queryClient, true)
	const { showSideBar, setShowSideBar } = useContext(CommonCtx)
	const [staffingRequirement, setStaffingRequirement] = useState(initialDataMinimumEmployeeCount)
	const closeSidebar = () => setShowSideBar(false)

	const onSuccessOfGlobalStaffing = (res: AxiosResponse<any, any>) => {
		const staffingData: PerDayCount[] = res.data.data.lists
		// Sort data to match the order of the pascalCaseDays array
		const sortedData = days.map(dayName => staffingData.find(weekDay => weekDay.dayName.toLowerCase() === dayName));
		setStaffingRequirement({ availability: sortedData as PerDayCount[]})
	}

	// on create custom menu success
	const onSuccess = (message: string, variant: string) => {
		staffingQuery.refetch()
		setToastMessage({ message, variant, show: true })
	}

	// on api error
	const onError = (message: string, variant: string) => {
		staffingQuery.refetch()
		setToastMessage({ message, variant, show: true })
	}

	const putStaffingRequirementsApiCall = (formData: MinimumEmployeeCountType) => {
		const globalStaffingData: AddGlobalStaffingCount = {
			data: formData.availability,
		}
		addGlobalStaffingApi(globalStaffingMutation, globalStaffingData, onSuccess, onError)
	}

	// api call to fetch staffing requirements
	const staffingQuery = useQueryHook(STAFFING_REQUIREMENTS_LIST, () => getGlobalStaffing(), onSuccessOfGlobalStaffing)

	return (
		<Offcanvas
			show={showSideBar}
			onHide={() => closeSidebar()}
			backdrop="static"
			// responsive="xl"
			placement="end"
			className="custom-offcanvas minimum-employee-sidebar"
		>
			<Offcanvas.Header closeButton>
				<Offcanvas.Title>
					<JTranslation text={STAFF_COUNT} />
				</Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body>
				<Formik
					initialValues={staffingRequirement}
					validationSchema={weeklyEmployeeCountValidationSchema}
					enableReinitialize={true}
					onSubmit={putStaffingRequirementsApiCall}
				>
					{({ errors, touched }) => (
						<Form>
							<div className="row">
								<div className="col-md-12 col-lg-12 mb-3">
									<FieldArray
										name="data"
										render={() => (
											<div className="">
												<div className="row">
													<div className="col-md-3"></div>
													<div className="col-md-5">
														<label className="form-label d-flex">
															<JTranslation
																typeCase="pascal"
																text="Minimum staff count"
															/>
															<span className="mandatory">*</span>
														</label>
													</div>
												</div>

												{staffingRequirement.availability.map((weekDay, index) => (
													<div key={index} className="row mb-2">
														<div className="col-md-3">
															<JTranslation typeCase="pascal" text={weekDay ? weekDay.dayName : ""} />
														</div>
														<div className="col-md-9">
															<StaffCountCard
																errors={errors}
																touched={touched}
																index={index}
															/>
														</div>
													</div>
												))}
											</div>
										)}
									/>
								</div>
							</div>
							<div></div>
							<div className="save-btn-section shadow save-btn-absolute">
								<button
									className="btn btn-custom-primary-outline"
									type="reset"
									data-testid="cancel-btn"
									onClick={() => setShowSideBar(false)}
								>
									<JTranslation typeCase="pascal" text={CANCEL} />
								</button>

								<button className="btn btn-custom-primary" type="submit" data-testid="save-btn">
									<JTranslation typeCase="pascal" text={SAVE} />
								</button>
							</div>
						</Form>
					)}
				</Formik>
			</Offcanvas.Body>
		</Offcanvas>
	)
}

export default MinimumEmployeeCountSideBar
