import { useEffect } from 'react'
import { STAR_RATING_POSITIONS, SurveyQuestionType } from '../../../constants/constants'
import {
  MatrixRowAnswer,
  Survey,
  SurveyCategories,
  SurveyQuestion,
  SurveyMarks,
  SurveyLookupTable,
} from '../../../constants/staticTypes'
import { NO, NOT_APPLICABLE, TYPE_ANSWER, YES } from '../../../constants/strings'
import { Slider } from 'antd'
import { JTranslation } from '../../../helpers/jTranslate'

type Props = {
  survey: Survey | undefined
  setSurvey: React.Dispatch<React.SetStateAction<Survey | undefined>>
  comments: string
  setComments: React.Dispatch<React.SetStateAction<string>>
  surveyAnswerValidation: { [key: string]: number | boolean }[]
}

function SurveyQuestions({ survey, setSurvey, comments, setComments, surveyAnswerValidation }: Props) {
  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 100)
  }, [])

  // get survey question class based on validity
  const getSurveyQuestionClassname = (catIndex: number, qstnIndex: number) => {
    let classname = 'question-box-outer custom-shadow p-3 rounded-2 text-break'
    if (surveyAnswerValidation.length > 0) {
      const answerItem = surveyAnswerValidation.filter(
        (item) => item.catIndex === catIndex && item.qstnIndex === qstnIndex
      )[0]
      if (answerItem && answerItem.isValidData === false) {
        classname = classname + ' border-red'
      }
    }
    return classname
  }

  // update answer
  const onAnswerUpdate = (
    value: number | boolean | string | MatrixRowAnswer,
    category: SurveyCategories,
    question: SurveyQuestion
  ) => {
    if (survey) {
      const catIndex = survey.surveyCategories.indexOf(category)
      const qstnIndex = category.surveyQuestions.indexOf(question) || 0

      if (question.surveyQuestionType === SurveyQuestionType.MATRIX_TABLE) {
        category.surveyQuestions.splice(qstnIndex, 1, {
          ...category.surveyQuestions[qstnIndex],
          matrixRowsAnswers: {
            ...category.surveyQuestions[qstnIndex].matrixRowsAnswers,
            ...(value as MatrixRowAnswer),
          },
        })
      } else {
        category.surveyQuestions.splice(qstnIndex, 1, {
          ...category.surveyQuestions[qstnIndex],
          surveyQuestionAnswer: value as string | boolean | number,
        })
      }

      survey.surveyCategories.splice(catIndex, 1, {
        ...category,
      })

      setSurvey({
        ...survey,
        surveyCategories: [...survey.surveyCategories],
      })
    }
  }

  const mapValueToRange = (value: number = 0): number | null => {
    const lookupTable: SurveyLookupTable = {
      0: 1,
      25: 2,
      50: 3,
      75: 4,
      100: 5,
    }

    if (value in lookupTable) {
      return lookupTable[value]
    }

    return null
  }

  // get answer ui based on question type
  const getAnswerUI = (category: SurveyCategories, question: SurveyQuestion) => {
    const catIndex = survey?.surveyCategories.indexOf(category)
    const qstnIndex = category.surveyQuestions.indexOf(question)

    switch (question.surveyQuestionType) {
      case SurveyQuestionType.STAR_RATING:
        const starFill = question.surveyQuestionAnswer as number
        return (
          <div className="col-8 col-sm-8 col-md-8 mb-2 star">
            <div className="star-rating">
              {STAR_RATING_POSITIONS.map((_starValue, index) => {
                let className = 'ri-star-line'
                if (index < starFill) {
                  className = 'ri-star-fill'
                }
                return (
                  <i
                    className={className}
                    style={{ cursor: 'pointer' }}
                    key={index}
                    data-testid={"star-rating" + index}
                    onClick={() => onAnswerUpdate(index + 1, category, question)}
                  ></i>
                )
              })}
            </div>
          </div>
        )

      case SurveyQuestionType.TRUE_FALSE:
        let checked
        switch (question.surveyQuestionAnswer) {
          case true:
            checked = true
            break

          case false:
            checked = false
            break

          default:
            checked = ''
            break
        }

        return (
          <div className='col-md-12 mb-3'>
            <div className='form-check form-check-inline'>
              <input
                className='form-check-input'
                type='radio'
                autoComplete='off'
                checked={checked as boolean}
                data-testid="true-answer"
                onClick={() => onAnswerUpdate(true, category, question)}
                id={'toggle-switch#' + catIndex + '#' + qstnIndex + '#yes'}
              />
              <label className='form-check-label'>
                <JTranslation text={YES} />
              </label>
            </div>
            <div className='form-check form-check-inline'>
              <input
                className='form-check-input'
                type='radio'
                autoComplete='off'
                data-testid="false-answer"
                checked={checked !== '' && !(checked as boolean)}
                onClick={() => onAnswerUpdate(false, category, question)}
                id={'toggle-switch#' + catIndex + '#' + qstnIndex + '#no'}
              />
              <label className='form-check-label'>
                <JTranslation text={NO} />
              </label>
            </div>
            <div className='form-check form-check-inline'>
              <input
                className='form-check-input'
                type='radio'
                autoComplete='off'
                data-testid="no-answer"
                checked={
                  question.surveyQuestionAnswer !== undefined && checked === ''
                }
                onClick={() => onAnswerUpdate('', category, question)}
                id={
                  'toggle-switch#' +
                  catIndex +
                  '#' +
                  qstnIndex +
                  '#not-available'
                }
              />
              <label className='form-check-label'>
                <JTranslation text={NOT_APPLICABLE} />

              </label>
            </div>
          </div>
        )

      // case SurveyQuestionType.MATRIX_TABLE:
      //     let matrixChoices = [] as string[];
      //     if (question.matrixColumns) {
      //         matrixChoices = question.matrixColumns[0].choices;
      //     }
      //     return <div className="container table-container" role="table">
      //         <div className="table-scroll">
      //             <div className="flex-table header" role="rowgroup">
      //                 <div className="flex-row-new first" role="columnheader"></div>
      //                 {matrixChoices && matrixChoices.map((column, colIndex) => {
      //                     return <div key={colIndex} className="flex-row-new" role="columnheader">{column}</div>
      //                 })}
      //             </div>

      //             {question.matrixRows && question.matrixRows.map((row, rowIndex) => {
      //                 return <div className="flex-table " role="rowgroup" key={rowIndex}>
      //                     <div className="flex-row-new first" role="cell">
      //                         {row}
      //                     </div>
      //                     {matrixChoices && matrixChoices.map((_col, index) => {
      //                         let checkedColumn = -1;
      //                         if (question.matrixRowsAnswers && question.matrixRowsAnswers[row]) {
      //                             const value = question.matrixRowsAnswers[row].firstColumn;
      //                             checkedColumn = value - 1;
      //                         }
      //                         return <div className="flex-row-new" role="cell">
      //                             <div className="form-check  ">
      //                                 <input className="form-check-input" type="radio" autoComplete="off"
      //                                     id={rowIndex + "#" + index}
      //                                     onClick={() => {
      //                                         const matrixRowAnswer = {
      //                                             [row]: {
      //                                                 firstColumn: index + 1
      //                                             }
      //                                         }
      //                                         onAnswerUpdate(matrixRowAnswer, category, question);
      //                                     }}
      //                                     checked={checkedColumn === index} />
      //                             </div>
      //                         </div>
      //                     })}
      //                 </div>
      //             })}
      //         </div>
      //     </div>
      case SurveyQuestionType.MATRIX_TABLE:
        let matrixChoices = [] as string[]
        let marks: SurveyMarks = {}
        if (question.matrixColumns) {
          matrixChoices = question.matrixColumns[0].choices
          marks = {
            0: {
              style: {
                transform: 'translateX(0%)',
              },
              label: matrixChoices[0],
            },

            25: {
              label: matrixChoices[1],
            },
            50: {
              label: matrixChoices[2],
            },
            75: {
              label: matrixChoices[3],
            },
            100: {
              style: {
                // color: '#f50',
                //right:"0",
                left: '100%',
                transform: 'translateX(-100%)',
              },
              label: matrixChoices[4],
            },
          }
        }

        const getLabel = (row: string, matrixRowsAnswers: any) => {
          if (matrixRowsAnswers?.[row]?.firstColumn) {


            const index = matrixRowsAnswers?.[row]?.firstColumn
              ? matrixRowsAnswers?.[row]?.firstColumn - 1
              : 0

            return (
              <JTranslation
                text={`<strong>- ${matrixChoices[index]}</strong>`}
                type='html'
              />
            )
          }
          return (<span className='no-option-selected'>  <JTranslation
                text="- Select an option" type='html'/></span>)
        }

        return (
          <div className="px-3 table-container" role="table">
            {question.matrixRows?.map((row, rowIndex) => {
              return (
                <>
                  {row ? row : ''}
                  {/* function call to display the selected label on top the slider */}
                  {getLabel(row, question.matrixRowsAnswers)}

                  <Slider
                    className='custom-slider-mark'
                    marks={marks}
                    step={null}
                    data-testid="slider-answer"
                    tooltip={{
                      // @ts-ignore
                      formatter: (value: number) => `${marks?.[value]?.label}`,
                    }}
                    onChange={(value) => {
                      const mappedValue: number | null = mapValueToRange(value)
                      if (mappedValue) {
                        const matrixRowAnswer = {
                          [row]: {
                            firstColumn: mappedValue,
                          },
                        }
                        onAnswerUpdate(matrixRowAnswer, category, question)
                      }
                    }}
                    dots={true}
                    railStyle={{ background: '#E8E8E8' }}
                    trackStyle={{ background: '#FEAA15' }}
                  />
                </>
              )
            })}
          </div>
        )

      case SurveyQuestionType.TEXT_BOX:
        return (
          <div className="col-md-12">
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                autoComplete="off"
                id={'text-input'}
                maxLength={200}
                data-testid="text-answer"
                placeholder={TYPE_ANSWER}
                onChange={(e) => onAnswerUpdate(e.target.value, category, question)}
              />
            </div>
          </div>
        )

      default:
        const textAreaText = question.surveyQuestionAnswer as string
        return (
          <div className="col-md-12">
            <div className="mb-3">
              <textarea
                className="form-control"
                id={'text-area'}
                maxLength={2000}
                onChange={(e) => onAnswerUpdate(e.target.value, category, question)}
                value={textAreaText}
                rows={3}
                placeholder={TYPE_ANSWER}
                data-testid="textarea-answer"
              ></textarea>
            </div>
          </div>
        )
    }
  }

  return (
    <>
      <div className='row mt-3'>
        <div className='col-md-12 text-center  mb-4'>
          <h3 className='mb-0 color-primary-custom'>
            <JTranslation text='Rate your Experience' />
          </h3>
        </div>
      </div>

      {/* <!-----question set-----> */}
      {survey?.surveyCategories.map((category, index) => {
        return (
          <div className='row' key={index}>
            <div className='col-md-12 mt-3  mb-2'>
              <h5 className='qus-category'>
                <JTranslation text={category.surveyCategoryName} type='html' />
              </h5>
            </div>

            {category.surveyQuestions.map((question, qstnIndex) => {
              let className = 'col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3'
              if (
                question.surveyQuestionType === SurveyQuestionType.MATRIX_TABLE
              ) {
                className = 'col-md-12 mb-3'
              }
              return (
                <div className={className} key={index + '#' + qstnIndex}>
                  <div className={getSurveyQuestionClassname(index, qstnIndex)}>
                    <div className='row star-rate-outer'>
                      <div className='col-md-12'>
                        <p>
                        {question.isRequired && (
                            <span className='mandatory me-1 fw-bold'>*</span>
                          )}
                          <JTranslation
                            text={question.surveyQuestionName}
                            type='html'
                          />

                         
                        </p>
                      </div>
                      {getAnswerUI(category, question)}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )
      })}
      {/* <!-----end question set-----> */}

      {/*  <!----comments section-------> */}
      <div className='row'>
        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3'>
          <div className='question-box-outer custom-shadow p-2 rounded-2 text-break'>
            <div className='row'>
              <div className='col-md-12'>
                <p>
                  <JTranslation text="If you have any comments/ suggestions?" />

                </p>
              </div>
              <div className='col-md-12'>
                <div className='mb-3'>
                  <textarea
                    className='form-control'
                    id='comments'
                    value={comments}
                    maxLength={2000}
                    onChange={(e) => setComments(e.target.value)}
                    rows={3}
                    placeholder={TYPE_ANSWER}
                    data-testid="comments"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SurveyQuestions
