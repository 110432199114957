import { useContext, useEffect, useState } from "react"
import { ToastMessageProps } from "../../constants/staticTypes"
import { CommonCtx } from "../../context/CommonCtxProvider"
import { AlertVariant, toastMessageInitialData } from "../../constants/constants"
import Offcanvas from "react-bootstrap/esm/Offcanvas"
import ToastAlert from "../alert/ToastAlert"
import { JTranslation } from "../../helpers/jTranslate"
import { PublishedSchedule, ScheduleData, Staff } from "../../helpers/workTimeScheduleHelper"
import { useFormik } from "formik"
import { Dropdown } from "primereact/dropdown"
import { checkIfManageSwapRequest, handleShiftRequest, SwapRequestForm, swapRequestFormInitialValues, swapRequestTypes } from "../../helpers/swapScheduleHelper"
import "../../pages/shift_swap/shift_swap.scss"
import { CANCEL } from "../../constants/strings"
import { capitalizeFirstLetter, imageOnErrorHandler } from "../../helpers/utils"
import dayjs from "dayjs"
import useMutationHook from "../../hooks/useMutationHook"
import { useQueryClient } from "react-query"
import { STAFFS_WITH_SCHEDULES_AND_SHIFT } from "../../constants/queryKeys"
import { InputSwitch } from "primereact/inputswitch"

type Props = {
    shiftData: PublishedSchedule | null
    staffData: Staff | null
    staffsWithSchedule: ScheduleData | null
    handleClose: () => void
}

type DropDown = { value: string, name: string }
type ShiftDropDown = { value: string, name: string, toStartTime: string, toEndTime: string, toRole: string }

const SwapRequestFormSideBar = ({
    shiftData,
    staffData,
    staffsWithSchedule,
    handleClose,
}: Readonly<Props>) => {
    const queryClient = useQueryClient()
    const shiftRequestMutation = useMutationHook(queryClient, true)
    const { showSideBar } = useContext(CommonCtx)
    const managePermission = checkIfManageSwapRequest(queryClient)

    const [formData, setFormData] = useState<SwapRequestForm>(swapRequestFormInitialValues)
    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
    const [staffList, setStaffList] = useState<DropDown[]>([])
    const [shiftList, setShiftList] = useState<ShiftDropDown[]>([])
    const [dateList, setDateList] = useState<DropDown[]>([])
    const [selectedDate, setSelectedDate] = useState<string | null>(null)

    const onSuccess = () => {
        setToastMessage({ message: 'Shift request created successfully', show: true, variant: AlertVariant.SUCCESS })
        queryClient.refetchQueries([STAFFS_WITH_SCHEDULES_AND_SHIFT])
        cleanUpForm()
        handleClose()
    }

    const onError = (error: string, variant: string) => {
        setToastMessage({ message: error, show: true, variant: variant })
    }

    const swapRequestFormikForm = useFormik({
        enableReinitialize: true,
        initialValues: formData,
        validate: (data) => {
            let errors: any = {}
            if (data.requestType === 'swap') {
                if (!data.toTenantStaffId) errors.toTenantStaffId = 'required'
                if (!selectedDate) errors.selectedDate = 'required'
                if (!data.toShiftId) errors.toShiftId = 'required'
            }
            return errors
        },
        onSubmit: (data) => {
            // console.log(data)
            if (data.requestType === 'pool') {
                delete data.toShiftId
                delete data.toTenantStaffId
                delete data.toStartTime
                delete data.toEndTime
                delete data.toRole
            }
            handleShiftRequest(shiftRequestMutation, data, onSuccess, onError)
        },
    })

    const filterStaffList = () => {
        if (staffsWithSchedule) {
            const filterStaffListData = staffsWithSchedule.staffs.map((staff) => {
                if (swapRequestFormikForm.values.fromTenantStaffId !== staff.id) {
                    // filter only staff with shifts
                    const checkIfStaffHasShift = Object.keys(staff.shifts).length
                    if (checkIfStaffHasShift === 0) return null

                    return {
                        value: staff.id,
                        name: capitalizeFirstLetter(staff.preferredName)
                            ?? `${capitalizeFirstLetter(staff.firstName)} ${staff.lastName}`
                    }
                }
                return null
            }).filter((staff) => staff !== null)
            setStaffList(filterStaffListData as { value: string, name: string }[])
        }
    }

    const cleanUpForm = () => {
        setStaffList([])
        setShiftList([])
        setDateList([])
        setSelectedDate('')
        setFormData(swapRequestFormInitialValues)
    }

    // filter staff based on type selection
    useEffect(() => {
        if (swapRequestFormikForm.values.requestType === 'swap') {
            filterStaffList()
        }
    }, [formData.requestType, swapRequestFormikForm.values.requestType, swapRequestFormikForm.values.fromTenantStaffId])

    // filter date based on staff selection
    useEffect(() => {
        if (swapRequestFormikForm.values.toTenantStaffId && staffsWithSchedule) {
            const filterDateList: DropDown[] = []
            staffsWithSchedule.staffs.forEach((staff) => {
                if (swapRequestFormikForm.values.toTenantStaffId === staff.id) {
                    const shiftDates = Object.keys(staff.shifts)
                    shiftDates.forEach((date) => {
                        const parsedDate = dayjs(date).format('MMM D, YYYY')
                        // take dates that are not in the past and current
                        const today = dayjs()
                        if (dayjs(date).isAfter(today)) {
                            filterDateList.push({ value: date, name: parsedDate })
                        }
                    })
                }
            })
            setDateList(filterDateList as DropDown[])
        }
    }, [swapRequestFormikForm.values.toTenantStaffId])

    // filter shifts based on employee and date selection
    useEffect(() => {
        if (selectedDate && swapRequestFormikForm.values.toTenantStaffId && staffsWithSchedule) {
            const filterShiftList: ShiftDropDown[] = []
            staffsWithSchedule.staffs.forEach((staff) => {
                if (swapRequestFormikForm.values.toTenantStaffId === staff.id) {
                    const shiftDates = Object.keys(staff.shifts)
                    shiftDates.forEach((date) => {
                        if (date === selectedDate) {
                            staff.shifts[date].forEach((shift) => {
                                const updatedShift = (shift as any) as PublishedSchedule
                                filterShiftList.push({
                                    value: updatedShift.id,
                                    // name: `${updatedShift.assignedShifts.shiftName} (${updatedShift.assignedShifts.startTime} - ${updatedShift.assignedShifts.endTime})`,
                                    name: `${updatedShift.assignedShifts.startTime} - ${updatedShift.assignedShifts.endTime}`,
                                    toStartTime: updatedShift.assignedShifts.startTime,
                                    toEndTime: updatedShift.assignedShifts.endTime,
                                    toRole: updatedShift.assignedShifts.role,
                                })
                            })
                        }
                    })
                }
            })
            setShiftList(filterShiftList as ShiftDropDown[])
        }
    }, [selectedDate, swapRequestFormikForm.values.toTenantStaffId])

    useEffect(() => {
        if (shiftData && staffData && staffsWithSchedule) {
            setFormData({
                ...formData,
                scheduleId: shiftData.scheduleId,
                fromShiftId: shiftData.id,
                fromTenantStaffId: shiftData.tenantStaffId,
                fromStartTime: shiftData.assignedShifts.startTime,
                fromEndTime: shiftData.assignedShifts.endTime,
                fromRole: shiftData.assignedShifts.role,
                toShiftId: '',
                toTenantStaffId: '',
                toStartTime: '',
                toEndTime: '',
                toRole: '',
                requestType: 'swap',
            })
        }
        filterStaffList()
    }, [showSideBar])

    return (
        <Offcanvas
            show={showSideBar}
            className='custom-offcanvas'
            onHide={() => {
                cleanUpForm()
                handleClose()
            }}
            backdrop="static"
            // responsive="xl"
            placement="end"
        >
            {/* TOAST MESSAGE COMPONENT */}
            <ToastAlert
                show={toastMessage.show}
                onClose={() => setToastMessage(toastMessageInitialData)}
                message={toastMessage.message}
                variant={toastMessage.variant}
            />

            <Offcanvas.Header closeButton>
                <Offcanvas.Title><JTranslation typeCase="pascal" text={'Shift Swap'} /></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <form className="swap-request-form" onSubmit={swapRequestFormikForm.handleSubmit}>
                    <div className="d-flex flex-row">
                        <img
                            className="rounded-circle"
                            src={staffData?.signedUrl}
                            alt={staffData?.firstName}
                            style={{ width: '60px', height: '60px' }}
                            onError={imageOnErrorHandler}
                        />
                        <div className="d-flex flex-column ms-3">
                            <p className="m-0"><b>{staffData?.preferredName ?? (staffData?.firstName + ' ' + staffData?.lastName)}</b></p>
                            <small className="m-0">{shiftData?.assignedShifts ? shiftData?.assignedShifts?.role : ''}</small>
                            <small className="m-0">
                                {shiftData?.scheduleDate ? `${dayjs(shiftData?.scheduleDate).format('MMM D, YYYY')} : ` : ''}
                                {shiftData?.assignedShifts ? shiftData?.assignedShifts?.startTime : ''} - {shiftData?.assignedShifts ? shiftData?.assignedShifts?.endTime : ''}
                            </small>
                        </div>
                    </div>

                    <div className="pt-4 row">
                        <div className={`col-md-12 col-lg-12 mb-3`}>
                            <label className="form-label">
                                <JTranslation typeCase="pascal" text={'Swap To'} />
                            </label>
                            <div className="p-inputgroup flex-1">
                                <Dropdown
                                    className={`flex-grow-1 festival-dropdown`}
                                    data-testid="request-type-dropdown"
                                    value={swapRequestFormikForm.values.requestType}
                                    onChange={(event) => {
                                        const { value: type } = event.target
                                        if (type === 'pool') {
                                            // clear all fields related to swap
                                            setSelectedDate('')
                                            swapRequestFormikForm.setFieldValue('skipChecks', false, true)
                                            swapRequestFormikForm.setFieldValue('toTenantStaffId', '', true)
                                            swapRequestFormikForm.setFieldValue('toShiftId', '', true)
                                            swapRequestFormikForm.setFieldValue('toStartTime', '', true)
                                            swapRequestFormikForm.setFieldValue('toEndTime', '', true)
                                            swapRequestFormikForm.setFieldValue('toRole', '', true)
                                        }
                                        swapRequestFormikForm.setFieldValue('requestType', type, true)
                                    }}
                                    options={swapRequestTypes}
                                    optionLabel="name"
                                    optionValue="value"
                                    dataKey="request-type-dropdown"
                                />
                            </div>
                        </div>

                        <div className={`col-md-12 col-lg-12 mb-3 ${swapRequestFormikForm.values.requestType === 'pool' ? 'd-none' : ''}`}>
                            <label className="form-label">
                                <JTranslation typeCase="pascal" text={'Employee'} />
                            </label>
                            <div className="p-inputgroup flex-1">
                                <Dropdown
                                    className={`flex-grow-1 festival-dropdown ${swapRequestFormikForm.errors.toTenantStaffId ? 'border-danger' : ''}`}
                                    data-testid="to-employee-dropdown"
                                    value={swapRequestFormikForm.values.toTenantStaffId}
                                    onChange={(event) => {
                                        swapRequestFormikForm.setFieldValue('toTenantStaffId', event.target.value, true)
                                    }}
                                    options={staffList}
                                    optionLabel="name"
                                    optionValue="value"
                                    dataKey="to-employee-dropdown"
                                />
                            </div>

                            {managePermission && (
                                <div className="pt-3 row">
                                    <div className={`col-md-12 col-lg-12`}>
                                        <label className="form-label d-flex align-items-center">
                                            <InputSwitch
                                                checked={swapRequestFormikForm.values.skipChecks}
                                                onChange={(event) => {
                                                    swapRequestFormikForm.setFieldValue('skipChecks', event.value, true)
                                                }}
                                            />
                                            <span className="ms-2">
                                                <JTranslation typeCase="pascal" text={'Swap without permission'} />
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className={`col-md-12 col-lg-12 mb-3 ${swapRequestFormikForm.values.requestType === 'pool' ? 'd-none' : ''}`}>
                            <label className="form-label">
                                <JTranslation typeCase="pascal" text={'Date'} />
                            </label>
                            <div className="p-inputgroup flex-1">
                                <Dropdown
                                    className={`flex-grow-1 festival-dropdown ${(swapRequestFormikForm.errors as any)?.selectedDate ? 'border-danger' : ''}`}
                                    data-testid="to-date-dropdown"
                                    value={selectedDate}
                                    onChange={(event) => {
                                        setSelectedDate(event.target.value)
                                    }}
                                    options={dateList}
                                    optionLabel="name"
                                    optionValue="value"
                                    dataKey="to-date-dropdown"
                                />
                            </div>
                        </div>

                        <div className={`col-md-12 col-lg-12 mb-3 ${swapRequestFormikForm.values.requestType === 'pool' ? 'd-none' : ''}`}>
                            <label className="form-label">
                                <JTranslation typeCase="pascal" text={'Shift'} />
                            </label>
                            <div className="p-inputgroup flex-1">
                                <Dropdown
                                    className={`flex-grow-1 festival-dropdown ${swapRequestFormikForm.errors.toShiftId ? 'border-danger' : ''}`}
                                    data-testid="to-shift-dropdown"
                                    value={swapRequestFormikForm.values.toShiftId}
                                    onChange={(event) => {
                                        const { value: shiftId } = event.target
                                        const shift = shiftList.find((shift) => shift.value === shiftId)

                                        swapRequestFormikForm.setFieldValue('toShiftId', shiftId, true)
                                        swapRequestFormikForm.setFieldValue('toStartTime', shift?.toStartTime, true)
                                        swapRequestFormikForm.setFieldValue('toEndTime', shift?.toEndTime, true)
                                        swapRequestFormikForm.setFieldValue('toRole', shift?.toRole, true)
                                    }}
                                    options={shiftList}
                                    optionLabel="name"
                                    optionValue="value"
                                    dataKey="to-shift-dropdown"
                                />
                            </div>
                        </div>

                        {/* Footer */}
                        <div className="save-btn-section shadow save-btn-absolute">
                            <button
                                className="btn btn-custom-primary-outline ms-2"
                                type="button"
                                data-testid="clear-button"
                                onClick={() => {
                                    cleanUpForm()
                                    handleClose()
                                }}
                            >
                                <JTranslation typeCase="pascal" text={CANCEL} />
                            </button>

                            <button
                                className="btn btn-custom-primary ms-2"
                                type="submit"
                                data-testid="save-button"
                            >
                                {(swapRequestFormikForm.values.requestType === 'swap' && swapRequestFormikForm.values.skipChecks) && <JTranslation typeCase="pascal" text={'Swap'} />}
                                {(swapRequestFormikForm.values.requestType === 'swap' && !swapRequestFormikForm.values.skipChecks) && <JTranslation typeCase="pascal" text={'Request To Swap'} />}
                                {swapRequestFormikForm.values.requestType === 'pool' && <JTranslation typeCase="pascal" text={'Request To Pool'} />}
                            </button>
                        </div>
                    </div>
                </form>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default SwapRequestFormSideBar