import './styles/headerStyle.scss'
import 'react-circular-progressbar/dist/styles.css'
import { guestOnlyRoutes } from '../../constants/routes'
import { isGuestOnlyRoute } from '../../helpers/utils'
import { TranslationContext } from '../../helpers/jTranslate'
import { useContext } from 'react'
import { useInitializeGA } from '../../hooks/useTrackEvent'
import { useLocation } from 'react-router-dom'
import LanguageSwitcher from './LanguageSwitcher'
import LogoComponent from './LogoComponent'
import SpecialDayEffect from '../../components/celebrations/SpecialDayEffect'
import FontSlider from './FontSlider'

const IsGuestPage = () => {
  const location = useLocation()
  const { changeTargetLanguage } = useContext(TranslationContext)
  const isGuestRoute = isGuestOnlyRoute(location.pathname, guestOnlyRoutes)

  if (!isGuestRoute) {
    // set to english if user access management area
    changeTargetLanguage('en')
  }

  return isGuestRoute
}

function HeaderComponent() {
  // Initialize Google Analytics
  useInitializeGA()

  return (
    <>
      {IsGuestPage() && <SpecialDayEffect />}
      <header className="headerBar fixed-top align-items-center">
        <div className="tg-btn-nt-clps d-flex me-auto">
          <div className="d-flex ham-container">
            <LogoComponent />
          </div>
        </div>
        {IsGuestPage() && <LanguageSwitcher />}
        <div className="me-3">
          <FontSlider />
        </div>
      </header>
    </>
  )
}

export default HeaderComponent
