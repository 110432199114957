import React, { useEffect, useRef } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import { isEqual } from 'lodash';

interface QuillEditorProps {
  initialContent: string;
  onChange: (content: string) => void;
}

const QuillEditor: React.FC<QuillEditorProps> = ({ initialContent, onChange }) => {
  const editorRef = useRef<HTMLDivElement | null>(null);
  const quillRef = useRef<Quill | null>(null);

  useEffect(() => {
    if (editorRef.current && !quillRef.current) {
      // Initialize Quill editor
      quillRef.current = new Quill(editorRef.current, {
        theme: 'snow',
        modules: {
          toolbar: false,
        },
      });

      // Set initial content if quillRef is not null
      if (quillRef.current) {
        quillRef.current.clipboard.dangerouslyPasteHTML(initialContent);

        // Set up the text-change event listener
        quillRef.current.on('text-change', () => {
          if (quillRef.current) {
            const content = quillRef.current.root.innerHTML;
            onChange(content);
          }
        });
      }
    }
  }, []); // Initialize only once

  useEffect(() => {
    if (quillRef.current) {
      const currentContent = quillRef.current.root.innerHTML;
      if (!isEqual(currentContent, initialContent)) {
        quillRef.current.clipboard.dangerouslyPasteHTML(initialContent);
      }
    }
  }, [initialContent]); // Update content when initialContent changes

  // Clean up on component unmount
  useEffect(() => {
    return () => {
      if (quillRef.current) {
        quillRef.current.off('text-change');
        quillRef.current = null;
      }
    };
  }, []);

  return <div className="quill-editor-for-ai" ref={editorRef}></div>;
};

export default QuillEditor;
