import { useEffect, useState } from "react";
import { ADD_CATEGORY_TITLE, CANCEL, CATEGORY_NAME, DESCRIPTION, EDIT_CATEGORY_TITLE, NEW_CATEGORY, HELP_NEW_CATEGORY, SAVE, AI_TEXT_GENERATOR } from "../../../constants/strings";
import { Alert, Offcanvas, OverlayTrigger } from "react-bootstrap";
import { AxiosResponse } from "axios";
import { createCustomCategory, editCustomCategory } from "../../../helpers/customMenuHelper";
import { MenuItem, Menu, Payload, CustomMenuCategoriesAndItems, CreateCustomCategoryType, EditCustomCategoryType, SubCategory, ToastMessageProps } from "../../../constants/staticTypes";
import { popover } from '../../../helpers/utils';
import { toastMessageInitialData } from "../../../constants/constants";
import { upsertCategory } from "../../../helpers/menuManagementHelper";
import { UseMutationResult } from "react-query";
import AiTextGenerator from "../../ai_text_generator/AiTextGenerator";
import ToastAlert from "../../alert/ToastAlert";
import { JTranslation } from "../../../helpers/jTranslate";
import MlxPopover from "../../common/MlxPopover";

type Props = {
    show: boolean,
    handleClose: () => void,
    upsertMenu: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onSuccess: (message: string) => void,
    parentId?: string,
    editItem: Menu | MenuItem | SubCategory | undefined
    parentComponent?: "F&B_MENU" | "CUSTOM_F&B_MENU"
    customMenuMode?: "CREATE" | "UPDATE"
}

function AddCategory({ show, handleClose, upsertMenu, onSuccess, parentId, editItem, parentComponent: parent = "F&B_MENU", customMenuMode = "CREATE" }: Props) {
    const [name, setCategoryName] = useState("");
    const [desc, setCategoryDescription] = useState("");
    const [showAlert, setShow] = useState(false);
    const [alertVariant, setVariant] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showAiTextGenerator, setShowAiTextGenerator] = useState(false)
    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)

    useEffect(() => {
        let categoryEditItem = editItem ? editItem as CustomMenuCategoriesAndItems : undefined;
        setCategoryName(categoryEditItem?.categoryName ? categoryEditItem.categoryName : "");
        setCategoryDescription(categoryEditItem?.categoryDescription ? categoryEditItem.categoryDescription : "");
    }, [show, editItem])

    // on api error
    const onError = (message: string, variant: string) => {
        setVariant(variant);
        setErrorMessage(message);
        setShow(true);
    }

    const apiCallForSave = () => {
        if (parent === "F&B_MENU") {
            // api call
            upsertCategory(upsertMenu, {
                categoryId: editItem?.id,
                parentCategoryId: parentId,
                categoryName: name,
                categoryDescription: desc
            }, onSuccess, onError);
        } else if (parent === "CUSTOM_F&B_MENU" && customMenuMode === "CREATE") {
            const params: CreateCustomCategoryType = {
                parentCategoryId: parentId!,
                categoryName: name,
                categoryDescription: desc,
                photoKey: ""
            }
            // api call
            createCustomCategory(upsertMenu, params, onSuccess, onError);
        } else if (parent === "CUSTOM_F&B_MENU" && customMenuMode === "UPDATE") {
            const params: EditCustomCategoryType = { categoryId: editItem?.id!, categoryName: name, photoKey: "", categoryDescription: desc }
            // api call
            editCustomCategory(upsertMenu, params, onSuccess, onError);
        }
    }

    return (
        <Offcanvas className="custom-offcanvas" show={show} onHide={() => {
            setCategoryName("");
            handleClose();
        }}
            placement="end"
            backdrop="static"
        >

            <ToastAlert
                data-testid="toast"
                message={toastMessage.message}
                onClose={() => setToastMessage(toastMessageInitialData)}
                show={toastMessage.show}
                variant={toastMessage.variant}
            />

            <Offcanvas.Header closeButton>
                <Offcanvas.Title className="d-flex">{editItem
                    ? <JTranslation typeCase="pascal" text={EDIT_CATEGORY_TITLE} />
                    : <JTranslation typeCase="pascal" text={ADD_CATEGORY_TITLE} />
                }
                    <MlxPopover data={{title:NEW_CATEGORY, body:HELP_NEW_CATEGORY}} />
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="row">
                    <div className="col-md-12 mb-4">
                        <label htmlFor="category" className="form-label"><JTranslation typeCase="pascal" text={CATEGORY_NAME} /><span className="mandatory ">*</span></label>
                        <input type="text" className="form-control" id="category" data-testid="category"
                            autoComplete="off"
                            autoFocus
                            maxLength={100}
                            value={name}
                            onChange={(e) => setCategoryName(e.target.value)} />
                    </div>
                    <div className="col-md-12">
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <label className="form-label"><JTranslation typeCase="pascal" text={DESCRIPTION} /></label>
                            <button className="btn btn-sm btn-custom-primary-outline" onClick={() => { setShowAiTextGenerator(!showAiTextGenerator) }}>
                                <JTranslation typeCase="pascal" text={AI_TEXT_GENERATOR} />
                            </button>
                        </div>
                    </div>
                    <div className="col-12">
                        <AiTextGenerator
                            key='menuCategoryDescription'
                            show={showAiTextGenerator}
                            callBack={(aiResponse: string) => setCategoryDescription(aiResponse)}
                            type='menuCategoryDescription'
                        />
                    </div>

                    <div className="mb-3">
                        <textarea className="form-control" id="exampleFormControlTextarea1" data-testid="category-desc" rows={4} maxLength={2000}
                            value={desc} onChange={(e) => setCategoryDescription(e.target.value)}></textarea>

                    </div>
                </div>
                {showAlert && <div className="row">
                    <div className="col-md-12">
                        <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
                            <p>
                                <JTranslation typeCase="capitalize" text={errorMessage} />
                            </p>
                        </Alert>
                    </div>
                </div>
                }
                <div className="save-btn-section shadow save-btn-absolute">
                    <button className="btn btn-custom-primary-outline" type="button" data-testid="clear-button"
                        onClick={() => {
                            setCategoryName("");
                            setCategoryDescription("");
                            handleClose();
                        }}><JTranslation typeCase="pascal" text={CANCEL} /></button>
                    <button className="btn btn-custom-primary" type="button" data-testid="save-button"
                        disabled={name?.trim().length === 0}
                        onClick={(e) => {
                            e.preventDefault();
                            apiCallForSave();
                        }}><JTranslation typeCase="pascal" text={SAVE} /></button>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default AddCategory;