import { Component, ErrorInfo, ReactNode } from 'react'
import ErrorPage from '../../pages/error_page/ErrorPage'
import { ERROR_BOUNDARY_TITLE } from '../../constants/strings'

interface ErrorBoundaryProps {
    children: ReactNode
}

interface ErrorBoundaryState {
    hasError: boolean
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props)
        this.state = {
            hasError: false,
        }
    }

    static getDerivedStateFromError(_: Error): ErrorBoundaryState {
        // Update state to indicate an error has occurred.
        return { hasError: true }
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can log the error to an error reporting service here.
        console.error('Error Boundary caught an error:', error, errorInfo)
    }

    render() {
        if (this.state.hasError) {
            return <ErrorPage title={ERROR_BOUNDARY_TITLE} bodyText='' />
        }

        return this.props.children
    }
}

export default ErrorBoundary
