import { useRef, useState } from 'react'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { AxiosResponse } from 'axios'
import { FileType } from '../../../constants/constants'
import { Galleria } from 'primereact/galleria'
import { Gallery } from '../../../constants/staticTypes'
import { itemTemplate, responsiveOptions, galleryViewSettings, findDetailsByItemId, FindMenuValuesResult, getDefaultPrice } from '../../../helpers/menuViewHelper'
import { PUBLIC_MENU } from '../../../constants/queryKeys'
import { useQueryClient } from 'react-query'
import { useTrackEvent } from '../../../hooks/useTrackEvent'
import Slider from 'react-slick'

type Props = {
    galleryImages: Gallery[] | undefined
    id: string | undefined
}

function GalleryView({ galleryImages, id }: Props) {
    const { trackEvent } = useTrackEvent()
    const [activeIndex, setActiveIndex] = useState(0)
    const galleryRef = useRef<Galleria>(null)
    const queryClient = useQueryClient()
    const res = queryClient.getQueryData(PUBLIC_MENU) as AxiosResponse<any, any>
    const list = res?.data?.data?.list
    let menuData: FindMenuValuesResult | null = { categoryName: '', itemName: '', itemPrices: [], menuName: '' }

    // handle image click
    const onImageClick = (index: number) => {
        setActiveIndex(index)
        if (galleryRef.current !== null) {
            galleryRef.current.show()
        }
    }

    return (
        <div className="gallery-slider-outer bg gallery-slide-thumb mb-4">
            <Galleria
                ref={galleryRef}
                value={galleryImages}
                responsiveOptions={responsiveOptions}
                numVisible={7}
                style={{ maxWidth: '850px' }}
                activeIndex={activeIndex}
                onItemChange={(e) => setActiveIndex(e.index)}
                circular
                fullScreen
                showItemNavigators
                showIndicators
                showThumbnails={false}
                item={itemTemplate}
            />

            {galleryImages && (
                <Slider {...galleryViewSettings}>
                    {galleryImages?.map((gallery, index) => {
                        if (gallery.type === FileType.IMAGE) {
                            return (
                                <div key={gallery.src} className="gallery-thumb img-box d-flex">
                                    <img
                                        src={gallery.src}
                                        className=""
                                        width={60}
                                        height={60}
                                        alt=""
                                        onClick={() => {
                                            onImageClick(index)
                                            if (id) {
                                                menuData = findDetailsByItemId({
                                                    itemId: id,
                                                    menuData: list
                                                })
                                                if (menuData?.categoryName) {
                                                    const { categoryName, itemName, itemPrices, menuName } = menuData
                                                    const price = getDefaultPrice(itemPrices).map((item) => (item === 'Infinity' ? null : `$${item}`))
                                                    // Google Analytics
                                                    trackEvent({
                                                        eventName: "view_menu_item_image",
                                                        value: {
                                                            menu_name: menuName,
                                                            menu_category: categoryName,
                                                            menu_item_name: itemName,
                                                            menu_item_price: price,
                                                        },
                                                    })
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            )
                        } else {
                            return (
                                <div key={gallery.src} className="video-box" onClick={() => {
                                    onImageClick(index)
                                    if (id) {
                                        menuData = findDetailsByItemId({
                                            itemId: id,
                                            menuData: list
                                        })
                                        if (menuData?.categoryName) {
                                            const { categoryName, itemName, itemPrices, menuName } = menuData
                                            const price = getDefaultPrice(itemPrices).map((item) => (item === 'Infinity' ? null : `$${item}`))
                                            // Google Analytics
                                            trackEvent({
                                                eventName: 'view_menu_item_video',
                                                value: {
                                                    menu_name: menuName,
                                                    menu_category: categoryName,
                                                    menu_item_name: itemName,
                                                    menu_item_price: price,
                                                },
                                            })
                                        }
                                    }
                                }}>
                                    <video src={gallery.src} className="" width={70} height={70} />
                                    <div className="overlay"></div>
                                    <div className="play-icon">
                                        <i className="ri-play-circle-line"></i>
                                    </div>
                                </div>
                            )
                        }
                    })}
                </Slider>
            )}
        </div>
    )
}

export default GalleryView
