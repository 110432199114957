import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'

// Extend the dayJS library to make the week start date as 'Monday'
dayjs.extend(updateLocale)
dayjs.updateLocale('en', {
	weekStart: 1,
})

export default function configuredDayjs() {
	return dayjs
}
