import { AxiosResponse } from "axios";
import { getErrorMessage } from "./utils";
import { HttpMethods, AlertVariant } from "../constants/constants";
import { UseMutationResult } from "react-query";
import { welcomeScreenConfigApi, welcomeConfigPublicApi, aboutUsConfigPublicApi } from "../constants/apiEndPoints";
import { WelcomeScreenData, Payload } from "../constants/staticTypes";
import HttpServiceHelper from "./httpServiceHelper";

export const initialWelcomeImageData = { key: '', signedUrl: '' }

// get welcome screen config data
export const getWelcomeScreenConfigData = () => {
    return HttpServiceHelper({
        url: welcomeScreenConfigApi,
        method: HttpMethods.GET,
    });
}

// post welcome screen config data
export const updateWelcomeScreenConfig = (welcomeMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    data: WelcomeScreenData,
    onSuccess: (message: string, data: WelcomeScreenData) => void,
    onError: (res: string, variant: string) => void) => {
    welcomeMutation.mutate({
        url: welcomeScreenConfigApi,
        method: HttpMethods.PUT,
        data: data,
    }, {
        onSuccess: (res) => onSuccess(res.data.message, res.data.data),
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    })
}

// get public welcome screen config
export const getPublicWelcomeScreenConfig = () => {
    return HttpServiceHelper({
        url: welcomeConfigPublicApi,
        method: HttpMethods.GET,
        noAuth: true
    });
}

// welcome page slider settings
export const welcomeSliderSettings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    variableWidth: true,

    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        },
        {
            breakpoint: 1192,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 780,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 572,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

// get public welcome screen config
export const getPublicAboutUsScreenConfig = () => {
    return HttpServiceHelper({
        url: aboutUsConfigPublicApi,
        method: HttpMethods.GET,
        noAuth: true
    });
}