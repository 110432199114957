import React from "react";
import { Modal } from "react-bootstrap";
import { STAR_RATING_POSITIONS, SurveyQuestionType } from "../../constants/constants";
import { SurveyQuestion, SurveyReport } from "../../constants/staticTypes";
import { CLOSE, NO, NOT_APPLICABLE, SURVEY_ANSWERS, YES } from "../../constants/strings";
import { JTranslation } from "../../helpers/jTranslate";

type Props = {
    show: boolean,
    handleClose: () => void,
    surveyReport: SurveyReport
}

function SurveyReportViewModal({ show, surveyReport, handleClose }: Props) {

    // answer ui based on question type
    const getAnswerUI = (question: SurveyQuestion) => {
        switch (question.surveyQuestionType) {
            case SurveyQuestionType.STAR_RATING:
                return <div className="col-12 mb-3 survey-report-border">
                    <div className="star-rating">
                        {STAR_RATING_POSITIONS.map((_starValue, index) => {
                            let className = "ri-star-line";
                            if (question.surveyQuestionAnswer &&
                                index < question.surveyQuestionAnswer) {
                                className = "ri-star-fill";
                            }
                            return <i className={className}
                                key={index}></i>
                        })}
                    </div>
                </div>

            case SurveyQuestionType.TRUE_FALSE:
                let answer = "";
                if (question.surveyQuestionAnswer !== undefined) {
                    answer = NOT_APPLICABLE;
                    if (question.surveyQuestionAnswer) {
                        answer = YES;
                    } else if (question.surveyQuestionAnswer !== null) {
                        answer = NO;
                    }
                }
                return <div className="col-md-12 mb-3 survey-report-border">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" id="" checked={answer === YES}
                            readOnly disabled={answer !== YES} autoComplete="off"/>
                        <label className="form-check-label">{YES}</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" id="" checked={answer === NO}
                            readOnly disabled={answer !== NO} autoComplete="off"/>
                        <label className="form-check-label">{NO}</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" id="" checked={answer === NOT_APPLICABLE}
                            readOnly disabled={answer !== NOT_APPLICABLE} autoComplete="off"/>
                        <label className="form-check-label">{NOT_APPLICABLE}</label>
                    </div>
                </div>

            case SurveyQuestionType.MATRIX_TABLE:
                let matrixChoices = [] as string[];
                if (question.matrixColumns) {
                    matrixChoices = question.matrixColumns[0].choices;
                }
                return <div className="px-3 table-container mb-3 survey-report-border" role="table">
                    <div className="table-scroll">
                        <div className="flex-table header" role="rowgroup">
                            <div className="flex-row-new first" role="columnheader"></div>
                            {matrixChoices && matrixChoices.map((column, colIndex) => {
                                return <div key={colIndex} className="flex-row-new" role="columnheader">{column}</div>
                            })}
                        </div>

                        {question.matrixRows && question.matrixRows.map((row, rowIndex) => {
                            return <div className="flex-table " role="rowgroup" key={rowIndex}>
                                <div className="flex-row-new first" role="cell">
                                    {row}
                                </div>
                                {matrixChoices && matrixChoices.map((_col, index) => {
                                    let checkedColumn = -1;
                                    if (question.matrixRowsAnswers && question.matrixRowsAnswers[row]) {
                                        const value = question.matrixRowsAnswers[row].firstColumn;
                                        checkedColumn = value - 1;
                                    }
                                    return <div className="flex-row-new" role="cell">
                                        <div className="form-check  ">
                                            <input className="form-check-input" type="radio" autoComplete="off"
                                                id={rowIndex + "#" + index}
                                                readOnly
                                                disabled={checkedColumn !== index}
                                                checked={checkedColumn === index} />
                                        </div>
                                    </div>
                                })}
                            </div>
                        })}
                    </div>
                </div>

            case SurveyQuestionType.TEXT_AREA:
                return <div className="col-md-12 mb-3 survey-report-border">
                    <textarea className="form-control" id="" rows={3} readOnly
                        defaultValue={question.surveyQuestionAnswer as string} />
                </div>

            default:
                return <div className="col-md-12 mb-3 survey-report-border">
                    <div className="mb-3">
                        <input type="text" className="form-control" autoComplete="off"
                            id={"text-input"}
                            readOnly
                            defaultValue={question.surveyQuestionAnswer as string}
                        />
                    </div>
                </div>
        }
    }

    return <Modal show={show} size="lg" onHide={handleClose} data-testid="survey-report-modal">
        <Modal.Header closeButton>
            <Modal.Title className="modal-custom-title"><JTranslation typeCase="pascal" text={SURVEY_ANSWERS} /></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {surveyReport.surveyAnswers && surveyReport.surveyAnswers.map((survey, index) => {
                return <React.Fragment key={index}>
                    <div className="row">
                        <div className="col-md-12 display-qstn-set-ttl">
                            <div className="fw-bold qustn-set-name text-center">
                                {survey.surveySetName}
                            </div>
                        </div>
                    </div>

                    {survey.surveyCategories.map((category, catIndex) => {
                        return <div className="row mb-3 display-qstn-container" key={index + "#" + catIndex}>
                            <div className="col-md-12 mb-3 text-center">
                                <div className="fw-bold qustn-category-name ">
                                    {category.surveyCategoryName}
                                </div>
                            </div>
                            {category.surveyQuestions.map((question, qstnIndex) => {
                                return <React.Fragment key={index + "#" + catIndex + "#" + qstnIndex}>
                                    <div className="col-md-12">
                                        <p className="mb-0">
                                            {qstnIndex + 1}. {question.surveyQuestionName}
                                        </p>
                                    </div>
                                    {getAnswerUI(question)}
                                </React.Fragment>
                            })}
                        </div>
                    })}
                </React.Fragment>
            })}
        </Modal.Body>
        <Modal.Footer className="save-btn-section-footer">
            <button className="btn btn-custom-primary-outline" type="button" onClick={handleClose}
            data-testid="survey-report-close"><JTranslation typeCase="pascal" text={CLOSE} /></button>
        </Modal.Footer>
    </Modal>
}

export default SurveyReportViewModal;