import React, { useCallback, useEffect, useMemo, useState, useRef, useContext } from 'react';
import "./style/fileUpload.scss";
import { ALLOWED_FILE_FORMATS, ROOT_FOLDER } from '../../constants/constants';
import { ApiFolderAndFileDS, CustomFileData, CustomFileMap, DndDetails, RenameFileProps, RenameFolderProps, SaveFileToDb, VFSProps, SaveFolderToDb, RenameFolderOnDb, DeleteFolderOnDb, UploadedFileProp, ZipItem, DownloadZip, Doc, docToBeViewed } from '../../constants/staticTypes'
import { ChonkyActions, FileArray, FileData, FileBrowser, FileContextMenu, FileList, FileNavbar, FileToolbar, setChonkyDefaults } from "chonky";
import { ChonkyIconFA } from "chonky-icon-fontawesome";
import { createNewFolder, renameFolderApi, renameFileApi, deleteFileApi, deleteFolderApi } from "../../helpers/folderUploadHelper";
import { deleteMultipleFilesAndFolders } from '../../helpers/multiFileHelper';
import { FileManagementCtx } from '../../context/FileManagementCtxProvider';
import { getFileSignedUrl, uploadToSignedUrl, saveFileToDBHelper } from "../../helpers/fileUploadHelper";
import { getParentFolderId, checkFileExtension, getFileExtensions, getIntegerFileId, getZipItems, getPermittedFileActions } from './helperFiles/fileManagerHelper'
import { NEW_FOLDER, RENAME_FILE, RENAME_FOLDER, FILE_NOT_SUPPORTED, DELETE_WARNING, FILE_UPLOAD_LIMIT_WARNING, FILES, FILE_UPLOAD_INPROGRESS, DOCUMENT_VIEWER, DOWNLOAD } from '../../constants/strings';
import { ulid } from 'ulid';
import { useQueryClient } from "react-query";
import CommonModal from "../../components/common_modal/CommonModal";
import CustomSearch from './helperFiles/CustomSearch';
import DocumentViewer from '../../components/doc_viewer/DocumentViewer';
import DropZone from './helperFiles/DropZone';
import HandleAction from './helperFiles/HandleAction';
import ToastAlert from "../../components/alert/ToastAlert";
import useDownloader from 'react-use-downloader';
import useMutationHook from "../../hooks/useMutationHook";
import WarningModal from "../../components/warning_modal/WarningModal";

export const VFSBrowser: React.FC<VFSProps> = React.memo(({ masterFsMap, globalSearchValue, setGlobalSearchValue, setReCallListApi, VFSBrowserProps }) => {
    // REACT QUERY VARIABLES
    const queryClient = useQueryClient()
    const fileManagerMutation = useMutationHook(queryClient, true)

    // CONTEXT VARIABLES
    const { totalFileCount, uploadedFileCount, setTotalFileCount, setUploadedFileCount } = useContext(FileManagementCtx)

    // LOCAL VARIABLES
    const [currentName, setCurrentName] = useState<string>('')
    const [docToBeViewed, setDocToBeViewed] = useState<Doc[]>([])
    const [fileNameToBeViewed, setFileNameToBeViewed] = useState("")
    const [selectedFilesForAction, setSelectedFilesForAction] = useState<CustomFileData[]>([])
    const [tempFile, setTempFile] = useState<ApiFolderAndFileDS[]>([])
    const fileUploadLimit = 100 // max number of file that can be upload in one shot 
    const { download } = useDownloader()
    const inputFile = useRef(null)
    let dragAndDropDetails: DndDetails
    let idToBeDeleted: number | string = ""
    let idToBeRenamed = ''
    let newFolderId = ''
    let parentIdForDelete = ''
    let uploadInProgress = false

    // ALERT VARIABLES
    const [alertVariant, setVariant] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [showAlert, setShowAlert] = useState(false)
    const [showDocViewer, setShowDocViewer] = useState(false)

    // MODAL VARIABLES
    const [modalInputText, setModalInputText] = useState('')
    const [modalTitle, setModalTitle] = useState(NEW_FOLDER)
    const [showModal, setModal] = useState(false)
    const [warning, setWarning] = useState(false)

    // callback function to set the docToBeViewed variable for HandleActions component
    const updateDocToBeViewed = ({ doc, fileName }: docToBeViewed) => {
        setFileNameToBeViewed(fileName)
        setDocToBeViewed(doc)
    }

    // closes the document viewer modal
    const closeDocViewer = () => setShowDocViewer(false)

    // on api error
    const onError = (message: string, variant: string) => {
        setVariant(variant)
        setErrorMessage(message)
        setShowAlert(true)
        handleCreateFolderError(newFolderId)
        handleDndFolderNameDuplication(dragAndDropDetails)
    }

    // Helper method to attach our custom TypeScript types to the imported JSON file map.
    const prepareCustomFileMap = () => {
        const baseFileMap = masterFsMap?.fileMap as unknown as CustomFileMap
        const rootFolderId = masterFsMap?.rootFolderId
        return { baseFileMap, rootFolderId }
    }

    // Hook that sets up our file map and defines functions used to mutate - `deleteFolder`,
    // `moveFiles`, and so on.
    const useCustomFileMap = () => {
        const { baseFileMap, rootFolderId } = prepareCustomFileMap()
        // Setup the React state for our file map and the current folder.
        const [fileMap, setFileMap] = useState(baseFileMap)
        const [currentFolderId, setCurrentFolderId] = useState(rootFolderId)

        // Setup logic to listen to changes in current folder ID without having to update
        // `useCallback` hooks. Read more about it here:
        // https://reactjs.org/docs/hooks-faq.html#is-there-something-like-instance-variables
        const currentFolderIdRef = useRef(currentFolderId)
        useEffect(() => {
            currentFolderIdRef.current = currentFolderId
        }, [currentFolderId])

        // Function that will be called when user deletes files either using the toolbar
        // button or `Delete` key.
        const deleteFile = useCallback((files: CustomFileData[], multi?: boolean) => {
            setFileMap((currentFileMap) => {
                // Create a copy of the file map to make sure we don't mutate it.
                const newFileMap = { ...currentFileMap }
                files.forEach((file) => {
                    // stop execution if object is a folder
                    if (file.isDir) return
                    // Delete file from the file map
                    idToBeDeleted = getIntegerFileId(file)
                    delete newFileMap[file.id]

                    // Update the parent folder to make sure it doesn't try to load the
                    // file we just deleted.
                    if (file.parentId) {
                        const parent = newFileMap[file.parentId]!
                        const newChildrenIds = parent?.childrenIds?.filter((id) => id !== file.id) || []
                        newFileMap[file.parentId] = {
                            ...parent,
                            childrenIds: newChildrenIds,
                        }
                    }
                })

                // delete file api call
                !multi && deleteFileApi(
                    fileManagerMutation,
                    {
                        fileId: idToBeDeleted,
                    },
                    onError
                )

                return newFileMap
            })
        }, [])

        // Function that will be called when user deletes folder either using the toolbar
        // button or `Delete` key.
        const deleteFolder = useCallback((files: CustomFileData[], multi?: boolean) => {
            setFileMap((currentFileMap) => {
                // Create a copy of the file map to make sure we don't mutate it.
                const newFileMap = { ...currentFileMap }
                let idToBeDeleted = ''
                files.forEach((file) => {
                    // stop execution if object is a file
                    if (!file.isDir) return
                    // Delete file from the file map.
                    idToBeDeleted = file.id
                    delete newFileMap[file.id]

                    // Update the parent folder to make sure it doesn't try to load the
                    // file we just deleted.
                    if (file.parentId) {
                        const parent = newFileMap[file.parentId]!
                        const newChildrenIds = parent?.childrenIds?.filter((id) => id !== file.id) || []
                        newFileMap[file.parentId] = {
                            ...parent,
                            childrenIds: newChildrenIds,
                        }
                    }
                })

                // delete folder api call
                let data: DeleteFolderOnDb
                if (typeof idToBeDeleted === "string" && idToBeDeleted !== ROOT_FOLDER) {
                    data = {
                        folderUuid: idToBeDeleted,
                    }
                } else {
                    data = {
                        folderId: idToBeDeleted,
                    }
                }

                !multi && deleteFolderApi(
                    fileManagerMutation,
                    data,
                    onError
                )

                return newFileMap
            })
        }, [])

        // Function that will be called once move folder api throws validation error
        const handleDndFolderNameDuplication = useCallback(({ destination, draggedFile }: DndDetails) => {
            setFileMap((currentFileMap) => {
                // Create a copy of the file map to make sure we don't mutate it.
                const newFileMap = { ...currentFileMap }
                files.forEach(() => {
                    // Delete file from the file map.
                    delete newFileMap[draggedFile.id]
                    // Update the parent folder to make sure it doesn't try to load the
                    // file we just deleted.
                    const parent = newFileMap[destination.id]!
                    const newChildrenIds = parent?.childrenIds?.filter((id) => id !== draggedFile.id) || []
                    newFileMap[destination.id] = {
                        ...parent,
                        childrenIds: newChildrenIds,
                    }
                    // add the dragged folder back to its original position
                    const restore = newFileMap[draggedFile.parentId]
                    const restoreChildrenIds = newFileMap[draggedFile.parentId]?.childrenIds
                    newFileMap[draggedFile.parentId] = {
                        ...restore,
                        // @ts-ignore-start
                        childrenIds: [...restoreChildrenIds, draggedFile.id],
                        // @ts-ignore-end
                    }
                    newFileMap[draggedFile.id] = {
                        ...draggedFile,
                    }
                })

                return newFileMap
            })
        }, [])

        // Function that will be called once create folder api throws validation error
        const handleCreateFolderError = useCallback((folderId: string) => {
            if (folderId) {
                setFileMap((currentFileMap) => {
                    // Create a copy of the file map to make sure we don't mutate it.
                    const newFileMap = { ...currentFileMap }
                    files.forEach(() => {
                        // Delete file from the file map.
                        delete newFileMap[folderId]

                        // Update the parent folder to make sure it doesn't try to load the
                        // file we just deleted.
                        const parent = newFileMap[parentIdForDelete]!
                        const newChildrenIds = parent?.childrenIds?.filter((id) => id !== folderId) || []
                        newFileMap[parentIdForDelete] = {
                            ...parent,
                            childrenIds: newChildrenIds,
                        }
                    })

                    return newFileMap
                })
            }
        }, [])

        // Function that will be called when files are moved from one folder to another
        // using drag & drop.
        const moveFiles = useCallback(
            (files: CustomFileData[], source: CustomFileData, destination: CustomFileData) => {
                setFileMap((currentFileMap) => {
                    const newFileMap = { ...currentFileMap }
                    const moveFileIds = new Set(files.map((f) => f.id))

                    // Delete files from their source folder.
                    const newSourceChildrenIds = source.childrenIds!.filter((id) => !moveFileIds.has(id))
                    newFileMap[source.id] = {
                        ...source,
                        childrenIds: newSourceChildrenIds,
                    }

                    // Add the files to their destination folder.
                    const newDestinationChildrenIds = [...destination.childrenIds!, ...files.map((f) => f.id)]
                    newFileMap[destination.id] = {
                        ...destination,
                        childrenIds: newDestinationChildrenIds,
                    }

                    // Finally, update the parent folder ID on the files from source folder
                    // ID to the destination folder ID.
                    files.forEach((file) => {
                        newFileMap[file.id] = {
                            ...file,
                            parentId: destination.id,
                        }
                    })

                    return newFileMap
                })
            },
            []
        )

        // Function that will be called when user creates a new folder using the toolbar
        const createFolder = useCallback((folderName: string) => {
            setFileMap((currentFileMap) => {
                const newFileMap = { ...currentFileMap }

                // Create the new folder
                newFolderId = ulid()
                parentIdForDelete = currentFolderIdRef.current
                newFileMap[newFolderId] = {
                    id: newFolderId,
                    uuid: newFolderId,
                    name: folderName,
                    isDir: true,
                    modDate: new Date(),
                    parentId: currentFolderIdRef.current,
                    childrenIds: [],
                }

                // Update parent folder to reference the new folder.
                const parent = newFileMap[currentFolderIdRef.current]
                newFileMap[currentFolderIdRef.current] = {
                    ...parent,
                    childrenIds: [...parent.childrenIds!, newFolderId],
                }
                // create folder api call
                if (modalTitle === NEW_FOLDER) {
                    let data: SaveFolderToDb
                    if (typeof parent.id === "string" && parent.id !== ROOT_FOLDER) {
                        data = {
                            folderId: newFolderId,
                            folderName,
                            parentFolderUuid: parent.id,
                        }
                    } else {
                        data = {
                            folderId: newFolderId,
                            folderName,
                            parentFolderId: getParentFolderId(parent.id),
                        }
                    }
                    // api call
                    createNewFolder(
                        fileManagerMutation,
                        data,
                        onError
                    )
                }
                return newFileMap
            })
        }, [])

        // Function that will be called when user uploaded a new file
        const uploadFile = useCallback((file: ApiFolderAndFileDS, parentRef: string) => {
            setFileMap((currentFileMap) => {
                const newFileMap = { ...currentFileMap }
                // Create the new file
                const newFileId = file.uuid
                newFileMap[newFileId] = {
                    id: newFileId,
                    dbId: file.id,
                    name: file.fileName,
                    isDir: false,
                    modDate: new Date(),
                    parentId: parentRef,
                    childrenIds: [],
                    signedUrl: file.signedUrl,
                }

                // Update parent folder to reference the new file.
                const parent = newFileMap[parentRef]
                newFileMap[parentRef] = {
                    ...parent,
                    childrenIds: [...parent.childrenIds!, newFileId],
                }

                return newFileMap
            })
        }, [])

        // returns current folder if ref 
        const getCurrentParentRefId = useCallback(() => {
            return currentFolderIdRef.current
        }, [])

        // Function that will be called when user renames a file
        const renameFile = useCallback(({ files, fileName }: RenameFileProps) => {
            setFileMap((currentFileMap) => {
                // Create a copy of the file map to make sure we don't mutate it.
                const newFileMap = { ...currentFileMap }
                let itemDbId

                files.forEach((file) => {
                    idToBeRenamed = file.id
                    itemDbId = file.dbId ? file.dbId : file.id
                })

                if (fileName) {
                    // add new file name to newFileMap
                    newFileMap[idToBeRenamed] = {
                        ...newFileMap[idToBeRenamed],
                        id: idToBeRenamed,
                        name: fileName,
                        isDir: false,
                        modDate: new Date(),
                        parentId: currentFolderIdRef.current,
                    }

                    // api call to rename file
                    renameFileApi(
                        fileManagerMutation,
                        {
                            fileId: itemDbId,
                            fileName,
                        },
                        onError
                    )
                }

                return newFileMap
            })
        }, [])

        // Function that will be called when user renames a folder
        const renameFolder = useCallback(({ files, folderName }: RenameFolderProps) => {
            setFileMap((currentFileMap) => {
                // Create a copy of the file map to make sure we don't mutate it.
                const newFileMap = { ...currentFileMap }

                files.forEach((file) => (idToBeRenamed = file.id))

                if (folderName) {
                    // add new folder name to newFileMap
                    newFileMap[idToBeRenamed] = {
                        id: idToBeRenamed,
                        name: folderName,
                        isDir: true,
                        modDate: new Date(),
                        parentId: currentFolderIdRef.current,
                        childrenIds: newFileMap[idToBeRenamed].childrenIds,
                    }

                    // Update parent folder to reference the new folder.
                    const parent = newFileMap[currentFolderIdRef.current]
                    newFileMap[currentFolderIdRef.current] = {
                        ...parent,
                        childrenIds: [...parent.childrenIds!, idToBeRenamed],
                    }

                    let data: RenameFolderOnDb
                    if (typeof idToBeRenamed === "string" && idToBeRenamed !== ROOT_FOLDER) {
                        data = {
                            folderName,
                            folderUuid: idToBeRenamed,
                        }
                    } else {
                        data = {
                            folderName,
                            folderId: idToBeRenamed,
                        }
                    }

                    // api call to rename folder
                    renameFolderApi(
                        fileManagerMutation,
                        data,
                        onError
                    )
                }

                return newFileMap
            })
        }, [])

        return {
            createFolder,
            currentFolderId,
            deleteFile,
            deleteFolder,
            fileMap,
            getCurrentParentRefId,
            handleCreateFolderError,
            handleDndFolderNameDuplication,
            moveFiles,
            renameFile,
            renameFolder,
            setCurrentFolderId,
            uploadFile,
        }
    }

    const useFiles = (fileMap: CustomFileMap, currentFolderId: string): FileArray => {
        return useMemo(() => {
            const currentFolder = fileMap[currentFolderId]
            const childrenIds = currentFolder?.childrenIds!
            const files = childrenIds?.map((fileId: string) => fileMap[fileId])
            return files
        }, [currentFolderId, fileMap])
    }

    const useFolderChain = (fileMap: CustomFileMap, currentFolderId: string): FileArray => {
        return useMemo(() => {
            const currentFolder = fileMap[currentFolderId]
            const folderChain = [currentFolder]
            let parentId = currentFolder?.parentId
            while (parentId) {
                const parentFile = fileMap[parentId]
                if (parentFile) {
                    folderChain.unshift(parentFile)
                    parentId = parentFile.parentId
                } else {
                    break
                }
            }

            return folderChain
        }, [currentFolderId, fileMap])
    }

    // CHONKY METHODS AND VARIABLES
    const {
        createFolder,
        currentFolderId,
        deleteFile,
        deleteFolder,
        fileMap,
        getCurrentParentRefId,
        handleCreateFolderError,
        handleDndFolderNameDuplication,
        moveFiles,
        renameFile,
        renameFolder,
        setCurrentFolderId,
        uploadFile,
    } = useCustomFileMap()

    const fileActions = useMemo(() => getPermittedFileActions(queryClient), [queryClient])
    const files = useFiles(fileMap, currentFolderId)
    const folderChain = useFolderChain(fileMap, currentFolderId)
    const thumbnailGenerator = useCallback((file: FileData) => (file.thumbnailUrl ? `https://chonky.io${file.thumbnailUrl}` : null), [])
    setChonkyDefaults({ iconComponent: ChonkyIconFA, disableDragAndDropProvider: false })

    const saveFileToDB = async (fileProps: UploadedFileProp) => {
        let folderData: SaveFileToDb
        if (typeof currentFolderId === 'string' && currentFolderId !== ROOT_FOLDER) {
            folderData = {
                fileKey: fileProps.fileKey,
                fileName: fileProps.fileName,
                parentFolderUuid: currentFolderId,
            }
        } else {
            folderData = {
                fileKey: fileProps.fileKey,
                fileName: fileProps.fileName,
                parentFolderId: getParentFolderId(currentFolderId),
            }
        }

        return new Promise(async (resolve) => {
            await saveFileToDBHelper(folderData).then(res => resolve(res.data.data as ApiFolderAndFileDS))
        })

    }

    const clearInputModal = useCallback(() => {
        setModal(false)
        setModalInputText('')
    }, [])

    const proceedAction = useCallback(() => {
        switch (modalTitle) {
            case NEW_FOLDER:
                createFolder(modalInputText)
                clearInputModal()
                break
            case RENAME_FOLDER:
                renameFolder({ files: tempFile, folderName: modalInputText })
                clearInputModal()
                break
            case RENAME_FILE:
                renameFile({ files: tempFile, fileName: modalInputText })
                clearInputModal()
                break

            default:
                break
        }
    }, [createFolder, clearInputModal, modalInputText, modalTitle, renameFile, renameFolder, tempFile])


    const emptyCurrentName = useCallback(() => setCurrentName(''), [])

    const hideWarningModal = useCallback(() => setWarning(false), [])

    const changeDragAndDropDetails = (param: DndDetails) => { dragAndDropDetails = param }

    const commonModalHideAction = useCallback(() => {
        clearInputModal()
        emptyCurrentName()
    }, [clearInputModal, emptyCurrentName])

    const deleteFolderOrFile = useCallback(() => {
        // switch function for single and multi file delete
        let fileCount = tempFile.length
        if (fileCount > 1) {
            // multi file delete
            let zipItems: ZipItem[] = getZipItems(tempFile)
            let itemsForDelete: DownloadZip = { items: zipItems }

            const onDeleteSuccess = () => {
                tempFile.forEach((file) => {
                    if (file.isDir) {
                        deleteFolder(selectedFilesForAction, true)
                    } else {
                        deleteFile(selectedFilesForAction, true)
                    }
                })
            }

            const onDeleteError = (message: string, variant: string) => {
                setVariant(variant)
                setErrorMessage(message)
                setShowAlert(true)
            }
            // bulk delete api call
            deleteMultipleFilesAndFolders(fileManagerMutation, itemsForDelete, onDeleteSuccess, onDeleteError)
        } else {
            // single file delete
            let isDir = tempFile[0].isDir
            if (isDir) {
                deleteFolder(selectedFilesForAction)
            } else {
                deleteFile(selectedFilesForAction)
            }
        }
        setWarning(false)
    }, [deleteFile, deleteFolder, selectedFilesForAction, tempFile, fileManagerMutation])

    const fileUploadToSignedUrl = (file: File, signedUrl: string, fileHeaderData: Object) => {
        return new Promise(async (resolve) => {
            const dataUploadToSignedUrl = await uploadToSignedUrl(file, signedUrl, fileHeaderData)
            resolve(dataUploadToSignedUrl);
        });
    }

    const uploadFilePromiseChain = (file: File, parentRef: string) => {
        // show toast message to indicate upload start
        if (!uploadInProgress) {
            setVariant("warning")
            setErrorMessage(FILE_UPLOAD_INPROGRESS)
            setShowAlert(true)
            uploadInProgress = true
        }

        return new Promise(async (resolve) => {
            // api call to get signed URL 
            const signedUrlsData = await getFileSignedUrl(file);
            const fileData = signedUrlsData.data.data;
            const signedUrl = fileData.url;
            const fileHeaderData = fileData.fields;
            // api call to upload file to S3 bucket
            await fileUploadToSignedUrl(file, signedUrl, fileHeaderData);
            const saveData: UploadedFileProp = {
                fileKey: fileData.fileKey,
                fileName: file.name,
            };
            // api call to save file info to DB          
            await saveFileToDB(saveData).then((file) => {
                // adding newly upload file to file-manager data structure
                uploadFile(file as ApiFolderAndFileDS, parentRef)
                // storing file count in context variable to show upload progress on header
                setUploadedFileCount((prev) => prev + 1)
            }
            );
            resolve(signedUrlsData);
        });
    };

    async function sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const fileInputAction = async (files: FileList) => {
        let fileCount = files.length ? files.length : 0
        let acceptedFileCount = 0
        let showAcceptedFileFormatError = true
        // current folder ref
        const parentRef = getCurrentParentRefId()
        // file counting and format validation
        for (let i = 0; i < fileCount; i++) {
            if (checkFileExtension(files[i]) && fileCount <= fileUploadLimit) {
                acceptedFileCount++
            } else {
                if (showAcceptedFileFormatError && fileCount < fileUploadLimit) alert(`${FILE_NOT_SUPPORTED} (${ALLOWED_FILE_FORMATS})`)
                showAcceptedFileFormatError = false
            }
        }
        // storing file count in context variable to show upload progress on header
        setTotalFileCount((prev) => prev + acceptedFileCount)

        // looping to upload multiple files
        for (let i = 0; i < fileCount; i++) {
            if (files && files.length > 0 && checkFileExtension(files[i])) {
                // stop file upload if the limit is exceeded
                if (files.length > fileUploadLimit) {
                    alert(`${FILE_UPLOAD_LIMIT_WARNING} ${fileUploadLimit} ${FILES}`)
                    return
                }
                const file = files[i]
                // start of upload api call chain
                uploadFilePromiseChain(file, parentRef)
                // slowing API calls to AWS to avoid request rejection
                await sleep(1250)
            }
        }
    }

    const modalContent = (
        <input
            type="text"
            className="form-control"
            id="category"
            autoFocus
            autoComplete="off"
            maxLength={200}
            value={modalInputText ? modalInputText : currentName}
            onChange={(e) => {
                emptyCurrentName()
                setModalInputText(e.target.value.trimStart())
            }}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    proceedAction()
                }
            }}
        />
    )

    const additionalActionsButtons = (
        <>
            {/* download button */}
            <button type="button" className="btn btn-custom-primary" onClick={() => {
                let file = tempFile[0]
                download(file.signedUrl, file.fileName)
            }}>
                {DOWNLOAD}
            </button>
        </>
    )

    useEffect(() => {
        // reset file upload progress toast message once the upload is completed 
        if (totalFileCount === uploadedFileCount) uploadInProgress = false // eslint-disable-line
    }, [totalFileCount, uploadedFileCount])

    return (
        <>
            {/* TOAST MESSAGE COMPONENT */}
            <ToastAlert show={showAlert} onClose={setShowAlert} message={errorMessage} variant={alertVariant} />

            {/* MODAL POPUP FOR DELETE WARNING  */}
            <WarningModal
                callback={deleteFolderOrFile}
                onHide={hideWarningModal}
                show={warning}
                title={DELETE_WARNING}
            />

            {/* MODAL POPUP FOR FOLDER AND FILE NAMING */}
            <CommonModal
                callback={proceedAction}
                disableProceed={modalInputText ? false : true}
                modalContent={modalContent}
                onHide={commonModalHideAction}
                show={showModal}
                title={modalTitle}
            />

            {/* MODAL FOR SHOWING THE DOCUMENT VIEWER */}
            <CommonModal
                callback={closeDocViewer}
                hideProceed
                modalContent={<DocumentViewer docs={docToBeViewed} />}
                modalSize='xl'
                onHide={setShowDocViewer}
                show={showDocViewer}
                title={fileNameToBeViewed ? fileNameToBeViewed : DOCUMENT_VIEWER}
                modalBodyCss={fileNameToBeViewed?.includes("xls") ? "doc-viewer-body-for-excel-files" : "doc-viewer-body"}
                additionalActions={true}
                additionalActionsButtons={additionalActionsButtons}
            />

            <div className="custom-file-upload col-md-12 col-lg-12  user-scroll-fix">
                {/* FILE UPLOAD INPUT FIELD */}
                <input
                    id="file"
                    autoComplete="off"
                    ref={inputFile}
                    style={{ display: 'none' }}
                    type="file"
                    accept={getFileExtensions()}
                    onChange={(e) => fileInputAction(e.target.files!)}
                    multiple
                />
                <DropZone fileInputAction={fileInputAction}>
                    {/* CHONKY FILE BROWSER COMPONENT */}
                    <FileBrowser
                        files={files}
                        {...VFSBrowserProps}
                        folderChain={folderChain}
                        fileActions={fileActions}
                        onFileAction={(data) =>
                            HandleAction({
                                changeDragAndDropDetails,
                                createFolder,
                                data,
                                download,
                                fileManagerMutation,
                                inputFile,
                                modalInputText,
                                moveFiles,
                                onError,
                                renameFile,
                                renameFolder,
                                tempFile,
                                updateDocToBeViewed,
                                setCurrentFolderId,
                                setCurrentName,
                                setErrorMessage,
                                setModal,
                                setModalTitle,
                                setSelectedFilesForAction,
                                setShowAlert,
                                setShowDocViewer,
                                setTempFile,
                                setVariant,
                                setWarning,
                            })
                        }
                        defaultFileViewActionId={ChonkyActions.EnableListView.id}
                        thumbnailGenerator={thumbnailGenerator}
                    >
                        {/* ADDING CUSTOM COMPONENTS TO FILE BROWSER */}
                        <CustomSearch
                            setGlobalSearchValue={setGlobalSearchValue}
                            setReCallListApi={setReCallListApi}
                            globalSearchValue={globalSearchValue}
                        />
                        <FileNavbar />
                        <FileToolbar />
                        <FileList />
                        <FileContextMenu />
                    </FileBrowser>
                </DropZone>
            </div>
        </>
    )
})

export default VFSBrowser