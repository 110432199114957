const EulaContent = () => {
  return (
    <div>
      <p>By registering as staff for AAABBCC ("the Company"), you agree to be bound by the terms and conditions of this End User License Agreement ("Agreement"). Please read the following terms and conditions carefully before proceeding with registration.</p>

      <h2>1. License Grant</h2>
      <p>Subject to your compliance with the terms of this Agreement, the Company grants you a limited, non-exclusive, non-transferable, non-sublicensable license to access and use the Company's staff registration system for the purpose of registering as an employee of the Company and managing your employment-related information.</p>

      <h2>2. Data Collection and Use</h2>
      <p>By registering as staff, you acknowledge and agree that the Company may collect, store, and process your personal information, including but not limited to your name, address, phone number, email address, date of birth, social security number, and employment history, for the purpose of managing your employment with the Company.</p>

      <p>The Company will take reasonable steps to protect your personal information from unauthorized access, use, or disclosure. The Company will not share your personal information with third parties, except as required by law or in the event of a business transfer, such as a merger, acquisition, or sale of assets.</p>

      <h2>3. Restrictions</h2>
      <p>You agree not to use the staff registration system for any purpose other than as expressly authorized in this Agreement. You shall not sublicense, lease, rent, loan, or transfer the staff registration system or your access rights to any third party. You shall not reverse engineer, decompile, disassemble, or otherwise attempt to derive the source code or underlying technology of the staff registration system.</p>

      <h2>4. Termination</h2>
      <p>This Agreement is effective until terminated by either party. Your rights under this Agreement will terminate automatically without notice from the Company if you fail to comply with any term(s) of this Agreement. Upon termination of this Agreement, you shall cease all use of the staff registration system and destroy any copies, full or partial, of any materials related to the system in your possession or control.</p>

      <h2>5. Disclaimer of Warranties</h2>
      <p>The staff registration system is provided "as is" and the Company disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement of proprietary rights.</p>

      <h2>6. Limitation of Liability</h2>
      <p>In no event shall the Company be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including, but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses, resulting from your use of or inability to use the staff registration system.</p>

      <h2>7. Governing Law and Dispute Resolution</h2>
      <p>This Agreement shall be governed by and construed in accordance with the laws of the jurisdiction in which the Company is located. Any disputes arising out of or in connection with this Agreement shall be resolved through good faith negotiation or, failing that, by submission to the exclusive jurisdiction of the courts</p>
    </div>
  )
}

export default EulaContent
