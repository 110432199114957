import React, { useState, useContext, useEffect } from 'react';
import 'react-phone-number-input/style.css';
import { ADD_NEW_ADD_ON, CANCEL, EDIT_ADD_ON, NAME, PRICE, SAVE, UPDATE, ASSOCIATED_ITEM, ASSOCIATED_ITEMS } from '../../constants/strings';
import { ADD_ONS_LIST } from "../../constants/queryKeys";
import { Addon, NewAddon, ToastMessageProps, SingleAddon, SingleAddOnResponse, SelectedMenusItem } from "../../constants/staticTypes";
import { CommonCtx } from '../../context/CommonCtxProvider';
import { FormClass, toastMessageInitialData } from "../../constants/constants";
import { initialFormData, createNewAddOn, updateAddOn, fetchSingleAddOn } from "../../helpers/manageAddOnsHelper";
import { isDecimalNumberWithDecimalPoints } from '../../helpers/utils';
import { useQueryClient } from "react-query";
import { v4 as uuidv4 } from "uuid";
import Offcanvas from 'react-bootstrap/Offcanvas';
import ToastAlert from "../alert/ToastAlert";
import useMutationHook from "../../hooks/useMutationHook";
import { JTranslation } from '../../helpers/jTranslate';

type Props = {
    editAddon: Addon | undefined,
    setEditAddOn: React.Dispatch<React.SetStateAction<Addon | undefined>>,
    handleClose: () => void,
}

const AddOnsSideBar = ({ editAddon, setEditAddOn, handleClose }: Props) => {
    // CONTEXT VARIABLE
    const { showSideBar, setShowSideBar } = useContext(CommonCtx)
    // LOCAL VARIABLES
    const queryClient = useQueryClient()
    const addOnMutation = useMutationHook(queryClient, true) // add or update addOn mutation
    // STATE VARIABLES
    const [formData, setFormData] = useState<NewAddon>(initialFormData)
    const [associatedItems, setAssociatedItems] = useState<SelectedMenusItem[] | null>(null)
    // ALERT VARIABLES
    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)

    // effect for setting the form data
    useEffect(() => {
        if (editAddon) {
            setFormData({
                addonName: editAddon?.addonName !== null ? editAddon?.addonName : '',
                addonPrice: editAddon?.addonPrice !== null ? editAddon?.addonPrice : '',
                addonId: editAddon?.id !== null ? editAddon?.id : '',
            })
        }
    }, [editAddon])

    // This effect invokes the single addon API to retrieve the list of associated items
    useEffect(() => {
        if (editAddon) {
            const param: SingleAddon = { addonId: editAddon.addonId }
            //   api call
            fetchSingleAddOn(addOnMutation, param, onSingleAddOnSuccess, onError)
        }

        return () => {
            // cleanup
            setAssociatedItems(null)
        }
    }, [editAddon])

    // on add or update addOn success
    const onSingleAddOnSuccess = (data: SingleAddOnResponse) => {
        setAssociatedItems(data.selectedMenus)
    }

    // on add or update addOn success
    const onSuccess = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
        queryClient.refetchQueries([ADD_ONS_LIST]) // nosonar
        cleanUpData()
    }

    // on add or update addOn error
    const onError = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
    }

    // clear data
    const cleanUpData = () => {
        setFormData(initialFormData)
        setEditAddOn(undefined)
        setToastMessage(toastMessageInitialData)
        setShowSideBar(false)
    }

    const isFormValid = () => {
        let addonName = formData.addonName?.trim().length > 0 ? FormClass.VALID : FormClass.INVALID
        let addonPrice = formData.addonPrice?.trim().length > 0 ? FormClass.VALID : FormClass.INVALID

        if (addonName === FormClass.VALID && addonPrice === FormClass.VALID) {
            return true
        } else {
            return false
        }
    }

    const submitForm = () => {
        if (editAddon) {
            const updateAddOnData: NewAddon = {
                ...initialFormData,
                addonName: formData.addonName,
                addonPrice: formData.addonPrice,
                addonId: formData.addonId,
            }
            // update addon api call
            updateAddOn(addOnMutation, updateAddOnData, onSuccess, onError)
        } else {
            const newAddOnData: NewAddon = {
                ...initialFormData,
                addonName: formData.addonName,
                addonPrice: formData.addonPrice,
            }
            // create addon api call
            createNewAddOn(addOnMutation, newAddOnData, onSuccess, onError)
        }
    }

    return (
        <Offcanvas
            show={showSideBar}
            className='custom-offcanvas'
            onHide={() => {
                handleClose()
                cleanUpData()
            }}
            backdrop="static"
            // responsive="xl"
            placement="end"
        >
            {/* TOAST MESSAGE COMPONENT */}
            <ToastAlert
                show={toastMessage.show}
                onClose={() => setToastMessage(toastMessageInitialData)}
                message={toastMessage.message}
                variant={toastMessage.variant}
            />

            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{editAddon 
                    ? <JTranslation typeCase="pascal" text={EDIT_ADD_ON} /> 
                    : <JTranslation typeCase="pascal" text={ADD_NEW_ADD_ON} />
                }</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="row">
                    <div className=" col-md-12 col-lg-12 mb-3">
                        <label htmlFor="validationCustom01" className="form-label">
                            <JTranslation typeCase="pascal" text={NAME} />
                            <span className="mandatory ">*</span>{' '}
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            autoComplete="off"
                            maxLength={100}
                            value={formData.addonName}
                            data-testid="addon-name-input"
                            onChange={(e) => {
                                setFormData({ ...formData, addonName: e.target.value })
                            }}
                        />
                    </div>
                    <div className=" col-md-12 col-lg-12 mb-3">
                        <label htmlFor="validationCustom01" className="form-label">
                            <JTranslation typeCase="pascal" text={PRICE} />
                            <span className="mandatory ">*</span>{' '}
                        </label>
                        <div className="input-group custom-prepend-input">
                            <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                            </div>
                            <input
                                type="text"
                                className="form-control"
                                id="addonPrice"
                                autoComplete="off"
                                maxLength={10}
                                value={formData.addonPrice}
                                data-testid="addon-price-input"
                                onChange={(e) => {
                                    if (isDecimalNumberWithDecimalPoints(e,2)) {
										setFormData({ ...formData, addonPrice: e.target.value })
									}
                                }}
                            />
                        </div>
                    </div>
                </div>

                {associatedItems && associatedItems.length > 0 &&
                    <label htmlFor="validationCustom01" className="form-label">
                        {associatedItems?.length > 1 
                            ? <JTranslation typeCase="pascal" text={ASSOCIATED_ITEMS} /> 
                            : <JTranslation typeCase="pascal" text={ASSOCIATED_ITEM} />
                        }
                    </label>
                }

                <div className=" col-md-12 col-lg-12 mb-3">
                    {editAddon &&
                        associatedItems?.map((item) => {
                            return <div key={uuidv4()} className="capsule">{item.itemName}</div>
                        })}
                </div>

                <div className="save-btn-section shadow save-btn-absolute">
                    <button
                        className="btn btn-custom-primary-outline"
                        type="button"
                        data-testid="cancel-btn"
                        onClick={() => {
                            handleClose()
                            cleanUpData()
                        }}
                    >
                        <JTranslation typeCase="pascal" text={CANCEL} />
                    </button>

                    <button
                        className="btn btn-custom-primary"
                        type="button"
                        disabled={!isFormValid()}
                        data-testid="save-btn"
                        onClick={() => {
                            // validate and submit form
                            if (isFormValid()) {
                                submitForm()
                            }
                        }}
                    >
                        {editAddon 
                            ? <JTranslation typeCase="pascal" text={UPDATE} /> 
                            : <JTranslation typeCase="pascal" text={SAVE} />
                        }
                    </button>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default AddOnsSideBar