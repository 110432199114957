import { AxiosResponse, } from "axios";
import { getErrorMessage } from "./utils";
import { HttpMethods, AlertVariant } from "../constants/constants";
import { listAllAddOnsApi, addOnsApi, addOnsSingleApi } from "../constants/apiEndPoints";
import { Payload, NewAddon, DeleteAddon, SingleAddon, SingleAddOnResponse } from "../constants/staticTypes";
import { UseMutationResult } from "react-query";
import HttpServiceHelper from "./httpServiceHelper";

// initial form state
export const initialFormData = {
    addonName: "",
    addonPrice: "",
    addonDescription: '',
    addonDiscount: '',
    addonImageKey: ''
}

// initial state for newAddon
export const initialAddOnDeleteData: DeleteAddon = {
    addonId: ""
}

// fetch all users
export const getAddOnsList = (search: string, limit: number, lastKey: string) => {
    return HttpServiceHelper({
        url: listAllAddOnsApi + "?search=" + search + "&limit=" + limit + "&lastKey=" + lastKey,
        method: HttpMethods.GET
    })
}

// create new add-on
export const createNewAddOn = (
    addOnMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    addOnData: NewAddon,
    onSuccess: (message: string, variant: string) => void,
    onError: (res: string, variant: string) => void) => {
    addOnMutation.mutate({
        url: addOnsApi,
        method: HttpMethods.POST,
        data: addOnData,
    }, {
        onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    });
}

// update add-on
export const updateAddOn = (
    addOnMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    addOnData: NewAddon,
    onSuccess: (message: string, variant: string) => void,
    onError: (res: string, variant: string) => void) => {
    addOnMutation.mutate({
        url: addOnsApi,
        method: HttpMethods.PUT,
        data: addOnData,
    }, {
        onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    });
}

// delete add-on
export const deleteAddOn = (
    addOnMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    addOnData: DeleteAddon,
    onSuccess: (message: string, variant: string) => void,
    onError: (res: string, variant: string) => void) => {
    addOnMutation.mutate({
        url: addOnsApi,
        method: HttpMethods.DELETE,
        data: addOnData,
    }, {
        onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    });
}

// single add-on details
export const fetchSingleAddOn = (
    addOnMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    param: SingleAddon,
    onSuccess: (data: SingleAddOnResponse) => void,
    onError: (res: string, variant: string) => void) => {
    addOnMutation.mutate({
        url: addOnsSingleApi,
        method: HttpMethods.POST,
        data: param,
    }, {
        onSuccess: (res) => onSuccess(res.data.data),
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    });
}
