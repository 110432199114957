import { AxiosError } from "axios";
import { ErrorMessage, FormatTime, DateFormat, DateTimeFormat, GenerateAiDescription, GenerateImageAiDescription, DurationInput, DaysInput, Menu, CustomMenuCategoriesAndItems, TimezoneInfo, DayOfWeek } from "../constants/staticTypes";
import { DATE_TIME_FORMAT, TIME_FORMAT, DATE_FORMAT, SELECTED_MENU, SPECIAL_MENU_CODE, STAFF_ID, USER_NAME, TIMEZONE_INFO } from "../constants/constants";
import { PERMISSIONS, PERMISSION_LIST, SHOW_LOADER, TENANT_INFO } from "../constants/queryKeys";
import { Popover } from "react-bootstrap";
import { QueryClient } from "react-query";
import { routes } from "../constants/routes";
import { UNKNOWN_ERROR } from "../constants/strings";
import dayjs from 'dayjs';
import moment from 'moment';
import placeholder from "../assets/images/placeholder-avatar.jpg";
import { userLogout } from "./authHelper";
import configuredDayjs from "../config/dayjs.config";
import { JTranslation } from "./jTranslate";
import * as momentTz from 'moment-timezone';
import htmlToDraft from "html-to-draftjs";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import he from "he";

// strong password check
export const isStrongPassword = (password: string) => {
    const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])(?=.*[!@#$%^&*])(?=.{6,})/; // nosonar
    if (strongRegex.test(password)) {
        return true;
    }
    return false;
}

// toggle sidebar 
export const setSidebarToggle = (value?: boolean) => ({ value });

// convert to pascal case string
export const toPascalCase = (value: string) => value?.replace(/\b\w(?:['\w]*)/g,
    function (match) { return match.charAt(0).toUpperCase() + match.substring(1).toLowerCase(); });

// set loader
export const setLoader = (show = false) => ({ show });

// show / hide loader
export const showLoader = (queryClient: QueryClient, show: boolean) =>
    queryClient.setQueryData(SHOW_LOADER, setLoader(show));

// This function is triggered if an error occurs while loading an image
export const imageOnErrorHandler = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
) => {
    event.currentTarget.src = placeholder;
    event.currentTarget.className = "error dataAvatar";
};

// get error message from axios response
export const getErrorMessage = (error: unknown) => {
    let axiosError = error as AxiosError;
    let errorData = {
        ...axiosError.response as ErrorMessage
    }

    if (axiosError.response?.status === 422) {
        return errorData.data.errors[0].message
    } else {
        return errorData.data?.message !== undefined ? errorData.data.message : UNKNOWN_ERROR;
    }
}

// check if value is number or decimal
export const isDecimalNumber = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const decimalValidationRegex = /^[+-]?\d*(?:[.,]\d*)?$/;
    if (decimalValidationRegex.test(evt.target.value))
        return true;
    return false;
}

// html to plain text
export const htmlToText = (value: string) => {
    if (value !== null) {
        let plainText = value.replace(/<[^>]+>/ig, '');
        plainText = plainText.replaceAll("&nbsp;", '');
        return plainText;
    }
    return "";
}

// check permission for user
export const checkPermission = (queryClient: QueryClient, feature: string, action?: string) => {
    const permissionData = queryClient.getQueryData(PERMISSIONS) as any;
    const permissions = permissionData?.permissions ? permissionData.permissions : getPermissionFromLocalStorage();
    if (permissions) {
        const keys = Object.keys(permissions);
        if (keys.includes(feature)) {
            if (action) {
                if (permissions[feature].includes(action)) {
                    return true;
                }
            } else {
                return true;
            }
        } else if (permissions.isAdmin) {
            return true;
        }
    }
    return false;
}

export const getPermissionFromLocalStorage = () => {
    const permission = localStorage.getItem(PERMISSIONS);
    const permissionObject = JSON.parse(permission!);
    return permissionObject;
}

// check to display dashboard
export const showDashboard = (queryClient: QueryClient) => {
    const permissionData = queryClient.getQueryData(PERMISSIONS) as any;
    const permissions = permissionData?.permissions;
    if (permissions && Object.keys(permissions).length > 0) {
        return true;
    }
    return false;
}

// get item change status
export const isItemUnchanged = (oldItem: any, newItem: any) => {
    return (JSON.stringify(oldItem) === JSON.stringify(newItem));
}

// set user data
export const setUserData = (prevData: any,) => ({ prevData });

// format date mm/dd/yyyy
export const formatDate = (date: string | number) => {
    if (date !== null) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('/');
    }
    return "";
}

// game wheel rotation
export const getRotationDegrees = (
    prizeNumber: number,
    numberOfPrizes: number
) => {
    const degreesPerPrize = 360 / numberOfPrizes;
    const initialRotation = 88 + degreesPerPrize / 2;
    const randomDifference = (-1 + Math.random() * 2) * degreesPerPrize * 0.35;

    const prizeRotation =
        degreesPerPrize * (numberOfPrizes - prizeNumber) -
        initialRotation +
        randomDifference;

    return numberOfPrizes - prizeNumber > numberOfPrizes / 2
        ? -360 + prizeRotation
        : prizeRotation;
};

export const clamp = (min: number, max: number, val: number) =>
    Math.min(Math.max(min, +val), max);

// get grid link class name
const getButtonClassName = (disabled: boolean) => {
    let className = "p-button p-component p-paginator-next p-paginator-element p-link p-button-icon-only";
    if (disabled) {
        className = className + " p-disabled"
    }
    return className;
}

// trigger pagination
const triggerPagination = (
    setPagination: (value: React.SetStateAction<boolean>) => void,
    onSearchTrigger: (searchKey: string, lastKeyValue: string | null) => void,
    lastKey: string,
    searchValue: string) => {
    setPagination(true);
    onSearchTrigger(searchValue, lastKey)
}

// get custom paginator template for grid
export const getCustomPaginator = (
    setPagination: (value: React.SetStateAction<boolean>) => void,
    onSearchTrigger: (searchKey: string, lastKeyValue: string | null) => void,
    dataLength: number,
    lastKey: string | null,
    searchValue: string
) => {
    const customPaginatorTemplate = {
        layout:
            "PrevPageLink PageLinks NextPageLink",
        PrevPageLink: (options: any) => {
            if (dataLength === 0) {
                return <></>
            }
            return (
                <button aria-label="Next Page"
                    type="button"
                    disabled={options.disabled}
                    onClick={options.onClick}
                    className={getButtonClassName(options.disabled)}>
                    <span className="p-button-icon p-c p-paginator-icon pi pi-angle-left"></span>
                    <span className="p-button-label p-c">&nbsp;</span>
                </button>
            );
        },
        NextPageLink: (options: any) => {
            if (dataLength === 0) {
                return <></>
            }
            const disabled = lastKey === null && options.disabled;
            return (
                <button aria-label="Next Page"
                    type="button"
                    disabled={disabled}
                    onClick={lastKey !== null && options.disabled ?
                        () => triggerPagination(setPagination, onSearchTrigger, lastKey, searchValue) : options.onClick}
                    className={getButtonClassName(disabled)}>
                    <span className="p-button-icon p-c p-paginator-icon pi pi-angle-right"></span>
                    <span className="p-button-label p-c">&nbsp;</span>
                </button>
            );
        },
        PageLinks: (options: any) => {
            if (lastKey !== null && options.currentPage === options.totalPages - 1 &&
                options.page === options.totalPages - 3) {
                return <></>
            }
            return (
                <>
                    <button
                        type="button"
                        className={options.className}
                        onClick={options.onClick}
                    >
                        {options.page + 1}
                    </button>
                    {lastKey !== null && options.currentPage === options.totalPages - 1 &&
                        options.currentPage === options.page && (
                            <button
                                type="button"
                                className={options.className.replace("p-highlight", "")}
                                onClick={() => triggerPagination(setPagination, onSearchTrigger, lastKey, searchValue)}
                            >
                                {options.page + 2}
                            </button>
                        )}
                </>
            );
        },
        RowsPerPageDropdown: <></>,
        CurrentPageReport: <></>,
        FirstPageLink: <></>,
        LastPageLink: <></>,
        JumpToPageInput: <></>
    };
    return customPaginatorTemplate;
}

// get date value
export const getDateValue = (value: string) => {
    if (value.length > 0) {
        return new Date(value);
    } else {
        return null;
    }
}


//popover
export const popover = (title: string, content: string) => {
    return <Popover id="popover-basic" className="shadow">
        <Popover.Header as="h3"><JTranslation typeCase="pascal" text={title} /></Popover.Header>
        <Popover.Body>
            <JTranslation typeCase="none" text={content} />
        </Popover.Body>
    </Popover>
}

//popover
export const popoverTemplate = (title: string, content: JSX.Element) => {
    return <Popover id="popover-basic" className="shadow">
        <Popover.Header as="h3"><JTranslation typeCase="pascal" text={title} /></Popover.Header>
        <Popover.Body>
            {content}
        </Popover.Body>
    </Popover>
}

// get drop down year for graph
export const getYearRange = () => {
    let year_range_data = [];
    for (let i = new Date().getFullYear(); i >= 2020; i--) {
        year_range_data.push(i);
    }
    return year_range_data;
}

// initial capitalize
export const capitalizeFirstLetter = (input: string | null) => {
    if (!input) return "";

    const words = input.toLowerCase().split('_');
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalizedWords.join('');
};

export const getFirstThreeLetter = (input: string | null) => {
    if (!input) return "";

    return input.charAt(0).toUpperCase() + input.slice(1, 3);
}

// detect device platform
export const isMobile = () => {
    return /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent)
}

// detect if the path is public / user facing 
export const isPathPublic = (pathname: string) => {
    if (pathname.startsWith(routes.menu_view) || pathname.startsWith(routes.special_menu)) {
        return true;
    }
    switch (pathname) {
        case routes.welcome_page:
        case routes.login:
        case routes.menu_item_view:
        case routes.menu_view:
        case routes.survey_guest:
        case routes.guest_game:
        case routes.set_password:
        case routes.forgot:
        case routes.reset:
            return true
        default:
            return false
    }
}

/**
 * Sleeps for a specified amount of time.
 * @param {number} ms - The type to sleep in milliseconds.
 * @returns {Promise<void>} - A promise that resolves after the specified time.
 * Usage - await sleep(1000)
 */
export const sleep = async (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
};


export const isJSONObjectEmpty = (obj: any): boolean => {
    if (typeof obj !== 'object') {
        return true;
    }

    return Object.keys(obj).length === 0;
}

export const isWysiwygEditorEmpty = (value: string) => {
    if (!value) {
        return true
    } else if (value === "<p></p>\n") {
        return true
    } else {
        return false
    }
}

export const getDomainFromURL = (): string => {
    const url = new URL(window.location.href);
    return url.origin;
};

export const timeFormatter = ({ time, format = TIME_FORMAT }: FormatTime) => {
    let momentTime = moment(time, 'HH:mm').format(format);

    const timezoneInfo = JSON.parse(localStorage.getItem(TIMEZONE_INFO) as string) as TimezoneInfo
    let utcOffset = 0;
    if (timezoneInfo) {
        const { defaultTimezone } = timezoneInfo
        utcOffset = momentTz.tz(defaultTimezone).utcOffset()
    }

    if (momentTime === "Invalid date") {
        return moment.unix(Number(time)).utcOffset(utcOffset).format(format);
    } else {
        return moment(time, 'HH:mm').utcOffset(utcOffset).format(format)
    }
}

export const dateFormatter = ({ date, format = DATE_FORMAT }: DateFormat) => {
    const timezoneInfo = JSON.parse(localStorage.getItem(TIMEZONE_INFO) as string) as TimezoneInfo
    if (timezoneInfo) {
        const { defaultTimezone } = timezoneInfo
        const utcOffset = momentTz.tz(defaultTimezone).utcOffset()
        return moment(date).utcOffset(utcOffset).format(format)
    }
    return moment(date).format(format)
}

export const dateTimeFormatter = ({ dateTime, format = DATE_TIME_FORMAT }: DateTimeFormat) => {
    return moment(dateTime).format(format)
}

export const aiTextGeneratorParams: GenerateAiDescription = {
    type: "",
    negativeText: "",
    positiveText: "",
    textLength: "short"
}

export const aiImageGeneratorParams: GenerateImageAiDescription = {
    type: "",
    negativeText: "",
    positiveText: "",
    imageSize: "small",
    maxResponseCount: 5,
}

export const isNaNCheck = (value: any) => Number.isNaN(value)

export const calculateDuration = ({
    type = 'full-day',
    fromDate,
    toDate,
    startTime,
    endTime,
}: DurationInput): string => {
    if (type === 'time-off') {
        const start = dayjs(startTime)
        const end = dayjs(endTime)

        // Calculate the time difference in minutes
        const durationInMinutes = end.diff(start, 'minutes')

        // Convert minutes to hours with decimal points
        const durationInHours = durationInMinutes / 60

        // Limit to one decimal place
        return durationInHours.toFixed(1) === '1.0' ? '1 hour' : `${durationInHours.toFixed(1)} hours`;
    } else if (type === 'full-day' || type === null) {
        const from = dayjs(fromDate)
        const to = dayjs(toDate)

        // Calculate the time difference in days
        const durationInDays = to.diff(from, 'days') + 1
        if (durationInDays === 0) {
            return '1 day'; // If start and end date are the same or it's just one day
        }

        return durationInDays === 1 ? `${durationInDays} day` : `${durationInDays} days`
    } else {
        throw new Error('Invalid duration type provided');
    }
}

export const calculateDays = ({ fromDate, toDate }: DaysInput): string => {
    const from = dayjs(fromDate)
    const to = dayjs(toDate)

    // Calculate the time difference in days
    const durationInDays = to.diff(from, 'days') + 1
    if (durationInDays === 0) {
        return '1 day'; // If start and end date are the same or it's just one day
    }

    return durationInDays === 1 ? `${durationInDays} day` : `${durationInDays} days`
}

export const getStaffId = (): string => {
    const staffId = localStorage.getItem(STAFF_ID)
    if (staffId) {
        return staffId
    } else {
        return 'superadmin'
    }
}

export const getNumberWithTwoDecimals = (value: number): string => {
    if (value.toString().includes('.')) {
        const parts = value.toString().split('.')
        if (parts[1].length === 1) {
            return `${parts[0]}.${parts[1]}0`
        }
        return `${Math.floor(value * 100) / 100}`
    }
    return `${value.toFixed(2)}`
}

// check if value is number or decimal with decimal points
export const isDecimalNumberWithDecimalPoints = (evt: React.ChangeEvent<HTMLInputElement>, decimalPoints: number) => {
    const decimalValidationRegex = new RegExp(`^[+-]?\\d*(?:[.,]\\d{0,${decimalPoints}})?$`);
    if (decimalValidationRegex.test(evt.target.value))
        return true;
    return false;
}

type logoutProps = {
    queryClient: QueryClient
}

export const logoutActions = ({ queryClient }: logoutProps) => {
    // Excluding public APIs from logout
    const excludeQueries = [
        PERMISSIONS,
        PERMISSION_LIST,
        TENANT_INFO,
        SHOW_LOADER
    ]
    const allQueries = queryClient.getQueryCache().getAll();
    for (const query of allQueries) {
        if (!excludeQueries.includes(query.queryKey as string)) {
            queryClient.removeQueries(query)
        }
    }

    localStorage.removeItem(USER_NAME)
    localStorage.removeItem(PERMISSIONS)
    localStorage.removeItem(STAFF_ID)
    localStorage.removeItem('activeMenu')
    localStorage.removeItem(SELECTED_MENU);
    localStorage.removeItem(SPECIAL_MENU_CODE);
    userLogout()

    // manually removing AWS storage
    for (const key in localStorage) {
        if (key.includes('CognitoIdentityServiceProvider')) {
            localStorage.removeItem(key);
        }
    }
}

// data-table column sort
export const dataTableColumnSort = (data: Readonly<any[]>, key: string, order: number | null | undefined) => {
    return data?.slice()?.sort((a, b) => {
        const valA = a[key].toUpperCase(); // Convert to uppercase for case-insensitive sorting
        const valB = b[key].toUpperCase();
        if (valA < valB) {
            return order === 1 ? -1 : 1;
        } else if (valA > valB) {
            return order === 1 ? 1 : -1;
        }
        return 0;
    });
}

// Check if a path is a guest-only route
export const isGuestOnlyRoute = (path: string, guestPages: string[]): boolean => {
    return (
        guestPages.includes(path) ||
        path.startsWith('/guest/') ||
        path.startsWith('/menu/') ||
        path.startsWith('/news/') ||
        path.startsWith('/events/') ||
        path.startsWith('/item-view/') ||
        path.startsWith('/special-menu/') ||
        path.startsWith('/guest-survey/') ||
        path.startsWith('/item-view-special-menu/')
    );
}

export const isUserLoggedInCognito = () => {
    let isLoggedIn = false
    for (const key in localStorage) {
        if (key.includes('CognitoIdentityServiceProvider')) {
            isLoggedIn = true
        }
    }

    return isLoggedIn
}

export const isQrScanPresent = () => {
    // Get the query string from the URL
    const queryString = window.location.search;

    // Create a URLSearchParams object from the query string
    const params = new URLSearchParams(queryString);
    // Check if the 'qr_scan' parameter is present and return a boolean
    let result = params.has('qr_scan');
    if (result) localStorage.setItem('qr_scan', 'true');
    return result;
}

// get menu and category slugs
export const menuAndCategorySlugsFromItem = (
    menuList: Menu[],
    itemId: string
): { menuSlug: string; categorySlug: string } => {
    let menuSlug = '';
    let categorySlug = '';
    for (const category of menuList) {
        for (const subCategory of category.subCategory) {
            const foundItem = subCategory.menuItems.find(item => item.id === itemId);
            if (foundItem) {
                menuSlug = category.urlSlug ?? '';
                categorySlug = subCategory.urlSlug ?? '';
                break;
            }
        }
    }
    return { menuSlug, categorySlug };
};

export const getRootElement = (): HTMLElement => {
    return document.querySelector('html') as HTMLElement;
}

export const changeFontSize = (delta: number | null) => {
    const rootElement = getRootElement();
    if (delta) {
        // const currentSize = parseFloat(rootElement.style.fontSize) || 14;
        // const newSize = Math.max(0.5, currentSize + delta);
        rootElement.style.fontSize = `calc(var(--font-size) + ${delta}px)`;

        // Skip changing font size for the <nav> element
        const skipElements = [
            {
                element: rootElement.querySelector('.login-header') as HTMLElement,
                initialFontSize: '14px',
            },
            {
                element: rootElement.querySelector('.font-slider') as HTMLElement,
                initialFontSize: '14px',
            },
            {
                element: rootElement.querySelector('.headerBar') as HTMLElement,
                initialFontSize: '14px',
            },
            {
                element: rootElement.querySelector('.qr-code-scan>button>span.p-button-icon.p-c.pi.pi-qrcode') as HTMLElement,
                initialFontSize: '17.5px',
            },
            {
                element: rootElement.querySelector('.ham-container>.tg-btn-clps>.btn') as HTMLElement,
                initialFontSize: '26px',
            },
            {
                element: rootElement.querySelector('.login-user') as HTMLElement,
                initialFontSize: '14px',
            },
        ]
        for (const htmlElement of skipElements) {
            if (htmlElement.element) {
                (htmlElement.element).style.fontSize = htmlElement.initialFontSize;
            }
        }
    } else {
        rootElement.style.fontSize = '';
    }
}

// get all days of the week from given data
export const getAllWeekDays = (date: dayjs.Dayjs, format?: string) => {
    const dayJs = configuredDayjs();
    const weekDates = [];
    const firstDayOfWeek = dayJs(date).startOf("week");
    for (let i = 0; i < 7; i++) {
        let day = firstDayOfWeek.add(i, "days");
        if (format) {
            day.format(format)
        }
        weekDates.push(day);
    }
    return weekDates;
}

// Deep clone the initial values to prevent unintended mutation
export const customDeepClone = (value: any): any => {
    if (dayjs.isDayjs(value)) {
        return dayjs(value); // or value.clone() depending on your needs
    }

    if (Array.isArray(value)) {
        return value.map(customDeepClone);
    }

    if (typeof value === 'object' && value !== null) {
        const clonedObject: Record<string, any> = {};
        for (const key in value) {
            if (Object.prototype.hasOwnProperty.call(value, key)) {
                clonedObject[key] = customDeepClone(value[key]);
            }
        }
        return clonedObject;
    }

    return value;
};

// get all days from given start and end date
export const getAllDays = (fromDate: dayjs.Dayjs, toDate: dayjs.Dayjs) => {

    // Check if date is valid
    if (!fromDate.isValid() || !toDate.isValid()) {
        // throw new Error("Invalid date provided.");
        return []
    }

    // Check if start is after to date
    if (toDate.isBefore(fromDate)) {
        // throw new Error(
        // 	`From date should be before To date. From: ${fromDate.format('DD-MM-YYYY')} To: ${toDate.format('DD-MM-YYYY')}`
        // )
        return []
    }

    const dates: dayjs.Dayjs[] = [];
    for (let day = fromDate; day.diff(toDate, "days") <= 0; day = day.add(1, "day")) {
        dates.push(day);
    }
    return dates
}

export const replaceMessageValues = (str: string, values: any[]): string => {
    const regex = /TXT/g;
    const matches = str.match(regex);
    if (matches && matches.length !== values.length) {
        console.error("The number of 'TXT' occurrences must match the number of values provided");
        return str;
    }

    return str.replace(regex, (_) => values.shift() || "");
}

export const getFirstLetterUpperCase = (input: string): string => input?.charAt(0).toUpperCase();

export const filterMenuList = (data: Menu[], searchMenu: string, searchForAdminSide = false) => {
    const keywordRegex = searchMenu ? new RegExp(searchMenu, 'i') : null
    let filteredData: Menu[] = data.map((categoryItem) => {
        const filteredCategory: Menu = { ...categoryItem }
        filteredCategory.subCategory = categoryItem.subCategory
            .map((subCategoryItem) => {
                const filteredSubCategory = { ...subCategoryItem }
                filteredSubCategory.menuItems = subCategoryItem.menuItems.filter((menuItem) => {
                    return !keywordRegex || keywordRegex.test(menuItem.itemName)
                })
                return filteredSubCategory
            })
            .filter((subCategoryItem) => {
                return subCategoryItem.menuItems.length > 0
            })
        return filteredCategory
    })

    if (searchForAdminSide) {
        filteredData = filteredData.filter((menuItem) => {
            return menuItem.subCategory.length > 0
        })
    }

    filteredData = filteredData.sort((a, b) => {
        const aHasItems = +a.subCategory.some((sub) => sub.menuItems.length > 0)
        const bHasItems = +b.subCategory.some((sub) => sub.menuItems.length > 0)
        return bHasItems - aHasItems
    })
    return filteredData
}

export const countMenuItems = (data: Menu[], searchMenu: string, searchForAdminSide = false) => {
    const keywordRegex = searchMenu ? new RegExp(searchMenu, 'i') : null;
    let totalMenuItemCount = 0;

    data.forEach((categoryItem) => {
        categoryItem.subCategory.forEach((subCategoryItem) => {
            const filteredMenuItems = subCategoryItem.menuItems.filter((menuItem) => {
                return !keywordRegex || keywordRegex.test(menuItem.itemName);
            });
            totalMenuItemCount += filteredMenuItems.length;
        });
    });

    return totalMenuItemCount;
}

export const filterCustomMenuList = (data: CustomMenuCategoriesAndItems[], searchMenu: string) => {
    const keywordRegex = searchMenu ? new RegExp(searchMenu, 'i') : null
    let filteredData: CustomMenuCategoriesAndItems[] = data
        .map((CustomMenuCategoriesAndItem) => {
            const filteredCustomMenuCategoriesAndItem: CustomMenuCategoriesAndItems = { ...CustomMenuCategoriesAndItem }
            filteredCustomMenuCategoriesAndItem.menuItems = filteredCustomMenuCategoriesAndItem.menuItems.filter(
                (menuItem) => {
                    return !keywordRegex || keywordRegex.test(menuItem.itemName)
                }
            )
            return filteredCustomMenuCategoriesAndItem
        })
        .filter((CustomMenuCategoriesAndItems) => {
            return CustomMenuCategoriesAndItems.menuItems.length > 0
        })
    return filteredData
}

export const convertUTCtoLocalByOffset = (
    date: string | number | dayjs.Dayjs | moment.Moment | Date,
    {
        inclTime = false,
        isUtc = true,
        format = '',
        humanizeFormat = false
    }: {
        inclTime?: boolean;
        isUtc?: boolean;
        format?: string;
        humanizeFormat?: boolean;
    } = {}
): string => {
    if (!date) {
        return ''
    }

    let localDate: moment.Moment
    try {
        if (typeof date === 'string' || typeof date === 'number') {
            localDate = moment.utc(new Date(date))
        } else if (moment.isMoment(date)) {
            localDate = date.clone()
        } else if (dayjs.isDayjs(date)) {
            localDate = moment.utc(date.toDate())
        } else if (date instanceof Date) {
            localDate = moment.utc(date)
        } else {
            throw new Error('Invalid date format')
        }

        if (!localDate.isValid()) {
            console.error(`Invalid date: ${date}`)
            return ''
        }
    } catch (error) {
        console.error(`Error converting UTC date to local date: ${error} for ${date}`)
        return ''
    }
    const timezoneInfo = JSON.parse(localStorage.getItem(TIMEZONE_INFO) as string) as TimezoneInfo
    if (!timezoneInfo) {
        console.error('Timezone info not found in local storage')
        return ''
    }

    const { dayLightSavingOffSetInMinutes, defaultDateFormat, defaultTimeFormat, defaultTimezone, isDayLightSaving } =
        timezoneInfo

    if (isUtc) {
        let utcOffset = momentTz.tz(defaultTimezone).utcOffset()
        // Moment TZ will handle DST correctly, so no need for additional logic for now
        // if (isDayLightSaving) {
        // const offset = parseInt(dayLightSavingOffSetInMinutes)
        // if (isNaN(offset)) {
        //     console.error('Invalid daylight saving offset')
        //     return ''
        // }
        // utcOffset += offset
        // }
        localDate = localDate.utcOffset(utcOffset)
    }

    if (format.trim().length === 0) {
        format = `${defaultDateFormat} ${inclTime ? defaultTimeFormat : ''}`
    }

    if (humanizeFormat) {
        return localDate.fromNow()
    }

    return localDate.format(format)
}

export const convertTextToHTML = (text: string) => {
    const blocksFromHtml = htmlToDraft(text)
    const { contentBlocks, entityMap } = blocksFromHtml

    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
    const editorState = EditorState.createWithContent(contentState)

    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const htmlOutput = draftToHtml(rawContentState);

    return htmlOutput
}

export const escapeHtml = (html: string | undefined | null): string => {
    if (!html || html?.trim().length === 0) return ''
    // return html
    //     .replace(/&/g, '&amp;')
    //     .replace(/</g, '&lt;')
    //     .replace(/>/g, '&gt;')
    //     .replace(/"/g, '&quot;')
    //     .replace(/'/g, '&#039;')
    return he.encode(html, { encodeEverything: true })
}

export const unescapeHtml = (encodedHtml: string | undefined | null): string => {
    if (!encodedHtml || encodedHtml?.trim().length === 0) return ''
    // return encodedHtml
    //     .replace(/&amp;/g, '&')
    //     .replace(/&lt;/g, '<')
    //     .replace(/&gt;/g, '>')
    //     .replace(/&quot;/g, '"')
    //     .replace(/&#039;/g, "'")
    return he.decode(encodedHtml)
}

export const htmlToString = (html: string): string => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    if (!doc.body) return "";

    // Function to recursively extract text content while preserving spaces for line breaks
    const extractTextWithSpaces = (node: Node): string => {
        let text = "";
        node.childNodes.forEach(child => {
            if (child.nodeType === Node.TEXT_NODE) {
                text += child.textContent;
            } else if (child.nodeType === Node.ELEMENT_NODE) {
                const element = child as HTMLElement;
                // Add space for block-level elements to prevent concatenation
                if (element.tagName === 'BR' || element.tagName === 'P' || element.tagName === 'DIV' ||
                    element.tagName === 'H1' || element.tagName === 'H2' || element.tagName === 'H3' ||
                    element.tagName === 'H4' || element.tagName === 'H5' || element.tagName === 'H6' ||
                    element.tagName === 'LI') {
                    text += " ";
                }
                text += extractTextWithSpaces(child);
            }
        });
        return text;
    };

    return extractTextWithSpaces(doc.body).trim();
};

export const isEmptyObject = (obj: object): boolean => {
    return Object.keys(obj).length === 0;
};

export const formatTimeToAmPm = (timeString: string) => dayjs(timeString, 'HH:mm:ss').format('hh:mm A')

export const hexToRgb = (hex: string) => {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, '');

    // Parse the hex color code into its RGB components
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    return { r, g, b };
};