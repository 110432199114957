import { UseMutationResult } from 'react-query'
import { permissionPublicApi, permissionRoleListApi } from '../constants/apiEndPoints'
import { HttpMethods } from '../constants/constants'
import {
	PermissionList,
	Permission,
	RolePermissions,
	PermissionCreationForm,
	Payload,
	PermissionForm,
	PermissionListForm,
} from '../constants/staticTypes'
import HttpServiceHelper from './httpServiceHelper'
import { AxiosResponse } from 'axios'
import { getErrorMessage } from './utils'

// get user info and assigned permissions
export const getPermissionList = () => {
	return HttpServiceHelper({
		// url: permissionListApi,
		url: permissionPublicApi,
		method: HttpMethods.GET,
		noAuth: true,
	})
}

// get permission list based on roles
export const getPermissionRoles = () => {
	return HttpServiceHelper({
		url: `${permissionRoleListApi}?sort[updatedAt]=DESC`,
		method: HttpMethods.GET,
	})
}

// simply permission data
export const simplifyPermissions = (permissionList: PermissionList[]): Permission[] => {
	const flattenPermissions = (permissions: Permission[]): Permission[] => {
		return permissions.map((permission) => {
			const { permissions, ...rest } = permission
			return {
				...rest,
				permissions: flattenPermissions(permissions),
			}
		})
	}

	let result: Permission[] = []

	permissionList.forEach((module) => {
		result = result.concat(flattenPermissions(module.permissions))
	})

	return result
}

// convert permission to permission form
export const convertListToPermissionForm = (permissions: Permission[]): PermissionForm[] => {
	let permissionFormData: PermissionForm[] = []

	permissions.forEach((item) => {
		if (item.isActive) {
			permissionFormData.push({
				defaultPermissionId: item.id,
				isActive: item.isActive,
			})
		}

		if (item.permissions && item.permissions.length > 0) {
			permissionFormData = permissionFormData.concat(convertListToPermissionForm(item.permissions))
		}
	})

	return permissionFormData
}

export const updateFullPermissions = (fullPermissions: Permission[], latestDetails: Permission[]): Permission[] => {
	const userPermissionsMap = new Map()
	latestDetails.forEach((up) => userPermissionsMap.set(up.id, up))

	const updatePermission = (permission: Permission) => {
		const userPermission = userPermissionsMap.get(permission.id)
		if (userPermission) {
			permission.isActive = userPermission.isActive
			if (permission.permissions && userPermission.permissions) {
				const userSubPermissionsMap = new Map()
				userPermission.permissions.forEach((permission: Permission) =>
					userSubPermissionsMap.set(permission.permissionId, permission)
				)
				permission.permissions.forEach((subPermission) => {
					const userSubPermission = userSubPermissionsMap.get(subPermission.id)
					if (userSubPermission) {
						subPermission.isActive = userSubPermission.isActive
					}
				})
			}
		}
		return permission
	}

	return fullPermissions.map(updatePermission)
}

// Recursively update nested permissions
export const updatePermissionsItems = (
	permissionList: Permission[],
	parentId: string,
	updatedValue: boolean
): Permission[] => {
	const flattenPermissions = (permissions: Permission[]): Permission[] => {
		return permissions.map((permission) => {
			const { permissions, isActive, ...rest } = permission
			return {
				...rest,
				isActive:
					permission?.id === parentId || permission?.parentPermissionId === parentId
						? updatedValue
						: permission.isActive,
				permissions: flattenPermissions(permissions),
			}
		})
	}
	return flattenPermissions(permissionList)
}

// Add custom permission
export const addCustomPermission = (
	addPermissionMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	item: PermissionCreationForm,
	onAddRoleSuccess: (item: RolePermissions) => void,
	onError: (errorMessage: string) => void
) => {
	addPermissionMutation.mutate(
		{
			url: permissionRoleListApi,
			method: HttpMethods.POST,
			data: item,
		},
		{
			onSuccess: (res) => onAddRoleSuccess(res.data?.data),
			onError: (res) => onError(getErrorMessage(res)),
		}
	)
}

// delete custom permission
export const deleteCustomPermission = (
	deletePermissionMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	permissionId: string,
	onDeleteSuccess: (permissionId: string) => void,
	onError: (errorMessage: string) => void
) => {
	deletePermissionMutation.mutate(
		{
			url: permissionRoleListApi,
			method: HttpMethods.DELETE,
			data: {
				id: permissionId,
			},
		},
		{
			onSuccess: () => onDeleteSuccess(permissionId),
			onError: (res) => onError(getErrorMessage(res)),
		}
	)
}

// update custom permission
export const updateCustomPermission = (
	updatePermissionMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	item: PermissionListForm,
	onUpdateSuccess: (item: { data: RolePermissions; message: string }) => void,
	onError: (errorMessage: string) => void
) => {
	updatePermissionMutation.mutate(
		{
			url: permissionRoleListApi,
			method: HttpMethods.PUT,
			data: item,
		},
		{
			onSuccess: (res) => onUpdateSuccess(res.data),
			onError: (res) => onError(getErrorMessage(res)),
		}
	)
}
