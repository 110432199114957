import { useQueryClient } from "react-query"
import useMutationHook from "../../hooks/useMutationHook"
import { useContext, useState } from "react"
import { CommonCtx } from "../../context/CommonCtxProvider"
import { AxiosResponse } from "axios"
import ToastAlert from "../../components/alert/ToastAlert"
import Loader from "../../components/loader/Loader"
import WarningModal from "../../components/warning_modal/WarningModal"
import { toastMessageInitialData } from "../../constants/constants"
import { ToastMessageProps, AreaType } from "../../constants/staticTypes"
import { DELETE_WARNING } from "../../constants/strings"
import useQueryHook from "../../hooks/useQueryHook"
import { AREA_LIST } from "../../constants/queryKeys"
import { deleteArea, getAreaList } from "../../helpers/manageAreaHelper"
import AreaListDataGrid from "../../components/area/AreaListDataGrid"
import AreaSideBar from "../../components/area/AreaSideBar"

function Area() {
    const queryClient = useQueryClient()
    const areaMutation = useMutationHook(queryClient, true)
    // CONTEXT VARIABLE
    const { setShowSideBar: setAddOnSideBar } = useContext(CommonCtx)
    // ALERT VARIABLES
    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
    // WARNING MODAL
    const [showWarning, setWarning] = useState(false) // Warning popup
    const [warningMessage, setWarningMessage] = useState('') // Warning message
    // STATE VARIABLES
    const [areaList, setAreaList] = useState<AreaType[]>([])
    const [editArea, setEditArea] = useState<AreaType>()
    const [deleteAreaData, setDeleteAreaData] = useState<AreaType>()
    const [isInitialLoad, setInitialLoad] = useState(true)

    // side bar close
    const handleClose = () => setAddOnSideBar(false)

    // area list fetch success
    const onSuccessOfAreaList = (res: AxiosResponse<any, any>) => {
        const responseData = (res.data?.data.lists ?? []) as AreaType[]
        setAreaList(responseData)
        setInitialLoad(false)
    }

    // fetch area list
    const areaData = useQueryHook(AREA_LIST, () => getAreaList('', 500, ''), onSuccessOfAreaList)

    // on trigger search
    const onSearchTrigger = (searchKey: string) => {
        if (areaData.isFetching) {
            queryClient.cancelQueries([AREA_LIST]) // nosonar
        }
        queryClient.fetchQuery(AREA_LIST, () => getAreaList(searchKey, 500, '')) // nosonar
    }

    // on add or update area success
    const onSuccess = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
        queryClient.refetchQueries([AREA_LIST]) // nosonar
    }

    // on add or update area error
    const onError = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
    }

    // on edit button click
    const onEditClick = (area: AreaType) => {
        setEditArea(area)
    }

    // on delete button click
    const onDeleteClick = (area: AreaType) => {
        setDeleteAreaData(area)
        setWarningMessage(DELETE_WARNING)
        setWarning(true)
    }

    // api call to delete add-on
    const deleteAreaApiCall = () => {
        if (!deleteAreaData) return
        deleteArea(areaMutation, deleteAreaData, onSuccess, onError)
    }

    if (areaData.isLoading || isInitialLoad) {
        return <Loader />
    }

    return (
        <>
            <WarningModal
                show={showWarning}
                title={warningMessage}
                onHide={() => {
                    setWarning(false)
                    setDeleteAreaData(undefined)
                }}
                callback={() => {
                    deleteAreaApiCall()
                    setWarning(false)
                }}
            />

            <ToastAlert
                data-testid="toast"
                message={toastMessage.message}
                onClose={() => setToastMessage(toastMessageInitialData)}
                show={toastMessage.show}
                variant={toastMessage.variant}
            />

            <AreaSideBar
                editArea={editArea}
                handleClose={handleClose}
                setEditArea={setEditArea}
            />

            <div className="col-md-12 col-lg-12 mb-3 h-100 ">
                <AreaListDataGrid
                    areaList={areaList}
                    editArea={editArea}
                    loading={areaData.isLoading}
                    onDeleteClick={onDeleteClick}
                    onEditClick={onEditClick}
                    onSearchTrigger={onSearchTrigger}
                />
            </div>
        </>
    )
}

export default Area