import { AxiosResponse } from 'axios'
import { getErrorMessage } from './utils'
import { HttpMethods, AlertVariant } from '../constants/constants'
import { UseMutationResult } from 'react-query'
import { holidayApi } from '../constants/apiEndPoints'
import * as Yup from 'yup';
import dayjs from 'dayjs'
import HttpServiceHelper from './httpServiceHelper'
import {
    AddHolidayResponse,
    ConfirmPopupType,
    HolidayData,
    HolidayFormDataType,
    Id,
    Payload,
} from '../constants/staticTypes'

export const currentYear = dayjs().year();

export const generateYearOptions = () => {
    const nextYear = dayjs().add(1, 'year').year();
    const years = [];

    for (let i = 0; i < 3; i++) {
        const year = nextYear - i;
        years.push({ label: `${year}`, value: `${year}` });
    }

    return years;
}

export const initialValuesForHoliday: HolidayFormDataType = {
    id: "",
    title: "",
    holidayType: "",
    startDate: dayjs().startOf('day').toDate(),
    endDate: dayjs().endOf('day').toDate(),
    durationType: 'full-day',
    timeLabel: 'morning',
    duration: '1 day',
    description: "",
    isPaid: false,
    isOpenForBusiness: true,
}

export const halfDayValidationSchema = Yup.object({
    title: Yup.string().required('Title is a required field'),
    startDate: Yup.date().required('From date is required'),
    durationType: Yup.string().required(),
    holidayType: Yup.string().required('Holiday type is a required field'),
    timeLabel: Yup.string().required('This is a required field'),
})

export const fullDayValidationSchema = Yup.object({
    title: Yup.string().required('Title is a required field'),
    durationType: Yup.string().required(),
    holidayType: Yup.string().required('Holiday type is a required field'),
    startDate: Yup.date()
        .required('From date is required')
        .test('date-range', 'From date must be less than or equal to To date', function (startDate, { parent }) {
            return dayjs(startDate).isBefore(parent.endDate, 'day') || dayjs(startDate).isSame(parent.endDate, 'day')
        }),
    endDate: Yup.date()
        .required('To date is required')
        .test('date-range', 'To date must be greater than or equal to From date', function (endDate, { parent }) {
            return dayjs(endDate).isAfter(parent.startDate, 'day') || dayjs(endDate).isSame(parent.startDate, 'day')
        }),
})

export const initialValuesForConfirmPopup: ConfirmPopupType = {
    visible: false,
    buttonName: '',
    requestId: '',
    action: 'PENDING',
}

// fetch all holiday list
export const getAllHolidaysList = (year = currentYear) => {
    return HttpServiceHelper({
        url: `${holidayApi}/list/${year}`,
        method: HttpMethods.GET,
    })
}

export const holidayAdd = (
    timeOffMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    params: HolidayFormDataType,
    onSuccess: (data: AddHolidayResponse) => void,
    onError: (res: string) => void
) => {
    timeOffMutation.mutate(
        {
            url: `${holidayApi}/item`,
            method: HttpMethods.POST,
            data: params,
        },
        {
            onSuccess: (res) => onSuccess(res.data),
            onError: (res) => onError(getErrorMessage(res)),
        }
    )
}

export const holidayUpdate = (
    timeOffMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    params: HolidayFormDataType,
    onSuccess: (data: AddHolidayResponse) => void,
    onError: (res: string) => void
) => {
    timeOffMutation.mutate(
        {
            url: `${holidayApi}/item`,
            method: HttpMethods.PUT,
            data: params,
        },
        {
            onSuccess: (res) => onSuccess(res.data),
            onError: (res) => onError(getErrorMessage(res)),
        }
    )
}

export const holidayDelete = (
    timeOffMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    params: Id,
    onSuccess: (data: AddHolidayResponse) => void,
    onError: (res: string, variant: string) => void
) => {
    timeOffMutation.mutate(
        {
            url: `${holidayApi}/id`,
            method: HttpMethods.DELETE,
            data: params,
        },
        {
            onSuccess: (res) => onSuccess(res.data),
            onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
        }
    )
}

export const getHolidayDataById = (
    timeOffMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    params: Id,
    onSuccess: (data: HolidayData) => void,
    onError: (res: string, variant: string) => void
) => {
    timeOffMutation.mutate(
        {
            url: `${holidayApi}/id`,
            method: HttpMethods.POST,
            data: params,
        },
        {
            onSuccess: (res) => onSuccess(res.data.data),
            onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
        }
    )
}
