import React, { useEffect, useState, useCallback, useContext } from "react";
import { ADD, LABEL, NAME, PRICE } from "../../../../constants/strings";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { FaPlus, FaDollarSign } from "react-icons/fa";
import { isDecimalNumberWithDecimalPoints } from "../../../../helpers/utils";
import { MenuItem, Price, ModalState } from "../../../../constants/staticTypes";
import { PriceKeys, PriceType } from "../../../../constants/constants";
import PriceComponent from '../draggable/PriceComponent';
import WarningModal from "../../../warning_modal/WarningModal";
import { JTranslation, TranslationContext, jTranslationText } from "../../../../helpers/jTranslate";

type Props = {
    priceRow: number[] | [],
    setPriceRow: React.Dispatch<React.SetStateAction<number[]>>,
    formData: MenuItem,
    setFormData: React.Dispatch<React.SetStateAction<MenuItem>>,
    type: PriceType
}

function AddPrice({ priceRow, setPriceRow, formData, setFormData, type }: Props) {
    const prices = type === PriceType.ITEM ? formData.itemPrices : formData.addonPrices;
    const key = type === PriceType.ITEM ? PriceKeys.ITEM : PriceKeys.ADDON;
    const maxDefaultCount = 2
    // STATE VARIABLES
    const [defaultCount, setDefaultCount] = useState(0)
    const [defaultRowInfo, setDefaultRowInfo] = useState(0)
    const [priceLabel, setPriceLabel] = useState("");
    const [priceValue, setPriceValue] = useState("");
    const [rowToBeDeleted, setRowToBeDeleted] = useState(0)
    const [translationText, setTranslatedText] = useState<{
        price: string,
        label: string,
      }>({ 
        price: PRICE,
        label: LABEL,
    });
    // WARNING MODAL STATE VARIABLES
    const [modalState, setModalState] = useState<ModalState>("ADD_DEFAULT_PRICE")
    const [modalTitle, setModalTitle] = useState("")
    const [showWarning, setWarning] = useState(false)

    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext;

    // drag n drop sensors
    const sensors = useSensors(useSensor(PointerSensor, {
        activationConstraint: {
            distance: 2
        }
    }));

    // sort prices for api payload
    const sortPriceForApi = (sortedList: number[], type: string) => {
        const priceKey = type === PriceType.ITEM ? PriceKeys.ITEM : PriceKeys.ADDON;
        let sortedFormData = formData;
        let newArray: Price[] = [];

        sortedList.forEach((sortedItem) => {
            newArray.push(sortedFormData[priceKey][sortedItem - 1]);
        });

        sortedFormData[priceKey] = newArray
        setFormData(sortedFormData)
    }

    // drag end handler
    const handleQuestionDragEnd = (event: DragEndEvent, price: number[], type: string) => {
        const { active, over } = event;
        const oldIndex = active?.data.current && active.data.current.sortable.index;
        const newIndex = over?.data.current && over?.data.current.sortable.index;
        let sortedList = arrayMove(price, oldIndex, newIndex);
        setPriceRow(sortedList)
        sortPriceForApi(sortedList, type)
    }

    const getAddDisabled = () => {
        let disabled = false;
        if (!priceValue) {
            disabled = true;
        }
        return disabled;
    }

    const addNewPriceRow = useCallback(() => {
        let newPriceRow = prices.map((_item, index) => {
            return index + 1;
        });
        setPriceRow(newPriceRow);
    }, [prices, setPriceRow])

    const deletePriceRow = (row: number) => {
        prices.splice(row, 1);
        setFormData({ ...formData, [key]: prices });
    }

    const showWarningModal = (state: ModalState, title: string) => {
        setWarning(true)
        setModalState(state)
        setModalTitle(title)
    }

    const changeDefaultPriceSelection = (row: number) => {
        let normalizedItemPrices: Price[] = []
        let modifiedFormData: MenuItem = formData

        formData?.itemPrices.forEach((rowData, index) => {
            if (row === index && defaultCount < maxDefaultCount) {
                normalizedItemPrices.push({ ...rowData, isDefault: !rowData.isDefault })
                setDefaultCount(defaultCount + 1)
            } else if (row === index && defaultCount === maxDefaultCount) {
                normalizedItemPrices.push({ ...rowData, isDefault: false })
                if (rowData.isDefault) setDefaultCount(defaultCount - 1)
            } else {
                normalizedItemPrices.push(rowData)
            }
        })

        modifiedFormData.itemPrices = normalizedItemPrices
        setFormData(modifiedFormData)
        addNewPriceRow()
    }

    // EFFECT TO RERENDER THE PRICE ROWS WHEN FORM DATA CHANGES  
    useEffect(() => {
        addNewPriceRow()
    }, [formData, prices, setPriceRow, addNewPriceRow]);

    // EFFECT TO UPDATE THE defaultCount VARIABLE ON PAGE LOAD 
    useEffect(() => {
        let count = 0
        formData.itemPrices.forEach((item) => {
            if (item.isDefault) count = count + 1
        });
        setDefaultCount(count)
    }, [formData, defaultCount]);

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: PRICE, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: LABEL, typeCase: 'pascal', translationContext }),
                ])
                setTranslatedText({
                    price: translations[0] ?? PRICE,
                    label: translations[1] ?? LABEL,
                })
            } catch {
                setTranslatedText({
                    price: PRICE,
                    label: LABEL,
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    return (
        <>
            {/* WARNING MODAL FOR DEFAULT PRICE  */}
            <WarningModal
                show={showWarning}
                title={modalTitle}
                onHide={() => setWarning(!showWarning)}
                callback={() => {
                    switch (modalState) {
                        case "ADD_DEFAULT_PRICE":
                            setWarning(false)
                            break;
                        case "CHANGE_DEFAULT_PRICE":
                            changeDefaultPriceSelection(defaultRowInfo)
                            setWarning(false)
                            break;
                        case "DELETE_PRICE":
                            deletePriceRow(rowToBeDeleted)
                            setWarning(false)
                            break;
                        default:
                            setWarning(false)
                            break;
                    }

                }} />

            <div className="d-flex flex-column">
                <div className="add-price-container mb-3">
                    <div className="flex-grow-0">
                        <div className="col-md-12 ">
                            <div className="add-p-bg ps-3 pe-3 pb-2 pt-3 ">
                                <div className="d-flex row gy-2 gx-3">
                                    <div className="col-md-4">
                                        <label className="visually-hidden" htmlFor={"priceValueInput"}><JTranslation typeCase="pascal" text={PRICE} /></label>
                                        <div className="input-group">
                                            <div className="input-group-text"><FaDollarSign /></div>
                                            <input data-testid={"priceValueInput"}
                                                value={priceValue}
                                                maxLength={10}
                                                type="text" className="form-control" id={"priceValueInput"}
                                                placeholder={translationText.price}
                                                autoComplete="off"
                                                onChange={(e) => {
                                                    if (isDecimalNumberWithDecimalPoints(e, 2)) {
                                                        setPriceValue(e.target.value);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="visually-hidden" htmlFor={"priceFieldInput"}><JTranslation typeCase="pascal" text={NAME} /></label>
                                        <input type="text" className="form-control"
                                            autoComplete="off"
                                            placeholder={translationText.label}
                                            value={priceLabel}
                                            maxLength={500}
                                            onChange={(e) => setPriceLabel(e.target.value)}
                                            id={"priceFieldInput"} data-testid={"priceFieldInput"} />
                                    </div>
                                    <div className="col-md-2">
                                        <button type="submit" className="btn btn-custom-primary w-100"
                                            data-testid="addBtn"
                                            disabled={getAddDisabled()}
                                            onClick={() => {
                                                let prices = null
                                                if (type === PriceType.ITEM) {
                                                    prices = formData.itemPrices
                                                } else {
                                                    prices = formData.addonPrices
                                                }

                                                let newPriceRow = ++priceRow[priceRow.length - 1];
                                                if (newPriceRow === 0) {
                                                    newPriceRow = 1;
                                                }

                                                setPriceRow([...priceRow, newPriceRow]);
                                                setFormData({
                                                    ...formData, [key]: [...prices, {
                                                        fieldName: priceLabel,
                                                        fieldValue: priceValue,
                                                        isDefault: false
                                                    }]
                                                });
                                                setPriceValue("");
                                                setPriceLabel("");

                                            }}>
                                            <FaPlus /> <JTranslation typeCase="pascal" text={ADD} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex-grow-1 add-prize-scroll mt-3" id="scrollContainer">
                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            onDragEnd={(e) => handleQuestionDragEnd(e, priceRow, type)}>
                            <SortableContext
                                items={priceRow.map(item => item)}
                                strategy={verticalListSortingStrategy}>
                                {priceRow.map((row, index) => {
                                    return (
                                        <PriceComponent
                                            defaultCount={defaultCount}
                                            formData={formData}
                                            key={index}
                                            row={row}
                                            setDefaultRowInfo={setDefaultRowInfo}
                                            setFormData={setFormData}
                                            setRowToBeDeleted={setRowToBeDeleted}
                                            showWarningModal={showWarningModal}
                                            type={type}
                                        />
                                    )
                                })}
                            </SortableContext>
                        </DndContext>

                        {/* ..............end display price list........... */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddPrice;