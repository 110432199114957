import { useEffect, useState, useCallback } from 'react'

// Custom hook for infinite scroll
export const useInfiniteScroll = (callback: () => void, threshold: number = 100) => {
    const [isFetching, setIsFetching] = useState(false)

    const handleScroll = useCallback(() => {
        // Check if we're close to the bottom of the window
        if (window.innerHeight + window.scrollY < document.documentElement.scrollHeight - threshold || isFetching)
            return

        // Trigger the callback to fetch more data
        setIsFetching(true)
    }, [isFetching, threshold])

    useEffect(() => {
        if (isFetching) {
            callback()
        }
    }, [isFetching, callback])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [handleScroll])

    return { isFetching, setIsFetching } as const
}
