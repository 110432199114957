import { AxiosResponse } from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { QueryClient } from "react-query";
import { GUEST_VISITED_GRAPH } from "../../constants/queryKeys";
import { getGuestVisitedData, guestVisitedInitialGraphData } from "../../helpers/dashboardHelper";
import useQueryHook from "../../hooks/useQueryHook";
import moment from 'moment';
import { GraphData, GraphOptions } from "../../pages/dashboard/Dashboard";
import { Bar } from 'react-chartjs-2';
import { customDeepClone, getYearRange } from "../../helpers/utils";
import { JTranslation, TranslationContext, jTranslationText } from "../../helpers/jTranslate";
import { useWindowSize } from "react-use";

type Props = {
    queryClient: QueryClient,
    graphOptions: GraphOptions;
}

function GuestVisitedGraph({ queryClient, graphOptions }: Props) {
    const [customGraphOptions, setCustomGraphOptions] = useState<GraphOptions>(graphOptions);
    const [year, setYear] = useState(new Date().getFullYear());
    const [guestVisitedData, setGuestVisitedData] = useState<GraphData>(guestVisitedInitialGraphData());
    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext
    const chartRef = useRef<any>(null);
    const { width } = useWindowSize();

    const onFetchSuccess = async (res: AxiosResponse<any, any>) => {
        const data = res.data.data as {
            [key: string]: {
                visitedCount: number,
                surveyAttendedCount: number
            }
        };
        let surveyAttendedDataset = [] as number[];
        let visitedCountDataset = [] as number[];
        const labels = moment.months().map(month => {
            const key = month.toLowerCase();
            if (data[key]) {
                surveyAttendedDataset.push(data[key].surveyAttendedCount);
                visitedCountDataset.push(data[key].visitedCount);
            } else {
                surveyAttendedDataset.push(0);
                visitedCountDataset.push(0);
            }
            return month.substring(0, 3);
        });

        const guestData = {
            labels,
            datasets: [
                {
                    label: (await jTranslationText({ text: 'Guest Visited', typeCase: 'pascal', translationContext })) as string,
                    data: visitedCountDataset,
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
                {
                    label: (await jTranslationText({ text: 'Guest Attended Survey', typeCase: 'pascal', translationContext })) as string,
                    data: surveyAttendedDataset,
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                },
            ],
        };
        setGuestVisitedData(guestData);
    }

    // fetch graph data
    useQueryHook(GUEST_VISITED_GRAPH,
        () => getGuestVisitedData(queryClient, year), onFetchSuccess);

    useEffect(() => {
        queryClient.refetchQueries([GUEST_VISITED_GRAPH])
    }, [targetLanguage])

    useEffect(() => {
        const chartParentWidth = chartRef?.current.getBoundingClientRect().width
        const { plugins: { legend } } =  customDeepClone(graphOptions) as GraphOptions;
        if(chartParentWidth && chartParentWidth <= 670) {
            legend.align = "start"; 
            legend.labels.boxWidth = 10;
        } else {
            legend.align = "center"; 
            legend.labels.boxWidth = 40;
        }        
        setCustomGraphOptions({...customDeepClone(graphOptions), plugins: { legend } })
    }, [width]);

    return <div className="card h-100 custom-shadow border-radius-05 border-0">
        <div className="card-header  border-radius-card-header-05 border-0 py-2">
            <div className="d-flex  align-items-center">
                <div className="flex-grow-1">
                    <h4 className="mb-0 title"><JTranslation typeCase="pascal" text={"Survey Completion Rate"} /></h4>
                </div>
                <div className="flex-grow-0  d-flex justify-content-end ">
                    <div className="col-md-12">
                        <select className="form-select" aria-label="Default select example" data-test-field="guest-visited-select"
                            value={year}
                            onChange={(e) => {
                                setYear(parseInt(e.target.value));
                                queryClient.fetchQuery(GUEST_VISITED_GRAPH,
                                    () => getGuestVisitedData(queryClient, parseInt(e.target.value)));
                            }}>
                            {getYearRange().map(yearValue => {
                                return <option key={yearValue}
                                    value={yearValue}>{yearValue}</option>
                            })}
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div className="card-body flex-grow-1 overflow-hidden">
            <div className="survey-section ">
                <div className="row">
                    <div ref={chartRef} className="col-lg-12 d-flex flex-column justify-content-center" style={{ minHeight: '250px', overflow: 'auto' }}>
                        <Bar redraw={true} options={customGraphOptions} data={guestVisitedData}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default GuestVisitedGraph;