import { Field, ErrorMessage, FormikErrors, FormikTouched } from 'formik'
import { JTranslation } from '../../helpers/jTranslate'
import { MinimumEmployeeCountType, PerDayCount } from '../../constants/staticTypes'

type Props = {
    errors: FormikErrors<MinimumEmployeeCountType>,
    touched: FormikTouched<MinimumEmployeeCountType>,
    index: number,
}

const minCountCard = ({ index, errors, touched }: Props) => {

    return (
        <div className="mb-6">
            <Field
                id={`availability.${index}.minCount`}
                name={`availability.${index}.minCount`}
                style={errors.availability && (errors.availability[index] as PerDayCount)?.minCount && touched.availability && (touched.availability[index]?.minCount) ? { border: '1px solid red' } : undefined}
                className="form-control"
                type="text"
                autoComplete="off"
                maxLength="3"
            />
            <ErrorMessage className="formik-error" name={`availability.${index}.minCount`} component="div" render={(error) => <span className='formik-error'><JTranslation text={error} /></span>} />
        </div>
    )
}

export default minCountCard