import { useContext, useEffect } from 'react'
import './styles/redirection.scss'
import { Amplify, Auth } from 'aws-amplify'
import { awsConfigMagicAuth, awsConfig } from '../../config/awsConfig'
import { CHANNEL, NAVIGATE_TO_ROUTE } from '../../constants/strings'
import { FNB_GLOBAL_SETTINGS, LOGIN_STATUS } from '../../constants/queryKeys'
import { getValueAfterHash } from '../../helpers/menuViewHelper'
import { LOGIN_METHOD, STAFF_ID, TENANT_ADMIN_TYPE, TENANT_ADMIN, TENANT_ID_HEADER, USER_NAME, ORG_ID_HEADER } from '../../constants/constants'
import { routes } from '../../constants/routes'
import { savePermissions, getUserInfo, getUserPermissions, saveLoginStatus } from '../../helpers/authHelper'
import { showLoader } from '../../helpers/utils'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { Users } from '../../constants/staticTypes'
import { useTrackEvent } from '../../hooks/useTrackEvent'
import { TranslationContext } from '../../helpers/jTranslate'
interface Params {
  linkCode: string
  userId: string
  userName: string
}

Amplify.configure(awsConfigMagicAuth)

const MagicLinkPage = () => {
  const navigate = useNavigate()

  const { trackEvent } = useTrackEvent()
  const [searchParams] = useSearchParams()
  const queryClient = useQueryClient()
  const { changeTargetLanguage } = useContext(TranslationContext)

  const loginStatus = queryClient.getQueryData(LOGIN_STATUS) as { isLoggedIn: boolean }

  useEffect(() => {
    const channel = new BroadcastChannel(CHANNEL)

    const validateMagicLink = async (params: Params) => {
      const { linkCode, userName } = params

      try {
        const authUser = await Auth.signIn(userName)
        const res = await Auth.sendCustomChallengeAnswer(authUser, linkCode)
        if (res) {
          await Auth.currentSession()
          if (
            res.attributes['custom:userType'] === TENANT_ADMIN ||
            res.attributes['custom:userType'] === TENANT_ADMIN_TYPE
          ) {
            localStorage.setItem('user', res.attributes.name)
            savePermissions(queryClient, { isAdmin: true })
          } else {
            // get user info
            const userInfoResponse = await getUserInfo(queryClient, res.username)
            const userInfo = userInfoResponse.data.data as Users
            localStorage.setItem(USER_NAME, userInfo.preferredName)
            localStorage.setItem(STAFF_ID, userInfo.id)
            const userPermissions = getUserPermissions(userInfo)
            const isLocal = process.env.REACT_APP_IS_LOCAL_ENV
            changeTargetLanguage(userInfo?.preferredLangKey.toLowerCase() ?? 'en')
            if (Object.keys(userPermissions).length === 0 && !isLocal) {
              navigate(routes.menu_view)
            }
            savePermissions(queryClient, userPermissions)
          }
          queryClient.fetchQuery(FNB_GLOBAL_SETTINGS) // Update time out settings
          saveLoginStatus(queryClient, true)
          showLoader(queryClient, false)

          // Google Analytics
          trackEvent({
            eventName: 'login_success',
            value: {
              method: LOGIN_METHOD.link,
            },
          })
          Amplify.configure(awsConfig)
          channel.postMessage(NAVIGATE_TO_ROUTE)
        }
      } catch (error) {
        console.error('Error continuing auth', error)
        // Google Analytics
        trackEvent({
          eventName: 'login_failure',
          value: {
            method: LOGIN_METHOD.link,
          },
        })
      } finally {
        navigate(routes.login)
      }
    }

    // validate only if not logged in else redirect to home/welcome page
    if (loginStatus?.isLoggedIn) {
      navigate(routes.welcome_page)
    } else {
      const params: Params = {
        linkCode: searchParams.get('code') ?? '',
        userId: searchParams.get('user') + '#' + getValueAfterHash(window.location.href)!,
        userName: searchParams.get('userName') + '#' + localStorage.getItem(ORG_ID_HEADER),
      }
      validateMagicLink(params)
    }

    return () => {
      channel.close()
    }
  }, [searchParams])

  return (
    <div className="h-100 redirection-container">
      <div className="redirection-wrapper">
        <span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </span>
        <div className="base">
          <span></span>
          <div className="face"></div>
        </div>
      </div>
      <div className="longfazers">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <h1>Redirecting</h1>
    </div>
  )
}

export default MagicLinkPage
