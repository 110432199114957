import { useContext, useEffect, useRef, useState } from 'react'
import { ADD_ADD_ONS, ADD_ADD_ONS_GROUP, ADD_ONS, ADD_ONS_GROUP, ADD_ON_GROUP, ADD_ON_LIST, DELETE, EDIT, INCLUDED_ADD_ONS, NAME, PRICE } from '../../constants/strings'
import { Addon, AddonGroup, NewAddon, NewAddonGroup } from '../../constants/staticTypes'
import { checkPermission, getNumberWithTwoDecimals, toPascalCase } from '../../helpers/utils'
import { Column } from 'primereact/column'
import { CommonCtx } from '../../context/CommonCtxProvider'
import { DataTable } from 'primereact/datatable'
import { DebounceInput } from 'react-debounce-input'
import { FaSearch, FaEdit } from 'react-icons/fa'
import { MOBILE_VIEW_BREAKPOINT, PermissionKeys, SubPermissionKeys } from '../../constants/constants'
import { useQueryClient } from 'react-query'
import { TabView, TabPanel } from 'primereact/tabview'
import { OverlayTrigger } from 'react-bootstrap'
import Tooltip from 'react-bootstrap/Tooltip'
import { cloneAddOnGroup } from '../../helpers/manageAddOnsGroupHelper'
import useMutationHook from '../../hooks/useMutationHook'
import { routes } from '../../constants/routes'
import { useNavigate } from 'react-router-dom'
import { JTranslation, TranslationContext, jTranslationText } from '../../helpers/jTranslate'
import { useWindowSize } from 'react-use'
import { DataView } from 'primereact/dataview'
import { Card } from 'primereact/card'
import MlxTooltip from '../common/MlxTooltip'

type Props = {
    addOnList: Addon[]
    loading: boolean
    onSearchTrigger: (searchKey: string) => void
    onEditClick: (addOn: Addon) => void
    onDeleteClick: (addOn: Addon) => void
    editAddOn: NewAddon | undefined
    addOnGroupList: AddonGroup[]
    editAddOnGroup: NewAddonGroup | undefined
    onAddonGroupDeleteClick: (addOn: AddonGroup) => void
    onAddonGroupEditClick: (addOn: AddonGroup) => void
    onAddonGroupSearchTrigger: (searchKey: string) => void
    activeIndex: number
	onAddonGroupSuccess: (message: string, variant: string) => void
	onError: (message: string, variant: string) => void
}

function AddOnsListDataGrid({ 
    addOnList, 
    loading, 
    onSearchTrigger, 
    onEditClick,
    onDeleteClick, 
    editAddOn,
    addOnGroupList,
    editAddOnGroup,
    onAddonGroupDeleteClick,
    onAddonGroupEditClick,
    onAddonGroupSearchTrigger,
    activeIndex,
	onAddonGroupSuccess,
	onError
}: Props) {
    // CONTEXT VARIABLE
    const { setShowSideBar: setAddOnSideBar, setShowAddOnGroupSideBar: setAddOnGroupSideBar } = useContext(CommonCtx)
    const queryClient = useQueryClient()
    const cloneAddOnMutation = useMutationHook(queryClient, true) // addon group mutation
	const navigate = useNavigate()
	const tabRoutes = [routes.manage_addons, routes.manage_addons_groups]
	const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext

	const [translationText, setTranslatedText] = useState<{
        clone: string,
        edit: string,
        delete: string,
		search: string,
        paginationTemplate: string,
		emptyMessage: string,
		emptyMessageGroup: string,
	}>({ 
        clone: 'Clone',
        edit: EDIT,
        delete: DELETE,
		search: 'Search',
        paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
		emptyMessage: "No Add-Ons Found",
		emptyMessageGroup: "No Add-On Groups Found",
    });
	const [showTitle, setShowTitle] = useState(true)
    const { width, height } = useWindowSize()
	const breakPoint = MOBILE_VIEW_BREAKPOINT
	const searchBoxRef1 = useRef(null)
	const searchBoxRef2 = useRef(null)

	// Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: 'Clone', typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: EDIT, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: DELETE, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: 'Search', typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: 'Showing 1 to 2 of 3 items', typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: 'No Add-Ons Found', typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: 'No Add-On Groups Found', typeCase: 'pascal', translationContext }),
                ])
                setTranslatedText({
                    clone: translations[0] ?? 'Clone',
                    edit: translations[1] ?? EDIT,
                    delete: translations[2] ?? DELETE,
					search: translations[3] ?? 'Search',
					paginationTemplate:
						translations[4]
							?.replace('1', '{first}')
							?.replace('2', '{last}')
							?.replace('3', '{totalRecords}') ?? 'Showing {first} to {last} of {totalRecords} items',
					emptyMessage: translations[5] ?? 'No Add-Ons Found',
					emptyMessageGroup: translations[6] ?? 'No Add-On Groups Found',
                })
            } catch {
                setTranslatedText({
                    clone: 'Clone',
                    edit: EDIT,
                    delete: DELETE,
					search: 'Search',
                    paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
					emptyMessage: 'No Add-On Found',
					emptyMessageGroup: 'No Add-On Group Found',
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])
	
    const deleteButtonTemplate = (addOn: Addon, fieldData:any) => {
        return (
            <>
                {
					<MlxTooltip target={`.d-${fieldData.rowIndex}`}>
                    <i
						data-pr-tooltip={translationText.delete}
						data-pr-position='bottom'
                        className={
                            'ri-delete-bin-line delete d-'+ fieldData.rowIndex +
                            (!checkPermission(queryClient, PermissionKeys.MANAGE_ADDONS, SubPermissionKeys.DELETE) &&
                                ' disabled') + (width <= breakPoint ? ' rounded-background shadow-sm ms-2' : '')
                        }
						data-testid="delete-addon"
                        onClick={(e) => {
                            e.stopPropagation()
                            if (checkPermission(queryClient, PermissionKeys.MANAGE_ADDONS, SubPermissionKeys.DELETE)) {
                                onDeleteClick(addOn)
                            }
                        }}
                    ></i>
					</MlxTooltip>
                }
            </>
        )
    }

    const editButtonTemplate = (addOn: Addon, fieldData:any) => {

        return (
			<span className={`${width <= breakPoint ? 'rounded-background shadow-sm ms-2' : ''}`}>
				<MlxTooltip target={`.e-${fieldData.rowIndex}`}>
				<FaEdit
					className={`edit e-${fieldData.rowIndex} ${!checkPermission(queryClient, PermissionKeys.MANAGE_ADDONS, SubPermissionKeys.EDIT) && ' disabled'
						}`}
					data-pr-tooltip={translationText.edit}
					data-pr-position="bottom"
					data-testid="edit-addon"
					onClick={(e) => {
						e.preventDefault()
						if (checkPermission(queryClient, PermissionKeys.MANAGE_ADDONS, SubPermissionKeys.EDIT)) {
							onEditClick(addOn)
							setAddOnSideBar(true)
						}
					}}
				/>
				</MlxTooltip>
			</span>
        )
    }

    const deleteButtonTemplateForAddonGroup = (addOnGroup: AddonGroup) => {
		return (
			<>
				{
					<MlxTooltip target='.delete'>
						<i
							data-pr-tooltip={translationText.delete}
							data-pr-position='bottom'
							className={
								'ri-delete-bin-line delete ' +
								(!checkPermission(queryClient, PermissionKeys.MANAGE_ADDON_GROUPS, SubPermissionKeys.DELETE) &&
									' disabled') + (width <= breakPoint ? ' rounded-background shadow-sm ms-2' : '')
							}
							data-testid="delete-addongroup"
							onClick={(e) => {
								e.stopPropagation()
								if (checkPermission(queryClient, PermissionKeys.MANAGE_ADDON_GROUPS, SubPermissionKeys.DELETE)) {
									onAddonGroupDeleteClick(addOnGroup)
								}
							}}
						></i>
					</MlxTooltip>
				}
			</>
		)
	}

	const editButtonTemplateForAddonGroup = (addOnGroup: AddonGroup) => {
		return (
			<span className={`${width <= breakPoint ? 'rounded-background shadow-sm ms-2' : ''}`}>
				<MlxTooltip target='.edit'>
				<FaEdit
					className={`edit ${
						!checkPermission(queryClient, PermissionKeys.MANAGE_ADDON_GROUPS, SubPermissionKeys.EDIT) && ' disabled'
					}`}
					data-pr-tooltip={translationText.edit}
					data-pr-position="bottom"
					data-testid="edit-addongroup"
					onClick={(e) => {
						e.preventDefault()
						if (checkPermission(queryClient, PermissionKeys.MANAGE_ADDON_GROUPS, SubPermissionKeys.EDIT)) {
							onAddonGroupEditClick(addOnGroup)
							setAddOnGroupSideBar(true)
						}
					}}
				/>
				</MlxTooltip>
			</span>
		)
	}

    const priceColumnTemplate = (addOn: Addon) => {
        const price = getNumberWithTwoDecimals(+(addOn.addonPrice))
        return <span className=" ">{`$${price}`}</span>
    }

    const priceColumnTemplateForAddonGroup = (addOnGroup: AddonGroup) => {
		const price = getNumberWithTwoDecimals(+(addOnGroup.addonGroupPrice))
		return <span className=" ">{`$${price}`}</span>
	}

	const includedAddonsForAddonGroup = (addOnGroup: AddonGroup) => {
		let addOns = addOnGroup.selectedAddonsData
			?.sort((a: any, b: any) => a?.addonName.localeCompare(b?.addonName))
			?.map((addOn) => addOn.addonName)
			.join(', ')
		if (addOns?.length > 70) {
			return (
				<OverlayTrigger
					trigger={["hover", "hover"]}
					placement="auto"
					overlay={<Tooltip className="addon-tooltip">{addOns}</Tooltip>}
				>
					<span>{addOns.slice(0, 70) + '...'}</span>
				</OverlayTrigger>
			)
		}
		return <span>{addOns}</span>
	}

	const copyButtonTemplateForAddonGroup = (addOnGroup: AddonGroup) => {
		return <MlxTooltip target='.clone'>
			<i data-pr-tooltip={translationText.clone} data-pr-position="bottom" className={`ri-file-copy-fill clone ${width <= breakPoint ? ' rounded-background shadow-sm ms-2' : ''}`} data-testid={"clone#" + addOnGroup.id}
            style={{ cursor: "pointer" }}
            onClick={(e) => {
                e.preventDefault()
				if (checkPermission(queryClient, PermissionKeys.MANAGE_ADDON_GROUPS, SubPermissionKeys.ADD)) {
					cloneAddOnGroup(cloneAddOnMutation, {id: addOnGroup.id as string}, onAddonGroupSuccess, onError)
				}
            }}>
        	</i>
		</MlxTooltip>
	}

	const addOnListTemplate = (addOn: Addon, fieldData:any) => {
		return (
			<Card className="data-view-card mt-2 d-flex flex-column justify-content-even">
				<div className="d-flex flex-row justify-content-between align-items-center">
					<p className="m-0 text-nowrap">{toPascalCase(addOn.addonName)}</p>
					<p className="m-0 text-nowrap">{priceColumnTemplate(addOn)}</p>
				</div>
				<div className="view-footer mt-2 pt-3 d-flex flex-row justify-content-between align-items-center">
					<div className="d-flex justify-content-even align-items-center">
						{editButtonTemplate(addOn, fieldData)}
						{deleteButtonTemplate(addOn, fieldData)}
					</div>
				</div>
			</Card>
		)
	}

	const addOnGroupListTemplate = (addOnGroup: AddonGroup) => {
		return (
			<Card className="data-view-card mt-2 d-flex flex-column justify-content-even">
				<div className="d-flex flex-row justify-content-between align-items-center">
					<p className="m-0 text-nowrap">{toPascalCase(addOnGroup.addonGroupName)}</p>
					<p className="m-0 text-nowrap">{priceColumnTemplateForAddonGroup(addOnGroup)}</p>
				</div>
				<div className='mt-2'>
					<div className="m-0">{includedAddonsForAddonGroup(addOnGroup)}</div>
				</div>
				<div className="view-footer mt-2 pt-3 d-flex flex-row justify-content-between align-items-center">
					<div className="d-flex justify-content-even align-items-center">
						{copyButtonTemplateForAddonGroup(addOnGroup)}
						{editButtonTemplateForAddonGroup(addOnGroup)}
						{deleteButtonTemplateForAddonGroup(addOnGroup)}
					</div>
				</div>
			</Card>
		)
	}

    return (
		<div className="col-md-12 col-lg-12 mb-3 addOn-scroll-fix h-100">
			<div className="card h-100">
				<div className="card-header flex-grow-0">
					<div className="d-flex align-items-center">
						<div className="flex-grow-1">
							{showTitle &&
								<h4 className="mb-0 title"><JTranslation typeCase="pascal" text={ADD_ON_LIST} /></h4>
							}
						</div>
						{activeIndex === 0 && (
							<div className="flex-grow-0 me-2 ms-1">
								<button
									className={`btn btn-custom-primary d-flex icon-only-btn ${
										!checkPermission(queryClient, PermissionKeys.MANAGE_ADDONS, SubPermissionKeys.ADD) && ' disabled'
									}`}
									type="button"
									data-testid="add-addon-button"
									onClick={() => {
										if (checkPermission(queryClient, PermissionKeys.MANAGE_ADDONS, SubPermissionKeys.ADD)) {
											setAddOnSideBar(true)
										}
									}}
								>
									<i className="ri-add-circle-line me-1"></i>
									<span className="btn-text"><JTranslation typeCase="pascal" text={ADD_ADD_ONS} /></span>
								</button>
							</div>
						)}
						{activeIndex === 1 && (
							<div className="flex-grow-0 me-2 ms-1">
								<button
									className={`btn btn-custom-primary d-flex icon-only-btn ${
										!checkPermission(queryClient, PermissionKeys.MANAGE_ADDON_GROUPS, SubPermissionKeys.ADD) && ' disabled'
									}`}
									type="button"
									data-testid="add-addongroup-button"
									onClick={() => {
										if (checkPermission(queryClient, PermissionKeys.MANAGE_ADDON_GROUPS, SubPermissionKeys.ADD)) {
											setAddOnGroupSideBar(true)
										}
									}}
								>
									<i className="ri-add-circle-line me-1"></i>
									<span className="btn-text"><JTranslation typeCase="pascal" text={ADD_ADD_ONS_GROUP} /></span>
								</button>
							</div>
						)}
						<div className="flex-grow-0  d-flex justify-content-end ">
							<div className="col-md-12 ">
								<div className={`form-group has-search ${!showTitle ? '' : 'search-small-box'}`}>
									<span className="fa fa-search form-control-feedback">
										<FaSearch />{" "}
									</span>
									<DebounceInput
										inputRef={searchBoxRef1}
										onFocusCapture={() => {breakPoint >= width && setShowTitle(false)}}
										onBlurCapture={(event: any) => {                                                
											if(breakPoint >= width && event.target.value.length === 0 && (searchBoxRef2.current as HTMLInputElement | null)?.value.length === 0) {
												setShowTitle(true)
											}
										}}
										className={activeIndex === 0 ? "form-control " : "d-none"}
										placeholder={translationText.search}
										minLength={0}
										debounceTimeout={500}
										data-testid ="addon-search"
										onChange={(e) => onSearchTrigger(e.target.value)}
									/>
									<DebounceInput
										inputRef={searchBoxRef2}
										onFocusCapture={() => {breakPoint >= width && setShowTitle(false)}}
										onBlurCapture={(event: any) => {                                                
											if(breakPoint >= width && event.target.value.length === 0 && (searchBoxRef1.current as HTMLInputElement | null)?.value.length === 0) {
												setShowTitle(true)
											}
										}}
										className={activeIndex === 1 ? "form-control " : "d-none"}
										placeholder={translationText.search}
										minLength={0}
										debounceTimeout={500}
										data-testid ="addongroup-search"
										onChange={(e) => onAddonGroupSearchTrigger(e.target.value)}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* Had to use Tabs separately, since the overflow scroll for the pagination wont work inside tab panel */}
				<TabView className="add-on-tab mt-2" activeIndex={activeIndex} onTabChange={(e) => {
					navigate(`${tabRoutes[e.index]}`)
				}}>
					{checkPermission(queryClient, PermissionKeys.MANAGE_ADDONS) &&
						<TabPanel header={<JTranslation typeCase="pascal" text={ADD_ONS} />}></TabPanel>
					}
					{checkPermission(queryClient, PermissionKeys.MANAGE_ADDON_GROUPS) && 
						<TabPanel header={<JTranslation typeCase="pascal" text={ADD_ONS_GROUP} />}></TabPanel>
					}
				</TabView>

				<div className={activeIndex === 0 ? "card-body flex-grow-1 overflow-hidden" : "d-none"}>
					<div className="row mb-5 h-100">
						<div className="col-lg-12" style={{ height: "100%", overflow: "auto" }}>
							{width <= breakPoint ? (
								<DataView
									value={addOnList}
									layout={'list'}
									itemTemplate={addOnListTemplate}
									paginator
									rows={10}
									paginatorClassName="p-dataview-customers"
									dataKey="id"
									emptyMessage={translationText.emptyMessage}
								/>
							) : (
								<DataTable
									value={addOnList}
									paginator
									className="p-datatable-customers"
									rows={10}
									scrollable
									scrollHeight="flex"
									paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
									rowsPerPageOptions={[10, 25, 50]}
									dataKey="id"
									rowHover
									selectionMode="single"
									selection={editAddOn}
									loading={loading}
									responsiveLayout="scroll"
									emptyMessage={translationText.emptyMessage}
									currentPageReportTemplate={translationText.paginationTemplate}
								>
									<Column
										field="addonName"
										header={<JTranslation typeCase="pascal" text={NAME} />}
										sortable
										className="word-break-grid"
										alignFrozen="right"
										style={{minWidth:'10rem'}}
									/>
									<Column
										field="addonPrice"
										header={<JTranslation typeCase="pascal" text={PRICE} />}
										headerClassName="addonAlignment"
										className="word-break-grid addonAlignment"
										alignFrozen="right"
										body={priceColumnTemplate}
										style={{minWidth:'8rem',maxWidth:'8rem'}}
									/>
									<Column
										field="edit"
										style={{ maxWidth: "3rem", width: "3rem" }}
										bodyClassName="bg-sticky-color"
										body={editButtonTemplate}
										alignFrozen="right"
										frozen={true}
									/>
									<Column
										field="delete"
										style={{ maxWidth: "3rem", width: "3rem" }}
										bodyClassName="bg-sticky-color"
										body={deleteButtonTemplate}
										alignFrozen="right"
										frozen={true}
									/>
								</DataTable>
							)}
						</div>
					</div>
				</div>

				<div className={activeIndex === 1 ? "card-body flex-grow-1 overflow-hidden" : "d-none"}>
					<div className="row mb-5 h-100">
						<div className="col-lg-12" style={{ height: "100%", overflow: "auto" }}>
							{width <= breakPoint ? (
								<DataView
									value={addOnGroupList}
									layout={'list'}
									itemTemplate={addOnGroupListTemplate}
									paginator
									rows={10}
									paginatorClassName="p-dataview-customers"
									dataKey="id"
									emptyMessage={translationText.emptyMessage}
								/>
							) : (
								<DataTable
									value={addOnGroupList}
									paginator
									className="p-datatable-customers"
									rows={10}
									scrollable
									scrollHeight="flex"
									paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
									rowsPerPageOptions={[10, 25, 50]}
									dataKey="id"
									rowHover
									selectionMode="single"
									selection={editAddOnGroup}
									loading={loading}
									responsiveLayout="scroll"
									emptyMessage={translationText.emptyMessageGroup}
									currentPageReportTemplate={translationText.paginationTemplate}
								>
									<Column
										field="addonGroupName"
										header={<JTranslation typeCase="pascal" text={ADD_ON_GROUP} />}
										sortable
										className="word-break-grid"
										alignFrozen="right"
										style={{minWidth: "20rem" , maxWidth: "20em" }}
									/>
									
									<Column
										field="addonsGroupItems"
										header={<JTranslation typeCase="pascal" text={INCLUDED_ADD_ONS} />}
										className="word-break-grid"
										alignFrozen="left"
										body={includedAddonsForAddonGroup}
										style={{minWidth: "18rem" , maxWidth: "auto" }}
									/>
									<Column
										field="addonGroupPrice"
										style={{ maxWidth: "12rem", minWidth: "12rem" }}
										header={<JTranslation typeCase="pascal" text={PRICE} />}
										headerClassName="addonAlignment"
										className="word-break-grid addonAlignment"
										alignFrozen="right"
										body={priceColumnTemplateForAddonGroup}   
									/>
									<Column
										field="copy"
										style={{ maxWidth: "3rem", minWidth: "3rem" }}
										bodyClassName="bg-sticky-color"
										body={copyButtonTemplateForAddonGroup}
										alignFrozen="right"
										frozen={true}
									/>
									<Column
										field="edit"
										style={{ maxWidth: "3rem", minWidth: "3rem" }}
										bodyClassName="bg-sticky-color"
										body={editButtonTemplateForAddonGroup}
										alignFrozen="right"
										frozen={true}
									/>
									<Column
										field="delete"
										style={{ maxWidth: "3rem", minWidth: "3rem" }}
										bodyClassName="bg-sticky-color"
										body={deleteButtonTemplateForAddonGroup}
										alignFrozen="right"
										frozen={true}
									/>
								</DataTable>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default AddOnsListDataGrid
