import React, { useContext, useEffect, useState } from 'react'
import { AI_TEXT_GENERATOR, AUTO_PUBLISH_DATE, CANCEL, DESCRIPTION, EXPIRY_DATE, HEADING, PUBLISH, SAVE_AS_DRAFT, SCHEDULE, TAG, UPDATE, URL_SLUG, URL_TEXT, USER_UPLOAD_PHOTO_ERROR, USER_UPLOAD_VIDEO_ERROR, HELP_EVENT_TAG, HELP_EVENT_URL_TEXT, HELP_EVENT_URL_SLUG, HELP_EVENT_AUTO_PUBLISH_DATE, HELP_NEWS_AUTO_PUBLISH_DATE } from '../../constants/strings';
import { AlertVariant, FileType, IMAGE_TYPE, PageMode, toastMessageInitialData } from '../../constants/constants'
import { areDatesSame, convertToSlug, createNewsApi, formatDateForApi, getFullPostDetails, getValidationSchema, initialValuesForNews, today, updateNewsApi } from '../../helpers/newsAndEventsHelper';
import { CommonCtx } from '../../context/CommonCtxProvider'
import { CropType, NewsAndEventsId, NewsPost, PostResponseType, PostType, ToastMessageProps } from '../../constants/staticTypes'
import { ErrorMessage, Field, Form, Formik, FieldProps } from 'formik'
import { escapeHtml, getDomainFromURL, popover, unescapeHtml } from '../../helpers/utils'
import { JTranslation } from '../../helpers/jTranslate'
import { NEWS_AND_EVENTS_LIST } from '../../constants/queryKeys'
import { OverlayTrigger } from "react-bootstrap"
import { routes } from '../../constants/routes'
import { useQueryClient } from 'react-query'
import AiTextGenerator from '../ai_text_generator/AiTextGenerator'
import DatePickerComponent from '../date_picker/DatePickerComponent'
import FileUpload from '../f&b_menu/admin/add_item_forms/FileUpload'
import ToastAlert from '../alert/ToastAlert'
import useMutationHook from '../../hooks/useMutationHook'
import WysiwygEditorBasic from '../wysiwyg_editor/WysiwygEditorBasic'
import MlxPopover from '../common/MlxPopover';

type Props = {
    onClose: Function
    pageMode: PageMode.ADD | PageMode.EDIT
    postType: PostType.NEWS | PostType.EVENT
    selectedPostId: NewsAndEventsId
}
const NewsForm = ({ onClose, pageMode, selectedPostId, postType }: Props) => {
    const queryClient = useQueryClient()
    const newsAndEventsMutation = useMutationHook(queryClient, true)
    const domainName = getDomainFromURL()
    // CONTEXT VARIABLE
    const { setShowSideBar } = useContext(CommonCtx)
    // STATE VARIABLES
    const [autoPublishDate, setAutoPublishDate] = useState<Date | null>(today)
    const [formData, setFormData] = useState<NewsPost>(initialValuesForNews)
    const [images, setImages] = useState<string[]>([])
    const [saveAsDraft, setSaveAsDraft] = useState(false)
    const [showAiTextGenerator, setShowAiTextGenerator] = useState(false)
    const [slugModified, setSlugModified] = useState(false)
    const [submitButtonName, setSubmitButtonName] = useState(pageMode === PageMode.EDIT ? UPDATE : PUBLISH)
    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
    const [videos, setVideos] = useState<string[]>([])

    const cropType: CropType = 'NEWS';
    const validateImageDimension = { width: IMAGE_TYPE[cropType].width, height: IMAGE_TYPE[cropType].height };

    // effect to change submit button name
    useEffect(() => {
        if (pageMode === PageMode.EDIT) {
            setSubmitButtonName(UPDATE)
        } else {
            if (areDatesSame({ date1: autoPublishDate, date2: today })) {
                setSubmitButtonName(PUBLISH)
            } else {
                setSubmitButtonName(SCHEDULE)
            }
        }
    }, [autoPublishDate, pageMode])

    // effect to call api to get news details, if page on edit mode
    useEffect(() => {
        if (pageMode === PageMode.EDIT && selectedPostId) {
            // get news details api call
            getFullPostDetails(newsAndEventsMutation, selectedPostId, onGetDetailsSuccess, onError)
        }
    }, [pageMode, selectedPostId]) // eslint-disable-line

    const getRouteForSlug = () => {
        if (postType === PostType.NEWS) {
            return routes.news_in_detail
        } else {
            return routes.events_in_detail
        }
    }

    // on delete image or video
    const onDeleteFile = (fileType: FileType, index: number) => {
        if (fileType === FileType.IMAGE) {
            const updatedPhotoKeys = [...formData.photoKeys]
            updatedPhotoKeys.splice(index, 1)
            setFormData({ ...formData, photoKeys: updatedPhotoKeys })

            const updatedImages = [...images]
            updatedImages.splice(index, 1)
            setImages(updatedImages)
        } else {
            const updatedVideoKeys = [...formData.videoKeys]
            updatedVideoKeys.splice(index, 1)
            setFormData({ ...formData, videoKeys: updatedVideoKeys })

            const updatedVideos = [...videos]
            updatedVideos.splice(index, 1)
            setVideos(updatedVideos)
        }
    }

    // on file upload success
    const onUploadSuccess = (key: string, fileUrl: string, fileType: FileType) => {
        if (fileType === FileType.IMAGE) {
            setFormData({ ...formData, photoKeys: [...formData.photoKeys, key] })
            setImages([...images, fileUrl])
        } else {
            setFormData({ ...formData, videoKeys: [...formData.videoKeys, key] })
            setVideos([...videos, fileUrl])
        }
    }

    // on file upload error
    const onUploadError = (fileType: FileType) =>
        displayToast(
            fileType === FileType.IMAGE ? USER_UPLOAD_PHOTO_ERROR : USER_UPLOAD_VIDEO_ERROR,
            AlertVariant.ERROR
        )

    const displayToast = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
    }

    const handleAutoPublish = (value: Date | null) => {
        setAutoPublishDate(value)
    }

    const onGetDetailsSuccess = (res: PostResponseType) => {
        const {
            signedPhotosKeys,
            signedVideoKeys,
            publishDate,
            saveAction,
            expiryDate,
            description,
            urlSlug,
            postTags,
            title,
            photoKeys,
            videoKeys,
        } = res
        setImages(signedPhotosKeys)
        setVideos(signedVideoKeys)

        setFormData((prevValues) => {
            return {
                ...prevValues,
                description,
                expiryDate: expiryDate ? new Date(expiryDate) : null,
                photoKeys,
                postTags,
                publishDate: new Date(publishDate),
                saveAction,
                title,
                urlSlug,
                videoKeys,
            }
        })
    }

    const onSuccess = (message: string) => {
        setToastMessage({
            message: message,
            show: true,
            variant: AlertVariant.SUCCESS,
        })

        // list api recall
        queryClient.refetchQueries([NEWS_AND_EVENTS_LIST]) // nosonar

        setTimeout(() => {
            setShowSideBar(false)
        }, 2000)
    }

    const onError = (error: string) => {
        setToastMessage({
            message: error,
            show: true,
            variant: AlertVariant.ERROR,
        })
    }

    const handleTitleChange = (value: string) => {
        setFormData((prevValues) => {
            return { ...prevValues, title: value, urlSlug: convertToSlug(value) }
        })
    }

    const handleURLChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target
        value ? setSlugModified(true) : setSlugModified(false)
        setFormData((prevValues) => {
            return { ...prevValues, urlSlug: convertToSlug(value) }
        })
    }

    // Function to validate and handle data related to a news post
    const validate = (values: NewsPost) => {
        // If the slug is not modified and the page is in ADD mode, handle the title change
        if (!slugModified && pageMode === PageMode.ADD) handleTitleChange(values.title);

        // Handle auto-publishing based on the provided publish date
        handleAutoPublish(values.publishDate);

        // Update the form data by spreading the rest of the values and merging with previous value
        setFormData((prevValue) => {
            return ({
                ...prevValue,
                title: values.title,
                postTags: values.postTags,
                publishDate: values.publishDate,
                expiryDate: values.expiryDate,
            })
        });
    }

    const handleSubmit = (values: NewsPost) => {
        const { publishDate, expiryDate } = values
        if (pageMode === PageMode.ADD) {
            // create news api call
            createNewsApi(
                newsAndEventsMutation,
                {
                    ...values,
                    publishDate: formatDateForApi({ date: publishDate }),
                    expiryDate: formatDateForApi({ date: expiryDate }),
                    notificationType: "newNews",
                    saveAction: saveAsDraft ? 'draft' : 'create'
                },
                onSuccess,
                onError
            )
        } else {
            // update news api call
            updateNewsApi(
                newsAndEventsMutation,
                {
                    ...values,
                    ...selectedPostId,
                    publishDate: formatDateForApi({ date: publishDate }),
                    expiryDate: formatDateForApi({ date: expiryDate }),
                    notificationType: "newNews",
                    saveAction: saveAsDraft ? 'draft' : 'create'
                },
                onSuccess,
                onError
            )
        }
    }

    return (
        <>
            {/* toaster component */}
            <ToastAlert
                show={toastMessage.show}
                onClose={() => setToastMessage(toastMessageInitialData)}
                message={toastMessage.message}
                variant={toastMessage.variant}
            />

            <Formik
                enableReinitialize={true}
                initialValues={formData}
                onSubmit={handleSubmit}
                validate={validate}
                validateOnChange={true}
                validationSchema={() => getValidationSchema({ postType, saveAsDraft })}
            >
                {({ errors, touched }) => (
                    <Form>
                        <div className="mb-3">
                            <label className="form-label d-flex">
                                <JTranslation typeCase="pascal" text={HEADING} />
                                <span className="mandatory ">*</span>
                            </label>
                            <Field
                                style={errors.title && touched.title ? { border: '1px solid red' } : undefined}
                                name="title"
                                className="form-control"
                                type="text"
                                autoComplete="off"
                                maxLength="250"
                                data-testid="news-heading"
                            />
                            <ErrorMessage className="formik-error" name="title" component="div" render={(error) => <div className='formik-error'><JTranslation typeCase="none" text={error} /></div>} />
                        </div>
                        <div className="mb-3">
                            <div className="help-small">
                                <label className="form-label"><JTranslation typeCase="pascal" text={TAG} /></label>
                                <MlxPopover data={{title:TAG, body:HELP_EVENT_TAG}}/>
                            </div>
                            <Field
                                style={errors.postTags && touched.postTags ? { border: '1px solid red' } : undefined}
                                name="postTags"
                                className="form-control"
                                type="text"
                                autoComplete="off"
                                maxLength="250"
                                data-testid="news-tags"
                            />
                            <ErrorMessage className="formik-error" name="postTags" component="div" render={(error) => <div className='formik-error'><JTranslation typeCase="none" text={error} /></div>} />
                        </div>
                        <div className="mb-3">
                            <div className="help-small">
                                <label className="form-label d-flex">
                                    <JTranslation typeCase="pascal" text={URL_TEXT} />
                                    <span className="mandatory ">*</span>
                                </label>
                                <MlxPopover data={{title:URL_TEXT, body:HELP_EVENT_URL_TEXT}}/>
                            </div>
                            <Field
                                style={errors.urlSlug && touched.urlSlug ? { border: '1px solid red' } : undefined}
                                name="urlSlug"
                                className="form-control"
                                type="text"
                                autoComplete="off"
                                maxLength="150"
                                data-testid="news-url-slug"
                                onChange={handleURLChange}
                            />
                            <ErrorMessage className="formik-error" name="urlSlug" component="div" render={(error) => <div className='formik-error'><JTranslation typeCase="none" text={error} /></div>} />
                        </div>
                        <div className="mb-3">
                            <div className="help-small">
                                <label className="form-label"><JTranslation typeCase="pascal" text={URL_SLUG} /></label>{' '}
                                <MlxPopover data={{title:URL_SLUG, body:HELP_EVENT_URL_SLUG}}/>
                            </div>
                            <strong>{formData.urlSlug && `${domainName}${getRouteForSlug()}/${formData.urlSlug}`}</strong>
                        </div>


                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <label className="form-label"><JTranslation typeCase="pascal" text={DESCRIPTION} /></label>
                            <button type='button' className="btn btn-sm btn-custom-primary-outline" data-testid="ai-text-button" onClick={() => { setShowAiTextGenerator(!showAiTextGenerator) }}>
                                {AI_TEXT_GENERATOR}
                            </button>
                        </div>
                        <AiTextGenerator
                            key='newsDescription'
                            show={showAiTextGenerator}
                            callBack={(aiResponse: string) => setFormData((prev) => ({ ...prev, description: aiResponse }))}
                            type='newsDescription'
                        />



                        <div className="mb-3">
                            <WysiwygEditorBasic
                                editorContent={unescapeHtml(formData?.description)}
                                data-testid="news-description"
                                callback={(value) => {
                                    setFormData((prev) => ({ ...prev, description: escapeHtml(value) }))
                                }}
                            />
                        </div>
                        <div className="row mb-3">
                            <div className="mb-3 col-md-6">
                                <div className="help-small">
                                    <label className="form-label d-flex">
                                        <JTranslation typeCase="pascal" text={AUTO_PUBLISH_DATE} />
                                        <span className="mandatory ">*</span>
                                    </label>
                                    <MlxPopover data={{title:AUTO_PUBLISH_DATE, body:HELP_EVENT_AUTO_PUBLISH_DATE}}/>
                                </div>
                                <Field name="publishDate">
                                    {({ field, form }: FieldProps) => (
                                        <DatePickerComponent
                                            selectedDate={field.value}
                                            minDate={new Date()}
                                            data-testid="publish-date-picker"
                                            maxDate={
                                                new Date(
                                                    new Date().setFullYear(
                                                        new Date().getFullYear() + 5
                                                    )
                                                )
                                            }
                                            customInput={
                                                <input
                                                    style={
                                                        errors.publishDate && touched.publishDate
                                                            ? { border: '1px solid red' }
                                                            : undefined
                                                    }
                                                    className="form-control"
                                                    type="text"
                                                    maxLength={10}
                                                    autoComplete="off"
                                                />
                                            }
                                            setDate={(value) => form.setFieldValue(field.name, value)}
                                        />
                                    )}
                                </Field>
                                <ErrorMessage className="formik-error" name="publishDate" component="div" render={(error) => <div className='formik-error'><JTranslation typeCase="none" text={error} /></div>} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <div className="help-small">
                                    <label className="form-label"><JTranslation typeCase="pascal" text={EXPIRY_DATE} /></label>
                                    <MlxPopover data={{title:EXPIRY_DATE, body:HELP_NEWS_AUTO_PUBLISH_DATE}}/>
                                </div>
                                <Field name="expiryDate">
                                    {({ field, form }: FieldProps) => (
                                        <DatePickerComponent
                                            selectedDate={field.value}
                                            minDate={new Date()}
                                            data-testid="expiry-date-picker"
                                            maxDate={
                                                new Date(
                                                    new Date().setFullYear(
                                                        new Date().getFullYear() + 5
                                                    )
                                                )
                                            }
                                            customInput={
                                                <input
                                                    style={
                                                        errors.expiryDate && touched.expiryDate
                                                            ? { border: '1px solid red' }
                                                            : undefined
                                                    }
                                                    className="form-control"
                                                    type="text"
                                                    maxLength={10}
                                                    autoComplete="off"
                                                />
                                            }
                                            setDate={(value) => form.setFieldValue(field.name, value)}
                                        />
                                    )}
                                </Field>
                                <ErrorMessage className="formik-error" name="expiryDate" component="div" render={(error) => <div className='formik-error'><JTranslation typeCase="none" text={error} /></div>} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            {/* file upload */}
                            <div className="col-md-12  col-lg-12  ">
                                <div className="row img-flex">
                                    <FileUpload
                                        displayToast={displayToast}
                                        onUploadError={onUploadError}
                                        uploadFileMutation={newsAndEventsMutation}
                                        onUploadSuccess={onUploadSuccess}
                                        images={images}
                                        videos={videos}
                                        onDeleteFile={onDeleteFile}
                                        setImages={setImages}
                                        setFormData={setFormData}
                                        formData={formData}
                                        divClass=""
                                        validateImageDimension={validateImageDimension}
                                        cropType={cropType}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* action button */}
                        <div className="save-btn-section shadow save-btn-absolute">
                            <button className="btn btn-custom-primary-outline" type="reset" data-testid="cancel-btn" onClick={() => onClose()}>
                                <JTranslation typeCase="pascal" text={CANCEL} />
                            </button>

                            <button
                                className="btn btn-custom-primary"
                                type="submit"
                                data-testid="save-draft-btn"
                                onClick={() => setSaveAsDraft(true)}
                            >
                                <JTranslation typeCase="pascal" text={SAVE_AS_DRAFT} />
                            </button>

                            <button
                                className="btn btn-custom-secondary"
                                type="submit"
                                data-testid="publish-btn"
                                onClick={() => setSaveAsDraft(false)}
                            >
                                <JTranslation typeCase="pascal" text={submitButtonName} />
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default NewsForm
