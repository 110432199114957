import { Skeleton } from 'primereact/skeleton'

type propsTypes={
	width?:string,
	height?:string
}
const FnBLoader = (props:propsTypes) => {
	return <Skeleton width={props.width? props.width : "100%"} height={props.height? props.height :'9rem'} ></Skeleton>
}

export default FnBLoader
