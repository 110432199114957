import { Modal } from "react-bootstrap";
import winnerImg from "../../../assets/images/game-congrats.svg";
import betterluckImg from "../../../assets/images/game-better-luck.svg";
import { BETTER_LUCK_NEXT_TIME, CONGRATULATIONS, CONTINUE, TRY_AGAIN_NEXT_TIME } from "../../../constants/strings";

type Props = {
    show: boolean,
    handleClose: () => void,
    prize: string,
    isWinner: boolean
}

function WinnerModal({ show, handleClose, prize, isWinner }: Props) {

    return <Modal show={show} size="lg" className="winner-modal" centered onHide={handleClose}
        backdrop={"static"}>
        <Modal.Header closeButton />
        <Modal.Body>
            <div className="row align-items-center">
                <div className="game-winner-img-container">
                    <img src={isWinner ? winnerImg : betterluckImg} className="img-fluid" alt=""></img>
                </div>

                <div className="col-12 text-center mt-5">
                    <h4 className={isWinner ? "winner-msg" : "betterluck-msg"}>{isWinner ? `${CONGRATULATIONS}!!` : `${BETTER_LUCK_NEXT_TIME}!`}</h4>
                    <p>{isWinner ? `You won ${prize}` : TRY_AGAIN_NEXT_TIME}</p>
                </div>

                <div className="col-md-12 text-center  nxt-btn mt-3 mb-3">
                    <button type="button"
                        className="btn btn-primary btn-lg  btn-custom-primary d-inline-flex align-items-center justify-content-center "
                        data-testid="continue-btn"
                        onClick={handleClose}>
                        {CONTINUE}
                        <i className="ri-arrow-right-line"></i>
                    </button>
                </div>

            </div>
        </Modal.Body>
    </Modal>
}
export default WinnerModal;