import { useState, useEffect } from "react";
import '../../assets/styles/scss/main.scss';
import './styles/reset-password.scss';
import { BACK_TO_WELCOME, CONFIRM_PASSWORD_TEXT, FORGOT_PASSWORD_ERROR, PASSWORD_MISMATCH_ERROR, NEW_PASSWORD_TEXT, SUBMIT, EULA, FORCE_LOGOUT, CHANNEL } from "../../constants/strings";
import { isStrongPassword, logoutActions } from "../../helpers/utils";
import { Link, useSearchParams } from "react-router-dom";
import { routes } from "../../constants/routes";
import { setUserPasswordApi } from "../../helpers/authHelper";
import { useQueryClient } from "react-query";
import CommonModal from "../../components/common_modal/CommonModal";
import EulaContent from "./EulaContent";
import PasswordChecklist from "react-password-checklist";
import useMutationHook from "../../hooks/useMutationHook";
import LogoComponent from "../../components/header/LogoComponent";

function SetPassword() {
  const queryClient = useQueryClient();
  // STATE VARIABLES
  const [password, setPassword] = useState("");
  const [cnfPassword, setConfirmPassword] = useState("");
  const [isError, setError] = useState(false);
  const [isPasswordError, setPasswordError] = useState(false);
  const [isPasswordWeak, setPasswordWeakError] = useState(false);
  const [isTermsAgreed, setIsTermsAgreed] = useState(true);
  const [termsAgreedModal, setTermsAgreedModal] = useState(false);

  // reset password mutation
  const resetPasswordMutation = useMutationHook(queryClient, true);

  // get url params
  const [searchParams] = useSearchParams();
  const userName = searchParams.get("user");
  const code = searchParams.get("code");

  // send password reset email success
  const onSetPasswordSuccess = () => {
    logoutActions({ queryClient })
    window.location.href = routes.login;
  }

  // error sending password reset email
  const onSetPasswordError = () => {
    setError(true);
  }

  // on change error check
  const onPasswordsChange = (currentInput: string, otherPassword: string) => {
    if (isPasswordError) {
      setPasswordError(currentInput !== otherPassword);
    }
    if (isPasswordWeak) {
      setPasswordWeakError(isStrongPassword(currentInput));
      setPasswordError(currentInput !== otherPassword);
    }
  }

  // proceed action
  const proceedAction = () => {
    setTermsAgreedModal(false)
  }

  return (
    <div className='p-0 user-style'>
      {/* common modal for EULA */}
      <CommonModal
        callback={proceedAction}
        hideFooter
        modalContent={<EulaContent />}
        onHide={setTermsAgreedModal}
        show={termsAgreedModal}
        title={EULA}
        modalSize='lg'
      />
      <div className='login d-flex justify-content-center align-items-center'>
        <div className='container'>
          <div className='row justify-content-center align-items-center'>
            <div className='col-md-9 col-lg-5 col-xl-5 '>
              <div className='login-box'>
                <div className='login-header text-center d-flex align-items-center justify-content-center'>
                  <LogoComponent />
                </div>
                <div className='login-body'>
                  <div className='form-section'>
                    <div className='mb-3 login-title text-center'>
                      <h5 className='mb-0'>Set Password</h5>
                      <p>Please set your new password</p>
                    </div>

                    <input
                      autoFocus
                      autoComplete='new-password'
                      type='password'
                      className='form-control form-control-lg mb-3'
                      placeholder={NEW_PASSWORD_TEXT}
                      aria-label='.form-control-lg example'
                      onChange={(e) => {
                        setPassword(e.target.value)
                        onPasswordsChange(e.target.value, cnfPassword)
                      }}
                    />
                    <input
                      autoComplete='off'
                      type='password'
                      className='form-control form-control-lg mb-3'
                      placeholder={CONFIRM_PASSWORD_TEXT}
                      aria-label='.form-control-lg example'
                      onChange={(e) => {
                        setConfirmPassword(e.target.value)
                        onPasswordsChange(e.target.value, password)
                      }}
                    />

                    {isError && (
                      <div className='error-msg mb-3'>
                        {FORGOT_PASSWORD_ERROR}
                      </div>
                    )}
                    {isPasswordError && (
                      <div className='error-msg mb-3'>
                        {PASSWORD_MISMATCH_ERROR}
                      </div>
                    )}

                    <div className='p-2'>
                      <PasswordChecklist
                        className='password-checklist'
                        rules={[
                          'minLength',
                          'specialChar',
                          'number',
                          'capital',
                          'match',
                        ]}
                        minLength={6}
                        value={password}
                        valueAgain={cnfPassword}
                        iconSize={14}
                      />
                    </div>

                    {/* <div className="password-criteria m-2">
                      <input
                        type="checkbox"
                        checked={isTermsAgreed}
                        onChange={() => {
                          setIsTermsAgreed(!isTermsAgreed)
                        }}
                      />{' '}
                      <label>{AGREE_TO}</label>{' '}
                      <span
                        className="email-text"
                        onClick={() => {
                          setTermsAgreedModal(true)
                        }}
                      >
                        {AGREE_TO_TERMS}
                      </span>
                    </div> */}


                    <div className='d-grid gap-2 mb-3'>
                      <button
                        type='button'
                        className='btn btn-primary btn-lg btn-block btn-custom-primary'
                        disabled={
                          password?.trim().length === 0 ||
                          cnfPassword?.trim().length === 0 ||
                          !isTermsAgreed
                        }
                        onClick={(e) => {
                          e.preventDefault()
                          if (password === cnfPassword) {
                            if (isStrongPassword(password)) {
                              setError(false)
                              setPasswordError(false)
                              setPasswordWeakError(false)
                              const data = {
                                userName: userName,
                                code: code,
                                password: password,
                                isAcceptedPrivacyPolicy: isTermsAgreed,
                              }
                              setUserPasswordApi(
                                resetPasswordMutation,
                                data,
                                onSetPasswordSuccess,
                                onSetPasswordError
                              )
                            } else {
                              setPasswordWeakError(true)
                              setPasswordError(false)
                            }
                          } else {
                            setPasswordWeakError(false)
                            setPasswordError(true)
                          }
                        }}
                      >
                        {SUBMIT.toUpperCase()}
                      </button>
                    </div>
                    <div className='row d-flex justify-content-end forget-pass-sec'>
                      <div className='col-auto d-flex align-items-center fw-semibold'>
                        <i className='ri-arrow-left-line'></i>
                        <Link to={routes.welcome_page}>
                          {BACK_TO_WELCOME}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SetPassword;