// Icon.tsx
import React from 'react'
import heartIcon from '../../../assets/images/hearts3d.png'

import { getValentinesDayEffects } from '../celebrationsConfigs'
interface IconProps {
	iconSrc?: string
	index?: number
}

const HeartIcon: React.FC<IconProps> = ({ iconSrc = null, index = 0 }) => {
	const valentinesDayEffect = getValentinesDayEffects()

	const { showCardEffects = false, showEffects: valentinesShowEffects = false } = valentinesDayEffect || {}

	// const position: Position =
	//   positions[Math.floor(Math.random() * positions.length)]
	// Positions array to cycle through
	const positions = ['top-right', 'top-left', 'center-right', 'lower-right', 'bottom-right', 'bottom-left'] as const

	// Calculate position based on index
	const positionClass = 'top-right' // positions[(index % (positions?.length || 0))||0]

	if (valentinesShowEffects && showCardEffects) {
		return <img src={iconSrc ?? heartIcon} className={`iconLove ${positionClass}`} alt="Icon" />
	}

	return null
}

export default HeartIcon
