import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const useCustomBackButton = (route = "/") => {
    const navigate = useNavigate();

    useEffect(() => {
        const handleBackButton = (event: BeforeUnloadEvent) => {
            event.preventDefault();
            navigate(route); // Change this to the desired route
        };

        window.history.pushState(null, '', window.location.pathname); // Reset the history state
        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, [navigate]);
};

export default useCustomBackButton;
