import { Modal, ModalHeader } from 'react-bootstrap'
import { CommonModalProps } from '../../constants/staticTypes'
import { PROCEED, CANCEL } from '../../constants/strings'
import { JTranslation } from '../../helpers/jTranslate'

function CommonModal({
    additionalActions = true,
    additionalActionsButtons,
    callback = () => { },
    disableProceed = false,
    disableActionButtons = false,
    hideCancel = false,
    hideCloseButton = false,
    hideFooter = false,
    hideProceed = false,
    modalBodyCss,
    modalContent,
    modalSize,
    onHide,
    show,
    title,
}: CommonModalProps) {
    return (
        // @ts-expect-error
        <Modal centered onHide={() => onHide(false)} show={show} size={modalSize}>
            <ModalHeader className="modal-header-bg" closeButton={!hideCloseButton}>
                <h6 className="fw-bold mb-0 x">{title}</h6>
            </ModalHeader>
            <Modal.Body className={modalBodyCss}>{modalContent}</Modal.Body>

            {!hideFooter && (
                <Modal.Footer>
                    {!hideCancel && (
                        <button
                            type="button"
                            className="btn  btn-custom-primary-outline"
                            disabled={disableActionButtons}
                            data-bs-dismiss="modal"
                            onClick={() => onHide(false)}
                        >
                            <JTranslation typeCase="pascal" text={CANCEL} />
                        </button>
                    )}

                    {!hideProceed && (
                        <button
                            type="button"
                            className="btn btn-custom-primary"
                            disabled={disableProceed || disableActionButtons}
                            onClick={(e) => {
                                e.preventDefault()
                                callback()
                            }}
                        >
                            <JTranslation typeCase="pascal" text={PROCEED} />
                        </button>
                    )}

                    {/* additional buttons with actions */}
                    {additionalActions && additionalActionsButtons}
                </Modal.Footer>
            )}
        </Modal>
    )
}

export default CommonModal
