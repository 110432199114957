import { createContext, Dispatch, PropsWithChildren, SetStateAction, useState, useMemo, useEffect, useContext } from 'react'
import { FORCE_LOGOUT, CHANNEL, NAVIGATE_TO_ROUTE } from '../constants/strings'
import { getFnBGlobalSettings } from '../helpers/menuManagementHelper'
import { getUserGlobalConfig } from '../helpers/userRolesHelper'
import { initialPosValue, initialFnBGlobalSettings, initialUserGlobalSettings, initialGuestFnbGlobalSettings, initialColorTheme } from './defaultContextValues'
import { PosLoginResponse, FnBGlobalSetting, UserGlobalSettings, GuestFnbGlobalSetting } from '../constants/staticTypes'
import { guestOnlyRoutes, routes } from '../constants/routes'
import { saveLoginStatus, userLogout } from '../helpers/authHelper'
import { showLoader, logoutActions, isUserLoggedInCognito, hexToRgb } from '../helpers/utils'
import { TranslationContext } from '../helpers/jTranslate'
import { useNavigate } from 'react-router-dom'
import { useQueryClient, useMutation } from 'react-query'
import useVisibility from '../hooks/UseVisibility'
import { initParticlesEngine } from '@tsparticles/react'
import { loadAll } from '@tsparticles/all'
import { LOGIN_STATUS } from '../constants/queryKeys'

// SETTER TYPE DECLARATIONS
type SetBoolean = Dispatch<SetStateAction<boolean>>
type SetString = Dispatch<SetStateAction<string>>
type SetPosUserInfo = Dispatch<SetStateAction<PosLoginResponse>>
type SetFnBGlobalSettings = Dispatch<SetStateAction<FnBGlobalSetting>>
type SetGuestFnBGlobalSettings = Dispatch<SetStateAction<GuestFnbGlobalSetting>>
type SetUserGlobalSettings = Dispatch<SetStateAction<UserGlobalSettings>>
type SetColorTheme = Dispatch<SetStateAction<string>>

// UPDATE FUNCTION DECLARATIONS
const UpdateBoolean: SetBoolean = () => false
const UpdateString: SetString = () => ""
const UpdatePosUserInfo: SetPosUserInfo = () => initialPosValue
const UpdateFnBGlobalSettings: SetFnBGlobalSettings = () => initialFnBGlobalSettings
const UpdateGuestFnBGlobalSettings: SetGuestFnBGlobalSettings = () => initialGuestFnbGlobalSettings
const UpdateUserGlobalSettings: SetUserGlobalSettings = () => initialUserGlobalSettings
const UpdateColorTheme: SetColorTheme = () => initialColorTheme

// CONTEXT VARIABLES
export const CommonCtx = createContext({
    // open and close add user sidebar
    showSideBar: false,
    setShowSideBar: UpdateBoolean,
    // user login in type
    isUserPos: false,
    setIsUserPos: UpdateBoolean,
    // details of user logged in via POS credentials
    posUserInfo: initialPosValue,
    setPosUserInfo: UpdatePosUserInfo,
    // F&B menu global settings
    fnBGlobalSettings: initialFnBGlobalSettings,
    setFnBGlobalSettings: UpdateFnBGlobalSettings,
    // Guest F&B menu global settings
    guestFnbGlobalSettings: initialGuestFnbGlobalSettings,
    setGuestFnbGlobalSettings: UpdateGuestFnBGlobalSettings,
    // open and close add schedule menu
    scheduleMenu: false,
    setScheduleMenu: UpdateBoolean,
    // user global configuration
    userGlobalSettings: initialUserGlobalSettings,
    setUserGlobalSettings: UpdateUserGlobalSettings,
    // news and events
    postViewFromInternal: "",
    setPostViewFromInternal: UpdateString,
    // open and close add user sidebar
    showAddOnGroupSideBar: false,
    setShowAddOnGroupSideBar: UpdateBoolean,
    // font resize
    initialLoad: false,
    setInitialLoad: UpdateBoolean,
    // guest view search
    guestViewSearchFilter: "",
    setGuestViewSearchFilter: UpdateString,
    // particle engine loaded status
    particleEngineLoaded: false,
    setParticleEngineLoaded: UpdateBoolean,
    // color theme
    colorTheme: initialColorTheme,
    setColorTheme: UpdateColorTheme,
})

// NOTE: "CONTEXT VARIABLE" AND "STATE VARIABLE" NAMES SHOULD BE IDENTICAL
export const CommonCtxProvider = ({ children }: PropsWithChildren) => {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const isTabVisible = useVisibility();
    // STATE VARIABLES
    const [showSideBar, setShowSideBar] = useState(false)
    const [showAddOnGroupSideBar, setShowAddOnGroupSideBar] = useState(false)
    const [fnBGlobalSettings, setFnBGlobalSettings] = useState<FnBGlobalSetting>(initialFnBGlobalSettings)
    const [guestFnbGlobalSettings, setGuestFnbGlobalSettings] = useState<GuestFnbGlobalSetting>(initialGuestFnbGlobalSettings)
    const [isUserPos, setIsUserPos] = useState(false)
    const [posUserInfo, setPosUserInfo] = useState<PosLoginResponse>(initialPosValue)
    const [scheduleMenu, setScheduleMenu] = useState(false)
    const [userExists, setUserExists] = useState(false)
    const [userGlobalSettings, setUserGlobalSettings] = useState<UserGlobalSettings>(initialUserGlobalSettings)
    const [postViewFromInternal, setPostViewFromInternal] = useState("")
    const [initialLoad, setInitialLoad] = useState(false)
    const [guestViewSearchFilter, setGuestViewSearchFilter] = useState("")
    const [particleEngineLoaded, setParticleEngineLoaded] = useState(false)
    const [colorTheme, setColorTheme] = useState<string>(initialColorTheme)

    // CONTEXT VARIABLES
    const { changeTargetLanguage } = useContext(TranslationContext)
    // create a new broadcast channel with the same name
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const channel = new BroadcastChannel(CHANNEL)

    useEffect(() => {
        // Check if 'user' key exists in localStorage
        const user = localStorage.getItem('user') ? true : false;
        setUserExists(user);
    }, [channel])

    useEffect(() => {
        if (userExists && isUserLoggedInCognito()) {
            // api call to get the F&B menu global settings
            getFnBGlobalSettings().then((res) => {
                const globalSettings = res.data?.data as FnBGlobalSetting
                setFnBGlobalSettings(globalSettings)
            })

            // api call to get the users global settings
            getUserGlobalConfig()
                .then((res) => setUserGlobalSettings(res.data?.data))
                .catch(() => saveLoginStatus(queryClient, false))
        }
    }, [userExists]) // eslint-disable-line

    // handle force logout using react query
    const userLogoutMutation = useMutation(userLogout, {
        onMutate: () => {
            showLoader(queryClient, true)
        },
        onSuccess: () => {
            // logout success
            logoutActions({ queryClient })
            setPosUserInfo(initialPosValue)
            setIsUserPos(false)
            changeTargetLanguage('en')
            localStorage.setItem('targetLanguage', 'en')
            localStorage.setItem('languageChangeTime', new Date().getTime().toString())
            navigate(routes.welcome_page)
        },
        onSettled: () => {
            showLoader(queryClient, false)
        },
    })

    // load particle engine
    const loadParticlesEngine = async () => {
        initParticlesEngine(async (engine) => {
            await loadAll(engine);
        }).then(() => {
            setParticleEngineLoaded(true);
        });
    }

    // effect to force logout/navigate the application from all tabs
    useEffect(() => {

        // listen for messages on the channel
        channel.onmessage = function (event) {
            if (event.data === FORCE_LOGOUT) {
                userLogoutMutation.mutate()
            }
            if (event.data === NAVIGATE_TO_ROUTE) {
                // navigate all routes except current active one
                if (!isTabVisible) navigate(routes.welcome_page)
            }
            if (event.data.type === 'redirect' && event.data.url) {
                const newUrl = new URL(event.data.url.replace(/\/$/, ''))
                const checkIfGuestRoute = guestOnlyRoutes.includes(newUrl?.pathname)

                if (!checkIfGuestRoute) {
                    const loginStatus = queryClient.getQueryData(LOGIN_STATUS) as {
                        isLoggedIn: boolean
                    }
                    if (loginStatus?.isLoggedIn) {
                        navigate(newUrl.pathname, { replace: true })
                    }
                } else {
                    navigate(newUrl.pathname, { replace: true })
                }
            }
        }

        // clean up
        return () => {
            channel.close();
        };
    }, [userLogoutMutation]) // eslint-disable-line

    // load particle engine
    useEffect(() => {
        loadParticlesEngine()
    }, [])

    // updateColorTheme
    useEffect(() => {
        if (colorTheme) {
            const hexValue = colorTheme;
            const rgbValue = hexToRgb(hexValue);
            
            const root = document.documentElement;
            root.style.setProperty('--primary-color', hexValue);
            root.style.setProperty('--primary-color-rgb', `${rgbValue.r}, ${rgbValue.g}, ${rgbValue.b}`);
        }
    }, [colorTheme])

    // CONTEXT PROVIDER
    return (
        <CommonCtx.Provider
            value={useMemo(() => {
                return {
                    showSideBar,
                    setShowSideBar,
                    posUserInfo,
                    setPosUserInfo,
                    isUserPos,
                    setIsUserPos,
                    fnBGlobalSettings,
                    setFnBGlobalSettings,
                    guestFnbGlobalSettings,
                    setGuestFnbGlobalSettings,
                    scheduleMenu,
                    setScheduleMenu,
                    userGlobalSettings,
                    setUserGlobalSettings,
                    postViewFromInternal,
                    setPostViewFromInternal,
                    showAddOnGroupSideBar,
                    setShowAddOnGroupSideBar,
                    initialLoad,
                    setInitialLoad,
                    guestViewSearchFilter,
                    setGuestViewSearchFilter,
                    particleEngineLoaded,
                    setParticleEngineLoaded,
                    colorTheme,
                    setColorTheme,
                }
            }, [
                showSideBar,
                posUserInfo,
                isUserPos,
                fnBGlobalSettings,
                guestFnbGlobalSettings,
                scheduleMenu,
                userGlobalSettings,
                postViewFromInternal,
                showAddOnGroupSideBar,
                initialLoad,
                guestViewSearchFilter,
                particleEngineLoaded,
                colorTheme,
            ])}
        >
            {children}
        </CommonCtx.Provider>
    )
}
