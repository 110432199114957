import withSidebar from "../../hoc/withSidebar";
import "./styles/survey.scss";
import { useEffect, useState } from 'react';
import { CANCEL, FILL_REQUIRED_FIELDS, QUESTION_SET_NAME, SAVE, SURVEY_MANAGEMENT, UPDATE } from "../../constants/strings";
import { useLocation, useNavigate } from "react-router-dom";
import ToastAlert from "../../components/alert/ToastAlert";
import { AlertVariant } from "../../constants/constants";
import AddQuestionCategory from "../../components/survey/admin/AddQuestionCategory";
import useMutationHook from "../../hooks/useMutationHook";
import { getQuestionSetSubmitData, setQuestionIds, upsertSurveyQuestions } from "../../helpers/surveyManagementHelper";
import { useQueryClient } from "react-query";
import { routes } from "../../constants/routes";
import { SurveyCategories } from "../../constants/staticTypes";
import { OverlayTrigger } from "react-bootstrap";
import { getDomainFromURL, popover } from "../../helpers/utils";
import { convertToSlug } from "../../helpers/newsAndEventsHelper";
import { JTranslation } from "../../helpers/jTranslate";

type Params = {
    id: string,
    questionTitle: string,
    urlSlug: string,
    isGameAvailable: boolean,
    message?: string,
    surveyCategories?: SurveyCategories[]
}

function CreateQuestionSet() {
    const location = useLocation();
    const navigate = useNavigate();
    const params = location.state as Params;
    const queryClient = useQueryClient();
    const surveyUpsertMutation = useMutationHook(queryClient, true);

    const [categories, setCategories] = useState<SurveyCategories[]>([]);
    const [isGameAvailable, setGameAvailable] = useState(false);
    const [questionSetName, setQuestionSetName] = useState("");
    const [saveButtonText, setSaveButtonText] = useState(SAVE);
    const [showToast, setToast] = useState(false); // Toast alert state
    const [toastMessage, setToastMessage] = useState(""); // Toast message
    const [toastVariant, setToastVariant] = useState(""); // Toast Variant
    const [urlSlug, setUrlSlug] = useState(params.urlSlug);


    // show toast
    const displayToast = (message: string, variant: string) => {
        setToastVariant(variant);
        setToastMessage(message);
        setToast(true);
    }

    useEffect(() => {
        if (params.message) { // display toast if any
            displayToast(params.message, AlertVariant.SUCCESS);
        }

        if (params.surveyCategories) { // set categories if exist
            const newCategories = setQuestionIds(params.surveyCategories); // set temp id to questions
            params.surveyCategories = newCategories.map(cat => { // prevent old data
                const newQuestions = cat.surveyQuestions.map(qstn => {
                    return {
                        ...qstn
                    }
                });
                return {
                    ...cat,
                    surveyQuestions: [...newQuestions]
                }
            });
            setCategories([...newCategories]);
            setSaveButtonText(UPDATE);
        }

        setQuestionSetName(params.questionTitle); // set question set title
        setGameAvailable(params.isGameAvailable);
    }, []);

    // on success 
    const onSuccess = (message: string) => {
        navigate(routes.survey, { state: { message } });
    }

    return (
        <>
            <ToastAlert data-testid="toast" show={showToast} onClose={setToast} message={toastMessage} variant={toastVariant} />
            <div className="col-md-12 col-lg-12 mb-3 h-100 ">
                <div className="page-title">
                    <h5><JTranslation typeCase="pascal" text={SURVEY_MANAGEMENT} /></h5>
                </div>

                <div className="col-md-12 col-lg-12 mb-3 h-100 ">
                    <div className="card h-100 ">
                        <div className="card-header flex-grow-0">
                            <div className="d-flex  align-items-center py-1">
                                {/* <div className="flex-grow-0 me-2 back-icon" onClick={() => navigate(-1)}>
                                    <i className="ri-arrow-left-line"></i></div> */}
                                <div className='button btn flex-grow-0 me-2 back-btn back-btn-new' data-testid="back-btn" onClick={() => navigate(-1)}>
                                    <i className='ri-arrow-left-line'></i>
                                </div>
                                <div className="flex-grow-0  col-sm-11 col-md-6">
                                    <input className="form-control" type="text" autoComplete="off"
                                        value={questionSetName}
                                        onChange={(e) => setQuestionSetName(e.target.value)}
                                        data-testid="question-name-input"
                                        placeholder={QUESTION_SET_NAME} aria-label="default input example"></input>
                                </div>
                            </div>
                        </div>
                        <AddQuestionCategory prevCategories={params.surveyCategories} categories={categories}
                            setCategories={setCategories} saveButtonText={saveButtonText}
                            isGameAvailable={isGameAvailable} setGameAvailable={setGameAvailable} urlSlug={urlSlug} setUrlSlug={setUrlSlug} />
                    </div>
                    <div className="save-btn-section shadow save-btn-fixed">
                        <button className="btn btn-custom-primary-outline" type="button" data-testid="cancel=btn"
                            onClick={() => navigate(-1)}><JTranslation typeCase="pascal" text={CANCEL} /></button>
                        <button className="btn btn-custom-primary" type="button" data-testid="save-btn"
                            disabled={categories.length === 0 || !urlSlug}
                            onClick={() => {
                                if (categories.length > 0) {
                                    const submitData = getQuestionSetSubmitData(categories); // remove unwanted items
                                    if (submitData.isValidData) { // check form valid before invoking api
                                        const data = {
                                            surveySetId: params.id,
                                            isGameAvailable,
                                            urlSlug: urlSlug,
                                            surveySetName: questionSetName,
                                            surveyCategories: [...submitData.newCategories],
                                        }
                                        upsertSurveyQuestions(queryClient, surveyUpsertMutation, data, onSuccess, displayToast);
                                    } else {
                                        displayToast(FILL_REQUIRED_FIELDS, AlertVariant.ERROR);
                                    }
                                }
                            }}><JTranslation typeCase="pascal" text={saveButtonText} /></button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default withSidebar(CreateQuestionSet);