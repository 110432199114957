import { AxiosResponse } from "axios";
import { QueryClient, UseMutationResult } from "react-query";
import { publicSurveyQuestionSlugApi, publicSurveyQuestionsApi, publicSurveySubmitApi, publicTenantsListApi, usersMaskedData } from "../constants/apiEndPoints";
import { AlertVariant, HttpMethods, SurveyQuestionType } from "../constants/constants"
import { Payload, Survey, MaskedPersonalInfo } from "../constants/staticTypes";
import HttpServiceHelper from "./httpServiceHelper"
import { getErrorMessage } from "./utils";

export const initialMaskedData = {
    anniversaryDate: "",
    birthDate: "",
    emailId: "",
    fullName: "",
    id: "",
    userId: "",
    isActive: true,
    phoneNumber: "",
    totalVisitCount: 0,
    zipCode: ""
}

// get all active survey questions
export const getSurveyQuestions = (queryClient: QueryClient) => {
    return HttpServiceHelper({
        url: publicSurveyQuestionsApi,
        method: HttpMethods.GET,
        queryClient
    });
}

// submit guest survey
export const submitSurvey = (queryClient: QueryClient,
    submitSurveyMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    data: {},
    onSuccess: (res: AxiosResponse<any, any>) => void,
    onError: (message: string, variant: string) => void) => {
    submitSurveyMutation.mutate({
        url: publicSurveySubmitApi,
        method: HttpMethods.POST,
        data: data,
        queryClient
    }, {
        onSuccess,
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    })
}

export const getSingleSurveyBySlug = (queryClient: QueryClient,
    submitSurveyMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    data: { urlSlug: string | undefined },
    onSuccess: (res: AxiosResponse<any, any>) => void,
    onError: (message: string, variant: string) => void) => {
    submitSurveyMutation.mutate({
        url: publicSurveyQuestionSlugApi,
        method: HttpMethods.POST,
        data: data,
        queryClient
    }, {
        onSuccess,
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    })
}

// set checked state to false for question type true or false
export const getUpdatedSurveyCategories = (survey: Survey) => {
    return survey.surveyCategories.filter(category => category.isActive)
        .map(category => {
            const updatedQuestions = category.surveyQuestions.filter(question => question.isActive)
                .map(qstn => {
                    if (qstn.surveyQuestionType === SurveyQuestionType.TRUE_FALSE) {
                        return {
                            ...qstn,
                            //surveyQuestionAnswer: false
                        }
                    }
                    return qstn;
                });
            return {
                ...category,
                surveyQuestions: [...updatedQuestions]
            }
        });
}

// get formatted data for submit
export const getApiFormattedSurveyFormData = (personalInfo: MaskedPersonalInfo, survey: Survey, comments: string) => {
    let validDataArray = [] as { [key: string]: boolean | number }[];
    const surveyCategories = survey?.surveyCategories.map((category, index) => {
        const surveyQuestions = category.surveyQuestions.map((question, qstnIndex) => {
            let validDataObj = {
                catIndex: index,
                qstnIndex,
                isValidData: true,
                isRequired: question.isRequired
            }
            let surveyQuestion = {
                surveyQuestionName: question.surveyQuestionName,
                surveyQuestionType: question.surveyQuestionType
            } as any;
            if (question.surveyQuestionType === SurveyQuestionType.MATRIX_TABLE) {
                if (question.isRequired) {
                    if (question.matrixRowsAnswers && question.matrixRows) {
                        const matrixRowsAnswerKeys = Object.keys(question.matrixRowsAnswers);
                        if (matrixRowsAnswerKeys.length < question.matrixRows.length) {
                            validDataObj.isValidData = false;
                        }
                    } else {
                        validDataObj.isValidData = false;
                    }
                }
                surveyQuestion = {
                    ...surveyQuestion,
                    matrixColumns: question.matrixColumns,
                    matrixRows: question.matrixRows,
                    matrixRowsAnswers: question.matrixRowsAnswers
                }
            } else {
                if (question.isRequired && question.surveyQuestionAnswer === undefined) {
                    validDataObj.isValidData = false;
                }
                surveyQuestion = {
                    ...surveyQuestion,
                    surveyQuestionAnswer: question.surveyQuestionAnswer
                }
            }
            validDataArray.push(validDataObj);
            return surveyQuestion;
        })
        return {
            surveyCategoryName: category.surveyCategoryName,
            surveyCategoryType: category.surveyCategoryType,
            surveyQuestions: [...surveyQuestions]
        }
    }) as any[];

    return {
        surveyAnswersValid: validDataArray,
        formData: {
            ...personalInfo,
            surveyAnswers: [{
                surveySetId: survey?.id,
                surveySetName: survey?.surveySetName,
                surveyCategories: [...surveyCategories, {
                    surveyCategoryName: "Other Info",
                    surveyCategoryType: "other",
                    surveyQuestions: [{
                        surveyQuestionName: "Comments / Suggestions",
                        surveyQuestionType: SurveyQuestionType.TEXT_AREA,
                        surveyQuestionAnswer: comments
                    }]
                }]
            }]
        }
    };
}

// get all tenant staffs
export const getTenantStaffs = (queryClient: QueryClient, search: string, limit: number, lastKey: string | null) => {
    return HttpServiceHelper({
        url: publicTenantsListApi + "?search=" + search + "&limit=" + limit + "&lastKey=" + lastKey,
        method: HttpMethods.GET,
        queryClient
    });
}

// get mask user data using email
export const getMaskedUserData = (queryClient: QueryClient,
    maskedUserDataMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    data: {},
    onSuccess: (res: AxiosResponse<any, any>) => void,
    onError: (message: string, variant: string) => void) => {
    maskedUserDataMutation.mutate({
        url: usersMaskedData,
        method: HttpMethods.POST,
        data: data,
        queryClient
    }, {
        onSuccess,
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    })
}