import { AlertVariant, HttpMethods } from '../constants/constants'
import { AxiosResponse } from 'axios'
import { getErrorMessage } from './utils'
import { invoiceListAllFiles } from '../constants/apiEndPoints'
import { Payload, ListAllInvoiceApiResponse } from '../constants/staticTypes'
import { UseMutationResult } from 'react-query'

// get all invoice files
export const getAllInvoiceFiles = (
	invoiceFileMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	urlParams: string,
	onSuccess: (data: ListAllInvoiceApiResponse) => void,
	onError: (res: string, variant: string) => void
) => {
	invoiceFileMutation.mutate(
		{
			url: invoiceListAllFiles + urlParams,
			method: HttpMethods.GET,
		},
		{
			onSuccess: (res) => onSuccess(res.data.data),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}
