import { SetStateAction, useContext, useEffect, useState } from "react";
import { ACTIVATE_CATEGORY, CATEGORY_DELETE_WARNING, DEACTIVATE_CATEGORY, DELETE_WARNING, ACTIVATE, DEACTIVATE, EDIT, DELETE } from "../../../../constants/strings";
import { checkPermission } from "../../../../helpers/utils";
import { CSS } from '@dnd-kit/utilities';
import { FaEdit, FaToggleOff, FaToggleOn } from "react-icons/fa";
import { CustomMenuCategoriesAndItems, MenuItem, CustomMenu } from "../../../../constants/staticTypes";
import { PermissionKeys, SubPermissionKeys, WarningCallback } from "../../../../constants/constants";
import { QueryClient } from "react-query";
import { useSortable } from "@dnd-kit/sortable";
import { TranslationContext, jTranslationText } from "../../../../helpers/jTranslate";
import MlxTooltip from "../../../common/MlxTooltip";

type Props = {
    category: CustomMenuCategoriesAndItems,
    onActiveInactiveToggle: (id: string, message: string, callbackType: SetStateAction<WarningCallback | undefined>) => void,
    queryClient: QueryClient
    selectedCategory: CustomMenuCategoriesAndItems | undefined
    selectedMenu: CustomMenu | undefined
    setAddCatgory: React.Dispatch<React.SetStateAction<boolean>>,
    setCallbackType: React.Dispatch<React.SetStateAction<WarningCallback | undefined>>,
    setDeleteItem: React.Dispatch<React.SetStateAction<string | undefined>>,
    setEditItem: React.Dispatch<React.SetStateAction<CustomMenuCategoriesAndItems | MenuItem | undefined>>,
    setSelectedCategory: React.Dispatch<React.SetStateAction<CustomMenuCategoriesAndItems | undefined>>
    setSelectedItem: React.Dispatch<React.SetStateAction<MenuItem | undefined>>
    setWarning: React.Dispatch<React.SetStateAction<boolean>>,
    setWarningMessage: React.Dispatch<React.SetStateAction<string>>,
    showAddCategory: boolean,
}

function CustomCategoryComponent({ setEditItem, category, selectedCategory, setSelectedCategory, setAddCatgory, showAddCategory,
    setDeleteItem, queryClient, setSelectedItem, setWarningMessage, setWarning, setCallbackType, onActiveInactiveToggle }: Props) {

    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext

    const [translationText, setTranslatedText] = useState<{
        deActivate: string,
        activate: string,
        edit: string,
        delete: string,
      }>({ 
        deActivate: DEACTIVATE,
        activate: ACTIVATE,
        edit: EDIT,
        delete: DELETE,
    });

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: DEACTIVATE, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: ACTIVATE, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: EDIT, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: DELETE, typeCase: 'pascal', translationContext }),
                ])
                setTranslatedText({
                    deActivate: translations[0] ?? DEACTIVATE,
                    activate: translations[1] ?? ACTIVATE,
                    edit: translations[2] ?? EDIT,
                    delete: translations[3] ?? DELETE,
                })
            } catch {
                setTranslatedText({
                    deActivate: DEACTIVATE,
                    activate: ACTIVATE,
                    edit: EDIT,
                    delete: DELETE,
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition } = useSortable({ id: category.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        touchAction: "pan-y"
    };

    return <div key={category.id} ref={setNodeRef} style={style} {...attributes} {...listeners}
        className={"viewMenubox-container custom-height " + (selectedCategory?.id === category.id && "active ") +

            (!category.isActive && " in-active")}
        onClick={() => {
            setSelectedCategory(category)
            setSelectedItem(category?.menuItems[0]);
        }}>

        <div className="menubox-img-contanier category custom-height">
            <MlxTooltip target=".menu-title">
            <div className="menu-title" data-pr-tooltip={category.categoryName} data-pr-position="bottom">
                {category.categoryName}
            </div>
            </MlxTooltip>
        </div>
        <div className="menubox-toolbox tools">
            {category.isActive ?
                <FaToggleOn title={translationText.deActivate} className={"view " +
                    (!checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.STATUS) &&
                        " disabled")} onClick={() => {
                            if (checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.STATUS)) {
                                onActiveInactiveToggle(category.id, DEACTIVATE_CATEGORY, WarningCallback.ACTIVATE_DEACTIVATE_MENU);
                            }
                        }} /> :
                <FaToggleOff title={translationText.activate} className={"view " +
                    (!checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.STATUS) &&
                        " disabled")} onClick={() => {
                            if (checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.STATUS)) {
                                onActiveInactiveToggle(category.id, ACTIVATE_CATEGORY, WarningCallback.ACTIVATE_DEACTIVATE_MENU);
                            }
                        }} />}
            <FaEdit title={translationText.edit} className={"edit " +
                (!checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.EDIT) &&
                    " disabled")} onClick={() => {
                        if (checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.EDIT)) {
                            setEditItem(category);
                            setAddCatgory(!showAddCategory);
                        }
                    }} />
            <i title={translationText.delete} className={"ri-delete-bin-line delete " +
                (!checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.DELETE) &&
                    " disabled")} onClick={() => {
                        if (checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.DELETE)) {
                            setCallbackType(WarningCallback.DEL_CATEGORY);
                            setDeleteItem(category.id);
                            if (category.menuItems.length > 0) {
                                setWarningMessage(CATEGORY_DELETE_WARNING);
                            } else {
                                setWarningMessage(DELETE_WARNING);
                            }
                            setWarning(true);
                        }
                    }}></i>
        </div>
    </div>
}

export default CustomCategoryComponent;