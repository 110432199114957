import { useQueryClient, UseQueryResult } from "react-query"
import { useWindowSize } from "react-use"
import { currentWeekDaysString } from "../../helpers/availabilityHelper"
import { getStaffId } from "../../helpers/utils"
import useMutationHook from "../../hooks/useMutationHook"
import { useContext, useEffect, useState } from "react"
import { AlertVariant, MOBILE_VIEW_BREAKPOINT, toastMessageInitialData } from "../../constants/constants"
import { ToastMessageProps } from "../../constants/staticTypes"
import { JTranslation, jTranslationText, TranslationContext } from "../../helpers/jTranslate"
import { EDIT, DELETE } from "../../constants/strings"
import useQueryHook from "../../hooks/useQueryHook"
import { SWAP_REQUEST_LIST } from "../../constants/queryKeys"
import { actionTemplate, checkIfManageSwapRequest, checkIfShiftTaken, deleteSwapRequest, getSwapList, initialValuesForSwapRequestConfirmPopup, ShiftSwapPoolRequests, statusTemplate, SwapRequestConfirmPopupType, SwapRequestList, SwapStaffDetails, updateSwapRequestStatus } from "../../helpers/swapScheduleHelper"
import { AxiosResponse } from "axios"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import dayjs from "dayjs"
import { Badge } from "primereact/badge"
import { Card } from "primereact/card"
import { Divider } from "primereact/divider"
import { DataView } from "primereact/dataview"
import TakeSwiftRequestSideBar from "./TakeSwiftRequestSideBar"
import { CommonCtx } from "../../context/CommonCtxProvider"
import ToastAlert from "../alert/ToastAlert"
import Loader from "../loader/Loader"
import { ConfirmPopup } from "primereact/confirmpopup"
import CommonModal from "../common_modal/CommonModal"
import { capitalize } from "lodash"
import { ScheduleData } from "../../helpers/workTimeScheduleHelper"
import { Button } from "primereact/button"
import { Tooltip } from "primereact/tooltip"
import ApproveTakenShiftSideBar from "./ApproveTakenShiftSideBar"

type Props = {
    staffsWithSchedule: ScheduleData | null;
    staffsWithScheduleQuery: UseQueryResult<AxiosResponse<any, any>, unknown>;
    scheduleDataQuery: UseQueryResult<AxiosResponse<any, any>, unknown>;
    selectedWeek: dayjs.Dayjs;
}

function SwapRequestDataListGrid({
    staffsWithSchedule,
    staffsWithScheduleQuery,
    scheduleDataQuery,
    selectedWeek
}: Readonly<Props>) {
    const queryClient = useQueryClient()
    const swapRequestMutuation = useMutationHook(queryClient, true)
    const { width } = useWindowSize()
    const translationContext = useContext(TranslationContext)
    const { showSideBar, setShowSideBar } = useContext(CommonCtx)

    const staffId = getStaffId()
    const currentWeek = currentWeekDaysString()
    const breakPoint = MOBILE_VIEW_BREAKPOINT
    const { targetLanguage } = translationContext
    const managePermission = checkIfManageSwapRequest(queryClient)

    const [translationText, setTranslatedText] = useState<{
        edit: string,
        delete: string,
        search: string,
        paginationTemplate: string,
        emptyMessage: string,
        confirmationMessage: string,
    }>({
        edit: EDIT,
        delete: DELETE,
        search: 'Search',
        paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
        emptyMessage: 'No Availability found',
        confirmationMessage: 'Are you sure you want to proceed?',
    });
    const [swapRequestList, setSwapRequestList] = useState<SwapRequestList[]>([])
    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
    const [showWarning, setShowWarning] = useState(false)
    const [takenSwapShiftRequest, setTakenSwapShiftRequest] = useState<SwapRequestList | null>(null)
    const [approveSwapShiftRequest, setApproveSwapShiftRequest] = useState<SwapRequestList | null>(null)
    const [requestInfo, setRequestInfo] = useState<SwapRequestConfirmPopupType>(initialValuesForSwapRequestConfirmPopup)

    // fetch swap request list
    const swapListQuery = useQueryHook(
        SWAP_REQUEST_LIST,
        () => getSwapList(managePermission ? undefined : staffId),
        (response: AxiosResponse<any, any>) => {
            const swapResponseData = (response?.data?.data?.lists ?? []) as SwapRequestList[]
            setSwapRequestList(swapResponseData)
        },
        (error) => {
            console.log(`Error fetching swap list: ${error}`)
        },
        true,
        false
    )

    const getName = (staff: SwapStaffDetails) => {
        const staffName = staff?.user?.preferredName ?? `${staff?.user?.firstName} ${staff?.user?.lastName}`
        return capitalize(staffName ?? '')
    }

    const changeRequestStatus = () => {
        // console.log(requestInfo)
        updateSwapRequestStatus(
            swapRequestMutuation,
            {
                id: requestInfo?.requestId,
                newStatus: requestInfo?.action,
                approverRole: requestInfo?.approverRole,
            },
            (data) => {
                setToastMessage({ message: data.message, variant: AlertVariant.SUCCESS, show: true })
                setRequestInfo(initialValuesForSwapRequestConfirmPopup)
                queryClient.refetchQueries([SWAP_REQUEST_LIST])
            },
            (error) => {
                setRequestInfo(initialValuesForSwapRequestConfirmPopup)
                setToastMessage({ message: error, variant: AlertVariant.ERROR, show: true })
            }
        )
    }

    const deleteSwapRequestById = () => {
        setShowWarning(false)
        deleteSwapRequest(
            swapRequestMutuation,
            { id: requestInfo?.requestId },
            (data) => {
                setToastMessage({ message: data.message, variant: AlertVariant.SUCCESS, show: true })
                setRequestInfo(initialValuesForSwapRequestConfirmPopup)
                queryClient.refetchQueries([SWAP_REQUEST_LIST])
            },
            (error) => {
                setRequestInfo(initialValuesForSwapRequestConfirmPopup)
                setToastMessage({ message: error, variant: AlertVariant.ERROR, show: true })
            }
        )
    }

    const shiftRequestTakenShifts = (swapRequest: SwapRequestList) => {
        const shiftPool = swapRequest?.shiftSwapPoolRequests
        if (checkIfShiftTaken(swapRequest, staffId) && !managePermission) {
            const shift = swapRequest?.shiftSwapPoolRequests?.find(item => item?.tenantStaffId === staffId)
            if (width <= breakPoint) {
                return (
                    <div className="d-flex flex-row">
                        <span>
                            {/* {shift?.shiftData?.assignedShifts?.shiftName} : */}
                            {/* <span className="ms-1"> */}
                                {shift?.shiftData?.scheduleDate ? dayjs(shift?.shiftData?.scheduleDate).format('MMM D, YYYY') : ''} {`(${shift?.shiftData?.assignedShifts?.startTime} - ${shift?.shiftData?.assignedShifts?.endTime})`}
                            {/* </span> */}
                        </span>
                    </div>
                )
            }
            return (
                <div className="d-flex flex-column">
                    <span>
                        {shift?.shiftData?.scheduleDate ? dayjs(shift?.shiftData?.scheduleDate).format('MMM D, YYYY') : ''}
                        {/* <small className="ms-2 text-warning">
                            {shift?.shiftData?.assignedShifts?.shiftName}
                        </small> */}
                    </span>
                    <span>
                        {`(${shift?.shiftData?.assignedShifts?.startTime} - ${shift?.shiftData?.assignedShifts?.endTime})`}
                    </span>
                </div>
            )
        }
        return (
            <>
                <Button
                    className={`btn btn-sm btn-warning ${'shifts-taken-' + swapRequest?.id} ${shiftPool.length > 0 ? 'ms-1' : ''}`}
                    type="button"
                >
                    <span className="ms-2"><b>{shiftPool.length}</b> <JTranslation typeCase="capitalize" text={'Shifts'} /></span>
                </Button>
                {shiftPool?.length > 0 && (
                    <Tooltip className="py-2 swap-req-tooltip" target={`.shifts-taken-${swapRequest?.id}`}>
                        {shiftPool?.map((shift, index) => {
                            return (
                                <div key={shift?.id} className={`d-flex flex-column ${(shift?.status as any) === 'APPROVED' ? 'text-success' : ''}`}>
                                    {index !== 0 && <Divider className="p-0 my-1" />}
                                    <span>
                                        {shift?.shiftData?.scheduleDate ? dayjs(shift?.shiftData?.scheduleDate).format('MMM D, YYYY') : ''}
                                        {/* <small className="ms-2 text-warning">{shift?.shiftData?.assignedShifts?.shiftName}</small> */}
                                    </span>
                                    <span>
                                        {`(${shift?.shiftData?.assignedShifts?.startTime} - ${shift?.shiftData?.assignedShifts?.endTime})`}
                                    </span>
                                </div>
                            )
                        })}
                    </Tooltip>
                )}
            </>
        )
    }

    const shiftRequestTakenUsers = (swapRequest: SwapRequestList) => {
        const shiftPool = swapRequest?.shiftSwapPoolRequests
        if (checkIfShiftTaken(swapRequest, staffId) && !managePermission) {
            const data = swapRequest?.shiftSwapPoolRequests?.find(item => item?.tenantStaffId === staffId)
            const staffName = getName((data as ShiftSwapPoolRequests)?.tenantStaff)
            return (
                <div className={width <= breakPoint ? "d-flex flex-row" : "d-flex flex-column"}>
                    <span>{width <= breakPoint ? `${staffName} : ` : <b>{`${staffName}`}</b>} </span>
                    <span className={width <= breakPoint ? "ms-1" : ""}>
                        {width <= breakPoint
                            ? <>{data?.shiftData?.assignedShifts?.role}</>
                            : <small>{data?.shiftData?.assignedShifts?.role}</small>
                        }
                    </span>
                </div>
            )
        }
        return (
            <>
                <Button className={`btn btn-sm btn-warning ${'users-taken-' + swapRequest?.id}`} type="button" text >
                    <span className="ms-2"><b>{shiftPool.length}</b> <JTranslation typeCase="capitalize" text={'Users'} /></span>
                </Button>
                {shiftPool?.length > 0 && (
                    <Tooltip className="py-2 swap-req-tooltip" target={`.users-taken-${swapRequest?.id}`}>
                        {shiftPool?.map((shift, index) => {
                            return (
                                <div key={shift?.id} className={`d-flex flex-column ${(shift?.status as any) === 'APPROVED' ? 'text-success' : ''}`}>
                                    {index !== 0 && <Divider className="p-0 my-1" />}
                                    <b>{getName(shift?.tenantStaff) ?? ''}</b>
                                    <span><small>{shift?.shiftData?.assignedShifts?.role}</small></span>
                                </div>
                            )
                        })}
                    </Tooltip>
                )}
            </>
        )
    }

    const itemTemplate = (data: SwapRequestList) => {
        return (
            <Card className="data-view-card mt-2 d-flex flex-column justify-content-even">
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <p className="m-0">{getName(data?.fromTenantStaff) ?? ''}</p>
                </div>
                <div className='mt-2'>
                    <div className="d-flex flex-column">
                        <span className="d-flex flex-row align-items-center">
                            <b className="me-2"><JTranslation typeCase="capitalize" text={'Shift Offered'} />:</b>
                        </span>
                        <div className="d-flex flex-column justify-content-center align-items-start">
                            <span>{data?.fromShift?.assignedShifts?.role}</span>
                            <span>
                                {/* {data?.fromShift?.assignedShifts?.shiftName} :  */}
                                {data?.fromShift?.scheduleDate ? dayjs(data?.fromShift?.scheduleDate).format('MMM D, YYYY') : ''} ({data?.fromShift?.assignedShifts?.startTime} - {data?.fromShift?.assignedShifts?.endTime})
                            </span>
                        </div>
                    </div>
                    <Divider className="p-0 m-0" align="center">
                        <Badge severity={'warning'} value={(
                            <div className="d-flex align-items-center">
                                <span><i className="pi pi-arrow-right-arrow-left me-2"></i></span>
                                {data?.requestType === 'swap'
                                    ? <JTranslation typeCase="pascal" text={'DIRECT'} />
                                    : <JTranslation typeCase="pascal" text={'POOL'} />
                                }
                            </div>
                        )} className="custom-badge" />
                    </Divider>
                    <div className="d-flex flex-column">
                        <span className="me-2">
                            <b className="me-2"><JTranslation typeCase="capitalize" text={'Taken By'} />:</b>
                        </span>
                        {(data?.requestType === 'pool') ? (
                            <div className={"d-flex flex-row"}>
                                <span>{shiftRequestTakenUsers(data)}</span>
                                <span>{shiftRequestTakenShifts(data)}</span>
                            </div>
                        ) : (
                            <div className="d-flex flex-column justify-content-center align-items-start">
                                <p className="m-0">{getName(data?.toTenantStaff) ?? ''} : {data?.toShift?.assignedShifts?.role}</p>
                                <span>
                                    {/* {data?.toShift?.assignedShifts?.shiftName} :  */}
                                    {data?.toShift?.scheduleDate ? dayjs(data?.toShift?.scheduleDate).format('MMM D, YYYY') : ''} ({data?.toShift?.assignedShifts?.startTime} - {data?.toShift?.assignedShifts?.endTime})
                                    </span>
                            </div>
                        )}
                    </div>
                </div>
                <div className="view-footer mt-2 pt-3 d-flex flex-row justify-content-between align-items-center">
                    <div className='d-flex justify-content-even align-items-center'>
                        {statusTemplate(data, managePermission)}
                    </div>
                    <div className='d-flex justify-content-even align-items-center'>
                        {actionTemplate(
                            data,
                            staffId,
                            managePermission,
                            true,
                            setRequestInfo,
                            staffsWithSchedule
                        )}
                    </div>
                </div>
            </Card>
        )
    }

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: EDIT, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: DELETE, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: 'Search', typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: 'Showing 1 to 2 of 3 items', typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: 'No Swap Request found', typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: 'Are you sure you want to proceed?', typeCase: 'pascal', translationContext }),
                ])

                setTranslatedText({
                    edit: translations[0] ?? EDIT,
                    delete: translations[1] ?? DELETE,
                    search: translations[2] ?? 'Search',
                    paginationTemplate:
                        translations[3]
                            ?.replace('1', '{first}')
                            ?.replace('2', '{last}')
                            ?.replace('3', '{totalRecords}') ?? 'Showing {first} to {last} of {totalRecords} items',
                    emptyMessage: translations[4] ?? 'No Swap Request found',
                    confirmationMessage: translations[5] ?? 'Are you sure you want to proceed?',
                })
            } catch {
                setTranslatedText({
                    edit: EDIT,
                    delete: DELETE,
                    search: 'Search',
                    paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
                    emptyMessage: 'No Swap Request found',
                    confirmationMessage: 'Are you sure you want to proceed?',
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    useEffect(() => {
        if (takenSwapShiftRequest) {
            setShowSideBar(true)
        } else {
            setTakenSwapShiftRequest(null)
        }
    }, [takenSwapShiftRequest])

    useEffect(() => {
        if (approveSwapShiftRequest) {
            setShowSideBar(true)
        } else {
            setApproveSwapShiftRequest(null)
        }
    }, [approveSwapShiftRequest])

    useEffect(() => {
        if (requestInfo) {
            switch (requestInfo.action) {
                case 'CONFIRM_DELETE':
                    setShowWarning(true)
                    break;
                case 'POOL_REQUESTS':
                    const swapRequestForApprove = swapRequestList?.find((data) => data?.id === requestInfo.requestId)
                    if (swapRequestForApprove) {
                        setApproveSwapShiftRequest(swapRequestForApprove)
                    }
                    break;
                case 'TAKE_POOL_REQUEST':
                    const swapRequest = swapRequestList?.find((data) => data?.id === requestInfo.requestId)
                    if (swapRequest) {
                        setTakenSwapShiftRequest(swapRequest)
                    }
                    break;
            }
        }
    }, [requestInfo])

    // loading screen
    if (swapListQuery.isLoading) {
        return <Loader />
    }

    return (
        <>
            {showSideBar && takenSwapShiftRequest && (
                <TakeSwiftRequestSideBar
                    handleClose={() => {
                        setTakenSwapShiftRequest(null)
                        setShowSideBar(false)
                    }}
                    takenSwapShiftRequest={takenSwapShiftRequest}
                    swapListQuery={swapListQuery}
                    staffsWithSchedule={staffsWithSchedule}
                />
            )}

            {showSideBar && approveSwapShiftRequest && (
                <ApproveTakenShiftSideBar
                    handleClose={() => {
                        setApproveSwapShiftRequest(null)
                        setShowSideBar(false)
                    }}
                    swapRequest={approveSwapShiftRequest}
                    swapListQuery={swapListQuery}
                />
            )}

            {/* accept request popup */}
            <ConfirmPopup
                target={document.getElementById(requestInfo.buttonName) ?? undefined}
                visible={requestInfo.visible}
                onHide={() =>
                    setRequestInfo(initialValuesForSwapRequestConfirmPopup)
                }
                message={translationText.confirmationMessage}
                icon="pi pi-exclamation-triangle"
                accept={() => changeRequestStatus()}
            />

            {/* reject request popup */}
            <CommonModal
                show={showWarning}
                modalContent={'Do you wish to proceed with deleting the swap request?'}
                title={'Delete Swap Request'}
                modalSize="md"
                disableProceed={requestInfo.action !== "CONFIRM_DELETE"}
                onHide={() => {
                    setRequestInfo(initialValuesForSwapRequestConfirmPopup)
                    setShowWarning(false)
                }}
                callback={() => deleteSwapRequestById()}
                hideCloseButton
            />

            {/* TOAST MESSAGE COMPONENT */}
            <ToastAlert
                show={toastMessage.show}
                onClose={() => setToastMessage(toastMessageInitialData)}
                message={toastMessage.message}
                variant={toastMessage.variant}
            />
            <div className="row h-100">
                <div className="col-lg-12" style={{ height: '100%' }}>
                    {width <= breakPoint ? (
                        <DataView
                            value={swapRequestList}
                            layout={'list'}
                            itemTemplate={itemTemplate}
                            paginator
                            rows={10}
                            paginatorClassName="p-dataview-customers"
                            dataKey="id"
                            emptyMessage={translationText.emptyMessage}
                            loading={swapListQuery.isFetching}
                            pt={{ loadingOverlay: { className: 'color-primary-custom' } }}
                        />
                    ) : (
                        <DataTable
                            value={swapRequestList}
                            paginator
                            className="p-datatable-customers"
                            rows={10}
                            scrollable
                            scrollHeight="flex"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            rowsPerPageOptions={[10, 25, 50]}
                            dataKey="id"
                            rowHover
                            responsiveLayout="scroll"
                            emptyMessage={translationText.emptyMessage}
                            currentPageReportTemplate={translationText.paginationTemplate}
                            loading={swapListQuery.isFetching}
                            pt={{ loadingOverlay: { className: 'color-primary-custom' } }}
                        >
                            <Column
                                style={{ minWidth: '10rem' }}
                                header={<JTranslation typeCase="pascal" text={'Name'} />}
                                className="word-break-grid"
                                body={(data: SwapRequestList) => {
                                    return (
                                        <div className="d-flex flex-column">
                                            <b>{getName(data?.fromTenantStaff) ?? ''}</b>
                                            <span><small>{data?.fromShift?.assignedShifts?.role}</small></span>
                                        </div>
                                    )
                                }}
                            />
                            <Column
                                style={{ minWidth: '10rem' }}
                                header={<JTranslation typeCase="pascal" text={'Shift Offered'} />}
                                className="word-break-grid"
                                body={(data: SwapRequestList) => {
                                    return (
                                        <div className="d-flex flex-column">
                                            <span>
                                                {data?.fromShift?.scheduleDate ? dayjs(data?.fromShift?.scheduleDate).format('MMM D, YYYY') : ''}
                                                {/* <small className="ms-2 text-warning">{data?.fromShift?.assignedShifts?.shiftName}</small> */}
                                            </span>
                                            <span>
                                                {data?.fromShift?.assignedShifts?.startTime} - {data?.fromShift?.assignedShifts?.endTime}
                                            </span>
                                        </div>
                                    )
                                }}
                            />
                            <Column
                                style={{ minWidth: '8rem' }}
                                header={<JTranslation typeCase="pascal" text={'Swap Type'} />}
                                className="word-break-grid"
                                body={(data: SwapRequestList) => {
                                    return (
                                        <div className="d-flex flex-row align-items-center">
                                            <span> <i className="pi pi-arrow-right-arrow-left"></i></span>
                                            <span className="ms-2">
                                                {data?.requestType === 'swap'
                                                    ? <JTranslation typeCase="pascal" text={'DIRECT'} />
                                                    : <JTranslation typeCase="pascal" text={'POOL'} />
                                                }
                                            </span>
                                        </div>
                                    )
                                }}
                            />
                            <Column
                                style={{ minWidth: '10rem' }}
                                header={<JTranslation typeCase="pascal" text={'Taken By'} />}
                                className="word-break-grid"
                                body={(data: SwapRequestList) => {
                                    if (data?.requestType === 'pool') {
                                        return shiftRequestTakenUsers(data)
                                    }
                                    return (
                                        <div className="d-flex flex-column">
                                            <b>{getName(data?.toTenantStaff) ?? ''}</b>
                                            <span><small>{data?.toShift?.assignedShifts?.role}</small></span>
                                        </div>
                                    )
                                }}
                            />
                            <Column
                                style={{ minWidth: '10rem' }}
                                header={<JTranslation typeCase="pascal" text={'Shift Received'} />}
                                className="word-break-grid"
                                body={(data: SwapRequestList) => {
                                    if (data?.requestType === 'pool') {
                                        return shiftRequestTakenShifts(data)
                                    }
                                    return (
                                        <div className="d-flex flex-column">
                                            <span>
                                                {data?.toShift?.scheduleDate ? dayjs(data?.toShift?.scheduleDate).format('MMM D, YYYY') : ''}
                                                {/* <small className="ms-2 text-warning">{data?.toShift?.assignedShifts?.shiftName}</small> */}
                                            </span>
                                            <span>
                                                {data?.toShift?.assignedShifts?.startTime} - {data?.toShift?.assignedShifts?.endTime}
                                            </span>
                                        </div>
                                    )
                                }}
                            />
                            <Column
                                // className="word-break-grid"
                                // style={{ minWidth: '210px' }}
                                style={{ whiteSpace: 'nowrap', width: '195px' }}
                                header={<JTranslation typeCase="pascal" text={'Status'} />}
                                body={(data) => statusTemplate(data, managePermission)}
                            />

                            {/* Action Buttons */}
                            <Column
                                header={<JTranslation typeCase="pascal" text={'Action'} />}
                                style={{ whiteSpace: 'nowrap', width: '181px' }}
                                bodyClassName="bg-sticky-color"
                                body={(data) => actionTemplate(
                                    data,
                                    staffId,
                                    managePermission,
                                    false,
                                    setRequestInfo,
                                    staffsWithSchedule
                                )}
                                alignFrozen="right"
                                frozen={true}
                            />
                        </DataTable>
                    )}
                </div>
            </div>
        </>
    )
}

export default SwapRequestDataListGrid