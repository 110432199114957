import React, { useState, useContext, useEffect } from 'react';
import 'react-phone-number-input/style.css';
import { CANCEL, NAME, SAVE, UPDATE, DESCRIPTION } from '../../constants/strings';
import { AREA_LIST } from "../../constants/queryKeys";
import { ToastMessageProps, AreaType, AddAreaTypeFormData, AreaTypeFormData } from "../../constants/staticTypes";
import { CommonCtx } from '../../context/CommonCtxProvider';
import { FormClass, toastMessageInitialData } from "../../constants/constants";
import { useQueryClient } from "react-query";
import Offcanvas from 'react-bootstrap/Offcanvas';
import ToastAlert from "../alert/ToastAlert";
import useMutationHook from "../../hooks/useMutationHook";
import { JTranslation } from '../../helpers/jTranslate';
import { InputTextarea } from 'primereact/inputtextarea';
import { createNewArea, updateArea } from '../../helpers/manageAreaHelper';

type Props = {
    editArea: AreaType | undefined,
    setEditArea: React.Dispatch<React.SetStateAction<AreaType | undefined>>,
    handleClose: () => void,
}

const AddOnsSideBar = ({ editArea, setEditArea, handleClose }: Readonly<Props>) => {
    const { showSideBar, setShowSideBar } = useContext(CommonCtx)
    const queryClient = useQueryClient()
    const areaMutation = useMutationHook(queryClient, true) // add or update area mutation

    // STATE VARIABLES
    const [formData, setFormData] = useState<AreaTypeFormData>({id: '', departmentName: '', departmentDescription: ''})
    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)

    // effect for setting the form data
    useEffect(() => {
        if (editArea) {
            setFormData({
                id: editArea.id ?? '',
                departmentName: editArea.departmentName ?? '',
                departmentDescription: editArea.departmentDescription ?? '',
            })
        }
    }, [editArea])

    // on add or update addOn success
    const onSuccess = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
        queryClient.refetchQueries([AREA_LIST]) // nosonar
        setTimeout(() => {
            cleanUpData()
        }, 3000)
    }

    // on add or update addOn error
    const onError = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
    }

    // clear data
    const cleanUpData = () => {
        setFormData({id: '', departmentName: '', departmentDescription: ''})
        setEditArea(undefined)
        setToastMessage(toastMessageInitialData)
        setShowSideBar(false)
    }

    const isFormValid = () => {
        let departmentName = formData.departmentName?.trim().length > 0 ? FormClass.VALID : FormClass.INVALID
        if (departmentName === FormClass.VALID) {
            return true
        } else {
            return false
        }
    }

    const submitForm = () => {
        if (editArea) {
            const updateAreaData: AreaTypeFormData = {
                id: formData.id,
                departmentName: formData.departmentName,
                departmentDescription: formData.departmentDescription,
            }
            // update addon api call
            updateArea(areaMutation, updateAreaData, onSuccess, onError)
        } else {
            const newAreaData: AddAreaTypeFormData = {
                departmentName: formData.departmentName,
                departmentDescription: formData.departmentDescription,
            }
            // create addon api call
            createNewArea(areaMutation, newAreaData, onSuccess, onError)
        }
    }

    return (
        <Offcanvas
            show={showSideBar}
            className='custom-offcanvas'
            onHide={() => {
                handleClose()
                cleanUpData()
            }}
            backdrop="static"
            // responsive="xl"
            placement="end"
        >
            {/* TOAST MESSAGE COMPONENT */}
            <ToastAlert
                show={toastMessage.show}
                onClose={() => setToastMessage(toastMessageInitialData)}
                message={toastMessage.message}
                variant={toastMessage.variant}
            />

            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{editArea 
                    ? <JTranslation typeCase="pascal" text={'Edit Area'} /> 
                    : <JTranslation typeCase="pascal" text={'Add Area'} />
                }</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="row">
                    <div className=" col-md-12 col-lg-12 mb-3">
                        <label htmlFor="departmentName" className="form-label">
                            <JTranslation typeCase="pascal" text={NAME} />
                            <span className="mandatory ">*</span>{' '}
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="departmentName"
                            autoComplete="off"
                            maxLength={100}
                            value={formData.departmentName}
                            data-testid="area-name-input"
                            onChange={(e) => {
                                setFormData({ ...formData, departmentName: e.target.value })
                            }}
                        />
                    </div>
                    <div className=" col-md-12 col-lg-12 mb-3 d-flex flex-column">
                        <label htmlFor="departmentDescription" className="form-label">
                            <JTranslation typeCase="pascal" text={DESCRIPTION} />
                        </label>
                        <InputTextarea
                            id="departmentDescription"
                            autoComplete="off"
                            value={formData.departmentDescription}
                            data-testid="area-description-input"
                            onChange={(e) => {
                                setFormData({ ...formData, departmentDescription: e.target.value })
                            }}
                            rows={5} cols={3}
                            autoResize
                        />
                    </div>
                </div>

                <div className="save-btn-section shadow save-btn-absolute">
                    <button
                        className="btn btn-custom-primary-outline"
                        type="button"
                        data-testid="cancel-btn"
                        onClick={() => {
                            handleClose()
                            cleanUpData()
                        }}
                    >
                        <JTranslation typeCase="pascal" text={CANCEL} />
                    </button>

                    <button
                        className="btn btn-custom-primary"
                        type="button"
                        disabled={!isFormValid()}
                        data-testid="save-btn"
                        onClick={() => {
                            // validate and submit form
                            if (isFormValid()) {
                                submitForm()
                            }
                        }}
                    >
                        {editArea 
                            ? <JTranslation typeCase="pascal" text={UPDATE} /> 
                            : <JTranslation typeCase="pascal" text={SAVE} />
                        }
                    </button>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default AddOnsSideBar