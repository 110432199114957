import React from 'react'
import Lottie from 'react-lottie'
import animationData from './christmasLogoAnimation.json'
import './christmasLogoAnimation.css'
// import { getChristmasEffects } from '../celebrationsConfigs'

const ChristmasAnimation: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
	// const christmasEffects = getChristmasEffects()
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	}

	// if (christmasEffects?.showSpecialLogo) {
    return (
      <div className='animation-container-christmas-logo' style={style}>
        <Lottie
          options={defaultOptions}
          height={70}
          width={70}
          isPaused={false}
          isStopped={false}
        />
      </div>
    )
//   }
// 	return null
}

export default ChristmasAnimation
