import { useEffect, useState, useContext, useCallback } from "react";
import { Alert, Offcanvas, OverlayTrigger } from "react-bootstrap";
import { AxiosResponse } from "axios";
import { FnBCtx } from '../../../context/MoveItemCtxProvider';
import { MENU_LIST } from '../../../constants/queryKeys';
import { Menu, Payload, DropDownOptions } from "../../../constants/staticTypes";
import { moveItemCategory, moveCategoryMenu, copyCategoryMenu, copyItemCategory, getTitleText, getPopoverText } from "../../../helpers/menuManagementHelper";
import { SELECT_MENU, SELECT_CATEGORY, CANCEL, CATEGORY_NAME, MENU_NAME, MOVE, ITEM_NAME, COPY } from "../../../constants/strings";
import { UseMutationResult, useQueryClient } from "react-query";
import Select from 'react-select';
import { JTranslation } from "../../../helpers/jTranslate";
import MlxPopover from "../../common/MlxPopover";

type Props = {
    handleClose: () => void,
    onSuccess: (message: string) => void,
    show: boolean,
    upsertMenu: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
}

function MoveItem({ show, handleClose, upsertMenu, onSuccess }: Props) {
    // CONTEXT VARIABLES
    const { itemToBeMoved, itemToBeMovedDetails, moveType, categoryToBeMoved, categoryToBeMovedDetails } = useContext(FnBCtx)
    // STATE VARIABLES
    const [alertVariant, setVariant] = useState("");
    const [categoryOptions, setCategoryOptions] = useState<DropDownOptions[] | []>([])
    const [errorMessage, setErrorMessage] = useState("");
    const [fullMenuList, setFullMenuList] = useState<Menu[] | []>([]);
    const [menuOptions, setMenuOptions] = useState<DropDownOptions[] | []>([])
    const [selectedCategoryItem, setCategoryMenuItem] = useState<any>("");
    const [selectedMenuItem, setSelectedMenuItem] = useState<any>("");
    const [showAlert, setShow] = useState(false);
    // LOCAL VARIABLES
    const queryClient = useQueryClient();
    const menuListData: any = queryClient.getQueryData(MENU_LIST);

    // on api error
    const onError = (message: string, variant: string) => {
        setVariant(variant);
        setErrorMessage(message);
        setShow(true);
    }

    const moveApiCall = () => {
        if (moveType === "item") {
            moveItemCategory(upsertMenu, {
                itemMenuId: itemToBeMoved,
                newCategoryId: selectedCategoryItem.value,
            }, onSuccess, onError);
        }
        if (moveType === "category") {
            moveCategoryMenu(upsertMenu, {
                categoryId: categoryToBeMoved,
                newMenuId: selectedMenuItem.value,
            }, onSuccess, onError);
        }
        if (moveType === "copy-item-to") {
            copyItemCategory(upsertMenu, {
                itemMenuId: itemToBeMoved,
                newCategoryId: selectedCategoryItem.value,
            }, onSuccess, onError);
        }
        if (moveType === "copy-category-to") {
            copyCategoryMenu(upsertMenu, {
                categoryId: categoryToBeMoved,
                newMenuId: selectedMenuItem.value,
            }, onSuccess, onError);
        }
    }

    const closeAndClearMoveItem = () => {
        handleClose();
        setSelectedMenuItem("");
        setCategoryMenuItem("");
        getMenuOptions();
        setShow(false);
    }

    const getMenuOptions = useCallback(() => {
        let menuData: Menu[] = menuListData?.data?.data as Menu[]
        setMenuOptions(menuData.map(menuItem => ({ value: menuItem.id, label: menuItem.categoryName })))
        setFullMenuList(menuData)
    }, [menuListData])

    const getCategoryOptions = useCallback(() => {
        if (selectedMenuItem?.value) {
            let menuCategoriesCollection: DropDownOptions[] = [];
            let categoriesOfSelectedMenu: Menu[] = fullMenuList.filter((item) => item.id === selectedMenuItem.value);

            categoriesOfSelectedMenu[0]?.subCategory.forEach((category) => {
                menuCategoriesCollection.push({
                    value: category.id,
                    label: category.categoryName,
                });
            });

            setCategoryOptions(menuCategoriesCollection);
        } else {
            setCategoryOptions([]);
        }
    }, [selectedMenuItem, fullMenuList]);

    // EFFECT TO GET CACHED MENU LIST DATA 
    useEffect(() => { getMenuOptions() }, [getMenuOptions, menuListData])

    // EFFECT TO GET THE CATEGORY OPTIONS ONCE A MENU ITEM IS SELECTED
    useEffect(() => { getCategoryOptions() }, [selectedMenuItem, getCategoryOptions])

    // EFFECT TO CLEAR ALL THE DROPDOWN ONCE THE SIDEBAR IS CLOSED
    useEffect(() => {
        setSelectedMenuItem("")
        setCategoryMenuItem("")
    }, [show])


    return (
        <Offcanvas show={show} onHide={() => closeAndClearMoveItem()} placement="end">
            <Offcanvas.Header closeButton className="border-bottom">
                <Offcanvas.Title className="d-flex justify-content-center align-items-center"><JTranslation typeCase="pascal" text={getTitleText(moveType) as string} />
                    <MlxPopover data={getPopoverText(moveType)} />
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="row">
                    <div className="col-md-12  mb-3 breadcrumb-custom">
                        <span>{moveType.includes("item") 
                            ? <JTranslation typeCase="pascal" text={ITEM_NAME} /> 
                            : <JTranslation typeCase="pascal" text={CATEGORY_NAME} />
                        } : </span>
                        <span className="fw-bold">
                            {
                                // @ts-ignore-start
                                moveType.includes("item") ? itemToBeMovedDetails?.itemName : categoryToBeMovedDetails?.categoryName
                                // @ts-ignore-end
                            }
                        </span>

                    </div>
                    <div className="col-md-12  mb-3">
                        <label htmlFor="category" className="form-label"><JTranslation typeCase="pascal" text={MENU_NAME} /><span className="mandatory ">*</span></label>
                        <Select
                            options={menuOptions}
                            placeholder={<JTranslation typeCase="pascal" text={SELECT_MENU} />}
                            isClearable
                            onChange={(e) => {
                                setSelectedMenuItem(e);
                                setCategoryMenuItem("");
                            }}
                            value={selectedMenuItem}
                        />
                    </div>
                    {moveType.includes("item") &&
                        <div className="mb-3">
                            <label className="form-label"><JTranslation typeCase="pascal" text={CATEGORY_NAME} /><span className="mandatory ">*</span></label>
                            <Select
                                options={categoryOptions}
                                placeholder={<JTranslation typeCase="pascal" text={SELECT_CATEGORY} />}
                                isClearable
                                onChange={(e) => setCategoryMenuItem(e)}
                                value={selectedCategoryItem}
                            />
                        </div>}

                </div>
                {showAlert && <div className="row">
                    <div className="col-md-12">
                        <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
                            <p>
                                <JTranslation typeCase="capitalize" text={errorMessage} />
                            </p>
                        </Alert>
                    </div>
                </div>
                }
                <div className="save-btn-section shadow save-btn-absolute">
                    <button className="btn btn-custom-primary-outline" type="button"
                        onClick={() => closeAndClearMoveItem()}><JTranslation typeCase="pascal" text={CANCEL} /></button>
                    <button className="btn btn-custom-primary" type="button"
                        disabled={moveType === "item" ? !selectedMenuItem || !selectedCategoryItem : !selectedMenuItem}
                        onClick={(e) => {
                            e.preventDefault();
                            moveApiCall();
                        }}>{moveType.includes("copy") 
                            ? <JTranslation typeCase="pascal" text={COPY} /> 
                            : <JTranslation typeCase="pascal" text={MOVE} />
                        }</button>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default MoveItem;