import { useState, useContext, useEffect, useCallback } from 'react'
import { ADD_ONS_GROUP_LIST, ADD_ONS_LIST } from '../../constants/queryKeys'
import { Addon, AddonGroup, DeleteAddon, DeleteAddonGroup, ToastMessageProps } from '../../constants/staticTypes'
import { AxiosResponse } from 'axios'
import { CommonCtx } from '../../context/CommonCtxProvider'
import { DELETE_WARNING, MANAGE_ADD_ONS, ADD_ON_HELP } from '../../constants/strings'
import { getAddOnsList, deleteAddOn, initialAddOnDeleteData } from '../../helpers/manageAddOnsHelper'
import { OverlayTrigger } from 'react-bootstrap'
import { popover } from '../../helpers/utils'
import { toastMessageInitialData } from '../../constants/constants'
import { useQueryClient } from 'react-query'
import AddOnsListDataGrid from '../../components/manage_addons/AddOnsListDataGrid'
import AddOnsSideBar from '../../components/manage_addons/AddOnsSideBar'
import Loader from '../../components/loader/Loader'
import ToastAlert from '../../components/alert/ToastAlert'
import useMutationHook from '../../hooks/useMutationHook'
import useQueryHook from '../../hooks/useQueryHook'
import WarningModal from '../../components/warning_modal/WarningModal'
import withSidebar from '../../hoc/withSidebar'
import AddOnsGroupSideBar from '../../components/manage_addons/AddOnsGroupSideBar'
import { deleteAddOnGroup, getAddOnsGroupList, initialAddOnGroupDeleteData } from '../../helpers/manageAddOnsGroupHelper'
import { useLocation } from 'react-router-dom'
import { routes } from '../../constants/routes'
import { JTranslation } from '../../helpers/jTranslate'
import MlxPopover from '../../components/common/MlxPopover'

function ManageAddons() {
    const queryClient = useQueryClient()
    const addOnMutation = useMutationHook(queryClient, true) // addon mutation
    const addOnGroupMutation = useMutationHook(queryClient, true) // addon group mutation
    // CONTEXT VARIABLE
    const { setShowSideBar: setAddOnSideBar, setShowAddOnGroupSideBar: setAddOnGroupSideBar } = useContext(CommonCtx)
    // ALERT VARIABLES
    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
    // WARNING MODAL
    const [showWarning, setWarning] = useState(false) // Warning popup
    const [warningMessage, setWarningMessage] = useState('') // Warning message
    // STATE VARIABLES
    const [addOnList, setAddOnList] = useState<Addon[]>([])
    const [addOnGroupList, setAddOnGroupList] = useState<AddonGroup[]>([])
    const [editAddOnData, setEditAddOn] = useState<Addon>() // edit addOn data
    const [editAddOnGroupData, setEditAddOnGroup] = useState<AddonGroup>() // edit addOn group data
    const [deleteAddOnData, setDeleteAddOn] = useState<DeleteAddon>(initialAddOnDeleteData) // delete addOn data
    const [deleteAddOnGroupData, setDeleteAddOnGroup] = useState<DeleteAddonGroup>(initialAddOnGroupDeleteData) // delete addOn group data
    const [isInitialLoad, setInitialLoad] = useState(true)
    const [activeIndex, setActiveIndex] = useState(0);
    const location = useLocation();

    // fetch add-on/add-on group based on tab change
    const refetchQueriesBasedOnCurrentUrlPath = useCallback(() => {
        const currentPath = location.pathname;
        if (currentPath === routes.manage_addons) {
            queryClient.refetchQueries([ADD_ONS_LIST])
            setActiveIndex(0);
        }
        if (currentPath === routes.manage_addons_groups) {
            queryClient.refetchQueries([ADD_ONS_GROUP_LIST])
            setActiveIndex(1)
        }   
    }, [location.pathname, queryClient]); // checked pathname, since calling same URL, location has diff key
    
    useEffect(() => {
        refetchQueriesBasedOnCurrentUrlPath();
    }, [refetchQueriesBasedOnCurrentUrlPath]);

    // side bar close
    const handleClose = () => setAddOnSideBar(false)
    const handleCloseAddOnGroup = () => setAddOnGroupSideBar(false)

    // addOns list fetch success
    const onAddOnListFetchSuccess = (res: AxiosResponse<any, any>) => {
        setAddOnList(res.data?.data.list)
        setInitialLoad(false)
    }

    // addOns group list fetch success
    const onAddOnGroupListFetchSuccess = (res: AxiosResponse<any, any>) => {
        setAddOnGroupList(res.data?.data.list)
        setInitialLoad(false)
    }

    // fetch addOns list
    const addOnData = useQueryHook(ADD_ONS_LIST, () => getAddOnsList('', 500, ''), onAddOnListFetchSuccess)

    // fetch addOns group list
    const addOnGroupData = useQueryHook(ADD_ONS_GROUP_LIST, () => getAddOnsGroupList('', 500, ''), onAddOnGroupListFetchSuccess)

    if (addOnData.isLoading || addOnGroupData.isLoading || isInitialLoad) {
        return <Loader />
    }

    // on trigger search
    const onSearchTrigger = (searchKey: string) => {
        if (addOnData.isFetching) {
            queryClient.cancelQueries([ADD_ONS_LIST]) // nosonar
        }
        queryClient.fetchQuery(ADD_ONS_LIST, () => getAddOnsList(searchKey, 500, '')) // nosonar
    }

    // on trigger search for add-on group
    const onAddonGroupSearchTrigger = (searchKey: string) => {
        if (addOnGroupData.isFetching) {
            queryClient.cancelQueries([ADD_ONS_GROUP_LIST]) // nosonar
        }
        queryClient.fetchQuery(ADD_ONS_GROUP_LIST, () => getAddOnsGroupList(searchKey, 500, '')) // nosonar
    }

    // on add or update addOn success
    const onSuccess = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
        queryClient.refetchQueries([ADD_ONS_LIST]) // nosonar
    }

    const onAddonGroupSuccess = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
        queryClient.refetchQueries([ADD_ONS_GROUP_LIST]) // nosonar
    }

    // on add or update addOn error
    const onError = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
    }

    // on edit button click
    const onEditClick = (addOn: Addon) => {
        setEditAddOn(addOn)
    }

    // on edit button click for add-on group
    const onAddonGroupEditClick = (addOnGroup: AddonGroup) => {
        setEditAddOnGroup(addOnGroup)
    }

    // on delete button click
    const onDeleteClick = (addOn: Addon) => {
        setDeleteAddOn({ addonId: addOn.id })
        setWarningMessage(DELETE_WARNING)
        setWarning(true)
    }

    // on delete button click for add-on group
    const onAddonGroupDeleteClick = (addOnGroup: AddonGroup) => {
        setDeleteAddOnGroup({ id: addOnGroup.id })
        setWarningMessage(DELETE_WARNING)
        setWarning(true)
    }

    // api call to delete add-on
    const deleteAddOnApiCall = () => {
        deleteAddOn(addOnMutation, deleteAddOnData, onSuccess, onError)
    }

    // api call to delete add-on group
    const deleteAddOnGroupApiCall = () => {
        deleteAddOnGroup(addOnGroupMutation, deleteAddOnGroupData, onAddonGroupSuccess, onError)
    }

    return (
        <>
            <WarningModal
                show={showWarning}
                title={warningMessage}
                onHide={() => {
                    setWarning(false)
                    if(activeIndex===0) {
                        setDeleteAddOn(initialAddOnDeleteData)
                    } else {
                        setDeleteAddOnGroup(initialAddOnGroupDeleteData)
                    }
                }}
                callback={() => {
                    if(activeIndex===0) {
                        deleteAddOnApiCall()
                    } else {
                        deleteAddOnGroupApiCall()
                    }
                    setWarning(false)
                }}
            />

            <ToastAlert
                data-testid="toast"
                message={toastMessage.message}
                onClose={() => setToastMessage(toastMessageInitialData)}
                show={toastMessage.show}
                variant={toastMessage.variant}
            />

            <AddOnsGroupSideBar
                addOnList={addOnList}
                handleClose={handleCloseAddOnGroup}
                editAddonGroup={editAddOnGroupData}
                setEditAddOnGroup={setEditAddOnGroup}
            />

            <AddOnsSideBar
                editAddon={editAddOnData}
                handleClose={handleClose}
                setEditAddOn={setEditAddOn}
            />

            <div className="col-md-12 col-lg-12 mb-3 h-100 ">
                <div className="page-title">
                    <h5 className="d-flex"><JTranslation typeCase="pascal" text={MANAGE_ADD_ONS} />
                        <MlxPopover data={{title:MANAGE_ADD_ONS, body:ADD_ON_HELP}}/>
                    </h5>
                </div>

                <AddOnsListDataGrid
                    addOnList={addOnList}
                    editAddOn={editAddOnData}
                    loading={addOnData.isLoading || addOnGroupData.isLoading}
                    onDeleteClick={onDeleteClick}
                    onEditClick={onEditClick}
                    onSearchTrigger={onSearchTrigger}
                    activeIndex={activeIndex}
                    addOnGroupList={addOnGroupList}
                    editAddOnGroup={editAddOnGroupData}
                    onAddonGroupDeleteClick={onAddonGroupDeleteClick}
                    onAddonGroupEditClick={onAddonGroupEditClick}
                    onAddonGroupSearchTrigger={onAddonGroupSearchTrigger}
                    onAddonGroupSuccess={onAddonGroupSuccess}
                    onError={onError}
                />
            </div>
        </>
    )
}
export default withSidebar(ManageAddons)
