import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { createRoot, Root } from 'react-dom/client'


// Define a type for the roots cache
interface RootsCache {
  [containerId: string]: Root;
}
// Initialize the roots object with the correct type
const roots: RootsCache = {};

export const renderPopup = ({
  visible,
  handleUpdate,
  handleSnooze,
  appVersion = '',
  serverVersion = '',
  loadingRefresh = false,
  loadingSnooze = false,
  popupContainer,
}: {
  visible: boolean
  handleUpdate: () => void
  handleSnooze: () => void
  appVersion: any
  serverVersion: any
  loadingRefresh: any
  loadingSnooze: any
  popupContainer: any
}) => {
  // Use the container's ID or another unique identifier as a key
  const containerId = popupContainer.id || 'defaultPopupContainer'

  // Check if a root already exists for this container
  if (!roots[containerId]) {
    // If not, create a new root and store it
    roots[containerId] = createRoot(popupContainer)
  }
  // const root = createRoot(popupContainer)

  // Use the stored root to render the component
  roots[containerId].render(
    <UpdateNotificationPopup
      visible={visible}
      handleUpdate={handleUpdate}
      handleSnooze={handleSnooze}
      appVersion={appVersion}
      serverVersion={serverVersion}
      loadingRefresh={loadingRefresh}
      loadingSnooze={loadingSnooze}
    />
  )
}

export const compareVersions = (version1: any, version2: any) => {
  const parts1 = version1.split('.').map(Number)
  const parts2 = version2.split('.').map(Number)

  const maxLength = Math.max(parts1.length, parts2.length)

  for (let i = 0; i < maxLength; i++) {
    const part1 = parts1[i] || 0
    const part2 = parts2[i] || 0

    if (part1 > part2) {
      return 1 // version1 is greater
    } else if (part1 < part2) {
      return -1 // version2 is greater
    }
  }

  return 0 // version1 and version2 are equal
}

const footer = ({handleSnooze,loadingSnooze,handleUpdate,loadingRefresh}:any)=>(
  <div className='updateFooter'>
        <Button
          label='Snooze (15 mins)'
          onClick={handleSnooze}
          className='btn-custom-primary-outline p-button-sm'
          icon='ri-alarm-line'
          type='button'
          style={{ marginRight: '1rem' }}
          loading={loadingSnooze}
          //   severity='warning'
          // outlined={true}
        />
         <Button
          label='Update'
          onClick={handleUpdate}
          icon="ri-loop-right-line"
          className='btn-custom-primary p-button-sm'
          loading={loadingRefresh}
        />
  </div>
)

export const UpdateNotificationPopup = ({
  visible,
  handleUpdate,
  handleSnooze,
  appVersion = '',
  serverVersion = '',
  loadingRefresh = false,
  loadingSnooze = false,
}: {
  visible: boolean
  handleUpdate: () => void
  handleSnooze: () => void
  appVersion: any
  serverVersion: any
  loadingRefresh: any
  loadingSnooze: any
}) => (
  <Dialog
    visible={visible}
    onHide={() => {}}
    header='Update Available'
    footer={footer({
      handleSnooze,
      loadingSnooze,
      handleUpdate,
      loadingRefresh,
    })}
    modal
    closable={false}
    closeOnEscape={false}
    maskStyle={{ background: 'rgba(0, 0, 0, 0.5)' }}
    //style={{ width: '25vw' }}
    breakpoints={{'10000000px' : '35vw', '1730px': '35vw', '985px': '45vw', '964px': '45vw', '766px': '65vw', '641px': '80vw', '440px': '98vw' }}
    className='updateAvailable'
  >
    {/* visible={visible}
    onHide={handleSnooze}
    header='Update Available'
    footer={null} */}
    <div style={{ fontSize: '1.1rem', textAlign: 'center' }}>
      <p style={{ marginBottom: '1rem' }}>
        Level up with the latest update! Click <strong>“Update”</strong> to
        unlock exciting new features and enhancements. Want to delay the
        excitement? Hit “Snooze” to be reminded later.
      </p>

      {/* <br />
        Current Version: {appVersion ? appVersion : 'N/A'}
        <br />
        New Version: {serverVersion ? serverVersion : 'N/A'} */}
    </div>
  </Dialog>
)
