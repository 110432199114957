// This component is used to display an alert when there is a network issue
// An alert notification is created with primereact toast component
// When the system goes offline, an offline alert pops up which is not closable. As soon as the network is up, an online alert pops up which is closable.

import { Toast, ToastProps } from 'primereact/toast';
import React, { useEffect, useState } from 'react';
import { JTranslation } from '../../helpers/jTranslate';

interface Props extends ToastProps {
  networkToastRef: React.RefObject<Toast>;
}

const NetworkAlertToast: React.FC<Props> = ({ networkToastRef, ...toastProps }) => {
  const [previousStatus, setPreviousStatus] = useState<boolean | null>(null);

  useEffect(() => {
    const handleNetworkChange = () => {
      const online = navigator.onLine;

      if (networkToastRef.current) {
        if (previousStatus !== null && previousStatus !== online) {
          if (online) {
            // Clear existing offline toast before showing the online toast
            networkToastRef.current.clear();
            
            // Use a timeout to ensure the toast is visible for the desired time
            setTimeout(() => {
              networkToastRef.current?.show({
                severity: 'success',
                life: 3000,
                content: onlineContent(),
                className: 'online-toast-wrapper'
              });
            }, 500); // Small delay to allow clear to process
          } else {
            // Clear existing toasts before showing the offline toast
            networkToastRef.current.clear();
            setTimeout(() => {
              networkToastRef.current?.show({
                severity: 'error',
                sticky: true, // Make the offline toast persistent
                closable: false,
                content: offlineContent(),
                className: 'offline-toast-wrapper'
              });
            }, 500) // Small delay to allow clear to process
          }
        }
      }

      setPreviousStatus(online);
    };

    window.addEventListener('online', handleNetworkChange);
    window.addEventListener('offline', handleNetworkChange);

    // Initial status check
    handleNetworkChange();

    return () => {
      window.removeEventListener('online', handleNetworkChange);
      window.removeEventListener('offline', handleNetworkChange);
    };
  }, [previousStatus, networkToastRef]);

  const onlineContent = () => {
    return(
      <div className='online-toast d-flex justify-content-between'>
        <i className="ri-wifi-line me-2"></i>
        <span className="online-title me-1"><JTranslation typeCase="none" text={"You're online now!"} /></span>
        <span className="network-detail"><JTranslation typeCase="none" text={"Internet is connected"} /></span>
      </div>
    )
  }

  const offlineContent = () => {
    return(
      <div className='offline-toast d-flex justify-content-between'>
        <i className="ri-wifi-off-line me-2"></i>
        <span className="offline-title me-1"><JTranslation typeCase="none" text={"You're offline!"} /></span>
        <span className="network-detail"><JTranslation typeCase="none" text={"Please check your connection"} /></span>
      </div>
    )
  }

  return <Toast ref={networkToastRef} className='network-toast' position="bottom-center" {...toastProps} />;
};

export default NetworkAlertToast;
