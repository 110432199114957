import React from 'react';
import SnowflakeEffect from './christmas/SnowFlakeEffect';
import SantaSleigh from './christmas/SantaSleigh';
import {
  getChristmasEffects,
  getValentinesDayEffects,
  getStPatricksDayEffects,
  getEasterDayEffects,
  getCincoDeMayoDayEffects,
  getMothersDayEffects,
} from './celebrationsConfigs';
import HeartFallingEffect from './valentinesDay/HeartFallingEffect';
import CupidAnimation from './valentinesDay/CupidAnimation';
import GoldFallingEffect from './stPatricksDay/GoldFallingEffect';
import AnimatedCharacter from './stPatricksDay/AnimatedCharacter';
import EggFallingEffect from './easter/EggFallingEffect';
import FlowerFallingEffect from './mothersDay/FlowerFallingEffect';
import AnimatedEasterCharacter from './easter/AnimatedCharacter';
import AnimatedCincoDeMayoCharacter from './cincoDeMayo/AnimatedCharacter';
import MothersDayCharacter from './mothersDay/AnimatedCharacter';
import { useQueryClient } from 'react-query';
import { BRANDING_INFO } from '../../constants/queryKeys';
import { BrandingData } from '../../constants/staticTypes';
import { useLocation } from 'react-router-dom';
import {
  FESTIVAL_TEMPLATES,
  FESTIVAL_THEME_PAGE_CONFIG,
} from '../../constants/constants';

const SpecialDayEffect: React.FC = () => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const branding = queryClient.getQueryData<BrandingData>(BRANDING_INFO);

  const christmasEffect = getChristmasEffects();
  const valentinesDayEffect = getValentinesDayEffects();
  const stPatricksDayEffect = getStPatricksDayEffects();
  const easterEffect = getEasterDayEffects();
  const cincoDeMayoDayEffect = getCincoDeMayoDayEffects();
  const mothersDayEffect = getMothersDayEffects();

  if (branding?.enableCelebrationEffect) {
    // if not in welcome page
    if (
      location.pathname === '/welcome' &&
      !branding?.showCelebrationTemplateOn?.includes(
        FESTIVAL_THEME_PAGE_CONFIG.WELCOME_PAGE
      )
    ) {
      return null;
    }

    // if not in all other pages
    if (
      location.pathname !== '/welcome' &&
      !branding?.showCelebrationTemplateOn?.includes(
        FESTIVAL_THEME_PAGE_CONFIG.ALL_OTHER_PAGES
      )
    ) {
      return null;
    }

    switch (branding?.activeCelebrationTemplate) {
      case FESTIVAL_TEMPLATES.CHRISTMAS_NEWYEAR:
        return (
          <>
            {christmasEffect?.showEffects && (
              <SnowflakeEffect christmasEffect={christmasEffect} />
            )}
            <SantaSleigh christmasEffect={christmasEffect} />
          </>
        );
      case FESTIVAL_TEMPLATES.VALENTINES_DAY:
        return (
          <>
            {valentinesDayEffect?.showEffects && <HeartFallingEffect />}
            {valentinesDayEffect?.showCupid && <CupidAnimation />}
          </>
        );
      case FESTIVAL_TEMPLATES.ST_PATRICKS_DAY:
        return (
          <>
            {stPatricksDayEffect?.showEffects && <GoldFallingEffect />}
            {stPatricksDayEffect?.showAnimatedCharacter && (
              <AnimatedCharacter />
            )}
          </>
        );
      case FESTIVAL_TEMPLATES.EASTER:
        return (
          <>
            {easterEffect?.showEffects && <EggFallingEffect />}
            {easterEffect?.showAnimatedCharacter && <AnimatedEasterCharacter />}
          </>
        );
      case FESTIVAL_TEMPLATES.CINCO_DEMAYO:
        return (
          <>
            {cincoDeMayoDayEffect?.showAnimatedCharacter && (
              <AnimatedCincoDeMayoCharacter />
            )}
          </>
        );
      case FESTIVAL_TEMPLATES.MOTHERS_DAY:
        return (
          <>
            {mothersDayEffect?.showEffects && <FlowerFallingEffect />}
            {mothersDayEffect?.showAnimatedCharacter && <MothersDayCharacter />}
          </>
        );
      default:
        return null;
    }
  }

  return null;
};

export default SpecialDayEffect;
