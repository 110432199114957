import withSidebar from "../../hoc/withSidebar";
import { useState } from 'react';
import useQueryHook from "../../hooks/useQueryHook";
import { GUEST_LIST } from "../../constants/queryKeys";
import { getGuestInfoList, initialPaginationData } from "../../helpers/guestInfoListHelper";
import { AxiosResponse } from "axios";
import { GuestInfo, PaginationData } from "../../constants/staticTypes";
import Loader from "../../components/loader/Loader";
import GuestInfoListGrid from "../../components/guest_info/GuestInfoListGrid";
import { useQueryClient } from "react-query";
import { HELP_GUEST_INFO, GUEST_INFO } from "../../constants/strings";
import {popover } from '../../helpers/utils';
import { OverlayTrigger } from "react-bootstrap";
import { JTranslation } from "../../helpers/jTranslate";
import MlxPopover from "../../components/common/MlxPopover";

function GuestInfoList() {
    const queryClient = useQueryClient();
    const [isInitialLoad, setInitialLoad] = useState(true);
    const [paginationData, setPaginationData] = useState<PaginationData>(initialPaginationData);
    const [guestList, setGuestList] = useState<GuestInfo[]>([]);

    // users list fetch success
    const onGuestListFetchSuccess = (res: AxiosResponse<any, any>) => {
        setGuestList(res.data?.data.list);
        setPaginationData(res.data?.data.paginationData);
        setInitialLoad(false);
    }

    // fetch guests list
    const guestData = useQueryHook(GUEST_LIST,
        () => getGuestInfoList(queryClient, "", 500, paginationData.lastKey), onGuestListFetchSuccess);

    if (guestData.isLoading || isInitialLoad) {
        return <Loader />
    }

    // on trigger search
    const onSearchTrigger = (searchKey: string) => {
        if (guestData.isFetching) {
            queryClient.cancelQueries([GUEST_LIST]);
        }
        queryClient.fetchQuery(GUEST_LIST, () => getGuestInfoList(queryClient, searchKey, 500, ""));
    }

    return (
        <>
            <div className="col-md-12 col-lg-12 mb-3 h-100 ">
                <div className="page-title">
                    <h5 className="d-flex"><JTranslation typeCase="pascal" text={"Guest Information"} />
                        <MlxPopover data={{title:GUEST_INFO, body:HELP_GUEST_INFO}}/>
                    </h5>
                </div>

                <GuestInfoListGrid guests={guestList} loading={guestData.isLoading}
                    onSearchTrigger={onSearchTrigger} />
            </div>
        </>
    )
}
export default withSidebar(GuestInfoList);