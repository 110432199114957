import { ChonkyActions, FileHelper } from 'chonky'
import { downloadMultipleFilesAndFolders } from '../../../helpers/multiFileHelper'
import { moveFileApi, moveFolderApi } from '../../../helpers/folderUploadHelper'
import { NEW_FOLDER, RENAME_FOLDER, RENAME_FILE } from '../../../constants/strings'
import { renameFolderAction, uploadFileAction } from './customActions'
import { ROOT_FOLDER } from '../../../constants/constants'

import {
    ApiFolderAndFileDS,
    DownloadZip,
    DownloadZipApiResponse,
    HandleActionProps,
    MoveFileOnDb,
    MoveFolderOnDb,
    ZipItem,
} from '../../../constants/staticTypes'

import {
    getIntegerFileId,
    getParentFolderId,
    getSelectedFileName,
    getSelectedFileUrl,
    getZipItems,
    openFileExplorer,
    openFileInNewTab,
} from './fileManagerHelper'

const HandleAction = ({
    createFolder,
    data,
    download,
    fileManagerMutation,
    inputFile,
    modalInputText,
    moveFiles,
    renameFile,
    renameFolder,
    tempFile,
    updateDocToBeViewed,
    changeDragAndDropDetails,
    onError,
    setCurrentFolderId,
    setCurrentName,
    setErrorMessage,
    setModal,
    setModalTitle,
    setSelectedFilesForAction,
    setShowAlert,
    setShowDocViewer,
    setTempFile,
    setVariant,
    setWarning,
}: HandleActionProps) => {
    switch (data.id) {
        case ChonkyActions.ChangeSelection.id:
            // assigning data to external variables to be accessed by the common modal
            data.state.selectedFiles.length && setTempFile(data.state.selectedFiles)
            break
        case ChonkyActions.OpenFiles.id:
            const { targetFile, files } = data.payload
            const fileToOpen = targetFile ?? files[0]
            if (fileToOpen && FileHelper.isDirectory(fileToOpen)) {
                setCurrentFolderId(fileToOpen.id)
                return
            }
            break

        case ChonkyActions.DeleteFiles.id:
            setSelectedFilesForAction(data.state.selectedFilesForAction)
            setWarning(true)
            break

        case ChonkyActions.MoveFiles.id:
            moveFiles(data.payload.files, data.payload.source!, data.payload.destination)
            break

        case ChonkyActions.CreateFolder.id:
            // open modal popup for new folder
            setModalTitle(NEW_FOLDER)
            setModal(true)
            if (modalInputText) createFolder(modalInputText)
            break

        case renameFolderAction.id:
            setCurrentName(data.state.selectedFiles[0].name)
            let isDir = data.state.selectedFiles[0].isDir
            if (isDir) {
                // open modal for folder rename
                setModalTitle(RENAME_FOLDER)
                setModal(true)
                renameFolder({ files: tempFile, folderName: modalInputText })
            } else {
                // open modal for file rename
                setModalTitle(RENAME_FILE)
                setModal(true)
                renameFile({ files: tempFile, fileName: modalInputText })
            }
            break

        case uploadFileAction.id:
            // open file upload window
            openFileExplorer(inputFile)
            break

        case ChonkyActions.OpenSelection.id:
            // open file on a document viewer
            const selectedFile = data.state.selectedFiles[0]
            let signedUrl = getSelectedFileUrl(selectedFile as ApiFolderAndFileDS)
            // updates variables and opens the document viewer modal
            updateDocToBeViewed({
                doc: [{ uri: signedUrl }],
                fileName: selectedFile.name,
            })
            setShowDocViewer(true)
            break

        case ChonkyActions.DownloadFiles.id:
            // download file on a new tab
            let selectedFiles: ApiFolderAndFileDS[] = data.state.selectedFiles
            let fileUrl = getSelectedFileUrl(selectedFiles[0])
            let name = getSelectedFileName(selectedFiles[0])

            // switch api for single and multiple file download
            if (selectedFiles.length > 1 || (selectedFiles.length === 1 && selectedFiles[0].isDir === true)) {
                let zipItems: ZipItem[] = getZipItems(selectedFiles)
                let itemsForDownload: DownloadZip = { items: zipItems }
                const onZipDownloadSuccess = (message: string, data: DownloadZipApiResponse) => {
                    openFileInNewTab(data.fileUrlSigned)
                }

                const onZipDownloadError = (error: string, variant: string) => {
                    setVariant(variant)
                    setErrorMessage(error)
                    setShowAlert(true)
                }

                // api call to get zip file's signerURL
                downloadMultipleFilesAndFolders(
                    fileManagerMutation,
                    itemsForDownload,
                    onZipDownloadSuccess,
                    onZipDownloadError
                )
            } else {
                download(fileUrl, name)
            }
            break

        case ChonkyActions.EndDragNDrop.id:
            // drag and drop files and folders
            const { destination, draggedFile } = data.payload
            // saving details to stop folder name duplication
            changeDragAndDropDetails({ destination, draggedFile }) // eslint-disable-line
            if (draggedFile.isDir) {
                let data: MoveFolderOnDb
                if (
                    typeof destination.id === 'string' &&
                    destination.id !== ROOT_FOLDER &&
                    typeof draggedFile.id === 'number'
                ) {
                    data = {
                        folderId: draggedFile.id,
                        parentFolderUuid: getParentFolderId(destination.id),
                    }
                } else if (
                    typeof destination.id === 'number' &&
                    typeof draggedFile.id === 'string' &&
                    draggedFile.id !== ROOT_FOLDER
                ) {
                    data = {
                        folderUuid: draggedFile.id,
                        parentFolderId: getParentFolderId(destination.id),
                    }
                } else if (typeof draggedFile.id === 'number' && typeof destination.id === 'string') {
                    data = {
                        folderId: draggedFile.id,
                        parentFolderUuid: getParentFolderId(destination.id),
                    }
                } else if (typeof draggedFile.id === 'string' && typeof destination.id === 'number') {
                    data = {
                        folderUuid: draggedFile.id,
                        parentFolderId: getParentFolderId(destination.id),
                    }
                } else if (typeof destination.id === 'string' && typeof draggedFile.id === 'string') {
                    data = {
                        folderUuid: draggedFile.id,
                        parentFolderUuid: getParentFolderId(destination.id),
                    }
                } else {
                    data = {
                        folderId: draggedFile.id,
                        parentFolderId: getParentFolderId(destination.id),
                    }
                }
                // api call to move folder
                moveFolderApi(fileManagerMutation, data, onError)
            } else {
                let data: MoveFileOnDb
                if (typeof destination.id === 'string' && destination.id !== ROOT_FOLDER) {
                    data = {
                        fileId: getIntegerFileId(draggedFile),
                        parentFolderUuid: destination.id,
                    }
                } else {
                    data = {
                        fileId: getIntegerFileId(draggedFile),
                        parentFolderId: getParentFolderId(destination.id),
                    }
                }
                // api call to move file
                moveFileApi(fileManagerMutation, data, onError)
            }
            break

        case ChonkyActions.MouseClickFile.id:
            const { clickType, file } = data.payload
            if (clickType === 'double' && !file.isDir) {
                // updates variables and opens the document viewer modal
                updateDocToBeViewed({
                    doc: [{ uri: file.signedUrl }],
                    fileName: file.name,
                })
                setShowDocViewer(true)
            }
            break

        default:
            break
    }
}

export default HandleAction
