import { QueryClient } from "react-query";
import { guestListApi } from "../constants/apiEndPoints"
import { HttpMethods } from "../constants/constants"
import HttpServiceHelper from "./httpServiceHelper"

// fetch all guests
export const getGuestInfoList = (queryClient: QueryClient, search: string, limit: number, lastKey: string | null) => {
    return HttpServiceHelper({
        url: guestListApi + "?search=" + search + "&limit=" + limit + "&lastKey=" + lastKey,
        method: HttpMethods.GET,
        queryClient
    });
}

// initial pagination data
export const initialPaginationData = {
    Count: 1,
    ScannedCount: 1,
    lastKey: ""
}