import { QueryClient } from 'react-query'
import {
	markAllAsReadNotificationsApi,
	markAsReadNotificationsApi,
	notificationUnreadListApi,
	notificationsListApi,
} from '../constants/apiEndPoints'
import { HttpMethods } from '../constants/constants'
import HttpServiceHelper from './httpServiceHelper'
import { GetNotificationsApiParams, NotificationObject } from '../constants/staticTypes'

// initial pagination dataḌ
export const initialNotificationPaginationData = {
	Count: 1,
	ScannedCount: 1,
	lastKey: '',
}

export const getIconName = (type: NotificationObject['topic']) => {
	switch (type) {
		case 'time-off-admin':
		case 'time-off-user':
			return 'ri-rest-time-line'
		case 'availability-admin':
		case 'availability-user':
			return 'ri-list-check-3'
		case 'news':
			return 'ri-newspaper-line'
		case 'event':
			return 'ri-calendar-event-line'
		case 'shift-swap-list':
		case 'shift-swap-schedule':
			return 'ri-arrow-left-right-line'
		default:
			return 'pi pi-bell'
	}
}

// fetch all notifications sorted by publish date
export const getNotificationListView = (queryClient: QueryClient, params: GetNotificationsApiParams, id: string) => {
	const { search, limit, lastKey } = params
	return HttpServiceHelper({
		url: notificationsListApi + '?search=' + search + '&limit=' + limit + '&lastKey=' + lastKey,
		method: HttpMethods.POST,
		queryClient,
		data: {
			id,
		},
	})
}

// fetch all notifications unread
export const getUnreadNotificationListView = (queryClient: QueryClient, id: string) => {
	return HttpServiceHelper({
		url: notificationUnreadListApi,
		method: HttpMethods.POST,
		queryClient,
		data: {
			id,
		},
	})
}

// mark all as read
export const markAllNotificationsAsRead = (queryClient: QueryClient, staffId: string, readStatus = true) => {
	return HttpServiceHelper({
		url: markAllAsReadNotificationsApi,
		method: HttpMethods.PUT,
		queryClient,
		data: {
			staffId,
			readStatus,
		},
	})
}

// mark  as read
export const markNotificationAsRead = (
	queryClient: QueryClient,
	params: {
		staffId: string
		notificationIds: string[]
		readStatus: boolean
	}
) => {
	return HttpServiceHelper({
		url: markAsReadNotificationsApi,
		method: HttpMethods.PUT,
		queryClient,
		data: params,
	})
}

export const getNavigateUrl = (notification: NotificationObject) => {
	switch (notification.topic) {
		case 'time-off-user':
			return '/time-off'
		case 'time-off-admin':
			return '/time-off-requests'
		case 'availability-admin':
			return '/availability-list'
		case 'availability-user':
			return '/availability'
		case 'holiday':
			return `/holidays`
		case 'news':
		case 'event':
			return `/news-and-events/`
		case 'shift-user':
			return `/staff-schedule-view/`
		case 'shift-swap-list':
			return `/shift-swap/requests/`
		case 'shift-swap-schedule':
			return `/shift-swap/`
		default:
			return ''
	}
}
