import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { SURVEY_CATEGORY_TYPES } from "../../../constants/constants";
import { SurveyCategories } from "../../../constants/staticTypes";
import { ADD_QUESTION, CATEGORY_TYPE, HELP_ADD_QUESTION, CATEGORY_NAME } from "../../../constants/strings";
import { SurveySetDeleteType } from "./AddQuestionCategory";
import CategoryQuestions from "./CategoryQuestions";
import { CSS } from '@dnd-kit/utilities';
import { useRef } from "react";
import { OverlayTrigger } from "react-bootstrap";
import { popover } from '../../../helpers/utils';
import { JTranslation } from "../../../helpers/jTranslate";
import { useTranslation } from "../../../hooks/useTranslation";
import { Panel } from 'primereact/panel';
import MlxPopover from "../../common/MlxPopover";

type Props = {
    categories: SurveyCategories[],
    setCategories: React.Dispatch<React.SetStateAction<SurveyCategories[]>>,
    category: SurveyCategories,
    index: number,
    setDeleteCatIndex: React.Dispatch<React.SetStateAction<number>>,
    setDeletetype: React.Dispatch<React.SetStateAction<string>>,
    setWarning: React.Dispatch<React.SetStateAction<boolean>>,
    setDeleteQuestionIndex: React.Dispatch<React.SetStateAction<number>>,
    accordionActiveKey: string[],
    setAccordionActiveKey: React.Dispatch<React.SetStateAction<string[]>>,
    setOnBlur: React.Dispatch<React.SetStateAction<string[]>>,
    blurredCategories: string[],
    activeIndex: number,
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>
}

function Categories({ categories, setCategories, category, index, setDeleteCatIndex, setDeletetype, setOnBlur,
    blurredCategories, setWarning, setDeleteQuestionIndex, accordionActiveKey, setAccordionActiveKey, activeIndex, setActiveIndex }: Props) {
    const inputRef = useRef<HTMLInputElement>(null);

    // drag end handler
    const handleQuestionDragEnd = (event: DragEndEvent, cat: SurveyCategories) => {
        const { active, over } = event;
        const oldIndex = active?.data.current && active.data.current.sortable.index;
        const newIndex = over?.data.current && over?.data.current.sortable.index;
        let sortedList = arrayMove(cat.surveyQuestions.filter(qstn => qstn.isDeleted === false), oldIndex, newIndex);
        const catIndex = categories.indexOf(cat);
        categories.splice(catIndex, 1, {
            ...cat,
            surveyQuestions: [...sortedList]
        });
        setCategories([...categories]);
    }

    // drag n drop sensors
    const sensors = useSensors(useSensor(PointerSensor, {
        activationConstraint: {
            distance: 2
        }
    }));

    // on category change / update
    const onSurveyCategoryChange = (cat: SurveyCategories, key: string, value: string | boolean) => {
        const catIndex = categories.indexOf(cat);
        categories.splice(catIndex, 1, {
            ...cat,
            [key]: value
        });
        setCategories([...categories]);
    }

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition } = useSortable({ id: category.id as string });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        touchAction: "none"
    };

    const handlePanelToggle = (newIndex: number) => {
        setActiveIndex(prevIndex => (prevIndex === newIndex ? -1 : newIndex));
    };

    return <Panel style={style}
        key={index}
        className="custom-panel"
        toggleable
        collapsed ={index !== activeIndex}
        onToggle={() => handlePanelToggle(index)}
        expandIcon={()=><i className="pi pi-chevron-down"></i>}
        collapseIcon={()=><i className="pi pi-chevron-up"></i>}
        header = {
            <div className="custom-primary-header mb-0">
                <div className="d-flex align-items-center justify-center flex-grow-0">
                <div className="flex-grow-0 me-2 d-flex align-items-center">
                    <div className="drag-ic" ref={setNodeRef} {...attributes} {...listeners}>
                        <i className="ri-grid-fill"></i>
                    </div>
                </div>
                </div>
                <div className="d-flex flex-wrap gap-2 flex-grow-1">
                <div className="flex-grow-1 d-flex flex-row align-items-center flex-wrap me-3">
                    <label htmlFor="category-name" className="form-label"><JTranslation typeCase="pascal" text={CATEGORY_NAME} /><span className="mandatory "> *</span></label>
                    <input className="form-control" type="text" id="category-name" ref={inputRef} autoComplete="off"
                        placeholder={useTranslation("Type your category name", 'capitalize')}
                        maxLength={200}
                        data-testid="category-name"
                        autoFocus={category && category.surveyCategoryName === "" &&
                            !blurredCategories.includes(category.id as string)}
                        onBlur={() => {
                            if (category.id && !blurredCategories.includes(category.id)) {
                                setOnBlur([...blurredCategories, category.id as string]);
                            }
                        }}
                        value={category.surveyCategoryName}
                        onChange={(e) => {
                            onSurveyCategoryChange(category, "surveyCategoryName", e.target.value);
                        }}
                        onClick={(e) => e.stopPropagation()}
                        aria-label="default input example"></input>
                </div>
                <div className="flex-grow-0">
                    <div className="">
                        <label htmlFor="category-type" className="form-label"><JTranslation typeCase="pascal" text={CATEGORY_TYPE} /><span className="mandatory "> *</span></label>
                        <select className="form-select" aria-label="Default select example"
                            onClick={(e) => e.stopPropagation()}
                            value={category.surveyCategoryType}
                            data-testid="category-type-select"
                            onChange={(e) => {
                                onSurveyCategoryChange(category, "surveyCategoryType", e.target.value);
                            }}>
                            <option value="" disabled>{useTranslation('Select', 'pascal')}</option>
                            {Object.keys(SURVEY_CATEGORY_TYPES).map(catType => {
                                return <option value={catType}>{SURVEY_CATEGORY_TYPES[catType]}</option>
                            })}
                        </select>
                    </div>
                </div>

                <div className="flex-grow-1 d-flex justify-content-end align-items-center">
                    <span className="me-4 d-flex justify-content-center">
                        <div className="form-check form-switch mb-0 d-flex align-items-center">
                            <input className="form-check-input" autoComplete="off"
                                type="checkbox" role="switch" id="flexSwitchCheckDefault"
                                checked={category.isActive}
                                data-testid="enable-switch"
                                onChange={() => {
                                    onSurveyCategoryChange(category, "isActive", !category.isActive);
                                }}
                                onClick={(e) => e.stopPropagation()} />
                            <label className="form-check-label ms-2 line-height-0"><JTranslation typeCase="pascal" text={"Enable"} /></label>
                        </div>
                    </span>

                    <span className="me-4 delete-round"
                        data-testid="delete-category"
                        onClick={(e) => {
                            e.stopPropagation();
                            setDeleteCatIndex(categories.indexOf(category));
                            setDeletetype(SurveySetDeleteType.DEL_CATEGORY);
                            setWarning(true);
                        }}>
                        <i className="ri-delete-bin-line delete "></i>
                    </span>
                </div>
                </div>             
            </div>
        }
    >
        <div className="d-flex flex-column">
                <div className="flex-grow-0 categoryBox-header mb-2 sticky-add-btn">
                    <div className="row">
                        <div className="col-md-6 d-flex">
                            <button className="btn btn-custom-primary-outline "
                                onClick={() => {
                                    const catIndex = categories.indexOf(category);
                                    const existingQuestions = category.surveyQuestions ?
                                        category.surveyQuestions : [];
                                    categories.splice(catIndex, 1, {
                                        ...category,
                                        surveyQuestions: [...existingQuestions, {
                                            id: "id" + Math.random().toString(16).slice(2),
                                            surveyQuestionName: "",
                                            surveyQuestionType: "starRating",
                                            isRequired: false,
                                            isActive: true,
                                            isDeleted: false
                                        }]
                                    });
                                    setCategories([...categories]);
                                }}
                                type="button" data-testid="add-question" ><JTranslation typeCase="pascal" text={ADD_QUESTION} /></button>

                            <MlxPopover data={{title:ADD_QUESTION, body:HELP_ADD_QUESTION}}/>
                        </div>
                    </div>
                </div>
                <div className="flex-grow-1 ">
                    <div className="row">
                        {/* <!-------questions------> */}
                        {category.surveyQuestions &&
                            category.surveyQuestions.filter(qstn => qstn.isDeleted === false).length > 0 &&
                            <DndContext
                                sensors={sensors}
                                collisionDetection={closestCenter}
                                onDragEnd={(e) => handleQuestionDragEnd(e, category)}>
                                <SortableContext items={category.surveyQuestions.filter(qstn => qstn.isDeleted === false)
                                    .map(qstn => qstn.id as string)}
                                    strategy={verticalListSortingStrategy}>
                                    {category.surveyQuestions.filter(qstn => qstn.isDeleted === false)
                                        .map((categoryQuestion, qIndex) => {
                                            return <CategoryQuestions catIndex={categories.indexOf(category)}
                                                categories={categories} setCategories={setCategories} key={qIndex}
                                                setDeleteCatIndex={setDeleteCatIndex} setDeletetype={setDeletetype}
                                                setDeleteQuestionIndex={setDeleteQuestionIndex} setWarning={setWarning}
                                                deleteType={SurveySetDeleteType.DEL_QUESTION} category={category}
                                                qstnIndex={category.surveyQuestions.indexOf(categoryQuestion)}
                                                categoryQuestion={categoryQuestion} />
                                        })}
                                </SortableContext>
                            </DndContext>}
                        {/* <!-------questions end------> */}
                    </div>
                </div>
            </div>
    </Panel>
}

export default Categories;