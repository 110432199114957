import type React from 'react';
import { useState, useEffect, useRef } from 'react';
import Lottie from 'react-lottie';
import './AnimatedCharacter.css';
import { getCincoDeMayoDayEffects } from '../celebrationsConfigs';

const AnimatedCharacter: React.FC = () => {
  const cincoDeMayoDayEffects = getCincoDeMayoDayEffects();
  const [animationData, setAnimationData] = useState(null);
  const [playing, setPlaying] = useState(false);

  const audioRef = useRef(new Audio(cincoDeMayoDayEffects?.chimeUrl || ''));

  useEffect(() => {
    // Fetch the animation data
    fetch(cincoDeMayoDayEffects?.animatedCharacterUrl || '')
      .then((response) => response.json())
      .then((data) => setAnimationData(data))
      .catch((error) =>
        console.error('Error loading the animation data:', error)
      );

    // Configure the audio to loop
    audioRef.current.loop = true;

    // Cleanup function to handle component unmount
    return () => {
      audioRef.current.pause();
      audioRef.current.src = ''; // Helps release the audio resource
    };
  }, [
    cincoDeMayoDayEffects?.animatedCharacterUrl,
    cincoDeMayoDayEffects?.chimeUrl,
  ]); // Dependencies that could affect effect execution

  const togglePlay = () => {
    // Toggle the playing state and manage audio accordingly
    if (playing) {
      audioRef.current.pause();
    } else {
      audioRef.current
        .play()
        .catch((error) => console.error('Error playing audio:', error));
    }
    setPlaying(!playing);
  };

  const togglePause = () => {
    // Toggle the paused state to control animation
    const characterElement = document.querySelector(
      '.animation-container-character-cincodemayo'
    );
 
    if (characterElement) {
      // characterElement.classList.toggle('paused');
      if (!playing) {
        characterElement.classList.add('paused'); // Add paused class if not playing
      } else {
        characterElement.classList.remove('paused'); // Remove paused class if playing
      }
    }
  };

  // Handle loading state
  if (!animationData) {
    return <></>; // Provides feedback while loading
  }

  // Configuration for the Lottie animation
  const defaultOptions = {
    loop: true,
    autoplay: false, // Playback is manually controlled
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div
      className={`animation-container-character-cincodemayo ${
        playing ? 'animate' : 'paused'
      }`}
      style={{ position: 'fixed', bottom: '0', left: '0' }}
      onClick={() => {
        togglePlay();
        togglePause();
      }}
    >
      <Lottie
        options={defaultOptions}
        // height={80}
        // width={80}
        isPaused={!playing}
        isStopped={!playing}
      />
    </div>
  );
};

export default AnimatedCharacter;
