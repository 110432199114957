import { QueryClient, useMutation } from 'react-query';
import HttpServiceHelper from '../helpers/httpServiceHelper';
import { showLoader } from '../helpers/utils';

/**
 * Custom Hook For Mutation
 */
const useMutationHook = (queryClient: QueryClient, show = false) =>
  useMutation(HttpServiceHelper, {
    onMutate: () => { showLoader(queryClient, show) },
    onSettled: () => { showLoader(queryClient, false) },
  });
export default useMutationHook;
