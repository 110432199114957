import { useEffect, useState, useContext } from 'react';
import _ from "lodash";
import { AlertVariant, FormClass, SPECIAL_MENU_CODE } from "../../constants/constants";
import { AxiosResponse } from "axios";
import { BACK, FILL_REQUIRED_FIELDS, FILL_SURVEY, NEXT, SUBMIT, YOU_WILL_GET_EXCITING_OFFERS } from "../../constants/strings";
import { getApiFormattedSurveyFormData, getSurveyQuestions, getTenantStaffs, getUpdatedSurveyCategories, submitSurvey, getMaskedUserData, initialMaskedData } from "../../helpers/guestSurveyHelper";
import { getDeviceId } from "../../helpers/userTrackingHelper";
import { getDomainFromURL, isQrScanPresent } from '../../helpers/utils';
import { GUEST_SURVEY, SERVERS_LIST, USER_INFO, USER_TRACKING } from "../../constants/queryKeys";
import { JTranslation, jTranslationText, TranslationContext } from '../../helpers/jTranslate';
import { routes } from "../../constants/routes";
import { Survey, ServerInfo, Users, PersonalInfo, MaskedPersonalInfo, SurveyMode } from "../../constants/staticTypes";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useTrackEvent } from '../../hooks/useTrackEvent';
import * as EmailValidator from 'email-validator';
import Loader from "../../components/loader/Loader";
import SurveyPersonalInfo from "../../components/survey/guest/SurveyPersonalInfo";
import SurveyQuestions from "../../components/survey/guest/SurveyQuestions";
import ToastAlert from "../../components/alert/ToastAlert";
import useMutationHook from "../../hooks/useMutationHook";
import useQueryHook from "../../hooks/useQueryHook";
import withPublicHeader from "../../hoc/withPublicHeader";
import { ScrollTop } from 'primereact/scrolltop';

function GuestSurvey() {
    const { trackEvent } = useTrackEvent();
    const [searchParams] = useSearchParams();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const menuCode = localStorage.getItem(SPECIAL_MENU_CODE)
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const surveySubmitMutation = useMutationHook(queryClient, true);
    const translationContext = useContext(TranslationContext)
    const userInfo = queryClient.getQueryData(USER_INFO) as AxiosResponse<any, any>;
    const loggedInUser = userInfo?.data?.data as Users;
    const formClasses = {
        true: FormClass.VALID,
        false: FormClass.INVALID
    } as { [key: string]: string };

    const [isFirstPage, showFirstPage] = useState(true);
    const [reCaptchaSuccess, setReCaptchaSuccess] = useState(false);
    const [isGameAvailable, setGameAvailable] = useState(false);
    const [showToast, setToast] = useState(false); // Toast alert state
    const [toastMessage, setToastMessage] = useState(""); // Toast message
    const [toastVariant, setToastVariant] = useState(""); // Toast Variant
    const [surveyAnswerValidation, setSurveyAnswerValidation] = useState<{ [key: string]: boolean | number }[]>([]);

    const [pageMode, setPageMode] = useState<SurveyMode>("New")



    // read data from tracking query
    const userTracking = queryClient.getQueryData(USER_TRACKING) as any;

    // personal info
    const [personalInfo, setPersonalInfo] = useState<PersonalInfo>({
        emailId: "",
        fullName: "",
        serverId: "",
        serverName: "",
        serverPosNumber: "",
        guestDeviceId: null,
        guestExpectedId: null,
        anniversaryDate: null as Date | null,
        birthDate: null as Date | null,
        zipCode: "",
        phoneNumber: ""
    });

    // masked personal info
    const [maskedPersonalInfo, setMaskedPersonalInfo] = useState<MaskedPersonalInfo>(initialMaskedData)

    // form validation for personal info
    const [formValidations, setFormValidations] = useState({
        fullName: FormClass.VALID,
        email: FormClass.VALID,
        zip: FormClass.VALID,
        birthday: FormClass.VALID
    } as { [key: string]: string });

    // survey state
    const [survey, setSurvey] = useState<Survey>();

    // servers list
    const [serversLists, setServersList] = useState<ServerInfo[]>([]);

    // comments or suggestions
    const [comments, setComments] = useState("");

    // fetch questions
    const questionsData = useQueryHook(GUEST_SURVEY, () => getSurveyQuestions(queryClient), (res) => {
        const questions = res.data.data as Survey[];
        questions[0].surveyCategories = [...getUpdatedSurveyCategories(questions[0])];
        setGameAvailable(questions[0].isGameAvailable);
        setSurvey(questions[0]);
    });

    useEffect(() => {
      if (isQrScanPresent()) {
        // Google Analytics
        trackEvent({
          eventName: 'qr_scan',
          value: {
            qr_link_destination: window.location.pathname,
          }
        });
      }
    }, []) // eslint-disable-line

    // Create an event handler so you can call the verification on button click event or form submit
    useEffect(() => {
        if (executeRecaptcha) {
            // Verify the reCAPTCHA token on component mount
            const verifyRecaptchaToken = async () => {
                try {
                    const token = await executeRecaptcha();
                    if (token) setReCaptchaSuccess(true)
                } catch (error) {
                    console.error('Failed to execute reCAPTCHA:', error);
                    setReCaptchaSuccess(false)
                }
            };

            verifyRecaptchaToken();
        }
    }, [executeRecaptcha]);

    // set guest device info
    useEffect(() => {
        if (userTracking !== undefined && userTracking !== null) {
            const guestData = userTracking.data.data.userData;
            const guestExpectedId = guestData.id;
            const guestDeviceId = getDeviceId();
            setPersonalInfo({
                ...personalInfo,
                guestExpectedId,
                guestDeviceId
            });
        }
    }, []) // eslint-disable-line

    // fetch servers list
    const serverData = useQueryHook(SERVERS_LIST, () => getTenantStaffs(queryClient, "", 500, ""), (res) => {
        const servers = res.data.data.lists as ServerInfo[];
        setServersList(servers);

        // set selection to current logged in tenant
        let serverInfo = null;
        if (loggedInUser) {
            serverInfo = servers.filter(user => user.posNumber === loggedInUser.posNumber)[0];
        } else if (searchParams.get("server") !== null) {
            serverInfo = servers.filter(user => user.posNumber === searchParams.get("server"))[0];
        }

        if (serverInfo) {
            setPersonalInfo({
                ...personalInfo,
                serverId: serverInfo.id,
                serverName: serverInfo.preferredName,
                serverPosNumber: serverInfo.posNumber
            });
        }
    })

    // get display page
    const getDisplayPageComponent = () => {
        if (isFirstPage) {
            return <SurveyPersonalInfo personalInfo={personalInfo} setPersonalInfo={setPersonalInfo}
                formValidations={formValidations} setFormValidations={setFormValidations}
                serversList={serversLists} tenantsIsLoading={serverData.isLoading}
                pageMode={pageMode} setPageMode={setPageMode} maskedPersonalInfo={maskedPersonalInfo} />;
        } else {
            return <SurveyQuestions survey={survey} setSurvey={setSurvey} surveyAnswerValidation={surveyAnswerValidation}
                comments={comments} setComments={setComments} />;
        }
    }

    // on success
    const onMaskedDataSuccess = (res: AxiosResponse<any, any>) => {
        setPageMode("Masked")
        const response: MaskedPersonalInfo = res.data.data.guestData
        setMaskedPersonalInfo({
            anniversaryDate: response.anniversaryDate,
            birthDate: response.birthDate,
            emailId: response.emailId,
            fullName: response.fullName,
            userId: response.id,
            isActive: response.isActive,
            phoneNumber: response.phoneNumber,
            totalVisitCount: response.totalVisitCount,
            zipCode: response.zipCode,
        })
    }

    // on success
    const onSuccess = (res: AxiosResponse<any, any>) => {
        // replace guest id
        if (userTracking !== undefined && userTracking !== null) {
            queryClient.setQueryData(USER_TRACKING, { data: { data: { userData: res.data.data.userData } } });
        }

        if (isGameAvailable) { // navigate to game
            navigate(routes.guest_game, {
                state: {
                    surveyResponseId: res.data.data.surveyResponseId,
                    message: res.data.message,
                    variant: AlertVariant.SUCCESS
                }
            });
        } else { 
          // check if the menu is normal or special menu and redirect accordingly 
              if (menuCode) {
              navigate(`${routes.special_menu}/${menuCode}`, {
                state: {
                  message: res.data.message,
                  variant: AlertVariant.SUCCESS,
                },
            })
          } else {
            navigate(routes.menu_view, {
              state: {
                message: res.data.message,
                variant: AlertVariant.SUCCESS,
              },
            })
          }
        }
    }

    // show toast
    const displayToast =async (message: string, variant: string) => {
        const translatedMessage = await jTranslationText({
          text: message,
          translationContext,
        })
        setToastVariant(variant);
        setToastMessage(translatedMessage ?? "")
        setToast(true);
    }

    const onError = (message: string, variant: string) => {
        setMaskedPersonalInfo(initialMaskedData)
        setPageMode("New")
    }

    // get submit button display text
    const getSubmitButtonText = () => {
        if (isFirstPage) {
            return  <JTranslation text={NEXT} />;
        }
        return <JTranslation text={SUBMIT} />
    }

    // once a valid email address is received, call api to get masked user data once it is available
    useEffect(() => {
        let debounce_fn = _.debounce(function () {
            if (EmailValidator.validate(personalInfo.emailId)) {
                getMaskedUserData(
                    queryClient,
                    surveySubmitMutation,
                    { emailId: personalInfo.emailId.toLowerCase() },
                    onMaskedDataSuccess,
                    onError
                )
            }
        }, 1000)
        debounce_fn()
    }, [personalInfo.emailId]) // eslint-disable-line

    return (
      <>
        {questionsData.isLoading ? (
          <Loader />
        ) : (
          <>
            <div className='position-relative'>
              <ToastAlert
                data-testid='toast'
                show={showToast}
                onClose={setToast}
                message={toastMessage}
                variant={toastVariant}
              />
            </div>

            <ScrollTop target='parent' className='scroll-top' behavior='smooth'/>

            <div className='container h-100'>
              <div className='row mt-3  mb-3'>
                <div className='col-md-12 p-0'>
                  <div className='adbox-container py-4'>
                    <div className='d-flex align-items-ceneter survey-header'>
                      <div className='flex-grow-1 justify-content-center  flex-column'>
                        <h6>
                          <JTranslation text={FILL_SURVEY} />
                        </h6>
                        <h5>
                          <JTranslation text={YOU_WILL_GET_EXCITING_OFFERS} />
                        </h5>
                      </div>
                      <div className='overlay'></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-3 mt-3'>
                <div className='col-lg-12 p-0'>
                  <div className='card '>
                    <div className='card-body survey-position survey-form mb-7'>
                      {getDisplayPageComponent()}
                    </div>
                    <div className='row survey-btn-container shadow'>
                      <div className='col-md-12 text-center nxt-btn mb-3'>
                        {!isFirstPage && (
                          <button
                            type='button'
                            className='btn btn-primary btn-lg  btn-custom-primary-outline d-inline-flex align-items-center justify-content-center '
                            onClick={() => showFirstPage(true)}
                            data-testid="back-btn"
                          >
                            <i className='ri-arrow-left-line'></i>{' '}
                            <JTranslation text={BACK} />
                            {' '}
                          </button>
                        )}

                        <button
                          type='button'
                          className='btn btn-primary btn-lg  btn-custom-primary d-inline-flex align-items-center justify-content-center '
                          data-testid="next-btn"
                          onClick={() => { // nosonar
                            if (isFirstPage) {
                              // Google Analytics
                              trackEvent({
                                eventName: "start_survey",
                                label: "click on the next button on survey's first page",
                                value: {
                                    page_path: `/${routes.survey_guest}`,
                                    page_location: `${getDomainFromURL()}/${routes.survey_guest}`,

                                },
                              });

                              const validations = {
                                birthday:
                                  formClasses[
                                    (personalInfo.birthDate !== null).toString()
                                  ],
                                email:
                                  formClasses[
                                    EmailValidator.validate(
                                      personalInfo.emailId
                                    ).toString()
                                  ],
                                zip: formClasses[
                                  (
                                    personalInfo.zipCode?.trim().length > 0
                                  ).toString()
                                ],
                                fullName:
                                  formClasses[
                                    (
                                      personalInfo.fullName?.trim().length > 0
                                    ).toString()
                                  ],
                              }
                              setFormValidations({
                                ...validations,
                              })

                              showFirstPage(
                                pageMode === 'Masked'
                                  ? false
                                  : !(
                                      validations.birthday ===
                                        FormClass.VALID &&
                                      validations.fullName ===
                                        FormClass.VALID &&
                                      validations.zip === FormClass.VALID &&
                                      validations.email === FormClass.VALID
                                    )
                              )
                            } else {
                              let data: MaskedPersonalInfo = {
                                ...personalInfo,
                              } as unknown as MaskedPersonalInfo
                              if (pageMode === 'Masked') {
                                data.anniversaryDate =
                                  maskedPersonalInfo.anniversaryDate
                                data.birthDate = maskedPersonalInfo.birthDate
                                data.fullName = maskedPersonalInfo.fullName
                                data.phoneNumber = maskedPersonalInfo.fullName
                                data.userId = maskedPersonalInfo.userId
                              }
                              const submitData = getApiFormattedSurveyFormData(
                                data,
                                survey as Survey,
                                comments
                              )
                              if (
                                submitData.surveyAnswersValid.every(
                                  (item) => item.isValidData === true
                                ) &&
                                reCaptchaSuccess
                              ) {
                                submitSurvey(
                                  queryClient,
                                  surveySubmitMutation,
                                  submitData.formData,
                                  onSuccess,
                                  displayToast // nosonar
                                )
                                // Google Analytics
                                trackEvent({
                                  eventName: "finish_survey",
                                  label: "click on the submit button on survey page",
                                  value: {
                                      page_path: `/${routes.survey_guest}`,
                                      page_location: `${getDomainFromURL()}/${routes.survey_guest}`,

                                  },
                                });
                              } else if (!reCaptchaSuccess) {
                                displayToast(
                                  'ReCaptcha validation failed',
                                  AlertVariant.ERROR
                                )
                              } else {
                                setSurveyAnswerValidation([
                                  ...submitData.surveyAnswersValid,
                                ])
                                displayToast(
                                  FILL_REQUIRED_FIELDS,
                                  AlertVariant.ERROR
                                )
                              }
                            }
                          }}
                        >
                          {getSubmitButtonText()}{' '}
                          <i className='ri-arrow-right-line'></i>{' '}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    )
}

export default withPublicHeader(GuestSurvey);