import { useState, useContext, useEffect } from 'react';
import { ADD_CUSTOM_MENU, CUSTOM_MENU_LIST, EVENT_CODE, STATUS, CREATED_DATE, ACTIVE, INACTIVE, NAME, DELETE, EDIT, QR_DOWNLOAD } from '../../constants/strings';
import { changeCustomMenuStatus } from '../../helpers/customMenuHelper';
import { checkPermission, convertUTCtoLocalByOffset, toPascalCase } from '../../helpers/utils';
import { Column } from 'primereact/column';
import { CreateCustomMenu, ChangeCustomMenuStatus, ToastMessageProps, CustomMenu, BrandingResponseData } from '../../constants/staticTypes';
import { DataTable } from 'primereact/datatable';
import { DebounceInput } from 'react-debounce-input';
import { FaSearch, FaEdit } from 'react-icons/fa';
import { FnBCtx } from '../../context/MoveItemCtxProvider';
import { MOBILE_VIEW_BREAKPOINT, PermissionKeys, SubPermissionKeys, toastMessageInitialData } from '../../constants/constants';
import { routes } from '../../constants/routes';
import { saveAs } from 'file-saver';
import { TENANT_INFO } from '../../constants/queryKeys';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import axios, { AxiosResponse } from 'axios';
import html2canvas from "html2canvas";
import qrcode from "qrcode-generator";
import ToastAlert from '../alert/ToastAlert';
import useMutationHook from '../../hooks/useMutationHook';
import { JTranslation, TranslationContext, jTranslationText } from '../../helpers/jTranslate';
import { Props } from './CustomMenuListDataGrid';
import { useWindowSize } from 'react-use';
import { DataView } from 'primereact/dataview';
import { Card } from 'primereact/card';
import MlxTooltip from '../common/MlxTooltip';

export function CustomMenuListDataGrid({
    customMenuList, loading, onSearchTrigger, onEditClick, onDeleteClick, editCustomMenu,
}: Props) {
    // LOCAL VARIABLES
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const customMenuMutation = useMutationHook(queryClient, true);
    // CONTEXT VARIABLES
    const { setSelectedCategory } = useContext(FnBCtx);
    const translationContext = useContext(TranslationContext);
    const { targetLanguage } = translationContext;
    // STATE VARIABLES
    const [search, setSearch] = useState("");
    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData);
    const [mobileLogUrl, setMobileLogoUrl] = useState({ url: '', imageKey: '' });
    const [imageSrc, setImageSrc] = useState<string>('');
    const [translationText, setTranslatedText] = useState<{
        download: string;
        edit: string;
        delete: string;
        search: string;
        paginationTemplate: string;
        emptyMessage: string;
    }>({
        download: QR_DOWNLOAD,
        edit: EDIT,
        delete: DELETE,
        search: 'Search',
        paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
        emptyMessage: 'No custom menu found'
    });
    const [showTitle, setShowTitle] = useState(true)
    const { width, height } = useWindowSize()
	const breakPoint = MOBILE_VIEW_BREAKPOINT

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: QR_DOWNLOAD, typeCase: 'none', translationContext }),
                    jTranslationText({ text: EDIT, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: DELETE, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: 'Search', typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: 'Showing 1 to 2 of 3 items', typeCase: 'pascal', translationContext }),
                ]);
                setTranslatedText({
                    download: translations[0] ?? QR_DOWNLOAD,
                    edit: translations[1] ?? EDIT,
                    delete: translations[2] ?? DELETE,
                    search: translations[3] ?? 'Search',
                    paginationTemplate: translations[4]
                        ?.replace('1', '{first}')
                        ?.replace('2', '{last}')
                        ?.replace('3', '{totalRecords}') ?? 'Showing {first} to {last} of {totalRecords} items',
                    emptyMessage: translations[4] ?? 'No custom menu found',
                });
            } catch {
                setTranslatedText({
                    download: QR_DOWNLOAD,
                    edit: EDIT,
                    delete: DELETE,
                    search: 'Search',
                    paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
                    emptyMessage: 'No custom menu found',
                });
            }
        };
        fetchTranslation();
    }, [targetLanguage]);

    useEffect(() => {
        const fetchImage = async () => {
            if (!mobileLogUrl.url || imageSrc) return;
            try {
                // Fetch the image as a Blob
                const response = await axios.get(mobileLogUrl.url, {
                    responseType: 'blob',
                });

                // Create a local URL for the blob
                const localUrl = URL.createObjectURL(response.data);

                // Update state with the local URL
                setImageSrc(localUrl);
            } catch (error) {
                console.error('Error fetching image:', error);
            }
        };

        fetchImage();
    }, [mobileLogUrl.url, imageSrc]);

    useEffect(() => {
        const res = queryClient.getQueryData(TENANT_INFO) as AxiosResponse<any, any>;
        let data = res?.data?.data as BrandingResponseData;
        const { branding } = data;
        setMobileLogoUrl({ imageKey: '', url: branding?.logos?.small });
    }, [queryClient]);

    // generate qr image
    const generateQR = async (menu: CreateCustomMenu) => {
        let qrDiv;
        const canvas = await html2canvas((document.getElementById("qr-code") as HTMLElement), {
            onclone: (doc) => {
                qrDiv = doc.getElementById("qr-code") as HTMLElement;
                qrDiv.style.display = "inline-block";

                // add qr code
                const qrText = `${window.location.protocol}//${window.location.hostname}${routes.special_menu}/${menu.menuCode}?qr_scan=true`;
                let qr = qrcode(0, 'L');
                qr.addData(qrText);
                qr.make();
                // (doc.getElementById("qr-top-label") as HTMLElement).innerHTML = fnBGlobalSettings.qrCodeSettings.qrCodeTopLabel;
                (doc.getElementById("qr-img") as HTMLElement).innerHTML = qr.createSvgTag(10);
                (doc.getElementById("qr-name") as HTMLElement).innerHTML = menu.menuName;
            }
        });
        let image = canvas.toDataURL("image/png", 1);
        return image;
    };

    const downloadQR = async (menu: CreateCustomMenu) => {
        let qrImage = await generateQR(menu);
        saveAs(qrImage, `${menu.menuName}.png`);
    };

    // download qr template
    const downloadQRTemplate = (menu: CreateCustomMenu) => {
        return (
            <MlxTooltip target='.download'>
            <i
                data-pr-tooltip={translationText.download}
                data-pr-position='bottom'
                className={`ri-download-2-fill download ${width <= breakPoint ? 'rounded-background shadow-sm ms-2' : ''}`}
                data-testid={'qr#' + menu.id}
                onClick={(e) => {
                    e.stopPropagation();
                    downloadQR(menu);
                }}
            ></i>
            </MlxTooltip>
        );
    };

    // on create custom menu success
    const onSuccess = (message: string, variant: string, data: CustomMenu) => {
        onSearchTrigger("");
        setToastMessage({ message, variant, show: true });
    };

    // on api error
    const onError = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true });
    };

    const activeInactiveTemplate = (menu: CreateCustomMenu) => {
        return <>
            <div className="d-flex">
                {<div className="flex-grow-0 ">
                    <span className="form-check form-switch">
                        <input className="form-check-input form-check-input-custom"
                            type="checkbox"
                            autoComplete="off"
                            checked={Boolean(menu.isActive)}
                            data-testid="status-check"
                            disabled={!checkPermission(queryClient, PermissionKeys.MANAGE_CUSTOM_FNB_MENU, SubPermissionKeys.STATUS)}
                            onChange={() => {
                                if (checkPermission(queryClient, PermissionKeys.MANAGE_CUSTOM_FNB_MENU, SubPermissionKeys.STATUS)) {
                                    // API CALL TO CHANGE STATUS
                                    const params: ChangeCustomMenuStatus = { menuId: menu.id, status: !menu.isActive };
                                    changeCustomMenuStatus(customMenuMutation, params, onSuccess, onError);
                                }
                            }}
                            id="flexSwitchCheckDefault" 
                            style={width <= breakPoint ? { width: '3em', height: '1.5em' } : { width: '2.25em', height: '1em' }}
                        />
                    </span>
                </div>}
                {width > breakPoint && <div className="flex-grow-1">{menu.isActive ? ACTIVE : INACTIVE}</div>}
            </div>
        </>;
    };

    const deleteButtonTemplate = (event: CreateCustomMenu) => {
        return (
            <>
                {<MlxTooltip target='.delete'>
                    <i
                    data-pr-tooltip={translationText.delete}
                    data-pr-position='bottom'
                    className={'ri-delete-bin-line delete ' +
                        (!checkPermission(queryClient, PermissionKeys.MANAGE_CUSTOM_FNB_MENU, SubPermissionKeys.DELETE) &&
                            ' disabled') + (width <= breakPoint ? ' rounded-background shadow-sm ms-2' : '')}
                    data-testid="delete-btn"
                    onClick={(e) => {
                        e.stopPropagation();
                        if (checkPermission(queryClient, PermissionKeys.MANAGE_CUSTOM_FNB_MENU, SubPermissionKeys.DELETE)) {
                            onDeleteClick(event);
                        }
                    }}
                ></i>
                </MlxTooltip>}
            </>
        );
    };

    const editButtonTemplate = (event: CreateCustomMenu) => {
        return (
            <span className={`${width <= breakPoint ? 'rounded-background shadow-sm ms-2' : ''}`}>
                <MlxTooltip target='.edit'>
                <FaEdit
                    className={`edit ${!checkPermission(queryClient, PermissionKeys.MANAGE_CUSTOM_FNB_MENU, SubPermissionKeys.EDIT) && ' disabled'}`}
                    data-pr-tooltip={translationText.edit}
                    data-pr-position="bottom"
                    data-testid="edit-btn"
                    onClick={(e) => {
                        e.preventDefault();
                        if (checkPermission(queryClient, PermissionKeys.MANAGE_CUSTOM_FNB_MENU, SubPermissionKeys.EDIT)) {
                            onEditClick(event);
                        }
                    }} 
                />
                </MlxTooltip>
            </span>
        );
    };

    const previewEventByCodeTemplate = (menu: CreateCustomMenu) => {
        const protocol = window.location.protocol;
        const domain = window.location.hostname;
        const port = window.location.port;
        const url = `${protocol}//${domain}${port ? `:${port}` : ''}`;

        const urlText = `${url}${routes.special_menu}/${menu.menuCode}`;

        return (
            <MlxTooltip target='.email-text'>
                <a
                    className='overflow-ellipsis email-text'
                    data-pr-tooltip={`Preview ${menu.menuCode}`}
                    data-pr-position='bottom'
                    href={urlText}
                    data-testid="event-code-link"
                    onClick={(e) => {
                        e.preventDefault();
                        window.open(urlText, '_blank');
                    }}
                >
                    {menu.menuCode}
                </a>
            </MlxTooltip>
        );
    };

    const itemTemplate = (customMenu: CreateCustomMenu) => {
        return (
			<Card className="data-view-card mt-2 d-flex flex-column justify-content-even">
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <p className="m-0 text-nowrap">{toPascalCase(customMenu.menuName)}</p>
					<p className="m-0 text-nowrap">{convertUTCtoLocalByOffset(customMenu.createdAt)}</p>
                </div>
				<div className='mt-2'>
					<p className="m-0">{previewEventByCodeTemplate(customMenu)}</p>
				</div>
				<div className="view-footer mt-2 pt-3 d-flex flex-row justify-content-between align-items-center">
					<div className='d-flex justify-content-even align-items-center'>
                        {downloadQRTemplate(customMenu)}{editButtonTemplate(customMenu)}{deleteButtonTemplate(customMenu)}
                    </div>
					<div className='d-flex justify-content-even align-items-center'>{activeInactiveTemplate(customMenu)}</div>
				</div>
			</Card>
		)
    }

    return (
        <>
            <ToastAlert
                data-testid="toast"
                message={toastMessage.message}
                onClose={() => setToastMessage(toastMessageInitialData)}
                show={toastMessage.show}
                variant={toastMessage.variant} />

            <div id="qr-code" className='qr-code-parent' style={{ display: "none" }}>
                {/* <p id="qr-top-label" className='qr-code-child qr-code-label-top'></p> */}
                <div className="qr-image-wrapper">
                    {mobileLogUrl.url && (<img alt="small-logo" src={imageSrc} className="fnb-qr-code-logo" />)}
                    <div id="qr-img"></div>
                </div>
                {/* <p id="qr-bottom-label" className='qr-code-child qr-code-label-bottom'></p> */}
                <p id="qr-name" className='qr-code-child qr-code-preferred-name'></p>
            </div>

            <div id="qr-code" className='qr-code-parent' style={{ display: "none" }}>
                {mobileLogUrl.url && (<img alt="small-logo" src={imageSrc} className="cus-menu-qr-code-logo" />)}
                {/* <p id="qr-top-label" className='qr-code-child qr-code-label-top'></p> */}
                <div id="qr-img"></div>
                {/* <p id="qr-label" className='qr-code-child qr-code-label-bottom'></p> */}
                <p id="qr-name" className='qr-code-child qr-code-preferred-name'></p>
            </div>

            <div className="col-md-12 col-lg-12 mb-3 addOn-scroll-fix h-100">
                <div className="card h-100">
                    <div className="card-header flex-grow-0">
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                                {showTitle &&
                                    <h4 className="mb-0 title"><JTranslation typeCase="pascal" text={CUSTOM_MENU_LIST} /></h4>
                                }
                            </div>
                            <div className="flex-grow-0 me-2 ms-1">
                                <button
                                    className="btn btn-custom-primary d-flex icon-only-btn"
                                    type="button"
                                    data-testid="add-custom-menu-btn"
                                    onClick={() => {
                                        setSelectedCategory(undefined);
                                        navigate(`${routes.custom_menu_list}/create`);
                                    }}
                                >
                                    <i className="ri-add-circle-line me-1"></i>
                                    <span className="btn-text"><JTranslation typeCase="pascal" text={ADD_CUSTOM_MENU} /></span>
                                </button>
                            </div>
                            <div className="flex-grow-0  d-flex justify-content-end ">
                                <div className="col-md-12 ">
                                    <div className={`form-group has-search ${!showTitle ? '' : 'search-small-box'}`}>
                                        <span className="fa fa-search form-control-feedback">
                                            <FaSearch />{' '}
                                        </span>
                                        <DebounceInput
                                            onFocusCapture={() => {breakPoint >= width && setShowTitle(false)}}
                                            onBlurCapture={(event: any) => {                                                
                                                if(breakPoint >= width && event.target.value.length === 0) {
                                                    setShowTitle(true)
                                                }
                                            }}
                                            className="form-control "
                                            placeholder={translationText.search}
                                            minLength={0}
                                            value={search}
                                            debounceTimeout={500}
                                            data-testid="custom-menu-search"
                                            onChange={(e) => {
                                                setSearch(e.target.value);
                                                onSearchTrigger(e.target.value);
                                            }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body flex-grow-1 overflow-hidden">
                        <div className="row mb-5 h-100">
                            <div className="col-lg-12" style={{ height: '100%', overflow: 'auto' }}>
                            {width <= breakPoint ? (
                                <DataView
                                    value={customMenuList}
                                    layout={'list'}
                                    itemTemplate={itemTemplate}
                                    paginator
                                    rows={10}
                                    paginatorClassName="p-dataview-customers"
                                    dataKey="id"
                                    emptyMessage={translationText.emptyMessage}
                                />
                                ) : (
                                        <DataTable
                                            value={customMenuList}
                                            paginator
                                            className="p-datatable-customers"
                                            rows={10}
                                            scrollable
                                            scrollHeight="flex"
                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                            rowsPerPageOptions={[10, 25, 50]}
                                            dataKey="id"
                                            rowHover
                                            selectionMode="single"
                                            selection={editCustomMenu}
                                            loading={loading}
                                            responsiveLayout="scroll"
                                            emptyMessage={translationText.emptyMessage}
                                            currentPageReportTemplate={translationText.paginationTemplate}
                                        >
                                            <Column
                                                field="menuName"
                                                header={<JTranslation typeCase="pascal" text={NAME} />}
                                                sortable
                                                className="word-break-grid"
                                                alignFrozen="right"
                                                style={{ minWidth: '18rem' }} />
                                            <Column
                                                field="menuCode"
                                                header={<JTranslation typeCase="pascal" text={EVENT_CODE} />}
                                                className="word-break-grid"
                                                alignFrozen="right"
                                                body={previewEventByCodeTemplate}
                                                style={{ minWidth: '10rem', maxWidth: '10rem' }} />
                                            <Column
                                                field="createdAt"
                                                header={<JTranslation typeCase="pascal" text={CREATED_DATE} />}
                                                body={(menu) => convertUTCtoLocalByOffset(menu.createdAt)}
                                                className="word-break-grid"
                                                alignFrozen="right"
                                                style={{ minWidth: '10rem', maxWidth: '10rem' }} />
                                            <Column
                                                field="isActive"
                                                header={<JTranslation typeCase="pascal" text={STATUS} />}
                                                body={activeInactiveTemplate}
                                                className="word-break-grid"
                                                alignFrozen="right"
                                                style={{ minWidth: '10rem', maxWidth: '10rem' }} />
                                            <Column
                                                field="qr"
                                                style={{ width: '4rem' }}
                                                body={downloadQRTemplate}
                                                bodyClassName="bg-sticky-color"
                                                alignFrozen="right"
                                                frozen={true} />
                                            <Column
                                                field="edit"
                                                style={{ width: '4rem' }}
                                                bodyClassName="bg-sticky-color"
                                                body={editButtonTemplate}
                                                alignFrozen="right"
                                                frozen={true} />
                                            <Column
                                                field="delete"
                                                style={{ width: '4rem' }}
                                                bodyClassName="bg-sticky-color"
                                                body={deleteButtonTemplate}
                                                alignFrozen="right"
                                                frozen={true} />
                                        </DataTable>
                                )
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
