import { Skeleton } from 'primereact/skeleton';

const WelcomeSkeleton = () => {
    return (
        <>
            <Skeleton className="mb-2"></Skeleton>
            <Skeleton width="10rem" className="mb-2"></Skeleton>
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="4rem" className="mb-2"></Skeleton>
        </>
    )
}

export default WelcomeSkeleton