import { AxiosResponse } from "axios";
import { QueryClient, UseMutationResult } from "react-query";
import { surveyReportsApi, surveyReportsExportApi } from "../constants/apiEndPoints";
import { AlertVariant, HttpMethods } from "../constants/constants";
import { Payload } from "../constants/staticTypes";
import HttpServiceHelper from "./httpServiceHelper";
import { getErrorMessage } from "./utils";

// format payload data
export const getFormattedReportPayloadData = (search: string, lastKey: string | null, limit: number,
    startDate: string, endDate: string) => {
    const startDateValue = startDate.length > 0 ? startDate : null;
    const endDateValue = endDate.length > 0 ? endDate : null;
    let data = {
        search,
        limit,
        lastKey
    } as {}

    if (startDateValue !== null || endDateValue !== null) {
        data = {
            ...data,
            dateRange: {
                startDate: startDateValue,
                endDate: endDateValue
            }
        }
    }
    return data;
}

// fetch survey reports list
export const getSurveyReports = (queryClient: QueryClient, search: string, lastKey: string | null, limit: number,
    startDate: string, endDate: string) => {

    return HttpServiceHelper({
        url: surveyReportsApi,
        method: HttpMethods.POST,
        data: getFormattedReportPayloadData(search, lastKey, limit, startDate, endDate),
        queryClient
    });
}

// export csv
export const exportReportsCSV = (queryClient: QueryClient, data: {}, displayToast: (message: string, variant: string) => void,
    exportCSVMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>) => {
    exportCSVMutation.mutate({
        url: surveyReportsExportApi,
        method: HttpMethods.POST,
        data,
        queryClient
    }, {
        onSuccess: (res) => {
            const link = document.createElement('a');
            link.href = res.data.data.file.signedUrl;

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            if (link.parentNode !== null) {
                link.parentNode.removeChild(link);
            }
        },
        onError: (err) => { displayToast(getErrorMessage(err), AlertVariant.ERROR) }
    });
}