import { AxiosResponse } from 'axios'
import { shiftConfigApi } from '../constants/apiEndPoints'
import { getErrorMessage } from './utils'
import { HttpMethods, AlertVariant } from '../constants/constants'
import {
	Payload, Shift,
	ShiftForm,
	ShiftFormData,
	ShiftTemplate,
	ShiftTemplatesData
} from '../constants/staticTypes'
import { UseMutationResult } from 'react-query'
import dayjs, { Dayjs } from 'dayjs'
import HttpServiceHelper from './httpServiceHelper'
import * as Yup from 'yup'

export const shiftInitialValue = {
	shifts: [],
}

export const shiftValidationSchema = Yup.object().shape({
	shiftName: Yup.string().required('Required'),
	startTime: Yup.string().required('Required'),
	endTime: Yup.string()
		.required('Required')
		.test('is-valid-end-time', 'End time must be greater than start time', function (value) {
			const startTime = dayjs(this.parent.startTime) as Dayjs
			const endTime = dayjs(value)
			return endTime.isAfter(startTime)
		}),
})

export const convertFormDataToApiData = (inputData: Record<string, any>): ShiftTemplatesData => {
	const shiftTemplates: ShiftTemplate[] = []

	if (inputData.shifts) {
		const shifts = inputData.shifts

		// Extract relevant information from input data and create shift templates data
		for (let i = 0; i < shifts.length; i++) {
			const shiftData: ShiftTemplate = {
				shiftName: shifts[i].shiftName,
				startTime: dayjs(shifts[i].startTime).format('HH:mm'),
				endTime: dayjs(shifts[i].endTime).format('HH:mm'),
			}

			shiftTemplates.push(shiftData)
		}
	}

	return { shiftTemplates } as ShiftTemplatesData
}

export function convertApiDataToFormData(shifts: Shift[]): ShiftFormData[] {
	const updatedShifts: ShiftFormData[] = shifts.map((template) => ({
		id: template.id,
		shiftName: template.shiftName,
		startTime: dayjs(template.startTime, 'HH:mm'),
		endTime: dayjs(template.endTime, 'HH:mm'),
	}))

	return updatedShifts
}

export const addShiftConfig = (
	addMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	param: ShiftForm,
	onSuccess: (message: string, variant: string) => void,
	onError: (res: string, variant: string) => void
) => {
	addMutation.mutate(
		{
			url: `${shiftConfigApi}/item`,
			method: HttpMethods.POST,
			data: param,
		},
		{
			onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

export const updateShiftConfig = (
	editMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	param: Shift,
	onSuccess: (message: string, variant: string) => void,
	onError: (res: string, variant: string) => void
) => {
	editMutation.mutate(
		{
			url: `${shiftConfigApi}/item`,
			method: HttpMethods.PUT,
			data: param,
		},
		{
			onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

export const deleteShiftConfig = (
	deleteMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	param: { id: string },
	onSuccess: (message: string, variant: string) => void,
	onError: (res: string, variant: string) => void
) => {
	deleteMutation.mutate(
		{
			url: `${shiftConfigApi}/item`,
			method: HttpMethods.DELETE,
			data: param,
		},
		{
			onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

// fetch shift config
export const getShiftConfigList = () => {
	return HttpServiceHelper({
		url: `${shiftConfigApi}/list`,
		method: HttpMethods.GET,
	})
}
