import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { getAllDays, toPascalCase } from "../../helpers/utils";
import dayjs from "dayjs";
import { MutableRefObject, useEffect, useState } from "react";
import { ProgressBar } from "primereact/progressbar";
import { Button } from "primereact/button";
import { useVisibleColumns } from "../../hooks/useVisibleColumns";
import useElementSize from "../../hooks/useElementSize";
import { v4 as uuidv4 } from "uuid";
import { useWindowSize } from "react-use";
import { cloneDeep } from "lodash";
import { JTranslation } from "../../helpers/jTranslate";
import { HolidayData } from "../../constants/staticTypes";
import { Tooltip } from "primereact/tooltip";

type Props = {
	tableData: any[];
	mandatoryDays: dayjs.Dayjs[];
	weekDays: dayjs.Dayjs[];
	previewRef: MutableRefObject<null>;
	showPreview: boolean;
	setShowPreview: React.Dispatch<React.SetStateAction<boolean>>;
	loading: boolean;
	selectedRoles: string[];
	holidayList: HolidayData[];
};

function AvailabilityViewDataGrid({
	tableData,
	mandatoryDays,
	weekDays,
	previewRef,
	showPreview,
	setShowPreview,
	loading,
	selectedRoles,
	holidayList,
}: Readonly<Props>) {
	const [availabilityDatatableRef, { width }] = useElementSize();
	const { width: windowWidth } = useWindowSize();
	const totalColumns = weekDays?.length ?? 7;

	const [userList, setUserList] = useState<any[]>([]);
	const [totalCount, setTotalCount] = useState<{ day: string, count: number }[]>([]);
	const [visibleCount, setVisibleCount] = useState(totalColumns);
	const { visibleColumns, showNext, showPrev, startIndex } = useVisibleColumns(
		totalColumns,
		visibleCount
	);

	const nameTemplate = (data: any) => {
		const roles = data.jobRoles.map((role: any) => role?.jobRole?.jobRoleName);
		return (
			<div
				className="availability-names pt-3 ps-5"
				style={{
					borderLeft: "1px solid #efefef",
					minHeight: "5rem",
					width: "100%",
				}}
			>
				<span>
					<b>
						{data.firstName || data.lastName
							? `${toPascalCase(data?.firstName)} ${toPascalCase(
								data?.lastName
							)}`
							: toPascalCase(data.loginUserName)}
					</b>
				</span>
				<br />
				<span>{roles.join(", ")}</span>
			</div>
		);
	};

	const headerTemplate = (date: dayjs.Dayjs, index: number) => {
		const style = {
			minWidth: "10rem",
			width: "100%",
			minHeight: "6rem",
			borderRight: "1px solid #efefef",
			borderLeft: "0px",
		};
		if (index === 0) {
			style.borderLeft = "1px solid #efefef";
		}
		if (index === weekDays.length - 1) {
			style.borderRight = "0px";
		}

		const currentDate = date.format("ddd")
		const currentUserCount = totalCount.find((total: any) => total.day === currentDate)
		const holidayData = holidayList?.find((holiday) => {
			const holidayStartDate = dayjs(holiday?.startDate)
			const holidayEndDate = dayjs(holiday?.endDate)
			if (
				date.isBetween(holidayStartDate, holidayEndDate, 'day') ||
				date.isSame(holidayStartDate, 'day') ||
				date.isSame(holidayEndDate, 'day')
			) {
				return holiday
			}
		})
		return (
			<div
				className="d-flex flex-column align-items-center justify-content-center availability-header position-relative"
				style={style}
			>
				<span>
					<b>{currentDate}</b>
				</span>
				<span className="text-muted">{date.format("MMM DD")}</span>
				<span className="w-100 d-flex flex-row align-items-center justify-content-center mt-2">
					{holidayData && (
						<>
							<Tooltip target={`.holiday-icon-${holidayData?.id}`} />
							<span
								data-pr-tooltip={holidayData?.title}
								data-pr-position='bottom'
								className={`holiday-icon-${holidayData?.id} me-2 btn-custom-primary-outline`}
								data-testid="holiday-icon"
							>
								<i className="pi pi-calendar-times"></i>
							</span>
						</>
					)}
					<span className="d-flex flex-row align-items-center">
						<ProgressBar
							className="availability-progress-bar"
							style={{ width: "5rem", height: "8px" }}
							value={(currentUserCount?.count ?? 0) / (userList.length ?? 1) * 100}
							displayValueTemplate={(_) => <span></span>}
						></ProgressBar>
						<span className="ms-2" style={{ fontSize: "9px", fontWeight: "600", paddingTop: '1px' }}>
							{currentUserCount?.count ?? 0}/{userList.length}
						</span>
					</span>
				</span>
				{visibleCount !== totalColumns && visibleColumns[visibleColumns.length - 1] === index && index < totalColumns - 1 && (
					<div
						style={{
							position: "absolute",
							right: "0",
							zIndex: "3",
							top: "5px"
						}}
					>
						<Button
							style={{ height: "2rem", width: "2rem", opacity: 0.8 }}
							icon="pi pi-angle-right"
							raised
							// text
							severity="warning"
							aria-label="Previous Day"
							type="button"
							rounded
							onClick={() => {
								showNext();
							}}
						/>
					</div>
				)}
			</div>
		);
	};

	const parseTime = (timeString: string) => {
		// Replace dots with colons
		const formattedTime = timeString.replace(/\./g, ':');

		// Create a Day.js object with the current date and the time string
		const today = dayjs().format('YYYY-MM-DD'); // Get today's date
		const dateTime = `${today}T${formattedTime}`;

		return dayjs(dateTime);
	}

	// check if any day is unavailable
	const checkUnavailableDay = (currentDate: dayjs.Dayjs, allDays: any[]) => {
		if (allDays.length === 0) return false

		const clonedDays = cloneDeep(allDays)
		const convertStringToDayJS = [];
		for (const day of clonedDays) {
			// can have single time, day or multiple days
			const startDate = dayjs(day.startDate)
			const endDate = day.type === 'full-day' ? dayjs(day.endDate) : startDate
			const totalDays = getAllDays(startDate, endDate)
			convertStringToDayJS.push(...totalDays)
		}
		const checkIfDayExists = convertStringToDayJS.find((day) => day.isSame(currentDate, "day"))
		if (checkIfDayExists) {
			return clonedDays.find((day) => dayjs(day?.startDate).isSame(checkIfDayExists, "day") && !['CANCELLED', "REJECTED"].includes(day?.actionStatus))
		}
		return null
	}

	const availableTemplate = (data: any, currentDate: dayjs.Dayjs, index: number) => {
		const style = {
			minWidth: "10rem",
			width: "100%",
			minHeight: "5rem",
			borderRight: "1px solid #efefef",
			borderLeft: "0px",
		};
		if (index === 0) {
			style.borderLeft = "1px solid #efefef";
		}
		const checkNonAvailability = checkUnavailableDay(currentDate, data?.unavailableDays);
		const checkMandatoryDay = mandatoryDays?.find((day) => day.isSame(currentDate, "day"));
		const holidayData = holidayList?.find((holiday) => {
			const holidayStartDate = dayjs(holiday?.startDate)
			const holidayEndDate = dayjs(holiday?.endDate)
			if (
				currentDate.isBetween(holidayStartDate, holidayEndDate, 'day') ||
				currentDate.isSame(holidayStartDate, 'day') ||
				currentDate.isSame(holidayEndDate, 'day')
			) {
				return holiday
			}
		})
		let isBusinessClosed = false
		if (holidayData?.isOpenForBusiness !== null && holidayData?.isOpenForBusiness !== undefined) {
			isBusinessClosed = !holidayData?.isOpenForBusiness
		}
		return (
			<div
				className="d-flex flex-column align-items-center justify-content-center available-template"
				style={style}
			>
				<span
					className={`d-flex flex-column align-items-center justify-content-center availability-block ${checkMandatoryDay || isBusinessClosed
						? isBusinessClosed
							? 'availability-holiday' : 'availability-mandate'
						: checkNonAvailability
							? 'availability-inactive' : 'availability-active'
						}`}
					style={{ minHeight: "4rem", width: "95%" }}
				>
					{checkMandatoryDay || isBusinessClosed
						? <>
							{(checkMandatoryDay && !isBusinessClosed) && (
								<span className="mandate-block">
									<span className="text-muted text-reason">
										<i className="ri-circle-fill me-1"></i>
										<JTranslation text={"Mandatory"} typeCase="capitalize" />
									</span>
								</span>
							)}
							<JTranslation text={!isBusinessClosed ? "Available" : "Closed"} typeCase="capitalize" />
						</>
						: checkNonAvailability
							? <>
								<span className="text-muted">
									<JTranslation text={"Not Available"} typeCase="capitalize" />
								</span>
								<span className="text-muted text-reason">
									<JTranslation
										text={
											checkNonAvailability?.type === "full-day"
												? checkNonAvailability?.reason
												: `(${parseTime(checkNonAvailability?.startTime).isValid()
													? parseTime(checkNonAvailability?.startTime).format("hh:mm A")
													: ""
												} - ${parseTime(checkNonAvailability?.endTime).isValid()
													? parseTime(checkNonAvailability?.endTime).format("hh:mm A")
													: ""
												})`
										}
										typeCase="capitalize"
									/>
								</span>
							</>
							: <JTranslation text={"Available"} typeCase="capitalize" />
					}

				</span>
			</div>
		);
	};

	// Adjust the number of visible columns based on screen width
	useEffect(() => {
		// Breakpoints to adjust the number of visible columns
		const updateVisibleCount = () => {
			const updatedWidth = showPreview ? windowWidth : width;
			if (updatedWidth > 1190) {
				setVisibleCount(7);
			} else if (updatedWidth > 1020) {
				setVisibleCount(6);
			} else if (updatedWidth > 880) {
				setVisibleCount(5);
			} else if (updatedWidth > 740) {
				setVisibleCount(4);
			} else if (updatedWidth > 600) {
				setVisibleCount(3);
			} else if (updatedWidth > 460) {
				setVisibleCount(2);
			} else {
				setVisibleCount(1);
			}
		};
		updateVisibleCount();
	}, [width, showPreview]);

	useEffect(() => {
		const userCountsOfWeek = []
		for (const currentDay of weekDays) {
			let count = 0;
			for (const user of userList) {
				const checkMandatoryDay = mandatoryDays?.find((day) => day.isSame(currentDay, "day"));
				const unavailableDay = checkUnavailableDay(currentDay, user?.unavailableDays)

				if ((!unavailableDay || checkMandatoryDay)) {
					count += 1
				}
			}
			userCountsOfWeek.push({ day: currentDay.format("ddd"), count: count })
		}
		setTotalCount(userCountsOfWeek)
	}, [userList])

	useEffect(() => {
		if (selectedRoles.length > 0) {
			const filterUserList = tableData?.filter((user) => {
				const userRoles = user?.jobRoles?.map((role: any) => role?.jobRoleId)
				return selectedRoles.every((role) => userRoles?.includes(role))
			})
			setUserList(filterUserList)
		} else {
			setUserList(tableData);
		}
	}, [selectedRoles])

	useEffect(() => {
		setUserList(tableData);
	}, [tableData])

	return (
		<div className="row mb-5 h-100" ref={availabilityDatatableRef}>
			<div
				className="col-lg-12 mt-3"
				style={{ height: "100%", overflow: "auto" }}
				ref={previewRef}
			>
				<DataTable
					value={userList}
					className="p-datatable-availability"
					scrollable
					scrollHeight="flex"
					dataKey="id"
					responsiveLayout="scroll"
					emptyMessage="No Data Found"
					showGridlines={true}
					loading={loading}
				>
					<Column
						style={{
							minWidth: "11rem",
							minHeight: "5rem",
							width: "100%",
							padding: "0px",
						}}
						field="loginUserName"
						className="word-break-grid"
						body={nameTemplate}
						headerClassName="p-header-first-column"
						header={() => {
							return (
								<div
									style={{
										minWidth: "11rem",
										minHeight: "5rem",
										width: "100%",
										position: "relative",
									}}
									className="d-flex flex-row align-items-center justify-content-start ps-3"
								>
									<Button
										hidden={!showPreview}
										// size="large"
										// raised
										rounded
										outlined
										icon="pi pi-window-minimize"
										className="p-button-primary color-primary-custom"
										title="Close Preview"
										onClick={() => {
											setShowPreview(false)
										}}
									/>

									{visibleCount !== totalColumns && visibleColumns[0] > 0 && (
										<div
											style={{
												position: "absolute",
												right: "-35px",
												zIndex: "3",
												top: "0"
											}}
										>
											<Button
												style={{ height: "2rem", width: "2rem", opacity: 0.8 }}
												icon="pi pi-angle-left"
												raised
												// text
												severity="warning"
												aria-label="Previous Day"
												type="button"
												rounded
												onClick={() => {
													showPrev();
												}}
											/>
										</div>
									)}
								</div>
							);
						}}
					/>

					{weekDays.map((date, index) => {
						return (
							<Column
								style={{
									minWidth: "10rem",
									width: "100%",
									minHeight: "5rem",
									padding: "0px",
								}}
								key={index}
								field=""
								header={headerTemplate(date, index)}
								body={(data: any) => availableTemplate(data, date, index)}
								hidden={!visibleColumns.includes(index)}
							/>
						);
					})}

					{/* Extra columns to make sure the columns are arranged from left - right */}
					{visibleCount - (totalColumns - startIndex) > 0 &&
						Array.from({
							length: visibleCount - (totalColumns - startIndex),
						}).map((_, index) => {
							return (
								<Column
									style={{
										minWidth: "10rem",
										width: "100%",
										minHeight: "5rem",
										padding: "0px",
									}}
									key={uuidv4()}
									field=""
								/>
							);
						})}
				</DataTable>
			</div>
		</div>
	);
}

export default AvailabilityViewDataGrid;
