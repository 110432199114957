import React, { useContext, useEffect, useState } from 'react';
import { ACTIVE_DEFAULT_PRICE_WARNING, LAST_ACTIVE_DEFAULT_PRICE_WARNING, DEFAULT_PRICE_WARNING, DEFAULT_PRICE, DELETE_DEFAULT_PRICE_WARNING, DELETE_WARNING, LABEL, NAME, PRICE, DELETE_DISPLAY_PRICE_WARNING } from "../../../../constants/strings";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import { FaDollarSign } from "react-icons/fa";
import { isDecimalNumberWithDecimalPoints } from "../../../../helpers/utils";
import { MenuItem, ModalState } from "../../../../constants/staticTypes";
import { PriceKeys, PriceType } from "../../../../constants/constants";
import Form from 'react-bootstrap/Form';
import { JTranslation, TranslationContext, jTranslationText } from '../../../../helpers/jTranslate';

type Props = {
    defaultCount: number,
    formData: MenuItem,
    row: number,
    setDefaultRowInfo: React.Dispatch<React.SetStateAction<number>>,
    setFormData: React.Dispatch<React.SetStateAction<MenuItem>>,
    setRowToBeDeleted: React.Dispatch<React.SetStateAction<number>>,
    showWarningModal: (state: ModalState, title: string) => void,
    type: PriceType,
}

export const PriceComponent = ({ defaultCount, formData, row, type, setFormData, setDefaultRowInfo, showWarningModal, setRowToBeDeleted }: Props) => {
    const prices = type === PriceType.ITEM ? formData.itemPrices : formData.addonPrices;
    const key = type === PriceType.ITEM ? PriceKeys.ITEM : PriceKeys.ADDON;
    const rowId = type === PriceType.ITEM ? PriceKeys.ITEM : PriceKeys.ADDON;

    const [translationText, setTranslatedText] = useState<{
        price: string,
        label: string,
      }>({ 
        price: PRICE,
        label: LABEL,
    });

    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext;

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: PRICE, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: LABEL, typeCase: 'pascal', translationContext }),
                ])
                setTranslatedText({
                    price: translations[0] ?? PRICE,
                    label: translations[1] ?? LABEL,
                })
            } catch {
                setTranslatedText({
                    price: PRICE,
                    label: LABEL,
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition } = useSortable({ id: row });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        touchAction: "none"
    };
    return (
        <div key={row} style={style} className="col-md-12 mb-2 mt-0" id={rowId + "#" + row}  >
            <div className="add-p-bg-sub ps-3 pe-3 pb-2 pt-3  ">
                <div className="col-12 gy-2 gx-3">
                    <div className="d-flex">
                        <div className="flex-grow-0 d-flex align-items-center justify-content-center">
                            <div className="drag-ic" ref={setNodeRef} {...attributes} {...listeners}>
                                <i className="ri-grid-fill"></i>
                            </div>
                        </div>
                        <div className="flex-grow-1 pe-2 ps-2">
                            <div className="d-flex row gy-2 gx-3">
                                <div className="col-md-4">
                                    <label className="visually-hidden" htmlFor={"priceValue#" + row}><JTranslation typeCase="pascal" text={PRICE} /></label>
                                    <div className="input-group">
                                        <div className="input-group-text"><FaDollarSign /></div>
                                        <input data-testid={"priceValue#" + row}
                                            value={prices[row - 1]?.fieldValue !== null ? prices[row - 1]?.fieldValue : ""}
                                            type="text" className="form-control" id={"priceValue#" + row}
                                            placeholder={translationText.price}
                                            maxLength={10}
                                            autoComplete="off"
                                            onChange={(e) => {
                                                if (isDecimalNumberWithDecimalPoints(e, 2)) {
                                                    let fieldName = prices[row - 1].fieldName;
                                                    let isDefault = prices[row - 1].isDefault;
                                                    prices.splice(row - 1, 1, {
                                                        fieldName: fieldName,
                                                        fieldValue: e.target.value,
                                                        isDefault: isDefault
                                                    });
                                                    setFormData({
                                                        ...formData, [key]: prices
                                                    });
                                                }
                                            }} />
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <label className="visually-hidden" htmlFor={"priceField#" + row}>{NAME}</label>
                                    <input type="text" className="form-control"
                                        autoComplete="off"
                                        placeholder={translationText.label}
                                        value={prices[row - 1]?.fieldName !== null ? prices[row - 1]?.fieldName : ""}
                                        maxLength={500}
                                        onChange={(e) => {
                                            let fieldValue = prices[row - 1].fieldValue;
                                            let isDefault = prices[row - 1].isDefault;
                                            prices.splice(row - 1, 1, {
                                                fieldName: e.target.value,
                                                fieldValue: fieldValue,
                                                isDefault: isDefault
                                            });
                                            setFormData({
                                                ...formData, [key]: formData[key]
                                            });
                                        }}
                                        id={"priceField#" + row} data-testid={"priceField#" + row} />
                                </div>
                            </div>
                        </div>
                        <div className="flex-grow-0 d-flex align-items-center justify-content-center">
                            <div className="delete text-center" onClick={() => {
                                setRowToBeDeleted(row - 1)
                                if (prices[row - 1]?.isDefault) {
                                    defaultCount === 1 ? showWarningModal("DELETE_PRICE", DELETE_DEFAULT_PRICE_WARNING) : showWarningModal("DELETE_PRICE", DELETE_DISPLAY_PRICE_WARNING)
                                } else {
                                    showWarningModal("DELETE_PRICE", DELETE_WARNING)
                                }
                            }}>
                                <i className="ri-delete-bin-6-line"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-2">
                    <div className="d-flex">
                        {type === PriceType.ITEM &&
                            <>
                                <div
                                    className="d-flex"
                                    onClick={() => {
                                        if (!(prices[row - 1]?.isDefault === false && defaultCount === 2)) {
                                            setDefaultRowInfo(row - 1)
                                            if (prices[row - 1]?.isDefault) {
                                                defaultCount === 1 ? showWarningModal('CHANGE_DEFAULT_PRICE', LAST_ACTIVE_DEFAULT_PRICE_WARNING) : showWarningModal("CHANGE_DEFAULT_PRICE", ACTIVE_DEFAULT_PRICE_WARNING)
                                            } else {
                                                showWarningModal("CHANGE_DEFAULT_PRICE", DEFAULT_PRICE_WARNING)
                                            }
                                        }
                                    }}>
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        label=' '
                                        checked={prices[row - 1]?.isDefault}
                                        disabled={prices[row - 1]?.isDefault === false && defaultCount === 2}
                                        onClick={() => { }}
                                    />
                                    <span className={prices[row - 1]?.isDefault === false && defaultCount === 2 ? "disabled" : ""}>
                                        <JTranslation typeCase="pascal" text={DEFAULT_PRICE} />
                                    </span>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PriceComponent
