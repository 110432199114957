import { useEffect, useContext, useState } from 'react'
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { CommonCtx } from "../../../context/CommonCtxProvider";
import { FormClass } from "../../../constants/constants";
import { ServerInfo, PersonalInfo, SurveyMode, MaskedPersonalInfo } from "../../../constants/staticTypes";
import * as EmailValidator from 'email-validator';
import DatePicker from 'react-date-picker';
import {
    ANNIVERSARY,
    BIRTHDAY,
    DATE_ERROR,
    EMAIL_ERROR,
    EMAIL_TEXT,
    FILL_YOUR_PERSONAL_INFO,
    FULL_NAME_ERROR,
    FULL_NAME,
    LOADING,
    PHONE_TEXT,
    SELECT,
    SERVER_NAME,
    ZIP_CODE_ERROR,
    ZIP_CODE,
} from '../../../constants/strings'
import {
  JTranslation,
  jTranslationText,
  TranslationContext,
} from '../../../helpers/jTranslate'
import Select from 'react-select'

type Props = {
    personalInfo: PersonalInfo,
    setPersonalInfo: React.Dispatch<React.SetStateAction<PersonalInfo>>,
    formValidations: { [key: string]: string; },
    setFormValidations: React.Dispatch<React.SetStateAction<{ [key: string]: string; }>>,
    serversList: ServerInfo[],
    tenantsIsLoading: boolean
    pageMode: SurveyMode
    setPageMode: React.Dispatch<React.SetStateAction<SurveyMode>>
    maskedPersonalInfo: MaskedPersonalInfo
}

function SurveyPersonalInfo({ personalInfo, setPersonalInfo, formValidations, setFormValidations,
    serversList: tenantsList, tenantsIsLoading, pageMode, setPageMode, maskedPersonalInfo }: Props) {
    // CONTEXT VARIABLES
    const { posUserInfo, isUserPos } = useContext(CommonCtx)
       const translationContext = useContext(TranslationContext)

       const [ serverApiMessageTranslated, setServerApiMessageTranslated ] = useState('loading......')

         useEffect(() => {
           const fetchData = async () => {
             if (tenantsIsLoading) {
               const message = await TranslateText(LOADING)
               setServerApiMessageTranslated(message)
             } else {
               const message = await TranslateText(SELECT)
               setServerApiMessageTranslated(message)
             }
           }

           fetchData()
         }, [tenantsIsLoading, translationContext])

       const TranslateText = async (text: string): Promise<string> => {
         let returnText = text
         try {
           const translatedText = await jTranslationText({
             text,
             translationContext,
           })
           returnText = translatedText || ''
         } catch (e) {
           console.log('Translation error on ItemView:', e)
         }
         return returnText
       }
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // set valid class if the form field value is valid
    const setValidField = (key: string, value: boolean) => {
        if (formValidations[key] === FormClass.INVALID && value) {
            setFormValidations({
                ...formValidations,
                [key]: FormClass.VALID
            })
        }
    }

    return (
      <div className='row mt-3'>
        <div className='col-md-12 text-center  mb-4'>
          <h3 className=''>
            <JTranslation text={FILL_YOUR_PERSONAL_INFO} />
          </h3>
        </div>
        <div className='col-md-12 d-flex justify-content-end  mb-2'>
          {pageMode === 'Masked' && (
            <button
              className='btn btn-secondary d-flex'
              onClick={() => setPageMode('Edit')}
            >
              <i className='ri-pencil-line me-1'></i>Edit
            </button>
          )}
        </div>
        <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3'>
          <label className='form-label'>
            <JTranslation text={EMAIL_TEXT} />
            <span className='mandatory ms-1 fw-bold '>*</span>{' '}
          </label>
          <input
            type='text'
            className={formValidations.email}
            id='validationCustom03'
            autoComplete='off'
            value={personalInfo.emailId}
            data-testid="email"
            onChange={(e) => {
              setPersonalInfo({ ...personalInfo, emailId: e.target.value })
              setValidField('email', EmailValidator.validate(e.target.value))
            }}
          />
          <div className='invalid-feedback' data-testid='error-email'>
            <JTranslation text={EMAIL_ERROR} />
          </div>
        </div>

        <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3'>
          <label className='form-label'>
            <JTranslation text={PHONE_TEXT} />
          </label>
          {pageMode === 'Masked' ? (
            <input
              type='text'
              disabled
              className='form-control'
              autoComplete='off'
              value={maskedPersonalInfo.phoneNumber}
              data-testid="phone"
            />
          ) : (
            <input
              type='text'
              className='form-control'
              id='validationCustom03'
              maxLength={12}
              autoComplete='off'
              value={personalInfo.phoneNumber}
              data-testid="phone"
              onChange={(e) => {
                setPersonalInfo({
                  ...personalInfo,
                  phoneNumber: e.target.value.replace(/\D/, ''),
                })
              }}
            />
          )}
        </div>

        <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3'>
          <label className='form-label'>
            <JTranslation text={FULL_NAME} />
            <span className='mandatory ms-1 fw-bold '>*</span>{' '}
          </label>
          {pageMode === 'Masked' ? (
            <input
              type='text'
              disabled
              className='form-control'
              autoComplete='off'
              value={maskedPersonalInfo.fullName}
              data-testid="full-name"
            />
          ) : (
            <>
              <input
                type='text'
                className={formValidations.fullName}
                id='validationCustom03'
                autoComplete='off'
                maxLength={130}
                value={personalInfo.fullName}
                data-testid="full-name"
                onChange={(e) => {
                  setPersonalInfo({ ...personalInfo, fullName: e.target.value })
                  setValidField('fullName', e.target.value?.trim().length > 0)
                }}
              />
              <div className='invalid-feedback' data-testid='error-name'>
                <JTranslation text={FULL_NAME_ERROR} />
              </div>
            </>
          )}
        </div>

        <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3'>
          <label className='form-label'>
            <JTranslation text={SERVER_NAME} />
          </label>
          <Select
            className='form-select-survey'
            aria-label='Default select example'
            data-testid="server-name"
            options={tenantsList?.map((item) => {
              return {
                value: item.id,
                label: item.preferredName,
                id: item.id,
              }
            })}
            placeholder={serverApiMessageTranslated}
            isSearchable
            value={tenantsList
              .filter(tenant => tenant?.id === personalInfo?.serverId)
              .map((item) => ({ value: item.id, label: item.preferredName, id: item.id }))
            }
            onChange={(selectedItem) => {
              const serverInfo = tenantsList.find(tenant => tenant?.id === selectedItem?.id)
              if(serverInfo) {
                setPersonalInfo({
                  ...personalInfo,
                  serverId: serverInfo.id,
                  serverName: serverInfo.preferredName,
                  serverPosNumber: serverInfo.posNumber,
                })
              }
            }}
          />
        </div>

        <div className='col-sm-12 col-md-6 col-lg-3 col-xl-3 mb-3'>
          <label className='form-label'>
            <JTranslation text={ANNIVERSARY} />
          </label>
          {pageMode === 'Masked' ? (
            <input
              type='text'
              disabled
              className='form-control'
              autoComplete='off'
              value={maskedPersonalInfo.anniversaryDate}
            />
          ) : (
            <>
              <DatePicker
                className={'custom-datepicker'}
                onChange={(date) => {
                  setPersonalInfo({
                    ...personalInfo,
                    anniversaryDate: date as Date,
                  })
                }}
                value={personalInfo.anniversaryDate}
                disableCalendar={true}
                format='MM/dd/yyyy'
                yearPlaceholder='YYYY'
                monthPlaceholder='MM'
                dayPlaceholder='DD'
                monthAriaLabel='MONTH'
                maxDate={new Date()}
                minDate={new Date('01-01-1900')}
                calendarAriaLabel='dob'
                nativeInputAriaLabel='date of birth'
                showLeadingZeros={false}
                required={false}
                data-testid="anniversary-datepicker"
              />
            </>
          )}
        </div>

        <div className='col-sm-12 col-md-6 col-lg-3 col-xl-3 mb-3'>
          <label className='form-label'>
            <JTranslation text={BIRTHDAY} />
            <span className='mandatory ms-1 fw-bold '>*</span>{' '}
          </label>
          {pageMode === 'Masked' ? (
            <input
              type='text'
              disabled
              className='form-control'
              autoComplete='off'
              value={maskedPersonalInfo.birthDate}
            />
          ) : (
            <>
              <DatePicker
                className={'custom-datepicker'}
                onChange={(date) => {
                  setPersonalInfo({
                    ...personalInfo,
                    birthDate: date as Date,
                  })
                  setValidField('birthday', date !== null)
                }}
                value={personalInfo.birthDate}
                disableCalendar={true}
                format='MM/dd/yyyy'
                yearPlaceholder='YYYY'
                monthPlaceholder='MM'
                dayPlaceholder='DD'
                monthAriaLabel='MONTH'
                maxDate={new Date()}
                minDate={new Date('01-01-1900')}
                calendarAriaLabel='dob'
                nativeInputAriaLabel='date of birth'
                showLeadingZeros={false}
                required={false}
                data-testid="birthday-datepicker"
              />
              {formValidations.birthday === FormClass.INVALID && (
                <div
                  className='invalid-feedback'
                  style={{ display: 'block' }}
                  data-testid='error-date'
                >
                  <JTranslation text={DATE_ERROR} />
                </div>
              )}
            </>
          )}
        </div>
        <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3'>
          <label className='form-label'>
            <JTranslation text={ZIP_CODE} />
            <span className='mandatory ms-1 fw-bold '>*</span>{' '}
          </label>
          {pageMode === 'Masked' ? (
            <input
              type='text'
              disabled
              className='form-control'
              autoComplete='off'
              value={maskedPersonalInfo.zipCode}
              data-testid="zipcode"
            />
          ) : (
            <>
              <input
                type='text'
                className={formValidations.zip}
                id='validationCustom03'
                autoComplete='off'
                maxLength={6}
                value={personalInfo.zipCode}
                data-testid="zipcode"
                onChange={(e) => {
                  setPersonalInfo({
                    ...personalInfo,
                    zipCode: e.target.value.replace(/\D/, ''),
                  })
                  setValidField('zip', e.target.value?.trim().length > 0)
                }}
              />
              <div className='invalid-feedback' data-testid='error-zip'>
                <JTranslation text={ZIP_CODE_ERROR} />
              </div>
            </>
          )}
        </div>
      </div>
    )
}

export default SurveyPersonalInfo;