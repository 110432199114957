import { useCallback } from 'react'

// Define the hook's return type
type UseShareResult = {
    share: (data: { title?: string; text?: string; url?: string }) => Promise<void>
    isSupported: boolean
}

const useShare = (): UseShareResult => {
    // Determine if the Web Share API is supported
    const isSupported = 'share' in navigator

    // Create a share function that wraps navigator.share()
    const share = useCallback(
        async (data: { title?: string; text?: string; url?: string }) => {
            if (isSupported) {
                try {
                    await navigator.share(data)
                    // console.log('Successfully shared')
                } catch (error) {
                    // console.log('Error sharing:', error)
                }
            } else {
                console.log('Web Share API not supported on this platform')
            }
        },
        [isSupported]
    )

    return { share, isSupported }
}

export default useShare
