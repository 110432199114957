import React, { useEffect, useState, useContext } from "react";
import { ADD_EXTRA, BACK, ITEM_NOT_AVAILABLE, RECOMMENDATION_WITH, VIEW_WINE_HISTORY, REDIRECTION_FROM_ITEM_WARNING, REDIRECTION_FROM_ITEM_TITLE, KEY_INGREDIENTS, VIEW_DETAILS } from "../../../constants/strings";
import { AxiosResponse } from "axios";
import { BreadCrumb } from 'primereact/breadcrumb';
import { capitalizeFirstLetter, getNumberWithTwoDecimals, isJSONObjectEmpty, unescapeHtml} from "../../../helpers/utils";
import { CommonCtx } from "../../../context/CommonCtxProvider";
import { CUSTOM_MENU_LIST_GUEST_VIEW } from "../../../constants/queryKeys";
import { customMenuItemDetailsView } from "../../../helpers/customMenuHelper";
import { FileType, TENANT_NAME, SpecialMenuIdPrefixes, toastMessageInitialData } from "../../../constants/constants";
import { findMenuAndCategoryName, getDefaultPrice, getMinPriceArrayWithoutSort, getValueAfterHash, getBreadCrumbPath } from "../../../helpers/menuViewHelper";
import { FnBCtx } from "../../../context/MoveItemCtxProvider";
import { getDomainFromURL } from "../../../helpers/utils";
import { JTranslation, jTranslationText, TranslationContext } from '../../../helpers/jTranslate'
import { MenuItem, Price, Gallery, CustomMenuCategoriesAndItems, ToastMessageProps, CustomMenuGuestView } from "../../../constants/staticTypes";
import { routes } from "../../../constants/routes";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import { v4 as uuidv4 } from "uuid";
import CommonModal from "../../common_modal/CommonModal";
import GalleryView from "./GalleryView";
import Loader from "../../loader/Loader";
import placeholderImage from "../../../assets/images/menu/540x360.png";
import ToastAlert from "../../alert/ToastAlert";
import useMutationHook from "../../../hooks/useMutationHook";
import withPublicHeader from "../../../hoc/withPublicHeader";
import { useTrackEvent } from "../../../hooks/useTrackEvent";
import useCustomBackButton from "../../../hooks/useCustomBackButton";

type ItemState = {
  item: CustomMenuCategoriesAndItems,
  menu: CustomMenuGuestView
}

function SpecialMenuItemView() {
  useCustomBackButton(routes.menu_view)
  const navigate = useNavigate();
  const location = useLocation();
  const idPrefix = SpecialMenuIdPrefixes.SPECIAL_MENU_ITEM;
  const itemParams = location.state as ItemState;
  const queryClient = useQueryClient();
  const TENANT = localStorage.getItem(TENANT_NAME);
  const customMenuMutation = useMutationHook(queryClient, true)
  const { itemId } = useParams()
  const { trackEvent } = useTrackEvent()
  // CONTEXT VARIABLES
  const { fnBGlobalSettings } = useContext(CommonCtx)
  const { rerender, setRerender, userSelectedMenu } = useContext(FnBCtx)
  // STATE VARIABLES
  const [galleryImages, setGalleryImages] = useState<Gallery[]>();
  const [item, setItem] = useState<MenuItem>();
  const [suggestedItem, setSuggestedItem] = useState<MenuItem>();
  const [showWarning, setShowWarning] = useState(false)
  const [breadCrumbItems, setBreadCrumbItems] =  useState<{ label: string, url?: string }[]>([])
  const [completeCustomMenuId, setCompleteCustomMenuId] = useState("")
  // ALERT VARIABLES
  const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)

  const specialMenuList = queryClient.getQueryData(CUSTOM_MENU_LIST_GUEST_VIEW) as AxiosResponse<any, any>;
  const translationContext = useContext(TranslationContext)
  const TranslateText = async (text: string) => {
    let returnText = text
    try {
      const translatedText = await jTranslationText({
        text,
        translationContext,
      })
      returnText = translatedText || ''
    } catch (e) {
      console.log('Translation error on ItemView:', e)
    }
    return returnText
  }

  useEffect(() => {
    const onSuccess = (data: MenuItem) => {
      setItem(data)
    }

    const onError = (message: string, variant: string) => {
      setToastMessage({ message, variant, show: true })
    }

    if (completeCustomMenuId) {
      customMenuItemDetailsView({ itemMenuId: completeCustomMenuId }, customMenuMutation, onSuccess, onError)
    }
  }, [completeCustomMenuId]) // eslint-disable-line


  useEffect(() => {
    if (itemId) {
      setCompleteCustomMenuId(`${idPrefix}${itemId}`)
    }
  }, [itemId]) // eslint-disable-line

  useEffect(() => {
    let isMounted = true
    const fetchData = async () => {
      if (
        !isJSONObjectEmpty(specialMenuList?.data?.data) &&
        !isJSONObjectEmpty(item)
      ) {
        const path = getBreadCrumbPath({
          data: [specialMenuList.data.data],
          id: completeCustomMenuId,
        })

        try {

          const categoryName = await TranslateText(path?.categoryName || '')
          const itemName = await TranslateText(path?.itemName || '')

          if (isMounted) {
            if (path) {
              setBreadCrumbItems([
                { label: categoryName || '', url: `${routes.special_menu}/${itemParams.menu.menuCode}` },
                { label: itemName || '' },
              ])
            }
          }
        } catch (error) {
          // Handle any errors here
        }
      }
    }

    fetchData()

    return () => {
      // Cleanup function to set isMounted to false when the component unmounts
      isMounted = false
    }
  }, [specialMenuList, item, translationContext]) // eslint-disable-line

  // on back press
  window.onpopstate = () => {
    if (suggestedItem) {
      navigate(`${routes.item_view_special_menu}${location.search}`, {
        state: { item: { ...itemParams.item } }
      });
    } else {
      if(itemParams.menu.menuCode) {
        navigate(`${routes.special_menu}/${itemParams.menu.menuCode}${location.search}`);
      }
    }
    document.body.classList.remove("p-overflow-hidden");
  };


  // get item price with field names
  const getItemPricesAndFieldNames = (itemPrices: Price[]) => {
    return getDefaultPrice(itemPrices).map((item) => {
      let fieldName = itemPrices.filter((price) => Number(price.fieldValue) === Number(item) ? price.fieldName : null)
      return (
        <div key={uuidv4()} className="d-flex mb-1 align-items-baseline flex-column">
          <div className="price-ft  w-100" >
            <div className="d-flex text-nowrap overflow-hidden align-items-baseline">
              <div className="flex-grow-0 me-2">
                {item === "Infinity" ? "..." : `$${item} `}
              </div>
              <span className="flex-grow-1 overflow-hidden text-ellipsis">
                <JTranslation text={fieldName[0]?.fieldName} />
              </span>
            </div>

          </div>
        </div>
      )
    })
  }

  // get list of prices with display price on top
  const getListOfPrices = (item: MenuItem) => {
    let prices: JSX.Element[] = [];
    let defaultPriceFound = false
    item?.itemPrices?.forEach((price, index) => {
      if (price.isDefault) {
        defaultPriceFound = true
        prices.unshift(
          <h3
            key={uuidv4()}
            className='item-ttl item-price mb-2 me-3 display-price'
          >
            ${getNumberWithTwoDecimals(+(price?.fieldValue))}{' '}
            <span>
              {' '}
              <JTranslation text={price?.fieldName || ''} />

            </span>
          </h3>
        )
      } else {
        prices.push(
          <h3 key={uuidv4()} className='item-ttl item-price mb-2 me-3'>
            ${getNumberWithTwoDecimals(+(price?.fieldValue))}{' '}
            <span>
              {' '}
              <JTranslation text={price?.fieldName || ''} />
            </span>
          </h3>
        )
      }
    })
    // condition to make the first price bold if no display price is set
    if (!defaultPriceFound) {
      prices = []
      item?.itemPrices?.forEach((price, index) => {
        if (index === 0) {
          prices.push(
            <h3
              key={uuidv4()}
              className='item-ttl item-price mb-2 me-3 display-price'
            >
              ${getNumberWithTwoDecimals(+(price?.fieldValue))}{' '}
              <span>
                {' '}
                <JTranslation text={price?.fieldName || ''} />
              </span>
            </h3>
          )
        } else {
          prices.push(
            <h3
              key={uuidv4()}
              className='item-ttl item-price mb-2 me-3'
            >
              ${getNumberWithTwoDecimals(+(price?.fieldValue))}{' '}
              <span>
                {' '}
                <JTranslation text={price?.fieldName || ''} />
              </span>
            </h3>
          )
        }
      })
    }

    return prices
  }

  const RedirectWarningModalContent = (<p>{REDIRECTION_FROM_ITEM_WARNING}</p>)

  const closeModalAndRedirect = () => {
    setShowWarning(false)
    setRerender(rerender + 1)
    // redirect to menu view page
    window.location.replace(routes.menu_view);
  }



  // set image / video gallery
  useEffect(() => {
    window.scrollTo(0, 0);
    let galleryArray: Gallery[] = [];
    item?.signedPhotoKeys?.forEach(img => {
      let newImage = {
        src: img,
        type: FileType.IMAGE
      }
      galleryArray.push(newImage);
    });

    item?.signedVideoKeys?.forEach(video => {
      let newVideo = {
        src: video,
        type: FileType.VIDEO
      }
      galleryArray.push(newVideo);
    });
    setGalleryImages(galleryArray);
  }, [item]);

  /**
   * if
   * the isDefault is true bring that to the start of the array
   * else
   * find the lowest price and bring that to the start of the array
   */
  useEffect(() => {
    let itemPrices = item?.itemPrices;
    let isDefaultFound = false

    itemPrices?.forEach((item, index) => {
      if (item.isDefault) {
        isDefaultFound = true
        itemPrices?.unshift(item);
        itemPrices?.splice(index + 1, 1);
      }
    });


    if (!isDefaultFound && itemPrices !== undefined) {
      getMinPriceArrayWithoutSort(itemPrices)
    }

    return () => {
      itemPrices?.forEach((item, index) => {
        if (item.isDefault) {
          isDefaultFound = true
          itemPrices?.unshift(item);
          itemPrices?.splice(index + 1, 1);
        }
      });
    }

  }, [item]);

  return (
    <>
      {!item ? (
        // <Loader />
        <></>
      ) : (
        <div className='container'>
          {/* redirection modal popup */}
          <CommonModal
            show={showWarning}
            modalContent={RedirectWarningModalContent}
            title={REDIRECTION_FROM_ITEM_TITLE}
            hideCancel
            modalSize='md'
            onHide={closeModalAndRedirect}
            callback={closeModalAndRedirect}
            hideCloseButton
          />

          <ToastAlert
            data-testid="toast"
            message={toastMessage.message}
            onClose={() => setToastMessage(toastMessageInitialData)}
            show={toastMessage.show}
            variant={toastMessage.variant}
          />

          <div className='row mb-3 mt-3'>
            <div className='col-lg-12 p-0'>
              <div className='card'>
                <div className='card-header p-0 card-breadcrumb'>
                  <div className=' '>
                    <div className='col-md-12 d-flex align-items-center p-2'>
                      <div
                        className='button btn  back-btn back-btn-new'
                        title={BACK}
                        onClick={() => navigate(-1)}
                      >
                        <i className='ri-arrow-left-line'></i>
                      </div>
                      <BreadCrumb
                        className='custom-breadcrumb'
                        model={breadCrumbItems}
                      />
                    </div>
                  </div>
                </div>
                <div className='card-body px-0'>
                  <div className='row mt-3 '>
                    <div className='col-md-5 image-large-sticky'>
                      <div className='item-list-image-large mb-3 p-0'>
                        <img
                          src={
                            (item?.signedPhotoKeys &&
                              item.signedPhotoKeys[0]) ||
                            placeholderImage
                          }
                          className=''
                          alt=''
                        />
                      </div>
                      <GalleryView galleryImages={galleryImages} id={item.id} />
                    </div>
                    <div className='col-md-7 item-view-main '>
                      <div className='item-view-bg shadow-sm rounded p-3'>
                        <h3 className='item-ttl'>
                          <JTranslation
                            text={item?.itemName!}
                          //   type='html'
                          />
                        </h3>

                        <h5 className='text-danger'>
                          {' '}
                          <JTranslation
                            text={item?.isAvailable ? '' : ITEM_NOT_AVAILABLE}
                          //   type='html'
                          />
                        </h5>
                        <div>
                          {item.itemTag?.split(',').map((tag) => (
                            <span key={uuidv4()} className="item-badge mb-1 mt-1">{<JTranslation text={tag} />}</span>
                          ))}
                        </div>
                        <JTranslation
                          text={
                            unescapeHtml(item?.itemDescription)
                          }
                          type='html'
                        />

                        <div className='price-main mt-4 mb-4'>
                          {item?.isMarketPrice ? (
                            <div className='market-price-text'>
                              <JTranslation
                                text={fnBGlobalSettings.marketPriceLabel}
                              />
                            </div>
                          ) : (
                            getListOfPrices(item!)
                          )}
                        </div>

                        {item?.historyInfo?.title && (
                          <div
                            className='view-wine-history mb-4 mt-1'
                            onClick={() => {
                              navigate(routes.wine_history, {
                                state: { item: item.historyInfo },
                              })

                              findMenuAndCategoryName({ menuItemId: item.id, selectedMenu: userSelectedMenu, isCustomMenu: true })
                                .then((result) => {
                                  if (result) {
                                    const { categoryName, menuName } = result
                                    const { itemName, itemPrices } = item
                                    const price = getDefaultPrice(itemPrices).map((item) => (item === 'Infinity' ? null : `$${item}`))
                                    // Google Analytics
                                    trackEvent({
                                      eventName: 'custom_menu_view_wine_history',
                                      label: 'Click on "view history" link on custom menu item view page',
                                      value: {
                                        page_path: '/item-view-special-menu',
                                        page_location: `${getDomainFromURL()}/item-view-special-menu`,
                                        menu_name: menuName,
                                        menu_category: categoryName,
                                        menu_item_name: itemName,
                                        menu_item_price: price,
                                        wine_origin: item.historyInfo.originCountry
                                      }
                                    });
                                  }
                                })
                            }}
                          >
                            <i className='ri-goblet-fill'></i>{' '}
                            <JTranslation
                              text={VIEW_WINE_HISTORY}
                            //   type='html'
                            />
                          </div>
                        )}

                        {item?.selectedAddonsData && (
                          <div className='add-extra-box mb-5'>
                            <h5>
                              {item.selectedAddonsData.length
                                ? <JTranslation
                                  text={ADD_EXTRA}

                                />
                                : null}
                            </h5>
                            {item.selectedAddonsData // nosonar
                              .sort(
                                (a, b) =>
                                  Number(a.addonPrice) - Number(b.addonPrice)
                              ) // Sort the array numerically based on addonPrice
                              .map((addon, index) => {
                                return (
                                  <div key={uuidv4()} className='price'>
                                    <span className='col-8'>
                                      <JTranslation
                                        text={addon.addonName}
                                      //   type='html'
                                      />{' '}
                                      :
                                    </span>
                                    {addon.addonPrice && (
                                      <span className='fw-bold ms-3 add-extra-price'>
                                        ${' '}
                                        {getNumberWithTwoDecimals(+(addon?.addonPrice))}
                                      </span>
                                    )}
                                  </div>
                                )
                              })}
                          </div>
                        )}

                        {item?.selectedKeyIngredientsData && (
                          <div className='add-extra-box mb-5'>
                            {item.selectedKeyIngredientsData.length ? (
                              <div className='d-flex align-items-center mb-3'>
                                <h5 className='mb-0 ms-2'>
                                  <JTranslation
                                    text={KEY_INGREDIENTS}
                                  //   type='html'
                                  />{' '}
                                </h5>
                              </div>
                            ) : null}
                            <div className='key-ingredients-container'>
                              {item.selectedKeyIngredientsData // nosonar
                                .sort((a, b) =>
                                  a.ingredientName.localeCompare(
                                    b.ingredientName
                                  )
                                ) // Sort in ascending order based on ingredientName
                                .map((ingredient) => (
                                  <div
                                    className='d-flex key-ingredients-link align-items-center mb-2'
                                    key={uuidv4()}
                                    onClick={() => {
                                      navigate(
                                        `${routes.menu_item_view
                                        }/${getValueAfterHash(
                                          item.id ?? ''
                                        )}/${getValueAfterHash(
                                          ingredient.ingredientId
                                        )}`
                                      )

                                      findMenuAndCategoryName({ menuItemId: item.id, selectedMenu: userSelectedMenu, isCustomMenu: true })
                                        .then((result) => {
                                          if (result) {
                                            const { categoryName, menuName } = result
                                            const { itemName, itemPrices } = item
                                            const price = getDefaultPrice(itemPrices).map((item) => (item === 'Infinity' ? null : `$${item}`))
                                            // Google Analytics
                                            trackEvent({
                                              eventName: 'custom_menu_view_ingredient',
                                              label: 'Click on "view more" button on custom menu ingredients list',
                                              value: {
                                                page_path: '/item-view-special-menu',
                                                page_location: `${getDomainFromURL()}/item-view-special-menu`,
                                                menu_name: menuName,
                                                menu_category: categoryName,
                                                menu_item_name: itemName,
                                                menu_item_price: price,
                                                ingredient_name: ingredient.ingredientName
                                              }
                                            });
                                          }
                                        })
                                    }
                                    }
                                  >
                                    <div className='ing-link icon me-2'>
                                      <i className='ri-link'></i>
                                    </div>
                                    <div className='ing-link ingredients-name ellipsis'>
                                      <JTranslation
                                        text={ingredient.ingredientName}
                                      />
                                    </div>
                                    <div className='ing-link bullet white-space-nowrap text-muted'>
                                      <JTranslation
                                        text={VIEW_DETAILS}
                                      />
                                      <i className='ri-arrow-right-line ms-1'></i>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        )}

                        <div className='additional-info-box mb-3'>
                          {item?.additionalInfo?.map(
                            (additionalInfo, index) => {
                              return (
                                <React.Fragment key={uuidv4()}>
                                  <JTranslation
                                    text={unescapeHtml(additionalInfo?.textContent || "")}
                                    type='html'
                                  />
                                </React.Fragment>
                              )
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {item?.suggestedItemsData &&
                    item?.suggestedItemsData?.length > 0 && (
                      <div className='row mb-3 recomendation-ttle mt-5'>
                        <h5 className='fw-bold'>
                          <JTranslation
                            text={`${capitalizeFirstLetter(TENANT)}
                            ${RECOMMENDATION_WITH}
                            <span>${item?.itemName}</span>`}
                            type='html'
                          />
                        </h5>
                      </div>
                    )}

                  <div className='row'>
                    {item?.suggestedItemsData?.map((suggestion, index) => {
                      return (
                        <div
                          key={uuidv4()}
                          className='col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-3'
                          onClick={() => {
                            window.scrollTo(0, 0)
                            setSuggestedItem(suggestion)
                          }}
                        >
                          <div
                            key={uuidv4()}
                            className='item-list-main shadow-custom item-recommendation p-2'
                          >
                            <div className='item-list-image me-2 col-4  p-0'>
                              <img
                                src={
                                  (suggestion?.signedPhotoKeys?.length &&
                                    suggestion.signedPhotoKeys[0]) ||
                                  placeholderImage
                                }
                                className=''
                                alt=''
                              />
                            </div>

                            <div className='item-list-content col-8  p-2'>
                              <div className='title mb-2'>
                                <JTranslation
                                  text={suggestion.itemName}
                                // type='html'
                                />{' '}
                              </div>

                              <div className='mb-2'>
                                {fnBGlobalSettings.showMenuSuggestedItemCardPrice && (
                                  <div className='price-ft display-price'>
                                    {!suggestion.isMarketPrice &&
                                      getDefaultPrice(suggestion.itemPrices) ? (
                                      getItemPricesAndFieldNames(
                                        suggestion.itemPrices
                                      )
                                    ) : (
                                      <div className='market-price-text'>
                                        <JTranslation
                                          text={
                                            fnBGlobalSettings.marketPriceLabel
                                          }
                                        />
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row  pb-3'>
            <div className='col-md-12 p-0'>
              <div className='adbox-container '>
                <div className='d-flex align-items-ceneter'>
                  {/* <div className='flex-grow-1'>
                    <img src={adLeftImg} alt='' />
                  </div> */}
                  <div className='flex-grow-1 p-5 txt-section'>
                    <h3>
                      <JTranslation text='Win a Free Dinner' />
                    </h3>
                    <h4 className='mb-3'>
                      <JTranslation text='By Reviewing Us' />
                    </h4>
                    <button
                      className='btn  btn-lg  btn-custom-primary'
                      onClick={() =>
                        navigate(`${routes.survey_guest}${location.search}`)
                      }
                    >
                      {' '}
                      <JTranslation text='Take Survey' />
                      <i className='ri-arrow-right-line'></i>
                    </button>
                  </div>
                  <div className='overlay'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default withPublicHeader(SpecialMenuItemView);