import { SPECIAL_EFFECTS_NAME } from '../../constants/constants'
import {
  celebrationEffectsChristmasType,
  celebrationEffectsType,
  celebrationEffectsValentinesDayType,
  StPatricksDayEffectsType,EasterEffectsType, CincoDeMayoEffectsType, MothersDayEffectsType
} from '../../constants/staticTypes'

export const getChristmasEffects = ():
  | celebrationEffectsChristmasType
  | undefined => {
  try {
    const storedEffectsString = localStorage.getItem(SPECIAL_EFFECTS_NAME)
    if (storedEffectsString) {
      const storedEffects: celebrationEffectsType =
        JSON.parse(storedEffectsString)
      return storedEffects.christmasNewYear
    }
  } catch (error) {
    console.error('Error parsing Christmas effects:', error)
  }
  return undefined
}

export const getValentinesDayEffects = ():
  | celebrationEffectsValentinesDayType
  | undefined => {
  try {
    const storedEffectsString = localStorage.getItem(SPECIAL_EFFECTS_NAME)
    if (storedEffectsString) {
      const storedEffects: celebrationEffectsType =
        JSON.parse(storedEffectsString)
      return storedEffects.valentinesDay
    }
  } catch (error) {
    console.error('Error parsing Christmas effects:', error)
  }
  return undefined
}

export const getStPatricksDayEffects = ():
  | StPatricksDayEffectsType
  | undefined => {
  try {
    const storedEffectsString = localStorage.getItem(SPECIAL_EFFECTS_NAME)
    if (storedEffectsString) {
      const storedEffects: celebrationEffectsType =
        JSON.parse(storedEffectsString)
      return storedEffects.stPatricksDay as StPatricksDayEffectsType
    }
  } catch (error) {
    console.error('Error parsing Christmas effects:', error)
  }
  return undefined
}
export const getEasterDayEffects = ():
  | EasterEffectsType
  | undefined => {
  try {
    const storedEffectsString = localStorage.getItem(SPECIAL_EFFECTS_NAME)
    if (storedEffectsString) {
      const storedEffects: celebrationEffectsType =
        JSON.parse(storedEffectsString)
      return storedEffects.easter as EasterEffectsType
    }
  } catch (error) {
    console.error('Error parsing Christmas effects:', error)
  }
  return undefined
}
export const getCincoDeMayoDayEffects = ():
  | CincoDeMayoEffectsType
  | undefined => {
  try {
    const storedEffectsString = localStorage.getItem(SPECIAL_EFFECTS_NAME)
    if (storedEffectsString) {
      const storedEffects: celebrationEffectsType =
        JSON.parse(storedEffectsString)
      return storedEffects.cincoDeMayo as CincoDeMayoEffectsType
    }
  } catch (error) {
    console.error('Error parsing Cinco de Mayo effects:', error)
  }
  return undefined
}
export const getMothersDayEffects = ():
  | MothersDayEffectsType
  | undefined => {
  try {
    const storedEffectsString = localStorage.getItem(SPECIAL_EFFECTS_NAME)
    if (storedEffectsString) {
      const storedEffects: celebrationEffectsType =
        JSON.parse(storedEffectsString)
      return storedEffects.mothersDay as MothersDayEffectsType
    }
  } catch (error) {
    console.error('Error parsing Mothers day effects:', error)
  }
  return undefined
}
