import { AxiosResponse } from "axios";
import { QueryClient, UseMutationResult } from "react-query";
import { Payload } from "../constants/staticTypes";
import { AlertVariant, HttpMethods, PermissionKeys, SubPermissionKeys } from "../constants/constants";
import { checkPermission, getErrorMessage } from "./utils";
import { shiftSwapAddApi, shiftSwapListApi, shiftSwapTakeApi, shiftSwapUpdateApi } from "../constants/apiEndPoints";
import HttpServiceHelper from "./httpServiceHelper";
import { PublishedSchedule, ScheduleData, ShiftsByDate, Staff } from "./workTimeScheduleHelper";
import { Badge } from "primereact/badge";
import { JTranslation } from "./jTranslate";
import { Dispatch, SetStateAction } from "react";
import MlxPopover from "../components/common/MlxPopover";
import { MORE_INFO } from "../constants/strings";
import MlxTooltip from "../components/common/MlxTooltip";
import dayjs from "dayjs";

export type SwapRequestType = 'swap' | 'pool'

export type SwapRequestStatus =
    'PENDING' |  // Owner
    'CANCELLED' | // Owner
    'DELETED' | // Owner
    'STAFF_APPROVED' | // second user
    'STAFF_REJECTED' | // second user
    'MANAGER_APPROVED' | // Admin approval
    'MANAGER_REJECTED' | // Admin rejection
    'POOL_REQUESTS' | // View pool requests
    'CONFIRM_DELETE' | // Confirm popup
    'TAKE_POOL_REQUEST' // Take pool request

export type SwapRequestForm = {
    scheduleId: string;
    fromShiftId: string;
    fromTenantStaffId: string;
    fromStartTime: string;
    fromEndTime: string;
    fromRole: string;
    toShiftId?: string;
    toTenantStaffId?: string;
    toStartTime?: string;
    toEndTime?: string;
    toRole?: string;
    requestType: SwapRequestType;
    skipChecks: boolean;
}

export type SwapPoolRequest = {}

export type UserDetails = {
    firstName: string;
    id: string;
    lastName: string;
    photoKey: string;
    preferredName: string;
}

export type ShiftSwapPoolRequests = {
    createdAt: string;
    id: string;
    shiftData: PublishedSchedule;
    shiftId: string;
    shiftSwapRequestId: string;
    status: SwapRequestStatus;
    tenantStaffId: string;
    updatedAt: string;
    tenantStaff: SwapStaffDetails
}

export type SwapStaffDetails = Staff & {
    user: UserDetails
}

export type SwapRequestList = {
    actionStatus: SwapRequestStatus;
    fromShift: PublishedSchedule;
    fromShiftId: string;
    fromTenantStaff: SwapStaffDetails;
    fromTenantStaffId: string;
    id: string;
    poolRequests: SwapPoolRequest;
    requestType: SwapRequestType;
    scheduleId: string;
    shiftSwapPoolRequests: ShiftSwapPoolRequests[]
    tenantId: string;
    toShift: PublishedSchedule;
    toShiftId: string;
    toTenantStaff: SwapStaffDetails;
    toTenantStaffId: string;
    updatedAt: string;
}

export type SwapRequestConfirmPopupType = {
    visible: boolean;
    buttonName: string;
    requestId: string;
    action: SwapRequestStatus;
    approverRole: ApproverRole
};

export type ApproverRole = 'self' | 'staff' | 'manager'

export type TakeSwapRequestForm = {
    shiftSwapRequestId: string;
    shiftId: string;
    tenantStaffId: string;
}

export const initialValuesForSwapRequestConfirmPopup: SwapRequestConfirmPopupType = {
    visible: false,
    buttonName: '',
    requestId: '',
    action: 'PENDING',
    approverRole: 'self'
}

export const swapRequestTypes: { value: SwapRequestType; name: string }[] = [
    { value: 'swap', name: 'Employee' },
    { value: 'pool', name: 'Pool' },
]

export const swapRequestFormInitialValues: SwapRequestForm = {
    scheduleId: '',
    fromShiftId: '',
    fromTenantStaffId: '',
    fromStartTime: '',
    fromEndTime: '',
    fromRole: '',
    toShiftId: '',
    toTenantStaffId: '',
    toStartTime: '',
    toEndTime: '',
    toRole: '',
    requestType: 'swap',
    skipChecks: false,
}

export const checkIfManageSwapRequest = (queryClient: QueryClient) => {
    // return false
    return checkPermission(queryClient, PermissionKeys.MANAGE_SWAP_SHIFT, SubPermissionKeys.MANAGE_REQUEST)
}

export const handleShiftRequest = (
    shiftRequestMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    param: SwapRequestForm,
    onSuccess: (data: { message: string; status: boolean; }) => void,
    onError: (res: string, variant: string) => void
) => {
    shiftRequestMutation.mutate(
        {
            url: shiftSwapAddApi,
            method: HttpMethods.POST,
            data: param,
        },
        {
            onSuccess: (res) => onSuccess(res.data.data),
            onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
        }
    )
}

export const handleTakeSwapShiftRequest = (
    takeSwapPoolRequestMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    param: TakeSwapRequestForm,
    onSuccess: (data: { message: string; status: boolean; }) => void,
    onError: (res: string, variant: string) => void
) => {
    takeSwapPoolRequestMutation.mutate(
        {
            url: shiftSwapTakeApi,
            method: HttpMethods.POST,
            data: param,
        },
        {
            onSuccess: (res) => onSuccess(res.data.data),
            onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
        }
    )
}

// TODO: needs change
export const updateSwapRequestStatus = (
    shiftRequestMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    param: { id: string, newStatus: SwapRequestStatus, approverRole: ApproverRole },
    onSuccess: (data: { message: string; status: boolean; }) => void,
    onError: (res: string, variant: string) => void
) => {
    shiftRequestMutation.mutate(
        {
            url: shiftSwapUpdateApi,
            method: HttpMethods.POST,
            data: param,
        },
        {
            onSuccess: (res) => onSuccess({ message: res?.data?.message, status: res?.data?.status }),
            onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
        }
    )
}

export const deleteSwapRequest = (
    shiftRequestMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    param: { id: string },
    onSuccess: (data: { message: string; status: boolean; }) => void,
    onError: (res: string, variant: string) => void
) => {
    shiftRequestMutation.mutate(
        {
            url: shiftSwapAddApi,
            method: HttpMethods.DELETE,
            data: param,
        },
        {
            onSuccess: (res) => onSuccess({ message: res?.data?.message, status: res?.data?.status }),
            onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
        }
    )
}

// fetch all users
export const getSwapList = (staffId?: string) => {
    return HttpServiceHelper({
        url: `${shiftSwapListApi}${staffId ? '/' + staffId : ''}`,
        method: HttpMethods.GET,
    })
}

export const checkIfShiftTaken = (swapRequestList: SwapRequestList, staffId: string) => {
    if (swapRequestList?.requestType === 'pool') {
        const swapRequestPoolList = swapRequestList?.shiftSwapPoolRequests ?? []
        const checkShiftTaken = swapRequestPoolList?.find(item => item?.tenantStaffId === staffId)
        return !!checkShiftTaken
    }
    return false
}

export const statusTemplate = (rowData: SwapRequestList, isAdmin: boolean) => {
    const currentStatus = rowData?.actionStatus

    let note: string | null = null
    let badgeText: string = ''
    let badgeSeverity: "success" | "info" | "warning" | "danger" | null = null
    switch (currentStatus) {
        case 'PENDING':
            badgeText = 'Pending'
            badgeSeverity = 'warning'
            break;
        case 'CANCELLED':
            badgeText = 'Cancelled'
            badgeSeverity = 'info'
            note = `` // TODO: add note
            break;
        case 'DELETED':
            badgeText = 'Deleted'
            badgeSeverity = 'info'
            note = `` // TODO: add note
            break;
        case 'STAFF_APPROVED':
            badgeText = 'Approval Pending'
            badgeSeverity = 'warning'
            note = `` // TODO: add note
            if (isAdmin) {
                badgeText = 'Staff accepted'
                note = `` // TODO: add note
            }
            break;
        case 'STAFF_REJECTED':
            badgeText = 'Staff rejected'
            badgeSeverity = 'danger'
            note = `` // TODO: add note
            break;
        case 'MANAGER_APPROVED':
            badgeText = 'Approved'
            badgeSeverity = 'success'
            break;
        case 'MANAGER_REJECTED':
            badgeText = 'Manager rejected'
            badgeSeverity = 'danger'
            note = `` // TODO: add note
            break;
        default:
            return <></>
    }
    return (
        <div className="d-flex align-items-center justify-content-start">
            <Badge
                className="me-2 custom-badge"
                value={<JTranslation typeCase="pascal" text={badgeText} />}
                severity={badgeSeverity}>
            </Badge>
            {note && (
                <MlxPopover data={{ title: MORE_INFO, body: note }} />
            )}
        </div>
    )
}

export const checkIfStaffHaveShifts = (scheduleData: ScheduleData | null, staffId: string) => {
    if (scheduleData) {
        let shifts: any[] = []
        scheduleData?.staffs?.forEach(item => {
            if (staffId === item?.id) {
                const currentShifts = Object.values(item?.shifts ?? {})
                if (currentShifts.length > 0) {
                    shifts = [...shifts, currentShifts]
                }
            }
        })
        return !!shifts.length
    }
    return false
}

export const actionTemplate = (
    rowData: SwapRequestList,
    staffId: string,
    isAdmin: boolean,
    isMobileView: boolean,
    setRequestInfo: Dispatch<SetStateAction<SwapRequestConfirmPopupType>>,
    staffsWithSchedule: ScheduleData | null
) => {
    const currentStatus = rowData?.actionStatus
    switch (currentStatus) {
        case 'PENDING':
            // check if direct request
            if (rowData?.requestType === 'swap' && rowData?.toTenantStaff?.id === staffId) {
                return (
                    <div className="d-flex align-items-center justify-content-start">
                        <button
                            className={`btn btn-sm btn-success me-2 ${isMobileView && 'rounded-circle'}`}
                            type="button"
                            id={'staff-approve-' + rowData?.id}
                            onClick={(e) => {
                                e.stopPropagation()
                                setRequestInfo({
                                    buttonName: 'staff-approve-' + rowData?.id,
                                    visible: true,
                                    requestId: rowData?.id,
                                    action: 'STAFF_APPROVED',
                                    approverRole: 'staff'
                                })
                            }}
                        >
                            <i className={`ri-check-fill ${!isMobileView && 'me-1'}`}></i>
                            {!isMobileView && <JTranslation typeCase="pascal" text={'Accept'} />}
                        </button>

                        <button
                            className={`btn btn-danger btn-sm me-2 ${isMobileView && 'rounded-circle'}`}
                            type="button"
                            id={'staff-reject-' + rowData?.id}
                            onClick={(e) => {
                                e.stopPropagation()
                                setRequestInfo({
                                    buttonName: 'staff-reject-' + rowData?.id,
                                    visible: true,
                                    requestId: rowData?.id,
                                    action: 'STAFF_REJECTED',
                                    approverRole: 'staff'
                                })
                            }}
                        >
                            <i className={`ri-close-fill ${!isMobileView && 'me-1'}`}></i>
                            {!isMobileView && <JTranslation typeCase="pascal" text={'Reject'} />}
                        </button>
                    </div>
                )
            }
            // check if pool request
            if (rowData?.requestType === 'pool') {
                if (rowData?.fromTenantStaff?.id === staffId) {
                    return (
                        <div className="d-flex align-items-center justify-content-start">
                            <div className="d-flex align-items-center justify-content-start">
                                <button
                                    className={`btn btn-sm btn-warning me-2 ${isMobileView && 'rounded-circle'}`}
                                    type="button"
                                    id={rowData?.id}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setRequestInfo({
                                            buttonName: rowData?.id,
                                            visible: false,
                                            requestId: rowData?.id,
                                            action: 'POOL_REQUESTS',
                                            approverRole: 'staff'
                                        })
                                    }}
                                >
                                    <i className={`ri-eye-line ${!isMobileView && 'me-1'}`}></i>
                                    {!isMobileView && <JTranslation typeCase="pascal" text={'View Requests'} />}
                                </button>
                            </div>
                            <div className={isMobileView ? 'rounded-background shadow-sm' : 'ms-2'}>
                                <MlxTooltip target='.delete'>
                                    <i
                                        id={'delete-' + rowData?.id}
                                        className={'ri-delete-bin-line delete'}
                                        data-pr-tooltip={'Delete'}
                                        data-pr-position='bottom'
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            // open reject popup
                                            setRequestInfo({
                                                buttonName: 'delete-' + rowData?.id,
                                                visible: false,
                                                requestId: rowData?.id,
                                                action: 'CONFIRM_DELETE',
                                                approverRole: 'staff'
                                            })
                                        }}
                                    ></i>
                                </MlxTooltip>
                            </div>
                        </div>
                    )
                }

                // check if shift taken
                if (!checkIfShiftTaken(rowData, staffId) && checkIfStaffHaveShifts(staffsWithSchedule, staffId)) {
                    return (
                        <div className="d-flex align-items-center justify-content-start">
                            <div className="d-flex align-items-center justify-content-start">
                                <button
                                    className={`btn btn-sm btn-warning me-2 ${isMobileView && 'rounded-circle'}`}
                                    type="button"
                                    id={rowData?.id}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setRequestInfo({
                                            buttonName: rowData?.id,
                                            visible: false,
                                            requestId: rowData?.id,
                                            action: 'TAKE_POOL_REQUEST',
                                            approverRole: 'staff'
                                        })
                                    }}
                                >
                                    <i className={`ri-calender-check ${!isMobileView && 'me-1'}`}></i>
                                    {!isMobileView && <JTranslation typeCase="pascal" text={'Take Shift'} />}
                                </button>
                            </div>
                        </div>
                    )
                }
            }
            return <></>
        case 'CANCELLED':
            return <></>
        case 'DELETED':
            return <></>
        case 'STAFF_APPROVED':
            if (isAdmin) {
                return (
                    <div className="d-flex align-items-center justify-content-start">
                        <button
                            className={`btn btn-sm btn-success me-2 ${isMobileView && 'rounded-circle'}`}
                            type="button"
                            id={'admin-approve-' + rowData?.id}
                            onClick={(e) => {
                                e.stopPropagation()
                                setRequestInfo({
                                    buttonName: 'admin-approve-' + rowData?.id,
                                    visible: true,
                                    requestId: rowData?.id,
                                    action: 'MANAGER_APPROVED',
                                    approverRole: 'manager'
                                })
                            }}
                        >
                            <i className={`ri-check-fill ${!isMobileView && 'me-1'}`}></i>
                            {!isMobileView && <JTranslation typeCase="pascal" text={'Approve'} />}
                        </button>

                        <button
                            className={`btn btn-danger btn-sm me-2 ${isMobileView && 'rounded-circle'}`}
                            type="button"
                            id={'admin-reject-' + rowData?.id}
                            onClick={(e) => {
                                e.stopPropagation()
                                setRequestInfo({
                                    buttonName: 'admin-reject-' + rowData?.id,
                                    visible: true,
                                    requestId: rowData?.id,
                                    action: 'MANAGER_REJECTED',
                                    approverRole: 'manager'
                                })
                            }}
                        >
                            <i className={`ri-close-fill ${!isMobileView && 'me-1'}`}></i>
                            {!isMobileView && <JTranslation typeCase="pascal" text={'Reject'} />}
                        </button>
                    </div>
                )
            }
            return <></>
        case 'STAFF_REJECTED':
            return <></>
        case 'MANAGER_APPROVED':
            return <></>
        case 'MANAGER_REJECTED':
            return <></>
        default:
            return <></>
    }
}

export const checkIfDateExpired = (shift: PublishedSchedule) => {
    const today = dayjs() // TODO: may change with time zone
    return !dayjs(shift?.scheduleDate).isAfter(today)
}

export const allowSwapRequest = (shift: PublishedSchedule) => {
    const notAllowedSwapStatus = ['PENDING', 'STAFF_APPROVED']
    const shiftDetails = [...shift?.fromSwapRequests, ...shift?.toSwapRequests]
    const swapShiftDetails = shiftDetails?.find((item) => item?.id === shift?.assignedShifts?.poolData?.swapRequestId)
    const currentSwapStatus = swapShiftDetails?.actionStatus ?? ''

    if (notAllowedSwapStatus.includes(currentSwapStatus) || checkIfDateExpired(shift)) {
        return false
    }
    return true
}

export const getBackgroudColorForSwap = (shift: PublishedSchedule) => {
    const shiftDetails = [...shift?.fromSwapRequests, ...shift?.toSwapRequests]
    const swapShiftDetails = shiftDetails?.find((item) => item?.id === (shift?.assignedShifts?.poolData?.swapRequestId ?? shift?.assignedShifts?.oldData?.swapRequestId))

    if (checkIfDateExpired(shift)) return 'swap-schedule-card-expired'

    if (!swapShiftDetails) return 'swap-schedule-card-default'

    switch (swapShiftDetails?.actionStatus) {
        case 'PENDING':
        case 'STAFF_APPROVED':
            return 'swap-schedule-card-pending'
        // case 'CANCELLED':
        // case 'DELETED':
        // case 'MANAGER_REJECTED':
        // case 'STAFF_REJECTED':
        //     return 'swap-schedule-card-rejected'
        // case 'MANAGER_APPROVED':
        //     return 'swap-schedule-card-approved'
        default:
            return 'swap-schedule-card-default'
    }
}