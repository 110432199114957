import { useRef, useEffect } from 'react'
import { Field, FieldArray, FieldProps } from 'formik'
import { JTranslation } from '../../../helpers/jTranslate'
import { InputNumber } from 'primereact/inputnumber'
import { RadioButton } from 'primereact/radiobutton'
import { RecurrenceProps } from '../../../constants/staticTypes'

const Daily = ({ errors, every, setNextOccurrence }: RecurrenceProps) => {
    const inputNumberRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (inputNumberRef.current) {
            inputNumberRef.current.autocomplete = "off"
        }
    }, [])

    return (
        <>
            <div className="row mb-4">
                <div className='d-flex align-items-center gap-2'>
                    <span className="">
                        <Field name="every">
                            {({ field, form }: FieldProps) => (
                                <RadioButton
                                    name={field.name}
                                    className={errors.every ? 'p-invalid' : undefined}
                                    value="every"
                                    data-testid="daily-radio-option-every"
                                    checked={field.value === "every"}
                                    onChange={(e) => {
                                        form.setFieldValue(field.name, e.value)
                                        setNextOccurrence(null)
                                    }}
                                />
                            )}
                        </Field>
                        <label className='ms-2'><JTranslation typeCase="none" text="Every" /></label>
                    </span>
                    <span className="">
                        <Field name="repeatsEveryNthDay">
                            {({ field, form }: FieldProps) => (
                                <>
                                    <InputNumber
                                        name={field.name}
                                        inputClassName={errors.repeatsEveryNthDay ? 'p-invalid' : undefined}
                                        onChange={(e) => {
                                            if (e.value) form.setFieldValue(field.name, e.value)
                                        }}
                                        value={field.value}
                                        disabled={every === "every-weekday" || every === undefined}
                                        min={1}
                                        max={99}
                                        data-testid="daily-repeats-every-nth-day"
                                        className='width-50'
                                        inputRef={inputNumberRef}
                                    />
                                    <span className='m-2'>
                                        <JTranslation typeCase="none" text={field.value > 1 ? "days" : 'day'} />
                                    </span>
                                </>
                            )}
                        </Field>
                    </span>
                </div>
            </div>
            <div className='d-flex gap-2 mb-2'>
                <Field name="every">
                    {({ field, form }: FieldProps) => (
                        <RadioButton
                            name={field.name}
                            className={errors.every ? 'p-invalid' : undefined}
                            value="every-weekday"
                            data-testid="daily-radio-option-every-weekday"
                            checked={field.value === "every-weekday"}
                            onChange={(e) => {
                                form.setFieldValue(field.name, e.value)
                                setNextOccurrence(null)
                            }}
                        />
                    )}
                </Field>
                <JTranslation typeCase="none" text="Every weekday" />
            </div>
            <div className='row ps-5'>
                {/* <FieldArray name="selectedDaysOfWeek">
                    {() => (
                        <>
                            {['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].map((day) => (
                                <label htmlFor={day} key={day} className='mb-2'>
                                    <Field
                                        name="selectedDaysOfWeek"
                                        style={errors.selectedDaysOfWeek ? { border: '1px solid red' } : undefined}
                                        disabled={every === "every" || every === undefined}
                                        type="checkbox"
                                        className="me-2 form-check-input"
                                        value={day}
                                        autoComplete="off"
                                        data-testid={`daily-repeats-every-${day}`}
                                    />
                                    <JTranslation typeCase="none" text={day.charAt(0).toUpperCase() + day.slice(1)} />
                                </label>
                            ))}
                        </>
                    )}
                </FieldArray> */}

                <label htmlFor="sunday" className='mb-2'>
                    <Field
                        name="selectedDaysOfWeek"
                        style={errors.selectedDaysOfWeek ? { border: '1px solid red' } : undefined}
                        disabled={every === "every" || every === undefined}
                        type="checkbox"
                        className="me-2 form-check-input"
                        value="sunday"
                        autoComplete="off"
                        data-testid="daily-repeats-every-sunday"
                    />
                    <JTranslation typeCase="pascal" text='sunday' />
                </label>
                <label htmlFor="monday" className='mb-2'>
                    <Field
                        name="selectedDaysOfWeek"
                        style={errors.selectedDaysOfWeek ? { border: '1px solid red' } : undefined}
                        disabled={every === "every" || every === undefined}
                        type="checkbox"
                        className="me-2 form-check-input"
                        value="monday"
                        autoComplete="off"
                        data-testid="daily-repeats-every-monday"
                    />
                    <JTranslation typeCase="pascal" text='monday' />
                </label>
                <label htmlFor="tuesday" className='mb-2'>
                    <Field
                        name="selectedDaysOfWeek"
                        style={errors.selectedDaysOfWeek ? { border: '1px solid red' } : undefined}
                        disabled={every === "every" || every === undefined}
                        type="checkbox"
                        className="me-2 form-check-input"
                        value="tuesday"
                        autoComplete="off"
                        data-testid="daily-repeats-every-tuesday"
                    />
                    <JTranslation typeCase="pascal" text='tuesday' />
                </label>
                <label htmlFor="wednesday" className='mb-2'>
                    <Field
                        name="selectedDaysOfWeek"
                        style={errors.selectedDaysOfWeek ? { border: '1px solid red' } : undefined}
                        disabled={every === "every" || every === undefined}
                        type="checkbox"
                        className="me-2 form-check-input"
                        value="wednesday"
                        autoComplete="off"
                        data-testid="daily-repeats-every-wednesday"
                    />
                    <JTranslation typeCase="pascal" text='wednesday' />
                </label>
                <label htmlFor="thursday" className='mb-2'>
                    <Field
                        name="selectedDaysOfWeek"
                        style={errors.selectedDaysOfWeek ? { border: '1px solid red' } : undefined}
                        disabled={every === "every" || every === undefined}
                        type="checkbox"
                        className="me-2 form-check-input"
                        value="thursday"
                        autoComplete="off"
                        data-testid="daily-repeats-every-thursday"
                    />
                    <JTranslation typeCase="pascal" text='thursday' />
                </label>
                <label htmlFor="friday" className='mb-2'>
                    <Field
                        name="selectedDaysOfWeek"
                        style={errors.selectedDaysOfWeek ? { border: '1px solid red' } : undefined}
                        disabled={every === "every" || every === undefined}
                        type="checkbox"
                        className="me-2 form-check-input"
                        value="friday"
                        autoComplete="off"
                        data-testid="daily-repeats-every-friday"
                    />
                    <JTranslation typeCase="pascal" text='friday' />
                </label>
                <label htmlFor="saturday" className='mb-2'>
                    <Field
                        name="selectedDaysOfWeek"
                        style={errors.selectedDaysOfWeek ? { border: '1px solid red' } : undefined}
                        disabled={every === "every" || every === undefined}
                        type="checkbox"
                        className="me-2 form-check-input"
                        value="saturday"
                        autoComplete="off"
                        data-testid="daily-repeats-every-saturday"
                    />
                    <JTranslation typeCase="pascal" text='saturday' />
                </label>

                {/* Display error message if any */}
                {errors.selectedDaysOfWeek && (
                    <div className="formik-error">{errors.selectedDaysOfWeek}</div>
                )}
            </div>
        </>
    )
}

export default Daily