// useVisibleColumns.ts
import { useState, useEffect } from 'react';

export const useVisibleColumns = (totalColumns: number, visibleCount: number) => {
    const [visibleColumns, setVisibleColumns] = useState<number[]>([]);
    const [startIndex, setStartIndex] = useState(0);

    useEffect(() => {
        const visibleColumnsUpdate = Array.from({ length: totalColumns }, (_, index) => index).slice(startIndex, startIndex + visibleCount)
        setVisibleColumns(visibleColumnsUpdate);
    }, [startIndex, visibleCount, totalColumns]);

    const showNext = () => {
        if (startIndex + visibleCount < totalColumns) {
            setStartIndex(startIndex + visibleCount);
        }
    };

    const showPrev = () => {
        if (startIndex - visibleCount >= 0) {
            setStartIndex(startIndex - visibleCount);
        } else {
            setStartIndex(0);
        }
    };

    return { visibleColumns, showNext, showPrev, startIndex };
};
