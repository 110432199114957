import { useState, useEffect, useContext } from 'react'
import { AlertVariant, toastMessageInitialData } from '../../constants/constants'
import { CommonCtx } from '../../context/CommonCtxProvider'
import { dateFormatter, getErrorMessage, timeFormatter, unescapeHtml } from '../../helpers/utils'
import { Gallery, PostResponseType, ToastMessageProps, NewsAndEventsSlugId, PostType } from '../../constants/staticTypes'
import { getSinglePostDetails, emptyPostValues } from '../../helpers/newsAndEventsHelper'
import { JTranslation } from '../../helpers/jTranslate'
import { processImageURLsForGallery, processVideoURLsForGallery } from '../../helpers/menuViewHelper'
import { routes } from '../../constants/routes'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { v4 as uuidv4 } from "uuid"
import GalleryViewWithFooter from '../../components/f&b_menu/guest/GalleryViewWithFooter'
import Loader from '../../components/loader/Loader'
import SharePopup from './SharePopup'
import ToastAlert from '../../components/alert/ToastAlert'
import withPublicHeader from '../../hoc/withPublicHeader'

const PostDetailedView = () => {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const { urlSlug } = useParams()
    const { postViewFromInternal } = useContext(CommonCtx)
    const location = useLocation()

    // STATE VARIABLES
    const [displayPosition, setDisplayPosition] = useState(false);
    const [galleryView, setGalleryView] = useState<Gallery[]>([])
    const [loading, setLoading] = useState(true)
    const [position, setPosition] = useState('center');
    const [postInfo, setPostInfo] = useState<PostResponseType>(emptyPostValues)
    const [postSlug, setPostSlug] = useState('')
    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)


    const showShare = (position: string) => {
        setDisplayPosition(true);

        if (position) {
            setPosition(position);
        }
    }

    const hideShare = () => {
        setDisplayPosition(false);
    }


    const onGetDetailsSuccess = (res: PostResponseType) => {
        setLoading(false)
        setPostInfo(res)
        setGalleryView(() => {
            let images = processImageURLsForGallery(res.signedPhotosKeys)
            let videos = processVideoURLsForGallery(res.signedVideoKeys)
            return [...images, ...videos]
        })
    }

    const onError = (error: string) => {
        setToastMessage({
            message: error,
            show: true,
            variant: AlertVariant.ERROR,
        })
        setLoading(false)
    }

    // effect to set the postId
    useEffect(() => {
        if (urlSlug) setPostSlug(urlSlug)
        window.scrollTo(0, 0)
    }, [urlSlug])

    useEffect(() => {
        if (postSlug) {
            const param: NewsAndEventsSlugId = { urlSlug: postSlug }
            getSinglePostDetails(queryClient, param)
                .then((res) => {
                    onGetDetailsSuccess(res.data.data)
                })
                .catch((res) => {
                    onError(getErrorMessage(res))
                });
        }
    }, [postSlug]) // eslint-disable-line

    if (loading) {
        return <Loader />
    }

    return (
        <>
            {/* toaster component */}
            <ToastAlert
                show={toastMessage.show}
                onClose={() => setToastMessage(toastMessageInitialData)}
                message={toastMessage.message}
                variant={toastMessage.variant}
            />
            {/* share popup component */}
            <SharePopup displayPosition={displayPosition} hideShare={hideShare} title={postInfo.title} urlSlug={postInfo.urlSlug} postType={PostType.NEWS} />
            <div className="container">
                <div className="row mb-3 mt-3">
                    <div className="col-lg-12 p-0">
                        <div className="card">
                            <div className="card-header p-0 card-breadcrumb">
                                {postViewFromInternal && <div className=" ">
                                    <div className="col-md-12 d-flex align-items-center p-2 justify-content-between">
                                        <div
                                            className="button btn  back-btn back-btn-new"
                                            data-testid="back-btn"
                                            onClick={() => {
                                                navigate(-1)
                                            }}
                                        >
                                            <i className="ri-arrow-left-line"></i>
                                        </div>
                                        <div className="flex-grow-0">
                                            <button className="shareButton button btn  back-btn back-btn-new" data-testid="share-btn" onClick={() => showShare('bottom')}>
                                                <i className="ri-share-line"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row pb-3 mb-3">
                                            <div className="col-sm-6 col-md-5 col-lg-4 d-flex align-items-start mb-3 justify-content-center ingredients-gallery">
                                                {/* gallery view component  */}
                                                <GalleryViewWithFooter galleryImages={galleryView} />
                                            </div>
                                            <div className="col-sm-6 col-md-7 col-lg-8">
                                                <h3 className='d-flex mb-3 mt-1'>
                                                    <JTranslation text={postInfo.title} />
                                                </h3>
                                                {postInfo.postTags?.split(',').map((tag) => (
                                                    <span key={uuidv4()} className="item-badge mb-1">{<JTranslation text={tag} />}</span>
                                                ))}

                                                {/* NEWS */}
                                                {postInfo.postType === PostType.NEWS &&
                                                    <>
                                                        <div className="d-flex align-items-center mb-3 mt-1">
                                                            <div className="flex-grow-1 d-flex fw-bold opacity-75">
                                                                <span className="me-2 d-flex">
                                                                    <i className="ri-calendar-line"></i>
                                                                </span>
                                                                <span>
                                                                    {
                                                                        <JTranslation text={dateFormatter({ date: postInfo.publishDate })} />
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                <div className="mb-3 mt-1">
                                                    <JTranslation text={unescapeHtml(postInfo?.description)} type="html" />
                                                </div>

                                                {/* EVENTS */}
                                                {postInfo.postType === PostType.EVENT &&
                                                    <>
                                                        {postInfo.location
                                                            ? (
                                                                <div className="d-flex align-items-center mb-3 mt-1">
                                                                    <div className="flex-grow-1 d-flex fw-bold opacity-75">
                                                                        <span className="me-2 d-flex">
                                                                            <i className="ri-map-pin-line"></i>
                                                                        </span>
                                                                        <span>
                                                                            {' '}
                                                                            {<JTranslation text={postInfo.location} />}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            )
                                                            : ""}

                                                        {postInfo.isRecurring
                                                            ? ""
                                                            : (
                                                                <div className="d-flex align-items-center mb-3 mt-1">
                                                                    <div className="flex-grow-1 d-flex fw-bold opacity-75">
                                                                        <span className="me-2 d-flex">
                                                                            <i className="ri-calendar-line"></i>
                                                                        </span>
                                                                        <span>
                                                                            {' '}
                                                                            {
                                                                                <JTranslation text={dateFormatter({ date: postInfo.startDate })} />
                                                                            } To {<JTranslation text={dateFormatter({ date: postInfo.endDate })} />}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }

                                                        <div className="d-flex align-items-center mb-3 mt-1">
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-grow-1 fw-bold opacity-75">
                                                                    <span className="me-2">
                                                                        <i className="ri-time-line"></i>
                                                                    </span>
                                                                    <span>
                                                                        {<JTranslation text={timeFormatter({ time: postInfo.startTime })} />} To{' '}
                                                                        {<JTranslation text={timeFormatter({ time: postInfo.endTime })} />}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row  pb-3">
                    <div className="col-md-12 p-0">
                        <div className="adbox-container ">
                            <div className="d-flex align-items-ceneter">
                                {/* <div className="flex-grow-1">
                                    <img src={adLeftImg} alt="" />
                                </div> */}

                                <div className="flex-grow-1 p-5 txt-section">
                                    <h3>
                                        <JTranslation text="Win a Free Dinner" />
                                    </h3>
                                    <h4 className="mb-3">
                                        <JTranslation text="By Reviewing Us" />
                                    </h4>
                                    <button
                                        className="btn  btn-lg  btn-custom-primary"
                                        onClick={() =>
                                            navigate(`${routes.survey_guest}${location.search}`)}
                                        data-testid="survey"
                                    >
                                        {' '}
                                        <JTranslation text="Take Survey" />
                                        <i className="ri-arrow-right-line"></i>
                                    </button>
                                </div>
                                <div className="overlay"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default withPublicHeader(PostDetailedView)
