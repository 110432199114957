import React from 'react'
import { routes } from '../constants/routes'

// change style according to page
export const getSidebarDivClassName = (location: any) => {
    switch (location.pathname) {
        case routes.manage_users:
            return 'row min-H-inner-new'
        case routes.manage_coupon:
        case routes.game_management:
            return 'row scroll-fix-container'
        case routes.file_management:
            return 'row scroll-fix-container h-100'
        case routes.file_type_verification:
            return 'row scroll-fix-container'
        case routes.dashboard:
            return 'row'
        default:
            return 'row min-H-inner'
    }
}

// check the route and set the active menu
export const ResetActiveMenu = (
    location: any,
    menuClicked: Function,
    setActiveMenu: React.Dispatch<React.SetStateAction<string | null>>
) => {
    switch (location.pathname) {
        case routes.dashboard:
            setActiveMenu('dashboard')
            menuClicked('dashboard')
            break
        case routes.user_roles:
        case routes.manage_users:
            setActiveMenu('collapseUserManagement')
            menuClicked('collapseUserManagement')
            break
        case routes.menu:
            setActiveMenu('fbmenu')
            menuClicked('fbmenu')
            break
        case routes.survey:
        case routes.game_management:
        case routes.manage_coupon:
            setActiveMenu('collapseGuestManagement')
            menuClicked('collapseGuestManagement')
            break
        case routes.guest_info:
            setActiveMenu('guestInfo')
            menuClicked('guestInfo')
            break
        case routes.file_management:
            setActiveMenu('collapseFileManagement')
            menuClicked('collapseFileManagement')
            break
        case routes.survey_report:
            setActiveMenu('reports')
            menuClicked('reports')
            break
        default:
            break
    }
}
