import { useEffect, useState, useContext } from 'react'
import '../assets/styles/scss/main.scss'
import './styles/sidebar.scss'
import { BiFoodMenu } from 'react-icons/bi'
import { checkPermission, getStaffId, setSidebarToggle, showDashboard } from '../helpers/utils'
import { COPYRIGHT } from '../constants/strings'
import { FaAngleDown } from 'react-icons/fa'
import { FnBCtx } from '../context/MoveItemCtxProvider'
import { IoFastFoodOutline } from 'react-icons/io5'
import { IoMdPizza } from 'react-icons/io'
import { NavLink, useLocation } from 'react-router-dom'
import { PermissionKeys, SubPermissionKeys } from '../constants/constants'
import { ResetActiveMenu, getSidebarDivClassName } from '../helpers/withSidebarHelper'
import { RiPagesLine, RiLeafLine } from 'react-icons/ri'
import { PiBackpackLight } from "react-icons/pi";
import { TbCalendarCog } from "react-icons/tb";
import { routes } from '../constants/routes'
import { SIDEBAR_TOGGLE } from '../constants/queryKeys'
import { useQuery, useQueryClient } from 'react-query'
import HeaderComponent from '../components/header/HeaderComponent'
import Package from '../../package.json'
import { JTranslation } from '../helpers/jTranslate'

const withSidebar = (WrappedComponent: Function) => {
	return (props: any) => {
		const queryClient = useQueryClient()
		const location = useLocation()
		const toggle = useQuery(SIDEBAR_TOGGLE, () => setSidebarToggle(), { retry: 0, enabled: false })
		const [activeMenu, setActiveMenu] = useState<string | null>('')
		const menuClicked = (menuItem: string) => localStorage.setItem('activeMenu', menuItem)
		const staffId = getStaffId()
		// context variables
		const { setDndLock } = useContext(FnBCtx)

		// check multiple permissions
		const checkPermissions = (permissions: string[]) => {
			return permissions.some((permission) => checkPermission(queryClient, permission))
		}

		// navigate to add-ons
		const navigateToAddon = () => {
			const addOnPermissions = checkPermission(queryClient, PermissionKeys.MANAGE_ADDONS)
			const addOnGroupPermissions = checkPermission(queryClient, PermissionKeys.MANAGE_ADDON_GROUPS)
			if (addOnPermissions) {
				return routes.manage_addons
			} else if (addOnGroupPermissions) {
				return routes.manage_addons_groups
			}
			return ''
		}

		// removes static background image
		document.body.classList.remove('user-style')

		// open and close left panel based on screen size
		window.onresize = function () {
			if (window.innerWidth <= 1180.98) {
				queryClient.setQueryData(SIDEBAR_TOGGLE, setSidebarToggle(true))
			} else {
				queryClient.setQueryData(SIDEBAR_TOGGLE, setSidebarToggle(false))
			}
		}

		// effect to set the active menu item
		useEffect(() => {
			setActiveMenu(localStorage.getItem('activeMenu'))
			// open and close left panel based on screen size
			if (window.innerWidth <= 1180.98) {
				queryClient.setQueryData(SIDEBAR_TOGGLE, setSidebarToggle(true))
			} else {
				queryClient.setQueryData(SIDEBAR_TOGGLE, setSidebarToggle(false))
			}
			// reset activeMenu on page unmount
			return ResetActiveMenu(location, menuClicked, setActiveMenu)
		}, [location, queryClient])

		return (
			<div className={toggle.data?.value ? 'p-0 sb-sidenav-toggled' : 'p-0'}>
				<div id="layoutSidenav">
					<HeaderComponent toggle={toggle} />

					<div id="layoutSidenav_nav">
						<nav className="sb-sidenav  sb-sidenav-light" id="">
							<div className="sb-sidenav-menu">
								<div className="nav">
									{showDashboard(queryClient) && (
										<NavLink
											to={routes.dashboard}
											className="nav-link dashboard-tt"
											onClick={() => menuClicked('dashboard')}
											title="Dashboard"
											tabIndex={1}
										>
											<div className="sb-nav-link-icon">
												<i className="ri-dashboard-3-line"></i>{' '}
											</div>
											<span className="menu-text">
												<JTranslation typeCase="pascal" text={'Dashboard'} />
											</span>
										</NavLink>
									)}

									{showDashboard(queryClient) &&
										checkPermissions([
											PermissionKeys.MANAGE_USERS,
											PermissionKeys.MANAGE_ROLES,
											PermissionKeys.MANAGE_PERMISSIONS,
										]) && (
											<>
												<span
													className={`nav-link  ${activeMenu === 'collapseUserManagement' ? 'active' : 'collapsed'
														} manage-user-tt`}
													data-bs-toggle="collapse"
													data-bs-target="#collapseUserManagement"
													aria-expanded={activeMenu === 'collapseUserManagement'}
													aria-controls="collapseUserManagement"
													onClick={() => {
														menuClicked('collapseUserManagement')
														queryClient.setQueryData(SIDEBAR_TOGGLE, true)
													}}
													title="Manager User"
													tabIndex={2}
												>
													<div className="sb-nav-link-icon">
														<i className="ri-user-settings-line"></i>
													</div>
													<span className="menu-text">
														<JTranslation typeCase="pascal" text={'Users & Permission'} />
													</span>
													<div className="sb-sidenav-collapse-arrow">
														<FaAngleDown />
													</div>
												</span>

												{/* sub menu items */}
												<nav className="sb-sidenav-menu-nested nav">
													<div
														className={`collapse  ${activeMenu === 'collapseUserManagement'
															? 'show collapseUserManagement'
															: ''
															}`}
														id="collapseUserManagement"
														aria-labelledby="collapseOne"
														data-bs-parent="#layoutSidenav_nav"
													>
														{checkPermission(queryClient, PermissionKeys.MANAGE_USERS) && (
															<NavLink
																to={routes.manage_users}
																className="nav-link users-tt"
																onClick={() => menuClicked('collapseUserManagement')}
																title="Users"
															>
																<div className="sb-nav-link-icon">
																	<i className="ri-user-add-line"></i>{' '}
																</div>
																<span className="menu-text">
																	<JTranslation typeCase="pascal" text={'Users'} />
																</span>
															</NavLink>
														)}
														{checkPermission(queryClient, PermissionKeys.MANAGE_ROLES) && (
															<NavLink
																to={routes.roles}
																// to={routes.user_roles}
																className="nav-link roles-tt"
																onClick={() => menuClicked('collapseUserManagement')}
																title="Roles"
															>
																<div className="sb-nav-link-icon">
																	<i className="ri-shield-user-line"></i>{' '}
																</div>
																<span className="menu-text">
																	<JTranslation typeCase="pascal" text={'Roles'} />
																</span>
															</NavLink>
														)}
														{checkPermission(
															queryClient,
															PermissionKeys.MANAGE_PERMISSIONS
														) && (
																<NavLink
																	to={routes.permission_groups}
																	className="nav-link roles-tt"
																	onClick={() => menuClicked('collapseUserManagement')}
																	title="Permissions"
																>
																	<div className="sb-nav-link-icon">
																		<i className="ri-shield-user-line"></i>{' '}
																	</div>
																	<span className="menu-text">
																		<JTranslation
																			typeCase="pascal"
																			text={'Permissions'}
																		/>
																	</span>
																</NavLink>
															)}
													</div>
												</nav>
											</>
										)}

									{showDashboard(queryClient) &&
										checkPermissions([
											PermissionKeys.MANAGE_MENU,
											PermissionKeys.MANAGE_CUSTOM_FNB_MENU,
											PermissionKeys.MANAGE_ADDONS,
											PermissionKeys.MANAGE_ADDON_GROUPS,
											PermissionKeys.MANAGE_INGREDIENTS,
										]) && (
											<>
												<span
													className={`nav-link  ${activeMenu === 'fbmenu' ? 'active' : 'collapsed'
														} fbmenu-tt`}
													data-bs-toggle="collapse"
													data-bs-target="#fbmenu"
													aria-expanded={activeMenu === 'fbmenu'}
													aria-controls="fbmenu"
													onClick={() => {
														menuClicked('fbmenu')
														queryClient.setQueryData(SIDEBAR_TOGGLE, true)
													}}
													title="Food and Beverage Menu"
													tabIndex={3}
												>
													<div className="sb-nav-link-icon">
														<BiFoodMenu />
													</div>
													<span className="menu-text">
														<JTranslation typeCase="pascal" text={'F&B Menu'} />
													</span>
													<div className="sb-sidenav-collapse-arrow">
														<FaAngleDown />
													</div>
												</span>

												{/* sub menu items */}
												<nav className="sb-sidenav-menu-nested nav">
													<div
														className={`collapse  ${activeMenu === 'fbmenu' ? 'show fbmenu' : ''
															}`}
														id="fbmenu"
														aria-labelledby="collapseOne"
														data-bs-parent="#layoutSidenav_nav"
													>
														{checkPermission(queryClient, PermissionKeys.MANAGE_MENU) && (
															<NavLink
																to={routes.menu}
																className="nav-link fb-tt"
																onClick={() => {
																	menuClicked('fbmenu')
																	setDndLock(true)
																}}
																title="F&B Menu Config"
															>
																<div className="sb-nav-link-icon">
																	<IoFastFoodOutline />{' '}
																</div>
																<span className="menu-text">
																	<JTranslation
																		typeCase="pascal"
																		text={'F&B Menu Config'}
																	/>
																</span>
															</NavLink>
														)}
														{checkPermission(
															queryClient,
															PermissionKeys.MANAGE_CUSTOM_FNB_MENU
														) && (
																<NavLink
																	to={routes.custom_menu_list}
																	className="nav-link custom-tt"
																	onClick={() => {
																		menuClicked('fbmenu')
																		setDndLock(true)
																	}}
																	title="Custom Menu Config"
																>
																	<div className="sb-nav-link-icon">
																		<RiPagesLine />{' '}
																	</div>
																	<span className="menu-text">
																		<JTranslation
																			typeCase="pascal"
																			text={'Custom Menu Config'}
																		/>
																	</span>
																</NavLink>
															)}
														{(checkPermission(queryClient, PermissionKeys.MANAGE_ADDONS) ||
															checkPermission(
																queryClient,
																PermissionKeys.MANAGE_ADDON_GROUPS
															)) && (
																<NavLink
																	to={navigateToAddon()}
																	className="nav-link addons-tt"
																	onClick={() => menuClicked('fbmenu')}
																	title="Add-Ons"
																>
																	<div className="sb-nav-link-icon">
																		<IoMdPizza />{' '}
																	</div>
																	<span className="menu-text">
																		<JTranslation typeCase="pascal" text={'Add-Ons'} />
																	</span>
																</NavLink>
															)}
														{checkPermission(
															queryClient,
															PermissionKeys.MANAGE_INGREDIENTS
														) && (
																<NavLink
																	to={routes.manage_ingredients}
																	className="nav-link ing-tt"
																	onClick={() => menuClicked('fbmenu')}
																	title="Ingredients"
																>
																	<div className="sb-nav-link-icon">
																		<RiLeafLine />{' '}
																	</div>
																	<span className="menu-text">
																		<JTranslation
																			typeCase="pascal"
																			text={'Ingredients'}
																		/>
																	</span>
																</NavLink>
															)}
													</div>
												</nav>
											</>
										)}

									{showDashboard(queryClient) &&
										checkPermissions([
											PermissionKeys.MANAGE_SURVEY,
											PermissionKeys.MANAGE_GAME,
											PermissionKeys.MANAGE_COUPON,
										]) && (
											<>
												<span
													className={`nav-link  ${activeMenu === 'collapseGuestManagement'
														? 'active'
														: 'collapsed'
														} guest-sat-tt`}
													data-bs-toggle="collapse"
													data-bs-target="#collapseGuestManagement"
													aria-expanded={activeMenu === 'collapseGuestManagement'}
													aria-controls="collapseGuestManagement"
													onClick={() => {
														menuClicked('collapseGuestManagement')
														queryClient.setQueryData(SIDEBAR_TOGGLE, true)
													}}
													title="Guest Satisfaction"
													tabIndex={4}
												>
													<div className="sb-nav-link-icon">
														<i className="ri-question-answer-line"></i>
													</div>
													<span className="menu-text">
														<JTranslation typeCase="pascal" text={'Guest Satisfaction'} />
													</span>
													<div className="sb-sidenav-collapse-arrow">
														<FaAngleDown />
													</div>
												</span>

												{/* sub menu items */}
												<nav className="sb-sidenav-menu-nested nav">
													<div
														className={`collapse  ${activeMenu === 'collapseGuestManagement'
															? 'show collapseGuestManagement'
															: ''
															}`}
														id="collapseGuestManagement"
														aria-labelledby="collapseTwo"
														data-bs-parent="#layoutSidenav_nav"
													>
														{checkPermission(queryClient, PermissionKeys.MANAGE_SURVEY) && (
															<NavLink
																to={routes.survey}
																className="nav-link survey-tt"
																onClick={() => menuClicked('collapseGuestManagement')}
																title="Surveys"
															>
																<div className="sb-nav-link-icon">
																	<i className="ri-survey-line"></i>{' '}
																</div>
																<span className="menu-text">
																	<JTranslation typeCase="pascal" text={'Surveys'} />
																</span>
															</NavLink>
														)}

														{checkPermission(queryClient, PermissionKeys.MANAGE_GAME) && (
															<NavLink
																to={routes.game_management}
																className="nav-link"
																onClick={() => menuClicked('collapseGuestManagement')}
																title="Games"
															>
																<div className="sb-nav-link-icon">
																	<i className="ri-trophy-line"></i>
																</div>
																<span className="menu-text">
																	<JTranslation typeCase="pascal" text={'Games'} />
																</span>
															</NavLink>
														)}

														{checkPermission(queryClient, PermissionKeys.MANAGE_COUPON) && (
															<NavLink
																to={routes.manage_coupon}
																className="nav-link"
																onClick={() => menuClicked('collapseGuestManagement')}
																title="Vouchers"
															>
																<div className="sb-nav-link-icon">
																	<i className="ri-coupon-line"></i>
																</div>
																<span className="menu-text">
																	<JTranslation typeCase="pascal" text={'Vouchers'} />
																</span>
															</NavLink>
														)}
													</div>
												</nav>
											</>
										)}

									{showDashboard(queryClient) &&
										checkPermission(queryClient, PermissionKeys.MANAGE_GUEST_INFO) && (
											<NavLink
												to={routes.guest_info}
												className="nav-link"
												onClick={() => menuClicked('guestInfo')}
												title="Guest Info"
												tabIndex={5}
											>
												<div className="sb-nav-link-icon">
													<i className="ri-user-star-line"></i>{' '}
												</div>
												<span className="menu-text">
													<JTranslation typeCase="pascal" text={'Guest Info'} />
												</span>
											</NavLink>
										)}

									{showDashboard(queryClient) &&
										(checkPermission(queryClient, PermissionKeys.MANAGE_TIME_OFF) ||
											checkPermission(queryClient, PermissionKeys.MANAGE_MY_TIME_OFF)) && (
											<>
												<span
													className={`nav-link  ${activeMenu === 'scheduleManagement' ? 'active' : 'collapsed'
														}`}
													data-bs-toggle="collapse"
													data-bs-target="#scheduleManagement"
													aria-expanded={activeMenu === 'scheduleManagement'}
													aria-controls="scheduleManagement"
													onClick={() => {
														menuClicked('scheduleManagement')
														queryClient.setQueryData(SIDEBAR_TOGGLE, true)
													}}
													title="Scheduling"
													tabIndex={6}
												>
													<div className="sb-nav-link-icon">
														<i className="ri-calendar-event-line"></i>
													</div>
													<span className="menu-text">
														<JTranslation typeCase="pascal" text={'Scheduling'} />
													</span>
													<div className="sb-sidenav-collapse-arrow">
														<FaAngleDown />
													</div>
												</span>

												{checkPermission(queryClient, PermissionKeys.MANAGE_SHIFT) && (
													<nav className="sb-sidenav-menu-nested nav">
														<div
															className={`collapse  ${activeMenu === 'scheduleManagement'
																? 'show scheduleManagement'
																: ''
																}`}
															id="scheduleManagement"
															aria-labelledby="collapseTwo"
															data-bs-parent="#layoutSidenav_nav"
														>
															<NavLink
																to={routes.shift_config}
																className="nav-link"
																onClick={() => menuClicked('scheduleManagement')}
																title="Shift Config"
																tabIndex={7}
															>
																<div className="sb-nav-link-icon">
																<TbCalendarCog />{' '}
																</div>
																<span className="menu-text">
																	<JTranslation
																		typeCase="pascal"
																		text={'Shift Config'}
																	/>
																</span>
															</NavLink>
														</div>
													</nav>
												)}

												{checkPermission(queryClient, PermissionKeys.MANAGE_TIME_OFF, SubPermissionKeys.MANAGE_REQUEST) && (
													<nav className="sb-sidenav-menu-nested nav">
														<div
															className={`collapse  ${activeMenu === 'scheduleManagement'
																? 'show scheduleManagement'
																: ''
																}`}
															id="scheduleManagement"
															aria-labelledby="collapseTwo"
															data-bs-parent="#layoutSidenav_nav"
														>
															<NavLink
																to={routes.time_off_requests}
																className="nav-link"
																onClick={() => menuClicked('scheduleManagement')}
																title="Time Off Requests"
																tabIndex={8}
															>
																<div className="sb-nav-link-icon">
																	<i className="ri-rest-time-line"></i>{' '}
																</div>
																<span className="menu-text">
																	<JTranslation
																		typeCase="pascal"
																		text={'Time Off Requests'}
																	/>
																</span>
															</NavLink>
														</div>
													</nav>
												)}

												{
													<nav className="sb-sidenav-menu-nested nav">
														<div
															className={`collapse  ${activeMenu === 'scheduleManagement'
																? 'show scheduleManagement'
																: ''
																}`}
															id="scheduleManagement"
															aria-labelledby="collapseTwo"
															data-bs-parent="#layoutSidenav_nav"
														>
															<NavLink
																to={routes.time_off}
																className="nav-link"
																onClick={() => menuClicked('scheduleManagement')}
																title="My Time Off"
																tabIndex={9}
															>
																<div className="sb-nav-link-icon">
																	<i className="ri-time-line"></i>{' '}
																</div>
																<span className="menu-text">
																	<JTranslation
																		typeCase="pascal"
																		text={'My Time Off'}
																	/>
																</span>
															</NavLink>
														</div>
													</nav>
												}

												{checkPermission(queryClient, PermissionKeys.MANAGE_AVAILABILITY) && (
														<nav className="sb-sidenav-menu-nested nav">
															<div
																className={`collapse  ${activeMenu === 'scheduleManagement'
																	? 'show scheduleManagement'
																	: ''
																	}`}
																id="scheduleManagement"
																aria-labelledby="collapseTwo"
																data-bs-parent="#layoutSidenav_nav"
															>
																<NavLink
																	to={routes.availability}
																	className="nav-link"
																	onClick={() => {
																		menuClicked('scheduleManagement')
																	}}
																	title="My Availability"
																>
																	<div className="sb-nav-link-icon">
																	<i className="ri-calendar-check-line"></i>{' '}
																	</div>
																	<span className="menu-text">
																		<JTranslation
																			typeCase="pascal"
																			text={'My Availability'}
																		/>
																	</span>
																</NavLink>
															</div>
														</nav>
													)}

												{checkPermission(
													queryClient,
													PermissionKeys.MANAGE_AVAILABILITY,
													SubPermissionKeys.MANAGE_REQUEST
												) && (
														<nav className="sb-sidenav-menu-nested nav">
															<div
																className={`collapse  ${activeMenu === 'scheduleManagement'
																	? 'show scheduleManagement'
																	: ''
																	}`}
																id="scheduleManagement"
																aria-labelledby="collapseTwo"
																data-bs-parent="#layoutSidenav_nav"
															>
																<NavLink
																	to={routes.availability_list}
																	className="nav-link"
																	onClick={() => {
																		menuClicked('scheduleManagement')
																	}}
																	title="Availability Requests"
																>
																	<div className="sb-nav-link-icon">
																		<i className="ri-list-check-3"></i>{' '}
																	</div>
																	<span className="menu-text">
																		<JTranslation
																			typeCase="pascal"
																			text={'Availability Requests'}
																		/>
																	</span>
																</NavLink>
															</div>
														</nav>
													)}

												{checkPermission(
													queryClient,
													PermissionKeys.MANAGE_AVAILABILITY,
													SubPermissionKeys.VIEW_AVAILABILITY
												) && (
														<nav className="sb-sidenav-menu-nested nav">
															<div
																className={`collapse  ${activeMenu === 'scheduleManagement'
																	? 'show scheduleManagement'
																	: ''
																	}`}
																id="scheduleManagement"
																aria-labelledby="collapseTwo"
																data-bs-parent="#layoutSidenav_nav"
															>
																<NavLink
																	to={routes.view_availability}
																	className="nav-link"
																	onClick={() => {
																		menuClicked('scheduleManagement')
																	}}
																	title="View Availability"
																>
																	<div className="sb-nav-link-icon">
																	<i className="ri-calendar-line"></i>{' '}
																	</div>
																	<span className="menu-text">
																		<JTranslation
																			typeCase="pascal"
																			text={'View Availability'}
																		/>
																	</span>
																</NavLink>
															</div>
														</nav>
													)}

												{
													<nav className="sb-sidenav-menu-nested nav">
														<div
															className={`collapse  ${activeMenu === 'scheduleManagement'
																? 'show scheduleManagement'
																: ''
																}`}
															id="scheduleManagement"
															aria-labelledby="collapseTwo"
															data-bs-parent="#layoutSidenav_nav"
														>
															<NavLink
																to={routes.holidays}
																className="nav-link"
																onClick={() => {
																	menuClicked('scheduleManagement')
																}}
																title="Closure & Holiday"
															>
																<div className="sb-nav-link-icon">
																<PiBackpackLight />{' '}
																</div>
																<span className="menu-text">
																	<JTranslation typeCase="pascal" text={'Closure & Holiday'} />
																</span>
															</NavLink>
														</div>
													</nav>
												}

												{checkPermission(
													queryClient,
													PermissionKeys.MANAGE_WORK_TIME_SCHEDULE
												) && (
													<nav className="sb-sidenav-menu-nested nav">
														<div className={`collapse  ${activeMenu === 'scheduleManagement'
															? 'show scheduleManagement'
															: ''
															}`}
															id="scheduleManagement"
															aria-labelledby="collapseTwo"
															data-bs-parent="#layoutSidenav_nav"
														>

															<NavLink
																to={routes.manage_work_schedule}
																className="nav-link"
																onClick={() => {
																	menuClicked('scheduleManagement')
																}}
																title="Schedules"
															>
																<div className="sb-nav-link-icon">
																<i className="ri-calendar-todo-fill"></i>{' '}
																</div>
																<span className="menu-text"><JTranslation typeCase="pascal" text={"Schedules"} /></span>
															</NavLink>
														</div>
													</nav>
												)}

												{checkPermission(
													queryClient,
													PermissionKeys.MANAGE_WORK_TIME_SCHEDULE
												) && (
													<nav className="sb-sidenav-menu-nested nav">
														<div className={`collapse  ${activeMenu === 'scheduleManagement'
															? 'show scheduleManagement'
															: ''
															}`}
															id="scheduleManagement"
															aria-labelledby="collapseTwo"
															data-bs-parent="#layoutSidenav_nav"
														>

															<NavLink
																to={routes.print_preview}
																className="nav-link"
																onClick={() => {
																	menuClicked('scheduleManagement')
																}}
																title="Print Preview"
															>
																<div className="sb-nav-link-icon">
																<i className="ri-printer-line"></i>{' '}
																</div>
																<span className="menu-text"><JTranslation typeCase="pascal" text={"Print Preview"} /></span>
															</NavLink>
														</div>
													</nav>
												)}

												{
													<nav className="sb-sidenav-menu-nested nav">
														<div className={`collapse  ${activeMenu === 'scheduleManagement'
															? 'show scheduleManagement'
															: ''
															}`}
															id="scheduleManagement"
															aria-labelledby="collapseTwo"
															data-bs-parent="#layoutSidenav_nav"
														>

															<NavLink
																to={routes.staff_schedule}
																className="nav-link"
																onClick={() => {
																	menuClicked('scheduleManagement')
																}}
																title="My Schedule"
															>
																<div className="sb-nav-link-icon">
																<i className="ri-calendar-schedule-line"></i>{' '}
																</div>
																<span className="menu-text"><JTranslation typeCase="pascal" text={"My Schedule"} /></span>
															</NavLink>
														</div>
													</nav>
												}

												{
													<nav className="sb-sidenav-menu-nested nav">
														<div className={`collapse  ${activeMenu === 'scheduleManagement'
															? 'show scheduleManagement'
															: ''
															}`}
															id="scheduleManagement"
															aria-labelledby="collapseTwo"
															data-bs-parent="#layoutSidenav_nav"
														>
															<NavLink
																to={routes.shift_swap}
																className="nav-link"
																onClick={() => {
																	menuClicked('scheduleManagement')
																}}
																title="Shift Swap"
															>
																<div className="sb-nav-link-icon">
																<i className="ri-arrow-left-right-line"></i>{' '}
																</div>
																<span className="menu-text"><JTranslation typeCase="pascal" text={"Shift Swap"} /></span>
															</NavLink>
														</div>
													</nav>
												}
											</>
										)}

									{showDashboard(queryClient) &&
										checkPermission(queryClient, PermissionKeys.MANAGE_NEWS_AND_EVENTS) && (
											<NavLink
												to={routes.news_and_events_management}
												className="nav-link"
												onClick={() => menuClicked('newsAndEvents')}
												title="News and Events"
												tabIndex={10}
											>
												<div className="sb-nav-link-icon">
													<i className="ri-global-line"></i>{' '}
												</div>
												<span className="menu-text">
													<JTranslation typeCase="pascal" text={'News and Events'} />
												</span>
											</NavLink>
										)}

									{showDashboard(queryClient) && checkPermissions([PermissionKeys.MANAGE_FILES]) && (
										<>
											<span
												className={`nav-link  ${activeMenu === 'collapseFileManagement' ? 'active' : 'collapsed'
													}`}
												data-bs-toggle="collapse"
												data-bs-target="#collapseFileManagement"
												aria-expanded={activeMenu === 'collapseFileManagement'}
												aria-controls="collapseFileManagement"
												onClick={() => {
													menuClicked('collapseFileManagement')
													queryClient.setQueryData(SIDEBAR_TOGGLE, true)
												}}
												title="File Operations"
												tabIndex={11}
											>
												<div className="sb-nav-link-icon">
													<i className="ri-file-line"></i>
												</div>
												<span className="menu-text">
													<JTranslation typeCase="pascal" text={'File Operations'} />
												</span>
												<div className="sb-sidenav-collapse-arrow">
													<FaAngleDown />
												</div>
											</span>

											{/* sub menu items */}
											<nav className="sb-sidenav-menu-nested nav">
												<div
													className={`collapse  ${activeMenu === 'collapseFileManagement'
														? 'show collapseFileManagement'
														: ''
														}`}
													id="collapseFileManagement"
													aria-labelledby="collapseThree"
													data-bs-parent="#layoutSidenav_nav"
												>
													{checkPermission(queryClient, PermissionKeys.MANAGE_FILES) && (
														<NavLink
															to={routes.file_management}
															className="nav-link"
															onClick={() => menuClicked('collapseFileManagement')}
															title="Cloud Storage"
														>
															<div className="sb-nav-link-icon">
																<i className="ri-cloud-line"></i>
															</div>
															<span className="menu-text">
																<JTranslation
																	typeCase="pascal"
																	text={'Cloud Storage'}
																/>
															</span>
														</NavLink>
													)}

													{checkPermission(queryClient, PermissionKeys.MANAGE_FILES) && (
														<NavLink
															to={routes.file_type_verification}
															className="nav-link"
															onClick={() => menuClicked('collapseFileManagement')}
															title="File Type Verification"
														>
															<div className="sb-nav-link-icon">
																<i className="ri-file-mark-line"></i>
															</div>
															<span className="menu-text">
																<JTranslation
																	typeCase="pascal"
																	text={'File Type Verification'}
																/>
															</span>
														</NavLink>
													)}
												</div>
											</nav>
										</>
									)}

									{showDashboard(queryClient) &&
										checkPermission(queryClient, PermissionKeys.MANAGE_REPORTS) && (
											<NavLink
												to={routes.survey_report}
												className="nav-link"
												onClick={() => menuClicked('reports')}
												title="Reports"
												tabIndex={12}
											>
												<div className="sb-nav-link-icon">
													<i className="ri-file-chart-line"></i>{' '}
												</div>
												<span className="menu-text">
													<JTranslation typeCase="pascal" text={'Reports'} />
												</span>
											</NavLink>
										)}

									{showDashboard(queryClient) &&
										checkPermissions([
											PermissionKeys.MANAGE_BRANDING,
											PermissionKeys.MANAGE_WELCOME_SCREEN,
											PermissionKeys.MANAGE_ABOUT_US,
											PermissionKeys.MANAGE_GENERAL_SETTINGS,
										]) && (
											<NavLink
												to={routes.settings}
												className="nav-link"
												onClick={() => menuClicked('settings')}
												title="Settings"
												tabIndex={13}
											>
												<div className="sb-nav-link-icon">
													<i className="ri-settings-5-line"></i>{' '}
												</div>
												<span className="menu-text">
													<JTranslation typeCase="pascal" text={'Settings'} />
												</span>
											</NavLink>
										)}

									<NavLink
										to={routes.profile}
										className="nav-link"
										onClick={() => menuClicked('profile')}
										title="Profile"
										tabIndex={14}
									>
										<div className="sb-nav-link-icon">
											<i className="ri-user-settings-line"></i>{' '}
										</div>
										<span className="menu-text">
											<JTranslation typeCase="pascal" text={'My Profile'} />
										</span>
									</NavLink>
								</div>
								<div className="copyright-container">
									<div className="copyright">
										<p>V {Package.version}</p>
										<p>{COPYRIGHT}</p>
									</div>
									<div className="copyright-collapse">
										<i className="ri-copyright-line"></i>
									</div>
								</div>
							</div>
						</nav>
					</div>

					<div id="layoutSidenav_content">
						<main
							className={
								location.pathname === routes.dashboard ? 'content-sec overflow-auto' : 'content-sec'
							}
						>
							<div
								className={
									location.pathname === routes.user_roles ||
										location.pathname === routes.welcome_screen_config
										? 'container-fluid px-3 py-3  min-H'
										: 'container-fluid h-100 px-3 py-3'
								}
							>
								<div className={getSidebarDivClassName(location)}>
									<WrappedComponent {...props} />
								</div>
							</div>
						</main>
					</div>
				</div>
			</div>
		)
	}
}

export default withSidebar
