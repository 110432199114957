import { useEffect, useState, useContext } from 'react'
import { Column } from 'primereact/column'
import { FileManagementCtx } from '../../context/FileManagementCtxProvider'
import { DataTable } from 'primereact/datatable'
import { ExtractedFileData, FileTypeOption, ExtractedFileApiResponse, Search, FileTypeVerificationTabs } from '../../constants/staticTypes'
import { FILE_NAME, UPLOADED_BY, STATUS, FILE_TYPE, Date_N_Time, CLICK_TO_OPEN } from '../../constants/strings'
import { getAllExtractedFiles, getAllFilesTypes } from '../../helpers/verifyDocumentTypeHelper'
import { DATE_TIME_FORMAT } from '../../constants/constants'
import { useQueryClient } from 'react-query'
import FileTypeVerificationActions from './FileTypeVerificationActions'
import CommonModal from '../../components/common_modal/CommonModal'
import DocumentViewer from '../../components/doc_viewer/DocumentViewer'
import FileTypeDropdown from './FileTypeDropdown'
import moment from 'moment'
import ToastAlert from '../../components/alert/ToastAlert'
import useMutationHook from '../../hooks/useMutationHook'
import { JTranslation, TranslationContext, jTranslationText } from '../../helpers/jTranslate'

const FileTypeVerificationDataTable = ({ searchValue, basicRows, pageNumber, setTotal, storeCurrentTabName }: Search) => {
    const queryClient = useQueryClient()
    const verifyDocumentMutation = useMutationHook(queryClient, true)
    // CONTEXT VARIABLES
    const { fileTypeOptions, setFileTypeOptions, extractedFiles, setExtractedFiles, refetchAPI, setRefetchAPI } = useContext(FileManagementCtx)
    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext
    // STATE VARIABLE
    const [showDocViewer, setShowDocViewer] = useState(false)
    const [fileNameToBeViewed, setFileNameToBeViewed] = useState<ExtractedFileData>()
    const [translationText, setTranslatedText] = useState<{
        openDoc: string,
    }>({ 
        openDoc: CLICK_TO_OPEN,
    });
    // ALERT VARIABLES
    const [alertVariant, setVariant] = useState('')
    const [toastMessage, setToastMessage] = useState('')
    const [showAlert, setShowAlert] = useState(false)

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: CLICK_TO_OPEN, typeCase: 'pascal', translationContext }),
                ])
                
                setTranslatedText({
                    openDoc: translations[0] ?? CLICK_TO_OPEN,
                })
            } catch {
                setTranslatedText({
                    openDoc: CLICK_TO_OPEN,
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    // closes the document viewer modal
    const closeDocViewer = () => setShowDocViewer(false)

    /* MODAL FOR SHOWING THE DOCUMENT VIEWER */
    const tableDocumentViewer = (doc: ExtractedFileData) => {
        return (
            <>
                <span
                    className="email-text"
                    title={translationText.openDoc}
                    data-testid={"file-name#" + doc.id}
                    onClick={() => {
                        setFileNameToBeViewed(doc)
                        setShowDocViewer(true)
                    }}
                >
                    {doc.file.name}
                </span>
            </>
        )
    }

    const dateAndTime = (rowData: ExtractedFileData) => moment(rowData.createdAt).format(DATE_TIME_FORMAT)

    const onGetFileTypeSuccess = (response: FileTypeOption[]) => {
        setFileTypeOptions(response)
        setRefetchAPI(true)
    }

    const onGetFilesListSuccess = (response: ExtractedFileApiResponse) => {
        setExtractedFiles(response.item_list)
        setTotal(response.total)
    }

    const onError = (errorMessage: string, variant: string) => {
        setToastMessage(errorMessage)
        setVariant(variant)
    }

    // effect to refetch list api bring updated data on screen
    useEffect(() => {
        setRefetchAPI(true)
        storeCurrentTabName("verify_type" as FileTypeVerificationTabs)
    }, [setRefetchAPI]) // eslint-disable-line

    // effect to set the refetch variable back to false
    useEffect(() => {
        if (refetchAPI)
            setInterval(() => {
                setRefetchAPI(false)
            }, 1000)
    }, [refetchAPI, setRefetchAPI])

    // // effect to make api call on page load
    useEffect(() => {
        // api call to get all files types for dropdown options
        if (!fileTypeOptions.length) {
            getAllFilesTypes(verifyDocumentMutation, onGetFileTypeSuccess, onError)
        }
        // api call to get all extracted files for user confirmation
        if (fileTypeOptions.length && refetchAPI) {
            let payload = `?page=${pageNumber}&perPageSize=${basicRows}&searchValue=${searchValue}`
            getAllExtractedFiles(verifyDocumentMutation, payload, onGetFilesListSuccess, onError)
        }
    }, [fileTypeOptions, refetchAPI, basicRows, pageNumber, searchValue]) // eslint-disable-line

    return (
        <>
            <div className="h-100">
                {/* TOAST MESSAGE COMPONENT */}
                <ToastAlert show={showAlert} onClose={setShowAlert} message={toastMessage} variant={alertVariant} />
                {/* MODAL FOR SHOWING THE DOCUMENT VIEWER  */}
                <CommonModal
                    callback={closeDocViewer}
                    hideProceed
                    modalContent={<DocumentViewer docs={[{ uri: fileNameToBeViewed?.signedUrl! }]} />}
                    modalSize="xl"
                    onHide={setShowDocViewer}
                    show={showDocViewer}
                    title={fileNameToBeViewed?.file.name!}
                    modalBodyCss={
                        fileNameToBeViewed?.file.name?.includes('xls')
                            ? 'doc-viewer-body-for-excel-files'
                            : 'doc-viewer-body'
                    }
                />
                {/* DATA TABLE */}
                <DataTable value={extractedFiles} responsiveLayout="scroll" scrollable scrollHeight="flex">
                    <Column
                        field="file.name"
                        header={<JTranslation typeCase="pascal" text={FILE_NAME} />}
                        style={{ minWidth: '15rem' }}
                        body={tableDocumentViewer}
                    />
                    <Column
                        field="file.uploadedUserName"
                        header={<JTranslation typeCase="pascal" text={UPLOADED_BY} />}
                        style={{ minWidth: '12rem', maxWidth: '12rem' }}
                    />
                    <Column
                        field="textractJobStatusLabel"
                        header={<JTranslation typeCase="pascal" text={STATUS} />}
                        style={{ minWidth: '10rem', maxWidth: '10rem' }}
                    />
                    <Column
                        field="textractIdentifiedEntity"
                        header={<JTranslation typeCase="pascal" text={FILE_TYPE} />}
                        style={{ minWidth: '10rem', maxWidth: '10rem' }}
                        body={FileTypeDropdown}
                    />
                    <Column
                        field="createdAt"
                        header={<JTranslation typeCase="pascal" text={Date_N_Time} />}
                        body={dateAndTime}
                        style={{ minWidth: '12rem', maxWidth: '12rem' }}
                    />
                    <Column
                        body={FileTypeVerificationActions}
                        bodyClassName="bg-sticky-color"
                        className="justify-content-start"
                        style={{ minWidth: '8rem', maxWidth: '8rem' }}
                        alignFrozen="right"
                        frozen={true}
                    />
                </DataTable>
            </div>
        </>
    )
}

export default FileTypeVerificationDataTable
