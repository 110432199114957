import { useState, useEffect, useRef } from 'react';

interface Size {
    width: number;
    height: number;
}

const useElementSize = (): [React.MutableRefObject<any>, Size] => {
    const ref = useRef<any>(null);
    const [size, setSize] = useState<Size>({ width: 0, height: 0 });

    useEffect(() => {
        const currentRef = ref.current;

        // Ensure ref is an Element
        if (!(currentRef instanceof Element)) return;

        const observer = new ResizeObserver((entries) => {
            if (entries[0]) {
                const { width, height } = entries[0].contentRect;
                setSize({ width, height });
            }
        });

        observer.observe(currentRef);

        // Cleanup observer on unmount
        return () => {
            observer.unobserve(currentRef);
            observer.disconnect();
        };
    }, []);

    return [ref, size];
};

export default useElementSize;
