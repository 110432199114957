import { useState, useEffect, useContext } from "react";
import { AxiosResponse } from "axios";
import { CommonCtx } from "../../../../context/CommonCtxProvider";
import { FaCaretRight, FaPlus } from "react-icons/fa";
import { FileType, IMAGE_TYPE, PriceType, toastMessageInitialData } from "../../../../constants/constants";
import { createRecommendedPairingOptions } from "../../../../helpers/menuViewHelper";
import { getItems, initialHistoryData } from "../../../../helpers/menuManagementHelper";
import { MENU_ITEMS } from "../../../../constants/queryKeys";
import { MenuItem, Payload, Addon, Ingredient, SingleIngredient, ToastMessageProps, AddonGroup, SelectedAddonGroup, CropType } from "../../../../constants/staticTypes";
import { getNumberWithTwoDecimals } from '../../../../helpers/utils';
import { UseMutationResult, useQueryClient } from "react-query";
import AdditionalInfo from "./AdditionalInfo";
import AddPrice from "./AddPrice";
import AddWineHistoryForm from "./AddWineHistoryForm";
import AiTextGenerator from "../../../ai_text_generator/AiTextGenerator";
import FileUpload from "./FileUpload";
import Select from 'react-select'
import ToastAlert from "../../../alert/ToastAlert";
import useQueryHook from "../../../../hooks/useQueryHook";
import WarningModal from "../../../warning_modal/WarningModal";

import {
    ADD_HISTORY,
    AI_TEXT_GENERATOR,
    ADD_INGREDIENTS,
    ADD_PRICE,
    ADD_SUGGESTION_ITEMS,
    ADDON_PRICE,
    DELETE_WARNING,
    DESCRIPTION,
    HELP_ADD_ONS_PRICE,
    HELP_ADD_PRICE,
    HELP_HISTORY,
    HELP_ITEM_NAME,
    HELP_ITEM_TAG,
    HELP_MARKET_PRICE,
    HELP_SUGGESTION_ITEMS,
    HELP_UNAVAILABLE,
    MARK_UNAVAILABLE,
    MARKET_PRICE,
    MENU_ITEM_NAME,
    SHOW_PRICE_AS_MARKET_PRICE,
    TAG,
    WINE_HISTORY,
    ADD_ON_GROUP,
    EMPTY_ADD_ON_GROUP,
    ADD_ONS_LIST,
} from '../../../../constants/strings'
import AddAddonGroupModal from "./AddAddonGroupModal";
import { JTranslation, TranslationContext, jTranslationText } from "../../../../helpers/jTranslate";
import MlxTooltip from "../../../common/MlxTooltip";
import MlxPopover from "../../../common/MlxPopover";

type Props = {
    formData: MenuItem,
    setFormData: React.Dispatch<React.SetStateAction<MenuItem>>,
    displayToast: (message: string, variant: string) => void,
    uploadFileMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onUploadSuccess: (key: string, fileUrl: string, fileType: FileType) => void,
    onUploadError: (fileType: FileType) => void,
    images: string[],
    videos: string[],
    onDeleteFile: (fileType: FileType, index: number) => void,
    setImages: React.Dispatch<React.SetStateAction<string[]>>
    addOnList: Addon[],
    ingredientsList: Ingredient[],
    parentComponent?: "F&B_MENU" | "CUSTOM_F&B_MENU",
    addOnGroupList: AddonGroup[]
}

function AddBeverageForm({ formData, setFormData, displayToast, uploadFileMutation, setImages,
    onUploadSuccess, onUploadError, images, videos, onDeleteFile, addOnList, ingredientsList, parentComponent = "F&B_MENU", addOnGroupList }: Props) {
    const queryClient = useQueryClient();
    const { fnBGlobalSettings } = useContext(CommonCtx)
    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext

    const [showWineHistory, setWineHistory] = useState(false);
    const [priceRow, setPriceRow] = useState([0]);
    const [items, setItems] = useState<MenuItem[]>([]);
    const [warning, setWarning] = useState(false);
    const [recommendedPairingOptions, setRecommendedPairingOptions] = useState<any[]>([]);
    const [showAiTextGenerator, setShowAiTextGenerator] = useState(false)
    const [showAddonGroupModal, setAddonGroupModal] = useState(false);
    // ALERT VARIABLES
    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
    const [deleteAddOnGroup, setDeleteAddOnGroup] = useState<AddonGroup | undefined>();

    const cropType: CropType = 'BEVERAGE';
    const validateImageDimension = { width: IMAGE_TYPE[cropType].width, height: IMAGE_TYPE[cropType].height };

    const [translationText, setTranslatedText] = useState<{
        loading: string,
        select: string,
    }>({
        loading: 'Loading...',
        select: 'Select',
    });

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: 'Loading...', typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: 'Select', typeCase: 'pascal', translationContext }),
                ])
                setTranslatedText({
                    loading: translations[0] ?? 'Loading...',
                    select: translations[1] ?? 'Select',
                })
            } catch {
                setTranslatedText({
                    loading: 'Loading...',
                    select: 'Select',
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    // react query to fetch menu items
    useQueryHook(MENU_ITEMS, getItems, (res) => {
        let data = res.data.data as MenuItem[];
        const newData = data.filter(item => item.itemName !== null) // removes all items with item name null 
        setItems(newData.sort((a: any, b: any) => a.itemName.localeCompare(b.itemName)));
    });

    const isHistoryAdded = () => {
        return formData.historyInfo.title &&
            formData.historyInfo.description &&
            formData.historyInfo.title !== null && formData.historyInfo.description !== null &&
            formData.historyInfo.title?.trim().length > 0 &&
            formData.historyInfo.description?.trim().length > 0
    }

    useEffect(() => {
        const res = queryClient.getQueryData(MENU_ITEMS) as AxiosResponse<any, any>;
        let data = res?.data?.data as MenuItem[]
        if (data) setItems(data)
    }, [queryClient])

    useEffect(() => {
        if (items.length)
            createRecommendedPairingOptions({
                items: items,
                setRecommendedPairingOptions: setRecommendedPairingOptions,
                fnBGlobalSettings: fnBGlobalSettings,
            })
    }, [items]) // eslint-disable-line

    const removeAddOnGroup = () => {
        const filteredSelectedAddonGroups = formData?.selectedAddonGroups?.filter(eachAddonGroup => eachAddonGroup.addonGroupId !== deleteAddOnGroup?.id) as SelectedAddonGroup[]
        if (filteredSelectedAddonGroups.length > 0) {
            setFormData({ ...formData, selectedAddonGroups: filteredSelectedAddonGroups });
        } else {
            delete formData.selectedAddonGroups
            setFormData(formData)
        }
        setDeleteAddOnGroup(undefined);
        setWarning(false);
    }

    return (
        <>
            <ToastAlert
                data-testid="toast"
                message={toastMessage.message}
                onClose={() => setToastMessage(toastMessageInitialData)}
                show={toastMessage.show}
                variant={toastMessage.variant}
            />

            <WarningModal show={warning} onHide={() => {
                setDeleteAddOnGroup(undefined);
                setWarning(false)
            }} title={DELETE_WARNING} callback={() => {
                if (deleteAddOnGroup) {
                    removeAddOnGroup();
                } else {
                    setFormData({ ...formData, historyInfo: initialHistoryData });
                }
                setWarning(false);
            }} />

            <AddWineHistoryForm show={showWineHistory} onHide={setWineHistory} formData={formData} setFormData={setFormData}
                onUploadError={onUploadError} displayToast={displayToast} />

            <AddAddonGroupModal
                formData={formData}
                setFormData={setFormData}
                showAddonGroupModal={showAddonGroupModal}
                setAddonGroupModal={setAddonGroupModal}
                addOnGroupList={addOnGroupList}
            />

            <div className="col-lg-8 pt-3">
                <div className="row">
                    <div className="col-md-6  mb-3">
                        <div className="help-small">
                            <label htmlFor="itemName" className="form-label"><JTranslation typeCase="pascal" text={MENU_ITEM_NAME} /><span className="mandatory ">*</span></label>
                            <MlxPopover data={{title:MENU_ITEM_NAME, body:HELP_ITEM_NAME}}/>
                        </div>
                        <input type="text" className="form-control" id="itemName" data-testid="itemName"
                            value={formData.itemName}
                            maxLength={100}
                            autoComplete="off"
                            onChange={(e) => setFormData({ ...formData, itemName: e.target.value })} />
                    </div>
                    <div className="col-md-6  mb-3">
                        <div className="help-small">
                            <label htmlFor="itemTag" className="form-label"><JTranslation typeCase="pascal" text={TAG} /></label>
                            <MlxPopover data={{title:TAG, body:HELP_ITEM_TAG}}/>
                        </div>
                        <input type="text" className="form-control" id="itemTag" data-testid="itemTag"
                            value={formData.itemTag}
                            maxLength={300}
                            autoComplete="off"
                            onChange={(e) => setFormData({ ...formData, itemTag: e.target.value })} />
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="form-check">
                            <input className="form-check-input" checked={!formData.isAvailable}
                                autoComplete="off"
                                data-testid="markUnavailable"
                                type="checkbox" id="flexCheckDefault" onChange={() => {
                                    setFormData({ ...formData, isAvailable: !formData.isAvailable });
                                }} />
                            <label className="form-check-label d-flex align-items-center" >
                                <JTranslation typeCase="pascal" text={MARK_UNAVAILABLE} />
                                <div className="help-small">
                                    <MlxPopover data={{title:MARK_UNAVAILABLE, body:HELP_UNAVAILABLE}}/>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>

                <div className="row gy-2 gx-3 align-items-center mb-3">
                    <label className="fw-bold d-flex align-items-center"><JTranslation typeCase="pascal" text={ADD_PRICE} />
                        <div className="help-small">
                            <MlxPopover data={{title:ADD_PRICE, body:HELP_ADD_PRICE}}/>
                        </div>
                    </label>

                    <div className="col-md-12 mb-2">
                        <div className="d-flex">
                            <div className="checkbox-container flex-grow-0">
                                <input className="form-check-input" checked={formData.isMarketPrice}
                                    autoComplete="off"
                                    data-testid="showPriceCheckbox"
                                    type="checkbox" id="flexCheckDefault" onChange={() => {
                                        setFormData({ ...formData, isMarketPrice: !formData.isMarketPrice });
                                    }} />
                            </div>
                            <div className="label-container  flex-grow-1 d-flex ps-3">
                                <label className="form-check-label d-flex align-items-center" >
                                    <JTranslation typeCase="pascal" text={SHOW_PRICE_AS_MARKET_PRICE} />

                                    <div className="help-small">
                                        <MlxPopover data={{title:MARKET_PRICE, body:HELP_MARKET_PRICE}}/>
                                    </div>
                                </label>
                            </div>


                        </div>
                    </div>

                    <div className={formData.isMarketPrice ? "disabled-div" : ""}>
                        <AddPrice priceRow={priceRow} setPriceRow={setPriceRow} formData={formData} setFormData={setFormData}
                            type={PriceType.ITEM} />
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <label><JTranslation typeCase="pascal" text={DESCRIPTION} /><span className="mandatory "></span></label>
                    <button className="btn btn-sm btn-custom-primary-outline" onClick={() => { setShowAiTextGenerator(!showAiTextGenerator) }}>
                        <JTranslation typeCase="pascal" text={AI_TEXT_GENERATOR} />
                    </button>
                </div>
                <AiTextGenerator
                    key='menuItemDescription'
                    show={showAiTextGenerator}
                    callBack={(aiResponse: string) => setFormData((prev) => ({ ...prev, itemDescription: aiResponse }))}
                    type='menuItemDescription'
                />

                <div className="row">
                    <div className="col-lg-12 mb-3">
                        <textarea className="form-control" id="desc-text-area" rows={3}
                            data-testid="desc-text-area"
                            value={formData.itemDescription ? formData.itemDescription : ""}
                            maxLength={2000}
                            onChange={(e) => setFormData({ ...formData, itemDescription: e.target.value })}></textarea>
                    </div>
                </div>

                <div className="row px-2 gy-2 gx-3 align-items-center mb-3 mt-2">
                    <div className={parentComponent === "CUSTOM_F&B_MENU" ? "display-none" : "rounded border py-2"}>

                        <div className="col-md-12 px-3 py-2 add-on-group-box">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <label className="title"><JTranslation typeCase="pascal" text={ADD_ON_GROUP} /></label>
                                <button className="btn btn-sm btn-custom-primary-outline" data-testid="addonGroupBt" onClick={() => { setAddonGroupModal(true) }}>
                                    <i className="ri-add-line"></i> <JTranslation typeCase="pascal" text={ADD_ON_GROUP} />
                                </button>

                            </div>


                            {!formData?.selectedAddonGroups &&
                                <>
                                    <hr></hr>
                                    <div className="d-flex align-items-center mt-2 empty-addon-group">
                                        <FaCaretRight size={18} className="noAddon-icon" />
                                        <span className="mx-1"><JTranslation typeCase="capitalize" text={EMPTY_ADD_ON_GROUP} /></span>
                                    </div>
                                </>
                            }
                        </div>

                        {formData?.selectedAddonGroups && formData?.selectedAddonGroups?.length > 0 &&
                            addOnGroupList.filter(eachAddOnGroup => {
                                if (eachAddOnGroup?.id && formData?.selectedAddonGroups && formData?.selectedAddonGroups.map(eachAddOnGroup => eachAddOnGroup.addonGroupId).includes(eachAddOnGroup?.id)) {
                                    return eachAddOnGroup
                                }
                            }).map(addOnGroup => {
                                return (
                                    <div key={addOnGroup.addonGroupId} className="mt-2 p-3 addon-group-list rounded">
                                        <div className="d-flex  align-items-center">
                                            <div className="flex-grow-1">
                                                <div className="col-12 d-flex justify-content-start align-items-center">
                                                    <h6>{addOnGroup.addonGroupName} - ${getNumberWithTwoDecimals(+addOnGroup?.addonGroupPrice)} <JTranslation typeCase="lower" text={"each"} /></h6>
                                                </div>
                                                <div className="col-12 mt-2">
                                                    {addOnGroup?.selectedAddonsData?.map(addOn => (
                                                        <div key={addOn.addonId} className="capsule">{addOn.addonName}</div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="flex-grow-0">
                                                <MlxTooltip target=".text-danger">
                                                    <div className="text-danger wine-ad-info-delete p-2 ms-2 rounded-circle"
                                                        data-pr-tooltip="Remove Addon Group"
                                                        data-pr-position="mouse"
                                                        onClick={() => {
                                                            setDeleteAddOnGroup(addOnGroup);
                                                            setWarning(true);
                                                        }}>
                                                        <i className="ri-delete-bin-6-line"></i>
                                                    </div>
                                                </MlxTooltip>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                        <hr className="mt-4" />
                        <div className="col-md-12 px-3 py-2 mb-2 add-on-group-box mt-4">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <label className="title"><JTranslation typeCase="pascal" text={ADD_ONS_LIST} /></label>
                                <MlxPopover data={{title:ADD_ONS_LIST, body:HELP_ADD_ONS_PRICE}}/>
                            </div>
                        </div>
                        <div className="col-md-12">
                            {/*<div className="help-small">
                                <label className="title" htmlFor="autoSizingInput">{ADD_ONS_LIST}
                                </label> 
                               
                            </div>*/}
                            <Select
                                options={addOnList.map((item) => {
                                    return {
                                        value: item.id,
                                        label: item.addonName,
                                        id: item.id,
                                    }
                                })}
                                placeholder={addOnList.length === 0 ? translationText.loading : translationText.select}
                                isSearchable
                                isMulti
                                data-testid="addonsSelect"
                                value={addOnList
                                    .filter((item) =>
                                        item.id &&
                                        formData.selectedAddons.some((addOn) => addOn.addonId === item.id)
                                    )
                                    .map((item) => ({ value: item.id, label: item.addonName, id: item.id }))
                                }

                                onChange={(selectedItems) => {
                                    let selectedValues = selectedItems.map((item) => {
                                        return {
                                            addonId: item.id,
                                            addonPrice: addOnList.find(addOn => addOn.id === item.value)?.addonPrice,
                                            addonDiscount: ""
                                        }
                                    }) as Addon[]

                                    setFormData({ ...formData, selectedAddons: selectedValues })
                                }}
                            />

                        </div>
                    </div>
                </div>

                <div className="row gy-2 gx-3 align-items-center mb-3 mt-2">
                    <div className={parentComponent === "CUSTOM_F&B_MENU" ? "display-none" : "col-md-12"}>
                        <div className="help-small">
                            <label className="" htmlFor="autoSizingInput"><JTranslation typeCase="pascal" text={ADD_SUGGESTION_ITEMS} />
                            </label>
                            <MlxPopover data={{title:ADD_SUGGESTION_ITEMS, body:HELP_SUGGESTION_ITEMS}}/>
                        </div>
                        <Select
                            options={recommendedPairingOptions}
                            placeholder={items.length === 0 ? translationText.loading : translationText.select}
                            isSearchable
                            isMulti
                            data-testid="recommendedPairingSelect"
                            value={items
                                .filter((item) => item.id && formData.suggestedItems.includes(item.id))
                                .map((item) => ({ value: item.itemName, label: item.itemName, id: item.id }))}
                            onChange={(selectedItems) => {
                                let selectedValues = selectedItems.map((item) => item.id)
                                setFormData({ ...formData, suggestedItems: selectedValues })
                            }}
                        />
                    </div>
                    <div className="col-md-12">
                        <div className="help-small">
                            <label className="" htmlFor="autoSizingInput"><JTranslation typeCase="pascal" text={ADD_INGREDIENTS} />
                            </label>
                            <MlxPopover data={{title:ADDON_PRICE, body:HELP_ADD_ONS_PRICE}}/>
                        </div>
                        <Select
                            options={ingredientsList.map((item) => {
                                return {
                                    value: item.ingredientId,
                                    label: item.ingredientName,
                                    id: item.id,
                                }
                            })}
                            placeholder={ingredientsList.length === 0 ? translationText.loading : translationText.select}
                            isSearchable
                            isMulti
                            data-testid="ingredientsSelect"
                            value={ingredientsList
                                .filter((item) =>
                                    item.ingredientId &&
                                    formData.selectedKeyIngredients?.some((ingredient) => ingredient.ingredientId === item.ingredientId)
                                )
                                .map((item) => ({ value: item.ingredientId, label: item.ingredientName, id: item.ingredientId }))
                            }

                            onChange={(selectedItems) => {
                                let selectedValues = selectedItems.map((item) => {
                                    return {
                                        ingredientId: item.id
                                    }
                                }) as SingleIngredient[]

                                setFormData({ ...formData, selectedKeyIngredients: selectedValues })
                            }}
                        />
                    </div>
                </div>

                <AdditionalInfo formData={formData} setFormData={setFormData} />

                <div className="row">
                    <div className="col-lg-12 mb-3 d-flex">
                        {isHistoryAdded() ? <div className="fw-bold"><JTranslation typeCase="pascal" text={WINE_HISTORY} /></div> :
                            <button className="fw-bold btn-transparent btn-additional-info"
                                data-testid="addHistoryBtn"
                                onClick={() => setWineHistory(true)}><FaPlus /> <JTranslation typeCase="pascal" text={ADD_HISTORY} /></button>}
                        <div className="help-small">
                            <MlxPopover data={{title:ADD_HISTORY, body:HELP_HISTORY}}/>
                        </div>

                    </div>
                </div>
                {isHistoryAdded() &&
                    <div className="row">
                        <div className="col-lg-12 mb-3">
                            <div className="alert alert-primary" role="alert">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="col-11 overflow-hidden" onClick={() => setWineHistory(true)}>
                                        <label className="history-item">{formData.historyInfo.title}</label></div>
                                    <div className="col-1 text-end" onClick={() => {
                                        setWarning(true);
                                    }}>
                                        <i className="ri-delete-bin-6-line delete"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
            </div >
            <FileUpload displayToast={displayToast} onUploadError={onUploadError} uploadFileMutation={uploadFileMutation}
                onUploadSuccess={onUploadSuccess} images={images} videos={videos} onDeleteFile={onDeleteFile}
                formData={formData} setImages={setImages} setFormData={setFormData} validateImageDimension={validateImageDimension} cropType={cropType} divClass="col-lg-4 pt-3 custom-bg" parent="beverage" />
        </>
    )
}

export default AddBeverageForm;