import { useEffect, useState, useContext } from 'react'
import { Button } from 'primereact/button'
import { changeCustomMenuMarketPriceLabel, toggleCustomMenuCardPrice } from '../../../helpers/customMenuHelper'
import { CommonCtx } from '../../../context/CommonCtxProvider'
import { FnBCtx } from '../../../context/MoveItemCtxProvider'
import { FnBGlobalSetting } from '../../../constants/staticTypes'
import { InputSwitch } from 'primereact/inputswitch'
import { Offcanvas, OverlayTrigger } from 'react-bootstrap'
import { Panel } from 'primereact/panel'
import { popover } from '../../../helpers/utils'
import { SpecialMenuIdPrefixes } from '../../../constants/constants'
import { useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import useMutationHook from '../../../hooks/useMutationHook'
import {
    CUSTOM_LABEL_FOR_MARKET_PRICE,
    CUSTOM_MENU_CONFIGURATION,
    DISABLE_DND,
    DND_HELP,
    DND,
    ENABLE_DND,
    GLOBAL_PRICE_DISPLAY,
    HELP_CUSTOM_MENU_CONFIG,
    HIDE_DISPLAY_PRICE,
    LOCK_DND,
    SHOW_DISPLAY_PRICE,
    UNLOCK_DND,
} from '../../../constants/strings'
import { JTranslation, TranslationContext, jTranslationText } from '../../../helpers/jTranslate'
import MlxPopover from '../../common/MlxPopover'

type Props = {
    handleClose: () => void
    show: boolean
    displayToast: (message: string, variant: string) => void
}

function CustomMenuFnBConfig({ show, handleClose, displayToast }: Props) {
    const queryClient = useQueryClient()
    const fnBGlobalConfigMutation = useMutationHook(queryClient, true)
    const idPrefix = SpecialMenuIdPrefixes.SPECIAL_MENU
    const { customMenuId } = useParams()
    // CONTEXT VARIABLES
    const { dndLock, setDndLock } = useContext(FnBCtx)
    const { fnBGlobalSettings, setFnBGlobalSettings } = useContext(CommonCtx)
    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext
    // STATE VARIABLE
    const [settings, setSettings] = useState<FnBGlobalSetting>(fnBGlobalSettings)
    const [customLabelEdited, setCustomLabelEdited] = useState(false)
    const [menuId, setMenuId] = useState('')
    const [translationText, setTranslatedText] = useState<{
        lockDnd: string,
        unlockDnd: string,
      }>({ 
        lockDnd: LOCK_DND,
        unlockDnd: UNLOCK_DND,
    })

    const onSuccess = (newSettings: FnBGlobalSetting) => {
        setFnBGlobalSettings({
            ...newSettings,
        })
    }

    // api call to change global price display
    const changeGlobalPriceDisplay = () => {
        toggleCustomMenuCardPrice(
            { showMenuCardPrice: !settings?.showMenuCardPrice, menuId: menuId },
            fnBGlobalConfigMutation,
            onSuccess,
            displayToast
        )
    }

    // api call to change default market price text
    const changeMarketPriceText = () => {
        changeCustomMenuMarketPriceLabel(
            { marketPriceLabel: settings?.marketPriceLabel, menuId: menuId },
            fnBGlobalConfigMutation,
            onSuccess,
            displayToast
        )
    }

    // effect to show and hide the save and cancel buttons
    useEffect(() => {
        if (settings?.marketPriceLabel && settings?.marketPriceLabel !== fnBGlobalSettings.marketPriceLabel) {
            setCustomLabelEdited(true)
        } else {
            setCustomLabelEdited(false)
        }

        return () => {
            setCustomLabelEdited(false)
        }
    }, [settings, fnBGlobalSettings])

    // effect to replaced settings value with sever state on component mount
    useEffect(() => {
        setSettings(fnBGlobalSettings)
    }, [show, fnBGlobalSettings])

    // setting custom menu id
    useEffect(() => {
        if (customMenuId) {
            setMenuId(`${idPrefix}${customMenuId}`)
        }
    }, [customMenuId, idPrefix])

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: LOCK_DND, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: UNLOCK_DND, typeCase: 'pascal', translationContext }),
                ])
                setTranslatedText({
                    lockDnd: translations[0] ?? LOCK_DND,
                    unlockDnd: translations[1] ?? UNLOCK_DND,
                })
            } catch {
                setTranslatedText({
                    lockDnd: LOCK_DND,
                    unlockDnd: UNLOCK_DND,
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    return (
        <Offcanvas show={show} onHide={() => handleClose()} placement="end" backdrop="static">
            <Offcanvas.Header closeButton className="border-bottom">
                <Offcanvas.Title className="d-flex justify-content-center align-items-center">
                    <JTranslation typeCase="pascal" text={CUSTOM_MENU_CONFIGURATION} />
                    <MlxPopover data={{title:CUSTOM_MENU_CONFIGURATION, body:HELP_CUSTOM_MENU_CONFIG}} />
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Panel className="mt-2 p-panel-content-custom">
                    <div className="d-flex">
                        <div className="flex-grow-1 me-2"><JTranslation typeCase="pascal" text={GLOBAL_PRICE_DISPLAY} /></div>
                        <div className="flex-grow-0">
                            <InputSwitch
                                className="custom-input-switch"
                                checked={!settings?.showMenuCardPrice}
                                onChange={() => {
                                    setSettings({
                                        ...settings,
                                        showMenuCardPrice: !settings?.showMenuCardPrice,
                                    })
                                    // api call
                                    changeGlobalPriceDisplay()
                                }}
                                title={settings?.showMenuCardPrice ? HIDE_DISPLAY_PRICE : SHOW_DISPLAY_PRICE}
                            />
                        </div>
                    </div>
                </Panel>
                <div className="drag-bg mt-3">
                    <div className="d-flex align-items-center">
                        <div className="flex-grow-1 d-flex align-items-center me-2">
                            {dndLock 
                                ? <JTranslation typeCase="pascal" text={ENABLE_DND} /> 
                                : <JTranslation typeCase="pascal" text={DISABLE_DND} />
                            }
                            <MlxPopover data={{title:DND, body:DND_HELP}} />
                        </div>
                        <div className="flex-grow-0 d-flex">
                            <InputSwitch
                                className="custom-input-switch"
                                checked={!dndLock}
                                onChange={() => setDndLock(!dndLock)}
                                title={dndLock ? translationText.lockDnd : translationText.unlockDnd}
                            />
                        </div>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-12">
                        <div className="form-group input-group custom-input-group">
                            <label htmlFor="custom_label" className=" form-label w-100 d-block">
                                <JTranslation typeCase="pascal" text={CUSTOM_LABEL_FOR_MARKET_PRICE} />
                            </label>
                            <input
                                id="custom_label"
                                className="form-control"
                                autoComplete="off"
                                maxLength={50}
                                value={settings?.marketPriceLabel}
                                onChange={(e) =>
                                    setSettings({
                                        ...settings,
                                        marketPriceLabel: e.target.value,
                                    })
                                }
                            />

                            <span className="input-group-text" onClick={() => setCustomLabelEdited(true)}>
                                <i className="ri-pencil-line"></i>
                            </span>
                        </div>

                        {customLabelEdited && (
                            <>
                                <div className="d-flex justify-content-end">
                                    <Button
                                        icon="pi pi-check"
                                        aria-label="Filter"
                                        className="mt-1 p-button-sm  p-button-rounded p-button-success p-button-text"
                                        onClick={() => {
                                            // api call
                                            changeMarketPriceText()
                                        }}
                                    />
                                    <Button
                                        icon="pi pi-times"
                                        className=" mt-1 p-button-rounded p-button-danger p-button-text"
                                        aria-label="Cancel"
                                        onClick={() =>
                                            setSettings({
                                                ...settings,
                                                marketPriceLabel: fnBGlobalSettings.marketPriceLabel,
                                            })
                                        }
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default CustomMenuFnBConfig
