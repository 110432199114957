import { useEffect, useRef, useState } from 'react'
import '../manage_work_schedule/work_schedule.scss'
import { AxiosResponse } from 'axios'
import { DATE_FORMAT, toastMessageInitialData } from '../../constants/constants'
import { DatePicker } from 'antd'
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown'
import { fetchDraftedAndPublishedScheduleIds, fetchStaffsWithSchedulesAndShift, getGlobalStaffing, getWeekRange, initialDataMinimumEmployeeCount, initialScheduleStatus, Schedule, ScheduleData, ScheduleIdsResponse, ScheduleMetadata, Staff, StartAndEndDates } from '../../helpers/workTimeScheduleHelper'
import { getRolesList } from '../../helpers/roleHelper'
import { getShiftConfigList } from '../../helpers/shiftConfigHelper'
import { isEmptyObject } from '../../helpers/utils'
import { JTranslation } from '../../helpers/jTranslate'
import { Margin, usePDF } from 'react-to-pdf'
import { PrimeReactDropDown, ToastMessageProps, JobRole, ShiftTemplate, PerDayCount, ShiftDropDown } from '../../constants/staticTypes'
import { PRINT_PREVIEW, PRINT_PREVIEW_HELP } from '../../constants/strings'
import { ROLES_LIST, SCHEDULE_STATUS, SHIFT_CONFIG_LIST, STAFFING_REQUIREMENTS_LIST, STAFFS_WITH_SCHEDULES_AND_SHIFT } from '../../constants/queryKeys'
import { useReactToPrint } from 'react-to-print';
import dayjs from 'dayjs'
import Loader from '../../components/loader/Loader'
import MlxPopover from '../../components/common/MlxPopover'
import MlxTooltip from '../../components/common/MlxTooltip'
import RoleBasedShiftScheduler from './RoleBasedShiftScheduler'
import ToastAlert from '../../components/alert/ToastAlert'
import useQueryHook from '../../hooks/useQueryHook'
import withSidebar from '../../hoc/withSidebar'

const RoleBasedView = () => {
    const [selectedWeek, setSelectedWeek] = useState<dayjs.Dayjs>(dayjs())
    const [selectedDateRange, setSelectedDateRange] = useState<StartAndEndDates>(getWeekRange(selectedWeek))
    const [selectedDay, setSelectedDay] = useState('')
    const [selectedView, setSelectedView] = useState<string | null>('role-based')
    const [selectedEmployeeName, setSelectedEmployeeName] = useState('')
    const [staffDataWithSchedule, setStaffDataWithSchedule] = useState<Staff | null>(null)
    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
    const [shiftSideBar, setShiftSideBar] = useState(false)
    const [pageInitialLoad, setPageInitialLoad] = useState(true)
    const [roles, setRoles] = useState<PrimeReactDropDown[]>([])
    const [shifts, setShifts] = useState<ShiftDropDown[]>([])
    const [draftedScheduleId, setDraftedScheduleId] = useState('')
    const [publishedScheduleId, setPublishedScheduleId] = useState('')
    const [scheduleStatus, setScheduleStatus] = useState<Schedule>(initialScheduleStatus)
    const [scheduleData, setScheduleData] = useState<ScheduleIdsResponse | null>(null)
    const [staffsWithSchedule, setStaffsWithSchedule] = useState<ScheduleData | null>(null)
    const [staffingRequirement, setStaffingRequirement] = useState(initialDataMinimumEmployeeCount)
    const [scheduleId, setScheduleId] = useState('')
    const [scheduleMetadata, setScheduleMetadata] = useState<ScheduleMetadata | null>(null)
    const contentToPrint = useRef(null);
    const { toPDF, targetRef: contentToPDF } = usePDF({ filename: 'Weekly_Staff_Shift_Schedule.pdf', method: 'save', page: { orientation: 'landscape', margin: Margin.SMALL } });


    const reloadPageData = () => {
        setScheduleData(null)
        setScheduleId("")
        setDraftedScheduleId("")
        setPublishedScheduleId("")
        scheduleDataQuery.refetch()
    }

    const onSuccessOfStaffsWithSchedule = (res: AxiosResponse<any, any>) => {
        const staffsWithSchedule: ScheduleData = res.data.data
        setStaffsWithSchedule(staffsWithSchedule)
        setScheduleMetadata(staffsWithSchedule?.schedule?.scheduleMetadata ?? {} as ScheduleMetadata)
        setPageInitialLoad(false)
    }

    const onSuccessOfScheduleStatus = (res: AxiosResponse<any, any>) => {
        const scheduleData: ScheduleIdsResponse = res.data.data
        setScheduleData(scheduleData)
        // Destructure published from scheduleData
        const { published } = scheduleData

        // Check if published schedules is empty
        const isPublishedEmpty = isEmptyObject(published)

        // If published is not empty, save the published's id using savePublishedScheduleId
        if (!isPublishedEmpty) {
            savePublishedScheduleId(published.id)
        } else {
            setScheduleData(null)
            setScheduleId("")
            setDraftedScheduleId("")
            setPublishedScheduleId("")
            setStaffsWithSchedule(null)
            setScheduleMetadata(null)
        }
    }

    const onSuccessOfGlobalStaffing = (res: AxiosResponse<any, any>) => {
        const staffingData: PerDayCount[] = res.data.data.lists
        setStaffingRequirement({ availability: staffingData })
    }

    const onRoleListSuccess = (res: AxiosResponse<any, any>) => {
        let data: JobRole[] | [] = res.data?.data.lists
        let roleDetails: PrimeReactDropDown[] = []
        data.forEach((item) => {
            if (item.isActive === true) {
                roleDetails.push({
                    code: item.id,
                    name: item.jobRoleName,
                })
            }
        })
        setRoles(roleDetails)
    }

    const onShiftConfigSuccess = (res: AxiosResponse<any, any>) => {
        const data: ShiftTemplate[] = res.data?.data.lists
        let shiftDetails: ShiftDropDown[] = []
        data.forEach((item) => {
            const { shiftName, startTime, endTime } = item
            shiftDetails.push({
                code: shiftName,
                name: shiftName,
                startTime: startTime,
                endTime: endTime
            })
        })
        setShifts(shiftDetails)
    }

    const savePublishedScheduleId = (id: string) => setPublishedScheduleId(id)
    const saveEmployeeName = (name: string) => setSelectedEmployeeName(name)

    // fetch shift list (api call)
    useQueryHook(SHIFT_CONFIG_LIST, () => getShiftConfigList(), onShiftConfigSuccess)
    // fetch role list (api call)
    useQueryHook(ROLES_LIST, () => getRolesList({ limit: 500 }), onRoleListSuccess)
    // api call to fetch staffing requirements
    const staffingQuery = useQueryHook(STAFFING_REQUIREMENTS_LIST, () => getGlobalStaffing(), onSuccessOfGlobalStaffing)
    // schedule data main QUERY-1
    const scheduleDataQuery = useQueryHook(SCHEDULE_STATUS, () => fetchDraftedAndPublishedScheduleIds(selectedDateRange), onSuccessOfScheduleStatus)

    const getScheduleId = (publishedScheduleId: string): string => {
        if (publishedScheduleId) {
            // Case 1: Return publishedScheduleId if draft is empty
            return publishedScheduleId;
        } else {
            // Default case: Return an empty string or handle as needed
            return '';
        }
    }

    // Determine published schedule ID to use
    useEffect(() => {
        const scheduleId = getScheduleId(publishedScheduleId);
        setScheduleId(scheduleId)
    }, [publishedScheduleId])
    //  schedule data dependent QUERY-3
    const staffsWithScheduleQuery = useQueryHook(STAFFS_WITH_SCHEDULES_AND_SHIFT, () => fetchStaffsWithSchedulesAndShift({ scheduleId }), onSuccessOfStaffsWithSchedule, undefined, !!scheduleId)

    // assigning API data to state variables
    useEffect(() => {
        if (staffsWithSchedule) {
            const { schedule } = staffsWithSchedule;
            setScheduleStatus(schedule);
        }
    }, [staffsWithSchedule]); // Run effect when staffsWithSchedule data changes

    // refetch the staff schedule api once the selected date has changed 
    useEffect(() => {
        reloadPageData()
    }, [selectedDateRange])

    // Calculate the date range for the selected week
    useEffect(() => {
        const dateRange = getWeekRange(selectedWeek)
        setSelectedDateRange(dateRange)
    }, [selectedWeek])

    const handlePrint = useReactToPrint({
        documentTitle: "Weekly_Staff_Shift_Schedule",
        removeAfterPrint: true,
    });

    // loading screen
    if (staffsWithScheduleQuery.isFetching || staffsWithScheduleQuery.isFetching) {
        return <Loader />
    }
    // error screen
    if (scheduleDataQuery.status === "error" || staffsWithScheduleQuery.status === "error") {
        return (
            <div>
                <span>Oops! Something went wrong.</span>
                <span>We're sorry, but an error occurred while loading this page. Please try refreshing the page or come back later.</span>
            </div>
        )
    }

    return (
        <>
            <div className="page-title d-flex mb-3 align-items-center">
                <div className="flex-grow-1 d-flex">
                    <span className="d-flex">
                        <h5 className="d-flex mb-0 align-items-center">
                            <JTranslation typeCase="pascal" text={PRINT_PREVIEW} />
                            <MlxPopover
                                data={{ title: PRINT_PREVIEW, body: PRINT_PREVIEW_HELP }}
                            />
                        </h5>
                    </span>
                </div>
            </div>
            {/* TOAST MESSAGE COMPONENT */}
            <ToastAlert
                show={toastMessage.show}
                onClose={() => setToastMessage(toastMessageInitialData)}
                message={toastMessage.message}
                variant={toastMessage.variant}
            />
            <div className="col-md-12 col-lg-12 mb-3 h-100 ">
                <div className="card h-100 ">
                    <div className="card-header flex-grow-0">
                        <div className="d-flex  align-items-center">
                            <div className="flex-grow-1"></div>
                            <div className="flex-grow-0 me-3 ms-1 gap-2 d-flex">
                                {/* <MlxTooltip target=".print-icon">
                                    <div className="icon me-2 align-items-center justify-content-center d-flex">
                                        <i
                                            className="ri-download-2-line print-icon icon"
                                            onClick={() => toPDF()}
                                            data-pr-tooltip="Export to PDF"
                                            data-pr-position="left"
                                        />
                                    </div>
                                </MlxTooltip> */}
                                <MlxTooltip target=".print-icon">
                                    <div className="icon me-2 align-items-center justify-content-center d-flex">
                                        <i
                                            className="ri-printer-line print-icon icon"
                                            onClick={() => {
                                                handlePrint(null, () => contentToPrint.current)
                                            }}
                                            data-pr-tooltip="Print Schedule"
                                            data-pr-position="left"
                                        />
                                    </div>
                                </MlxTooltip>
                                <Dropdown
                                    value={selectedView}
                                    onChange={(e: DropdownChangeEvent) => {
                                        setSelectedView(e.value);
                                    }}
                                    options={[
                                        { name: "Role Based View", code: "role-based" },
                                        { name: "Show All Staffs", code: "all-staffs" },
                                    ]}
                                    optionLabel="name"
                                    optionValue="code"
                                    placeholder="Select View"
                                    className="w-full md:w-14rem"
                                />
                                <span>
                                    <DatePicker
                                        onChange={(date) => {
                                            setSelectedWeek(dayjs(date));
                                        }}
                                        picker="week"
                                        value={selectedWeek}
                                        className="custom-schedule-calendar"
                                        format={(value) =>
                                            `${dayjs(value)
                                                .startOf("week")
                                                .format(DATE_FORMAT)} - ${dayjs(value)
                                                    .endOf("week")
                                                    .format(DATE_FORMAT)}`
                                        }
                                        allowClear={false}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="card-body flex-grow-1 overflow-auto">
                        <div className="survey-section h-100 overflow-auto">
                            <RoleBasedShiftScheduler
                                shiftSideBar={shiftSideBar}
                                setShiftSideBar={setShiftSideBar}
                                saveEmployeeName={saveEmployeeName}
                                selectedWeek={selectedWeek}
                                staffsWithSchedule={staffsWithSchedule}
                                staffingRequirement={staffingRequirement.availability}
                                scheduleId={scheduleId}
                                setToastMessage={setToastMessage}
                                reloadPageData={reloadPageData}
                                setData={setStaffDataWithSchedule}
                                setSelectedDay={setSelectedDay}
                                scheduleMetadata={scheduleMetadata}
                                isLoading={
                                    scheduleDataQuery.isFetching ||
                                    staffsWithScheduleQuery.isFetching
                                }
                                selectedView={selectedView}
                            />
                        </div>
                    </div>
                    {/* Rendering a table for printing and pdf export purposes */}
                    <div
                        style={{ position: "absolute", zIndex: "-100", height: "auto" }}
                    >
                        <style type="text/css" media="print">
                            {
                                "\
                    @page { size: landscape; }\
                    "
                            }
                        </style>
                        <div ref={contentToPrint}>
                            <div ref={contentToPDF}>
                                <div className="card-body flex-grow-1">
                                    <div className="survey-section h-100">
                                        <RoleBasedShiftScheduler
                                            shiftSideBar={shiftSideBar}
                                            setShiftSideBar={setShiftSideBar}
                                            saveEmployeeName={saveEmployeeName}
                                            selectedWeek={selectedWeek}
                                            staffsWithSchedule={staffsWithSchedule}
                                            staffingRequirement={staffingRequirement.availability}
                                            scheduleId={scheduleId}
                                            setToastMessage={setToastMessage}
                                            reloadPageData={reloadPageData}
                                            setData={setStaffDataWithSchedule}
                                            setSelectedDay={setSelectedDay}
                                            scheduleMetadata={scheduleMetadata}
                                            isLoading={
                                                scheduleDataQuery.isFetching ||
                                                staffsWithScheduleQuery.isFetching
                                            }
                                            selectedView={selectedView}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default withSidebar(RoleBasedView)
