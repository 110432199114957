import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { COUPON_CODE, CREATED, EMAIL_TEXT, EXPIRY, NO_COUPONS, PRIZE, PRIZE_DESCRIPTION, REDEEM, REDEEMED, REDEEM_COUPON, STATUS } from "../../constants/strings";
import { checkPermission, convertUTCtoLocalByOffset, getCustomPaginator } from "../../helpers/utils";
import { Coupon } from '../../constants/staticTypes'; 
import { useQueryClient } from "react-query";
import useMutationHook from "../../hooks/useMutationHook";
import { redeemCoupon } from "../../helpers/manageCouponsHelper";
import { AlertVariant, MOBILE_VIEW_BREAKPOINT, PermissionKeys, SubPermissionKeys } from "../../constants/constants";
import WarningModal from "../warning_modal/WarningModal";
import { useContext, useEffect, useState } from "react";
import { JTranslation, TranslationContext, jTranslationText } from '../../helpers/jTranslate';
import { useWindowSize } from 'react-use';
import { Card } from 'primereact/card';
import { DataView } from 'primereact/dataview';
import MlxTooltip from '../common/MlxTooltip';

type Props = {
    searchValue: string,
    lastKey: string | null,
    coupons: Coupon[],
    onSearchTrigger: (searchKey: string, lastKeyValue: string | null) => void,
    setPagination: React.Dispatch<React.SetStateAction<boolean>>,
    isLoading: boolean,
    selectedPage: number,
    setSelectedPage: React.Dispatch<React.SetStateAction<number>>,
    setCouponsList: React.Dispatch<React.SetStateAction<Coupon[]>>,
    showToastMessage: (message: string, variant: string) => void
}

function CouponsListDataGrid({ coupons, onSearchTrigger, lastKey, searchValue, setPagination, isLoading,
    selectedPage, setSelectedPage, setCouponsList, showToastMessage }: Props) {
    const queryClient = useQueryClient();
    const redeemCouponMutation = useMutationHook(queryClient, true);
    const [showWarning, setWarning] = useState(false);
    const [redeemCouponData, setRedeemCouponData] = useState<Coupon>({} as Coupon);

    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext

	const [translationText, setTranslatedText] = useState<{
        paginationTemplate: string,
        emptyMessage: string,
      }>({ 
        paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
        emptyMessage: NO_COUPONS
    });

    const { width, height } = useWindowSize()
	const breakPoint = MOBILE_VIEW_BREAKPOINT

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: 'Showing 1 to 2 of 3 items', typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: NO_COUPONS, typeCase: 'pascal', translationContext }),
                ])
                
                setTranslatedText({
                    paginationTemplate:
                        translations[0]
                            ?.replace('1', '{first}')
                            ?.replace('2', '{last}')
                            ?.replace('3', '{totalRecords}') ?? 'Showing {first} to {last} of {totalRecords} items',
                    emptyMessage: translations[1] ?? NO_COUPONS
                })
            } catch {
                setTranslatedText({
                    paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
                    emptyMessage: NO_COUPONS
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    // on redeem success
    const onRedeemSuccess = (oldItem: Coupon, newItem: Coupon, message: string) => {
        const index = coupons.indexOf(oldItem);
        coupons.splice(index, 1, {
            ...newItem
        });
        setCouponsList([...coupons]);
        showToastMessage(message, AlertVariant.SUCCESS);
    }

    const emailBodyTemplate = (coupon: Coupon) => {
        return <MlxTooltip target='.email-text'>
            <a className="overflow-ellipsis email-text" 
                data-pr-tooltip={coupon.guestEmail} 
                data-pr-position="bottom" 
                data-testid={"email#" + coupon.couponCode}
                href={"mailto:" + coupon.guestEmail}
            >
                    {coupon.guestEmail}
            </a>
        </MlxTooltip>;
    }

    const statusBodyTemplate = (coupon: Coupon) => {
        if (coupon.couponStatus.toLowerCase() === "active") {
            return <button className={`btn  btn-success`} data-testid={`${coupon.id}-redeem`}
                disabled={!checkPermission(queryClient, PermissionKeys.MANAGE_COUPON, SubPermissionKeys.REDEEM)}
                onClick={() => {
                    if (checkPermission(queryClient, PermissionKeys.MANAGE_COUPON, SubPermissionKeys.REDEEM)) {
                        setRedeemCouponData(coupon);
                        setWarning(true);
                    }
                }}><JTranslation typeCase="pascal" text={REDEEM} /></button>
        } else {
            return <span className={`qr-status ${coupon.couponStatus.toLowerCase()}`}><JTranslation typeCase="pascal" text={coupon.couponStatus} /></span>;
        }
    }

    const itemTemplate = (coupon: Coupon) => {
        return (
			<Card className="data-view-card mt-2 d-flex flex-column justify-content-even">
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <p className="m-0 text-nowrap">{coupon.couponCode}</p>
                </div>
				<div className='mt-2'>
					<p className="m-0">{emailBodyTemplate(coupon)}</p>
                    <div className="d-flex flex-row align-items-center">
                        <span className="me-2"><JTranslation typeCase="capitalize" text={PRIZE} />:</span>
					    <p className="m-0 font-bold">{coupon.gamePrizeDetails.prizeWinning}</p>
                    </div>
                    <div className="d-flex flex-row align-items-center">
                        <span className="me-2"><JTranslation typeCase="capitalize" text={EXPIRY} />:</span>
					    <p className="m-0 font-bold">{convertUTCtoLocalByOffset(coupon.couponValidTillFormatted)}</p>
                    </div>
				</div>
				<div className="view-footer mt-2 pt-3 d-flex flex-row justify-content-between align-items-center">
					<div className='d-flex justify-content-even align-items-center'>
                        {!!coupon.statusChangeAt &&
                            coupon.couponStatus.toLowerCase() === "redeemed" && 
                            <div className="d-flex flex-row align-items-center">
                                <span className="me-2"><JTranslation typeCase="capitalize" text={REDEEMED} />:</span>
                                <p className="m-0 font-bold">{convertUTCtoLocalByOffset(coupon.statusChangeAt)}</p>
                            </div>
                        }
                    </div>
                    <div className="d-flex flex-row align-items-center">{statusBodyTemplate(coupon)}</div>
				</div>
			</Card>
		)
    }

    return <>
        <WarningModal title={REDEEM_COUPON} show={showWarning} onHide={() => setWarning(false)}
            callback={() => {
                setWarning(false);
                redeemCoupon(queryClient, redeemCouponData, redeemCouponMutation, onRedeemSuccess, showToastMessage)
            }} 
        />
        {width <= breakPoint ? (
            <DataView
                value={coupons}
                layout={'list'}
                itemTemplate={itemTemplate}
                paginator
                rows={10}
                paginatorClassName="p-dataview-customers"
                dataKey="id"
                emptyMessage={translationText.emptyMessage}
            />
            ) : (
                <DataTable value={coupons} paginator className="p-datatable-customers" rows={10}
                    pageLinkSize={3} first={selectedPage} onPage={(e) => { setSelectedPage(e.first) }}
                    scrollable scrollHeight="flex"
                    paginatorTemplate={getCustomPaginator(setPagination, onSearchTrigger, coupons.length, lastKey, searchValue)}
                    dataKey="id" rowHover responsiveLayout="scroll"
                    emptyMessage={NO_COUPONS} loading={isLoading}
                    currentPageReportTemplate={translationText.paginationTemplate}>
                    <Column field="couponCode" header={<JTranslation typeCase="pascal" text={COUPON_CODE} />} sortable style={{ minWidth: '10rem' }}/>
                    <Column field="guestEmail" className='' header={<JTranslation typeCase="pascal" text={EMAIL_TEXT} />} sortable style={{ maxWidth: '15rem', minWidth: '15rem' }}
                        body={emailBodyTemplate} />
                    <Column field="gamePrizeDetails.prizeWinning" header={<JTranslation typeCase="pascal" text={PRIZE} />} sortable style={{ maxWidth: '15rem', minWidth: '15rem' }} />
                    <Column field="gamePrizeDetails.prizeDescription" header={<JTranslation typeCase="pascal" text={PRIZE_DESCRIPTION} />} sortable style={{ maxWidth: '15rem', minWidth: '15rem' }} />
                    <Column field="createdAt" header={<JTranslation typeCase="pascal" text={CREATED} />}
                        sortable style={{ minWidth: '8rem' }}
                        body={(coupon: Coupon) => {
                            if (coupon.createdAt) {
                                return <>{convertUTCtoLocalByOffset(coupon.createdAt)}</>
                            }
                            return "";
                        }} />
                    <Column field="couponValidTillFormatted" header={<JTranslation typeCase="pascal" text={EXPIRY} />} sortable style={{ minWidth: '8rem' }}
                        sortFunction={(e) => {
                            const rows = e?.data as Coupon[];
                            rows.sort((a, b) => {
                                if (a.couponValidTillFormatted === "") {
                                    return -1;
                                }
                                return new Date(a.couponValidTillFormatted).getTime() - new Date(b.couponValidTillFormatted).getTime()
                            });
                            if (e.order === 1) {
                                return rows;
                            } else {
                                return rows.reverse();
                            }
                        }} />
                    <Column field="statusChangeAt" header={<JTranslation typeCase="pascal" text={REDEEMED} />}
                        sortable style={{ minWidth: '12rem' }}
                        body={(coupon: Coupon) => {
                            if (coupon.statusChangeAt &&
                                coupon.couponStatus.toLowerCase() === "redeemed") {
                                return <>{convertUTCtoLocalByOffset(coupon.statusChangeAt)}</>
                            }
                            return "";
                        }} />
                    <Column field="couponStatus" sortable header={<JTranslation typeCase="pascal" text={STATUS} />} className="td-center" style={{ maxWidth: '12rem', minWidth: '12rem' }}
                        body={statusBodyTemplate} bodyClassName="bg-sticky-color" alignFrozen="right" frozen={true} />
                </DataTable >
            )
        }
    </>
}
export default CouponsListDataGrid;