import React from 'react'
import Lottie from 'react-lottie'
import animationData from './animations/bunnyAnimationColor.json'
import './easterLogoAnimation.css'
import { getEasterDayEffects } from '../celebrationsConfigs'

const EasterLogoAnimation: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  const easterDayEffects = getEasterDayEffects()
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  if (easterDayEffects?.showSpecialLogo) {
    return (
      <div className='animation-container-easter-logo' style={style}>
        <Lottie
          options={defaultOptions}
          height={70}
          width={70}
          isPaused={false}
          isStopped={false}
        />
      </div>
    )
  }
  return null
}

export default EasterLogoAnimation
