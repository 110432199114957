import { createContext, Dispatch, PropsWithChildren, SetStateAction, useState, useMemo } from "react";
import { initialScheduleMenu, initialStringArray, initialCustomMenuResponse } from "./defaultContextValues";
import { MenuItem, Move, SubCategory, ScheduleMenuId, CustomMenu, CustomMenuCategoriesAndItems, Menu, CustomMenuGuestView } from "../constants/staticTypes";

// SETTER TYPE DECLARATIONS
type SetBoolean = Dispatch<SetStateAction<boolean>>;
type SetCategory = Dispatch<SetStateAction<string>>;
type SetCategoryDetails = Dispatch<SetStateAction<SubCategory>>;
type SetCustomMenuItem = Dispatch<SetStateAction<MenuItem[]>>;
type SetMenuItem = Dispatch<SetStateAction<string>>;
type SetMenuItemDetails = Dispatch<SetStateAction<MenuItem>>;
type SetMoveType = Dispatch<SetStateAction<Move>>;
type SetNumber = Dispatch<SetStateAction<number>>;
type SetScheduleMenu = Dispatch<SetStateAction<ScheduleMenuId>>;
type SetUpdateAny = Dispatch<SetStateAction<any>>;
type SetUpdateArray = Dispatch<SetStateAction<string[]>>;
type SetUpdateCustomMenu = Dispatch<SetStateAction<CustomMenu>>;
type SetUpdateSelectedMenu = Dispatch<SetStateAction<Menu | CustomMenuGuestView | undefined>>;
type SetUpdateCustomMenuCategory = Dispatch<SetStateAction<CustomMenuCategoriesAndItems | undefined>>;

// UPDATE FUNCTION DECLARATIONS
const UpdateArray: SetUpdateArray = () => []
const UpdateBoolean: SetBoolean = () => false;
const UpdateCategory: SetCategory = () => "";
const UpdateCategoryDetails: SetCategoryDetails = () => [];
const UpdateCustomMenu: SetUpdateCustomMenu = () => initialCustomMenuResponse;
const UpdateCustomMenuItemDetails: SetCustomMenuItem = () => [];
const UpdateMenuItem: SetMenuItem = () => "";
const UpdateMenuItemDetails: SetMenuItemDetails = () => [];
const UpdateMoveType: SetMoveType = () => "item";
const UpdateNumber: SetNumber = () => 0;
const UpdateScheduleAny: SetUpdateAny = () => [];
const UpdateScheduleMenu: SetScheduleMenu = () => initialScheduleMenu;
const UpdateCustomMenuCategory: SetUpdateCustomMenuCategory = () => undefined;
const UpdateSelectedMenu: SetUpdateSelectedMenu = () => undefined;

// CONTEXT VARIABLES
export const FnBCtx = createContext({
    // F&B menu configuration 
    fnBMenuConfigSideBar: false,
    setFnBMenuConfigSideBar: UpdateBoolean,
    // drag and drop lock feature
    dndLock: false,
    setDndLock: UpdateBoolean,
    // move item and category
    moveType: "item",
    setMoveType: UpdateMoveType,
    moveItemSideBar: false,
    setMoveItemSideBar: UpdateBoolean,
    moveCategorySideBar: false,
    setCategoryItemSideBar: UpdateBoolean,
    // move item to a different category
    itemToBeMoved: "",
    setItemToBeMoved: UpdateMenuItem,
    itemToBeMovedDetails: [],
    setItemToBeMovedDetails: UpdateMenuItemDetails,
    // move category to a different menu
    categoryToBeMoved: "",
    setCategoryToBeMoved: UpdateCategory,
    categoryToBeMovedDetails: [],
    setCategoryToBeMovedDetails: UpdateCategoryDetails,
    // schedule menu
    scheduleMenuItem: initialScheduleMenu,
    setScheduleMenuItem: UpdateScheduleMenu,
    // redirection trigger for item view page
    rerender: 0,
    setRerender: UpdateNumber,
    // guest view selected menu item changed
    changeInSelectedMenu: 0,
    setChangeInSelectedMenu: UpdateNumber,
    // selected tags
    selectedTag: initialStringArray,
    setSelectedTag: UpdateArray,
    // copy of the selected menu
    selectedMenuCopy: {},
    setSelectedMenuCopy: UpdateScheduleAny,
    // custom F&B menu
    customMenuItem: initialCustomMenuResponse,
    setCustomMenuItem: UpdateCustomMenu,
    isFormValidated: false,
    setIsFormValidated: UpdateBoolean,
    source: [],
    setSource: UpdateCustomMenuItemDetails,
    target: [],
    setTarget: UpdateCustomMenuItemDetails,
    selectedCategory: undefined,
    setSelectedCategory: UpdateCustomMenuCategory,
    userSelectedMenu: undefined,
    setUserSelectedMenu: UpdateSelectedMenu,
});

export const FnBMenuCtxProvider = ({ children }: PropsWithChildren) => {
    // NOTE: "CONTEXT VARIABLE" AND "STATE VARIABLE" NAMES SHOULD BE IDENTICAL
    // STATE VARIABLES
    const [categoryToBeMoved, setCategoryToBeMoved] = useState("");
    const [categoryToBeMovedDetails, setCategoryToBeMovedDetails] = useState<any>({});
    const [changeInSelectedMenu, setChangeInSelectedMenu] = useState(0);
    const [customMenuItem, setCustomMenuItem] = useState<CustomMenu>(initialCustomMenuResponse);
    const [dndLock, setDndLock] = useState(true);
    const [fnBMenuConfigSideBar, setFnBMenuConfigSideBar] = useState(false);
    const [isFormValidated, setIsFormValidated] = useState(false)
    const [itemToBeMoved, setItemToBeMoved] = useState("");
    const [itemToBeMovedDetails, setItemToBeMovedDetails] = useState<any>({});
    const [moveCategorySideBar, setCategoryItemSideBar] = useState(false);
    const [moveItemSideBar, setMoveItemSideBar] = useState(false);
    const [moveType, setMoveType] = useState<Move>("item");
    const [rerender, setRerender] = useState(0);
    const [scheduleMenuItem, setScheduleMenuItem] = useState<ScheduleMenuId>(initialScheduleMenu);
    const [selectedCategory, setSelectedCategory] = useState<any>()
    const [userSelectedMenu, setUserSelectedMenu] = useState<any>({});
    const [selectedMenuCopy, setSelectedMenuCopy] = useState<any>({});
    const [selectedTag, setSelectedTag] = useState<string[]>(initialStringArray);
    const [source, setSource] = useState<any>([])
    const [target, setTarget] = useState<any>([])

    // CONTEXT PROVIDER
    return (
        <FnBCtx.Provider
            value={useMemo(() => {
                return (
                    {
                        categoryToBeMoved, setCategoryToBeMoved,
                        categoryToBeMovedDetails, setCategoryToBeMovedDetails,
                        changeInSelectedMenu, setChangeInSelectedMenu,
                        customMenuItem, setCustomMenuItem,
                        dndLock, setDndLock,
                        fnBMenuConfigSideBar, setFnBMenuConfigSideBar,
                        isFormValidated, setIsFormValidated,
                        itemToBeMoved, setItemToBeMoved,
                        itemToBeMovedDetails, setItemToBeMovedDetails,
                        moveCategorySideBar, setCategoryItemSideBar,
                        moveItemSideBar, setMoveItemSideBar,
                        moveType, setMoveType,
                        rerender, setRerender,
                        scheduleMenuItem, setScheduleMenuItem,
                        selectedCategory, setSelectedCategory,
                        userSelectedMenu, setUserSelectedMenu,
                        selectedMenuCopy, setSelectedMenuCopy,
                        selectedTag, setSelectedTag,
                        source, setSource,
                        target, setTarget,
                    }
                )
            }, [
                categoryToBeMoved,
                categoryToBeMovedDetails,
                changeInSelectedMenu,
                customMenuItem,
                dndLock,
                fnBMenuConfigSideBar,
                isFormValidated,
                itemToBeMoved,
                itemToBeMovedDetails,
                moveCategorySideBar,
                moveItemSideBar,
                moveType,
                rerender,
                scheduleMenuItem,
                selectedCategory,
                userSelectedMenu,
                selectedMenuCopy,
                selectedTag,
                source,
                target,
            ])}        >
            {children}
        </FnBCtx.Provider>
    );
};
