import { Accordion, AccordionTab } from 'primereact/accordion'
import { QueryClient } from 'react-query'
import { v4 as uuidv4 } from 'uuid'
import { JTranslation } from '../../helpers/jTranslate'
import { Permission } from '../../constants/staticTypes'
import { ChangeEvent } from 'react'

type Props = {
	permissions: Permission[]
	onPermissionChange: (permission: Permission, event: ChangeEvent<HTMLInputElement>) => void
}

function PermissionFeatureList({ permissions, onPermissionChange }: Readonly<Props>) {
	return (
		<>
			{permissions && permissions?.length > 0 && (
				<div className="mt-2">
					<Accordion activeIndex={parseInt(permissions[0].permissionKey)}>
						{permissions.map((feature, index) => (
							<AccordionTab
								key={feature.permissionKey}
								data-testid={feature.permissionKey + '#permission'}
								header={
									<div
										className={
											feature.permissions.length > 0
												? 'd-flex align-items-center'
												: 'accordian-custom-arrow d-flex align-items-center'
										}
									>
										<span className="form-check btn-lg-custom form-switch ">
											<input
												className="form-check-input form-check-input-custom"
												type="checkbox"
												autoComplete="off"
												data-testid={feature.permissionKey}
												checked={feature.isActive}
												disabled={feature.isDefault}
												onClick={(e) => e.stopPropagation()}
												onChange={(e) => {
													e.stopPropagation()
													if (!feature.isDefault) {
														onPermissionChange(feature, e)
													}
												}}
												id="flexSwitchCheckDefault"
											/>
										</span>
										<span className="role-name text-black">
											<JTranslation typeCase="pascal" text={feature.permissionName} />
										</span>
									</div>
								}
								headerClassName={feature.permissions.length > 0 ? '' : 'accordion-no-children'}
								contentClassName={feature.permissions.length > 0 ? '' : 'd-none'}
							>
								{feature.permissions.length > 0 && (
									<div>
										{feature.permissions.map((permission, _index) => (
											<div
												key={`${permission.permissionKey}-${uuidv4()}`}
												className="d-flex mb-3"
											>
												<div className="flex-grow-0 ">
													<div className="">
														<span className="form-check form-switch">
															<input
																className="form-check-input form-check-input-custom"
																type="checkbox"
																autoComplete="off"
																data-testid={
																	feature.permissionKey +
																	' ' +
																	permission.permissionName
																}
																checked={permission.isActive}
																disabled={permission.isDefault}
																onChange={(e) => {
																	e.stopPropagation()
																	if (!permission.isDefault) {
																		onPermissionChange(permission, e)
																	}
																}}
																id="flexSwitchCheckDefault"
															/>
														</span>
													</div>
												</div>
												<div className="flex-grow-1">
													<JTranslation typeCase="pascal" text={permission.permissionName} />
												</div>
												<div className=""></div>
											</div>
										))}
									</div>
								)}
							</AccordionTab>
						))}
					</Accordion>
				</div>
			)}
		</>
	)
}

export default PermissionFeatureList
