import { useEffect, useState, useContext } from 'react'
import { AxiosResponse } from 'axios'
import { BreadCrumb } from 'primereact/breadcrumb'
import { getIngredientsDetailsBySlug, initialIngredientResponse } from '../../../helpers/manageIngredientsHelper'
import { Gallery, MenuItem, SingleIngredientResponse, ToastMessageProps } from '../../../constants/staticTypes'
import { getBreadCrumbPath, getMenuItemsDetailsBySlug, getMenuItemsPublic, processImageURLsForGallery } from '../../../helpers/menuViewHelper'
import { isJSONObjectEmpty, toPascalCase, unescapeHtml } from '../../../helpers/utils'
import { INGREDIENTS_ITEM_DETAILS, MENU_ITEM_DETAIL, PUBLIC_MENU } from '../../../constants/queryKeys'
import { routes } from '../../../constants/routes'
import { toastMessageInitialData } from '../../../constants/constants'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import GalleryViewWithFooter from './GalleryViewWithFooter'
import Loader from '../../loader/Loader'
import ToastAlert from '../../alert/ToastAlert'
import useQueryHook from '../../../hooks/useQueryHook'
import withPublicHeader from '../../../hoc/withPublicHeader'
import {
  JTranslation,
  jTranslationText,
  TranslationContext,
} from '../../../helpers/jTranslate'
import { Skeleton } from 'primereact/skeleton'

function ItemView() {
  const navigate = useNavigate()
  const location = useLocation()
  const queryClient = useQueryClient()
  // URL PARAMS
  const { menuSlug, categorySlug, itemSlug, ingredientSlug } = useParams()
  // STATE VARIABLES
  const [item, setItem] = useState<MenuItem>()
  const [breadCrumbItems, setBreadCrumbItems] = useState<{ label: string, url: string }[]>([])
  const [galleryImages, setGalleryImages] = useState<Gallery[]>([])
  const [ingredientInfo, setIngredientInfo] = useState<SingleIngredientResponse>(initialIngredientResponse)
  const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
  const translationContext = useContext(TranslationContext)
  const TranslateText = async (text: string) => {
    let returnText = text
    try {
      const translatedText = await jTranslationText({
        text,
        translationContext,
      })
      returnText = translatedText || ''
    } catch (e) {
      console.log('Translation error on ItemView:', e)
    }
    return returnText
  }

  // api call to fetch menu items
  const listDetails = useQueryHook(PUBLIC_MENU, () => getMenuItemsPublic(queryClient), () => { }, undefined, true, false);
  const publicMenuList = queryClient.getQueryData(PUBLIC_MENU) as AxiosResponse<any, any>

  useEffect(() => {
    if (ingredientSlug === undefined) {
      // go back if user directly access page
      navigate(-1)
    }
  }, []) // eslint-disable-line

  // get ingredients id from slug
  const ingredientDetails = useQueryHook(
    INGREDIENTS_ITEM_DETAILS,
    () => getIngredientsDetailsBySlug(queryClient, ingredientSlug as string),
    (res) => {
      let data = res.data.data as SingleIngredientResponse
      setIngredientInfo(data)
      setGalleryImages(processImageURLsForGallery(data.signedImageUrls))
    },
    undefined,
    true,
    true
  )

  // fetch item details
  const itemDetails = useQueryHook(
    MENU_ITEM_DETAIL,
    () => getMenuItemsDetailsBySlug(queryClient, itemSlug as string),
    (res) => {
      let data = res.data.data.details as MenuItem
      setItem(data)
    },
    undefined,
    true,
    false
  )

  useEffect(() => {
    let isMounted = true

    const fetchData = async () => {
      if (
        publicMenuList &&
        !isJSONObjectEmpty(publicMenuList.data.data.list) &&
        !isJSONObjectEmpty(item)
      ) {
        const path = getBreadCrumbPath({
          data: publicMenuList.data.data.list,
          id: item?.id ?? '',
        })

        try {
          const menuName = await TranslateText(path?.menuName || "")
          const categoryName = await TranslateText(path?.categoryName || "")
          const itemName = await TranslateText(path?.itemName || "")
          const ingredientName = await TranslateText(ingredientInfo?.ingredientName || "")

          if (isMounted) {
            if (path) {
              setBreadCrumbItems([
                { label: toPascalCase(menuName || ""), url: `${routes.menu_view}/${menuSlug}` },
                { label: toPascalCase(categoryName || ""), url: `${routes.menu_view}/${menuSlug}/${categorySlug}` },
                { label: toPascalCase(itemName || ""), url: `${routes.menu_view}/${menuSlug}/${categorySlug}/${itemSlug}` },
                { label: toPascalCase(ingredientName || ""), url: `#` },
              ])
            }
          }
        } catch (error) {
          // Handle any errors here
        }
      }
    }

    fetchData()

    return () => {
      // Cleanup function to set isMounted to false when the component unmounts
      isMounted = false
    }
  }, [publicMenuList, item, translationContext, ingredientInfo])

  useEffect(() => {
    ingredientDetails.refetch(); // Trigger the query again when itemSlug changes
  }, [ingredientSlug]);

  return (
    <>
      {(
        ingredientDetails.isLoading ||  
        ingredientDetails.isRefetching ||  
        ingredientDetails.isFetching
      ) ? (
        <Loader />
      ) : (
        <div className='container'>
          {/* TOAST MESSAGE COMPONENT */}
          <ToastAlert
            show={toastMessage.show}
            onClose={() => setToastMessage(toastMessageInitialData)}
            message={toastMessage.message}
            variant={toastMessage.variant}
          />
          <div className='row mb-3 mt-3'>
            <div className='col-lg-12 p-0'>
              <div className='card'>
                <div className='card-header p-0'>
                  <div className=' '>
                    <div className='col-md-12 d-flex align-items-center p-2'>
                      <div
                        className='button btn  back-btn back-btn-new'
                        onClick={() => navigate(-1)}
                      >
                        <i className='ri-arrow-left-line'></i>
                      </div>

                      {(
                        ingredientDetails.isFetching ||  
                        itemDetails.isLoading ||  
                        itemDetails.isRefetching ||  
                        itemDetails.isFetching ||  
                        listDetails.isLoading || 
                        listDetails.isRefetching || 
                        listDetails.isFetching
                      ) ? (
                        <div className='row ms-2'>
                          <div className='col-3'>
                            <Skeleton width='6rem' height='2rem'></Skeleton>
                          </div>
                          <div className='col-3'>
                            <Skeleton width='6rem' height='2rem'></Skeleton>
                          </div>
                          <div className='col-3'>
                            <Skeleton width='6rem' height='2rem'></Skeleton>
                          </div>
                          <div className='col-3'>
                            <Skeleton width='8rem' height='2rem'></Skeleton>
                          </div>
                        </div>
                      ) : (
                        <BreadCrumb
                          className='custom-breadcrumb'
                          model={breadCrumbItems}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-md-12 d-flex align-items-center p-3 justify-content-between mb-3 border-bottom'>
                      <div className='wine-history-title col-12 d-flex align-items-start  '>
                        <h3 className='m-0'>
                          <JTranslation
                            text={toPascalCase(ingredientInfo.ingredientName)} 
                          />
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='row pb-3 d-flex mb-3'>
                        <div className='col-sm-6 col-md-5 col-lg-4 d-flex align-items-start mb-3 justify-content-center ingredients-gallery'>
                          {/* gallery view component  */}
                            <GalleryViewWithFooter
                              galleryImages={galleryImages}
                            />                          
                        </div>
                        <div className='col-sm-6 col-md-7 col-lg-8'>
                          <p>
                            <JTranslation
                              text={unescapeHtml(ingredientInfo?.ingredientDescription)}
                              type='html'
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row  pb-3'>
            <div className='col-md-12 p-0'>
              <div className='adbox-container '>
                <div className='d-flex align-items-ceneter'>
                  {/* <div className='flex-grow-1'>
                    <img src={adLeftImg} alt='' />
                  </div> */}

                  <div className='flex-grow-1 p-5 txt-section'>
                    <h3>
                      <JTranslation text='Win a Free Dinner' />
                    </h3>
                    <h4 className='mb-3'>
                      <JTranslation text='By Reviewing Us' />
                    </h4>
                    <button
                      className='btn  btn-lg  btn-custom-primary'
                      data-testid="take-survey"
                      onClick={() =>
                        navigate(`${routes.survey_guest}${location.search}`)
                      }
                    >
                      {' '}
                      <JTranslation text='Take Survey' />
                      <i className='ri-arrow-right-line'></i>
                    </button>
                  </div>
                  <div className='overlay'></div>
                </div>
                <div className='overlay'></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default withPublicHeader(ItemView)
