import { useState, useContext, useEffect } from 'react'
import './styles/custom-menu.scss'
import { AlertVariant } from '../../constants/constants'
import { Button } from 'primereact/button'
import { CommonCtx } from '../../context/CommonCtxProvider'
import { CUSTOM_MENU_CONFIGURATION, GLOBAL_CONFIGURATION } from '../../constants/strings'
import { FnBCtx } from '../../context/MoveItemCtxProvider'
import { getSingleCustomMenu } from '../../helpers/customMenuHelper'
import { CustomMenuCategoriesAndItems, MenuItem, CustomMenu, GetSingleCustomMenuParamType } from '../../constants/staticTypes'
import { useQueryClient } from 'react-query'
import AddCategory from '../../components/f&b_menu/admin/AddCategory'
import AddItem from '../../components/f&b_menu/admin/AddItem'
import CustomMenuItemsList from '../../components/manage_custom_menu/CustomMenuItemsList'
import CustomMenuFnBConfig from '../../components/f&b_menu/admin/CustomMenuFnBConfig'
import FnBMenuSchedule from '../../components/f&b_menu/admin/FnBMenuSchedule'
import ToastAlert from '../../components/alert/ToastAlert'
import useMutationHook from '../../hooks/useMutationHook'
import withSidebar from '../../hoc/withSidebar'
import { JTranslation } from '../../helpers/jTranslate'
import MlxTooltip from '../../components/common/MlxTooltip'

function CustomMenuManagement() {
    const queryClient = useQueryClient()
    const upsertMenuMutation = useMutationHook(queryClient, true)
    // STATE VARIABLES
    const [deleteItem, setDeleteItem] = useState<string | undefined>('')
    const [editItem, setEditItem] = useState<MenuItem | CustomMenuCategoriesAndItems | undefined>()
    const [loading, setLoading] = useState(false)
    const [selectedItem, setSelectedItem] = useState<MenuItem | undefined>()
    const [selectedMenu, setSelectedMenu] = useState<CustomMenu | undefined>()
    const [showAddCategory, setAddCategory] = useState(false)
    const [showAddItem, setAddItem] = useState(false)
    const [showAddMenu, setAddMenu] = useState(false)
    const [isFoodForm, setFormType] = useState(true);
    // TOAST VARIABLES 
    const [showToast, setToast] = useState(false) // Toast alert state
    const [toastMessage, setToastMessage] = useState('') // Toast message
    const [toastVariant, setToastVariant] = useState('') // Toast Variant
    // CONTEXT VARIABLE
    const { scheduleMenu, setScheduleMenu, setFnBGlobalSettings } = useContext(CommonCtx)
    const { fnBMenuConfigSideBar, setFnBMenuConfigSideBar, customMenuItem, setCustomMenuItem, selectedCategory, setSelectedCategory } = useContext(FnBCtx)

    useEffect(() => {
        if (Object.keys(customMenuItem).length) {
            setSelectedMenu(customMenuItem)
            const { menuCategoryAndItems } = customMenuItem
            if (Object.keys(menuCategoryAndItems).length) {
                //    @ts-ignore
                let category = menuCategoryAndItems.filter((item) => item.id === selectedCategory?.id)
                //    @ts-ignore
                setSelectedCategory(category.length ? category[0] : menuCategoryAndItems[0])
                setSelectedItem(menuCategoryAndItems[0]?.menuItems[0])
            }
        }
    }, [customMenuItem]) // eslint-disable-line

    // show toast
    const displayToast = (message: string, variant: string) => {
        setToastVariant(variant)
        setToastMessage(message)
        setToast(true)
    }

    // on get single custom menu success
    const onGetSingleCustomMenuSuccess = (data: CustomMenu) => {
        setCustomMenuItem(data)
        setFnBGlobalSettings(data.globalSettings)
        setLoading(false)
    }

    const onError = (message: string, variant: string) => {
        setToastVariant(variant)
        setToastMessage(message)
        setToast(true)
        setLoading(false)
    }

    // api call to get single custom menu
    const getSingleCustomMenuApiCall = () => {
        if (selectedMenu?.id) {
            setLoading(true)
            const menuId: GetSingleCustomMenuParamType = { menuId: selectedMenu?.id! }
            getSingleCustomMenu(upsertMenuMutation, menuId, onGetSingleCustomMenuSuccess, onError)
        }
    }

    // api success response
    const onSuccess = (message: string) => {
        getSingleCustomMenuApiCall()
        displayToast(message, AlertVariant.SUCCESS)
        setAddMenu(false)
        setAddCategory(false)
        setAddItem(false)
        setDeleteItem('')
        setEditItem(undefined)
    }

    return (
        <>
            <ToastAlert
                data-testid="toast"
                message={toastMessage}
                onClose={setToast}
                show={showToast}
                variant={toastVariant}
            />
            {/* F&B menu configuration side drawer */}
            <CustomMenuFnBConfig
                show={fnBMenuConfigSideBar}
                handleClose={() => setFnBMenuConfigSideBar(false)}
                displayToast={displayToast}
            />
            {/* Menu schedule configuration side drawer */}
            <FnBMenuSchedule
                displayToast={displayToast}
                handleClose={() => setScheduleMenu(false)}
                show={scheduleMenu}
            />
            <AddCategory
                parentComponent="CUSTOM_F&B_MENU"
                customMenuMode={editItem ? 'UPDATE' : 'CREATE'}
                editItem={editItem}
                handleClose={() => setAddCategory(!showAddCategory)}
                onSuccess={onSuccess}
                parentId={selectedMenu?.id}
                show={showAddCategory}
                upsertMenu={upsertMenuMutation}
            />

            <div className="col-md-12 col-lg-12 mb-3 h-100">
                <div className="page-title d-flex mb-3 align-items-center">
                    <div className="flex-grow-1">
                        <h5 className="mb-0"><JTranslation typeCase="pascal" text={CUSTOM_MENU_CONFIGURATION} /></h5>
                    </div>
                    <div className="flex-grow-0 align-items-center">
                        {/* F&B configuration*/}
                        <MlxTooltip target='.p-button-rounded'>
                            <Button
                                icon="ri-settings-4-line "
                                className=" p-button-rounded p-button-primary p-button-text fs-4 text-nowrap color-primary-custom"
                                data-testid="global-config"
                                data-pr-tooltip={GLOBAL_CONFIGURATION}
                                data-pr-position='left'
                                onClick={() => {
                                    setFnBMenuConfigSideBar(!fnBMenuConfigSideBar)
                                }}
                            />
                        </MlxTooltip>
                    </div>
                </div>

                {showAddItem ? (
                    <AddItem
                        isFoodForm={isFoodForm}
                        setFormType={setFormType}
                        displayToast={displayToast}
                        editItem={editItem}
                        // @ts-ignore
                        itemCategoryId={selectedCategory?.id}
                        onSuccess={onSuccess}
                        setAddItem={setAddItem}
                        parentComponent='CUSTOM_F&B_MENU'
                        customMenuMode={editItem ? 'UPDATE' : 'CREATE'}
                    />
                ) : (
                    <CustomMenuItemsList
                        setFormType={setFormType}
                        deleteItem={deleteItem}
                        displayToast={displayToast}
                        loading={loading}
                        onSuccess={onSuccess}
                        selectedCategory={selectedCategory}
                        selectedItem={selectedItem}
                        selectedMenu={selectedMenu}
                        setAddCatgory={setAddCategory}
                        setAddItem={setAddItem}
                        setAddMenu={setAddMenu}
                        setDeleteItem={setDeleteItem}
                        setEditItem={setEditItem}
                        setSelectedCategory={setSelectedCategory}
                        setSelectedItem={setSelectedItem}
                        setSelectedMenu={setSelectedMenu}
                        showAddCategory={showAddCategory}
                        showAddItem={showAddItem}
                        showAddMenu={showAddMenu}
                        upsertMenu={upsertMenuMutation}
                    />
                )}
            </div>
        </>
    )
}
export default withSidebar(CustomMenuManagement)
