import { useEffect, useState } from 'react'

const useVisibility = () => {
	const [isTabVisible, setIsTabVisible] = useState(!document.hidden)

	const handleVisibilityChange = () => {
		setIsTabVisible(!document.hidden)
	}

	useEffect(() => {
		document.addEventListener('visibilitychange', handleVisibilityChange)

		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange)
		}
	}, [])

	return isTabVisible
}

export default useVisibility
