import { useState, useContext } from 'react'
import { AxiosResponse } from 'axios'
import { CommonCtx } from '../../context/CommonCtxProvider'
import { DELETE_WARNING, MANAGE_INGREDIENTS, INGREDIENTS_HELP } from '../../constants/strings'
import { getIngredientsList, deleteIngredient, initialIngredientDeleteData } from '../../helpers/manageIngredientsHelper'
import { Ingredient, DeleteIngredientType, ToastMessageProps } from '../../constants/staticTypes'
import { INGREDIENTS_LIST } from '../../constants/queryKeys'
import { OverlayTrigger } from 'react-bootstrap'
import { popover } from '../../helpers/utils'
import { toastMessageInitialData } from '../../constants/constants'
import { useQueryClient } from 'react-query'
import IngredientsListDataGrid from '../../components/manage_ingredients/IngredientsListDataGrid'
import IngredientsSideBar from '../../components/manage_ingredients/IngredientsSideBar'
import Loader from '../../components/loader/Loader'
import ToastAlert from '../../components/alert/ToastAlert'
import useMutationHook from '../../hooks/useMutationHook'
import useQueryHook from '../../hooks/useQueryHook'
import WarningModal from '../../components/warning_modal/WarningModal'
import withSidebar from '../../hoc/withSidebar'
import { JTranslation } from '../../helpers/jTranslate'
import MlxPopover from '../../components/common/MlxPopover'

function ManageIngredients() {
    const queryClient = useQueryClient()
    const ingredientMutation = useMutationHook(queryClient, true)
    // CONTEXT VARIABLE
    const { setShowSideBar: setAddOnSideBar } = useContext(CommonCtx)
    // ALERT VARIABLES
    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
    // WARNING MODAL
    const [showWarning, setWarning] = useState(false) // Warning popup
    const [warningMessage, setWarningMessage] = useState('') // Warning message
    // STATE VARIABLES
    const [ingredientsList, setIngredientsList] = useState<Ingredient[]>([])
    const [editIngredient, setIngredient] = useState<Ingredient>()
    const [deleteIngredientData, setDeleteIngredientData] = useState<DeleteIngredientType>(initialIngredientDeleteData)
    const [isInitialLoad, setInitialLoad] = useState(true)

    // side bar close
    const handleClose = () => setAddOnSideBar(false)

    // ingredients list fetch success
    const onSuccessOfIngredientsList = (res: AxiosResponse<any, any>) => {
        setIngredientsList(res.data?.data.list)
        setInitialLoad(false)
    }

    // fetch ingredients list
    const ingredientsData = useQueryHook(INGREDIENTS_LIST, () => getIngredientsList('', 500, ''), onSuccessOfIngredientsList)

    if (ingredientsData.isLoading || isInitialLoad) {
        return <Loader />
    }

    // on trigger search
    const onSearchTrigger = (searchKey: string) => {
        if (ingredientsData.isFetching) {
            queryClient.cancelQueries([INGREDIENTS_LIST]) // nosonar
        }
        queryClient.fetchQuery(INGREDIENTS_LIST, () => getIngredientsList(searchKey, 500, '')) // nosonar
    }

    // on add or update ingredients success
    const onSuccess = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
        queryClient.refetchQueries([INGREDIENTS_LIST]) // nosonar
    }

    // on add or update ingredients error
    const onError = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
    }

    // on edit button click
    const onEditClick = (ingredient: Ingredient) => {
        setIngredient(ingredient)
    }

    // on delete button click
    const onDeleteClick = (ingredient: Ingredient) => {
        setDeleteIngredientData({ ingredientId: ingredient.id })
        setWarningMessage(DELETE_WARNING)
        setWarning(true)
    }

    // api call to delete add-on
    const deleteAddOnApiCall = () => {
        deleteIngredient(ingredientMutation, deleteIngredientData, onSuccess, onError)
    }

    return (
        <>
            <WarningModal
                show={showWarning}
                title={warningMessage}
                onHide={() => {
                    setWarning(false)
                    setDeleteIngredientData(initialIngredientDeleteData)
                }}
                callback={() => {
                    deleteAddOnApiCall()
                    setWarning(false)
                }}
            />

            <ToastAlert
                data-testid="toast"
                message={toastMessage.message}
                onClose={() => setToastMessage(toastMessageInitialData)}
                show={toastMessage.show}
                variant={toastMessage.variant}
            />

            <IngredientsSideBar
                editIngredient={editIngredient}
                handleClose={handleClose}
                setIngredient={setIngredient}
            />

            <div className="col-md-12 col-lg-12 mb-3 h-100 ">
                <div className="page-title">
                    <h5 className="d-flex"><JTranslation typeCase="pascal" text={MANAGE_INGREDIENTS} />
                        <MlxPopover data={{title:MANAGE_INGREDIENTS, body:INGREDIENTS_HELP}}/>
                    </h5>
                </div>

                <IngredientsListDataGrid
                    ingredientsList={ingredientsList}
                    editIngredient={editIngredient}
                    loading={ingredientsData.isLoading}
                    onDeleteClick={onDeleteClick}
                    onEditClick={onEditClick}
                    onSearchTrigger={onSearchTrigger}
                />
            </div>
        </>
    )
}
export default withSidebar(ManageIngredients)
