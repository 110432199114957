/**
 * Triggers a haptic feedback-like vibration.
 * @param {number} type - The type of haptic feedback (e.g., 1 for a short vibration, 2 for a longer one).
 */
export const JHapticFeedBack = (type:number=1)=> {
  const patterns:any = {
    1: [50], // Short vibration
    2: [100] // Longer vibration
  };

  const pattern = patterns[type] || patterns[1]; // Default to type 1 if an unrecognized type is provided

  if ('vibrate' in navigator) {
     window.navigator.vibrate(pattern);
  } else {
    console.log('Vibration API is not supported on this device.');
  }
}
