import { createContext, Dispatch, PropsWithChildren, SetStateAction, useState, useMemo } from 'react'
import { FileTypeOption, ExtractedFileData, ActionButtonToBeEnabled, InvoiceDataList, InvoiceFilter } from '../constants/staticTypes'
import { INVOICE_FILTER_OPTIONS } from '../constants/constants'

// SETTER TYPE DECLARATIONS
type SetBoolean = Dispatch<SetStateAction<boolean>>
type SetButtonToBeEnabled = Dispatch<SetStateAction<ActionButtonToBeEnabled[]>>
type SetFileTypeOptions = Dispatch<SetStateAction<FileTypeOption[]>>
type SetInvoiceFileList = Dispatch<SetStateAction<InvoiceDataList[]>>
type SetInvoiceFilter = Dispatch<SetStateAction<InvoiceFilter[]>>
type SetNumber = Dispatch<SetStateAction<number>>
type SetUpdateExtractedFiles = Dispatch<SetStateAction<ExtractedFileData[]>>

// UPDATE FUNCTION DECLARATIONS
const UpdateBoolean: SetBoolean = () => false
const UpdateButtonToBeEnabled: SetButtonToBeEnabled = () => []
const UpdateExtractedFiles: SetUpdateExtractedFiles = () => []
const UpdateFileTypeOptions: SetFileTypeOptions = () => []
const UpdateInvoiceFileList: SetInvoiceFileList = () => []
const UpdateInvoiceFilter: SetInvoiceFilter = () => []
const UpdateNumber: SetNumber = () => 0
const UpdateRefetchApi: SetBoolean = () => true

// CONTEXT VARIABLES
export const FileManagementCtx = createContext({
    // invoice page filter
    filterValue: INVOICE_FILTER_OPTIONS,
    setFilterValue: UpdateInvoiceFilter,
    // invoice list data
    invoiceFileList: [],
    setInvoiceFileList: UpdateInvoiceFileList,
    // list of action button to be enabled on file type verification tab
    buttonToBeEnabled: [],
    setButtonToBeEnabled: UpdateButtonToBeEnabled,
    // document approval counter to refetch the list api
    refetchAPI: true,
    setRefetchAPI: UpdateRefetchApi,
    // list of extracted documents
    extractedFiles: [],
    setExtractedFiles: UpdateExtractedFiles,
    // file type dropdown options for verify uploaded documents page
    fileTypeOptions: [],
    setFileTypeOptions: UpdateFileTypeOptions,
    // multi file upload progress variables
    totalFileCount: 0,
    setTotalFileCount: UpdateNumber,
    uploadedFileCount: 0,
    setUploadedFileCount: UpdateNumber,
    showUploadProgress: false,
    setShowUploadProgress: UpdateBoolean,
})

export const FileManagementCtxProvider = ({ children }: PropsWithChildren) => {
    // NOTE: "CONTEXT VARIABLE" AND "STATE VARIABLE" NAMES SHOULD BE IDENTICAL
    // STATE VARIABLES
    const [buttonToBeEnabled, setButtonToBeEnabled] = useState<any>([])
    const [extractedFiles, setExtractedFiles] = useState<any>([])
    const [fileTypeOptions, setFileTypeOptions] = useState<any>([])
    const [invoiceFileList, setInvoiceFileList] = useState<any>([])
    const [refetchAPI, setRefetchAPI] = useState(true)
    const [showUploadProgress, setShowUploadProgress] = useState(false)
    const [totalFileCount, setTotalFileCount] = useState(0)
    const [uploadedFileCount, setUploadedFileCount] = useState(0)
    const [filterValue, setFilterValue] = useState<InvoiceFilter[]>(INVOICE_FILTER_OPTIONS)

    // CONTEXT PROVIDER
    return (
        <FileManagementCtx.Provider
            value={useMemo(() => {
                return {
                    refetchAPI, setRefetchAPI,
                    extractedFiles, setExtractedFiles,
                    fileTypeOptions, setFileTypeOptions,
                    showUploadProgress, setShowUploadProgress,
                    totalFileCount, setTotalFileCount,
                    uploadedFileCount, setUploadedFileCount,
                    buttonToBeEnabled, setButtonToBeEnabled,
                    invoiceFileList, setInvoiceFileList,
                    filterValue, setFilterValue
                }
            }, [
                buttonToBeEnabled,
                extractedFiles,
                fileTypeOptions,
                filterValue,
                invoiceFileList,
                refetchAPI,
                showUploadProgress,
                totalFileCount,
                uploadedFileCount,
            ])}
        >
            {children}
        </FileManagementCtx.Provider>
    )
}
