import { useContext, useEffect, useState } from "react";
import { Button, Modal, OverlayTrigger } from "react-bootstrap";
import { QueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../constants/routes";
import { getDomainFromURL, popover } from '../../../helpers/utils';
import {
    CANCEL, CREATE, CREATE_QUESTION_SET, CREATE_QUESTION_SET_PLACEHOLDER,
    GAME_ENABLED_TEXT, HELP_QUESTION_SET, QUESTION_SET_TITLE, GAME_ENABLED_HELP_TEXT, HELP_GAME_AFTER_SURVEY, HELP_EVENT_URL_SLUG, HELP_EVENT_URL_TEXT, URL_SLUG, URL_TEXT
} from "../../../constants/strings";
import { createSurveySet } from "../../../helpers/surveyManagementHelper";
import useMutationHook from "../../../hooks/useMutationHook";
import { convertToSlug } from "../../../helpers/newsAndEventsHelper";
import { JTranslation, TranslationContext, jTranslationText } from "../../../helpers/jTranslate";
import { useTranslation } from "../../../hooks/useTranslation";
import MlxPopover from "../../common/MlxPopover";

type Props = {
    queryClient: QueryClient,
    show: boolean,
    onHide: () => void,
    displayToast: (message: string, variant: string) => void
}

function CreateQuestionSetModal({ show, onHide, queryClient, displayToast }: Props) {
    const createSurveyMutation = useMutationHook(queryClient, true);
    const navigate = useNavigate();
    const domainName = getDomainFromURL();
    const [isGameAvailable, setGameAvailable] = useState(true);
    const [questionTitle, setQuestionTitle] = useState("");
    const [urlSlug, setUrlSlug] = useState("");

    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext

    const [translationText, setTranslatedText] = useState<{
        urlText: string,
    }>({ 
        urlText: "Enter your URL text",
    });

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: "Enter your URL text", typeCase: 'pascal', translationContext }),
                ])
                
                setTranslatedText({
                    urlText: translations[0] ?? "Enter your URL text",
                })
            } catch {
                setTranslatedText({
                    urlText: "Enter your URL text",
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    const onSuccess = (message: string, id: string) => {
        navigate(routes.survey_create, {
            state: {
                id,
                message,
                questionTitle,
                isGameAvailable,
                urlSlug
            }
        });
    }

    const handleURLChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target
        setUrlSlug(convertToSlug(value))
    }

    return <Modal show={show} onHide={() => {
        onHide();
        setQuestionTitle("");
        setUrlSlug("");
    }}
        data-testid="create-question-modal">
        <Modal.Header closeButton>
            <h5 className="modal-title d-flex align-items-center" id=""><JTranslation typeCase="pascal" text={CREATE_QUESTION_SET} />
                <div className="help-small">
                    <MlxPopover data={{title:CREATE_QUESTION_SET, body:HELP_QUESTION_SET}}/>
                </div>
            </h5>
        </Modal.Header>
        <Modal.Body>
            <div className="mb-3">
                <label className="form-label"><JTranslation typeCase="pascal" text={QUESTION_SET_TITLE} /></label>
                <input type="text" className="form-control" id="question-set-title" autoComplete="off"
                    data-testid="question-set-title"
                    autoFocus
                    maxLength={200}
                    value={questionTitle}
                    onChange={(e) => setQuestionTitle(e.target.value)}
                    placeholder={useTranslation(CREATE_QUESTION_SET_PLACEHOLDER, 'capitalize')} />
            </div>
            <div className="mb-2">
                <div className="help-small">
                    <label className="form-label d-flex">
                        <JTranslation typeCase="pascal" text={URL_TEXT} />
                        <span className="mandatory ">*</span>
                    </label>
                    <MlxPopover data={{title:URL_TEXT, body:HELP_EVENT_URL_TEXT}}/>
                </div>
                <input
                    name="urlSlug"
                    className="form-control"
                    type="text"
                    autoComplete="off"
                    maxLength={150}
                    value={urlSlug}
                    data-testid="survey-url-text"
                    onChange={handleURLChange}
                />
            </div>
            <div className="mb-2">
                <div className="help-small">
                    <label className="form-label"><JTranslation typeCase="pascal" text={URL_SLUG} /></label>:{' '}
                    <MlxPopover data={{title:URL_SLUG, body:HELP_EVENT_URL_SLUG}}/>
                </div>
                <div className="input-group mb-3">
                    <input type="text" className="form-control" disabled 
                    value={urlSlug 
                        ? `${domainName}${routes.survey_guest}/${urlSlug}` 
                        : translationText.urlText
                    }/>
                </div>
            </div>
            <div className="form-check mb-3">
                <input className="form-check-input" type="checkbox" id="flexCheckDefault" autoComplete="off"
                    data-testid="game-available-checkbox"
                    checked={isGameAvailable}
                    onChange={() => setGameAvailable(!isGameAvailable)} />
                <label className="form-check-label d-flex align-items-center" >
                    <JTranslation typeCase="pascal" text={GAME_ENABLED_TEXT} />
                    <div className="help-small">
                        <MlxPopover data={{title:GAME_ENABLED_HELP_TEXT, body:HELP_GAME_AFTER_SURVEY}}/>
                    </div>
                </label>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button className="btn btn-custom-primary-outline" onClick={() => {
                onHide();
                setQuestionTitle("");
            }} data-testid="btn-cancel">
                <JTranslation typeCase="pascal" text={CANCEL} />
            </Button>
            <Button className="btn btn-custom-primary"
                data-testid="btn-create"
                onClick={() => {
                    if (questionTitle?.trim().length > 0) {
                        onHide();
                        setUrlSlug("");
                        createSurveySet(queryClient, createSurveyMutation, questionTitle, urlSlug, isGameAvailable, onSuccess, displayToast);
                    }
                }}
                disabled={questionTitle?.trim().length === 0 || urlSlug?.trim().length === 0}>
                <JTranslation typeCase="pascal" text={CREATE} />
            </Button>
        </Modal.Footer>
    </Modal>
}

export default CreateQuestionSetModal;