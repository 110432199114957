import { SetStateAction, useState, useContext, useEffect } from "react";
import { ADD_NEW, CATEGORY, HELP_CATEGORY, HELP_ITEMS, ITEMS, ADD_EXISTING, ACTIVATE_CATEGORY, ACTIVATE_ITEM, BACK, CHOOSE_TYPE } from "../../constants/strings";
import { arrayMove, rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import { checkPermission, isMobile, customDeepClone, filterCustomMenuList } from "../../helpers/utils";
import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { CustomMenuItemsListProps, DeleteCustomCategoryType, CustomMenuCategoriesAndItems, MenuItem, ChangeCustomItemStatus, ChangeCustomCategoryStatus, CustomMenu } from "../../constants/staticTypes";
import { DebounceInput } from "react-debounce-input";
import { deleteCustomCategory, customMenuDeleteItem, activeInactiveCustomItem, activeInactiveCustomCategory, customMenuSortItem, customMenuSortCategory } from "../../helpers/customMenuHelper";
import { FaSearch } from "react-icons/fa";
import { FnBCtx } from "../../context/MoveItemCtxProvider";
import { getValueAfterHash } from "../../helpers/menuViewHelper";
import { JTranslation, TranslationContext, jTranslationText } from "../../helpers/jTranslate";
import { NextButton, PrevButton, usePrevNextButtons } from "../f&b_menu/guest/EmblaCarouselArrowButton";
import { Panel } from "primereact/panel";
import { PermissionKeys, SubPermissionKeys, WarningCallback, DragItemType, MOBILE_VIEW_BREAKPOINT } from "../../constants/constants";
import { routes } from "../../constants/routes";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useWindowSize } from "react-use";
import { v4 as uuidv4 } from "uuid";
import CreateCustomMenuComponent from "../../pages/custom_f&b_menu/CreateCustomMenu";
import CustomCategoryComponent from "../f&b_menu/admin/draggable/CustomCategoryComponent";
import CustomItemComponent from "../f&b_menu/admin/draggable/CustomItemComponent";
import FnBLoader from "../f&b_menu/admin/FnBLoader";
import MlxPopover from "../common/MlxPopover";
import ToastAlert from "../alert/ToastAlert";
import useEmblaCarousel from "embla-carousel-react";
import useMutationHook from "../../hooks/useMutationHook";
import WarningModal from "../warning_modal/WarningModal";
import CommonModal from "../common_modal/CommonModal";
import ChooseFoodType from "../f&b_menu/admin/ChooseFoodType";

function CustomMenuItemsList({
  setFormType,
  setEditItem,
  selectedMenu,
  setSelectedMenu,
  setSelectedCategory,
  setDeleteItem,
  setAddCatgory,
  showAddCategory,
  selectedCategory,
  setAddItem,
  showAddItem,
  selectedItem,
  setSelectedItem,
  deleteItem,
  onSuccess,
  displayToast,
  upsertMenu,
  loading,
}: CustomMenuItemsListProps) {
  // react query variables
  const queryClient = useQueryClient();
  const deleteItemMutation = useMutationHook(queryClient, true);
  const sortItemMutation = useMutationHook(queryClient, true);
  const activeInactiveMutation = useMutationHook(queryClient, true); // activate inactivate items or category
  const navigate = useNavigate();
  const { customMenuId } = useParams();
  const { width, height } = useWindowSize();
  const breakPoint = MOBILE_VIEW_BREAKPOINT;
  // context variables
  const { dndLock } = useContext(FnBCtx);
  const translationContext = useContext(TranslationContext);
  const { targetLanguage } = translationContext;
  // state variables
  const [showCategorySelection, setShowCategorySelection] = useState(false);
  const [addType, setAddType] = useState<"food" | "beverage" | null>(null);
  const [showWarning, setWarning] = useState(false); // Warning popup
  const [callbackType, setCallbackType] = useState<WarningCallback>();
  const [warningMessage, setWarningMessage] = useState("");
  const [alertVariant, setVariant] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showAlert, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [showTitle, setShowTitle] = useState(true);
  const [translationText, setTranslatedText] = useState<{ search: string }>({ search: "Search" });
  const [filteredCustomMenu, setFilteredCustomMenu] = useState<CustomMenu>(customDeepClone(selectedMenu) as CustomMenu);
  const [emblaRef, emblaApi] = useEmblaCarousel({ dragFree: true });
  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  // Translate on load and language switch
  useEffect(() => {
    const fetchTranslation = async () => {
      try {
        const translations = await Promise.all([
          jTranslationText({
            text: "Search",
            typeCase: "pascal",
            translationContext,
          }),
        ]);

        setTranslatedText({
          search: translations[0] ?? "Search",
        });
      } catch {
        setTranslatedText({
          search: "Search",
        });
      }
    };
    fetchTranslation();
  }, [targetLanguage]);

  // on active inactive toggle click
  const onActiveInactiveToggle = (
    id: string,
    message: string,
    callbackType: SetStateAction<WarningCallback | undefined>
  ) => {
    setDeleteItem(id);
    setCallbackType(callbackType);
    setWarningMessage(message);
    setWarning(true);
  };

  // drag n drop sensors
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 2,
      },
    })
  );

  // drag end handler
  const handleDragEnd = (event: DragEndEvent, type: string) => {
    const { active, over } = event;
    const oldIndex = active?.data.current && active.data.current.sortable.index;
    const newIndex = over?.data.current && over?.data.current.sortable.index;
    let sortedIds: string[] = [];

    switch (type) {
      case DragItemType.CATEGORY_DRAG:
        if (filteredCustomMenu) {
          setSelectedCategory(
            filteredCustomMenu.menuCategoryAndItems.filter(
              (sub) => sub.id === active.id
            )[0]
          );
          let sortedCategoriesList = arrayMove(
            filteredCustomMenu.menuCategoryAndItems,
            oldIndex,
            newIndex
          );
          sortedIds = sortedCategoriesList.map((listItem) => listItem.id);
          setSelectedMenu({
            ...filteredCustomMenu,
            menuCategoryAndItems: sortedCategoriesList as [
              CustomMenuCategoriesAndItems
            ],
          });
        }
        break;

      case DragItemType.ITEM_DRAG:
        if (selectedCategory) {
          setSelectedItem(
            selectedCategory.menuItems.filter(
              (item) => item.id === active.id
            )[0]
          );
          let sortedItemsList = arrayMove(
            selectedCategory?.menuItems,
            oldIndex,
            newIndex
          );
          sortedIds = sortedItemsList.map((listItem) => listItem.id as string);
          setSelectedCategory({
            ...selectedCategory,
            menuItems: sortedItemsList as [MenuItem],
          });
        }
        break;

      default:
        break;
    }

    // invoke api
    if (type === DragItemType.ITEM_DRAG) {
      customMenuSortItem(
        { menuItemIdsInOrder: sortedIds },
        sortItemMutation,
        onSuccess,
        displayToast
      );
    } else {
      customMenuSortCategory(
        { categoryIdsInOrder: sortedIds, isParentList: true },
        sortItemMutation,
        onSuccess,
        displayToast
      );
    }
  };

  useEffect(() => {
    const clonedMenu = customDeepClone(selectedMenu) as CustomMenu;
    if (!clonedMenu) return;

    if (search.trim().length > 0) {
      const updatedMenuList = filterCustomMenuList(
        clonedMenu?.menuCategoryAndItems,
        search
      );
      clonedMenu.menuCategoryAndItems = updatedMenuList;

      setFilteredCustomMenu(clonedMenu);
      setSelectedCategory(updatedMenuList[0]);
    } else {
      setFilteredCustomMenu(clonedMenu as CustomMenu);
      setSelectedCategory(clonedMenu?.menuCategoryAndItems[0]);
    }
  }, [search, selectedMenu]);

  return (
    <>
      <CommonModal
        modalSize="sm"
        show={showCategorySelection}
        modalContent={<ChooseFoodType addType={addType} setAddType={setAddType} setFormType={setFormType} />}
        onHide={setShowCategorySelection}
        title={CHOOSE_TYPE}
        callback={() => {
          setAddType(null)
          setAddItem(!showAddItem)
          setAddType(addType)
        }}
        disableProceed={!Boolean(addType)}
      />

      <WarningModal
        show={showWarning}
        title={warningMessage}
        onHide={setWarning}
        callback={() => {
          setWarning(false);
          switch (callbackType) {
            case WarningCallback.DEL_ITEM:
              customMenuDeleteItem(
                deleteItem,
                deleteItemMutation,
                onSuccess,
                displayToast
              );
              break;

            case WarningCallback.DEL_CATEGORY:
              const categoryId: DeleteCustomCategoryType = {
                categoryId: deleteItem!,
              };
              deleteCustomCategory(
                deleteItemMutation,
                categoryId,
                onSuccess,
                displayToast
              );
              break;

            case WarningCallback.ACTIVATE_DEACTIVATE_ITEM:
              const itemStatus =
                warningMessage === ACTIVATE_ITEM ? true : false;
              const itemParams: ChangeCustomItemStatus = {
                itemMenuId: deleteItem,
                status: itemStatus,
              };
              activeInactiveCustomItem(
                activeInactiveMutation,
                itemParams,
                onSuccess,
                displayToast
              );
              break;

            default:
              const categoryStatus =
                warningMessage === ACTIVATE_CATEGORY ? true : false;
              const categoryParams: ChangeCustomCategoryStatus = {
                categoryId: deleteItem,
                status: categoryStatus,
              };
              activeInactiveCustomCategory(
                activeInactiveMutation,
                categoryParams,
                onSuccess,
                displayToast
              );
              break;
          }
        }}
      />
      <ToastAlert
        data-testid="toast"
        show={showAlert}
        onClose={setShow}
        message={errorMessage}
        variant={alertVariant}
      />
      <div className="card f-b-height overflow-auto">
        <div className="card-header flex-grow-0  mb-3">
          <div className="d-flex  align-items-center py-1">
            {/*  <div className="flex-grow-0 me-2 back-icon" onClick={() => {
                        navigate(routes.custom_menu_list)
                    }}>
                        <i className="ri-arrow-left-line"></i>
                    </div> */}
            <div
              className="button btn flex-grow-0 me-2 back-btn back-btn-new"
              data-testid="back-btn"
              onClick={() => {
                navigate(routes.custom_menu_list);
              }}
            >
              <i className="ri-arrow-left-line"></i>
            </div>
            <div className="flex-grow-1">
              <h4 className="mb-0 title">
                <JTranslation typeCase="pascal" text={BACK} />
              </h4>
            </div>
          </div>
        </div>
        <div className="card-body flex-grow-1 overflow-auto">
          {/* Create custom menu component  */}
          <CreateCustomMenuComponent />

          <Panel
            className="mt-3"
            headerTemplate={
              <div className="p-panel-header p-2 w-100 d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  {showTitle && (
                    <h5 className="m-0">
                      <JTranslation
                        typeCase="pascal"
                        text={"Special Menu Items"}
                      />
                    </h5>
                  )}
                </div>
                <div className="d-flex align-items-center">
                  <div className="col-md-12">
                    <div
                      className={`form-group has-search ${!showTitle ? "" : "search-small-box"
                        }`}
                    >
                      <span className="fa fa-search form-control-feedback">
                        <FaSearch />{" "}
                      </span>
                      <DebounceInput
                        onFocusCapture={() => {
                          breakPoint >= width && setShowTitle(false);
                        }}
                        onBlurCapture={(event: any) => {
                          if (
                            breakPoint >= width &&
                            event.target.value.length === 0
                          ) {
                            setShowTitle(true);
                          }
                        }}
                        className="form-control"
                        placeholder={translationText.search}
                        minLength={0}
                        debounceTimeout={500}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            }
          >
            <div
              className={
                customMenuId ? "row mb-3 mt-3" : "row mb-3 mt-3 disabled"
              }
            >
              <div className="col-md-12 mb-3">
                <div className="menu-ttle-bg d-flex justify-content-between">
                  <h5 className="d-flex align-items-center mb-0">
                    <JTranslation typeCase="pascal" text={CATEGORY} />
                    <MlxPopover data={{ title: CATEGORY, body: HELP_CATEGORY }} />
                  </h5>
                  {isMobile() ? (
                    <button
                      type="button"
                      className=" btn btn-custom-primary-outline "
                      id="items-add-new"
                      data-testid="add-new-category"
                      onClick={() => {
                        if (
                          checkPermission(
                            queryClient,
                            PermissionKeys.MANAGE_CUSTOM_FNB_MENU,
                            SubPermissionKeys.ADD
                          )
                        ) {
                          setEditItem(undefined);
                          setAddCatgory(!showAddCategory);
                        }
                      }}
                      disabled={
                        filteredCustomMenu === undefined ||
                        !checkPermission(
                          queryClient,
                          PermissionKeys.MANAGE_CUSTOM_FNB_MENU,
                          SubPermissionKeys.ADD
                        )
                      }
                    >
                      <i className="ri-add-line me-1"></i>{" "}
                      <JTranslation typeCase="pascal" text={ADD_NEW} />
                    </button>
                  ) : null}
                </div>
              </div>
              <div className="addMenuContainer">
                {!isMobile() ? (
                  <div className="add-btn-outer">
                    <div className="d-flex">
                      <button
                        className="addMenubox-container"
                        onClick={() => {
                          if (
                            checkPermission(
                              queryClient,
                              PermissionKeys.MANAGE_CUSTOM_FNB_MENU,
                              SubPermissionKeys.ADD
                            )
                          ) {
                            setEditItem(undefined);
                            setAddCatgory(!showAddCategory);
                          }
                        }}
                        disabled={
                          filteredCustomMenu === undefined ||
                          !checkPermission(
                            queryClient,
                            PermissionKeys.MANAGE_CUSTOM_FNB_MENU,
                            SubPermissionKeys.ADD
                          )
                        }
                      >
                        <i className="ri-add-line"></i>
                        <h6>
                          <JTranslation typeCase="pascal" text={ADD_NEW} />
                        </h6>
                      </button>
                    </div>
                  </div>
                ) : null}

                {/* SKELETON LOADER */}
                {loading && <FnBLoader />}

                {dndLock
                  ? !loading && (
                    <div
                      id="menu-scroll"
                      className="view-btn-scroll-container d-inline-block overflow-x-hidden nav-scroller-content-new"
                      key={"droppable-key"}
                      style={{ overflow: "auto" }}
                    >
                      {/* image-navigation using Embla Carousel */}
                      <div className="embla">
                        <div
                          className="embla__viewport"
                          ref={emblaRef}
                        >
                          <div className="embla__container">
                            {filteredCustomMenu?.menuCategoryAndItems.map(
                              (category) => {
                                return (
                                  <CustomCategoryComponent
                                    key={uuidv4()}
                                    setAddCatgory={setAddCatgory}
                                    setCallbackType={setCallbackType}
                                    setDeleteItem={setDeleteItem}
                                    setEditItem={setEditItem}
                                    setSelectedCategory={setSelectedCategory}
                                    setSelectedItem={setSelectedItem}
                                    setWarning={setWarning}
                                    setWarningMessage={setWarningMessage}
                                    selectedCategory={selectedCategory}
                                    selectedMenu={filteredCustomMenu}
                                    showAddCategory={showAddCategory}
                                    category={category}
                                    onActiveInactiveToggle={
                                      onActiveInactiveToggle
                                    }
                                    queryClient={queryClient}
                                  />
                                );
                              }
                            )}
                          </div>
                          <div className="category-btns">
                            <PrevButton
                              onClick={onPrevButtonClick}
                              disabled={prevBtnDisabled}
                            />
                            <NextButton
                              onClick={onNextButtonClick}
                              disabled={nextBtnDisabled}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                  : !loading &&
                  filteredCustomMenu && (
                    <DndContext
                      sensors={sensors}
                      collisionDetection={closestCenter}
                      onDragEnd={(e) =>
                        handleDragEnd(e, DragItemType.CATEGORY_DRAG)
                      }
                    >
                      <SortableContext
                        items={filteredCustomMenu.menuCategoryAndItems.map(
                          (category) => category.id
                        )}
                        strategy={rectSortingStrategy}
                      >
                        <div className="view-btn-scroll-container">
                          {filteredCustomMenu.menuCategoryAndItems.map(
                            (category) => {
                              return (
                                <CustomCategoryComponent
                                  category={category}
                                  key={uuidv4()}
                                  onActiveInactiveToggle={
                                    onActiveInactiveToggle
                                  }
                                  queryClient={queryClient}
                                  selectedCategory={selectedCategory}
                                  selectedMenu={filteredCustomMenu}
                                  setAddCatgory={setAddCatgory}
                                  setCallbackType={setCallbackType}
                                  setDeleteItem={setDeleteItem}
                                  setEditItem={setEditItem}
                                  setSelectedCategory={setSelectedCategory}
                                  setSelectedItem={setSelectedItem}
                                  setWarning={setWarning}
                                  setWarningMessage={setWarningMessage}
                                  showAddCategory={showAddCategory}
                                />
                              );
                            }
                          )}
                        </div>
                      </SortableContext>
                    </DndContext>
                  )}
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-12 mb-3">
                <div className="col-md-12 mb-3">
                  <div className="menu-ttle-bg d-flex justify-content-between">
                    <div className="d-flex">
                      {" "}
                      <h5 className="d-flex align-items-center mb-0">
                        <JTranslation typeCase="pascal" text={ITEMS} />
                        <MlxPopover data={{ title: ITEMS, body: HELP_ITEMS }} />
                      </h5>
                    </div>
                    {isMobile() ? (
                      <div className="d-flex">
                        <button
                          type="button"
                          className=" btn btn-custom-primary-outline me-2"
                          id="items-add-new"
                          data-testid="add-new-item"
                          onClick={() => {
                            if (
                              checkPermission(
                                queryClient,
                                PermissionKeys.MANAGE_CUSTOM_FNB_MENU,
                                SubPermissionKeys.ADD
                              )
                            ) {
                              setEditItem(undefined);
                              setAddItem(!showAddItem);
                            }
                          }}
                          disabled={
                            filteredCustomMenu === undefined ||
                            selectedCategory === undefined ||
                            !checkPermission(
                              queryClient,
                              PermissionKeys.MANAGE_CUSTOM_FNB_MENU,
                              SubPermissionKeys.ADD
                            )
                          }
                        >
                          <i className="ri-add-line me-1"></i>{" "}
                          <JTranslation typeCase="pascal" text={ADD_NEW} />
                        </button>
                        <button
                          type="button"
                          className=" btn btn-custom-primary-outline "
                          id="items-add-new"
                          data-testid="add-existing"
                          onClick={() => {
                            if (
                              checkPermission(
                                queryClient,
                                PermissionKeys.MANAGE_CUSTOM_FNB_MENU,
                                SubPermissionKeys.ADD
                              )
                            ) {
                              navigate(
                                `${routes.custom_menu_list
                                }/${customMenuId}/${getValueAfterHash(
                                  selectedCategory?.id!
                                )}`
                              );
                            }
                          }}
                          disabled={
                            filteredCustomMenu === undefined ||
                            selectedCategory === undefined ||
                            !checkPermission(
                              queryClient,
                              PermissionKeys.MANAGE_CUSTOM_FNB_MENU,
                              SubPermissionKeys.ADD
                            )
                          }
                        >
                          <i className="ri-add-line me-1"></i>{" "}
                          <JTranslation typeCase="pascal" text={ADD_EXISTING} />
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="add-item-outer">
                {!isMobile() ? (
                  <div className="add-new-section">
                    <div className="add-btn-outer">
                      <div className="d-flex flex-column">
                        <button
                          className="addMenubox-container mb-1 new-btn-height"
                          onClick={() => {
                            if (
                              checkPermission(
                                queryClient,
                                PermissionKeys.MANAGE_CUSTOM_FNB_MENU,
                                SubPermissionKeys.ADD
                              )
                            ) {
                              setEditItem(undefined);
                              setShowCategorySelection(true);
                            }
                          }}
                          disabled={
                            filteredCustomMenu === undefined ||
                            selectedCategory === undefined ||
                            !checkPermission(
                              queryClient,
                              PermissionKeys.MANAGE_CUSTOM_FNB_MENU,
                              SubPermissionKeys.ADD
                            )
                          }
                        >
                          <i className="ri-add-line"></i>
                          <h6>
                            <JTranslation typeCase="pascal" text={ADD_NEW} />
                          </h6>
                        </button>
                        <button
                          className="addMenubox-container new-btn-height white-bg"
                          onClick={() => {
                            if (
                              checkPermission(
                                queryClient,
                                PermissionKeys.MANAGE_CUSTOM_FNB_MENU,
                                SubPermissionKeys.ADD
                              )
                            ) {
                              navigate(
                                `${routes.custom_menu_list
                                }/${customMenuId}/${getValueAfterHash(
                                  selectedCategory?.id!
                                )}`
                              );
                            }
                          }}
                          disabled={
                            filteredCustomMenu === undefined ||
                            selectedCategory === undefined ||
                            !checkPermission(
                              queryClient,
                              PermissionKeys.MANAGE_CUSTOM_FNB_MENU,
                              SubPermissionKeys.ADD
                            )
                          }
                        >
                          <i className="ri-add-line"></i>
                          <h6>
                            <JTranslation
                              typeCase="pascal"
                              text={ADD_EXISTING}
                            />
                          </h6>
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="add-new-section">
                  <div className="add-btn-outer">
                    <div className="d-flex"></div>
                  </div>
                </div>

                {/* SKELETON LOADER */}
                {loading && <FnBLoader />}

                {dndLock
                  ? !loading && (
                    <div
                      className="menu-item-section"
                      style={{ overflow: "auto" }}
                    >
                      {selectedCategory?.menuItems.map((item) => {
                        return (
                          <CustomItemComponent
                            item={item}
                            key={uuidv4()}
                            onActiveInactiveToggle={onActiveInactiveToggle}
                            onSuccess={onSuccess}
                            queryClient={queryClient}
                            selectedItem={selectedItem}
                            setAddItem={setAddItem}
                            setCallbackType={setCallbackType}
                            setDeleteItem={setDeleteItem}
                            setEditItem={setEditItem}
                            setSelectedItem={setSelectedItem}
                            setWarning={setWarning}
                            setWarningMessage={setWarningMessage}
                            showAddItem={showAddItem}
                            upsertMenu={upsertMenu}
                            setVariant={setVariant}
                            setShow={setShow}
                            setErrorMessage={setErrorMessage}
                          />
                        );
                      })}
                    </div>
                  )
                  : !loading &&
                  selectedCategory && (
                    <DndContext
                      sensors={sensors}
                      collisionDetection={closestCenter}
                      onDragEnd={(e) =>
                        handleDragEnd(e, DragItemType.ITEM_DRAG)
                      }
                    >
                      <SortableContext
                        items={selectedCategory.menuItems.map(
                          (item) => item.id as string
                        )}
                        strategy={rectSortingStrategy}
                      >
                        <div
                          className="menu-item-section"
                          style={{ overflow: "auto" }}
                        >
                          {selectedCategory.menuItems.map((item) => {
                            return (
                              <CustomItemComponent
                                item={item}
                                key={uuidv4()}
                                onActiveInactiveToggle={
                                  onActiveInactiveToggle
                                }
                                onSuccess={onSuccess}
                                queryClient={queryClient}
                                selectedItem={selectedItem}
                                setAddItem={setAddItem}
                                setCallbackType={setCallbackType}
                                setDeleteItem={setDeleteItem}
                                setEditItem={setEditItem}
                                setSelectedItem={setSelectedItem}
                                setWarning={setWarning}
                                setWarningMessage={setWarningMessage}
                                showAddItem={showAddItem}
                                upsertMenu={upsertMenu}
                                setVariant={setVariant}
                                setShow={setShow}
                                setErrorMessage={setErrorMessage}
                              />
                            );
                          })}
                        </div>
                      </SortableContext>
                    </DndContext>
                  )}
              </div>
            </div>
          </Panel>
        </div>
      </div>
    </>
  );
}

export default CustomMenuItemsList;
