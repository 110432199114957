import { useContext, useState } from 'react'
import { OverlayTrigger } from 'react-bootstrap'
import ToastAlert from '../../components/alert/ToastAlert'
import { AlertVariant, DATE_FORMAT, FormClass, toastMessageInitialData } from '../../constants/constants'
import { ProfileFormData, ToastMessageProps, UserFormData, JobRole, Users } from '../../constants/staticTypes'
import {
	PROFILE_SETTINGS,
	HELP_PROFILE_SETTINGS,
	HELP_USER_PHOTO,
	USER_PHOTO,
	UPLOAD_PHOTO_TYPE_ERROR,
	USER_UPLOAD_SUCCESS,
	USER_UPLOAD_PHOTO_ERROR,
	FIRST_NAME,
	FULL_NAME_ERROR,
	LAST_NAME,
	EMAIL_TEXT,
	MOBILE_TEXT,
	PREFERRED_LANGUAGE,
	CANCEL,
	SAVE,
	USER_UPDATE_SUCCESS,
	USER_UPDATE_ERROR,
	DELETE_WARNING,
} from '../../constants/strings'
import { JTranslation, TranslationContext } from '../../helpers/jTranslate'
import withSidebar from '../../hoc/withSidebar'
import { dateFormatter, getErrorMessage, popover, toPascalCase } from '../../helpers/utils'
import { Avatar } from 'primereact/avatar'
import placeholder from '../../assets/images/placeholder-avatar.jpg'
import { useQueryClient } from 'react-query'
import useMutationHook from '../../hooks/useMutationHook'
import useQueryHook from '../../hooks/useQueryHook'
import { USER_INFO } from '../../constants/queryKeys'
import { getUserInfo, userSession } from '../../helpers/authHelper'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../constants/routes'
import {
	countries,
	initialProfileFormData,
	initialProfileFormValidationData,
	uploadFile,
	upsertUsers,
} from '../../helpers/manageUsersHelper'
import Loader from '../../components/loader/Loader'
import { Dropdown } from 'primereact/dropdown'
import { cloneDeep } from 'lodash'
import { Button } from 'primereact/button'
import WarningModal from '../../components/warning_modal/WarningModal'
import { useTranslation } from '../../hooks/useTranslation'
import { Skeleton } from 'primereact/skeleton'
import MlxPopover from '../../components/common/MlxPopover'

function Profile() {
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const uploadFileMutation = useMutationHook(queryClient, true) // upload file mutation
	const upsertUserMutation = useMutationHook(queryClient, true) // add or update user mutation

	const [showWarning, setShowWarning] = useState(false)
	const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
	const [image, setImage] = useState('')
	const [preferredName, setPrefferedName] = useState('')
	const [editPreferredName, setEditPrefferedName] = useState(false)
	const [formData, setFormData] = useState<ProfileFormData>(initialProfileFormData)
	const [userData, setUserData] = useState<Users>()
	const [formValidations, setFormValidations] = useState(initialProfileFormValidationData)

	const { targetLanguage, changeTargetLanguage } = useContext(TranslationContext)

	const fetchUserInfo = useQueryHook(
		USER_INFO,
		async () => {
			const session = await userSession()
			if (!session) {
				navigate(routes.dashboard)
			}
			return getUserInfo(queryClient, session.username)
		},
		(res) => {
			const data = res?.data?.data as Users
			setUserData(data)
			setFormData({
				firstName: data?.firstName,
				lastName: data?.lastName,
				photoKey: data?.photoKey ?? '',
				preferredLangKey: data?.preferredLangKey ?? 'en',
				staffId: data?.id,
			})
			setImage(data?.signedUrl ?? '')
			setPrefferedName(data?.preferredName ?? '')

			if (targetLanguage && targetLanguage.toLowerCase() !== data?.preferredLangKey?.toLowerCase()) {
				changeTargetLanguage(targetLanguage)
				localStorage.setItem('targetLanguage', targetLanguage)
				localStorage.setItem('languageChangeTime', new Date().getTime().toString())
			}
		}
	)

	// on file upload success
	const onUploadSuccess = (key: string, imageUrl: string) => {
		// setToastMessage({ message: USER_UPLOAD_SUCCESS, show: true, variant: AlertVariant.SUCCESS })
		setFormData({ ...formData, photoKey: key })
		setImage(imageUrl)

		const currentFormData = cloneDeep(userData) as Users
		const updatedFormData = { ...currentFormData, photoKey: key }
		submitForm(updatedFormData)
	}

	// on file upload error
	const onUploadError = () =>
		setToastMessage({ message: USER_UPLOAD_PHOTO_ERROR, show: true, variant: AlertVariant.ERROR })

	// on add or update user success
	const onAddUpdateUserSuccess = () => {
		setToastMessage({
			message: USER_UPDATE_SUCCESS,
			show: true,
			variant: AlertVariant.SUCCESS,
		})
		onClear()
		fetchUserInfo.refetch()
	}

	// on add or update user error
	const onAddUpdateUserError = (res: unknown) => {
		try {
			setToastMessage({
				message: getErrorMessage(res),
				show: true,
				variant: AlertVariant.ERROR,
			})
		} catch (e) {
			setToastMessage({
				message: USER_UPDATE_ERROR,
				show: true,
				variant: AlertVariant.ERROR,
			})
		}
	}

	// set validations true
	const setValidationsTrue = () => setFormValidations(initialProfileFormValidationData)

	// clear data
	const onClear = () => {
		setFormData({
			firstName: userData?.firstName ?? '',
			lastName: userData?.lastName ?? '',
			photoKey: userData?.photoKey ?? '',
			preferredLangKey: userData?.preferredLangKey ?? 'en',
			staffId: userData?.id ?? '',
		})
		setImage(userData?.signedUrl ?? '')
		setPrefferedName(userData?.preferredName ?? '')
		setValidationsTrue()
	}

	const getAssignedRoles = (rolesList: JobRole[]) => {
		const jobRoles = rolesList.map((roles) => roles.jobRoleName)
		return jobRoles.join(' | ')
	}

	const submitForm = (formData: Users) => {
		const updatedFormData = {
			email: formData?.email !== null ? formData?.email : '',
			photoKey: formData?.photoKey ?? '',
			preferredName: formData.preferredName,
			firstName: formData.firstName,
			lastName: formData.lastName,
			hireDate: formData.hireDate ? new Date(formData.hireDate)?.toDateString() : null,
			phoneNumber: formData?.phoneNumber ?? undefined,
			posNumber: formData.posNumber,
			loginUserName: formData.loginUserName,
			jobRoles: formData.jobRoles.map((assigned) => assigned.id),
			passwordLessLoginOnly: formData.passwordLessLoginOnly,
			sendPasswordCreation: formData.sendPasswordCreation,
			sendPasswordCreationTo: '',
			staffId: formData.id,
			preferredLangKey: formData.preferredLangKey,
			staffPermissionRoles: formData.staffPermissionRoles.map((assigned) => assigned.permissionRoleId),
		}
		upsertUsers(updatedFormData, upsertUserMutation, onAddUpdateUserSuccess, onAddUpdateUserError, userData?.id)
	}

	// click add or update user
	const onFormSubmit = () => {
		let firstName = formData.firstName?.trim().length > 0 ? FormClass.VALID : FormClass.INVALID
		let lastName = formData.lastName?.trim().length > 0 ? FormClass.VALID : FormClass.INVALID
		let preferredLangKeyValid = formData.preferredLangKey?.trim().length > 0 ? '' : ' is-invalid-custom'

		if (firstName === FormClass.VALID && lastName === FormClass.VALID && preferredLangKeyValid === '') {
			const currentFormData = cloneDeep(userData) as Users
			const updatedFormData = { ...currentFormData, ...formData }
			submitForm(updatedFormData)
		} else {
			setFormValidations({
				firstName: firstName,
				lastName: lastName,
				preferredLangKey: preferredLangKeyValid,
			})
		}
	}

	// if (fetchUserInfo.isLoading || fetchUserInfo.isRefetching) {
	// 	return <Loader />
	// }

	return (
		<>
			{/* toaster component */}
			<ToastAlert
				show={toastMessage.show}
				onClose={() => setToastMessage(toastMessageInitialData)}
				message={toastMessage.message}
				variant={toastMessage.variant}
			/>

			<WarningModal
				show={showWarning}
				title={useTranslation(DELETE_WARNING, 'capitalize')}
				onHide={() => {
					setShowWarning(false)
				}}
				callback={() => {
					setShowWarning(false)
					const currentFormData = cloneDeep(userData) as Users
					const updatedFormData = {
						...currentFormData,
						photoKey: null,
					}
					submitForm(updatedFormData)
				}}
			/>

			<div className="page-title">
				<h5 className="d-flex">
					<JTranslation typeCase="pascal" text={PROFILE_SETTINGS} />
					<MlxPopover data={{title:PROFILE_SETTINGS, body:HELP_PROFILE_SETTINGS}}/>
				</h5>
			</div>

			<div className="col-md-12 col-lg-12 mb-3 h-100 edit-profile">
				<div className="row h-100">
					<div className="col-md-4 col-lg-4 mb-3">
						<div className="card h-100">
							<div className="card-body">
								<div className="d-flex justify-content-end align-items-center">
									<div className="profile-help">
										<MlxPopover data={{title:USER_PHOTO, body:HELP_USER_PHOTO}}/>
									</div>
								</div>
								{fetchUserInfo.isLoading ||
									(fetchUserInfo.isFetching && (
										<div className="mt-4 d-flex flex-column justify-content-center align-items-center">
											<div className="profile-bg">
												<div className="profile-container user-delete-profile">
													<Skeleton shape="circle" size="10rem"></Skeleton>
												</div>
											</div>

											<div className="mt-3 d-flex align-items-center">
												<Skeleton width="8rem"></Skeleton>
											</div>

											<div className="mt-3 d-flex flex-column align-items-center">
												<p className="m-0">
													<Skeleton width="5rem"></Skeleton>
												</p>
												<p className="mt-2">
													<Skeleton width="10rem"></Skeleton>
												</p>
											</div>

											<div className="mt-3 d-flex align-items-center">
												<p className="">
													<span className="me-1">
														<Skeleton width="8rem"></Skeleton>
													</span>
												</p>
											</div>
										</div>
									))}

								{!fetchUserInfo.isLoading && !fetchUserInfo.isFetching && (
									<div className="mt-4 d-flex flex-column justify-content-center align-items-center">
										<div className="profile-bg">
											<div className="profile-container user-delete-profile">
												<Avatar
													className="mr-2 shadow-sm d-flex flex-grow-0 align-items-center justify-content-center user-delete"
													size="xlarge"
													shape="circle"
													image={
														image !== null && image?.trim().length > 0 ? image : placeholder
													}
													imageAlt="user"
												/>

												{image !== null && image?.trim().length > 0 && (
													<div
														className="delete-user-icon"
														data-testid="delete-profile-img"
														style={{ cursor: 'pointer' }}
														onClick={() => setShowWarning(true)}
													>
														<i className="ri-delete-bin-6-line"></i>
													</div>
												)}

												<label htmlFor="file-upload" className="cam-icon">
													<div className=" shadow-sm">
														<i className="ri-pencil-line"></i>
														<input
															className="d-none"
															data-testid="upload"
															id="file-upload"
															type="file"
															accept="image/*"
															autoComplete="off"
															disabled={
																fetchUserInfo.isLoading || fetchUserInfo.isFetching
															}
															onChange={(e) => {
																// Trigger File Upload
																if (e.target.files && e.target.files?.length > 0) {
																	if (
																		e.target.files[0].type.indexOf('image') !== -1
																	) {
																		uploadFile(
																			e.target.files[0],
																			uploadFileMutation,
																			onUploadSuccess,
																			onUploadError
																		)
																		e.target.value = ''
																	} else {
																		setToastMessage({
																			message: UPLOAD_PHOTO_TYPE_ERROR,
																			show: true,
																			variant: AlertVariant.ERROR,
																		})
																	}
																}
															}}
														/>
													</div>
												</label>
											</div>
										</div>

										{!editPreferredName && (
											<div className="mt-3 d-flex align-items-center">
												<b className="me-1">{toPascalCase(userData?.preferredName ?? '')}</b>
												<i
													role="button"
													className="ri-pencil-line"
													onClick={() => setEditPrefferedName(true)}
												></i>
											</div>
										)}

										{editPreferredName && (
											<div className="mt-3 d-flex align-items-center">
												<div className="form-group input-group custom-input-group">
													<input
														id="custom_label"
														className="form-control"
														autoComplete="off"
														maxLength={50}
														value={preferredName}
														onChange={(e) => setPrefferedName(e.target.value)}
													/>
												</div>

												<div className="d-flex justify-content-end">
													<Button
														icon="pi pi-check"
														aria-label="Filter"
														className="mt-1 p-button-sm  p-button-rounded p-button-success p-button-text"
														onClick={() => {
															// api call
															if (
																preferredName?.length > 0 &&
																preferredName !== userData?.preferredName
															) {
																setEditPrefferedName(false)
																const currentFormData = cloneDeep(userData) as Users
																const updatedFormData = {
																	...currentFormData,
																	preferredName: preferredName,
																}
																submitForm(updatedFormData)
															}
														}}
													/>
													<Button
														icon="pi pi-times"
														className=" mt-1 p-button-rounded p-button-danger p-button-text"
														aria-label="Cancel"
														onClick={() => {
															setPrefferedName(userData?.preferredName ?? '')
															setEditPrefferedName(false)
														}}
													/>
												</div>
											</div>
										)}

										<div className="mt-3 d-flex flex-column align-items-center">
											<p className="m-0">
												<span className="me-1">POS:</span>
												{userData?.posNumber}
											</p>
											<p className="mt-1">{getAssignedRoles(userData?.jobRoles ?? [])}</p>
										</div>

										<div className="mt-3 d-flex align-items-center">
											<p className="">
												<span className="me-1">
													<JTranslation typeCase="pascal" text={'Hire Date'} />:
												</span>
												<b>
													{userData?.hireDate
														? dateFormatter({
																date: userData?.hireDate,
																format: DATE_FORMAT,
														  })
														: ''}
												</b>
											</p>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="col-md-8 col-lg-8 mb-3">
						<div className="card h-100">
							<div className="card-header flex-grow-0">
								<div className="d-flex  align-items-center">
									<div className="flex-grow-1">
										<h5 className="m-0">
											<JTranslation typeCase="pascal" text={'Edit Profile'} />
										</h5>
									</div>
								</div>
							</div>
							<div className="card-body flex-grow-1 overflow-auto">
								<div className="row">
									<div className=" col-md-6 col-lg-6 mb-3">
										<label htmlFor="validationCustom02" className="form-label">
											<JTranslation typeCase="pascal" text={FIRST_NAME} />
											<span className="mandatory ">*</span>{' '}
										</label>
										<input
											type="text"
											className={formValidations.firstName}
											id="validationCustom02"
											data-testid="firstname"
											autoComplete="off"
											maxLength={130}
											value={formData.firstName}
											onChange={(e) => {
												setFormData({ ...formData, firstName: e.target.value })
												if (formValidations.firstName === FormClass.INVALID) {
													setFormValidations({
														...formValidations,
														firstName:
															e.target.value?.trim().length > 0
																? FormClass.VALID
																: FormClass.INVALID,
													})
												}
											}}
										/>

										{formValidations.firstName === FormClass.INVALID && (
											<div className="invalid-feedback" data-testid="error-name">
												<JTranslation typeCase="capitalize" text={FULL_NAME_ERROR} />
											</div>
										)}
									</div>

									<div className=" col-md-6 col-lg-6 mb-3">
										<label htmlFor="validationCustom03" className="form-label">
											<JTranslation typeCase="pascal" text={LAST_NAME} />
											<span className="mandatory ">*</span>{' '}
										</label>
										<input
											type="text"
											className={formValidations.lastName}
											autoComplete="off"
											id="validationCustom03"
											data-testid="lastname"
											maxLength={130}
											value={formData.lastName}
											onChange={(e) => {
												setFormData({ ...formData, lastName: e.target.value })
												if (formValidations.lastName === FormClass.INVALID) {
													setFormValidations({
														...formValidations,
														lastName:
															e.target.value?.trim().length > 0
																? FormClass.VALID
																: FormClass.INVALID,
													})
												}
											}}
										/>

										{formValidations.lastName === FormClass.INVALID && (
											<div className="invalid-feedback" data-testid="error-name">
												<JTranslation typeCase="capitalize" text={FULL_NAME_ERROR} />
											</div>
										)}
									</div>

									<div className=" col-md-6  col-lg-6 mb-3">
										<label htmlFor="emailId" className="form-label">
											<JTranslation typeCase="pascal" text={EMAIL_TEXT} />{' '}
										</label>
										<input
											type="text"
											data-testid="email"
											className="form-control"
											id="emailId"
											value={userData?.email}
											autoComplete="off"
											readOnly={true}
										/>
									</div>

									<div className=" col-md-6  col-lg-6 mb-3">
										<label htmlFor="emailId" className="form-label">
											<JTranslation typeCase="pascal" text={MOBILE_TEXT} />{' '}
										</label>
										<input
											type="text"
											data-testid="email"
											className="form-control"
											id="emailId"
											value={userData?.phoneNumber}
											autoComplete="off"
											readOnly={true}
										/>
									</div>

									<div className=" col-md-6  col-lg-6 mb-3">
										<label htmlFor="preferredLangKey" className="form-label">
											<JTranslation typeCase="pascal" text={PREFERRED_LANGUAGE} />
										</label>
										<Dropdown
											className={
												formValidations.preferredLangKey + ' w-100 preferred-language-dropdown'
											}
											data-testid="preferred-language"
											value={formData.preferredLangKey?.toLowerCase()}
											options={countries}
											optionLabel="langName"
											optionValue="langKey"
											onChange={(e) => {
												setFormData({
													...formData,
													preferredLangKey: e.value,
												})
											}}
											// placeholder={useTranslation('Select', 'pascal')}
										/>

										{formValidations.preferredLangKey === ' is-invalid-custom' && (
											<div className="invalid-feedback" data-testid="error-roles">
												<JTranslation typeCase="capitalize" text={'Please choose a language'} />
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="card-footer p-0 border-0">
								<div className="save-btn-section">
									<button
										className="btn btn-custom-primary-outline"
										type="button"
										data-testid="clear-button"
										disabled={fetchUserInfo.isLoading || fetchUserInfo.isFetching}
										onClick={() => {
											onClear()
										}}
									>
										<JTranslation typeCase="pascal" text={CANCEL} />
									</button>

									<button
										className="btn btn-custom-primary"
										type="button"
										data-testid="save-button"
										disabled={fetchUserInfo.isLoading || fetchUserInfo.isFetching}
										onClick={() => {
											onFormSubmit()
										}}
									>
										<JTranslation typeCase="pascal" text={SAVE} />
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default withSidebar(Profile)
