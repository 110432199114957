import { useContext, useEffect, useRef, useState } from 'react'
import './styles/headerStyle.scss'
import 'react-circular-progressbar/dist/styles.css'
import { isGuestOnlyRoute, setSidebarToggle } from '../../helpers/utils'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { FaBars } from 'react-icons/fa'
import { FILE_UPLOAD_COMPLETED, FILE_UPLOAD } from '../../constants/strings'
import { FileManagementCtx } from '../../context/FileManagementCtxProvider'
import { guestOnlyRoutes, routes } from '../../constants/routes'
import { JTranslation } from '../../helpers/jTranslate'
import { SIDEBAR_TOGGLE } from '../../constants/queryKeys'
import { SPECIAL_MENU_CODE } from '../../constants/constants'
import { useInitializeGA } from '../../hooks/useTrackEvent'
import { useLocation, useNavigate } from 'react-router-dom'
import { useQueryClient, UseQueryResult } from 'react-query'
import HeaderDropDown from './HeaderDropdown'
import LanguageSwitcher from './LanguageSwitcher'
import LogoComponent from './LogoComponent'
import SpecialDayEffect from '../../components/celebrations/SpecialDayEffect'
import ToastAlert from '../../components/alert/ToastAlert'
import WarnTabCloseAndReload from './WarnTabCloseAndReload'
import FontSlider from './FontSlider'
import NotificationPanelPopup from '../manage_notifications/NotificationPanelPopup'

type Props = {
	toggle?: UseQueryResult<{ value: boolean | undefined }, unknown>
	isAdmin?: boolean
}

const IsGuestPage = () => {
	const location = useLocation()
	const isGuestRoute = isGuestOnlyRoute(location.pathname, guestOnlyRoutes)

	return isGuestRoute
}

function HeaderComponent({ toggle, isAdmin = true }: Props) {
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const location = useLocation()
	const menuCode = localStorage.getItem(SPECIAL_MENU_CODE)

	// CONTEXT VARIABLES
	const {
		uploadedFileCount,
		totalFileCount,
		showUploadProgress,
		setTotalFileCount,
		setUploadedFileCount,
		setShowUploadProgress,
	} = useContext(FileManagementCtx)

	// ALERT VARIABLES
	const [alertVariant, setVariant] = useState('')
	const [errorMessage, setErrorMessage] = useState('')
	const [showAlert, setShowAlert] = useState(false)

	// STATE VARIABLES
	const [uploadPercentage, setUploadPercentage] = useState(0)

	// Initialize Google Analytics
	useInitializeGA()

	const getMenuRedirectionURL = () => {
		// if menuCode exists, it means that the user is in special menu page
		if (menuCode) {
			return `${routes.special_menu}/${menuCode}`
		} else {
			return `${routes.menu_view}${location.search}`
		}
	}

	// get active class for public nav links
	const getActiveClass = (path: string, key: string) => {
		if (path.startsWith(key)) {
			return 'active'
		}
		return ''
	}

	// effect to reset the file upload count once the uploads are complete
	useEffect(() => {
		if (uploadedFileCount === totalFileCount && uploadedFileCount !== 0 && totalFileCount !== 0) {
			setTotalFileCount(0)
			setUploadedFileCount(0)
			setVariant('success')
			setErrorMessage(FILE_UPLOAD_COMPLETED)
			setShowAlert(true)
		}

		// calculate upload percentage
		setUploadPercentage((uploadedFileCount / totalFileCount) * 100)
		// progress bar display logic
		uploadedFileCount || totalFileCount ? setShowUploadProgress(true) : setShowUploadProgress(false)
	}, [
		uploadedFileCount,
		totalFileCount,
		setTotalFileCount,
		setUploadedFileCount,
		setUploadPercentage,
		setShowUploadProgress,
	])

	const menuLeft = useRef<any>(null)
	const menuRight = useRef(null)
	const toast = useRef(null)

	const items = [
		{
			label: 'Font Size',
			items: [
				{
					label: 'Increase Font',
					icon: 'ri-add-fill',
				},
				{
					label: 'Decrease Font',
					icon: 'ri-subtract-line',
				},
				{
					label: 'Reset',
					icon: 'ri-refresh-line',
				},
			],
		},
	]

	return (
		<>
			{IsGuestPage() && <SpecialDayEffect />}
			{isAdmin ? (
				<nav className="sb-topnav navbar navbar-expand navbar-light bg-light d-flex sticky-top flex-md-nowrap p-0 shadow">
					{/* TOAST MESSAGE COMPONENT */}
					<ToastAlert
						dataTestId="header-toast"
						show={showAlert}
						onClose={setShowAlert}
						message={errorMessage}
						variant={alertVariant}
					/>
					<div className="tg-btn-nt-clps d-flex me-auto">
						<div className="d-flex ham-container">
							<div className="tg-btn-clps">
								{/* Sidebar Toggle-*/}
								<button
									className="btn btn-link btn-lg order-0 order-lg-0  me-lg-0"
									id="sidebarToggle"
									onClick={() =>
										queryClient.setQueryData(SIDEBAR_TOGGLE, setSidebarToggle(!toggle?.data?.value))
									}
								>
									<FaBars />
								</button>
							</div>
							<div className="d-flex ham-container">
								<LogoComponent />
							</div>
						</div>
					</div>

					{/* file upload progress spinner */}
					{showUploadProgress && (
						<div className="d-flex align-items-center">
							<JTranslation typeCase="pascal" text={FILE_UPLOAD} />
							<div className="me-2 ms-2" style={{ width: 45, height: 45 }}>
								<CircularProgressbar
									value={uploadPercentage}
									text={`${uploadedFileCount}/${totalFileCount}`}
									strokeWidth={10}
									styles={buildStyles({
										textColor: '#009107',
										pathColor: '#009107',
										trailColor: '#ebebeb',
									})}
								/>
							</div>
						</div>
					)}
					{!IsGuestPage() && (
						<div className="me-3">
							<NotificationPanelPopup />
						</div>
					)}
					<div className="me-3">
						<FontSlider />
					</div>
					{/* Inform user about the upload in progress in the event of tab close or reload */}
					{showUploadProgress && <WarnTabCloseAndReload />}
					{<LanguageSwitcher />}

					{/* Dropdown Options */}
					<HeaderDropDown queryClient={queryClient} />
				</nav>
			) : (
				<div className="flex-grow-0">
					<header className="headerBar fixed-top align-items-center">
						<div className="tg-btn-nt-clps d-flex me-auto">
							<div className="d-flex ham-container">
								<LogoComponent />
							</div>
						</div>
						<div className="font-new">
							{/* <Menu model={items} popup ref={menuLeft} id="popup_menu_left" popupAlignment="left" />
            <Button  icon="ri-font-size" rounded outlined severity='warning' className="mr-2" onClick={(event) => menuLeft.current?.toggle(event)} aria-controls="popup_menu_left" aria-haspopup></Button> */}
						</div>
						<div className="me-3">
							<FontSlider />
						</div>
						{<LanguageSwitcher />}
						{/* Dropdown Options */}
						<HeaderDropDown queryClient={queryClient} />
					</header>
					<nav className="subnavbar">
						<Navbar className="nav-custom-bg navbar-expand py-1" expand="lg">
							<Container fluid className="justify-content-end">
								<Navbar.Toggle aria-controls="basic-navbar-nav" />
								<Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
									<Nav
										className="justify-content-end"
										onSelect={(selectedKey) => navigate(selectedKey as string)}
									>
										<Nav.Link
											className={getActiveClass(
												location.pathname,
												menuCode ? `${routes.special_menu}/${menuCode}` : routes.menu_view
											)}
											eventKey={getMenuRedirectionURL()}
										>
											<JTranslation text="Menu" typeCase="pascal" />
										</Nav.Link>
										<Nav.Link
											className={getActiveClass(location.pathname, routes.survey_guest)}
											eventKey={`${routes.survey_guest}${location.search}`}
										>
											<JTranslation text="Survey" typeCase="pascal" />
										</Nav.Link>
										<Nav.Link
											className={
												getActiveClass(location.pathname, routes.news_and_events) ||
												getActiveClass(location.pathname, routes.news_only) ||
												getActiveClass(location.pathname, routes.news_in_detail) ||
												getActiveClass(location.pathname, routes.events_only) ||
												getActiveClass(location.pathname, routes.events_in_detail)
											}
											eventKey={`${routes.news_and_events}${location.search}`}
										>
											<JTranslation text="News & Events" typeCase="pascal" />
										</Nav.Link>
									</Nav>
								</Navbar.Collapse>
							</Container>
						</Navbar>
					</nav>
				</div>
			)}
		</>
	)
}

export default HeaderComponent
