import React from 'react'
import Lottie from 'react-lottie'
import animationData from './AnimationHeartsCollide.json'
import './HeartAnimation.css'
import { getValentinesDayEffects } from '../celebrationsConfigs'

const HeartAnimation: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
	const valentinesDayEffect = getValentinesDayEffects()
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	}

	if (valentinesDayEffect?.showSpecialLogo) {
		return (
			<div className="animation-container-heart" style={style}>
				<Lottie options={defaultOptions} height={70} width={70} isPaused={false} isStopped={false} />
			</div>
		)
	}
	return null
}

export default HeartAnimation
