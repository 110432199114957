import { useEffect, useState } from 'react';
import { Card } from 'primereact/card';
import { Carousel } from 'primereact/carousel';
import { DayOfWeek } from '../../constants/staticTypes';
import { generateWeekdayDates, Weekday, days, generateTwoWeekdayDates, getDateOfWeekday, PublishedScheduleExtended } from '../../helpers/workTimeScheduleHelper';
import { NO_SHIFT_ASSIGNED } from '../../constants/strings';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import { useWindowSize } from 'react-use';
import { MOBILE_VIEW_BREAKPOINT } from '../../constants/constants';
import { JTranslation } from '../../helpers/jTranslate';
import { Skeleton } from 'primereact/skeleton';

type Props = {
    selectedWeek: dayjs.Dayjs,
    parent?: string
    scheduleData: PublishedScheduleExtended[]
    isFetching: boolean
}

const StaffScheduleMobileView = ({ selectedWeek, scheduleData, isFetching, parent }: Props) => {
    const [weekdayDates, setWeekdayDates] = useState<Weekday[]>([]);
    const [page, setPage] = useState(0);
    const [currentDay, setCurrentDay] = useState<{ day: DayOfWeek, occurrence: number, index: number } | null>(null);
    const [currentOccurrence, setCurrentOccurrence] = useState(0);
    const { width } = useWindowSize();
    const breakPoint = MOBILE_VIEW_BREAKPOINT;

    // Generate unique days list with occurrence index
    const uniqueDays = [...Array(2)].flatMap((_, week) =>
        days.map((day, index) => ({
            day,
            occurrence: week,
            index: index + (week * 7)
        }))
    );

    useEffect(() => {
        // Calculate the index to set the page
        // If today is Sunday (0), we should go to the next Monday which is at index 7 in uniqueDays
        // For other days, we should go to the next day in the current week
        const todayIndex = dayjs().day(); // Get current day of the week (0 for Sunday, 1 for Monday, etc.)

        // Determine the next day index (if today is Sunday, go to next Monday)
        const nextDayIndex = todayIndex === 0 ? 7 : todayIndex;

        setPage(nextDayIndex);
    }, []);

    useEffect(() => {
        if (selectedWeek) {
            const dates = parent === 'dashboard' ? generateTwoWeekdayDates(selectedWeek) : generateWeekdayDates(selectedWeek);
            setWeekdayDates(dates);
        }
    }, [selectedWeek]);

    const getFormattedDate = (day: DayOfWeek) => {
        let date = '';
        weekdayDates?.forEach((weekDay) => {
            if (weekDay.day === day) {
                date = dayjs(weekDay.date).format('ddd, D MMM YYYY');
            }
        });
        return date;
    }

    const getUpcomingFormattedDate = (day: DayOfWeek | undefined, occurrence: number) => {
        let date = '';
        let count = 0;

        weekdayDates?.forEach((weekDay) => {
            if (weekDay.day === day) {
                if (count === occurrence) {
                    date = dayjs(weekDay.date).format('ddd, D MMM YYYY');
                }
                count++;
            }
        });

        return date;
    }

    const getScheduleFormattedDate = (day: DayOfWeek, occurrence: number) => {
        let date = '';
        let count = 0;

        weekdayDates?.forEach((weekDay) => {
            if (weekDay.day === day) {
                if (count === occurrence) {
                    date = dayjs(weekDay.date).format('YYYY-MM-DD'); // Use ISO string format
                }
                count++;
            }
        });

        return date;
    }

    // Original cardBodyTemplate function for single week
    const cardBodyTemplate = (weekDay: DayOfWeek) => {
        if (!scheduleData.length) return
        const data = scheduleData[0].shifts
            .filter(item => {
                const { scheduleDate } = item;
                const currentCellDate = getDateOfWeekday({ day: weekDay, selectedWeek, format: 'YYYY-MM-DD' });
                return scheduleDate && scheduleDate === currentCellDate;
            })
            .map(item => {
                const { assignedShifts } = item;
                const { startTime, endTime, role, isBd, isClose } = assignedShifts;
                return (
                    <div key={uuidv4()} className='staff-schedule-dashboard-card'>
                        <div className="staff-schedule">
                            {isClose ? <div className="time">{`${startTime} - "Close"`}</div> : ""}
                            {isBd ? <div className="time">{`${startTime} - "V"`}</div> : ""}
                            {!isBd && !isClose ? <div className="time">{`${startTime} - ${endTime}`}</div> : ''}
                            <div className="role">{<JTranslation text={role} />}</div>
                        </div>
                    </div>
                );
            });

        if (data?.length === 0) {
            return (
                <div className="staff-schedule-card-no-reservation">
                    <div className="staff-schedule staff-schedule-dashboard"><JTranslation typeCase="none" text={NO_SHIFT_ASSIGNED} /></div>
                </div>
            );
        }

        return <>{data}</>;
    }

    // New upcomingCardBodyTemplate function for two weeks
    const upcomingCardBodyTemplate = (weekDay: DayOfWeek, occurrence: number) => {
        // Find the date corresponding to this occurrence of the day
        const specificDate = getScheduleFormattedDate(weekDay, occurrence);

        // Filter and map valid schedule entries
        if(!scheduleData.length) return        
        const data = scheduleData[0].shifts
            ?.filter(item => {
                const { scheduleDate } = item;
                return scheduleDate && scheduleDate === specificDate;
            })
            .map(item => {
                const { assignedShifts } = item;
                const { shiftName, startTime, endTime, role, isBd, isClose } = assignedShifts;
                return (
                    <div key={uuidv4()} className='staff-schedule-dashboard-card'>
                        <div className="staff-schedule-dashboard">
                            {/* <div className="shiftName">{shiftName}</div> */}
                            {isClose ? <div className="time">{`${startTime} - "Close"`}</div> : ""}
                            {isBd ? <div className="time">{`${startTime} - "V"`}</div> : ""}
                            {!isBd && !isClose ? <div className="time">{`${startTime} - ${endTime}`}</div> : ''}
                            <div className="role">{role}</div>
                        </div>
                    </div>
                );
            });

        if (data?.length === 0) {
            return (
                <div className="staff-schedule-card-no-reservation">
                    <div className="staff-schedule-dashboard"><JTranslation typeCase="none" text={NO_SHIFT_ASSIGNED} /></div>
                </div>
            );
        }

        return <>{data}</>;
    }

    const dayTemplate = ({ day, occurrence, index }: { day: DayOfWeek, occurrence: number, index: number }) => {
        return (
            <Card key={index} className='data-view-card mb-3 border-0 shadow-none upcoming-schedule h-100 min-hgt-195'>
                {/* <p>{getUpcomingFormattedDate(day, occurrence)}</p> */}
                {isFetching ?
                    <Skeleton height='4rem'></Skeleton>
                    :
                    <div className="dashboard-schedule-scroll">
                        {upcomingCardBodyTemplate(day, occurrence)}
                    </div>
                }
            </Card>
        );
    }

    const upcomingDayHeader = (dayDetails: any) => {
        return (
            <>
                <p className='text-center mb-0 mt-1' style={{ fontSize: "1rem" }}>{getUpcomingFormattedDate(dayDetails.day, dayDetails.occurrence)}</p>
            </>
        )
    }

    return (
        <>
            {parent === 'dashboard' ? (
                <Card className='data-view-card mb-3 h-100 shadow-none'>
                    <p className='fw-bold mb-1'><JTranslation typeCase="pascal" text={"Upcoming Schedule"} /></p>
                    <Carousel
                        value={uniqueDays}
                        className='d-flex align-items-center justify-content-center mb-2'
                        numVisible={1}
                        numScroll={1}
                        itemTemplate={(item) => upcomingDayHeader(item)}
                        page={page}
                        onPageChange={(e) => {
                            setPage(e.page);
                            setCurrentDay(uniqueDays[e.page]);
                            setCurrentOccurrence(uniqueDays[e.page].occurrence);
                        }}
                        showIndicators={false}
                    />
                    {currentDay && dayTemplate(currentDay)}
                </Card>
            ) : (
                <div className="card">
                    {days.map(day => (
                        <Card key={uuidv4()} className='data-view-card mb-3 h-100 '>
                            <p>{getFormattedDate(day)}</p>
                            {cardBodyTemplate(day)}
                        </Card>
                    ))}
                </div>
            )}
        </>
    );
}

export default StaffScheduleMobileView;
