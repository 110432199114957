import { useState, useEffect, useContext } from 'react';
import {
  ThemeTemplate,
  addBrandLogo,
  enableThemeBranding,
  initialFestivalThemeFormData,
  themeTemplates,
  updateFestivalTheme,
} from '../../helpers/brandingHelper';
import {
  AlertVariant,
  FESTIVAL_THEME_PAGE_CONFIG,
  SPECIAL_EFFECTS_NAME,
  toastMessageInitialData,
} from '../../constants/constants';
import { AxiosResponse } from 'axios';
import {
  UPLOAD_WEB_LOG,
  UPLOAD_MOBILE_LOG,
  LOGO_SIZE_ERROR,
  LOGO_FORMAT_ERROR,
  RESET,
  DELETE_TEMPLATE_WARNING,
  SAVE,
} from '../../constants/strings';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { BRANDING_INFO, TENANT_INFO } from '../../constants/queryKeys';
import {
  ToastMessageProps,
  SetLogo,
  BrandingResponseData,
  FestivalThemeFormData,
  BrandingData,
} from '../../constants/staticTypes';
import { uploadFile } from '../../helpers/manageUsersHelper';
import { useQuery, useQueryClient } from 'react-query';
import ToastAlert from '../../components/alert/ToastAlert';
import type {
  RcFile,
  UploadFile as UploadFileType,
  UploadProps,
} from 'antd/es/upload/interface';
import type { UploadChangeParam } from 'antd/es/upload';
import useMutationHook from '../../hooks/useMutationHook';
import { JTranslation } from '../../helpers/jTranslate';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from '../../hooks/useTranslation';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import SpecialDayEffect from '../../components/celebrations/SpecialDayEffect';
import WarningModal from '../../components/warning_modal/WarningModal';
import { getTenantInfo } from '../../helpers/authHelper';
import { CommonCtx } from '../../context/CommonCtxProvider';
import { initParticlesEngine } from '@tsparticles/react';
import { loadAll } from '@tsparticles/all';
import { ColorPicker } from 'primereact/colorpicker';
import { InputText } from 'primereact/inputtext';
import { initialColorTheme } from '../../context/defaultContextValues';

const BrandingConfig = () => {
  const queryClient = useQueryClient();
  const uploadFileMutation = useMutationHook(queryClient, true);
  const festivalThemeMutation = useMutationHook(queryClient, true);
  const [tempColorTheme, setTempColorTheme] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [webLogUrl, setWebLogoUrl] = useState({ url: '', imageKey: '' });
  const [mobileLogUrl, setMobileLogoUrl] = useState<UploadFileType[]>([
    {
      url: '',
      uid: '-1',
      name: '',
      status: 'done',
    },
  ]);
  const [toastMessage, setToastMessage] = useState<ToastMessageProps>(
    toastMessageInitialData
  );
  const [formFestivalData, setFormFestivalData] =
    useState<FestivalThemeFormData | null>(null);
  const [filteredThemeTemplate, setFilteredThemeTemplate] = useState<
    ThemeTemplate[]
  >([]);
  const [preiveiwing, setPreiveiwing] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const { data: tenantResponseData } = useQuery<AxiosResponse<any, any>>(
    TENANT_INFO,
    getTenantInfo
  );
  const stopLabel = useTranslation('Stop', 'pascal');
  const previewLabel = useTranslation('Preview', 'pascal');
  const loadingLabel = useTranslation('Loading', 'pascal');
  const { particleEngineLoaded, setParticleEngineLoaded, colorTheme, setColorTheme } = useContext(CommonCtx);

  const festivalThemeForm = useFormik({
    enableReinitialize: true,
    initialValues: formFestivalData ?? initialFestivalThemeFormData,
    validate: (data) => {
      let errors: any = {};
      if (data.enableCelebrationEffect) {
        if (data.showCelebrationTemplateOn.length === 0) {
          errors.showCelebrationTemplateOn = 'requried';
        }
      }
      return errors;
    },
    onSubmit: (data) => {
      setPreiveiwing(false);
      updateFestivalTheme(
        festivalThemeMutation,
        data,
        () => {
          setToastMessage({
            message: 'Theme Updated Successfully',
            show: true,
            variant: AlertVariant.SUCCESS,
          });
          onSuccess();
        },
        (error: string, variant: string) => {
          onError(error, variant);
          festivalThemeForm.resetForm();
        }
      );
    },
  });

  useEffect(() => {
    let data = tenantResponseData?.data?.data as BrandingResponseData;
    const { branding } = data;
    setMobileLogoUrl([{ uid: '-1', name: '', url: branding?.logos?.small }]);

    const brandingInfo = queryClient.getQueryData<BrandingData>(BRANDING_INFO);
    const celebrationTemplates = brandingInfo?.showCelebrationTemplateOn ?? [];
    setFormFestivalData({
      enableCelebrationEffect: brandingInfo?.enableCelebrationEffect ?? false,
      activeCelebrationTemplate: brandingInfo?.activeCelebrationTemplate ?? '',
      showCelebrationTemplateOn: celebrationTemplates,
    });

    const celebrationEffects = brandingInfo?.celebrationEffects ?? {};
    const filterThemeTemplates = themeTemplates.filter((theme) =>
      Object.keys(celebrationEffects).includes(theme.key)
    );
    setFilteredThemeTemplate(filterThemeTemplates);
    setPreiveiwing(false);
  }, [queryClient, tenantResponseData]);

  const onSuccess = () => queryClient.refetchQueries([TENANT_INFO]);

  const onError = (error: string, variant: string) =>
    setToastMessage({ message: error, show: true, variant: variant });

  // on file upload success
  const onUploadSuccess = (key: string, imageUrl: string) => {
    setToastMessage({
      message: 'Logo Uploaded Successfully',
      show: true,
      variant: AlertVariant.SUCCESS,
    });
    setMobileLogoUrl([{ uid: '-1', url: imageUrl, name: key }]);
    setLoading(false);
    // api call to update image key on DB
    const param: SetLogo = { imageKey: key, logoType: 'small' };
    addBrandLogo(uploadFileMutation, param, onSuccess, onError);
  };

  // on file upload error
  const onUploadError = () =>
    setToastMessage({
      message: 'Logo Uploaded Failed',
      show: true,
      variant: AlertVariant.ERROR,
    });

  const handleChange: UploadProps['onChange'] = (
    info: UploadChangeParam<UploadFileType>
  ) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      uploadFile(
        info.file.originFileObj!,
        uploadFileMutation,
        onUploadSuccess,
        onUploadError
      );
    }
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      setToastMessage({
        message: LOGO_FORMAT_ERROR,
        show: true,
        variant: AlertVariant.ERROR,
      });
      return isJpgOrPng;
    }
    const isLimit = file.size / 1024 < 1024;
    if (!isLimit) {
      setToastMessage({
        message: `${LOGO_SIZE_ERROR} 1MB!`,
        show: true,
        variant: AlertVariant.ERROR,
      });
      return isLimit;
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>
        <JTranslation typeCase='pascal' text={'Upload'} />
      </div>
    </div>
  );

  const showFestivalThemePreview = (show: boolean) => {
    if (show) {
      const brandingInfo = queryClient.getQueryData<BrandingData>(
        BRANDING_INFO
      ) as BrandingData;
      const enabledThemeBrandingInfo = enableThemeBranding(
        brandingInfo,
        festivalThemeForm.values.activeCelebrationTemplate
      );
      localStorage.setItem(
        SPECIAL_EFFECTS_NAME,
        JSON.stringify(enabledThemeBrandingInfo.celebrationEffects || {})
      );
      queryClient.setQueryData(BRANDING_INFO, enabledThemeBrandingInfo);

      if (particleEngineLoaded) {
        setPreiveiwing(show)
      } else {
        initParticlesEngine(async (engine) => {
          setPreiveiwing(show)
          await loadAll(engine);
        }).then(() => {
          setParticleEngineLoaded(true);
        });
      }

    } else {
      setPreiveiwing(show);
      queryClient.refetchQueries(TENANT_INFO);
    }
  };

  useEffect(() => {
    setTempColorTheme(colorTheme);
    return () => {
      setShowWarning(false);
      showFestivalThemePreview(false);
    };
  }, []);

  return (
    <>
      {/* Delete modal */}
      <WarningModal
        show={showWarning}
        title={DELETE_TEMPLATE_WARNING}
        onHide={() => {
          setShowWarning(false);
        }}
        callback={() => {
          setShowWarning(false);
          festivalThemeForm.setValues(initialFestivalThemeFormData, true);
          festivalThemeForm.submitForm();
        }}
      />

      <ToastAlert
        data-testid='toast'
        message={toastMessage.message}
        onClose={() => setToastMessage(toastMessageInitialData)}
        show={toastMessage.show}
        variant={toastMessage.variant}
      />

      <div className={preiveiwing && particleEngineLoaded ? '' : 'd-none'}>
        <SpecialDayEffect />
      </div>

      <div className='row h-100 overflow-auto'>
        <div className='col-md-12 mb-3'>
          <div className='primary-title-bg d-flex justify-content-between mb-4'>
            <h5 className='d-flex align-items-center mb-0'>
              <JTranslation typeCase='pascal' text={'Update Logo'} />
            </h5>
          </div>
          <div className='survey-section'>
            <div className='d-flex flex-sm-row flex-column'>
              <div className='d-flex me-4 mb-2'>
                <div className='d-flex flex-column '>
                  <span className='mb-2'>
                    <JTranslation typeCase='pascal' text={UPLOAD_WEB_LOG} />
                  </span>
                  <Upload
                    name='avatar'
                    listType='picture-card'
                    className='avatar-uploader branding-upload'
                    disabled={true}
                    showUploadList={false}
                    data-testid='upload-web-logo'
                    action='https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188'
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                  >
                    {webLogUrl.url ? (
                      <img
                        src={webLogUrl.url}
                        alt='avatar'
                        style={{
                          width: '100%',
                        }}
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </div>
              </div>
              <div className='d-flex mb-2'>
                <div className='d-flex flex-column'>
                  <span className='mb-2'>
                    <JTranslation typeCase='pascal' text={UPLOAD_MOBILE_LOG} />
                  </span>
                  <Upload
                    name='avatar'
                    listType='picture-card'
                    className='avatar-uploader branding-upload upload-container mb-2'
                    showUploadList={{
                      showPreviewIcon: false,
                      showRemoveIcon: false,
                    }}
                    action='https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188'
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                    fileList={mobileLogUrl}
                    data-testid='upload-mobile-logo'
                  >
                    {uploadButton}
                  </Upload>
                </div>
              </div>
            </div>
            <div className='d-flex'></div>
          </div>
        </div>

        <form
          onSubmit={festivalThemeForm.handleSubmit}
          className='col-md-12 mb-3 festival-form'
        >
          <div className='primary-title-bg d-flex justify-content-between mb-4'>
            <h5 className='d-flex align-items-center mb-0'>
              <JTranslation typeCase='pascal' text={'Festival Theming'} />
            </h5>
          </div>
          <div className='col-md-8 col-lg-7 mb-4'>
            <label className='form-label'>
              <JTranslation typeCase='pascal' text={'Select Festival'} />
            </label>
            <div className='p-inputgroup flex-1'>
              <Dropdown
                className={`flex-grow-1 festival-dropdown ${festivalThemeForm.errors.enableCelebrationEffect
                  ? 'border-danger'
                  : ''
                  }`}
                data-testid='festival-dropdown'
                value={festivalThemeForm.values.activeCelebrationTemplate}
                onChange={(event) => {
                  const selectedTemplate = event.target.value;
                  festivalThemeForm.setFieldValue(
                    'activeCelebrationTemplate',
                    selectedTemplate ?? '',
                    true
                  );
                  if (selectedTemplate) {
                    festivalThemeForm.setFieldValue(
                      'enableCelebrationEffect',
                      true,
                      true
                    );
                    showFestivalThemePreview(false);
                  } else {
                    festivalThemeForm.setFieldValue(
                      'enableCelebrationEffect',
                      false,
                      true
                    );
                    festivalThemeForm.setFieldValue(
                      'showCelebrationTemplateOn',
                      [],
                      true
                    );
                  }
                }}
                options={filteredThemeTemplate}
                optionLabel='name'
                optionValue='key'
                placeholder={useTranslation('Select a festival', 'pascal')}
              />
              {festivalThemeForm.values.activeCelebrationTemplate && (
                <Button
                  label={
                    preiveiwing
                      ? !particleEngineLoaded
                        ? loadingLabel
                        : stopLabel
                      : previewLabel
                  }
                  iconPos='right'
                  type='button'
                  icon={
                    preiveiwing
                      ? !particleEngineLoaded
                        ? 'pi pi-spin pi-spinner'
                        : 'pi pi-stop-circle'
                      : 'pi pi-play'
                  }
                  className='preview-icon flex-grow-0 btn-secondary'
                  onClick={() => {
                    showFestivalThemePreview(!preiveiwing);
                  }}
                  disabled={preiveiwing && !particleEngineLoaded}
                />
              )}
            </div>
          </div>

          <div className='col-md-8 col-lg-7 mb-4'>
            {festivalThemeForm.values.enableCelebrationEffect && (
              <>
                <label className='form-label'>
                  <JTranslation typeCase='pascal' text={'Display Area'} />
                </label>
                <div className='row'>
                  <div className='col-md-6 col-lg-6 mt-2'>
                    <div
                      className={`festival-page-selection  ${festivalThemeForm.errors.showCelebrationTemplateOn
                        ? ' border border-danger'
                        : ''
                        }`}
                    >
                      <div className='d-flex'>
                        <div className='flex-grow-0'>
                          <Button icon='pi pi-home' />
                        </div>
                        <div
                          className='flex-grow-1 mr-2 ps-2 d-flex align-items-center justify-content-between text-nowrap overflow-hidden'
                        >
                          <span className='menu-text'>
                            <JTranslation
                              typeCase='pascal'
                              text={'Welcome page'}
                            />
                          </span>
                          <span className='form-switch'>
                            <input
                              className='form-check-input form-check-input-custom'
                              type='checkbox'
                              autoComplete='off'
                              checked={festivalThemeForm.values.showCelebrationTemplateOn.includes(
                                FESTIVAL_THEME_PAGE_CONFIG.WELCOME_PAGE
                              )}
                              data-testid='welcome-page-check'
                              onChange={(event) => {
                                let celebrationTemplates = [
                                  ...festivalThemeForm.values
                                    .showCelebrationTemplateOn,
                                ];
                                if (event.target.checked) {
                                  celebrationTemplates.push(
                                    FESTIVAL_THEME_PAGE_CONFIG.WELCOME_PAGE
                                  );
                                } else {
                                  celebrationTemplates =
                                    celebrationTemplates.filter(
                                      (page) =>
                                        page !==
                                        FESTIVAL_THEME_PAGE_CONFIG.WELCOME_PAGE
                                    );
                                }
                                festivalThemeForm.setFieldValue(
                                  'showCelebrationTemplateOn',
                                  celebrationTemplates,
                                  true
                                );
                              }}
                              id='flexSwitchCheckDefault'
                              style={{ width: '3em', height: '1.5em' }}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6 col-lg-6 mt-2'>
                    <div
                      className={`festival-page-selection ${festivalThemeForm.errors.showCelebrationTemplateOn
                        ? ' border border-danger'
                        : ''
                        }`}
                    >
                      <div className='d-flex'>
                        <div className='flex-grow-0'>
                          <Button icon='pi pi-desktop' />
                        </div>
                        <div
                          className='flex-grow-1 ps-2 pe-2 d-flex align-items-center justify-content-between text-nowrap overflow-hidden '
                        >
                          <span className='menu-text'>
                            <JTranslation
                              typeCase='pascal'
                              text={'All Other pages'}
                            />
                          </span>
                          <span className='form-switch'>
                            <input
                              className='form-check-input form-check-input-custom'
                              type='checkbox'
                              autoComplete='off'
                              checked={festivalThemeForm.values.showCelebrationTemplateOn.includes(
                                FESTIVAL_THEME_PAGE_CONFIG.ALL_OTHER_PAGES
                              )}
                              data-testid='all-other-page-check'
                              onChange={(event) => {
                                let celebrationTemplates = [
                                  ...festivalThemeForm.values
                                    .showCelebrationTemplateOn,
                                ];
                                if (event.target.checked) {
                                  celebrationTemplates.push(
                                    FESTIVAL_THEME_PAGE_CONFIG.ALL_OTHER_PAGES
                                  );
                                } else {
                                  celebrationTemplates =
                                    celebrationTemplates.filter(
                                      (page) =>
                                        page !==
                                        FESTIVAL_THEME_PAGE_CONFIG.ALL_OTHER_PAGES
                                    );
                                }
                                festivalThemeForm.setFieldValue(
                                  'showCelebrationTemplateOn',
                                  celebrationTemplates,
                                  true
                                );
                              }}
                              id='flexSwitchCheckDefault'
                              style={{ width: '3em', height: '1.5em' }}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className='mt-5 w-100 d-flex justify-content-between'>
              <div className='d-flex align-items-center'>
                {festivalThemeForm.values.enableCelebrationEffect && (
                  <button
                    className='btn btn-danger ms-2 d-flex align-items-center'
                    type='button'
                    data-testid='preview-button'
                    onClick={() => {
                      setShowWarning(true);
                    }}
                  >
                    <JTranslation typeCase='pascal' text={'Remove'} />
                  </button>
                )}
              </div>
              {festivalThemeForm.dirty && (
                <div className='d-flex align-items-center'>
                  <button
                    className='btn btn-custom-primary-outline ms-2'
                    type='button'
                    data-testid='clear-button'
                    disabled={preiveiwing || !festivalThemeForm.dirty}
                    onClick={() => {
                      const brandingInfo =
                        queryClient.getQueryData<BrandingData>(BRANDING_INFO);
                      const celebrationTemplates =
                        brandingInfo?.showCelebrationTemplateOn ?? [];
                      festivalThemeForm.setValues(
                        {
                          enableCelebrationEffect:
                            brandingInfo?.enableCelebrationEffect ?? false,
                          activeCelebrationTemplate:
                            brandingInfo?.activeCelebrationTemplate ?? '',
                          showCelebrationTemplateOn: celebrationTemplates,
                        },
                        true
                      );
                    }}
                  >
                    <JTranslation typeCase='pascal' text={RESET} />
                  </button>

                  <button
                    className='btn btn-custom-primary ms-2'
                    type='submit'
                    data-testid='save-button'
                  // disabled={}
                  >
                    <JTranslation typeCase='pascal' text={'Update changes'} />
                  </button>
                </div>
              )}
            </div>
          </div>
        </form>

        <div className='col-md-12 mb-3 festival-form'>
          <div className='primary-title-bg d-flex justify-content-between mb-4'>
            <h5 className='d-flex align-items-center mb-0'>
              <JTranslation typeCase='pascal' text={'Color Theme'} />
            </h5>
          </div>
          <div className='col-12'>
            <div className="d-flex flex-row">
              <ColorPicker
                format='hex'
                value={tempColorTheme}
                onChange={(event) => setTempColorTheme(`#${event.target.value}`)}
                inline
              />
              <span className='ms-3 d-flex flex-column justify-content-between'>
                <InputText
                  value={colorTheme}
                  onChange={(event) => {
                    const isHexColor = (hex: string) => {
                      const hexPattern = /^#?([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/;
                      return hexPattern.test(hex);
                    }
                    if (isHexColor(event.target.value)) {
                      setTempColorTheme(event.target.value)
                    }
                  }}
                />
                <div className='d-flex flex-row justify-content-between'>
                  <button
                    type='button'
                    className='btn btn-secondary'
                    data-testid='save'
                    onClick={() => {
                      setColorTheme(initialColorTheme);
                    }}
                  >
                    {'Reset'}
                  </button>
                  <button
                    type='button'
                    className='btn btn-custom-primary'
                    data-testid='save'
                    onClick={() => {
                      setColorTheme(tempColorTheme);
                    }}
                  >
                    {SAVE}
                  </button>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default BrandingConfig;
