// import { QrReader } from 'react-qr-reader';
import { QrScanner } from 'react-qrcode-scanner'

import { AlertVariant } from '../../constants/constants';
import { QR_CODE_SCANNER_CAMERA_ERROR } from '../../constants/strings';
import "./styles/coupons.scss";
import { JTranslation } from '../../helpers/jTranslate';

type Props = {
  setScanResult: (result: string) => void
  showToastMessage: (message: string, variant: string) => void
  setScanner: React.Dispatch<React.SetStateAction<boolean>>
  hideBackButton?: boolean
  showScanner: boolean
}

function QRScanner({
  setScanResult,
  showToastMessage,
  setScanner,
  hideBackButton = false,
  showScanner=false,
}: Props) {
  return (
    <>
      {!hideBackButton && (
        <div className='card-header flex-grow-0'>
          <div
            className='d-flex  align-items-center back-icon'
            onClick={() => setScanner(false)}
          >
            <div className='flex-grow-0 me-2 d-flex  align-items-center justify-content-center'>
              <i className='ri-arrow-left-line'></i>
            </div>
            <div className='flex-grow-1'>
              <h4 className='mb-0 title'><JTranslation typeCase="pascal" text={"Back"} /></h4>
            </div>
          </div>
        </div>
      )}
      <div className='row'>
        <div
          className='qr-cam-wrapper'
          style={{ minWidth: 'auto', margin: 'auto' }}
        >
          {showScanner? <QrScanner
            onScan={(result: any) => {

              if (!!result) {
                setScanResult(result)
                // setScanResult(result.getText())
                // setScanner(false)
              }
            }}
            onError={(error: any) => {
              if (!!error && !!error.message) {
                if (error.name === 'NotAllowedError') {
                  showToastMessage(
                    QR_CODE_SCANNER_CAMERA_ERROR,
                    AlertVariant.ERROR
                  )
                } else {
                  showToastMessage(error.message, AlertVariant.ERROR)
                }
                setScanner(false)
              }
            }}
            facingMode='environment'
            flipHorizontally={true}
          /> : null }
        </div>
      </div>
      <div className='qr-description'>
        <i className='ri-information-line'></i><JTranslation typeCase="none" text={'Please Scan QR Code'} />
      </div>
    </>
  )
}
export default QRScanner;