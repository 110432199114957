import { useEffect, useState } from "react";
import { Wheel } from "../../components/game/guest/Wheel";
import withPublicHeader from "../../hoc/withPublicHeader";
import "./style/game.scss";
import { useQueryClient } from "react-query";
import { GUEST_PRIZES, GUEST_WINNING_PRIZE } from "../../constants/queryKeys";
import { confirmWin, getGuestPrizesList, getPrize, getPrizeNumber } from "../../helpers/gameHelper";
import useQueryHook from "../../hooks/useQueryHook";
import Loader from "../../components/loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";
import ToastAlert from "../../components/alert/ToastAlert";
import { AlertVariant, SPECIAL_MENU_CODE } from "../../constants/constants";
import WinnerModal from "../../components/game/guest/WinnerModal";
import useMutationHook from "../../hooks/useMutationHook";
import PrizesListModal from "../../components/game/guest/PrizesListModal";
import { PRIZE_LIST } from "../../constants/strings";
import { JTranslation } from '../../helpers/jTranslate'
import { useTrackEvent } from "../../hooks/useTrackEvent";
import { getDomainFromURL } from "../../helpers/utils";

export type WheelSegments = {
    id: string,
    option: string,
    style: { backgroundColor: string, textColor: string }
}

type SurveyResponse = {
    surveyResponseId: string,
    message: string,
    variant: string
}

function GuestSpinWheel() {
    const queryClient = useQueryClient();
    const { trackEvent } = useTrackEvent();

    const menuCode = localStorage.getItem(SPECIAL_MENU_CODE)
    const [spin, setSpin] = useState(false);
    const [prizesList, setPrizesList] = useState<WheelSegments[]>([]);
    const [winningPrize, setWinningPrize] = useState("");
    const [betterLuckId, setBetterLuckId] = useState("");
    const location = useLocation();
    const navigate = useNavigate();
    const [showToast, setToast] = useState(false); // Toast alert state
    const [toastMessage, setToastMessage] = useState(""); // Toast message
    const [toastVariant, setToastVariant] = useState(""); // Toast Variant
    const surveyObject = location.state as SurveyResponse;
    const [showWinnerModal, setWinner] = useState(false); // winner popup
    const [showPrizeList, setPrizesListModal] = useState(false); // prizes list modal
    const gameWinMutation = useMutationHook(queryClient, false);

    const redirectToMenu = () => {
      if (menuCode) {
        navigate(`${routes.special_menu}/${menuCode}`)
      } else {
        navigate(routes.menu_view, { replace: true })
      }
	}

    useEffect(() => {
        // navigate to menu page if no survey id not present
        if (surveyObject) {
            setToastVariant(AlertVariant.SUCCESS);
            setToastMessage(surveyObject.message);
            setToast(true);
            window.history.replaceState({}, document.title);
        } else {
            redirectToMenu();
        }
    }, []); // eslint-disable-line

    // colors for slices
    const segColors = [
        "#BABE00", "#91BD4A", "#00A056", "#01A8DD", "#EF8031", "#FFD85E", "#4F4B4C", "#21CFB0",
        "#DD6B01", "#B28D0F", "#D18E06", "#FEAA15", "#C06243", "#FF5631", "#455A64", "#00A056"
    ];

    // fetch prizes list
    useQueryHook(GUEST_PRIZES, () => getGuestPrizesList(queryClient),
        (res) => { // on success
            let list = res.data.data.list as { [key: string]: string }[];
            let betterLuckData = res.data.data.betterLuckData as { [key: string]: string };
            let betterLuckCount: number = 0;
            let indexIncrement: number = 1;
            let totalItems: number = list.length;
            if (betterLuckData) {
                setBetterLuckId(betterLuckData.id);
                betterLuckCount = parseInt(betterLuckData.displayOnGameCount);
                totalItems = totalItems + betterLuckCount;
                indexIncrement = Math.round(totalItems / betterLuckCount);
            }

            // add better luck items into list
            let increment: number = indexIncrement;
            for (let i = 0; i < betterLuckCount; i++) {
                list.splice(increment - 1, 0, betterLuckData);
                increment = increment + indexIncrement;
            }

            const getStyle = (itemId: string, index: number) => {
                if (itemId === betterLuckData.id) {
                    return {
                        backgroundColor: 'black',
                        textColor: "white",
                    }
                } else {
                    return {
                        backgroundColor: segColors[index],
                        textColor: 'black',
                    }
                }
            }

            // populate list
            const prizes = list.map((item, index) => {
                return {
                    id: item.id,
                    option: item.prizeWinning,
                    style: getStyle(item.id, index)
                }
            })
            setPrizesList(prizes);
        });

    // fetch prize
    useQueryHook(GUEST_WINNING_PRIZE, () => getPrize(queryClient, surveyObject.surveyResponseId),
        (res) => {
            setWinningPrize(res.data.data.spinData.id);
        });

    if (prizesList.length === 0 || winningPrize.length === 0) {
        return <Loader />
    }

    return (
      <>
        <ToastAlert
          data-testid='toast'
          show={showToast}
          onClose={setToast}
          message={toastMessage}
          variant={toastVariant}
        />
        <WinnerModal
          show={showWinnerModal}
          handleClose={() => redirectToMenu()}
          prize={prizesList[getPrizeNumber(prizesList, winningPrize)].option}
          isWinner={winningPrize !== betterLuckId}
        />
        <div className='flex-grow-1'>
          <div className='p-0 h-100'>
            <div data-testid='' className='gameBg gameouter h-100'>
              <div className='container d-flex h-100 justify-content-centerr align-items-center h-100 flex-column'>
                <div className='col-md-12 col-lg-7 col-xl-7 text-end mb-2  mt-3'>
                  <button
                    className={'spin-button btn  btn-sm   rounded-pill'}
                    onClick={() => setPrizesListModal(true)}
                    data-testid="prize-list"
                  >
                    <JTranslation text={PRIZE_LIST} />
                  </button>
                </div>
                <div className='row justify-content-center align-items-center mt-3 h-100'>
                  <div className='col-md-12 col-lg-7 col-xl-7 d-flex align-items-center justify-content-center flex-grow-1'>
                    <Wheel
                      mustStartSpinning={spin}
                      prizeNumber={getPrizeNumber(prizesList, winningPrize)}
                      innerBorderWidth={0}
                      innerRadius={0}
                      radiusLineWidth={0}
                      data={prizesList}
                      onStopSpinning={() => {
                        if (winningPrize !== betterLuckId) {
                          confirmWin(
                            queryClient,
                            gameWinMutation,
                            surveyObject.surveyResponseId,
                            winningPrize
                          )
                        }
                        setSpin(false)
                        setWinner(true)
                      }}
                    />
                  </div>

                  <div className='col-md-12 text-center mt-3 mb-2'>
                    <button
                      className={
                        'spin-button btn  btn-lg  btn-custom-primary rounded-pill'
                      }
                      data-testid="spin-wheel"
                      onClick={() => {
                        setSpin(true)
                      // Google Analytics
                      trackEvent({
                        eventName: "start_game",
                        value: {
                            game_name: "spin_the_wheel",
                            page_location: `${getDomainFromURL()}/${routes.guest_game}`,

                        },
                      });

                      trackEvent({
                        eventName: "finish_game",
                        value: {
                            game_name: "spin_the_wheel",
                            game_outcome: prizesList[getPrizeNumber(prizesList, winningPrize)].option,
                            page_location: `${getDomainFromURL()}/${routes.guest_game}`,

                        },
                      });

                      }
                      }
                    >
                      <JTranslation text='SPIN THE WHEEL' />
                    </button>
                  </div>
                  <PrizesListModal
                    prizesList={prizesList.filter(
                      (prize) => prize.id !== betterLuckId
                    )}
                    show={showPrizeList}
                    handleClose={() => setPrizesListModal(false)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
}

export default withPublicHeader(GuestSpinWheel);