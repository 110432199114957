import { useContext, useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'
import { CANCEL, DURATION, APPLY, UPDATE, FROM, TO, REASON, HOURS, DAYS } from '../../constants/strings'
import { checkPermission, calculateDuration, getStaffId, toPascalCase } from '../../helpers/utils'
import { CommonCtx } from '../../context/CommonCtxProvider'
import { ErrorMessage, Field, Form, Formik, FieldProps } from 'formik'
import { getUsersList } from '../../helpers/manageUsersHelper'
import { PageMode, PermissionKeys, AlertVariant, SubPermissionKeys } from '../../constants/constants'
import { parseTimeForTimePicker } from '../../helpers/newsAndEventsHelper'
import { routes } from '../../constants/routes'
import { ScheduleManagementCtx } from '../../context/ScheduleManagementCtxProvider'
import { TimePicker } from 'antd'
import { useLocation } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { USERS_LIST, USERS_TIME_OFF_LIST } from '../../constants/queryKeys'
import DatePickerComponent from '../date_picker/DatePickerComponent'
import dayjs from 'dayjs'
import useMutationHook from '../../hooks/useMutationHook'
import useQueryHook from '../../hooks/useQueryHook'
import {
    addTimeOffRequest,
    fullDayValidationSchema,
    initialValuesForTimeOff,
    timeOffValidationSchema,
    updateTimeOffRequest,
} from '../../helpers/timeOffHelper'

import {
    AddTimeOff,
    AddTimeOffResponse,
    TimeOffRequestFormType,
    ToastMessageProps,
    UpdateTimeOff,
    Users,
} from '../../constants/staticTypes'
import { JTranslation, TranslationContext, jTranslationText } from '../../helpers/jTranslate'

type Props = {
    onClose: Function
    pageMode: PageMode.ADD | PageMode.EDIT
    setToastMessage: React.Dispatch<React.SetStateAction<ToastMessageProps>>
}

const TimeOffRequestForm = ({ onClose, pageMode, setToastMessage }: Props) => {
    const queryClient = useQueryClient()
    const timeOffMutation = useMutationHook(queryClient, true)
    const submitButtonName = pageMode === PageMode.EDIT ? UPDATE : APPLY
    const location = useLocation()
    const currentPath = location.pathname
    // CONTEXT VARIABLE
    const { setShowSideBar } = useContext(CommonCtx)
    const { timeOffData, setTimeOffData } = useContext(ScheduleManagementCtx)
    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext
    // STATE VARIABLES
    const [usersList, setUsersList] = useState<Users[]>([])
    const [userDropDownOptions, setDropDownOptions] = useState<any[]>([])
    const [translationText, setTranslatedText] = useState<{
        startTime: string,
        endTime: string,
        loading: string,
        select: string,
        self: string,
      }>({ 
        startTime: 'Start Time',
        endTime: 'End Time',
        loading: 'Loading...',
        select: 'Select',
        self: 'Self',
    });
    // Get the current date
    const currentDate = new Date()
    // Calculate the maximum date, 5 years from now
    const maxDate = new Date()
    maxDate.setFullYear(currentDate.getFullYear() + 5)
    const staffId = getStaffId()

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: 'Start Time', typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: 'End Time', typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: 'Loading...', typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: 'Select', typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: 'Self', typeCase: 'pascal', translationContext }),
                ])
                
                setTranslatedText({
                    startTime: translations[0] ?? 'Start Time',
                    endTime: translations[1] ?? 'End Time',
                    loading: translations[2] ?? 'Loading...',
                    select: translations[3] ?? 'Select',
                    self: translations[4] ?? 'Self',
                })
            } catch {
                setTranslatedText({
                    startTime: 'Start Time',
                    endTime: 'End Time',
                    loading: 'Loading...',
                    select: 'Select',
                    self: 'Self',
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    useEffect(() => {
        const data = queryClient.getQueryData(USERS_LIST) as AxiosResponse<any, any>
        onUserListFetchSuccess(data)

        if(currentPath === routes.time_off && pageMode === PageMode.ADD) {
            setTimeOffData({...initialValuesForTimeOff, staffId })
        }

        return () => {
            setTimeOffData(initialValuesForTimeOff)
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        let userDropDownOptions: any = []

        usersList?.forEach((item) => {
            if (item.isActive) {
                userDropDownOptions.push(
                    <option key={item.id} value={item.id}>
                        {toPascalCase(item?.preferredName)}
                    </option>
                )
            }
        })

        setDropDownOptions(userDropDownOptions)
    }, [usersList])

    useEffect(() => {
        calculateDuration({
            type: timeOffData.type,
            endTime: timeOffData.endTime,
            startTime: timeOffData.startTime,
            fromDate: timeOffData.fromDate,
            toDate: timeOffData.toDate,
        })
    }, [timeOffData])

    const getValidationSchema = () => {
        if (timeOffData.type === 'full-day') {
            return fullDayValidationSchema
        } else {
            return timeOffValidationSchema
        }
    }

    // users list fetch success
    const onUserListFetchSuccess = (res: AxiosResponse<any, any>) => {
        const responseData = res?.data?.data.lists as Users[]
        setUsersList(responseData)
    }

    // fetch users list
    const userData = useQueryHook(USERS_LIST, () => getUsersList('', 500, ''), onUserListFetchSuccess)

    const validate = (values: TimeOffRequestFormType) => {
        setTimeOffData((prevValues) => {
            return {
                ...prevValues,
                type: values.type,
                fromDate: new Date(values.fromDate),
                toDate: new Date(values.toDate),
                startTime: values.startTime,
                endTime: values.endTime,
                duration: calculateDuration({
                    type: values.type,
                    endTime: values.endTime,
                    startTime: values.startTime,
                    fromDate: values.fromDate,
                    toDate: values.toDate,
                }),
                reason: values.reason,
                note: values.note,
                staffId: values.staffId,
            }
        })
    }

    const handleSubmit = (values: TimeOffRequestFormType) => {
        const { endTime, fromDate, note, reason, startTime, toDate, type, staffId } = values

        const commonParams = {
            startDate: dayjs(fromDate).format('YYYY-MM-DD'),
            endDate: type !== 'full-day' ? dayjs(fromDate).format('YYYY-MM-DD') : dayjs(toDate).format('YYYY-MM-DD'),
            startTime: type !== 'full-day' && dayjs(startTime).isValid() ? dayjs(startTime).format('HH:mm') : null,
            endTime: type !== 'full-day' && dayjs(endTime).isValid() ? dayjs(endTime).format('HH:mm') : null,
            note,
            reason,
            staffId,
            type,
        }

        if (pageMode === PageMode.ADD) {
            const addParams = commonParams
            addTimeOffRequest(timeOffMutation, addParams as AddTimeOff, onSuccess, onError)
        } else {
            const updateParams = {
                ...commonParams,
                id: timeOffData.id!,
            }
            
            updateTimeOffRequest(timeOffMutation, updateParams as UpdateTimeOff, onSuccess, onError)
        }
    }

    const onSuccess = (res: AddTimeOffResponse) => {
        queryClient.fetchQuery(USERS_TIME_OFF_LIST)
        setShowSideBar(false)
        setToastMessage({ message: res.message, show: true, variant: AlertVariant.SUCCESS })
    }

    const onError = (message: string) => {
        setToastMessage({ message: message, show: true, variant: AlertVariant.ERROR })
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={timeOffData}
            onSubmit={handleSubmit}
            validate={validate}
            validateOnChange={true}
            validationSchema={getValidationSchema}
        >
            {(
                { errors, touched } // nosonar
            ) => (
                <Form>
                    <div className="mb-3">
                        <label className="form-label d-flex">
                            <JTranslation typeCase="pascal" text={DURATION} />
                            <span className="mandatory ">*</span>
                        </label>
                        <Field
                            style={errors.type && touched.type ? { border: '1px solid red' } : undefined}
                            name="type"
                            as="select"
                            className="form-control"
                            type="text"
                            value={timeOffData.type}
                            autoComplete="off"
                            maxLength="250"
                        >
                            {/* Dropdown options */}
                            <option key="full-day" value="full-day">
                                Full Day
                            </option>
                            <option key="time-off" value="time-off">
                                Hours
                            </option>
                        </Field>

                        <ErrorMessage className="formik-error"  name="type" component="div" render={(error) => <span className='formik-error'><JTranslation typeCase="pascal" text={error} /></span>} />
                    </div>
                    {
                        (
                            checkPermission(queryClient, PermissionKeys.MANAGE_TIME_OFF, SubPermissionKeys.ADD) ||
                            checkPermission(queryClient, PermissionKeys.MANAGE_TIME_OFF, SubPermissionKeys.EDIT)
                        ) && (
                        <div className="mb-3">
                            <label className="form-label d-flex">
                                <JTranslation typeCase="pascal" text={"User"} />
                                <span className="mandatory ">*</span>
                            </label>
                            <Field
                                style={errors.staffId && touched.staffId ? { border: '1px solid red' } : undefined}
                                name="staffId"
                                as="select"
                                className="form-control"
                                type="text"
                                autoComplete="off"
                                value={timeOffData.staffId}
                                maxLength="250"
                                disabled={userData.isLoading}
                            >
                                {/* Dropdown options */}
                                {currentPath === routes.time_off_requests ? (
                                    <>
                                        <option key="select" value="" selected>
                                            {userData.isLoading ? translationText.loading : translationText.select}
                                        </option>
                                        {/* <option key="self" value={staffId} disabled={staffId === 'superadmin'} >
                                            Self
                                        </option> */}
                                        {userDropDownOptions}
                                    </>
                                ) : (
                                    <option key="self" value={staffId} disabled={staffId === 'superadmin'} selected>
                                        {translationText.self}
                                    </option>
                                )}
                            </Field>
                            <ErrorMessage className="formik-error"  name="staffId" component="div" render={(error) => <span className='formik-error'><JTranslation typeCase="pascal" text={error} /></span>} />
                        </div>
                    )}
                    <div className="row">
                        <div className="mb-3 col-md-6">
                            <div className="help-small">
                                <label className="form-label d-flex">
                                    <JTranslation typeCase="pascal" text={"From Date"} />
                                    <span className="mandatory ">*</span>
                                </label>
                            </div>
                            <Field name="fromDate">
                                {({ field, form }: FieldProps) => (
                                    <DatePickerComponent
                                        isClearable={false}
                                        selectedDate={field.value}
                                        minDate={new Date()}
                                        maxDate={maxDate}
                                        numberOfUpcomingYears={1}
                                        customInput={
                                            <input
                                                style={
                                                    errors.fromDate && touched.fromDate
                                                        ? { border: '1px solid red' }
                                                        : undefined
                                                }
                                                className="form-control"
                                                type="text"
                                                maxLength={10}
                                                autoComplete="off"
                                            />
                                        }
                                        setDate={(value) => form.setFieldValue(field.name, value)}
                                    />
                                )}
                            </Field>
                            <ErrorMessage className="formik-error"  name="fromDate" component="div" render={(error) => <span className='formik-error'><JTranslation typeCase="pascal" text={error} /></span>} />
                        </div>
                        {timeOffData.type !== 'time-off' && (
                            <div className="mb-3 col-md-6">
                                <div className="help-small">
                                    <label className="form-label d-flex">
                                        <JTranslation typeCase="pascal" text={"To Date"} />
                                        <span className="mandatory ">*</span>
                                    </label>
                                </div>
                                <Field name="toDate">
                                    {({ field, form }: FieldProps) => (
                                        <DatePickerComponent
                                            isClearable={false}
                                            selectedDate={field.value}
                                            minDate={new Date()}
                                            maxDate={maxDate}
                                            numberOfUpcomingYears={1}
                                            customInput={
                                                <input
                                                    style={
                                                        errors.toDate && touched.toDate
                                                            ? { border: '1px solid red' }
                                                            : undefined
                                                    }
                                                    className="form-control"
                                                    type="text"
                                                    maxLength={10}
                                                    autoComplete="off"
                                                />
                                            }
                                            setDate={(value) => form.setFieldValue(field.name, value)}
                                        />
                                    )}
                                </Field>
                                <ErrorMessage className="formik-error"  name="toDate" component="div" render={(error) => <span className='formik-error'><JTranslation typeCase="pascal" text={error} /></span>} />
                            </div>
                        )}
                    </div>
                    {timeOffData.type === 'time-off' && (
                        <div className="row mb-3">
                            <div className="mb-3 col-md-6">
                                <div className="help-small">
                                    <label className="form-label d-flex">
                                        <JTranslation typeCase="pascal" text={FROM} />
                                        <span className="mandatory ">*</span>
                                    </label>
                                </div>
                                <Field name="startTime">
                                    {({ field, form }: FieldProps) => (
                                        <TimePicker
                                            className="form-control"
                                            popupClassName="custom-antd-time-picker"
                                            size="middle"
                                            use12Hours
                                            clearIcon={false}
                                            format="hh:mm A"
                                            minuteStep={30}
                                            placeholder={translationText.startTime}
                                            // Bind the value and onChange handler
                                            value={parseTimeForTimePicker({ formattedTime: field.value })}
                                            onChange={(value) => form.setFieldValue(field.name, value)}
                                            style={
                                                errors.startTime && touched.startTime
                                                    ? { border: '1px solid red' }
                                                    : undefined
                                            }
                                            inputReadOnly={true}
                                        />
                                    )}
                                </Field>
                                <ErrorMessage className="formik-error"  name="startTime" component="div" render={(error) => <span className='formik-error'><JTranslation typeCase="pascal" text={error} /></span>} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <div className="help-small">
                                    <label className="form-label d-flex">
                                        <JTranslation typeCase="pascal" text={TO} />
                                        <span className="mandatory ">*</span>
                                    </label>
                                </div>
                                <Field name="endTime">
                                    {({ field, form }: FieldProps) => (
                                        <TimePicker
                                            className="form-control"
                                            popupClassName="custom-antd-time-picker"
                                            size="middle"
                                            use12Hours
                                            clearIcon={false}
                                            format="hh:mm A"
                                            minuteStep={30}
                                            placeholder={translationText.endTime}
                                            // Bind the value and onChange handler
                                            value={parseTimeForTimePicker({ formattedTime: field.value })}
                                            onChange={(value) => form.setFieldValue(field.name, value)}
                                            style={
                                                errors.endTime && touched.endTime
                                                    ? { border: '1px solid red' }
                                                    : undefined
                                            }
                                            inputReadOnly={true}
                                        />
                                    )}
                                </Field>
                                <ErrorMessage className="formik-error"  name="endTime" component="div" render={(error) => <span className='formik-error'><JTranslation typeCase="pascal" text={error} /></span>} />
                            </div>
                        </div>
                    )}
                    <div className="mb-3">
                        <label className="form-label d-flex">{timeOffData.type === 'time-off' 
                            ? <JTranslation typeCase="pascal" text={HOURS} /> 
                            : <JTranslation typeCase="pascal" text={DAYS} />
                        }</label>
                        <Field
                            style={errors.duration && touched.duration ? { border: '1px solid red' } : undefined}
                            name="duration"
                            className="form-control"
                            type="text"
                            autoComplete="off"
                            maxLength="250"
                            disabled
                        />
                        <ErrorMessage className="formik-error"  name="duration" component="div" render={(error) => <span className='formik-error'><JTranslation typeCase="pascal" text={error} /></span>} />
                    </div>
                    <div className="mb-3">
                        <label className="form-label d-flex">
                            <JTranslation typeCase="pascal" text={REASON} />
                            <span className="mandatory ">*</span>
                        </label>
                        <Field
                            style={errors.reason && touched.reason ? { border: '1px solid red' } : undefined}
                            name="reason"
                            as="textarea"
                            className="form-control reason"
                            type="text"
                            autoComplete="off"
                            maxLength="1000"
                        />
                        <ErrorMessage className="formik-error"  name="reason" component="div" render={(error) => <span className='formik-error'><JTranslation typeCase="pascal" text={error} /></span>} />
                    </div>

                    {currentPath === routes.time_off_requests &&
                        checkPermission(queryClient, PermissionKeys.MANAGE_TIME_OFF) && (
                            <div className="mb-3">
                                <label className="form-label d-flex"><JTranslation typeCase="pascal" text={"Note"} /></label>
                                <Field
                                    style={errors.note && touched.note ? { border: '1px solid red' } : undefined}
                                    name="note"
                                    as="textarea"
                                    className="form-control reason"
                                    type="text"
                                    autoComplete="off"
                                    maxLength="1000"
                                />
                                <ErrorMessage className="formik-error"  name="note" component="div" render={(error) => <span className='formik-error'><JTranslation typeCase="pascal" text={error} /></span>} />
                            </div>
                        )}

                    {/* action button */}
                    <div className="save-btn-section shadow save-btn-absolute">
                        <button className="btn btn-custom-primary-outline" type="reset" onClick={() => onClose()}>
                            <JTranslation typeCase="pascal" text={CANCEL} />
                        </button>

                        <button className="btn btn-custom-primary" type="submit" disabled={userData.isLoading}>
                            <JTranslation typeCase="pascal" text={submitButtonName} />
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default TimeOffRequestForm
