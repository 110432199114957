import { useSortable } from "@dnd-kit/sortable";
import { MATRIX_COLUMN_DEFAULT_CELL_TYPE, MATRIX_COLUMN_DEFAULT_NAME, SURVEY_QUESTION_TYPES } from "../../../constants/constants";
import { ADD_ROW, ANSWER_TYPE, ENABLE, MANDATORY, OPTION, QUESTION } from "../../../constants/strings";
import { CSS } from '@dnd-kit/utilities';
import { SurveyCategories, SurveyQuestion } from "../../../constants/staticTypes";
import { useState } from "react";
import { JTranslation } from "../../../helpers/jTranslate";


type Props = {
    catIndex: number,
    deleteType: string,
    category: SurveyCategories,
    categories: SurveyCategories[],
    setCategories: React.Dispatch<React.SetStateAction<SurveyCategories[]>>,
    setDeletetype: React.Dispatch<React.SetStateAction<string>>,
    setDeleteCatIndex: React.Dispatch<React.SetStateAction<number>>,
    setDeleteQuestionIndex: React.Dispatch<React.SetStateAction<number>>,
    setWarning: React.Dispatch<React.SetStateAction<boolean>>,
    categoryQuestion: SurveyQuestion,
    qstnIndex: number
}

function CategoryQuestions({ category, categories, setCategories, catIndex, setDeletetype, deleteType,
    setDeleteCatIndex, setWarning, setDeleteQuestionIndex, qstnIndex, categoryQuestion }: Props) {
    const [blurredQuestions, setOnBlur] = useState<string[]>([]);
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition } = useSortable({ id: categoryQuestion.id as string });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        touchAction: "none"
    };

    // survey questions change / update
    const onSurveyQuestionChange = (key: string, value: string | boolean) => {
        category.surveyQuestions.splice(qstnIndex, 1, {
            ...category.surveyQuestions[qstnIndex],
            [key]: value
        });
        if (key === "surveyQuestionType") {
            if (value === "matrixTable") {
                category.surveyQuestions.splice(qstnIndex, 1, {
                    ...category.surveyQuestions[qstnIndex],
                    matrixColumns: [
                        {
                            name: MATRIX_COLUMN_DEFAULT_NAME,
                            cellType: MATRIX_COLUMN_DEFAULT_CELL_TYPE,
                            isRequired: false,
                            choices: ["", "", "", "", ""] // fixed size 5
                        }
                    ],
                    matrixRows: [""]
                });
            } else {
                delete category.surveyQuestions[qstnIndex].matrixColumns;
                delete category.surveyQuestions[qstnIndex].matrixRows;
            }
        }
        updateCategoriesState();
    }

    // update categories state
    const updateCategoriesState = () => {
        categories.splice(catIndex, 1, {
            ...category
        });
        setCategories([...categories]);
    }

    return <div className="col-md-12 mb-2" key={categoryQuestion.id} style={style} >
        <div className="survey-question-outer rounded-1">
            <div className="row">
                <div className="col-1 d-flex align-items-center justify-content-center">
                    <div className="drag-ic" ref={setNodeRef} {...attributes} {...listeners}>
                        <i className="ri-grid-fill"></i>
                    </div>
                </div>

                <div className="col-10">
                    <div className="mb-3">
                        <label className="form-label"><JTranslation typeCase="pascal" text={QUESTION} /> </label><span className="mandatory "> *</span>
                        <input type="text" className="form-control" autoComplete="off"
                            id={"cat#" + catIndex + "#question#" + qstnIndex}
                            data-testid={"cat#" + catIndex + "#question#" + qstnIndex}
                            maxLength={200}
                            value={categoryQuestion.surveyQuestionName}
                            autoFocus={categoryQuestion.surveyQuestionName === ""}
                            onBlur={() => {
                                if (categoryQuestion.id && !blurredQuestions.includes(categoryQuestion.id)) {
                                    setOnBlur([...blurredQuestions, categoryQuestion.id as string]);
                                }
                            }}
                            onChange={(e) => {
                                onSurveyQuestionChange("surveyQuestionName", e.target.value);
                            }} />
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="mb-1">
                                <label className="form-label mb-0"><JTranslation typeCase="pascal" text={ANSWER_TYPE} /> </label>
                                <select className="form-select" aria-label="Default select example"
                                    value={categoryQuestion.surveyQuestionType}
                                    data-testid={"ans-type#" + qstnIndex}
                                    onChange={(e) => {
                                        onSurveyQuestionChange("surveyQuestionType", e.target.value);
                                    }}>
                                    {Object.keys(SURVEY_QUESTION_TYPES).map(questionType => {
                                        return <option value={questionType}>{SURVEY_QUESTION_TYPES[questionType]}</option>
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="col-md-8">
                            <label className="form-label col-md-12">&nbsp;</label>
                            <div className="form-check form-check-inline form-switch">
                                <input className="form-check-input" autoComplete="off"
                                    type="checkbox" role="switch"
                                    id="flexSwitchCheckDefault"
                                    data-testid={"mandatory#" + qstnIndex}
                                    checked={category.surveyQuestions[qstnIndex].isRequired}
                                    onChange={() => {
                                        onSurveyQuestionChange("isRequired",
                                            !category.surveyQuestions[qstnIndex].isRequired);
                                    }} />
                                <label className="form-check-label"><JTranslation typeCase="pascal" text={MANDATORY} /></label>
                            </div>

                            <div className="form-check form-check-inline form-switch">
                                <input className="form-check-input" autoComplete="off"
                                    type="checkbox" role="switch"
                                    id="flexSwitchCheckDefault"
                                    checked={category.surveyQuestions[qstnIndex].isActive}
                                    data-testid={"enable#" + qstnIndex}
                                    onChange={() => {
                                        onSurveyQuestionChange("isActive",
                                            !category.surveyQuestions[qstnIndex].isActive);
                                    }} />
                                <label className="form-check-label"><JTranslation typeCase="pascal" text={ENABLE} /></label>
                            </div>
                        </div>
                    </div>

                    {categoryQuestion.surveyQuestionType === "matrixTable" &&
                        <div className="row">
                            <div className="col-md-12 table-responsive-sm">
                                <table className="survey-matrix table mt-4">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th className="align-middle" scope="col"><JTranslation typeCase="pascal" text={"Options"} /></th>
                                            {categoryQuestion.matrixColumns &&
                                                categoryQuestion.matrixColumns[0].choices.map((option, choiceIndex) =>
                                                    <th scope="col"><input type="text" className="form-control col-2" autoComplete="off"
                                                        id={choiceIndex.toString()}
                                                        value={option}
                                                        maxLength={20}
                                                        data-testid={"option#" + choiceIndex}
                                                        onChange={(e) => {
                                                            if (categoryQuestion.matrixColumns) {
                                                                categoryQuestion.matrixColumns[0]
                                                                    .choices.splice(choiceIndex, 1, e.target.value);
                                                            }
                                                            updateCategoriesState();
                                                        }}
                                                        placeholder={OPTION + " " + (choiceIndex + 1)} /></th>)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {categoryQuestion.matrixRows?.map((row, rowIndex) => {
                                            return <tr>
                                                <th scope="row"><input type="text" className="form-control" autoComplete="off"
                                                    id={rowIndex.toString()}
                                                    value={row}
                                                    maxLength={20}
                                                    onChange={(e) => {
                                                        category.surveyQuestions[qstnIndex]
                                                            .matrixRows?.splice(rowIndex, 1, e.target.value);
                                                        category.surveyQuestions.splice(qstnIndex, 1, {
                                                            ...category.surveyQuestions[qstnIndex],
                                                            matrixRows: [...category.surveyQuestions[qstnIndex].matrixRows as string[]]
                                                        });
                                                        updateCategoriesState();
                                                    }}
                                                    data-testid={"row-option#" + rowIndex}
                                                    placeholder={OPTION + " " + (rowIndex + 1)} />
                                                    {(rowIndex !== 0 ||
                                                        (categoryQuestion.matrixRows &&
                                                            categoryQuestion.matrixRows?.length > 1)) &&
                                                        <span className="side-delete"
                                                            data-testid={"row-option-delete" + rowIndex} 
                                                            onClick={() => {
                                                                category.surveyQuestions[qstnIndex]
                                                                    .matrixRows?.splice(rowIndex, 1);
                                                                updateCategoriesState();
                                                            }}><i className="ri-close-circle-line"></i></span>}
                                                </th>
                                                {categoryQuestion.matrixColumns &&
                                                    categoryQuestion.matrixColumns[0].choices.map((option, choiceIndex) =>
                                                        <td className="col align-middle">
                                                            <div className="d-flex justify-content-center">
                                                                <div className="form-check form-check-inline ">
                                                                    <input className="form-check-input" type="radio" name=" " autoComplete="off"
                                                                        id={choiceIndex.toString()}
                                                                        value={option} disabled />
                                                                </div>
                                                            </div>
                                                        </td>)}
                                            </tr>
                                        })}
                                    </tbody>
                                </table>

                                <div className="col-md-12 " style={{ cursor: "pointer" }}
                                    data-testid={"add-row#" + qstnIndex}
                                    onClick={() => {
                                        const newRows = [...category.surveyQuestions[qstnIndex].matrixRows as string[], ""]
                                        category.surveyQuestions.splice(qstnIndex, 1, {
                                            ...category.surveyQuestions[qstnIndex],
                                            matrixRows: [...newRows]
                                        });
                                        updateCategoriesState();
                                    }}>
                                    <span className="d-flex fw-bold align-items-center">
                                        <i className="ri-add-fill"></i> <JTranslation typeCase="pascal" text={ADD_ROW} />
                                    </span>

                                </div>
                            </div>
                        </div>}


                </div>

                <div className="col-1 p-0 d-flex align-items-center justify-content-center">
                    <div className=" delete-round" 
                        data-testid={"delete-question#" + qstnIndex}
                        onClick={() => {
                            setDeletetype(deleteType);
                            setDeleteCatIndex(catIndex);
                            setDeleteQuestionIndex(qstnIndex);
                            setWarning(true);
                        }
                    }>
                        <i className="ri-delete-bin-line delete "></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default CategoryQuestions;