// EggFallingEffect.tsx
import { useContext, useEffect } from 'react'
import Particles, { initParticlesEngine } from '@tsparticles/react'
import type { ISourceOptions } from '@tsparticles/engine'

import { loadAll } from '@tsparticles/all'
import './falling.css'

import { getEasterDayEffects } from '../celebrationsConfigs'

import eggImage from './egg.png'
import { CommonCtx } from '../../../context/CommonCtxProvider'


const EggFallingEffect = () => {
  const { setParticleEngineLoaded } = useContext(CommonCtx)
  const easterEffects = getEasterDayEffects()
  const {
    // @ts-ignore
    particles: { count = 8, maxSize=30, minOpacity=0.4, maxOpacity=0.8 },
  } = easterEffects || {}

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadAll(engine)
    }).then(() => {
      setParticleEngineLoaded(true)
    })
  }, [])

  const particlesOptions: ISourceOptions = {
    fullScreen: {
      enable: true,
      zIndex: 0, // Set zIndex to 0 so it doesn't overlap other elements
    },
    fpsLimit: 60, // Lower FPS limit to reduce load time and improve performance
    interactivity: {
      events: {
        onHover: {
          enable: false, // Disable hover effect
        },
        onClick: {
          enable: false, // Disable click effect
        },
      },
    },
    particles: {
      number: {
        value: count,
      },
      shape: {
        type: 'image',
        options: {
          image: {
            src: eggImage,
          },
        },
      },
      opacity: {
        value: maxOpacity,
      },
      size: {
        value: maxSize, // Increase size for better visibility
      },
      move: {
        enable: true,
        speed: 2, // Adjust speed for a more natural falling effect
        direction: 'bottom', // Make particles fall from top to bottom
        outModes: {
          default: 'out', // Particles will be removed when they go out of canvas
        },
      },
    },
    detectRetina: true,
  }

  return (
    <>
      <Particles
        className='easter-day-particles'
        id='ts-particles'
        options={particlesOptions}
      />
    </>
  )
}

export default EggFallingEffect
