import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { useState, useEffect } from 'react'
import { createRoot } from 'react-dom/client';

import Package from '../../package.json'

import { compareVersions, renderPopup } from './updatePopupHelpers'

// Define the global container for the Popup
const popupContainer = document.createElement('div');
document.body.appendChild(popupContainer);
const popupRoot = createRoot(popupContainer); // Create a root for the popupContainer

let serverVersion: number | string = 0
let appVersion: number | string = 0

const useQueryHook = (
  QUERY_ID: string,
  queryFn: () => Promise<AxiosResponse<any, any>>,
  onSuccess?: ((data: AxiosResponse<any, any>) => void) | undefined,
  onError?: ((err: unknown) => void) | undefined,
  enabled = true,
  refetchOnWindowFocus = false
) => {
  const [showUpdatePopup, setShowUpdatePopup] = useState(false)
  const [loadingRefresh, setLoadingRefresh] = useState(false)
  const [loadingSnooze, setLoadingSnooze] = useState(false)

  const handleUpdate = async () => {
    setLoadingRefresh(true)
    if (window.location.reload) {
      // Reload the page without using the cache
      // @ts-ignore
      window.location.reload(true)
    } else {
      // For older browsers, simulate a hard refresh
      window.location.href = window?.location?.href
    }
  }

  const handleSnooze = () => {
    setShowUpdatePopup(false)
    setLoadingSnooze(true)
    const snoozeTime = Date.now() + 15 * 60 * 1000 // Store snooze time as current time + 15 minutes
    localStorage.setItem('popupSnoozeTime', snoozeTime.toString()) // Store snooze time in local storage
    renderPopup({
      visible: false,
      handleUpdate: () => {},
      handleSnooze: () => {},
      appVersion: '',
      serverVersion: '',
      loadingRefresh,
      loadingSnooze,
      popupContainer: popupRoot,
    })
  }

  useEffect(() => {
    // Remove the global Popup when the component is unmounted
    return () => {
      setTimeout(() => popupRoot.unmount(), 0);
    }
  }, [])

  useEffect(() => {
    // Call renderPopup again with updated values when loadingRefresh changes
    renderPopup({
      visible: loadingRefresh,
      handleUpdate,
      handleSnooze,
      appVersion,
      serverVersion,
      loadingRefresh,
      loadingSnooze,
      popupContainer,
    })
  }, [loadingRefresh])

  return useQuery(QUERY_ID, queryFn, {
    retry: 0,
    refetchOnWindowFocus: refetchOnWindowFocus ? 'always' : false,
    onSuccess: (data) => {
      if (onSuccess) {
        onSuccess(data)
      }

      // Check if update is required and show the popup
      serverVersion = data?.headers?.['x-version'] || '0.0.0'
      appVersion = Package?.version || '0.0.0'

      const comparisonResult = compareVersions(serverVersion, appVersion)
      // comparisonResult === 1) API version is greater than app version
      if (
        serverVersion &&
        appVersion &&
        comparisonResult === 1 &&
        !showUpdatePopup
      ) {
        //   renderPopup({ visible: true, handleUpdate, handleSnooze })

        const storedSnoozeTime = localStorage.getItem('popupSnoozeTime')
        if (storedSnoozeTime) {
          const snoozeTime = parseInt(storedSnoozeTime)
          if (snoozeTime < Date.now()) {
            setShowUpdatePopup(true)
            renderPopup({
              visible: true,
              handleUpdate,
              handleSnooze,
              appVersion,
              serverVersion,
              loadingRefresh,
              loadingSnooze,
              popupContainer,
            })
          }
        } else {
          setShowUpdatePopup(true)
          renderPopup({
            visible: true,
            handleUpdate,
            handleSnooze,
            appVersion,
            serverVersion,
            loadingRefresh,
            loadingSnooze,
            popupContainer,
          })
        }
      }
    },
    onError: onError,
    enabled: enabled,
    cacheTime: (30 * 60 * 1000)
  })
}

export default useQueryHook
