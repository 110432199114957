import { ChonkyIconName, defineFileAction } from 'chonky'

export const renameFolderAction = defineFileAction({
    id: 'rename_folder',
    requiresSelection: true,
    button: {
        name: 'Rename',
        contextMenu: true,
        toolbar: true,
        icon: ChonkyIconName.text,
        tooltip: 'Rename',
        iconOnly: false,
    },
} as const)

export const uploadFileAction = defineFileAction({
    id: 'upload_file',
    button: {
        name: 'Upload',
        contextMenu: true,
        toolbar: true,
        icon: ChonkyIconName.upload,
        iconOnly: false,
        tooltip: 'Upload',
    },
} as const)

export const createFolderAction = defineFileAction({
    id: 'create_folder',
    button: {
        name: 'Create folder',
        contextMenu: true,
        toolbar: true,
        icon: ChonkyIconName.folderCreate,
        tooltip: 'Create folder',
        iconOnly: false,
    },
} as const)

export const deleteAction = defineFileAction({
    id: 'delete_files',
    requiresSelection: true,
    button: {
        name: 'Delete',
        contextMenu: true,
        toolbar: true,
        icon: ChonkyIconName.trash,
        tooltip: 'Delete',
        group: 'Actions',
    },
} as const)

export const selectAllAction = defineFileAction({
    id: 'select_all_files',
    button: {
        name: 'Select all files',
        contextMenu: false,
        icon: ChonkyIconName.trash,
        tooltip: 'Select all files',
        toolbar: false,
        group: 'Actions',
    },
} as const)

export const clearSelectionAction = defineFileAction({
    id: 'clear_selection',
    button: {
        name: 'Clear selection',
        toolbar: false,
        contextMenu: false,
        group: 'Actions',
    },
} as const)

export const openSelectionAction = defineFileAction({
    id: 'open_selection',
    requiresSelection: true,
    button: {
        name: 'Open selection',
        toolbar: true,
        contextMenu: true,
        group: 'Actions',
        icon: ChonkyIconName.openFiles,
    },
} as const)

export const toggleHiddenFileAction = defineFileAction({
    id: 'toggle_hidden_files',
    button: {
        name: 'Show hidden files',
        toolbar: false,
        contextMenu: false,
        group: 'Options',
    },
} as const)

export const downloadAction = defineFileAction({
    id: 'download_files',
    requiresSelection: true,
    button: {
        name: 'Download',
        toolbar: true,
        contextMenu: true,
        group: 'Actions',
        icon: ChonkyIconName.download,
        tooltip: 'Download',
    },
} as const)
