import { useState, useContext, useEffect } from 'react'
import { Button } from 'primereact/button'
import { CommonCtx } from '../../context/CommonCtxProvider'
import { OverlayTrigger } from 'react-bootstrap'
import { popover } from '../../helpers/utils'
import { QR_CODE_LABEL, USER_CONFIGURATION, HELP_USER_CONFIG } from '../../constants/strings'
import { toastMessageInitialData } from '../../constants/constants'
import { updateQrCodeLabel } from '../../helpers/userRolesHelper'
import { UserGlobalSettings, ToastMessageProps } from '../../constants/staticTypes'
import { useQueryClient } from 'react-query'
import { UserCtx } from '../../context/UsersCtxProvider'
import Offcanvas from 'react-bootstrap/Offcanvas'
import ToastAlert from '../alert/ToastAlert'
import useMutationHook from '../../hooks/useMutationHook'
import { JTranslation } from '../../helpers/jTranslate'
import MlxPopover from '../common/MlxPopover'

const UserConfigSideBar = () => {
    // CONTEXT VARIABLE
    const { userGlobalConfigSidebar, setUserGlobalConfigSidebar } = useContext(UserCtx)
    const { userGlobalSettings, setUserGlobalSettings } = useContext(CommonCtx)
    const { qrCodeLabel } = userGlobalSettings || {};
    // LOCAL VARIABLES
    const queryClient = useQueryClient()
    const userConfigMutation = useMutationHook(queryClient, true) // user global config mutation
    // STATE VARIABLES
    const [newQrCodeLabel, setNewQrCodeLabel] = useState(qrCodeLabel)
    const [qrCodeLabelEdited, setQrCodeLabelEdited] = useState(false)
    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)

    const cleanUp = () => {
        setNewQrCodeLabel(qrCodeLabel)
        setQrCodeLabelEdited(false)
    }

    const handleClose = () => {
        setUserGlobalConfigSidebar(false)
        setToastMessage(toastMessageInitialData)
    }

    const onSuccess = (message: string, data: UserGlobalSettings, variant: string) => {
        setToastMessage({ message, variant, show: true })
        setUserGlobalSettings({ qrCodeLabel: data.qrCodeLabel })
        setQrCodeLabelEdited(false)
    }

    const onError = (message: string, variant: string) => {
        cleanUp()
        setToastMessage({ message, variant, show: true })
    }

    // POST api call to create and updated
    const updateQrLabel = () => {
        const data: UserGlobalSettings = { qrCodeLabel: newQrCodeLabel }
        updateQrCodeLabel(userConfigMutation, data, onSuccess, onError)
    }

    // effect to check if the qr code label is edited
    useEffect(() => {
        if (newQrCodeLabel && newQrCodeLabel !== qrCodeLabel) {
            setQrCodeLabelEdited(true)
        } else {
            setQrCodeLabelEdited(false)
        }

        // clean-up
        return () => {
            setQrCodeLabelEdited(false)
        }
    }, [qrCodeLabel, newQrCodeLabel])

    return (
        <Offcanvas
            show={userGlobalConfigSidebar}
            onHide={() => handleClose()}
            backdrop="static"
            // responsive="xl"
            placement="end"
        >
            {/* TOAST MESSAGE COMPONENT */}
            <ToastAlert
                show={toastMessage.show}
                onClose={() => setToastMessage(toastMessageInitialData)}
                message={toastMessage.message}
                variant={toastMessage.variant}
            />

            <Offcanvas.Header closeButton>
                <Offcanvas.Title className="d-flex justify-content-center align-items-center">
                    <JTranslation typeCase="pascal" text={USER_CONFIGURATION} />
                    <MlxPopover data={{title:USER_CONFIGURATION, body:HELP_USER_CONFIG}}/>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="row">
                    <div className="row mt-4">
                        <div className="col-12">
                            <div className="form-group input-group custom-input-group">
                                <label htmlFor="custom_label" className=" form-label w-100 d-block">
                                    <JTranslation typeCase="pascal" text={QR_CODE_LABEL} />
                                </label>
                                <input
                                    id="custom_label"
                                    className="form-control"
                                    autoComplete="off"
                                    maxLength={127}
                                    value={newQrCodeLabel}
                                    onChange={(e) => setNewQrCodeLabel(e.target.value)}
                                />

                                <span className="input-group-text" onClick={() => setQrCodeLabelEdited(true)}>
                                    <i className="ri-pencil-line"></i>
                                </span>
                            </div>

                            {qrCodeLabelEdited && (
                                <>
                                    <div className="d-flex justify-content-end">
                                        <Button
                                            icon="pi pi-check"
                                            aria-label="Filter"
                                            className="mt-1 p-button-sm  p-button-rounded p-button-success p-button-text"
                                            onClick={() => {
                                                // api call
                                                updateQrLabel()
                                            }}
                                        />
                                        <Button
                                            icon="pi pi-times"
                                            className=" mt-1 p-button-rounded p-button-danger p-button-text"
                                            aria-label="Cancel"
                                            onClick={() => cleanUp()}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default UserConfigSideBar
