import { useContext, useEffect } from 'react'
import { AddTimeOffResponse, HolidayFormDataType, ToastMessageProps } from '../../constants/staticTypes'
import { calculateDays } from '../../helpers/utils'
import { CANCEL, DURATION, ADD, UPDATE, TITLE, DESCRIPTION, DAYS, ELIGIBLE_FOR_PAY, HOLIDAY_TYPE, BUSINESS_CLOSE } from '../../constants/strings'
import { CommonCtx } from '../../context/CommonCtxProvider'
import { ErrorMessage, Field, Form, Formik, FieldProps } from 'formik'
import { HOLIDAYS_LIST } from '../../constants/queryKeys'
import { initialValuesForHoliday, fullDayValidationSchema, halfDayValidationSchema, holidayAdd, holidayUpdate, getAllHolidaysList } from '../../helpers/holidayHelper'
import { InputSwitch } from 'primereact/inputswitch'
import { JTranslation } from '../../helpers/jTranslate'
import { PageMode, AlertVariant } from '../../constants/constants'
import { ScheduleManagementCtx } from '../../context/ScheduleManagementCtxProvider'
import { SelectButton } from 'primereact/selectbutton'
import { useQueryClient } from 'react-query'
import DatePickerComponent from '../date_picker/DatePickerComponent'
import dayjs from 'dayjs'
import useMutationHook from '../../hooks/useMutationHook'


type Props = {
    onClose: Function
    pageMode: PageMode.ADD | PageMode.EDIT
    setToastMessage: React.Dispatch<React.SetStateAction<ToastMessageProps>>
    year: string
}

const HolidayForm = ({ onClose, pageMode, setToastMessage, year }: Props) => {
    const queryClient = useQueryClient()
    const timeOffMutation = useMutationHook(queryClient, true)
    const submitButtonName = pageMode === PageMode.EDIT ? UPDATE : ADD
    const options = ['morning', 'afternoon'];
    // CONTEXT VARIABLE
    const { setShowSideBar } = useContext(CommonCtx)
    const { holidayData, setHolidayData } = useContext(ScheduleManagementCtx)
    // STATE VARIABLES

    // Get the current date
    const currentDate = new Date()
    // Calculate the maximum date, 5 years from now
    const maxDate = new Date()
    maxDate.setFullYear(currentDate.getFullYear() + 1)

    useEffect(() => {
        return () => {
            setHolidayData(initialValuesForHoliday)
        }
    }, []) // eslint-disable-line


    useEffect(() => {
        calculateDays({
            fromDate: holidayData.startDate,
            toDate: holidayData.endDate,
        })
    }, [holidayData])

    const getValidationSchema = () => {
        if (holidayData.durationType === "full-day") {
            return fullDayValidationSchema
        } else {
            return halfDayValidationSchema
        }
    }

    const onSuccess = (res: AddTimeOffResponse) => {
        queryClient.fetchQuery(HOLIDAYS_LIST, () => getAllHolidaysList(Number(year)))
        setShowSideBar(false)
        setToastMessage({ message: res.message, show: true, variant: AlertVariant.SUCCESS })
    }

    const onError = (message: string) => {
        setToastMessage({ message: message, show: true, variant: AlertVariant.ERROR })
    }

    const validate = (values: HolidayFormDataType) => {
        setHolidayData((prevValues) => {
            return {
                ...prevValues,
                title: values.title,
                startDate: values.startDate,
                endDate: values.durationType === "full-day" ? values.endDate : values.startDate,
                durationType: values.durationType,
                description: values.description,
                timeLabel: values.timeLabel,
                holidayType: values.holidayType,
                isPaid: Boolean(values.isPaid),
                isOpenForBusiness: Boolean(values.isOpenForBusiness),
                duration: calculateDays({
                    fromDate: values.startDate,
                    toDate: values.endDate,
                }),
            }
        })
    }

    const handleSubmit = (values: HolidayFormDataType) => {
        const { description, durationType, endDate, id, isPaid, startDate, title, timeLabel, holidayType, isOpenForBusiness } = values;
        const formattedValues: HolidayFormDataType = {
            startDate: dayjs(startDate).format('YYYY-MM-DD'),
            endDate: dayjs(endDate).format('YYYY-MM-DD'),
            durationType,
            description,
            holidayType,
            isPaid,
            isOpenForBusiness,
            title,
            ...(durationType === 'full-day'
                ? {}
                : {
                    startTime: timeLabel === 'morning' ? '00:00' : '12:00',
                    endTime: timeLabel === 'morning' ? '11:59' : '21:59',
                    timeLabel,
                }),
            ...(pageMode === PageMode.EDIT
                ? { id: id! }
                : {}
            ),
        };

        if (pageMode === PageMode.ADD) {
            holidayAdd(timeOffMutation, formattedValues, onSuccess, onError)
        } else {
            holidayUpdate(timeOffMutation, formattedValues, onSuccess, onError)
        }
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={holidayData}
            onSubmit={handleSubmit}
            validate={validate}
            validateOnChange={true}
            validationSchema={getValidationSchema}
        >
            {(
                { errors, touched }
            ) => ( // nosonar
                <Form>
                    <div className="mb-3">
                        <label className="form-label d-flex"><JTranslation typeCase="pascal" text={TITLE} />
                            <span className="mandatory ">*</span>
                        </label>
                        <Field
                            style={errors.title && touched.title ? { border: '1px solid red' } : undefined}
                            name="title"
                            className="form-control"
                            type="text"
                            autoComplete="off"
                            maxLength="250"
                        />
                        <ErrorMessage className="formik-error" name="title" component="div" render={(error) => <span className='formik-error'><JTranslation typeCase="pascal" text={error} /></span>} />
                    </div>
                    <div className="mb-3">
                        <label className="form-label d-flex">
                            <JTranslation typeCase="pascal" text={HOLIDAY_TYPE} />
                            <span className="mandatory ">*</span>
                        </label>
                        <Field
                            name="holidayType"
                            style={errors.holidayType && touched.holidayType ? { border: '1px solid red' } : undefined}
                            as="select"
                            className="form-control"
                            type="text"
                            value={holidayData.holidayType}
                            autoComplete="off"
                            maxLength="250"
                        >
                            {/* Dropdown options */}
                            <option key="null" value="">
                                Select
                            </option>
                            <option key="PH" value="public">
                                Federal Holiday
                            </option>
                            <option key="sh" value="state">
                                State Holiday
                            </option>
                            <option key="RH" value="religious">
                                Religious Holiday
                            </option>
                            <option key="RH" value="cultural">
                                Cultural Holiday
                            </option>
                            <option key="rh" value="optional_observances">
                                Restaurant Holiday
                            </option>
                        </Field>
                        <ErrorMessage className="formik-error" name="holidayType" component="div" render={(error) => <span className='formik-error'><JTranslation typeCase="pascal" text={error} /></span>} />
                    </div>
                    <div className="mb-3 d-flex">
                        <Field name="isOpenForBusiness">
                            {({ field, form }: FieldProps) => (
                                <InputSwitch
                                    className="custom-input-switch"
                                    checked={!field.value}
                                    onChange={(e) => form.setFieldValue(field.name, !e.value)}
                                />
                            )}
                        </Field>
                        <label className="form-label d-flex me-2 m-1"><JTranslation typeCase="pascal" text={BUSINESS_CLOSE} /></label>
                    </div>

                    <div className="row">
                        <div className="mb-3 col-md-6">
                            <div className="help-small">
                                <label className="form-label d-flex">
                                    <JTranslation typeCase="pascal" text={"From Date"} />
                                    <span className="mandatory ">*</span>
                                </label>
                            </div>
                            <Field name="startDate">
                                {({ field, form }: FieldProps) => (
                                    <DatePickerComponent
                                        isClearable={false}
                                        disableYear={pageMode === PageMode.EDIT}
                                        selectedDate={new Date(field.value)}
                                        minDate={new Date()}
                                        maxDate={maxDate}
                                        numberOfUpcomingYears={1}
                                        customInput={
                                            <input
                                                style={
                                                    errors.startDate && touched.startDate
                                                        ? { border: '1px solid red' }
                                                        : undefined
                                                }
                                                className="form-control"
                                                type="text"
                                                maxLength={10}
                                                autoComplete="off"
                                            />
                                        }
                                        setDate={(value) => form.setFieldValue(field.name, value)}
                                    />
                                )}
                            </Field>
                            <ErrorMessage className="formik-error" name="startDate" component="div" render={(error) => <span className='formik-error'><JTranslation typeCase="pascal" text={error} /></span>} />
                        </div>

                        {holidayData.durationType === "full-day" && (
                            <div className="mb-3 col-md-6">
                                <div className="help-small">
                                    <label className="form-label d-flex">
                                        <JTranslation typeCase="pascal" text={"To Date"} />
                                        <span className="mandatory ">*</span>
                                    </label>
                                </div>
                                <Field name="endDate">
                                    {({ field, form }: FieldProps) => (
                                        <DatePickerComponent
                                            isClearable={false}
                                            disableYear={pageMode === PageMode.EDIT}
                                            selectedDate={new Date(field.value)}
                                            minDate={new Date()}
                                            maxDate={maxDate}
                                            numberOfUpcomingYears={1}
                                            customInput={
                                                <input
                                                    style={
                                                        errors.endDate && touched.endDate
                                                            ? { border: '1px solid red' }
                                                            : undefined
                                                    }
                                                    className="form-control"
                                                    type="text"
                                                    maxLength={10}
                                                    autoComplete="off"
                                                />
                                            }
                                            setDate={(value) => form.setFieldValue(field.name, value)}
                                        />
                                    )}
                                </Field>
                                <ErrorMessage className="formik-error" name="endDate" component="div" render={(error) => <span className='formik-error'><JTranslation typeCase="pascal" text={error} /></span>} />
                            </div>
                        )}
                    </div>
                    <div className="mb-3">
                        <label className="form-label d-flex">
                            <JTranslation typeCase="pascal" text={DURATION} />
                            <span className="mandatory ">*</span>
                        </label>
                        <Field
                            name="durationType"
                            style={errors.durationType && touched.durationType ? { border: '1px solid red' } : undefined}
                            as="select"
                            className="form-control"
                            type="text"
                            value={holidayData.durationType}
                            autoComplete="off"
                            maxLength="250"
                        >
                            {/* Dropdown options */}
                            <option key="full-day" value="full-day">
                                Full Day
                            </option>
                            <option key="half-day" value="half-day">
                                Half Day
                            </option>
                        </Field>
                        <ErrorMessage className="formik-error" name="durationType" component="div" render={(error) => <span className='formik-error'><JTranslation typeCase="pascal" text={error} /></span>} />
                    </div>

                    {holidayData.durationType === "half-day" && (
                        <div className="mb-3">
                            <Field name="timeLabel">
                                {({ field, form }: FieldProps) => (
                                    <SelectButton
                                        value={field.value}
                                        style={errors.timeLabel && touched.timeLabel ? { border: '1px solid red' } : undefined}
                                        options={options}
                                        onChange={(e) => form.setFieldValue(field.name, e.value)}
                                        unselectable={true}
                                    />
                                )}
                            </Field>
                            <ErrorMessage className="formik-error" name="timeLabel" component="div" render={(error) => <span className='formik-error'><JTranslation typeCase="pascal" text={error} /></span>} />
                        </div>
                    )}

                    {holidayData.durationType === "full-day" && (
                        <div className="mb-3">
                            <label className="form-label d-flex"><JTranslation typeCase="pascal" text={DAYS} /></label>
                            <Field
                                name="duration"
                                style={errors.duration && touched.duration ? { border: '1px solid red' } : undefined}
                                className="form-control"
                                type="text"
                                autoComplete="off"
                                maxLength="250"
                                disabled
                            />
                            <ErrorMessage className="formik-error" name="duration" component="div" render={(error) => <span className='formik-error'><JTranslation typeCase="pascal" text={error} /></span>} />
                        </div>
                    )}

                    <div className="mb-3">
                        <label className="form-label d-flex"><JTranslation typeCase="pascal" text={DESCRIPTION} /></label>
                        <Field
                            name="description"
                            style={errors.description && touched.description ? { border: '1px solid red' } : undefined}
                            className="form-control reason"
                            as="textarea"
                            autoComplete="off"
                            maxLength="1000"
                        />
                        <ErrorMessage className="formik-error" name="description" component="div" render={(error) => <span className='formik-error'><JTranslation typeCase="pascal" text={error} /></span>} />
                    </div>
                    <div className="mb-3 d-flex">
                        <Field name="isPaid">
                            {({ field, form }: FieldProps) => (
                                <InputSwitch
                                    className="custom-input-switch"
                                    checked={field.value}
                                    onChange={(e) => form.setFieldValue(field.name, e.value)}
                                />
                            )}
                        </Field>
                        <label className="form-label d-flex me-2 m-1"><JTranslation typeCase="pascal" text={ELIGIBLE_FOR_PAY} /></label>
                    </div>

                    {/* action button */}
                    <div className="save-btn-section shadow save-btn-absolute">
                        <button className="btn btn-custom-primary-outline" type="reset" onClick={() => onClose()}>
                            <JTranslation typeCase="pascal" text={CANCEL} />
                        </button>

                        <button className="btn btn-custom-primary" type="submit">
                            <JTranslation typeCase="pascal" text={submitButtonName} />
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default HolidayForm
