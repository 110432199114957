import { useState, useContext } from 'react';
import { CommonCtx } from '../../context/CommonCtxProvider';
import { dateFormatter, unescapeHtml } from '../../helpers/utils'
import { JTranslation } from '../../helpers/jTranslate'
import { NewsPostGuestView, PostType } from '../../constants/staticTypes'
import { routes } from '../../constants/routes'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from "uuid";
import blankImage from '../../assets/images/menu/600x400.png'
import SharePopup from "./SharePopup"

type props = {
    news: NewsPostGuestView
}

const NewsCard = ({ news }: props) => {
    const { urlSlug, signedPhotosKeys, title, postTags, description, publishDate } = news
    const navigate = useNavigate()
    const { setPostViewFromInternal } = useContext(CommonCtx)
    const [position, setPosition] = useState('center');
    const [displayPosition, setDisplayPosition] = useState(false);

    const showShare = (position: string) => {
        setDisplayPosition(true);

        if (position) {
            setPosition(position);
        }
    }

    const hideShare = () => {
        setDisplayPosition(false);
    }

    return (

        <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-3" >
            <div className="  p-3 border rounded news-box h-100">
                <div className="h-100 d-flex flex-column">
                    <div className="flex-grow-1 flex-column w-100"
                        data-testid="news-card"
                        onClick={() => {
                            setPostViewFromInternal(urlSlug)
                            navigate(`${routes.news_in_detail}/${urlSlug}`)
                        }}>
                        <div className='image-container'>
                            <img
                                alt="news"
                                src={
                                    signedPhotosKeys && typeof signedPhotosKeys !== 'object'
                                        ? signedPhotosKeys
                                        : blankImage
                                }
                                className="h-40 object-fit-cover mb-3 rounded shadow-custom w-100"
                            />
                        </div>
                        <h5 className=" mb-2">{<JTranslation text={title} />}</h5>
                        {postTags?.split(',').map((tag) => (
                            <span key={uuidv4()} className="item-badge mb-1">{<JTranslation text={tag} />}</span>
                            ))}
                            <div className="description description-news-events mb-3 mt-1">
                                {<JTranslation text={unescapeHtml(description)} type="html" />}
                            </div>
                        </div>

                        <div className="flex-grow-0">
                            <hr className="my-2"></hr>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 fw-bold opacity-75">
                                    <span className="me-2">
                                        <i className="ri-calendar-line"></i>
                                    </span>
                                    <span>{<JTranslation text={dateFormatter({ date: publishDate })} />}</span>
                                </div>
                                <div className="flex-grow-0">
                                    <button className="shareButton button btn  back-btn back-btn-new" data-testid="share-news" onClick={() => showShare('bottom')}>
                                    <i className="ri-share-line"></i>
                                    </button>
                                </div>
                            </div>
                            <p className="mb-0"></p>
                        </div>
                    </div>
                </div>

                <SharePopup displayPosition={displayPosition} hideShare={hideShare} title={title} urlSlug={urlSlug} postType={PostType.NEWS} />
           
        </div>
    )
}

export default NewsCard
