import { AxiosResponse } from "axios"
import { UseMutationResult } from "react-query"
import { areaListApi } from "../constants/apiEndPoints"
import { AlertVariant, HttpMethods } from "../constants/constants"
import { Payload, AreaType, AddAreaTypeFormData, AreaTypeFormData } from "../constants/staticTypes"
import HttpServiceHelper from "./httpServiceHelper"
import { getErrorMessage } from "./utils"

// fetch all users
export const getAreaList = (
    search: string,
    limit: number,
    lastKey: string
) => {
    return HttpServiceHelper({
        url: areaListApi + '?search=' + search + '&limit=' + limit + '&lastKey=' + lastKey,
        method: HttpMethods.GET,
    })
}


// delete area
export const deleteArea = (
    areaMutation: UseMutationResult<
        AxiosResponse<any, any>,
        unknown,
        Payload,
        void
    >,
    param: AreaType,
    onSuccess: (message: string, variant: string) => void,
    onError: (res: string, variant: string) => void
) => {
    areaMutation.mutate(
        {
            url: areaListApi,
            method: HttpMethods.DELETE,
            data: {id: param.id},
        },
        {
            onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
            onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
        }
    )
}


// create new add-on
export const createNewArea = (
    areaMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    areaData: AddAreaTypeFormData,
    onSuccess: (message: string, variant: string) => void,
    onError: (res: string, variant: string) => void) => {
        areaMutation.mutate({
        url: areaListApi,
        method: HttpMethods.POST,
        data: areaData,
    }, {
        onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    });
}

// update add-on
export const updateArea = (
    areaMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    areaData: AreaTypeFormData,
    onSuccess: (message: string, variant: string) => void,
    onError: (res: string, variant: string) => void) => {
        areaMutation.mutate({
        url: areaListApi,
        method: HttpMethods.PUT,
        data: areaData,
    }, {
        onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    });
}