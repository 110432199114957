import { Modal } from "react-bootstrap";
import { OFFER, OFFERS, PRIZE_LIST } from "../../../constants/strings";
import { WheelSegments } from "../../../pages/game/GuestSpinWheel";

type Props = {
    show: boolean,
    handleClose: () => void,
    prizesList: WheelSegments[]
}

function PrizesListModal({ show, handleClose, prizesList }: Props) {

    return <Modal show={show} onHide={handleClose}  centered>
        <Modal.Header closeButton>
            <h5 className="modal-title">{PRIZE_LIST}</h5>
        </Modal.Header>
        <Modal.Body>
            {/* <table>
                <tr>
                    <th>
                        {OFFER}
                    </th>
                </tr>
               
            </table> */}
            <div className="prize-list-table">
            <div className="container p-0">
		<div className="leaderboard">
			<div className="head">
            <i className="ri-trophy-line"></i>
				<h1>{OFFERS}</h1>
			</div>
			<div className="body">
				<ol>
                {prizesList.map(prize => {
                    return<li>
                        <mark>
                            {prize.option}
                        </mark>
                        </li>
                })}
					
				</ol>
			</div>
		</div>
	</div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-custom-primary-outline" type="button" data-testid="close-btn" onClick={handleClose}>Close</button>
        </Modal.Footer>
    </Modal>
}

export default PrizesListModal;