import { useState, useContext, useEffect } from "react";
import "./styles/manageUser.scss";
import { ACTIVATE_USER, DELETE_WARNING, INACTIVATE_USER, USER_ACTIVATE_ERROR, USER_ACTIVATE_SUCCESS, USER_CONFIGURATION, USER_DELETE_ERROR, USER_DELETE_SUCCESS, USER_INACTIVE_SUCCESS } from "../../constants/strings";
import { activateInactivateUser, deleteUser, getUsersList } from "../../helpers/manageUsersHelper";
import { AlertVariant } from "../../constants/constants";
import { AxiosResponse } from "axios";
import { Button } from 'primereact/button'
import { CommonCtx } from "../../context/CommonCtxProvider";
import { useQueryClient } from "react-query";
import { USER_PAGE_TITLE, GLOBAL_CONFIGURATION } from "../../constants/strings";
import { UserCtx } from "../../context/UsersCtxProvider";
import { Users } from "../../constants/staticTypes";
import { USERS_LIST } from "../../constants/queryKeys";
import AddUserSideBar from "../../components/manage_users/AddUserSideBar";
import Loader from "../../components/loader/Loader";
import ToastAlert from "../../components/alert/ToastAlert";
import useMutationHook from "../../hooks/useMutationHook";
import useQueryHook from "../../hooks/useQueryHook";
import UserConfigSideBar from "../../components/manage_users/UserConfigSideBar";
import UserListDataGrid from "../../components/manage_users/UserListDataGrid";
import WarningModal from "../../components/warning_modal/WarningModal";
import withSidebar from "../../hoc/withSidebar";
import { JTranslation, TranslationContext, jTranslationText } from "../../helpers/jTranslate";
import MlxTooltip from "../../components/common/MlxTooltip";


function ManageUsers() {
    const queryClient = useQueryClient();
    const deleteUserMutation = useMutationHook(queryClient, true); // update user mutation
    const activateInactivateMutation = useMutationHook(queryClient, true);
    // CONTEXT VARIABLE
    const { setShowSideBar: setAddUserSideBar } = useContext(CommonCtx)
    const { userGlobalConfigSidebar, setUserGlobalConfigSidebar } = useContext(UserCtx)
    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext
    // STATE VARIABLES
    const [users, setUsersList] = useState<Users[]>([]);
    const [showToast, setToast] = useState(false); // Toast alert state
    const [toastMessage, setToastMessage] = useState(""); // Toast message
    const [toastVariant, setToastVariant] = useState(""); // Toast Variant
    const [editUserData, setEditUser] = useState<Users>(); // edit user data
    const [deleteUserData, setDeleteUser] = useState<Users>(); // delete user data
    const [showWarning, setWarning] = useState(false); // Warning popup
    const [warningMessage, setWarningMessage] = useState(""); // Warning message
    const [isInitialLoad, setInitialLoad] = useState(true);
    const [activateInactivateUserData, setActivateInactivateUser] = useState<Users>(); // edit user data
    const [translationText, setTranslatedText] = useState<{globalConfiguration: string}>({globalConfiguration: USER_CONFIGURATION})

    // side bar show and close
    const handleClose = () => setAddUserSideBar(false);

    // users list fetch success
    const onUserListFetchSuccess = (res: AxiosResponse<any, any>) => {
        setUsersList(res.data?.data.lists);
        setInitialLoad(false);
    }

    // fetch users list
    const userData = useQueryHook(USERS_LIST, () => getUsersList("", 500, ""), onUserListFetchSuccess);

    // on trigger search
    const onSearchTrigger = (searchKey: string) => {
        if (userData.isFetching) {
            queryClient.cancelQueries([USERS_LIST]);
        }
        queryClient.fetchQuery(USERS_LIST, () => getUsersList(searchKey, 500, ""));
    }

    // show toast
    const showToastMessage = (message: string, variant: string, data?: Users) => {
        setToastMessage(message);
        setToastVariant(variant);
        setToast(true);

        if (data) {
            // const newData = users.map(user => {
            //     if (user.id === data.id) {
            //         user = data;
            //     }
            //     return user;
            // });
            // setUsersList(newData);

            // re-call list api, since the data is changed
            userData.refetch();
        }
    }

    // on edit button click
    const onEditClick = (user: Users) => {
        setEditUser(user);
    }

    // on delete button click
    const onDeleteClick = (user: Users) => {
        setDeleteUser(user);
        setWarningMessage(DELETE_WARNING);
        setWarning(true);
    }

    // on delete user success
    const onDeleteSuccess = () => {
        if (deleteUserData) {
            deleteUserData.isActive = false;
        }
        showToastMessage(USER_DELETE_SUCCESS, "success", deleteUserData);
        setDeleteUser(undefined);
    }

    // on delete user error
    const onDeleteError = () => {
        showToastMessage(USER_DELETE_ERROR, "success", deleteUserData);
        setDeleteUser(undefined);
    }

    // on active inactive click
    const onActiveInactiveClick = (user: Users) => {
        setActivateInactivateUser(user);
        const message = user.isActive ? INACTIVATE_USER : ACTIVATE_USER;
        setWarningMessage(message);
        setWarning(true);
    }

    // activate inactivate success
    const onActivateInactivateSuccess = (user: Users) => {
        showToastMessage(user.isActive ? USER_ACTIVATE_SUCCESS : USER_INACTIVE_SUCCESS,
            AlertVariant.SUCCESS, user)
    }

    // activate inactivate error
    const onActivateInactivateError = () => {
        showToastMessage(USER_ACTIVATE_ERROR, AlertVariant.ERROR)
    }

    useEffect(() => {
		const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({
                        text: USER_CONFIGURATION,
                        typeCase: 'pascal',
                        translationContext,
                    }),
                ])
                setTranslatedText({globalConfiguration: translations[0]?? USER_CONFIGURATION})
            } catch {
                setTranslatedText({globalConfiguration: USER_CONFIGURATION})
            }
		}
		fetchTranslation()
	}, [targetLanguage])

    if (userData.isLoading || isInitialLoad) {
        return <Loader />
    }

    return (
        <>
            <WarningModal show={showWarning} title={warningMessage}
                onHide={() => {
                    setWarning(false);
                    setDeleteUser(undefined);
                    setActivateInactivateUser(undefined);
                }}
                callback={() => {
                    setWarning(false);
                    if (deleteUserData) {
                        deleteUser({ id: deleteUserData?.id }, deleteUserMutation, onDeleteSuccess, onDeleteError);
                    } else if (activateInactivateUserData) {
                        let isActive = !activateInactivateUserData.isActive;
                        activateInactivateUser({ ...activateInactivateUserData, isActive }, activateInactivateMutation,
                            onActivateInactivateSuccess, onActivateInactivateError);
                    }
                }} />
            <ToastAlert data-testid="toast" show={showToast} onClose={setToast} message={toastMessage} variant={toastVariant} />

            <AddUserSideBar showToast={showToastMessage} editUser={editUserData} setEditUser={setEditUser} handleClose={handleClose} />

            <UserConfigSideBar />

            <div className="col-md-12 col-lg-12 mb-3 h-100 ">
                <div className="page-title d-flex mb-3 align-items-center">
                    <div className="flex-grow-1">
                        <h5 className="mb-0"><JTranslation typeCase="pascal" text={USER_PAGE_TITLE} /></h5>
                    </div>
                    {/* <div className="flex-grow-0 align-items-center"> */}
                        {/* user global configuration*/}
                        {/* <MlxTooltip target='.p-button-rounded'>
                        <Button
                            icon="ri-settings-4-line "
                            className=" p-button-rounded p-button-primary p-button-text text-nowrap fs-4 color-primary-custom"
                            data-pr-tooltip={translationText.globalConfiguration}
                            data-pr-position='left'
                            onClick={() => { setUserGlobalConfigSidebar(!userGlobalConfigSidebar) }}
                            data-testid="global-config-button"
                        /> 
                        </MlxTooltip> */}
                    {/* </div> */}
                </div>

                <UserListDataGrid
                    users={users} loading={userData.isLoading} onEditClick={onEditClick} onDeleteClick={onDeleteClick}
                    onSearchTrigger={onSearchTrigger} showToast={showToastMessage} editUser={editUserData}
                    onActiveInactiveClick={onActiveInactiveClick} />
            </div>
        </>
    )
}
export default withSidebar(ManageUsers);