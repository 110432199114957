import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { useState } from "react";
import { OverlayTrigger } from "react-bootstrap";
import { SurveyCategories } from "../../../constants/staticTypes";
import { ADD_CATEGORY, DELETE_WARNING, GAME_ENABLED_TEXT, HELP_EVENT_URL_SLUG, HELP_EVENT_URL_TEXT, PLEASE_ADD_CATEGORY, UPDATE, URL_SLUG, URL_TEXT } from "../../../constants/strings";
import WarningModal from "../../warning_modal/WarningModal";
import Categories from "./Categories";
import { routes } from "../../../constants/routes";
import { convertToSlug } from "../../../helpers/newsAndEventsHelper";
import { getDomainFromURL, popover } from "../../../helpers/utils";
import { JTranslation } from "../../../helpers/jTranslate";
import MlxPopover from "../../common/MlxPopover";

type Props = {
    prevCategories?: SurveyCategories[],
    categories: SurveyCategories[],
    setCategories: React.Dispatch<React.SetStateAction<SurveyCategories[]>>,
    isGameAvailable: boolean,
    setGameAvailable: React.Dispatch<React.SetStateAction<boolean>>,
    saveButtonText: string,
    urlSlug: string,
    setUrlSlug: React.Dispatch<React.SetStateAction<string>>,
}

export enum SurveySetDeleteType {
    DEL_CATEGORY = "delete-category",
    DEL_QUESTION = "delete-question"
}

function AddQuestionCategory({ prevCategories, categories, setCategories, isGameAvailable, setGameAvailable, saveButtonText, urlSlug, setUrlSlug }: Props) {
    const domainName = getDomainFromURL();
    const [showWarning, setWarning] = useState(false); // show delete warning modal
    const [deleteType, setDeletetype] = useState(""); // delete type
    const [deleteCatIndex, setDeleteCatIndex] = useState(-1); // category delete index
    const [deleteQuestionIndex, setDeleteQuestionIndex] = useState(-1); // question delete index
    const [accordionActiveKey, setAccordionActiveKey] = useState(["0"]);
    const [blurredCategories, setOnBlur] = useState<string[]>([]);
    const [activeIndex, setActiveIndex] = useState(-1);

    // drag end handler
    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;
        const oldIndex = active?.data.current && active.data.current.sortable.index;
        const newIndex = over?.data.current && over?.data.current.sortable.index;
        let sortedList = arrayMove(categories, oldIndex, newIndex);
        setCategories([...sortedList]);
    }

    // drag n drop sensors
    const sensors = useSensors(useSensor(PointerSensor, {
        activationConstraint: {
            distance: 2
        }
    }));

    const handleURLChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target
        setUrlSlug(convertToSlug(value))
    }

    return <>
        <WarningModal show={showWarning} onHide={() => setWarning(false)} title={DELETE_WARNING} callback={() => {
            if (deleteType === SurveySetDeleteType.DEL_CATEGORY) {
                if (saveButtonText === UPDATE) { // set flag if update
                    if (prevCategories) {
                        const prevCategory = prevCategories.filter(category =>
                            category.id === categories[deleteCatIndex].id)[0]; // get old value if exists

                        if (prevCategory) {
                            categories.splice(deleteCatIndex, 1, {
                                ...prevCategory,
                                isDeleted: true
                            });
                        } else {
                            categories.splice(deleteCatIndex, 1);
                        }
                    } else {
                        categories.splice(deleteCatIndex, 1);
                    }
                } else {
                    categories.splice(deleteCatIndex, 1);
                }
            } else {
                const questions = categories[deleteCatIndex].surveyQuestions;
                if (saveButtonText === UPDATE) { // set flag if update
                    if (prevCategories) {
                        const prevCategory = prevCategories.filter(category =>
                            category.id === categories[deleteCatIndex].id)[0]; // get old value if exists
                        const prevQuestion = prevCategory?.surveyQuestions.filter(qstn =>
                            qstn.id === questions[deleteQuestionIndex].id)[0];

                        if (prevQuestion) {
                            questions.splice(deleteQuestionIndex, 1, {
                                ...prevQuestion,
                                isDeleted: true
                            });
                        } else {
                            questions.splice(deleteQuestionIndex, 1);
                        }
                    } else {
                        questions.splice(deleteQuestionIndex, 1);
                    }
                } else {
                    questions.splice(deleteQuestionIndex, 1);
                }

                categories.splice(deleteCatIndex, 1, {
                    ...categories[deleteCatIndex],
                    surveyQuestions: [...questions]
                });
            }
            setCategories([...categories]);
            setWarning(false);
        }} />
        <div className="card-body flex-grow-1 overflow-auto">
            <div className="survey-section">
                <div className="row mb-3">
                    <div className="col-md-6 mb-3">
                        <div className="help-small">
                            <label className="form-label d-flex">
                                {URL_TEXT}
                                <span className="mandatory ">*</span>
                            </label>
                            <MlxPopover data={{title:URL_TEXT, body:HELP_EVENT_URL_TEXT}}/>
                        </div>
                        <input
                            name="urlSlug"
                            className="form-control"
                            type="text"
                            autoComplete="off"
                            maxLength={150}
                            value={urlSlug}
                            data-testid="survey-url-text"
                            onChange={handleURLChange}
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="help-small">
                            <label className="form-label">{URL_SLUG}</label>
                            <MlxPopover data={{title:URL_SLUG, body:HELP_EVENT_URL_SLUG}}/>
                        </div>
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" disabled value={urlSlug ? `${domainName}${routes.survey_guest}/${urlSlug}` : "Enter your URL text"}/>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-0 me-3">
                                <button className="btn btn-custom-primary" type="button"
                                    onClick={() => setCategories([...categories, {
                                        id: "id" + Math.random().toString(16).slice(2),
                                        surveyCategoryName: "",
                                        surveyCategoryType: "",
                                        isActive: false,
                                        isDeleted: false,
                                        surveyQuestions: []
                                    }])}
                                    data-testid="add-category-btn" ><JTranslation typeCase="pascal" text={ADD_CATEGORY} /></button>
                            </div>
                            <div className="flex-grow-0">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="" autoComplete="off"
                                        data-testid="game-checkbox"
                                        checked={isGameAvailable}
                                        onChange={() => setGameAvailable(!isGameAvailable)} />
                                    <label className="form-check-label" >
                                        <JTranslation typeCase="capitalize" text={GAME_ENABLED_TEXT} />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row add-category-container mb-5">
                    {categories.length === 0 && <div className="col-12 text-center no-action">
                        <i className="ri-error-warning-line"></i>
                        <div><JTranslation typeCase="pascal" text={PLEASE_ADD_CATEGORY} /></div>
                    </div>}
                    {categories.length > 0 &&
                        categories.filter(cat => cat.isDeleted === false).length > 0 &&
                        <div>
                            <DndContext
                                sensors={sensors}
                                collisionDetection={closestCenter}
                                onDragEnd={handleDragEnd}>
                                <SortableContext items={categories.filter(cat => cat.isDeleted === false)
                                    .map(cat => cat.id as string)}
                                    strategy={verticalListSortingStrategy}>
                                    {categories.filter(cat => cat.isDeleted === false)
                                        .map((category, index) => {
                                            return <Categories key={index} setDeleteCatIndex={setDeleteCatIndex} setDeletetype={setDeletetype}
                                                setWarning={setWarning} setCategories={setCategories} index={index} category={category}
                                                setDeleteQuestionIndex={setDeleteQuestionIndex} categories={categories}
                                                accordionActiveKey={accordionActiveKey} setOnBlur={setOnBlur}
                                                setAccordionActiveKey={setAccordionActiveKey} blurredCategories={blurredCategories} activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
                                        })}
                                </SortableContext>
                            </DndContext>
                        </div>
                    }
                </div>
            </div>
        </div>
    </>
}

export default AddQuestionCategory;