import withSidebar from "../../hoc/withSidebar";
import { useContext, useEffect, useRef, useState } from 'react';
import { useQueryClient } from "react-query";
import QRScanner from "../../components/manage_coupon/QRScanner";
import ToastAlert from "../../components/alert/ToastAlert";
import { FaSearch } from "react-icons/fa";
import { AxiosResponse } from "axios";
import { COUPONS_LIST } from "../../constants/queryKeys";
import useQueryHook from "../../hooks/useQueryHook";
import { getActiveCouponsList, getCouponDetails } from "../../helpers/manageCouponsHelper";
import CouponsListDataGrid from "../../components/manage_coupon/CouponsListDataGrid";
import useMutationHook from "../../hooks/useMutationHook";
import { AlertVariant, MOBILE_VIEW_BREAKPOINT, PermissionKeys, SubPermissionKeys } from "../../constants/constants";
import { CHECK, CLEAR, HELP_COUPONS, NO_COUPONS,MANAGE_COUPON } from "../../constants/strings";
import { checkPermission,popover } from "../../helpers/utils";
import { OverlayTrigger } from "react-bootstrap";
import { Coupon } from "../../constants/staticTypes";
import { JTranslation, TranslationContext, jTranslationText } from "../../helpers/jTranslate";
import { useWindowSize } from "react-use";
import { DebounceInput } from "react-debounce-input";
import MlxPopover from "../../components/common/MlxPopover";

function ManageCoupon() {
    const queryClient = useQueryClient();
    const [showScanner, setScanner] = useState(false);
    const [couponCode, setCouponCode] = useState("");
    const [showToast, setToast] = useState(false); // Toast alert state
    const [toastMessage, setToastMessage] = useState(""); // Toast message
    const [toastVariant, setToastVariant] = useState(""); // Toast Variant
    const [pagination, setPagination] = useState(false);
    const [coupons, setCouponsList] = useState<Coupon[]>([]);
    const [page, setPage] = useState(0);
    const [lastKey, setLastKey] = useState<string | null>("");
    const [searchValue, setSearch] = useState("");
    const couponDetailsMutation = useMutationHook(queryClient, true);
    const limit = 200;
    const [checkButtonText, setCheckButtonText] = useState(CHECK);
    const couponCodeRef = useRef<HTMLInputElement>(null);

    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext

	  const [translationText, setTranslatedText] = useState<{
        placeholder: string,
        search: string,
      }>({ 
        placeholder: "Enter your voucher code",
        search: "Search",
    });

    const [showTitle, setShowTitle] = useState(true)
    const { width, height } = useWindowSize()
	  const breakPoint = MOBILE_VIEW_BREAKPOINT

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: "Enter your voucher code", typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: "Search", typeCase: 'pascal', translationContext }),
                ])
                
                setTranslatedText({
					placeholder: translations[0] ?? "Enter your voucher code",
					search: translations[1] ?? "Search",
				})
            } catch {
                setTranslatedText({
                    placeholder: "Enter your voucher code",
                    search: "Search",
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    const setScanResult = (result: string) => {
        setCouponCode(result);
        setScanner(false);
        if (couponCodeRef.current !== null) {
            couponCodeRef.current.focus();
        }
    }

    // show toast
    const showToastMessage = (message: string, variant: string) => {
        setToastMessage(message);
        setToastVariant(variant);
        setToast(true);
    }

    // fetch success
    const onFetchSuccess = (res: AxiosResponse<any, any>) => {
        const couponsList = res.data.data.list as Coupon[]
        if (pagination) {
            setCouponsList([...coupons, ...couponsList]);
            setPage(page + 10);
        } else {
            setCouponsList(couponsList);
        }
        setPagination(false);
        setLastKey(res.data.data.paginationData.lastKey);
    }

    // fetch coupons list
    const couponsData = useQueryHook(COUPONS_LIST,
        () => getActiveCouponsList(queryClient, "", "", limit), onFetchSuccess);

    // on trigger search
    const onSearchTrigger = (searchKey: string, lastKeyValue: string | null) => {
        if (couponsData.isFetching) {
            queryClient.cancelQueries([COUPONS_LIST]);
        }
        queryClient.fetchQuery(COUPONS_LIST, () =>
            getActiveCouponsList(queryClient, searchKey, lastKeyValue, limit));
    }

    // on coupon details fetch success
    const onCouponDetailsSuccess = (coupon: Coupon, message: string) => {
        if (coupon.id !== null) {
            setCheckButtonText(CLEAR);
            setCouponsList([coupon]);
            setLastKey(null);
            showToastMessage(message, AlertVariant.SUCCESS);
        } else {
            showToastMessage(NO_COUPONS, AlertVariant.ERROR);
        }
    }

    return (
      <>
        <ToastAlert
          data-testid='toast'
          show={showToast}
          onClose={setToast}
          message={toastMessage}
          variant={toastVariant}
        />
        <div className='col-md-12 col-lg-12  manage-user-sticky user-scroll-fix-first '>
          <div className='page-title'>
            <h5 className='d-flex'>
              <JTranslation typeCase="pascal" text={"Manage Voucher"} />
              <MlxPopover data={{title:MANAGE_COUPON, body:HELP_COUPONS}}/>
            </h5>
          </div>

          <div className='card mb-3 '>
            {!showScanner && (
              <div className='card-body flex-grow-1 '>
                <div className='survey-section '>
                  <div className='row '>
                    <div className='col-sm-12 col-md-12 col-lg-12 col-xl-10'>
                      <div className='d-flex align-items-center coupon-outer'>
                        <div className='flex-grow-1 d-flex justify-content-end '>
                          <div className='input-group'>
                            {/*  <span className="input-group-text" id="basic-addon1"><i className="ri-qr-code-line"></i></span> */}
                            <input
                              type='text'
                              className='form-control'
                              data-testid='coupon-code'
                              autoComplete='off'
                              placeholder={translationText.placeholder}
                              value={couponCode}
                              maxLength={20}
                              ref={couponCodeRef}
                              disabled={checkButtonText === CLEAR}
                              onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                  getCouponDetails(
                                    queryClient,
                                    couponCode,
                                    couponDetailsMutation,
                                    onCouponDetailsSuccess,
                                    showToastMessage
                                  )
                                }
                              }}
                              aria-label='Enter your voucher code'
                              aria-describedby='basic-addon2'
                              onChange={(e) => setCouponCode(e.target.value)}
                            />
                            <div className='input-group-append'>
                              <button
                                className='btn btn-custom-primary'
                                type='button'
                                data-testid='coupon-check-button'
                                disabled={
                                  couponCode?.trim().length === 0 ||
                                  !checkPermission(
                                    queryClient,
                                    PermissionKeys.MANAGE_COUPON,
                                    SubPermissionKeys.VALIDITY_CHECK
                                  )
                                }
                                onClick={() => {
                                  if (
                                    checkPermission(
                                      queryClient,
                                      PermissionKeys.MANAGE_COUPON,
                                      SubPermissionKeys.VALIDITY_CHECK
                                    )
                                  ) {
                                    if (checkButtonText === CLEAR) {
                                      onSearchTrigger('', null)
                                      setCouponCode('')
                                      setCheckButtonText(CHECK)
                                    } else {
                                      getCouponDetails(
                                        queryClient,
                                        couponCode,
                                        couponDetailsMutation,
                                        onCouponDetailsSuccess,
                                        showToastMessage
                                      )
                                    }
                                  }
                                }}
                              >
                                <JTranslation typeCase="pascal" text={checkButtonText} />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className='flex-grow-1 d-flex justify-content-start ms-2'>
                          <button
                            className='btn btn-success d-flex btn-small-qr'
                            type='button'
                            data-testid='scan-coupon'
                            onClick={() => {
                              if (
                                checkPermission(
                                  queryClient,
                                  PermissionKeys.MANAGE_COUPON,
                                  SubPermissionKeys.VALIDITY_CHECK
                                )
                              ) {
                                setScanner(!showScanner)
                              }
                            }}
                            disabled={
                              !checkPermission(
                                queryClient,
                                PermissionKeys.MANAGE_COUPON,
                                SubPermissionKeys.VALIDITY_CHECK
                              )
                            }
                          >
                            <i className='me-1 ri-qr-scan-2-line'></i> <JTranslation typeCase="none" text={"Scan QR Code"} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {showScanner && (
              <QRScanner
                setScanResult={setScanResult}
                showToastMessage={showToastMessage}
                setScanner={setScanner}
                showScanner={showScanner}
              />
            )}
          </div>
        </div>

        <div className='col-md-12 col-lg-12 mb-3 user-scroll-fix  '>
          <div className='card user-list-conainer  '>
            <div className='card-header sticky-card-header'>
              <div className='d-flex  align-items-center'>
                <div className='flex-grow-1'>
                  {showTitle &&
                    <h4 className='mb-0 title'><JTranslation typeCase="pascal" text={"Voucher List"} /></h4>
                  }
                </div>
                <div className='flex-grow-0  d-flex justify-content-end '>
                  <div className='col-md-12'>
                    <div className={`form-group has-search ${!showTitle ? '' : 'search-small-box'}`}>
                      <span className='fa fa-search form-control-feedback'>
                        <FaSearch />{' '}
                      </span>
                      <DebounceInput
                        onFocusCapture={() => {breakPoint >= width && setShowTitle(false)}}
                        onBlurCapture={(event: any) => {                                                
                            if(breakPoint >= width && event.target.value.length === 0) {
                                setShowTitle(true)
                            }
                        }}
                        autoComplete='off'
                        className="form-control "
                        placeholder={translationText.search}
                        minLength={0}
                        debounceTimeout={500}
                        data-testid="coupon-search"
                        onChange={(e) => {
                          setSearch(e.target.value)
                          onSearchTrigger(e.target.value, null)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='card-body '
              style={{ height: '100%', overflow: 'auto' }}
            >
              <div className='survey-section h-100'>
                <div className='row mb-5 h-100'>
                  <div
                    className='col-lg-12'
                    style={{ height: '100%', overflow: 'auto' }}
                  >
                    <CouponsListDataGrid
                      lastKey={lastKey}
                      onSearchTrigger={onSearchTrigger}
                      searchValue={searchValue}
                      isLoading={couponsData.isFetching}
                      selectedPage={page}
                      setPagination={setPagination}
                      setSelectedPage={setPage}
                      coupons={coupons}
                      setCouponsList={setCouponsList}
                      showToastMessage={showToastMessage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
}

export default withSidebar(ManageCoupon);