import { useEffect, useRef, useState } from 'react'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { FileType } from '../../../constants/constants'
import { Galleria } from 'primereact/galleria'
import { Gallery } from '../../../constants/staticTypes'
import noImageUpload from '../../../assets/images/menu/no_image_upload.png'



type Props = {
    galleryImages: Gallery[] | undefined
}

function GalleryViewWithFooter({ galleryImages }: Props) {
    const [activeIndex, setActiveIndex] = useState(0)
    const galleryRef = useRef<Galleria>(null)

    useEffect(() => {
      return () => {
        // remove the attributes added by galleria in fullscreen
        document.body.classList.remove('p-overflow-hidden');
        document.body.style.removeProperty('--scrollbar-width');
      }
    }, [])
    

    const itemTemplate = (item: any) => {
        const onImageClick = () => {
            setActiveIndex(galleryImages?.indexOf(item) || 0);
            galleryRef?.current?.show();
        };
        if (item.type === FileType.IMAGE) {
            return (
                <img
                    src={item.src}
                    onError={(e) =>
                        (e.currentTarget.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png')
                    }
                    alt={item.alt}
                    style={{ maxHeight: '500px', minHeight: '400px', width: '100%', display: 'block', objectFit: 'scale-down' }}
                    data-testid={"image#" + item.id}
                    onClick={onImageClick}
                />
            )
        } else {
            return <video src={item.src} controls controlsList="nodownload" disablePictureInPicture  width={600} />
        }
    }

    const thumbnailTemplate = (item: any) => {
        if (item.type === FileType.IMAGE) {
            return (
                <img
                    src={item.src}
                    onError={(e) =>
                        (e.currentTarget.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png')
                    }
                    alt={item.alt}
                    style={{ maxWidth: '150px', width: '100px', maxHeight: '50px', height: '50px', objectFit: 'cover' }}
                    data-testid={"thumbnail-image#" + item.id}
                />
            )
        } else {
            return (
                <div style={{ position: 'relative', maxWidth: '150px', width: '100px', maxHeight: '50px', height: '50px', overflow: 'hidden' }}>
                    <video src={item.src} style={{ maxWidth: '150px', width: '100px', maxHeight: '50px', height: '50px', objectFit: 'cover' }} />
                    <div style={{ position: 'absolute', top: '50%', left: '40%', transform: 'translate(-50%, -50%)', zIndex: 1, backgroundColor: 'rgb(0,0,0,0.5)', paddingTop:"3px" }}>
                        <i className='ri-play-circle-line' style={{ fontSize: '25px', color: '#fff' }}/>
                    </div>
                </div>
            )
        }
    }

    const responsiveOptions = [
        {
            breakpoint: '1600px',
            numVisible: 3
        },
        {
            breakpoint: '868px',
            numVisible: 2
        },
        {
            breakpoint: '767px',
            numVisible: 5
        }, 
        {
            breakpoint: '540px',
            numVisible: 3
        },
        {
            breakpoint: '367px',
            numVisible: 2
        },
        {
            breakpoint: '361px',
            numVisible: 1
        }
    ];

    return (
        <div className={`gallery-slider-outer bg gallery-slide-thumb h-100 ${galleryImages?.length ? '' : 'no-gallery-image'}`}>

            {
                galleryImages?.length ?
                    <>
                        <Galleria
                            // ref={galleryRef}
                            value={galleryImages}
                            //@ts-ignore
                            thumbnail={thumbnailTemplate}
                            responsiveOptions={responsiveOptions}
                            numVisible={4}
                            //style={{ maxWidth: '640px' }}
                            activeIndex={activeIndex}
                            onItemChange={(e) => setActiveIndex(e.index)}
                            circular
                            showItemNavigators
                            // showIndicators
                            showThumbnails={true}
                            item={itemTemplate}
                        />

                        <Galleria
                            ref={galleryRef}
                            value={galleryImages}
                            //@ts-ignore
                            thumbnail={thumbnailTemplate}
                            responsiveOptions={responsiveOptions}
                            numVisible={3}
                            //style={{ maxWidth: '640px' }}
                            activeIndex={activeIndex}
                            onItemChange={(e) => setActiveIndex(e.index)}
                            circular
                            fullScreen
                            showItemNavigators
                            // showIndicators
                            showThumbnails={false}
                            item={itemTemplate}
                        />
                    </>
                    :
                    <img
                        src={noImageUpload}
                        alt="No uploads provided"

                    />
            }
        </div>
    )
}

export default GalleryViewWithFooter
