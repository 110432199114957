import { AxiosResponse } from "axios";
import { QueryClient, UseMutationResult } from "react-query";
import { surveyDefaultApi, surveyManagementApi, surveyQuestionSetCloneApi, surveyQuestionSetStatusApi } from "../constants/apiEndPoints";
import { AlertVariant, HttpMethods, SurveyQuestionType } from "../constants/constants";
import { QUESTION_SET } from "../constants/queryKeys";
import { Payload, Survey, SurveyCategories } from "../constants/staticTypes";
import HttpServiceHelper from "./httpServiceHelper";
import { getErrorMessage } from "./utils";

// create survey set
export const createSurveySet = (
    queryClient: QueryClient,
    surveyMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    surveySetName: string,
    urlSlug: string,
    isGameAvailable: boolean,
    onSuccess: (message: string, id: string) => void,
    displayToast: (message: string, variant: string) => void
) => {
    surveyMutation.mutate({
        queryClient,
        url: surveyManagementApi,
        method: HttpMethods.POST,
        data: {
            surveySetName,
            isGameAvailable,
            urlSlug
        }
    }, {
        onSuccess: (res) => onSuccess(res.data.message, res.data.data.id),
        onError: (res) => displayToast(getErrorMessage(res), AlertVariant.ERROR)
    });
}

// create / update survey questions
export const upsertSurveyQuestions = (
    queryClient: QueryClient,
    surveyUpsertMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    data: {},
    onSuccess: (message: string) => void,
    displayToast: (message: string, variant: string) => void
) => {
    surveyUpsertMutation.mutate({
        url: surveyManagementApi,
        method: HttpMethods.PUT,
        data,
        queryClient
    }, {
        onSuccess: (res) => onSuccess(res.data.message),
        onError: (res) => displayToast(getErrorMessage(res), AlertVariant.ERROR)
    })
}

// fetch all question set
export const getQuestionSetList = (queryClient: QueryClient) => {
    return HttpServiceHelper({
        url: surveyManagementApi,
        method: HttpMethods.GET,
        queryClient
    })
}

// question set active inactive status
export const activeInactiveQuestionSet = (
    queryClient: QueryClient,
    activeInactiveMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    data: {
        surveySetId: string,
        isActive: boolean
    },
    onResponse: (id: string, message: string, variant: string) => void
) => {
    activeInactiveMutation.mutate(
        {
            url: surveyQuestionSetStatusApi,
            method: HttpMethods.POST,
            data: data,
            queryClient,
        },
        {
            onSuccess: (res) => onResponse(data.surveySetId, res.data.message, AlertVariant.SUCCESS),
            onError: (res) => onResponse(data.surveySetId, getErrorMessage(res), AlertVariant.ERROR),
        }
    )
}

// make question set default 
export const makeQuestionSetDefault = (
    queryClient: QueryClient,
    activeInactiveMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    data: {
        surveySetId: string,
    },
    onResponse: (id: string, message: string, variant: string) => void
) => {
    activeInactiveMutation.mutate(
        {
            url: surveyDefaultApi,
            method: HttpMethods.POST,
            data: data,
            queryClient,
        },
        {
            onSuccess: (res) => onResponse(data.surveySetId, res.data.message, AlertVariant.SUCCESS),
            onError: (res) => onResponse(data.surveySetId, getErrorMessage(res), AlertVariant.ERROR),
        }
    )
}

// delete survey set
export const deleteSurveySet = (
    queryClient: QueryClient,
    deleteQuestionSetMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    surveySetId: string,
    onSuccess: (id: string, message: string, variant: string) => void,
    displayToast: (message: string, variant: string) => void
) => {
    deleteQuestionSetMutation.mutate({
        url: surveyManagementApi,
        method: HttpMethods.DELETE,
        data: {
            surveySetId
        },
        queryClient
    }, {
        onSuccess: (res) => onSuccess(surveySetId, res.data.message, AlertVariant.SUCCESS),
        onError: (res) => displayToast(getErrorMessage(res), AlertVariant.ERROR)
    })
}

// set id to questions and category for drag and drop
export const setQuestionIds = (surveyCategories: SurveyCategories[]) => {
    return surveyCategories.map(category => {
        const newsurveyQuestions = category.surveyQuestions.map(qstn => {
            return {
                ...qstn,
                id: "id" + Math.random().toString(16).slice(2)
            }
        });
        return {
            ...category,
            id: "id" + Math.random().toString(16).slice(2),
            surveyQuestions: [...newsurveyQuestions]
        }
    });
}

// question set formatted submit data
export const getQuestionSetSubmitData = (surveyCategories: SurveyCategories[]) => {

    // check data valid
    let isValidData = true;
    const newCategories = surveyCategories.map(category => {

        // check validity
        if (category.surveyCategoryName?.trim().length === 0 ||
            category.surveyCategoryType.length === 0) {
            isValidData = false;
        }

        const newsurveyQuestions = category.surveyQuestions.map(qstn => {
            const newQuestion = {
                isActive: qstn.isActive,
                isDeleted: qstn.isDeleted,
                isRequired: qstn.isRequired,
                surveyQuestionName: qstn.surveyQuestionName,
                surveyQuestionType: qstn.surveyQuestionType
            } as { [key: string]: any };

            // check validity
            if (qstn.surveyQuestionName?.trim().length === 0) {
                isValidData = false;
            } else if (qstn.surveyQuestionType === SurveyQuestionType.MATRIX_TABLE && !qstn.isDeleted) {
                if (qstn.matrixColumns) {
                    if (qstn.matrixColumns[0].choices.filter(col => col?.trim().length === 0).length > 0) {
                        isValidData = false;
                    }
                    newQuestion.matrixColumns = qstn.matrixColumns;
                    newQuestion.matrixRows = qstn.matrixRows;
                } else {
                    isValidData = false;
                }
            }
            return {
                ...newQuestion
            }
        });
        return {
            surveyCategoryName: category.surveyCategoryName,
            surveyCategoryType: category.surveyCategoryType,
            isActive: category.isActive,
            isDeleted: category.isDeleted,
            surveyQuestions: [...newsurveyQuestions]
        }
    });

    return {
        isValidData,
        newCategories
    }
}

// question set clone
export const cloneQuestionSet = (
    queryClient: QueryClient,
    cloneMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    surveySetId: string,
    onResponse: (id: string, message: string, variant: string) => void
) => {
    cloneMutation.mutate({
        url: surveyQuestionSetCloneApi,
        method: HttpMethods.POST,
        data: {
            surveySetId
        },
        queryClient
    }, {
        onSuccess: (res) => onResponse(QUESTION_SET, res.data.message, AlertVariant.SUCCESS),
        onError: (res) => onResponse(surveySetId, getErrorMessage(res), AlertVariant.ERROR)
    })
}

// get sorted survey set
export const getSortedSurveySet = (questionSet: Survey[]) => {
    questionSet.sort((a, b) => {
        if (a.createdAt !== undefined && b.createdAt !== undefined) {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        } else {
            return -1;
        }
    });
    const defaultSet = questionSet.filter(qstnSet => qstnSet.isDefault === true)[0];
    const activeSet = questionSet.filter(qstnSet => qstnSet.isActive === true && qstnSet.isDefault !== true)[0];

    if (defaultSet) {
        const defIndex = questionSet.indexOf(defaultSet);
        questionSet.splice(defIndex, 1);
        questionSet.splice(0, 0, defaultSet);
    }

    if (activeSet) {
        const activeIndex = questionSet.indexOf(activeSet);
        const replaceIndex = defaultSet ? 1 : 0;
        questionSet.splice(activeIndex, 1);
        questionSet.splice(replaceIndex, 0, activeSet);
    }
    return questionSet;
}