import { useEffect } from "react";
import { FILE_UPLOAD_EXIT_WARNING } from "../../constants/strings";

const WarnTabCloseAndReload = () => {
    useEffect(() => {
        const handleTabClose = (event: any) => {
            event.preventDefault();
            event.returnValue = FILE_UPLOAD_EXIT_WARNING;
            return event.returnValue;
        };

        window.addEventListener("beforeunload", handleTabClose);

        // clean-up
        return () => {
            window.removeEventListener("beforeunload", handleTabClose);
        };
    }, []);

    return <></>;
};

export default WarnTabCloseAndReload;
