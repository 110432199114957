import { useContext, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { AlertVariant, toastMessageInitialData } from "../../constants/constants";
import { ToastMessageProps } from "../../constants/staticTypes";
import { JTranslation } from "../../helpers/jTranslate";
import { ShiftSwapPoolRequests, SwapRequestList, updateSwapRequestStatus } from "../../helpers/swapScheduleHelper";
import ToastAlert from "../alert/ToastAlert";
import { useQueryClient, UseQueryResult } from "react-query";
import { CommonCtx } from "../../context/CommonCtxProvider";
import { imageOnErrorHandler } from "../../helpers/utils";
import useMutationHook from "../../hooks/useMutationHook";
import dayjs from "dayjs";
import { CANCEL } from "../../constants/strings";
import { AxiosResponse } from "axios";

type Props = {
    swapRequest: SwapRequestList | null;
    swapListQuery: UseQueryResult<AxiosResponse<any, any>, unknown>;
    handleClose: () => void;
}

const ApproveTakenShiftSideBar = ({
    swapRequest,
    swapListQuery,
    handleClose,
}: Readonly<Props>) => {
    const queryClient = useQueryClient()
    const approveOfferedShiftRequestMutation = useMutationHook(queryClient, true)
    const { showSideBar } = useContext(CommonCtx)

    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
    const [selectedShift, setSelectedShift] = useState<ShiftSwapPoolRequests | null>(null)

    const handleApproveShift = () => {
        if (selectedShift) {
            updateSwapRequestStatus(
                approveOfferedShiftRequestMutation,
                {
                    id: selectedShift?.id,
                    newStatus: 'STAFF_APPROVED',
                    approverRole: 'staff'
                },
                (response) => {
                    setToastMessage({ message: response.message, show: true, variant: AlertVariant.SUCCESS })
                    setSelectedShift(null)
                    swapListQuery.refetch()
                    handleClose()
                },
                (error) => {
                    setToastMessage({ message: error, show: true, variant: AlertVariant.ERROR })
                }
            )
        }
    }

    return (
        <Offcanvas
            show={showSideBar}
            className='custom-offcanvas'
            onHide={() => {
                setSelectedShift(null)
                handleClose()
            }}
            backdrop="static"
            // responsive="xl"
            placement="end"
        >
            {/* TOAST MESSAGE COMPONENT */}
            <ToastAlert
                show={toastMessage.show}
                onClose={() => setToastMessage(toastMessageInitialData)}
                message={toastMessage.message}
                variant={toastMessage.variant}
            />

            <Offcanvas.Header closeButton>
                <Offcanvas.Title><JTranslation typeCase="pascal" text={'Approve Offered Shift'} /></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <>
                    <div className="d-flex flex-row">
                        <img
                            className="rounded-circle"
                            src={swapRequest?.fromTenantStaff?.user?.photoKey}
                            alt={swapRequest?.fromTenantStaff?.user?.firstName}
                            style={{ width: '60px', height: '60px' }}
                            onError={imageOnErrorHandler}
                        />
                        <div className="d-flex flex-column ms-3">
                            <p className="m-0"><b>{swapRequest?.fromTenantStaff?.user?.preferredName ?? (swapRequest?.fromTenantStaff?.user?.firstName + ' ' + swapRequest?.fromTenantStaff?.user?.lastName)}</b></p>
                            <small className="m-0">{swapRequest?.fromShift?.assignedShifts ? swapRequest?.fromShift?.assignedShifts?.role : ''}</small>
                            <small className="m-0">
                                {/* {swapRequest?.fromShift?.assignedShifts ? swapRequest?.fromShift?.assignedShifts?.shiftName : ''} - */} {swapRequest?.fromShift?.assignedShifts ? swapRequest?.fromShift?.assignedShifts?.startTime : ''} - {swapRequest?.fromShift?.assignedShifts ? swapRequest?.fromShift?.assignedShifts?.endTime : ''}
                            </small>
                        </div>
                    </div>

                    <div className="mt-5">
                        <h6><JTranslation typeCase="pascal" text={'Shifts Requests'} /></h6>
                        <div className="mt-4 px-2">
                            {swapRequest?.shiftSwapPoolRequests?.map((shift, index) => {
                                return (
                                    <div
                                        key={shift?.id}
                                        className="d-flex flex-row align-items-center px-2 py-3 rounded mt-3"
                                        style={{
                                           // backgroundColor: '#F8F9FA',
                                            backgroundColor: selectedShift?.id === shift?.id ? '#fffcf7' : '#F8F9FA',
                                            border: selectedShift?.id === shift?.id ? '2.5px solid #fbaa07' : '2.5px solid #F8F9FA',
                                            boxShadow: selectedShift?.id === shift?.id ? '0px 0px 17px 0px rgb(154 161 171 / 18%)' : 'none'
                                        }}
                                        role="button"
                                        onClick={() => {
                                            // TODO: Handle click
                                            setSelectedShift(shift)
                                        }}
                                    >
                                        {/*  TODO: Needs changes */}
                                        <img
                                            className="rounded-circle"
                                            src={shift?.tenantStaff?.user?.photoKey}
                                            alt={`profile-img`}
                                            style={{ width: '50px', height: '50px' }}
                                            onError={imageOnErrorHandler}
                                        />
                                        <div className="ms-2 d-flex flex-column">
                                            <span>
                                                {/*  TODO: Needs changes */}
                                                <b>{`${shift?.tenantStaff?.user?.preferredName ?? `${shift?.tenantStaff?.user?.firstName} ${shift?.tenantStaff?.user?.lastName}`}`}</b>
                                                <small>
                                                    <small className="ms-2 text-warning fw-bold">{shift?.shiftData?.assignedShifts?.role}</small>
                                                </small>
                                            </span>
                                            <small>
                                                {shift?.shiftData?.scheduleDate ? dayjs(shift?.shiftData?.scheduleDate).format('MMM D, YYYY') : ''}
                                                <small className="ms-2">
                                                    {/* {`(${shift?.shiftData?.assignedShifts?.shiftName}: ${shift?.shiftData?.assignedShifts?.startTime} - ${shift?.shiftData?.assignedShifts?.endTime})`} */}
                                                    {`${shift?.shiftData?.assignedShifts?.startTime} - ${shift?.shiftData?.assignedShifts?.endTime}`}
                                                </small>
                                            </small>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                        {/* Footer */}
                        <div className="save-btn-section shadow save-btn-absolute">
                            <button
                                className="btn btn-custom-primary-outline ms-2"
                                type="button"
                                data-testid="clear-button"
                                onClick={() => {
                                    setSelectedShift(null)
                                    handleClose()
                                }}
                            >
                                <JTranslation typeCase="pascal" text={CANCEL} />
                            </button>

                            <button
                                className="btn btn-custom-primary ms-2"
                                type="submit"
                                data-testid="save-button"
                                disabled={!selectedShift}
                                onClick={handleApproveShift}
                            >
                                <JTranslation typeCase="pascal" text={'Approve Shift'} />
                            </button>
                        </div>
                    </div>
                </>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default ApproveTakenShiftSideBar