import { AlertVariant, FileType, HttpMethods, ScheduleTypeValues } from '../constants/constants'
import _ from 'lodash';
import { AxiosResponse } from 'axios'
import { capitalizeFirstLetter, getErrorMessage, getNumberWithTwoDecimals } from './utils'
import { Menu, Price, Payload, ScheduleMenuId, MenuSchedule, DisableMenuSchedule, PeriodicSchedules, BreadCrumbProp, Gallery, CustomMenuGuestView, MenuItem, FnBGlobalSetting } from '../constants/staticTypes'
import { NO_SCHEDULE } from '../constants/strings'
import { publicItemDetails, publicMenuList, menuScheduleGetApi, menuScheduleApi, publicItemDetailsBySlug } from '../constants/apiEndPoints'
import { QueryClient, UseMutationResult } from 'react-query'
import { SELECT_MENU_ITEM } from '../constants/queryKeys'
import { Skeleton } from 'primereact/skeleton'
import HttpServiceHelper from './httpServiceHelper'
import moment from 'moment'

export const findDetailsByItemId = ({ itemId, menuData }: { menuData: Menu[], itemId: string }): FindMenuValuesResult | null => {
    for (const category of menuData) {
        for (const subCategory of category.subCategory) {
            for (const menuItem of subCategory.menuItems) {
                if (menuItem.id === itemId) {
                    return {
                        menuName: category.categoryName,
                        categoryName: subCategory.categoryName,
                        itemName: menuItem.itemName,
                        itemPrices: menuItem.itemPrices,
                    }
                }
            }
        }
    }
    return null // Return null if item with the given ID is not found
}
export interface FindMenuValuesResult {
    categoryName: string;
    menuName: string;
    itemName: string;
    itemPrices: Price[];
}

export const findMenuValuesById = async ({ menu: input, targetId }: { menu: Menu[], targetId: string }): Promise<FindMenuValuesResult | null> => {
    // Simulate an asynchronous operation
    return Promise.resolve().then(() => {
        for (const category of input) {
            for (const subCategory of category.subCategory) {
                for (const menuItem of subCategory.menuItems) {
                    if (menuItem.id === targetId) {
                        return {
                            menuName: category.categoryName,
                            categoryName: subCategory.categoryName,
                            itemName: menuItem.itemName,
                            itemPrices: menuItem.itemPrices
                        };
                    }
                }
            }
        }

        return null; // Return null if the ID is not found
    });
};

type findMenuAndCategoryNameType = {
    menuItemId: string | undefined,
    selectedMenu: Menu | undefined,
    isCustomMenu?: boolean,
}

// Asynchronous function to find menuName and categoryName
export const findMenuAndCategoryName = async ({ menuItemId, selectedMenu, isCustomMenu = false }: findMenuAndCategoryNameType): Promise<{ menuName: string, categoryName: string } | null> => {
    // Check if selectedMenu and its properties are defined before proceeding
    if (!selectedMenu?.subCategory) {
        return null;
    }

    // Search through each subCategory in the menu
    for (const subCategory of selectedMenu.subCategory) {
        // Check if subCategory and its properties are defined before proceeding
        if (!subCategory?.menuItems) {
            continue;
        }

        // Search through each menuItem in the subCategory
        for (const menuItem of subCategory.menuItems) {
            // Check if menuItem and its id are defined before proceeding
            if (!menuItem?.id) {
                continue;
            }

            // Check if the current menuItem's id matches the given menuItemId
            if (menuItem.id === menuItemId) {
                return {
                    menuName: isCustomMenu ? selectedMenu.menuName! : selectedMenu.categoryName,
                    categoryName: subCategory.categoryName,
                };
            }
        }
    }

    // Return null if the menuItemId is not found
    return null;
};

type PairingOptions = {
    items: MenuItem[]
    setRecommendedPairingOptions: React.Dispatch<React.SetStateAction<any>>
    fnBGlobalSettings: FnBGlobalSetting
}

export const createRecommendedPairingOptions = ({ items, setRecommendedPairingOptions, fnBGlobalSettings }: PairingOptions) => {
    // Main function to group and sort items
    const grouped: { [key: string]: any[] } = {};

    // Group items by category
    items.forEach((item) => {
        const category = item.categoryDetails?.id!;
        if (category) {
            grouped[category] = grouped[category] || [];
            grouped[category].push(item);
        }
    });

    // Sort categories and items within each category
    const sortedCategories = Object.keys(grouped).sort((a, b) => {
        const aName = grouped[a][0].categoryDetails?.categoryName || '';
        const bName = grouped[b][0].categoryDetails?.categoryName || '';
        return aName.localeCompare(bName);
    });

    const sortedItems: any[] = [];

    // Go through each sorted category and sort items within
    sortedCategories.forEach((category) => {
        const itemsInCategory = grouped[category];
        itemsInCategory.sort((a, b) => a.itemName?.localeCompare(b.itemName));

        // Transform items into the desired output format
        itemsInCategory.forEach((item) => {
            const price = getDefaultPrice(item.itemPrices).map((item) => (item === 'Infinity' ? '...' : `$${item}`))
            sortedItems.push({
                value: item.itemName,
                label: item.isMarketPrice
                    ? `${item.itemName}, (Category: ${item?.categoryDetails?.categoryName}), ${fnBGlobalSettings.marketPriceLabel}`
                    : `${item.itemName}, (Category: ${item?.categoryDetails?.categoryName}), ${price}`,
                id: item.id,
            });
        });
    });

    setRecommendedPairingOptions(sortedItems);
};

// get existing schedule of the current menu item
export const getScheduleMenu = (
    params: ScheduleMenuId,
    mutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onSuccess: (schedule: MenuSchedule) => void,
    onError: (message: string, variant: string) => void
) => {
    mutation.mutate(
        {
            url: menuScheduleGetApi,
            method: HttpMethods.POST,
            data: params,
        },
        {
            onSuccess: (res) => onSuccess(res.data.data),
            onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
        }
    )
}

// get existing schedule of the current menu item
export const addUpdateMenuSchedule = (
    params: MenuSchedule,
    mutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onSuccess: (message: string, variant: string) => void,
    onError: (message: string, variant: string) => void
) => {
    mutation.mutate(
        {
            url: menuScheduleApi,
            method: HttpMethods.POST,
            data: params,
        },
        {
            onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
            onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
        }
    )
}

// get existing schedule of the current menu item
export const disableMenuSchedule = (
    params: DisableMenuSchedule,
    mutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onSuccess: (message: string, variant: string) => void,
    onError: (message: string, variant: string) => void
) => {
    mutation.mutate(
        {
            url: menuScheduleApi,
            method: HttpMethods.POST,
            data: params,
        },
        {
            onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
            onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
        }
    )
}

// get menu items list
export const getMenuItemsPublic = (queryClient: QueryClient) => {
    return HttpServiceHelper({
        url: publicMenuList,
        method: HttpMethods.GET,
        queryClient,
    })
}

// get menu item details
export const getMenuItemsDetails = (queryClient: QueryClient, itemMenuId: string) => {
    return HttpServiceHelper({
        url: publicItemDetails,
        method: HttpMethods.POST,
        queryClient,
        data: {
            itemMenuId,
        },
    })
}

// get menu item details
export const getMenuItemsDetailsBySlug = (queryClient: QueryClient, itemMenuSlug: string) => {
    return HttpServiceHelper({
        url: `${publicItemDetailsBySlug}/${itemMenuSlug}`,
        method: HttpMethods.GET,
        queryClient,
    })
}

// menu item selection
export const setMenuSelection = (menu: Menu | CustomMenuGuestView | undefined) => ({ ...menu })

// save menu item selection
export const saveMenuSelection = (queryClient: QueryClient, menu: Menu | CustomMenuGuestView | undefined) =>
    queryClient.setQueryData(SELECT_MENU_ITEM, setMenuSelection(menu))

// get min price
export const getMinPrice = (itemPrices: Price[]) => {
    let filteredPrices = itemPrices.filter((price) => price.fieldValue !== null)
    let prices = filteredPrices.map((priceValue) => parseFloat(priceValue.fieldValue))
    const minPrice = Math.min(...prices.map((value) => value));
    return [getNumberWithTwoDecimals(minPrice)]
}

// get min price array
export const getMinPriceArray = (itemPrices: Price[]) => {
    return itemPrices.sort((a, b) => (Number(a.fieldValue) > Number(b.fieldValue) ? 1 : -1))
}

// get min price array without sort
export const getMinPriceArrayWithoutSort = (itemPrices: Price[]) => {
    let sortedValue = JSON.parse(JSON.stringify(itemPrices))
    sortedValue.sort((a: Price, b: Price) => (Number(a.fieldValue) > Number(b.fieldValue) ? 1 : -1))

    return itemPrices?.forEach((item, index) => {
        if (sortedValue[0].fieldValue === item.fieldValue) {
            itemPrices?.unshift(item)
            itemPrices?.splice(index + 1, 1)
        }
    })
}

// get default price
export const getDefaultPrice = (itemPrices: Price[]) => {
    let filteredPrices = itemPrices.filter((price) => price.isDefault === true)
    let prices = filteredPrices.map((priceValue) => getNumberWithTwoDecimals(+(priceValue?.fieldValue)))
    if (prices.length) {
        return prices
    } else {
        return getMinPrice(itemPrices)
    }
}

// item view gallery template
export const itemTemplate = (item: any) => {
    if (item.type === FileType.IMAGE) {
        return (
            <img
                src={item.src}
                onError={(e) =>
                    (e.currentTarget.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png')
                }
                alt={item.alt}
                style={{ width: '100%', display: 'block' }}
            />
        )
    } else {
        return <video src={item.src} controls width={600} />
    }
}

// gallery responsive options
export const responsiveOptions = [
    {
        breakpoint: '1024px',
        numVisible: 5,
    },
    {
        breakpoint: '768px',
        numVisible: 3,
    },
    {
        breakpoint: '560px',
        numVisible: 1,
    },
]

// set menu data
export const setMenuData = (data: Menu[], setMenuList: React.Dispatch<React.SetStateAction<Menu[]>>) => {
    data = data?.filter((menu) => menu.isActive)
    const newMenu = data.map((menu) => {
        const newSub = menu.subCategory.map((sub) => {
            const menuItems = sub.menuItems.map((menuItem) => {
                return {
                    ...menuItem,
                }
            })
            return {
                ...sub,
                menuItems: [...menuItems],
            }
        })
        return {
            ...menu,
            subCategory: [...newSub],
        }
    })
    setMenuList([...newMenu] as Menu[])
    return [...newMenu] as Menu[]
}

// skeleton loader for welcome page F&B menu
export const SliderSkeleton = () => (
    <div className="skeleton-loader">
        <Skeleton></Skeleton>
        <Skeleton></Skeleton>
        <Skeleton></Skeleton>
        <Skeleton></Skeleton>
        <Skeleton></Skeleton>
    </div>
)

// initial value for menu schedule component
export const initialScheduleValue: MenuSchedule = {
    id: '',
    isActive: true,
    parentCategoryId: null,
    rowStatus: 'ACTIVE',
    specificDatesSchedules: [],
    periodicSchedules: {
        monday: {
            isActive: false,
            selectedHours: [{ startTime: null, endTime: null }],
        },
        tuesday: {
            isActive: false,
            selectedHours: [{ startTime: null, endTime: null }],
        },
        wednesday: {
            isActive: false,
            selectedHours: [{ startTime: null, endTime: null }],
        },
        thursday: {
            isActive: false,
            selectedHours: [{ startTime: null, endTime: null }],
        },
        friday: {
            isActive: false,
            selectedHours: [{ startTime: null, endTime: null }],
        },
        saturday: {
            isActive: false,
            selectedHours: [{ startTime: null, endTime: null }],
        },
        sunday: {
            isActive: false,
            selectedHours: [{ startTime: null, endTime: null }],
        },
    },
    scheduleUpdatedAt: '',
    scheduleType: ScheduleTypeValues.PERIODIC,
}

export const weekDayOrder = ['monday', 'tuesday', 'wednesday', "thursday", "friday", "saturday", "sunday"]

export const replaceSelectedHoursWithEmptyArray = (schedule: MenuSchedule) => {
    const updatedSchedule: MenuSchedule = { ...schedule };

    for (const day in updatedSchedule.periodicSchedules) {
        const { startTime, endTime } = updatedSchedule.periodicSchedules[day].selectedHours[0];

        if (startTime === null && endTime === null) {
            updatedSchedule.periodicSchedules[day].selectedHours = [];
        }
    }

    return updatedSchedule;
}

export const shouldShowMenuCard = (periodicSchedules: PeriodicSchedules) => {
    const currentDay = moment().format('dddd').toLowerCase();
    const currentTime = moment().format('h:mm A');
    // If the 'qr_scan' flag is present in local storage, skip the scheduled menu filtering and display the entire menu list.
    if(localStorage.getItem('qr_scan')) return NO_SCHEDULE;

    if (periodicSchedules && Object.keys(periodicSchedules).length === 0) {
        return NO_SCHEDULE
    } else if (periodicSchedules && Object.keys(periodicSchedules).length > 0 && periodicSchedules[currentDay]?.isActive) {
        const selectedHours = periodicSchedules[currentDay].selectedHours;

        for (const { startTime, endTime } of selectedHours) {
            if (moment(currentTime, 'h:mm A').isBetween(moment(startTime, 'h:mm A'), moment(endTime, 'h:mm A').subtract(1, 'minute'), null, '[]')) {
                return true;
            }
        }
    }

    return false;
}

export const validateTimeRanges = (schedule: PeriodicSchedules): string[] => {
    let errors: string[] = [];

    for (const day in schedule) {
        const { isActive, selectedHours } = schedule[day];

        if (isActive) {
            for (const { startTime, endTime } of selectedHours) {
                if (startTime !== null && endTime !== null) {
                    const startMoment = moment(startTime, 'h:mm A');
                    const endMoment = moment(endTime, 'h:mm A');

                    if (!startMoment.isValid()) {
                        errors.push(`Invalid start time on ${day}`);
                    }

                    if (!endMoment.isValid()) {
                        errors.push(`Invalid end time on ${day}`);
                    }

                    if (startMoment.isValid() && endMoment.isValid() && startMoment.isAfter(endMoment)) {
                        errors.push(`Start time is greater than end time on ${capitalizeFirstLetter(day)}.`);
                    }

                    if (startMoment.isSame(endMoment)) {
                        errors.push(`Start time and end time are the same on ${capitalizeFirstLetter(day)}.`);
                    }
                }
            }
        }
    }

    return errors;
}

export const sliderSettings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    variableWidth: true,

    responsive: [
        {
            breakpoint: 1700,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 5,
            }
        },
        {
            breakpoint: 1485,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            }
        },
        {
            breakpoint: 1298,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        },
        {
            breakpoint: 1133,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            }
        },
        {
            breakpoint: 961,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                //initialSlide: 3
            }
        },
        {
            breakpoint: 775,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                //initialSlide: 3
            }
        },
        {
            breakpoint: 780,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                //initialSlide: 3
            }
        },
        {
            breakpoint: 735,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                //initialSlide: 3
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                //initialSlide: 2
            }
        },
        {
            breakpoint: 549,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                //initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

export const getValueAfterHash = (str: string): string | null => {
    const index = str.indexOf('#');
    if (index !== -1) {
        return str.substring(index + 1);
    }
    return null;
}
interface CategoryType {
    categoryName: string;
    id: string;
    urlSlug: string;
}

export const extractCategoryInfo = (data: any): CategoryType[] => {
    const result: CategoryType[] = [];

    if (data.isActive && data.subCategory && Array.isArray(data.subCategory)) {
        for (const subCategory of data.subCategory) {
            const { categoryName, id, isActive, urlSlug } = subCategory;
            if (isActive) result.push({ categoryName, id, urlSlug });
        }
    }

    return result;
}

export const getItemTags = (data: Menu): string[] => {
    const itemTags: Set<string> = new Set();

    for (const category of data.subCategory) {
        for (const menuItem of category.menuItems) {
            if (category.isActive) {
                if (menuItem.isActive && menuItem.itemTag !== null) {
                    const tags: string[] = menuItem.itemTag.split(",").map(tag => tag.trim());
                    tags.forEach(tag => itemTags.add(tag));
                }
            }
        }
    }


    return Array.from(itemTags);
}

type filterMenuItemsByTag = {
    dataStructure: any
    selectedTags: string[]
}

export const filterSubCategoryByItemTag = ({ dataStructure, selectedTags }: filterMenuItemsByTag): any => {
    const filteredStructure = _.cloneDeep(dataStructure);

    filteredStructure.subCategory = filteredStructure.subCategory.filter((subCat: any) => {
        if (subCat.menuItems) {
            const filteredMenuItems = subCat.menuItems.filter((menuItem: any) => {
                if (menuItem.itemTag) {
                    const itemTags = menuItem.itemTag.split(",").map((tag: string) => tag.trim());
                    return selectedTags.some((tag: string) => itemTags.includes(tag));
                }
                return false;
            });

            subCat.menuItems = filteredMenuItems;
            return filteredMenuItems.length > 0;
        }
        return false;
    });

    return filteredStructure;
}


type Result = {
    menuName: string;
    categoryName: string;
    itemName: string;
};

export const getBreadCrumbPath = ({ data, id }: BreadCrumbProp): Result | null => {
    for (const item of data) {
        if (item.subCategory && Array.isArray(item.subCategory)) {
            for (const subCategory of item.subCategory) {
                if (subCategory.menuItems && Array.isArray(subCategory.menuItems)) {
                    for (const menuItem of subCategory.menuItems) {
                        if (menuItem.id === id) {
                            return {
                                menuName: item.categoryName,
                                categoryName: subCategory.categoryName,
                                itemName: menuItem.itemName
                            };
                        }
                    }
                }
            }
        }
    }
    return null;
}

export const galleryViewSettings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    variableWidth: true,

    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

export const processImageURLsForGallery = (URLs: string[]): Gallery[] => {
    return URLs.map((url) => ({
        src: url,
        type: "image"
    }));
};

export const processVideoURLsForGallery = (URLs: string[]): Gallery[] => {
    return URLs.map((url) => ({
        src: url,
        type: "video"
    }));
};
