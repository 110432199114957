import { TabPanel, TabView } from 'primereact/tabview'
import { JTranslation } from '../../helpers/jTranslate'
import withSidebar from '../../hoc/withSidebar'
import { useState } from 'react'
import { PermissionKeys } from '../../constants/constants'
import BrandingConfig from '../branding_config/BrandingConfig'
import WelcomeScreenConfig from '../welcome_screen_config/WelcomeScreenConfig'
import GeneralSettings from './GeneralSettings'
import { checkPermission } from '../../helpers/utils'
import { useQueryClient } from 'react-query'
import AboutUs from './AboutUs'
import { useWindowSize } from 'react-use'
import Area from './Area'

function Settings() {
	const queryClient = useQueryClient()
	const { width: screenWidth } = useWindowSize()
	const BREAKPOINT = 570

	// STATE VARIABLES
	const [activeIndex, setActiveIndex] = useState(0)

	return (
		<>
			<div className="page-title">
				<h5 className="d-flex">
					<JTranslation typeCase="pascal" text={'Settings'} />
				</h5>
			</div>

			<div className="col-md-12 col-lg-12 mb-3 h-100">
				<div className="card h-100 overflow-hidden">
					<div className="card-header p-0 border-0">
						<div className="d-flex align-items-center">
							<TabView
								className={`mt-2 w-100 settings-tab ${screenWidth > BREAKPOINT ? 'hide-tab-arrow' : ''}`}
								activeIndex={activeIndex}
								onTabChange={(e) => setActiveIndex(e.index)}
								scrollable={true}								
							>
								<TabPanel
									header={<JTranslation typeCase="pascal" text={'General Settings'} />}
								></TabPanel>
								<TabPanel
									disabled={!checkPermission(queryClient, PermissionKeys.MANAGE_BRANDING)}
									header={<JTranslation typeCase="pascal" text={'Branding'} />}
								></TabPanel>
								<TabPanel
									disabled={!checkPermission(queryClient, PermissionKeys.MANAGE_WELCOME_SCREEN)}
									header={<JTranslation typeCase="pascal" text={'Welcome Screen'} />}
								></TabPanel>
								<TabPanel
									header={<JTranslation typeCase="pascal" text={'About Us'} />}
								></TabPanel>
								<TabPanel
									className='pe-5' header={<JTranslation typeCase="pascal" text={'Area'} />}
								></TabPanel>
							</TabView>
						</div>
					</div>
					<div className="card-body overflow-hidden">
						{activeIndex === 0 && <GeneralSettings />}
						{checkPermission(queryClient, PermissionKeys.MANAGE_BRANDING) && activeIndex === 1 && (
							<BrandingConfig />
						)}
						{checkPermission(queryClient, PermissionKeys.MANAGE_WELCOME_SCREEN) && activeIndex === 2 && (
							<WelcomeScreenConfig />
						)}
						{activeIndex === 3 && <AboutUs />}
						{activeIndex === 4 && <Area />}
					</div>
				</div>
			</div>
		</>
	)
}

export default withSidebar(Settings)
