import { useQueryClient } from "react-query";
import { PermissionKeys, SubPermissionKeys } from "../../../constants/constants";
import { COUNT, HELP_NO_OFFER, NO_OFFER_TEXT, SAVE, TEXT, NO_OFFER } from "../../../constants/strings";
import { updateBetterLuckData } from "../../../helpers/gameHelper";
import { checkPermission, popover } from "../../../helpers/utils";
import useMutationHook from "../../../hooks/useMutationHook";
import { OverlayTrigger } from "react-bootstrap";
import { useTranslation } from "../../../hooks/useTranslation";
import { JTranslation } from "../../../helpers/jTranslate";
import MlxPopover from "../../common/MlxPopover";

export type BetterLuckData = {
    prizeWinning: string,
    displayOnGameCount: number | string
}

type Props = {
    betterLuckData: BetterLuckData,
    setBetterLuckData: React.Dispatch<React.SetStateAction<BetterLuckData>>,
    displayToast: (message: string, variant: string) => void
}

function NoOfferText({ betterLuckData, setBetterLuckData, displayToast }: Props) {
    const queryClient = useQueryClient();
    const betterLuckTextUpdateMutation = useMutationHook(queryClient, true);

    return <div className=" px-3  bg-no-offer">
        <div className="row gx-3 pt-3 pb-2">
            <div className="row d-flex align-items-center">
                <div className="col-auto pe-0 mb-2">
                    <div className="help-small align-items-center">
                        <label className=" "><JTranslation typeCase="pascal" text={"No offer"} /></label>
                        <MlxPopover data={{title:NO_OFFER, body:HELP_NO_OFFER}}/>
                    </div>
                </div>
                <div className="col-auto col-md-3 me-3 mb-2">
                    <input type="text" className="form-control" id="inputPassword"
                        autoComplete="off"
                        placeholder={useTranslation(NO_OFFER_TEXT, 'capitalize')}
                        maxLength={10}
                        value={betterLuckData.prizeWinning}
                        data-testid="no-offer-text"
                        onChange={(e) => setBetterLuckData({
                            ...betterLuckData,
                            prizeWinning: e.target.value
                        })} />
                </div>
                <div className="col-auto p-0  display-count mb-2">
                    <label className=" "><JTranslation typeCase="pascal" text={"Display Count"} /></label>

                </div>
                <div className="col-md col-md-2 col-auto mb-2">
                    <input type="text" className="form-control" id="inputPassword"
                        autoComplete="off"
                        placeholder={useTranslation(COUNT, 'capitalize')}
                        maxLength={1}
                        value={betterLuckData.displayOnGameCount}
                        data-testid="display-count"
                        onChange={(e) => setBetterLuckData({
                            ...betterLuckData,
                            displayOnGameCount: e.target.value.replace(/\D/, '')
                        })} />
                </div>
                <div className="col-md col-auto  d-flex mb-2">
                    <button className="btn btn-custom-primary"
                        type="button" data-testid="save-btn"
                        disabled={betterLuckData.prizeWinning?.trim().length === 0 ||
                            betterLuckData.displayOnGameCount === "" || betterLuckData.displayOnGameCount === "0" ||
                            (!checkPermission(queryClient, PermissionKeys.MANAGE_GAME, SubPermissionKeys.ADD) &&
                                !checkPermission(queryClient, PermissionKeys.MANAGE_GAME, SubPermissionKeys.EDIT))}
                        onClick={() => {
                            if (checkPermission(queryClient, PermissionKeys.MANAGE_GAME, SubPermissionKeys.ADD) ||
                                checkPermission(queryClient, PermissionKeys.MANAGE_GAME, SubPermissionKeys.EDIT)) {
                                updateBetterLuckData(queryClient, betterLuckData,
                                    betterLuckTextUpdateMutation, displayToast)
                            }
                        }}><JTranslation typeCase="pascal" text={SAVE} /></button>

                </div>
            </div>
        </div>
    </div>
}

export default NoOfferText;