import { useEffect } from 'react'
import './styles/redirection.scss'
import { getShortCodeToUrlApi } from '../../constants/apiEndPoints'
import { HttpMethods } from '../../constants/constants'
import { routes } from "../../constants/routes"
import { useNavigate, useParams } from 'react-router-dom'
import HttpServiceHelper from '../../helpers/httpServiceHelper'

const RedirectPage = () => {
  const navigate = useNavigate()
  const { shortCode } = useParams()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await HttpServiceHelper({
          url: getShortCodeToUrlApi,
          method: HttpMethods.POST,
          noAuth: true,
          data: {
            shortCode,
          },
        })

        const { url } = data?.data || {}
        if (url) {
          window.location.replace(url)
        } else {
          console.log('Error fetching data:', 'No URL found')
          // Handle error case
          // Redirect to an error page or display an error message
          navigate(routes.welcome_page, {
            replace: true
          })
        }
      } catch (error) {
        console.error('Error fetching data:', error)
        // Redirect to an error page or display an error message
        navigate(routes.welcome_page, {
          replace: true,
        })
      }
    }

    fetchData()
  }, [navigate, shortCode])

  return (
    <div className='h-100 redirection-container'>
      <div className='redirection-wrapper'>
        <span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </span>
        <div className='base'>
          <span></span>
          <div className='face'></div>
        </div>
      </div>
      <div className='longfazers'>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <h1>Redirecting</h1>
    </div>
  )
}

export default RedirectPage
