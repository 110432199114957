import { AxiosResponse } from "axios";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useContext, useEffect, useState } from "react";
import { QueryClient } from "react-query";
import { GUEST_VISITED_GRID } from "../../constants/queryKeys";
import { GuestInfo } from "../../constants/staticTypes";
import { ANNIVERSARY, BIRTHDAY, EMAIL_TEXT, FULL_NAME, LAST_VISIT, MOBILE_TEXT, NO_OF_SURVEY, NO_OF_VISIT, ZIP_CODE } from "../../constants/strings";
import { getGuestVisitedDataGrid } from "../../helpers/dashboardHelper";
import { convertUTCtoLocalByOffset, toPascalCase } from "../../helpers/utils";
import useQueryHook from "../../hooks/useQueryHook";
import { JTranslation, TranslationContext, jTranslationText } from "../../helpers/jTranslate";
import { useWindowSize } from "react-use";
import { MOBILE_VIEW_BREAKPOINT } from "../../constants/constants";
import { Card } from "primereact/card";
import { Badge } from "primereact/badge";
import { DataView } from "primereact/dataview";
import MlxTooltip from "../common/MlxTooltip";

type Props = {
    queryClient: QueryClient
}

function GuestVisitedGrid({ queryClient }: Props) {
    const [userData, setUserData] = useState<GuestInfo[]>([]);
    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext

    const [translationText, setTranslatedText] = useState<{
        paginationTemplate: string,
        emptyMessage: string,
    }>({ 
        paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
        emptyMessage: 'No guests info found',
    });

    const { width, height } = useWindowSize()
	const breakPoint = MOBILE_VIEW_BREAKPOINT

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: 'Showing 1 to 2 of 3 items', typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: 'No guests info found', typeCase: 'pascal', translationContext }),
                ])
                
                setTranslatedText({
                    paginationTemplate:
                        translations[0]
                            ?.replace('1', '{first}')
                            ?.replace('2', '{last}')
                            ?.replace('3', '{totalRecords}') ?? 'Showing {first} to {last} of {totalRecords} items',
                    emptyMessage: translations[1] ?? 'No guests info found',
                })
            } catch {
                setTranslatedText({
                    paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
                    emptyMessage: 'No guests info found',
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    // api fetch success
    const onFetchSuccess = (res: AxiosResponse<any, any>) => {
        setUserData(res.data?.data);
    }

    const emailBodyTemplate = (guest: GuestInfo) => {
        return <>
            <MlxTooltip target=".email-text">
                <a className="email-text" data-pr-tooltip={guest.emailId} data-pr-position="bottom" href={"mailto:" + guest.emailId}>{guest.emailId}</a>
            </MlxTooltip>
        </>
    }

    // fetch frequest visit data
    const gridData = useQueryHook(GUEST_VISITED_GRID,
        () => getGuestVisitedDataGrid(queryClient), onFetchSuccess);

    
    const itemTemplate = (guest: GuestInfo) => {
        return (
            <Card className="data-view-card mt-2 d-flex flex-column justify-content-even">
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <p className="m-0">{toPascalCase(guest.fullName)}</p>
                    <span className="d-flex align-items-center">
                        <span className="small-text">
                            <JTranslation typeCase="pascal" text={'Visits'} />
                        </span>
                        <Badge value={guest.totalVisitCount} className="ms-2" />
                    </span>
                </div>
                <div className='mt-2'>
                    <div className="d-flex flex-row align-items-center">
                        <p className="m-0">
                            {emailBodyTemplate(guest)}{guest.phoneNumber ? ', ' : ''}
                            {guest.phoneNumber}
                        </p>
                    </div>
                    {guest.birthDate && 
                        <div className="d-flex flex-row align-items-center">
                            <span className="me-2"><JTranslation typeCase="capitalize" text={BIRTHDAY} />:</span>
                            <p className="m-0 font-bold">{convertUTCtoLocalByOffset(guest.birthDate)}</p>
                        </div>
                    }   
                    {guest.anniversaryDate && 
                        <div className="d-flex flex-row align-items-center">
                            <span className="me-2"><JTranslation typeCase="capitalize" text={ANNIVERSARY} />:</span>
                            <p className="m-0 font-bold">{convertUTCtoLocalByOffset(guest.anniversaryDate)}</p>
                        </div>
                    } 
                    {guest.zipCode && 
                        <div className="d-flex flex-row align-items-center">
                            <span className="me-2"><JTranslation typeCase="capitalize" text={ZIP_CODE} />:</span>
                            <p className="m-0 font-bold">{guest.zipCode}</p>
                        </div>
                    }                 
                </div>
                <div className="view-footer mt-2 pt-3 d-flex flex-row justify-content-between align-items-center">
                        <div className="d-flex flex-row align-items-center"></div>
                        <div className="d-flex flex-row align-items-center">
                            <span className="me-2"><JTranslation typeCase="capitalize" text={LAST_VISIT} />:</span>
                            <p className="m-0 font-bold">{convertUTCtoLocalByOffset(guest.lastVisitedAt)}</p>
                        </div>
                </div>
            </Card>
        )
    }

    return <div className="card h-100 custom-shadow border-radius-05 border-0">
        <div className="card-header order-radius-card-header-05 border-0 py-3">
            <div className="d-flex  align-items-center">
                <div className="flex-grow-1">
                    <h4 className="mb-0 title"><JTranslation typeCase="pascal" text={"Frequently Visited Guests"} /></h4>
                </div>
                <div className="flex-grow-0  d-flex justify-content-end ">
                    <div className="col-md-12">
                    </div>
                </div>
            </div>
        </div>
        <div className="card-body flex-grow-1 overflow-hidden">
            <div className="survey-section ">
                <div className="row">
                    <div className="col-lg-12" style={{ height: 'auto', overflow: 'auto' }}>
                        {width <= breakPoint ? (
                            <DataView
                                value={userData}
                                layout={'list'}
                                itemTemplate={itemTemplate}
                                paginator
                                rows={5}
                                paginatorClassName="p-dataview-customers"
                                dataKey="id"
                                emptyMessage={translationText.emptyMessage}
                                currentPageReportTemplate={translationText.paginationTemplate}
                            />
                            ) : (
                                <DataTable value={userData} className="p-datatable-customers" rows={10}
                                    currentPageReportTemplate={translationText.paginationTemplate}
                                    scrollable scrollHeight="flex"
                                    dataKey="id" 
                                    loading={gridData.isLoading} responsiveLayout="scroll"
                                    emptyMessage={translationText.emptyMessage}>
                                    <Column field="fullName" header={<JTranslation typeCase="pascal" text={FULL_NAME} />} sortable className="word-break-grid" style={{ minWidth: '15rem', maxWidth: '20rem' }} />
                                    <Column field="phoneNumber" header={<JTranslation typeCase="pascal" text={MOBILE_TEXT} />} sortable style={{ maxWidth: '10rem', minWidth: '10rem' }} />
                                    <Column field="emailId" header={<JTranslation typeCase="pascal" text={EMAIL_TEXT} />} className="overflow-ellipsis" sortable style={{ maxWidth: '15rem', minWidth: '15rem' }}
                                        body={emailBodyTemplate} />
                                    <Column field="birthDate" header={<JTranslation typeCase="pascal" text={BIRTHDAY} />} sortable style={{ maxWidth: '9rem', minWidth: '9rem' }}
                                        body={(guest: GuestInfo) => <>{convertUTCtoLocalByOffset(guest.birthDate)}</>} />
                                    <Column field="anniversaryDate" header={<JTranslation typeCase="pascal" text={ANNIVERSARY} />} sortable style={{ maxWidth: '9rem', minWidth: '9rem' }}
                                        body={(guest: GuestInfo) => <>{convertUTCtoLocalByOffset(guest.anniversaryDate)}</>} />
                                    <Column field="lastVisitedAt" header={<JTranslation typeCase="pascal" text={LAST_VISIT} />} sortable style={{ maxWidth: '9rem', minWidth: '9rem' }}
                                        body={(guest: GuestInfo) => <>{convertUTCtoLocalByOffset(guest.lastVisitedAt)}</>} />
                                    <Column field="zipCode" header={<JTranslation typeCase="pascal" text={ZIP_CODE} />} sortable style={{ maxWidth: '10rem', minWidth: '10rem' }} />
                                    <Column field="totalSurveyCount" header={<JTranslation typeCase="pascal" text={NO_OF_SURVEY} />} sortable style={{ minWidth: '9rem' }} />
                                    <Column field="totalVisitCount" header={<JTranslation typeCase="pascal" text={NO_OF_VISIT} />} sortable style={{ minWidth: '9rem' }} />
                                </DataTable>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default GuestVisitedGrid;