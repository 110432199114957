import moment from "moment";
import { guestSurveyRatingGraph, guestVisitedData, guestVisitedGraph } from "../constants/apiEndPoints";
import { HttpMethods } from "../constants/constants";
import { QueryClient } from "react-query";
import HttpServiceHelper from "./httpServiceHelper";

// guest visited graph data
export const getGuestVisitedData = (
    queryClient: QueryClient,
    year: number) => {
    return HttpServiceHelper({
        url: `${guestVisitedGraph}?startDate=01/01/${year}&endDate=12/31/${year}`,
        method: HttpMethods.GET,
        queryClient
    });
}

// graph initial data for guest visited
export const guestVisitedInitialGraphData = () => {
    const dataset = [] as number[];
    const labels = moment.months().map(month => {
        dataset.push(0);
        return month.substring(0, 3)
    });
    const graphData = {
        labels,
        datasets: [
            {
                label: 'Guest Visited',
                data: dataset,
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Guest Attended Survey',
                data: dataset,
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };
    return graphData;
}

// guest visited grid data
export const getGuestVisitedDataGrid = (
    queryClient: QueryClient) => {
    return HttpServiceHelper({
        url: guestVisitedData,
        method: HttpMethods.GET,
        queryClient
    });
}

// guest survey rating graph data
export const getGuestSurveyRatingData = (
    queryClient: QueryClient,
    year: number) => {
    return HttpServiceHelper({
        url: `${guestSurveyRatingGraph}?startDate=01/01/${year}&endDate=12/31/${year}`,
        method: HttpMethods.GET,
        queryClient
    });
}
