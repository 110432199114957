import React, { SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import { ACTIVATE_ITEM, DEACTIVATE_ITEM, DELETE_WARNING, ACTIVATE, DEACTIVATE, EDIT, DELETE } from "../../../../constants/strings";
import { AxiosResponse } from "axios";
import { checkPermission, getErrorMessage } from "../../../../helpers/utils";
import { CSS } from '@dnd-kit/utilities';
import { FaEdit, FaToggleOff, FaToggleOn } from "react-icons/fa";
import { getMenuItemEditData, cloneMenuItem, getAsyncCustomMenuItem } from "../../../../helpers/menuManagementHelper";
import { MenuItem, CustomMenuCategoriesAndItems, Payload, SingleMenuItem } from "../../../../constants/staticTypes";
import { AlertVariant, PermissionKeys, SubPermissionKeys, WarningCallback } from "../../../../constants/constants";
import { QueryClient, UseMutationResult } from "react-query";
import { useSortable } from "@dnd-kit/sortable";
import placeholderImage from "../../../../assets/images/menu/540x360.png";
import useMutationHook from '../../../../hooks/useMutationHook';
import { TranslationContext, jTranslationText } from '../../../../helpers/jTranslate';
import MlxTooltip from '../../../common/MlxTooltip';

type Props = {
    item: MenuItem,
    onActiveInactiveToggle: (id: string, message: string, callbackType: SetStateAction<WarningCallback | undefined>) => void
    onSuccess: (message: string) => void,
    queryClient: QueryClient,
    selectedItem: MenuItem | undefined,
    setAddItem: React.Dispatch<React.SetStateAction<boolean>>,
    setCallbackType: React.Dispatch<React.SetStateAction<WarningCallback | undefined>>,
    setDeleteItem: React.Dispatch<React.SetStateAction<string | undefined>>,
    setEditItem: React.Dispatch<React.SetStateAction<CustomMenuCategoriesAndItems | MenuItem | undefined>>,
    setSelectedItem: React.Dispatch<React.SetStateAction<MenuItem | undefined>>,
    setWarning: React.Dispatch<React.SetStateAction<boolean>>,
    setWarningMessage: React.Dispatch<React.SetStateAction<string>>,
    showAddItem: boolean,
    upsertMenu: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    setVariant: React.Dispatch<React.SetStateAction<string>>,
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
    setShow: React.Dispatch<React.SetStateAction<boolean>>,
}

function CustomItemComponent({
	item,
	setSelectedItem,
	selectedItem,
	setEditItem,
	setAddItem,
	showAddItem,
	queryClient,
	setCallbackType,
	setDeleteItem,
	setWarningMessage,
	setWarning,
	onActiveInactiveToggle,
	onSuccess,
	upsertMenu,
    setVariant,
    setErrorMessage,
    setShow
}: Props) {

    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext

    const [translationText, setTranslatedText] = useState<{
        deActivate: string,
        activate: string,
        edit: string,
        delete: string,
      }>({ 
        deActivate: DEACTIVATE,
        activate: ACTIVATE,
        edit: EDIT,
        delete: DELETE,
    });

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: DEACTIVATE, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: ACTIVATE, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: EDIT, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: DELETE, typeCase: 'pascal', translationContext }),
                ])
                setTranslatedText({
                    deActivate: translations[0] ?? DEACTIVATE,
                    activate: translations[1] ?? ACTIVATE,
                    edit: translations[2] ?? EDIT,
                    delete: translations[3] ?? DELETE,
                })
            } catch {
                setTranslatedText({
                    deActivate: DEACTIVATE,
                    activate: ACTIVATE,
                    edit: EDIT,
                    delete: DELETE,
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    const itemCard = useRef(null)
	const getSingleCustomMenuItemMutation = useMutationHook(queryClient, true)

    const getAsyncCustomMenuDetails = (editItem: MenuItem) => {
		const param: SingleMenuItem = { id: editItem?.id as string}
		getAsyncCustomMenuItem(param, getSingleCustomMenuItemMutation)
			.then((response: any) => {
				onSingleMenuItemSuccess(response?.data?.data)
			})
			.catch((error) => {
				onError(getErrorMessage(error), AlertVariant.ERROR)
			})
	}

    const onSingleMenuItemSuccess = (data: MenuItem) => {
		setEditItem(getMenuItemEditData(data))
		setAddItem(!showAddItem)
	}

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition } = useSortable({ id: item.id as string });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        touchAction: "pan-y"
    };

    // on api error
    const onError = (message: string, variant: string) => {
        setVariant(variant);
        setErrorMessage(message);
        setShow(true);
    }

    const cloneItemApiCall = (itemMenuId: string) => {
        cloneMenuItem(upsertMenu, {
            itemMenuId: itemMenuId,
        }, onSuccess, onError);
    }

    return <div className="col-12 col-sm-4 col-md-3 col-lg-2" key={item.id} onClick={() => setSelectedItem(item)} ref={setNodeRef} style={style}
        {...attributes} {...listeners}>
        <div
            ref={itemCard}
            // onMouseEnter={() => {
            //     // @ts-ignore
            //     itemCard.current.click()
            // }}
            className={"viewMenubox-container mb-3 item " + (selectedItem?.id === item.id && "active ") + (!item.isActive && " in-active")}
        >
            <div className="menubox-img-contanier category custom-height">
                <img src={item.signedPhotoKeys && item.signedPhotoKeys.length > 0 ? item.signedPhotoKeys[0] : placeholderImage} alt="menu item" className="img-fluid" />
            </div>
            <div className="menubox-toolbox">
                <MlxTooltip target=".menu-title">
                <div className="menu-title" data-pr-tooltip={item.itemName} data-pr-position="bottom">
                    {item.itemName}
                </div>
                </MlxTooltip>
            </div>

            <div className="menubox-toolbox ">
                {item.isActive ?
                    <FaToggleOn title={translationText.deActivate} className={"view " +
                        (!checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.STATUS) &&
                            " disabled")} onClick={() =>
                                onActiveInactiveToggle(item.id as string, DEACTIVATE_ITEM, WarningCallback.ACTIVATE_DEACTIVATE_ITEM)} /> :
                    <FaToggleOff title={translationText.activate} className={"view " +
                        (!checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.STATUS) &&
                            " disabled")} onClick={() =>
                                onActiveInactiveToggle(item.id as string, ACTIVATE_ITEM, WarningCallback.ACTIVATE_DEACTIVATE_ITEM)} />}
                <FaEdit title={translationText.edit} className={"edit " +
                    (!checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.EDIT) &&
                        " disabled")} onClick={() => {
                            if (checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.EDIT)) {
                                getAsyncCustomMenuDetails(item)
                            }
                        }} />
                <i title={translationText.delete} className={"ri-delete-bin-line delete " +
                    (!checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.DELETE) &&
                        " disabled")} onClick={() => {
                            if (checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.DELETE)) {
                                setCallbackType(WarningCallback.DEL_ITEM);
                                setDeleteItem(item.id);
                                setWarningMessage(DELETE_WARNING);
                                setWarning(true);
                            }
                        }}></i>
            </div>

        </div>
    </div>
}

export default CustomItemComponent;