import { UseMutationResult } from 'react-query'
import { userRolesApi } from '../constants/apiEndPoints'
import { AlertVariant, HttpMethods } from '../constants/constants'
import HttpServiceHelper from './httpServiceHelper'
import { AxiosResponse } from 'axios'
import { JobRoleAddForm, JobRoleEditForm, Payload } from '../constants/staticTypes'
import { getErrorMessage } from './utils'

// payload for fetching user roles
export const getRolesList = ({ searchValue = '', limit = 0, page = 0, sort = 'DESC' } = {}) => {
	let apiUrl = userRolesApi

	const queryParams = new URLSearchParams()
	if (searchValue.length > 0) {
		queryParams.append(`search[jobRoleName]`, searchValue)
	}
	if (limit > 0) {
		queryParams.append('limit', limit.toString())
	}
	if (sort) {
		queryParams.append('sort[jobRoleName]', sort)
	}
    if (sort) {
		queryParams.append('page', page.toString())
	}

	if (queryParams.toString()) {
		apiUrl += `?${queryParams.toString()}`
	}

	return HttpServiceHelper({
		url: apiUrl,
		method: HttpMethods.GET,
	})
}

// create new role
export const createNewRole = (
	roleListMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	roleData: JobRoleAddForm,
	onSuccess: (message: string, variant: string) => void,
	onError: (res: string, variant: string) => void
) => {
	roleListMutation.mutate(
		{
			url: userRolesApi,
			method: HttpMethods.POST,
			data: roleData,
		},
		{
			onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

// update role
export const updateRole = (
	roleListMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	roleData: JobRoleEditForm,
	onSuccess: (message: string, variant: string) => void,
	onError: (res: string, variant: string) => void
) => {
	roleListMutation.mutate(
		{
			url: userRolesApi,
			method: HttpMethods.PUT,
			data: roleData,
		},
		{
			onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

// delete role
export const deleteJobRole = (
	roleListMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	roleData: { id: string },
	onSuccess: (message: string, variant: string) => void,
	onError: (res: string, variant: string) => void
) => {
	roleListMutation.mutate(
		{
			url: userRolesApi,
			method: HttpMethods.DELETE,
			data: roleData,
		},
		{
			onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}
