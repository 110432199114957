import { useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import { FileType } from "../../../../constants/constants";

type Props = {
    image: string,
    index: number,
    setIndex: React.Dispatch<React.SetStateAction<{
        type: FileType;
        index: number;
    }>>,
    setWarning: React.Dispatch<React.SetStateAction<boolean>>
}

function ImageThumbnail({ image, index, setIndex, setWarning }: Props) {

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition } = useSortable({ id: image });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        touchAction: "none"
    };

    return <div className=" col-6 col-md-4 col-lg-6 col-xl-6 mb-2" key={image} {...attributes} {...listeners} ref={setNodeRef} style={style}>
        <div className="img-thumb-main">
            <div className="view-img-preview">
                <img src={image} className="img-fluid" alt=" " />
            </div>
            <div className="preview-tool text-end">
                <i className="ri-delete-bin-6-line delete" onClick={() => {
                    setIndex({
                        type: FileType.IMAGE,
                        index
                    });
                    setWarning(true);
                }}></i>
            </div>
        </div>
    </div>
}

export default ImageThumbnail;