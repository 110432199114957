import { AlertVariant, HttpMethods } from '../constants/constants';
import { AxiosResponse } from 'axios';
import { Badge } from 'primereact/badge';
import { eventApi, eventListGuestViewApi, fetchNextOccurrence, listPostsApi, newsAndEventsApi, newsAndEventsSlugApi, newsAndEventsStatusApi, newsApi, newsListGuestViewApi } from '../constants/apiEndPoints';
import { EventPostForApi, EventPostFormData, Every, FetchNextOccurrence, FormatDateFromAntdForApiType, FormatTimeFromAntdForApiType, GetNewsAndEventsApiParams, NewsAndEventsId, NewsAndEventsListType, NewsAndEventsSlugId, NewsAndEventStatusType, NewsPost, NewsPostForApi, ParseTimeForAntdTimePickerType, Payload, PostResponseType, PostType, RecurringPattern } from '../constants/staticTypes';
import { getErrorMessage } from './utils';
import { NEWS_N_EVENTS_WARNINGS } from '../constants/strings';
import { QueryClient } from 'react-query';
import { UseMutationResult } from 'react-query';
import * as Yup from 'yup';
import dayjs, { Dayjs } from 'dayjs';
import HttpServiceHelper from './httpServiceHelper';
import moment from 'moment';

export const today = new Date()
// Setting the time to 00:00:00
today.setHours(0, 0, 0, 0)

export const initialValuesForNews: NewsPost = {
  title: '',
  postTags: '',
  urlSlug: '',
  description: '',
  publishDate: new Date(),
  expiryDate: null,
  photoKeys: [],
  videoKeys: [],
  saveAction: 'create',
}

export const initialValuesForEvents: EventPostFormData = {
  title: '',
  postTags: '',
  location: '',
  description: '',
  photoKeys: [],
  videoKeys: [],
  publishDate: new Date(),
  startDate: null,
  endDate: null,
  recurringStartTime: '',
  recurringEndTime: '',
  startTime: '',
  endTime: '',
  urlSlug: '',
  saveAction: 'create',
  isRecurring: false,
  every: 'every',
  recurringPattern: '',
}

export const emptyPostValues: PostResponseType = {
  createdAt: 0,
  createdAtDate: '',
  description: '',
  endDate: '',
  endTime: '',
  expiryDate: '',
  id: '',
  location: '',
  photoKeys: [],
  postTags: '',
  postType: '',
  publishDate: '',
  publishedStatus: 'DRAFT',
  rowStatus: '',
  saveAction: 'draft',
  signedPhotosKeys: [],
  signedVideoKeys: [],
  startDate: '',
  startTime: '',
  title: '',
  updatedAt: 0,
  updatedAtDate: '',
  urlSlug: '',
  videoKeys: [],
  isRecurring: false,
  recurringOptions: {},
  recurringPattern: '',
  every: 'every',
}

export const weekdays = [
  { label: 'Sunday', value: 'sunday' },
  { label: 'Monday', value: 'monday' },
  { label: 'Tuesday', value: 'tuesday' },
  { label: 'Wednesday', value: 'wednesday' },
  { label: 'Thursday', value: 'thursday' },
  { label: 'Friday', value: 'friday' },
  { label: 'Saturday', value: 'saturday' },
];

export const dayOptions = [
  { label: 'First', value: 'first' },
  { label: 'Second', value: 'second' },
  { label: 'Third', value: 'third' },
  { label: 'Fourth', value: 'fourth' },
  { label: 'Last', value: 'last' },
];

export const monthDays = Array.from({ length: 31 }, (_, index) => {
  const day = index + 1;
  return { label: `${day}`, value: `${day}` };
});

export const monthOptions = [
  { label: 'January', value: 'january' },
  { label: 'February', value: 'february' },
  { label: 'March', value: 'march' },
  { label: 'April', value: 'april' },
  { label: 'May', value: 'may' },
  { label: 'June', value: 'june' },
  { label: 'July', value: 'july' },
  { label: 'August', value: 'august' },
  { label: 'September', value: 'september' },
  { label: 'October', value: 'october' },
  { label: 'November', value: 'november' },
  { label: 'December', value: 'december' },
];

// Function to compare two dates and return true if they are the same
export const areDatesSame = ({ date1, date2 }: { date1: Date | null; date2: Date | null }): boolean => {
  // Parse the input dates using Moment.js
  const momentDate1 = moment(date1).startOf('day')
  const momentDate2 = moment(date2)
  // Use the isSame method to compare the dates
  return momentDate1.unix() === momentDate2.unix()
}

export const parseTimeForTimePicker = ({
  formattedTime,
  format = 'HH:mm:ss',
}: ParseTimeForAntdTimePickerType): Dayjs | null => {
  // Parse the given formatted time string using the provided format
  if (formattedTime && formattedTime !== 'Invalid Date') {
    return dayjs(formattedTime, format)
  } else {
    return null
  }
}

export const formatTimeForApi = ({ IsoTime, format = 'HH:mm:ss' }: FormatTimeFromAntdForApiType) => {
  const parsedDate = dayjs(IsoTime)
  // Checking if the parsed date is valid
  if (parsedDate.isValid()) {
    return dayjs(IsoTime).format(format)
  } else {
    return IsoTime
  }
}

export const formatDateForApi = ({ date, format = 'YYYY-MM-DD' }: FormatDateFromAntdForApiType) => {
  const parsedDate = dayjs(date)
  // Checking if the parsed date is valid
  if (parsedDate.isValid()) {
    return dayjs(date).format(format)
  } else {
    return ''
  }
}

type ValidationSchemaProps = {
  postType: string;
  saveAsDraft: boolean;
  isRecurring?: boolean;
  every?: Every;
  recurringPattern?: RecurringPattern;
}

export const getValidationSchema = ({ postType, saveAsDraft, isRecurring = false, every = '', recurringPattern = '' }: ValidationSchemaProps) => {
  if (postType === PostType.NEWS && saveAsDraft) {
    return newsValidationSchemaForDraft
  } else if (postType === PostType.NEWS && !saveAsDraft) {
    return newsValidationSchema
  } else if (postType === PostType.EVENT && saveAsDraft) {
    return eventValidationSchemaForDraft
  } else if (postType === PostType.EVENT && !saveAsDraft && isRecurring) {
    switch (recurringPattern) {
      case 'daily':
        if (every === 'every') {
          return row1DailyRecurringEvent
        } else {
          return row2DailyRecurringEvent
        }
      case 'weekly':
        return weeklyRecurringEventSchema
      case 'monthly':
        if (every === 'every') {
          return row1MonthlyRecurringEventSchema
        } else {
          return row2MonthlyRecurringEventSchema
        }
      default:
        return baseRecurringEventSchema
    }
  } else {
    return eventValidationSchema
  }
}

type BadgeSeverityType = 'success' | 'info' | 'warning' | null | undefined;

export const postTypeTemplate = (post: NewsAndEventsListType) => {
  const { postType, isRecurring } = post

  let badgeValue = ''
  let badgeSeverity: BadgeSeverityType

  switch (postType) {
    case 'event':
      if (isRecurring) {
        badgeValue = 'Recurring Event'
        badgeSeverity = 'success'
      } else {
        badgeValue = 'Event'
        badgeSeverity = 'success'
      }
      break
    case 'news':
      badgeValue = 'News'
      badgeSeverity = 'warning'
      break
    default:
      badgeValue = 'Other'
      badgeSeverity = 'info'
      break
  }

  return <Badge value={badgeValue} severity={badgeSeverity}></Badge>
}

export const convertToSlug = (str: string): string => {
  return str
    .trim() // Remove leading and trailing spaces, if any
    .toLowerCase()
    .replace(/[^\w\s-]/g, '') // Remove non-word characters (except spaces and hyphens)
    .replace(/\s+/g, '-') // Replace spaces with hyphens
}

// Utility function to check if the date is same or after the start of the day.
const isFutureOrToday = (date: any) => moment(date).isSameOrAfter(moment().startOf('day').format('YYYY-MM-DD'))

// Utility function to retrieve the publish date from context.
const getPublishDateFromContext = (context: any) => context.parent.publishDate

// Defines the validation schema for news items
export const newsValidationSchema = Yup.object({
  title: Yup.string().min(5).required(NEWS_N_EVENTS_WARNINGS.HEADING_DATE_REQUIRED),
  postTags: Yup.string().nullable(),
  urlSlug: Yup.string().required(NEWS_N_EVENTS_WARNINGS.URL_ERROR),

  publishDate: Yup.date()
    .required(NEWS_N_EVENTS_WARNINGS.PUBLISH_DATE_REQUIRED)
    .test('is-future-date', NEWS_N_EVENTS_WARNINGS.PUBLISH_DATE_ERROR, (value) => isFutureOrToday(value)),

  expiryDate: Yup.date()
    .nullable()
    .test('is-future-date', NEWS_N_EVENTS_WARNINGS.EXPIRY_DATE_ERROR, (value, context) => {
      if (!getPublishDateFromContext(context) || !value) {
        return true
      }
      return isFutureOrToday(value)
    })
    .test('is-expiry-date-greater', NEWS_N_EVENTS_WARNINGS.EXPIRY_DATE_GREATER_ERROR, (value, context) => {
      const publishDate = getPublishDateFromContext(context)
      if (!publishDate || !value) {
        return true
      }
      return isFutureOrToday(value) && moment(value).isAfter(publishDate)
    }),
})

export const newsValidationSchemaForDraft = Yup.object({
  title: Yup.string().min(5).required(NEWS_N_EVENTS_WARNINGS.HEADING_DATE_REQUIRED),
})

// Utility to create a moment object from date and time
function createMomentFromDateTime(date: string, time: string): moment.Moment {
  const formattedDate = moment(date).local().format('YYYY-MM-DD')
  const formattedTime = moment(time, 'hh:mm A').local().format('HH:mm:ss')
  return moment(`${formattedDate} ${formattedTime}`)
}

// Function to check whether endDate is greater than startDate
const isEndDateGreaterOrEqual = ({ startDate, endDate }: { startDate: string; endDate: string }): boolean => {
  // Parse dates using Moment
  const startMoment = moment(startDate)
  const endMoment = moment(endDate)

  // Check if endDate is after startDate
  return endMoment.isSameOrAfter(startMoment)
}

// Function to check whether startDate is greater than endDate
const isStartDateGreaterOrEqual = ({ startDate, endDate }: { startDate: string; endDate: string }): boolean => {
  // Parse dates using Moment
  const startMoment = moment(startDate)
  const endMoment = moment(endDate)

  // Check if startDate is after endDate
  return startMoment.isSameOrBefore(endMoment)
}

export const eventValidationSchema = Yup.object({
  title: Yup.string().min(5).required(NEWS_N_EVENTS_WARNINGS.HEADING_DATE_REQUIRED),
  postTags: Yup.string().nullable(),
  urlSlug: Yup.string().required(NEWS_N_EVENTS_WARNINGS.URL_ERROR),
  publishDate: Yup.string().required(NEWS_N_EVENTS_WARNINGS.PUBLISH_DATE_REQUIRED),
  startDate: Yup.string()
    .required(NEWS_N_EVENTS_WARNINGS.EVENT_DATE_REQUIRED)
    .test(
      'is-start-greater-than-end-time',
      NEWS_N_EVENTS_WARNINGS.START_DATE_IN_PAST_ERROR,
      function (value, context) {
        const { endDate } = context.parent
        if (endDate) {
          return isStartDateGreaterOrEqual({ startDate: value, endDate: endDate })
        } else {
          return true
        }
      }
    ),
  endDate: Yup.string()
    .required(NEWS_N_EVENTS_WARNINGS.EVENT_DATE_REQUIRED)
    .test(
      'is-end-greater-than-start-time',
      NEWS_N_EVENTS_WARNINGS.END_DATE_AFTER_START_ERROR,
      function (value, context) {
        const { startDate } = context.parent
        if (startDate) {
          return isEndDateGreaterOrEqual({ startDate: startDate, endDate: value })
        } else {
          return true
        }
      }
    ),
  startTime: Yup.string()
    .required(NEWS_N_EVENTS_WARNINGS.START_TIME_REQUIRED)
    .test('is-future-time', NEWS_N_EVENTS_WARNINGS.START_TIME_AFTER_END_ERROR, function (_value, context) {
      const { startDate, endDate, endTime, startTime } = context.parent
      const formattedStartTime = moment(startTime).format('hh:mm A')
      const formattedEndTime = moment(endTime).format('hh:mm A')
      const inputStartTimeAndDate = formattedStartTime === "Invalid date" ? createMomentFromDateTime(startDate, startTime) : createMomentFromDateTime(startDate, formattedStartTime)
      const inputEndTimeAndDate = formattedEndTime === "Invalid date" ? createMomentFromDateTime(endDate, endTime) : createMomentFromDateTime(endDate, formattedEndTime)
      return inputStartTimeAndDate.isBefore(inputEndTimeAndDate)
    }),
  endTime: Yup.string()
    .required(NEWS_N_EVENTS_WARNINGS.END_TIME_REQUIRED)
    .test('is-future-time', NEWS_N_EVENTS_WARNINGS.END_TIME_AFTER_START_ERROR, function (_value, context) {
      const { startDate, endDate, endTime, startTime } = context.parent
      const formattedStartTime = moment(startTime).format('hh:mm A')
      const formattedEndTime = moment(endTime).format('hh:mm A')
      const inputStartTimeAndDate = formattedStartTime === "Invalid date" ? createMomentFromDateTime(startDate, startTime) : createMomentFromDateTime(startDate, formattedStartTime)
      const inputEndTimeAndDate = formattedEndTime === "Invalid date" ? createMomentFromDateTime(endDate, endTime) : createMomentFromDateTime(endDate, formattedEndTime)

      return inputEndTimeAndDate.isAfter(inputStartTimeAndDate)
    })
    .test('is-endTime-greater', NEWS_N_EVENTS_WARNINGS.END_TIME_AFTER_START_ERROR, function (value, context) {
      const { startDate, endDate, startTime, endTime } = context.parent

      if (!startDate || !endDate || !startTime || !endTime) {
        return true
      }

      const formattedStartTime = moment(startTime).format('hh:mm A')
      const formattedEndTime = moment(endTime).format('hh:mm A')
      const startMoment = formattedStartTime === "Invalid date" ? createMomentFromDateTime(startDate, startTime) : createMomentFromDateTime(startDate, formattedStartTime)
      const endMoment = formattedEndTime === "Invalid date" ? createMomentFromDateTime(endDate, endTime) : createMomentFromDateTime(endDate, formattedEndTime)
      return endMoment.isAfter(startMoment)
    }),
})

export const baseRecurringEventSchema = Yup.object({
  title: Yup.string().min(5).required(NEWS_N_EVENTS_WARNINGS.HEADING_DATE_REQUIRED),
  postTags: Yup.string().nullable(),
  urlSlug: Yup.string().required(NEWS_N_EVENTS_WARNINGS.URL_ERROR),
  publishDate: Yup.string().required(NEWS_N_EVENTS_WARNINGS.PUBLISH_DATE_REQUIRED),
  recurringStartTime: Yup.string().required('Start time is required'),
  recurringEndTime: Yup.string().required('End time is required').test('is-greater', 'End time must be greater than start time', function (value) {
    let startTime;
    let endTime;

    // Check if recurringStartTime and recurringEndTime are both in format 2
    if (moment(this.parent.recurringStartTime, 'HH:mm:ss', true).isValid() && moment(value, 'HH:mm:ss', true).isValid()) {
      startTime = moment(this.parent.recurringStartTime, 'HH:mm:ss', true);
      endTime = moment(value, 'HH:mm:ss', true);
    } else {
      // If not, parse both as format 1
      startTime = moment(this.parent.recurringStartTime);
      endTime = moment(value);
    }

    // Check if both startTime and endTime are valid moments and if endTime is after startTime
    return startTime.isValid() && endTime.isValid() && endTime.isAfter(startTime);
  }),
  recurringPattern: Yup.string().required(NEWS_N_EVENTS_WARNINGS.EVENT_RECURRENCE),
});

// Extend the base schema for specific cases
export const row1DailyRecurringEvent = baseRecurringEventSchema.shape({
  every: Yup.string().required(),
  repeatsEveryNthDay: Yup.number().required(),
});

export const row2DailyRecurringEvent = baseRecurringEventSchema.shape({
  every: Yup.string().required(),
  selectedDaysOfWeek: Yup.array()
    .when('every', (every, schema) => {
      // @ts-ignore
      return every === 'every'
        ? schema.of(Yup.string()) // Make selectedDaysOfWeek not required
        : schema.of(Yup.string()).min(1, 'At least one day must be selected').required('At least one day must be selected')
    }),
});

export const weeklyRecurringEventSchema = baseRecurringEventSchema.shape({
  repeatsEveryNthWeek: Yup.number().required(),
  selectedDaysOfWeek: Yup.string().required(),
});

export const row1MonthlyRecurringEventSchema = baseRecurringEventSchema.shape({
  every: Yup.string().required(),
  selectedDaysOfWeek: Yup.string().required(),
  selectedOrdinal: Yup.string().required(),
  repeatsEveryNthMonth: Yup.number().required(),
});

export const row2MonthlyRecurringEventSchema = baseRecurringEventSchema.shape({
  every: Yup.string().required(),
  selectedMonthDay: Yup.string().required(),
  repeatsEveryNthMonthRow2: Yup.number().required(),
});

export const eventValidationSchemaForDraft = Yup.object({
  title: Yup.string().min(5).required(NEWS_N_EVENTS_WARNINGS.HEADING_DATE_REQUIRED),
})

// fetch all users
export const getNewsAndEventsList = (search: string, limit: number, lastKey: string) => {
  return HttpServiceHelper({
    url: listPostsApi + '?search=' + search + '&limit=' + limit + '&lastKey=' + lastKey,
    method: HttpMethods.GET,
  })
}

// fetch all events sorted by publish date
export const getEventsListGuestView = (queryClient: QueryClient, params: GetNewsAndEventsApiParams) => {
  return HttpServiceHelper({
    url: eventListGuestViewApi,
    method: HttpMethods.POST,
    queryClient,
    data: {
      ...params,
    },
  })
}


export const getNewsListGuestView = (
  queryClient: QueryClient,
  params: GetNewsAndEventsApiParams
) => {
  return HttpServiceHelper({
    url: newsListGuestViewApi,
    method: HttpMethods.POST,
    queryClient,
    data: {
      ...params,
    },
  });
}

// create news
export const createNewsApi = (
  newsAndEventsMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
  param: NewsPostForApi,
  onSuccess: (data: string) => void,
  onError: (res: string, variant: string) => void
) => {
  newsAndEventsMutation.mutate(
    {
      url: newsApi,
      method: HttpMethods.POST,
      data: param,
    },
    {
      onSuccess: (res) => onSuccess(res.data.message),
      onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
    }
  )
}

// update news
export const updateNewsApi = (
  newsAndEventsMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
  param: NewsPostForApi,
  onSuccess: (data: string) => void,
  onError: (res: string, variant: string) => void
) => {
  newsAndEventsMutation.mutate(
    {
      url: newsApi,
      method: HttpMethods.PUT,
      data: param,
    },
    {
      onSuccess: (res) => onSuccess(res.data.message),
      onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
    }
  )
}

// delete news and events
export const deleteNewsAndEvents = (
  newsAndEventsMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
  data: NewsAndEventsId,
  onSuccess: (message: string) => void,
  onError: (res: string, variant: string) => void
) => {
  newsAndEventsMutation.mutate(
    {
      url: newsAndEventsApi,
      method: HttpMethods.DELETE,
      data: data,
    },
    {
      onSuccess: (res) => onSuccess(res.data.message),
      onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
    }
  )
}

// get full post details
export const getFullPostDetails = (
  newsAndEventsMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
  data: NewsAndEventsId,
  onSuccess: (data: PostResponseType) => void,
  onError: (res: string, variant: string) => void
) => {
  newsAndEventsMutation.mutate(
    {
      url: newsAndEventsApi,
      method: HttpMethods.POST,
      data: data,
    },
    {
      onSuccess: (res) => onSuccess(res.data.data),
      onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
    }
  )
}

export const getSinglePostDetails = (queryClient: QueryClient, params: NewsAndEventsSlugId) => {
  const { urlSlug } = params
  return HttpServiceHelper({
    url: newsAndEventsSlugApi,
    method: HttpMethods.POST,
    queryClient,
    data: {
      urlSlug
    },
  });
}

// change post status
export const changeNewsAndEventStatus = (
  newsAndEventsMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
  data: NewsAndEventStatusType,
  onSuccess: (message: string) => void,
  onError: (res: string, variant: string) => void
) => {
  newsAndEventsMutation.mutate(
    {
      url: newsAndEventsStatusApi,
      method: HttpMethods.POST,
      data: data,
    },
    {
      onSuccess: (res) => onSuccess(res.data.message),
      onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
    }
  )
}

// create event
export const createEventApi = (
  newsAndEventsMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
  data: EventPostForApi,
  onSuccess: (message: string) => void,
  onError: (res: string, variant: string) => void
) => {
  newsAndEventsMutation.mutate(
    {
      url: eventApi,
      method: HttpMethods.POST,
      data: data,
    },
    {
      onSuccess: (res) => onSuccess(res.data.message),
      onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
    }
  )
}

// update event
export const updateEventApi = (
  newsAndEventsMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
  data: EventPostForApi,
  onSuccess: (message: string) => void,
  onError: (res: string, variant: string) => void
) => {
  newsAndEventsMutation.mutate(
    {
      url: eventApi,
      method: HttpMethods.PUT,
      data: data,
    },
    {
      onSuccess: (res) => onSuccess(res.data.message),
      onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
    }
  )
}

export type NextOccurrenceData = { nextOccurrenceDate: string }

// fetch next event occurrence date 
export const getNextEventOccurrenceApi = (
  newsAndEventsMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
  data: FetchNextOccurrence,
  onSuccess: (data: NextOccurrenceData) => void,
  onError: (res: string, variant: string) => void
) => {
  newsAndEventsMutation.mutate(
    {
      url: fetchNextOccurrence,
      method: HttpMethods.POST,
      data: data,
    },
    {
      onSuccess: (res) => onSuccess(res.data.data),
      onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
    }
  )
}