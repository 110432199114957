import { FaSearch } from "react-icons/fa";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ANNIVERSARY, BIRTHDAY, EMAIL_TEXT, FIRST_VISIT, FULL_NAME, LAST_VISIT, MOBILE_TEXT, NO_OF_VISIT, ZIP_CODE } from "../../constants/strings";
import { GuestInfo } from "../../constants/staticTypes";
import { convertUTCtoLocalByOffset, toPascalCase } from "../../helpers/utils";
import { useContext, useState, useEffect } from "react";
import { JTranslation, TranslationContext, jTranslationText } from "../../helpers/jTranslate";
import { DebounceInput } from "react-debounce-input";
import { useWindowSize } from "react-use";
import { MOBILE_VIEW_BREAKPOINT } from "../../constants/constants";
import { Card } from "primereact/card";
import { Badge } from "primereact/badge";
import { DataView } from "primereact/dataview";
import MlxTooltip from "../common/MlxTooltip";

type Props = {
    guests: GuestInfo[],
    loading: boolean,
    onSearchTrigger: (searchKey: string) => void
}

function GuestInfoListGrid({ guests, loading, onSearchTrigger }: Props) {

    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext

    const [translationText, setTranslatedText] = useState<{
        search: string,
        paginationTemplate: string,
        emptyMessage: string,
    }>({ 
        search: 'Search',
        paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
        emptyMessage: "No guest found."
    });

    const [showTitle, setShowTitle] = useState(true)
    const { width, height } = useWindowSize()
	const breakPoint = MOBILE_VIEW_BREAKPOINT

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: 'Search', typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: 'Showing 1 to 2 of 3 items', typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: 'No guest found.', typeCase: 'pascal', translationContext }),
                ])
                
                setTranslatedText({
                    search: translations[0] ?? 'Search',
                    paginationTemplate:
                        translations[1]
                            ?.replace('1', '{first}')
                            ?.replace('2', '{last}')
                            ?.replace('3', '{totalRecords}') ?? 'Showing {first} to {last} of {totalRecords} items',
                    emptyMessage: translations[2] ?? 'No guest found.',
                })
            } catch {
                setTranslatedText({
                    search: 'Search',
                    paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
                    emptyMessage: 'No guest found.',
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    const emailBodyTemplate = (guest: GuestInfo) => {
        return <MlxTooltip target=".email-text">
            <a className="email-text" data-pr-tooltip={guest.emailId} data-pr-position="bottom" data-testid={`email#-${guest.id}`} href={"mailto:" + guest.emailId}>{guest.emailId}</a>
            </MlxTooltip>
    }

    const itemTemplate = (guest: GuestInfo) => {
        return (
            <Card className="data-view-card mt-2 d-flex flex-column justify-content-even">
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <p className="m-0">{toPascalCase(guest.fullName)}</p>
                    <span className="d-flex align-items-center">
                        <span className="small-text">
                            <JTranslation typeCase="pascal" text={'Visits'} />
                        </span>
                        <Badge value={guest.totalVisitCount} className="ms-2" />
                    </span>
                </div>
                <div className='mt-2'>
                    <div className="d-flex flex-row align-items-center">
                        <p className="m-0">
                            {emailBodyTemplate(guest)}{guest.phoneNumber ? ', ' : ''}
                            {guest.phoneNumber}
                        </p>
                    </div>
                    {guest.birthDate && 
                        <div className="d-flex flex-row align-items-center">
                            <span className="me-2"><JTranslation typeCase="capitalize" text={BIRTHDAY} />:</span>
                            <p className="m-0 font-bold">{convertUTCtoLocalByOffset(guest.birthDate)}</p>
                        </div>
                    }   
                    {guest.anniversaryDate && 
                        <div className="d-flex flex-row align-items-center">
                            <span className="me-2"><JTranslation typeCase="capitalize" text={ANNIVERSARY} />:</span>
                            <p className="m-0 font-bold">{convertUTCtoLocalByOffset(guest.anniversaryDate)}</p>
                        </div>
                    } 
                    {guest.zipCode && 
                        <div className="d-flex flex-row align-items-center">
                            <span className="me-2"><JTranslation typeCase="capitalize" text={ZIP_CODE} />:</span>
                            <p className="m-0 font-bold">{guest.zipCode}</p>
                        </div>
                    }                 
                </div>
                <div className="view-footer mt-2 pt-3 d-flex flex-row justify-content-between align-items-center">
                        <div className="d-flex flex-row align-items-center small-text">
                            <span className="me-2"><JTranslation typeCase="capitalize" text={FIRST_VISIT} />:</span>
                            <p className="m-0 font-bold">{convertUTCtoLocalByOffset(guest.createdAt)}</p>
                        </div>
                        <div className="d-flex flex-row align-items-center">
                            <span className="me-2"><JTranslation typeCase="capitalize" text={LAST_VISIT} />:</span>
                            <p className="m-0 font-bold">{convertUTCtoLocalByOffset(guest.lastVisitedAt)}</p>
                        </div>
                </div>
            </Card>
        )
    }

    return (<div className="col-md-12 col-lg-12 mb-3 h-100 ">
        <div className="card h-100 ">
            <div className="card-header flex-grow-0">
                <div className="d-flex  align-items-center">
                    <div className="flex-grow-1">
                        {showTitle && 
                            <h4 className="mb-0 title"><JTranslation typeCase="pascal" text={"Guest List"} /></h4>
                        }
                    </div>
                    <div className="flex-grow-1 d-flex justify-content-end ">
                        <div className="col-md-4">
                            <div className={`form-group has-search ${!showTitle ? '' : 'search-small-box'}`}>
                                <span className="fa fa-search form-control-feedback"><FaSearch />  </span>
                                <DebounceInput
                                    onFocusCapture={() => {breakPoint >= width && setShowTitle(false)}}
                                    onBlurCapture={(event: any) => {                                                
                                        if(breakPoint >= width && event.target.value.length === 0) {
                                            setShowTitle(true)
                                        }
                                    }}
                                    autoComplete="off"
                                    className="form-control "
                                    placeholder={translationText.search}
                                    minLength={0}
                                    debounceTimeout={500}
                                    data-testid="search"
                                    onChange={(e) => onSearchTrigger(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body flex-grow-1 overflow-hidden">
                <div className="survey-section h-100">
                    <div className="row mb-5 h-100">
                        <div className="col-lg-12" style={{ height: '100%', overflow: 'auto' }}>
                            {width <= breakPoint ? (
                                <DataView
                                    value={guests}
                                    layout={'list'}
                                    itemTemplate={itemTemplate}
                                    paginator
                                    rows={10}
                                    paginatorClassName="p-dataview-customers"
                                    dataKey="id"
                                    emptyMessage={translationText.emptyMessage}
                                    currentPageReportTemplate={translationText.paginationTemplate}
                                />
                                ) : (
                                    <DataTable value={guests} paginator className="p-datatable-customers" rows={10}
                                        scrollable scrollHeight="flex"
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[10, 25, 50]}
                                        dataKey="id" rowHover
                                        loading={loading} responsiveLayout="scroll"
                                        globalFilterFields={['name', 'country.name', 'representative.name', 'balance', 'status']}
                                        emptyMessage={translationText.emptyMessage}
                                        currentPageReportTemplate={translationText.paginationTemplate}>
                                        <Column field="fullName" header={<JTranslation typeCase="pascal" text={FULL_NAME} />} sortable className="word-break-grid" style={{ minWidth: '15rem', maxWidth: '20rem' }} />
                                        <Column field="phoneNumber" header={<JTranslation typeCase="pascal" text={MOBILE_TEXT} />} sortable style={{ maxWidth: '10rem', minWidth: '10rem' }} />
                                        <Column field="emailId" header={<JTranslation typeCase="pascal" text={EMAIL_TEXT} />} className="overflow-ellipsis" sortable style={{ maxWidth: '15rem', minWidth: '15rem' }}
                                            body={emailBodyTemplate} />
                                        <Column field="birthDate" header={<JTranslation typeCase="pascal" text={BIRTHDAY} />} sortable style={{ maxWidth: '9rem', minWidth: '9rem' }}
                                            body={(guest: GuestInfo) => <>{convertUTCtoLocalByOffset(guest.birthDate)}</>} />
                                        <Column field="anniversaryDate" header={<JTranslation typeCase="pascal" text={ANNIVERSARY} />} sortable style={{ maxWidth: '9rem', minWidth: '9rem' }}
                                            body={(guest: GuestInfo) => <>{convertUTCtoLocalByOffset(guest.anniversaryDate)}</>} />
                                        <Column field="createdAt" header={<JTranslation typeCase="pascal" text={FIRST_VISIT} />} sortable style={{ maxWidth: '9rem', minWidth: '9rem' }}
                                            body={(guest: GuestInfo) => <>{convertUTCtoLocalByOffset(guest.createdAt)}</>} />
                                        <Column field="lastVisitedAt" header={<JTranslation typeCase="pascal" text={LAST_VISIT} />} sortable style={{ maxWidth: '9rem', minWidth: '9rem' }}
                                            body={(guest: GuestInfo) => {
                                                if (guest.lastVisitedAt !== null) {
                                                    return <>{convertUTCtoLocalByOffset(guest.lastVisitedAt)}</>
                                                }
                                            }} />
                                        <Column field="zipCode" header={<JTranslation typeCase="pascal" text={ZIP_CODE} />} sortable style={{ maxWidth: '10rem', minWidth: '10rem' }} />
                                        <Column field="totalVisitCount" header={<JTranslation typeCase="pascal" text={NO_OF_VISIT} />} sortable style={{ minWidth: '9rem' }} />
                                    </DataTable>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}
export default GuestInfoListGrid;