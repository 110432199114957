import { useEffect, useState } from 'react'
import './styles/wysiwyg.scss'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

type Props = {
	editorContent: string
	callback: (value: string) => void
}

function WysiwygEditorBasic({ editorContent, callback }: Props) {
	const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
	const [contentSet, setContent] = useState(false)

	const getEditorContentHtml = (content: ContentState) => {
		const rawDraft = convertToRaw(content)
		return draftToHtml(rawDraft)
	}

	useEffect(() => {
		// populate data on re mount
		if (editorContent && editorContent !== null) {
			const contentBlock = htmlToDraft(editorContent)
			const htmlContent = getEditorContentHtml(editorState.getCurrentContent())
			if ((contentBlock && !contentSet) || htmlContent !== editorContent) {
				const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks, contentBlock.entityMap)
				const editor = EditorState.createWithContent(contentState)
				setEditorState(editor)
				setContent(true)
			}
		}
	}, [contentSet, editorContent]) // eslint-disable-line

	return (
		<Editor
			wrapperClassName="wrapper-border"
			editorClassName="editor-outer"
			toolbarClassName="toolbar-box"
			toolbar={{
				inline: {
					inDropdown: false,
					className: undefined,
					wrapperClassname: 'editor-bg',
					component: undefined,
					dropdownClassName: undefined,
					options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
					bold: { className: 'editor-bg' },
					monospace: { className: 'editor-hide' },
					superscript: { className: 'editor-hide' },
					subscript: { className: 'editor-hide' },
				},
				blockType: {
					// className: "editor-hide"
				},
				fontFamily: {
					className: 'editor-hide',
				},
				emoji: {
					className: 'editor-hide',
				},
				image: {
					className: 'editor-hide',
				},
				embedded: {
					className: 'editor-hide',
				},
				colorPicker: {
					className: 'editor-hide',
				},
				link: {
					className: 'editor-hide',
				},
				fontSize: {
					// className: "editor-hide",
				},
			}}
			editorState={editorState}
			onEditorStateChange={(value) => {
				callback(getEditorContentHtml(value.getCurrentContent()))
				setEditorState(value)
			}}
			stripPastedStyles={true}
			handlePastedText={() => false}
		/>
	)
}
export default WysiwygEditorBasic
