import React, { ReactNode } from 'react';

// Define types for header and cell components
type HeaderProps = {
  label: string;
};

type CellProps = {
  content: ReactNode;
};

// Generic Table component with customizable headers and cells
type TableProps = {
  numRows: number;
  numCols: number;
  RowHeaderComponent: React.ComponentType<HeaderProps>;
  ColHeaderComponent: React.ComponentType<HeaderProps>;
  RowComponent: React.ComponentType<CellProps>;
  ColComponent: React.ComponentType<CellProps>;
};

const Table: React.FC<TableProps> = ({
  numRows,
  numCols,
  RowHeaderComponent,
  ColHeaderComponent,
  RowComponent,
  ColComponent,
}) => {
  return (
    <table>
      <thead>
        <tr>
          <th></th> {/* Empty cell for the top-left corner */}
          {[...Array(numCols)].map((_, colIndex) => (
            <th key={colIndex}>
              <ColHeaderComponent label={`Col ${colIndex + 1}`} />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...Array(numRows)].map((_, rowIndex) => (
          <tr key={rowIndex}>
            <td>
              <RowHeaderComponent label={`Row ${rowIndex + 1}`} />
            </td>
            {[...Array(numCols)].map((_, colIndex) => (
              <td key={colIndex}>
                <RowComponent content={`(${rowIndex + 1}, ${colIndex + 1})`} />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

// Example usage:
const CustomRowHeader: React.FC<HeaderProps> = ({ label }) => <div>{label}</div>;
const CustomColHeader: React.FC<HeaderProps> = ({ label }) => <div>{label}</div>;
const CustomRow: React.FC<CellProps> = ({ content }) => <div>{content}</div>;
const CustomCol: React.FC<CellProps> = ({ content }) => <div>{content}</div>;

const App: React.FC = () => {
  return (
    <Table
      numRows={8}
      numCols={8}
      RowHeaderComponent={CustomRowHeader}
      ColHeaderComponent={CustomColHeader}
      RowComponent={CustomRow}
      ColComponent={CustomCol}
    />
  );
};

export default App;
