import { DATE_FORMAT } from '../../../constants/constants';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { EventPost, EventPostFormData, RecurringPattern } from '../../../constants/staticTypes';
import { Field, FieldProps, FormikErrors, FormikTouched } from 'formik';
import { JTranslation } from '../../../helpers/jTranslate';
import { RadioButton } from 'primereact/radiobutton';
import Daily from './Daily';
import moment from 'moment';
import Monthly from './Monthly';
import Weekly from './Weekly';

type Props = {
    errors: FormikErrors<EventPost>
    touched: FormikTouched<EventPost>
    values: EventPostFormData
    nextOccurrence: string | null
    isLoading: boolean
    setNextOccurrence: Dispatch<SetStateAction<string | null>>
}

const RecurringTimeForm = ({ errors, touched, values, nextOccurrence, isLoading, setNextOccurrence }: Props) => {
    const { every } = values
    const [recurrence, setRecurrence] = useState<RecurringPattern>('')

    useEffect(() => {
        setRecurrence(values.recurringPattern)
    }, [values])

    const getInputComponents = (recurrence: RecurringPattern) => {
        switch (recurrence) {
            case 'daily':
                return <Daily errors={errors} touched={touched} every={every} setNextOccurrence={setNextOccurrence} />
            case 'weekly':
                return <Weekly errors={errors} touched={touched} every={every} setNextOccurrence={setNextOccurrence} />
            case 'monthly':
                return <Monthly errors={errors} touched={touched} every={every} setNextOccurrence={setNextOccurrence} />
            default:
                return <div className='d-flex select-recurrent'><span><i className="ri-information-2-line"></i></span> <span className='ms-1'><JTranslation typeCase="none" text="Select Event Recurrence" /></span></div>
        }
    }

    return (
        <div className="row mb-3">
            <div className="col-12">
                <div className="custom-bg rounded">
                    <div className="row p-3">
                        <div className="col-12">
                            <h6 className="fw-bold mb-3">
                                <JTranslation typeCase="none" text="Event Recurrence" />
                            </h6>
                        </div>
                        <div className="mb-4 col-md-12 d-flex gap-3 recurrence-bg">
                            <div>
                                <Field name="recurringPattern">
                                    {({ field, form }: FieldProps) => (
                                        <RadioButton
                                            id="recurringPatternDaily"
                                            className={errors.recurringPattern && touched.recurringPattern ? 'p-invalid' : undefined}
                                            value="daily"
                                            data-testid="events-heading-daily"
                                            checked={recurrence === "daily"}
                                            onChange={(e) => {
                                                form.setFieldValue(field.name, e.value);
                                                setRecurrence(e.value);
                                                setNextOccurrence(null);
                                            }}
                                        />
                                    )}
                                </Field>
                                <label htmlFor="recurringPattern" className="ms-2">
                                    <JTranslation typeCase="none" text="Daily" />
                                </label>
                            </div>
                            <div>
                                <Field name="recurringPattern">
                                    {({ field, form }: FieldProps) => (
                                        <RadioButton
                                            id="recurringPatternWeekly"
                                            className={errors.recurringPattern && touched.recurringPattern ? 'p-invalid' : undefined}
                                            value="weekly"
                                            data-testid="events-heading-weekly"
                                            checked={recurrence === "weekly"}
                                            onChange={(e) => {
                                                form.setFieldValue(field.name, e.value);
                                                setRecurrence(e.value);
                                                setNextOccurrence(null);
                                            }}
                                        />
                                    )}
                                </Field>
                                <label htmlFor="recurringPattern" className="ms-2">
                                    <JTranslation typeCase="none" text="Weekly" />
                                </label>
                            </div>
                            <div>
                                <Field name="recurringPattern">
                                    {({ field, form }: FieldProps) => (
                                        <RadioButton
                                            id="recurringPatternMonthly"
                                            className={errors.recurringPattern && touched.recurringPattern ? 'p-invalid' : undefined}
                                            value="monthly"
                                            data-testid="events-heading-monthly"
                                            checked={recurrence === "monthly"}
                                            onChange={(e) => {
                                                form.setFieldValue(field.name, e.value);
                                                setRecurrence(e.value);
                                                setNextOccurrence(null);
                                            }}
                                        />
                                    )}
                                </Field>
                                <label htmlFor="recurringPattern" className="ms-2">
                                    <JTranslation typeCase="none" text="Monthly" />
                                </label>
                            </div>
                        </div>
                        
                        <div className="mb-3 col-md-12">{getInputComponents(recurrence)}</div>

                        <span className="mb-2">
                            {nextOccurrence && !isLoading ? (
                                <strong>
                                    <JTranslation typeCase="none" text="The next event occurrence date is" /> '
                                    {moment(nextOccurrence).format(DATE_FORMAT)}'{' '}
                                </strong>
                            ) : (
                                ''
                            )}
                            {isLoading && <i className="pi pi-spin pi-spinner" style={{ fontSize: '1.5rem' }}></i>}
                        </span>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default RecurringTimeForm
