import { useContext, useEffect, useState } from 'react'
import { NAME, DESCRIPTION, DELETE, EDIT } from '../../constants/strings'
import { AreaType, Ingredient } from '../../constants/staticTypes'
import { checkPermission, toPascalCase } from '../../helpers/utils'
import { Column } from 'primereact/column'
import { CommonCtx } from '../../context/CommonCtxProvider'
import { DataTable } from 'primereact/datatable'
import { DebounceInput } from 'react-debounce-input'
import { FaSearch, FaEdit } from 'react-icons/fa'
import { MOBILE_VIEW_BREAKPOINT, PermissionKeys, SubPermissionKeys } from '../../constants/constants'
import { useQueryClient } from 'react-query'
import { JTranslation, TranslationContext, jTranslationText } from '../../helpers/jTranslate'
import { useWindowSize } from 'react-use'
import { DataView } from 'primereact/dataview'
import { Card } from 'primereact/card'
import MlxTooltip from '../common/MlxTooltip'
import { Tooltip } from 'primereact/tooltip'

type Props = {
    areaList: AreaType[]
    loading: boolean
    onSearchTrigger: (searchKey: string) => void
    onEditClick: (addOn: AreaType) => void
    onDeleteClick: (addOn: AreaType) => void
    editArea: AreaType | undefined
}

function AreaListDataGrid({
    areaList,
    loading,
    onSearchTrigger,
    onEditClick,
    onDeleteClick,
    editArea,
}: Readonly<Props>) {
    // CONTEXT VARIABLE
    const { setShowSideBar: setAddOnSideBar } = useContext(CommonCtx)
    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext

    const [translationText, setTranslatedText] = useState<{
        edit: string,
        delete: string,
        search: string,
        paginationTemplate: string,
        emptyMessage: string,
    }>({
        edit: EDIT,
        delete: DELETE,
        search: 'Search',
        paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
        emptyMessage: 'No Area Found',
    });
    const [showTitle, setShowTitle] = useState(true)
    const { width } = useWindowSize()
    const breakPoint = MOBILE_VIEW_BREAKPOINT

    const deletePermission = true //checkPermission(queryClient, PermissionKeys.MANAGE_ADDONS, SubPermissionKeys.DELETE)
    const editPermission = true //checkPermission(queryClient, PermissionKeys.MANAGE_ADDONS, SubPermissionKeys.EDIT)
    const addPermission = true //checkPermission(queryClient, PermissionKeys.MANAGE_ADDONS, SubPermissionKeys.ADD)

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: EDIT, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: DELETE, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: 'Search', typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: 'Showing 1 to 2 of 3 items', typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: 'No Area Found', typeCase: 'pascal', translationContext }),
                ])

                setTranslatedText({
                    edit: translations[0] ?? EDIT,
                    delete: translations[1] ?? DELETE,
                    search: translations[2] ?? 'Search',
                    paginationTemplate:
                        translations[3]
                            ?.replace('1', '{first}')
                            ?.replace('2', '{last}')
                            ?.replace('3', '{totalRecords}') ?? 'Showing {first} to {last} of {totalRecords} items',
                    emptyMessage: translations[4] ?? 'No Area Found',
                })
            } catch {
                setTranslatedText({
                    edit: EDIT,
                    delete: DELETE,
                    search: 'Search',
                    paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
                    emptyMessage: 'No Area Found',
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    const deleteButtonTemplate = (area: AreaType) => {
        return (
            <>
                {
                    <MlxTooltip target='.delete'>
                        <i
                            data-pr-tooltip={translationText.delete}
                            data-pr-position='bottom'
                            className={
                                'ri-delete-bin-line delete ' +
                                (!deletePermission && ' disabled') + (width <= breakPoint ? ' rounded-background shadow-sm ms-2' : '')
                            }
                            data-testid="delete-ingredient"
                            onClick={(e) => {
                                e.stopPropagation()
                                if (deletePermission) {
                                    onDeleteClick(area)
                                }
                            }}
                        ></i>
                    </MlxTooltip>
                }
            </>
        )
    }

    const editButtonTemplate = (area: AreaType) => {
        return (
            <span className={`${width <= breakPoint ? 'rounded-background shadow-sm ms-2' : ''}`}>
                <MlxTooltip target=".edit">
                    <FaEdit
                        className={`edit ${!editPermission && ' disabled'}`}
                        data-pr-tooltip={translationText.edit}
                        data-pr-position="bottom"
                        data-testid="edit-ingredient"
                        onClick={(e) => {
                            e.preventDefault()
                            if (editPermission) {
                                onEditClick(area)
                                setAddOnSideBar(true)
                            }
                        }}
                    />
                </MlxTooltip>
            </span>
        )
    }

    const itemTemplate = (area: AreaType) => {
        return (
            <Card className="data-view-card mt-2 d-flex flex-column justify-content-even">
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <p className="m-0">{toPascalCase(area.departmentName)}</p>
                </div>
                <div className="view-footer mt-2 pt-3 d-flex flex-row justify-content-between align-items-center">
                    <div className='d-flex justify-content-even align-items-center'>
                        {editButtonTemplate(area)}{deleteButtonTemplate(area)}
                    </div>
                </div>
            </Card>
        )
    }

    return (
        <div className="col-md-12 col-lg-12 mb-3 addOn-scroll-fix h-100">
            <div className="card h-100">
                <div className="card-header flex-grow-0">
                    <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                            {showTitle &&
                                <h4 className="mb-0 title"><JTranslation typeCase="pascal" text={'Area of operation'} /></h4>
                            }
                        </div>
                        <div className="flex-grow-0 me-2 ms-1">
                            <button
                                className="btn btn-custom-primary d-flex icon-only-btn"
                                type="button"
                                data-testid="add-area-btn"
                                onClick={() => setAddOnSideBar(true)}
                            >
                                <i className="ri-add-circle-line me-1"></i>
                                <span className="btn-text"><JTranslation typeCase="pascal" text={'Add Area'} /></span>
                            </button>
                        </div>
                        <div className="flex-grow-0  d-flex justify-content-end ">
                            <div className="col-md-12 ">
                                <div className={`form-group has-search ${!showTitle ? '' : 'search-small-box'}`}>
                                    <span className="fa fa-search form-control-feedback">
                                        <FaSearch />{' '}
                                    </span>
                                    <DebounceInput
                                        onFocusCapture={() => { breakPoint >= width && setShowTitle(false) }}
                                        onBlurCapture={(event: any) => {
                                            if (breakPoint >= width && event.target.value.length === 0) {
                                                setShowTitle(true)
                                            }
                                        }}
                                        className="form-control "
                                        placeholder={translationText.search}
                                        minLength={0}
                                        debounceTimeout={500}
                                        data-testid="area-search"
                                        onChange={(e) => onSearchTrigger(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body flex-grow-1 overflow-hidden">
                    <div className="row mb-5 h-100">
                        <div className="col-lg-12" style={{ height: '100%', overflow: 'auto' }}>
                            {width <= breakPoint ? (
                                <DataView
                                    value={areaList}
                                    layout={'list'}
                                    itemTemplate={itemTemplate}
                                    paginator
                                    rows={10}
                                    paginatorClassName="p-dataview-customers"
                                    dataKey="id"
                                    emptyMessage={translationText.emptyMessage}
                                />
                            ) : (
                                <DataTable
                                    value={areaList}
                                    paginator
                                    className="p-datatable-customers"
                                    rows={10}
                                    scrollable
                                    scrollHeight="flex"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    rowsPerPageOptions={[10, 25, 50]}
                                    dataKey="id"
                                    rowHover
                                    selectionMode="single"
                                    selection={editArea}
                                    loading={loading}
                                    responsiveLayout="scroll"
                                    emptyMessage={translationText.emptyMessage}
                                    currentPageReportTemplate={translationText.paginationTemplate}
                                >
                                    <Column
                                        style={{ minWidth: '10rem' }}
                                        field="departmentName"
                                        header={<JTranslation typeCase="pascal" text={NAME} />}
                                        sortable
                                        className="word-break-grid"
                                        alignFrozen="right"
                                    />
                                    <Column
                                        style={{ minWidth: '20rem' }}
                                        field="departmentDescription"
                                        header={<JTranslation typeCase="pascal" text={DESCRIPTION} />}
                                        className="word-break-grid"
                                        alignFrozen="right"
                                        body={(area: AreaType) => {
                                            if (!area?.departmentDescription) { return ''; }

                                            if (area?.departmentDescription.length > 50) {
                                                return (
                                                    <div>
                                                        <Tooltip position='mouse' target={`.area-description-${area.id}`}>
                                                            <span data-pr-tooltip={`area-description-tooltip-${area.id}`}>
                                                                <JTranslation type='text' typeCase="capitalize" text={(area?.departmentDescription)} />
                                                            </span>
                                                        </Tooltip>
                                                        <span className={`area-description-${area.id}`}>
                                                            <JTranslation typeCase="capitalize" text={(area?.departmentDescription.substring(0, 50) + '...')} />
                                                        </span>
                                                    </div>
                                                )
                                            }
                                            return <JTranslation typeCase="capitalize" text={area?.departmentDescription} />
                                        }}
                                    />
                                    <Column
                                        field="edit"
                                        style={{ maxWidth: '3rem', minWidth: '3rem' }}
                                        bodyClassName="bg-sticky-color"
                                        body={editButtonTemplate}
                                        alignFrozen="right"
                                        frozen={true}
                                    />
                                    <Column
                                        field="delete"
                                        style={{ maxWidth: '3rem', minWidth: '3rem' }}
                                        bodyClassName="bg-sticky-color"
                                        body={deleteButtonTemplate}
                                        alignFrozen="right"
                                        frozen={true}
                                    />
                                </DataTable>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AreaListDataGrid
