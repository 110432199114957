import { useState, useEffect, useContext, useCallback } from 'react'
import { AI_TEXT_GENERATOR, GENERATE, LONG, MEDIUM, PROMPT_PLACEHOLDER, PROMPT, REGENERATE_DESCRIPTION, RESPONSE_LENGTH, SHORT, CLEAR_PROMPT } from '../../constants/strings'
import { aiTextGeneratorParams, htmlToString, isMobile } from '../../helpers/utils'
import { AiTextResponse, AiTextGeneratorProps, AiTextLength, GenerateAiDescription, ToastMessageProps } from '../../constants/staticTypes'
import { generateDescriptionUsingAI } from '../../helpers/manageIngredientsHelper'
import { JTranslation, TranslationContext, jTranslationText } from '../../helpers/jTranslate'
import { toastMessageInitialData } from '../../constants/constants'
import { useQueryClient } from 'react-query'
import { v4 as uuidv4 } from "uuid";
import ToastAlert from '../alert/ToastAlert'
import useMutationHook from '../../hooks/useMutationHook'
import QuillEditor from './QuillEditor'
let positiveText = ""

const AiTextGenerator = ({ callBack, show, type }: AiTextGeneratorProps) => {
    const queryClient = useQueryClient()
    const ingredientsMutation = useMutationHook(queryClient, true)
    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext
    const uuid = uuidv4()
    // STATE VARIABLES
    const [param, setParam] = useState<GenerateAiDescription>(aiTextGeneratorParams)
    const [aiGeneratedCount, setAiGeneratedCount] = useState(0)
    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
    const [translationText, setTranslatedText] = useState<{ promptPlaceholder: string, }>({ promptPlaceholder: PROMPT_PLACEHOLDER })
    const [positiveText, setPositiveText] = useState("");

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: PROMPT_PLACEHOLDER, typeCase: 'pascal', translationContext }),
                ])
                setTranslatedText({
                    promptPlaceholder: translations[0] ?? PROMPT_PLACEHOLDER,
                })
            } catch {
                setTranslatedText({
                    promptPlaceholder: PROMPT_PLACEHOLDER,
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage]) // eslint-disable-line

    const getPromptBasedOnType = useCallback(() => {
        const highlightStyle = `
        color:#fbaa07; 
        background-color:#feefd0; 
        font-weight: bold;
        `
        switch (type) {
            case 'aboutUsDescription':
                setPositiveText(`Compose a compelling 'About Us' page for <span style="${highlightStyle}">Your Restaurant's Name</span>, established in <span style="${highlightStyle}">Year</span> and located in <span style="${highlightStyle}">Your Restaurant's Location</span>. Highlight the diverse menu of vegetarian and non-vegetarian dishes, and the unique cuisines offered`);
                break;
            case 'eventDescription':
                setPositiveText(`Compose a write-up for a restaurant's event <span style="${highlightStyle}">Your Restaurant's Name</span>. <span style="${highlightStyle}">Please describe the event details here</span>`);
                break;
            case 'ingredientDescription':
                setPositiveText(`Compose a write-up about an ingredient used in the restaurant. <span style="${highlightStyle}">Please provide a description of the ingredient here</span>`);
                break;
            case 'menuAdditionalInfo':
                setPositiveText(`Compose a write-up providing additional information about a food or beverage item. <span style="${highlightStyle}">Please include the specific information you would like to convey here</span>`);
                break;
            case 'menuCategoryDescription':
                setPositiveText(`Compose a write-up providing information about a food or beverage category. <span style="${highlightStyle}">Please describe the category here</span>`);
                break;
            case 'menuDescription':
                setPositiveText(`Compose a write-up for a <span style="${highlightStyle}">Celebration's Name</span> menu offered at our restaurant`);
                break;
            case 'menuItemDescription':
                setPositiveText(`Compose a write-up detailing information about a food or beverage item. <span style="${highlightStyle}">Please describe the menu item here</span>`);
                break;
            case 'newsDescription':
                setPositiveText(`Compose a write-up for a restaurant's news article listing page. <span style="${highlightStyle}">Please describe the news details here</span>`);
                break;
            case 'welcomeScreen':
                setPositiveText(`Compose a welcome page write-up for a restaurant named <span style="${highlightStyle}">Your Restaurant's Name</span>, located at <span style="${highlightStyle}">Your Restaurant's Location</span>`);
                break;
            case 'wineHistory':
                setPositiveText(`Compose a write-up detailing the history of a specific wine. <span style="${highlightStyle}">Please describe the wine here</span>`);
                break;
            default:
                setPositiveText("");
                break;
        }
    }, [type])

    const onSuccess = (response: AiTextResponse) => {
        const { aiResponse } = response
        callBack(aiResponse[0].text)
        setAiGeneratedCount((perCount) => perCount + 1)
    }

    // on add or update ingredient error
    const onError = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
    }

    const handleRadioButton = (textLength: AiTextLength) => {
        setParam((prevValue) => {
            return { ...prevValue, textLength }
        })
    }

    const onProceed = () => {
        generateDescriptionUsingAI(ingredientsMutation, {
            ...param,
            positiveText: htmlToString(positiveText),
            type: type,
        }, onSuccess, onError)
    }

    const updateContent = useCallback((content: string) => {
        setPositiveText(content)
    }, [])

    useEffect(() => {
        if (!positiveText || positiveText === "<p><br></p>") getPromptBasedOnType()
        // cleanup
        return () => {
            setPositiveText("")
            setAiGeneratedCount(0)
        }
    }, [getPromptBasedOnType])


    return (
        <div>
            {/* TOAST MESSAGE COMPONENT */}
            <ToastAlert
                show={toastMessage.show}
                onClose={() => setToastMessage(toastMessageInitialData)}
                message={toastMessage.message}
                variant={toastMessage.variant}
            />

            {show && (
                <div className={show ? "ai-container ai-transition border mb-3" : "ai-container border mb-3"}>
                    <div className='fw-bold mb-3'><JTranslation typeCase="pascal" text={AI_TEXT_GENERATOR} /></div>
                    <div className="d-flex justify-content-between mb-2 align-items-center">
                    <div><JTranslation typeCase="pascal" text={PROMPT} />
                    </div>
                    {isMobile() && (
                        <button className="btn btn-sm btn-custom-primary" onClick={() => { updateContent("") }}>
                            <JTranslation typeCase="pascal" text={CLEAR_PROMPT} />
                        </button>
                    )}
                    </div>
                    
                    <QuillEditor initialContent={positiveText} onChange={(content) => updateContent(content)} />
                    <div className=" col-md-12 col-lg-12  mt-3">

                        <div className='mb-1'><JTranslation typeCase="pascal" text={RESPONSE_LENGTH} /></div>

                        <div className="form-check form-check-inline mb-3">
                            <input
                                className="form-check-input"
                                type="radio"
                                name={uuid}
                                value={AiTextLength.SHORT}
                                checked={param.textLength === AiTextLength.SHORT}
                                onChange={() => handleRadioButton(AiTextLength.SHORT)}
                            />
                            <JTranslation typeCase="pascal" text={SHORT} />
                        </div>
                        <div className="form-check form-check-inline mb-3">
                            <input
                                className="form-check-input"
                                type="radio"
                                name={uuid}
                                value={AiTextLength.MEDIUM}
                                checked={param.textLength === AiTextLength.MEDIUM}
                                onChange={() => handleRadioButton(AiTextLength.MEDIUM)}
                            />
                            <JTranslation typeCase="pascal" text={MEDIUM} />
                        </div>
                        <div className="form-check form-check-inline mb-3">
                            <input
                                className="form-check-input"
                                type="radio"
                                name={uuid}
                                value={AiTextLength.LONG}
                                checked={param.textLength === AiTextLength.LONG}
                                onChange={() => handleRadioButton(AiTextLength.LONG)}
                            />
                            <JTranslation typeCase="pascal" text={LONG} />
                        </div>
                        <div className="col-12 mt-3">
                            <button
                                type="button"
                                className="btn  btn-custom-primary-outline w-100"
                                onClick={() => onProceed()}
                            >
                                {aiGeneratedCount
                                    ? <JTranslation typeCase="pascal" text={REGENERATE_DESCRIPTION} />
                                    : <JTranslation typeCase="pascal" text={GENERATE} />
                                }
                            </button>
                        </div>
                    </div>
                </div>


            )}
        </div>
    )
}

export default AiTextGenerator
