import { useEffect } from "react";
import { ADD_OFFERS, CANCEL, DESCRIPTION, EDIT_OFFER, MILES, MIN_VISIT_COUNT, OFFER, RADIUS, SAVE, MAX_COUPON, UPDATE, WEIGHTAGE, ZIP_CODE, DAYS, COUPON_VALIDITY, HELP_COUPON_VALIDITY, HELP_MAX_COUPON, HELP_ZIP_RADIUS, HELP_MIN_VISIT, HELP_WEIGHTAGE, FREQUENCY, HELP_MAX_FREQUENCY } from "../../constants/strings";
import { addEditOffer, initialGameFormData } from "../../helpers/gameHelper";
import { AlertVariant, PermissionKeys, SubPermissionKeys } from "../../constants/constants";
import { AxiosResponse } from "axios";
import { checkPermission, popover } from "../../helpers/utils";
import { Chips } from 'primereact/chips';
import { Game, GameForm } from "../../constants/staticTypes";
import { OverlayTrigger } from "react-bootstrap";
import { useQueryClient } from "react-query";
import Offcanvas from 'react-bootstrap/Offcanvas';
import useMutationHook from "../../hooks/useMutationHook";
import { JTranslation } from "../../helpers/jTranslate";
import MlxPopover from "../../components/common/MlxPopover";

type Props = {
    displayToast: (message: string, variant: string) => void,
    offersList: Game[]
    setOffersList: React.Dispatch<React.SetStateAction<Game[]>>,
    formData: GameForm,
    setFormData: React.Dispatch<React.SetStateAction<GameForm>>,
    editId: string,
    setEditId: React.Dispatch<React.SetStateAction<string>>,
    activeKey: string,
    setAccordionActiveKey: React.Dispatch<React.SetStateAction<string>>,
    showSideBar: boolean,
    setShowSideBar: React.Dispatch<React.SetStateAction<boolean>>,
    getOffersList: Function
}

function AddOfferSidebar({ displayToast, offersList, setOffersList,
    editId, setEditId, formData, setFormData, showSideBar, setShowSideBar, getOffersList }: Props) {

    const queryClient = useQueryClient();
    const addEditMutation = useMutationHook(queryClient, true);
    const zipInputId = "zip-input";

    // limit zipcode maxlength
    useEffect(() => {
        let zipInput = document.getElementById(zipInputId);
        if (zipInput !== null) zipInput?.setAttribute("maxlength", "6");
    }, [])

    // on add edit success
    const onSuccess = (res: AxiosResponse<any, any>) => {
        const updatedData = res.data.data as Game;

        // update list data
        if (editId?.trim().length > 0) {
            const index = offersList.findIndex(item => item.id === editId);
            offersList.splice(index, 1, { ...updatedData });
            setOffersList([...offersList]);
        } else {
            setOffersList([...offersList, updatedData]);
        }

        // reset data
        setEditId("");
        setFormData({ ...initialGameFormData });
        setShowSideBar(false)

        // display message
        displayToast(res.data.message, AlertVariant.SUCCESS);

        // recall offer list api to reload table data
        getOffersList().then((res: AxiosResponse<any, any>) => setOffersList(res.data?.data.list))
    }

    return (
        <>
            <Offcanvas
                show={showSideBar}
                onHide={() => {
                    setEditId("");
                    setShowSideBar(false);
                    setFormData({
                        ...initialGameFormData,
                        winningCriterias: {
                            ...initialGameFormData.winningCriterias
                        }
                    });
                }}
                backdrop="static"
                // responsive="xl"
                placement="end"
                className="custom-offcanvas"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{editId.length > 0 ? EDIT_OFFER : ADD_OFFERS}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="card-body p-0 flex-grow-1">
                        <div className="survey-section">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row gx-3 d-flex align-items-end">
                                        <div className="col-md-12 mb-2">
                                            <label className="form-label"><JTranslation typeCase="pascal" text={OFFER} /><span className="mandatory ms-1 fw-bold ">*</span></label>
                                            <input type="text" className="form-control" autoComplete="off"
                                                data-testid="offer"
                                                placeholder="" aria-label=""
                                                value={formData.prizeWinning}
                                                maxLength={20}
                                                onChange={(e) => setFormData({
                                                    ...formData,
                                                    prizeWinning: e.target.value
                                                })} />
                                        </div>
                                        <div className="col-md-12 mb-2">
                                            <label className="form-label"><JTranslation typeCase="pascal" text={DESCRIPTION} /></label>
                                            <input type="text" className="form-control" autoComplete="off"
                                                data-testid="game-desc"
                                                maxLength={1000}
                                                placeholder="" aria-label=""
                                                value={formData.prizeDescription}
                                                onChange={(e) => setFormData({
                                                    ...formData,
                                                    prizeDescription: e.target.value
                                                })} />

                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div className="help-small">
                                                <label className="form-label"><JTranslation typeCase="pascal" text={MIN_VISIT_COUNT} /><span className="mandatory ms-1 fw-bold ">*</span></label>
                                                <MlxPopover data={{title:MIN_VISIT_COUNT, body:HELP_MIN_VISIT}}/>
                                            </div>
                                            <input type="text" className="form-control" autoComplete="off"
                                                data-testid="min-visit-count"
                                                placeholder="" aria-label="" maxLength={5}
                                                value={formData.winningCriterias.minVisitCount}
                                                onChange={(e) => setFormData({
                                                    ...formData,
                                                    winningCriterias: {
                                                        ...formData.winningCriterias,
                                                        minVisitCount: e.target.value.replace(/\D/, '')
                                                    }
                                                })} />
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div className="help-small">
                                                <label className="form-label"><JTranslation typeCase="pascal" text={WEIGHTAGE} /><span className="mandatory ms-1 fw-bold ">*</span></label>
                                                <MlxPopover data={{title:WEIGHTAGE, body:HELP_WEIGHTAGE}}/>
                                            </div>
                                            <select
                                                data-testid="weightage"
                                                name="weightage"
                                                className="form-control"
                                                value={formData.winningCriterias.weightage}
                                                onChange={(e) => setFormData({
                                                    ...formData,
                                                    winningCriterias: {
                                                        ...formData.winningCriterias,
                                                        weightage: e.target.value.replace(/\D/, '')
                                                    }
                                                })}
                                            >
                                                <option value="">Select</option>
                                                <option value="95">Very High</option>
                                                <option value="75">High</option>
                                                <option value="50">Medium</option>
                                                <option value="30">Low</option>
                                                <option value="10">Very Low</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6 game-zip mb-2">
                                            <div className="help-small">
                                                <label className="form-label"><JTranslation typeCase="pascal" text={ZIP_CODE} /></label>
                                                <MlxPopover data={{title:ZIP_CODE, body:HELP_ZIP_RADIUS}}/>
                                            </div>
                                            <Chips value={formData.winningCriterias.zipCodes}
                                                inputId={zipInputId}
                                                addOnBlur separator=","
                                                maxLength={6}
                                                dadta-testid="zip-code"
                                                onChange={(e) => {
                                                    if(Array.isArray(e.value)){
                                                        setFormData({
                                                            ...formData,
                                                            winningCriterias: {
                                                                ...formData.winningCriterias,
                                                                zipCodes: [...e.value],
                                                                locationRadiusInMiles: e.value?.length === 0 ? "" :
                                                                    formData.winningCriterias.locationRadiusInMiles
                                                            }
                                                        });
                                                    }
                                                }}
                                                keyfilter="pint" />
                                        </div>

                                        <div className="col-md-6 mb-2">
                                            <div className="help-small">
                                                <label className="form-label">
                                                    <JTranslation typeCase="pascal" text={RADIUS} /> (<JTranslation typeCase="pascal" text={MILES} />)
                                                </label>
                                                <MlxPopover data={{title:RADIUS, body:HELP_ZIP_RADIUS}}/>
                                            </div>
                                            <input type="text" className="form-control" autoComplete="off"
                                                data-testid="radius" maxLength={3}
                                                placeholder="" aria-label=""
                                                disabled={formData.winningCriterias.zipCodes?.length === 0}
                                                value={formData.winningCriterias.locationRadiusInMiles}
                                                onChange={(e) => setFormData({
                                                    ...formData,
                                                    winningCriterias: {
                                                        ...formData.winningCriterias,
                                                        locationRadiusInMiles: e.target.value.replace(/\D/, '')
                                                    }
                                                })} />
                                        </div>

                                        <div className="col-md-6 mb-2">
                                            <div className="help-small">
                                                <label className="form-label"><JTranslation typeCase="pascal" text={MAX_COUPON} /><span className="mandatory ms-1 fw-bold ">*</span></label>
                                                <MlxPopover data={{title:MAX_COUPON, body:HELP_MAX_COUPON}}/>
                                            </div>
                                            <input type="text" className="form-control" autoComplete="off"
                                                data-testid="max-vouchers"
                                                maxLength={5}
                                                value={formData.stockCount}
                                                placeholder="" aria-label=""
                                                onChange={(e) => setFormData({
                                                    ...formData,
                                                    stockCount: e.target.value.replace(/\D/, '')
                                                })} />
                                        </div>

                                        <div className="col-md-6 mb-2">
                                            <div className="help-small">
                                                <label className="form-label"><JTranslation typeCase="pascal" text={FREQUENCY} /><span className="mandatory ms-1 fw-bold ">*</span></label>
                                                <MlxPopover data={{title:FREQUENCY, body:HELP_MAX_FREQUENCY}}/>
                                            </div>
                                            <select
                                                className="form-control"
                                                name="stockRepeatInterval"
                                                data-testid="frequency"
                                                value={formData.stockRepeatInterval}
                                                onChange={(e) => setFormData({
                                                    ...formData,
                                                    stockRepeatInterval: e.target.value
                                                })}

                                            >
                                                <option value="DAILY">Daily</option>
                                                <option value="WEEKLY">Weekly</option>
                                                <option value="MONTHLY">Monthly</option>
                                                <option value="QUARTERLY">Quarterly</option>
                                                <option value="YEARLY">Yearly</option>
                                            </select>
                                        </div>

                                        <div className="col-md-6 mb-2">
                                            <div className="help-small">
                                                <label className="form-label">
                                                    <JTranslation typeCase="pascal" text={COUPON_VALIDITY} /> (<JTranslation typeCase="pascal" text={DAYS} />)<span className="mandatory ms-1 fw-bold ">*</span>
                                                </label>
                                                <MlxPopover data={{title:COUPON_VALIDITY, body:HELP_COUPON_VALIDITY}}/>
                                            </div>
                                            <input type="text" className="form-control" autoComplete="off"
                                                data-testid="voucher-validity"
                                                maxLength={5}
                                                value={formData.couponCodeMaxDaysValidity}
                                                placeholder="" aria-label=""
                                                onChange={(e) => setFormData({
                                                    ...formData,
                                                    couponCodeMaxDaysValidity: e.target.value.replace(/\D/, '')
                                                })}
                                            />
                                        </div>

                                        <div className="row  gx-3 d-flex align-items-end ">
                                            <div className="col-md mb-2 d-flex justify-content-start ">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="save-btn-section shadow save-btn-absolute flex-grow-0">
                        <button className="btn  btn-custom-primary-outline me-2"
                            onClick={() => {
                                setEditId("");
                                setShowSideBar(false);
                                setFormData({
                                    ...initialGameFormData,
                                    winningCriterias: {
                                        ...initialGameFormData.winningCriterias
                                    }
                                });
                            }}
                            type="button" data-testid="cancel-btn"><JTranslation typeCase="pascal" text={CANCEL} />
                        </button>

                        <button className="btn btn-custom-primary "
                            disabled={formData.prizeWinning?.trim().length === 0 ||
                                formData.stockCount?.trim().length === 0 ||
                                formData.stockRepeatInterval?.trim().length === 0 ||
                                formData.winningCriterias.minVisitCount.length === 0 ||
                                formData.couponCodeMaxDaysValidity?.trim().length === 0 ||
                                formData.winningCriterias.weightage.length === 0 ||
                                (!checkPermission(queryClient, PermissionKeys.MANAGE_GAME, SubPermissionKeys.ADD) && editId.length === 0) ||
                                (!checkPermission(queryClient, PermissionKeys.MANAGE_GAME, SubPermissionKeys.EDIT) && editId.length > 0)}
                            onClick={() => {
                                if ((editId.length === 0 && checkPermission(queryClient, PermissionKeys.MANAGE_GAME, SubPermissionKeys.ADD)) ||
                                    (editId.length > 0 && checkPermission(queryClient, PermissionKeys.MANAGE_GAME, SubPermissionKeys.EDIT))) {
                                    addEditOffer(queryClient, editId, formData as any, addEditMutation, onSuccess, displayToast);
                                }
                            }}
                            type="button"
                            data-testid="save-btn">{editId.length > 0 
                                ? <JTranslation typeCase="pascal" text={UPDATE} /> 
                                : <JTranslation typeCase="pascal" text={SAVE} />
                        }</button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}
export default AddOfferSidebar;