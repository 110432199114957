import { AxiosResponse } from 'axios'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { UseMutationResult } from 'react-query'
import { Payload, PermissionCreationForm, RolePermissions } from '../../constants/staticTypes'
import { ADD, ADD_CUSTOM_PERMISSION, CLEAR, PERMISSION_DESC, PERMISSION_NAME } from '../../constants/strings'
import { JTranslation } from '../../helpers/jTranslate'
import { addCustomPermission } from '../../helpers/permissionHelper'

type Props = {
	show: boolean
	onHide: React.Dispatch<React.SetStateAction<boolean>>
	addPermissionMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>
	onAddPermissionSuccess: (item: RolePermissions) => void
	onError: (errorMessage: string) => void
}

function AddCustomPermission({ show, onHide, addPermissionMutation, onAddPermissionSuccess, onError }: Props) {
	const [permissionRoleName, setPermissionRoleName] = useState('')
	const [permissionRoleDescription, setPermissionRoleDescription] = useState('')

	return (
		<Modal data-testid="custom-permission-modal" centered show={show}>
			<Modal.Header>
				<h5 className="modal-title" id="exampleModalLabel">
					<JTranslation typeCase="pascal" text={'Add Custom Permission Group'} />
				</h5>
				<button
					type="button"
					className="btn-close"
					data-bs-dismiss="modal"
					onClick={() => {
						setPermissionRoleName('')
						setPermissionRoleDescription('')
						onHide(false)
					}}
					aria-label="Close"
					data-testid="close-button"
				></button>
			</Modal.Header>
			<Modal.Body>
				<div className="mb-3">
					<label htmlFor="permissionRoleName" className="form-label">
						<JTranslation typeCase="pascal" text={"Permission Group Name"} />
						<span className="mandatory">*</span>
					</label>
					<input
						className="form-control"
						id="permissionRoleName"
						data-testid="permission-name"
						autoFocus
						maxLength={50}
						autoComplete="off"
						value={permissionRoleName}
						onChange={(e) => setPermissionRoleName(e.target.value)}
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="exampleFormControlInput2" className="form-label">
						<JTranslation typeCase="pascal" text={"Permission Description"} />
					</label>
					<textarea
						className="form-control"
						id="exampleFormControlInput2"
						rows={3}
						data-testid="permission-desc"
						autoComplete="new-password"
						value={permissionRoleDescription}
						onChange={(e) => setPermissionRoleDescription(e.target.value)}
						maxLength={2000}
					></textarea>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button
					type="button"
					className="btn  btn-custom-primary-outline"
					data-bs-dismiss="modal"
					onClick={() => {
						setPermissionRoleName('')
						setPermissionRoleDescription('')
					}}
				>
					<JTranslation typeCase="pascal" text={CLEAR} />
				</button>
				<button
					type="button"
					className="btn btn-custom-primary"
					data-testid="add-new-permission-save"
					disabled={permissionRoleName?.trim().length === 0}
					onClick={(e) => {
						e.preventDefault()
						if (permissionRoleName?.trim().length > 0) {
							let newPermission: PermissionCreationForm = {
								permissionRoleName: permissionRoleName,
								permissionRoleDescription: permissionRoleDescription,
							}
							setPermissionRoleName('')
							setPermissionRoleDescription('')
							onHide(false)
							addCustomPermission(addPermissionMutation, newPermission, onAddPermissionSuccess, onError)
						}
					}}
				>
					<JTranslation typeCase="pascal" text={ADD} />
				</button>
			</Modal.Footer>
		</Modal>
	)
}

export default AddCustomPermission
