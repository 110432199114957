// SnowflakeEffect.tsx
import React, { useEffect } from 'react'
import Snowflakes from 'magic-snowflakes'
import { celebrationEffectsChristmasType } from '../../../constants/staticTypes'

interface SantaSleighProps {
	christmasEffect: celebrationEffectsChristmasType | undefined
}

const SnowflakeEffect: React.FC<SantaSleighProps> = ({ christmasEffect }: Readonly<SantaSleighProps>) => {
	useEffect(() => {
		const snowFlakesDefaults = {
			colors: [
				'#ADD8E6', // Light Blue
				'#C0C0C0', // Silver
				'#FFD700', // Gold
				// '#0000CD', // Dark Blue
				'#FF6347', // Soft Red
				'#32CD32', // Light Green
				'#5ECDEF', // Light Blue
			],
			count: 5,
			maxSize: 30,
			minOpacity: 0.4,
			maxOpacity: 0.8,
		}

		const { colors, count, maxSize, minOpacity, maxOpacity } = christmasEffect?.snowFlakes || snowFlakesDefaults

		let snowflakeInstances: any = []

		colors.forEach((color) => {
			const snowflakes = new Snowflakes({
				count,
				maxSize,
				color,
				minOpacity,
				maxOpacity,
			})
			snowflakeInstances.push(snowflakes)
		})

		return () => {
			// Clean up
			snowflakeInstances.forEach((snowflake: any) => {
				snowflake.stop()
				snowflake.destroy()
			})
			snowflakeInstances = []
		}
	}, [])

	return null // This component doesn't render anything itself
}

export default SnowflakeEffect
