import withSidebar from "../../hoc/withSidebar";
import { useContext, useEffect, useState } from 'react';
import { FaSearch } from "react-icons/fa";
import { CREATE_QUESTION_SET, DOWNLOAD_QR_CODE, SURVEY_MANAGEMENT } from "../../constants/strings";
import CreateQuestionSetModal from "../../components/survey/admin/CreateQuestionSetModal";
import { useQueryClient } from "react-query";
import ToastAlert from "../../components/alert/ToastAlert";
import useQueryHook from "../../hooks/useQueryHook";
import Loader from "../../components/loader/Loader";
import axios, { AxiosResponse } from "axios";
import { getQuestionSetList, getSortedSurveySet } from "../../helpers/surveyManagementHelper";
import { QUESTION_SET, TENANT_INFO } from "../../constants/queryKeys";
import { BrandingResponseData, Survey } from "../../constants/staticTypes";
import SurveyQuestionSetGrid from "../../components/survey/admin/SurveyQuestionSetGrid";
import { useLocation } from "react-router-dom";
import { AlertVariant, MOBILE_VIEW_BREAKPOINT, PermissionKeys, SubPermissionKeys } from "../../constants/constants";
import { checkPermission, formatDate } from "../../helpers/utils";
import saveAs from "file-saver";
import html2canvas from "html2canvas";
import { routes } from "../../constants/routes";
import qrcode from "qrcode-generator";
import { TranslationContext, jTranslationText, JTranslation } from "../../helpers/jTranslate";
import { DebounceInput } from "react-debounce-input";
import { useWindowSize } from "react-use";

type Message = {
    message: string
}

function SurveyManagement() {
    const queryClient = useQueryClient();
    const location = useLocation();
    const [show, showQuestionModal] = useState(false);
    const [showToast, setToast] = useState(false); // Toast alert state
    const [toastMessage, setToastMessage] = useState(""); // Toast message
    const [toastVariant, setToastVariant] = useState(""); // Toast Variant
    const [isInitialLoad, setInitialLoad] = useState(true);
    const [questionSetList, setQuestionSetList] = useState<Survey[]>([]);
    const [actualQuestionList, setActualQuestionSetList] = useState<Survey[]>([]); // unmodified question set 
    const [mobileLogUrl, setMobileLogoUrl] = useState({ url: '', imageKey: '' });
    const [imageSrc, setImageSrc] = useState<string>('');
    const [generatingQR, setGeneratingQR] = useState(false);
    const [translationText, setTranslatedText] = useState<{
        search: string,
      }>({ 
        search: 'Search',
    });

    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext

    const [showTitle, setShowTitle] = useState(true)
    const { width, height } = useWindowSize()
	const breakPoint = MOBILE_VIEW_BREAKPOINT

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: 'Search', typeCase: 'pascal', translationContext }),
                ])
                setTranslatedText({
                    search: translations[0] ?? 'Search',
                })
            } catch {
                setTranslatedText({
                    search: 'Search',
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    useEffect(() => {
        const fetchImage = async () => {
            if (!mobileLogUrl.url || imageSrc) return
            try {
                // Fetch the image as a Blob
                const response = await axios.get(mobileLogUrl.url, {
                    responseType: 'blob',
                });

                // Create a local URL for the blob
                const localUrl = URL.createObjectURL(response.data);

                // Update state with the local URL
                setImageSrc(localUrl);
            } catch (error) {
                console.error('Error fetching image:', error);
            }
        };

        fetchImage();
    }, [mobileLogUrl.url, imageSrc]);

    useEffect(() => {
        const res = queryClient.getQueryData(TENANT_INFO) as AxiosResponse<any, any>
        let data = res?.data?.data as BrandingResponseData
        const { branding } = data
        setMobileLogoUrl({ imageKey: '', url: branding?.logos?.small })
    }, [queryClient])

    // fetch question set data
    const questionSetData = useQueryHook(QUESTION_SET, () => getQuestionSetList(queryClient), (res: AxiosResponse<any, any>) => {
        let questionSetResponse = res.data.data as Survey[];

        // sort items by date
        questionSetResponse.sort((a, b) => a.createdAt !== undefined && b.createdAt !== undefined &&
            a.createdAt < b.createdAt ? 1 : -1)

        const sortedItems = getSortedSurveySet(questionSetResponse);
        setQuestionSetList([...sortedItems]);
        setActualQuestionSetList([...sortedItems]);
        setInitialLoad(false);
    });

    // show toast
    const displayToast = (message: string, variant: string) => {
        setToastVariant(variant);
        setToastMessage(message);
        setToast(true);
    }

    // display success toast after create survey
    useEffect(() => {
        const messageObject = location.state as Message;
        if (messageObject) {
            displayToast(messageObject.message, AlertVariant.SUCCESS);
            window.history.replaceState({}, document.title);
        }
    }, [])

    // generate qr image
    const generateQR = async () => {
        let qrDiv;
        const canvas = await html2canvas((document.getElementById("qr-code") as HTMLElement), {
            onclone: (doc) => {
                qrDiv = doc.getElementById("qr-code") as HTMLElement;
                qrDiv.style.display = "inline-block";

                // add qr code
                const qrText = `${window.location.protocol}//${window.location.hostname}${routes.survey_guest}?qr_scan=true`;
                let qr = qrcode(0, 'L');
                qr.addData(qrText);
                qr.make();
                (doc.getElementById("qr-img") as HTMLElement).innerHTML = qr.createSvgTag(10);
                (doc.getElementById("qr-name") as HTMLElement).innerHTML = "Scan to provide feedback!";
            }
        });
        let image = canvas.toDataURL("image/png", 1.0);
        return image;
    }

    const downloadQR = async () => {
        setGeneratingQR(true)
        let qrImage = await generateQR();
        setGeneratingQR(false)
        saveAs(qrImage, `Survey-QR-code.png`);
    }


    if (questionSetData.isLoading || isInitialLoad) {
        return <Loader />
    }

    return (
        <>
            <ToastAlert data-testid="toast" show={showToast} onClose={setToast} message={toastMessage} variant={toastVariant} />
            <div className="col-md-12 col-lg-12 mb-3 h-100 " data-testid="survey">
                <div className="page-title">
                    <h5><JTranslation typeCase="pascal" text={SURVEY_MANAGEMENT} /></h5>
                </div>
                <div id="qr-code" className='qr-code-parent' style={{ display: "none" }}>
                    {/* <p id="qr-top-label" className='qr-code-child qr-code-label-top'></p> */}
                    <div className="qr-image-wrapper">
                        {mobileLogUrl.url && (<img alt="small-logo" src={imageSrc} className="fnb-qr-code-logo" />)}
                        <div id="qr-img" ></div>
                    </div>
                    {/* <p id="qr-bottom-label" className='qr-code-child qr-code-label-bottom'></p> */}
                    <p id="qr-name" className='qr-code-child qr-code-preferred-name'></p>
                </div>
                <div className="col-md-12 col-lg-12 mb-3 h-100 ">
                    <div className="card h-100 ">
                        <div className="card-header flex-grow-0">
                            <div className="d-flex  align-items-center">
                                <div className="flex-grow-1">
                                    {showTitle &&
                                        <h4 className="mb-0 title"><JTranslation typeCase="pascal" text={"Question Set List"} /></h4>
                                    }
                                </div>                              
                                <div className="flex-grow-0 me-2">
                                    <button className="btn btn-custom-primary icon-only-btn"
                                        data-testid="btn-create-question-set"
                                        disabled={!checkPermission(queryClient, PermissionKeys.MANAGE_SURVEY, SubPermissionKeys.ADD)}
                                        onClick={() => {
                                            if (checkPermission(queryClient, PermissionKeys.MANAGE_SURVEY, SubPermissionKeys.ADD)) {
                                                showQuestionModal(true);
                                            }
                                        }}
                                        type="button"><i className="ri-add-circle-line me-2"></i>
                                        <span className="btn-text"><JTranslation typeCase="pascal" text={CREATE_QUESTION_SET} /></span>
                                        </button>
                                </div>
                                <div className="flex-grow-0 me-2">
                                    <button className="btn btn-custom-primary-outline icon-only-btn btn btn-primary"
                                        data-testid="btn-create-question-set"
                                        disabled={generatingQR}
                                        onClick={() => { downloadQR() }}
                                        type="button"><i className="ri-download-line me-2"></i>
                                        <span className="btn-text"><JTranslation typeCase="none" text={DOWNLOAD_QR_CODE} /></span>
                                    </button>
                                </div>
                                <div className="flex-grow-0 d-flex justify-content-end ">
                                    <div className="col-md-12">
                                        <div className={`form-group has-search ${!showTitle ? '' : 'search-small-box'}`}>
                                            <span className="fa fa-search form-control-feedback"><FaSearch />  </span>
                                            <DebounceInput
                                                onFocusCapture={() => {breakPoint >= width && setShowTitle(false)}}
                                                onBlurCapture={(event: any) => {                                                
                                                    if(breakPoint >= width && event.target.value.length === 0) {
                                                        setShowTitle(true)
                                                    }
                                                }}
                                                className="form-control "
                                                placeholder={translationText.search}
                                                minLength={0}
                                                debounceTimeout={500}
                                                data-testid="search"
                                                autoComplete="off"
                                                onChange={(e) => {
                                                    if (actualQuestionList.length > 0) {
                                                        let filteredList;
                                                        let searchValue = e.target.value?.trim().toLowerCase();
                                                        filteredList = actualQuestionList.filter(item => {
                                                            let createdAt = "";
                                                            if (item.createdAt) {
                                                                createdAt = formatDate(item.createdAt)
                                                            }

                                                            if (searchValue.length === 1) {
                                                                return item.surveySetName.toLowerCase().startsWith(searchValue) ||
                                                                    createdAt.startsWith(searchValue);
                                                            } else {
                                                                return item.surveySetName.toLowerCase().includes(searchValue) ||
                                                                    createdAt.includes(searchValue);
                                                            }
                                                        });
                                                        setQuestionSetList([...filteredList]);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body flex-grow-1 overflow-hidden">
                            <div className="survey-section h-100">
                                <div className="row mb-5 h-100">
                                    <div className="col-lg-12" style={{ height: '100%', overflow: 'auto' }}>
                                        <SurveyQuestionSetGrid questionSetList={questionSetList} queryClient={queryClient}
                                            setQuestionSetList={setQuestionSetList} displayToast={displayToast}
                                            setActualQuestionSet={setActualQuestionSetList}
                                            actualQuestionList={actualQuestionList} questionSetData={questionSetData} />
                                    </div>

                                    <CreateQuestionSetModal show={show} onHide={() => showQuestionModal(false)}
                                        queryClient={queryClient} displayToast={displayToast} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default withSidebar(SurveyManagement);