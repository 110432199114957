import { createContext, Dispatch, PropsWithChildren, SetStateAction, useState, useMemo } from 'react'
import { initialValuesForTimeOff } from '../helpers/timeOffHelper'
import { initialValuesForHoliday } from '../helpers/holidayHelper'
import { TimeOffRequestFormType, HolidayFormDataType, AvailabilityFormData } from '../constants/staticTypes'
import { initialValuesForAvailability } from '../helpers/availabilityHelper'

// SETTER TYPE DECLARATIONS
type SetTimeOffData = Dispatch<SetStateAction<TimeOffRequestFormType>>
type SetHolidayData = Dispatch<SetStateAction<HolidayFormDataType>>
type SetAvailabilityData = Dispatch<SetStateAction<AvailabilityFormData>>

// UPDATE FUNCTION DECLARATIONS
const UpdateTimeOffData: SetTimeOffData = () => initialValuesForTimeOff
const UpdateHolidayData: SetHolidayData = () => initialValuesForHoliday
const UpdateAvailabilityData: SetAvailabilityData = () => initialValuesForAvailability

// CONTEXT VARIABLES
export const ScheduleManagementCtx = createContext({
	timeOffData: initialValuesForTimeOff,
	setTimeOffData: UpdateTimeOffData,
	holidayData: initialValuesForHoliday,
	setHolidayData: UpdateHolidayData,
	availabilityData: initialValuesForAvailability,
	setAvailabilityData: UpdateAvailabilityData,
})

export function ScheduleManagementCtxProvider({ children }: PropsWithChildren) {
	// STATE VARIABLES
	const [timeOffData, setTimeOffData] = useState(initialValuesForTimeOff)
	const [holidayData, setHolidayData] = useState(initialValuesForHoliday)
	const [availabilityData, setAvailabilityData] = useState(initialValuesForAvailability)

	// CONTEXT PROVIDER
	return (
		<ScheduleManagementCtx.Provider
			value={useMemo(
				() => ({
					timeOffData, setTimeOffData,
					holidayData, setHolidayData,
					availabilityData, setAvailabilityData,
				}),
				[timeOffData, holidayData, availabilityData]
			)}
		>
			{children}
		</ScheduleManagementCtx.Provider>
	)
}
