import React, { useState, useContext, useEffect, useRef } from 'react'
import { CommonCtx } from '../../context/CommonCtxProvider'
import { INGREDIENTS_LIST } from '../../constants/queryKeys'
import { toastMessageInitialData, AlertVariant, FileType, IMAGE_TYPE } from '../../constants/constants'
import { useQueryClient } from 'react-query'
import { v4 as uuidv4 } from 'uuid'
import AiTextGenerator from '../ai_text_generator/AiTextGenerator'
import FileUpload from '../f&b_menu/admin/add_item_forms/FileUpload'
import ImageCropModal from '../f&b_menu/admin/add_item_forms/ImageCropModal'
import Offcanvas from 'react-bootstrap/Offcanvas'
import ToastAlert from '../alert/ToastAlert'
import useMutationHook from '../../hooks/useMutationHook'

import {
  Ingredient,
  ToastMessageProps,
  SingleIngredient,
  SingleIngredientResponse,
  UpdateIngredient,
  CreateIngredient,
  SelectedMenusItem,
  CropType,
} from '../../constants/staticTypes'

import {
  initialIngredientResponse,
  createNewIngredient,
  updateIngredient,
  fetchSingleIngredientsPrivate,
} from '../../helpers/manageIngredientsHelper'

import {
  ADD_NEW_INGREDIENT,
  AI_TEXT_GENERATOR,
  CANCEL,
  DESCRIPTION,
  EDIT_INGREDIENT,
  NAME,
  SAVE,
  UPDATE,
  USER_UPLOAD_PHOTO_ERROR,
  ASSOCIATED_ITEM,
  ASSOCIATED_ITEMS,
} from '../../constants/strings'
import { JTranslation } from '../../helpers/jTranslate'

type Props = {
  editIngredient: Ingredient | undefined
  setIngredient: React.Dispatch<React.SetStateAction<Ingredient | undefined>>
  handleClose: () => void
}

// let intervalId: NodeJS.Timer
let intervalId: ReturnType<typeof setInterval>

const formatTextWithLineBreaks = (text: string) => {
  // Replace "\n\n" with line break HTML tag "<br>"
  return text.replace(/\n\n/g, '\n')
}

const IngredientsSideBar = ({ editIngredient, setIngredient, handleClose }: Props) => {
  // CONTEXT VARIABLE
  const { showSideBar, setShowSideBar } = useContext(CommonCtx)

  // LOCAL VARIABLES
  const queryClient = useQueryClient()
  const ingredientsMutation = useMutationHook(queryClient, true) // ingredients file mutation
  // STATE VARIABLES
  const [formData, setFormData] = useState<SingleIngredientResponse>(initialIngredientResponse)
  const [imageCropSrc, setImageCrop] = useState<string | ArrayBuffer | null>(null)
  const [showAiTextGenerator, setShowAiTextGenerator] = useState(false)

  // ALERT VARIABLES
  const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
  const [associatedItems, setAssociatedItems] = useState<SelectedMenusItem[] | null>(null)

  const cropType: CropType = 'INGREDIENT';
  const validateImageDimension = { width: IMAGE_TYPE[cropType].width, height: IMAGE_TYPE[cropType].height };

  // effect for setting the form data
  useEffect(() => {
    if (editIngredient) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ingredientName: editIngredient.ingredientName ?? '',
        ingredientDescription: editIngredient.ingredientDescription ?? '',
        ingredientId: editIngredient.id ?? '',
      }))
      setDisplayText(editIngredient.ingredientDescription)
    }
  }, [editIngredient])

  // This effect invokes the single ingredient API to retrieve ingredient details
  useEffect(() => {
    if (editIngredient) {
      const param: SingleIngredient = {
        ingredientId: editIngredient.ingredientId,
      }
      //   api call
      fetchSingleIngredientsPrivate(ingredientsMutation, param, onSingleIngredientsSuccess, onError)
    }

    return () => {
      // cleanup
      setFormData(initialIngredientResponse)
      setAssociatedItems(null)
    }
  }, [editIngredient]) // eslint-disable-line

  // show toast
  const displayToast = (message: string, variant: string) => {
    setToastMessage({ message, variant, show: true })
  }

  // on delete file
  const onDeleteFile = (fileType: FileType, index: number) => {
    if (fileType === FileType.IMAGE) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        imageKeys: prevFormData.imageKeys.filter((_, i) => i !== index),
        signedImageUrls: prevFormData.signedImageUrls.filter((_, i) => i !== index),
      }))
    }
  }

  // on file upload success
  const onUploadSuccess = (key: string, imageUrl: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      signedImageUrls: [...prevFormData.signedImageUrls, imageUrl],
      imageKeys: [...prevFormData.imageKeys, key],
    }))
  }

  // on file upload error
  const onUploadError = () => {
    setToastMessage({
      message: USER_UPLOAD_PHOTO_ERROR,
      variant: AlertVariant.ERROR,
      show: true,
    })
  }

  // on add or update ingredient success
  const onSingleIngredientsSuccess = (data: SingleIngredientResponse) => {
    setFormData(data)
    setAssociatedItems(data.selectedMenus)
  }

  /**
   * ai textarea effects
   */

  const [displayText, setDisplayText] = useState('')
  const textareaRef = useRef(null)

  const scrollTextareaToBottom = () => {
    if (textareaRef.current) {
      // @ts-ignore
      textareaRef.current.scrollTop = textareaRef.current?.scrollHeight
    }
  }

  useEffect(() => {
    scrollTextareaToBottom()
  }, [displayText])

  const updateDisplayText = (text: string) => {
    let currentIndex = 0


    intervalId = setInterval(() => {
      setDisplayText(text.substring(0, currentIndex))
      currentIndex++
      if (currentIndex > text.length) {
        clearInterval(intervalId)
        scrollTextareaToBottom()
      }
    }, 0) // Adjust the typing
  }

  // on add or update ingredient success
  const onAiSuccess = (text: string) => {
    if (text) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ingredientDescription: text,
      }))
      updateDisplayText(text)
    }
  }

  /**
   * ai textarea effects ends
   */

  // on add or update ingredient success
  const onSuccess = (message: string, variant: string) => {
    setToastMessage({ message, variant, show: true })
    queryClient.refetchQueries([INGREDIENTS_LIST]) // nosonar
    setTimeout(() => {
      cleanUpData()
    }, 3000)
  }

  // on add or update ingredient error
  const onError = (message: string, variant: string) => {
    setToastMessage({ message, variant, show: true })
  }

  // clear data
  const cleanUpData = () => {
    setFormData(initialIngredientResponse)
    setIngredient(undefined)
    setToastMessage(toastMessageInitialData)
    setShowSideBar(false)
    setDisplayText('')
    clearInterval(intervalId)
  }

  const isFormValid = (): boolean => {
    const { ingredientName, ingredientDescription } = formData

    const isNameValid = ingredientName?.trim().length > 0
    const isDescriptionValid = ingredientDescription?.trim().length > 0

    return isNameValid && isDescriptionValid
  }

  const submitForm = () => {
    const { ingredientId, ingredientName, ingredientDescription, imageKeys } = formData

    if (editIngredient) {
      // Update ingredient API call
      const updateIngredientData: UpdateIngredient = {
        ingredientName,
        ingredientDescription,
        ingredientId,
        imageKeys: imageKeys,
      }
      updateIngredient(ingredientsMutation, updateIngredientData, onSuccess, onError)
    } else {
      // Create ingredient API call
      const newIngredientData: CreateIngredient = {
        ingredientName,
        ingredientDescription,
        imageKeys: imageKeys,
      }
      createNewIngredient(ingredientsMutation, newIngredientData, onSuccess, onError)
    }
  }

  return (
    <Offcanvas
      show={showSideBar}
      className="custom-offcanvas"
      onHide={() => {
        handleClose()
        cleanUpData()
      }}
      backdrop="static"
      // responsive="xl"
      placement="end"
    >
      {/* TOAST MESSAGE COMPONENT */}
      <ToastAlert
        show={toastMessage.show}
        onClose={() => setToastMessage(toastMessageInitialData)}
        message={toastMessage.message}
        variant={toastMessage.variant}
      />

      {/* IMAGE CROP */}
      <ImageCropModal
        image={imageCropSrc as string}
        show={imageCropSrc !== null}
        setImageCrop={setImageCrop}
        mutation={ingredientsMutation}
        onSuccess={onUploadSuccess}
        onError={onUploadError}
        displayToast={displayToast}
      />

      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{editIngredient 
          ? <JTranslation typeCase="pascal" text={EDIT_INGREDIENT} /> 
          : <JTranslation typeCase="pascal" text={ADD_NEW_INGREDIENT} />
        }</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="row">
          <div className=" col-md-12 col-lg-12 mb-3">
            <label htmlFor="validationCustom01" className="form-label">
              <JTranslation typeCase="pascal" text={NAME} />
              <span className="mandatory ">*</span>{' '}
            </label>
            <input
              type="text"
              className="form-control"
              id="validationCustom01"
              autoComplete="off"
              maxLength={100}
              data-testid="ingredient-name"
              value={formData.ingredientName}
              onChange={(e) => {
                setFormData({ ...formData, ingredientName: e.target.value })
              }}
            />
          </div>
          <div className=" col-md-12 col-lg-12 mb-3">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <label htmlFor="validationCustom01" className="form-label mb-0">
                <JTranslation typeCase="pascal" text={DESCRIPTION} />
                <span className="mandatory ">*</span>{' '}
              </label>
              <button className="btn btn-sm btn-custom-primary-outline" data-testid="ai-text-btn" onClick={() => { setShowAiTextGenerator(!showAiTextGenerator) }}>
                <JTranslation typeCase="pascal" text={AI_TEXT_GENERATOR} />
              </button>
            </div>
            <AiTextGenerator key='ingredientDescription'  callBack={onAiSuccess} show={showAiTextGenerator} type='ingredientDescription' />

            <textarea
              ref={textareaRef}
              className="form-control ingredient-description mb-2"
              id="ingredientDescription"
              autoComplete="off"
              maxLength={10000}
              data-testid="ingredient-desc"
              value={formatTextWithLineBreaks(displayText)}
              //   value={formatTextWithLineBreaks(formData.ingredientDescription)}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  ingredientDescription: e.target.value,
                })
                setDisplayText(e.target.value)
              }}
            />
          </div>
        </div>

        {associatedItems && associatedItems.length > 0 && (
          <label htmlFor="validationCustom01" className="form-label">
            {associatedItems?.length > 1 
              ? <JTranslation typeCase="pascal" text={ASSOCIATED_ITEMS} /> 
              : <JTranslation typeCase="pascal" text={ASSOCIATED_ITEM} />
            }
          </label>
        )}

        <div className=" col-md-12 col-lg-12 mb-3">
          {editIngredient &&
            associatedItems?.map((item) => {
              return (
                <div key={uuidv4()} className="capsule">
                  {item.itemName}
                </div>
              )
            })}
        </div>

        <div className="row">
          <div className=" col-md-12 col-lg-12 mb-3">
            <FileUpload
              displayToast={displayToast}
              onUploadError={onUploadError}
              uploadFileMutation={ingredientsMutation}
              onUploadSuccess={onUploadSuccess}
              images={formData.signedImageUrls}
              videos={[]}
              onDeleteFile={onDeleteFile}
              formData={formData}
              setImages={() => { }}
              setFormData={setFormData}
              disableVideoUpload={true}
              validateImageDimension={validateImageDimension} 
              cropType={cropType}
            />
          </div>
        </div>

        <div className="save-btn-section shadow save-btn-absolute">
          <button
            className="btn btn-custom-primary-outline"
            type="button"
            data-testid="cancel-btn"
            onClick={() => {
              handleClose()
              cleanUpData()
            }}
          >
            <JTranslation typeCase="pascal" text={CANCEL} />
          </button>

          <button
            className="btn btn-custom-primary"
            type="button"
            disabled={!isFormValid()}
            data-testid="save-btn"
            onClick={() => {
              // validate and submit form
              if (isFormValid()) {
                submitForm()
              }
            }}
          >
            {editIngredient 
              ? <JTranslation typeCase="pascal" text={UPDATE} /> 
              : <JTranslation typeCase="pascal" text={SAVE} />
            }
          </button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default IngredientsSideBar
