import { AdditionalInfo, HistoryInfo, MenuItem, Payload, Price, FnBGlobalSetting, QrCodeLabelsType, SingleMenuItem, MenuResetTimeInMinutesType } from "../constants/staticTypes";
import { AlertVariant, HttpMethods, ItemType, PermissionKeys, SubPermissionKeys } from "../constants/constants";
import { AxiosResponse } from "axios";
import { checkPermission, getErrorMessage, popover } from "./utils";
import { MOVE_ITEM, MOVE_CATEGORY, HELP_MOVE_ITEM, HELP_MOVE_CATEGORY, COPY_CATEGORY, COPY_ITEM, HELP_COPY_CATEGORY, HELP_COPY_ITEM } from "../constants/strings";
import { QueryClient, UseMutationResult } from "react-query";
import HttpServiceHelper from "./httpServiceHelper";
import {
    categorySortApi,
    changeMarketPriceLabelApi,
    customMenuItemDetailsApi,
    getFnBGlobalSettingsApi,
    itemSortApi,
    menuCategoryManagementApi,
    menuCategoryStatusApi,
    menuCopyCategoryToMenu,
    menuCopyItemToCategoryMenu,
    menuItemApi,
    menuItemDetailsApi,
    menuItemManagementApi,
    menuItemsApi,
    menuItemStatusApi,
    menuMoveCategoryMenu,
    menuMoveItemCategory,
    menuMoveItemDuplicate,
    qrCodeApi,
    setMenuRestTimeInMinutesApi,
    toggleCardPriceApi,
    toggleSuggestedCardPriceApi,
} from "../constants/apiEndPoints";

// get initial item form data
export const getInitialAddItemFormData = (itemCategoryId: string | undefined, isFoodForm: boolean): MenuItem => ({
    itemCategoryId,
    isAvailable: true,
    isMarketPrice: false,
    itemType: isFoodForm ? ItemType.FOOD : ItemType.BEVERAGE,
    itemName: "",
    itemTag: "",
    itemPrices: [],
    itemDescription: "",
    addonPrices: [],
    selectedAddons: [],
    selectedKeyIngredients: [],
    additionalInfo: [
        {
            textContent: "",
            imageKeys: [],
            signedImageKeys: []
        }
    ],
    photoKeys: [],
    videoKeys: [],
    suggestedItems: [],
    historyInfo: initialHistoryData
});

// initial wine history object data
export const initialHistoryData = {
    title: "",
    subTitle: "",
    description: "",
    originCountry: "",
    otherHtmlContents: "",
    photoKeys: [] as string[],
    signedPhotoKeys: [] as string[],
    color: "",
    bouquet: "",
    taste: "",
    denomination: "",
    additionalInfo: [{
        textContent: "",
        imageKeys: [] as string[],
        signedImageKeys: [] as string[]
    }]
}

// get item formatted for edit
export const getMenuItemEditData = (item: MenuItem) => {
    let historyInfo: HistoryInfo = initialHistoryData;
    if (item.historyInfo) {
        let additionalInfo = historyInfo.additionalInfo;
        if (Array.isArray(item.historyInfo.additionalInfo)) {
            additionalInfo = item.historyInfo.additionalInfo.map(info => {
                info.textContent = info.textContent || "";
                info.imageKeys = info.imageKeys || [];
                return info;
            });
        }

        historyInfo = {
            title: item.historyInfo.title || historyInfo.title,
            subTitle: item.historyInfo.subTitle || historyInfo.subTitle,
            description: item.historyInfo.description || historyInfo.description,
            originCountry: item.historyInfo.originCountry || historyInfo.originCountry,
            otherHtmlContents: item.historyInfo.otherHtmlContents || historyInfo.otherHtmlContents,
            photoKeys: item.historyInfo.photoKeys || historyInfo.photoKeys,
            signedPhotoKeys: item.historyInfo.signedPhotoKeys || historyInfo.signedPhotoKeys,
            color: item.historyInfo.color || historyInfo.color,
            bouquet: item.historyInfo.bouquet || historyInfo.bouquet,
            taste: item.historyInfo.taste || historyInfo.taste,
            denomination: item.historyInfo.denomination || historyInfo.denomination,
            additionalInfo
        }
    }

    let formData: MenuItem = {
        itemCategoryId: item?.categoryId ?? null,
        isAvailable: item.isAvailable ?? false,
        isMarketPrice: item.isMarketPrice ?? false,
        itemName: item.itemName,
        itemDescription: item.itemDescription,
        itemPrices: item?.itemPrices ? [...item.itemPrices] : [],
        itemTag: item.itemTag,
        photoKeys: item.photoKeys,
        itemMenuId: item?.id ?? undefined,
        addonPrices: item?.addonPrices ? [...item.addonPrices] : [],
        selectedAddons: item?.selectedAddons ? [...item.selectedAddons] : [],
        selectedKeyIngredients: [...item.selectedKeyIngredients ?? []],
        historyInfo,
        itemType: item.itemType,
        additionalInfo: item.additionalInfo,
        videoKeys: item.videoKeys,
        suggestedItems: item.suggestedItems,
        signedPhotoKeys: item.signedPhotoKeys,
        signedVideoKeys: item.signedVideoKeys
    }

    if (item?.selectedAddonGroups && item?.selectedAddonGroups.length > 0) {
        formData = { ...formData, selectedAddonGroups: item?.selectedAddonGroups }
    }

    return formData;
}

// get menu list
export const getMenuItems = () => {
    return HttpServiceHelper({
        url: menuItemManagementApi,
        method: HttpMethods.GET
    });
}

// get global F&B menu settings
export const getFnBGlobalSettings = () => {
    return HttpServiceHelper({
        url: getFnBGlobalSettingsApi,
        method: HttpMethods.GET
    });
}

// get menu items without categories
export const getItems = () => {
    return HttpServiceHelper({
        url: menuItemsApi,
        method: HttpMethods.GET
    });
}

// create / update category
export const upsertCategory = (
    createMenuMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    data: {
        categoryId?: string,
        parentCategoryId?: string,
        categoryName: string,
        photoKey?: string,
        categoryDescription?: string
    },
    onSuccess: (message: string) => void,
    onError: (res: string, variant: string) => void) => {
    createMenuMutation.mutate({
        url: menuCategoryManagementApi,
        method: data.categoryId ? HttpMethods.PUT : HttpMethods.POST,
        data: data
    }, {
        onSuccess: (res) => onSuccess(res.data.message),
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    });
}

// delete category
export const deleteCategory = (
    categoryId: string | undefined,
    deleteCategoryMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onSuccess: (message: string) => void,
    onError: (res: string, variant: string) => void) => {
    deleteCategoryMutation.mutate({
        url: menuCategoryManagementApi,
        method: HttpMethods.DELETE,
        data: {
            categoryId
        }
    }, {
        onSuccess: (res) => onSuccess(res.data.message),
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    });
}

// active inactive category
export const activeInactiveCategory = (
    categoryId: string | undefined,
    activeInactiveMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onSuccess: (message: string) => void,
    onError: (res: string, variant: string) => void) => {
    activeInactiveMutation.mutate({
        url: menuCategoryStatusApi,
        method: HttpMethods.POST,
        data: {
            categoryId
        }
    }, {
        onSuccess: (res) => onSuccess(res.data.message),
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    });
}

// change market price label
export const changeMarketPriceLabel = (
    marketPriceLabel: string,
    fnBGlobalConfigMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onSuccess: (marketPriceLabel: string, message: string) => void,
    onError: (res: string, variant: string) => void) => {
    fnBGlobalConfigMutation.mutate({
        url: changeMarketPriceLabelApi,
        method: HttpMethods.POST,
        data: {
            marketPriceLabel
        }
    }, {
        onSuccess: (res) => onSuccess(res.data.data.marketPriceLabel, res.data.message),
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    });
}

// change market price label
export const toggleCardPrice = (
    showMenuCardPrice: boolean,
    fnBGlobalConfigMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onSuccess: (newSettings: FnBGlobalSetting) => void,
    onError: (res: string, variant: string) => void) => {
    fnBGlobalConfigMutation.mutate({
        url: toggleCardPriceApi,
        method: HttpMethods.POST,
        data: {
            showMenuCardPrice
        }
    }, {
        onSuccess: (res) => onSuccess(res.data.data),
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    });
}

// change market price label
export const toggleSuggestedCardPrice = (
    showMenuSuggestedItemCardPrice: boolean,
    fnBGlobalConfigMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onSuccess: (newSettings: FnBGlobalSetting) => void,
    onError: (res: string, variant: string) => void) => {
    fnBGlobalConfigMutation.mutate({
        url: toggleSuggestedCardPriceApi,
        method: HttpMethods.POST,
        data: {
            showMenuSuggestedItemCardPrice
        }
    }, {
        onSuccess: (res) => onSuccess(res.data.data),
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    });
}

// create item
export const upsertItem = (
    createItemMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    form: MenuItem,
    onSuccess: (message: string) => void,
    onError: (res: string, variant: string) => void) => {

    // format data
    const additionalInfo = formatAdditionalInfo(form.additionalInfo);
    const historyAdditionalInfo = formatAdditionalInfo(form.historyInfo.additionalInfo);

    // remove empty prices
    const filteredItemPrices = formatPricesData(form.itemPrices);
    const filteredAddonPrices = formatPricesData(form.addonPrices);

    // invoke api
    createItemMutation.mutate({
        url: menuItemApi,
        method: form.itemMenuId ? HttpMethods.PUT : HttpMethods.POST,
        data: {
            ...form,
            itemPrices: [...filteredItemPrices],
            addonPrices: [...filteredAddonPrices],
            additionalInfo,
            historyInfo: {
                title: form.historyInfo.title,
                subTitle: form.historyInfo.subTitle,
                description: form.historyInfo.description,
                originCountry: form.historyInfo.originCountry,
                otherHtmlContents: form.historyInfo.otherHtmlContents,
                photoKeys: form.historyInfo.photoKeys,
                color: form.historyInfo.color,
                bouquet: form.historyInfo.bouquet,
                taste: form.historyInfo.taste,
                denomination: form.historyInfo.denomination,
                additionalInfo: historyAdditionalInfo
            }
        }
    }, {
        onSuccess: (res) => onSuccess(res.data.message),
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    });
}

// format prices data to remove empty values
export const formatPricesData = (pricesArray: Price[]) => {
    return pricesArray.filter(price =>
        (price.fieldName !== null && price.fieldName?.trim().length > 0) ||
        (price.fieldValue !== null && price.fieldValue?.trim().length > 0));
}

// format additional info data
export const formatAdditionalInfo = (additionalInfo: AdditionalInfo[]) => {
    return additionalInfo.map(info => {
        return {
            textContent: info.textContent,
            imageKeys: info.imageKeys
        }
    });
}

// delete item
export const deleteMenuItem = (
    itemMenuId: string | undefined,
    deleteItemMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onSuccess: (message: string) => void,
    onError: (res: string, variant: string) => void) => {
    deleteItemMutation.mutate({
        url: menuItemApi,
        method: HttpMethods.DELETE,
        data: {
            itemMenuId
        }
    }, {
        onSuccess: (res) => onSuccess(res.data.message),
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    });
}

// active inactive menu item
export const activeInactiveItem = (
    queryClient: QueryClient,
    itemMenuId: string | undefined,
    activeInactiveMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onSuccess: (message: string) => void,
    onError: (res: string, variant: string) => void) => {
    if (checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.STATUS)) {
        activeInactiveMutation.mutate({
            url: menuItemStatusApi,
            method: HttpMethods.POST,
            data: {
                itemMenuId
            }
        }, {
            onSuccess: (res) => onSuccess(res.data.message),
            onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
        });
    }
}

// sort category
export const categorySort = (categoryIdsInOrder: string[], isParentList: boolean,
    sortMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onSuccess: (message: string) => void,
    onError: (res: string, variant: string) => void) => {
    sortMutation.mutate({
        url: categorySortApi,
        method: HttpMethods.POST,
        data: {
            categoryIdsInOrder,
            isParentList
        }
    }, {
        onSuccess: (res) => onSuccess(res.data.message),
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    });
}

// sort items
export const itemSort = (menuItemIdsInOrder: string[],
    sortMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onSuccess: (message: string) => void,
    onError: (res: string, variant: string) => void) => {
    sortMutation.mutate({
        url: itemSortApi,
        method: HttpMethods.POST,
        data: {
            menuItemIdsInOrder
        }
    }, {
        onSuccess: (res) => onSuccess(res.data.message),
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    });
}

// move item to another category 
export const moveItemCategory = (
    createMenuMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    data: {
        itemMenuId: string,
        newCategoryId: string,
    },
    onSuccess: (message: string) => void,
    onError: (res: string, variant: string) => void) => {
    createMenuMutation.mutate({
        url: menuMoveItemCategory,
        method: HttpMethods.POST,
        data: data
    }, {
        onSuccess: (res) => onSuccess(res.data.message),
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    });
}

// move category to another menu 
export const moveCategoryMenu = (
    createMenuMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    data: {
        categoryId: string,
        newMenuId: string,
    },
    onSuccess: (message: string) => void,
    onError: (res: string, variant: string) => void) => {
    createMenuMutation.mutate({
        url: menuMoveCategoryMenu,
        method: HttpMethods.POST,
        data: data
    }, {
        onSuccess: (res) => onSuccess(res.data.message),
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    });
}

// copy category to another menu 
export const copyCategoryMenu = (
    createMenuMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    data: {
        categoryId: string,
        newMenuId: string,
    },
    onSuccess: (message: string) => void,
    onError: (res: string, variant: string) => void) => {
    createMenuMutation.mutate({
        url: menuCopyCategoryToMenu,
        method: HttpMethods.POST,
        data: data
    }, {
        onSuccess: (res) => onSuccess(res.data.message),
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    });
}

// move item to another category 
export const copyItemCategory = (
    createMenuMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    data: {
        itemMenuId: string,
        newCategoryId: string,
    },
    onSuccess: (message: string) => void,
    onError: (res: string, variant: string) => void) => {
    createMenuMutation.mutate({
        url: menuCopyItemToCategoryMenu,
        method: HttpMethods.POST,
        data: data
    }, {
        onSuccess: (res) => onSuccess(res.data.message),
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    });
}


// move category to another menu 
export const cloneMenuItem = (
    createMenuMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    data: {
        itemMenuId: string
    },
    onSuccess: (message: string) => void,
    onError: (res: string, variant: string) => void) => {
    createMenuMutation.mutate({
        url: menuMoveItemDuplicate,
        method: HttpMethods.POST,
        data: data
    }, {
        onSuccess: (res) => onSuccess(res.data.message),
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    });
}

export const getTitleText = (moveType: string) => {
    switch (moveType) {
        case 'item':
            return MOVE_ITEM

        case 'category':
            return MOVE_CATEGORY

        case 'copy-item-to':
            return COPY_ITEM

        case 'copy-category-to':
            return COPY_CATEGORY

        default:
            break
    }
}

export const getPopoverText = (moveType: string) => {
    switch (moveType) {
        case 'item':
            return {title: MOVE_ITEM, body: HELP_MOVE_ITEM}

        case 'category':
            return {title: MOVE_CATEGORY, body: HELP_MOVE_CATEGORY}

        case 'copy-item-to':
            return {title: COPY_ITEM, body: HELP_COPY_ITEM}

        case 'copy-category-to':
            return {title: COPY_CATEGORY, body: HELP_COPY_CATEGORY}

        default:
            return {title: MOVE_ITEM, body: HELP_MOVE_ITEM}
    }
}

// active inactive category
export const addQrCodeLabels = (
    params: QrCodeLabelsType,
    fnBGlobalConfigMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onSuccess: (newSetting: FnBGlobalSetting, message: string) => void,
    onError: (res: string, variant: string) => void) => {
    fnBGlobalConfigMutation.mutate({
        url: qrCodeApi,
        method: HttpMethods.POST,
        data: params
    }, {
        onSuccess: (res) => onSuccess(res.data.data, res.data.message),
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    });
}

// get single menu item details
export const getSingleMenuItem = (
    params: SingleMenuItem,
    getSingleMenuItemMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onSuccess: (menuItem: MenuItem, message: string) => void,
    onError: (res: string, variant: string) => void
) => {
    getSingleMenuItemMutation.mutate(
        {
            url: menuItemDetailsApi,
            method: HttpMethods.POST,
            data: params,
        },
        {
            onSuccess: (res) => onSuccess(res.data.data, res.data.message),
            onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
        }
    )
}

// get menu items details
export const getAsyncSingleMenuItem = async (
    params: SingleMenuItem,
    getSingleMenuItemMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>
) => {
    return await getSingleMenuItemMutation.mutateAsync({
        url: menuItemDetailsApi,
        method: HttpMethods.POST,
        data: params,
    })
}

// get custom menu items without categories
export const getAsyncCustomMenuItem = async (
    params: SingleMenuItem,
    getSingleMenuItemMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>
) => {
    return await getSingleMenuItemMutation.mutateAsync({
        url: customMenuItemDetailsApi,
        method: HttpMethods.POST,
        data: params,
    })
}

// set menu reset time
export const updateMenuRestTime = (
    params: MenuResetTimeInMinutesType,
    fnBGlobalConfigMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onSuccess: (newSetting: FnBGlobalSetting, message: string) => void,
    onError: (res: string, variant: string) => void) => {
    fnBGlobalConfigMutation.mutate({
        url: setMenuRestTimeInMinutesApi,
        method: HttpMethods.POST,
        data: params
    }, {
        onSuccess: (res) => onSuccess(res.data.data, res.data.message),
        onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
    });
}