import React, { useEffect, useRef } from 'react'
import { Toast } from 'primereact/toast'
import { ToastMessageVariant } from '../../constants/staticTypes'
import { JTranslation } from '../../helpers/jTranslate'
// Updated Props type with specific variant types
type Props = {
  show: boolean
  onClose: React.Dispatch<React.SetStateAction<boolean>>
  message: string
  variant: string// Restricting variant to specific values
  dataTestId?: string
  timeOut?: number | undefined
}
function getVariantMessage(
  variant: any
): string {
  switch (variant) {
    case 'success':
      return 'Success'
    case 'info':
      return 'Info'
    case 'warn':
      return 'Warn'
    case 'error':
      return 'Error Occurred'
    default:
      return ''
  }
}

function mapVariant(input: string): ToastMessageVariant {
  switch (input.toLowerCase()) {
    case 'error':
    case 'danger':
      return 'error'
    case 'success':
      return 'success'
    case 'info':
      return 'info'
    case 'warn':
    case 'warning':
      return 'warn'
    default:
      throw new Error('Invalid variant')
  }
}

function ToastAlert({
  show,
  onClose,
  message,
  variant,
  dataTestId,
  timeOut = 3000,
}: Props) {
  const toast = useRef<Toast>(null)

  useEffect(() => {
    if (show) {
      // const id = new Date().getTime().toString()
      toast.current?.show({
        // id,
        severity: mapVariant(variant),
        summary: <JTranslation typeCase="capitalize" text={getVariantMessage(variant)} />,
        detail: <JTranslation typeCase="capitalize" text={message} />,
        life: timeOut,

        // Implementing callbacks
      })
    }
  }, [show, message, variant])

  const handleOnHide = () => {
    onClose(false) // Resetting the show state
  }

  const handleOnRemove = (message: any) => {
    // Additional logic for when a toast is removed
    onClose(false) // Resetting the show state
  }

  const handleOnShow = () => {

  }
  return (
    <div className='custom-toast-old' data-testid={dataTestId}>
      <Toast
        ref={toast}
        onShow={handleOnShow}
        onHide={handleOnHide}
        onRemove={handleOnRemove}
      />
    </div>
  )
}
// ;<Toast ref={toast} onShow={onShow} onHide={onHide} onRemove={onRemove} />
export default ToastAlert
