import React, { useRef, useState, useEffect } from 'react'
import 'react-image-crop/src/ReactCrop.scss'
import { AlertVariant, IMAGE_TYPE, FileType } from '../../../../constants/constants'
import { AxiosResponse } from 'axios'
import { CANCEL, UPLOAD_PHOTO } from '../../../../constants/strings'
import { getCroppedImageByCanvas, getVideoSignedUrl, uploadFileService, uploadFileUsingSignedUrl } from '../../../../helpers/fileUploadHelper'
import { getErrorMessage, showLoader } from '../../../../helpers/utils'
import { Modal } from 'react-bootstrap'
import { Payload, DisplayModalType, CropType, Dimension } from '../../../../constants/staticTypes'
import { UseMutationResult, useQueryClient } from 'react-query'
import ReactCrop, { Crop, PixelCrop, centerCrop, convertToPixelCrop, makeAspectCrop } from 'react-image-crop'
import { JTranslation } from '../../../../helpers/jTranslate'
import { Slider } from 'primereact/slider'
import { useDebounceEffect } from '../../../../hooks/useDebounceEffect'
import { canvasPreview } from './CanvasPreview'

type Props = {
	image: string
	show: boolean
	modalHeight?: string
	hideModal?: (param: DisplayModalType) => void
	setImageCrop: React.Dispatch<React.SetStateAction<string | ArrayBuffer | null>>
	mutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>
	onSuccess: (key: string, fileUrl: string, fileType: FileType) => void
	onError: (fileType: FileType) => void
	displayToast: (message: string, variant: string) => void
	cropType?: CropType
}

function ImageCropModal({
	image,
	show,
	setImageCrop,
	displayToast,
	mutation,
	onSuccess,
	onError,
	modalHeight,
	hideModal,
	cropType = 'FREEFORM',
}: Readonly<Props>) {
	const previewCanvasRef = useRef<HTMLCanvasElement>(null)
	const imageRef = useRef<HTMLImageElement>(null)
	const [minDimension, setMinDimension] = useState<Dimension>()
	const [aspect, setAspect] = useState<number | undefined>()
	const [crop, setCrop] = useState<Crop>()
	const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
	const queryClient = useQueryClient()
	const [scale, setScale] = useState<number>(50)

	useEffect(() => {
		setAspect(IMAGE_TYPE[cropType].aspectRatio)
		setMinDimension({ width: IMAGE_TYPE[cropType].width, height: IMAGE_TYPE[cropType].height })
	}, [cropType])

	// effect to hide and show modal body once the image crop modal is open
	useEffect(() => {
		if (hideModal) {
			show ? hideModal('display-none') : hideModal('')

			return () => {
				hideModal('')
			}
		}
	}, [show]) // eslint-disable-line

	// hide modal
	const onHide = () => {		
		setScale(50)
		setCrop(undefined)
		setImageCrop(null)
	}

	const centerAspectCrop = (
		mediaWidth: number,
		mediaHeight: number,
		cropWidth: number,
		cropHeight: number,
		aspect: number
	) => {
		return centerCrop(
			makeAspectCrop(
				{
					unit: 'px',
					height: cropHeight,
					width: cropWidth,
				},
				aspect,
				mediaWidth,
				mediaHeight
			),
			mediaWidth,
			mediaHeight
		)
	}

	useDebounceEffect(
		async () => {
			if (completedCrop?.width && completedCrop?.height && imageRef.current && previewCanvasRef.current) {
				// We use canvasPreview as it's much faster than imgPreview.
				canvasPreview(imageRef.current, previewCanvasRef.current, completedCrop, scale / 50)
			}
		},
		100,
		[completedCrop, scale]
	)

	const onBigImageUrlSuccess = (
		key: string,
		fileType: FileType,
		url: string,
		file: File,
		fields: { [key: string]: string }
	) => {
		uploadFileUsingSignedUrl(url, key, file, mutation, onSuccess, onError, FileType.IMAGE, fields)
	}

	return (
		<Modal centered show={show} size="lg" scrollable={true}>
			<Modal.Header>
				<div className="w-100 d-flex justify-content-between align-items-center">
					<h5 className="mb-0">
						<JTranslation typeCase="pascal" text={'Crop Image'} />
					</h5>
					<div className="d-flex justify-content-even align-items-center zoom-slider">
						<i className="pi pi-search-plus me-2" />
						<Slider
							style={{ height: '0.45rem', width: '10rem' }}
							value={scale}
							onChange={(e) => {
								const scale = e.value as number
								setScale(scale)
							}}
						/>
						<i className="pi pi-search-minus ms-2" />
					</div>
				</div>
			</Modal.Header>
			<Modal.Body className="col-sm-12">
				<>
					<div className="crop-image" style={{ minHeight: modalHeight }}>
						<ReactCrop
							className="img-thumb-main"
							crop={crop}
							onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
							keepSelection
							onComplete={(pixelCrop, percentCrop) => setCompletedCrop(pixelCrop)}
							minWidth={minDimension?.width}
							minHeight={minDimension?.height}
							aspect={aspect}
						>
							<img
								src={image}
								alt="crop"
								ref={imageRef}
								onLoad={(event) => {
									const { width, height, naturalHeight, naturalWidth } = event.currentTarget
									if (aspect && minDimension?.width && minDimension?.height) {
										const newCrop = centerAspectCrop(
											width,
											height,
											minDimension?.width,
											minDimension?.height,
											aspect
										)
										setCrop(newCrop)
										setCompletedCrop(convertToPixelCrop(newCrop, width, height))
									} else {
										const newCrop = centerCrop(
											makeAspectCrop({ unit: '%', width: 50 }, 1, naturalWidth, naturalHeight),
											naturalWidth,
											naturalHeight
										)
										setCrop(newCrop)
										setCompletedCrop(convertToPixelCrop(newCrop, width, height))
									}
								}}
								style={{ transform: `scale(${scale / 50})`, maxHeight: '82vh' }}
							/>
						</ReactCrop>
					</div>
					<div className="d-flex justify-content-end mt-2">
						<button className="btn btn-custom-primary-outline" onClick={onHide}>
							<JTranslation typeCase="pascal" text={CANCEL} />
						</button>
						<button
							className="ms-2 btn btn-custom-primary"
							onClick={async () => {
								// crop and upload image
								try {
									onHide()
									const currentImage = imageRef.current
									const previewCanvas = previewCanvasRef.current
									if (currentImage && completedCrop && previewCanvas) {
										showLoader(queryClient, true)
										// const image = await getCroppedImage(currentImage, completedCrop)
										const image = await getCroppedImageByCanvas(previewCanvas, completedCrop)
										const croppedFile = new File([image] as BlobPart[], 'cropped_image.png', {
											type: 'image/png',
										})

										// check if file size is greater than 5MB
										if(croppedFile.size > 5 * 1024 * 1024) {
											getVideoSignedUrl(
												croppedFile,
												mutation,
												onBigImageUrlSuccess,
												onError,
											)
										} else {
											uploadFileService(
												croppedFile,
												mutation,
												onSuccess,
												onError,
												FileType.IMAGE
											)
										}
									}
								} catch (e) {
									showLoader(queryClient, false)
									displayToast(getErrorMessage(e), AlertVariant.ERROR)
								}
							}}
						>
							<JTranslation typeCase="pascal" text={UPLOAD_PHOTO} />
						</button>
					</div>
					{!!completedCrop && (
						// Added canvas to have the full resolution of the image even, when the crop done in mobile screen
						<canvas
							ref={previewCanvasRef}
							style={{
								border: '1px solid black',
								objectFit: 'contain',
								width: completedCrop.width,
								height: completedCrop.height,
								display: 'none',
							}}
						/>
					)}
				</>
			</Modal.Body>
		</Modal>
	)
}

export default React.memo(ImageCropModal)
