import React from 'react'
import withSidebar from '../../hoc/withSidebar'
import FullCalendar from '../../helpers/delete'

const AdminLayoutPage = () => {
	return (
		<div>
			<h4>A page for testing components and libraries</h4>
			<FullCalendar />
		</div>
	)
}

export default withSidebar(AdminLayoutPage)
