import { useQueryClient } from 'react-query'
import useMutationHook from '../../hooks/useMutationHook'
import {
	AboutUsConfig,
	AboutUsConfigFormData,
	CropType,
	ToastMessageProps,
	UploadedAboutUsImage,
} from '../../constants/staticTypes'
import { ChangeEvent, useEffect, useState } from 'react'
import { AlertVariant, FileType, IMAGE_TYPE, toastMessageInitialData } from '../../constants/constants'
import {
	getAboutUsScreenConfig,
	imagePlacementTemplates,
	initialAboutUsFormData,
	initialAboutUsImageData,
	updateAboutUsData,
} from '../../helpers/aboutUsHelper'
import { useFormik } from 'formik'
import ToastAlert from '../../components/alert/ToastAlert'
import { JTranslation } from '../../helpers/jTranslate'
import { InputText } from 'primereact/inputtext'
import {
	ABOUT_US_IMAGE_PLACEMENT_MESSAGE_1,
	ABOUT_US_IMAGE_PLACEMENT_MESSAGE_2,
	AI_TEXT_GENERATOR,
	CANCEL,
	FILE_UPLOAD_LIMIT,
	UPLOAD_PHOTO,
	UPLOAD_PHOTO_ERROR,
	UPLOAD_PHOTO_INFO,
	UPLOAD_PHOTO_MESSAGE,
	USER_UPLOAD_PHOTO_ERROR,
	USER_UPLOAD_VIDEO_ERROR,
} from '../../constants/strings'
import WysiwygEditorBasic from '../../components/wysiwyg_editor/WysiwygEditorBasic'
import { ABOUT_US_CONFIG } from '../../constants/queryKeys'
import { AxiosResponse } from 'axios'
import useQueryHook from '../../hooks/useQueryHook'
import AiTextGenerator from '../../components/ai_text_generator/AiTextGenerator'
import { checkIfHeicHeif, uploadFile } from '../../helpers/fileUploadHelper'
import menuImg from '../../assets/images/menu/no_image_upload.png'
import InnerLoader from '../../components/loader/InnerLoader'
import { Dropdown } from 'primereact/dropdown'
import ImageCropModal from '../../components/f&b_menu/admin/add_item_forms/ImageCropModal'
import { convertTextToHTML, escapeHtml, replaceMessageValues, unescapeHtml } from '../../helpers/utils'

function AboutUs() {
	const queryClient = useQueryClient()
	const aboutUsMutuation = useMutationHook(queryClient, true)
	const uploadFileMutation = useMutationHook(queryClient, true) // upload file mutation

	// STATE VARIABLES
	const [aboutUsFormData, setAboutUsFormData] = useState<AboutUsConfig | null>(null)
	const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
	const [showAiTextGenerator, setShowAiTextGenerator] = useState(false)
	const [aboutUsImage, setAboutUsImage] = useState<UploadedAboutUsImage>(initialAboutUsImageData)
	const [imageCropSrc, setImageCrop] = useState<string | ArrayBuffer | null>(null)
	const [cropType, setCropType] = useState<CropType>('MENU')
	const [validateImageDimension, setValidateImageDimension] = useState<{ width: number; height: number }>({
		width: IMAGE_TYPE[cropType].width as number,
		height: IMAGE_TYPE[cropType].height as number,
	})

	const aboutUsConfig = useQueryHook(ABOUT_US_CONFIG, getAboutUsScreenConfig, (res: AxiosResponse) => {
		const config: AboutUsConfig = res.data.data
		setAboutUsFormData(config)
		setAboutUsImage({ key: config.imageKey, signedUrl: config.signedUrl })
	})

	const onSuccess = () => {
		setToastMessage({ message: 'Successfully updated', show: true, variant: AlertVariant.SUCCESS })
		aboutUsConfig.refetch()
	}

	const onError = (error: string, variant: string) =>
		setToastMessage({ message: error, show: true, variant: variant })

	// upload photo success
	const onUploadSuccess = (key: string, imageUrl: string, _fileType: FileType) => {
		setAboutUsImage({ key, signedUrl: imageUrl })
		aboutUsFromik.setFieldValue('imageKey', key)
	}

	// show toast
	const displayToast = (message: string, variant: string) => {
		setToastMessage({ message: message, show: true, variant: variant })
	}

	// on file upload error
	const onUploadError = (fileType: FileType) => {
		displayToast(
			fileType === FileType.IMAGE ? USER_UPLOAD_PHOTO_ERROR : USER_UPLOAD_VIDEO_ERROR,
			AlertVariant.ERROR
		)
	}

	// FORMIK INITIALIZATION
	const aboutUsFromik = useFormik({
		enableReinitialize: true,
		initialValues: aboutUsFormData ?? initialAboutUsFormData,
		validate: (data) => {
			let errors: any = {}
			if (data.imagePlacement === undefined || data.imagePlacement === null) {
				errors.imagePlacement = 'required'
			}
			if (data.aboutUsTitle === undefined || data.aboutUsTitle === null) {
				errors.aboutUsTitle = 'required'
			}
			if (data.aboutUsRichTextData === undefined || data.aboutUsRichTextData === null) {
				errors.aboutUsRichTextData = 'required'
			}
			return errors
		},
		onSubmit: (data) => {
			const payloadData: AboutUsConfigFormData = {
				imageKey: aboutUsImage.key,
				imagePlacement: data.imagePlacement,
				aboutUsRichTextData: data.aboutUsRichTextData,
				aboutUsTitle: data.aboutUsTitle,
			}
			updateAboutUsData(aboutUsMutuation, payloadData, onSuccess, onError)
		},
	})

	// validate upload image dimensions
	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files && e.target.files[0]
		if (!file) {
			return // Handle no file selected case
		}

		const reader = new FileReader()
		reader.onload = (event) => {
			if (event.target?.result && typeof event.target?.result === 'string') {
				const img = new Image()
				img.onload = () => {
					const width = img.naturalWidth
					const height = img.naturalHeight
					if (
						validateImageDimension?.width &&
						validateImageDimension?.height &&
						(width < validateImageDimension.width || height < validateImageDimension.height)
					) {
						displayToast(
							replaceMessageValues(UPLOAD_PHOTO_ERROR, [
								validateImageDimension?.width,
								validateImageDimension?.height,
							]),
							AlertVariant.ERROR
						)
						e.target.value = ''
					} else {
						uploadFile(
							FileType.IMAGE,
							e,
							uploadFileMutation,
							onUploadSuccess,
							onUploadError,
							displayToast,
							setImageCrop
						)
					}
				}
				img.src = event.target.result
			}
		}
		reader.readAsDataURL(file)
	}

	useEffect(() => {
		aboutUsConfig.refetch()
	}, [])

	useEffect(() => {
		const aboutUsResponse = queryClient.getQueryData(ABOUT_US_CONFIG) as AxiosResponse<any, any>
		const aboutUsData = aboutUsResponse?.data?.data as AboutUsConfig

		if (aboutUsFromik.values?.imagePlacement === 'top') {
			setCropType('BANNER')
			setValidateImageDimension({
				width: IMAGE_TYPE['BANNER'].width as number,
				height: IMAGE_TYPE['BANNER'].height as number,
			})
			// clear image on placement change
			if (['left', 'right'].includes(aboutUsData?.imagePlacement)) {
				setAboutUsImage(initialAboutUsImageData)
			}
		} else {
			setCropType('MENU')
			setValidateImageDimension({
				width: IMAGE_TYPE['MENU'].width as number,
				height: IMAGE_TYPE['MENU'].height as number,
			})
			// clear image on placement change
			if (aboutUsData?.imagePlacement === 'top') {
				setAboutUsImage(initialAboutUsImageData)
			}
		}
	}, [aboutUsFromik.values?.imagePlacement])

	if (aboutUsConfig.isFetching) return <InnerLoader />

	return (
		<>
			<ToastAlert
				data-testid="toast"
				message={toastMessage.message}
				onClose={() => setToastMessage(toastMessageInitialData)}
				show={toastMessage.show}
				variant={toastMessage.variant}
			/>

			<ImageCropModal
				image={imageCropSrc as string}
				show={imageCropSrc !== null}
				setImageCrop={setImageCrop}
				mutation={aboutUsMutuation}
				onSuccess={onUploadSuccess}
				onError={onUploadError}
				displayToast={displayToast}
				cropType={cropType}
			/>

			<form onSubmit={aboutUsFromik.handleSubmit} className="row h-100 pb-5 overflow-auto">
				<div className="col-md-5 col-lg-4 d-flex flex-column">
					<div className="flex-grow-0">
						<label className="form-label">
							<JTranslation typeCase="pascal" text={'Select Image Placement'} />
						</label>
						<div className="p-inputgroup flex-1">
							<Dropdown
								className={`flex-grow-1 festival-dropdown ${aboutUsFromik.errors.imagePlacement ? 'border-danger' : ''
									}`}
								data-testid="about-us-image-placement"
								value={aboutUsFromik.values.imagePlacement}
								onChange={(event) => {
									aboutUsFromik.setFieldValue('imagePlacement', event.target.value, true)
								}}
								options={imagePlacementTemplates}
								optionLabel="name"
								optionValue="value"
							/>
						</div>
					</div>

					<div className="flex-grow-auto pt-3">
						<div
							className={`${aboutUsImage.signedUrl ? 'img-thumb-main' : 'no-image-avathar img-thumb-main'
								} ${aboutUsFromik.errors.imageKey ? 'border border-danger rounded' : ''}`}
						>
							<div className="view-img-preview welcome-img-box">
								{aboutUsImage?.signedUrl?.trim().length > 0 ? (
									<img src={aboutUsImage.signedUrl} className="img-fluid rounded-1" alt=" " />
								) : (
									<img src={menuImg} className="img-fluid rounded-1" alt=" " />
								)}
							</div>
							<div className="preview-tool text-end">
								<i
									role="button"
									className="ri-delete-bin-6-line delete"
									onClick={() => {
										setAboutUsImage(initialAboutUsImageData)
									}}
								></i>
							</div>
						</div>
						<div className="pt-3 d-flex flex-column upload-c mb-3">
							<label
								htmlFor="history-upload"
								className={`custom-file-upload btn  btn-custom-primary-outline w-100                                 
                                            ${aboutUsImage.key && ' disabled'}`}
							>
								<i className="fas fa-plus"></i> <JTranslation typeCase="pascal" text={UPLOAD_PHOTO} />{' '}
							</label>
							<input
								id="history-upload"
								type="file"
								accept="image/*,.heic,.heif"
								autoComplete="off"
								disabled={aboutUsImage.key ? true : false}
								data-testid="upload-img"
								onChange={async (event) => {
									const updatedEvent = await checkIfHeicHeif(event)
									if (validateImageDimension) {
										handleFileChange(updatedEvent)
									} else {
										uploadFile(
											FileType.IMAGE,
											updatedEvent,
											uploadFileMutation,
											onUploadSuccess,
											onUploadError,
											onError,
											setImageCrop
										)
									}
								}}
							/>
							<div className="mt-2 small text-secondary">
								*{' '}
								<JTranslation
									typeCase="capitalize"
									text={
										validateImageDimension?.width && validateImageDimension?.height
											? replaceMessageValues(UPLOAD_PHOTO_INFO, [
												validateImageDimension?.width,
												validateImageDimension?.height,
											])
											: UPLOAD_PHOTO_MESSAGE
									}
								/>
							</div>
							{/* <div className="mt-2 small text-secondary">
								* <JTranslation typeCase="pascal" text={UPLOAD_PHOTO_MESSAGE} />
							</div>
							<div className="small text-secondary">
								* <JTranslation typeCase="pascal" text={FILE_UPLOAD_LIMIT} />
							</div> */}
							{/* <div className="small text-secondary">
								* <JTranslation typeCase="capitalize" text={ABOUT_US_IMAGE_PLACEMENT_MESSAGE_1} />
							</div>
							<div className="small text-secondary">
								* <JTranslation typeCase="capitalize" text={ABOUT_US_IMAGE_PLACEMENT_MESSAGE_2} />
							</div> */}
						</div>
					</div>
				</div>

				<div className="col-md-7 col-lg-8">
					<div className="col-md-12 mb-3 festival-form">
						<div className="col-12">
							<label className="form-label">
								<JTranslation typeCase="pascal" text={'Title'} />
							</label>
							<div className="p-inputgroup flex-1">
								<InputText
									className={`${aboutUsFromik.errors.aboutUsTitle ? 'border border-danger' : ''}`}
									data-testid="aboutus-title"
									value={aboutUsFromik?.values?.aboutUsTitle}
									onChange={(event) => {
										aboutUsFromik.setFieldValue('aboutUsTitle', event.target.value, true)
									}}
								/>
							</div>
						</div>

						<div className="col-12 mt-4">
							<label className="form-label w-100 d-flex justify-content-between align-items-center">
								<JTranslation typeCase="pascal" text={'Description'} />
								<div className="flex-grow-0 d-flex justify-content-end ">
									<button
										type="button"
										className="btn btn-sm btn-custom-primary-outline"
										data-testid="ai-text-btn"
										onClick={() => {
											setShowAiTextGenerator(!showAiTextGenerator)
										}}
									>
										<JTranslation typeCase="pascal" text={AI_TEXT_GENERATOR} />
									</button>
								</div>
							</label>
							<div className="col-12">
								<AiTextGenerator
									key="aboutUsDescription"
									show={showAiTextGenerator}
									callBack={(aiResponse: string) => {
										const htmlString = convertTextToHTML(aiResponse)
										aboutUsFromik.setFieldValue('aboutUsRichTextData', htmlString, true)
									}}
									type="aboutUsDescription"
								/>
							</div>
							<div
								className={`${aboutUsFromik.errors.aboutUsRichTextData ? 'border border-danger' : ''}`}
							>
								<WysiwygEditorBasic
									editorContent={unescapeHtml(aboutUsFromik?.values?.aboutUsRichTextData)}
									data-testid="aboutus-description"
									callback={(value) => {
										aboutUsFromik.setFieldValue('aboutUsRichTextData', escapeHtml(value), true)
									}}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="save-btn-section shadow save-btn-absolute">
					<button
						className="btn btn-custom-primary-outline ms-2"
						type="button"
						data-testid="clear-button"
						// disabled={!aboutUsFromik.dirty}
						onClick={() => {
							const aboutUsResponse = queryClient.getQueryData(ABOUT_US_CONFIG) as AxiosResponse<any, any>
							const aboutUsData = aboutUsResponse.data.data as AboutUsConfig
							aboutUsFromik.setValues(aboutUsData)
							setAboutUsImage({ key: aboutUsData.imageKey, signedUrl: aboutUsData.signedUrl })
						}}
					>
						<JTranslation typeCase="pascal" text={CANCEL} />
					</button>

					<button
						className="btn btn-custom-primary ms-2"
						type="submit"
						data-testid="save-button"
						disabled={!aboutUsFromik.isValid}
					>
						<JTranslation typeCase="pascal" text={'Publish'} />
					</button>
				</div>
			</form>
		</>
	)
}

export default AboutUs
