import type React from 'react';
import './cincoDeMayoLogoAnimation.css';
import { getCincoDeMayoDayEffects } from '../celebrationsConfigs';

const CincoDeMayoLogoAnimation: React.FC<{ style?: React.CSSProperties }> = ({
  style,
}) => {
  const cincoDeMayoDayEffects = getCincoDeMayoDayEffects();

  if (cincoDeMayoDayEffects?.showSpecialLogo) {
    return (
      <div className='animation-container-cincodemayo-logo'>
        <img
          src={cincoDeMayoDayEffects?.specialLogoUrl || ''}
          alt='CincodeMayo'
          style={style}
        />
      </div>
    );
  }
  return null;
};

export default CincoDeMayoLogoAnimation;
