import { Accordion, AccordionTab } from 'primereact/accordion';
import { QueryClient } from "react-query";
import { PermissionKeys, SubPermissionKeys } from "../../constants/constants";
import { UserRolesList } from "../../constants/staticTypes";
import { getItemSelectedState } from "../../helpers/userRolesHelper";
import { checkPermission, toPascalCase } from "../../helpers/utils";
import { v4 as uuidv4 } from 'uuid';
import { JTranslation } from "../../helpers/jTranslate";

type Props = {
    queryClient: QueryClient,
    selectedItem: UserRolesList | undefined,
    selectRoleItem: React.Dispatch<React.SetStateAction<UserRolesList | undefined>>
}

function FeatureList({ queryClient, selectedItem, selectRoleItem }: Props) {
    return (
        <>
            {selectedItem &&
                <div className="mt-2">
                    <Accordion activeIndex={parseInt(selectedItem.rolePermissions[0].permissionKey)}>
                        {selectedItem?.rolePermissions.map((feature, index) =>
                            <AccordionTab key={feature.permissionKey} data-testid={feature.permissionKey + "#permission"}
                                header={
                                    <div className={feature.permissions.length > 0 ? "d-flex align-items-center" : 'accordian-custom-arrow d-flex align-items-center'}>
                                        <span className="form-check btn-lg-custom form-switch ">
                                            <input className="form-check-input form-check-input-custom"
                                                type="checkbox"
                                                autoComplete="off"
                                                data-testid={feature.permissionKey}
                                                checked={feature.isEnabled}
                                                disabled={
                                                    selectedItem.isDefault ||
                                                    ('allowMakeAsDisabled' in feature && !feature.allowMakeAsDisabled) ||
                                                    !checkPermission(queryClient, PermissionKeys.MANAGE_ROLES, SubPermissionKeys.EDIT)
                                                }
                                                onClick={(e) => e.stopPropagation()}
                                                onChange={(e) => {
                                                    if (
                                                        (
                                                            !selectedItem.isDefault || 
                                                            ('allowMakeAsDisabled' in feature && !feature.allowMakeAsDisabled)
                                                        ) && 
                                                        checkPermission(queryClient, PermissionKeys.MANAGE_ROLES,
                                                        SubPermissionKeys.EDIT)
                                                    ) {
                                                        selectRoleItem(getItemSelectedState(selectedItem, index, e.target.checked, "all"));
                                                    }
                                                }}
                                                id="flexSwitchCheckDefault" />
                                        </span>
                                        <span className="role-name text-black">
                                            <JTranslation typeCase="pascal" text={feature.permissionName} />
                                        </span>
                                    </div>
                                }
                            >
                                {
                                    feature.permissions.length > 0 && <div>
                                        {feature.permissions.map((permission, _index) => <div key={`${permission.key}-${uuidv4()}`} className="d-flex mb-3">
                                            <div className="flex-grow-0 ">
                                                <div className=""><span className="form-check form-switch">
                                                    <input className="form-check-input form-check-input-custom"
                                                        type="checkbox"
                                                        autoComplete="off"
                                                        data-testid={feature.permissionKey + " " + permission.name}
                                                        checked={permission.value}
                                                        disabled={
                                                            (
                                                                selectedItem.isDefault || 
                                                                ('allowMakeAsDisabled' in permission ? !permission.allowMakeAsDisabled : selectedItem.isDefault)
                                                            ) ||
                                                            !checkPermission(queryClient, PermissionKeys.MANAGE_ROLES,
                                                                SubPermissionKeys.EDIT)
                                                        }
                                                        onChange={(e) => {
                                                            if (
                                                                (
                                                                    !selectedItem.isDefault || 
                                                                    ('allowMakeAsDisabled' in permission ? permission.allowMakeAsDisabled : !selectedItem.isDefault)
                                                                ) &&
                                                                checkPermission(queryClient, PermissionKeys.MANAGE_ROLES,
                                                                    SubPermissionKeys.EDIT)
                                                            ) {
                                                                selectRoleItem(getItemSelectedState(selectedItem, index, e.target.checked, permission.key));
                                                            }
                                                        }}
                                                        id="flexSwitchCheckDefault" />
                                                </span></div>
                                            </div>
                                            <div className="flex-grow-1"><JTranslation typeCase="pascal" text={permission.name} /></div>
                                            <div className=""></div>
                                        </div>)}
                                    </div>
                                }
                            </AccordionTab>
                        )}
                    </Accordion>
                </div>
            }
        </>
    );
}

export default FeatureList;