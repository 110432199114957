import { useCallback, useContext, useEffect, useRef, useState } from 'react'
// import { ReactQueryDevtools } from 'react-query/devtools'
// import { serviceWorkerRegistration } from './pwa-bkp/serviceWorkerRegistration'
// import { useSnackbar } from 'notistack'
import { AxiosResponse } from 'axios'
import { checkPermission, showLoader, isMobile, isPathPublic } from './helpers/utils'
import { ContextAPI } from './context/ContextAPI'
import {
	getRootRoute,
	getTenantInfo,
	getUserInfo,
	getUserPermissions,
	saveLoginStatus,
	savePermissions,
	setLoginStatus,
	setPermissions,
	userSession,
	getGoogleRecaptchaKey,
} from './helpers/authHelper'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import {
	BRANDING_INFO,
	LOGIN_STATUS,
	PERMISSIONS,
	PERMISSION_LIST,
	TENANT_INFO,
	USER_INFO,
} from './constants/queryKeys'
import {
	PermissionKeys,
	SubPermissionKeys,
	TENANT_ADMIN,
	TENANT_ADMIN_TYPE,
	TENANT_ID_HEADER,
	TENANT_NAME,
	STAFF_ID,
	USER_NAME,
	SPECIAL_EFFECTS_NAME,
	FESTIVAL_TEMPLATES,
	TIMEZONE_INFO,
	ORG_ID_HEADER,
} from './constants/constants'
import { routes } from './constants/routes'
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom'
import { Toast } from 'primereact/toast'
import { useQuery, useQueryClient } from 'react-query'
import { BrandingData, BrandingResponseData, PermissionList, TimezoneInfo, Users } from './constants/staticTypes'
import { useWakeLock } from 'react-screen-wake-lock'
// PAGES
import AddExistingItems from './pages/custom_f&b_menu/AddExistingItems'
import AdminLayoutPage from './pages/admin_layout_page/AdminLayoutPage'
import BrandingConfig from './pages/branding_config/BrandingConfig'
import CreateQuestionSet from './pages/survey/CreateQuestionSet'
import CustomMenuManagement from './pages/custom_f&b_menu/CustomMenuManagement'
import Dashboard from './pages/dashboard/Dashboard'
import ErrorBoundary from './components/error_boundary/ErrorBoundary'
import EventsOnlyGuestView from './pages/news_&_events/EventsOnlyGuestView'
import FileManagement from './pages/file_management/FileUploadWrapper'
import FileTypeVerification from './pages/file_type_verification/FileTypeVerification'
import ForgotPassword from './pages/forgot_password/ForgotPassword'
import GameManagement from './pages/game/GameManagement'
import GuestInfoList from './pages/guest_info/GuestInfoList'
import GuestSpinWheel from './pages/game/GuestSpinWheel'
import GuestSurvey from './pages/survey/GuestSurvey'
import GuestView from './pages/f&b_menu/GuestView'
import HolidayConfig from './pages/holiday_config/HolidayConfig'
import IngredientsView from './components/f&b_menu/guest/IngredientsView'
import ItemView from './components/f&b_menu/guest/ItemView'
import Loader from './components/loader/Loader'
import Login from './pages/login/Login'
import MagicLinkPage from './pages/magic_link_page/MagicLinkPage'
import ManageAddons from './pages/manage_addons/ManageAddons'
import ManageCoupon from './pages/manage_coupon/ManageCoupon'
import ManageCustomMenu from './pages/manage_custom_menu/ManageCustomMenu'
import ManageIngredients from './pages/manage_ingredients/ManageIngredients'
import ManageUsers from './pages/manage_users/ManageUsers'
import MenuManagement from './pages/f&b_menu/MenuManagement'
import NewsAndEvents from './pages/news_&_events/NewsAndEvents'
import NewsAndEventsGuestView from './pages/news_&_events/NewsAndEventsGuestView'
import NewsOnlyGuestView from './pages/news_&_events/NewsOnlyGuestView'
import PostDetailedView from './pages/news_&_events/PostDetailedView'
import RedirectPage from './pages/redirect_page/RedirectPage'
import ResetPassword from './pages/reset_password/ResetPassword'
import SetPassword from './pages/reset_password/SetPassword'
import ShiftConfig from './pages/shift_config/ShiftConfig'
import SpecialMenuGuestView from './pages/f&b_menu/SpecialMenuGuestView'
import SpecialMenuItemView from './components/f&b_menu/guest/SpecialMenuItemView'
import SurveyManagement from './pages/survey/SurveyManagement'
import SurveyReports from './pages/reports/SurveyReport'
import TimeOffRequest from './pages/time_off_request/TimeOffRequest'
import useQueryHook from './hooks/useQueryHook'
import UserRoles from './pages/user_roles/UserRoles'
import useVisibility from './hooks/UseVisibility'
import WelcomePage from './pages/welcome_page/WelcomePage'
import WelcomeScreenConfig from './pages/welcome_screen_config/WelcomeScreenConfig'
import WineHistory from './components/f&b_menu/guest/WineHistory'
import withLoader from './hoc/withLoader'
import Availability from './pages/availability/Availability'
import ViewAvailability from './pages/availability/ViewAvailability'
import GuestSurveyPublic from './pages/survey/GuestSurveyPublic'
import AvailabilityList from './pages/availability/AvailabilityList'
import { TranslationContext } from './helpers/jTranslate'
import ManageWorkSchedule from './pages/manage_work_schedule/ManageWorkSchedule'
import Profile from './pages/manage_users/Profile'
import Settings from './pages/settings/Settings'
import { defaultTimezoneInfo } from './helpers/generalSettingsHelper'
import StaffScheduleView from './pages/schedule_view_for_staff/StaffScheduleView'
import MyNotifications from './pages/notifications/MyNotifications'
import PermissionGroups from './pages/permission_groups/PermissionGroups'
import { getPermissionList, simplifyPermissions } from './helpers/permissionHelper'
import Roles from './pages/roles/Roles'
import { CHANNEL, FORCE_LOGOUT } from './constants/strings'
import NetworkAlertToast from './components/common/NetworkAlertToast'
import RoleBasedView from './pages/role_based_view/RoleBasedView'
import ShiftSwap from './pages/shift_swap/ShiftSwap'

function App() {
	const navigate = useNavigate()
	const toast = useRef<Toast>(null)
	const location = useLocation()
	const networkToastRef = useRef<any>(null)

	const isLocal = process.env.REACT_APP_IS_LOCAL_ENV
	const { changeTargetLanguage } = useContext(TranslationContext)

	// const { enqueueSnackbar } = useSnackbar()

	// useEffect(() => {
	//   if (serviceWorkerRegistration) {
	//     // listen to the onUpdate event of serviceWorkerRegistration
	//     serviceWorkerRegistration.onupdatefound = () => {
	//       const installingWorker = serviceWorkerRegistration.installing
	//       if (installingWorker) {
	//         // show a toast notification when a new service worker is installing
	//         installingWorker.onstatechange = () => {
	//           if (installingWorker.state === 'installed') {
	//             if (navigator.serviceWorker.controller) {
	//               if (toast.current) {
	//                 toast?.current?.show({
	//                   severity: 'info',
	//                   summary: 'A new version of the app is available',
	//                   detail: 'Please refresh the page to update.',
	//                   sticky: true,

	//                   // onClick: () => {
	//                   //   window.location.reload()
	//                   // },
	//                 })
	//               } else {
	//                 console.log(
	//                   'toast is null',
	//                   'A new version of the app is available'
	//                 )
	//               }
	//             } else {
	//                if (toast.current) {
	//                  toast?.current?.show({
	//                    severity: 'success',
	//                    summary: 'The app is now available offline.',
	//                  })
	//                } else {
	//                  console.log(
	//                    'toast is null',
	//                    'The app is now available offline.'
	//                  )
	//                }
	//             }
	//           }
	//         }
	//       }
	//     }
	//   }
	// }, [toast, serviceWorkerRegistration])

	// user login status query
	const queryClient = useQueryClient()
	useQuery(LOGIN_STATUS, () => setLoginStatus(false), {
		enabled: false,
		retry: 0,
		refetchOnWindowFocus: false,
		cacheTime: Infinity,
	})

	// login status value
	const loginStatus = queryClient.getQueryData(LOGIN_STATUS) as {
		isLoggedIn: boolean
	}

	// Disable screen timeout
	const [disabledScreenTimeout, setDisabledScreenTimeout] = useState(false)
	const isTabVisible = useVisibility()
	const { isSupported, request, release } = useWakeLock({
		onRequest: useCallback(() => setDisabledScreenTimeout(true), []),
		onError: useCallback((error: any) => setDisabledScreenTimeout(false), []),
		onRelease: useCallback(() => setDisabledScreenTimeout(false), []),
	})

	const handleRequest = useCallback(
		(isVisible: boolean) => {
			if (isSupported && isVisible && !disabledScreenTimeout) request()
		},
		[isSupported, request]
	)

	const handleRelease = useCallback(() => {
		if (isSupported && disabledScreenTimeout) release()
	}, [isSupported, release])

	useEffect(() => {
		handleRequest(isTabVisible)
		// return () => handleRelease();
	}, [handleRequest, handleRelease, isTabVisible])

	// track user data change in forms
	//useQuery(DATA_CHANGE, () => setPermissions({}), { retry: 0, enabled: false });

	// user permissions
	useQuery(PERMISSIONS, () => setPermissions({}), { retry: 0, enabled: false })

	// fetch permission list
	useQuery(PERMISSION_LIST, getPermissionList, {
		enabled: true,
		refetchOnWindowFocus: false,
		staleTime: Infinity,
	})

	// fetch user info
	useQueryHook(
		USER_INFO,
		async () => {
			const session = await userSession()
			return getUserInfo(queryClient, session.username)
		},
		(res) => {
			const data = res.data.data as Users

			// check if user is active
			if (!data?.isActive) {
				// create a new broadcast channel with the same name
				const channel = new BroadcastChannel(CHANNEL)
				channel.postMessage(FORCE_LOGOUT)
				channel.close()
			}

			if (data?.isAdmin) {
				savePermissions(queryClient, { isAdmin: true })
				saveLoginStatus(queryClient, true)
				showLoader(queryClient, false)
			}

			const userPermissions = getUserPermissions(data)
			if (Object.keys(userPermissions).length > 0) {
				savePermissions(queryClient, userPermissions)
				localStorage.setItem(USER_NAME, data?.preferredName)
				localStorage.setItem(STAFF_ID, data?.id)
				changeTargetLanguage(data?.preferredLangKey?.toLowerCase() ?? 'en')
				saveLoginStatus(queryClient, true)
				showLoader(queryClient, false)
			} else {
				// create a new broadcast channel with the same name
				const channel = new BroadcastChannel(CHANNEL)
				channel.postMessage(FORCE_LOGOUT)
				channel.close()
			}
		}
	)

	// tenant info fetch success
	const onTenantInfoSuccess = (res: AxiosResponse<any, any>) => {
		let responseData = res.data?.data as BrandingResponseData
		localStorage.setItem(TENANT_ID_HEADER, responseData.id)
		localStorage.setItem(ORG_ID_HEADER, responseData.organization.id)
		localStorage.setItem(TENANT_NAME, responseData.branding.displayName)
		localStorage.setItem(SPECIAL_EFFECTS_NAME, JSON.stringify(responseData?.branding?.celebrationEffects || {}))

		const brandingInfo = responseData?.branding
		queryClient.setQueryData(BRANDING_INFO, brandingInfo)

		const brandingData = responseData.branding
		const timezoneInfo: TimezoneInfo = {
			dayLightSavingOffSetInMinutes: defaultTimezoneInfo.dayLightSavingOffSetInMinutes,
			defaultDateFormat: brandingData?.defaultDateFormat ?? defaultTimezoneInfo.defaultDateFormat,
			defaultTimeFormat: brandingData?.defaultTimeFormat ?? defaultTimezoneInfo.defaultTimeFormat,
			defaultTimezone: brandingData?.defaultTimezone ?? defaultTimezoneInfo.defaultTimezone,
			isDayLightSaving: defaultTimezoneInfo.isDayLightSaving,
			weekStartsOn: brandingData?.weekStartsOn ?? defaultTimezoneInfo.weekStartsOn,
		}
		localStorage.setItem(TIMEZONE_INFO, JSON.stringify(timezoneInfo))

		saveLoginStatus(queryClient, localStorage.getItem(USER_NAME) !== null)
	}

	// fetch tenant info
	const tenantInfo = useQueryHook(
		TENANT_INFO,
		getTenantInfo,
		onTenantInfoSuccess
		// (error) => console.log('tenant info error', error),
		// true,
		// true
	)

	if (tenantInfo.isLoading) {
		return <Loader />
	}

	document.body.classList.add('user-style')

	return (
		<div
			className={isPathPublic(location.pathname) ? 'container-fluid global-text-selection' : 'container-fluid'}
			onContextMenu={(event) => (isMobile() && isPathPublic(location.pathname) ? event.preventDefault() : true)}
		>
			<Toast ref={toast} />
			{/* Toast to show the network connectivity status */}
			<NetworkAlertToast networkToastRef={networkToastRef} />
			<div className="row">
				<ErrorBoundary>
					<ContextAPI>
						<GoogleReCaptchaProvider reCaptchaKey={getGoogleRecaptchaKey()}>
							<Routes>
								<>
									{loginStatus?.isLoggedIn ? (
										<>
											<Route path={routes.dashboard} element={<Dashboard />} />

											{checkPermission(queryClient, PermissionKeys.MANAGE_USERS) && (
												<Route path={routes.manage_users} element={<ManageUsers />} />
											)}

											{checkPermission(queryClient, PermissionKeys.MANAGE_TIME_OFF, SubPermissionKeys.MANAGE_REQUEST) && (
												<Route path={routes.time_off_requests} element={<TimeOffRequest />} />
											)}

											{checkPermission(queryClient, PermissionKeys.MANAGE_TIME_OFF) && (
												<Route path={routes.time_off} element={<TimeOffRequest />} />
											)}

											{(checkPermission(queryClient, PermissionKeys.MANAGE_ADDONS) ||
												checkPermission(queryClient, PermissionKeys.MANAGE_ADDON_GROUPS)) && (
													<Route
														path={`${routes.manage_addons}/:currentTabIndex?`}
														element={<ManageAddons />}
													/>
												)}

											{checkPermission(queryClient, PermissionKeys.MANAGE_INGREDIENTS) && (
												<Route
													path={routes.manage_ingredients}
													element={<ManageIngredients />}
												/>
											)}

											{checkPermission(queryClient, PermissionKeys.MANAGE_CUSTOM_FNB_MENU) && (
												<Route path={routes.custom_menu_list} element={<ManageCustomMenu />} />
											)}

											{checkPermission(queryClient, PermissionKeys.MANAGE_CUSTOM_FNB_MENU) && (
												<Route
													path={`${routes.custom_menu_list}/:customMenuId`}
													element={<CustomMenuManagement />}
												/>
											)}

											{checkPermission(queryClient, PermissionKeys.MANAGE_CUSTOM_FNB_MENU) && (
												<Route
													path={`${routes.custom_menu_list}/create`}
													element={<CustomMenuManagement />}
												/>
											)}

											{checkPermission(queryClient, PermissionKeys.MANAGE_CUSTOM_FNB_MENU) && (
												<Route
													path={`${routes.custom_menu_list}/:customMenuId/:itemCategoryId`}
													element={<AddExistingItems />}
												/>
											)}

											{checkPermission(queryClient, PermissionKeys.MANAGE_ROLES) && (
												<Route path={routes.user_roles} element={<UserRoles />} />
											)}

											{checkPermission(queryClient, PermissionKeys.MANAGE_MENU) && (
												<Route path={routes.menu} element={<MenuManagement />} />
											)}

											{checkPermission(queryClient, PermissionKeys.MANAGE_SURVEY) && (
												<>
													<Route path={routes.survey} element={<SurveyManagement />} />

													<Route
														path={routes.survey_create}
														element={<CreateQuestionSet />}
													/>
												</>
											)}

											{checkPermission(queryClient, PermissionKeys.MANAGE_GUEST_INFO) && (
												<Route path={routes.guest_info} element={<GuestInfoList />} />
											)}

											{checkPermission(queryClient, PermissionKeys.MANAGE_NEWS_AND_EVENTS) && (
												<Route
													path={routes.news_and_events_management}
													element={<NewsAndEvents />}
												/>
											)}

											{checkPermission(queryClient, PermissionKeys.MANAGE_REPORTS) && (
												<Route path={routes.survey_report} element={<SurveyReports />} />
											)}

											{checkPermission(queryClient, PermissionKeys.MANAGE_GAME) && (
												<Route path={routes.game_management} element={<GameManagement />} />
											)}

											{checkPermission(queryClient, PermissionKeys.MANAGE_FILES) && (
												<Route path={routes.file_management} element={<FileManagement />} />
											)}

											{checkPermission(queryClient, PermissionKeys.MANAGE_WELCOME_SCREEN) && (
												<Route
													path={routes.welcome_screen_config}
													element={<WelcomeScreenConfig />}
												/>
											)}

											{checkPermission(queryClient, PermissionKeys.MANAGE_FILES) && (
												<Route
													path={routes.file_type_verification}
													element={<FileTypeVerification />}
												/>
											)}

											<Route path={routes.holidays} element={<HolidayConfig />} />

											{checkPermission(queryClient, PermissionKeys.MANAGE_AVAILABILITY) && (
												<Route path={routes.availability} element={<Availability />} />
											)}

											{checkPermission(
												queryClient,
												PermissionKeys.MANAGE_AVAILABILITY,
												SubPermissionKeys.MANAGE_REQUEST
											) && (
													<Route path={routes.availability_list} element={<AvailabilityList />} />
												)}

											{checkPermission(
												queryClient,
												PermissionKeys.MANAGE_AVAILABILITY,
												SubPermissionKeys.VIEW_AVAILABILITY
											) && (
													<Route path={routes.view_availability} element={<ViewAvailability />} />
												)}

											{checkPermission(queryClient, PermissionKeys.MANAGE_BRANDING) && (
												<Route path={routes.branding_config} element={<BrandingConfig />} />
											)}

											{checkPermission(queryClient, PermissionKeys.MANAGE_SHIFT) && (
												<Route path={routes.shift_config} element={<ShiftConfig />} />
											)}

											{checkPermission(queryClient, PermissionKeys.MANAGE_ROLES) && (
												<Route path={routes.roles} element={<Roles />} />
											)}

											{checkPermission(queryClient, PermissionKeys.MANAGE_WORK_TIME_SCHEDULE) && (
												<Route path={routes.manage_work_schedule} element={<ManageWorkSchedule />} />
											)}

											{checkPermission(queryClient, PermissionKeys.MANAGE_PERMISSIONS) && (
												<Route path={routes.permission_groups} element={<PermissionGroups />} />
											)}

											{(checkPermission(queryClient, PermissionKeys.MANAGE_BRANDING) ||
												checkPermission(queryClient, PermissionKeys.MANAGE_WELCOME_SCREEN) ||
												checkPermission(queryClient, PermissionKeys.MANAGE_ABOUT_US) ||
												checkPermission(
													queryClient,
													PermissionKeys.MANAGE_GENERAL_SETTINGS
												)) && <Route path={routes.settings} element={<Settings />} />}

											<Route path={routes.staff_schedule} element={<StaffScheduleView />} />
											<Route path={routes.profile} element={<Profile />} />

											<Route path={routes.my_notifications} element={<MyNotifications />} />
											<Route path={routes.print_preview} element={<RoleBasedView />} />

											<Route path={routes.shift_swap} element={<ShiftSwap />} />
											<Route path={routes.swap_requests} element={<ShiftSwap />} />
										</>
									) : (
										<>
											<Route path={routes.login} element={<Login />} />

											<Route path={routes.forgot} element={<ForgotPassword />} />

											<Route path={routes.reset} element={<ResetPassword />} />
										</>
									)}

									<Route path={routes.welcome_page} element={<WelcomePage />} />

									<Route path={routes.menu_view} element={<GuestView />} />

									<Route path={`${routes.menu_view}/:menuSlug`} element={<GuestView />} />

									<Route
										path={`${routes.menu_view}/:menuSlug/:categorySlug`}
										element={<GuestView />}
									/>

									<Route
										path={`${routes.special_menu}/:menuCode`}
										element={<SpecialMenuGuestView />}
									/>

									<Route path={routes.menu_item_view} element={<ItemView />} />

									<Route
										path={`${routes.menu_view}/:menuSlug/:categorySlug/:itemSlug`}
										element={<ItemView />}
									/>

									<Route
										path={`${routes.item_view_special_menu}/:itemId`}
										element={<SpecialMenuItemView />}
									/>

									<Route
										path={`${routes.menu_item_view}/:itemId/:ingredientId`}
										element={<IngredientsView />}
									/>
									<Route
										path={`${routes.menu_view}/:menuSlug/:categorySlug/:itemSlug/:ingredientSlug`}
										element={<IngredientsView />}
									/>

									<Route path={routes.news_and_events} element={<NewsAndEventsGuestView />} />

									<Route path={routes.news_only} element={<NewsOnlyGuestView />} />

									<Route path={`${routes.news_in_detail}/:urlSlug`} element={<PostDetailedView />} />

									<Route
										path={`${routes.events_in_detail}/:urlSlug`}
										element={<PostDetailedView />}
									/>

									<Route path={routes.events_only} element={<EventsOnlyGuestView />} />

									<Route path={routes.wine_history} element={<WineHistory />} />

									<Route
										path={`${routes.menu_view}/:menuSlug/:categorySlug/:itemSlug/history`}
										element={<WineHistory />}
									/>

									<Route path={routes.survey_guest} element={<GuestSurvey />} />

									<Route path={`${routes.survey_guest}/:urlSlug`} element={<GuestSurveyPublic />} />

									<Route path={routes.manage_coupon} element={<ManageCoupon />} />

									<Route path={routes.guest_game} element={<GuestSpinWheel />} />

									<Route path={routes.set_password} element={<SetPassword />} />

									<Route path={routes.redirect_page} element={<RedirectPage />} />

									<Route path={routes.magic_link_page} element={<MagicLinkPage />} />

									<Route path={routes.ui_tests} element={<AdminLayoutPage />} />

									<Route path={routes.about_us} element={<WelcomePage />} />

									<Route
										path="*"
										element={
											<Navigate
												to={
													loginStatus?.isLoggedIn
														? getRootRoute(loginStatus?.isLoggedIn, queryClient)
														: routes.welcome_page
												}
											/>
										}
									/>
								</>
							</Routes>
						</GoogleReCaptchaProvider>
						{/* <ReactQueryDevtools /> */}
					</ContextAPI>
				</ErrorBoundary>
			</div>
		</div>
	)
}

export default withLoader(App)
