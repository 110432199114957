import { useContext } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { PageMode } from '../../constants/constants'
import { ToastMessageProps } from '../../constants/staticTypes'
import { REGULAR_AVAILABILITY, SET_AVAILABILITY, TEMP_AVAILABILITY } from '../../constants/strings'
import { CommonCtx } from '../../context/CommonCtxProvider'
import AvailabilityForm from './AvailabilityForm'
import { JTranslation } from '../../helpers/jTranslate'

type Props = {
	pageMode: PageMode.ADD | PageMode.EDIT
	setShowSideBar: React.Dispatch<React.SetStateAction<boolean>>
	setToastMessage: React.Dispatch<React.SetStateAction<ToastMessageProps>>
	setMode: React.Dispatch<React.SetStateAction<PageMode>>
	availabilityType: 'REGULAR' | 'TEMPORARY'
}

function AvailabilitySideBar({ pageMode, setMode, setShowSideBar, setToastMessage, availabilityType }: Props) {
	// CONTEXT VARIABLE
	const { showSideBar } = useContext(CommonCtx)
	const onClose = () => {
		setMode(PageMode.ADD)
		setShowSideBar(false)
	}

	return (
		<Offcanvas
			show={showSideBar}
			onHide={() => onClose()}
			backdrop="static"
			// responsive="xl"
			placement="end"
			className="custom-offcanvas"
		>
			<Offcanvas.Header closeButton>
				<Offcanvas.Title className=''>
					{availabilityType === 'REGULAR' 
						? <JTranslation typeCase="pascal" text={REGULAR_AVAILABILITY} /> 
						: <JTranslation typeCase="pascal" text={TEMP_AVAILABILITY} />
					}
				</Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body>
				<div className="row">
					<div className=" col-md-12 col-lg-12 mb-3 ">
						<AvailabilityForm availabilityType={availabilityType} onClose={onClose} pageMode={pageMode} setToastMessage={setToastMessage} />
					</div>
				</div>
			</Offcanvas.Body>
		</Offcanvas>
	)
}

export default AvailabilitySideBar
