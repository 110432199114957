import { useState, useEffect, useCallback, useContext } from 'react'
import { BlockUI } from 'primereact/blockui';
import { capitalizeFirstLetter } from '../../../helpers/utils';
import { DAILY, FROM, TO, CLEAR } from '../../../constants/strings'
import { initialScheduleValue, weekDayOrder, validateTimeRanges } from '../../../helpers/menuViewHelper'
import { MenuSchedule, ScheduleMenuId } from '../../../constants/staticTypes'
import { ScheduleTypeValues } from '../../../constants/constants';
import { TimePicker } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs';
import { JTranslation, TranslationContext, jTranslationText } from '../../../helpers/jTranslate';

type Props = {
    currentSchedule: MenuSchedule
    isDaily: boolean
    scheduleMenuInfo: ScheduleMenuId
    setCurrentSchedule: React.Dispatch<React.SetStateAction<MenuSchedule>>
    setDisableSave: React.Dispatch<React.SetStateAction<boolean>>
    setIsDaily: React.Dispatch<React.SetStateAction<boolean>>
}

const MenuScheduleComponent = ({ currentSchedule, isDaily, setIsDaily, setCurrentSchedule, setDisableSave }: Props) => {
    const [periodicSchedules, setPeriodicSchedules] = useState(currentSchedule.periodicSchedules);
    const [errorMessages, setErrorMessages] = useState<string[]>([])
    const [translationText, setTranslatedText] = useState<{
        startTime: string,
        endTime: string,
    }>({
        startTime: "Start Time",
        endTime: "End Time"
    });

    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext

    const enableAndDisableSaveButton = useCallback(() => { // nosonar
        let mismatchFound = false;
        let isInactiveCount = 0

        for (const day of weekDayOrder) {
            const isActive = currentSchedule.periodicSchedules[day].isActive
            const selectedHours = currentSchedule.periodicSchedules[day].selectedHours[0];

            if (!isActive && !isDaily) {
                isInactiveCount = isInactiveCount + 1
            }

            if (isInactiveCount === 7) {
                mismatchFound = true;
                break;
            }

            if (isActive && selectedHours && Object.keys(selectedHours).length) {
                const { startTime, endTime } = selectedHours;

                if ((startTime && !endTime) || (!startTime && endTime) || (!startTime && !endTime)) {
                    mismatchFound = true;
                    break;
                }
            }
        }
        setDisableSave(mismatchFound);
    }, [currentSchedule.periodicSchedules, isDaily, setDisableSave])

    const onStartChange = (time: Dayjs, day: string) => {
        if (time) {
            const updatedPeriodicSchedules = { ...periodicSchedules };
            updatedPeriodicSchedules[day].selectedHours[0].startTime = time.format('h:mm A');
            setPeriodicSchedules(updatedPeriodicSchedules);
            setCurrentSchedule({
                ...currentSchedule,
                scheduleType: ScheduleTypeValues.PERIODIC,
                periodicSchedules: updatedPeriodicSchedules,
            })
        } else {
            const updatedPeriodicSchedules = { ...periodicSchedules };
            updatedPeriodicSchedules[day].selectedHours[0].startTime = null;
            setPeriodicSchedules(updatedPeriodicSchedules);
            setCurrentSchedule({
                ...currentSchedule,
                scheduleType: ScheduleTypeValues.PERIODIC,
                periodicSchedules: updatedPeriodicSchedules,
            })
        }
    };

    const onEndChange = (time: Dayjs | null, day: string) => {
        if (time) {
            const updatedPeriodicSchedules = { ...periodicSchedules };
            updatedPeriodicSchedules[day].selectedHours[0].endTime = time.format('h:mm A');
            setPeriodicSchedules(updatedPeriodicSchedules);
            setCurrentSchedule({
                ...currentSchedule,
                scheduleType: ScheduleTypeValues.PERIODIC,
                periodicSchedules: updatedPeriodicSchedules,
            });
        } else {
            const updatedPeriodicSchedules = { ...periodicSchedules };
            updatedPeriodicSchedules[day].selectedHours[0].endTime = null;
            setPeriodicSchedules(updatedPeriodicSchedules);
            setCurrentSchedule({
                ...currentSchedule,
                scheduleType: ScheduleTypeValues.PERIODIC,
                periodicSchedules: updatedPeriodicSchedules,
            })
        }
    };

    const getFormattedTime = (day: string, from: "startTime" | "endTime") => {
        if (from === "startTime" && periodicSchedules[day]?.selectedHours[0]?.startTime) {
            return dayjs(periodicSchedules[day]?.selectedHours[0]?.startTime, 'h:mm A')
        } else if (from === "endTime" && periodicSchedules[day]?.selectedHours[0]?.endTime) {
            return dayjs(periodicSchedules[day]?.selectedHours[0]?.endTime, 'h:mm A')
        } else {
            return null
        }
    }

    const getWeekDaySwitchState = (day: string) => {
        return periodicSchedules[day].isActive
    }

    const enableAndDisableWeekDaySwitch = (day: string) => {
        periodicSchedules[day].isActive = !periodicSchedules[day].isActive
        setCurrentSchedule({
            ...currentSchedule,
            periodicSchedules: periodicSchedules,
        })
    }

    const clearSchedules = () => {
        setCurrentSchedule({
            ...currentSchedule,
            periodicSchedules: {
                monday: {
                    isActive: false,
                    selectedHours: [{ startTime: null, endTime: null }],
                },
                tuesday: {
                    isActive: false,
                    selectedHours: [{ startTime: null, endTime: null }],
                },
                wednesday: {
                    isActive: false,
                    selectedHours: [{ startTime: null, endTime: null }],
                },
                thursday: {
                    isActive: false,
                    selectedHours: [{ startTime: null, endTime: null }],
                },
                friday: {
                    isActive: false,
                    selectedHours: [{ startTime: null, endTime: null }],
                },
                saturday: {
                    isActive: false,
                    selectedHours: [{ startTime: null, endTime: null }],
                },
                sunday: {
                    isActive: false,
                    selectedHours: [{ startTime: null, endTime: null }],
                },
            }
        })
    }

    useEffect(() => {
        let defaultCheckBox = currentSchedule.scheduleType === 'always' || currentSchedule.scheduleType === ''
        setIsDaily(defaultCheckBox)
        // clean-up
        return () => {
            setIsDaily(false)
        }
    }, [currentSchedule.scheduleType]) // eslint-disable-line

    useEffect(() => {
        setPeriodicSchedules(currentSchedule.periodicSchedules)
        // clean-up
        return () => {
            setPeriodicSchedules(initialScheduleValue.periodicSchedules)
        }
    }, [currentSchedule])

    // effect to disable and enable the save button based on start and end time 
    useEffect(() => {
        enableAndDisableSaveButton()
        const errors = validateTimeRanges(currentSchedule.periodicSchedules)
        setErrorMessages(errors)
        // disable save button if any error are found on the schedule time range
        if (errors.length) {
            setDisableSave(true)
        }

    }, [currentSchedule, enableAndDisableSaveButton, setDisableSave])


    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: "Start Time", typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: "End Time", typeCase: 'pascal', translationContext }),
                ])
                setTranslatedText({
                    startTime: translations[0] ?? "Start Time",
                    endTime: translations[1] ?? "End Time",
                })
            } catch {
                setTranslatedText({
                    startTime: "Start Time",
                    endTime: "End Time",
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    return (
        <>
            <div className="d-flex mb-3">
                <div className='label-container d-flex flex-grow-1 p-2 rounded align-items-center justify-content-between'>
                    <h6 className='mb-0'><JTranslation typeCase="pascal" text={DAILY} /></h6>
                    <span className="form-check form-switch">
                        <input
                            className="form-check-input form-check-input-custom"
                            type="checkbox"
                            checked={isDaily}
                            onChange={(event) => {
                                const isChecked = event.target.checked
                                setIsDaily(isChecked)
                                clearSchedules()
                            }}
                        />
                    </span>
                </div>

            </div>
            <div className='col-12'>
                <BlockUI className='p-2' blocked={isDaily} template={<i className="pi pi-lock" style={{ 'fontSize': '3rem' }} />}>
                    {weekDayOrder.map((day) => {
                        return (
                            <div key={uuidv4()} className='mb-3 d-flex align-items-center'>
                                <span className="form-check form-switch">
                                    <input
                                        className="form-check-input form-check-input-custom"
                                        type="checkbox"
                                        onChange={() => enableAndDisableWeekDaySwitch(day)}
                                        checked={getWeekDaySwitchState(day)}
                                    />
                                </span>

                                <span className='col-3'>{capitalizeFirstLetter(day)}</span>
                                <TimePicker
                                    popupClassName="custom-antd-time-picker"
                                    id={FROM}
                                    size="small"
                                    use12Hours
                                    format="h:mm a"
                                    value={getFormattedTime(day, "startTime")}
                                    defaultValue={dayjs().set('hour', 0).set('minute', 0)}
                                    // @ts-ignore
                                    onChange={(time) => onStartChange(time, day)}
                                    minuteStep={15}
                                    placeholder={translationText.startTime}
                                    disabled={!getWeekDaySwitchState(day)}
                                    inputReadOnly={true}
                                />
                                <span className='mr-2 px-2'> - </span>
                                <TimePicker
                                    popupClassName="custom-antd-time-picker"
                                    id={TO}
                                    size="small"
                                    use12Hours
                                    format="h:mm a"
                                    value={getFormattedTime(day, "endTime")}
                                    // @ts-ignore
                                    onChange={(time) => onEndChange(time, day)}
                                    minuteStep={15}
                                    placeholder={translationText.endTime}
                                    disabled={!getWeekDaySwitchState(day)}
                                    inputReadOnly={true}
                                />
                            </div>
                        )
                    })}

                    <div className='col-12 mt-3'>
                        <button
                            className="btn btn-sm btn-custom-primary-outline"
                            type="button"
                            onClick={() => {
                                clearSchedules()
                                setIsDaily(true)
                            }}><i className="ri-refresh-line"></i> <JTranslation typeCase="pascal" text={CLEAR} /></button>
                    </div>

                    <div className='col-12 mt-3'>

                    </div>
                    {errorMessages && errorMessages?.length > 0 ? (<div className="alert alert-danger custom-alert" role="alert">
                        {errorMessages.map((error) => (<p className='mb-1' key={uuidv4()}><JTranslation typeCase="capitalize" text={error} /></p>))}
                    </div>) : ""}

                </BlockUI>
            </div>
        </>
    )
}

export default MenuScheduleComponent
