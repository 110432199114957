import { ReactNode, useRef, useState } from "react";
import DatePicker, { ReactDatePicker } from "react-datepicker";
import 'react-datepicker/src/stylesheets/datepicker.scss';

type Props = {
    disabled?: boolean,
    isClearable?: boolean,
    disableYear?: boolean,
    selectedDate: Date | null,
    numberOfUpcomingYears?: number,
    maxDate?: Date | null,
    minDate?: Date | null,
    setDate: React.Dispatch<React.SetStateAction<Date | null>>,
    customInput: ReactNode
}

function DatePickerComponent({ selectedDate, setDate, customInput, disabled, maxDate = new Date(), minDate = null, isClearable, numberOfUpcomingYears, disableYear = false }: Props) {
    const [inputElement, setInputElement] = useState<HTMLInputElement | null>(null)
    const currentYear = new Date().getFullYear()
    const getYears = () => {
        let max = new Date().getFullYear()
        let min = 1900;
        let yearsArray = []
        for (let i = max; i >= min; i--) {
            yearsArray.push(i)
        }
        return yearsArray;
    }
    const getFutureYears = () => {
        let max = new Date(
            numberOfUpcomingYears ? new Date().setFullYear(new Date().getFullYear() + numberOfUpcomingYears) : new Date().setFullYear(new Date().getFullYear() + 5)
        ).getFullYear()
        let min = currentYear
        let yearsArray = []
        for (let i = max; i >= min; i--) {
            yearsArray.push(i)
        }
        return yearsArray
    }

    function calculateYears(maxDate: Date | null, currentYear: number): ReturnType<typeof getYears | typeof getFutureYears> {
        // Check if maxDate is available
        if (maxDate) {
            // Check if the year from maxDate is greater than the current year
            if (maxDate.getFullYear() > currentYear) {
                return getFutureYears(); // Call the function for future years if the condition is true
            }
        }

        return getYears(); // Default to calling the function for current years
    }

    // Usage example with appropriate values for maxDate and currentYear
    const years = calculateYears(maxDate, currentYear);


    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    // on date typed
    const handleDateChangeRaw = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        let value = e.target.value;
        if (value !== undefined) {
            let dateValueRegex = /^[0-9/]+$/g;
            let matchList = value.match(dateValueRegex);
            if ((matchList !== null &&
                matchList.length > 0 &&
                matchList[0] === value &&
                value !== "/" &&
                !value.includes("//")) ||
                value.length === 0) {
                return;
            }
            e.preventDefault();
        }
    }

    return (
        <DatePicker
            className="form-control"
            isClearable={isClearable}
            disabled={disabled}
            placeholderText="mm/dd/yyyy"
            renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
            }) => (
                <div
                    style={{
                        margin: 10,
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                        {"<"}
                    </button>
                    <select
                        disabled={disableYear}
                        value={date.getFullYear()}
                        onChange={({ target: { value } }) => changeYear(parseInt(value))}
                    >
                        {years.map((option: number) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>

                    <select
                        value={months[date.getMonth()]}
                        onChange={({ target: { value } }) =>
                            changeMonth(months.indexOf(value))
                        }
                    >
                        {months.map((month) => (
                            <option key={month} value={month}>
                                {month}
                            </option>
                        ))}
                    </select>

                    <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                        {">"}
                    </button>
                </div>
            )}
            dateFormat="MM/dd/yyyy"
            selected={selectedDate}
            onChange={(date: Date) => {
                setDate(date);
            }}
            minDate={minDate}
            maxDate={maxDate}
            onChangeRaw={handleDateChangeRaw}
            customInput={customInput}
            openToDate={selectedDate !== null ? selectedDate : new Date()}
            onFocus={(event) => {
                // hide native keyboard on focus
                setInputElement(event.target);
                event.target.readOnly = true;
            }}
            onBlur={(event) => {
                setInputElement(event.target);
                event.target.readOnly = false;
            }}
            onCalendarClose={() => {
                if(inputElement) {
                    inputElement.blur();
                    inputElement.readOnly = false;
                    setInputElement(null);
                }
            }}
        />
    );
}

export default DatePickerComponent;