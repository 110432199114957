import { useState, useContext, useEffect } from 'react'
import { checkPermission, convertUTCtoLocalByOffset, capitalizeFirstLetter, toPascalCase } from '../../helpers/utils'
import { Column } from 'primereact/column'
import { CommonCtx } from '../../context/CommonCtxProvider'
import { DataTable } from 'primereact/datatable'
import { deleteNewsAndEvents, postTypeTemplate, changeNewsAndEventStatus } from '../../helpers/newsAndEventsHelper'
import { FaEdit, FaSearch, FaEye } from 'react-icons/fa'
import { NEWS_AND_EVENTS_LIST } from '../../constants/queryKeys'
import { routes } from '../../constants/routes'
import { useQueryClient } from 'react-query'
import AddNewsAndEventsSideBar from './AddNewsAndEventsSideBar'
import ToastAlert from '../alert/ToastAlert'
import useMutationHook from '../../hooks/useMutationHook'
import WarningModal from '../warning_modal/WarningModal'
import { NewsAndEventsId, NewsAndEventsListType, NewsAndEventsSwitchStatuses, NewsAndEventStatusType, PostType, ToastMessageProps } from '../../constants/staticTypes'
import { AlertVariant, MOBILE_VIEW_BREAKPOINT, PageMode, PermissionKeys, SubPermissionKeys, toastMessageInitialData } from '../../constants/constants'
import { ADD_NEWS_AND_EVENTS, DELETE_WARNING, DELETE, EDIT, NEWS_AND_EVENTS, PUBLISH_DATE, STATUS, TITLE, TYPE, VIEW } from '../../constants/strings'
import { JTranslation, TranslationContext, jTranslationText } from '../../helpers/jTranslate'
import { DebounceInput } from 'react-debounce-input'
import { useWindowSize } from 'react-use'
import { Card } from 'primereact/card'
import { DataView } from 'primereact/dataview'
import MlxTooltip from '../common/MlxTooltip'

type Props = {
    loading: boolean
    onSearchTrigger: (searchKey: string) => void
    posts: NewsAndEventsListType[]
}

function NewsAndEventsListGrid({ posts, loading, onSearchTrigger }: Props) {
    const queryClient = useQueryClient()
    const newsAndEventsMutation = useMutationHook(queryClient, true)
    // CONTEXT VARIABLE
    const { setShowSideBar } = useContext(CommonCtx)
    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext
    // STATE VARIABLE
    const [pageMode, setPageMode] = useState<PageMode.ADD | PageMode.EDIT>(PageMode.ADD)
    const [postType, setPostType] = useState<PostType.NEWS | PostType.EVENT>(PostType.NEWS)
    const [selectedPostId, setSelectedPostId] = useState<NewsAndEventsId>({ postId: '' })
    const [showWarning, setWarning] = useState(false)
    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
    const [translationText, setTranslatedText] = useState<{
        edit: string,
        delete: string,
        search: string,
        paginationTemplate: string,
        view: string,
        emptyMessage: string,
      }>({ 
        edit: EDIT,
        delete: DELETE,
        search: 'Search',
        paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
        view: VIEW,
        emptyMessage: "No Posts Found."
    });
    const [showTitle, setShowTitle] = useState(true)
    const { width, height } = useWindowSize()
	const breakPoint = MOBILE_VIEW_BREAKPOINT

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: EDIT, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: DELETE, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: 'Search', typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: 'Showing 1 to 2 of 3 items', typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: VIEW, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: "No Posts Found.", typeCase: 'pascal', translationContext }),
                ])
                
                setTranslatedText({
                    edit: translations[0] ?? EDIT,
                    delete: translations[1] ?? DELETE,
                    search: translations[2] ?? 'Search',
                    paginationTemplate:
                        translations[3]
                            ?.replace('1', '{first}')
                            ?.replace('2', '{last}')
                            ?.replace('3', '{totalRecords}') ?? 'Showing {first} to {last} of {totalRecords} items',
                    view: translations[4] ?? VIEW,
                    emptyMessage: translations[5] ?? "No Posts Found.",
                })
            } catch {
                setTranslatedText({
                    edit: EDIT,
                    delete: DELETE,
                    search: 'Search',
                    paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
                    view: VIEW,
                    emptyMessage: "No Posts Found.",
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])    

    const onSuccess = (message: string) => {
        setToastMessage({
            message: message,
            show: true,
            variant: AlertVariant.SUCCESS,
        })
        // list api recall
        queryClient.refetchQueries([NEWS_AND_EVENTS_LIST]) // nosonar
    }
    const onError = (message: string) => {
        setToastMessage({
            message: message,
            show: true,
            variant: AlertVariant.ERROR,
        })
    }

    const deleteNewsAndEventsApiCall = () => {
        deleteNewsAndEvents(newsAndEventsMutation, selectedPostId, onSuccess, onError)
    }

    const activeInactiveTemplate = (post: NewsAndEventsListType) => {
        const { publishedStatus } = post
        let switchState: NewsAndEventsSwitchStatuses
        let isChecked: boolean

        switch (publishedStatus) {
            case 'CREATED':
                switchState = NewsAndEventsSwitchStatuses.INACTIVE
                isChecked = false
                break

            case 'PUBLISHED':
                switchState = NewsAndEventsSwitchStatuses.ACTIVE
                isChecked = true
                break

            default:
                switchState = NewsAndEventsSwitchStatuses.HIDDEN
                isChecked = false
                break
        }

        return (
                <div className={`d-flex ${width <= breakPoint ? 'align-items-center' : ''}`}>
                    {width <= breakPoint && <div className="flex-grow-1 me-2">{capitalizeFirstLetter(publishedStatus)}</div>}
                    {
                        <div className={`flex-grow-0 ${switchState === NewsAndEventsSwitchStatuses.ACTIVE ||
                            switchState === NewsAndEventsSwitchStatuses.INACTIVE? '' : 'd-none'}`}>
                            <span className="form-check form-switch">
                                {(switchState === NewsAndEventsSwitchStatuses.ACTIVE ||
                                    switchState === NewsAndEventsSwitchStatuses.INACTIVE) && (
                                        <input
                                            className="form-check-input form-check-input-custom"
                                            type="checkbox"
                                            autoComplete="off"
                                            checked={isChecked}
                                            data-testid={`published-btn-${post.id}`}
                                            disabled={
                                                !checkPermission(
                                                    queryClient,
                                                    PermissionKeys.MANAGE_NEWS_AND_EVENTS,
                                                    SubPermissionKeys.STATUS
                                                )
                                            }
                                            onChange={() => {
                                                if (
                                                    checkPermission(
                                                        queryClient,
                                                        PermissionKeys.MANAGE_NEWS_AND_EVENTS,
                                                        SubPermissionKeys.STATUS
                                                    )
                                                ) {
                                                    // API CALL TO CHANGE STATUS
                                                    const params: NewsAndEventStatusType = {
                                                        postId: post.id,
                                                        postStatus: !isChecked ? 'publish' : 'un_publish',
                                                    }
                                                    changeNewsAndEventStatus(
                                                        newsAndEventsMutation,
                                                        params,
                                                        onSuccess,
                                                        onError
                                                    )
                                                }
                                            }}
                                            id="flexSwitchCheckDefault"
                                            style={width <= breakPoint ? { width: '3em', height: '1.5em' } : { width: '2.25em', height: '1em' }}
                                        />
                                    )}
                            </span>
                        </div>
                    }
                    {width > breakPoint && <div className="flex-grow-1">{capitalizeFirstLetter(publishedStatus)}</div>}
                </div>
        )
    }

    const deleteButtonTemplate = (post: NewsAndEventsListType) => {
        const { id } = post

        return (
            <>
                {
                    <MlxTooltip target='.delete'>
                        <i
                            className={
                                'ri-delete-bin-line delete ' +
                                (!checkPermission(
                                    queryClient,
                                    PermissionKeys.MANAGE_NEWS_AND_EVENTS,
                                    SubPermissionKeys.DELETE
                                ) && ' disabled') + (width <= breakPoint ? ' rounded-background shadow-sm ms-2' : '')
                            }
                            data-pr-tooltip={translationText.delete}
                            data-pr-position='bottom'
                            data-testid={`delete-post#-${post.id}`}
                            onClick={(e) => {
                                e.stopPropagation()
                                if (
                                    checkPermission(
                                        queryClient,
                                        PermissionKeys.MANAGE_NEWS_AND_EVENTS,
                                        SubPermissionKeys.DELETE
                                    )
                                ) {
                                    // open delete pop-up
                                    setWarning(true)
                                    setSelectedPostId({ postId: id })
                                }
                            }}
                        ></i>
                    </MlxTooltip>
                }
            </>
        )
    }

    const editButtonTemplate = (post: NewsAndEventsListType) => {
        const { id, postType } = post

        const getPostType = () => {
            switch (postType) {
                case PostType.EVENT:
                    return PostType.EVENT
                case PostType.NEWS:
                    return PostType.NEWS
                default:
                    return PostType.NEWS
            }
        }

        return (
            <>
                {
                    <span className={`${width <= breakPoint ? 'rounded-background shadow-sm ms-2' : ''}`}>
                        <MlxTooltip target='.edit'>
                            <FaEdit
                                className={`edit ${!checkPermission(
                                    queryClient,
                                    PermissionKeys.MANAGE_NEWS_AND_EVENTS,
                                    SubPermissionKeys.EDIT
                                ) && ' disabled'
                                    }`}
                                data-pr-tooltip={translationText.edit}
                                data-pr-position='bottom'
                                data-testid={`edit-post#-${post.id}`}
                                onClick={(e) => {
                                    e.preventDefault()
                                    if (
                                        checkPermission(
                                            queryClient,
                                            PermissionKeys.MANAGE_NEWS_AND_EVENTS,
                                            SubPermissionKeys.EDIT
                                        )
                                    ) {
                                        setPageMode(PageMode.EDIT)
                                        setSelectedPostId({ postId: id })
                                        setPostType(getPostType)
                                        setShowSideBar(true)
                                    }
                                }}
                            />
                        </MlxTooltip>
                    </span>
                }
            </>
        )
    }

    const viewButtonTemplate = (post: NewsAndEventsListType) => {
        const { urlSlug, postType } = post

        const getRouteForSlug = () => {
            if (postType === PostType.NEWS) {
                return routes.news_in_detail
            } else {
                return routes.events_in_detail
            }
        }

        const URL = `${getRouteForSlug()}/${urlSlug}`

        return (
            <>
                {
                    <span className={`${width <= breakPoint ? 'rounded-background shadow-sm ms-2' : ''}`}>
                        <MlxTooltip target='.view'>
                            <FaEye
                                className={`view ${!checkPermission(queryClient, PermissionKeys.MANAGE_NEWS_AND_EVENTS) && ' disabled'
                                    }`}
                                data-pr-tooltip={translationText.view}
                                data-pr-position='bottom'
                                data-testid={`view-post#-${post.id}`}
                                onClick={(e) => {
                                    e.preventDefault()
                                    if (
                                        checkPermission(
                                            queryClient,
                                            PermissionKeys.MANAGE_NEWS_AND_EVENTS,
                                            SubPermissionKeys.EDIT
                                        )
                                    ) {
                                        // Open the URL in a new window
                                        window.open(URL, '_blank')
                                    }
                                }}
                            />
                        </MlxTooltip>
                    </span>
                }
            </>
        )
    }

    const itemTemplate = (post: NewsAndEventsListType) => {
        return (
			<Card className="data-view-card mt-2 d-flex flex-column justify-content-even">
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <p className="m-0">{toPascalCase(post.title)}</p>
                    {postTypeTemplate(post)}
                </div>
				<div className='mt-2'>
                    <div className="d-flex flex-row align-items-center">
                        <span className="me-2"><JTranslation typeCase="capitalize" text={PUBLISH_DATE} />:</span>
					    <p className="m-0 font-bold">{convertUTCtoLocalByOffset(post.publishDate)}</p>
                    </div>                  
				</div>
				<div className="view-footer mt-2 pt-3 d-flex flex-row justify-content-between align-items-center">
					<div className='d-flex justify-content-even align-items-center'>
                        {viewButtonTemplate(post)}{editButtonTemplate(post)}{deleteButtonTemplate(post)}
                    </div>
					<div className='d-flex justify-content-even align-items-center'>{activeInactiveTemplate(post)}</div>
				</div>
			</Card>
		)
    }

    return (
        <>
            {/* Delete modal */}
            <WarningModal
                show={showWarning}
                title={DELETE_WARNING}
                onHide={() => {
                    setWarning(false)
                }}
                callback={() => {
                    setWarning(false)
                    deleteNewsAndEventsApiCall()
                }}
            />

            {/* toaster component */}
            <ToastAlert
                show={toastMessage.show}
                onClose={() => setToastMessage(toastMessageInitialData)}
                message={toastMessage.message}
                variant={toastMessage.variant}
            />

            {/* Add and Edit News/Event Sidebar Component  */}
            <AddNewsAndEventsSideBar
                pageMode={pageMode}
                setShowSideBar={setShowSideBar}
                selectedPostId={selectedPostId}
                postType={postType}
                setPostType={setPostType}
            />

            <div className="col-md-12 col-lg-12 mb-3 h-100 ">
                <div className="card h-100 ">
                    <div className="card-header flex-grow-0">
                        <div className="d-flex  align-items-center">
                            <div className="flex-grow-1">
                                {showTitle &&
                                    <h4 className="mb-0 title"><JTranslation typeCase="pascal" text={NEWS_AND_EVENTS} /></h4>
                                }
                            </div>
                            <div className="flex-grow-0 me-2 ms-1">
                                <button
                                    className="btn btn-custom-primary d-flex icon-only-btn "
                                    data-testid="add-news-events-btn"
                                    type="button"
                                    onClick={() => {
                                        setPageMode(PageMode.ADD)
                                        setPostType(PostType.NEWS)
                                        setShowSideBar(true)
                                    }}
                                >
                                    <i className="ri-add-circle-line me-1"></i>
                                    <span className="btn-text"><JTranslation typeCase="pascal" text={ADD_NEWS_AND_EVENTS} /></span>
                                </button>
                            </div>
                            <div className="flex-grow-0 d-flex justify-content-end ">
                                <div className="col-md-12">
                                    <div className={`form-group has-search ${!showTitle ? '' : 'search-small-box'}`}>
                                        <span className="fa fa-search form-control-feedback">
                                            <FaSearch />{' '}
                                        </span>
                                        <DebounceInput
                                            onFocusCapture={() => {breakPoint >= width && setShowTitle(false)}}
                                            onBlurCapture={(event: any) => {                                                
                                                if(breakPoint >= width && event.target.value.length === 0) {
                                                    setShowTitle(true)
                                                }
                                            }}
                                            className="form-control"
                                            placeholder={translationText.search}
                                            minLength={0}
                                            debounceTimeout={500}
                                            onChange={(e) => {
                                                onSearchTrigger(e.target.value)
                                            }}
                                            data-test-id={'search'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body flex-grow-1 overflow-hidden">
                        <div className="survey-section h-100">
                            <div className="row mb-5 h-100">
                                <div className="col-lg-12" style={{ height: '100%', overflow: 'auto' }}>
                                {width <= breakPoint ? (
                                    <DataView
                                        value={posts}
                                        layout={'list'}
                                        itemTemplate={itemTemplate}
                                        paginator
                                        rows={10}
                                        paginatorClassName="p-dataview-customers"
                                        dataKey="id"
                                        emptyMessage={translationText.emptyMessage}
                                    />
                                    ) : (
                                        <DataTable
                                            value={posts}
                                            paginator
                                            className="p-datatable-customers"
                                            rows={10}
                                            scrollable
                                            scrollHeight="flex"
                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                            rowsPerPageOptions={[10, 25, 50]}
                                            dataKey="id"
                                            rowHover
                                            loading={loading}
                                            responsiveLayout="scroll"
                                            emptyMessage={translationText.emptyMessage}
                                            currentPageReportTemplate={translationText.paginationTemplate}
                                        >
                                            <Column field="title" header={<JTranslation typeCase="pascal" text={TITLE} />} sortable className="word-break-grid" style={{minWidth:'15rem'}}/>
                                            <Column field="postType" header={<JTranslation typeCase="pascal" text={TYPE} />} sortable body={postTypeTemplate} style={{minWidth:'10rem'}} />
                                            <Column
                                                field="publishDate"
                                                header={<JTranslation typeCase="pascal" text={PUBLISH_DATE} />}
                                                sortable
                                                body={(post) => convertUTCtoLocalByOffset(post.publishDate)}
                                                style={{minWidth:'10rem'}}
                                            />
                                            <Column
                                                field="publishedStatus"
                                                header={<JTranslation typeCase="pascal" text={STATUS} />}
                                                sortable
                                                body={activeInactiveTemplate}
                                                style={{minWidth:'15rem'}}
                                            />
                                            <Column
                                                field="view"
                                                style={{ maxWidth: '3rem', minWidth: '3rem' }}
                                                bodyClassName="bg-sticky-color"
                                                body={viewButtonTemplate}
                                                alignFrozen="right"
                                                frozen={true}
                                            />
                                            <Column
                                                field="edit"
                                                style={{ maxWidth: '3rem', minWidth: '3rem' }}
                                                bodyClassName="bg-sticky-color"
                                                body={editButtonTemplate}
                                                alignFrozen="right"
                                                frozen={true}
                                            />
                                            <Column
                                                field="delete"
                                                style={{ maxWidth: '3rem', minWidth: '3rem' }}
                                                bodyClassName="bg-sticky-color"
                                                body={deleteButtonTemplate}
                                                alignFrozen="right"
                                                frozen={true}
                                            />
                                        </DataTable>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default NewsAndEventsListGrid
