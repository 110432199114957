import withSidebar from "../../hoc/withSidebar";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import GuestVisitedGraph from "../../components/dashboard/GuestVisitedGraph";
import { useQueryClient } from "react-query";
import GuestVisitedGrid from "../../components/dashboard/GuestVisitedGrid";
import GuestSurveyRatingGraph from "../../components/dashboard/GuestSurveyRatingGraph";
import { checkPermission, convertUTCtoLocalByOffset, getStaffId } from "../../helpers/utils";
import { PermissionKeys, SubPermissionKeys } from "../../constants/constants";
import linkExpiredImg from '../../assets/images/link-expired.png';
import { JTranslation } from "../../helpers/jTranslate";
import TimeOffCard from "../../components/dashboard/TimeOffCard";
import AvailabilityRequestCard from "../../components/dashboard/AvailabilityRequestCard";
import MyScheduleCard from "../../components/dashboard/MyScheduleCard";
import CheckVoucherCard from "../../components/dashboard/CheckVoucherCard";
import UpcomingHolidaysCard from "../../components/dashboard/UpcomingHolidaysCard";
import NotificationCard from "../../components/dashboard/NotificationCard";

export type GraphData = {
    labels: string[];
    datasets: {
        label: string;
        data: (number | string)[];
        backgroundColor: string;
    }[];
}

export type GraphOptions = {
    responsive: boolean;
    maintainAspectRatio: boolean;
    aspectRatio: number;
    scales: {
        x: {
            grid: {
                display: boolean;
            };
        };
        y: {
            grid: {
                display: boolean;
            };
            suggestedMin: number,
            suggestedMax: number
        };
    };
    plugins: {
        legend: {
            position: "bottom" | "top";
            maxWidth?: number;
            maxHeight?: number;
            align?: "start" | "end" | "center";
            labels:  {
                boxWidth?: number
            }
        };
        title: {
            display: boolean;
        };
    };
}

function Dashboard() {

    const queryClient = useQueryClient();
    const staffId = getStaffId();

    // Dashboard permissions
    const isSuperAdmin = staffId === 'superadmin';
    const canViewSurveyCompletionGraph = checkPermission(queryClient, PermissionKeys.MANAGE_DASHBOARD, SubPermissionKeys.VIEW_SURVEY_COMPLETION_GRAPH);
    const canViewSurveyFeedbackGraph = checkPermission(queryClient, PermissionKeys.MANAGE_DASHBOARD, SubPermissionKeys.VIEW_SURVEY_FEEDBACK_GRAPH);
    const canViewFrequentlyVisited = checkPermission(queryClient, PermissionKeys.MANAGE_DASHBOARD, SubPermissionKeys.VIEW_FREQUENTLY_VISITED);
    const canViewTimeOffList = checkPermission(queryClient, PermissionKeys.MANAGE_DASHBOARD, SubPermissionKeys.VIEW_TIME_OFF_LIST);
    const canViewAvailability = checkPermission(queryClient, PermissionKeys.MANAGE_DASHBOARD, SubPermissionKeys.VIEW_AVAILABILITY);
    const canViewHolidayList = checkPermission(queryClient, PermissionKeys.MANAGE_DASHBOARD, SubPermissionKeys.VIEW_HOLIDAY_LIST);

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        PointElement,
        LineElement,
        Title,
        Title,
        Tooltip,
        Legend
    );

    const graphOptions = {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        scales: {
            x: {
                grid: {
                    display: false
                }
            },
            y: {
                grid: {
                    display: false
                },
                suggestedMin: 0,
                suggestedMax: 100
            }
        },
        plugins: {
            legend: {
                position: 'bottom' as const,
                labels: {
                    boxWidth: 30
                }
            },
            title: {
                display: false
            },
        },
    };

    return (
        <>
            <div className="page-title d-flex mb-3 align-items-center">
                <div className="flex-grow-1"><h5 className="mb-0"><JTranslation typeCase="pascal" text={"Dashboard"} /></h5></div>
            </div>
            {/* <div className="col-md-12 col-lg-12  manage-user-sticky user-scroll-fix-first">
        <div className="page-title">
                <h5>Dashboard</h5>
            </div>
        </div>

        <div className="col-md-12 col-lg-12 mb-3 user-scroll-fix ">
            as
        </div> */}
            {/* <div className="col-md-12 col-lg-4 mb-3  ">
               <div className="card h-100 custom-shadow border-radius-05 border-0">
                    <div className="card-body flex-grow-1 overflow-hidden">
                        <div className="d-flex justify-content-between mb-3">
                    <div>
                      <span className="block text-500 font-medium mb-3">
                        <h4>Scan</h4>
                      </span>
                      <div className="text-900 font-medium text-xl">
                        <h5>Voucher</h5>
                      </div>
                    </div>
                    <div>
                    <div className="d-flex align-items-center justify-content-center bg-blue-100 border-round">
                        <i className="ri-qr-code-line text-blue-500 text-xl"></i>
                        </div>
                    </div>
                    </div>
                    </div>
               </div>
            </div>
            <div className="col-md-12 col-lg-4 mb-3  ">
               <div className="card h-100 custom-shadow border-radius-05 border-0">
                    <div className="card-body flex-grow-1 overflow-hidden">
                        asd
                    </div>
               </div>
            </div>
            <div className="col-md-12 col-lg-4 mb-3  ">
               <div className="card h-100 custom-shadow border-radius-05 border-0">
                    <div className="card-body flex-grow-1 overflow-hidden">
                        asd
                    </div>
               </div>
            </div> */}

            {(isSuperAdmin || (canViewSurveyCompletionGraph || canViewSurveyFeedbackGraph || canViewFrequentlyVisited || canViewTimeOffList || canViewHolidayList)) ? 
                <>
                    <div className="col-md-12 col-lg-8 mb-3  ">
                        <MyScheduleCard />
                    </div>

                    <div className="col-md-12 col-lg-4 mb-3  ">
                        <NotificationCard />
                    </div>
                  
                    <div className="col-md-12 col-lg-6 mb-3  ">
                        <TimeOffCard />
                    </div>
        
                    <div className="col-md-12 col-lg-6 mb-3  ">
                        <UpcomingHolidaysCard />
                    </div>

                    {canViewSurveyCompletionGraph && (
                        <div className="col-md-12 col-lg-6 mb-3  ">
                            <GuestVisitedGraph queryClient={queryClient} graphOptions={graphOptions} />
                        </div>
                    )}
        
                    {canViewSurveyFeedbackGraph && (
                        <div className="col-md-12 col-lg-6 mb-3  ">
                            <GuestSurveyRatingGraph queryClient={queryClient} graphOptions={graphOptions} />
                        </div>
                    )}
        
                    {canViewFrequentlyVisited && (
                        <div className="col-md-12 col-lg-12 mb-3  ">
                            <GuestVisitedGrid queryClient={queryClient} />
                        </div>
                    )}
                    
                    {/* {canViewAvailability && ( */}
                        {/* <div className="col-md-12 col-lg-6 mb-3  ">
                            <AvailabilityRequestCard />
                        </div> */}
                    {/* )} */}

                    
                </>
                : 
                <div className="col-md-12 col-lg-12 mb-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="dashboard-alt">
                                <div className="text-center">
                                    <img src={linkExpiredImg} alt="coming-soon" className="img-fluid screen-img" />
                                    <h5 className="mt-2 mb-3">
                                        <JTranslation typeCase="capitalize" text={"We're sorry"} />, <span className="color-secondary-custom"> <JTranslation typeCase="capitalize" text={"We regret to inform you that access to this content is restricted at the moment"} />.</span>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default withSidebar(Dashboard);