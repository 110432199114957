import { CommonCtxProvider } from './CommonCtxProvider'
import { FileManagementCtxProvider } from './FileManagementCtxProvider'
import { FnBMenuCtxProvider } from './MoveItemCtxProvider'
import { PropsWithChildren } from 'react'
import { ScheduleManagementCtxProvider } from './ScheduleManagementCtxProvider'
import { UsersCtxProvider } from './UsersCtxProvider'
import { FirebaseCtxProvider } from './FirebaseCtxProvider'

export const ContextAPI = ({ children }: PropsWithChildren) => (
	<CommonCtxProvider>
		<FirebaseCtxProvider>
			<UsersCtxProvider>
				<FnBMenuCtxProvider>
					<ScheduleManagementCtxProvider>
						<FileManagementCtxProvider>{children}</FileManagementCtxProvider>
					</ScheduleManagementCtxProvider>
				</FnBMenuCtxProvider>
			</UsersCtxProvider>
		</FirebaseCtxProvider>
	</CommonCtxProvider>
)
