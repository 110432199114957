import { useEffect, useRef } from 'react'
import { Field, FieldProps } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { JTranslation } from '../../../helpers/jTranslate';
import { RecurrenceProps } from '../../../constants/staticTypes';
import { weekdays } from '../../../helpers/newsAndEventsHelper';
import { InputNumber } from 'primereact/inputnumber';

const Weekly = ({ errors }: RecurrenceProps) => {
    const inputNumberRef = useRef<HTMLInputElement>(null)

    useEffect(() => { 
        if(inputNumberRef.current) {
            inputNumberRef.current.autocomplete = "off"
        }
    }, [])

    return (
        <div className="d-flex align-items-center gap-2">
            <span className="">
                <Field name="repeatsEveryNthWeek">
                    {({ field, form }: FieldProps) => (
                        <>
                            <InputNumber
                                name={field.name}
                                inputClassName={errors.repeatsEveryNthWeek ? 'p-invalid' : undefined}
                                onChange={(e) => {
                                    if (e.value) form.setFieldValue(field.name, e.value)
                                }}
                                value={field.value}
                                min={1}
                                max={99}
                                data-testid="daily-repeats-every-nth-day"
                                className='width-50'
                                inputRef={inputNumberRef}
                            />
                            <span className='m-2'>
                                <JTranslation typeCase="none" text={field.value > 1 ? "weeks" : 'week'} />
                            </span>
                        </>
                    )}
                </Field>
            </span>
            <span className="">
                <Field name="selectedDaysOfWeek">
                    {({ field, form }: FieldProps) => (
                        <div className="p-field">
                            <Dropdown
                                id={field.name}
                                {...field}
                                options={weekdays}
                                onChange={(e) => form.setFieldValue(field.name, e.value)}
                                onBlur={field.onBlur}
                                className={form.errors.selectedDaysOfWeek ? 'p-invalid' : ''}
                                data-testid="weekly-repeats-dropdown"
                            />
                        </div>
                    )}
                </Field>
            </span>
        </div>
    );

}

export default Weekly