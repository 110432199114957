import { useState, useEffect, useContext } from 'react'
import { capitalizeFirstLetter } from '../../helpers/utils'
import { changeExtractedFileType } from '../../helpers/verifyDocumentTypeHelper'
import { ExtractedFileData, FileTypeOption, ChangeFileType, ActionButtonToBeEnabled } from '../../constants/staticTypes'
import { FileManagementCtx } from '../../context/FileManagementCtxProvider'
import { SELECT, CHOOSE_A_FILE_TYPE } from '../../constants/strings'
import { useQueryClient } from 'react-query'
import { v4 as uuidv4 } from 'uuid'
import Form from 'react-bootstrap/Form'
import ToastAlert from '../../components/alert/ToastAlert'
import useMutationHook from '../../hooks/useMutationHook'

const FileTypeDropdown = (props: ExtractedFileData) => {
    const { textractIdentifiedEntity, textractJobStatus, id, isUserChangedInitialEntity } = props
    const queryClient = useQueryClient()
    const verifyDocumentMutation = useMutationHook(queryClient, true)
    let typeForApi = ''

    // CONTEXT VARIABLES
    const { fileTypeOptions, buttonToBeEnabled, setButtonToBeEnabled, setRefetchAPI } = useContext(FileManagementCtx)

    // STATE VARIABLES
    const [options, setOptions] = useState<JSX.Element[]>([])

    // ALERT VARIABLES
    const [alertVariant, setVariant] = useState('')
    const [toastMessage, setToastMessage] = useState('')
    const [showAlert, setShowAlert] = useState(false)

    const arrayRemove = (arr: ActionButtonToBeEnabled[], value: ActionButtonToBeEnabled) => {
        return arr.filter(function (ele) {
            return ele !== value
        })
    }

    const getSelectedStatus = (item: FileTypeOption) => {
        if (textractIdentifiedEntity) {
            return item.displayLabel === textractIdentifiedEntity?.toLowerCase() ? true : false
        }
    }

    const getDropDownStatus = () => {
        let status
        if (
            (textractJobStatus === 'SUCCEEDED' || textractJobStatus === 'USER_REJECTED') &&
            textractIdentifiedEntity !== null
        ) {
            status = false
        }

        if (textractJobStatus === 'IN_PROGRESS') {
            status = true
        }

        if (textractIdentifiedEntity !== null && !isUserChangedInitialEntity && textractJobStatus === 'SUCCEEDED') {
            status = true
        }

        return status
    }

    const onSuccess = (message: string) => {
        setToastMessage(message)
        setVariant('success')
        setShowAlert(true)
        setRefetchAPI(true)
    }

    const onError = (errorMessage: string, variant: string) => {
        setToastMessage(errorMessage)
        setVariant(variant)
        setShowAlert(true)
        setRefetchAPI(true)
    }

    // api call to save file type change
    const saveFileTypeChange = () => {
        let payload: ChangeFileType = { extractResultId: id, textractIdentifiedEntity: typeForApi.toUpperCase() }
        // api call to change the extracted file type
        changeExtractedFileType(verifyDocumentMutation, payload, onSuccess, onError)
    }

    // effect to create dropdown options
    useEffect(() => {
        let isSelected: boolean | undefined = false
        const options = fileTypeOptions?.map((item: FileTypeOption) => {
            if (!isSelected) {
                isSelected = getSelectedStatus(item)
            }
            return (
                <option key={uuidv4()} value={item.displayLabel.toLowerCase()} selected={getSelectedStatus(item)}>
                    {capitalizeFirstLetter(item.displayLabel)}
                </option>
            )
        })
        const select = isSelected ? [] : [<option key={SELECT} value={SELECT}>{SELECT}</option>]
        setOptions([...select, ...options])
        isSelected = false
    }, [fileTypeOptions, textractIdentifiedEntity]) // eslint-disable-line

    return (
        <>
            <ToastAlert show={showAlert} onClose={setShowAlert} message={toastMessage} variant={alertVariant} />
            <Form.Select
                aria-label="file-type-select"
                title={CHOOSE_A_FILE_TYPE}
                disabled={getDropDownStatus()}
                data-testid={"file-type-select#" + id}
                onChange={(e) => {
                    if (e.target.value === SELECT) {
                        let buttonList = arrayRemove(buttonToBeEnabled, { documentId: id })
                        setButtonToBeEnabled(buttonList)
                    } else {
                        typeForApi = e.target.value
                        setButtonToBeEnabled([...buttonToBeEnabled, { documentId: id }])
                        saveFileTypeChange()
                    }
                }}
            >
                {options}
            </Form.Select>
        </>
    )
}

export default FileTypeDropdown
