import { Auth } from 'aws-amplify';
import axios from 'axios';
import { apiBaseUrl, getFnBGlobalSettingsApi, userGlobalConfigApi } from '../constants/apiEndPoints';
import { HttpMethods, TENANT_ID_HEADER } from '../constants/constants';
import { Payload } from '../constants/staticTypes';
// import { useTrackEvent } from '../hooks/useTrackEvent';
import { CHANNEL, FORCE_LOGOUT } from '../constants/strings';

type Config = {
    [key: string]: string | {};
}

/**
 * Helper Class For API Calls
 */
const HttpServiceHelper = (payload: Payload) => {
    // const { trackEvent } = useTrackEvent()
    const fetch = async () => {
        const config: Config = {
            headers: {
                'Cache-Control': 'no-cache',
                'Content-Type': 'application/json',
                [TENANT_ID_HEADER]: localStorage.getItem(TENANT_ID_HEADER),
                ...payload.headers
            },
        };

        if (!payload.noAuth) {
            let token = null;
            await Auth.currentSession()
                .then(data => {
                    token = data.getIdToken().getJwtToken();
                })
                .catch(() => {
                    token = null;
                });

            if (token !== null) {
                config.headers = {
                    Authorization: `${token}`,
                    ...config.headers as Config,
                };
            }
        }
        config.method = payload.method ? payload.method : HttpMethods.GET;
        config.url = payload.isSignedUrl ? payload.url : apiBaseUrl + payload.url; // base url + api
        if (payload.data) {
            config.data = payload.data;
        }

        // Add a response interceptor
        axios.interceptors.response.use(function (response) {
            // Do something with response data
            return response;
        }, function (error) {

            // function isServerError(errorCode: number) {
            //     // Check if the errorCode is a number starting with 5
            //     return typeof errorCode === 'number' && Math.floor(errorCode / 100) === 5;
            // }

            // const errorEvent = {
            //     "error_code": error.response.status,
            //     "error_message": error.response.data.message,
            //     "url": error.response.request.responseURL,
            //     "tenantName": localStorage.getItem(TENANT_NAME),
            //     "tenantId": localStorage.getItem(TENANT_ID_HEADER),
            //     "fatal": isServerError(error.response.status),
            // }

            // // Google Analytics
            // trackEvent({
            //     eventName: 'exception',
            //     value: errorEvent
            // });

            // check for 401 UnAuthorized error and force logout user
            const { response } = error || {};
            if (response?.status === 401) {
                const errorApiUrl = error?.config?.url || '';
                // skipped some private URLs, since these are being called after logout
                const skippedUrls = [userGlobalConfigApi, getFnBGlobalSettingsApi]
                const checkIfSkipped = skippedUrls.some(url => errorApiUrl.includes(url));
                if (!checkIfSkipped) {
                    // create a new broadcast channel with the same name
                    const channel = new BroadcastChannel(CHANNEL)

                    // listen for messages on the channel
                    channel.postMessage(FORCE_LOGOUT)

                    // clean up
                    channel.close()
                }
            }

            return Promise.reject(error);
        });

        return axios(config);
    };
    return fetch();
};

export default HttpServiceHelper;