import { AlertVariant, HttpMethods } from '../constants/constants'
import { AxiosResponse } from 'axios'
import { formatPricesData, formatAdditionalInfo } from './menuManagementHelper'
import { getErrorMessage } from './utils'
import { UseMutationResult, QueryClient } from 'react-query'
import HttpServiceHelper from './httpServiceHelper'
import {
  customMenuAddExistingItemsApi,
  customMenuCategoryApi,
  customMenuCategoryStatusApi,
  customMenuChangeMarketPriceLabelApi,
  customMenuGuestViewApi,
  customMenuItemApi,
  customMenuItemDetailsViewApi,
  customMenuItemStatusApi,
  customMenusApi,
  customMenuSortCategoryApi,
  customMenuSortItemApi,
  customMenuStatusApi,
  customMenuToggleCardPriceApi,
  customMenuToggleSuggestedCardPriceApi,
  getSingleCustomMenuApi,
  getSingleCustomMenuGuestApi,
} from '../constants/apiEndPoints'
import {
  AddExistingItemsParamType,
  ChangeCustomCategoryStatus,
  ChangeCustomItemStatus,
  ChangeCustomMenuStatus,
  CreateCustomCategoryType,
  CustomMenu,
  CustomMenuCategorySort,
  CustomMenuForm,
  CustomMenuGlobalConfigResponse,
  CustomMenuItemSort,
  CustomMenuItemViewParam,
  DeleteCustomCategoryType,
  DeleteCustomMenuType,
  EditCustomCategoryType,
  EditCustomMenu,
  FnBGlobalSetting,
  GetSingleCustomMenuParamType,
  MenuItem,
  Payload,
} from '../constants/staticTypes'

export const initialCustomMenu: CustomMenuForm = { menuDescription: '', photoKey: '', menuName: '' }

export const initialCustomMenuDeleteData: DeleteCustomMenuType = { menuId: '' }

// fetch all users
export const getCustomMenuList = (search: string, limit: number, lastKey: string) => {
  return HttpServiceHelper({
    url: customMenusApi + '?search=' + search + '&limit=' + limit + '&lastKey=' + lastKey,
    method: HttpMethods.GET,
  })
}

// fetch custom menu on 
export const getGuestViewCustomMenuList = (menuCode: string) => {
  return HttpServiceHelper({
    url: `${customMenuGuestViewApi}/${menuCode}`,
    method: HttpMethods.GET,
  })
}

// fetch a single custom menu
export const getSingleCustomMenu = (
  customMenuMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
  param: GetSingleCustomMenuParamType,
  onSuccess: (data: CustomMenu) => void,
  onError: (res: string, variant: string) => void
) => {
  customMenuMutation.mutate(
    {
      url: getSingleCustomMenuApi,
      method: HttpMethods.POST,
      data: param,
    },
    {
      onSuccess: (res) => onSuccess(res.data.data),
      onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
    }
  )
}

// delete custom menu
export const deleteCustomMenu = (
  customMenuMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
  param: DeleteCustomMenuType,
  onSuccess: (message: string, variant: string) => void,
  onError: (res: string, variant: string) => void
) => {
  customMenuMutation.mutate(
    {
      url: customMenusApi,
      method: HttpMethods.DELETE,
      data: param,
    },
    {
      onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
      onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
    }
  )
}

// create custom menu
export const createCustomMenu = (
  customMenuMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
  param: CustomMenuForm,
  onSuccess: (message: string, variant: string, data: CustomMenu) => void,
  onError: (res: string, variant: string) => void
) => {
  customMenuMutation.mutate(
    {
      url: customMenusApi,
      method: HttpMethods.POST,
      data: param,
    },
    {
      onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS, res.data.data),
      onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
    }
  )
}

// edit custom menu
export const editCustomMenuApi = (
  customMenuMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
  param: EditCustomMenu,
  onSuccess: (message: string, variant: string, data: CustomMenu) => void,
  onError: (res: string, variant: string) => void
) => {
  customMenuMutation.mutate(
    {
      url: customMenusApi,
      method: HttpMethods.PUT,
      data: param,
    },
    {
      onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS, res.data.data),
      onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
    }
  )
}

// change custom menu status
export const changeCustomMenuStatus = (
  customMenuMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
  param: ChangeCustomMenuStatus,
  onSuccess: (message: string, variant: string, data: CustomMenu) => void,
  onError: (res: string, variant: string) => void
) => {
  customMenuMutation.mutate(
    {
      url: customMenuStatusApi,
      method: HttpMethods.POST,
      data: param,
    },
    {
      onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS, res.data.data),
      onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
    }
  )
}

// create custom category
export const createCustomCategory = (
  customMenuMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
  param: CreateCustomCategoryType,
  onSuccess: (message: string, variant: string) => void,
  onError: (res: string, variant: string) => void
) => {
  customMenuMutation.mutate(
    {
      url: customMenuCategoryApi,
      method: HttpMethods.POST,
      data: param,
    },
    {
      onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
      onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
    }
  )
}

// edit custom category
export const editCustomCategory = (
  customMenuMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
  param: EditCustomCategoryType,
  onSuccess: (message: string, variant: string) => void,
  onError: (res: string, variant: string) => void
) => {
  customMenuMutation.mutate(
    {
      url: customMenuCategoryApi,
      method: HttpMethods.PUT,
      data: param,
    },
    {
      onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
      onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
    }
  )
}

// delete custom category
export const deleteCustomCategory = (
  customMenuMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
  param: DeleteCustomCategoryType,
  onSuccess: (message: string, variant: string) => void,
  onError: (res: string, variant: string) => void
) => {
  customMenuMutation.mutate(
    {
      url: customMenuCategoryApi,
      method: HttpMethods.DELETE,
      data: param,
    },
    {
      onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
      onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
    }
  )
}

// create custom menu item
export const createAndUpdateCustomItem = (
  createItemMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
  form: MenuItem,
  onSuccess: (message: string, variant: string) => void,
  onError: (res: string, variant: string) => void
) => {
  // format data
  const additionalInfo = formatAdditionalInfo(form.additionalInfo)
  const historyAdditionalInfo = formatAdditionalInfo(form.historyInfo.additionalInfo)

  // remove empty prices
  const filteredItemPrices = formatPricesData(form.itemPrices)
  const filteredAddonPrices = formatPricesData(form.addonPrices)

  // invoke api
  createItemMutation.mutate(
    {
      url: customMenuItemApi,
      method: form.itemMenuId ? HttpMethods.PUT : HttpMethods.POST,
      data: {
        ...form,
        itemPrices: [...filteredItemPrices],
        addonPrices: [...filteredAddonPrices],
        additionalInfo,
        historyInfo: {
          title: form.historyInfo.title,
          subTitle: form.historyInfo.subTitle,
          description: form.historyInfo.description,
          originCountry: form.historyInfo.originCountry,
          otherHtmlContents: form.historyInfo.otherHtmlContents,
          photoKeys: form.historyInfo.photoKeys,
          color: form.historyInfo.color,
          bouquet: form.historyInfo.bouquet,
          taste: form.historyInfo.taste,
          denomination: form.historyInfo.denomination,
          additionalInfo: historyAdditionalInfo,
        },
      },
    },
    {
      onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
      onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
    }
  )
}

// delete item
export const customMenuDeleteItem = (
  itemMenuId: string | undefined,
  deleteItemMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
  onSuccess: (message: string) => void,
  onError: (res: string, variant: string) => void
) => {
  deleteItemMutation.mutate(
    {
      url: customMenuItemApi,
      method: HttpMethods.DELETE,
      data: {
        itemMenuId,
      },
    },
    {
      onSuccess: (res) => onSuccess(res.data.message),
      onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
    }
  )
}

// active inactive menu item
export const activeInactiveCustomItem = (
  customMenuMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
  param: ChangeCustomItemStatus,
  onSuccess: (message: string, variant: string, data: CustomMenu) => void,
  onError: (res: string, variant: string) => void
) => {
  customMenuMutation.mutate(
    {
      url: customMenuItemStatusApi,
      method: HttpMethods.POST,
      data: param,
    },
    {
      onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS, res.data.data),
      onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
    }
  )
}

// active inactive menu item
export const activeInactiveCustomCategory = (
  customMenuMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
  param: ChangeCustomCategoryStatus,
  onSuccess: (message: string, variant: string, data: CustomMenu) => void,
  onError: (res: string, variant: string) => void
) => {
  customMenuMutation.mutate(
    {
      url: customMenuCategoryStatusApi,
      method: HttpMethods.POST,
      data: param,
    },
    {
      onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS, res.data.data),
      onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
    }
  )
}

// bulk upload existing menu items
export const addExistingItemToCategory = (
  customMenuMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
  param: AddExistingItemsParamType,
  onSuccess: (message: string, variant: string, data: CustomMenu) => void,
  onError: (res: string, variant: string) => void
) => {
  customMenuMutation.mutate(
    {
      url: customMenuAddExistingItemsApi,
      method: HttpMethods.POST,
      data: param,
    },
    {
      onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS, res.data.data),
      onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
    }
  )
}

// get menu item details
export const getSpecialMenuItemsDetails = (queryClient: QueryClient, menuId: string) => {
  return HttpServiceHelper({
    url: getSingleCustomMenuGuestApi,
    method: HttpMethods.POST,
    queryClient,
    data: {
      menuId,
    },
  })
}

// change market price label
export const changeCustomMenuMarketPriceLabel = (
  params: { marketPriceLabel: string, menuId: string },
  fnBGlobalConfigMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
  onSuccess: (newSettings: FnBGlobalSetting) => void,
  onError: (res: string, variant: string) => void
) => {
  fnBGlobalConfigMutation.mutate(
    {
      url: customMenuChangeMarketPriceLabelApi,
      method: HttpMethods.POST,
      data: params,
    },
    {
      onSuccess: (res: CustomMenuGlobalConfigResponse) => onSuccess(res.data.data.Attributes.globalSettings),
      onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
    }
  )
}

// change market price label
export const toggleCustomMenuCardPrice = (
  params: { showMenuCardPrice: boolean, menuId: string },
  fnBGlobalConfigMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
  onSuccess: (newSettings: FnBGlobalSetting) => void,
  onError: (res: string, variant: string) => void
) => {
  fnBGlobalConfigMutation.mutate(
    {
      url: customMenuToggleCardPriceApi,
      method: HttpMethods.POST,
      data: params,
    },
    {
      onSuccess: (res: CustomMenuGlobalConfigResponse) => onSuccess(res.data.data.Attributes.globalSettings),
      onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
    }
  )
}

// change market price label
export const toggleCustomMenuSuggestedCardPrice = (
  params: { showMenuSuggestedItemCardPrice: boolean, menuId: string },
  fnBGlobalConfigMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
  onSuccess: (newSettings: FnBGlobalSetting) => void,
  onError: (res: string, variant: string) => void
) => {
  fnBGlobalConfigMutation.mutate(
    {
      url: customMenuToggleSuggestedCardPriceApi,
      method: HttpMethods.POST,
      data: params,
    },
    {
      onSuccess: (res: CustomMenuGlobalConfigResponse) => onSuccess(res.data.data.Attributes.globalSettings),
      onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
    }
  )
}

export const customMenuSortItem = (
  data: CustomMenuItemSort,
  sortMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
  onSuccess: (message: string) => void,
  onError: (res: string, variant: string) => void) => {
  sortMutation.mutate({
    url: customMenuSortItemApi,
    method: HttpMethods.POST,
    data: data
  }, {
    onSuccess: (res) => onSuccess(res.data.message),
    onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
  });
}

// move item to another category 
export const customMenuSortCategory = (
  data: CustomMenuCategorySort,
  createMenuMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
  onSuccess: (message: string) => void,
  onError: (res: string, variant: string) => void) => {
  createMenuMutation.mutate({
    url: customMenuSortCategoryApi,
    method: HttpMethods.POST,
    data: data
  }, {
    onSuccess: (res) => onSuccess(res.data.message),
    onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
  });
}

// get item details for 
export const customMenuItemDetailsView = (
  data: CustomMenuItemViewParam,
  createMenuMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
  onSuccess: (data: MenuItem) => void,
  onError: (res: string, variant: string) => void) => {
  createMenuMutation.mutate({
    url: customMenuItemDetailsViewApi,
    method: HttpMethods.POST,
    data: data
  }, {
    onSuccess: (res) => onSuccess(res.data.data.details),
    onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR)
  });
}
