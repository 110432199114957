import { useState, useContext, useEffect } from 'react';
import withSidebar from "../../hoc/withSidebar";
import { FaSearch } from "react-icons/fa";
import ToastAlert from '../../components/alert/ToastAlert';
import { useQueryClient } from 'react-query';
import { Game } from '../../constants/staticTypes';
import { AxiosResponse } from 'axios';
import useQueryHook from '../../hooks/useQueryHook';
import Loader from '../../components/loader/Loader';
import { OFFERS_LIST } from '../../constants/queryKeys';
import { deleteOffer, getOffersList, initialGameFormData } from '../../helpers/gameHelper';
import OffersListGrid from '../../components/game/admin/OffersListGrid';
import { BetterLuckData } from '../../components/game/admin/NoOfferText';
import { BETTER_LUCK_NEXT_TIME, DELETE_WARNING, HELP_GAME, OFFER_LIST, GAME_MANAGEMENT, ADD_OFFERS } from '../../constants/strings';
import WarningModal from '../../components/warning_modal/WarningModal';
import useMutationHook from '../../hooks/useMutationHook';
import { AlertVariant, MOBILE_VIEW_BREAKPOINT } from '../../constants/constants';
import { OverlayTrigger } from "react-bootstrap";
import { popover } from '../../helpers/utils';
import { CommonCtx } from '../../context/CommonCtxProvider';
import AddOfferSidebar from './AddOfferSidebar';
import NoOfferText from '../../components/game/admin/NoOfferText';
import { JTranslation, TranslationContext, jTranslationText } from '../../helpers/jTranslate';
import { useWindowSize } from 'react-use';
import { DebounceInput } from 'react-debounce-input';
import MlxPopover from '../../components/common/MlxPopover';

function GameManagement() {
    // CONTEXT VARIABLE
    const { showSideBar, setShowSideBar } = useContext(CommonCtx)
    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext
    const queryClient = useQueryClient();
    const [isInitialLoad, setInitialLoad] = useState(true);
    const [offersList, setOffersList] = useState<Game[]>([]);
    const deleteOfferMutation = useMutationHook(queryClient, true);
    const [betterLuckData, setBetterLuckData] = useState<BetterLuckData>({
        prizeWinning: BETTER_LUCK_NEXT_TIME,
        displayOnGameCount: 0
    });

    const [showWarning, setWarning] = useState(false); // warning modal show or hide
    const [deleteId, setDeleteId] = useState(""); // offer delete id
    const [showToast, setToast] = useState(false); // Toast alert state
    const [toastMessage, setToastMessage] = useState(""); // Toast message
    const [toastVariant, setToastVariant] = useState(""); // Toast Variant
    const [activeKey, setAccordionActiveKey] = useState("0");

    const [formData, setFormData] = useState({ ...initialGameFormData });
    const [editId, setEditId] = useState("");
    const [translationText, setTranslatedText] = useState<{
        search: string,
      }>({ 
        search: 'Search',
    });
    const [showTitle, setShowTitle] = useState(true)
    const { width, height } = useWindowSize()
	const breakPoint = MOBILE_VIEW_BREAKPOINT

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: 'Search', typeCase: 'pascal', translationContext }),
                ])
                
                setTranslatedText({
					search: translations[0] ?? 'Search',
				})
            } catch {
                setTranslatedText({
                    search: 'Search',
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    // show toast
    const displayToast = (message: string, variant: string) => {
        setToastVariant(variant);
        setToastMessage(message);
        setToast(true);
    }

    // offers list fetch success
    const onOffersFetchSuccess = (res: AxiosResponse<any, any>) => {
        setOffersList(res.data?.data.list);
        const betterLuckText = res.data.data.betterLuckData.prizeWinning;
        const betterLuckCount = res.data.data.betterLuckData.displayOnGameCount;
        setBetterLuckData({
            prizeWinning: betterLuckText,
            displayOnGameCount: betterLuckCount
        });
        setInitialLoad(false);
    }

    // fetch offers list
    const offerData = useQueryHook(OFFERS_LIST,
        () => getOffersList(queryClient, "", 500), onOffersFetchSuccess);

    if (offerData.isLoading || isInitialLoad) {
        return <Loader />
    }

    // on trigger search
    const onSearchTrigger = (searchKey: string) => {
        if (offerData.isFetching) {
            queryClient.cancelQueries([OFFERS_LIST]);
        }
        queryClient.fetchQuery(OFFERS_LIST, () => getOffersList(queryClient, searchKey, 500));
    }

    // on delete offer success
    const onDeleteSuccess = (res: AxiosResponse<any, any>) => {
        displayToast(res.data.message, AlertVariant.SUCCESS);

        // clear edit if edit selected item is delete
        if (editId === deleteId) {
            setEditId("");
            setFormData({
                ...initialGameFormData,
                winningCriterias: {
                    ...initialGameFormData.winningCriterias
                }
            });
        }

        // update list data
        const updatedOffers = offersList.filter(item => item.id !== deleteId);
        setDeleteId("");
        setOffersList([...updatedOffers]);
    }

    return (
        <>
            <AddOfferSidebar
                displayToast={displayToast}
                editId={editId}
                activeKey={activeKey}
                setEditId={setEditId}
                setFormData={setFormData}
                formData={formData}
                setAccordionActiveKey={setAccordionActiveKey}
                offersList={offersList}
                setOffersList={setOffersList}
                showSideBar={showSideBar}
                setShowSideBar={setShowSideBar}
                getOffersList={() => getOffersList(queryClient, "", 500)}
            />
            <WarningModal title={DELETE_WARNING} show={showWarning} onHide={() => setWarning(false)} callback={() => {
                setWarning(false);
                deleteOffer(queryClient, deleteId, deleteOfferMutation, onDeleteSuccess, displayToast)
            }} />
            <ToastAlert data-testid="toast" show={showToast} onClose={setToast} message={toastMessage} variant={toastVariant} />
            <div className="col-md-12 col-lg-12  manage-user-sticky user-scroll-fix-first">
                <div className="page-title">
                    <h5 className='d-flex'><JTranslation typeCase="pascal" text={"Game Management"} />
                        <MlxPopover data={{title:GAME_MANAGEMENT, body:HELP_GAME}}/>
                    </h5>
                </div>
                <div className="card mb-3 ">
                    <div className="card-body">
                        <NoOfferText betterLuckData={betterLuckData} displayToast={displayToast} setBetterLuckData={setBetterLuckData} />
                    </div>
                </div>

            </div>





            <div className="col-md-12 col-lg-12 mb-3 user-scroll-fix ">
                <div className="card user-list-conainer ">
                    <div className="card-header sticky-card-header">
                        <div className="d-flex  align-items-center">
                            <div className="flex-grow-1">
                                {showTitle &&
                                    <h4 className="mb-0 title"><JTranslation typeCase="pascal" text={OFFER_LIST} /></h4>
                                }
                            </div>
                            <div className="flex-grow-0 me-2 ms-1">
                                <button className="btn btn-custom-primary d-flex icon-only-btn"
                                data-testid="add-offer-btn" type="button" 
                                onClick={() => setShowSideBar(true)}>
                                    <i className="ri-add-circle-line me-2"></i>
                                    <span className='btn-text'><JTranslation typeCase="pascal" text={ADD_OFFERS} /></span>
                                </button>
                            </div>
                            <div className="flex-grow-0 d-flex justify-content-end ">
                                <div className="col-md-12">
                                    <div className={`form-group has-search ${!showTitle ? '' : 'search-small-box'}`}>
                                        <span className="fa fa-search form-control-feedback"><FaSearch />  </span>
                                        <DebounceInput
                                            onFocusCapture={() => {breakPoint >= width && setShowTitle(false)}}
                                            onBlurCapture={(event: any) => {                                                
                                                if(breakPoint >= width && event.target.value.length === 0) {
                                                    setShowTitle(true)
                                                }
                                            }}
                                            autoComplete="off"
                                            className="form-control "
                                            placeholder={translationText.search}
                                            minLength={0}
                                            debounceTimeout={500}
                                            data-testid="search-offer"
                                            onChange={(e) => onSearchTrigger(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="card-body " style={{ height: '100%', overflow: 'auto' }}>
                        <OffersListGrid
                            offersList={offersList}
                            setAccordionActiveKey={setAccordionActiveKey}
                            setShowSideBar={setShowSideBar}
                            setDeleteId={setDeleteId}
                            setEditId={setEditId}
                            setFormData={setFormData}
                            setOffersList={setOffersList}
                            setWarning={setWarning}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
export default withSidebar(GameManagement);