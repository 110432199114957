// FlowerFallingEffect.tsx
import { useContext, useEffect } from 'react';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import type { ISourceOptions } from '@tsparticles/engine';

import { loadAll } from '@tsparticles/all';
import './falling.css';

import { getMothersDayEffects } from '../celebrationsConfigs';

// import eggImage from './egg.png';
import { CommonCtx } from '../../../context/CommonCtxProvider';

const FlowerFallingEffect = () => {
  const { setParticleEngineLoaded } = useContext(CommonCtx);
  const effects = getMothersDayEffects();
  const {
    // @ts-ignore
    particles: { count = 8, maxSize = 30, minOpacity = 0.4, maxOpacity = 0.8 },
  } = effects || {};

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadAll(engine);
    }).then(() => {
      setParticleEngineLoaded(true);
    });
  }, []);

  const particlesOptions: ISourceOptions = {
    fullScreen: {
      enable: true,
      zIndex: 0, // Set zIndex to 0 so it doesn't overlap other elements
    },
    fpsLimit: 60, // Lower FPS limit to reduce load time and improve performance
    interactivity: {
      events: {
        onHover: {
          enable: false, // Disable hover effect
        },
        onClick: {
          enable: false, // Disable click effect
        },
      },
    },
    particles: {
      number: {
        value: count,
      },
      // shape: {
      //   type: 'image',
      //   options: {
      //     image: {
      //       src: eggImage,
      //     },
      //   },
      // },
      shape: {
        type: 'char',
        options: {
          char: {
            value: ['💐', '🌸', '🌺', '🌼', '🌻', '🌹', '🌷', '🥀', '🏵️', '❤️'],
            font: '16px serif',
            style: '',
            weight: '400',
            fill: true,
          },
        },
      },
      opacity: {
        value: maxOpacity,
      },
      size: {
        value: maxSize, // Increase size for better visibility
      },
      move: {
        enable: true,
        speed: 2, // Adjust speed for a more natural falling effect
        direction: 'bottom', // Make particles fall from top to bottom
        outModes: {
          default: 'out', // Particles will be removed when they go out of canvas
        },
      },
    },
    detectRetina: true,
  };

  if (effects?.particles?.colors) {
    // @ts-ignore
    particlesOptions.particles.shape.options.char.value =
      effects.particles.colors;
  }
  return (
    <>
      <Particles
        className='easter-day-particles'
        id='ts-particles'
        options={particlesOptions}
      />
    </>
  );
};

export default FlowerFallingEffect;
