import { AxiosResponse } from 'axios'
import { getErrorMessage } from './utils'
import { HttpMethods, AlertVariant } from '../constants/constants'
import {
  aiImageGenerateApi,
  aiTextGenerateApi,
  IngredientsApi,
  ingredientsSingleApi,
  ingredientsSingleApiBySlug,
  ingredientsSingleApiPrivate,
  listAllIngredientsApi,
} from '../constants/apiEndPoints'
import {
  AiTextResponse,
  AiImageResponse,
  CreateIngredient,
  DeleteIngredientType,
  GenerateAiDescription,
  GenerateImageAiDescription,
  Payload,
  SingleIngredient,
  SingleIngredientResponse,
  UpdateIngredient,
} from '../constants/staticTypes'
import { QueryClient, UseMutationResult } from 'react-query'
import HttpServiceHelper from './httpServiceHelper'

// initial form state
export const initialIngredientResponse: SingleIngredientResponse = {
  id: '',
  imageKeys: [],
  ingredientDescription: '',
  ingredientId: '',
  ingredientName: '',
  signedImageUrls: [],
  selectedMenus: [],
}

export const initialIngredientDeleteData: DeleteIngredientType = {
  ingredientId: '',
}

// fetch all users
export const getIngredientsList = (
  search: string,
  limit: number,
  lastKey: string
) => {
  return HttpServiceHelper({
    url:
      listAllIngredientsApi +
      '?search=' +
      search +
      '&limit=' +
      limit +
      '&lastKey=' +
      lastKey,
    method: HttpMethods.GET,
  })
}

// create new ingredient
export const createNewIngredient = (
  ingredientsMutation: UseMutationResult<
    AxiosResponse<any, any>,
    unknown,
    Payload,
    void
  >,
  ingredientsData: CreateIngredient,
  onSuccess: (message: string, variant: string) => void,
  onError: (res: string, variant: string) => void
) => {
  ingredientsMutation.mutate(
    {
      url: IngredientsApi,
      method: HttpMethods.POST,
      data: ingredientsData,
    },
    {
      onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
      onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
    }
  )
}

// get single ingredients details
export const fetchSingleIngredients = (
  ingredientsMutation: UseMutationResult<
    AxiosResponse<any, any>,
    unknown,
    Payload,
    void
  >,
  param: SingleIngredient,
  onSuccess: (data: SingleIngredientResponse) => void,
  onError: (res: string, variant: string) => void
) => {
  ingredientsMutation.mutate(
    {
      url: ingredientsSingleApi,
      method: HttpMethods.POST,
      data: param,
    },
    {
      onSuccess: (res) => onSuccess(res.data.data),
      onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
    }
  )
}
export const fetchSingleIngredientsPrivate = (
  ingredientsMutation: UseMutationResult<
    AxiosResponse<any, any>,
    unknown,
    Payload,
    void
  >,
  param: SingleIngredient,
  onSuccess: (data: SingleIngredientResponse) => void,
  onError: (res: string, variant: string) => void
) => {
  ingredientsMutation.mutate(
    {
      url: ingredientsSingleApiPrivate,
      method: HttpMethods.POST,
      data: param,
    },
    {
      onSuccess: (res) => onSuccess(res.data.data),
      onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
    }
  )
}

// update ingredient
export const updateIngredient = (
  ingredientsMutation: UseMutationResult<
    AxiosResponse<any, any>,
    unknown,
    Payload,
    void
  >,
  param: UpdateIngredient,
  onSuccess: (message: string, variant: string) => void,
  onError: (res: string, variant: string) => void
) => {
  ingredientsMutation.mutate(
    {
      url: IngredientsApi,
      method: HttpMethods.PUT,
      data: param,
    },
    {
      onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
      onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
    }
  )
}

// delete ingredient
export const deleteIngredient = (
  ingredientsMutation: UseMutationResult<
    AxiosResponse<any, any>,
    unknown,
    Payload,
    void
  >,
  param: DeleteIngredientType,
  onSuccess: (message: string, variant: string) => void,
  onError: (res: string, variant: string) => void
) => {
  ingredientsMutation.mutate(
    {
      url: IngredientsApi,
      method: HttpMethods.DELETE,
      data: param,
    },
    {
      onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
      onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
    }
  )
}

// get AI generate ingredients description
export const generateDescriptionUsingAI = (
  ingredientsMutation: UseMutationResult<
    AxiosResponse<any, any>,
    unknown,
    Payload,
    void
  >,
  param: GenerateAiDescription,
  onSuccess: (data: AiTextResponse) => void,
  onError: (res: string, variant: string) => void
) => {
  ingredientsMutation.mutate(
    {
      url: aiTextGenerateApi,
      method: HttpMethods.POST,
      data: param,
    },
    {
      onSuccess: (res) => onSuccess(res.data.data),
      onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
    }
  )
}

// Remove newline characters from the string
const stringWithoutNewlines = (originalString: string): string => {
  return originalString.replace(/\n/g, '')
}

// get AI generate ingredients description
export const generateImageUsingAI = (
  ingredientsMutation: UseMutationResult<
    AxiosResponse<any, any>,
    unknown,
    Payload,
    void
  >,
  param: GenerateImageAiDescription,
  onSuccess: (data: AiImageResponse) => void,
  onError: (res: string, variant: string) => void
) => {
  const cleanParam: GenerateImageAiDescription = { ...param, positiveText: stringWithoutNewlines(param.positiveText) }
  ingredientsMutation.mutate(
    {
      url: aiImageGenerateApi,
      method: HttpMethods.POST,
      data: cleanParam,
    },
    {
      onSuccess: (res) => onSuccess(res.data.data),
      onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
    }
  )
}

// get ingredients item details
export const getIngredientsDetails = (queryClient: QueryClient, param: SingleIngredient) => {
  return HttpServiceHelper({
      url: ingredientsSingleApi,
      method: HttpMethods.POST,
      queryClient,
      data: param,
  })
}

// get menu item details
export const getIngredientsDetailsBySlug = (queryClient: QueryClient, ingredientSlug: string) => {
  return HttpServiceHelper({
      url: `${ingredientsSingleApiBySlug}/${ingredientSlug}`,
      method: HttpMethods.GET,
      queryClient,
  })
}