import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { FaEdit } from "react-icons/fa";
import { useQueryClient } from "react-query";
import { MOBILE_VIEW_BREAKPOINT, PermissionKeys, SubPermissionKeys } from "../../../constants/constants";
import { Game, GameForm } from "../../../constants/staticTypes";
import { AVAILABLE_COUPON, COUPON_VALIDITY, DESCRIPTION, MAX_COUPON, MIN_VISIT_COUNT, NO_OFFERS, OFFER, RADIUS, WEIGHTAGE, ZIP_CODE, STATUS, INACTIVE, ACTIVE, FREQUENCY, DELETE, EDIT } from "../../../constants/strings";
import { checkPermission } from "../../../helpers/utils";
import { changeOfferStatus, getOffersList } from "../../../helpers/gameHelper";
import { AxiosResponse } from "axios";
import { toPascalCase } from "../../../helpers/utils";
import { useContext, useState, useEffect } from "react";
import { JTranslation, TranslationContext, jTranslationText } from "../../../helpers/jTranslate";
import { useWindowSize } from "react-use";
import { DataView } from "primereact/dataview";
import { Card } from "primereact/card";
import { Badge } from "primereact/badge";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import MlxTooltip from "../../common/MlxTooltip";

type Props = {
    offersList: Game[],
    setAccordionActiveKey: React.Dispatch<React.SetStateAction<string>>
    setShowSideBar: React.Dispatch<React.SetStateAction<boolean>>
    setDeleteId: React.Dispatch<React.SetStateAction<string>>,
    setEditId: React.Dispatch<React.SetStateAction<string>>,
    setFormData: React.Dispatch<React.SetStateAction<GameForm>>,
    setOffersList: React.Dispatch<React.SetStateAction<Game[]>>,
    setWarning: React.Dispatch<React.SetStateAction<boolean>>,
}

function OffersListGrid({ offersList, setDeleteId, setWarning, setFormData, setEditId, setAccordionActiveKey, setShowSideBar, setOffersList }: Props) {

    const queryClient = useQueryClient();
    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext

    const [translationText, setTranslatedText] = useState<{
        edit: string,
        delete: string,
        paginationTemplate: string,
        emptyMessage: string,
    }>({ 
        edit: EDIT,
        delete: DELETE,
        paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
        emptyMessage: NO_OFFERS,
    });
    const { width, height } = useWindowSize()
	const breakPoint = MOBILE_VIEW_BREAKPOINT

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: EDIT, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: DELETE, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: 'Showing 1 to 2 of 3 items', typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: NO_OFFERS, typeCase: 'pascal', translationContext }),
                ])
                
                setTranslatedText({
                    edit: translations[0] ?? EDIT,
                    delete: translations[1] ?? DELETE,
                    paginationTemplate:
                        translations[2]
                            ?.replace('1', '{first}')
                            ?.replace('2', '{last}')
                            ?.replace('3', '{totalRecords}') ?? 'Showing {first} to {last} of {totalRecords} items',
                    emptyMessage: translations[3] ?? NO_OFFERS,
                })
            } catch {
                setTranslatedText({
                    edit: EDIT,
                    delete: DELETE,
                    paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
                    emptyMessage: NO_OFFERS,
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    // delete button
    const deleteButtonTemplate = (offer: Game) => {
        return <>
        <MlxTooltip target=".ri-delete-bin-line">
        <i data-pr-tooltip={translationText.delete} data-pr-position="bottom"
            className={"ri-delete-bin-line delete " +
                (!checkPermission(queryClient, PermissionKeys.MANAGE_GAME, SubPermissionKeys.DELETE) &&
                    " disabled") + (width <= breakPoint ? ' rounded-background shadow-sm ms-2' : '')} data-testid={"offer-delete#" + offer.id}
            onClick={() => {
                if (checkPermission(queryClient, PermissionKeys.MANAGE_GAME, SubPermissionKeys.DELETE)) {
                    setDeleteId(offer.id as string);
                    setWarning(true);
                }
            }}>
        </i>
        </MlxTooltip>
        </>
    }

    const getWeightLabel = (weight: number) => {
        if (weight >= 95) {
            return "Very High";
        } else if (weight >= 75) {
            return "High";
        } else if (weight >= 50) {
            return "Medium";
        } else if (weight >= 30) {
            return "Low";
        } else {
            return "Very Low";
        }
    }

    // edit button
    const editButtonTemplate = (offer: Game) => {
        return (
            <span className={`${width <= breakPoint ? 'rounded-background shadow-sm ms-2' : ''}`}>
                <MlxTooltip target=".edit">
                    <FaEdit
                        className={`edit ${!checkPermission(queryClient, PermissionKeys.MANAGE_USERS, SubPermissionKeys.EDIT) && ' disabled'}`}
                        data-pr-tooltip={translationText.edit}
                        data-pr-position="bottom"
                        data-testid={"offer-edit#" + offer.id}
                        onClick={(e) => {
                            e.preventDefault()
                            if (checkPermission(queryClient, PermissionKeys.MANAGE_GAME, SubPermissionKeys.EDIT)) {
                                setEditId(offer.id as string);
                                if (checkPermission(queryClient, PermissionKeys.MANAGE_GAME, SubPermissionKeys.EDIT)) {
                                    let zipCodes = [] as string[];
                                    if (offer.winningCriterias.zipCodes && offer.winningCriterias.zipCodes?.length > 0) {
                                        zipCodes = offer.winningCriterias.zipCodes;
                                    }

                                    let locationRadiusInMiles = "";
                                    if (offer.winningCriterias.locationRadiusInMiles && offer.winningCriterias.locationRadiusInMiles !== null) {
                                        locationRadiusInMiles = offer.winningCriterias.locationRadiusInMiles.toString();
                                    }

                                    setEditId(offer.id as string);
                                    setAccordionActiveKey("0");
                                    setFormData({
                                        prizeWinning: offer.prizeWinning,
                                        prizeDescription: offer.prizeDescription,
                                        prizeType: offer.prizeType,
                                        stockCount: offer.stockCount.toString(),
                                        stockRepeatInterval: offer.stockRepeatInterval,
                                        couponCodeMaxDaysValidity: offer.couponCodeMaxDaysValidity.toString(),
                                        winningCriterias: {
                                            minVisitCount: offer.winningCriterias.minVisitCount.toString(),
                                            weightage: offer.winningCriterias.weightage.toString(),
                                            locationRadiusInMiles,
                                            zipCodes
                                        }
                                    });
                                }
                                setShowSideBar(true)
                            }
                        }}
                    />
                </MlxTooltip>
            </span>
        )

    }

    // enable/disable button
    const statusButtonTemplate = (offer: Game) => {
        let buttonStatus = offer.isActive

        return (
            <div className="d-flex align-items-center">
                <div className="flex-grow-0">
                    <span className="form-check btn-lg-custom form-switch ">
                        <input
                            className="form-check-input form-check-input-custom"
                            type="checkbox"
                            title={offer.isActive ? ACTIVE : INACTIVE}
                            autoComplete="off"
                            checked={buttonStatus}
                            data-testid={"offer-status#" + offer.id}
                            disabled={!checkPermission(queryClient, PermissionKeys.MANAGE_ROLES, SubPermissionKeys.EDIT)}
                            onClick={(e) => {
                                e.preventDefault()
                                buttonStatus = !offer.isActive
                                // api call to change status
                                changeOfferStatus(queryClient, offer.id, buttonStatus).then(() => {
                                    // recall game offer list api
                                    getOffersList(queryClient, "", 500).then((res: AxiosResponse<any, any>) => {
                                        setOffersList(res.data?.data.list)
                                    })
                                })
                            }}
                            style={width <= breakPoint ? { width: '2.25em', height: '1.25em' } : { width: '1.75em', height: '0.75em' }}
                        />
                    </span>
                </div>
                <div className="flex-grow-0">
                    {width > breakPoint && <span className={`nobr`}>{offer.isActive ? ACTIVE : INACTIVE}</span>}
                </div>
            </div>
        )
    }

    // zip code template
    const zipCodeTemplate = (offer: Game) => {
        if (offer.winningCriterias.zipCodes) {
            let moreText = offer.winningCriterias.zipCodes.length > 1 ? " +" + (offer.winningCriterias.zipCodes.length - 1) + " more" : "";
            let zipCodes = offer.winningCriterias.zipCodes.join(", ");

            if(width <= breakPoint) {
                return <OverlayTrigger
                    trigger={["hover", "hover"]}
                    placement="auto"
                    overlay={<Tooltip className="addon-tooltip">{zipCodes}</Tooltip>}
                >
                    <span className={`customer-badge manager`}>{offer.winningCriterias.zipCodes[0] + moreText}</span>
                </OverlayTrigger>
            } else {
                return (
                    <MlxTooltip target=".zip-code">
                        <div data-pr-tooltip={zipCodes} data-pr-position="bottom" className="zip-code flex-grow-1 overflow-ellipsis">
                            <span className={`customer-badge manager`}>{offer.winningCriterias.zipCodes[0] + moreText}</span>
                        </div>
                    </MlxTooltip>
                );
            }
        }
        return <div></div>
    }

    // description template
    const descTemplate = (offer: Game) => {
        return <MlxTooltip target=".offer-description">
        <div data-pr-tooltip={offer.prizeDescription} data-pr-position="bottom"
            className="offer-description flex-grow-1 overflow-ellipsis">
            <span className={`nobr`}>{offer.prizeDescription}</span>
        </div>
        </MlxTooltip>;
    }

    const frequencyTemplate = (offer: Game) => {
        return <MlxTooltip target=".offer-frequency">
            <div data-pr-tooltip={offer.stockRepeatInterval} data-pr-position="mouse"
                className="offer-frequency flex-grow-1 overflow-ellipsis">
                <span className={`nobr`}>{toPascalCase(offer.stockRepeatInterval)}</span>
            </div>
        </MlxTooltip>;
    }

    const weightTemplate = (offer: Game) => {
        return <MlxTooltip target=".offer-weight">
            <div data-pr-tooltip={getWeightLabel(Number(offer.winningCriterias.weightage))} data-pr-position="mouse"
                className="offer-weight flex-grow-1 overflow-ellipsis">
                <span className={`nobr`}>{getWeightLabel(Number(offer.winningCriterias.weightage))}</span>
            </div>
        </MlxTooltip>;
    }

    const itemTemplate = (game: Game) => {
        return (
			<Card className="data-view-card mt-2 d-flex flex-column justify-content-even">
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <p className="m-0">{toPascalCase(game.prizeWinning)}</p>
                    <Badge value={<>{(game as any)?.stockRemainingCount}/{game.stockCount}</>} className="ms-2" />
                </div>
				<div className='mt-2'>
                    <div className="d-flex flex-row align-items-center">
                        <span className="me-2"><JTranslation typeCase="capitalize" text={MIN_VISIT_COUNT} />:</span>
					    <p className="m-0 font-bold">{game.winningCriterias.minVisitCount}</p>
                    </div>
                    {game.winningCriterias.zipCodes && 
                        <div className="d-flex flex-row align-items-center">
                            <span className="me-2"><JTranslation typeCase="capitalize" text={ZIP_CODE} />:</span>
                            <p className="m-0 font-bold">{zipCodeTemplate(game)}</p>
                        </div>
                    }
                    <div className="d-flex flex-row align-items-center">
                        <span className="me-2"><JTranslation typeCase="capitalize" text={FREQUENCY} />:</span>
                        <p className="m-0 font-bold">{frequencyTemplate(game)}</p>
                    </div>
                    <div className="d-flex flex-row align-items-center">
                        <span className="me-2"><JTranslation typeCase="capitalize" text={WEIGHTAGE} />:</span>
                        <p className="m-0 font-bold">{weightTemplate(game)}</p>
                    </div>                    
				</div>
				<div className="view-footer mt-2 pt-3 d-flex flex-row justify-content-between align-items-center">
					<div className='d-flex justify-content-even align-items-center'>
                        {editButtonTemplate(game)}{deleteButtonTemplate(game)}
                    </div>
					<div className='d-flex justify-content-even align-items-center'>{statusButtonTemplate(game)}</div>
				</div>
			</Card>
		)
    }

    return <>
        {width <= breakPoint ? (
            <DataView
                value={offersList}
                layout={'list'}
                itemTemplate={itemTemplate}
                paginator
                rows={10}
                paginatorClassName="p-dataview-customers"
                dataKey="id"
                emptyMessage={translationText.emptyMessage}
            />
            ) : (
                <DataTable value={offersList} paginator className="p-datatable-customers" rows={10}
                    scrollable scrollHeight="flex"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[10, 25, 50]}
                    dataKey="surveySetId" rowHover responsiveLayout="scroll"
                    globalFilterFields={['surveySetName', 'status']} emptyMessage={translationText.emptyMessage}
                    currentPageReportTemplate={translationText.paginationTemplate}
                    selectionMode="single"
                >
                    <Column field="prizeWinning" header={<JTranslation typeCase="pascal" text={OFFER} />} sortable className="word-break-grid" style={{ minWidth: '15rem' }} />
                    <Column field="prizeDescription" header={<JTranslation typeCase="pascal" text={DESCRIPTION} />} sortable style={{ minWidth: '15rem', maxWidth: '15rem' }} body={descTemplate} />
                    <Column field="winningCriterias.minVisitCount" header={<JTranslation typeCase="pascal" text={MIN_VISIT_COUNT} />} sortable className="word-break-grid" style={{ minWidth: '8rem', maxWidth: '8rem' }} />
                    <Column field="winningCriterias.zipCodes" sortable header={<JTranslation typeCase="pascal" text={ZIP_CODE} />} className="word-break-grid" style={{ minWidth: '10rem' }} body={zipCodeTemplate} />
                    <Column field="winningCriterias.locationRadiusInMiles" sortable header={<JTranslation typeCase="pascal" text={RADIUS} />} className="word-break-grid" style={{ maxWidth: '8rem', minWidth: '8rem' }} />
                    <Column field="stockRemainingCount" sortable header={<JTranslation typeCase="pascal" text={AVAILABLE_COUPON} />} className="word-break-grid" style={{ minWidth: '8rem' }} />
                    <Column field="stockCount" sortable header={<JTranslation typeCase="pascal" text={MAX_COUPON} />} className="word-break-grid" style={{ minWidth: '8rem' }} />
                    <Column field="stockRepeatInterval" sortable header={<JTranslation typeCase="pascal" text={FREQUENCY} />} className="word-break-grid" style={{ minWidth: '10rem' }} body={frequencyTemplate} />
                    <Column field="winningCriterias.weightage" header={<JTranslation typeCase="pascal" text={WEIGHTAGE} />} sortable className="word-break-grid" style={{ minWidth: '8rem', maxWidth: '8rem' }} body={weightTemplate} />
                    <Column field="couponCodeMaxDaysValidity" sortable className="word-break-grid" header={<JTranslation typeCase="pascal" text={COUPON_VALIDITY} />} style={{ minWidth: '8rem' }} />
                    <Column field="isActive" header={<JTranslation typeCase="pascal" text={STATUS} />} sortable className="word-break-grid" style={{ minWidth: '10rem', maxWidth: '10rem' }} body={statusButtonTemplate} />
                    <Column field="edit" style={{ maxWidth: '3rem', minWidth: '3rem' }} bodyClassName="bg-sticky-color" body={editButtonTemplate} alignFrozen="right" frozen={true} />
                    <Column field="delete" style={{ maxWidth: '3rem', minWidth: '3rem' }} bodyClassName="bg-sticky-color" body={deleteButtonTemplate} alignFrozen="right" frozen={true} />
                </DataTable >
            )
        }
        
    </>
}

export default OffersListGrid;