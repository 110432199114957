import React, { useCallback, useRef } from 'react'
import { DebounceInput } from 'react-debounce-input'
import { FaSearch, FaTimesCircle } from 'react-icons/fa'
import { SearchProps } from '../../../constants/staticTypes'
import { FILE_MANAGEMENT } from '../../../constants/strings'

const CustomSearch = ({ globalSearchValue, setGlobalSearchValue, setReCallListApi }: SearchProps) => {
    const inputField = useRef(null)

    const onClear = useCallback(() => {
        setGlobalSearchValue('')
        setReCallListApi(true)
    }, [setGlobalSearchValue, setReCallListApi])

    const changeGlobalSearchValue = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value.trimStart()) {
                setGlobalSearchValue(e.target.value)
            } else {
                // @ts-ignore-start
                inputField?.current?.click()
                // @ts-ignore-end
            }
        },
        [setGlobalSearchValue]
    )

    return (
        <>

            <div className="card-header sticky-card-header border-bottom-1 mb-2 custom-ch-card-header">
                <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                        <h4 className="mb-0 title">{FILE_MANAGEMENT}</h4>
                    </div>
                    <div className="flex-grow-0  d-flex justify-content-end ">
                        <div className="col-md-12 custom-search-chonky">
                            <div className="form-group has-search">
                                {globalSearchValue ? (
                                    <span className="fa fa-search form-control-feedback" ref={inputField} onClick={onClear}>
                                        <FaTimesCircle />{' '}
                                    </span>
                                ) : (
                                    <span className="fa fa-search form-control-feedback ">
                                        <FaSearch />{' '}
                                    </span>
                                )}

                                <DebounceInput
                                    className="form-control "
                                    placeholder="Search"
                                    minLength={2}
                                    value={globalSearchValue}
                                    debounceTimeout={500}
                                    onChange={changeGlobalSearchValue}
                                    autoFocus={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomSearch
