import { AxiosResponse } from 'axios'
import { UseMutationResult } from 'react-query'
import { aboutUsConfigApi, updateAboutUsDataApi } from '../constants/apiEndPoints'
import { HttpMethods, AlertVariant } from '../constants/constants'
import { AboutUsConfig, ImagePlacement, Payload, AboutUsConfigFormData } from '../constants/staticTypes'
import { getErrorMessage } from './utils'
import HttpServiceHelper from './httpServiceHelper'

export const initialAboutUsImageData = { key: '', signedUrl: '' }

export const initialAboutUsFormData: AboutUsConfig = {
	imageKey: '',
	signedUrl: '',
	imagePlacement: 'left',
	aboutUsTitle: '',
	aboutUsRichTextData: '',
}

export const updateAboutUsData = (
	updateDateSettingsMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	param: AboutUsConfigFormData,
	onSuccess: (data: AboutUsConfig) => void,
	onError: (res: string, variant: string) => void
) => {
	updateDateSettingsMutation.mutate(
		{
			url: updateAboutUsDataApi,
			method: HttpMethods.PUT,
			data: param,
		},
		{
			onSuccess: (res) => onSuccess(res.data.data),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

export const getAboutUsScreenConfig = () => {
	return HttpServiceHelper({
		url: aboutUsConfigApi,
		method: HttpMethods.GET,
	})
}

export const imagePlacementTemplates: { value: ImagePlacement; name: string }[] = [
	{ value: 'left', name: 'Left' },
	{ value: 'right', name: 'Right' },
	{ value: 'top', name: 'Top' },
]
