import { JTranslation } from '../../helpers/jTranslate'
import inactiveSurvey from '../../assets/images/inactive_survey.svg'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../constants/routes'

const InactiveSurveyCard = () => {
    const navigate = useNavigate()

    return (
        <div className="h-100 inactive-height">
            <img alt="inactive-menu" src={inactiveSurvey} />
            <span className="fs-5 mt-4 mb-3 text-center">
                <JTranslation text="This survey is currently Inactive" />
            </span>
            <button
                className="btn btn-custom-primary-outline d-flex mb-3"
                data-testid="go-to-home-button"
                onClick={() => navigate(routes.menu_view)}
                type="button">
                <i className="ri-home-5-line me-2"></i>
                <span className="btn-text">Go to Menu</span>
            </button>
        </div>
    )
}

export default InactiveSurveyCard
