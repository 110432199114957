import { useQueryClient } from 'react-query';
import '../../assets/styles/scss/main.scss';
import "./styles/userRolesStyles.scss";
import withSidebar from '../../hoc/withSidebar';
import { deleteRole, getItemForNewRole, getUserRoles, updateRole } from '../../helpers/userRolesHelper';
import { useContext, useEffect, useState } from 'react';
import { USER_ROLES } from '../../constants/queryKeys';
import AddCustomRole from '../../components/user_roles/AddCustomRole';
import useMutationHook from '../../hooks/useMutationHook';
import { UserRolesList } from '../../constants/staticTypes';
import WarningModal from '../../components/warning_modal/WarningModal';
import { ADD, CLEAR, CLEAR_CUSTOM_ROLE_WARNING, CLEAR_DEFAULT_ROLE_WARNING, CUSTOM_ROLES, DEFAULT_ROLES, DELETE_SUCCESS, DELETE_WARNING, HELP_CUSTOM_ROLES, HELP_DEFAULT_ROLES, ROLE_ADD_SUCCESS, ROLE_DESC, ROLE_NAME, ROLE_UPDATE_SUCCESS, SAVE, UPDATE_WARNING, USER_ROLES_TITLE } from '../../constants/strings';
import ToastAlert from '../../components/alert/ToastAlert';
import useQueryHook from '../../hooks/useQueryHook';
import { AxiosResponse } from 'axios';
import FeatureList from '../../components/user_roles/FeatureList';
import { AlertVariant, PermissionKeys, SubPermissionKeys } from '../../constants/constants';
import Loader from '../../components/loader/Loader';
import { checkPermission, isItemUnchanged, popover } from '../../helpers/utils';
import { OverlayTrigger } from "react-bootstrap";
import { JTranslation, TranslationContext, jTranslationText } from '../../helpers/jTranslate';
import MlxTooltip from '../../components/common/MlxTooltip';
import MlxPopover from '../../components/common/MlxPopover';

// user operations
enum UserOperations {
    SELECT, ADD_CUSTOM_ROLE, DELETE, CLEAR_FORM
}

function UserRoles() {
    const queryClient = useQueryClient();
    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext
    
    const [rolesList, setRolesList] = useState<UserRolesList[] | []>([]); // Full Roles List
    const [defaultRoles, setDefaultRoles] = useState<UserRolesList[] | []>([]); // Default Roles
    const [customRoles, setCustomRoles] = useState<UserRolesList[] | []>([]); // Custom Roles
    const [newCustomRoleItem, setNewCustomRoleItem] = useState<UserRolesList | undefined>(); // Item for adding new role
    const [selectedRole, selectRole] = useState(""); // Selected role state
    const [selectedItem, selectRoleItem] = useState<UserRolesList>(); // Selected role from full list
    const [nextItem, setNextItem] = useState<UserRolesList>(); // Next selected item
    const [showAddRole, setAddRoleModal] = useState(false); // Add role modal state
    const addRoleMutation = useMutationHook(queryClient, true); // Add role mutation
    const updateRoleMutation = useMutationHook(queryClient, true); // Update role mutation
    const deleteRoleMutation = useMutationHook(queryClient, true); // Delete role mutation
    const [showWarning, setWarning] = useState(false); // Warning popup
    const [warningMessage, setWarningMessage] = useState(""); // Warning message
    const [deleteRoleId, setDeleteRoleId] = useState(""); // Delete role id
    const [showToast, setToast] = useState(false); // Toast alert state
    const [toastMessage, setToastMessage] = useState(""); // Toast message
    const [toastVariant, setToastVariant] = useState(""); // Toast Variant
    const [isUpdateWarning, setUpdateWarning] = useState(false); // Flag to show update warning
    const [userNextOperation, setUserNextOperation] = useState<UserOperations>(); // user next operation
    const [isInitialLoad, setInitialLoad] = useState(true);
    const [translationText, setTranslatedText] = useState<{roleName: string, roleDesc: string}>({roleName: ROLE_NAME, roleDesc: ROLE_DESC});


    // update list
    const updateList = (data: UserRolesList[] | []) => {
        setRolesList(data);

        // sort and set custom roles
        let sortedCustomRoles = data.filter(item => item.isDefault === false && item.isActive === true);
        sortedCustomRoles.sort((a, b) => a.roleName.toLowerCase() > b.roleName.toLowerCase() ? 1 : -1)
        setCustomRoles(sortedCustomRoles);
    }

    // roles list fetch success
    const onSuccess = (res: AxiosResponse<any, any>) => {
        let data: UserRolesList[] | [] = res.data?.data;
        data = data.map(item => {
            if (item.roleDescription === null) {
                item.roleDescription = "";
            }
            return item;
        });
        updateList(data);

        // sort and set default roles
        let sortedDefaultRoles = data.filter(item => item.isDefault === true && item.isActive === true);
        sortedDefaultRoles.sort((a, b) => a.roleName.toLowerCase() > b.roleName.toLowerCase() ? 1 : -1)
        setDefaultRoles(sortedDefaultRoles);

        selectRole(sortedDefaultRoles.filter(item => item.isDefault === true && item.isActive === true)[0]?.id);
        selectRoleItem(sortedDefaultRoles.filter(item => item.isDefault === true && item.isActive === true)[0]);
        setNewCustomRoleItem(getItemForNewRole(data.filter(item => item.isActive === true)[0]));
        setInitialLoad(false);
    }

    // react query to fetch roles
    const userRoles = useQueryHook(USER_ROLES, getUserRoles, onSuccess);

    // user selection change
    const onChangeData = (item: UserRolesList | undefined) => {
        let updatedList = rolesList.map(role => {
            if (role.id === item?.id) {
                role = item;
            }
            return role;
        });
        updateList(updatedList);
    }

    // on role delete click
    const onDeleteClick = () => {
        setWarning(false);
        deleteRole(deleteRoleMutation, deleteRoleId, onDeleteSuccess, onError);
    }

    // on role delete success
    const onDeleteSuccess = (roleId: string) => {
        let newRolesList = rolesList.filter(item => item.id !== roleId);
        updateList(newRolesList);
        if (roleId === selectedRole) {
            let newCustomRoles = newRolesList.filter(item => item.isDefault === false && item.isActive === true);
            if (newCustomRoles.length > 0) {
                selectRole(newCustomRoles[newCustomRoles.length - 1].id);
                selectRoleItem(newCustomRoles[newCustomRoles.length - 1]);
            } else {
                selectRole(defaultRoles[0].id);
                selectRoleItem(defaultRoles[0]);
            }
        }
        displayToast(DELETE_SUCCESS, "success");
    }

    // on add role success
    const onAddRoleSuccess = (item: UserRolesList) => {
        setAddRoleModal(false);
        item = {
            ...item,
            roleDescription: item.roleDescription !== null ? item.roleDescription : ""
        }
        let updatedList = [...rolesList, item];
        updateList(updatedList);
        selectRole(item.id);
        selectRoleItem(item);
        displayToast(ROLE_ADD_SUCCESS, "success");
    }

    // on update role success
    const onUpdateRoleSuccess = () => {
        onChangeData(selectedItem);
        displayToast(ROLE_UPDATE_SUCCESS, "success");
    }

    // on update without saving
    const updateWithoutSave = () => {
        switch (userNextOperation) {
            case UserOperations.ADD_CUSTOM_ROLE:
                if (checkPermission(queryClient, PermissionKeys.MANAGE_ROLES, SubPermissionKeys.ADD)) {
                    setAddRoleModal(true);
                }
                break;

            case UserOperations.DELETE:
                if (checkPermission(queryClient, PermissionKeys.MANAGE_ROLES, SubPermissionKeys.DELETE)) {
                    setUpdateWarning(false);
                    setWarningMessage(DELETE_WARNING);
                }
                break;

            case UserOperations.CLEAR_FORM:
                if (selectedItem && checkPermission(queryClient, PermissionKeys.MANAGE_ROLES, SubPermissionKeys.EDIT)) {
                    const newItem = {
                        ...selectedItem,
                        roleName: selectedItem && selectedItem.isDefault ? selectedItem.roleName : "",
                        roleDescription: ""
                    }
                    selectRoleItem(newItem);
                }
                break;

            default:
                if (nextItem) {
                    selectRole(nextItem.id);
                    selectRoleItem(nextItem);
                }
                break;
        }
        setWarning(userNextOperation === UserOperations.DELETE);
    }

    // on error
    const onError = (errorMessage: string) => displayToast(errorMessage, AlertVariant.ERROR);

    // show toast
    const displayToast = (message: string, variant: string) => {
        setToastVariant(variant);
        setToastMessage(message);
        setToast(true);
    }

    // check and update selection of user role
    const onSelectionUpdate = (item: UserRolesList) => {
        if (itemUnchanged()) {
            selectRole(item.id);
            selectRoleItem(item);
        } else {
            setUserNextOperation(UserOperations.SELECT);
            setNextItem(item);
            showProceedWarning();
        }
    }

    // show continue without saving warning
    const showProceedWarning = (message = UPDATE_WARNING) => {
        setUpdateWarning(true);
        setWarningMessage(message);
        setWarning(true);
    }

    // get item change status
    const itemUnchanged = () => {
        let oldItem = rolesList.filter(role => role.id === selectedRole)[0];
        return isItemUnchanged(oldItem, selectedItem);
    }

    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({
                        text: ROLE_NAME,
                        typeCase: 'pascal',
                        translationContext,
                    }),
                    jTranslationText({
                        text: ROLE_DESC,
                        typeCase: 'pascal',
                        translationContext,
                    }),
                ])
                setTranslatedText({roleName: translations[0]?? ROLE_NAME, roleDesc: translations[1] ?? ROLE_DESC})
            } catch {
                setTranslatedText({roleName: ROLE_NAME, roleDesc: ROLE_DESC})
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    if (userRoles.isLoading || isInitialLoad) {
        return <Loader />
    }

    return (
        <>
            <AddCustomRole data-testid="add-role" show={showAddRole} onHide={setAddRoleModal} item={newCustomRoleItem}
                addRoleMutation={addRoleMutation} onAddRoleSuccess={onAddRoleSuccess} onError={onError} />
            <WarningModal show={showWarning} title={warningMessage} onHide={setWarning}
                callback={isUpdateWarning ? updateWithoutSave : onDeleteClick} />
            <ToastAlert data-testid="toast" show={showToast} onClose={setToast} message={toastMessage} variant={toastVariant} />
            <div className="page-title">
                <h5><JTranslation typeCase="pascal" text={USER_ROLES_TITLE} /></h5>
            </div>

            <div className="col-md-5 col-lg-4  mb-3 h-100 " data-testid="user-roles">
                <div className="card  roles-link h-100">
                    <div className="card-body h-100 over-fl-at mb-3 pt-0">
                        <div className="roles-sticky-header py-3">
                            <div className="d-flex align-items-center mb-2">

                                <div className="flex-grow-1 ">
                                    <h5 className="d-flex align-items-center card-title mb-0">
                                        <JTranslation typeCase="pascal" text={DEFAULT_ROLES} />
                                        <MlxPopover data={{title:DEFAULT_ROLES, body:HELP_DEFAULT_ROLES}}/>
                                    </h5>
                                </div>
                                <div className=" flex-grow-0 ">
                                </div>
                            </div>

                            <ul>
                                {defaultRoles.map(item =>
                                    <li data-testid={item.id} key={item.id} className={item.id === selectedRole ? "active" : ''}
                                        onClick={() => {
                                            onSelectionUpdate(item);
                                        }}>
                                        <JTranslation typeCase="pascal" text={item.roleName} />
                                    </li>)}
                            </ul>
                            <div className="d-flex align-items-center mt-3 ">
                                <div className="flex-grow-1 ">
                                    <h5 className="d-flex align-items-center card-title mb-0"><JTranslation typeCase="pascal" text={CUSTOM_ROLES} />
                                        <MlxPopover data={{title:CUSTOM_ROLES, body:HELP_CUSTOM_ROLES}}/>
                                    </h5>
                                </div>
                                <div className=" flex-grow-0 ">
                                    <button type="button" data-testid="add-role"
                                        disabled={!checkPermission(queryClient, PermissionKeys.MANAGE_ROLES, SubPermissionKeys.ADD)}
                                        className="btn btn-custom-primary-outline" onClick={() => {
                                            if (checkPermission(queryClient, PermissionKeys.MANAGE_ROLES, SubPermissionKeys.ADD)) {
                                                if (itemUnchanged()) {
                                                    setAddRoleModal(true);
                                                } else {
                                                    setUserNextOperation(UserOperations.ADD_CUSTOM_ROLE);
                                                    showProceedWarning();
                                                }
                                            }
                                        }}><JTranslation typeCase="pascal" text={ADD} /></button>
                                </div>
                            </div>

                        </div>

                        <div className="">
                            <ul>
                                {customRoles.map(item => <li className={item.id === selectedRole ? "d-flex active" : 'd-flex'}>
                                    <MlxTooltip target='.flex-grow-1'>
                                        <div data-pr-position="bottom" data-pr-tooltip={item.roleName} data-test-filed={item.id} key={item.id} className={item.id === selectedRole ? "flex-grow-1 overflow-ellipsis active" : 'flex-grow-1 overflow-ellipsis'}
                                            onClick={() => {
                                                onSelectionUpdate(item);
                                            }}>
                                            {item.roleName}
                                        </div>
                                    </MlxTooltip>
                                    <div className="flox-grow-0">
                                        <span className={" " + (!checkPermission(queryClient, PermissionKeys.MANAGE_ROLES, SubPermissionKeys.DELETE) &&
                                            " disabled")} 
                                        >
                                        <MlxTooltip target='.ri-delete-bin-6-line'>
                                            <i data-testid={item.id + "#delete"} data-pr-position="bottom" data-pr-tooltip="Delete"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (checkPermission(queryClient, PermissionKeys.MANAGE_ROLES, SubPermissionKeys.DELETE)) {
                                                        setDeleteRoleId(item.id);
                                                        if (itemUnchanged()) {
                                                            setUpdateWarning(false);
                                                            setWarningMessage(DELETE_WARNING);
                                                            setWarning(true);
                                                        } else {
                                                            setUserNextOperation(UserOperations.DELETE);
                                                            showProceedWarning();
                                                        }
                                                    }
                                                }}
                                                className="ri-delete-bin-6-line"
                                            ></i>
                                        </MlxTooltip>
                                        </span>
                                    </div>
                                </li>)}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-7 col-lg-8  h-100">
                <div className="card  roles-forms  h-100 ">
                    <div className="card-body h-100  ">
                        <div className="h-100 ">
                            <div className=" h-100 p-1 over-fl-at pb-6">
                                {selectedItem &&
                                    <div className="col-md-12"
                                        data-testid={selectedItem.id + "#selected"}>
                                        <div className="mb-3">
                                            <input type="text" className="form-control" id="roleName" 
                                                disabled={selectedItem?.isDefault ||
                                                    !checkPermission(queryClient, PermissionKeys.MANAGE_ROLES, SubPermissionKeys.EDIT)}
                                                data-testid="role-name-input"
                                                placeholder={translationText.roleName}
                                                autoComplete="off"
                                                onChange={(e) => {
                                                    if (selectedItem) {
                                                        const newItem = {
                                                            ...selectedItem,
                                                            roleName: e.target.value
                                                        }
                                                        selectRoleItem(newItem);
                                                    }
                                                }}
                                                value={selectedItem?.roleName} />
                                        </div>
                                        <div className="mb-3">
                                            <textarea className="form-control" id="exampleFormControlTextarea1"
                                                placeholder={translationText.roleDesc}
                                                disabled={!checkPermission(queryClient, PermissionKeys.MANAGE_ROLES, SubPermissionKeys.EDIT)}
                                                data-testid="role-desc-input"
                                                onChange={(e) => {
                                                    if (selectedItem) {
                                                        const newItem = {
                                                            ...selectedItem,
                                                            roleDescription: e.target.value
                                                        }
                                                        selectRoleItem(newItem);
                                                    }
                                                }}
                                                value={selectedItem?.roleDescription !== null ? selectedItem?.roleDescription : ""} rows={4}></textarea>
                                        </div>
                                    </div>}
                                <FeatureList queryClient={queryClient} selectedItem={selectedItem} selectRoleItem={selectRoleItem} />
                            </div>
                            <div className="save-btn-section shadow roles-btn-group">
                                <button className="btn btn-custom-primary-outline" type="button" data-testid="clear-button"
                                    disabled={selectedItem === undefined ||
                                        !checkPermission(queryClient, PermissionKeys.MANAGE_ROLES, SubPermissionKeys.EDIT)}
                                    onClick={() => {
                                        if (checkPermission(queryClient, PermissionKeys.MANAGE_ROLES, SubPermissionKeys.EDIT)) {
                                            setUserNextOperation(UserOperations.CLEAR_FORM);
                                            showProceedWarning(selectedItem?.isDefault ? CLEAR_DEFAULT_ROLE_WARNING : CLEAR_CUSTOM_ROLE_WARNING);
                                        }
                                    }}><JTranslation typeCase="pascal" text={CLEAR} /></button>
                                <button className="btn btn-custom-primary" type="button"
                                    data-testid="save-button"
                                    disabled={selectedItem === undefined || selectedItem?.roleName?.trim().length === 0 || itemUnchanged() ||
                                        !checkPermission(queryClient, PermissionKeys.MANAGE_ROLES, SubPermissionKeys.EDIT)}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (selectedItem && selectedItem.roleName?.trim().length > 0 &&
                                            checkPermission(queryClient, PermissionKeys.MANAGE_ROLES, SubPermissionKeys.EDIT)) {
                                            updateRole(updateRoleMutation, selectedItem,
                                                onUpdateRoleSuccess, onError);
                                        }
                                    }}><JTranslation typeCase="pascal" text={SAVE} /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withSidebar(UserRoles);