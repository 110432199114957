import { createContext, Dispatch, PropsWithChildren, SetStateAction, useState, useMemo } from 'react'

// SETTER TYPE DECLARATIONS
type SetBoolean = Dispatch<SetStateAction<boolean>>

// UPDATE FUNCTION DECLARATIONS
const UpdateBoolean: SetBoolean = () => false

// CONTEXT VARIABLES
export const UserCtx = createContext({
	// user global configuration
	userGlobalConfigSidebar: false,
	setUserGlobalConfigSidebar: UpdateBoolean,
})

export function UsersCtxProvider({ children }: PropsWithChildren) {
	// STATE VARIABLES
	const [userGlobalConfigSidebar, setUserGlobalConfigSidebar] = useState(false)

	// CONTEXT PROVIDER
	return (
		<UserCtx.Provider
			value={useMemo(
				() => ({
					userGlobalConfigSidebar,
					setUserGlobalConfigSidebar,
				}),
				[userGlobalConfigSidebar]
			)}
		>
			{children}
		</UserCtx.Provider>
	)
}
