import { useState, useEffect, useCallback, useMemo } from 'react'
import { ApiFolderAndFileDS, ChonkyRootFolder, ChonkyFolderNode, ChonkyParentAndChild } from '../../constants/staticTypes'
import { getUploadedFolderStructure, searchFileAndFolder, recallUploadedFolderStructure } from '../../helpers/folderUploadHelper'
import { ROOT_FOLDER } from '../../constants/constants'
import { UPLOADED_FILE_LIST } from '../../constants/queryKeys'
import { useQueryClient } from 'react-query'
import FileUploadFolderView from './FileUploadFolderView'
import Loader from '../../components/loader/Loader'
import moment from 'moment'
import useMutationHook from "../../hooks/useMutationHook"
import useQueryHook from '../../hooks/useQueryHook'
import withSidebar from '../../hoc/withSidebar'

const FileManagement = () => {
    // REACT QUERY VARIABLES
    const queryClient = useQueryClient()
    const fileManagerMutation = useMutationHook(queryClient, true)
    // LOCAL VARIABLES
    let rootFolderId = ROOT_FOLDER
    let customFileMap: ChonkyFolderNode = useMemo(() => ({}), [])
    let chonkyRootDS: ChonkyRootFolder = useMemo(
        () => ({
            rootFolderId,
            isDir: true,
            fileMap: {} as ChonkyFolderNode,
        }),
        [rootFolderId]
    )

    let initialValue: ChonkyRootFolder = {
        rootFolderId,
        isDir: true,
        fileMap: {
            ROOT_FOLDER: {
                id: ROOT_FOLDER,
                name: 'Home',
                isDir: true,
                childrenIds: [],
                modDate: '',
                parentId: '',
            },
        },
    }

    // STATE VARIABLES
    const [dataConverted, setDataConverted] = useState(false)
    const [fileManagerList, setFileManagerList] = useState<ApiFolderAndFileDS[]>([])
    const [globalSearchValue, setGlobalSearchValue] = useState<string>('')
    const [masterFsMap, setMasterFsMap] = useState<ChonkyRootFolder>(initialValue)
    const [reCallListApi, setReCallListApi] = useState(false)

    // on api error
    const onError = (message: string, variant: string) => {
        alert('error occurred')
    }

    // on api success
    const onSuccess = (message: string, data: ApiFolderAndFileDS[]) => {
        if (data.length) {
            setDataConverted(false)
            const newData: ApiFolderAndFileDS[] = data?.map(item => {
                item.files = item.files?.map(file => {
                    file.dbId = file.id;
                    file.id = file.uuid || "no_uuid";
                    return file;
                });
                if (!item.isDir) {
                    item.dbId = item.id;
                    item.id = item.uuid || "no_uuid";
                }
                return item;
            });
            setFileManagerList(newData)
        } else {
            setDataConverted(false)
            setMasterFsMap(initialValue)
            setDataConverted(true)
        }
    }

    // on api success
    const onRecallSuccess = (message: string, data: ApiFolderAndFileDS[]) => {
        setDataConverted(false)
        const newData: ApiFolderAndFileDS[] = data?.map(item => {
            item.files = item.files?.map(file => {
                file.dbId = file.id;
                file.id = file.uuid || "no_uuid"
                return file;
            });
            if (!item.isDir) {
                item.dbId = item.id;
                item.id = item.uuid || "no_uuid";
            }
            return item;
        });
        setFileManagerList(newData)
    }

    // API CALL TO GET THE LIST OF UPLOAD FILES AND FOLDER STRUCTURE
    const { status, isRefetching } = useQueryHook(
        UPLOADED_FILE_LIST,
        () => getUploadedFolderStructure(queryClient),
        (res) => {
            const newData: ApiFolderAndFileDS[] = res.data.data?.map((item: ApiFolderAndFileDS) => {
                item.files = item.files?.map((file: ApiFolderAndFileDS) => {
                    file.dbId = file.id;
                    file.id = file.uuid || "no_uuid"
                    return file;
                });
                if (!item.isDir) {
                    item.dbId = item.id;
                    item.id = item.uuid || "no_uuid";
                }
                return item;
            });

            setFileManagerList(newData)
        }
    )

    const addChildIdToParent = useCallback(
        ({ parentFolderId, childFolderId }: ChonkyParentAndChild) => {
            if (Object.isExtensible(customFileMap[parentFolderId]?.childrenIds)) {
                customFileMap[parentFolderId]?.childrenIds.push(childFolderId)
            }
        },
        [customFileMap]
    )

    const addFolderAndFiles = useCallback(
        (customFileMap: ChonkyFolderNode, item: ApiFolderAndFileDS) => {
            customFileMap[item.id] = {
                ...item,
                modDate: moment(item.updatedAt).toISOString() as unknown as string,
                childrenIds: [],
                parentId: item.parentFolderId ? item.parentFolderId : rootFolderId,
            }
        },
        [rootFolderId]
    )

    // creates initial data structure for chonky
    const createDataStructure = useCallback(() => {
        fileManagerList.forEach((item, index) => {
            if (index === 0) {
                customFileMap[rootFolderId] = {
                    id: rootFolderId,
                    name: 'Home',
                    isDir: true,
                    childrenIds: [],
                    modDate: '',
                    parentId: '',
                }
            }

            // adding folders to DS
            addFolderAndFiles(customFileMap, item)
        })

        fileManagerList.forEach((item, index) => {
            if (item.parentFolderId) {
                addChildIdToParent({
                    parentFolderId: item.parentFolderId,
                    childFolderId: item.id,
                })
            } else {
                addChildIdToParent({
                    parentFolderId: rootFolderId,
                    childFolderId: item.id,
                })
            }

            if (item.files?.length) {
                item.files.forEach((file) => {
                    if (file.parentFolderId && file.isDir === false) {
                        // adding files to DS
                        addFolderAndFiles(customFileMap, file)
                        // add files ids to folder as child
                        addChildIdToParent({
                            parentFolderId: file.parentFolderId,
                            childFolderId: file.id,
                        })
                    }
                })
            }
        })

    }, [addChildIdToParent, addFolderAndFiles, customFileMap, fileManagerList, rootFolderId])

    // creates data structure for chonky from file / folder search result
    const createSearchDataStructure = useCallback(() => {
        fileManagerList.forEach((item, index) => {
            if (index === 0) {
                customFileMap[rootFolderId] = {
                    id: rootFolderId,
                    name: 'Search Result',
                    isDir: true,
                    childrenIds: [],
                    modDate: '',
                    parentId: '',
                }
            }
            // add files ids to folder as child
            addChildIdToParent({
                parentFolderId: rootFolderId,
                childFolderId: item.id,
            })

            // adding files to DS
            addFolderAndFiles(customFileMap, item)
        })
    }, [addChildIdToParent, addFolderAndFiles, customFileMap, rootFolderId, fileManagerList])

    // EFFECT TO CONVERT API DATA STRUCTURE TO CHONKY'S DATA STRUCTURE
    useEffect(() => {
        if (fileManagerList.length && status === 'success') {
            // turns the spinner on
            setDataConverted(false)
            // creates the data structure for chonky file browser
            globalSearchValue ? createSearchDataStructure() : createDataStructure()
            // populating data in a local variable
            chonkyRootDS.fileMap = customFileMap
            // setting custom data structure to state variable for rendering
            setMasterFsMap(chonkyRootDS)
            // turns the spinner off
            setDataConverted(true)
        }

        // CONDITION TO HANDLE EMPTY ARRAY
        if (status === 'success' && fileManagerList.length === 0) {
            setDataConverted(true)
        }

        // CONDITION TO HANDLE DATA REFETCHING
        if (isRefetching) {
            setDataConverted(false)
        }
    }, [fileManagerList, status, isRefetching, chonkyRootDS, createDataStructure, createSearchDataStructure, customFileMap, globalSearchValue])

    // RECALLS THE API TO LOAD FOLDER STRUCTURE DATA ONCE THE SEARCH IS CLEARED
    const reloadFolderStructureApi = () => {
        if (reCallListApi) {
            recallUploadedFolderStructure(fileManagerMutation, onRecallSuccess, onError)
        }
    }

    // EFFECT TO CALL THE ADVANCE SEARCH API
    useEffect(() => {
        if (globalSearchValue) {
            searchFileAndFolder(
                fileManagerMutation,
                {
                    searchTerm: globalSearchValue,
                },
                onSuccess,
                onError
            )
        } else {
            reloadFolderStructureApi()
        }

        // clean up 
        return () => {
            setMasterFsMap(initialValue)
            setReCallListApi(false)
        }

    }, [globalSearchValue]) // eslint-disable-line

    if (dataConverted) {
        return (
            <FileUploadFolderView
                masterFsMap={masterFsMap}
                globalSearchValue={globalSearchValue}
                setGlobalSearchValue={setGlobalSearchValue}
                setReCallListApi={setReCallListApi}
            />
        )
    } else {
        return <Loader />
    }
}

export default withSidebar(FileManagement)
