import { AlertVariant, FileType, HttpMethods } from "../constants/constants";
import { AxiosResponse } from 'axios';
import { Crop, PixelCrop } from "react-image-crop";
import { fileUploadApi, getUploadUrlApi, getFileUploadUrlApi, saveFileUploadToDbApi, imageDataFetchPublicApi } from "../constants/apiEndPoints";
import { Payload } from "../constants/staticTypes";
import { UNKNOWN_ERROR, UPLOAD_PHOTO_TYPE_ERROR, UPLOAD_VIDEO_TYPE_ERROR } from "../constants/strings";
import { UseMutationResult, QueryClient } from "react-query";
import HttpServiceHelper from "./httpServiceHelper";
import heic2any from "heic2any";
import { ChangeEvent } from "react";

// file upload api call
export const uploadFileService = (
    file: File,
    uploadMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onUploadSuccess: (key: string, fileUrl: string, fileType: FileType) => void,
    onUploadError: (fileType: FileType) => void,
    fileType: FileType
) => {
    let formData = new FormData();
    formData.append('selectedFile', file);
    uploadMutation.mutate({
        url: fileUploadApi,
        method: HttpMethods.POST,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data: formData
    },
        {
            onSuccess: (res) => onUploadSuccess(res.data.data.fileData.key, URL.createObjectURL(file), fileType),
            onError: () => onUploadError(fileType)
        })
}

// file upload - signed url
export const uploadFileUsingSignedUrl = (
    url: string,
    key: string,
    file: File,
    uploadMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onUploadSuccess: (key: string, fileUrl: string, fileType: FileType, file: File) => void,
    onUploadError: (fileType: FileType, res: any) => void,
    fileType: FileType,
    fields: { [key: string]: string }) => {
    let formData = new FormData();
    Object.keys(fields).forEach(field => {
        formData.append(field, fields[field]);
    });
    formData.append('file', file);
    uploadMutation.mutate({
        url,
        method: HttpMethods.POST,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data: formData,
        isSignedUrl: true,
        noAuth: true
    },
        {
            onSuccess: (res) => onUploadSuccess(key, URL.createObjectURL(file), fileType, file),
            onError: (res) => onUploadError(fileType, res),
        })
}

// Trigger File Upload
export const uploadFile = (
    fileType: FileType,
    e: React.ChangeEvent<HTMLInputElement>,
    mutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onSuccess: (key: string, fileUrl: string, fileType: FileType) => void,
    onError: (fileType: FileType) => void,
    showToast: (message: string, variant: string) => void,
    setImageCrop?: React.Dispatch<React.SetStateAction<string | ArrayBuffer | null>>) => {
    if (e.target.files && e.target.files?.length > 0) {
        if (e.target.files[0].type.indexOf(fileType) !== -1) {
            if (fileType === FileType.IMAGE && setImageCrop) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    if (e.target !== null) {
                        setImageCrop(e.target.result);
                    }
                }
                reader.readAsDataURL(e.target.files[0]);
            } else {
                uploadFileService(e.target.files[0], mutation, onSuccess, onError, fileType);
            }
            e.target.value = "";
        } else {
            let errorMessage = fileType === FileType.IMAGE ? UPLOAD_PHOTO_TYPE_ERROR : UPLOAD_VIDEO_TYPE_ERROR;
            showToast(errorMessage, AlertVariant.ERROR)
        }
    }
}


// Define the Crop interface 
export const getCroppedImage = (sourceImage: HTMLImageElement, cropConfig: Crop): Promise<Blob | null> => {
    // Promise wrapper for async operations
    return new Promise((resolve, reject) => {
        // Validate crop dimensions
        if (cropConfig.width <= 0 || cropConfig.height <= 0) {
            reject(new Error("Invalid crop dimensions."));
            return;
        }

        // Create a canvas element
        const canvas = document.createElement("canvas");

        // Calculate scale factors
        const scaleX = sourceImage.naturalWidth / sourceImage.width;
        const scaleY = sourceImage.naturalHeight / sourceImage.height;

        // Set canvas dimensions
        canvas.width = Math.ceil(cropConfig.width * scaleX);
        canvas.height = Math.ceil(cropConfig.height * scaleY);

        // Get canvas context
        const ctx = canvas.getContext("2d");

        // Check if context is null
        if (ctx === null) {
            reject(new Error("Failed to get canvas context."));
            return;
        }

        // Draw the cropped image onto the canvas
        ctx.drawImage(
            sourceImage,
            cropConfig.x * scaleX,
            cropConfig.y * scaleY,
            cropConfig.width * scaleX,
            cropConfig.height * scaleY,
            0,
            0,
            cropConfig.width * scaleX,
            cropConfig.height * scaleY
        );

        // by default set image type as image/webp and png for ios devices
        let imageType = "image/png"
        if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
            imageType = "image/png"
        }

        // Generate a blob from the canvas content
        canvas.toBlob((blob) => {
            // Check if blob generation was successful
            if (!blob) {
                reject(new Error(UNKNOWN_ERROR));
            } else {
                resolve(blob);
            }
        }, imageType);
    });
};

export const getImageDataURL = (queryClient: QueryClient, url: string) => {
    return HttpServiceHelper({
        url: `${imageDataFetchPublicApi}?url=${encodeURIComponent(url)}`,
        method: HttpMethods.GET,
        queryClient
    });
};

// get video upload url
export const getVideoSignedUrl = (
    file: File,
    mutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onSuccess: (key: string, fileType: FileType, url: string, file: File, fields: { [key: string]: string }) => void,
    onUploadError: (fileType: FileType) => void) => {
    mutation.mutate({
        url: getUploadUrlApi,
        method: HttpMethods.POST,
        data: {
            fileName: file.name,
            fileSize: file.size
        }
    },
        {
            onSuccess: (res) => onSuccess(res.data.data.fileKey, FileType.VIDEO, res.data.data.url, file, res.data.data.fields),
            onError: () => onUploadError(FileType.VIDEO)
        })
}

// get file upload url
export const getFileSignedUrl = (file: any) => {
    return HttpServiceHelper({
        url: getFileUploadUrlApi,
        method: HttpMethods.POST,
        noAuth: false,
        data: {
            fileName: file.name,
            fileSize: file.size
        }
    });
}

// save uploaded file details to DB 
export const saveFileToDBHelper = (data: any) => {
    return HttpServiceHelper({
        url: saveFileUploadToDbApi,
        method: HttpMethods.POST,
        noAuth: false,
        data
    });
}

// upload file to S3 bucket
export const uploadToSignedUrl = (file: File, signedUrl: string, fields: any) => {
    let formData = new FormData();
    Object.keys(fields).forEach(field => {
        formData.append(field, fields[field]);
    });
    formData.append('file', file);
    return HttpServiceHelper({
        url: signedUrl,
        method: HttpMethods.POST,
        noAuth: true,
        isSignedUrl: true,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data: formData,
        isFile: true,
    },);
}

// function to create a blob from a canvas
export const getCroppedImageByCanvas = async (
    previewCanvas: HTMLCanvasElement,
    completedCrop: PixelCrop
): Promise<Blob | null> => {
    return new Promise((resolve, reject) => {
        // Validate crop dimensions
        if (completedCrop.width <= 0 || completedCrop.height <= 0) {
            reject(new Error("Invalid crop dimensions."));
            return;
        }
        // Create a canvas element
        const canvas = document.createElement("canvas");
        // Set canvas dimensions
        canvas.width = previewCanvas.width;
        canvas.height = previewCanvas.height;
        // Get canvas context
        const ctx = canvas.getContext("2d");
        // Check if context is null
        if (ctx === null) {
            reject(new Error("Failed to get canvas context."));
            return;
        }
        // Draw the cropped image onto the canvas
        ctx.drawImage(
            previewCanvas,
            0,
            0,
            previewCanvas.width,
            previewCanvas.height,
            0,
            0,
            previewCanvas.width,
            previewCanvas.height
        );

        // by default set image type as image/webp and png for ios devices
        let imageType = "image/webp"
        // if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
        // 	imageType = "image/png"
        // }

        // Generate a blob from the canvas content
        canvas.toBlob((blob) => {
            // Check if blob generation was successful
            if (!blob) {
                reject(new Error(UNKNOWN_ERROR));
            } else {
                // size of the data within the blob remains the same; only the metadata associated with the blob const changes.
                const webpBlob = new Blob([blob], { type: "image/png" });
                resolve(webpBlob);
            }
        }, imageType);
    });
}

export const checkIfHeicHeif = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (!file) {
        return event;
    }

    const fileName = file.name ?? '';
    const fileExtension = fileName.split('.').pop()?.toLowerCase() ?? '';
    if (fileExtension === 'heic' || fileExtension === 'heif') {
        const blob = await heic2any({
            blob: file,
            toType: 'image/jpeg',
        });
        const updatedBlob = Array.isArray(blob) ? blob[0] : blob;

        const pngFile = new File(
            [updatedBlob], file.name.replace(/\.[^/.]+$/, ".png"),
            { type: 'image/png' }
        );

        // Create a new DataTransfer object and add the PNG file to it
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(pngFile);

        // Update the file input with the new DataTransfer object
        event.target.files = dataTransfer.files;
        return event;
    } else {
        return event;
    }
}