import { useEffect, useState, useContext } from 'react'
import {
    CLICK_TO_OPEN,
    Date_N_Time,
    FILE_NAME,
    INVOICE_NUMBER,
    ORDER,
    TOTAL,
    UPLOADED_BY,
    VENDOR
} from '../../constants/strings'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import {
    ExtractedFileData,
    FileTypeVerificationTabs,
    InvoiceDataList,
    ListAllInvoiceApiResponse,
    Search,
    InvoiceFilter,
} from '../../constants/staticTypes'
import { FileManagementCtx } from '../../context/FileManagementCtxProvider'
import { getAllInvoiceFiles } from '../../helpers/invoiceListHelper'
import { DATE_TIME_FORMAT } from '../../constants/constants'
import { useQueryClient } from 'react-query'
import CommonModal from '../../components/common_modal/CommonModal'
import DocumentViewer from '../../components/doc_viewer/DocumentViewer'
import InvoiceListActions from './InvoiceListActions'
import moment from 'moment'
import ToastAlert from '../../components/alert/ToastAlert'
import useMutationHook from '../../hooks/useMutationHook'
import { JTranslation, TranslationContext, jTranslationText } from '../../helpers/jTranslate'

const InvoiceListDataTable = ({ searchValue, basicRows, pageNumber, setTotal, storeCurrentTabName }: Search) => {
    const queryClient = useQueryClient()
    const listAllInvoicesMutation = useMutationHook(queryClient, true)
    
    // CONTEXT VARIABLES
    const { refetchAPI, setRefetchAPI, invoiceFileList, setInvoiceFileList, filterValue } =
        useContext(FileManagementCtx)
    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext

    // STATE VARIABLE
    const [showDocViewer, setShowDocViewer] = useState(false)
    const [fileNameToBeViewed, setFileNameToBeViewed] = useState<ExtractedFileData>()
    const [translationText, setTranslatedText] = useState<{
        openDoc: string,
    }>({ 
        openDoc: CLICK_TO_OPEN,
    });

    // ALERT VARIABLES
    const [alertVariant, setVariant] = useState('')
    const [toastMessage, setToastMessage] = useState('')
    const [showAlert, setShowAlert] = useState(false)

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: CLICK_TO_OPEN, typeCase: 'pascal', translationContext }),
                ])
                
                setTranslatedText({
                    openDoc: translations[0] ?? CLICK_TO_OPEN,
                })
            } catch {
                setTranslatedText({
                    openDoc: CLICK_TO_OPEN,
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    // get table filter values
    const checkColumnDisplayProperty = (filterValue: InvoiceFilter[], columnName: string) => {
        let result
        filterValue.forEach((item) => {
            if (item.title === columnName) {
                result = item.display
            }
        })
        return result
    }

    // closes the document viewer modal
    const closeDocViewer = () => setShowDocViewer(false)

    /* MODAL FOR SHOWING THE DOCUMENT VIEWER */
    const tableDocumentViewer = (doc: ExtractedFileData) => {
        return (
            <>
                <span
                    className="email-text"
                    title={translationText.openDoc}
                    data-testid={"file-name#" + doc.id}
                    onClick={() => {
                        setFileNameToBeViewed(doc)
                        setShowDocViewer(true)
                    }}
                >
                    {doc.file.name}
                </span>
            </>
        )
    }

    const dateAndTime = (rowData: InvoiceDataList) => moment(rowData.createdAt).format(DATE_TIME_FORMAT)

    const onGetFilesListSuccess = (response: ListAllInvoiceApiResponse) => {
        setTotal(response.total)
        setInvoiceFileList(response.item_list)
    }

    const onError = (errorMessage: string, variant: string) => {
        setToastMessage(errorMessage)
        setVariant(variant)
    }

    // effect to refetch list api bring updated data on screen
    useEffect(() => {
        setRefetchAPI(true)
        storeCurrentTabName('invoices' as FileTypeVerificationTabs)
    }, [setRefetchAPI]) // eslint-disable-line

    // effect to set the refetch variable back to false
    useEffect(() => {
        if (refetchAPI)
            setInterval(() => {
                setRefetchAPI(false)
            }, 1000)
    }, [refetchAPI, setRefetchAPI])

    // effect to make api call on page load
    useEffect(() => {
        // api call to get all extracted files for user confirmation
        if (refetchAPI) {
            let payload = `?page=${pageNumber}&perPageSize=${basicRows}&searchValue=${searchValue}`
            getAllInvoiceFiles(listAllInvoicesMutation, payload, onGetFilesListSuccess, onError)
        }
    }, [refetchAPI, basicRows, pageNumber, searchValue]) // eslint-disable-line

    return (
        <>
            <div className="h-100">
                {/* TOAST MESSAGE COMPONENT */}
                <ToastAlert show={showAlert} onClose={setShowAlert} message={toastMessage} variant={alertVariant} />
                {/* MODAL FOR SHOWING THE DOCUMENT VIEWER  */}
                <CommonModal
                    callback={closeDocViewer}
                    hideProceed
                    modalContent={<DocumentViewer docs={[{ uri: fileNameToBeViewed?.signedUrl! }]} />}
                    modalSize="xl"
                    onHide={setShowDocViewer}
                    show={showDocViewer}
                    title={fileNameToBeViewed?.file.name!}
                    modalBodyCss={
                        fileNameToBeViewed?.file.name?.includes('xls')
                            ? 'doc-viewer-body-for-excel-files'
                            : 'doc-viewer-body'
                    }
                />
                {/* DATA TABLE */}
                <DataTable value={invoiceFileList} responsiveLayout="scroll" scrollable scrollHeight="flex">
                    <Column
                        field="file.name"
                        header={<JTranslation typeCase="pascal" text={FILE_NAME} />}
                        style={{ minWidth: '15rem' }}
                        body={tableDocumentViewer}
                    />
                    {checkColumnDisplayProperty(filterValue, UPLOADED_BY) && (
                        <Column
                            field="file.uploadedUserName"
                            header={<JTranslation typeCase="pascal" text={UPLOADED_BY} />}
                            style={{ minWidth: '10rem', maxWidth: '10rem' }}
                        />
                    )}
                    {checkColumnDisplayProperty(filterValue, Date_N_Time) && (
                        <Column
                            field="createdAt"
                            header={<JTranslation typeCase="pascal" text={Date_N_Time} />}
                            body={dateAndTime}
                            style={{ minWidth: '12rem', maxWidth: '12rem' }}
                        />
                    )}
                    {checkColumnDisplayProperty(filterValue, VENDOR) && (
                        <Column field="vendorName" header={<JTranslation typeCase="pascal" text={VENDOR} />} style={{ minWidth: '13rem', maxWidth: '13rem' }} />
                    )}
                    {checkColumnDisplayProperty(filterValue, INVOICE_NUMBER) && (
                        <Column
                            field="invoiceNumber"
                            header={<JTranslation typeCase="pascal" text={INVOICE_NUMBER} />}
                            style={{ minWidth: '10rem', maxWidth: '10rem' }}
                        />
                    )}
                    {checkColumnDisplayProperty(filterValue, ORDER) && (
                        <Column field="orderNumber" header={<JTranslation typeCase="pascal" text={ORDER} />} style={{ minWidth: '10rem', maxWidth: '10rem' }} />
                    )}
                    {checkColumnDisplayProperty(filterValue, TOTAL) && (
                        <Column field="total" header={<JTranslation typeCase="pascal" text={TOTAL} />} style={{ minWidth: '10rem', maxWidth: '10rem' }} />
                    )}
                    <Column
                        body={InvoiceListActions}
                        bodyClassName="bg-sticky-color"
                        className="justify-content-start"
                        alignFrozen="right"
                        style={{ minWidth: '8rem', maxWidth: '8rem' }}
                        frozen={true}
                    />
                </DataTable>
            </div>
        </>
    )
}

export default InvoiceListDataTable
