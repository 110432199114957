import { Card } from "primereact/card";
import { RadioButton } from "primereact/radiobutton";
import { JTranslation } from "../../../helpers/jTranslate/JTranslation";
import { BEVERAGE, FOOD } from "../../../constants/strings";
import { Dispatch, SetStateAction } from "react";

type Props = {
    addType: "food" | "beverage" | null
    setFormType: Dispatch<SetStateAction<boolean>>
    setAddType: Dispatch<SetStateAction<"food" | "beverage" | null>>
}

const ChooseFoodType = ({ addType, setAddType, setFormType }: Props) => {
    return (
        <div>
            <Card className="data-view-card choose-food-type">
                <div className="flex align-items-center">
                    <RadioButton
                        inputId="type-food"
                        name="food"
                        value="food"
                        onChange={(e) => {
                            setAddType(e.value);
                            setFormType(true);
                        }}
                        checked={addType === 'food'}
                    />
                    <label htmlFor="ingredient1" className="margin"> <JTranslation typeCase="pascal" text={FOOD} /></label>
                </div>
            </Card>
            <Card className="data-view-card choose-food-type">
                <div className="flex align-items-center">
                    <RadioButton
                        inputId="type-food"
                        name="beverage"
                        value="beverage"
                        onChange={(e) => {
                            setAddType(e.value);
                            setFormType(false);
                        }}
                        checked={addType === 'beverage'}
                    />
                    <label htmlFor="ingredient1" className="margin"> <JTranslation typeCase="pascal" text={BEVERAGE} /></label>
                </div>
            </Card>
        </div>
    )
}

export default ChooseFoodType