import { AlertVariant, HttpMethods } from '../constants/constants'
import { AxiosResponse } from 'axios'
import { getErrorMessage } from './utils'
import { Payload, DownloadZipApiResponse, DownloadZip, DeleteZip } from '../constants/staticTypes'
import { UseMutationResult } from 'react-query'
import { fileManagementDownloadAsZip, fileManagementDeleteMultiple } from '../constants/apiEndPoints'

// download multi file and folder as zip
export const downloadMultipleFilesAndFolders = (
    fileManagerMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    data: DownloadZip,
    onSuccess: (message: string, data: DownloadZipApiResponse) => void,
    onError: (res: string, variant: string) => void
) => {
    fileManagerMutation.mutate(
        {
            url: fileManagementDownloadAsZip,
            method: HttpMethods.POST,
            data: data,
        },
        {
            onSuccess: (res) => onSuccess(res.data.message, res.data.data),
            onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
        }
    )
}

// download multi file and folder as zip
export const deleteMultipleFilesAndFolders = (
    fileManagerMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    data: DeleteZip,
    onSuccess: (message: string, data: DownloadZipApiResponse) => void,
    onError: (res: string, variant: string) => void
) => {
    fileManagerMutation.mutate(
        {
            url: fileManagementDeleteMultiple,
            method: HttpMethods.DELETE,
            data: data,
        },
        {
            onSuccess: (res) => onSuccess(res.data.message, res.data.data),
            onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
        }
    )
}
