import axios, { AxiosResponse } from "axios";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useContext, useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { QueryClient, UseQueryResult } from "react-query";
import { useNavigate } from "react-router-dom";
import { AlertVariant, MOBILE_VIEW_BREAKPOINT, PermissionKeys, SubPermissionKeys } from "../../../constants/constants";
import { QUESTION_SET, TENANT_INFO } from "../../../constants/queryKeys";
import { routes } from "../../../constants/routes";
import { BrandingResponseData, PostType, Survey } from "../../../constants/staticTypes";
import { ACTIVATE_SURVEY_SET, ACTIVE, CREATED_DATE, DEFAULT, DEFAULT_QUESTION, DELETE, DELETE_WARNING, EDIT, NO_QUESTION_SET, QUESTION_SET_TEXT, STATUS } from "../../../constants/strings";
import { activeInactiveQuestionSet, cloneQuestionSet, deleteSurveySet, getSortedSurveySet, makeQuestionSetDefault } from "../../../helpers/surveyManagementHelper";
import { checkPermission, convertUTCtoLocalByOffset, toPascalCase } from "../../../helpers/utils";
import useMutationHook from "../../../hooks/useMutationHook";
import WarningModal from "../../warning_modal/WarningModal";
import html2canvas from "html2canvas";
import saveAs from "file-saver";
import qrcode from "qrcode-generator";
import SharePopup from "../../../pages/news_&_events/SharePopup";
import { JTranslation, TranslationContext, jTranslationText } from "../../../helpers/jTranslate";
import { DataView } from "primereact/dataview";
import { useWindowSize } from "react-use";
import { Card } from "primereact/card";
import MlxTooltip from "../../common/MlxTooltip";

type Props = {
    questionSetList: Survey[],
    setQuestionSetList: React.Dispatch<React.SetStateAction<Survey[]>>,
    displayToast: (message: string, variant: string) => void,
    queryClient: QueryClient,
    setActualQuestionSet: React.Dispatch<React.SetStateAction<Survey[]>>,
    actualQuestionList: Survey[],
    questionSetData: UseQueryResult<AxiosResponse<any, any>, unknown>
}

enum WarningType {
    DELETE = "delete",
    ACTIVE_INACTIVE = "active-inactive",
    DEFAULT = "default",
}

function SurveyQuestionSetGrid({ questionSetList, setQuestionSetList, displayToast, queryClient,
    setActualQuestionSet, actualQuestionList, questionSetData }: Props) {

    const activeInactiveMutation = useMutationHook(queryClient, true); // active inactive mutation
    const deleteQuestionSetMutation = useMutationHook(queryClient, true); // delete question set client mutation
    const cloneQuestionSetMutation = useMutationHook(queryClient, true); // clone question set client mutation
    const [showWarning, setWarning] = useState(false);
    const [deleteItemId, setDeleteItem] = useState("");
    const [activateItem, setActivateItem] = useState<Survey | null>(null);
    const [defaultItem, setDefaultItem] = useState<Survey | null>(null);
    const [prevSet, setPreviousQuestionSet] = useState<Survey[]>([]);
    const [warningType, setWarningType] = useState("");
    const [mobileLogUrl, setMobileLogoUrl] = useState({ url: '', imageKey: '' })
    const [imageSrc, setImageSrc] = useState<string>('');
    const [displayPosition, setDisplayPosition] = useState(false);
    const [surveyInfo, setSurveyInfo] = useState({ title: '', urlSlug: '', postType: PostType.SURVEY });
    const navigate = useNavigate();
    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext
    const { width, height } = useWindowSize()
	const breakPoint = MOBILE_VIEW_BREAKPOINT

	const [translationText, setTranslatedText] = useState<{
        edit: string,
        delete: string,
        clone: string,
        downloadQr: string,
        share: string,
        paginationTemplate: string,
        emptyMessage: string,
      }>({ 
        edit: EDIT,
        delete: DELETE,
        clone: 'Clone',
        downloadQr: 'Download QR Code',
        share: 'Share',
        paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
        emptyMessage: NO_QUESTION_SET
    });

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: EDIT, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: DELETE, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: 'Clone', typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: 'Download QR Code', typeCase: 'none', translationContext }),
                    jTranslationText({ text: 'Share', typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: 'Showing 1 to 2 of 3 items', typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: NO_QUESTION_SET, typeCase: 'pascal', translationContext }),
                ])
                
                setTranslatedText({
					edit: translations[0] ?? EDIT,
					delete: translations[1] ?? DELETE,
					clone: translations[2] ?? 'Clone',
                    downloadQr: translations[3] ?? 'Download QR Code',
                    share: translations[4] ?? 'Share',
					paginationTemplate:
						translations[5]
							?.replace('1', '{first}')
							?.replace('2', '{last}')
							?.replace('3', '{totalRecords}') ?? 'Showing {first} to {last} of {totalRecords} items',
                    emptyMessage: translations[6] ?? NO_QUESTION_SET,
				})
            } catch {
                setTranslatedText({
                    edit: EDIT,
                    delete: DELETE,
                    clone: 'Clone',
                    downloadQr: 'Download QR Code',
                    share: 'Share',
                    paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
                    emptyMessage: NO_QUESTION_SET
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    useEffect(() => {
        const fetchImage = async () => {
            if (!mobileLogUrl.url || imageSrc) return
            try {
                // Fetch the image as a Blob
                const response = await axios.get(mobileLogUrl.url, {
                    responseType: 'blob',
                });

                // Create a local URL for the blob
                const localUrl = URL.createObjectURL(response.data);

                // Update state with the local URL
                setImageSrc(localUrl);
            } catch (error) {
                console.error('Error fetching image:', error);
            }
        };

        fetchImage();
    }, [mobileLogUrl.url, imageSrc]);

    useEffect(() => {
        const res = queryClient.getQueryData(TENANT_INFO) as AxiosResponse<any, any>
        let data = res?.data?.data as BrandingResponseData
        const { branding } = data
        setMobileLogoUrl({ imageKey: '', url: branding?.logos?.small })
    }, [queryClient])

    // generate qr image
    const generateQR = async (survey: Survey) => {
        let qrDiv;
        const canvas = await html2canvas((document.getElementById("qr-code") as HTMLElement), {
            onclone: (doc) => {
                qrDiv = doc.getElementById("qr-code") as HTMLElement;
                qrDiv.style.display = "inline-block";

                // add qr code
                const qrText = `${window.location.protocol}//${window.location.hostname}${routes.survey_guest}/${survey.urlSlug}?qr_scan=true`;
                let qr = qrcode(0, 'L');
                qr.addData(qrText);
                qr.make();
                // (doc.getElementById("qr-top-label") as HTMLElement).innerHTML = capitalizeFirstLetter(TENANT);
                (doc.getElementById("qr-img") as HTMLElement).innerHTML = qr.createSvgTag(10);
                (doc.getElementById("qr-name") as HTMLElement).innerHTML = survey.surveySetName;
            }
        });
        let image = canvas.toDataURL("image/png", 1.0);
        return image;
    }

    // on success - active / inactive and delete
    const onResponse = (id: string, message: string, variant: string) => {
        displayToast(message, variant); // display toast
        setActivateItem(null)
        // update item
        const updatedItem = questionSetList.filter(item => item.id === id)[0];
        let index = questionSetList.indexOf(updatedItem);
        if (variant === AlertVariant.ERROR) {
            setQuestionSetList([...prevSet]);

            // update value in unmodified list
            const newActualList = actualQuestionList.map(item => {
                let prevItem = prevSet.filter(prev => prev.id === item.id)[0];
                if (prevItem) {
                    return prevItem;
                }
                return item;
            });
            setActualQuestionSet([...newActualList]);
        } else if (variant === AlertVariant.SUCCESS && deleteItemId === id) {
            questionSetList.splice(index, 1);
            setQuestionSetList([...questionSetList]);

            // update value in unmodified list
            let actualIndex = actualQuestionList.indexOf(updatedItem);
            actualQuestionList.splice(actualIndex, 1);
            setActualQuestionSet([...actualQuestionList]);
        }
        setPreviousQuestionSet([]);

        // refetch in case of clone
        if (id === QUESTION_SET) {
            questionSetData.refetch();
        }
    }

    // activate deactivate survey set
    const activateDeactivateSurveySet = (questionSet: Survey) => {
        // Toggle the isActive property of the clicked survey set
        const updatedList = questionSetList.map(item => {
            if (item.id === questionSet.id) {
                return {
                    ...item,
                    isActive: !item.isActive
                };
            }
            return item;
        });

        // Update both lists with the new isActive status
        setQuestionSetList(updatedList);
        setActualQuestionSet(updatedList);

        // API call
        activeInactiveQuestionSet(queryClient, activeInactiveMutation, { isActive: !questionSet.isActive, surveySetId: questionSet.id }, onResponse);
    }

    const activateDefaultSurveySet = (questionSet: Survey) => {
        let index = questionSetList.indexOf(questionSet);

        // Deactivate all other items
        let newList = [...questionSetList];
        if (!questionSet.isDefault) {
            newList = newList.map(item => ({
                ...item,
                isDefault: false
            }));
        }
        newList.splice(index, 1, {
            ...questionSet,
            isDefault: !questionSet.isDefault,
            isActive: true
        });
        setQuestionSetList([...getSortedSurveySet(newList)]);

        // Update value in unmodified list
        let newActualList = actualQuestionList.map(item => {
            if (item.id === questionSet.id) {
                return {
                    ...questionSet,
                    isDefault: !questionSet.isDefault,
                    isActive: true
                };
            } else {
                return {
                    ...item,
                    isDefault: false
                };
            }
        });
        setActualQuestionSet([...getSortedSurveySet(newActualList)]);
    }


    // invoke activate inactivate api only after state update
    useEffect(() => {
        if (prevSet.length > 0 && activateItem) {
            activateDeactivateSurveySet(activateItem);
        }
    }, [prevSet]) // eslint-disable-line

    useEffect(() => {
        if (prevSet.length > 0 && defaultItem) {
            activateDefaultSurveySet(defaultItem);
        }
    }, [prevSet, defaultItem]) // eslint-disable-line

    // clone button template
    const cloneButtonTemplate = (questionSet: Survey) => {
        return <MlxTooltip target='.clone'>
            <i data-pr-position="bottom" data-pr-tooltip={translationText.clone} className={"ri-file-copy-fill clone"} data-testid={"clone#" + questionSet.id}
                style={{ cursor: "pointer" }}
                onClick={() => {
                    if (checkPermission(queryClient, PermissionKeys.MANAGE_SURVEY, SubPermissionKeys.ADD)) {
                        cloneQuestionSet(queryClient, cloneQuestionSetMutation, questionSet.id,
                            onResponse);
                    }
                }}>
            </i>
        </MlxTooltip>
    }

    const downloadQR = async (survey: Survey) => {
        let qrImage = await generateQR(survey);
        saveAs(qrImage, `${survey.surveySetName}.png`);
    }

    // download qr template
    const downloadQRTemplate = (survey: Survey) => {
        return (
            <MlxTooltip target='.download'>
                <i
                    className={`ri-download-2-fill download ${width <= breakPoint ? 'rounded-background shadow-sm ms-2' : ''}`}
                    data-pr-tooltip={translationText.downloadQr}
                    data-pr-position="bottom"
                    data-testid={'qr#' + survey.id}
                    onClick={(e) => {
                        e.stopPropagation()
                        downloadQR(survey)
                    }}
                ></i>
            </MlxTooltip>
        )
    }

    // download qr template 
    const shareButtonTemplate = (survey: Survey) => {
        return (
            <MlxTooltip target='.download'>
                <i
                    className={`ri-share-fill email-text download ${width <= breakPoint ? 'rounded-background shadow-sm ms-2' : ''}`}
                    data-pr-tooltip={translationText.share}
                    data-pr-position="bottom"
                    data-testid={'qr#' + survey.id}
                    onClick={() => {
                        setDisplayPosition(true);
                        setSurveyInfo({ title: survey.surveySetName, postType: PostType.SURVEY, urlSlug: survey.urlSlug })
                    }}
                ></i>
            </MlxTooltip>
        )
    }

    // edit button
    const editButtonTemplate = (questionSet: Survey) => {
        return <span className={`${width <= breakPoint ? 'rounded-background shadow-sm ms-2' : ''}`}>
            <MlxTooltip target='.edit'>
                <FaEdit data-pr-position="bottom" data-pr-tooltip={translationText.edit}
                    className={`edit ${(!checkPermission(queryClient, PermissionKeys.MANAGE_SURVEY, SubPermissionKeys.EDIT) &&
                        " disabled")}`} data-testid={"edit#" + questionSet.id}
                    onClick={() => {
                        if (checkPermission(queryClient, PermissionKeys.MANAGE_SURVEY, SubPermissionKeys.EDIT)) {
                            navigate(routes.survey_create, {
                                state: {
                                    id: questionSet.id,
                                    questionTitle: questionSet.surveySetName,
                                    isGameAvailable: questionSet.isGameAvailable,
                                    surveyCategories: questionSet.surveyCategories,
                                    urlSlug: questionSet.urlSlug

                                }
                            })
                        }
                    }
                }>
                </FaEdit>
            </MlxTooltip>
        </span>
    }

    // delete button
    const deleteButtonTemplate = (questionSet: Survey) => {
        return questionSet.isDefault !== true ?
            <MlxTooltip target=".delete">
                <i data-pr-position="bottom" data-pr-tooltip={translationText.delete}
                    className={`ri-delete-bin-line delete 
                ${(!checkPermission(queryClient, PermissionKeys.MANAGE_SURVEY, SubPermissionKeys.DELETE) &&
                            " disabled")} ${width <= breakPoint ? 'rounded-background shadow-sm ms-2' : ''}`} data-testid={"delete#" + questionSet.id}
                    onClick={() => {
                        if (checkPermission(queryClient, PermissionKeys.MANAGE_SURVEY, SubPermissionKeys.DELETE)) {
                            setWarningType(WarningType.DELETE);
                            setDeleteItem(questionSet.id);
                            setWarning(true);
                        }
                    }
                }>
                </i>
            </MlxTooltip> : ""
    }

    // active inactive
    const activeInactiveTemplate = (questionSet: Survey) => {
        return (
            <div className="d-flex">
                <div className="flex-grow-0 ">
                    <span className="form-check form-switch">
                        <input className="form-check-input form-check-input-custom"
                            type="checkbox"
                            autoComplete="off"
                            data-testid={"active-inactive#" + questionSet.id}
                            checked={questionSet.isActive}
                            disabled={questionSet.isDefault}
                            onChange={() => {
                                if (checkPermission(queryClient, PermissionKeys.MANAGE_SURVEY, SubPermissionKeys.STATUS)) {
                                    setActivateItem(questionSet);
                                    if (!questionSet.isActive) {
                                        setWarningType(WarningType.ACTIVE_INACTIVE);
                                        setWarning(true);
                                    } else {
                                        setPreviousQuestionSet([...questionSetList]);
                                    }
                                }
                            }}
                            id="flexSwitchCheckDefault" 
                            style={width <= breakPoint ? { width: '3em', height: '1.5em' } : { width: '2.25em', height: '1em' }}
                        />
                    </span>
                </div>
                {width > breakPoint && <div className="flex-grow-1">{ACTIVE}</div>}
                <div className=""></div>
            </div>
        )
    }

    const defaultSurveyTemplate = (questionSet: Survey) => {
        return (
            <div className="d-flex">
                <div className="flex-grow-0 d-flex align-items-center">
                    {width <= breakPoint && <span className="me-2 text-nowrap">{DEFAULT}</span>}
                    <span className="form-check form-switch">
                        <input className="form-check-input form-check-input-custom"
                            type="checkbox"
                            autoComplete="off"
                            data-testid={"isDefault#" + questionSet.id}
                            disabled={questionSet.isDefault}
                            checked={questionSet.isDefault}
                            onChange={() => {
                                if (checkPermission(queryClient, PermissionKeys.MANAGE_SURVEY, SubPermissionKeys.STATUS)) {
                                    if (!questionSet.isDefault) {
                                        setDefaultItem(questionSet)
                                        setWarningType(WarningType.DEFAULT);
                                        setWarning(true);
                                    } else {
                                        setPreviousQuestionSet([...questionSetList]);
                                    }
                                }
                            }}
                            id="flexSwitchCheckDefault" 
                            style={width <= breakPoint ? { width: '3em', height: '1.5em' } : { width: '2.25em', height: '1em' }}
                        />
                    </span>
                </div>
                {width > breakPoint && <div className="flex-grow-1">{DEFAULT}</div>}
                <div className=""></div>
            </div>
        )
    }

    const questionBodyTemplate = (questionSet: Survey) => {
        return <MlxTooltip target=".email-text">
            <a
            className={`${width > breakPoint ? 'overflow-ellipsis' : ''} email-text`}
            data-pr-position="bottom"
            data-pr-tooltip={questionSet.surveySetName}
            href="/survey"
            onClick={(e) => {
                e.preventDefault()
                if (checkPermission(queryClient, PermissionKeys.MANAGE_SURVEY, SubPermissionKeys.EDIT)) {
                    navigate(routes.survey_create, {
                        state: {
                            id: questionSet.id,
                            questionTitle: questionSet.surveySetName,
                            isGameAvailable: questionSet.isGameAvailable,
                            surveyCategories: questionSet.surveyCategories
                        }
                    })
                }
            }}>
                {questionSet.surveySetName}
            </a>
        </MlxTooltip>
    }

    const hideShare = () => {
        setDisplayPosition(false);
    }

    const getModalTitle = () => {
        switch (warningType) {
            case WarningType.DELETE:
                return DELETE_WARNING

            case WarningType.DEFAULT:
                return DEFAULT_QUESTION

            case WarningType.ACTIVE_INACTIVE:
                return ACTIVATE_SURVEY_SET

            default:
                return DELETE_WARNING
        }
    }

    const itemTemplate = (question: Survey) => {
        return (
			<Card className="data-view-card mt-2 d-flex flex-column justify-content-even">
				<div className="d-flex flex-row justify-content-between align-items-center">
					<div className="m-0">{questionBodyTemplate(question)}</div>
					<div className="m-0 d-flex justify-content-even align-items-center">
						{defaultSurveyTemplate(question)}
					</div>
				</div>
				{question.createdAt && (
					<div className="mt-2">
						<p className="m-0 text-nowrap">
                            {convertUTCtoLocalByOffset(question.createdAt)}
                        </p>
					</div>
				)}
				<div className="view-footer mt-2 pt-3 d-flex flex-row justify-content-between align-items-center">
					<div className="d-flex justify-content-even align-items-center">
						{shareButtonTemplate(question)}
						{downloadQRTemplate(question)}
						{editButtonTemplate(question)}
						{deleteButtonTemplate(question)}
					</div>
					<div className="d-flex justify-content-even align-items-center">
						{activeInactiveTemplate(question)}
					</div>
				</div>
			</Card>
		)
    }

    return <>
        {/* share component */}
        <SharePopup displayPosition={displayPosition} hideShare={hideShare} title={surveyInfo.title} urlSlug={surveyInfo.urlSlug} postType={surveyInfo.postType} />

        <WarningModal title={getModalTitle()} show={showWarning} onHide={() => setWarning(false)}
            callback={() => {
                setWarning(false);
                setWarningType("");
                if (warningType === WarningType.DELETE) {
                    deleteSurveySet(queryClient, deleteQuestionSetMutation, deleteItemId, onResponse, displayToast);
                } else if (warningType === WarningType.DEFAULT) {
                    activeInactiveQuestionSet(queryClient, activeInactiveMutation, { isActive: true, surveySetId: defaultItem?.id! }, onResponse);
                    makeQuestionSetDefault(queryClient, activeInactiveMutation, { surveySetId: defaultItem?.id! }, () => {
                        questionSetData.refetch();
                        setDefaultItem(null);
                    });
                } else if (activateItem) {
                    setPreviousQuestionSet([...questionSetList]);
                }
            }} />

        <div id="qr-code" className='qr-code-parent' style={{ display: "none" }}>
            <p id="qr-top-label" className='qr-code-child qr-code-label-top'></p>
            <div className="qr-image-wrapper">
                {mobileLogUrl.url && (<img alt="small-logo" src={imageSrc} className="fnb-qr-code-logo" />)}
                <div id="qr-img" ></div>
            </div>
            <p id="qr-bottom-label" className='qr-code-child qr-code-label-bottom'></p>
        </div>

        {width <= breakPoint ? (
            <DataView
                value={questionSetList}
                layout={'list'}
                itemTemplate={itemTemplate}
                paginator
                rows={10}
                paginatorClassName="p-dataview-customers"
                dataKey="id"
                emptyMessage={translationText.emptyMessage}
            />
            ) : (
                <DataTable value={questionSetList} paginator className="p-datatable-customers" rows={10}
                    scrollable scrollHeight="flex"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[10, 25, 50]}
                    dataKey="surveySetId" rowHover responsiveLayout="scroll"
                    globalFilterFields={['surveySetName', 'status']} emptyMessage={translationText.emptyMessage}
                    currentPageReportTemplate={translationText.paginationTemplate}>
                    <Column field="surveySetName" header={<JTranslation typeCase="pascal" text={QUESTION_SET_TEXT} />} sortable className="word-break-grid" body={questionBodyTemplate} style={{ minWidth: '17rem' }}
                    />
                    <Column field="createdAt" header={<JTranslation typeCase="pascal" text={CREATED_DATE} />} sortable style={{ minWidth: '10rem', maxWidth: '12rem' }}
                        body={(questionSet: Survey) => {
                            if (questionSet.createdAt) {
                                return <>{convertUTCtoLocalByOffset(questionSet.createdAt)}</>
                            }
                            return "";
                        }} />
                    <Column field="isDefault" header={<JTranslation typeCase="pascal" text={"Default Survey"} />} style={{ maxWidth: '8rem', minWidth: '10rem' }} body={defaultSurveyTemplate} />
                    <Column field="isActive"  header={<JTranslation typeCase="pascal" text={STATUS} />} style={{ maxWidth: '8rem', minWidth: '10rem' }} body={activeInactiveTemplate} />
                    <Column field="share" style={{ maxWidth: '4.5rem', minWidth: '4.5rem' }} bodyClassName="bg-sticky-color" body={shareButtonTemplate} alignFrozen="right" frozen={true} />
                    <Column field="qrCode" style={{ maxWidth: '3rem', minWidth: '3rem' }} bodyClassName="bg-sticky-color" body={downloadQRTemplate} alignFrozen="right" frozen={true} />
                    <Column field="clone" style={{ maxWidth: '3rem', minWidth: '3rem' }} bodyClassName="bg-sticky-color" body={cloneButtonTemplate} alignFrozen="right" frozen={true} />
                    <Column field="edit" style={{ maxWidth: '3rem', minWidth: '3rem' }} bodyClassName="bg-sticky-color" body={editButtonTemplate} alignFrozen="right" frozen={true} />
                    <Column field="delete" style={{ maxWidth: '3rem', minWidth: '3rem' }} bodyClassName="bg-sticky-color" body={deleteButtonTemplate} alignFrozen="right" frozen={true} />
                </DataTable>
            )
        }
    </>
}

export default SurveyQuestionSetGrid;