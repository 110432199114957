import { useState, useEffect, useContext, useCallback, ChangeEvent } from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion';
import { CommonCtx } from '../../context/CommonCtxProvider';
import { createCustomMenu, initialCustomMenu, getSingleCustomMenu, editCustomMenuApi } from '../../helpers/customMenuHelper'
import { FileType, AlertVariant, toastMessageInitialData, SpecialMenuIdPrefixes, IMAGE_TYPE } from '../../constants/constants'
import { FnBCtx } from '../../context/MoveItemCtxProvider'
import { getValueAfterHash } from '../../helpers/menuViewHelper';
import { initialCustomMenuResponse } from '../../context/defaultContextValues';
import { escapeHtml, isWysiwygEditorEmpty, popover, replaceMessageValues, unescapeHtml } from '../../helpers/utils'
import { OverlayTrigger } from 'react-bootstrap'
import { routes } from '../../constants/routes'
import { ToastMessageProps, CustomMenu, GetSingleCustomMenuParamType, EditCustomMenu, UploadedAiImageInfo, CustomMenuForm, CropType } from '../../constants/staticTypes'
import { checkIfHeicHeif, uploadFile } from '../../helpers/fileUploadHelper'
import { useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import AiTextGenerator from '../../components/ai_text_generator/AiTextGenerator'
import ImageCropModal from '../../components/f&b_menu/admin/add_item_forms/ImageCropModal'
import menuImg1 from '../../assets/images/menu/no_image_upload.png'
import ToastAlert from '../../components/alert/ToastAlert'
import useMutationHook from '../../hooks/useMutationHook'
import WarningModal from '../../components/warning_modal/WarningModal'
import WysiwygEditorBasic from '../../components/wysiwyg_editor/WysiwygEditorBasic'
import CommonModal from '../../components/common_modal/CommonModal';
import AiImageGenerator from '../../components/ai_image_generator/AiImageGenerator';

import {
    AI_TEXT_GENERATOR,
    CANCEL,
    CUSTOM_MENU_DESCRIPTION_HELP,
    DELETE_WARNING,
    DESCRIPTION,
    SAVE,
    TITLE,
    UPLOAD_PHOTO_MESSAGE_MENU,
    UPLOAD_PHOTO,
    USER_UPLOAD_PHOTO_ERROR,
    AI_IMAGE_GENERATOR,
    UPLOAD_PHOTO_INFO,
    UPLOAD_PHOTO_ERROR,
} from '../../constants/strings'
import { JTranslation } from '../../helpers/jTranslate';
import MlxPopover from '../../components/common/MlxPopover';


const CreateCustomMenuComponent = () => {
    const { customMenuId } = useParams()
    const idPrefix = SpecialMenuIdPrefixes.SPECIAL_MENU
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const customMenuMutation = useMutationHook(queryClient, true)
    // CONTEXT VARIABLES
    const { setCustomMenuItem, isFormValidated, setIsFormValidated } = useContext(FnBCtx)
    const { setFnBGlobalSettings } = useContext(CommonCtx)
    // STATE VARIABLES
    const [completeCustomMenuId, setCompleteCustomMenuId] = useState("")
    const [formData, setFormData] = useState(initialCustomMenu)
    const [image, setImage] = useState('')
    const [imageCropSrc, setImageCrop] = useState<string | ArrayBuffer | null>(null)
    const [warning, setWarning] = useState(false)
    const [pageMode, setPageMode] = useState<"new" | "edit">("new")
    const [showAiTextGenerator, setShowAiTextGenerator] = useState(false)
    // ALERT VARIABLES
    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)

    const [photoKey, setPhotoKey] = useState("");
    const [aiImageModal, setAiImageModal] = useState(false);

    const cropType: CropType = 'BANNER';
    const validateImageDimension = { width: IMAGE_TYPE[cropType].width, height: IMAGE_TYPE[cropType].height };


    // api call create new custom menu
    const createCustomMenuApiCall = () => {
        createCustomMenu(customMenuMutation, formData, onSuccess, onError)
    }

    // api call create new custom menu
    const editCustomMenuApiCall = () => {
        const data: EditCustomMenu = { ...formData, menuId: completeCustomMenuId }
        editCustomMenuApi(customMenuMutation, data, onSuccess, onError)
    }

    // on get single custom menu success
    const onGetSingleCustomMenuSuccess = (data: CustomMenu) => {
        setCustomMenuItem(data)
        setFnBGlobalSettings(data.globalSettings)
        setFormData((prevState) => ({ ...prevState, menuName: data.menuName, menuDescription: data.menuDescription, photoKey: data.photoKey }))
        setImage(data.signedUrl)
        navigate(`${routes.custom_menu_list}/${getValueAfterHash(data.id)}`)
    }

    // api call to get single custom menu
    const getSingleCustomMenuApiCall = () => {
        const menuId: GetSingleCustomMenuParamType = { menuId: completeCustomMenuId }
        getSingleCustomMenu(customMenuMutation, menuId, onGetSingleCustomMenuSuccess, onError)
    }

    // on create custom menu success
    const onSuccess = (message: string, variant: string, data: CustomMenu) => {
        setToastMessage({ message, variant, show: true })
        setCompleteCustomMenuId(data.id)
    }

    // show toast
    const displayToast = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
    }

    // on file upload success
    const onUploadSuccess = (key: string, imageUrl: string) => {
        setFormData((prev) => ({ ...prev, photoKey: key }))
        setImage(imageUrl)
    }

    // on file upload error
    const onUploadError = () => displayToast(USER_UPLOAD_PHOTO_ERROR, AlertVariant.ERROR)

    // on api error
    const onError = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
    }

    useEffect(() => {
        if (completeCustomMenuId) getSingleCustomMenuApiCall()
    }, [completeCustomMenuId]) // eslint-disable-line

    useEffect(() => {
        if (customMenuId) {
            setCompleteCustomMenuId(`${idPrefix}${customMenuId}`)
            setPageMode("edit")
        }
    }, [customMenuId]) // eslint-disable-line

    useEffect(() => {
        // validate form data
        setIsFormValidated(!(formData.menuName && !isWysiwygEditorEmpty(formData.menuDescription)))
    }, [formData]) // eslint-disable-line

    useEffect(() => {
        // clean-up
        return () => {
            setCompleteCustomMenuId("")
            setFormData(initialCustomMenu)
            setIsFormValidated(false)
            setCustomMenuItem(initialCustomMenuResponse)
        }
    }, []) //eslint-disable-line

    // on delete file
    const onDeleteFile = () => {
        setImage("");
        setFormData((prev) => { return { ...prev, photoKey: '' } });
    }

    const saveImages = useCallback(({ imageKeys, signedImageUrls }: UploadedAiImageInfo) => {
        setPhotoKey(imageKeys[0]);
        setImage(signedImageUrls[0]);
        setFormData((prevData: CustomMenuForm) => {
            return { ...prevData, photoKey: photoKey }
        })
    }, [photoKey])

    // validate upload image dimensions
    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0]
        if (!file) {
            return // Handle no file selected case
        }

        const reader = new FileReader()
        reader.onload = (event) => {
            if (event.target?.result && typeof event.target?.result === 'string') {
                const img = new Image()
                img.onload = () => {
                    const width = img.naturalWidth
                    const height = img.naturalHeight
                    if (
                        validateImageDimension?.width &&
                        validateImageDimension?.height &&
                        (width < validateImageDimension.width || height < validateImageDimension.height)
                    ) {
                        displayToast(
                            replaceMessageValues(UPLOAD_PHOTO_ERROR, [
                                validateImageDimension?.width,
                                validateImageDimension?.height,
                            ]),
                            AlertVariant.ERROR
                        )
                        e.target.value = ''
                    } else {
                        uploadFile(
                            FileType.IMAGE,
                            e,
                            customMenuMutation,
                            onUploadSuccess,
                            onUploadError,
                            displayToast,
                            setImageCrop
                        )
                    }
                }
                img.src = event.target.result
            }
        }
        reader.readAsDataURL(file)
    }

    return (
        <>
            <CommonModal
                show={aiImageModal}
                modalContent={<AiImageGenerator callBack={saveImages} type="otherImage" cropType={cropType} />}
                onHide={setAiImageModal}
                title={AI_IMAGE_GENERATOR}
                hideFooter
            />
            <ImageCropModal
                displayToast={displayToast}
                image={imageCropSrc as string}
                mutation={customMenuMutation}
                onError={onUploadError}
                onSuccess={onUploadSuccess}
                setImageCrop={setImageCrop}
                show={imageCropSrc !== null}
                cropType={cropType}
            />
            <WarningModal
                show={warning}
                onHide={() => setWarning(false)}
                title={DELETE_WARNING}
                callback={() => {
                    setImage('')
                    setWarning(false)
                    //remove image
                    onDeleteFile()


                }}
            />
            <ToastAlert
                data-testid="toast"
                message={toastMessage.message}
                onClose={() => setToastMessage(toastMessageInitialData)}
                show={toastMessage.show}
                variant={toastMessage.variant}
            />

            <div className="custom-menu-config pt-0">
                <Accordion className='custom-accordion' activeIndex={0}>
                    <AccordionTab header={<JTranslation typeCase="pascal" text={"Special Menu Details"} />}>
                        <div className="row">

                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <label>
                                            <JTranslation typeCase="pascal" text={TITLE} />
                                            <span className="mandatory ">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="menu-name"
                                            autoComplete="off"
                                            value={formData.menuName}
                                            maxLength={100}
                                            data-testid="special-menu-title"
                                            onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, menuName: e.target.value }))
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-12 mb-3">

                                        <div className="d-flex justify-content-between align-items-center mb-2">
                                            <label className='d-flex align-items-center'>
                                                <JTranslation typeCase="pascal" text={DESCRIPTION} />
                                                <span className="mandatory ">*</span>
                                                <MlxPopover data={{title:DESCRIPTION, body:CUSTOM_MENU_DESCRIPTION_HELP}}/>
                                            </label>
                                            <button className="btn btn-sm btn-custom-primary-outline" data-testid="ai-text-btn" onClick={() => { setShowAiTextGenerator(!showAiTextGenerator) }}>
                                                <JTranslation typeCase="pascal" text={AI_TEXT_GENERATOR} />
                                            </button>
                                        </div>
                                        <AiTextGenerator
                                            key='menuDescription'
                                            show={showAiTextGenerator}
                                            callBack={(aiResponse: string) => setFormData((prev) => ({ ...prev, menuDescription: aiResponse }))}
                                            type='menuDescription'
                                        />

                                        <WysiwygEditorBasic
                                            editorContent={unescapeHtml(formData?.menuDescription)}
                                            data-testid="description"
                                            callback={(value) => {
                                                setFormData((prev) => ({ ...prev, menuDescription: escapeHtml(value) }))
                                            }}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className='col-md-4'>
                                <div className="row">
                                    <div className="col-md-12  col-lg-12  ">
                                        <div className="row img-flex">
                                            {/* <!---image----> */}
                                            <div className="mb-3 col-md-12  col-lg-12  ">
                                                <div className="row img-flex">
                                                    <div className=" col-12 mb-2">
                                                        <div
                                                            className={
                                                                image !== null && image?.trim().length > 0
                                                                    ? ' img-thumb-main'
                                                                    : 'no-image-avathar img-thumb-main'
                                                            }
                                                        >
                                                            <div className="view-img-preview">
                                                                <img
                                                                    src={
                                                                        image !== null && image?.trim().length > 0
                                                                            ? image
                                                                            : menuImg1
                                                                    }
                                                                    className="img-fluid rounded-1"
                                                                    alt=" "
                                                                />
                                                            </div>
                                                            <div className="preview-tool text-end">
                                                                <i
                                                                    className="ri-delete-bin-6-line delete"
                                                                    onClick={() => setWarning(true)}
                                                                ></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-3 col-md-12  col-lg-12 d-flex flex-column upload-c">
                                        <label
                                            htmlFor="file-upload"
                                            className="custom-file-upload btn  btn-custom-primary-outline w-100 "
                                        >
                                            <i className="fas fa-plus"></i> <JTranslation typeCase="pascal" text={UPLOAD_PHOTO} />
                                        </label>
                                        <input
                                            id="file-upload"
                                            type="file"
                                            accept="image/*,.heic,.heif"
                                            autoComplete="off"
                                            data-testid="upload"
                                            onChange={async (event) => {
                                                const updatedEvent = await checkIfHeicHeif(event)
                                                if (validateImageDimension) {
                                                    handleFileChange(updatedEvent)
                                                } else {
                                                    uploadFile(
                                                        FileType.IMAGE,
                                                        updatedEvent,
                                                        customMenuMutation,
                                                        onUploadSuccess,
                                                        onUploadError,
                                                        onError,
                                                        setImageCrop
                                                    )
                                                }
                                            }}
                                        />
                                        <div className="mt-2 small text-secondary"><JTranslation typeCase="capitalize" text={
                                            validateImageDimension?.width && validateImageDimension?.height
                                                ? replaceMessageValues(UPLOAD_PHOTO_INFO, [
                                                    validateImageDimension?.width,
                                                    validateImageDimension?.height,
                                                ])
                                                : UPLOAD_PHOTO_MESSAGE_MENU
                                        } /></div>
                                        <div className="col-12">
                                            <label
                                                htmlFor="image-generate"
                                                className="custom-file-upload btn  btn-custom-primary w-100"
                                                data-testid="ai-image-generate"
                                                onClick={() => setAiImageModal(true)}
                                            >
                                                <i className="fas fa-plus"></i> <JTranslation typeCase="pascal" text={AI_IMAGE_GENERATOR} />
                                            </label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-12 mt-3">
                            <div className="save-btn-section p-3 rounded">
                                <button
                                    type="button"
                                    className="btn  btn-custom-primary-outline"
                                    data-testid="cancel"
                                    onClick={() => {
                                        setFormData(initialCustomMenu)
                                        navigate(routes.custom_menu_list)
                                    }}
                                >
                                    <JTranslation typeCase="pascal" text={CANCEL} />
                                </button> {" "}
                                <button
                                    type="button"
                                    className="btn  btn-custom-primary"
                                    disabled={isFormValidated}
                                    data-testid="save"
                                    onClick={() => {
                                        if (pageMode === "new") {
                                            createCustomMenuApiCall()
                                        } else {
                                            editCustomMenuApiCall()
                                        }
                                    }}
                                >
                                    <JTranslation typeCase="pascal" text={SAVE} />
                                </button>
                            </div>

                        </div>
                    </AccordionTab>

                </Accordion>

            </div>
        </>
    )
}

export default CreateCustomMenuComponent
