import { useState, useContext } from 'react';
import { CommonCtx } from '../../context/CommonCtxProvider';
import { EventPostGuestView, PostType } from '../../constants/staticTypes';
import { JTranslation } from '../../helpers/jTranslate';
import { routes } from '../../constants/routes';
import { timeFormatter, dateFormatter, unescapeHtml } from '../../helpers/utils';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from "uuid";
import blankImage from '../../assets/images/menu/600x400.png';
import SharePopup from "./SharePopup";

type props = {
    event: EventPostGuestView
}

const EventsCard = ({ event }: props) => {
    const { description, endDate, endTime, location, postTags, signedPhotosKeys, startDate, startTime, title, urlSlug, isRecurring, nextOccurrenceDate } = event
    const navigate = useNavigate()
    const { setPostViewFromInternal } = useContext(CommonCtx)

    const [position, setPosition] = useState('center');
    const [displayPosition, setDisplayPosition] = useState(false);
    const showShare = (position: string) => {
        setDisplayPosition(true);

        if (position) {
            setPosition(position);
        }
    }

    const hideShare = () => {
        setDisplayPosition(false);
    }

    return (     
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-3">
                <div className="  p-3 border rounded news-box event-box h-100">
                    <div className="h-100 d-flex flex-column">
                        <div className="flex-grow-1 flex-column w-100"
                            data-testid="event-card"
                            onClick={() => {
                                setPostViewFromInternal(urlSlug)
                                navigate(`${routes.events_in_detail}/${urlSlug}`)
                            }}>

                            <img alt="event" src={signedPhotosKeys && typeof signedPhotosKeys !== 'object' ? signedPhotosKeys : blankImage}
                                className="h-40 object-fit-cover mb-3 rounded shadow-custom w-100"
                            />
                            <div className="d-flex flex-row">
                                <div className="col-3 pe-2 align-items-center justify-content-center d-flex">
                                <div className="eventDate flex-shrink-0">
                                    <div className="month">{<JTranslation text={dateFormatter({ date: isRecurring ? nextOccurrenceDate : startDate, format: "MMM" })} />}</div>
                                    <div className="day">{<JTranslation text={dateFormatter({ date: isRecurring ? nextOccurrenceDate : startDate, format: "DD" })} />}</div>
                                    <div className="year">{<JTranslation text={dateFormatter({ date: isRecurring ? nextOccurrenceDate : startDate, format: "YYYY" })} />}</div>
                                </div>
                                </div>
                                <div className="col-9">
                                    <h5 className=" mb-2">{<JTranslation text={title} />}</h5>
									{postTags?.split(',').map((tag) => (
										<span key={uuidv4()} className="item-badge mb-1">{<JTranslation text={tag} />}</span>
									))}
                                        <div className="description description-news-events mb-3 mt-1">
                                            {<JTranslation text={unescapeHtml(description)} type="html" />}
                                        </div>

                                        {location 
                                        ? <div className="d-flex align-items-center">
                                            <div className="flex-grow-1 d-flex fw-bold opacity-75">
                                                <span className="me-2 d-flex">
                                                    <i className="ri-map-pin-line"></i>
                                                </span>
                                                <span>
                                                    {' '}
                                                    {<JTranslation text={location} />}
                                                </span>
                                            </div>
                                        </div>
                                        : null
                                        }

                                        {isRecurring
                                            ? ""
                                            : <div className="d-flex align-items-center">
                                                <div className="flex-grow-1 d-flex fw-bold opacity-75">
                                                    <span className="me-2 d-flex">
                                                        <i className="ri-calendar-line"></i>
                                                    </span>
                                                    <span>
                                                        {' '}
                                                        {
                                                            <JTranslation text={dateFormatter({ date: startDate })} />
                                                        } To {<JTranslation text={dateFormatter({ date: endDate })} />}
                                                    </span>
                                                </div>
                                            </div>
                                        }
                                        
                                </div>
                            </div>
                        </div>

                        <div className="flex-grow-0">
                            <hr className="my-2"></hr>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 fw-bold opacity-75">
                                    <span className="me-2">
                                        <i className="ri-time-line"></i>
                                    </span>
                                    <span>
                                        {<JTranslation text={timeFormatter({ time: startTime })} />} To{' '}
                                        {<JTranslation text={timeFormatter({ time: endTime })} />}
                                    </span>
                                </div>
                                <div className="flex-grow-0">
                                    <button className="shareButton button btn  back-btn back-btn-new" data-testid="share-event" onClick={() => showShare('bottom')}>
                                    <i className="ri-share-line"></i>
                                    </button>
                                </div>
                            </div>
                            <p className="mb-0"></p>
                        </div>
                    </div>
                </div>

                {/* share component */}
                <SharePopup displayPosition={displayPosition} hideShare={hideShare} title={title} urlSlug={urlSlug} postType={PostType.EVENT} />

        </div>
    )
}

export default EventsCard
